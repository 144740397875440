import React, { memo } from 'react';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

/**
 * FormInput component
 * displays input with label and error in forms
 * @param { string } label - input label
 * @param { string || number } value - input current value
 * @param { string } name - input name
 * @param { function } handleBlur - blur handler
 * @param { function } handleChange - change handler
 * @param { string } error - input error
 * @param { string } helperText - input helpers text. displays if no error
 * @param {Object} props
 */
const FormInput = ({
  label,
  value,
  name,
  handleBlur,
  handleChange,
  error,
  helperText,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={classes.formRow}>
      <Typography
        variant="body1"
        className={classNames(classes.fieldName, classes.inputFieldName)}
      >
        {label}
      </Typography>
      <div>
        <TextField
          {...props}
          name={name}
          defaultValue={value}
          onBlur={handleBlur}
          onChange={handleChange}
          variant="outlined"
          error={!!error}
        />
        <FormHelperText error={!!error} className={classes.helperText}>
          {error || helperText}
        </FormHelperText>
      </div>
    </div>
  );
};

FormInput.propTypes = {
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default memo(FormInput);
