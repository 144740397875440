import { useEffect, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Leaflet from 'leaflet-providers';

import { CUSTOM_LAYERS_Z_INDEX, MAP_CONTROLS_POSITION } from 'constants/map';
import { useMapInstance, useMapControls, useMapEvents } from 'hooks/leaflet';

import './style.scss';

/**
 * Control for manage map layers with custom overlays
 * @param {Array} customOverlays
 * @param {Boolean} autoZIndex
 * @param {String} position
 * @param {Boolean} multiply
 * @return {null}
 */
const MapCustomControlLayers = ({
  customOverlays,
  autoZIndex,
  position,
  multiply,
}) => {
  const mapInstance = useMapInstance();
  const mapControls = useMapControls();

  const onOverlayAdd = useCallback(
    ({ layer }) => mapControls.hideOtherLayers(layer, true),
    [mapControls]
  );

  useEffect(() => {
    if (!mapControls) {
      return;
    }

    mapControls.setOptions({
      autoZIndex,
      multiply,
      position,
      iconClassName: 'custom-layers-control',
    });
  }, [mapControls, autoZIndex, position, multiply]);

  useEffect(() => {
    if (!mapInstance) {
      return;
    }

    customOverlays.forEach(({ name, layers }) => {
      layers.forEach(({ tile, title }) => {
        mapControls.addOverlay(
          new Leaflet.TileLayer(tile, { zIndex: CUSTOM_LAYERS_Z_INDEX }),
          title,
          name
        );
      });
    });

    mapControls.addTo(mapInstance);
    // eslint-disable-next-line
  }, [mapInstance, mapControls]);

  useMapEvents(
    {
      element: mapInstance,
      events: { overlayadd: onOverlayAdd },
    },
    [onOverlayAdd]
  );

  return null;
};

MapCustomControlLayers.propTypes = {
  customOverlays: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      layers: PropTypes.arrayOf(
        PropTypes.shape({
          tile: PropTypes.string,
          title: PropTypes.string,
        })
      ).isRequired,
    })
  ),
  position: PropTypes.string,
  autoZIndex: PropTypes.bool,
  multiply: PropTypes.bool,
};

MapCustomControlLayers.defaultProps = {
  customOverlays: [],
  position: MAP_CONTROLS_POSITION.topLeft,
  autoZIndex: false,
  multiply: false,
};

export default memo(MapCustomControlLayers);
