import React, { useCallback, memo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

/**
 * PropertiesListItem component
 * @param {Object} item
 * @param {Boolean} selected
 * @param {Function} onItemClick
 * @param {String} id
 */
const PropertiesListItem = ({ item, selected, onItemClick, id }) => {
  const classes = useStyles();

  const handleClick = useCallback(() => onItemClick && onItemClick(item), [
    onItemClick,
    item,
  ]);

  return (
    <Grid
      id={id}
      onClick={handleClick}
      item
      container
      alignItems="center"
      justifyContent="space-between"
      className={classNames(classes.pointItem, {
        [classes.pointItemSelected]: selected,
      })}
    >
      <Typography className={classes.itemName}>{item.name}</Typography>
    </Grid>
  );
};

PropertiesListItem.propTypes = {
  item: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onItemClick: PropTypes.func,
  id: PropTypes.string,
};

export default memo(PropertiesListItem);
