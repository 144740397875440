import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useModal } from 'hooks/useModal';
import LoaderOverlay from 'components/common/LoaderOverlay';
import ComparisonAgainstMeasurementsModal from 'components/modals/ComparisonAgainstMeasurementsModal';
import { requestBuoyStats } from 'ducks/buoys/actions';
import {
  selectBuoyById,
  selectBuoyRunsById,
  selectBuoysIsLoading,
  selectBuoyStatisticsById,
} from 'ducks/buoys/selectors';
import { selectFullZoneInfoByProjectId } from 'ducks/projects/selectors';
import SilentBoundary from 'components/common/SilentBoundary';
import { NOTIFICATION_MODAL } from 'constants/modals';

/**
 * Container for buoy information and statistics charts.
 * @param {Number} buoyId
 * @param {Number} modalId
 * @param {Number} projectId
 * @param {String} numericalSimulation
 * @return {jsx}
 */
const ComparisonAgainstMeasurements = ({
  buoyId,
  projectId,
  numericalSimulation,
  id: modalId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    buoy,
    projectZone,
    buoyRuns,
    buoyStatistics,
    isLoading,
  } = useSelector((state) => ({
    buoy: selectBuoyById(state, buoyId),
    buoyRuns: selectBuoyRunsById(state, buoyId),
    isLoading: selectBuoysIsLoading(state),
    projectZone: selectFullZoneInfoByProjectId(state, projectId),
    buoyStatistics: selectBuoyStatisticsById(state, buoyId),
  }));
  const { onClose, openModal } = useModal(modalId);

  useEffect(() => {
    const [dataset] = projectZone[numericalSimulation.toLowerCase()] || [];
    if (!buoy.id || !dataset) {
      return;
    }

    const zoneRunNames = dataset.runs
      ? dataset.runs.map(({ name }) => name)
      : [];
    const runIds = buoyRuns.reduce((acc, { name, id }) => {
      if (zoneRunNames.includes(name)) {
        acc.push(id);
      }

      return acc;
    }, []);
    dispatch(
      requestBuoyStats({
        buoyId: buoy.id,
        params: {
          runs: runIds.join(),
        },
      })
    );
  }, [buoy.id, numericalSimulation]);

  const errorHandler = useCallback(() => {
    onClose();
    openModal(NOTIFICATION_MODAL, {
      message: t('errors.common'),
      autoHideDuration: null,
    });
  }, [onClose, openModal, t]);

  return isLoading ? (
    <LoaderOverlay center />
  ) : (
    <SilentBoundary onError={errorHandler}>
      <ComparisonAgainstMeasurementsModal
        onClose={onClose}
        buoy={buoy}
        statistics={buoyStatistics}
      />
    </SilentBoundary>
  );
};

ComparisonAgainstMeasurements.propTypes = {
  id: PropTypes.string.isRequired,
  numericalSimulation: PropTypes.string.isRequired,
  buoyId: PropTypes.number.isRequired,
};

export default memo(ComparisonAgainstMeasurements);
