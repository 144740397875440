import { useCallback } from 'react';

import { useForceUpdate } from './useForceUpdate';

/**
 * hook for work with leaflet map popup container node
 * @param {Function} [onClose]
 * @param {Function} [onOpen]
 * @return {{ onPopupClose: Function, onPopupOpen: Function }}
 */
export const useMapPopupHandlers = ({ onOpen, onClose }) => {
  const forceUpdate = useForceUpdate();

  const onPopupOpen = (event) => {
    if (onOpen) {
      onOpen(event);
    }
    forceUpdate();
  };

  const onPopupClose = (event) => {
    if (onClose) {
      onClose(event);
    }
    forceUpdate();
  };

  return {
    onPopupOpen: useCallback(onPopupOpen, [onOpen]),
    onPopupClose: useCallback(onPopupClose, [onClose]),
  };
};
