import React, { memo } from 'react';
import Typography from '@material-ui/core/Typography';

import { toRadian } from 'helpers/common';
import { getPrettyNumber } from 'helpers/data';

import { useStyles } from './styles';

/**
 * Rose Tooltip - tooltip for rose graph.
 * Renders tooltip in the opposite side of selected sector, to not overlap
 * @param offsetRadius - radius of wanted offset
 * @param startAngle - selected sector startAngle
 * @param endAngle - selected sector endAngle
 * @param data - data to display in tooltip
 * @param probabilities - probabilities to show in extend info
 * @param legends - legends to show in extend info
 * @param legendsTitle - legends title to show in extend info
 * @param isExpanded - whether tooltip is expanded or not
 * @param tooltipOffset
 * @returns {JSX}
 */
const RoseTooltip = ({
  tooltipOffset,
  offsetRadius,
  startAngle,
  endAngle,
  probabilities,
  isExpanded,
  legendTitle,
  legends,
  data,
}) => {
  const classes = useStyles();

  const bissectriceAngle = toRadian(startAngle + (endAngle - startAngle) / 2);
  const rightOffset = 2 * Math.cos(bissectriceAngle) * offsetRadius;
  const topOffset = 2 * Math.sin(bissectriceAngle) * offsetRadius;

  return (
    <div
      className={classes.container}
      style={{
        position: 'relative',
        right: (tooltipOffset.right ?? 0) + rightOffset,
        top: (tooltipOffset.top ?? 0) + topOffset,
      }}
    >
      {Object.entries(data).map(([key, value]) => (
        <div key={key}>
          {key}: {value}
        </div>
      ))}
      {isExpanded ? (
        <div>
          <Typography variant="subtitle2">{legendTitle}:</Typography>
          {probabilities.map((probability, index) => (
            <div>
              {legends[index]}: {getPrettyNumber(probability)}
            </div>
          ))}
        </div>
      ) : (
        <Typography variant="subtitle2">Click for details</Typography>
      )}
    </div>
  );
};

export default memo(RoseTooltip);
