import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import {
  ComposedChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
  Scatter,
  Customized,
} from 'recharts';

import BaseTooltip from 'components/common/graphs/BaseTooltip';
import { formatDateToUTC } from 'helpers/date';
import { DATE_FORMATS, EOL } from 'constants/common';
import { palette } from 'common/theme';
import CustomParametersBlock from 'components/common/graphs/CustomParametersBlock';
import { CHART_SVG_ID } from 'constants/graphs';
import { useUniqueId } from 'hooks/useUniqueId';

import { useStyles } from './styles';
import OneLawLegend from './OneLawLegend';

const CANVAS_OFFSET = 20;
const AXIS_WIDTH = 30;
const TICK_FONT_SIZE = 10;
const PARAMETERS_WIDTH = 120;
const PARAMETERS_OFFSET = 10;
const LABEL_EDGE_OFFSET = 10;

const GRAPH_MARGIN = {
  left: CANVAS_OFFSET,
  right: PARAMETERS_OFFSET + PARAMETERS_WIDTH,
  top: CANVAS_OFFSET,
  bottom: CANVAS_OFFSET,
};

const LINE_COLORS = [
  palette.graphLines.blue,
  palette.graphLines.red,
  palette.graphLines.green,
  palette.black.main,
];

/**
 * Formatter for tick. Ticks formatter that shows only ticks which are 10^n (like 1, 10, 100, 1000)
 * @param {Number} tick
 * @return {String}
 */
const returnValuesPlotTickFormatter = (tick) => {
  const log10 = Math.log10(tick);
  return Math.abs(Math.round(log10) - log10) < 1e-6 ? tick : '';
};

/**
 * Circle element
 * @param {Number} cx
 * @param {Number} cy
 * @param {String|Number} key
 * @param {number} radius
 * @param {string} fill
 * @param {string} stroke
 * @param {String} className
 * @return {jsx}
 * @constructor
 */
const Circle = ({ cx, cy, key, className, radius, fill, stroke }) =>
  !!(cx && cy) && (
    <circle
      cx={cx}
      cy={cy}
      r={radius}
      key={key}
      fill={fill}
      stroke={stroke}
      className={className}
    />
  );

/**
 * Return values plot - logarithmic chart with lines and points
 * @param {{ chartData, axisData }} commonData
 * @param { Number } canvasWidth
 * @param { Number } canvasHeight
 * @param { string } selectedLaw
 * @param { bool } hasOutliers
 * @return { jsx }
 */
const ReturnValuesPlot = ({
  commonData,
  canvasWidth,
  canvasHeight,
  selectedLaw,
  hasOutliers,
}) => {
  const salt = useUniqueId();
  const classes = useStyles({ canvasOffset: CANVAS_OFFSET });
  const [tooltip, setTooltip] = useState();
  const chartRef = useRef(null);
  const wrapperRef = useRef(null);

  const {
    xTicks,
    yTicks,
    values,
    parameters,
    variantsData,
  } = commonData.chartData;
  const { axisInfo, axisLabels } = commonData.axisData;

  const totalOffset = AXIS_WIDTH + 2 * CANVAS_OFFSET;
  const chartWidth =
    canvasWidth + CANVAS_OFFSET + PARAMETERS_OFFSET + PARAMETERS_WIDTH;

  const onScatterEnter = useCallback(
    ({ tooltipPosition, payload }) => {
      const { container: chartContainer } = chartRef.current;
      const { left: chartX } = chartContainer.getBoundingClientRect();
      const { left: wrapperX } = wrapperRef.current.getBoundingClientRect();

      setTooltip({
        x: tooltipPosition.x + (chartX - wrapperX),
        y: tooltipPosition.y,
        content: payload,
      });
    },
    [chartRef, wrapperRef, setTooltip]
  );

  const onScatterLeave = useCallback(() => setTooltip(null), [setTooltip]);

  const variantsToShow = selectedLaw
    ? variantsData.filter(({ law }) => law === selectedLaw)
    : variantsData;

  const [selectedVariant] = selectedLaw ? variantsToShow : [];

  const dotsColor = palette.tertiary.main;
  const outlierDotsColor = palette.danger.main;

  const xAxisYLabelOffset =
    CANVAS_OFFSET + canvasHeight + AXIS_WIDTH - LABEL_EDGE_OFFSET;

  const preparedParameters = [
    { name: 'Law', value: selectedLaw },
    ...parameters,
  ];

  return (
    <Grid
      ref={wrapperRef}
      justifyContent="center"
      container
      className={classes.graphWrapper}
    >
      <Grid item>
        <Grid container>
          <Grid item>
            <ComposedChart
              id={CHART_SVG_ID + salt}
              ref={chartRef}
              width={chartWidth}
              height={canvasHeight + totalOffset}
              margin={GRAPH_MARGIN}
              className={classes.graphContainer}
              data={values}
            >
              <XAxis
                key={0}
                type="number"
                height={AXIS_WIDTH}
                ticks={xTicks}
                interval={0}
                domain={[xTicks[0], xTicks[xTicks.length - 1]]}
                scale="log"
                dataKey="x"
                tick={{ fontSize: TICK_FONT_SIZE }}
                label={{
                  fontSize: 12,
                  value: axisLabels.x,
                  position: 'bottom',
                  y: xAxisYLabelOffset,
                }}
                tickFormatter={returnValuesPlotTickFormatter}
              />
              <YAxis
                key={2}
                type="number"
                width={AXIS_WIDTH}
                ticks={yTicks}
                domain={[yTicks[0], yTicks[yTicks.length - 1]]}
                interval={0}
                tick={{ fontSize: TICK_FONT_SIZE }}
                label={{
                  fontSize: 12,
                  value: axisLabels.y,
                  angle: -90,
                  position: 'center',
                  dx: -15,
                }}
              />
              <CartesianGrid key={3} />

              {variantsToShow.map(({ law }, index) => {
                const color = LINE_COLORS[index];

                return [
                  <Line
                    key={`${law}.value`}
                    isAnimationActive={false}
                    dataKey={`${law}.value`}
                    type="monotone"
                    stroke={color}
                    fill={color}
                    dot={false}
                    connectNulls
                  />,
                  <Line
                    key={`${law}.lower`}
                    isAnimationActive={false}
                    dataKey={`${law}.lower`}
                    type="monotone"
                    stroke={color}
                    fill={color}
                    dot={false}
                    strokeDasharray="3 3"
                    connectNulls
                  />,
                  <Line
                    key={`${law}.upper`}
                    isAnimationActive={false}
                    dataKey={`${law}.upper`}
                    type="monotone"
                    stroke={color}
                    fill={color}
                    dot={false}
                    strokeDasharray="3 3"
                    connectNulls
                  />,
                ];
              })}

              <Scatter
                key={4}
                isAnimationActive={false}
                shape={Circle}
                dataKey="point"
                fill={dotsColor}
                className={classes.scatterPoint}
                stroke={palette.black.main}
                radius={4}
                onMouseEnter={onScatterEnter}
                onFocus={onScatterEnter}
                onMouseLeave={onScatterLeave}
                onBlur={onScatterLeave}
              />
              {(() => {
                if (hasOutliers) {
                  return (
                    <Scatter
                      key={5}
                      isAnimationActive={false}
                      shape={Circle}
                      dataKey="outlier"
                      fill={outlierDotsColor}
                      className={classes.scatterPoint}
                      stroke={palette.black.main}
                      radius={4}
                      onMouseEnter={onScatterEnter}
                      onFocus={onScatterEnter}
                      onMouseLeave={onScatterLeave}
                      onBlur={onScatterLeave}
                    />
                  );
                }
              })()}
              {/* TODO as soon as new format with 3 laws would be provided, add here legend for 3 laws (when no law is selected) */}
              {selectedVariant && (
                <Customized
                  key={6}
                  component={OneLawLegend}
                  confidence={selectedVariant.confidence}
                  colors={{
                    point: dotsColor,
                    outliers: outlierDotsColor,
                    line: LINE_COLORS[0],
                  }}
                  withOutlier={hasOutliers}
                />
              )}
              {!!selectedLaw && (
                <Customized
                  key={7}
                  component={CustomParametersBlock}
                  parameters={preparedParameters}
                  blockWidth={PARAMETERS_WIDTH}
                  leftOffset={PARAMETERS_OFFSET}
                  fontSize={10}
                />
              )}
            </ComposedChart>
          </Grid>
        </Grid>
      </Grid>
      {tooltip && (
        <BaseTooltip
          x={tooltip.x}
          y={tooltip.y}
          yPosition={tooltip.yPosition}
          offsetX={-40}
          offsetY={30}
        >
          {formatDateToUTC(
            new Date(tooltip.content.timestamp),
            DATE_FORMATS.ymdhmFormat
          )}
          {EOL}
          {axisInfo.x.longName.toLowerCase()}: {tooltip.content.x.toFixed(2)}
          &nbsp;
          {axisInfo.x.units}
          {EOL}
          {axisInfo.y.longName.toLowerCase()}:{' '}
          {tooltip.content.point
            ? tooltip.content.point.toFixed(2)
            : tooltip.content.outlier.toFixed(2)}
          &nbsp;
          {axisInfo.y.units}
        </BaseTooltip>
      )}
    </Grid>
  );
};

ReturnValuesPlot.defaultProps = {
  selectedLaw: PropTypes.string,
  canvasWidth: PropTypes.number,
  canvasHeight: PropTypes.number,
  commonData: PropTypes.shape({
    chartData: PropTypes.shape({
      xTicks: PropTypes.arrayOf(PropTypes.number),
      yTicks: PropTypes.arrayOf(PropTypes.number),
      parameters: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.string,
        })
      ),
      values: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.number,
          point: PropTypes.number,
        })
      ),
      variantsData: PropTypes.array,
    }),
    axisData: PropTypes.shape({
      axisInfo: PropTypes.object,
      axisLabels: PropTypes.shape({
        x: PropTypes.string,
        y: PropTypes.string,
      }),
    }),
  }),
};

ReturnValuesPlot.defaultProps = {
  canvasWidth: 290,
  canvasHeight: 260,
};

export default ReturnValuesPlot;
