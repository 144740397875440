import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import BaseModal from 'components/common/BaseModal';

import { useStyles } from './styles';

/**
 * PlanningErrorLogModal component
 * Show error log for failed planning
 * @param { function } onClose - handler for close modal button
 * @param { string } error - error message to display
 */
const PlanningErrorLogModal = ({ onClose, error }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <BaseModal onClose={onClose} title={t('plannings.errorLogModalTitle')}>
      <Typography className={classes.text}>{error}</Typography>
    </BaseModal>
  );
};

PlanningErrorLogModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
};

export default React.memo(PlanningErrorLogModal);
