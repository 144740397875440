import React from 'react';
import { useSelector } from 'react-redux';

import App from 'components/app/App';
import { selectIsInitialized } from 'ducks/app/selectors';
import { selectIsAuthorized, selectIsUserExpired } from 'ducks/user/selectors';

const AppContainer = () => {
  const isExpired = useSelector(selectIsUserExpired);
  const isAuthorized = useSelector(selectIsAuthorized);
  const isInitialized = useSelector(selectIsInitialized);

  return (
    <App
      isInitialized={isInitialized}
      isAuthorized={isAuthorized}
      isExpired={isExpired}
    />
  );
};

export default AppContainer;
