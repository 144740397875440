import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { EMPTY_FUNCTION, EMPTY_VALUE } from 'constants/common';
import Swatch from 'components/colors/Swatch';

import { useStyles } from './styles';

/**
 * Checker element which display colors to be selected
 * @param {Array} colors
 * @param {Function} onClick
 * @param {Function} onSwatchHover
 * @param {Text|Number} width
 * @param {Text|Hex} select
 * @param {Number} circleSize
 * @param {Object} styles
 */
const Selector = ({ colors, onClick, onSwatchHover, circleSize, ...props }) => {
  const classes = useStyles(props);

  return (
    <Box className={classes.root}>
      {colors.map((value) => (
        <Swatch
          className={classes.swatch}
          key={value}
          color={value}
          onHover={onSwatchHover}
          onClick={onClick}
          {...props}
        />
      ))}
    </Box>
  );
};

Selector.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  onSwatchHover: PropTypes.func,
  circleSize: PropTypes.number,
  styles: PropTypes.object,
};

Selector.defaultProps = {
  onClick: EMPTY_FUNCTION,
  circleSize: 20,
  styles: EMPTY_VALUE,
};

export default React.memo(Selector);
