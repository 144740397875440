import { AUTHENTIFICATION_LABEL } from 'constants/tracker';

import { SEND_TRACKING_DATA } from '../types';

export const trackOpenSignUp = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: AUTHENTIFICATION_LABEL,
  gtmAction: 'Open Signup',
});

export const trackSignUp = (data) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: AUTHENTIFICATION_LABEL,
  gtmAction: 'Signup',
  data,
});

export const trackRegistrationActivation = (data) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: AUTHENTIFICATION_LABEL,
  gtmAction: 'Activate account',
  data,
});

export const trackLogin = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: AUTHENTIFICATION_LABEL,
  gtmAction: 'Login',
});

export const trackForgotPassword = (data) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: AUTHENTIFICATION_LABEL,
  gtmAction: 'Forgot Password',
  data,
});

export const trackLogout = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: AUTHENTIFICATION_LABEL,
  gtmAction: 'Logout',
  username: undefined,
});
