import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  popupWrapper: {
    minWidth: 550,
    maxWidth: 650,
  },
  helpText: {
    whiteSpace: 'pre-wrap',
    paddingBottom: 10,
  },
  closeButton: {
    marginTop: 10,
    backgroundColor: palette.danger.middle,
    '&:hover': {
      backgroundColor: palette.danger.main,
    },
    color: palette.white.main,
  },
}));
