import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Leaflet from 'leaflet-providers';
import 'leaflet-search';
import 'leaflet-search/dist/leaflet-search.min.css';
import './style.scss';
import { useDispatch } from 'react-redux';

import { useMapInstance } from 'hooks/leaflet/useMapInstance';
import {
  MAP_CONTROLS_POSITION,
  MAP_CONTROLS_JSON_PARAMETERS,
} from 'constants/map';
import { useExpensiveRef } from 'hooks/useExpensiveRef';
import { mapSearch } from 'ducks/trackers/actions/map';

const MapSearchControl = ({
  url,
  jsonpParam,
  propertyName,
  propertyLoc,
  marker,
  autoCollapse,
  autoType,
  minLength,
  zoom,
  position,
}) => {
  const mapInstance = useMapInstance();
  const dispatch = useDispatch();
  const options = useMemo(
    () => ({
      url,
      jsonpParam,
      propertyName,
      propertyLoc,
      marker,
      autoCollapse,
      autoType,
      minLength,
      zoom,
      position,
    }),
    [
      url,
      jsonpParam,
      propertyName,
      propertyLoc,
      marker,
      autoCollapse,
      autoType,
      minLength,
      zoom,
      position,
    ]
  );
  const search = useExpensiveRef(() => Leaflet.control.search(options));

  useEffect(() => {
    if (!mapInstance) {
      return;
    }
    const element = search.current;

    mapInstance.addControl(element);
    return () => mapInstance.removeControl(element);
  }, [search, mapInstance]);

  useEffect(() => {
    if (!search.current) {
      return;
    }
    Leaflet.setOptions(search.current, options);
  }, [search, options]);

  const handleSearchLocationFound = () => {
    dispatch(mapSearch('Search found'));
  };
  const handleSearchExpanded = () => {
    dispatch(mapSearch('Search expanded'));
  };
  const handleSearchCollapsed = () => {
    dispatch(mapSearch('Search collapsed'));
  };

  if (!search.current.hasEventListeners('search:locationfound')) {
    search.current.on('search:locationfound', handleSearchLocationFound);
  }

  if (!search.current.hasEventListeners('search:expanded')) {
    search.current.on('search:expanded', handleSearchExpanded);
  }

  if (!search.current.hasEventListeners('search:collapsed')) {
    search.current.on('search:collapsed', handleSearchCollapsed);
  }

  return null;
};

MapSearchControl.propTypes = {
  url: PropTypes.string.isRequired,
  propertyLoc: PropTypes.arrayOf(PropTypes.string).isRequired,
  propertyName: PropTypes.string,
  jsonpParam: PropTypes.string,
  marker: PropTypes.object,
  autoCollapse: PropTypes.bool,
  autoType: PropTypes.bool,
  minLength: PropTypes.number,
  zoom: PropTypes.number,
  position: PropTypes.string,
};

MapSearchControl.defaultProps = {
  jsonpParam: MAP_CONTROLS_JSON_PARAMETERS.jsonCallback,
  marker: null,
  autoCollapse: false,
  autoType: false,
  minLength: 2,
  zoom: 10,
  position: MAP_CONTROLS_POSITION.topLeft,
  propertyName: 'display_name',
};

export default MapSearchControl;
