import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  graphWrapper: {
    position: 'relative',
  },
  scatterPoint: {
    '&:hover': {
      r: 5,
    },
  },
  graphContainer: {
    '& svg': {
      overflow: 'visible',
    },
    '& .recharts-cartesian-axis-tick-line': {
      display: 'none',
    },
  },
});
