import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import ManageGeometriesButton from 'components/common/buttons/ManageGeometriesButton';
import { useModal } from 'hooks/useModal';
import { MANAGE_GEOMETRIES_MODAL } from 'constants/modals';

/**
 * Open modal with geometries list and upload button
 * @param projectId
 * @return {jsx}
 */
const ManageGeometriesButtonContainer = ({ projectId }) => {
  const { openModal } = useModal();

  const openManageGeometriesModal = useCallback(
    () => openModal(MANAGE_GEOMETRIES_MODAL, { projectId }),
    [openModal, projectId]
  );

  return <ManageGeometriesButton onClick={openManageGeometriesModal} />;
};

ManageGeometriesButtonContainer.propTypes = {
  projectId: PropTypes.number,
};

export default memo(ManageGeometriesButtonContainer);
