import {
  REDIRECT_AFTER_IMPERSONATION_RELEASE,
  REDIRECT_AFTER_IMPERSONATION,
} from 'constants/common';

/**
 * Save releaseTo parameter from hijack params to local storage.
 *
 * @param {{releaseTo: string}} params
 */
export const setHijackParams = ({ releaseTo, loginTo }) => {
  localStorage.setItem(REDIRECT_AFTER_IMPERSONATION_RELEASE, releaseTo);
  localStorage.setItem(REDIRECT_AFTER_IMPERSONATION, loginTo);
};

/**
 * Returns releaseTo hijack param if present on local storage.
 * (also show if this is a hijacked session)
 *
 * @return {string}
 */
export const getHijackParams = () => ({
  releaseTo: localStorage.getItem(REDIRECT_AFTER_IMPERSONATION_RELEASE),
  loginTo: localStorage.getItem(REDIRECT_AFTER_IMPERSONATION),
});

/**
 * Removes hijack param string from the local storage.
 */
export const removeHijackParams = () => {
  localStorage.removeItem(REDIRECT_AFTER_IMPERSONATION_RELEASE);
  localStorage.removeItem(REDIRECT_AFTER_IMPERSONATION);
};
