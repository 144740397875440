const prefix = '@@reports';

export const REQUEST_REPORTS = `${prefix}/REQUEST_REPORTS`;
export const REQUEST_REPORTS_SUCCESS = `${prefix}/REQUEST_REPORTS_SUCCESS`;
export const REQUEST_REPORTS_ERROR = `${prefix}/REQUEST_REPORTS_ERROR`;

export const POST_REPORT = `${prefix}/POST_REPORT`;
export const POST_REPORT_SUCCESS = `${prefix}/POST_REPORT_SUCCESS`;
export const POST_REPORT_ERROR = `${prefix}/POST_REPORT_ERROR`;

export const GENERATE_REPORT = `${prefix}/GENERATE_REPORT`;
export const GENERATE_REPORT_SUCCESS = `${prefix}/GENERATE_REPORT_SUCCESS`;
export const GENERATE_REPORT_ERROR = `${prefix}/GENERATE_REPORT_ERROR`;

export const CANCEL_REPORT = `${prefix}/CANCEL_REPORT`;
export const CANCEL_REPORT_SUCCESS = `${prefix}/CANCEL_REPORT_SUCCESS`;
export const CANCEL_REPORT_ERROR = `${prefix}/CANCEL_REPORT_ERROR`;

export const GET_REPORT_DATA = `${prefix}/GET_REPORT_DATA`;
export const GET_REPORT_DATA_SUCCESS = `${prefix}/GET_REPORT_DATA_SUCCESS`;
export const GET_REPORT_DATA_ERROR = `${prefix}/GET_REPORT_DATA_ERROR`;

export const START_UPDATE_REPORT = `${prefix}/START_UPDATE_REPORT`;
export const CANCEL_UPDATE_REPORT = `${prefix}/CANCEL_UPDATE_REPORT`;
export const FINISH_UPDATE_REPORT = `${prefix}/FINISH_UPDATE_REPORT`;

export const CLEAN_REPORT = `${prefix}/CLEAN_REPORT`;
export const CLEAN_REPORT_SUCCESS = `${prefix}/CLEAN_REPORT_SUCCESS`;
export const CLEAN_REPORT_ERROR = `${prefix}/CLEAN_REPORT_ERROR`;

export const DELETE_REPORT = `${prefix}/DELETE_REPORT`;
export const DELETE_REPORT_SUCCESS = `${prefix}/DELETE_REPORT_SUCCESS`;
export const DELETE_REPORT_ERROR = `${prefix}/DELETE_REPORT_ERROR`;

export const REMOVE_REPORT = `${prefix}/REMOVE_REPORT`;

export const CREATE_REPORT = `${prefix}/CREATE_REPORT`;

export const RESET_REPORTS = `${prefix}/RESET_REPORTS`;

export const DOWNLOAD_REPORT = `${prefix}/DOWNLOAD_REPORT`;
export const DOWNLOAD_REPORT_SUCCESS = `${prefix}/DOWNLOAD_REPORT_SUCCESS`;
export const DOWNLOAD_REPORT_ERROR = `${prefix}/DOWNLOAD_REPORT_ERROR`;
