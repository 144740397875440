import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Binoculars, Database } from 'phosphor-react';

import BaseSideBar from 'components/common/BaseSideBar';
import DataValidationOverview from 'components/projects/DataValidation/DataValidationOverview';
import DataValidationContent from 'components/projects/DataValidation/DataValidationContent';
import PropertiesListItem from 'components/points/PropertiesListItem';
import {
  getGridsData,
  getBuoysByZoneRuns,
  getZoneDataSet,
} from 'helpers/zones';
import { useUrlQuery } from 'hooks/useUrlQuery';
import { trackNumericalSimulations } from 'ducks/trackers/actions/datavalidation';

import { useStyles } from './styles';
import tutorialContent from './tutorialContent.json';

const TutorialCard = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [tutorialStepIndex, setTutorialStepIndex] = useState(0);
  const [tutorialStepContent, setTutorialStepContent] = useState(null);

  /* Handle tutorial step content */
  useEffect(() => {
    setTutorialStepContent(tutorialContent[tutorialStepIndex]);
  }, [tutorialStepIndex]);

  /* Change tutorial step */
  const handleNextTutorialStep = useCallback(() => {
    setTutorialStepIndex(tutorialStepIndex + 1);
  }, [tutorialStepIndex]);
  const handlePreviousTutorialStep = useCallback(() => {
    setTutorialStepIndex(tutorialStepIndex - 1);
  }, [tutorialStepIndex]);

  return (
    <div id="dataValidationTutorial" className={classes.tutorialCard}>
      <div className={classes.tutorialTitleContainer}>
        <Database size={20} />
        {t('dataValidation.tutorial.title')}
      </div>
      <div
        className={classes.tutorialText}
        dangerouslySetInnerHTML={{ __html: tutorialStepContent }}
      ></div>
      <div className={classes.tutorialStepper}>
        {tutorialStepIndex + 1} of {tutorialContent.length}
      </div>
      <div className={classes.tutorialButtonsContainer}>
        {tutorialStepIndex + 1 < tutorialContent.length && (
          <Button
            onClick={handleNextTutorialStep}
            className={classes.nextButton}
          >
            {t('dataValidation.tutorial.next')}
          </Button>
        )}
        {tutorialStepIndex > 0 && (
          <Button
            onClick={handlePreviousTutorialStep}
            className={classes.previousButton}
          >
            {t('dataValidation.tutorial.previous')}
          </Button>
        )}
      </div>
    </div>
  );
};

/**
 * DataValidationDashboard - component for render side bar with info and Map with descriptions
 * @param {Object} zone
 * @param {Object} project
 * @param {Array} buoys
 * @param {Function} onPointClick
 * @param {Function} onBuoyClick

 * @returns {jsx}
 */
const DataValidationDashboard = ({
  project,
  zone,
  buoys,
  onPointClick,
  onBuoyClick,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    urlQuery: { ns: numericalSimulation },
    setUrlQuery,
  } = useUrlQuery();

  const numericalSimulationsItems = useMemo(() => getGridsData(zone), [zone]);
  const [view, setView] = useState('overview');

  /* Get zoneDataset info */
  const zoneDatasetList = getZoneDataSet(zone);

  /* Get validations statistics for buoys (all sensors) */
  const statisticsBuoys = useMemo(() => {
    /* List runs for all zone dataset */
    const zoneRunsList = [];
    zoneDatasetList.forEach((zoneDataset) => {
      zoneRunsList.push(...zoneDataset.runs);
    });
    /* List zone runs ids */
    const { zoneRunNames, zoneRunIds } = zoneRunsList.reduce(
      (acc, { name, id }) => {
        if (!acc.zoneRunIds.includes(id)) {
          acc.zoneRunIds.push(id);
          acc.zoneRunNames.push(name);
        }
        return acc;
      },
      {
        zoneRunIds: [],
        zoneRunNames: [],
      }
    );

    if (zoneRunIds.length === 0) {
      return buoys;
    }
    return getBuoysByZoneRuns({
      buoys,
      zoneRunNames,
      zoneRunIds,
    });
  }, [buoys, zoneDatasetList]);

  /* Init overview with first dataset item selected */
  useEffect(() => {
    if (numericalSimulationsItems && numericalSimulationsItems[0]) {
      onSelectDataset(numericalSimulationsItems[0]);
    }
  }, [numericalSimulationsItems]);

  /* Change viewed page in data validation dashboard */
  const onSelectValidationView = useCallback(() => {
    setView('validation');
    setUrlQuery(null);
  }, []);
  const onSelectOverviewView = useCallback(() => {
    setView('overview');
    onSelectDataset(numericalSimulationsItems[0]);
  }, []);

  /* Change dataset selected in overview */
  const onSelectDataset = useCallback(
    ({ id }) => {
      setUrlQuery({ ns: id });
      dispatch(trackNumericalSimulations(id));
    },
    [setUrlQuery]
  );

  return (
    <Grid container className={classes.dataValidationContainer}>
      {/* sidebar */}
      <Grid item className={classes.sideBarContainer}>
        <BaseSideBar
          sideBarClass={classes.dataValidationSideBar}
          id="dataValidationSidebar"
        >
          {/* overview menu item */}
          <div className={classes.sideBarMenuItemContainer}>
            <div
              onClick={onSelectOverviewView}
              className={classNames(classes.sideBarMenuItem, {
                [classes.sideBarMenuItemSelected]: view === 'overview',
              })}
            >
              <Binoculars size={20} color="#4451C7" />
              {t('dataValidation.titles.numericalSimulations')}
            </div>
            {view === 'overview' &&
              numericalSimulationsItems.map((item) => (
                <PropertiesListItem
                  item={item}
                  selected={[item.id, item.name].includes(numericalSimulation)}
                  onItemClick={onSelectDataset}
                  key={item.id}
                />
              ))}
          </div>
          {/* data validation menu item */}
          <div className={classes.sideBarMenuItemContainer}>
            <div
              onClick={onSelectValidationView}
              className={classNames(classes.sideBarMenuItem, {
                [classes.sideBarMenuItemSelected]: view === 'validation',
              })}
            >
              <Database size={20} color="#4451C7" />
              {t('dataValidation.titles.dataValidation')}
            </div>
          </div>
          {view === 'validation' && <TutorialCard />}
        </BaseSideBar>
      </Grid>
      {/* content */}
      <Grid item className={classes.contentContainer}>
        {/* validation view */}
        {view === 'validation' && (
          <DataValidationContent
            project={project}
            buoys={statisticsBuoys}
            onPointClick={onPointClick}
            onBuoyClick={onBuoyClick}
          />
        )}
        {/* overview view */}
        {view === 'overview' && numericalSimulation && (
          <DataValidationOverview
            numericalSimulationsItems={numericalSimulationsItems}
            zone={zone}
            zoneDatasetList={zoneDatasetList}
            numericalSimulation={numericalSimulation}
          ></DataValidationOverview>
        )}
      </Grid>
    </Grid>
  );
};

DataValidationDashboard.propTypes = {
  project: PropTypes.shape({
    zone: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
  zone: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  buoys: PropTypes.array.isRequired,
  onPointClick: PropTypes.func.isRequired,
  onBuoyClick: PropTypes.func.isRequired,
};

export default React.memo(DataValidationDashboard);
