import React, { memo } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import BaseModal from 'components/common/BaseModal';

import { useStyles } from './styles';

/**
 * DeleteReportModal component
 * Modal for approve project deletion
 * @param {function} closeModal
 * @param {function} deleteReport
 * @param {String} projectName
 */
const DeleteReportModal = ({ closeModal, deleteReport, reportName }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const controls = (
    <div className={classes.buttonsContainer}>
      <Button variant="contained" onClick={closeModal}>
        {t('buttons.cancel')}
      </Button>
      <Button variant="contained" onClick={deleteReport}>
        {t('buttons.confirm')}
      </Button>
    </div>
  );

  return (
    <BaseModal
      title={t('report.delete.modalTitle')}
      onClose={closeModal}
      controls={controls}
      modalClass={classes.container}
    >
      <div className={classes.body}>
        <Typography variant="body1" align="center">
          {t('report.delete.question')}
          <strong>&nbsp;{reportName}</strong>
          &nbsp;?&nbsp;
          {t('report.delete.warning')}
        </Typography>
      </div>
    </BaseModal>
  );
};

DeleteReportModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  deleteReport: PropTypes.func.isRequired,
  reportName: PropTypes.string,
};

DeleteReportModal.defaultProps = {
  reportName: '',
};

export default memo(DeleteReportModal);
