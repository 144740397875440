import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    width: ({ width }) => width,
    padding: 5,
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.4)',
    borderRadius: 5,
  },
  title: {
    textAlign: 'center',
    fontSize: ({ colorBoxSize }) => colorBoxSize,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  colorBox: {
    display: 'inline-block',
    width: ({ colorBoxSize }) => colorBoxSize,
    height: ({ colorBoxSize }) => colorBoxSize,
    boxShadow: '0 0 3px',
    borderRadius: 2,
  },
  legend: {
    fontSize: ({ colorBoxSize }) => colorBoxSize,
    marginLeft: 10,
    verticalAlign: 'middle',
  },
});
