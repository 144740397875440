import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { requestProjectStats } from 'ducks/projects/actions';
import ProjectReportComponent from 'components/projects/ProjectReport';
import {
  PROJECT_REPORTS_ALL_VALUE,
  PROJECT_REPORTS_CATEGORIES,
  PROJECT_REPORTS_PARTS,
} from 'constants/reports';
import { EMPTY_ARRAY } from 'constants/common';
import { reportsActions, reportsSelectors } from 'ducks/reports';
import { scrollToElement } from 'helpers/common';
import { requestComments } from 'ducks/comments/actions';

const pointsIdName = 'points';
const partsIdName = 'parts';
const reportButtonIdName = 'reportButton';

/**
 * Project analyze page container
 * displays project statistics
 * @param points - collection of points for current project
 * @param projectId - id of current project
 * @param projectName - name of current project
 * @param onSelectMarker - handler for point select
 * @param selectedMarkers - array of selected points ids
 * @param setSelectedMarkers - puts point ids in selectedMarkers array
 * @param allowGenerateReport - if true, user can generate report
 */
const ProjectReport = ({
  points,
  projectId,
  projectName,
  onSelectMarker,
  selectedMarkers,
  setSelectedMarkers,
  allowGenerateReport,
}) => {
  const dispatch = useDispatch();
  const isReportRunning = useSelector(
    reportsSelectors.selectIsCurrentReportRunning
  );

  useEffect(() => {
    dispatch(reportsActions.requestReports({ project: projectId }));
    dispatch(requestProjectStats(projectId));
    dispatch(requestComments());
    setSelectedMarkers(EMPTY_ARRAY);
  }, [dispatch, projectId, setSelectedMarkers]);

  useEffect(
    () => () => {
      dispatch(reportsActions.resetReports());
      dispatch(reportsActions.cancelUpdateReport());
    },
    [dispatch]
  );

  const changePoint = useCallback(
    (point) => {
      scrollToElement(partsIdName);
      return point.id === PROJECT_REPORTS_ALL_VALUE
        ? setSelectedMarkers(EMPTY_ARRAY)
        : onSelectMarker(point);
    },
    [onSelectMarker, setSelectedMarkers]
  );

  const generateReport = useCallback(
    (data) =>
      dispatch(
        reportsActions.createReport({
          ...data,
          points: selectedMarkers.length
            ? selectedMarkers
            : points.map(({ id }) => id),
          projectId,
        })
      ),
    [dispatch, projectId, selectedMarkers, points]
  );

  const cancelReport = useCallback(
    () => dispatch(reportsActions.cancelReport()),
    [dispatch]
  );

  return (
    <ProjectReportComponent
      points={points}
      categories={PROJECT_REPORTS_CATEGORIES}
      parts={PROJECT_REPORTS_PARTS}
      selectedMarkers={selectedMarkers}
      changePoint={changePoint}
      generateReport={generateReport}
      cancelReport={cancelReport}
      isGeneratingReport={isReportRunning}
      projectName={projectName}
      pointsIdName={pointsIdName}
      partsIdName={partsIdName}
      reportButtonIdName={reportButtonIdName}
      allowGenerateReport={allowGenerateReport}
    />
  );
};

ProjectReport.propTypes = {
  points: PropTypes.arrayOf(PropTypes.object).isRequired,
  projectId: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
  onSelectMarker: PropTypes.func.isRequired,
  selectedMarkers: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedMarkers: PropTypes.func.isRequired,
  allowGenerateReport: PropTypes.bool.isRequired,
};

export default memo(ProjectReport);
