import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modalContent: {
    width: 400,
    minHeight: 150,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    '&:focus': {
      outline: 'none',
    },
    padding: '40px 30px',
    margin: '5% auto',
    position: 'relative',
    maxHeight: '90vh',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      padding: 0,
    },
    padding: 15,
    background: theme.palette.sinay.primary['90'],
    color: theme.palette.sinay.primary['40'],
    border: `1px solid #C7C5D0`,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    alignItems: 'center',
    '&.warning': {
      background: theme.palette.sinay.tertiary['90'],
      color: theme.palette.sinay.tertiary['40'],
    },
  },
  noHeaderCloseIcon: {
    position: 'absolute',
    top: 2,
    right: 2,
    zIndex: 1,
  },
  modalBody: {
    border: `1px solid #C7C5D0`,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    padding: 15,
    overflowY: 'auto',
  },
  controls: {
    padding: 15,
    marginTop: 20,
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 2,
    fontWeight: 600,
    fontSize: 22,
    textAlign: 'center',
    overflowWrap: 'anywhere',
  },
  modalStyle: {
    overflowY: 'hidden',
  },
}));
