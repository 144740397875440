import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginRight: 15,
    },
    marginTop: 15,
  },
});
