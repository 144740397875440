import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  descriptionRow: {
    padding: '5px 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    '& > p': {
      fontSize: 14,
    },
  },
});
