import { PLANNING_PERCENTILES } from 'constants/projects';

import { jsonToFormData } from './data';

/**
 * Prepares planning template data from server for planning settings form
 * @param planningTemplateData - planning template data from server
 * @param projectId - current project id
 * @param file - uploaded planning file
 * @returns {{fileName: string, percentiles: string, file: object, formats: objects, locationAliases: object, projectId: string}}
 */
export const preparePlanningTemplateForSettingsForm = ({
  planningTemplateData,
  projectId,
  file,
}) => {
  const {
    locationAliases,
    downtimeFormats,
    ...planningData
  } = planningTemplateData;
  return {
    projectId,
    fileName: file.name,
    percentiles: PLANNING_PERCENTILES,
    formats: downtimeFormats,
    file,
    locationAliases,
    ...planningData,
  };
};

/**
 * Prepares planning data for create request
 * @param file - planning file
 * @param planningData - planning data
 * @returns {FormData}
 */
export const preparePlanningDataForRequest = ({
  projectId,
  file,
  planningData,
}) => {
  const { locationAliases, formats, ...planingDataFromForm } = planningData;
  const preparedAliases = locationAliases.reduce((acc, location, index) => {
    acc[`alias_${index}`] = location.alias;
    acc[`point_${index}`] = location.point;
    return acc;
  }, {});
  // get selected Downtime format, return its "value"
  const downtimeFormat = formats.find((f) => f.selected).value;

  return jsonToFormData({
    projectId,
    downtimeFormat,
    planningFile: file,
    aliasNb: Object.keys(locationAliases).length,
    ...preparedAliases,
    ...planingDataFromForm,
  });
};
