import React, { useCallback, useEffect, useState } from 'react';
import PropsTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useModal } from 'hooks/useModal';
import PercentileModalComponent from 'components/modals/MarineContractorsModal/Percentile/index';
import { ENTER_KEY_REGEX, PERCENTILE_TYPE } from 'constants/marineContractors';
import {
  selectMarineContractorsCustomer,
  selectMarineContractorsObjectError,
} from 'ducks/marineContractors/selectors';
import {
  cleanPercentilesError,
  updateMarineContractorsCustomerPercentiles,
} from 'ducks/marineContractors/actions';

/**
 * Percentile modal container
 * @param { string } id - modalId
 * @returns { JSX }
 */
const PercentileModalContainer = ({ id }) => {
  const { onClose } = useModal(id);
  const dispatch = useDispatch();
  const [percentile, setPercentile] = useState();
  const [keyPressed, setKeyPressed] = useState(false);

  const customerSettings = useSelector(selectMarineContractorsCustomer);
  const error = useSelector((state) =>
    selectMarineContractorsObjectError(PERCENTILE_TYPE)(state)
  );

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress);
    return () => {
      window.removeEventListener('keypress', handleKeyPress);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (keyPressed) {
      handleCreate();
      setKeyPressed(false);
    }
    // eslint-disable-next-line
  }, [keyPressed])

  const handleOnChange = (e) => {
    const value = e.target.value;
    setPercentile(+value);
  };

  const handleOnClose = useCallback(() => {
    dispatch(cleanPercentilesError());
    onClose();
    // eslint-disable-next-line
  }, [onClose]);

  const handleCreate = useCallback(() => {
    const preparedCustomerSettings = {
      customer: customerSettings.customer,
      percentiles: [...customerSettings.percentiles, percentile],
    };
    dispatch(
      updateMarineContractorsCustomerPercentiles(preparedCustomerSettings)
    );
    // eslint-disable-next-line
  }, [customerSettings, percentile]);

  const handleKeyPress = (e) => {
    if (e.key && e.key.match(ENTER_KEY_REGEX)) {
      setKeyPressed(true);
    }
  };

  return (
    <PercentileModalComponent
      onClose={handleOnClose}
      error={error}
      handleCreate={handleCreate}
      handleOnChange={handleOnChange}
    />
  );
};

PercentileModalContainer.propTypes = {
  id: PropsTypes.string.isRequired,
};

export default React.memo(PercentileModalContainer);
