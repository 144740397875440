import React, { memo } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { formatDate } from 'helpers/date';
import { DATE_FORMATS } from 'constants/common';
import ConfirmationModal from 'components/common/ConfirmationModal';

import { useStyles } from './styles';

/**
 * DeletePlanningModal component
 * displays modal for delete planning
 * @param { function} onClose - handler for close modal
 * @param { function} deletePlanning - handler for confirm button
 * @param { Object } planning - deleted planning object
 */
const DeletePlanningModal = ({ onClose, deletePlanning, planning }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ConfirmationModal
      title={t('plannings.deleteTitle')}
      onClose={onClose}
      onConfirm={deletePlanning}
      onCancel={onClose}
      modalClass={classes.container}
    >
      <div className={classes.body}>
        <Typography align="center">
          {t('plannings.deleteMessagePartOne')}
          <strong className={classes.planningName}>{planning.name}</strong>
          {t('plannings.computedOn', {
            computedAt: formatDate(
              planning.computedAt,
              DATE_FORMATS.longFormat
            ),
          })}
        </Typography>
        &nbsp;
        <Typography align="center">
          {t('plannings.deleteMessagePartTwo')}
        </Typography>
      </div>
    </ConfirmationModal>
  );
};

DeletePlanningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  deletePlanning: PropTypes.func.isRequired,
  planning: PropTypes.shape({
    id: PropTypes.number,
    user: PropTypes.number,
    project: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.string,
    taskId: PropTypes.string,
    document: PropTypes.string,
    computedAt: PropTypes.string,
    startedAt: PropTypes.string,
    points: PropTypes.array,
  }),
};

export default memo(DeletePlanningModal);
