import Typography from '@material-ui/core/Typography';
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import SimpleSelect from 'components/common/SimpleSelect';

import { useStyles } from '../styles';

/**
 * PlanningFormSelect component
 * displays select with label in planning form
 * @param { string } label - select label
 * @param { string || number } value - select value
 * @param { string } name - select name
 * @param { function } handleChange - change handler
 * @param { array } optionList - array of select options
 * @param {Object} props
 */
const PlanningFormSelect = ({
  label,
  value,
  name,
  handleChange,
  optionList,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={classes.formRow}>
      <Typography variant="body1" className={classes.fieldName}>
        {label}
      </Typography>
      <SimpleSelect
        {...props}
        name={name}
        value={value}
        className={classes.modalSelect}
        variant="outlined"
        optionList={optionList}
        onChange={handleChange}
      />
    </div>
  );
};

PlanningFormSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  optionList: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default memo(PlanningFormSelect);
