import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, buttons }) => ({
  children: {
    width: '100%',
  },
  mcContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
  },
  addBoatButton: {
    ...buttons.outlined.secondary,
    marginBottom: 20,
  },
  mcActionButton: {
    ...buttons.elevated.secondary,
    fontSize: 14,
    marginLeft: 10,
  },
  validStep: {
    marginTop: '5px',
  },
  gridCenter: {
    textAlign: 'center',
  },
  mainContainer: {
    padding: '10px 20px',
  },
  titleBox: {
    backgroundColor: palette.sinay.primary['40'],
    color: palette.white.main,
    textAlign: 'center',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    padding: '10px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& h2': {
      fontSize: 20,
      fontFamily: 'IBM Plex Sans',
      margin: 0,
    },
  },
  contentBox: {
    backgroundColor: palette.white.main,
    textAlign: 'center',
    border: '2px solid black',
    height: '50vh',
    overflowY: 'auto',
  },
  gridItem: {
    '&:hover': {
      backgroundColor: palette.lightGrey.light,
    },
    cursor: 'pointer',
  },
  gridItemSelected: {
    backgroundColor: palette.lightGrey.light,
    // trick to avoid little sparkling on operation selected when selecting a new boat
    transition: 'background-color 0.5s',
  },
  noContent: {
    fontStyle: 'italic',
    overflowWrap: 'anywhere',
  },
  iconImage: {
    width: 18,
    paddingLeft: 5,
  },
  boatIcon: {
    width: 24,
    marginLeft: 5,
    marginRight: 5,
  },
  addIcon: {
    width: 20,
    marginLeft: 5,
  },
  backButton: {
    ...buttons.elevated.neutral,
  },
  nextButton: {
    ...buttons.fill.primary,
  },
  boatsContainer: {
    height: '35vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    '@media(min-width: 1600px)': {
      height: '45vh',
    },
  },
}));
