// import Typography from '@material-ui/core/Typography';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';

import { selectProjectsCollection } from 'ducks/projects/selectors';
import { trackProjectRenameConfirm } from 'ducks/trackers/actions/projectManagment';
import { useForm } from 'hooks/useForm';
import ConfirmationModal from 'components/common/ConfirmationModal';

import { useStyles } from './styles';
import { validationRules } from './validationRules';

/**
 * RenameProjectModal component
 * Modal for approve project rename
 * @param {function} closeModal
 * @param {function} deleteProject
 * @param {String} projectName
 */
const RenameProjectModal = ({ closeModal, renameProject, projectName }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const MAX_CHARACTERS = 50;
  const dispatch = useDispatch();

  const initialValues = useMemo(
    () => ({
      projectName,
    }),
    [projectName]
  );

  const projects = useSelector(selectProjectsCollection);

  const projectNamesList = useMemo(
    () => projects.map((project) => project.name),
    [projects]
  );

  const { handleBlur, values, errors } = useForm({
    initialValues,
    validationRules: validationRules(projectNamesList),
  });

  const nameError = get(errors, 'projectName', false);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= MAX_CHARACTERS) {
      handleBlur(event);
    }
  };

  const submitForm = useCallback(() => {
    if (
      values.projectName &&
      values.projectName.length > 2 &&
      /^[-\w]+$/.test(values.projectName) &&
      !projectNamesList.includes(values.projectName)
    ) {
      renameProject(values.projectName);
      dispatch(trackProjectRenameConfirm());
    }
  });

  return (
    <ConfirmationModal
      title={t('projects.rename.modalTitle')}
      onClose={closeModal}
      onConfirm={submitForm}
      onCancel={closeModal}
      modalClass={classes.container}
    >
      <div className={classes.fullWidth}>
        <TextField
          fullWidth
          required
          name="projectName"
          size="medium"
          defaultValue={values.projectName}
          onBlur={handleBlur}
          onChange={handleInputChange}
          inputProps={{ maxLength: MAX_CHARACTERS }}
          variant="outlined"
        />
        <div className={classes.textContainer}>
          <Typography variant="caption" color="textSecondary">
            {`${values.projectName.length}/${MAX_CHARACTERS} ${t(
              'projects.rename.characterCount'
            )}`}
          </Typography>
          <FormHelperText className={classes.helperText} error>
            {nameError}
          </FormHelperText>
        </div>
      </div>
    </ConfirmationModal>
  );
};

RenameProjectModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  renameProject: PropTypes.func.isRequired,
  projectName: PropTypes.string,
};

RenameProjectModal.defaultProps = {
  projectName: '',
};

export default React.memo(RenameProjectModal);
