import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Route } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import ProjectMap from 'components/maps/ProjectMap';
import ProjectSideBar from 'components/projects/ProjectSidebar';
import NewPointFeatureGroup from 'components/maps/NewPointFeatureGroup';
import SelectablePointFeatureGroup from 'components/maps/SelectablePointFeatureGroup';
import SelectableOverviewPointFeatureGroup from 'components/maps/SelectableOverviewPointFeatureGroup';
import EditPointFeatureGroup from 'components/maps/EditPointFeatureGroup';
import ProjectAnalyze from 'containers/projects/ProjectAnalyze';
import ProjectReport from 'containers/projects/ProjectReport';
import PointLocations from 'containers/points/PointLocations';
import GetMapsContainer from 'containers/buttons/GetMapsContainer';
import ProjectPolygon from 'components/maps/ProjectPolygon';
import ProjectReportList from 'containers/projects/ProjectReportList';

import { useStyles } from './styles';

/**
 * ProjectDashboard - component for render side bar with info and Map with interactive
 * @param {Object} project
 * @param {Array} geometries
 * @param {Array} reports
 * @param {Array} zoneTileGeometries
 * @param {Boolean} isOverviewShown
 * @param {Boolean} isRunningProject
 * @param {Boolean} isLoadingMapsData
 * @param {Boolean} allowTileGeometries
 * @param {Boolean} isManager
 * @param {Boolean} isOfferExpired
 * @param {Boolean} mapsInOffer - if has maps in offer
 * @returns {jsx}
 */
const ProjectDashboard = ({
  project,
  isOverviewShown,
  isRunningProject,
  allowTileGeometries,
  mapsInOffer,
  isManager,
  isLoadingMapsData,
  geometries,
  reports,
  zoneTileGeometries,
  isOfferExpired,
}) => {
  const classes = useStyles();
  const [selectedMarkers, setSelectedMarkers] = useState([]);

  const selectMarkerHandler = useCallback(
    ({ id }) => {
      setSelectedMarkers((prevSelectedMarkers) =>
        prevSelectedMarkers.includes(+id)
          ? prevSelectedMarkers.filter((selectedId) => selectedId !== +id)
          : [...prevSelectedMarkers, +id]
      );
    },
    [setSelectedMarkers]
  );
  const selectSingleMarkerHandler = useCallback(
    ({ id }) => {
      setSelectedMarkers(([prevId]) => (prevId === +id ? [] : [+id]));
    },
    [setSelectedMarkers]
  );
  const selectSingleMarkerOnMap = useCallback(
    ({ sourceTarget }) => {
      selectSingleMarkerHandler(sourceTarget.properties);
    },
    [selectSingleMarkerHandler]
  );
  const selectMarkerOnMap = useCallback(
    ({ sourceTarget }) => selectMarkerHandler(sourceTarget.properties),
    [selectMarkerHandler]
  );
  const selectSingleMarkerOnMapWithoutDeselect = useCallback(
    ({ sourceTarget }) => setSelectedMarkers([+sourceTarget.properties.id]),
    [setSelectedMarkers]
  );

  const handleMarkerMenuSelection = useCallback((marker) => {
    setSelectedMarkers(([prevId]) =>
      prevId === +marker.id ? [] : [+marker.id]
    );
  });

  // If we have deleted points in selected array, we clearing array.
  useEffect(() => {
    // Remove deleted point from selected markers
    const markersWithoutDeleted = selectedMarkers.filter((markerId) =>
      project.points.some(({ id }) => id === markerId)
    );

    // Check diff between arrays
    if (markersWithoutDeleted.length !== selectedMarkers.length) {
      setSelectedMarkers(markersWithoutDeleted);
    }
    // eslint-disable-next-line
  }, [project.points, setSelectedMarkers]);

  return (
    <Grid container className={classes.projectContainer}>
      <Grid item className={classes.sideBarContainer}>
        <ProjectSideBar project={project}>
          <Route exact path={ROUTES.project}>
            <PointLocations
              points={project.points}
              projectId={project.id}
              onSelectMarker={selectSingleMarkerHandler}
              selectedMarkers={selectedMarkers}
              allowEditAndCompute={isManager}
              isOfferExpired={isOfferExpired}
            />
            {!mapsInOffer && (
              <GetMapsContainer
                zone={project.zone}
                projectId={project.id}
                allowBuyMap={!allowTileGeometries}
              />
            )}
          </Route>
          <Route exact path={ROUTES.projectAnalyze}>
            <ProjectAnalyze
              points={project.points}
              projectId={project.id}
              onSelectMarker={selectSingleMarkerHandler}
              selectedMarkerId={selectedMarkers[0]}
              selectMarkerOnMenu={handleMarkerMenuSelection}
            />
          </Route>
          <Route exact path={ROUTES.projectReport}>
            <ProjectReport
              points={project.points}
              projectId={project.id}
              projectName={project.name}
              onSelectMarker={selectMarkerHandler}
              selectedMarkers={selectedMarkers}
              setSelectedMarkers={setSelectedMarkers}
              allowGenerateReport={!isOfferExpired}
            />
          </Route>
        </ProjectSideBar>
      </Grid>
      <Route exact path={ROUTES.projectReport}>
        {!!reports.length && (
          <Grid item className={classes.sideSideBarContainer}>
            <ProjectReportList
              reports={reports}
              allowDeleteReport={isManager && !isOfferExpired}
              allowAddComment={!isOfferExpired}
            />
          </Grid>
        )}
      </Route>
      <Grid item className={classes.contentContainer}>
        <ProjectMap
          wrapperClass={classes.mapContainer}
          project={project}
          geometries={geometries}
          zoneTileGeometries={zoneTileGeometries}
          allowTileGeometries={!isLoadingMapsData && allowTileGeometries}
        >
          <Route exact path={ROUTES.project}>
            {isRunningProject ? (
              <ProjectPolygon project={project} />
            ) : (
              <NewPointFeatureGroup
                project={project}
                canCreatePoint={isManager && !isOfferExpired}
              />
            )}
            <EditPointFeatureGroup
              project={project}
              selectedMarkers={selectedMarkers}
              onSelectMarker={selectSingleMarkerOnMapWithoutDeselect}
            />
          </Route>
          <Route exact path={ROUTES.projectAnalyze}>
            <ProjectPolygon project={project} />
            <SelectableOverviewPointFeatureGroup
              points={project.points}
              selectedMarkers={selectedMarkers}
              selectMarker={selectSingleMarkerOnMap}
              isOverviewShown={isOverviewShown}
            />
          </Route>
          <Route exact path={ROUTES.projectReport}>
            <ProjectPolygon project={project} />
            <SelectablePointFeatureGroup
              points={project.points}
              selectedMarkers={selectedMarkers}
              selectMarker={selectMarkerOnMap}
              onlyComputed
            />
          </Route>
        </ProjectMap>
      </Grid>
    </Grid>
  );
};

ProjectDashboard.propTypes = {
  geometries: PropTypes.array.isRequired,
  reports: PropTypes.array.isRequired,
  zoneTileGeometries: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired,
  isRunningProject: PropTypes.bool,
  isLoadingMapsData: PropTypes.bool.isRequired,
  allowTileGeometries: PropTypes.bool,
  mapsInOffer: PropTypes.bool,
  isManager: PropTypes.bool.isRequired,
  isOfferExpired: PropTypes.bool,
};

ProjectDashboard.defaultProps = {
  isRunningProject: false,
  isOfferExpired: false,
};

export default React.memo(ProjectDashboard);
