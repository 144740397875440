import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import EditOtherProfileForm from 'components/forms/EditOtherProfileForm';
import BaseModal from 'components/common/BaseModal';

/**
 * Displays modal for editing other profile
 * @param { function } onClose - handler for close button modal
 * @param { Object } user - user information
 * @param { boolean } isCurrentUser - if true, modal open with current user data
 * @param { string } modalId - current modal id
 */
const EditOtherProfileModal = ({ onClose, user, isCurrentUser, modalId }) => {
  const { t } = useTranslation();
  const { username } = user;
  return (
    <BaseModal
      title={
        isCurrentUser
          ? t('collaborators.editYourProfile')
          : t('collaborators.editProfile', { username })
      }
      onClose={onClose}
    >
      <EditOtherProfileForm
        user={user}
        isCurrentUser={isCurrentUser}
        modalId={modalId}
      />
    </BaseModal>
  );
};

EditOtherProfileModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
  modalId: PropTypes.string.isRequired,
};

export default memo(EditOtherProfileModal);
