import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  formRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& input': {
      padding: 10,
      width: 400,
    },
  },
  helperText: {
    height: 20,
    wordWrap: 'break-word',
  },
  fieldName: {
    marginRight: 30,
    fontWeight: 700,
    width: 120,
    textAlign: 'right',
  },
  inputFieldName: {
    marginBottom: 20,
  },
}));
