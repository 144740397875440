import { generatePath } from 'react-router';

import request from 'helpers/request';
import {
  POINTS_URL,
  POINT_TASKS_URL,
  POINT_TASKS_COMPUTE,
  POINT_TASKS_CANCEL,
  POINT_TASKS_DOWNLOAD,
} from 'constants/api';
import { makeXhrRequest } from 'helpers/requestXhr';
import { camelizeKeys } from 'helpers/camelizer';
import { store } from 'store/index';
import {
  trackPointSuppressionConfirmation,
  trackAllPointsSuppressionConfirmation,
} from 'ducks/trackers/actions/workzone';

export const getPoints = (params) =>
  request.get(POINTS_URL, { params: { ordering: 'id', ...params } });

export const getPoint = (id, params) =>
  request.get(`${POINTS_URL}${id}/`, { params });

export const createPoints = (data, options) =>
  request.post(POINTS_URL, data, options);

export const createPointTask = (pointId) =>
  request.post(POINT_TASKS_URL, { point: pointId });

export const runPointTask = ({ taskId, processType }) =>
  request.put(generatePath(POINT_TASKS_COMPUTE, { id: taskId, processType }));

export const cancelPointTask = (taskId) =>
  request.put(generatePath(POINT_TASKS_CANCEL, { id: taskId }));

export const uploadPoints = (data) =>
  makeXhrRequest({
    data,
    method: 'POST',
    url: POINTS_URL,
    contentType: false,
  }).then(camelizeKeys(JSON.parse));

export const updatePoint = (id, data) =>
  request.put(`${POINTS_URL}${id}/`, data);

export const deletePoint = (id) => {
  store.dispatch(trackPointSuppressionConfirmation());
  request.delete(`${POINTS_URL}${id}/`);
};

export const deletePoints = (options) => {
  store.dispatch(trackAllPointsSuppressionConfirmation());
  request.delete(POINTS_URL, options);
};

export const downloadTask = (taskId) =>
  makeXhrRequest({
    method: 'GET',
    url: generatePath(POINT_TASKS_DOWNLOAD, { id: taskId }),
    responseType: 'arraybuffer',
  }).then((result) => result);
