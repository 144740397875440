import { makeStyles } from '@material-ui/core/styles';

import { theme } from 'common/theme';

export const useStyles = makeStyles(() => ({
  overviewContentContainer: {
    padding: 10,
  },
  environmentalConditionsButton: {
    display: 'flex',
    flexDirection: 'row',
    ...theme.buttons.outlined.neutral,
    /* override outlined button properties */
    borderRadius: 5,
  },
  descriptionContainer: {
    position: 'relative',
  },
  envDescriptionContainer: {
    marginTop: 20,
    padding: 10,
  },
  closeButton: {
    pointer: 'cursor',
    marginBottom: 15,
  },
}));
