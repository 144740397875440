import request from 'helpers/request';
import { STAT2D_URL, STAT2D_URL_PROPERTIES_POSTFIX } from 'constants/api';

export const getMaps = (params) => request.get(STAT2D_URL, { params });

export const buyMaps = (data) => request.post(STAT2D_URL, data);

export const getTileProperties = (url, options) =>
  request
    .get(`${url}${STAT2D_URL_PROPERTIES_POSTFIX}`, {
      ...options,
      withoutAuthorization: true,
      withCredentials: false,
      headers: {
        accept: '*/*',
      },
    })
    .catch(() => undefined);
