import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Question } from 'phosphor-react';
import { generatePath } from 'react-router';

import { requestProjectStats } from 'ducks/projects/actions';
import ProjectAnalyzeComponent from 'components/projects/ProjectAnalyze';
import {
  selectProjectPointStatsVariablesShortNames,
  selectProjectStatsFields,
  selectProjectStatsIsLoading,
  selectProjectStatsTypes,
  selectProjectStatsVariablesByNames,
} from 'ducks/projects/selectors';
import { scrollToElement } from 'helpers/common';
import { EMPTY_VALUE } from 'constants/common';
import { showModal } from 'ducks/modals/actions';
import { NOTIFICATION_MODAL, STATS_MODAL } from 'constants/modals';
import { STATS_ID_DELIMITER } from 'constants/projects';
import { getComputedPoints } from 'helpers/points';
import { ROUTES } from 'constants/routes';
import { STATS_TO_RUN } from 'constants/graphsConfig';
import { trackStats } from 'ducks/trackers/actions/workzone';

const statisticsIdName = 'statistics';
const variablesIdName = 'variables';
const fieldsIdName = 'fields';
const displayButtonIdName = 'displayStatistic';

/**
 * returns link to help page by given variable name
 * @param { string } variableName
 * @returns { string }
 */
const getHelpLinkByStatTypeName = () => generatePath(ROUTES.statistics);

/**
 * Project analyze page container
 * displays project statistics
 * @param points - collection of points for current project
 * @param projectId - id of current project
 * @param selectedMarkerId - selected point id
 * @param selectMarkerOnMenu - handler for point select from menu
 */
const ProjectAnalyze = ({
  points,
  projectId,
  selectedMarkerId,
  selectMarkerOnMenu,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const computedPoints = useMemo(() => getComputedPoints(points), [points]);
  const [scrollTo, setScrollTo] = useState(null);
  const [isSelected, setisSelected] = useState(null);

  useEffect(() => {
    if (selectedMarkerId) {
      setSelectedPoint((prevMarker) => {
        if (prevMarker.id !== selectedMarkerId) {
          const marker = computedPoints.filter(
            (item) => item.id === selectedMarkerId
          )[0];
          if (marker) {
            setCurrentPoints([marker]);
            return marker;
          }
        }
        clearSelection();
        return EMPTY_VALUE;
      });
    } else {
      clearSelection();
    }
  }, [selectedMarkerId, computedPoints, clearSelection]);

  useEffect(() => {
    dispatch(requestProjectStats(projectId));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (scrollTo) {
      scrollToElement(scrollTo);
      setScrollTo(null);
    }
  }, [scrollTo]);

  // Selected state
  const [selectedPoint, setSelectedPoint] = useState(EMPTY_VALUE);
  const [selectedField, setSelectedField] = useState(EMPTY_VALUE);
  const [selectedType, setSelectedType] = useState(EMPTY_VALUE);
  const [selectedVariable, setSelectedVariable] = useState(EMPTY_VALUE);

  // Init state
  const initFields = useSelector((state) =>
    selectProjectStatsFields(state, projectId, selectedMarkerId)
  );
  const statisticTypes = useSelector((state) =>
    selectProjectStatsTypes(
      state,
      projectId,
      selectedMarkerId,
      selectedField.id
    )
  );
  const initTypes = useMemo(
    () =>
      statisticTypes.map((item) => ({
        ...item,
        linkTo: getHelpLinkByStatTypeName(),
        icon: <Question size={20} />,
      })),
    [statisticTypes]
  );
  const initVariables = useSelector((state) =>
    selectProjectStatsVariablesByNames(
      state,
      projectId,
      selectedMarkerId,
      selectedField.id,
      selectedType.id
    )
  );

  // Current State
  const [currentPoints, setCurrentPoints] = useState(computedPoints);
  const [currentFields, setCurrentFields] = useState(initFields);
  const [currentTypes, setCurrentTypes] = useState(initTypes);
  const [currentVariables, setCurrentVariables] = useState(initVariables);

  const shortVariables = useSelector((state) =>
    selectProjectPointStatsVariablesShortNames(
      state,
      projectId,
      selectedMarkerId,
      selectedField.id,
      selectedType.id
    )
  );

  const statsIsLoading = useSelector((state) =>
    selectProjectStatsIsLoading(state, projectId)
  );

  const canRun = STATS_TO_RUN.includes(selectedType.id);

  useEffect(() => {
    if (initVariables.length === 1 && isSelected) {
      setSelectedVariable(canRun ? shortVariables[0] : initVariables[0]);
      setisSelected(false);
    }
  }, [isSelected, setisSelected, initVariables, shortVariables, canRun]);

  const changeField = useCallback(
    (field) => {
      setCurrentTypes([]);
      setCurrentVariables([]);
      setSelectedType(EMPTY_VALUE);
      setSelectedVariable(EMPTY_VALUE);
      setSelectedField((prevField) => {
        setCurrentFields(prevField.id === field.id ? initFields : [field]);
        return prevField.id === field.id ? EMPTY_VALUE : field;
      });
    },
    [
      setSelectedField,
      setSelectedType,
      setCurrentFields,
      initFields,
      initTypes,
      setSelectedVariable,
      setCurrentVariables,
      setCurrentTypes,
    ]
  );
  const changeType = useCallback(
    (type) => {
      setCurrentVariables([]);
      setSelectedVariable(EMPTY_VALUE);
      setSelectedType((prevType) => {
        setCurrentTypes(prevType.id === type.id ? initTypes : [type]);
        return prevType.id === type.id ? EMPTY_VALUE : type;
      });
      setisSelected(true);
    },
    [
      setSelectedType,
      setSelectedVariable,
      setCurrentTypes,
      setisSelected,
      initTypes,
      initVariables,
      setCurrentVariables,
    ]
  );
  const changeVariables = useCallback(
    (variable) => {
      setSelectedVariable((prevVariable) => {
        setCurrentVariables(
          prevVariable.id === variable.id ? initVariables : [variable]
        );
        return prevVariable.id === variable.id ? EMPTY_VALUE : variable;
      });
    },
    [setSelectedVariable, setCurrentVariables, initVariables]
  );

  const showNoVariableWarning = useCallback(() => {
    dispatch(
      showModal({
        modalType: NOTIFICATION_MODAL,
        options: {
          title: t('projects.analyze.noVariableTitle'),
          message: t('projects.analyze.selectVariable'),
        },
      })
    );
  }, [dispatch, t]);

  const showStatsModal = useCallback(
    (statsIds, helpLink) => {
      dispatch(
        showModal({
          modalType: STATS_MODAL,
          options: { statsIds, projectId, helpLink },
        })
      );
    },
    [dispatch, projectId]
  );

  const displayStatistics = useCallback(
    ({ currentTarget }) => {
      const statsIds = currentTarget.getAttribute('data-stats-ids');
      // const statType = currentTarget.getAttribute('data-stat-type');
      const field = currentTarget.getAttribute('selected-field-str');
      const variable = currentTarget.getAttribute('selected-variable-str');
      const type = currentTarget.getAttribute('selected-type-str');
      dispatch(trackStats(field, variable, type));
      const helpLink = generatePath(ROUTES.statistics);
      if (!statsIds) {
        return showNoVariableWarning();
      }
      return showStatsModal(statsIds.split(STATS_ID_DELIMITER), helpLink);
    },
    [showNoVariableWarning, showStatsModal]
  );

  const clearSelection = useCallback(() => {
    setSelectedField(EMPTY_VALUE);
    setSelectedType(EMPTY_VALUE);
    setSelectedVariable(EMPTY_VALUE);
    setCurrentFields([]);
    setCurrentTypes([]);
    setCurrentVariables([]);
    setSelectedField(EMPTY_VALUE);
    setSelectedType(EMPTY_VALUE);
    setSelectedVariable(EMPTY_VALUE);
    setSelectedPoint(EMPTY_VALUE);
    setCurrentPoints(computedPoints);
  }, []);

  useEffect(() => {
    // Init current fields
    if (
      selectedMarkerId &&
      Object.keys(selectedField).length === 0 &&
      (currentFields.length === 0 || currentFields.length !== initFields.length)
    ) {
      setCurrentFields(initFields);
    }

    // Init current types
    if (
      Object.keys(selectedField).length !== 0 &&
      Object.keys(selectedType).length === 0 &&
      (currentTypes.length === 0 || currentTypes.length !== initTypes.length)
    ) {
      setCurrentTypes(initTypes);
    }

    // Init current Variables
    if (
      Object.keys(selectedType).length !== 0 &&
      Object.keys(selectedVariable).length === 0 &&
      (currentVariables.length === 0 ||
        currentVariables.length !== initVariables.length)
    ) {
      setCurrentVariables(initVariables);
    }
  }, [
    initFields,
    initTypes,
    initVariables,
    selectedPoint,
    selectedField,
    selectedType,
    selectedVariable,
    currentFields,
    currentTypes,
    currentVariables,
    setCurrentPoints,
    setCurrentFields,
    setCurrentTypes,
    setCurrentVariables,
    computedPoints,
  ]);

  return (
    <ProjectAnalyzeComponent
      points={currentPoints}
      fields={currentFields}
      statisticTypes={currentTypes}
      variables={currentVariables}
      shortVariables={shortVariables}
      parameters={{ selectedField, selectedVariable, selectedType }}
      changeField={changeField}
      changeType={changeType}
      changeVariables={changeVariables}
      selectedMarkerId={selectedMarkerId}
      onSelectMarker={selectMarkerOnMenu}
      statsIsLoading={statsIsLoading}
      displayStatistics={displayStatistics}
      clearSelection={clearSelection}
      variablesIdName={variablesIdName}
      statisticsIdName={statisticsIdName}
      fieldsIdName={fieldsIdName}
      displayButtonIdName={displayButtonIdName}
      canRun={canRun}
    />
  );
};

ProjectAnalyze.propTypes = {
  points: PropTypes.array.isRequired,
  projectId: PropTypes.number.isRequired,
  selectedMarkerId: PropTypes.number,
  selectMarkerOnMenu: PropTypes.func.isRequired,
};

export default memo(ProjectAnalyze);
