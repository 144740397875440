import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import BaseModal from 'components/common/BaseModal';
import ManageGeometries from 'containers/geometries/ManageGeometries';

import { useStyles } from './styles';

/**
 * ManageGeometriesModal component
 * Modal for manage the geometries
 * @param {Number} projectId
 * @param {Function} onClose -  handler for close modal button
 */
const ManageGeometriesModal = ({ onClose, projectId }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <BaseModal
      onClose={onClose}
      title={t('projects.geometry.modalTitle')}
      modalClass={classes.container}
    >
      <ManageGeometries projectId={projectId} onCancel={onClose} />
    </BaseModal>
  );
};

ManageGeometriesModal.propTypes = {
  projectId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default React.memo(ManageGeometriesModal);
