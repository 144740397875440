import { get, pick } from 'lodash';

import {
  VALUES_KEY,
  RAW_DATA_GRAPH_PARAMS_PATH,
  EPD_GRAPH_VALUABLE_PARAMS,
  ANNUAL_INDEX,
  GRAPH_EMPTY_PLACEHOLDER,
  EPD_GRAPH_TOOLTIP_TITLE_PATH,
} from 'constants/graphs';
import { INVALID_GRAPH_DATA, INVALID_RANGE } from 'constants/errors';
import { camelizeBoth } from 'helpers/camelizer';
import { getPrettyNumber } from 'helpers/data';
import {
  getLevelsTypeByStatsId,
  getPreparedUnits,
  getTitleAdditionByLevelType,
  getTitleFromRawGraphData,
} from 'helpers/graphs/common';
import { EOL, MONTH_NAMES } from 'constants/common';

import { getMinMax, getRange } from '../common';

/**
 * returns prepared annual data for graph EmpiricalProbabilityDistribution
 * @param rawGraphData
 * @returns {{
 *    data: array,
 *    title: string,
 *    minYValue: number,
 *    maxYValue: number,,
 *    ylabel: string,
 *    xlabel: string,
 *    xUnits: string,
 *    levels: array,
 *    xValues: array,
 *    tooltipTitle: string,
 *    xDomain: array,
 *  }} or array of such objects
 */
export const getPreparedEmpiricalProbabilityAnnualGraphData = (
  rawGraphData
) => {
  const rawGraphParams = get(rawGraphData, RAW_DATA_GRAPH_PARAMS_PATH, null);
  if (!rawGraphParams) {
    throw Error(INVALID_GRAPH_DATA);
  }

  const { yName, xName } = camelizeBoth(
    pick(rawGraphParams, EPD_GRAPH_VALUABLE_PARAMS)
  );
  const xValuesParams = rawGraphData[xName];
  const yValuesParams = rawGraphData[yName];
  const { ylabel: yLabel, xlabel: xLabel } = rawGraphParams;
  const xUnits = getPreparedUnits(xValuesParams.attributes.units);
  const tooltipTitle = get(rawGraphData, EPD_GRAPH_TOOLTIP_TITLE_PATH, '');
  const title = getTitleFromRawGraphData(rawGraphData);
  const levels =
    rawGraphData.level && rawGraphData.level[VALUES_KEY]
      ? {
          type: getLevelsTypeByStatsId(rawGraphData.id),
          values: rawGraphData.level[VALUES_KEY],
        }
      : {};
  const hasLevels = !!Object.keys(levels).length;

  if (!hasLevels) {
    const preparedTitle = `${title}${EOL}Annual`;
    const yValues = yValuesParams[VALUES_KEY];
    const maxYValue = Math.max.apply(null, yValues);
    const minYValue = Math.min.apply(null, yValues);
    const xValues = xValuesParams[VALUES_KEY];
    const maxXValue = Math.max.apply(null, xValues);
    const xDomain = [0, maxXValue];
    const data = xValues.map((item, index) => ({
      xValue: getPrettyNumber(item),
      yValue: getPrettyNumber(yValues[index]),
    }));

    return {
      data,
      title: preparedTitle,
      minYValue,
      maxYValue,
      yLabel,
      xLabel,
      xUnits,
      levels,
      tooltipTitle,
      xValues,
      xDomain,
    };
  }
  const yValuesByLevel = yValuesParams[VALUES_KEY];
  const xValuesByLevel = xValuesParams[VALUES_KEY];

  return xValuesByLevel.reduce((acc, values, levelIndex) => {
    const levelTitleAddition = getTitleAdditionByLevelType(
      levels.type,
      levels.values[levelIndex]
    );
    const preparedTitle = `${title}${EOL}Annual${EOL}${levelTitleAddition}`;
    const yValues = yValuesByLevel[levelIndex];
    const maxYValue = Math.max.apply(null, yValues);
    const minYValue = Math.min.apply(null, yValues);
    const maxXValue = Math.max.apply(null, values);
    const xDomain = [0, maxXValue];
    const data = values.map((item, index) => ({
      xValue: getPrettyNumber(item),
      yValue: getPrettyNumber(yValues[index]),
    }));
    acc.push({
      data,
      title: preparedTitle,
      minYValue,
      maxYValue,
      yLabel,
      xLabel,
      xUnits,
      levels,
      tooltipTitle,
      xValues: xValuesByLevel,
      xDomain,
    });

    return acc;
  }, []);
};

/**
 * returns prepared monthly data for graph EmpiricalProbabilityDistribution
 * @param rawGraphData
 * @returns {{
 *    data: array,
 *    minYValue: number,
 *    maxYValue: number,
 *    title: string,
 *    xDomain: array,
 *    xValues: array,
 *    ylabel: string,
 *    xlabel: string,
 *    xUnits: string,
 *    levels: array,
 *    tooltipTitle: string,
 *  }} or array of such objects
 */
export const getPreparedEmpiricalProbabilityMonthlyGraphData = (
  rawGraphData
) => {
  const rawGraphParams = get(rawGraphData, RAW_DATA_GRAPH_PARAMS_PATH, null);
  if (!rawGraphParams) {
    throw Error(INVALID_GRAPH_DATA);
  }

  const { xName, yName, cName } = camelizeBoth(
    pick(rawGraphParams, EPD_GRAPH_VALUABLE_PARAMS)
  );

  /**
   * it could be `x` and `y` or `y` and `c` pair of params in data from stats API
   */
  const xParams = rawGraphData[xName ?? yName];
  const yParams = rawGraphData[xName ? yName : cName];
  const xValues = xParams[VALUES_KEY];
  const yValues = yParams[VALUES_KEY];
  const xUnits = getPreparedUnits(xParams.attributes.units);

  const title = getTitleFromRawGraphData(rawGraphData);
  const levels =
    rawGraphData.level && rawGraphData.level[VALUES_KEY]
      ? {
          type: getLevelsTypeByStatsId(rawGraphData.id),
          values: rawGraphData.level[VALUES_KEY],
        }
      : {};

  const tooltipTitle = get(rawGraphData, EPD_GRAPH_TOOLTIP_TITLE_PATH, '');
  const { ylabel: yLabel, xlabel: xLabel } = rawGraphParams;

  const hasLevels = !!Object.keys(levels).length;

  return yValues.reduce((acc, monthValues, monthIndex) => {
    const xValuesByMonth = xValues[monthIndex];
    if (hasLevels) {
      const dataByMonth = monthValues.reduce(
        (levelAcc, levelValues, levelIndex) => {
          const levelTitleAddition = getTitleAdditionByLevelType(
            levels.type,
            levels.values[levelIndex]
          );
          const preparedTitle = `${title}\nMonth: ${MONTH_NAMES[monthIndex]}\n${levelTitleAddition}`;
          const xValuesByLevel = xValuesByMonth[levelIndex];
          const maxYValue = Math.max.apply(null, levelValues);
          const minYValue = Math.min.apply(null, levelValues);
          const maxXValue = Math.max.apply(null, xValuesByLevel);
          const xDomain = [0, maxXValue];
          const data = levelValues.map((item, index) => ({
            yValue: getPrettyNumber(item),
            xValue: getPrettyNumber(xValuesByLevel[index]),
          }));
          /**
           * in graphs of this type cValues are yValues and xValues are yValues,
           * the same for xUnits and yUnits
           */
          levelAcc.push({
            data,
            title: preparedTitle,
            xDomain,
            maxYValue,
            minYValue,
            yLabel,
            xLabel,
            xUnits,
            levels,
            tooltipTitle,
            xValues,
          });

          return levelAcc;
        },
        []
      );
      acc.push(dataByMonth);
      return acc;
    }
    const maxYValue = Math.max.apply(null, yValues[monthIndex]);
    const minYValue = Math.min.apply(null, yValues[monthIndex]);
    const maxXValue = Math.max.apply(null, xValuesByMonth);
    const xDomain = [0, maxXValue];
    const preparedTitle = `${title}\nMonth: ${MONTH_NAMES[monthIndex]}`;
    const data = monthValues.map((item, index) => ({
      xValue: getPrettyNumber(xValuesByMonth[index]),
      yValue: getPrettyNumber(item),
    }));
    /**
     * in graphs of this type cValues are yValues and xValues are yValues,
     * the same for xUnits and yUnits
     */
    acc.push({
      data,
      title: preparedTitle,
      xDomain,
      maxYValue,
      minYValue,
      yLabel,
      xLabel,
      xUnits,
      levels,
      tooltipTitle,
      xValues,
    });

    return acc;
  }, []);
};

/**
 * returns data by probability for EPD graph
 * @param {Array} probabilities
 * @param {Array} xValues
 * @param {number} monthNumber
 * @param {boolean} hasLevels
 * @param {boolean} isAnnual
 * @returns [{
 *   data: [{xValue, yValue}],
 *   maxYValue,
 *   tooltipTitle,
 * }]
 */
export const getEpdDataByProbability = ({
  probabilities,
  xValues,
  monthNumber = ANNUAL_INDEX,
  hasLevels,
}) => {
  const isAnnual = monthNumber === ANNUAL_INDEX;
  const xValuesByMonth = isAnnual ? xValues : xValues[monthNumber - 1];
  if (hasLevels) {
    return probabilities.reduce(
      (probabilitiesAcc, levelProbability, levelIndex) => {
        const maxYValue = Math.max.apply(null, levelProbability);
        const data = levelProbability.map((yValue, index) => {
          const xValuesByLevel = xValuesByMonth[levelIndex];
          return {
            xValue: xValuesByLevel[index],
            yValue,
          };
        });
        probabilitiesAcc.push({
          data,
          maxYValue,
        });
        return probabilitiesAcc;
      },
      []
    );
  }
  return probabilities.reduce(
    (acc, probability, index) => {
      acc.data.push({
        xValue: xValuesByMonth[index],
        yValue: probability,
      });
      acc.maxYValue = Math.max.apply(null, probabilities);
      return acc;
    },
    { data: [], maxYValue: 0 }
  );
};

/**
 * returns calculated average probabilities by selected range (sectors on rose select)
 * @param { array } probabilities - initial probability with all leafs
 * @param {{ start, end }} range - selected leafs range (start, end indexes)
 * @param { number } monthNumber - month number, 0 by default (ANNUAL_INDEX)
 * @param { array } leafsOccurrences - leafs occurrences array
 * @param { bool } hasLevels - if true, graph has levels
 * (higher occurrence means higher affect on average probability)
 * @returns { number[][][] } average of selected range probabilities
 * @note average probability is calculated by 'Law of total probability'
 * considering weights (occurence/occurrencesSum)
 * ( (aOccurrence/(occurrencesSum)) * a + (aOccurrence/(occurrencesSum)) * b + ...  )
 */
export const getAverageProbabilityByRange = ({
  probabilities,
  range = {},
  monthNumber = ANNUAL_INDEX,
  hasLevels,
  leafsOccurrences,
}) => {
  if (!Number.isFinite(range.start) || !Number.isFinite(range.end)) {
    throw Error(INVALID_RANGE);
  }

  const [startIndex, endIndex] = getMinMax(range.start, range.end);
  const isAnnual = monthNumber === ANNUAL_INDEX;
  const monthIndex = monthNumber - 1;
  const currentProbabilities = isAnnual
    ? probabilities
    : probabilities[monthIndex];
  const occurrences = isAnnual
    ? leafsOccurrences
    : leafsOccurrences[monthIndex];
  const leafsAmount = occurrences.length;
  const selectedLeafsIndexes = !range.invert
    ? getRange(startIndex, endIndex)
    : getRange(0, startIndex).concat(getRange(endIndex, leafsAmount - 1));

  if (startIndex === endIndex) {
    return currentProbabilities[startIndex];
  }

  const selectedOccurrencesSum = selectedLeafsIndexes.reduce(
    (acc, index) => acc + occurrences[index],
    0
  );
  const averageProbabilities = [];
  const numberOfProbabilities = hasLevels
    ? currentProbabilities[0][0].length
    : currentProbabilities[0].length;

  if (!hasLevels) {
    for (
      let probabilityIndex = 0;
      probabilityIndex < numberOfProbabilities;
      probabilityIndex += 1
    ) {
      averageProbabilities[probabilityIndex] = 0;
      for (let i = 0; i < selectedLeafsIndexes.length; i += 1) {
        const leafIndex = selectedLeafsIndexes[i];
        const leaf = currentProbabilities[leafIndex];
        const probability = leaf[probabilityIndex];
        const currentAverage = averageProbabilities[probabilityIndex] || 0;
        if (probability === 0 || probability === GRAPH_EMPTY_PLACEHOLDER) {
          continue;
        }
        averageProbabilities[probabilityIndex] =
          currentAverage +
          (probability * occurrences[leafIndex]) / selectedOccurrencesSum;
      }
    }
    return averageProbabilities;
  }

  const numberOfLevels = currentProbabilities[0].length;
  for (let levelIndex = 0; levelIndex < numberOfLevels; levelIndex += 1) {
    if (!averageProbabilities[levelIndex]) {
      averageProbabilities[levelIndex] = [];
    }
    for (
      let probabilityIndex = 0;
      probabilityIndex < numberOfProbabilities;
      probabilityIndex += 1
    ) {
      averageProbabilities[levelIndex][probabilityIndex] = 0;
      for (let i = 0; i < selectedLeafsIndexes.length; i += 1) {
        const leafIndex = selectedLeafsIndexes[i];
        const leaf = currentProbabilities[leafIndex];
        const probability = leaf[levelIndex][probabilityIndex];
        const currentAverage =
          averageProbabilities[levelIndex][probabilityIndex] || 0;
        if (probability === 0 || probability === GRAPH_EMPTY_PLACEHOLDER) {
          continue;
        }
        averageProbabilities[levelIndex][probabilityIndex] =
          currentAverage +
          (probability * occurrences[leafIndex]) / selectedOccurrencesSum;
      }
    }
  }

  return averageProbabilities;
};
