import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  ({
    palette,
    buttons,
    navbarHeight,
    projectMenuHeight,
    baseSideBarWidth,
  }) => ({
    dataValidationContainer: {
      position: 'fixed',
      top: navbarHeight + projectMenuHeight,
      zIndex: 1101,
      right: 0,
      left: 0,
      bottom: 0,
    },
    sideBarContainer: {
      width: baseSideBarWidth,
    },
    dataValidationSideBar: {
      padding: '20px',
      paddingBottom: 0,
      boxShadow: `0 3px 5px 0 rgba(0, 0, 0, 0.4)`,
    },
    contentContainer: {
      width: `calc(100% - ${baseSideBarWidth}px)`,
      height: `100%`,
      overflowX: 'clip',
      overflowY: 'auto',
      padding: 20,
    },
    sideBarMenuItemContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },
    sideBarMenuItem: {
      cursor: 'pointer',
      color: palette.sinay.primary['40'],
      fontWeight: 600,
      textTransform: 'uppercase',
      marginTop: 10,
      marginBottom: 10,
      marginLeft: 5,
      padding: 10,
      display: 'flex',
      flexDirection: 'row',
      gap: 15,
    },
    sideBarMenuItemSelected: {
      background: palette.sinay.primary['90'],
    },
    tutorialCard: {
      border: `2px solid  ${palette.sinay.primary['90']}`,
      borderRadius: 5,
      padding: 15,
      marginTop: 40,
    },
    tutorialTitleContainer: {
      display: 'flex',
      flexDirection: 'row',
      gap: 10,
      alignItems: 'center',
      fontWeight: 600,
      marginBottom: 20,
    },
    tutorialText: {
      fontWeight: 600,
      fontSize: 13,
    },
    tutorialStepper: {
      textAlign: 'center',
      fontSize: 12,
      marginTop: 20,
      marginBottom: 10,
    },
    tutorialButtonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },
    nextButton: {
      ...buttons.fill.primary,
      width: '100%',
      fontSize: 12,
    },
    previousButton: {
      ...buttons.outlined.primary,
      width: '100%',
      fontSize: 12,
    },
  })
);
