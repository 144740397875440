import { createSelector } from 'reselect';

import { STATUS_SUCCESS, STATUS_ERROR, EMPTY_ARRAY } from 'constants/common';

const selectUsage = (state) => state.usage.data;

export const isReady = (state) => state.usage.status === STATUS_SUCCESS;

export const isError = ({ usage }) => usage.status === STATUS_ERROR;

export const selectCustomers = createSelector(
  selectUsage,
  ({ customers = EMPTY_ARRAY }) => customers
);

export const selectCustomerDetail = (id) =>
  createSelector(selectUsage, ({ customers = EMPTY_ARRAY }) =>
    // eslint-disable-next-line
    customers.find((customer) => customer.id == id)
  );

export const selectUsers = createSelector(
  selectUsage,
  ({ users = EMPTY_ARRAY }) => users
);

export const selectUserDetail = (id) =>
  createSelector(selectUsage, ({ users = EMPTY_ARRAY }) =>
    // eslint-disable-next-line
    users.find((user) => user.id == id)
  );

export const selectOffers = createSelector(
  selectUsage,
  ({ offers = EMPTY_ARRAY }) => offers
);

export const selectOfferDetail = (id) =>
  createSelector(selectUsage, ({ offers = EMPTY_ARRAY }) =>
    // eslint-disable-next-line
    offers.find((offer) => offer.id == id)
  );
