import { makeStyles } from '@material-ui/core/styles';

import { theme } from 'common/theme';

export const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    '& input': {
      padding: 10,
    },
  },
  modalSelect: {
    '& div': {
      padding: 10,
      maxWidth: 208,
      minWidth: 208,
      overflow: 'hidden',
    },
  },
  modalButton: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeButton: {
    ...theme.buttons.elevated.neutral,
  },
  saveButton: {
    ...theme.buttons.fill.primary,
  },
  helperText: {
    position: 'absolute',
    bottom: -20,
    wordWrap: 'break-word',
  },
  fieldName: {
    fontWeight: 700,
  },
  field: {
    marginLeft: 30,
    width: '100%',
  },
  commonErrors: {
    display: 'flex',
    justifyContent: 'center',
    '& p': {
      fontSize: 14,
      paddingBottom: 10,
    },
  },
  dataValidationDescription: {
    padding: '18px 0',
    fontSize: 12,
  },
}));
