import { alpha, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  '@keyframes firstDot': {
    from: { transform: 'scale(0)' },
    to: { transform: 'scale(1)' },
  },
  '@keyframes middleDot': {
    from: { transform: 'translate(0, 0)' },
    to: { transform: 'translate(24px, 0)' },
  },
  '@keyframes lastDot': {
    from: { transform: 'scale(1)' },
    to: { transform: 'scale(0)' },
  },
  wrapper: {
    display: 'inline-block',
    position: 'relative',
    width: 78,
    height: 54,
    '& > div': {
      position: 'absolute',
      top: 20,
      width: 13,
      height: 13,
      borderRadius: '50%',
      background: ({ color, opacity }) => alpha(color, opacity),
    },
    '& > div:nth-child(1)': {
      left: 8,
      animation: '$firstDot 0.6s infinite',
    },
    '& > div:nth-child(2)': {
      left: 8,
      animation: '$middleDot 0.6s infinite',
    },
    '& > div:nth-child(3)': {
      left: 32,
      animation: '$middleDot 0.6s infinite',
    },
    '& > div:nth-child(4)': {
      left: 56,
      animation: '$lastDot 0.6s infinite',
    },
  },
});
