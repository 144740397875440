export const defaultValues = {
  name: '',
  lastname: '',
  gender: '',
  occupation: '',
  password: '',
  email: '',
  checkbox: '',
  month: '',
};
