import { Select, InputLabel, InputBase } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useStyles } from './styles';

const labelId = 'simple-select-label';
/**
 * Simple select component. Wraps mui select.
 * Adds inline label if given.
 * @param { string } label
 * @param { array } optionList
 * @param { string || number } defaultValue
 * @param { boolean } valueUppercase
 * @param { boolean } withBaseInput
 * @param {Object} props
 */
const SimpleSelect = ({
  optionList,
  label,
  defaultValue,
  valueUppercase,
  withBaseInput,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div>
      {label && (
        <>
          <InputLabel
            id={labelId}
            className="inline-label"
            classes={{ root: classes.label }}
          >
            {label}
          </InputLabel>
          &nbsp;
        </>
      )}
      <Select
        {...props}
        displayEmpty
        defaultValue={defaultValue}
        labelId={labelId}
        classes={{
          selectMenu: classNames(classes.selectMenu, {
            [classes.uppercase]: valueUppercase,
          }),
        }}
        input={withBaseInput ? <InputBase /> : undefined}
      >
        {optionList.map((item) => (
          <MenuItem key={item.option} value={item.value}>
            {item.option}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

SimpleSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  optionList: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  valueUppercase: PropTypes.bool,
  withBaseInput: PropTypes.bool,
};

SimpleSelect.defaultProps = {
  valueUppercase: true,
  withBaseInput: false,
};

export default memo(SimpleSelect);
