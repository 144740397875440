import { makeStyles } from '@material-ui/core/styles';

import { theme } from 'common/theme';

export const useStyles = makeStyles(({ palette }) => ({
  list: {
    maxHeight: 250,
    overflowY: 'auto',
    paddingLeft: 0,
    margin: '0 0 40px',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 15px',
    '&:not(:last-child)': {
      borderBottom: '1px solid #ddd',
    },
  },
  listItemTitle: {
    fontSize: 14,
    fontWeight: 700,
  },
  listItemSubtitle: {
    fontSize: 12,
    color: palette.grey.main,
  },
  modalButtonRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  skipButton: {
    margin: '0 5px',
    minWidth: 100,
    ...theme.buttons.elevated.primary,
  },
  saveButton: {
    margin: '0 5px',
    minWidth: 100,
    ...theme.buttons.fill.primary,
  },
  formText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 15,
    fontSize: 14,
    textAlign: 'center',
  },
  formTextError: {
    textAlign: 'center',
    marginTop: 50,
    marginBottom: 15,
    fontSize: 14,
    color: palette.error.main,
  },
  formTextLink: {
    marginLeft: 5,
    fontSize: 14,
    color: palette.blue.main,
  },
}));
