import { makeStyles } from '@material-ui/core/styles';

import { theme } from 'common/theme';

export const useStyles = makeStyles(({ palette }) => ({
  row: {
    marginBottom: 5,
    '& input': {
      padding: '10px 0',
      color: palette.primary.main,
    },
  },
  fieldName: {
    textTransform: 'uppercase',
    fontSize: 14,
    color: palette.primary.main,
  },
  fullWidth: {
    width: '100%',
  },
  modalButtonRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modalButton: {
    margin: '0 5px',
    minWidth: 100,
  },
  helperText: {
    height: 20,
    wordWrap: 'break-word',
  },
  removeButton: {
    margin: '0 5px',
    minWidth: 100,
    ...theme.buttons.elevated.error,
  },
  cancelButton: {
    ...theme.buttons.elevated.neutral,
  },
  saveButton: {
    ...theme.buttons.fill.primary,
  },
}));
