import {
  getAxisDataByVariables,
  getGridTicksByMinMax,
} from 'helpers/graphs/common';
import {
  GRAPH_EMPTY_PLACEHOLDER,
  MIN_EXTREME_PEAK_ROSE_TICKS_AMOUNT,
  THRESHOLD_LINE_PERCENT_POSITION,
} from 'constants/graphs';
import { arrayExclude, getRange } from 'helpers/common';

/**
 * returns prepared common data for peak rose chart
 * @param {
 *   {
 *     threshold: number,
 *     empiricalAssociatedReturnValues: number[],
 *     empiricalReturnValues: number[],
 *     empiricalQuantiles: number[]
 *   }[]
 * } data
 * @returns {{ values: array, pointsTicks: array }}
 */
export const getPreparedExtremePeakRoseCommonData = (data) =>
  data.reduce(
    (
      result,
      {
        threshold,
        empiricalAssociatedReturnValues,
        empiricalReturnValues,
        empiricalOutliersAssociatedReturnValues = [],
        empiricalOutliersValues = [],
      }
    ) => {
      const [
        preparedOutliersAssociatedValues,
        preparedAssociatedValues,
        preparedReturnValues,
        preparedOutliersReturnValues,
      ] = [
        empiricalOutliersAssociatedReturnValues,
        empiricalAssociatedReturnValues,
        empiricalReturnValues,
        empiricalOutliersValues,
      ].map((rawValues) => arrayExclude(rawValues, GRAPH_EMPTY_PLACEHOLDER));

      const pointsMin = Math.floor(
        Math.min(
          Math.min.apply(null, preparedOutliersReturnValues),
          Math.min.apply(null, preparedReturnValues)
        )
      );
      const pointsMax = Math.ceil(
        Math.max(
          Math.max.apply(null, preparedOutliersReturnValues),
          Math.max.apply(null, preparedReturnValues)
        )
      );

      // gather points
      const points = preparedAssociatedValues.reduce((acc, angle, index) => {
        const value = preparedReturnValues[index];
        acc.push({ angle, value });
        return acc;
      }, []);

      // gather outliers
      const outliers = preparedOutliersAssociatedValues.reduce(
        (acc, angle, index) => {
          const value = preparedOutliersReturnValues[index];
          acc.push({ angle, outlier: value });
          return acc;
        },
        []
      );

      const thresholdPoints = getRange(1, 360).map((angle) => ({
        angle,
        threshold,
      }));
      const pointsMinOffset =
        (pointsMax - pointsMin) * THRESHOLD_LINE_PERCENT_POSITION;

      const pointsTicks = getGridTicksByMinMax({
        min: threshold - pointsMinOffset,
        max: pointsMax,
        minTicks: MIN_EXTREME_PEAK_ROSE_TICKS_AMOUNT,
        valueAsMaxTick: true,
      });
      pointsTicks.push(threshold);

      result.values = result.values.concat(points, thresholdPoints, outliers);
      result.pointsTicks = result.pointsTicks.concat(pointsTicks.sort());

      return result;
    },
    {
      values: [],
      pointsTicks: [],
    }
  );

/**
 * returns prepared peak rose data for chart
 * @param { object } rawData
 * @param { string } variantName
 * @returns {{
 *   chartData: { values: Array, pointsTicks: Array },
 *   axisData: {
 *     axisLabels: { x: string, y: string },
 *     axisInfo: { x: Object, y: Object }
 *   },
 * }}
 */
export const getPreparedExtremePeakRoseData = (rawData, variantName) => {
  const { data = [] } = rawData;

  const commonData = getPreparedExtremePeakRoseCommonData(data, variantName);
  const axisData = getAxisDataByVariables({
    xVariable: rawData.associatedVariable,
    yVariable: rawData.mainVariable,
  });

  return {
    chartData: commonData,
    axisData,
  };
};
