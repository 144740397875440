import { useCallback, useEffect, useRef } from 'react';

import { useValidation } from 'hooks/useValidation';
import { useFields } from 'hooks/useFields';
import { EMPTY_VALUE, EMPTY_FUNCTION } from 'constants/common';
import useIsMounted from 'hooks/useIsMounted';
import { usePersistentCallback } from 'hooks/usePersistentCallback';

/**
 * useForm hook. Combination of useFields and useValidation hooks.
 * Takes onSuccess and onError callbacks and all useFields and useValidation arguments.
 * onSuccess receives values as argument, onErrors receives errors
 * returns handleSubmit which performs given onSuccess or onError depends on validation
 * also returns all useFields and useValidation props
 * @returns {{
 *   handleChange: function,
 *   handleBlur: function,
 *   handleSubmit: function,
 *   setField: function,
 *   setFields: function,
 *   updateField: function,
 *   values: object,
 *   errors: object
 * }}
 * @note if you use uncontrolled inputs, it's recommended to add data-attribute `submit` to your submit element
 * @see handleBlur
 */
export function useForm({
  defaultValues = EMPTY_VALUE,
  initialValues = EMPTY_VALUE,
  initialErrors = EMPTY_VALUE,
  validationRules = EMPTY_VALUE,
  onSuccess = EMPTY_FUNCTION,
  onError = EMPTY_FUNCTION,
}) {
  const { errors, setErrors, validate, validateOne } = useValidation({
    validators: validationRules,
    initialErrors,
  });
  const { values, handleChange, setField, updateField, setFields } = useFields({
    ...defaultValues,
    ...initialValues,
  });

  const isMounted = useIsMounted();

  const allowReinitialize = useRef(true);

  /**
   * helps to manage defer validation and prevents double validation
   */
  const validationProps = useRef({ defer: false, allowed: true });

  /**
   * forces initial values update
   * It's useful for example when getting data for initialization from server ( {} --loading--> {...data} )
   * after loading we should update initial values, but only once to avoid bugs when incorrect usage
   */
  useEffect(() => {
    if (!isMounted.current) {
      allowReinitialize.current = true;
      return;
    }
    if (initialValues && Object.keys(initialValues).length) {
      allowReinitialize.current = false;
      setFields(initialValues);
    }
  }, [initialValues, setFields, isMounted]);

  /**
   * validation
   * Uses persistent callback to not cause recreate function each values update
   */
  const performValidation = usePersistentCallback(
    (passedValues = EMPTY_VALUE) => {
      const validationErrors = validate(values);
      const isValid =
        !Object.keys(validationErrors).length ||
        Object.values(validationErrors).every((error) => !error);

      return isValid
        ? onSuccess({ ...passedValues, ...values })
        : onError(validationErrors);
    },
    [validate, values]
  );

  /**
   * defer validation mode. Waits for values update, then validates.
   * For some cases when values may not be up to date in moment of pressing submit button
   */
  useEffect(() => {
    const { defer, allowed } = validationProps.current;
    if (defer && allowed) {
      validationProps.current.defer = false;
      validationProps.current.allowed = false;
      performValidation();
    }
  }, [values, performValidation]);

  /**
   * Updates and validates single value on blur event. Can be used with uncontrolled inputs
   * there's possible handlers race: when user clicks to submit button right from some uncontrolled input
   * in this case validation will be executed twice and callback execution could be interrupted
   * @note to avoid this, please add data-attribute `submit` to your submit element (button or whatever)
   * @param target
   * @param relatedTarget
   */
  const handleBlur = ({ target, relatedTarget }) => {
    const field = target.name || target.id;
    const value = target.value || '';
    setField(field, value);
    if (!!relatedTarget && relatedTarget.getAttribute('data-submit')) {
      validationProps.current.defer = true;
      return;
    }
    return validateOne(field, value);
  };

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (validationProps.current.allowed) {
      performValidation();
    }
    validationProps.current.allowed = true;
  };

  /**
   * Callback that allows to perform validation like handleSubmit
   * But also allows more values to be passed to 'onSuccess' function
   * @param {object} passedValues
   */
  const handlePassedSubmit = (passedValues) => {
    if (validationProps.current.allowed) {
      performValidation(passedValues);
    }
    validationProps.current.allowed = true;
  };

  return {
    errors,
    values,
    setField,
    setErrors,
    setFields,
    updateField,
    handleChange,
    handleSubmit: usePersistentCallback(handleSubmit, [performValidation]),
    handleBlur: useCallback(handleBlur, []),
    handlePassedSubmit: usePersistentCallback(handlePassedSubmit, [
      performValidation,
    ]),
  };
}
