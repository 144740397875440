import React, { memo } from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

/**
 * ManageGeometriesButton - component for render button "Manage geometries"
 * @param {Object} props
 * @returns {jsx}
 */
const ManageGeometriesButton = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button {...props} classes={{ root: classes.container }} variant="outlined">
      {t('geometries.manage')}
    </Button>
  );
};

ManageGeometriesButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default memo(ManageGeometriesButton);
