import { createSelector } from 'reselect';

import { EMPTY_VALUE, STATUS_SUCCESS } from 'constants/common';

export const selectOffersIds = ({ offers }) => offers.ids;

export const selectOffersData = ({ offers }) => offers.data;

export const selectOfferById = createSelector(
  selectOffersData,
  (_, offerId) => offerId,
  (offersData, offerId) => offersData[offerId] || EMPTY_VALUE
);

export const selectOfferZonesIdsById = createSelector(
  selectOfferById,
  ({ zones = [] }) => zones.map(({ id }) => id)
);

export const selectOfferZonesById = createSelector(
  selectOfferById,
  ({ zones = [] }) => zones
);

export const selectOffersIsLoaded = ({ offers }) =>
  offers.status === STATUS_SUCCESS;

export const selectOffers = createSelector(
  selectOffersIds,
  selectOffersData,
  (offersIds, offersData) => offersIds.map((id) => offersData[id])
);

export const selectOfferZoneIdsByOfferId = createSelector(
  [(_, offerId) => offerId, selectOffersData],
  (offerId, offersData) =>
    offersData[offerId]
      ? offersData[offerId].zones.map(({ id }) => id)
      : EMPTY_VALUE
);
