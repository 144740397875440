import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ navbarHeight, projectMenuHeight }) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: `calc(100vh - ${navbarHeight}px - ${projectMenuHeight}px)`,
  },
}));
