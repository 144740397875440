import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Question, MapPin, Database, ChartBar } from 'phosphor-react';

import { trackStatsHelp } from 'ducks/trackers/actions/workzone';
import filterVarIcon from 'assets/images/project/filter/variables.png';
import { ROUTES } from 'constants/routes';

import { useStyles } from './styles';

/**
 * Collapse component.
 * @param {Node} title
 * @param {String} iconLabel
 * @param {React.ReactNode} children
 * @param {Boolean} isOpen
 * @param {Boolean} displayHelpIcon
 * @param {Object} props
 * @returns {jsx}
 */
const Collapse = ({
  title,
  iconLabel,
  children,
  isOpen,
  displayHelpIcon,
  ...props
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleLinkClick = useCallback((e) => {
    e.stopPropagation();
    dispatch(trackStatsHelp('Statistic type list', 'Open Help'));
  }, []);

  return (
    <Accordion
      square
      classes={{
        root: classes.collapseRoot,
      }}
      defaultExpanded={isOpen}
      {...props}
    >
      <AccordionSummary
        classes={{
          root: classes.collapseSummaryRoot,
          content: classes.collapseSummaryContent,
          expanded: classes.collapseSummaryExpanded,
        }}
      >
        <div>
          {iconLabel === 'point_selection' && (
            <MapPin size={20} className={classes.iconTitle} />
          )}
          {iconLabel === 'field' && (
            <Database size={20} className={classes.iconTitle} />
          )}
          {iconLabel === 'statistic' && (
            <ChartBar size={20} className={classes.iconTitle} />
          )}
          {iconLabel === 'variable' && (
            <img src={filterVarIcon} alt="" className={classes.iconTitle} />
          )}
          {title}
        </div>

        {displayHelpIcon && (
          <Link
            to={ROUTES.statistics}
            target="_blank"
            className={classes.link}
            onClick={handleLinkClick}
          >
            <Question size={22} />
          </Link>
        )}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classes.collapsePanel,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

Collapse.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
  iconLabel: PropTypes.string,
  displayHelpIcon: PropTypes.bool,
};

export default memo(Collapse);
