import React, { memo } from 'react';
import { Sector } from 'recharts';
import PropTypes from 'prop-types';

import { palette } from 'common/theme';

/**
 * Rose Leaf component. Draws an svg sector with nested sectors, proportionally to given `parts`
 * each child sector have its own color from `colors`
 * @param { number } cx - x center coordinate
 * @param { number } cy - y center coordinate
 * @param { number } radius - parent sector radius
 * @param { number } startAngle - start angle
 * @param { number } endAngle - end angle
 * @param { array } parts - array of values, on which child sectors sizes are based
 * @param { number } total - total value (sum of all parts)
 * @param { array } colors - colors array
 * @param { string } stroke - stroke color
 * @returns { JSX }
 */
const RoseLeaf = ({
  cx,
  cy,
  radius,
  startAngle,
  endAngle,
  parts,
  total,
  colors,
  stroke,
}) => {
  if (!total) {
    return null;
  }

  const { sectors } = parts.reduce(
    (acc, currentValue, index) => {
      if (!currentValue) {
        return acc;
      }
      const innerRadius = acc.currentRadius;
      const outerRadius = innerRadius + (currentValue / total) * radius;
      const color = colors[index];
      acc.currentRadius = outerRadius;

      acc.sectors.push(
        <Sector
          cx={cx}
          cy={cy}
          key={color}
          fill={color}
          stroke={stroke}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
        />
      );

      return acc;
    },
    { currentRadius: 0, sectors: [] }
  );

  return <g>{sectors}</g>;
};

RoseLeaf.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  radius: PropTypes.number.isRequired,
  startAngle: PropTypes.number.isRequired,
  endAngle: PropTypes.number.isRequired,
  parts: PropTypes.arrayOf(PropTypes.number).isRequired,
  total: PropTypes.number.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  stroke: PropTypes.string,
};

RoseLeaf.defaultProps = {
  cx: 0,
  cy: 0,
  stroke: palette.lightGrey.light,
};

export default memo(RoseLeaf);
