import {
  EMPTY_VALUE,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'constants/common';
import createReducer from 'helpers/createReducer';
import { groupAndNormalizeArrayBy } from 'helpers/data';
import { prepareAndSetError } from 'helpers/apiErrors';

import {
  CREATE_PLANNING,
  CREATE_PLANNING_ERROR,
  CREATE_PLANNING_SUCCESS,
  DELETE_PLANNING_ERROR,
  DELETE_PLANNING_SUCCESS,
  GET_PLANNING_ERROR,
  GET_PLANNING_SUCCESS,
  REQUEST_PLANNINGS_LIST,
  REQUEST_PLANNINGS_LIST_ERROR,
  REQUEST_PLANNINGS_LIST_SUCCESS,
  STOP_UPDATE_PLANNING,
  UPLOAD_PLANNING,
  UPLOAD_PLANNING_ERROR,
  UPLOAD_PLANNING_SUCCESS,
} from './types';

const initialState = {
  data: EMPTY_VALUE,
  ids: [],
  error: EMPTY_VALUE,
  status: STATUS_NOT_REQUESTED,
};

const setLoading = (state) => ({ ...state, status: STATUS_LOADING });

const setSuccessStatus = (state) => ({
  ...state,
  status: STATUS_SUCCESS,
  error: EMPTY_VALUE,
});

const setPlanningsList = (state, { planningsList }) => ({
  ...state,
  ...groupAndNormalizeArrayBy(planningsList, 'project'),
  status: STATUS_SUCCESS,
});

const deletePlanning = (state, { planningId, projectId }) => {
  const { [projectId]: planningsList, ...data } = state.data;
  data[projectId] = planningsList.filter(({ id }) => id !== planningId);

  return {
    ...state,
    data,
  };
};

const createPlanning = (state, { projectId, planningData }) => {
  const { [projectId]: projectData = [], ...data } = state.data;
  data[projectId] = [planningData, ...projectData];

  return {
    ...state,
    data,
    status: STATUS_SUCCESS,
    error: EMPTY_VALUE,
  };
};

const putPlanningById = (state, { projectId, planningData }) => {
  const { [projectId]: projectData = [], ...data } = state.data;
  const planningIndex = projectData.findIndex(
    (planning) => planning.id === planningData.id
  );
  projectData[planningIndex] = planningData;
  data[projectId] = [...projectData];

  return {
    ...state,
    data,
    status: STATUS_SUCCESS,
    error: EMPTY_VALUE,
  };
};

export default createReducer(initialState, {
  [REQUEST_PLANNINGS_LIST]: setLoading,
  [REQUEST_PLANNINGS_LIST_SUCCESS]: setPlanningsList,
  [REQUEST_PLANNINGS_LIST_ERROR]: prepareAndSetError,
  [DELETE_PLANNING_SUCCESS]: deletePlanning,
  [DELETE_PLANNING_ERROR]: prepareAndSetError,
  [CREATE_PLANNING]: setLoading,
  [CREATE_PLANNING_SUCCESS]: createPlanning,
  [CREATE_PLANNING_ERROR]: prepareAndSetError,
  [UPLOAD_PLANNING]: setLoading,
  [UPLOAD_PLANNING_SUCCESS]: setSuccessStatus,
  [UPLOAD_PLANNING_ERROR]: prepareAndSetError,
  [GET_PLANNING_ERROR]: prepareAndSetError,
  [GET_PLANNING_SUCCESS]: putPlanningById,
  [STOP_UPDATE_PLANNING]: putPlanningById,
});
