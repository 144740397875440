/* eslint-disable */
import { createSelector } from 'reselect';

import {
  EMPTY_ARRAY,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'constants/common';
import {
  getTilesFromStats2dToGeometries,
  getTilesFromStats2dFields,
} from 'helpers/stats2d';
/* eslint-disable-next-line */
import { selectProjectOfferInstanceByProjectId } from 'ducks/projects/selectors';
import { selectOffersData } from 'ducks/offers/selectors';

export const selectMapsData = ({ stat2d }) => stat2d.data;

export const selectMapsPropertiesData = ({ stat2d }) => stat2d.properties;

export const selectMapsIds = ({ stat2d }) => stat2d.ids;

export const selectMapsByZoneId = ({ stat2d }, zoneId) =>
  stat2d.data[zoneId] ?? EMPTY_ARRAY;

export const selectNeedMapsLoadingById = ({ stat2d }, zoneId) =>
  stat2d.status === STATUS_NOT_REQUESTED && !stat2d.data[zoneId];

export const selectMapsStatusIsSuccess = ({ stat2d }) =>
  stat2d.status === STATUS_SUCCESS;

export const selectMapsStatusIsLoading = ({ stat2d }) =>
  stat2d.status === STATUS_LOADING;

export const select2dStats = createSelector(selectMapsData, (stats2dData) =>
  Object.values(stats2dData).reduce((acc, zoneStats2d) => {
    acc.push(...zoneStats2d);
    return acc;
  }, [])
);

export const select2dStatsFields = createSelector(
  select2dStats,
  (stats2dData) => Object.values(stats2dData).map(({ fields }) => fields)
);

export const selectTilesFromStats2dWithPropertiesByZoneId = createSelector(
  [selectMapsByZoneId, selectMapsPropertiesData],
  getTilesFromStats2dToGeometries
);

export const selectTilesFromStats2d = createSelector(
  select2dStatsFields,
  getTilesFromStats2dFields
);

export const selectAreMapsInOffer = createSelector(
  selectProjectOfferInstanceByProjectId,
  selectOffersData,
  ({ id }, offersData) => !!offersData?.[id]?.hasMaps
);

export const selectAllowBuyMaps = createSelector(
  selectMapsByZoneId,
  selectMapsStatusIsSuccess,
  (stat2dMaps, isSuccess) => !stat2dMaps.length && isSuccess
);
