import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { palette } from 'common/theme';

import { useStyles } from './styles';

/**
 * Horizontal loader with 3 dots
 * @param { string } color
 * @param { number } opacity
 * @param { boolean } justifyCenter
 * @returns { JSX }
 */
export const HorizontalLoader = ({ color, opacity, justifyCenter }) => {
  const classes = useStyles({ color, opacity });
  const wrapperClass = classNames(classes.wrapper, {
    [classes.justifyCenter]: justifyCenter,
  });

  return (
    <div className={wrapperClass}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

HorizontalLoader.propTypes = {
  color: PropTypes.string,
  opacity: PropTypes.number,
  justifyCenter: PropTypes.bool,
};

HorizontalLoader.defaultProps = {
  color: palette.grey.middle,
  opacity: 1,
  justifyCenter: false,
};
