import { useMemo } from 'react';

import { getMarkerIcon } from 'helpers/getMarkerIcon';

/**
 * Hook return marker by params
 * @param {Boolean} hasSatelliteValidation
 * @param {Boolean} isComputed
 * @param {Boolean} selected
 * @returns {MarkerIcon}
 */
export function useMarkerIcon({
  hasSatelliteValidation = false,
  isComputed = false,
  selected = false,
}) {
  return useMemo(
    () => getMarkerIcon(hasSatelliteValidation, isComputed, selected),
    [isComputed, hasSatelliteValidation, selected]
  );
}
