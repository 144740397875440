export const scatterPlotData = {
  nbTimeSteps: 844,
  rows: [
    [
      'bias [m.s-1]',
      'pbias [%]',
      'rmse [m.s-1]',
      'nrmse [%]',
      'corr [ ]',
      'SI [ ]',
      'std_ratio [ ]',
    ],
    [
      -0.08700613252871099,
      -1.2037331397420379,
      1.5682789402331392,
      8.444387440491612,
      0.8930329422684925,
      0.21697198552012464,
      0.913984590515582,
    ],
  ],
  'magw - France-WRF9k': {
    _values: [
      9.282700569409363,
      3.05006507006391,
      7.689286994503078,
      6.624351744668388,
      8.683115139390967,
      5.09449786185742,
      10.27242561186239,
      7.967286301144763,
      12.31433785189455,
      12.943184674735155,
      10.968471129403618,
      12.134694293960418,
      4.423222198997632,
      5.858363170881373,
      8.845111509781908,
      6.120623399654881,
      6.799943306494045,
      4.021595835186303,
      3.622172047020839,
      7.401103584522722,
      12.397080438009734,
      2.9499011838093003,
      6.05835549225809,
      9.642976515305929,
      3.8942269842463046,
      5.939593192612815,
      10.974208452055647,
      4.825390523417727,
      7.6146060548003645,
      4.014542525873749,
      3.134506032254677,
      5.172918910942562,
      7.088135369379801,
      9.769335417873885,
      6.734098126962712,
      1.969501442683237,
      13.322876359758604,
      8.283099689033152,
      8.80034009141788,
      13.523224588280064,
      9.421006407652241,
      10.622255355985843,
      13.103481132410666,
      13.909575600669594,
      4.542468234651898,
      3.681620278990868,
      1.3421301645567663,
      11.771807977902245,
      2.227967029552015,
      2.4250494538915603,
      8.598307839784665,
      5.413541430374653,
      3.882116744138168,
      12.377302459195375,
      10.552091664393807,
      11.540263629094444,
      16.97586607686812,
      10.514901299190399,
      2.962583083688149,
      9.943016342760119,
      9.02843313428248,
      10.018686568150724,
      11.572859436913413,
      12.98731674024662,
      3.39258386927042,
      2.146169975933857,
      5.227915063060467,
      8.11465418809933,
      1.4153501879193022,
      4.027060926256815,
      14.952594497909118,
      16.464627413526873,
      11.505672500246819,
      8.065997188055828,
      10.36947226395179,
      15.004198635524238,
      13.8609018559226,
      5.999037408487838,
      8.326691409229122,
      6.269335397202903,
      0.9926771565631016,
      4.239332053659709,
      1.5150568233938464,
      4.828519883724089,
      3.122922481919308,
      10.378964697286113,
      11.232299520634944,
      12.341691501608304,
      14.417667702958338,
      7.376239544333877,
      14.095831521668748,
      13.100293948671789,
      5.880501571735288,
      9.863126332454154,
      9.13869519718671,
      14.688536231923472,
      15.759949191543601,
      4.6918609683477115,
      7.959370486738051,
      5.869381383953133,
      5.151914111845355,
      6.997680437090819,
      2.468967428028521,
      5.818725766180278,
      6.7521890579020045,
      8.916948211221777,
      11.92530063886618,
      16.383325133455443,
      10.43694353035503,
      5.4106290085634745,
      1.4508529836054282,
      12.374303520598357,
      10.920144649800502,
      0.5884620667568938,
      3.5924457580659044,
      7.081158276864263,
      3.595489897865703,
      6.5817666015697105,
      1.633253612365582,
      1.2604748190857684,
      14.472411485951788,
      15.830047679496996,
      9.783217152112934,
      17.31079674273923,
      7.784705225507937,
      7.327522361974307,
    ],
    run: 'WIND',
    attributes: {
      units: 'm.s-1',
      long_name: 'wind speed',
      valid_max: '100',
      valid_min: '0',
      standard_name: 'wind_speed',
      vartype: 'f8',
      description: '',
    },
  },
  linregress: {
    slope: 0.9770765848084254,
    r2: 0.7975078359767198,
    intercept: 0.2507026734039908,
  },
  'wind_speed_cor - CERSAT': {
    _values: [
      9.347345341374844,
      4.129267232084593,
      7.538054305442598,
      6.851101524507059,
      9.83204664895278,
      5.328737609528085,
      10.57374524099208,
      9.259400850746612,
      12.422961070190002,
      11.24878135583908,
      9.605833054872274,
      11.61187565754324,
      4.549531678064236,
      6.187277204590279,
      7.868295109634954,
      4.699102501646445,
      5.745307795064659,
      2.2374695472426005,
      3.580880856190882,
      6.123535777280448,
      13.349721344283951,
      4.7909640473251205,
      5.4968610682689505,
      8.714530543793222,
      5.236737818831776,
      8.203601466706774,
      10.738798718795936,
      3.5124756255006537,
      8.178248819509472,
      6.788182880708354,
      1.846039860149361,
      3.9362602068830874,
      5.022566497799102,
      11.311061609913196,
      7.664251731400543,
      1.834213511131937,
      11.613309167769986,
      8.314174404882586,
      8.744950169653789,
      15.07161508241372,
      10.35343929695838,
      11.946222364061718,
      12.640905184154576,
      16.576063105460452,
      5.6097083450925895,
      4.642776547788448,
      2.4046520257559627,
      11.220321245974851,
      2.849477837332027,
      1.510850320964599,
      6.285059938284526,
      4.47622460542121,
      6.196753006531496,
      14.286712061909554,
      15.363495544495581,
      12.5202010300601,
      15.006022877979337,
      11.191541838932768,
      3.0198825189394585,
      18.133174690931313,
      11.083785127180782,
      9.855315141469182,
      13.826287649171416,
      14.921250245659431,
      6.304338199024864,
      3.5173657847063584,
      6.802789627416311,
      6.993032705023328,
      1.329501991243785,
      2.9098253682769317,
      18.672755536897476,
      14.674303999908867,
      16.92705482810349,
      5.075152162233033,
      8.754230896800697,
      14.760838191569581,
      14.570344594118367,
      13.15550450257888,
      11.693653985122411,
      3.849221033909322,
      1.0452614005345655,
      3.5319180405801256,
      4.069499087582052,
      2.6725346087138675,
      9.395612471494399,
      7.929945420611522,
      11.85612770071925,
      15.329736516549872,
      14.022554504216847,
      2.9082535214101646,
      12.438014736716255,
      12.070034147092123,
      2.8314242114399635,
      7.321743321085159,
      12.718943340393452,
      15.844983798594459,
      18.012014368684905,
      1.706926931191696,
      4.083156753835107,
      2.095604394212659,
      10.326565860394224,
      9.066841178514059,
      8.075691940057059,
      9.675805125475343,
      2.468831266908073,
      14.50022437425701,
      14.780617108168027,
      14.089782310520743,
      6.061867887499072,
      1.1542149163481803,
      3.459170386254373,
      8.679646686362025,
      6.424845958275991,
      1.512684207405956,
      1.3560551634171125,
      11.74804235305778,
      8.960443747587698,
      2.9650979740790238,
      12.44030911836598,
      6.068878830763084,
      17.040083815474404,
      16.108105916478642,
      14.786185110402329,
      19.444232218553292,
      13.293217901201013,
      2.1067332934427476,
    ],
    run: 'CERSAT',
    attributes: {
      units: 'm.s-1',
      long_name: 'wind speed',
      valid_max: '100',
      valid_min: '0',
      standard_name: 'wind_speed',
      vartype: 'f8',
      description: '',
    },
  },
  var_names: ['wind_speed_cor - CERSAT', 'magw - France-WRF9k'],
  radii: [
    14.0,
    24.0,
    62.0,
    68.0,
    18.0,
    44.0,
    26.0,
    34.0,
    10.0,
    6.0,
    22.0,
    4.0,
    36.0,
    74.0,
    44.0,
    18.0,
    42.0,
    6.0,
    34.0,
    52.0,
    16.0,
    12.0,
    32.0,
    28.0,
    10.0,
    4.0,
    24.0,
    20.0,
    12.0,
    4.0,
    12.0,
    12.0,
    28.0,
    18.0,
    56.0,
    16.0,
    8.0,
    36.0,
    32.0,
    6.0,
    24.0,
    8.0,
    12.0,
    4.0,
    16.0,
    28.0,
    14.0,
    4.0,
    26.0,
    18.0,
    16.0,
    24.0,
    4.0,
    6.0,
    2.0,
    16.0,
    2.0,
    18.0,
    28.0,
    2.0,
    16.0,
    10.0,
    14.0,
    6.0,
    4.0,
    8.0,
    28.0,
    36.0,
    20.0,
    12.0,
    2.0,
    2.0,
    2.0,
    20.0,
    10.0,
    6.0,
    10.0,
    2.0,
    2.0,
    10.0,
    8.0,
    12.0,
    4.0,
    2.0,
    2.0,
    2.0,
    12.0,
    4.0,
    2.0,
    2.0,
    2.0,
    6.0,
    2.0,
    4.0,
    12.0,
    4.0,
    2.0,
    4.0,
    2.0,
    2.0,
    2.0,
    10.0,
    2.0,
    2.0,
    2.0,
    2.0,
    4.0,
    2.0,
    2.0,
    2.0,
    6.0,
    2.0,
    2.0,
    10.0,
    6.0,
    4.0,
    2.0,
    4.0,
    2.0,
    2.0,
    2.0,
    2.0,
    2.0,
    2.0,
    2.0,
    2.0,
  ],
  error: {
    _values: [
      0.06464477196548124,
      1.079202162020683,
      0.15123268906048004,
      0.22674977983867084,
      1.1489315095618124,
      0.23423974767066547,
      0.30131962912969,
      1.2921145496018491,
      0.10862321829545252,
      1.6944033188960752,
      1.3626380745313433,
      0.5228186364171776,
      0.12630947906660417,
      0.32891403370890604,
      0.9768164001469541,
      1.4215208980084357,
      1.0546355114293862,
      1.7841262879437023,
      0.04129119082995736,
      1.2775678072422734,
      0.9526409062742172,
      1.8410628635158202,
      0.5614944239891395,
      0.9284459715127067,
      1.3425108345854717,
      2.2640082740939587,
      0.23540973325971137,
      1.3129148979170728,
      0.5636427647091073,
      2.7736403548346056,
      1.288466172105316,
      1.236658704059475,
      2.065568871580699,
      1.5417261920393113,
      0.9301536044378311,
      0.1352879315512998,
      1.7095671919886186,
      0.031074715849433332,
      0.055389921764090744,
      1.5483904941336561,
      0.9324328893061384,
      1.3239670080758756,
      0.46257594825608983,
      2.666487504790858,
      1.0672401104406912,
      0.9611562687975801,
      1.0625218611991964,
      0.551486731927394,
      0.621510807780012,
      0.9141991329269614,
      2.313247901500139,
      0.9373168249534425,
      2.3146362623933285,
      1.9094096027141791,
      4.811403880101775,
      0.9799374009656567,
      1.969843198888782,
      0.6766405397423689,
      0.05729943525130965,
      8.190158348171193,
      2.055351992898302,
      0.16337142668154137,
      2.2534282122580027,
      1.9339335054128117,
      2.9117543297544435,
      1.3711958087725016,
      1.5748745643558433,
      1.1216214830760016,
      0.08584819667551713,
      1.1172355579798832,
      3.7201610389883584,
      1.7903234136180064,
      5.421382327856671,
      2.9908450258227948,
      1.6152413671510928,
      0.24336044395465706,
      0.7094427381957669,
      7.156467094091043,
      3.3669625758932895,
      2.4201143632935813,
      0.052584243971463884,
      0.7074140130795832,
      2.554442264188206,
      2.155985275010221,
      6.272689989575091,
      2.4490192766745915,
      0.6238281800843062,
      2.9880450149415676,
      0.3951131987414911,
      4.4679860229237125,
      1.6578167849524927,
      1.0302598015796658,
      3.0490773602953247,
      2.5413830113689952,
      3.5802481432067417,
      1.1564475666709875,
      2.2520651771413043,
      2.9849340371560156,
      3.8762137329029436,
      3.773776989740474,
      5.174651748548869,
      2.0691607414232394,
      5.606724512028538,
      3.8570793592950645,
      4.283357790993931,
      5.583276163035233,
      2.8553164693018473,
      2.2935428229347004,
      4.3750756428559585,
      4.256414092215294,
      2.0083174026489443,
      3.6946568342363317,
      4.495298691524511,
      0.9242221406490623,
      2.2363905946487916,
      4.666884076193517,
      5.364953849721996,
      3.6166686274906867,
      10.807055506000397,
      4.8084040116773155,
      2.5676723295226154,
      0.27805823698164644,
      5.0029679582893944,
      2.1334354758140606,
      5.508512675693075,
      5.220789068531559,
    ],
    attributes: { units: 'm.s-1' },
  },
};
