export const meanVerticalProfileMonthlyGraphData = {
  id: 'WIN-magw-MEANCOLSTD-month_2147',
  point: {
    name: 'test',
    id: '2147',
  },
  layer: 'WIND',
  type: 'MEANCOLSTD',
  name: 'Mean vertical profile',
  variables: {
    names: ['magw'],
    variables: [
      {
        name: 'magw',
        long_name: 'wind speed',
        hidden: false,
        units: 'm.s-1',
      },
    ],
  },
  visualization: {
    kwargs: {
      colVar: 'std',
      x2Name: 'magw_std',
      ylabel: 'Altitude [m above surface]',
      xName: 'magw_mean',
      xlabel: 'Wind speed [m.s-1]',
      yName: 'level',
    },
    web: {
      'plot_col_profile-image-uri-0':
        '/static/3.6.3/projects/dev-ma/MA/open-ocean/ENGIE-LNG/World/WIND/Visualization/WIN-magw-MEANCOLSTD-month/2147_WIN-magw-MEANCOLSTD-month_month%24%7BPER%7D__plot_col_profile.png',
      'data-uri-0':
        '/api/projects/671/get_visu_data/?stats_id=WIN-magw-MEANCOLSTD-month_2147',
      'netcdf-uri-0':
        '/static/3.6.3/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2147/WIND/WIN-magw-MEANCOLSTD-month_2147.nc',
      'excel-uri-0':
        '/static/3.6.3/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2147/WIND/WIN-magw-MEANCOLSTD-month_2147.xls',
      // eslint-disable-next-line
      "data-legend-0": "Monthly mean vertical profile of wind speed at point test ${PER}",
      'matlab-uri-0':
        '/static/3.6.3/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2147/WIND/WIN-magw-MEANCOLSTD-month_2147.mat',
      // eslint-disable-next-line
      "plot_col_profile-image-legend-0": "Monthly mean vertical profile of wind speed at point test ${PER}"
    },
    driver: {
      suffix: null,
      fileformat: '.png',
      // eslint-disable-next-line
      "path": "/mnt/glusterfs/projects/dev-ma/MA/open-ocean/ENGIE-LNG/World/WIND/Visualization/WIN-magw-MEANCOLSTD-month/2147_WIN-magw-MEANCOLSTD-month_month${PER}__plot_col_profile.png",
      method: 'plot_col_profile',
    },
  },
  periodicity: 'month',
  success: true,
  month: {
    values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    dimensions: ['month'],
    attributes: {
      vartype: 'i2',
      units: '',
      description: 'index of the month',
    },
  },
  magwStd: {
    values: [
      [
        1.9641630859669352,
        1.9771900705579848,
        1.9902170551490346,
        2.003244039740084,
        2.0162710243311337,
        2.0292980089221833,
        2.0423249935132333,
        2.055351978104283,
        2.0683789626953324,
        2.081405947286382,
        2.0944329318774315,
        2.107459916468481,
        2.1204869010595306,
        2.1335138856505806,
        2.14654087024163,
        2.1595678548326798,
        2.1654805748851995,
        2.1713932949377193,
        2.177306014990239,
        2.1832187350427588,
        2.189131455095279,
        2.1950441751477987,
        2.2009568952003185,
        2.206869615252838,
        2.212782335305358,
        2.2186950553578777,
        2.2246077754103974,
        2.230520495462917,
        2.2364332155154374,
        2.242345935567957,
        2.248258655620477,
        2.2541713756729966,
        2.2600840957255164,
        2.265996815778036,
        2.271909535830556,
        2.2778222558830756,
        2.2837349759355954,
        2.2896476959881156,
        2.2955604160406353,
        2.301473136093155,
        2.307385856145675,
        2.310844575653661,
        2.314303295161647,
        2.317762014669633,
        2.321220734177619,
        2.3246794536856052,
        2.3281381731935915,
        2.3315968927015773,
        2.3350556122095636,
        2.3385143317175494,
        2.3419730512255357,
        2.345431770733522,
        2.3488904902415078,
        2.352349209749494,
        2.35580792925748,
        2.359266648765466,
        2.3627253682734524,
        2.366184087781438,
        2.3696428072894244,
        2.3731015267974103,
        2.3765602463053965,
        2.380018965813383,
        2.3834776853213686,
        2.386936404829355,
        2.3903951243373407,
        2.393853843845327,
        2.3963078443898604,
        2.3987618449343944,
        2.401215845478928,
        2.403669846023462,
        2.4061238465679953,
        2.408577847112529,
        2.4110318476570627,
        2.4134858482015966,
        2.41593984874613,
        2.4183938492906636,
        2.4208478498351975,
        2.423301850379731,
        2.425755850924265,
        2.4282098514687984,
        2.4306638520133323,
        2.433117852557866,
        2.4355718531023993,
        2.4380258536469332,
        2.4404798541914667,
        2.4429338547360007,
        2.445387855280534,
        2.447841855825068,
        2.4502958563696016,
        2.4527498569141355,
        2.455203857458669,
        2.457107328160779,
        2.459010798862889,
        2.460914269564999,
        2.462817740267109,
        2.464721210969219,
        2.466624681671329,
        2.468528152373439,
        2.470431623075549,
        2.472335093777659,
        2.474238564479769,
        2.476142035181879,
        2.478045505883989,
        2.479948976586099,
        2.481852447288209,
        2.4837559179903193,
        2.485659388692429,
        2.487562859394539,
        2.4894663300966493,
        2.491369800798759,
        2.493273271500869,
        2.495176742202979,
        2.497080212905089,
        2.4989836836071992,
        2.500887154309309,
        2.502790625011419,
        2.5043458738172952,
        2.5059011226231713,
        2.5074563714290474,
        2.5090116202349235,
        2.5105668690407996,
        2.5121221178466757,
        2.513677366652552,
        2.515232615458428,
        2.516787864264304,
        2.51834311307018,
        2.519898361876056,
        2.5214536106819323,
        2.5230088594878084,
        2.5245641082936845,
        2.5261193570995606,
        2.5276746059054367,
        2.529229854711313,
        2.530785103517189,
        2.532340352323065,
        2.533895601128941,
        2.535450849934817,
        2.5370060987406933,
        2.5385613475465694,
        2.5401165963524455,
        2.5416718451583216,
        2.542986789287486,
        2.5443017334166504,
        2.545616677545815,
        2.5469316216749793,
        2.5482465658041438,
        2.549561509933308,
        2.5508764540624727,
        2.552191398191637,
        2.5535063423208015,
        2.554821286449966,
        2.5561362305791304,
        2.557451174708295,
        2.5587661188374593,
        2.5600810629666237,
        2.561396007095788,
        2.5627109512249526,
        2.564025895354117,
        2.5653408394832815,
        2.566655783612446,
        2.5679707277416104,
        2.569285671870775,
        2.5706006159999393,
        2.5719155601291037,
        2.573230504258268,
        2.5745454483874326,
        2.5756845048028016,
        2.576823561218171,
        2.57796261763354,
        2.5791016740489097,
        2.5802407304642787,
        2.581379786879648,
        2.5825188432950172,
        2.5836578997103867,
        2.5847969561257558,
        2.585936012541125,
        2.5870750689564943,
        2.5882141253718634,
        2.589353181787233,
        2.590492238202602,
        2.5916312946179714,
        2.5927703510333404,
        2.5939094074487095,
        2.595048463864079,
        2.596187520279448,
        2.5973265766948175,
        2.5984656331101865,
        2.599604689525556,
        2.600743745940925,
        2.6018828023562945,
        2.6030218587716636,
      ],
      [
        1.8504058117547253,
        1.8626783201676664,
        1.8749508285806074,
        1.8872233369935485,
        1.8994958454064896,
        1.9117683538194308,
        1.9240408622323717,
        1.9363133706453128,
        1.948585879058254,
        1.9608583874711951,
        1.973130895884136,
        1.9854034042970772,
        1.9976759127100183,
        2.0099484211229592,
        2.0222209295359006,
        2.0344934379488415,
        2.0400637144837326,
        2.045633991018624,
        2.051204267553515,
        2.0567745440884067,
        2.0623448206232977,
        2.067915097158189,
        2.0734853736930803,
        2.079055650227972,
        2.084625926762863,
        2.0901962032977544,
        2.0957664798326454,
        2.101336756367537,
        2.106907032902428,
        2.1124773094373195,
        2.1180475859722105,
        2.123617862507102,
        2.129188139041993,
        2.1347584155768846,
        2.1403286921117757,
        2.145898968646667,
        2.1514692451815582,
        2.1570395217164497,
        2.162609798251341,
        2.168180074786232,
        2.1737503513211234,
        2.177008754212682,
        2.18026715710424,
        2.1835255599957986,
        2.186783962887357,
        2.1900423657789156,
        2.1933007686704737,
        2.1965591715620323,
        2.199817574453591,
        2.203075977345149,
        2.2063343802367075,
        2.209592783128266,
        2.2128511860198246,
        2.2161095889113827,
        2.219367991802941,
        2.2226263946944997,
        2.2258847975860583,
        2.2291432004776164,
        2.232401603369175,
        2.2356600062607335,
        2.2389184091522916,
        2.24217681204385,
        2.2454352149354087,
        2.248693617826967,
        2.2519520207185253,
        2.255210423610084,
        2.257522297253417,
        2.2598341708967498,
        2.2621460445400827,
        2.2644579181834157,
        2.266769791826748,
        2.269081665470081,
        2.271393539113414,
        2.273705412756747,
        2.27601728640008,
        2.278329160043413,
        2.280641033686746,
        2.282952907330079,
        2.2852647809734115,
        2.2875766546167444,
        2.2898885282600774,
        2.2922004019034103,
        2.2945122755467433,
        2.2968241491900763,
        2.2991360228334092,
        2.301447896476742,
        2.3037597701200747,
        2.3060716437634077,
        2.3083835174067406,
        2.3106953910500736,
        2.3130072646934066,
        2.31480049320628,
        2.316593721719153,
        2.3183869502320267,
        2.3201801787449,
        2.321973407257773,
        2.3237666357706463,
        2.3255598642835196,
        2.3273530927963932,
        2.3291463213092665,
        2.3309395498221397,
        2.332732778335013,
        2.334526006847886,
        2.33631923536076,
        2.338112463873633,
        2.3399056923865063,
        2.3416989208993795,
        2.3434921494122527,
        2.3452853779251264,
        2.3470786064379996,
        2.348871834950873,
        2.350665063463746,
        2.3524582919766193,
        2.354251520489493,
        2.356044749002366,
        2.3578379775152394,
        2.3593031518939243,
        2.3607683262726096,
        2.3622335006512944,
        2.3636986750299798,
        2.3651638494086646,
        2.36662902378735,
        2.368094198166035,
        2.36955937254472,
        2.371024546923405,
        2.3724897213020903,
        2.3739548956807752,
        2.3754200700594605,
        2.3768852444381454,
        2.3783504188168307,
        2.3798155931955156,
        2.381280767574201,
        2.382745941952886,
        2.384211116331571,
        2.385676290710256,
        2.3871414650889413,
        2.388606639467626,
        2.3900718138463115,
        2.3915369882249964,
        2.3930021626036817,
        2.3944673369823666,
        2.3957061242684747,
        2.396944911554583,
        2.3981836988406906,
        2.3994224861267988,
        2.400661273412907,
        2.401900060699015,
        2.4031388479851232,
        2.404377635271231,
        2.405616422557339,
        2.4068552098434473,
        2.4080939971295554,
        2.4093327844156636,
        2.4105715717017713,
        2.4118103589878794,
        2.4130491462739876,
        2.4142879335600957,
        2.415526720846204,
        2.4167655081323116,
        2.4180042954184198,
        2.419243082704528,
        2.420481869990636,
        2.4217206572767442,
        2.422959444562852,
        2.42419823184896,
        2.4254370191350683,
        2.426510105492293,
        2.427583191849518,
        2.4286562782067427,
        2.4297293645639675,
        2.4308024509211927,
        2.4318755372784175,
        2.4329486236356423,
        2.434021709992867,
        2.435094796350092,
        2.4361678827073168,
        2.4372409690645416,
        2.4383140554217664,
        2.4393871417789916,
        2.4404602281362164,
        2.4415333144934412,
        2.442606400850666,
        2.443679487207891,
        2.4447525735651157,
        2.4458256599223405,
        2.4468987462795653,
        2.4479718326367905,
        2.4490449189940153,
        2.45011800535124,
        2.451191091708465,
        2.4522641780656897,
      ],
      [
        1.8574236573009937,
        1.8697427104058937,
        1.882061763510794,
        1.894380816615694,
        1.906699869720594,
        1.919018922825494,
        1.9313379759303941,
        1.9436570290352941,
        1.9559760821401941,
        1.9682951352450941,
        1.9806141883499944,
        1.9929332414548944,
        2.0052522945597944,
        2.0175713476646946,
        2.0298904007695944,
        2.0422094538744946,
        2.0478008562295296,
        2.0533922585845645,
        2.0589836609395995,
        2.0645750632946345,
        2.0701664656496694,
        2.0757578680047044,
        2.0813492703597394,
        2.0869406727147743,
        2.0925320750698093,
        2.0981234774248443,
        2.1037148797798793,
        2.1093062821349142,
        2.114897684489949,
        2.1204890868449837,
        2.1260804892000187,
        2.1316718915550537,
        2.1372632939100886,
        2.1428546962651236,
        2.1484460986201586,
        2.1540375009751935,
        2.1596289033302285,
        2.1652203056852635,
        2.1708117080402984,
        2.1764031103953334,
        2.1819945127503684,
        2.1852652734545077,
        2.188536034158647,
        2.1918067948627864,
        2.1950775555669257,
        2.1983483162710655,
        2.201619076975205,
        2.204889837679344,
        2.2081605983834836,
        2.211431359087623,
        2.2147021197917622,
        2.2179728804959016,
        2.221243641200041,
        2.2245144019041807,
        2.22778516260832,
        2.2310559233124594,
        2.2343266840165987,
        2.237597444720738,
        2.2408682054248774,
        2.2441389661290168,
        2.247409726833156,
        2.250680487537296,
        2.2539512482414352,
        2.2572220089455746,
        2.260492769649714,
        2.2637635303538532,
        2.266084172004749,
        2.2684048136556445,
        2.27072545530654,
        2.2730460969574353,
        2.275366738608331,
        2.2776873802592266,
        2.280008021910122,
        2.282328663561018,
        2.2846493052119135,
        2.2869699468628086,
        2.2892905885137043,
        2.2916112301646,
        2.2939318718154955,
        2.296252513466391,
        2.2985731551172868,
        2.300893796768182,
        2.3032144384190776,
        2.305535080069973,
        2.307855721720869,
        2.3101763633717645,
        2.31249700502266,
        2.3148176466735553,
        2.317138288324451,
        2.3194589299753465,
        2.321779571626242,
        2.3235796011341474,
        2.325379630642052,
        2.3271796601499575,
        2.3289796896578627,
        2.3307797191657675,
        2.332579748673673,
        2.3343797781815776,
        2.336179807689483,
        2.337979837197388,
        2.339779866705293,
        2.341579896213198,
        2.3433799257211034,
        2.3451799552290082,
        2.3469799847369135,
        2.3487800142448187,
        2.3505800437527236,
        2.352380073260629,
        2.354180102768534,
        2.355980132276439,
        2.357780161784344,
        2.359580191292249,
        2.361380220800154,
        2.3631802503080594,
        2.3649802798159643,
        2.3667803093238695,
        2.3682510405201036,
        2.369721771716338,
        2.371192502912572,
        2.3726632341088068,
        2.374133965305041,
        2.3756046965012754,
        2.3770754276975095,
        2.378546158893744,
        2.380016890089978,
        2.3814876212862126,
        2.3829583524824467,
        2.3844290836786812,
        2.3858998148749153,
        2.38737054607115,
        2.388841277267384,
        2.3903120084636185,
        2.3917827396598526,
        2.393253470856087,
        2.394724202052321,
        2.3961949332485557,
        2.39766566444479,
        2.3991363956410243,
        2.4006071268372584,
        2.402077858033493,
        2.403548589229727,
        2.4047920747381295,
        2.406035560246532,
        2.4072790457549345,
        2.4085225312633374,
        2.40976601677174,
        2.4110095022801423,
        2.412252987788545,
        2.4134964732969473,
        2.4147399588053498,
        2.4159834443137527,
        2.417226929822155,
        2.4184704153305576,
        2.41971390083896,
        2.4209573863473626,
        2.422200871855765,
        2.423444357364168,
        2.4246878428725704,
        2.425931328380973,
        2.4271748138893754,
        2.428418299397778,
        2.4296617849061803,
        2.4309052704145833,
        2.4321487559229857,
        2.433392241431388,
        2.4346357269397907,
        2.435712883082284,
        2.4367900392247765,
        2.4378671953672697,
        2.438944351509763,
        2.4400215076522556,
        2.4410986637947487,
        2.4421758199372414,
        2.4432529760797346,
        2.4443301322222277,
        2.4454072883647204,
        2.4464844445072136,
        2.4475616006497067,
        2.4486387567921994,
        2.4497159129346926,
        2.4507930690771857,
        2.4518702252196785,
        2.4529473813621716,
        2.4540245375046648,
        2.4551016936471575,
        2.4561788497896506,
        2.4572560059321433,
        2.4583331620746365,
        2.4594103182171296,
        2.4604874743596223,
        2.4615646305021155,
      ],
      [
        1.8302785050810189,
        1.8424175224840886,
        1.8545565398871584,
        1.8666955572902282,
        1.8788345746932977,
        1.8909735920963675,
        1.9031126094994373,
        1.915251626902507,
        1.9273906443055768,
        1.9395296617086466,
        1.9516686791117164,
        1.963807696514786,
        1.9759467139178557,
        1.9880857313209255,
        2.0002247487239955,
        2.012363766127065,
        2.017873453431549,
        2.0233831407360334,
        2.0288928280405174,
        2.034402515345002,
        2.039912202649486,
        2.04542188995397,
        2.050931577258454,
        2.056441264562938,
        2.0619509518674226,
        2.0674606391719066,
        2.0729703264763906,
        2.078480013780875,
        2.083989701085359,
        2.0894993883898434,
        2.0950090756943274,
        2.1005187629988114,
        2.1060284503032958,
        2.1115381376077798,
        2.117047824912264,
        2.122557512216748,
        2.128067199521232,
        2.1335768868257166,
        2.1390865741302005,
        2.144596261434685,
        2.150105948739169,
        2.1533289092029917,
        2.1565518696668144,
        2.159774830130637,
        2.1629977905944595,
        2.1662207510582823,
        2.169443711522105,
        2.1726666719859278,
        2.1758896324497505,
        2.1791125929135733,
        2.1823355533773956,
        2.1855585138412184,
        2.188781474305041,
        2.192004434768864,
        2.1952273952326866,
        2.1984503556965094,
        2.2016733161603317,
        2.2048962766241544,
        2.208119237087977,
        2.2113421975518,
        2.2145651580156227,
        2.2177881184794455,
        2.2210110789432678,
        2.2242340394070905,
        2.2274569998709133,
        2.230679960334736,
        2.2329666871753977,
        2.235253414016059,
        2.2375401408567206,
        2.2398268676973823,
        2.2421135945380435,
        2.244400321378705,
        2.2466870482193664,
        2.248973775060028,
        2.2512605019006897,
        2.253547228741351,
        2.2558339555820126,
        2.2581206824226743,
        2.2604074092633355,
        2.262694136103997,
        2.264980862944659,
        2.26726758978532,
        2.2695543166259817,
        2.2718410434666434,
        2.2741277703073046,
        2.2764144971479663,
        2.2787012239886275,
        2.280987950829289,
        2.283274677669951,
        2.285561404510612,
        2.2878481313512737,
        2.2896218544886313,
        2.291395577625989,
        2.2931693007633465,
        2.2949430239007045,
        2.296716747038062,
        2.2984904701754196,
        2.300264193312777,
        2.302037916450135,
        2.3038116395874924,
        2.3055853627248504,
        2.307359085862208,
        2.3091328089995655,
        2.310906532136923,
        2.3126802552742807,
        2.3144539784116382,
        2.3162277015489963,
        2.318001424686354,
        2.3197751478237114,
        2.321548870961069,
        2.3233225940984266,
        2.325096317235784,
        2.326870040373142,
        2.3286437635104997,
        2.3304174866478573,
        2.332191209785215,
        2.33364044711168,
        2.335089684438145,
        2.33653892176461,
        2.3379881590910747,
        2.33943739641754,
        2.340886633744005,
        2.34233587107047,
        2.343785108396935,
        2.3452343457233997,
        2.346683583049865,
        2.34813282037633,
        2.349582057702795,
        2.35103129502926,
        2.3524805323557247,
        2.35392976968219,
        2.355379007008655,
        2.35682824433512,
        2.358277481661585,
        2.3597267189880498,
        2.361175956314515,
        2.36262519364098,
        2.364074430967445,
        2.36552366829391,
        2.3669729056203748,
        2.36842214294684,
        2.369647455647291,
        2.3708727683477417,
        2.372098081048193,
        2.3733233937486435,
        2.3745487064490947,
        2.3757740191495453,
        2.3769993318499965,
        2.378224644550447,
        2.3794499572508983,
        2.3806752699513494,
        2.3819005826518,
        2.383125895352251,
        2.384351208052702,
        2.385576520753153,
        2.3868018334536036,
        2.3880271461540548,
        2.389252458854506,
        2.3904777715549566,
        2.3917030842554077,
        2.3929283969558584,
        2.3941537096563095,
        2.39537902235676,
        2.3966043350572113,
        2.397829647757662,
        2.399054960458113,
        2.4001163745983236,
        2.401177788738534,
        2.4022392028787447,
        2.4033006170189553,
        2.4043620311591662,
        2.405423445299377,
        2.4064848594395873,
        2.407546273579798,
        2.4086076877200084,
        2.409669101860219,
        2.4107305160004295,
        2.41179193014064,
        2.412853344280851,
        2.4139147584210616,
        2.414976172561272,
        2.4160375867014827,
        2.4170990008416933,
        2.418160414981904,
        2.4192218291221144,
        2.420283243262325,
        2.421344657402536,
        2.4224060715427465,
        2.423467485682957,
        2.4245288998231675,
        2.425590313963378,
      ],
      [
        1.9512008333936948,
        1.9641418480030544,
        1.977082862612414,
        1.9900238772217733,
        2.002964891831133,
        2.0159059064404925,
        2.028846921049852,
        2.0417879356592117,
        2.0547289502685713,
        2.067669964877931,
        2.0806109794872905,
        2.0935519940966496,
        2.106493008706009,
        2.119434023315369,
        2.1323750379247284,
        2.145316052534088,
        2.1511897523182486,
        2.1570634521024092,
        2.16293715188657,
        2.1688108516707305,
        2.174684551454891,
        2.180558251239052,
        2.1864319510232124,
        2.192305650807373,
        2.1981793505915337,
        2.2040530503756943,
        2.209926750159855,
        2.2158004499440156,
        2.221674149728176,
        2.2275478495123364,
        2.233421549296497,
        2.2392952490806577,
        2.2451689488648183,
        2.251042648648979,
        2.2569163484331396,
        2.2627900482173002,
        2.268663748001461,
        2.2745374477856215,
        2.280411147569782,
        2.2862848473539428,
        2.2921585471381034,
        2.2955944412523315,
        2.299030335366559,
        2.302466229480787,
        2.3059021235950152,
        2.309338017709243,
        2.312773911823471,
        2.3162098059376985,
        2.3196457000519266,
        2.3230815941661547,
        2.3265174882803823,
        2.3299533823946104,
        2.3333892765088384,
        2.336825170623066,
        2.340261064737294,
        2.343696958851522,
        2.34713285296575,
        2.350568747079978,
        2.354004641194206,
        2.3574405353084336,
        2.3608764294226616,
        2.3643123235368892,
        2.3677482176511173,
        2.3711841117653454,
        2.374620005879573,
        2.378055899993801,
        2.3804937056637336,
        2.3829315113336667,
        2.385369317003599,
        2.387807122673532,
        2.3902449283434644,
        2.3926827340133974,
        2.39512053968333,
        2.3975583453532625,
        2.3999961510231955,
        2.402433956693128,
        2.4048717623630607,
        2.4073095680329932,
        2.4097473737029262,
        2.412185179372859,
        2.4146229850427914,
        2.417060790712724,
        2.419498596382657,
        2.4219364020525895,
        2.424374207722522,
        2.426812013392455,
        2.4292498190623877,
        2.4316876247323203,
        2.434125430402253,
        2.436563236072186,
        2.4390010417421184,
        2.4408919507235733,
        2.4427828597050287,
        2.4446737686864837,
        2.446564677667939,
        2.448455586649394,
        2.4503464956308494,
        2.4522374046123043,
        2.4541283135937597,
        2.4560192225752147,
        2.4579101315566696,
        2.459801040538125,
        2.46169194951958,
        2.4635828585010353,
        2.4654737674824903,
        2.4673646764639456,
        2.4692555854454006,
        2.4711464944268555,
        2.473037403408311,
        2.474928312389766,
        2.4768192213712212,
        2.478710130352676,
        2.4806010393341316,
        2.4824919483155865,
        2.484382857297042,
        2.486273766278497,
        2.4878187514112695,
        2.489363736544042,
        2.4909087216768153,
        2.492453706809588,
        2.4939986919423607,
        2.4955436770751334,
        2.497088662207906,
        2.498633647340679,
        2.500178632473452,
        2.5017236176062245,
        2.503268602738997,
        2.50481358787177,
        2.506358573004543,
        2.5079035581373157,
        2.5094485432700884,
        2.510993528402861,
        2.5125385135356337,
        2.514083498668407,
        2.5156284838011795,
        2.517173468933952,
        2.518718454066725,
        2.5202634391994976,
        2.5218084243322707,
        2.5233534094650434,
        2.524898394597816,
        2.526204660915019,
        2.5275109272322216,
        2.5288171935494246,
        2.5301234598666276,
        2.53142972618383,
        2.532735992501033,
        2.534042258818236,
        2.5353485251354386,
        2.5366547914526416,
        2.5379610577698446,
        2.539267324087047,
        2.54057359040425,
        2.541879856721453,
        2.543186123038656,
        2.5444923893558586,
        2.5457986556730616,
        2.5471049219902646,
        2.548411188307467,
        2.54971745462467,
        2.551023720941873,
        2.5523299872590757,
        2.5536362535762787,
        2.5549425198934816,
        2.556248786210684,
        2.557555052527887,
        2.558686591880617,
        2.559818131233347,
        2.560949670586077,
        2.5620812099388064,
        2.5632127492915364,
        2.5643442886442664,
        2.565475827996996,
        2.566607367349726,
        2.5677389067024556,
        2.5688704460551857,
        2.5700019854079157,
        2.5711335247606453,
        2.5722650641133753,
        2.573396603466105,
        2.574528142818835,
        2.575659682171565,
        2.5767912215242945,
        2.5779227608770245,
        2.579054300229754,
        2.580185839582484,
        2.581317378935214,
        2.5824489182879438,
        2.583580457640674,
        2.5847119969934034,
        2.5858435363461334,
      ],
      [
        1.8925013167453963,
        1.9050530165853548,
        1.9176047164253132,
        1.9301564162652716,
        1.94270811610523,
        1.9552598159451884,
        1.9678115157851468,
        1.9803632156251054,
        1.9929149154650638,
        2.0054666153050222,
        2.0180183151449804,
        2.030570014984939,
        2.0431217148248972,
        2.055673414664856,
        2.0682251145048145,
        2.0807768143447727,
        2.086473810976512,
        2.0921708076082517,
        2.097867804239991,
        2.1035648008717307,
        2.10926179750347,
        2.1149587941352093,
        2.120655790766949,
        2.1263527873986883,
        2.132049784030428,
        2.1377467806621673,
        2.1434437772939066,
        2.1491407739256463,
        2.1548377705573856,
        2.1605347671891253,
        2.1662317638208646,
        2.171928760452604,
        2.1776257570843436,
        2.183322753716083,
        2.1890197503478226,
        2.194716746979562,
        2.200413743611301,
        2.206110740243041,
        2.21180773687478,
        2.21750473350652,
        2.223201730138259,
        2.2265342595345614,
        2.2298667889308637,
        2.233199318327166,
        2.236531847723468,
        2.239864377119771,
        2.243196906516073,
        2.2465294359123753,
        2.2498619653086775,
        2.25319449470498,
        2.256527024101282,
        2.2598595534975843,
        2.2631920828938865,
        2.266524612290189,
        2.2698571416864914,
        2.2731896710827937,
        2.276522200479096,
        2.279854729875398,
        2.2831872592717004,
        2.2865197886680027,
        2.289852318064305,
        2.2931848474606076,
        2.29651737685691,
        2.299849906253212,
        2.3031824356495143,
        2.3065149650458165,
        2.3088794322812536,
        2.311243899516691,
        2.313608366752128,
        2.315972833987565,
        2.318337301223002,
        2.3207017684584397,
        2.3230662356938767,
        2.3254307029293138,
        2.3277951701647512,
        2.3301596374001883,
        2.3325241046356253,
        2.3348885718710624,
        2.3372530391065,
        2.339617506341937,
        2.341981973577374,
        2.344346440812811,
        2.3467109080482484,
        2.3490753752836855,
        2.3514398425191225,
        2.35380430975456,
        2.356168776989997,
        2.358533244225434,
        2.360897711460871,
        2.3632621786963086,
        2.3656266459317457,
        2.3674606692039815,
        2.369294692476217,
        2.3711287157484526,
        2.372962739020688,
        2.374796762292924,
        2.376630785565159,
        2.378464808837395,
        2.3802988321096303,
        2.382132855381866,
        2.3839668786541015,
        2.3858009019263373,
        2.3876349251985727,
        2.3894689484708085,
        2.391302971743044,
        2.3931369950152797,
        2.394971018287515,
        2.396805041559751,
        2.398639064831986,
        2.400473088104222,
        2.4023071113764574,
        2.404141134648693,
        2.4059751579209285,
        2.4078091811931643,
        2.4096432044653997,
        2.4114772277376355,
        2.4129757338617024,
        2.414474239985769,
        2.4159727461098357,
        2.4174712522339026,
        2.418969758357969,
        2.420468264482036,
        2.4219667706061023,
        2.423465276730169,
        2.424963782854236,
        2.4264622889783025,
        2.4279607951023694,
        2.4294593012264363,
        2.4309578073505027,
        2.4324563134745696,
        2.4339548195986365,
        2.435453325722703,
        2.43695183184677,
        2.4384503379708367,
        2.439948844094903,
        2.44144735021897,
        2.4429458563430364,
        2.4444443624671033,
        2.44594286859117,
        2.4474413747152366,
        2.4489398808393035,
        2.4502068497145815,
        2.45147381858986,
        2.452740787465138,
        2.454007756340416,
        2.455274725215694,
        2.4565416940909723,
        2.4578086629662503,
        2.4590756318415283,
        2.4603426007168068,
        2.4616095695920848,
        2.4628765384673628,
        2.4641435073426408,
        2.465410476217919,
        2.466677445093197,
        2.467944413968475,
        2.469211382843753,
        2.4704783517190316,
        2.4717453205943096,
        2.4730122894695876,
        2.474279258344866,
        2.475546227220144,
        2.476813196095422,
        2.4780801649707,
        2.4793471338459785,
        2.4806141027212565,
        2.4817116010814155,
        2.4828090994415746,
        2.4839065978017336,
        2.4850040961618927,
        2.4861015945220517,
        2.4871990928822107,
        2.48829659124237,
        2.489394089602529,
        2.490491587962688,
        2.491589086322847,
        2.492686584683006,
        2.493784083043165,
        2.4948815814033236,
        2.4959790797634827,
        2.4970765781236417,
        2.4981740764838007,
        2.49927157484396,
        2.500369073204119,
        2.501466571564278,
        2.502564069924437,
        2.503661568284596,
        2.504759066644755,
        2.505856565004914,
        2.506954063365073,
        2.508051561725232,
      ],
      [
        1.8979770270682255,
        1.9105650436450732,
        1.9231530602219207,
        1.9357410767987684,
        1.948329093375616,
        1.9609171099524636,
        1.9735051265293113,
        1.986093143106159,
        1.9986811596830065,
        2.0112691762598542,
        2.0238571928367017,
        2.0364452094135497,
        2.049033225990397,
        2.0616212425672447,
        2.0742092591440926,
        2.08679727572094,
        2.092510755883227,
        2.098224236045514,
        2.103937716207801,
        2.1096511963700877,
        2.115364676532374,
        2.121078156694661,
        2.126791636856948,
        2.132505117019235,
        2.1382185971815217,
        2.1439320773438086,
        2.1496455575060955,
        2.1553590376683824,
        2.161072517830669,
        2.166785997992956,
        2.1724994781552427,
        2.1782129583175296,
        2.1839264384798165,
        2.1896399186421034,
        2.1953533988043903,
        2.201066878966677,
        2.206780359128964,
        2.2124938392912505,
        2.2182073194535374,
        2.2239207996158243,
        2.2296342797781112,
        2.2329764514216635,
        2.2363186230652152,
        2.2396607947087674,
        2.243002966352319,
        2.2463451379958714,
        2.2496873096394236,
        2.2530294812829754,
        2.2563716529265276,
        2.2597138245700794,
        2.2630559962136316,
        2.266398167857184,
        2.2697403395007356,
        2.273082511144288,
        2.2764246827878396,
        2.279766854431392,
        2.283109026074944,
        2.286451197718496,
        2.289793369362048,
        2.2931355410056,
        2.296477712649152,
        2.2998198842927042,
        2.303162055936256,
        2.306504227579808,
        2.30984639922336,
        2.313188570866912,
        2.315559879385647,
        2.317931187904382,
        2.3203024964231167,
        2.3226738049418514,
        2.3250451134605865,
        2.327416421979321,
        2.329787730498056,
        2.332159039016791,
        2.3345303475355257,
        2.3369016560542604,
        2.3392729645729955,
        2.34164427309173,
        2.344015581610465,
        2.3463868901291995,
        2.3487581986479347,
        2.3511295071666694,
        2.353500815685404,
        2.355872124204139,
        2.358243432722874,
        2.3606147412416085,
        2.3629860497603437,
        2.3653573582790783,
        2.367728666797813,
        2.370099975316548,
        2.372471283835283,
        2.374310613619105,
        2.3761499434029263,
        2.3779892731867482,
        2.3798286029705698,
        2.3816679327543917,
        2.383507262538213,
        2.385346592322035,
        2.3871859221058567,
        2.3890252518896786,
        2.3908645816735,
        2.392703911457322,
        2.3945432412411436,
        2.3963825710249655,
        2.398221900808787,
        2.400061230592609,
        2.4019005603764305,
        2.4037398901602525,
        2.405579219944074,
        2.407418549727896,
        2.4092578795117174,
        2.4110972092955394,
        2.412936539079361,
        2.414775868863183,
        2.4166151986470044,
        2.4184545284308263,
        2.4199573702905566,
        2.421460212150287,
        2.422963054010017,
        2.4244658958697474,
        2.4259687377294776,
        2.427471579589208,
        2.428974421448938,
        2.4304772633086684,
        2.4319801051683987,
        2.433482947028129,
        2.434985788887859,
        2.4364886307475895,
        2.4379914726073197,
        2.43949431446705,
        2.4409971563267803,
        2.4424999981865105,
        2.444002840046241,
        2.445505681905971,
        2.4470085237657013,
        2.4485113656254316,
        2.450014207485162,
        2.451517049344892,
        2.4530198912046224,
        2.4545227330643526,
        2.456025574924083,
        2.4572962096116315,
        2.45856684429918,
        2.459837478986729,
        2.461108113674278,
        2.4623787483618265,
        2.463649383049375,
        2.4649200177369237,
        2.4661906524244728,
        2.4674612871120214,
        2.46873192179957,
        2.4700025564871186,
        2.4712731911746673,
        2.4725438258622163,
        2.473814460549765,
        2.4750850952373136,
        2.476355729924862,
        2.477626364612411,
        2.47889699929996,
        2.4801676339875085,
        2.481438268675057,
        2.4827089033626057,
        2.4839795380501544,
        2.4852501727377034,
        2.486520807425252,
        2.4877914421128007,
        2.4888921159439867,
        2.489992789775173,
        2.491093463606359,
        2.492194137437545,
        2.4932948112687314,
        2.4943954850999175,
        2.4954961589311035,
        2.4965968327622896,
        2.4976975065934757,
        2.4987981804246617,
        2.499898854255848,
        2.500999528087034,
        2.5021002019182204,
        2.5032008757494064,
        2.5043015495805925,
        2.5054022234117785,
        2.5065028972429646,
        2.5076035710741507,
        2.5087042449053367,
        2.509804918736523,
        2.5109055925677093,
        2.5120062663988953,
        2.5131069402300814,
        2.5142076140612675,
        2.5153082878924535,
      ],
      [
        1.9305322160683054,
        1.9433361495150365,
        1.9561400829617677,
        1.9689440164084988,
        1.98174794985523,
        1.994551883301961,
        2.0073558167486922,
        2.020159750195423,
        2.0329636836421545,
        2.0457676170888854,
        2.058571550535617,
        2.0713754839823477,
        2.084179417429079,
        2.09698335087581,
        2.1097872843225414,
        2.1225912177692723,
        2.1284026988155946,
        2.1342141798619165,
        2.140025660908239,
        2.145837141954561,
        2.151648623000883,
        2.1574601040472055,
        2.163271585093528,
        2.1690830661398497,
        2.174894547186172,
        2.1807060282324944,
        2.1865175092788163,
        2.1923289903251386,
        2.198140471371461,
        2.203951952417783,
        2.209763433464105,
        2.2155749145104275,
        2.22138639555675,
        2.227197876603072,
        2.233009357649394,
        2.2388208386957165,
        2.2446323197420384,
        2.2504438007883607,
        2.256255281834683,
        2.262066762881005,
        2.2678782439273273,
        2.2712777424130777,
        2.2746772408988276,
        2.278076739384578,
        2.281476237870328,
        2.2848757363560783,
        2.2882752348418287,
        2.2916747333275787,
        2.295074231813329,
        2.298473730299079,
        2.3018732287848294,
        2.3052727272705797,
        2.3086722257563297,
        2.31207172424208,
        2.31547122272783,
        2.3188707212135804,
        2.322270219699331,
        2.3256697181850807,
        2.329069216670831,
        2.332468715156581,
        2.3358682136423314,
        2.339267712128082,
        2.3426672106138318,
        2.346066709099582,
        2.349466207585332,
        2.3528657060710825,
        2.3552776886316544,
        2.3576896711922264,
        2.3601016537527983,
        2.3625136363133703,
        2.3649256188739423,
        2.367337601434514,
        2.369749583995086,
        2.372161566555658,
        2.37457354911623,
        2.376985531676802,
        2.379397514237374,
        2.381809496797946,
        2.384221479358518,
        2.38663346191909,
        2.389045444479662,
        2.391457427040234,
        2.3938694096008057,
        2.3962813921613777,
        2.3986933747219497,
        2.4011053572825216,
        2.4035173398430936,
        2.4059293224036655,
        2.4083413049642375,
        2.4107532875248094,
        2.4131652700853814,
        2.415036149107098,
        2.4169070281288145,
        2.418777907150531,
        2.4206487861722477,
        2.422519665193964,
        2.4243905442156803,
        2.426261423237397,
        2.4281323022591135,
        2.43000318128083,
        2.4318740603025466,
        2.433744939324263,
        2.4356158183459797,
        2.437486697367696,
        2.4393575763894124,
        2.441228455411129,
        2.4430993344328455,
        2.444970213454562,
        2.4468410924762787,
        2.4487119714979952,
        2.450582850519712,
        2.452453729541428,
        2.4543246085631445,
        2.456195487584861,
        2.4580663666065776,
        2.459937245628294,
        2.461465865092328,
        2.462994484556362,
        2.464523104020395,
        2.466051723484429,
        2.467580342948463,
        2.4691089624124967,
        2.47063758187653,
        2.472166201340564,
        2.4736948208045977,
        2.4752234402686315,
        2.476752059732665,
        2.4782806791966987,
        2.4798092986607325,
        2.4813379181247663,
        2.4828665375887997,
        2.4843951570528335,
        2.4859237765168674,
        2.487452395980901,
        2.4889810154449346,
        2.4905096349089684,
        2.492038254373002,
        2.493566873837036,
        2.4950954933010694,
        2.496624112765103,
        2.498152732229137,
        2.4994451615705793,
        2.500737590912022,
        2.502030020253464,
        2.503322449594907,
        2.504614878936349,
        2.505907308277792,
        2.507199737619234,
        2.508492166960677,
        2.509784596302119,
        2.5110770256435617,
        2.512369454985004,
        2.5136618843264467,
        2.514954313667889,
        2.5162467430093316,
        2.517539172350774,
        2.5188316016922165,
        2.520124031033659,
        2.5214164603751015,
        2.5227088897165437,
        2.5240013190579864,
        2.5252937483994287,
        2.5265861777408714,
        2.5278786070823136,
        2.5291710364237563,
        2.5304634657651985,
        2.531583018984328,
        2.5327025722034575,
        2.533822125422587,
        2.5349416786417165,
        2.5360612318608458,
        2.5371807850799755,
        2.5383003382991047,
        2.5394198915182344,
        2.5405394447373637,
        2.5416589979564934,
        2.5427785511756227,
        2.5438981043947524,
        2.5450176576138817,
        2.5461372108330114,
        2.5472567640521406,
        2.5483763172712703,
        2.5494958704903996,
        2.5506154237095293,
        2.5517349769286586,
        2.5528545301477883,
        2.5539740833669176,
        2.5550936365860473,
        2.5562131898051765,
        2.5573327430243062,
        2.5584522962434355,
      ],
      [
        2.0375756581491786,
        2.051089539400409,
        2.0646034206516393,
        2.0781173019028696,
        2.0916311831541,
        2.1051450644053302,
        2.1186589456565605,
        2.132172826907791,
        2.1456867081590207,
        2.159200589410251,
        2.1727144706614814,
        2.1862283519127117,
        2.199742233163942,
        2.2132561144151723,
        2.2267699956664027,
        2.240283876917633,
        2.2464175908330097,
        2.2525513047483865,
        2.2586850186637633,
        2.26481873257914,
        2.270952446494517,
        2.277086160409894,
        2.2832198743252707,
        2.2893535882406475,
        2.2954873021560243,
        2.301621016071401,
        2.3077547299867778,
        2.3138884439021545,
        2.3200221578175317,
        2.3261558717329085,
        2.3322895856482853,
        2.338423299563662,
        2.3445570134790388,
        2.3506907273944155,
        2.3568244413097923,
        2.362958155225169,
        2.3690918691405463,
        2.375225583055923,
        2.3813592969712998,
        2.3874930108866765,
        2.3936267248020533,
        2.3972147174327003,
        2.4008027100633473,
        2.4043907026939944,
        2.407978695324641,
        2.411566687955288,
        2.415154680585935,
        2.418742673216582,
        2.422330665847229,
        2.425918658477876,
        2.4295066511085226,
        2.4330946437391696,
        2.4366826363698166,
        2.4402706290004637,
        2.4438586216311107,
        2.4474466142617577,
        2.4510346068924043,
        2.4546225995230513,
        2.4582105921536983,
        2.4617985847843453,
        2.4653865774149923,
        2.4689745700456394,
        2.472562562676286,
        2.476150555306933,
        2.47973854793758,
        2.483326540568227,
        2.4858722618529567,
        2.4884179831376865,
        2.4909637044224167,
        2.4935094257071464,
        2.496055146991876,
        2.498600868276606,
        2.501146589561336,
        2.503692310846066,
        2.5062380321307955,
        2.5087837534155253,
        2.5113294747002555,
        2.513875195984985,
        2.516420917269715,
        2.5189666385544447,
        2.521512359839175,
        2.5240580811239046,
        2.5266038024086344,
        2.529149523693364,
        2.5316952449780943,
        2.534240966262824,
        2.5367866875475538,
        2.5393324088322835,
        2.5418781301170137,
        2.5444238514017434,
        2.546969572686473,
        2.5489441875218346,
        2.550918802357196,
        2.5528934171925575,
        2.554868032027919,
        2.55684264686328,
        2.5588172616986413,
        2.5607918765340028,
        2.562766491369364,
        2.5647411062047256,
        2.566715721040087,
        2.5686903358754485,
        2.57066495071081,
        2.572639565546171,
        2.5746141803815323,
        2.5765887952168938,
        2.578563410052255,
        2.5805380248876166,
        2.582512639722978,
        2.5844872545583395,
        2.586461869393701,
        2.588436484229062,
        2.5904110990644234,
        2.592385713899785,
        2.594360328735146,
        2.5963349435705076,
        2.5979483213657932,
        2.599561699161079,
        2.6011750769563644,
        2.60278845475165,
        2.6044018325469356,
        2.606015210342221,
        2.6076285881375068,
        2.6092419659327923,
        2.610855343728078,
        2.6124687215233635,
        2.614082099318649,
        2.6156954771139347,
        2.61730885490922,
        2.6189222327045054,
        2.620535610499791,
        2.6221489882950766,
        2.623762366090362,
        2.6253757438856478,
        2.6269891216809333,
        2.628602499476219,
        2.6302158772715045,
        2.63182925506679,
        2.6334426328620757,
        2.6350560106573613,
        2.636669388452647,
        2.638033479942073,
        2.6393975714314983,
        2.6407616629209243,
        2.64212575441035,
        2.6434898458997758,
        2.6448539373892013,
        2.6462180288786272,
        2.6475821203680527,
        2.6489462118574787,
        2.650310303346904,
        2.65167439483633,
        2.6530384863257557,
        2.6544025778151816,
        2.655766669304607,
        2.657130760794033,
        2.6584948522834586,
        2.6598589437728846,
        2.66122303526231,
        2.662587126751736,
        2.6639512182411615,
        2.6653153097305875,
        2.666679401220013,
        2.668043492709439,
        2.6694075841988645,
        2.6707716756882904,
        2.6719533054835947,
        2.673134935278899,
        2.6743165650742027,
        2.675498194869507,
        2.676679824664811,
        2.6778614544601154,
        2.679043084255419,
        2.6802247140507234,
        2.6814063438460276,
        2.682587973641332,
        2.6837696034366356,
        2.68495123323194,
        2.686132863027244,
        2.6873144928225483,
        2.688496122617852,
        2.6896777524131563,
        2.6908593822084605,
        2.6920410120037648,
        2.6932226417990686,
        2.694404271594373,
        2.695585901389677,
        2.6967675311849812,
        2.697949160980285,
        2.6991307907755893,
        2.7003124205708935,
      ],
      [
        2.1262678459660664,
        2.14036996338378,
        2.1544720808014937,
        2.168574198219207,
        2.1826763156369204,
        2.196778433054634,
        2.2108805504723477,
        2.2249826678900613,
        2.2390847853077744,
        2.253186902725488,
        2.2672890201432017,
        2.2813911375609153,
        2.295493254978629,
        2.309595372396342,
        2.3236974898140557,
        2.3377996072317693,
        2.3442003112362855,
        2.3506010152408017,
        2.357001719245318,
        2.3634024232498345,
        2.3698031272543507,
        2.376203831258867,
        2.382604535263383,
        2.3890052392678993,
        2.3954059432724155,
        2.401806647276932,
        2.4082073512814484,
        2.4146080552859646,
        2.4210087592904808,
        2.427409463294997,
        2.433810167299513,
        2.44021087130403,
        2.446611575308546,
        2.453012279313062,
        2.4594129833175784,
        2.4658136873220946,
        2.472214391326611,
        2.4786150953311274,
        2.4850157993356436,
        2.49141650334016,
        2.497817207344676,
        2.501561379165534,
        2.5053055509863915,
        2.5090497228072492,
        2.512793894628107,
        2.5165380664489647,
        2.5202822382698225,
        2.52402641009068,
        2.527770581911538,
        2.5315147537323957,
        2.5352589255532534,
        2.539003097374111,
        2.542747269194969,
        2.546491441015827,
        2.5502356128366848,
        2.5539797846575425,
        2.5577239564784002,
        2.561468128299258,
        2.5652123001201157,
        2.5689564719409734,
        2.572700643761831,
        2.576444815582689,
        2.5801889874035466,
        2.5839331592244044,
        2.587677331045262,
        2.59142150286612,
        2.5940780350497783,
        2.5967345672334368,
        2.5993910994170952,
        2.6020476316007537,
        2.6047041637844126,
        2.607360695968071,
        2.6100172281517295,
        2.612673760335388,
        2.6153302925190465,
        2.617986824702705,
        2.6206433568863634,
        2.623299889070022,
        2.625956421253681,
        2.6286129534373393,
        2.6312694856209977,
        2.633926017804656,
        2.6365825499883146,
        2.639239082171973,
        2.6418956143556316,
        2.64455214653929,
        2.647208678722949,
        2.6498652109066074,
        2.652521743090266,
        2.6551782752739244,
        2.657834807457583,
        2.659895373903695,
        2.6619559403498063,
        2.6640165067959183,
        2.6660770732420302,
        2.668137639688142,
        2.6701982061342537,
        2.6722587725803657,
        2.6743193390264777,
        2.676379905472589,
        2.678440471918701,
        2.680501038364813,
        2.682561604810925,
        2.6846221712570366,
        2.6866827377031486,
        2.6887433041492605,
        2.6908038705953725,
        2.692864437041484,
        2.694925003487596,
        2.696985569933708,
        2.6990461363798195,
        2.7011067028259315,
        2.7031672692720434,
        2.7052278357181554,
        2.707288402164267,
        2.709348968610379,
        2.7110325739851246,
        2.712716179359871,
        2.7143997847346166,
        2.7160833901093624,
        2.7177669954841086,
        2.7194506008588544,
        2.7211342062336006,
        2.7228178116083464,
        2.724501416983092,
        2.7261850223578383,
        2.727868627732584,
        2.72955223310733,
        2.731235838482076,
        2.732919443856822,
        2.7346030492315676,
        2.736286654606314,
        2.7379702599810596,
        2.7396538653558054,
        2.7413374707305516,
        2.7430210761052973,
        2.7447046814800435,
        2.7463882868547893,
        2.748071892229535,
        2.7497554976042813,
        2.751439102979027,
        2.752862571040849,
        2.754286039102672,
        2.755709507164494,
        2.757132975226316,
        2.758556443288138,
        2.759979911349961,
        2.761403379411783,
        2.762826847473605,
        2.7642503155354277,
        2.76567378359725,
        2.767097251659072,
        2.768520719720894,
        2.7699441877827167,
        2.771367655844539,
        2.772791123906361,
        2.774214591968183,
        2.7756380600300057,
        2.777061528091828,
        2.77848499615365,
        2.7799084642154726,
        2.7813319322772947,
        2.782755400339117,
        2.784178868400939,
        2.7856023364627616,
        2.7870258045245837,
        2.78825886864642,
        2.7894919327682564,
        2.7907249968900922,
        2.7919580610119286,
        2.793191125133765,
        2.794424189255601,
        2.7956572533774375,
        2.7968903174992734,
        2.7981233816211097,
        2.799356445742946,
        2.8005895098647824,
        2.8018225739866187,
        2.8030556381084546,
        2.804288702230291,
        2.8055217663521272,
        2.8067548304739636,
        2.8079878945958,
        2.8092209587176358,
        2.810454022839472,
        2.8116870869613084,
        2.8129201510831447,
        2.814153215204981,
        2.815386279326817,
        2.8166193434486533,
        2.8178524075704896,
      ],
      [
        1.8940472057952045,
        1.906609158486861,
        1.9191711111785175,
        1.931733063870174,
        1.9442950165618307,
        1.9568569692534872,
        1.9694189219451437,
        1.9819808746368002,
        1.9945428273284567,
        2.0071047800201134,
        2.01966673271177,
        2.0322286854034264,
        2.044790638095083,
        2.0573525907867394,
        2.069914543478396,
        2.0824764961700524,
        2.0881781463915314,
        2.0938797966130105,
        2.0995814468344896,
        2.1052830970559686,
        2.1109847472774477,
        2.1166863974989267,
        2.122388047720406,
        2.128089697941885,
        2.133791348163364,
        2.139492998384843,
        2.145194648606322,
        2.150896298827801,
        2.1565979490492806,
        2.1622995992707597,
        2.1680012494922387,
        2.1737028997137178,
        2.179404549935197,
        2.185106200156676,
        2.190807850378155,
        2.196509500599634,
        2.202211150821113,
        2.207912801042592,
        2.213614451264071,
        2.2193161014855503,
        2.2250177517070293,
        2.2283530032788232,
        2.2316882548506167,
        2.2350235064224107,
        2.238358757994204,
        2.241694009565998,
        2.2450292611377916,
        2.2483645127095855,
        2.251699764281379,
        2.255035015853173,
        2.2583702674249664,
        2.2617055189967603,
        2.265040770568554,
        2.2683760221403477,
        2.271711273712141,
        2.275046525283935,
        2.2783817768557286,
        2.2817170284275226,
        2.285052279999316,
        2.28838753157111,
        2.2917227831429035,
        2.2950580347146974,
        2.298393286286491,
        2.301728537858285,
        2.3050637894300783,
        2.308399041001872,
        2.3107654396515573,
        2.313131838301243,
        2.315498236950928,
        2.3178646356006136,
        2.3202310342502988,
        2.3225974328999843,
        2.3249638315496695,
        2.327330230199355,
        2.32969662884904,
        2.3320630274987257,
        2.334429426148411,
        2.3367958247980964,
        2.3391622234477816,
        2.341528622097467,
        2.3438950207471523,
        2.346261419396838,
        2.348627818046523,
        2.3509942166962086,
        2.3533606153458937,
        2.3557270139955793,
        2.3580934126452644,
        2.36045981129495,
        2.362826209944635,
        2.3651926085943207,
        2.367559007244006,
        2.3693945286375206,
        2.3712300500310355,
        2.3730655714245503,
        2.374901092818065,
        2.37673661421158,
        2.378572135605095,
        2.3804076569986097,
        2.3822431783921245,
        2.3840786997856394,
        2.385914221179154,
        2.387749742572669,
        2.389585263966184,
        2.391420785359699,
        2.393256306753214,
        2.395091828146729,
        2.3969273495402437,
        2.3987628709337585,
        2.4005983923272733,
        2.402433913720788,
        2.404269435114303,
        2.406104956507818,
        2.4079404779013327,
        2.4097759992948475,
        2.4116115206883624,
        2.413447042081877,
        2.414946772260156,
        2.416446502438435,
        2.4179462326167136,
        2.4194459627949927,
        2.4209456929732713,
        2.4224454231515504,
        2.423945153329829,
        2.425444883508108,
        2.426944613686387,
        2.428444343864666,
        2.4299440740429445,
        2.4314438042212236,
        2.4329435343995023,
        2.4344432645777814,
        2.43594299475606,
        2.437442724934339,
        2.4389424551126178,
        2.440442185290897,
        2.4419419154691755,
        2.4434416456474546,
        2.4449413758257332,
        2.4464411060040123,
        2.447940836182291,
        2.44944056636057,
        2.4509402965388487,
        2.452208300337217,
        2.4534763041355854,
        2.4547443079339533,
        2.4560123117323216,
        2.45728031553069,
        2.4585483193290583,
        2.4598163231274266,
        2.4610843269257945,
        2.462352330724163,
        2.463620334522531,
        2.4648883383208995,
        2.466156342119268,
        2.4674243459176357,
        2.468692349716004,
        2.4699603535143724,
        2.4712283573127407,
        2.472496361111109,
        2.473764364909477,
        2.4750323687078453,
        2.4763003725062136,
        2.477568376304582,
        2.4788363801029503,
        2.480104383901318,
        2.4813723876996865,
        2.482640391498055,
        2.483738786349372,
        2.484837181200689,
        2.485935576052006,
        2.4870339709033233,
        2.48813236575464,
        2.4892307606059574,
        2.490329155457274,
        2.4914275503085914,
        2.4925259451599087,
        2.4936243400112255,
        2.4947227348625427,
        2.49582112971386,
        2.4969195245651767,
        2.498017919416494,
        2.4991163142678112,
        2.500214709119128,
        2.5013131039704453,
        2.5024114988217625,
        2.5035098936730793,
        2.5046082885243965,
        2.5057066833757133,
        2.5068050782270306,
        2.507903473078348,
        2.5090018679296646,
        2.510100262780982,
      ],
      [
        1.9091946603739638,
        1.9218570760357274,
        1.9345194916974913,
        1.947181907359255,
        1.9598443230210187,
        1.9725067386827824,
        1.9851691543445462,
        1.9978315700063098,
        2.0104939856680737,
        2.0231564013298375,
        2.035818816991601,
        2.048481232653365,
        2.0611436483151286,
        2.0738060639768925,
        2.086468479638656,
        2.0991308953004197,
        2.1048781439037603,
        2.1106253925071012,
        2.1163726411104418,
        2.1221198897137827,
        2.1278671383171233,
        2.1336143869204642,
        2.1393616355238048,
        2.1451088841271457,
        2.1508561327304863,
        2.156603381333827,
        2.1623506299371678,
        2.1680978785405083,
        2.1738451271438493,
        2.17959237574719,
        2.1853396243505308,
        2.1910868729538713,
        2.196834121557212,
        2.2025813701605528,
        2.2083286187638933,
        2.2140758673672343,
        2.219823115970575,
        2.2255703645739158,
        2.2313176131772563,
        2.2370648617805973,
        2.242812110383938,
        2.246174035299214,
        2.2495359602144904,
        2.2528978851297667,
        2.256259810045043,
        2.2596217349603194,
        2.2629836598755957,
        2.2663455847908724,
        2.2697075097061488,
        2.273069434621425,
        2.2764313595367014,
        2.2797932844519777,
        2.283155209367254,
        2.2865171342825303,
        2.2898790591978067,
        2.293240984113083,
        2.2966029090283593,
        2.2999648339436356,
        2.303326758858912,
        2.3066886837741887,
        2.310050608689465,
        2.3134125336047413,
        2.3167744585200176,
        2.320136383435294,
        2.3234983083505703,
        2.3268602332658466,
        2.329245556953911,
        2.3316308806419754,
        2.3340162043300396,
        2.336401528018104,
        2.3387868517061685,
        2.3411721753942327,
        2.343557499082297,
        2.3459428227703616,
        2.348328146458426,
        2.35071347014649,
        2.3530987938345547,
        2.355484117522619,
        2.357869441210683,
        2.3602547648987473,
        2.362640088586812,
        2.365025412274876,
        2.3674107359629404,
        2.369796059651005,
        2.3721813833390692,
        2.3745667070271335,
        2.376952030715198,
        2.3793373544032623,
        2.3817226780913265,
        2.384108001779391,
        2.3864933254674554,
        2.3883435262611727,
        2.3901937270548905,
        2.392043927848608,
        2.3938941286423256,
        2.395744329436043,
        2.3975945302297608,
        2.399444731023478,
        2.401294931817196,
        2.4031451326109132,
        2.4049953334046306,
        2.4068455341983483,
        2.4086957349920657,
        2.4105459357857835,
        2.412396136579501,
        2.4142463373732186,
        2.416096538166936,
        2.4179467389606533,
        2.419796939754371,
        2.4216471405480884,
        2.423497341341806,
        2.4253475421355235,
        2.4271977429292413,
        2.4290479437229586,
        2.4308981445166764,
        2.4327483453103937,
        2.4342600694319527,
        2.4357717935535113,
        2.4372835176750702,
        2.438795241796629,
        2.4403069659181877,
        2.4418186900397467,
        2.4433304141613053,
        2.4448421382828642,
        2.446353862404423,
        2.4478655865259817,
        2.4493773106475407,
        2.4508890347690997,
        2.4524007588906582,
        2.453912483012217,
        2.455424207133776,
        2.4569359312553347,
        2.4584476553768937,
        2.4599593794984527,
        2.461471103620011,
        2.46298282774157,
        2.4644945518631287,
        2.4660062759846877,
        2.4675180001062467,
        2.469029724227805,
        2.470541448349364,
        2.4718195928822837,
        2.4730977374152032,
        2.4743758819481227,
        2.475654026481042,
        2.4769321710139613,
        2.478210315546881,
        2.4794884600798004,
        2.48076660461272,
        2.4820447491456394,
        2.4833228936785585,
        2.484601038211478,
        2.4858791827443976,
        2.487157327277317,
        2.4884354718102366,
        2.489713616343156,
        2.490991760876075,
        2.4922699054089947,
        2.4935480499419143,
        2.494826194474834,
        2.4961043390077533,
        2.497382483540673,
        2.498660628073592,
        2.4999387726065114,
        2.501216917139431,
        2.5024950616723505,
        2.503602240827495,
        2.5047094199826403,
        2.505816599137785,
        2.50692377829293,
        2.508030957448075,
        2.50913813660322,
        2.5102453157583646,
        2.5113524949135098,
        2.5124596740686544,
        2.5135668532237996,
        2.5146740323789443,
        2.5157812115340894,
        2.516888390689234,
        2.5179955698443792,
        2.519102748999524,
        2.520209928154669,
        2.5213171073098137,
        2.522424286464959,
        2.5235314656201036,
        2.5246386447752487,
        2.5257458239303934,
        2.5268530030855385,
        2.527960182240683,
        2.5290673613958283,
        2.530174540550973,
      ],
    ],
    dimensions: ['month', 'level'],
    attributes: {
      vartype: 'f8',
      units: 'm.s-1',
      description: 'standard deviation of magw',
      standard_name: 'magw_std',
    },
  },
  level: {
    values: [
      [
        10.0,
        11.0,
        12.0,
        13.0,
        14.0,
        15.0,
        16.0,
        17.0,
        18.0,
        19.0,
        20.0,
        21.0,
        22.0,
        23.0,
        24.0,
        25.0,
        26.0,
        27.0,
        28.0,
        29.0,
        30.0,
        31.0,
        32.0,
        33.0,
        34.0,
        35.0,
        36.0,
        37.0,
        38.0,
        39.0,
        40.0,
        41.0,
        42.0,
        43.0,
        44.0,
        45.0,
        46.0,
        47.0,
        48.0,
        49.0,
        50.0,
        51.0,
        52.0,
        53.0,
        54.0,
        55.0,
        56.0,
        57.0,
        58.0,
        59.0,
        60.0,
        61.0,
        62.0,
        63.0,
        64.0,
        65.0,
        66.0,
        67.0,
        68.0,
        69.0,
        70.0,
        71.0,
        72.0,
        73.0,
        74.0,
        75.0,
        76.0,
        77.0,
        78.0,
        79.0,
        80.0,
        81.0,
        82.0,
        83.0,
        84.0,
        85.0,
        86.0,
        87.0,
        88.0,
        89.0,
        90.0,
        91.0,
        92.0,
        93.0,
        94.0,
        95.0,
        96.0,
        97.0,
        98.0,
        99.0,
        100.0,
        101.0,
        102.0,
        103.0,
        104.0,
        105.0,
        106.0,
        107.0,
        108.0,
        109.0,
        110.0,
        111.0,
        112.0,
        113.0,
        114.0,
        115.0,
        116.0,
        117.0,
        118.0,
        119.0,
        120.0,
        121.0,
        122.0,
        123.0,
        124.0,
        125.0,
        126.0,
        127.0,
        128.0,
        129.0,
        130.0,
        131.0,
        132.0,
        133.0,
        134.0,
        135.0,
        136.0,
        137.0,
        138.0,
        139.0,
        140.0,
        141.0,
        142.0,
        143.0,
        144.0,
        145.0,
        146.0,
        147.0,
        148.0,
        149.0,
        150.0,
        151.0,
        152.0,
        153.0,
        154.0,
        155.0,
        156.0,
        157.0,
        158.0,
        159.0,
        160.0,
        161.0,
        162.0,
        163.0,
        164.0,
        165.0,
        166.0,
        167.0,
        168.0,
        169.0,
        170.0,
        171.0,
        172.0,
        173.0,
        174.0,
        175.0,
        176.0,
        177.0,
        178.0,
        179.0,
        180.0,
        181.0,
        182.0,
        183.0,
        184.0,
        185.0,
        186.0,
        187.0,
        188.0,
        189.0,
        190.0,
        191.0,
        192.0,
        193.0,
        194.0,
        195.0,
        196.0,
        197.0,
        198.0,
        199.0,
        200.0,
      ],
      [
        10.0,
        11.0,
        12.0,
        13.0,
        14.0,
        15.0,
        16.0,
        17.0,
        18.0,
        19.0,
        20.0,
        21.0,
        22.0,
        23.0,
        24.0,
        25.0,
        26.0,
        27.0,
        28.0,
        29.0,
        30.0,
        31.0,
        32.0,
        33.0,
        34.0,
        35.0,
        36.0,
        37.0,
        38.0,
        39.0,
        40.0,
        41.0,
        42.0,
        43.0,
        44.0,
        45.0,
        46.0,
        47.0,
        48.0,
        49.0,
        50.0,
        51.0,
        52.0,
        53.0,
        54.0,
        55.0,
        56.0,
        57.0,
        58.0,
        59.0,
        60.0,
        61.0,
        62.0,
        63.0,
        64.0,
        65.0,
        66.0,
        67.0,
        68.0,
        69.0,
        70.0,
        71.0,
        72.0,
        73.0,
        74.0,
        75.0,
        76.0,
        77.0,
        78.0,
        79.0,
        80.0,
        81.0,
        82.0,
        83.0,
        84.0,
        85.0,
        86.0,
        87.0,
        88.0,
        89.0,
        90.0,
        91.0,
        92.0,
        93.0,
        94.0,
        95.0,
        96.0,
        97.0,
        98.0,
        99.0,
        100.0,
        101.0,
        102.0,
        103.0,
        104.0,
        105.0,
        106.0,
        107.0,
        108.0,
        109.0,
        110.0,
        111.0,
        112.0,
        113.0,
        114.0,
        115.0,
        116.0,
        117.0,
        118.0,
        119.0,
        120.0,
        121.0,
        122.0,
        123.0,
        124.0,
        125.0,
        126.0,
        127.0,
        128.0,
        129.0,
        130.0,
        131.0,
        132.0,
        133.0,
        134.0,
        135.0,
        136.0,
        137.0,
        138.0,
        139.0,
        140.0,
        141.0,
        142.0,
        143.0,
        144.0,
        145.0,
        146.0,
        147.0,
        148.0,
        149.0,
        150.0,
        151.0,
        152.0,
        153.0,
        154.0,
        155.0,
        156.0,
        157.0,
        158.0,
        159.0,
        160.0,
        161.0,
        162.0,
        163.0,
        164.0,
        165.0,
        166.0,
        167.0,
        168.0,
        169.0,
        170.0,
        171.0,
        172.0,
        173.0,
        174.0,
        175.0,
        176.0,
        177.0,
        178.0,
        179.0,
        180.0,
        181.0,
        182.0,
        183.0,
        184.0,
        185.0,
        186.0,
        187.0,
        188.0,
        189.0,
        190.0,
        191.0,
        192.0,
        193.0,
        194.0,
        195.0,
        196.0,
        197.0,
        198.0,
        199.0,
        200.0,
      ],
      [
        10.0,
        11.0,
        12.0,
        13.0,
        14.0,
        15.0,
        16.0,
        17.0,
        18.0,
        19.0,
        20.0,
        21.0,
        22.0,
        23.0,
        24.0,
        25.0,
        26.0,
        27.0,
        28.0,
        29.0,
        30.0,
        31.0,
        32.0,
        33.0,
        34.0,
        35.0,
        36.0,
        37.0,
        38.0,
        39.0,
        40.0,
        41.0,
        42.0,
        43.0,
        44.0,
        45.0,
        46.0,
        47.0,
        48.0,
        49.0,
        50.0,
        51.0,
        52.0,
        53.0,
        54.0,
        55.0,
        56.0,
        57.0,
        58.0,
        59.0,
        60.0,
        61.0,
        62.0,
        63.0,
        64.0,
        65.0,
        66.0,
        67.0,
        68.0,
        69.0,
        70.0,
        71.0,
        72.0,
        73.0,
        74.0,
        75.0,
        76.0,
        77.0,
        78.0,
        79.0,
        80.0,
        81.0,
        82.0,
        83.0,
        84.0,
        85.0,
        86.0,
        87.0,
        88.0,
        89.0,
        90.0,
        91.0,
        92.0,
        93.0,
        94.0,
        95.0,
        96.0,
        97.0,
        98.0,
        99.0,
        100.0,
        101.0,
        102.0,
        103.0,
        104.0,
        105.0,
        106.0,
        107.0,
        108.0,
        109.0,
        110.0,
        111.0,
        112.0,
        113.0,
        114.0,
        115.0,
        116.0,
        117.0,
        118.0,
        119.0,
        120.0,
        121.0,
        122.0,
        123.0,
        124.0,
        125.0,
        126.0,
        127.0,
        128.0,
        129.0,
        130.0,
        131.0,
        132.0,
        133.0,
        134.0,
        135.0,
        136.0,
        137.0,
        138.0,
        139.0,
        140.0,
        141.0,
        142.0,
        143.0,
        144.0,
        145.0,
        146.0,
        147.0,
        148.0,
        149.0,
        150.0,
        151.0,
        152.0,
        153.0,
        154.0,
        155.0,
        156.0,
        157.0,
        158.0,
        159.0,
        160.0,
        161.0,
        162.0,
        163.0,
        164.0,
        165.0,
        166.0,
        167.0,
        168.0,
        169.0,
        170.0,
        171.0,
        172.0,
        173.0,
        174.0,
        175.0,
        176.0,
        177.0,
        178.0,
        179.0,
        180.0,
        181.0,
        182.0,
        183.0,
        184.0,
        185.0,
        186.0,
        187.0,
        188.0,
        189.0,
        190.0,
        191.0,
        192.0,
        193.0,
        194.0,
        195.0,
        196.0,
        197.0,
        198.0,
        199.0,
        200.0,
      ],
      [
        10.0,
        11.0,
        12.0,
        13.0,
        14.0,
        15.0,
        16.0,
        17.0,
        18.0,
        19.0,
        20.0,
        21.0,
        22.0,
        23.0,
        24.0,
        25.0,
        26.0,
        27.0,
        28.0,
        29.0,
        30.0,
        31.0,
        32.0,
        33.0,
        34.0,
        35.0,
        36.0,
        37.0,
        38.0,
        39.0,
        40.0,
        41.0,
        42.0,
        43.0,
        44.0,
        45.0,
        46.0,
        47.0,
        48.0,
        49.0,
        50.0,
        51.0,
        52.0,
        53.0,
        54.0,
        55.0,
        56.0,
        57.0,
        58.0,
        59.0,
        60.0,
        61.0,
        62.0,
        63.0,
        64.0,
        65.0,
        66.0,
        67.0,
        68.0,
        69.0,
        70.0,
        71.0,
        72.0,
        73.0,
        74.0,
        75.0,
        76.0,
        77.0,
        78.0,
        79.0,
        80.0,
        81.0,
        82.0,
        83.0,
        84.0,
        85.0,
        86.0,
        87.0,
        88.0,
        89.0,
        90.0,
        91.0,
        92.0,
        93.0,
        94.0,
        95.0,
        96.0,
        97.0,
        98.0,
        99.0,
        100.0,
        101.0,
        102.0,
        103.0,
        104.0,
        105.0,
        106.0,
        107.0,
        108.0,
        109.0,
        110.0,
        111.0,
        112.0,
        113.0,
        114.0,
        115.0,
        116.0,
        117.0,
        118.0,
        119.0,
        120.0,
        121.0,
        122.0,
        123.0,
        124.0,
        125.0,
        126.0,
        127.0,
        128.0,
        129.0,
        130.0,
        131.0,
        132.0,
        133.0,
        134.0,
        135.0,
        136.0,
        137.0,
        138.0,
        139.0,
        140.0,
        141.0,
        142.0,
        143.0,
        144.0,
        145.0,
        146.0,
        147.0,
        148.0,
        149.0,
        150.0,
        151.0,
        152.0,
        153.0,
        154.0,
        155.0,
        156.0,
        157.0,
        158.0,
        159.0,
        160.0,
        161.0,
        162.0,
        163.0,
        164.0,
        165.0,
        166.0,
        167.0,
        168.0,
        169.0,
        170.0,
        171.0,
        172.0,
        173.0,
        174.0,
        175.0,
        176.0,
        177.0,
        178.0,
        179.0,
        180.0,
        181.0,
        182.0,
        183.0,
        184.0,
        185.0,
        186.0,
        187.0,
        188.0,
        189.0,
        190.0,
        191.0,
        192.0,
        193.0,
        194.0,
        195.0,
        196.0,
        197.0,
        198.0,
        199.0,
        200.0,
      ],
      [
        10.0,
        11.0,
        12.0,
        13.0,
        14.0,
        15.0,
        16.0,
        17.0,
        18.0,
        19.0,
        20.0,
        21.0,
        22.0,
        23.0,
        24.0,
        25.0,
        26.0,
        27.0,
        28.0,
        29.0,
        30.0,
        31.0,
        32.0,
        33.0,
        34.0,
        35.0,
        36.0,
        37.0,
        38.0,
        39.0,
        40.0,
        41.0,
        42.0,
        43.0,
        44.0,
        45.0,
        46.0,
        47.0,
        48.0,
        49.0,
        50.0,
        51.0,
        52.0,
        53.0,
        54.0,
        55.0,
        56.0,
        57.0,
        58.0,
        59.0,
        60.0,
        61.0,
        62.0,
        63.0,
        64.0,
        65.0,
        66.0,
        67.0,
        68.0,
        69.0,
        70.0,
        71.0,
        72.0,
        73.0,
        74.0,
        75.0,
        76.0,
        77.0,
        78.0,
        79.0,
        80.0,
        81.0,
        82.0,
        83.0,
        84.0,
        85.0,
        86.0,
        87.0,
        88.0,
        89.0,
        90.0,
        91.0,
        92.0,
        93.0,
        94.0,
        95.0,
        96.0,
        97.0,
        98.0,
        99.0,
        100.0,
        101.0,
        102.0,
        103.0,
        104.0,
        105.0,
        106.0,
        107.0,
        108.0,
        109.0,
        110.0,
        111.0,
        112.0,
        113.0,
        114.0,
        115.0,
        116.0,
        117.0,
        118.0,
        119.0,
        120.0,
        121.0,
        122.0,
        123.0,
        124.0,
        125.0,
        126.0,
        127.0,
        128.0,
        129.0,
        130.0,
        131.0,
        132.0,
        133.0,
        134.0,
        135.0,
        136.0,
        137.0,
        138.0,
        139.0,
        140.0,
        141.0,
        142.0,
        143.0,
        144.0,
        145.0,
        146.0,
        147.0,
        148.0,
        149.0,
        150.0,
        151.0,
        152.0,
        153.0,
        154.0,
        155.0,
        156.0,
        157.0,
        158.0,
        159.0,
        160.0,
        161.0,
        162.0,
        163.0,
        164.0,
        165.0,
        166.0,
        167.0,
        168.0,
        169.0,
        170.0,
        171.0,
        172.0,
        173.0,
        174.0,
        175.0,
        176.0,
        177.0,
        178.0,
        179.0,
        180.0,
        181.0,
        182.0,
        183.0,
        184.0,
        185.0,
        186.0,
        187.0,
        188.0,
        189.0,
        190.0,
        191.0,
        192.0,
        193.0,
        194.0,
        195.0,
        196.0,
        197.0,
        198.0,
        199.0,
        200.0,
      ],
      [
        10.0,
        11.0,
        12.0,
        13.0,
        14.0,
        15.0,
        16.0,
        17.0,
        18.0,
        19.0,
        20.0,
        21.0,
        22.0,
        23.0,
        24.0,
        25.0,
        26.0,
        27.0,
        28.0,
        29.0,
        30.0,
        31.0,
        32.0,
        33.0,
        34.0,
        35.0,
        36.0,
        37.0,
        38.0,
        39.0,
        40.0,
        41.0,
        42.0,
        43.0,
        44.0,
        45.0,
        46.0,
        47.0,
        48.0,
        49.0,
        50.0,
        51.0,
        52.0,
        53.0,
        54.0,
        55.0,
        56.0,
        57.0,
        58.0,
        59.0,
        60.0,
        61.0,
        62.0,
        63.0,
        64.0,
        65.0,
        66.0,
        67.0,
        68.0,
        69.0,
        70.0,
        71.0,
        72.0,
        73.0,
        74.0,
        75.0,
        76.0,
        77.0,
        78.0,
        79.0,
        80.0,
        81.0,
        82.0,
        83.0,
        84.0,
        85.0,
        86.0,
        87.0,
        88.0,
        89.0,
        90.0,
        91.0,
        92.0,
        93.0,
        94.0,
        95.0,
        96.0,
        97.0,
        98.0,
        99.0,
        100.0,
        101.0,
        102.0,
        103.0,
        104.0,
        105.0,
        106.0,
        107.0,
        108.0,
        109.0,
        110.0,
        111.0,
        112.0,
        113.0,
        114.0,
        115.0,
        116.0,
        117.0,
        118.0,
        119.0,
        120.0,
        121.0,
        122.0,
        123.0,
        124.0,
        125.0,
        126.0,
        127.0,
        128.0,
        129.0,
        130.0,
        131.0,
        132.0,
        133.0,
        134.0,
        135.0,
        136.0,
        137.0,
        138.0,
        139.0,
        140.0,
        141.0,
        142.0,
        143.0,
        144.0,
        145.0,
        146.0,
        147.0,
        148.0,
        149.0,
        150.0,
        151.0,
        152.0,
        153.0,
        154.0,
        155.0,
        156.0,
        157.0,
        158.0,
        159.0,
        160.0,
        161.0,
        162.0,
        163.0,
        164.0,
        165.0,
        166.0,
        167.0,
        168.0,
        169.0,
        170.0,
        171.0,
        172.0,
        173.0,
        174.0,
        175.0,
        176.0,
        177.0,
        178.0,
        179.0,
        180.0,
        181.0,
        182.0,
        183.0,
        184.0,
        185.0,
        186.0,
        187.0,
        188.0,
        189.0,
        190.0,
        191.0,
        192.0,
        193.0,
        194.0,
        195.0,
        196.0,
        197.0,
        198.0,
        199.0,
        200.0,
      ],
      [
        10.0,
        11.0,
        12.0,
        13.0,
        14.0,
        15.0,
        16.0,
        17.0,
        18.0,
        19.0,
        20.0,
        21.0,
        22.0,
        23.0,
        24.0,
        25.0,
        26.0,
        27.0,
        28.0,
        29.0,
        30.0,
        31.0,
        32.0,
        33.0,
        34.0,
        35.0,
        36.0,
        37.0,
        38.0,
        39.0,
        40.0,
        41.0,
        42.0,
        43.0,
        44.0,
        45.0,
        46.0,
        47.0,
        48.0,
        49.0,
        50.0,
        51.0,
        52.0,
        53.0,
        54.0,
        55.0,
        56.0,
        57.0,
        58.0,
        59.0,
        60.0,
        61.0,
        62.0,
        63.0,
        64.0,
        65.0,
        66.0,
        67.0,
        68.0,
        69.0,
        70.0,
        71.0,
        72.0,
        73.0,
        74.0,
        75.0,
        76.0,
        77.0,
        78.0,
        79.0,
        80.0,
        81.0,
        82.0,
        83.0,
        84.0,
        85.0,
        86.0,
        87.0,
        88.0,
        89.0,
        90.0,
        91.0,
        92.0,
        93.0,
        94.0,
        95.0,
        96.0,
        97.0,
        98.0,
        99.0,
        100.0,
        101.0,
        102.0,
        103.0,
        104.0,
        105.0,
        106.0,
        107.0,
        108.0,
        109.0,
        110.0,
        111.0,
        112.0,
        113.0,
        114.0,
        115.0,
        116.0,
        117.0,
        118.0,
        119.0,
        120.0,
        121.0,
        122.0,
        123.0,
        124.0,
        125.0,
        126.0,
        127.0,
        128.0,
        129.0,
        130.0,
        131.0,
        132.0,
        133.0,
        134.0,
        135.0,
        136.0,
        137.0,
        138.0,
        139.0,
        140.0,
        141.0,
        142.0,
        143.0,
        144.0,
        145.0,
        146.0,
        147.0,
        148.0,
        149.0,
        150.0,
        151.0,
        152.0,
        153.0,
        154.0,
        155.0,
        156.0,
        157.0,
        158.0,
        159.0,
        160.0,
        161.0,
        162.0,
        163.0,
        164.0,
        165.0,
        166.0,
        167.0,
        168.0,
        169.0,
        170.0,
        171.0,
        172.0,
        173.0,
        174.0,
        175.0,
        176.0,
        177.0,
        178.0,
        179.0,
        180.0,
        181.0,
        182.0,
        183.0,
        184.0,
        185.0,
        186.0,
        187.0,
        188.0,
        189.0,
        190.0,
        191.0,
        192.0,
        193.0,
        194.0,
        195.0,
        196.0,
        197.0,
        198.0,
        199.0,
        200.0,
      ],
      [
        10.0,
        11.0,
        12.0,
        13.0,
        14.0,
        15.0,
        16.0,
        17.0,
        18.0,
        19.0,
        20.0,
        21.0,
        22.0,
        23.0,
        24.0,
        25.0,
        26.0,
        27.0,
        28.0,
        29.0,
        30.0,
        31.0,
        32.0,
        33.0,
        34.0,
        35.0,
        36.0,
        37.0,
        38.0,
        39.0,
        40.0,
        41.0,
        42.0,
        43.0,
        44.0,
        45.0,
        46.0,
        47.0,
        48.0,
        49.0,
        50.0,
        51.0,
        52.0,
        53.0,
        54.0,
        55.0,
        56.0,
        57.0,
        58.0,
        59.0,
        60.0,
        61.0,
        62.0,
        63.0,
        64.0,
        65.0,
        66.0,
        67.0,
        68.0,
        69.0,
        70.0,
        71.0,
        72.0,
        73.0,
        74.0,
        75.0,
        76.0,
        77.0,
        78.0,
        79.0,
        80.0,
        81.0,
        82.0,
        83.0,
        84.0,
        85.0,
        86.0,
        87.0,
        88.0,
        89.0,
        90.0,
        91.0,
        92.0,
        93.0,
        94.0,
        95.0,
        96.0,
        97.0,
        98.0,
        99.0,
        100.0,
        101.0,
        102.0,
        103.0,
        104.0,
        105.0,
        106.0,
        107.0,
        108.0,
        109.0,
        110.0,
        111.0,
        112.0,
        113.0,
        114.0,
        115.0,
        116.0,
        117.0,
        118.0,
        119.0,
        120.0,
        121.0,
        122.0,
        123.0,
        124.0,
        125.0,
        126.0,
        127.0,
        128.0,
        129.0,
        130.0,
        131.0,
        132.0,
        133.0,
        134.0,
        135.0,
        136.0,
        137.0,
        138.0,
        139.0,
        140.0,
        141.0,
        142.0,
        143.0,
        144.0,
        145.0,
        146.0,
        147.0,
        148.0,
        149.0,
        150.0,
        151.0,
        152.0,
        153.0,
        154.0,
        155.0,
        156.0,
        157.0,
        158.0,
        159.0,
        160.0,
        161.0,
        162.0,
        163.0,
        164.0,
        165.0,
        166.0,
        167.0,
        168.0,
        169.0,
        170.0,
        171.0,
        172.0,
        173.0,
        174.0,
        175.0,
        176.0,
        177.0,
        178.0,
        179.0,
        180.0,
        181.0,
        182.0,
        183.0,
        184.0,
        185.0,
        186.0,
        187.0,
        188.0,
        189.0,
        190.0,
        191.0,
        192.0,
        193.0,
        194.0,
        195.0,
        196.0,
        197.0,
        198.0,
        199.0,
        200.0,
      ],
      [
        10.0,
        11.0,
        12.0,
        13.0,
        14.0,
        15.0,
        16.0,
        17.0,
        18.0,
        19.0,
        20.0,
        21.0,
        22.0,
        23.0,
        24.0,
        25.0,
        26.0,
        27.0,
        28.0,
        29.0,
        30.0,
        31.0,
        32.0,
        33.0,
        34.0,
        35.0,
        36.0,
        37.0,
        38.0,
        39.0,
        40.0,
        41.0,
        42.0,
        43.0,
        44.0,
        45.0,
        46.0,
        47.0,
        48.0,
        49.0,
        50.0,
        51.0,
        52.0,
        53.0,
        54.0,
        55.0,
        56.0,
        57.0,
        58.0,
        59.0,
        60.0,
        61.0,
        62.0,
        63.0,
        64.0,
        65.0,
        66.0,
        67.0,
        68.0,
        69.0,
        70.0,
        71.0,
        72.0,
        73.0,
        74.0,
        75.0,
        76.0,
        77.0,
        78.0,
        79.0,
        80.0,
        81.0,
        82.0,
        83.0,
        84.0,
        85.0,
        86.0,
        87.0,
        88.0,
        89.0,
        90.0,
        91.0,
        92.0,
        93.0,
        94.0,
        95.0,
        96.0,
        97.0,
        98.0,
        99.0,
        100.0,
        101.0,
        102.0,
        103.0,
        104.0,
        105.0,
        106.0,
        107.0,
        108.0,
        109.0,
        110.0,
        111.0,
        112.0,
        113.0,
        114.0,
        115.0,
        116.0,
        117.0,
        118.0,
        119.0,
        120.0,
        121.0,
        122.0,
        123.0,
        124.0,
        125.0,
        126.0,
        127.0,
        128.0,
        129.0,
        130.0,
        131.0,
        132.0,
        133.0,
        134.0,
        135.0,
        136.0,
        137.0,
        138.0,
        139.0,
        140.0,
        141.0,
        142.0,
        143.0,
        144.0,
        145.0,
        146.0,
        147.0,
        148.0,
        149.0,
        150.0,
        151.0,
        152.0,
        153.0,
        154.0,
        155.0,
        156.0,
        157.0,
        158.0,
        159.0,
        160.0,
        161.0,
        162.0,
        163.0,
        164.0,
        165.0,
        166.0,
        167.0,
        168.0,
        169.0,
        170.0,
        171.0,
        172.0,
        173.0,
        174.0,
        175.0,
        176.0,
        177.0,
        178.0,
        179.0,
        180.0,
        181.0,
        182.0,
        183.0,
        184.0,
        185.0,
        186.0,
        187.0,
        188.0,
        189.0,
        190.0,
        191.0,
        192.0,
        193.0,
        194.0,
        195.0,
        196.0,
        197.0,
        198.0,
        199.0,
        200.0,
      ],
      [
        10.0,
        11.0,
        12.0,
        13.0,
        14.0,
        15.0,
        16.0,
        17.0,
        18.0,
        19.0,
        20.0,
        21.0,
        22.0,
        23.0,
        24.0,
        25.0,
        26.0,
        27.0,
        28.0,
        29.0,
        30.0,
        31.0,
        32.0,
        33.0,
        34.0,
        35.0,
        36.0,
        37.0,
        38.0,
        39.0,
        40.0,
        41.0,
        42.0,
        43.0,
        44.0,
        45.0,
        46.0,
        47.0,
        48.0,
        49.0,
        50.0,
        51.0,
        52.0,
        53.0,
        54.0,
        55.0,
        56.0,
        57.0,
        58.0,
        59.0,
        60.0,
        61.0,
        62.0,
        63.0,
        64.0,
        65.0,
        66.0,
        67.0,
        68.0,
        69.0,
        70.0,
        71.0,
        72.0,
        73.0,
        74.0,
        75.0,
        76.0,
        77.0,
        78.0,
        79.0,
        80.0,
        81.0,
        82.0,
        83.0,
        84.0,
        85.0,
        86.0,
        87.0,
        88.0,
        89.0,
        90.0,
        91.0,
        92.0,
        93.0,
        94.0,
        95.0,
        96.0,
        97.0,
        98.0,
        99.0,
        100.0,
        101.0,
        102.0,
        103.0,
        104.0,
        105.0,
        106.0,
        107.0,
        108.0,
        109.0,
        110.0,
        111.0,
        112.0,
        113.0,
        114.0,
        115.0,
        116.0,
        117.0,
        118.0,
        119.0,
        120.0,
        121.0,
        122.0,
        123.0,
        124.0,
        125.0,
        126.0,
        127.0,
        128.0,
        129.0,
        130.0,
        131.0,
        132.0,
        133.0,
        134.0,
        135.0,
        136.0,
        137.0,
        138.0,
        139.0,
        140.0,
        141.0,
        142.0,
        143.0,
        144.0,
        145.0,
        146.0,
        147.0,
        148.0,
        149.0,
        150.0,
        151.0,
        152.0,
        153.0,
        154.0,
        155.0,
        156.0,
        157.0,
        158.0,
        159.0,
        160.0,
        161.0,
        162.0,
        163.0,
        164.0,
        165.0,
        166.0,
        167.0,
        168.0,
        169.0,
        170.0,
        171.0,
        172.0,
        173.0,
        174.0,
        175.0,
        176.0,
        177.0,
        178.0,
        179.0,
        180.0,
        181.0,
        182.0,
        183.0,
        184.0,
        185.0,
        186.0,
        187.0,
        188.0,
        189.0,
        190.0,
        191.0,
        192.0,
        193.0,
        194.0,
        195.0,
        196.0,
        197.0,
        198.0,
        199.0,
        200.0,
      ],
      [
        10.0,
        11.0,
        12.0,
        13.0,
        14.0,
        15.0,
        16.0,
        17.0,
        18.0,
        19.0,
        20.0,
        21.0,
        22.0,
        23.0,
        24.0,
        25.0,
        26.0,
        27.0,
        28.0,
        29.0,
        30.0,
        31.0,
        32.0,
        33.0,
        34.0,
        35.0,
        36.0,
        37.0,
        38.0,
        39.0,
        40.0,
        41.0,
        42.0,
        43.0,
        44.0,
        45.0,
        46.0,
        47.0,
        48.0,
        49.0,
        50.0,
        51.0,
        52.0,
        53.0,
        54.0,
        55.0,
        56.0,
        57.0,
        58.0,
        59.0,
        60.0,
        61.0,
        62.0,
        63.0,
        64.0,
        65.0,
        66.0,
        67.0,
        68.0,
        69.0,
        70.0,
        71.0,
        72.0,
        73.0,
        74.0,
        75.0,
        76.0,
        77.0,
        78.0,
        79.0,
        80.0,
        81.0,
        82.0,
        83.0,
        84.0,
        85.0,
        86.0,
        87.0,
        88.0,
        89.0,
        90.0,
        91.0,
        92.0,
        93.0,
        94.0,
        95.0,
        96.0,
        97.0,
        98.0,
        99.0,
        100.0,
        101.0,
        102.0,
        103.0,
        104.0,
        105.0,
        106.0,
        107.0,
        108.0,
        109.0,
        110.0,
        111.0,
        112.0,
        113.0,
        114.0,
        115.0,
        116.0,
        117.0,
        118.0,
        119.0,
        120.0,
        121.0,
        122.0,
        123.0,
        124.0,
        125.0,
        126.0,
        127.0,
        128.0,
        129.0,
        130.0,
        131.0,
        132.0,
        133.0,
        134.0,
        135.0,
        136.0,
        137.0,
        138.0,
        139.0,
        140.0,
        141.0,
        142.0,
        143.0,
        144.0,
        145.0,
        146.0,
        147.0,
        148.0,
        149.0,
        150.0,
        151.0,
        152.0,
        153.0,
        154.0,
        155.0,
        156.0,
        157.0,
        158.0,
        159.0,
        160.0,
        161.0,
        162.0,
        163.0,
        164.0,
        165.0,
        166.0,
        167.0,
        168.0,
        169.0,
        170.0,
        171.0,
        172.0,
        173.0,
        174.0,
        175.0,
        176.0,
        177.0,
        178.0,
        179.0,
        180.0,
        181.0,
        182.0,
        183.0,
        184.0,
        185.0,
        186.0,
        187.0,
        188.0,
        189.0,
        190.0,
        191.0,
        192.0,
        193.0,
        194.0,
        195.0,
        196.0,
        197.0,
        198.0,
        199.0,
        200.0,
      ],
      [
        10.0,
        11.0,
        12.0,
        13.0,
        14.0,
        15.0,
        16.0,
        17.0,
        18.0,
        19.0,
        20.0,
        21.0,
        22.0,
        23.0,
        24.0,
        25.0,
        26.0,
        27.0,
        28.0,
        29.0,
        30.0,
        31.0,
        32.0,
        33.0,
        34.0,
        35.0,
        36.0,
        37.0,
        38.0,
        39.0,
        40.0,
        41.0,
        42.0,
        43.0,
        44.0,
        45.0,
        46.0,
        47.0,
        48.0,
        49.0,
        50.0,
        51.0,
        52.0,
        53.0,
        54.0,
        55.0,
        56.0,
        57.0,
        58.0,
        59.0,
        60.0,
        61.0,
        62.0,
        63.0,
        64.0,
        65.0,
        66.0,
        67.0,
        68.0,
        69.0,
        70.0,
        71.0,
        72.0,
        73.0,
        74.0,
        75.0,
        76.0,
        77.0,
        78.0,
        79.0,
        80.0,
        81.0,
        82.0,
        83.0,
        84.0,
        85.0,
        86.0,
        87.0,
        88.0,
        89.0,
        90.0,
        91.0,
        92.0,
        93.0,
        94.0,
        95.0,
        96.0,
        97.0,
        98.0,
        99.0,
        100.0,
        101.0,
        102.0,
        103.0,
        104.0,
        105.0,
        106.0,
        107.0,
        108.0,
        109.0,
        110.0,
        111.0,
        112.0,
        113.0,
        114.0,
        115.0,
        116.0,
        117.0,
        118.0,
        119.0,
        120.0,
        121.0,
        122.0,
        123.0,
        124.0,
        125.0,
        126.0,
        127.0,
        128.0,
        129.0,
        130.0,
        131.0,
        132.0,
        133.0,
        134.0,
        135.0,
        136.0,
        137.0,
        138.0,
        139.0,
        140.0,
        141.0,
        142.0,
        143.0,
        144.0,
        145.0,
        146.0,
        147.0,
        148.0,
        149.0,
        150.0,
        151.0,
        152.0,
        153.0,
        154.0,
        155.0,
        156.0,
        157.0,
        158.0,
        159.0,
        160.0,
        161.0,
        162.0,
        163.0,
        164.0,
        165.0,
        166.0,
        167.0,
        168.0,
        169.0,
        170.0,
        171.0,
        172.0,
        173.0,
        174.0,
        175.0,
        176.0,
        177.0,
        178.0,
        179.0,
        180.0,
        181.0,
        182.0,
        183.0,
        184.0,
        185.0,
        186.0,
        187.0,
        188.0,
        189.0,
        190.0,
        191.0,
        192.0,
        193.0,
        194.0,
        195.0,
        196.0,
        197.0,
        198.0,
        199.0,
        200.0,
      ],
    ],
    dimensions: ['month', 'level'],
    attributes: {
      vartype: 'f8',
      conventions: 'positive from seabed',
      standard_name: 'vertical level',
      long_name: 'vertical level',
      units: 'm',
      description: 'vertical level, in meters from mean sea level',
    },
  },
  magwMean: {
    values: [
      [
        7.937506240611101,
        7.990150428981487,
        8.042794617351872,
        8.095438805722257,
        8.148082994092643,
        8.200727182463028,
        8.253371370833413,
        8.3060155592038,
        8.358659747574185,
        8.41130393594457,
        8.463948124314957,
        8.516592312685342,
        8.569236501055727,
        8.621880689426112,
        8.674524877796499,
        8.727169066166883,
        8.751063340858824,
        8.774957615550763,
        8.798851890242704,
        8.822746164934642,
        8.846640439626583,
        8.870534714318522,
        8.894428989010462,
        8.918323263702401,
        8.942217538394342,
        8.966111813086282,
        8.990006087778221,
        9.013900362470162,
        9.0377946371621,
        9.061688911854041,
        9.08558318654598,
        9.10947746123792,
        9.133371735929861,
        9.1572660106218,
        9.18116028531374,
        9.20505456000568,
        9.22894883469762,
        9.25284310938956,
        9.2767373840815,
        9.300631658773439,
        9.32452593346538,
        9.338503188142095,
        9.35248044281881,
        9.366457697495525,
        9.38043495217224,
        9.394412206848957,
        9.408389461525672,
        9.422366716202387,
        9.436343970879102,
        9.450321225555818,
        9.464298480232534,
        9.478275734909248,
        9.492252989585964,
        9.50623024426268,
        9.520207498939396,
        9.53418475361611,
        9.548162008292826,
        9.562139262969541,
        9.576116517646257,
        9.590093772322971,
        9.604071026999687,
        9.618048281676403,
        9.632025536353119,
        9.646002791029833,
        9.659980045706549,
        9.673957300383265,
        9.68387432039849,
        9.693791340413714,
        9.703708360428939,
        9.713625380444164,
        9.723542400459387,
        9.733459420474611,
        9.743376440489836,
        9.753293460505061,
        9.763210480520286,
        9.77312750053551,
        9.783044520550735,
        9.79296154056596,
        9.802878560581183,
        9.812795580596408,
        9.822712600611633,
        9.832629620626857,
        9.842546640642082,
        9.852463660657307,
        9.862380680672532,
        9.872297700687756,
        9.88221472070298,
        9.892131740718204,
        9.902048760733429,
        9.911965780748654,
        9.921882800763878,
        9.92957503909417,
        9.93726727742446,
        9.944959515754753,
        9.952651754085045,
        9.960343992415336,
        9.968036230745627,
        9.975728469075918,
        9.98342070740621,
        9.991112945736502,
        9.998805184066793,
        10.006497422397084,
        10.014189660727375,
        10.021881899057668,
        10.029574137387959,
        10.03726637571825,
        10.044958614048541,
        10.052650852378832,
        10.060343090709125,
        10.068035329039416,
        10.075727567369707,
        10.083419805699998,
        10.09111204403029,
        10.098804282360582,
        10.106496520690873,
        10.114188759021165,
        10.120473775367588,
        10.126758791714012,
        10.133043808060435,
        10.13932882440686,
        10.145613840753283,
        10.151898857099708,
        10.15818387344613,
        10.164468889792555,
        10.170753906138978,
        10.177038922485403,
        10.183323938831826,
        10.189608955178251,
        10.195893971524674,
        10.202178987871099,
        10.208464004217522,
        10.214749020563946,
        10.22103403691037,
        10.227319053256794,
        10.233604069603217,
        10.239889085949642,
        10.246174102296065,
        10.25245911864249,
        10.258744134988913,
        10.265029151335337,
        10.27131416768176,
        10.27662807328658,
        10.281941978891398,
        10.287255884496217,
        10.292569790101036,
        10.297883695705853,
        10.303197601310671,
        10.30851150691549,
        10.313825412520309,
        10.319139318125128,
        10.324453223729947,
        10.329767129334765,
        10.335081034939584,
        10.340394940544401,
        10.34570884614922,
        10.351022751754039,
        10.356336657358858,
        10.361650562963677,
        10.366964468568495,
        10.372278374173314,
        10.377592279778133,
        10.38290618538295,
        10.388220090987769,
        10.393533996592588,
        10.398847902197407,
        10.404161807802225,
        10.408764922212631,
        10.413368036623037,
        10.417971151033443,
        10.42257426544385,
        10.427177379854253,
        10.43178049426466,
        10.436383608675065,
        10.440986723085471,
        10.445589837495877,
        10.450192951906283,
        10.454796066316689,
        10.459399180727095,
        10.4640022951375,
        10.468605409547905,
        10.473208523958311,
        10.477811638368717,
        10.482414752779123,
        10.487017867189529,
        10.491620981599935,
        10.49622409601034,
        10.500827210420745,
        10.505430324831151,
        10.510033439241557,
        10.514636553651963,
        10.519239668062369,
      ],
      [
        7.900149049419701,
        7.952545472440309,
        8.004941895460917,
        8.057338318481523,
        8.10973474150213,
        8.162131164522739,
        8.214527587543346,
        8.266924010563953,
        8.319320433584561,
        8.371716856605168,
        8.424113279625775,
        8.476509702646384,
        8.52890612566699,
        8.581302548687598,
        8.633698971708206,
        8.686095394728813,
        8.70987721306973,
        8.733659031410646,
        8.757440849751562,
        8.781222668092479,
        8.805004486433393,
        8.82878630477431,
        8.852568123115226,
        8.876349941456143,
        8.900131759797059,
        8.923913578137975,
        8.947695396478892,
        8.971477214819808,
        8.995259033160723,
        9.01904085150164,
        9.042822669842556,
        9.066604488183472,
        9.090386306524389,
        9.114168124865305,
        9.137949943206221,
        9.161731761547138,
        9.185513579888053,
        9.209295398228969,
        9.233077216569885,
        9.256859034910802,
        9.280640853251718,
        9.294552325180117,
        9.308463797108516,
        9.322375269036915,
        9.336286740965313,
        9.35019821289371,
        9.36410968482211,
        9.378021156750508,
        9.391932628678907,
        9.405844100607306,
        9.419755572535705,
        9.433667044464103,
        9.447578516392502,
        9.4614899883209,
        9.475401460249298,
        9.489312932177697,
        9.503224404106096,
        9.517135876034494,
        9.531047347962893,
        9.544958819891292,
        9.55887029181969,
        9.572781763748088,
        9.586693235676487,
        9.600604707604885,
        9.614516179533284,
        9.628427651461683,
        9.6382979978742,
        9.648168344286718,
        9.658038690699236,
        9.667909037111754,
        9.677779383524271,
        9.687649729936789,
        9.697520076349306,
        9.707390422761824,
        9.717260769174342,
        9.72713111558686,
        9.737001461999377,
        9.746871808411894,
        9.75674215482441,
        9.766612501236928,
        9.776482847649445,
        9.786353194061963,
        9.79622354047448,
        9.806093886886998,
        9.815964233299516,
        9.825834579712033,
        9.835704926124551,
        9.845575272537069,
        9.855445618949586,
        9.865315965362104,
        9.875186311774621,
        9.88284234724607,
        9.890498382717519,
        9.898154418188968,
        9.905810453660417,
        9.913466489131865,
        9.921122524603314,
        9.928778560074763,
        9.936434595546212,
        9.94409063101766,
        9.95174666648911,
        9.959402701960558,
        9.967058737432007,
        9.974714772903454,
        9.982370808374903,
        9.990026843846351,
        9.9976828793178,
        10.005338914789249,
        10.012994950260698,
        10.020650985732146,
        10.028307021203595,
        10.035963056675044,
        10.043619092146493,
        10.051275127617942,
        10.05893116308939,
        10.066587198560839,
        10.072842635017789,
        10.07909807147474,
        10.08535350793169,
        10.09160894438864,
        10.09786438084559,
        10.10411981730254,
        10.110375253759491,
        10.116630690216441,
        10.122886126673391,
        10.129141563130341,
        10.135396999587291,
        10.141652436044241,
        10.147907872501191,
        10.154163308958141,
        10.160418745415091,
        10.166674181872041,
        10.172929618328991,
        10.179185054785941,
        10.185440491242893,
        10.191695927699843,
        10.197951364156793,
        10.204206800613743,
        10.210462237070693,
        10.216717673527643,
        10.222973109984594,
        10.228262006149214,
        10.233550902313834,
        10.238839798478452,
        10.244128694643072,
        10.249417590807692,
        10.254706486972312,
        10.259995383136932,
        10.26528427930155,
        10.27057317546617,
        10.27586207163079,
        10.28115096779541,
        10.28643986396003,
        10.291728760124649,
        10.297017656289269,
        10.302306552453889,
        10.307595448618509,
        10.312884344783129,
        10.318173240947747,
        10.323462137112367,
        10.328751033276987,
        10.334039929441607,
        10.339328825606227,
        10.344617721770845,
        10.349906617935465,
        10.355195514100085,
        10.359776964347983,
        10.36435841459588,
        10.368939864843778,
        10.373521315091676,
        10.378102765339575,
        10.382684215587473,
        10.38726566583537,
        10.391847116083268,
        10.396428566331165,
        10.401010016579063,
        10.40559146682696,
        10.410172917074858,
        10.414754367322757,
        10.419335817570655,
        10.423917267818553,
        10.42849871806645,
        10.433080168314348,
        10.437661618562245,
        10.442243068810143,
        10.44682451905804,
        10.45140596930594,
        10.455987419553837,
        10.460568869801735,
        10.465150320049633,
        10.46973177029753,
      ],
      [
        7.595539892859727,
        7.6459160463736175,
        7.696292199887507,
        7.746668353401398,
        7.797044506915288,
        7.8474206604291785,
        7.897796813943068,
        7.948172967456959,
        7.998549120970849,
        8.04892527448474,
        8.099301427998629,
        8.14967758151252,
        8.20005373502641,
        8.2504298885403,
        8.300806042054191,
        8.35118219556808,
        8.374047048978213,
        8.396911902388343,
        8.419776755798475,
        8.442641609208607,
        8.465506462618738,
        8.48837131602887,
        8.511236169439002,
        8.534101022849132,
        8.556965876259264,
        8.579830729669396,
        8.602695583079527,
        8.625560436489659,
        8.64842528989979,
        8.671290143309921,
        8.694154996720053,
        8.717019850130185,
        8.739884703540318,
        8.762749556950448,
        8.78561441036058,
        8.808479263770712,
        8.831344117180842,
        8.854208970590975,
        8.877073824001107,
        8.899938677411237,
        8.92280353082137,
        8.936178612650782,
        8.949553694480196,
        8.962928776309608,
        8.97630385813902,
        8.989678939968435,
        9.003054021797848,
        9.01642910362726,
        9.029804185456674,
        9.043179267286087,
        9.0565543491155,
        9.069929430944914,
        9.083304512774326,
        9.096679594603739,
        9.110054676433151,
        9.123429758262565,
        9.136804840091978,
        9.15017992192139,
        9.163555003750805,
        9.176930085580217,
        9.19030516740963,
        9.203680249239044,
        9.217055331068456,
        9.230430412897869,
        9.243805494727283,
        9.257180576556696,
        9.266670348137414,
        9.276160119718133,
        9.285649891298851,
        9.29513966287957,
        9.304629434460288,
        9.314119206041006,
        9.323608977621726,
        9.333098749202444,
        9.342588520783163,
        9.352078292363881,
        9.361568063944599,
        9.371057835525319,
        9.380547607106037,
        9.390037378686756,
        9.399527150267474,
        9.409016921848192,
        9.418506693428911,
        9.42799646500963,
        9.437486236590349,
        9.446976008171067,
        9.456465779751785,
        9.465955551332504,
        9.475445322913222,
        9.484935094493942,
        9.49442486607466,
        9.501785704772862,
        9.509146543471065,
        9.516507382169268,
        9.52386822086747,
        9.531229059565671,
        9.538589898263874,
        9.545950736962077,
        9.55331157566028,
        9.560672414358482,
        9.568033253056685,
        9.575394091754887,
        9.58275493045309,
        9.590115769151291,
        9.597476607849494,
        9.604837446547696,
        9.6121982852459,
        9.619559123944102,
        9.626919962642305,
        9.634280801340507,
        9.64164164003871,
        9.64900247873691,
        9.656363317435114,
        9.663724156133316,
        9.671084994831519,
        9.678445833529722,
        9.684460076660931,
        9.690474319792143,
        9.696488562923353,
        9.702502806054564,
        9.708517049185774,
        9.714531292316986,
        9.720545535448196,
        9.726559778579407,
        9.732574021710617,
        9.738588264841828,
        9.744602507973038,
        9.75061675110425,
        9.75663099423546,
        9.762645237366671,
        9.768659480497881,
        9.774673723629093,
        9.780687966760302,
        9.786702209891514,
        9.792716453022724,
        9.798730696153935,
        9.804744939285145,
        9.810759182416357,
        9.816773425547566,
        9.822787668678778,
        9.828801911809988,
        9.83388688192337,
        9.838971852036753,
        9.844056822150135,
        9.849141792263516,
        9.854226762376898,
        9.859311732490282,
        9.864396702603663,
        9.869481672717045,
        9.874566642830429,
        9.87965161294381,
        9.884736583057192,
        9.889821553170574,
        9.894906523283957,
        9.899991493397339,
        9.90507646351072,
        9.910161433624102,
        9.915246403737486,
        9.920331373850868,
        9.92541634396425,
        9.930501314077633,
        9.935586284191015,
        9.940671254304396,
        9.945756224417778,
        9.950841194531161,
        9.955926164644543,
        9.96033096611188,
        9.964735767579215,
        9.969140569046552,
        9.973545370513888,
        9.977950171981224,
        9.98235497344856,
        9.986759774915896,
        9.991164576383232,
        9.995569377850568,
        9.999974179317904,
        10.00437898078524,
        10.008783782252577,
        10.013188583719915,
        10.01759338518725,
        10.021998186654587,
        10.026402988121923,
        10.03080778958926,
        10.035212591056595,
        10.039617392523931,
        10.044022193991267,
        10.048426995458604,
        10.05283179692594,
        10.057236598393276,
        10.061641399860612,
        10.066046201327948,
      ],
      [
        7.76842574347901,
        7.819948533607811,
        7.871471323736613,
        7.922994113865415,
        7.974516903994217,
        8.02603969412302,
        8.077562484251821,
        8.129085274380623,
        8.180608064509425,
        8.232130854638227,
        8.283653644767028,
        8.33517643489583,
        8.386699225024632,
        8.438222015153434,
        8.489744805282236,
        8.541267595411037,
        8.564652887089792,
        8.588038178768546,
        8.611423470447301,
        8.634808762126054,
        8.65819405380481,
        8.681579345483565,
        8.704964637162318,
        8.728349928841073,
        8.751735220519826,
        8.775120512198582,
        8.798505803877337,
        8.82189109555609,
        8.845276387234845,
        8.868661678913599,
        8.892046970592354,
        8.915432262271109,
        8.938817553949862,
        8.962202845628617,
        8.98558813730737,
        9.008973428986126,
        9.032358720664881,
        9.055744012343634,
        9.07912930402239,
        9.102514595701143,
        9.125899887379898,
        9.139579406080395,
        9.153258924780893,
        9.166938443481392,
        9.18061796218189,
        9.194297480882387,
        9.207976999582884,
        9.221656518283384,
        9.235336036983881,
        9.249015555684378,
        9.262695074384876,
        9.276374593085375,
        9.290054111785873,
        9.30373363048637,
        9.317413149186867,
        9.331092667887367,
        9.344772186587864,
        9.358451705288362,
        9.372131223988859,
        9.385810742689358,
        9.399490261389856,
        9.413169780090353,
        9.42684929879085,
        9.44052881749135,
        9.454208336191847,
        9.467887854892345,
        9.4775936278706,
        9.487299400848858,
        9.497005173827114,
        9.506710946805372,
        9.516416719783628,
        9.526122492761885,
        9.535828265740141,
        9.5455340387184,
        9.555239811696655,
        9.564945584674911,
        9.574651357653169,
        9.584357130631425,
        9.594062903609682,
        9.603768676587938,
        9.613474449566196,
        9.623180222544452,
        9.632885995522708,
        9.642591768500965,
        9.652297541479221,
        9.662003314457479,
        9.671709087435735,
        9.681414860413993,
        9.691120633392249,
        9.700826406370506,
        9.710532179348762,
        9.718060561747288,
        9.725588944145816,
        9.733117326544342,
        9.74064570894287,
        9.748174091341395,
        9.755702473739923,
        9.763230856138449,
        9.770759238536977,
        9.778287620935503,
        9.78581600333403,
        9.793344385732556,
        9.800872768131084,
        9.80840115052961,
        9.815929532928138,
        9.823457915326664,
        9.830986297725191,
        9.838514680123717,
        9.846043062522245,
        9.853571444920771,
        9.861099827319299,
        9.868628209717825,
        9.876156592116352,
        9.883684974514878,
        9.891213356913406,
        9.898741739311932,
        9.904892875613903,
        9.911044011915875,
        9.917195148217845,
        9.923346284519816,
        9.929497420821788,
        9.93564855712376,
        9.941799693425729,
        9.9479508297277,
        9.954101966029672,
        9.960253102331643,
        9.966404238633613,
        9.972555374935585,
        9.978706511237556,
        9.984857647539528,
        9.991008783841497,
        9.997159920143469,
        10.00331105644544,
        10.009462192747412,
        10.015613329049382,
        10.021764465351353,
        10.027915601653325,
        10.034066737955296,
        10.040217874257266,
        10.046369010559237,
        10.052520146861209,
        10.057720858501305,
        10.062921570141404,
        10.0681222817815,
        10.073322993421598,
        10.078523705061695,
        10.083724416701793,
        10.08892512834189,
        10.094125839981988,
        10.099326551622084,
        10.104527263262183,
        10.10972797490228,
        10.114928686542378,
        10.120129398182474,
        10.125330109822572,
        10.130530821462669,
        10.135731533102767,
        10.140932244742864,
        10.146132956382962,
        10.151333668023058,
        10.156534379663157,
        10.161735091303253,
        10.166935802943351,
        10.172136514583448,
        10.177337226223546,
        10.182537937863643,
        10.187042999201802,
        10.191548060539962,
        10.196053121878121,
        10.200558183216279,
        10.205063244554438,
        10.209568305892597,
        10.214073367230757,
        10.218578428568916,
        10.223083489907076,
        10.227588551245233,
        10.232093612583393,
        10.236598673921552,
        10.241103735259712,
        10.245608796597871,
        10.25011385793603,
        10.254618919274188,
        10.259123980612348,
        10.263629041950507,
        10.268134103288666,
        10.272639164626826,
        10.277144225964985,
        10.281649287303143,
        10.286154348641302,
        10.290659409979462,
        10.295164471317621,
      ],
      [
        7.120048480161402,
        7.167271016060415,
        7.2144935519594275,
        7.26171608785844,
        7.308938623757452,
        7.356161159656465,
        7.403383695555478,
        7.450606231454491,
        7.497828767353504,
        7.545051303252516,
        7.592273839151529,
        7.639496375050541,
        7.686718910949554,
        7.733941446848567,
        7.78116398274758,
        7.828386518646592,
        7.849820000277695,
        7.8712534819087985,
        7.892686963539902,
        7.914120445171005,
        7.9355539268021085,
        7.9569874084332115,
        7.978420890064315,
        7.9998543716954185,
        8.021287853326521,
        8.042721334957625,
        8.064154816588728,
        8.08558829821983,
        8.107021779850935,
        8.128455261482038,
        8.149888743113141,
        8.171322224744245,
        8.192755706375348,
        8.21418918800645,
        8.235622669637554,
        8.257056151268657,
        8.27848963289976,
        8.299923114530865,
        8.321356596161968,
        8.34279007779307,
        8.364223559424174,
        8.376761342438265,
        8.389299125452355,
        8.401836908466446,
        8.414374691480537,
        8.426912474494628,
        8.43945025750872,
        8.45198804052281,
        8.464525823536901,
        8.477063606550992,
        8.489601389565083,
        8.502139172579174,
        8.514676955593265,
        8.527214738607357,
        8.539752521621448,
        8.55229030463554,
        8.56482808764963,
        8.577365870663721,
        8.589903653677812,
        8.602441436691903,
        8.614979219705994,
        8.627517002720085,
        8.640054785734176,
        8.652592568748267,
        8.665130351762357,
        8.677668134776448,
        8.68656383339346,
        8.695459532010473,
        8.704355230627485,
        8.713250929244497,
        8.722146627861509,
        8.731042326478521,
        8.739938025095533,
        8.748833723712545,
        8.757729422329557,
        8.76662512094657,
        8.775520819563582,
        8.784416518180594,
        8.793312216797608,
        8.80220791541462,
        8.811103614031632,
        8.819999312648644,
        8.828895011265656,
        8.837790709882668,
        8.84668640849968,
        8.855582107116692,
        8.864477805733705,
        8.873373504350717,
        8.882269202967729,
        8.891164901584741,
        8.900060600201753,
        8.906960640110057,
        8.913860680018361,
        8.920760719926665,
        8.92766075983497,
        8.934560799743274,
        8.941460839651578,
        8.948360879559884,
        8.955260919468188,
        8.962160959376492,
        8.969060999284796,
        8.9759610391931,
        8.982861079101404,
        8.989761119009708,
        8.996661158918013,
        9.003561198826317,
        9.01046123873462,
        9.017361278642925,
        9.024261318551229,
        9.031161358459535,
        9.038061398367839,
        9.044961438276143,
        9.051861478184447,
        9.058761518092751,
        9.065661558001056,
        9.07256159790936,
        9.078199341015146,
        9.083837084120933,
        9.08947482722672,
        9.095112570332507,
        9.100750313438294,
        9.10638805654408,
        9.112025799649867,
        9.117663542755654,
        9.12330128586144,
        9.128939028967228,
        9.134576772073014,
        9.140214515178801,
        9.14585225828459,
        9.151490001390377,
        9.157127744496163,
        9.16276548760195,
        9.168403230707737,
        9.174040973813524,
        9.17967871691931,
        9.185316460025097,
        9.190954203130884,
        9.196591946236671,
        9.202229689342458,
        9.207867432448245,
        9.213505175554031,
        9.218271819431642,
        9.223038463309255,
        9.227805107186866,
        9.232571751064476,
        9.237338394942087,
        9.2421050388197,
        9.24687168269731,
        9.251638326574922,
        9.256404970452534,
        9.261171614330145,
        9.265938258207756,
        9.270704902085367,
        9.27547154596298,
        9.28023818984059,
        9.285004833718201,
        9.289771477595812,
        9.294538121473424,
        9.299304765351035,
        9.304071409228646,
        9.308838053106259,
        9.31360469698387,
        9.31837134086148,
        9.323137984739091,
        9.327904628616704,
        9.332671272494315,
        9.336800327233716,
        9.340929381973117,
        9.345058436712517,
        9.349187491451918,
        9.35331654619132,
        9.35744560093072,
        9.36157465567012,
        9.365703710409521,
        9.369832765148923,
        9.373961819888324,
        9.378090874627723,
        9.382219929367125,
        9.386348984106526,
        9.390478038845927,
        9.394607093585327,
        9.398736148324728,
        9.40286520306413,
        9.40699425780353,
        9.41112331254293,
        9.415252367282331,
        9.419381422021733,
        9.423510476761134,
        9.427639531500533,
        9.431768586239935,
        9.435897640979336,
      ],
      [
        5.80890348428124,
        5.8474300693297385,
        5.885956654378237,
        5.924483239426736,
        5.963009824475234,
        6.001536409523733,
        6.0400629945722315,
        6.07858957962073,
        6.117116164669229,
        6.155642749717727,
        6.194169334766226,
        6.2326959198147245,
        6.271222504863223,
        6.309749089911722,
        6.34827567496022,
        6.386802260008719,
        6.404288801915575,
        6.421775343822432,
        6.439261885729288,
        6.456748427636144,
        6.4742349695430015,
        6.491721511449858,
        6.509208053356715,
        6.526694595263571,
        6.544181137170427,
        6.561667679077284,
        6.57915422098414,
        6.596640762890996,
        6.6141273047978535,
        6.63161384670471,
        6.649100388611566,
        6.666586930518423,
        6.684073472425279,
        6.701560014332136,
        6.719046556238992,
        6.736533098145848,
        6.7540196400527055,
        6.771506181959562,
        6.788992723866418,
        6.806479265773275,
        6.823965807680131,
        6.834194778962932,
        6.844423750245732,
        6.854652721528532,
        6.864881692811332,
        6.875110664094133,
        6.885339635376933,
        6.895568606659733,
        6.905797577942534,
        6.916026549225334,
        6.926255520508135,
        6.936484491790934,
        6.946713463073735,
        6.956942434356535,
        6.967171405639336,
        6.9774003769221355,
        6.987629348204936,
        6.997858319487737,
        7.008087290770537,
        7.018316262053337,
        7.028545233336137,
        7.038774204618938,
        7.049003175901738,
        7.059232147184538,
        7.069461118467339,
        7.079690089750139,
        7.086947660374196,
        7.094205230998252,
        7.101462801622309,
        7.108720372246365,
        7.115977942870422,
        7.123235513494478,
        7.130493084118534,
        7.13775065474259,
        7.145008225366647,
        7.1522657959907034,
        7.15952336661476,
        7.1667809372388165,
        7.174038507862873,
        7.1812960784869295,
        7.188553649110986,
        7.195811219735043,
        7.203068790359099,
        7.210326360983156,
        7.217583931607211,
        7.224841502231268,
        7.232099072855324,
        7.239356643479381,
        7.246614214103437,
        7.253871784727494,
        7.26112935535155,
        7.266758764473793,
        7.272388173596035,
        7.278017582718277,
        7.28364699184052,
        7.289276400962763,
        7.294905810085004,
        7.300535219207247,
        7.30616462832949,
        7.311794037451731,
        7.317423446573974,
        7.323052855696217,
        7.328682264818459,
        7.334311673940701,
        7.339941083062944,
        7.345570492185186,
        7.351199901307429,
        7.356829310429671,
        7.362458719551913,
        7.368088128674156,
        7.373717537796398,
        7.37934694691864,
        7.384976356040883,
        7.390605765163126,
        7.396235174285367,
        7.40186458340761,
        7.406464145568168,
        7.411063707728726,
        7.415663269889284,
        7.420262832049842,
        7.4248623942103995,
        7.429461956370957,
        7.434061518531515,
        7.438661080692073,
        7.443260642852631,
        7.447860205013189,
        7.452459767173747,
        7.457059329334305,
        7.461658891494863,
        7.466258453655421,
        7.4708580158159785,
        7.475457577976536,
        7.480057140137094,
        7.484656702297652,
        7.48925626445821,
        7.493855826618768,
        7.498455388779326,
        7.503054950939884,
        7.507654513100442,
        7.512254075261,
        7.5168536374215575,
        7.520742511817025,
        7.524631386212492,
        7.528520260607959,
        7.532409135003426,
        7.536298009398893,
        7.54018688379436,
        7.544075758189827,
        7.547964632585294,
        7.551853506980761,
        7.555742381376228,
        7.559631255771695,
        7.563520130167162,
        7.56740900456263,
        7.571297878958097,
        7.575186753353564,
        7.579075627749031,
        7.582964502144498,
        7.586853376539965,
        7.590742250935432,
        7.594631125330899,
        7.598519999726366,
        7.602408874121833,
        7.6062977485173,
        7.610186622912767,
        7.614075497308234,
        7.617444193536824,
        7.620812889765413,
        7.624181585994003,
        7.627550282222592,
        7.630918978451181,
        7.63428767467977,
        7.63765637090836,
        7.641025067136949,
        7.644393763365539,
        7.647762459594128,
        7.651131155822718,
        7.654499852051307,
        7.657868548279896,
        7.661237244508485,
        7.664605940737075,
        7.667974636965664,
        7.671343333194254,
        7.674712029422843,
        7.678080725651433,
        7.681449421880022,
        7.684818118108611,
        7.6881868143372,
        7.69155551056579,
        7.694924206794379,
        7.698292903022969,
      ],
      [
        4.683632194323167,
        4.714695604923593,
        4.74575901552402,
        4.776822426124446,
        4.807885836724873,
        4.838949247325299,
        4.8700126579257255,
        4.9010760685261525,
        4.932139479126579,
        4.963202889727006,
        4.994266300327432,
        5.025329710927858,
        5.056393121528285,
        5.087456532128711,
        5.118519942729138,
        5.1495833533295645,
        5.163682491121053,
        5.17778162891254,
        5.1918807667040285,
        5.205979904495516,
        5.220079042287004,
        5.234178180078492,
        5.24827731786998,
        5.262376455661467,
        5.276475593452956,
        5.290574731244443,
        5.304673869035931,
        5.318773006827419,
        5.332872144618907,
        5.3469712824103945,
        5.361070420201883,
        5.37516955799337,
        5.389268695784859,
        5.403367833576346,
        5.417466971367834,
        5.431566109159322,
        5.44566524695081,
        5.459764384742297,
        5.473863522533786,
        5.487962660325273,
        5.502061798116761,
        5.510309265017282,
        5.518556731917803,
        5.526804198818324,
        5.5350516657188455,
        5.543299132619366,
        5.551546599519887,
        5.559794066420408,
        5.568041533320929,
        5.57628900022145,
        5.584536467121971,
        5.592783934022492,
        5.601031400923013,
        5.609278867823534,
        5.6175263347240545,
        5.625773801624575,
        5.634021268525097,
        5.642268735425618,
        5.650516202326139,
        5.658763669226659,
        5.66701113612718,
        5.675258603027701,
        5.683506069928223,
        5.6917535368287435,
        5.700001003729264,
        5.708248470629785,
        5.714100141520752,
        5.719951812411719,
        5.725803483302686,
        5.7316551541936525,
        5.73750682508462,
        5.743358495975587,
        5.749210166866554,
        5.755061837757521,
        5.760913508648487,
        5.766765179539455,
        5.772616850430421,
        5.778468521321388,
        5.7843201922123555,
        5.790171863103322,
        5.796023533994289,
        5.801875204885256,
        5.807726875776223,
        5.813578546667189,
        5.819430217558157,
        5.8252818884491235,
        5.831133559340091,
        5.836985230231058,
        5.842836901122024,
        5.848688572012992,
        5.854540242903958,
        5.859079151472726,
        5.863618060041494,
        5.868156968610262,
        5.872695877179031,
        5.877234785747799,
        5.881773694316567,
        5.886312602885335,
        5.890851511454103,
        5.895390420022871,
        5.899929328591639,
        5.904468237160407,
        5.909007145729175,
        5.913546054297943,
        5.918084962866711,
        5.922623871435479,
        5.927162780004248,
        5.931701688573016,
        5.936240597141784,
        5.940779505710552,
        5.94531841427932,
        5.9498573228480875,
        5.954396231416856,
        5.958935139985624,
        5.963474048554392,
        5.96801295712316,
        5.971721515454913,
        5.975430073786666,
        5.979138632118419,
        5.982847190450172,
        5.9865557487819245,
        5.990264307113677,
        5.99397286544543,
        5.997681423777183,
        6.001389982108936,
        6.005098540440689,
        6.008807098772442,
        6.012515657104195,
        6.016224215435947,
        6.0199327737676995,
        6.023641332099452,
        6.027349890431205,
        6.031058448762958,
        6.034767007094711,
        6.038475565426464,
        6.042184123758217,
        6.04589268208997,
        6.0496012404217225,
        6.053309798753475,
        6.057018357085228,
        6.060726915416981,
        6.063862456809186,
        6.0669979982013915,
        6.070133539593597,
        6.073269080985802,
        6.076404622378007,
        6.079540163770212,
        6.082675705162417,
        6.085811246554623,
        6.088946787946828,
        6.092082329339033,
        6.095217870731238,
        6.098353412123443,
        6.1014889535156485,
        6.104624494907854,
        6.107760036300059,
        6.110895577692264,
        6.114031119084469,
        6.117166660476674,
        6.1203022018688795,
        6.123437743261085,
        6.12657328465329,
        6.129708826045495,
        6.1328443674377,
        6.135979908829905,
        6.139115450222111,
        6.141831579720872,
        6.144547709219634,
        6.147263838718396,
        6.149979968217158,
        6.1526960977159195,
        6.155412227214682,
        6.158128356713443,
        6.160844486212206,
        6.163560615710967,
        6.1662767452097285,
        6.168992874708491,
        6.171709004207252,
        6.1744251337060145,
        6.177141263204776,
        6.179857392703538,
        6.1825735222023,
        6.185289651701061,
        6.1880057811998235,
        6.190721910698585,
        6.193438040197347,
        6.196154169696109,
        6.198870299194871,
        6.201586428693632,
        6.204302558192395,
        6.207018687691156,
      ],
      [
        4.488872691519513,
        4.518644392149363,
        4.548416092779211,
        4.578187793409061,
        4.6079594940389095,
        4.637731194668759,
        4.6675028952986075,
        4.697274595928457,
        4.7270462965583055,
        4.756817997188155,
        4.786589697818004,
        4.816361398447853,
        4.846133099077702,
        4.875904799707551,
        4.9056765003374,
        4.935448200967249,
        4.948961054235892,
        4.962473907504535,
        4.975986760773178,
        4.98949961404182,
        5.003012467310463,
        5.016525320579106,
        5.030038173847749,
        5.043551027116392,
        5.0570638803850345,
        5.070576733653677,
        5.08408958692232,
        5.097602440190963,
        5.111115293459607,
        5.1246281467282495,
        5.138140999996892,
        5.151653853265535,
        5.165166706534178,
        5.178679559802821,
        5.192192413071464,
        5.205705266340106,
        5.219218119608749,
        5.232730972877392,
        5.246243826146035,
        5.259756679414678,
        5.2732695326833205,
        5.281174045123224,
        5.289078557563127,
        5.296983070003031,
        5.304887582442935,
        5.3127920948828375,
        5.320696607322741,
        5.328601119762644,
        5.336505632202548,
        5.344410144642452,
        5.3523146570823545,
        5.360219169522258,
        5.368123681962162,
        5.376028194402065,
        5.383932706841969,
        5.391837219281872,
        5.399741731721775,
        5.407646244161679,
        5.415550756601583,
        5.423455269041486,
        5.431359781481389,
        5.439264293921292,
        5.447168806361196,
        5.4550733188011,
        5.462977831241003,
        5.470882343680906,
        5.4764906845096455,
        5.4820990253383854,
        5.4877073661671245,
        5.4933157069958645,
        5.4989240478246035,
        5.504532388653343,
        5.5101407294820826,
        5.515749070310822,
        5.521357411139562,
        5.526965751968301,
        5.53257409279704,
        5.53818243362578,
        5.543790774454519,
        5.549399115283259,
        5.555007456111998,
        5.560615796940737,
        5.566224137769477,
        5.571832478598216,
        5.577440819426956,
        5.583049160255695,
        5.588657501084434,
        5.594265841913174,
        5.599874182741913,
        5.605482523570653,
        5.611090864399392,
        5.6154410315086585,
        5.619791198617925,
        5.6241413657271915,
        5.628491532836459,
        5.632841699945725,
        5.637191867054992,
        5.641542034164258,
        5.645892201273525,
        5.650242368382791,
        5.654592535492059,
        5.658942702601325,
        5.663292869710592,
        5.667643036819858,
        5.671993203929125,
        5.676343371038391,
        5.680693538147659,
        5.685043705256925,
        5.689393872366192,
        5.693744039475458,
        5.698094206584725,
        5.702444373693991,
        5.7067945408032585,
        5.711144707912525,
        5.7154948750217915,
        5.719845042131058,
        5.723399387461694,
        5.726953732792332,
        5.730508078122968,
        5.734062423453605,
        5.737616768784242,
        5.741171114114878,
        5.744725459445515,
        5.748279804776152,
        5.751834150106789,
        5.755388495437425,
        5.758942840768062,
        5.762497186098699,
        5.766051531429335,
        5.7696058767599725,
        5.773160222090609,
        5.776714567421245,
        5.7802689127518825,
        5.783823258082519,
        5.787377603413156,
        5.7909319487437925,
        5.794486294074429,
        5.798040639405066,
        5.8015949847357025,
        5.80514933006634,
        5.808703675396976,
        5.8117088315545535,
        5.814713987712131,
        5.817719143869709,
        5.820724300027287,
        5.823729456184864,
        5.826734612342442,
        5.829739768500019,
        5.8327449246575975,
        5.835750080815175,
        5.8387552369727524,
        5.84176039313033,
        5.844765549287907,
        5.847770705445486,
        5.850775861603063,
        5.853781017760641,
        5.856786173918218,
        5.859791330075796,
        5.862796486233374,
        5.865801642390951,
        5.868806798548529,
        5.871811954706106,
        5.874817110863684,
        5.877822267021262,
        5.8808274231788396,
        5.883832579336417,
        5.886435764007579,
        5.88903894867874,
        5.891642133349903,
        5.894245318021064,
        5.896848502692226,
        5.8994516873633875,
        5.90205487203455,
        5.904658056705712,
        5.907261241376873,
        5.909864426048035,
        5.912467610719197,
        5.915070795390359,
        5.917673980061521,
        5.920277164732682,
        5.922880349403845,
        5.925483534075006,
        5.928086718746168,
        5.9306899034173295,
        5.933293088088492,
        5.935896272759654,
        5.938499457430815,
        5.941102642101977,
        5.943705826773139,
        5.946309011444301,
        5.9489121961154625,
      ],
      [
        4.569955512493516,
        4.600264981431391,
        4.630574450369266,
        4.660883919307141,
        4.691193388245016,
        4.72150285718289,
        4.751812326120765,
        4.78212179505864,
        4.8124312639965146,
        4.84274073293439,
        4.873050201872265,
        4.903359670810139,
        4.933669139748014,
        4.963978608685889,
        4.9942880776237635,
        5.024597546561639,
        5.038354483442744,
        5.052111420323849,
        5.065868357204954,
        5.079625294086059,
        5.093382230967164,
        5.107139167848269,
        5.120896104729375,
        5.13465304161048,
        5.148409978491585,
        5.16216691537269,
        5.175923852253795,
        5.1896807891349,
        5.203437726016005,
        5.21719466289711,
        5.230951599778215,
        5.24470853665932,
        5.258465473540425,
        5.27222241042153,
        5.285979347302636,
        5.299736284183741,
        5.313493221064846,
        5.327250157945951,
        5.341007094827056,
        5.354764031708161,
        5.368520968589266,
        5.3765682607895,
        5.384615552989735,
        5.392662845189969,
        5.400710137390203,
        5.4087574295904375,
        5.4168047217906725,
        5.424852013990907,
        5.432899306191141,
        5.440946598391376,
        5.44899389059161,
        5.457041182791844,
        5.465088474992078,
        5.473135767192313,
        5.4811830593925475,
        5.489230351592782,
        5.497277643793016,
        5.505324935993251,
        5.513372228193485,
        5.521419520393719,
        5.529466812593954,
        5.537514104794188,
        5.545561396994422,
        5.5536086891946566,
        5.561655981394892,
        5.569703273595126,
        5.575412918275997,
        5.5811225629568675,
        5.586832207637738,
        5.592541852318608,
        5.598251496999479,
        5.60396114168035,
        5.609670786361221,
        5.615380431042092,
        5.621090075722963,
        5.626799720403833,
        5.632509365084704,
        5.638219009765574,
        5.643928654446445,
        5.649638299127316,
        5.655347943808187,
        5.661057588489057,
        5.666767233169928,
        5.672476877850799,
        5.67818652253167,
        5.6838961672125405,
        5.689605811893411,
        5.695315456574281,
        5.701025101255152,
        5.706734745936023,
        5.712444390616894,
        5.716873135098514,
        5.721301879580134,
        5.725730624061754,
        5.730159368543373,
        5.734588113024993,
        5.739016857506613,
        5.743445601988233,
        5.747874346469852,
        5.7523030909514725,
        5.756731835433093,
        5.761160579914712,
        5.765589324396332,
        5.770018068877952,
        5.774446813359572,
        5.778875557841191,
        5.7833043023228115,
        5.787733046804432,
        5.792161791286051,
        5.796590535767671,
        5.801019280249291,
        5.805448024730911,
        5.80987676921253,
        5.81430551369415,
        5.81873425817577,
        5.82316300265739,
        5.826781550376005,
        5.830400098094619,
        5.8340186458132335,
        5.837637193531848,
        5.841255741250462,
        5.844874288969077,
        5.848492836687691,
        5.852111384406306,
        5.8557299321249205,
        5.8593484798435345,
        5.862967027562149,
        5.866585575280764,
        5.870204122999378,
        5.873822670717993,
        5.877441218436608,
        5.8810597661552215,
        5.884678313873836,
        5.888296861592451,
        5.891915409311065,
        5.89553395702968,
        5.899152504748294,
        5.902771052466909,
        5.906389600185523,
        5.910008147904137,
        5.913626695622752,
        5.916686134125914,
        5.9197455726290755,
        5.922805011132238,
        5.925864449635399,
        5.928923888138561,
        5.931983326641722,
        5.9350427651448845,
        5.938102203648046,
        5.941161642151208,
        5.944221080654369,
        5.947280519157531,
        5.950339957660693,
        5.953399396163855,
        5.956458834667016,
        5.959518273170178,
        5.962577711673339,
        5.9656371501765015,
        5.968696588679663,
        5.971756027182825,
        5.974815465685986,
        5.977874904189148,
        5.98093434269231,
        5.983993781195472,
        5.987053219698633,
        5.990112658201795,
        5.992762864379504,
        5.9954130705572135,
        5.998063276734922,
        6.000713482912632,
        6.003363689090341,
        6.006013895268049,
        6.008664101445759,
        6.011314307623468,
        6.013964513801177,
        6.016614719978886,
        6.019264926156595,
        6.021915132334304,
        6.024565338512013,
        6.027215544689723,
        6.029865750867431,
        6.03251595704514,
        6.03516616322285,
        6.037816369400558,
        6.040466575578268,
        6.043116781755977,
        6.0457669879336855,
        6.048417194111395,
        6.051067400289104,
        6.0537176064668135,
        6.056367812644522,
      ],
      [
        5.916878952915801,
        5.956121667279713,
        5.995364381643625,
        6.034607096007536,
        6.073849810371447,
        6.113092524735359,
        6.152335239099271,
        6.191577953463183,
        6.2308206678270945,
        6.270063382191006,
        6.309306096554918,
        6.34854881091883,
        6.387791525282741,
        6.427034239646653,
        6.4662769540105645,
        6.505519668374476,
        6.523331248829882,
        6.541142829285287,
        6.558954409740693,
        6.576765990196098,
        6.594577570651504,
        6.6123891511069095,
        6.630200731562314,
        6.64801231201772,
        6.665823892473125,
        6.683635472928531,
        6.701447053383936,
        6.719258633839342,
        6.737070214294747,
        6.754881794750153,
        6.7726933752055585,
        6.790504955660964,
        6.8083165361163696,
        6.826128116571775,
        6.84393969702718,
        6.861751277482585,
        6.879562857937991,
        6.897374438393396,
        6.915186018848802,
        6.9329975993042074,
        6.950809179759613,
        6.961228286404603,
        6.971647393049593,
        6.982066499694583,
        6.992485606339573,
        7.002904712984563,
        7.013323819629553,
        7.023742926274543,
        7.034162032919533,
        7.044581139564523,
        7.055000246209513,
        7.065419352854503,
        7.075838459499493,
        7.086257566144483,
        7.096676672789473,
        7.107095779434463,
        7.117514886079453,
        7.127933992724443,
        7.138353099369433,
        7.1487722060144225,
        7.159191312659413,
        7.169610419304403,
        7.180029525949393,
        7.190448632594383,
        7.2008677392393725,
        7.211286845884363,
        7.218679319694778,
        7.226071793505194,
        7.233464267315609,
        7.2408567411260245,
        7.24824921493644,
        7.255641688746856,
        7.263034162557271,
        7.270426636367686,
        7.277819110178102,
        7.285211583988517,
        7.292604057798933,
        7.299996531609348,
        7.307389005419764,
        7.314781479230179,
        7.322173953040594,
        7.3295664268510095,
        7.3369589006614255,
        7.344351374471841,
        7.351743848282256,
        7.359136322092672,
        7.366528795903087,
        7.373921269713502,
        7.381313743523918,
        7.388706217334334,
        7.396098691144749,
        7.40183273930769,
        7.407566787470632,
        7.413300835633574,
        7.419034883796516,
        7.424768931959457,
        7.430502980122399,
        7.436237028285341,
        7.441971076448283,
        7.4477051246112245,
        7.453439172774166,
        7.459173220937108,
        7.46490726910005,
        7.4706413172629915,
        7.476375365425933,
        7.4821094135888755,
        7.487843461751817,
        7.493577509914759,
        7.4993115580777,
        7.5050456062406425,
        7.510779654403584,
        7.516513702566526,
        7.522247750729467,
        7.52798179889241,
        7.533715847055351,
        7.539449895218293,
        7.544134953700341,
        7.548820012182389,
        7.553505070664437,
        7.558190129146486,
        7.562875187628534,
        7.567560246110582,
        7.572245304592631,
        7.576930363074679,
        7.5816154215567275,
        7.586300480038775,
        7.590985538520823,
        7.595670597002872,
        7.60035565548492,
        7.605040713966969,
        7.609725772449017,
        7.614410830931065,
        7.6190958894131136,
        7.6237809478951615,
        7.62846600637721,
        7.633151064859258,
        7.637836123341306,
        7.642521181823355,
        7.647206240305403,
        7.651891298787452,
        7.6565763572695,
        7.660537517774806,
        7.664498678280113,
        7.668459838785419,
        7.6724209992907255,
        7.676382159796032,
        7.680343320301339,
        7.684304480806645,
        7.6882656413119514,
        7.692226801817259,
        7.696187962322565,
        7.700149122827871,
        7.704110283333177,
        7.7080714438384845,
        7.712032604343791,
        7.715993764849097,
        7.719954925354403,
        7.7239160858597105,
        7.727877246365017,
        7.731838406870323,
        7.73579956737563,
        7.739760727880936,
        7.743721888386243,
        7.747683048891549,
        7.751644209396856,
        7.755605369902162,
        7.759036683207271,
        7.76246799651238,
        7.765899309817489,
        7.769330623122598,
        7.772761936427707,
        7.776193249732816,
        7.779624563037925,
        7.783055876343034,
        7.786487189648143,
        7.789918502953252,
        7.7933498162583605,
        7.7967811295634695,
        7.800212442868579,
        7.803643756173688,
        7.807075069478797,
        7.810506382783906,
        7.813937696089015,
        7.817369009394124,
        7.820800322699233,
        7.824231636004342,
        7.827662949309451,
        7.83109426261456,
        7.834525575919669,
        7.8379568892247775,
        7.841388202529886,
      ],
      [
        7.177685678044238,
        7.2252904830603395,
        7.272895288076441,
        7.320500093092543,
        7.368104898108644,
        7.415709703124746,
        7.463314508140847,
        7.510919313156949,
        7.558524118173049,
        7.606128923189151,
        7.653733728205252,
        7.701338533221354,
        7.7489433382374555,
        7.796548143253557,
        7.844152948269659,
        7.89175775328576,
        7.913364740171151,
        7.934971727056541,
        7.956578713941933,
        7.978185700827323,
        7.999792687712714,
        8.021399674598104,
        8.043006661483496,
        8.064613648368885,
        8.086220635254277,
        8.107827622139668,
        8.129434609025058,
        8.15104159591045,
        8.17264858279584,
        8.19425556968123,
        8.215862556566622,
        8.237469543452011,
        8.259076530337403,
        8.280683517222794,
        8.302290504108184,
        8.323897490993575,
        8.345504477878965,
        8.367111464764356,
        8.388718451649748,
        8.410325438535137,
        8.431932425420529,
        8.444571702502056,
        8.457210979583584,
        8.469850256665111,
        8.482489533746639,
        8.495128810828165,
        8.507768087909692,
        8.52040736499122,
        8.533046642072748,
        8.545685919154275,
        8.558325196235803,
        8.57096447331733,
        8.583603750398858,
        8.596243027480384,
        8.608882304561911,
        8.621521581643439,
        8.634160858724966,
        8.646800135806494,
        8.659439412888021,
        8.672078689969549,
        8.684717967051077,
        8.697357244132602,
        8.70999652121413,
        8.722635798295657,
        8.735275075377185,
        8.747914352458713,
        8.756882062262576,
        8.765849772066439,
        8.774817481870302,
        8.783785191674166,
        8.79275290147803,
        8.801720611281892,
        8.810688321085756,
        8.81965603088962,
        8.828623740693482,
        8.837591450497346,
        8.84655916030121,
        8.855526870105074,
        8.864494579908936,
        8.8734622897128,
        8.882429999516663,
        8.891397709320527,
        8.90036541912439,
        8.909333128928253,
        8.918300838732117,
        8.927268548535979,
        8.936236258339843,
        8.945203968143707,
        8.95417167794757,
        8.963139387751433,
        8.972107097555297,
        8.979062993679566,
        8.986018889803837,
        8.992974785928107,
        8.999930682052376,
        9.006886578176648,
        9.013842474300917,
        9.020798370425187,
        9.027754266549458,
        9.034710162673727,
        9.041666058797997,
        9.048621954922268,
        9.055577851046538,
        9.062533747170807,
        9.069489643295077,
        9.076445539419348,
        9.083401435543617,
        9.090357331667887,
        9.097313227792158,
        9.104269123916428,
        9.111225020040697,
        9.118180916164969,
        9.125136812289238,
        9.132092708413508,
        9.139048604537779,
        9.146004500662048,
        9.151687881619306,
        9.157371262576563,
        9.16305464353382,
        9.168738024491079,
        9.174421405448335,
        9.180104786405593,
        9.18578816736285,
        9.191471548320107,
        9.197154929277366,
        9.202838310234622,
        9.20852169119188,
        9.214205072149138,
        9.219888453106394,
        9.225571834063652,
        9.23125521502091,
        9.236938595978167,
        9.242621976935425,
        9.248305357892683,
        9.253988738849939,
        9.259672119807197,
        9.265355500764453,
        9.271038881721712,
        9.27672226267897,
        9.282405643636226,
        9.288089024593484,
        9.292894254724711,
        9.297699484855938,
        9.302504714987165,
        9.307309945118392,
        9.312115175249618,
        9.316920405380845,
        9.321725635512072,
        9.3265308656433,
        9.331336095774526,
        9.336141325905754,
        9.34094655603698,
        9.345751786168208,
        9.350557016299433,
        9.35536224643066,
        9.360167476561887,
        9.364972706693115,
        9.369777936824342,
        9.374583166955569,
        9.379388397086796,
        9.384193627218023,
        9.388998857349248,
        9.393804087480476,
        9.398609317611703,
        9.40341454774293,
        9.408219777874157,
        9.412382257546794,
        9.41654473721943,
        9.420707216892065,
        9.424869696564702,
        9.429032176237339,
        9.433194655909976,
        9.437357135582612,
        9.441519615255247,
        9.445682094927884,
        9.44984457460052,
        9.454007054273157,
        9.458169533945794,
        9.462332013618429,
        9.466494493291066,
        9.470656972963702,
        9.47481945263634,
        9.478981932308976,
        9.48314441198161,
        9.487306891654248,
        9.491469371326884,
        9.495631850999521,
        9.499794330672158,
        9.503956810344793,
        9.50811929001743,
        9.512281769690066,
      ],
      [
        7.833875659287981,
        7.885832535084526,
        7.937789410881072,
        7.989746286677617,
        8.041703162474162,
        8.093660038270707,
        8.145616914067254,
        8.1975737898638,
        8.249530665660345,
        8.30148754145689,
        8.353444417253435,
        8.40540129304998,
        8.457358168846525,
        8.509315044643072,
        8.561271920439617,
        8.613228796236163,
        8.636811111793639,
        8.660393427351115,
        8.683975742908592,
        8.707558058466068,
        8.731140374023544,
        8.75472268958102,
        8.778305005138497,
        8.801887320695974,
        8.82546963625345,
        8.849051951810926,
        8.872634267368403,
        8.896216582925879,
        8.919798898483354,
        8.94338121404083,
        8.966963529598306,
        8.990545845155783,
        9.014128160713259,
        9.037710476270735,
        9.061292791828212,
        9.084875107385688,
        9.108457422943165,
        9.13203973850064,
        9.155622054058117,
        9.179204369615594,
        9.20278668517307,
        9.216581455454367,
        9.230376225735663,
        9.24417099601696,
        9.257965766298257,
        9.271760536579555,
        9.28555530686085,
        9.299350077142147,
        9.313144847423445,
        9.32693961770474,
        9.340734387986037,
        9.354529158267335,
        9.368323928548632,
        9.382118698829927,
        9.395913469111225,
        9.409708239392522,
        9.42350300967382,
        9.437297779955115,
        9.451092550236412,
        9.46488732051771,
        9.478682090799005,
        9.492476861080302,
        9.5062716313616,
        9.520066401642897,
        9.533861171924192,
        9.54765594220549,
        9.557443487481668,
        9.56723103275785,
        9.577018578034028,
        9.586806123310208,
        9.596593668586388,
        9.606381213862567,
        9.616168759138748,
        9.625956304414927,
        9.635743849691107,
        9.645531394967287,
        9.655318940243466,
        9.665106485519646,
        9.674894030795826,
        9.684681576072006,
        9.694469121348185,
        9.704256666624365,
        9.714044211900545,
        9.723831757176724,
        9.733619302452905,
        9.743406847729084,
        9.753194393005264,
        9.762981938281444,
        9.772769483557623,
        9.782557028833804,
        9.792344574109983,
        9.799936384030433,
        9.807528193950883,
        9.815120003871336,
        9.822711813791786,
        9.830303623712236,
        9.837895433632687,
        9.845487243553137,
        9.85307905347359,
        9.86067086339404,
        9.86826267331449,
        9.87585448323494,
        9.88344629315539,
        9.891038103075843,
        9.898629912996293,
        9.906221722916744,
        9.913813532837194,
        9.921405342757645,
        9.928997152678097,
        9.936588962598547,
        9.944180772518997,
        9.951772582439448,
        9.959364392359898,
        9.96695620228035,
        9.9745480122008,
        9.982139822121251,
        9.988342782482096,
        9.994545742842943,
        10.000748703203788,
        10.006951663564635,
        10.01315462392548,
        10.019357584286327,
        10.025560544647172,
        10.03176350500802,
        10.037966465368864,
        10.044169425729711,
        10.050372386090556,
        10.056575346451403,
        10.062778306812248,
        10.068981267173095,
        10.07518422753394,
        10.081387187894787,
        10.087590148255632,
        10.09379310861648,
        10.099996068977324,
        10.106199029338171,
        10.112401989699016,
        10.118604950059863,
        10.124807910420708,
        10.131010870781555,
        10.1372138311424,
        10.142458359399948,
        10.147702887657495,
        10.152947415915042,
        10.15819194417259,
        10.163436472430137,
        10.168681000687684,
        10.173925528945231,
        10.179170057202779,
        10.184414585460326,
        10.189659113717873,
        10.19490364197542,
        10.200148170232968,
        10.205392698490515,
        10.210637226748062,
        10.21588175500561,
        10.221126283263157,
        10.226370811520704,
        10.231615339778251,
        10.236859868035799,
        10.242104396293346,
        10.247348924550893,
        10.25259345280844,
        10.257837981065988,
        10.263082509323535,
        10.268327037581082,
        10.272870054599714,
        10.277413071618346,
        10.281956088636978,
        10.286499105655611,
        10.291042122674243,
        10.295585139692875,
        10.300128156711507,
        10.304671173730139,
        10.30921419074877,
        10.313757207767404,
        10.318300224786036,
        10.322843241804668,
        10.3273862588233,
        10.331929275841931,
        10.336472292860563,
        10.341015309879197,
        10.345558326897828,
        10.35010134391646,
        10.354644360935092,
        10.359187377953724,
        10.363730394972356,
        10.36827341199099,
        10.372816429009621,
        10.377359446028253,
        10.381902463046885,
      ],
    ],
    dimensions: ['month', 'level'],
    attributes: {
      vartype: 'f8',
      units: 'm.s-1',
      description: 'mean value of magw',
      standard_name: 'magw_mean',
    },
  },
  global_attributes: {
    statistic: 'Mean vertical profile',
    history: '2020-03-25 19:58: generated by Open Ocean',
    location_name: 'test',
    longitude: "-18.45703 (18°27'25''W)",
    latitude: "15.42191 (15°25'18''N)",
    srs: 'EPSG:4326',
    period: '1979-01-01 00:00 - 2019-03-10 23:00',
    WIND_dataset: 'CFSR~CFSV2%CFSR3D',
    WIND_node_longitude: "-18.50000 (18°30'W)",
    WIND_node_latitude: "15.50000 (15°30'N)",
    WIND_node_distance: '9.8 km',
    sampling_period: '1h',
    depth: 2933.670347588957,
  },
};
