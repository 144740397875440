import { makeStyles } from '@material-ui/core/styles';
const buttonsBlockHeight = 178;
const tabsHeight = 75;
const collapseHeaderHeight = 48;
const sideBarPadding = 90;

export const useStyles = makeStyles(
  ({ navbarHeight, projectMenuHeight, palette, defaultTransition }) => ({
    container: {
      maxHeight: `calc(100vh - ${buttonsBlockHeight +
        tabsHeight +
        navbarHeight +
        projectMenuHeight +
        collapseHeaderHeight +
        sideBarPadding}px)`,
      overflowY: 'auto',
    },
    pointItem: {
      cursor: 'pointer',
      transition: defaultTransition,
      padding: 16,
      background: palette.sinay.primary['100'],
      color: palette.black.main,
      '&$pointItemSelected': {
        background: palette.sinay.primary['40'],
        color: palette.white.main,
        '&:hover': {
          background: palette.sinay.primary['50'],
        },
      },

      '&:hover': {
        background: palette.sinay.primary['95'],
      },
    },
    itemName: {
      fontSize: 16,
    },
    itemCoordinate: {
      color: palette.grey.main,
      fontSize: 14,
    },
    pointIcon: {
      height: 38,
    },
    iconImage: {
      paddingRight: 10,
      height: '100%',
    },
    pointItemSelected: {},
    deleteIcon: {
      width: 20,
    },
    deleteIconButton: {
      '& :hover': {
        width: 25,
        backgroundColor: palette.sinay.neutral['90'],
        borderRadius: 50,
        padding: 2,
      },
    },
    deleteIconButtonSelectedPoint: {
      '& :hover': {
        backgroundColor: palette.sinay.primary['70'],
      },
    },
  })
);
