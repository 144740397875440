import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { isError, selectUserDetail } from 'ducks/usage/selectors';
import { ROUTES } from 'constants/routes';
import DetailUserComponent from 'components/usage/DetailUser';
import { requestUserDetail } from 'ducks/usage/actions';
/**
 * UsagePage container
 * Display usage informations
 */
const DetailUser = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector(selectUserDetail(id));
  const history = useHistory();
  const failed = useSelector(isError);

  useEffect(() => {
    if (failed) {
      history.replace(ROUTES.home);
    }
  }, [failed, history]);

  useEffect(() => {
    dispatch(requestUserDetail(id));
  }, [id, dispatch]);

  return <DetailUserComponent user={user} />;
};

export default DetailUser;
