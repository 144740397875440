import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  AREA_GROUP_NAME,
  MAP_LAYERS,
  MAP_PROPERTIES,
  POINTS_GROUP_NAME,
  STAT2D_Z_INDEX,
} from 'constants/map';
import { SEARCH_API_URL } from 'common/config';
import ManageGeometriesButton from 'containers/buttons/ManageGeometries';
import {
  Map,
  MapGraticule,
  MapMouseCoordinates,
  MapExtraTilesControl,
  MapLegend,
  MapSearchControl,
  MapMeasureControl,
  MapColorBarControl,
  MapGeometriesControl,
} from 'components/leaflet';
import ZoneCustomLayers from 'components/maps/ZoneCustomLayers';

/**
 * ProjectMap - map render project zone and project points.
 * @param {Object} project
 * @param {Array} geometries
 * @param {Array} zoneTileGeometries
 * @param {Boolean} allowTileGeometries
 * @param {React.ReactNode} children
 * @param {Object} props
 * @returns {jsx}
 */
const ProjectMap = ({
  project,
  children,
  geometries,
  zoneTileGeometries,
  allowTileGeometries = true,
  allowGeometriesControl = true,
  showTilesGeometries = true,
  showPointsLegend = true,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Map
      defaultLayer={MAP_LAYERS.worldImagery}
      position={project.zone.geom}
      {...props}
    >
      {children}
      <MapSearchControl
        url={SEARCH_API_URL}
        propertyLoc={[MAP_PROPERTIES.latitude, MAP_PROPERTIES.longitude]}
      />
      {showTilesGeometries ? <ZoneCustomLayers zone={project.zone} /> : ''}
      <MapMeasureControl />
      <MapGraticule />
      {allowGeometriesControl && (
        <MapGeometriesControl
          zIndex={STAT2D_Z_INDEX}
          geometries={geometries}
          multiply
        >
          <ManageGeometriesButton projectId={project.id} />
        </MapGeometriesControl>
      )}
      {allowTileGeometries && (
        <MapExtraTilesControl
          tileGeometries={zoneTileGeometries}
          zIndex={STAT2D_Z_INDEX}
        >
          {!zoneTileGeometries.length && (
            <Typography align="center">
              {t('projects.layersNotAvailable')}
            </Typography>
          )}
        </MapExtraTilesControl>
      )}
      <MapColorBarControl />
      <MapLegend
        defaultGroups={
          showPointsLegend
            ? [AREA_GROUP_NAME, POINTS_GROUP_NAME]
            : [AREA_GROUP_NAME]
        }
      />
      <MapMouseCoordinates />
    </Map>
  );
};

ProjectMap.propTypes = {
  project: PropTypes.shape({
    zone: PropTypes.object.isRequired,
    points: PropTypes.array.isRequired,
  }).isRequired,
  geometries: PropTypes.array.isRequired,
  zoneTileGeometries: PropTypes.array.isRequired,
  children: PropTypes.node,
  allowTileGeometries: PropTypes.bool,
};

export default React.memo(ProjectMap);
