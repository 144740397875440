import React, { memo } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';

const SimpleTable = ({ header, children, ...props }) => (
  <Table {...props}>
    <TableHead>{header}</TableHead>
    <TableBody>{children}</TableBody>
  </Table>
);
export default memo(SimpleTable);
