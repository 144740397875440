import { createContext } from 'react';

const context = createContext({
  instance: null,
  controls: null,
});

const { Provider, Consumer } = context;

export {
  Provider as MapProvider,
  Consumer as MapConsumer,
  context as MapContext,
};
