import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import BaseModal from 'components/common/BaseModal';
import { Loader } from 'components/common/Loader';

import { useStyles } from './styles';

/**
 * PlanningWaitingModal component
 * Displays modal while waiting for planning response
 * @param { string } fileName - loaded file name
 */
const PlanningWaitingModal = ({ fileName }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <BaseModal
      title={t('plannings.waitingModalTitle', { fileName })}
      modalClass={classes.container}
    >
      <Loader justifyCenter />
    </BaseModal>
  );
};

PlanningWaitingModal.propTypes = {
  fileName: PropTypes.string.isRequired,
};

export default memo(PlanningWaitingModal);
