import {
  STATUS_LOADING,
  STATUS_ERROR,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
  EMPTY_VALUE,
  EMPTY_ARRAY,
} from 'constants/common';
import { NO_ID } from 'constants/marineContractors';
import { prepareErrorShape } from 'helpers/apiErrors';
import createReducer from 'helpers/createReducer';

import {
  REQUEST_MARINE_CONTRACTORS,
  REQUEST_MARINE_CONTRACTORS_SUCCESS,
  REQUEST_MARINE_CONTRACTORS_ERROR,
  REQUEST_MARINE_CONTRACTORS_JOB,
  REQUEST_MARINE_CONTRACTORS_JOB_SUCCESS,
  REQUEST_MARINE_CONTRACTORS_JOB_ERROR,
  RESET_MARINE_CONTRACTORS_STATE,
  SET_MARINE_CONTRATORS_OPERATIONS,
  REMOVE_MARINE_CONTRACTORS_OPERATIONS,
  CREATE_MARINE_CONTRACTORS_BOAT_SUCCESS,
  CREATE_MARINE_CONTRACTORS_BOAT_ERROR,
  UPDATE_MARINE_CONTRACTORS_BOAT_SUCCESS,
  UPDATE_MARINE_CONTRACTORS_BOAT_ERROR,
  DELETE_MARINE_CONTRACTORS_BOAT_SUCCESS,
  CREATE_MARINE_CONTRACTORS_OPERATION_SUCCESS,
  CREATE_MARINE_CONTRACTORS_OPERATION_ERROR,
  UPDATE_MARINE_CONTRACTORS_OPERATION_SUCCESS,
  UPDATE_MARINE_CONTRACTORS_OPERATION_ERROR,
  DELETE_MARINE_CONTRACTORS_OPERATION_SUCCESS,
  CREATE_MARINE_CONTRACTORS_CONSTRAINT_ERROR,
  CREATE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS,
  UPDATE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS,
  UPDATE_MARINE_CONTRACTORS_CONSTRAINT_ERROR,
  DELETE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS,
  UPDATE_MARINE_CONTRACTORS_OPERATION,
  REQUEST_MARINE_CONTRACTORS_VARIABLES_SUCCESS,
  REQUEST_MARINE_CONTRACTORS_VARIABLES,
  REQUEST_MARINE_CONTRACTORS_VARIABLES_ERROR,
  REQUEST_MARINE_CONTRACTORS_CUSTOMER,
  REQUEST_MARINE_CONTRACTORS_CUSTOMER_ERROR,
  REQUEST_MARINE_CONTRACTORS_CUSTOMER_SUCCESS,
  UPDATE_MARINE_CONTRACTORS_CUSTOMER_PERCENTILES_ERROR,
  UPDATE_MARINE_CONTRACTORS_BOAT,
  CREATE_MARINE_CONTRACTORS_BOAT,
  CREATE_MARINE_CONTRACTORS_OPERATION,
  CREATE_MARINE_CONTRACTORS_TEMPORARY_BOAT,
  CREATE_MARINE_CONTRACTORS_TEMPORARY_CONSTRAINT,
  UPDATE_MARINE_CONTRACTORS_CONSTRAINT,
  CREATE_MARINE_CONTRACTORS_CONSTRAINT,
  CLEAN_ALL_ERRORS,
  CLEAN_BOAT_CREATE_ERROR,
  CLEAN_BOAT_UPDATE_ERROR,
  CLEAN_OPERATION_CREATE_ERROR,
  CLEAN_OPERATION_UPDATE_ERROR,
  CLEAN_OPERATIONS_UPDATE_ERROR,
  CLEAN_CONSTRAINT_CREATE_ERROR,
  CLEAN_CONSTRAINT_UPDATE_ERROR,
  CLEAN_PERCENTILES_ERROR,
  UPDATE_MARINE_CONTRACTORS_TEMPORARY_CONSTRAINT,
  CLEAN_JOB_STATUS,
  REQUEST_MARINE_CONTRACTORS_RESULTS_SUCCESS,
  REQUEST_MARINE_CONTRACTORS_RESULTS_ERROR,
} from './types';

// this two functions remove child object relationship
const removeOperationKey = (boat) => {
  const { operations, ...b } = boat;
  return b;
};
const removeConstraintKey = (operation) => {
  const { constraints, ...o } = operation;
  return o;
};

const initialState = {
  data: {
    boats: EMPTY_ARRAY,
    operations: EMPTY_ARRAY,
    constraints: EMPTY_ARRAY,
    customer: EMPTY_VALUE,
    job: EMPTY_VALUE,
    variables: EMPTY_ARRAY,
    units: EMPTY_ARRAY,
    results: EMPTY_VALUE,
  },
  status: {
    contractors: STATUS_NOT_REQUESTED,
    job: STATUS_NOT_REQUESTED,
    customer: STATUS_NOT_REQUESTED,
    variables: STATUS_NOT_REQUESTED,
    boats: EMPTY_ARRAY,
    operations: EMPTY_ARRAY,
    constraints: EMPTY_ARRAY,
    results: STATUS_NOT_REQUESTED,
  },
  errors: {
    boats: EMPTY_ARRAY,
    operations: EMPTY_ARRAY,
    constraints: EMPTY_ARRAY,
    percentiles: EMPTY_VALUE,
    results: EMPTY_VALUE,
  },
  projectId: EMPTY_VALUE,
  error: EMPTY_VALUE,
};

// set loading status when fetching data from API
const setLoadingConstractorsStatus = (state) => ({
  ...state,
  status: { ...state.status, contractors: STATUS_LOADING },
});
const setLoadingJobStatus = (state) => ({
  ...state,
  status: { ...state.status, job: STATUS_LOADING },
});
const setLoadingVariablesStatus = (state) => ({
  ...state,
  status: { ...state.status, variables: STATUS_LOADING },
});
const setLoadingCustomerStatus = (state) => ({
  ...state,
  status: { ...state.status, customer: STATUS_LOADING },
});

// set global error if error happened in API calls
const setContractorsError = (state, { error }) => ({
  ...state,
  error,
  status: {
    ...state.status,
    contractors: STATUS_ERROR,
  },
});
const setJobError = (state, { error }) => ({
  ...state,
  error,
  status: {
    ...state.status,
    job: STATUS_ERROR,
  },
});
const setVariablesError = (state, { error }) => ({
  ...state,
  error,
  status: {
    ...state.status,
    variables: STATUS_ERROR,
  },
});
const setCustomerError = (state, { error }) => ({
  ...state,
  error,
  status: {
    ...state.status,
    customer: STATUS_ERROR,
  },
});
const setResultsError = (state, { error }) => ({
  ...state,
  error,
  status: {
    ...state.status,
    results: STATUS_ERROR,
  },
});

/**
 * setContractors - set marine contractors data (boats/operations/constraints) in the store
 * Only one API call is done with all customer data
 * Data are separated in object group to facilitate data operations
 */
const setContractors = (state, { boats }) => ({
  ...state,
  data: {
    ...state.data,
    boats: [...boats.map((b) => removeOperationKey(b))],
    operations: [
      ...boats.reduce(
        (prev, curr) => [
          ...prev,
          ...curr.operations.map((o) => removeConstraintKey(o)),
        ],
        []
      ),
    ],
    constraints: [
      ...boats.reduce(
        (prev, curr) => [
          ...prev,
          ...curr.operations.reduce((p, c) => [...p, ...c.constraints], []),
        ],
        []
      ),
    ],
  },
  status: {
    ...state.status,
    contractors: STATUS_SUCCESS,
  },
  error: EMPTY_VALUE,
});

/**
 * setJob - set user preferences (operations selected, points selected etc...)
 */
const setJob = (state, { job, projectId }) => ({
  ...state,
  data: {
    ...state.data,
    job,
  },
  status: {
    ...state.status,
    job: STATUS_SUCCESS,
  },
  error: EMPTY_VALUE,
  projectId,
});

/**
 * setVariables - set variables and associated units for constraints
 * Only one API call is done by fetching variables and associated units
 * When data is available, we group variables by units and units by variables
 */
const setVariables = (state, { variables }) => ({
  ...state,
  data: {
    ...state.data,
    variables: [...variables].reduce(
      (prev, curr) => [
        ...prev,
        {
          name: curr.name,
          longName: curr.longName,
          units: curr.units.reduce((p, c) => [...p, c.name], []),
        },
      ],
      []
    ),
    units: [...variables]
      .reduce(
        (prev, curr) => [
          ...prev,
          ...curr.units.map((u) => ({ name: u.name, variables: u.variables })),
        ],
        []
      )
      .filter(
        (unit, idx, arr) => arr.findIndex((u) => u.name === unit.name) === idx
      ),
  },
  status: {
    ...state.status,
    variables: STATUS_SUCCESS,
  },
  error: EMPTY_VALUE,
});

/**
 * setCustomer - set customer preferences (percentiles)
 */
const setCustomer = (state, { customer }) => ({
  ...state,
  data: {
    ...state.data,
    customer,
  },
  status: {
    ...state.status,
    customer: STATUS_SUCCESS,
  },
  errors: {
    ...state.errors,
    percentiles: EMPTY_VALUE,
  },
  error: EMPTY_VALUE,
});

/**
 * setResults - set job output results
 */
const setResults = (state, { results }) => ({
  ...state,
  data: {
    ...state.data,
    results,
  },
  status: {
    ...state.status,
    results: STATUS_SUCCESS,
  },
  errors: {
    ...state.errors,
    results: EMPTY_VALUE,
  },
  error: EMPTY_VALUE,
});

/**
 * setJobOperations - save selected operations
 */
const setJobOperations = (state, { operations }) => ({
  ...state,
  data: {
    ...state.data,
    job: {
      ...state.data.job,
      parameters: {
        ...state.data.job.parameters,
        operations: [
          ...state.data.job.parameters.operations,
          ...state.data.operations.filter(({ id }) =>
            operations.map((o) => o.id).includes(id)
          ),
        ],
      },
    },
  },
});

/**
 * removeJobOperations - delete operations from user preferences
 */
const removeJobOperations = (state, { operations }) => ({
  ...state,
  data: {
    ...state.data,
    job: {
      ...state.data.job,
      parameters: {
        ...state.data.job.parameters,
        operations: [...state.data.job.parameters.operations].filter(
          ({ id }) => !operations.map((o) => o.id).includes(id)
        ),
      },
    },
  },
});

const setPercentilesError = (state, { error }) => ({
  ...state,
  errors: {
    ...state.errors,
    percentiles: prepareErrorShape(error),
  },
});

/**
 * BOATS
 */
const addBoat = (state, { boat }) => ({
  ...state,
  data: {
    ...state.data,
    boats: [...state.data.boats.filter((b) => b.id !== NO_ID), boat],
  },
  status: {
    ...state.status,
    boats: [...state.status.boats].filter((b) => b.id !== NO_ID),
  },
});
// create a boat with id : 'noid' (not present in database)
const addTemporaryBoat = (state, { boat }) => ({
  ...state,
  data: {
    ...state.data,
    boats: [...state.data.boats.filter((b) => b.id !== NO_ID), boat],
  },
});
const updateBoat = (state, { boat }) => ({
  ...state,
  data: {
    ...state.data,
    boats: [...state.data.boats].map((b) => (b.id === boat.id ? boat : b)),
  },
  status: {
    ...state.status,
    boats: [...state.status.boats].filter((b) => b.id !== boat.id),
  },
});
const removeBoat = (state, { boat }) => ({
  ...state,
  data: {
    ...state.data,
    boats: [...state.data.boats].filter((b) => b.id !== boat.id),
    job: {
      ...state.data.job,
      parameters: {
        ...state.data.job.parameters,
        operations: [...state.data.job.parameters.operations].filter(
          (o) => o.boat !== boat.id
        ),
      },
    },
  },
});
const setBoatIsCreating = (state, { boat }) => ({
  ...state,
  status: {
    ...state.status,
    boats: [...state.status.boats, boat],
  },
  errors: {
    ...state.errors,
    boats: [...state.errors.boats].filter((b) => b !== NO_ID),
  },
});
const setBoatIsUpdating = (state, { boat }) => ({
  ...state,
  status: {
    ...state.status,
    boats: [...state.status.boats, boat],
  },
  errors: {
    ...state.errors,
    boats: [...state.errors.boats].filter((b) => b !== boat.id),
  },
});
// store boat error when (creating, updating, deleting)
const setBoatCreateError = (state, { boat, error }) => ({
  ...state,
  errors: {
    ...state.errors,
    boats: [
      ...state.errors.boats,
      { ...boat, error: prepareErrorShape(error) },
    ],
  },
  status: {
    ...state.status,
    boats: [...state.status.boats].filter((b) => b.id !== NO_ID),
  },
});
const setBoatUpdateError = (state, { boat, error }) => ({
  ...state,
  errors: {
    ...state.errors,
    boats: [
      ...state.errors.boats,
      { ...boat, error: prepareErrorShape(error) },
    ],
  },
  status: {
    ...state.status,
    boats: [...state.status.boats].filter((b) => b.id !== boat.id),
  },
});

/**
 * OPERATIONS
 */
const addOperation = (state, { operation }) => ({
  ...state,
  data: {
    ...state.data,
    operations: [...state.data.operations, operation],
    job: {
      ...state.data.job,
      parameters: {
        ...state.data.job.parameters,
        operations: [...state.data.job.parameters.operations, operation],
      },
    },
  },
  status: {
    ...state.status,
    operations: [...state.status.operations].filter(
      (o) => o.id !== NO_ID || o.boat !== operation.boat
    ),
  },
});
const updateOperation = (state, { operation }) => ({
  ...state,
  data: {
    ...state.data,
    operations: [...state.data.operations].map((o) =>
      o.id === operation.id ? operation : o
    ),
    job: {
      ...state.data.job,
      parameters: {
        ...state.data.job.parameters,
        operations: [...state.data.job.parameters.operations].map((o) =>
          o.id === operation.id ? operation : o
        ),
      },
    },
  },
  status: {
    ...state.status,
    operations: [...state.status.operations].filter(
      (o) => o.id !== operation.id || o.boat !== operation.boat
    ),
  },
});
const removeOperation = (state, { operation }) => ({
  ...state,
  data: {
    ...state.data,
    operations: [...state.data.operations].filter((o) => o.id !== operation.id),
    job: {
      ...state.data.job,
      parameters: {
        ...state.data.job.parameters,
        operations: [...state.data.job.parameters.operations].filter(
          (o) => o.id !== operation.id
        ),
      },
    },
  },
});
const setOperationIsCreating = (state, { operation }) => ({
  ...state,
  status: {
    ...state.status,
    operations: [...state.status.operations, operation],
  },
  errors: {
    ...state.errors,
    operations: [...state.errors.operations].filter(
      (o) => o.id !== NO_ID || o.boat !== operation.boat
    ),
  },
});
const setOperationIsUpdating = (state, { operation }) => ({
  ...state,
  status: {
    ...state.status,
    operations: [...state.status.operations, operation],
  },
  errors: {
    ...state.errors,
    operations: [...state.errors.operations].filter(
      (o) => o.id !== operation.id || o.boat !== operation.boat
    ),
  },
});
// store operation error when (creating, updating, deleting)
const setOperationCreateError = (state, { operation, error }) => ({
  ...state,
  errors: {
    ...state.errors,
    operations: [
      ...state.errors.operations,
      { ...operation, error: prepareErrorShape(error) },
    ],
  },
  status: {
    ...state.status,
    operations: [...state.status.operations].filter(
      (o) => o.id !== NO_ID || o.boat !== operation.boat
    ),
  },
});
const setOperationUpdateError = (state, { operation, error }) => ({
  ...state,
  errors: {
    ...state.errors,
    operations: [
      ...state.errors.operations,
      { ...operation, error: prepareErrorShape(error) },
    ],
  },
  status: {
    ...state.status,
    operations: [...state.status.operations].filter(
      (o) => o.id !== operation.id || o.boat !== operation.boat
    ),
  },
});

/**
 * CONSTRAINTS
 */
const addConstraint = (state, { constraint }) => ({
  ...state,
  data: {
    ...state.data,
    constraints: [
      ...state.data.constraints.filter((c) => c.id !== NO_ID),
      constraint,
    ],
  },
  status: {
    ...state.status,
    constraints: [...state.status.constraints].filter(
      (c) => c.id !== NO_ID || c.operation !== constraint.operation
    ),
  },
});
// create a constraint with id : 'noid' (not present in database)
const addTemporaryConstraint = (state, { constraint }) => ({
  ...state,
  data: {
    ...state.data,
    constraints: [...state.data.constraints, constraint],
  },
});
const updateConstraint = (state, { constraint }) => ({
  ...state,
  data: {
    ...state.data,
    constraints: [...state.data.constraints].map((c) =>
      c.id === constraint.id ? constraint : c
    ),
  },
  status: {
    ...state.status,
    constraints: [...state.status.constraints].filter(
      (c) => c.id !== constraint.id || c.operation !== constraint.operation
    ),
  },
});
const updateTemporaryConstraint = (state, { constraint }) => ({
  ...state,
  data: {
    ...state.data,
    constraints: [...state.data.constraints].map((c) =>
      c.id === constraint.id && c.operation === constraint.operation
        ? constraint
        : c
    ),
  },
});
const removeConstraint = (state, { constraint }) => ({
  ...state,
  data: {
    ...state.data,
    constraints: [...state.data.constraints].filter(
      (c) => c.id !== constraint.id
    ),
  },
});
const setConstraintIsCreating = (state, { constraint }) => ({
  ...state,
  status: {
    ...state.status,
    constraints: [...state.status.constraints, constraint],
  },
  errors: {
    ...state.errors,
    constraints: [...state.errors.constraints].filter(
      (c) => c.id !== NO_ID || c.operation !== constraint.operation
    ),
  },
});
const setConstraintIsUpdating = (state, { constraint }) => ({
  ...state,
  status: {
    ...state.status,
    constraints: [...state.status.constraints, constraint],
  },
  errors: {
    ...state.errors,
    constraints: [...state.errors.constraints].filter(
      (c) => c.id !== constraint.id || c.operation !== constraint.operation
    ),
  },
});
// store constraint error when (creating, updating, deleting)
const setConstraintCreateError = (state, { constraint, error }) => ({
  ...state,
  errors: {
    ...state.errors,
    constraints: [
      ...state.errors.constraints,
      { ...constraint, error: prepareErrorShape(error) },
    ],
  },
  status: {
    ...state.status,
    constraints: [...state.status.constraints].filter(
      (c) => c.id !== NO_ID || c.operation !== constraint.operation
    ),
  },
});
const setConstraintUpdateError = (state, { constraint, error }) => ({
  ...state,
  errors: {
    ...state.errors,
    constraints: [
      ...state.errors.constraints,
      { ...constraint, error: prepareErrorShape(error) },
    ],
  },
  status: {
    ...state.status,
    constraints: [...state.status.constraints].filter(
      (c) => c.id !== constraint.id || c.operation !== constraint.operation
    ),
  },
});

/**
 * CLEAN ERRORS
 */
// clean boat error
const cleanBoatCreateError = (state) => ({
  ...state,
  errors: {
    ...state.errors,
    boats: [...state.errors.boats].filter((b) => b.id !== NO_ID),
  },
});
const cleanBoatUpdateError = (state, { boat }) => ({
  ...state,
  errors: {
    ...state.errors,
    boats: [...state.errors.boats].filter((b) => b.id !== boat.id),
  },
});

// clean operation error
const cleanOperationCreateError = (state, { operation }) => ({
  ...state,
  errors: {
    ...state.errors,
    operations: [...state.errors.operations].filter(
      (o) => o.id !== NO_ID || o.boat !== operation.boat
    ),
  },
});
const cleanOperationUpdateError = (state, { operation }) => ({
  ...state,
  errors: {
    ...state.errors,
    operations: [...state.errors.operations].filter(
      (o) => o.id !== operation.id || o.boat !== operation.boat
    ),
  },
});
const cleanOperationsUpdateError = (state, { operations }) => ({
  ...state,
  errors: {
    ...state.errors,
    operations: [...state.errors.operations].filter(
      (o) =>
        !operations.map((os) => os.id).includes(o.id) ||
        !operations.map((os) => os.boat).includes(o.boat)
    ),
  },
});

// clean constraint error
const cleanConstraintCreateError = (state, { constraint }) => ({
  ...state,
  errors: {
    ...state.errors,
    constraints: [...state.errors.constraints].filter(
      (c) => c.id !== NO_ID || c.operation !== constraint.operation
    ),
  },
});
const cleanConstraintUpdateError = (state, { constraint }) => ({
  ...state,
  errors: {
    ...state.errors,
    constraints: [...state.errors.constraints].filter(
      (c) => c.id !== constraint.id || c.operation !== constraint.operation
    ),
  },
});

const cleanPercentilesError = (state) => ({
  ...state,
  errors: {
    ...state.errors,
    percentiles: EMPTY_VALUE,
  },
});

const cleanJobStatus = (state) => ({
  ...state,
  status: {
    ...state.status,
    results: STATUS_NOT_REQUESTED,
    job: STATUS_NOT_REQUESTED,
  },
});

// clean all errors for specific error object type (boats, operations, constraints)
const cleanAllErrors = (state, { objectType }) => ({
  ...state,
  errors: {
    ...state.errors,
    [objectType]: EMPTY_ARRAY,
  },
});

const resetState = () => ({
  ...initialState,
});

export default createReducer(initialState, {
  [REQUEST_MARINE_CONTRACTORS]: setLoadingConstractorsStatus,
  [REQUEST_MARINE_CONTRACTORS_SUCCESS]: setContractors,
  [REQUEST_MARINE_CONTRACTORS_ERROR]: setContractorsError,
  [REQUEST_MARINE_CONTRACTORS_VARIABLES]: setLoadingVariablesStatus,
  [REQUEST_MARINE_CONTRACTORS_VARIABLES_SUCCESS]: setVariables,
  [REQUEST_MARINE_CONTRACTORS_VARIABLES_ERROR]: setVariablesError,
  [REQUEST_MARINE_CONTRACTORS_JOB]: setLoadingJobStatus,
  [REQUEST_MARINE_CONTRACTORS_JOB_SUCCESS]: setJob,
  [REQUEST_MARINE_CONTRACTORS_JOB_ERROR]: setJobError,
  [REQUEST_MARINE_CONTRACTORS_CUSTOMER_SUCCESS]: setCustomer,
  [REQUEST_MARINE_CONTRACTORS_CUSTOMER_ERROR]: setCustomerError,
  [REQUEST_MARINE_CONTRACTORS_RESULTS_SUCCESS]: setResults,
  [REQUEST_MARINE_CONTRACTORS_RESULTS_ERROR]: setResultsError,
  [SET_MARINE_CONTRATORS_OPERATIONS]: setJobOperations,
  [REMOVE_MARINE_CONTRACTORS_OPERATIONS]: removeJobOperations,
  [REQUEST_MARINE_CONTRACTORS_CUSTOMER]: setLoadingCustomerStatus,
  [UPDATE_MARINE_CONTRACTORS_CUSTOMER_PERCENTILES_ERROR]: setPercentilesError,
  [CREATE_MARINE_CONTRACTORS_BOAT]: setBoatIsCreating,
  [CREATE_MARINE_CONTRACTORS_BOAT_SUCCESS]: addBoat,
  [CREATE_MARINE_CONTRACTORS_TEMPORARY_BOAT]: addTemporaryBoat,
  [CREATE_MARINE_CONTRACTORS_BOAT_ERROR]: setBoatCreateError,
  [UPDATE_MARINE_CONTRACTORS_BOAT]: setBoatIsUpdating,
  [UPDATE_MARINE_CONTRACTORS_BOAT_SUCCESS]: updateBoat,
  [UPDATE_MARINE_CONTRACTORS_BOAT_ERROR]: setBoatUpdateError,
  [DELETE_MARINE_CONTRACTORS_BOAT_SUCCESS]: removeBoat,
  // *WORK* display a modal ??
  // [DELETE_MARINE_CONTRACTORS_BOAT_ERROR]: setBoatError,
  [CREATE_MARINE_CONTRACTORS_OPERATION]: setOperationIsCreating,
  [CREATE_MARINE_CONTRACTORS_OPERATION_SUCCESS]: addOperation,
  [CREATE_MARINE_CONTRACTORS_OPERATION_ERROR]: setOperationCreateError,
  [UPDATE_MARINE_CONTRACTORS_OPERATION_SUCCESS]: updateOperation,
  [UPDATE_MARINE_CONTRACTORS_TEMPORARY_CONSTRAINT]: updateTemporaryConstraint,
  [UPDATE_MARINE_CONTRACTORS_OPERATION_ERROR]: setOperationUpdateError,
  [DELETE_MARINE_CONTRACTORS_OPERATION_SUCCESS]: removeOperation,
  // *WORK* display a modal ??
  // [DELETE_MARINE_CONTRACTORS_OPERATION_ERROR]: setOperationError,
  [CREATE_MARINE_CONTRACTORS_CONSTRAINT]: setConstraintIsCreating,
  [CREATE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS]: addConstraint,
  [CREATE_MARINE_CONTRACTORS_TEMPORARY_CONSTRAINT]: addTemporaryConstraint,
  [CREATE_MARINE_CONTRACTORS_CONSTRAINT_ERROR]: setConstraintCreateError,
  [UPDATE_MARINE_CONTRACTORS_OPERATION]: setOperationIsUpdating,
  [UPDATE_MARINE_CONTRACTORS_CONSTRAINT]: setConstraintIsUpdating,
  [UPDATE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS]: updateConstraint,
  [UPDATE_MARINE_CONTRACTORS_CONSTRAINT_ERROR]: setConstraintUpdateError,
  [DELETE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS]: removeConstraint,
  // *WORK* display a modal ??
  // [DELETE_MARINE_CONTRACTORS_CONSTRAINT_ERROR]: setConstraintError,
  [CLEAN_BOAT_CREATE_ERROR]: cleanBoatCreateError,
  [CLEAN_BOAT_UPDATE_ERROR]: cleanBoatUpdateError,
  [CLEAN_OPERATION_CREATE_ERROR]: cleanOperationCreateError,
  [CLEAN_OPERATION_UPDATE_ERROR]: cleanOperationUpdateError,
  [CLEAN_CONSTRAINT_CREATE_ERROR]: cleanConstraintCreateError,
  [CLEAN_CONSTRAINT_UPDATE_ERROR]: cleanConstraintUpdateError,
  [CLEAN_OPERATIONS_UPDATE_ERROR]: cleanOperationsUpdateError,
  [CLEAN_PERCENTILES_ERROR]: cleanPercentilesError,
  [CLEAN_JOB_STATUS]: cleanJobStatus,
  [CLEAN_ALL_ERRORS]: cleanAllErrors,
  [RESET_MARINE_CONTRACTORS_STATE]: resetState,
});
