import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useCallback, memo } from 'react';
import { User, Article, Graph, SignOut } from 'phosphor-react';

import { ROUTES } from 'constants/routes';

import { useStyles } from './styles';

const menuId = 'user-bar_menu';

/**
 * User bar component. Represents menu with profile and logout links
 * @param {String}wrapperClass
 * @param {function} logout
 */
const UserBarComponent = ({ user, wrapperClass, logout }) => {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = !!anchorEl;

  const handleProfileMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const logoutOnClick = useCallback(() => {
    handleMenuClose();
    logout();
  }, [handleMenuClose, logout]);

  const profileOnClick = useCallback(() => {
    handleMenuClose();
    history.push(ROUTES.profile);
  }, [history, handleMenuClose]);

  const plansOnClick = useCallback(() => {
    handleMenuClose();
    history.push(ROUTES.plans);
  }, [history, handleMenuClose]);

  const usageOnClick = useCallback(() => {
    handleMenuClose();
    history.push(ROUTES.usage);
  }, [history, handleMenuClose]);

  const userInitials =
    user.firstName.charAt(0).toUpperCase() +
    user.lastName.charAt(0).toUpperCase();

  return (
    <>
      <div className={wrapperClass || ''}>
        <div
          aria-label="account of current user"
          className={classes.userInitials}
          onClick={handleProfileMenuOpen}
        >
          {userInitials}
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        id={menuId}
        open={isMenuOpen}
        onClose={handleMenuClose}
        disableScrollLock
      >
        <MenuItem onClick={plansOnClick} className={classes.menuItemLabel}>
          <Article size={20} className={classes.menuItemIcon} />
          &nbsp;My plans
        </MenuItem>
        <MenuItem onClick={profileOnClick} className={classes.menuItemLabel}>
          <User size={20} className={classes.menuItemIcon} />
          &nbsp;My profile
        </MenuItem>
        {user.isSuperuser && (
          <MenuItem onClick={usageOnClick} className={classes.menuItemLabel}>
            <Graph size={20} className={classes.menuItemIcon} />
            &nbsp;Usage analyze
          </MenuItem>
        )}
        <MenuItem onClick={logoutOnClick} className={classes.menuItemLabel}>
          <SignOut size={20} className={classes.menuItemIcon} />
          &nbsp;Log out
        </MenuItem>
      </Menu>
    </>
  );
};

export const UserBar = memo(UserBarComponent);

UserBarComponent.propTypes = {
  user: PropTypes.object.isRequired,
  wrapperClass: PropTypes.string,
  logout: PropTypes.func.isRequired,
};

UserBarComponent.defaultProps = {
  wrapperClass: '',
};
