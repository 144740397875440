export const meanVerticalProfileAnnualGraphData = {
  id: 'WIN-magw-MEANCOLSTD_2147',
  point: {
    name: 'test',
    id: '2147',
  },
  layer: 'WIND',
  type: 'MEANCOLSTD',
  name: 'Mean vertical profile',
  variables: {
    names: ['magw'],
    variables: [
      {
        name: 'magw',
        long_name: 'wind speed',
        hidden: false,
        units: 'm.s-1',
      },
    ],
  },
  visualization: {
    kwargs: {
      colVar: 'std',
      x2Name: 'magw_std',
      ylabel: 'Altitude [m above surface]',
      xName: 'magw_mean',
      xlabel: 'Wind speed [m.s-1]',
      yName: 'level',
    },
    web: {
      'plot_col_profile-image-uri-0':
        '/static/3.6.3/projects/dev-ma/MA/open-ocean/ENGIE-LNG/World/WIND/Visualization/WIN-magw-MEANCOLSTD/2147_WIN-magw-MEANCOLSTD_all%24%7BPER%7D__plot_col_profile.png',
      'data-uri-0':
        '/api/projects/671/get_visu_data/?stats_id=WIN-magw-MEANCOLSTD_2147',
      'netcdf-uri-0':
        '/static/3.6.3/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2147/WIND/WIN-magw-MEANCOLSTD_2147.nc',
      'excel-uri-0':
        '/static/3.6.3/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2147/WIND/WIN-magw-MEANCOLSTD_2147.xls',
      'data-legend-0': 'Mean vertical profile of wind speed at point test',
      'matlab-uri-0':
        '/static/3.6.3/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2147/WIND/WIN-magw-MEANCOLSTD_2147.mat',
      'plot_col_profile-image-legend-0':
        'Mean vertical profile of wind speed at point test',
    },
    driver: {
      suffix: null,
      fileformat: '.png',
      // eslint-disable-next-line
      path: '/mnt/glusterfs/projects/dev-ma/MA/open-ocean/ENGIE-LNG/World/WIND/Visualization/WIN-magw-MEANCOLSTD/2147_WIN-magw-MEANCOLSTD_all${PER}__plot_col_profile.png',
      method: 'plot_col_profile',
    },
  },
  periodicity: 'all',
  level: {
    values: [
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
      32,
      33,
      34,
      35,
      36,
      37,
      38,
      39,
      40,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      49,
      50,
      51,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      59,
      60,
      61,
      62,
      63,
      64,
      65,
      66,
      67,
      68,
      69,
      70,
      71,
      72,
      73,
      74,
      75,
      76,
      77,
      78,
      79,
      80,
      81,
      82,
      83,
      84,
      85,
      86,
      87,
      88,
      89,
      90,
      91,
      92,
      93,
      94,
      95,
      96,
      97,
      98,
      99,
      100,
      101,
      102,
      103,
      104,
      105,
      106,
      107,
      108,
      109,
      110,
      111,
      112,
      113,
      114,
      115,
      116,
      117,
      118,
      119,
      120,
      121,
      122,
      123,
      124,
      125,
      126,
      127,
      128,
      129,
      130,
      131,
      132,
      133,
      134,
      135,
      136,
      137,
      138,
      139,
      140,
      141,
      142,
      143,
      144,
      145,
      146,
      147,
      148,
      149,
      150,
      151,
      152,
      153,
      154,
      155,
      156,
      157,
      158,
      159,
      160,
      161,
      162,
      163,
      164,
      165,
      166,
      167,
      168,
      169,
      170,
      171,
      172,
      173,
      174,
      175,
      176,
      177,
      178,
      179,
      180,
      181,
      182,
      183,
      184,
      185,
      186,
      187,
      188,
      189,
      190,
      191,
      192,
      193,
      194,
      195,
      196,
      197,
      198,
      199,
      200,
    ],
    dimensions: ['level'],
    attributes: {
      vartype: 'f8',
      units: 'm',
      standard_name: 'vertical level',
      conventions: 'positive from seabed',
      description: 'vertical level, in meters from mean sea level',
      long_name: 'vertical level',
    },
  },
  magwMean: {
    values: [
      6.565478968364586,
      6.609023414324705,
      6.652567860284822,
      6.696112306244941,
      6.739656752205059,
      6.783201198165177,
      6.826745644125295,
      6.870290090085414,
      6.913834536045531,
      6.9573789820056495,
      7.000923427965768,
      7.0444678739258855,
      7.088012319886004,
      7.131556765846122,
      7.17510121180624,
      7.218645657766358,
      7.2384097188201455,
      7.258173779873934,
      7.277937840927721,
      7.297701901981508,
      7.317465963035295,
      7.3372300240890835,
      7.356994085142871,
      7.376758146196658,
      7.396522207250446,
      7.416286268304233,
      7.436050329358021,
      7.455814390411808,
      7.475578451465596,
      7.495342512519383,
      7.515106573573171,
      7.534870634626959,
      7.554634695680746,
      7.574398756734533,
      7.5941628177883205,
      7.613926878842109,
      7.633690939895896,
      7.653455000949683,
      7.67321906200347,
      7.6929831230572585,
      7.712747184111046,
      7.724308418689475,
      7.735869653267904,
      7.747430887846333,
      7.758992122424762,
      7.770553357003191,
      7.78211459158162,
      7.79367582616005,
      7.805237060738479,
      7.816798295316908,
      7.828359529895337,
      7.839920764473766,
      7.851481999052195,
      7.863043233630624,
      7.8746044682090535,
      7.8861657027874825,
      7.897726937365912,
      7.909288171944341,
      7.92084940652277,
      7.9324106411012,
      7.943971875679629,
      7.955533110258058,
      7.967094344836487,
      7.978655579414916,
      7.990216813993345,
      8.001778048571774,
      8.009980875047132,
      8.01818370152249,
      8.026386527997849,
      8.034589354473207,
      8.042792180948567,
      8.050995007423925,
      8.059197833899283,
      8.067400660374641,
      8.07560348685,
      8.083806313325358,
      8.092009139800716,
      8.100211966276074,
      8.108414792751434,
      8.116617619226792,
      8.12482044570215,
      8.133023272177509,
      8.141226098652867,
      8.149428925128225,
      8.157631751603583,
      8.165834578078941,
      8.174037404554301,
      8.18224023102966,
      8.190443057505018,
      8.198645883980376,
      8.206848710455734,
      8.213211316978018,
      8.2195739235003,
      8.225936530022585,
      8.232299136544867,
      8.238661743067151,
      8.245024349589434,
      8.251386956111718,
      8.257749562634,
      8.264112169156284,
      8.270474775678567,
      8.276837382200851,
      8.283199988723133,
      8.289562595245417,
      8.2959252017677,
      8.302287808289984,
      8.308650414812266,
      8.31501302133455,
      8.321375627856833,
      8.327738234379117,
      8.3341008409014,
      8.340463447423684,
      8.346826053945966,
      8.35318866046825,
      8.359551266990533,
      8.365913873512817,
      8.371112501568962,
      8.376311129625108,
      8.381509757681254,
      8.3867083857374,
      8.391907013793546,
      8.397105641849691,
      8.402304269905837,
      8.407502897961983,
      8.412701526018129,
      8.417900154074275,
      8.42309878213042,
      8.428297410186566,
      8.43349603824271,
      8.438694666298856,
      8.443893294355002,
      8.449091922411148,
      8.454290550467293,
      8.45948917852344,
      8.464687806579585,
      8.46988643463573,
      8.475085062691877,
      8.480283690748022,
      8.485482318804168,
      8.490680946860314,
      8.49587957491646,
      8.500274952309653,
      8.504670329702847,
      8.50906570709604,
      8.513461084489233,
      8.517856461882426,
      8.52225183927562,
      8.526647216668813,
      8.531042594062006,
      8.5354379714552,
      8.539833348848394,
      8.544228726241586,
      8.54862410363478,
      8.553019481027974,
      8.557414858421167,
      8.56181023581436,
      8.566205613207552,
      8.570600990600747,
      8.57499636799394,
      8.579391745387133,
      8.583787122780327,
      8.58818250017352,
      8.592577877566713,
      8.596973254959906,
      8.6013686323531,
      8.605764009746293,
      8.609571458828459,
      8.613378907910624,
      8.61718635699279,
      8.620993806074955,
      8.624801255157118,
      8.628608704239284,
      8.632416153321449,
      8.636223602403614,
      8.64003105148578,
      8.643838500567945,
      8.64764594965011,
      8.651453398732276,
      8.65526084781444,
      8.659068296896605,
      8.66287574597877,
      8.666683195060935,
      8.6704906441431,
      8.674298093225266,
      8.678105542307431,
      8.681912991389597,
      8.68572044047176,
      8.689527889553926,
      8.693335338636091,
      8.697142787718256,
      8.700950236800422,
    ],
    dimensions: ['level'],
    attributes: {
      description: 'mean value of magw',
      units: 'm.s-1',
      standard_name: 'magw_mean',
      vartype: 'f8',
    },
  },
  global_attributes: {
    statistic: 'Mean vertical profile',
    history: '2020-03-25 19:58: generated by Open Ocean',
    location_name: 'test',
    longitude: "-18.45703 (18°27'25''W)",
    latitude: "15.42191 (15°25'18''N)",
    srs: 'EPSG:4326',
    period: '1979-01-01 00:00 - 2019-03-10 23:00',
    WIND_dataset: 'CFSR~CFSV2%CFSR3D',
    WIND_node_longitude: "-18.50000 (18°30'W)",
    WIND_node_latitude: "15.50000 (15°30'N)",
    WIND_node_distance: '9.8 km',
    sampling_period: '1h',
    depth: 2933.670347588957,
  },
  magwStd: {
    values: [
      2.3460228534337717,
      2.3615824593444423,
      2.377142065255113,
      2.3927016711657836,
      2.4082612770764538,
      2.4238208829871244,
      2.439380488897795,
      2.4549400948084656,
      2.4704997007191363,
      2.486059306629807,
      2.5016189125404775,
      2.517178518451148,
      2.5327381243618183,
      2.548297730272489,
      2.5638573361831596,
      2.57941694209383,
      2.5864791745877977,
      2.5935414070817657,
      2.6006036395757333,
      2.6076658720697012,
      2.6147281045636688,
      2.6217903370576363,
      2.6288525695516043,
      2.635914802045572,
      2.64297703453954,
      2.6500392670335073,
      2.657101499527475,
      2.664163732021443,
      2.6712259645154104,
      2.6782881970093784,
      2.685350429503346,
      2.6924126619973134,
      2.6994748944912814,
      2.706537126985249,
      2.713599359479217,
      2.7206615919731845,
      2.727723824467152,
      2.73478605696112,
      2.7418482894550875,
      2.7489105219490555,
      2.755972754443023,
      2.7601038956233683,
      2.764235036803714,
      2.7683661779840594,
      2.772497319164405,
      2.7766284603447504,
      2.780759601525096,
      2.7848907427054415,
      2.789021883885787,
      2.7931530250661325,
      2.7972841662464782,
      2.8014153074268235,
      2.8055464486071693,
      2.8096775897875146,
      2.8138087309678603,
      2.8179398721482056,
      2.8220710133285514,
      2.8262021545088967,
      2.8303332956892424,
      2.8344644368695877,
      2.8385955780499335,
      2.8427267192302788,
      2.8468578604106245,
      2.85098900159097,
      2.8551201427713155,
      2.859251283951661,
      2.862182375265283,
      2.8651134665789053,
      2.8680445578925275,
      2.8709756492061493,
      2.8739067405197716,
      2.876837831833394,
      2.879768923147016,
      2.8827000144606383,
      2.8856311057742605,
      2.8885621970878823,
      2.8914932884015045,
      2.894424379715127,
      2.897355471028749,
      2.9002865623423713,
      2.9032176536559935,
      2.9061487449696153,
      2.9090798362832375,
      2.9120109275968598,
      2.914942018910482,
      2.9178731102241042,
      2.9208042015377265,
      2.9237352928513483,
      2.9266663841649705,
      2.9295974754785927,
      2.932528566792215,
      2.9348020978446496,
      2.9370756288970843,
      2.939349159949519,
      2.9416226910019536,
      2.9438962220543883,
      2.946169753106823,
      2.948443284159257,
      2.950716815211692,
      2.9529903462641265,
      2.955263877316561,
      2.957537408368996,
      2.9598109394214305,
      2.962084470473865,
      2.9643580015263,
      2.9666315325787345,
      2.968905063631169,
      2.971178594683604,
      2.9734521257360385,
      2.9757256567884727,
      2.9779991878409073,
      2.980272718893342,
      2.9825462499457767,
      2.9848197809982113,
      2.987093312050646,
      2.9893668431030807,
      2.9912244532309917,
      2.9930820633589024,
      2.9949396734868134,
      2.9967972836147245,
      2.998654893742635,
      3.0005125038705462,
      3.0023701139984573,
      3.004227724126368,
      3.006085334254279,
      3.00794294438219,
      3.0098005545101008,
      3.011658164638012,
      3.013515774765923,
      3.015373384893834,
      3.0172309950217446,
      3.0190886051496557,
      3.020946215277567,
      3.0228038254054774,
      3.0246614355333885,
      3.0265190456612996,
      3.0283766557892102,
      3.0302342659171213,
      3.0320918760450324,
      3.033949486172943,
      3.035807096300854,
      3.0373776832852286,
      3.0389482702696027,
      3.040518857253977,
      3.0420894442383517,
      3.043660031222726,
      3.0452306182071003,
      3.046801205191475,
      3.0483717921758493,
      3.0499423791602234,
      3.051512966144598,
      3.0530835531289724,
      3.054654140113347,
      3.056224727097721,
      3.0577953140820955,
      3.05936590106647,
      3.0609364880508445,
      3.0625070750352186,
      3.064077662019593,
      3.0656482490039676,
      3.0672188359883417,
      3.068789422972716,
      3.0703600099570907,
      3.071930596941465,
      3.0735011839258393,
      3.0750717709102138,
      3.0764322752394615,
      3.0777927795687092,
      3.079153283897957,
      3.0805137882272047,
      3.0818742925564524,
      3.0832347968857,
      3.084595301214948,
      3.0859558055441956,
      3.0873163098734433,
      3.088676814202691,
      3.0900373185319387,
      3.0913978228611865,
      3.092758327190434,
      3.094118831519682,
      3.0954793358489296,
      3.0968398401781774,
      3.098200344507425,
      3.099560848836673,
      3.1009213531659205,
      3.1022818574951683,
      3.103642361824416,
      3.1050028661536637,
      3.1063633704829114,
      3.107723874812159,
      3.109084379141407,
    ],
    dimensions: ['level'],
    attributes: {
      description: 'standard deviation of magw',
      units: 'm.s-1',
      standard_name: 'magw_std',
      vartype: 'f8',
    },
  },
  success: true,
};
