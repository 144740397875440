import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  helpLinkContainer: {
    textAlign: 'center',
  },
  container: {
    width: 760,
  },
  contactLink: {
    backgroundColor: 'blue',
    color: palette.lightGrey.main,
  },
}));
