import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    textTransform: 'none',
    width: '100%',
    fontSize: 14,
    marginBottom: 5,
    padding: 5,
  },
});
