import { STEPS } from 'constants/marineContractors';

import {
  REQUEST_MARINE_CONTRACTORS,
  REQUEST_MARINE_CONTRACTORS_SUCCESS,
  REQUEST_MARINE_CONTRACTORS_ERROR,
  REQUEST_MARINE_CONTRACTORS_JOB,
  REQUEST_MARINE_CONTRACTORS_JOB_SUCCESS,
  REQUEST_MARINE_CONTRACTORS_JOB_ERROR,
  RESET_MARINE_CONTRACTORS_STATE,
  SET_MARINE_CONTRATORS_OPERATIONS,
  REMOVE_MARINE_CONTRACTORS_OPERATIONS,
  SAVE_MARINE_CONTRACTORS_JOB_OPERATIONS,
  UPDATE_MARINE_CONTRACTORS_STEP,
  CREATE_MARINE_CONTRACTORS_BOAT,
  CREATE_MARINE_CONTRACTORS_BOAT_SUCCESS,
  CREATE_MARINE_CONTRACTORS_BOAT_ERROR,
  UPDATE_MARINE_CONTRACTORS_BOAT,
  UPDATE_MARINE_CONTRACTORS_BOAT_SUCCESS,
  UPDATE_MARINE_CONTRACTORS_BOAT_ERROR,
  DELETE_MARINE_CONTRACTORS_BOAT,
  DELETE_MARINE_CONTRACTORS_BOAT_SUCCESS,
  DELETE_MARINE_CONTRACTORS_BOAT_ERROR,
  DELETE_MARINE_CONTRACTORS_OPERATION_ERROR,
  DELETE_MARINE_CONTRACTORS_OPERATION_SUCCESS,
  DELETE_MARINE_CONTRACTORS_OPERATION,
  UPDATE_MARINE_CONTRACTORS_OPERATION_ERROR,
  UPDATE_MARINE_CONTRACTORS_OPERATION_SUCCESS,
  UPDATE_MARINE_CONTRACTORS_OPERATION,
  CREATE_MARINE_CONTRACTORS_OPERATION_ERROR,
  CREATE_MARINE_CONTRACTORS_OPERATION_SUCCESS,
  CREATE_MARINE_CONTRACTORS_OPERATION,
  CREATE_MARINE_CONTRACTORS_CONSTRAINT,
  CREATE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS,
  CREATE_MARINE_CONTRACTORS_CONSTRAINT_ERROR,
  UPDATE_MARINE_CONTRACTORS_CONSTRAINT,
  UPDATE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS,
  UPDATE_MARINE_CONTRACTORS_CONSTRAINT_ERROR,
  DELETE_MARINE_CONTRACTORS_CONSTRAINT,
  DELETE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS,
  DELETE_MARINE_CONTRACTORS_CONSTRAINT_ERROR,
  REQUEST_MARINE_CONTRACTORS_VARIABLES,
  REQUEST_MARINE_CONTRACTORS_VARIABLES_SUCCESS,
  REQUEST_MARINE_CONTRACTORS_VARIABLES_ERROR,
  SAVE_MARINE_CONTRACTORS_JOB_POINTS,
  REQUEST_MARINE_CONTRACTORS_CUSTOMER,
  REQUEST_MARINE_CONTRACTORS_CUSTOMER_SUCCESS,
  REQUEST_MARINE_CONTRACTORS_CUSTOMER_ERROR,
  UPDATE_MARINE_CONTRACTORS_CUSTOMER,
  UPDATE_MARINE_CONTRACTORS_CUSTOMER_SUCCESS,
  UPDATE_MARINE_CONTRACTORS_CUSTOMER_ERROR,
  UPDATE_MARINE_CONTRACTORS_CUSTOMER_PERCENTILES_ERROR,
  UPDATE_MARINE_CONTRACTORS_CUSTOMER_PERCENTILES,
  SAVE_MARINE_CONTRACTORS_JOB_OUTPUTS,
  CREATE_MARINE_CONTRACTORS_TEMPORARY_BOAT,
  CREATE_MARINE_CONTRACTORS_TEMPORARY_CONSTRAINT,
  CLEAN_BOAT_CREATE_ERROR,
  CLEAN_ALL_ERRORS,
  CLEAN_BOAT_UPDATE_ERROR,
  CLEAN_OPERATION_CREATE_ERROR,
  CLEAN_OPERATION_UPDATE_ERROR,
  CLEAN_OPERATIONS_CREATE_ERROR,
  CLEAN_CONSTRAINT_CREATE_ERROR,
  CLEAN_CONSTRAINT_UPDATE_ERROR,
  CLEAN_JOB_STATUS,
  UPDATE_MARINE_CONTRACTORS_TEMPORARY_CONSTRAINT,
  START_UPDATE_MARINE_CONTRACTORS_JOB,
  STOP_UPDATE_MARINE_CONTRACTORS_JOB,
  FINISH_UPDATE_MARINE_CONTRACTORS_JOB,
  CLEAN_PERCENTILES_ERROR,
  REQUEST_MARINE_CONTRACTORS_RESULTS_ERROR,
  REQUEST_MARINE_CONTRACTORS_RESULTS_SUCCESS,
  REQUEST_MARINE_CONTRACTORS_RESULTS,
  DOWNLOAD_RESULTS,
  DOWNLOAD_RESULTS_ERROR,
} from './types';

export const requestMarineContractors = () => ({
  type: REQUEST_MARINE_CONTRACTORS,
});
export const requestMarineContractorsSuccess = (boats) => ({
  type: REQUEST_MARINE_CONTRACTORS_SUCCESS,
  boats,
});
export const requestMarineContractorsError = (error) => ({
  type: REQUEST_MARINE_CONTRACTORS_ERROR,
  error,
});

export const requestMarineContractorsVariables = () => ({
  type: REQUEST_MARINE_CONTRACTORS_VARIABLES,
});
export const requestMarineContractorsVariablesSuccess = (variables) => ({
  type: REQUEST_MARINE_CONTRACTORS_VARIABLES_SUCCESS,
  variables,
});
export const requestMarineContractorsVariablesError = (error) => ({
  type: REQUEST_MARINE_CONTRACTORS_VARIABLES_ERROR,
  error,
});

export const requestMarineContractorsJob = (projectId) => ({
  type: REQUEST_MARINE_CONTRACTORS_JOB,
  projectId,
});
export const requestMarineContractorsJobSuccess = (job) => ({
  type: REQUEST_MARINE_CONTRACTORS_JOB_SUCCESS,
  job,
});
export const requestMarineContractorsJobError = (error) => ({
  type: REQUEST_MARINE_CONTRACTORS_JOB_ERROR,
  error,
});

export const requestMarineContractorsCustomer = (customerId) => ({
  type: REQUEST_MARINE_CONTRACTORS_CUSTOMER,
  customerId,
});

export const requestMarineContractorsCustomerSuccess = (customer) => ({
  type: REQUEST_MARINE_CONTRACTORS_CUSTOMER_SUCCESS,
  customer,
});

export const requestMarineContractorsCustomerError = (error) => ({
  type: REQUEST_MARINE_CONTRACTORS_CUSTOMER_ERROR,
  error,
});

export const requestMarineContractorsResults = (job) => ({
  type: REQUEST_MARINE_CONTRACTORS_RESULTS,
  job,
});
export const requestMarineContractorsResultsSuccess = (results) => ({
  type: REQUEST_MARINE_CONTRACTORS_RESULTS_SUCCESS,
  results,
});
export const requestMarineContractorsResultsError = (error) => ({
  type: REQUEST_MARINE_CONTRACTORS_RESULTS_ERROR,
  error,
});

export const setMarineContractorsJobOperations = (operations) => ({
  type: SET_MARINE_CONTRATORS_OPERATIONS,
  operations,
});
export const removeMarineContractorsJobOperations = (operations) => ({
  type: REMOVE_MARINE_CONTRACTORS_OPERATIONS,
  operations,
});

export const cleanMarineContractorsJobStatus = () => ({
  type: CLEAN_JOB_STATUS,
});

export const saveMarineContractorsJobOperations = (
  job,
  operations,
  projectId
) => ({
  type: SAVE_MARINE_CONTRACTORS_JOB_OPERATIONS,
  job,
  operations,
  projectId,
});
export const saveMarineContractorsJobPoints = (
  job,
  points,
  projectId,
  step = null
) => ({
  type: SAVE_MARINE_CONTRACTORS_JOB_POINTS,
  job,
  points,
  projectId,
  step: !step ? STEPS.OUTPUTS : step,
});
export const saveMarineContractorsJobOutputs = (
  job,
  percentiles,
  outputFormat,
  projectId
) => ({
  type: SAVE_MARINE_CONTRACTORS_JOB_OUTPUTS,
  job,
  percentiles,
  outputFormat,
  projectId,
});

export const updateMarineContractorsStep = (job, projectId, step) => ({
  type: UPDATE_MARINE_CONTRACTORS_STEP,
  job,
  projectId,
  step,
});

export const startUpdateMarineContractorsJob = (job) => ({
  type: START_UPDATE_MARINE_CONTRACTORS_JOB,
  job,
});

export const stopUpdateMarineContractorsJob = () => ({
  type: STOP_UPDATE_MARINE_CONTRACTORS_JOB,
});

export const finishUpdateMarineContractorsJob = (job) => ({
  type: FINISH_UPDATE_MARINE_CONTRACTORS_JOB,
  job,
});

export const createMarineContractorsBoat = (boat) => ({
  type: CREATE_MARINE_CONTRACTORS_BOAT,
  boat,
});
export const createMarineContractorsTemporaryBoat = (boat) => ({
  type: CREATE_MARINE_CONTRACTORS_TEMPORARY_BOAT,
  boat,
});
export const createMarineContractorsBoatSuccess = (boat) => ({
  type: CREATE_MARINE_CONTRACTORS_BOAT_SUCCESS,
  boat,
});
export const createMarineContractorsBoatError = (boat, error) => ({
  type: CREATE_MARINE_CONTRACTORS_BOAT_ERROR,
  boat,
  error,
});

export const updateMarineContractorsBoat = (boat) => ({
  type: UPDATE_MARINE_CONTRACTORS_BOAT,
  boat,
});
export const updateMarineContractorsBoatSuccess = (boat) => ({
  type: UPDATE_MARINE_CONTRACTORS_BOAT_SUCCESS,
  boat,
});
export const updateMarineContractorsBoatError = (boat, error) => ({
  type: UPDATE_MARINE_CONTRACTORS_BOAT_ERROR,
  boat,
  error,
});

export const deleteMarineContractorsBoat = (boat, modalId) => ({
  type: DELETE_MARINE_CONTRACTORS_BOAT,
  boat,
  modalId,
});
export const deleteMarineContractorsBoatSuccess = (boat) => ({
  type: DELETE_MARINE_CONTRACTORS_BOAT_SUCCESS,
  boat,
});
export const deleteMarineContractorsBoatError = (error) => ({
  type: DELETE_MARINE_CONTRACTORS_BOAT_ERROR,
  error,
});

export const createMarineContractorsOperation = (operation) => ({
  type: CREATE_MARINE_CONTRACTORS_OPERATION,
  operation,
});
export const createMarineContractorsOperationSuccess = (operation) => ({
  type: CREATE_MARINE_CONTRACTORS_OPERATION_SUCCESS,
  operation,
});
export const createMarineContractorsOperationError = (operation, error) => ({
  type: CREATE_MARINE_CONTRACTORS_OPERATION_ERROR,
  operation,
  error,
});

export const updateMarineContractorsOperation = (operation) => ({
  type: UPDATE_MARINE_CONTRACTORS_OPERATION,
  operation,
});
export const updateMarineContractorsOperationSuccess = (operation) => ({
  type: UPDATE_MARINE_CONTRACTORS_OPERATION_SUCCESS,
  operation,
});
export const updateMarineContractorsOperationError = (operation, error) => ({
  type: UPDATE_MARINE_CONTRACTORS_OPERATION_ERROR,
  operation,
  error,
});

export const deleteMarineContractorsOperation = (operation, modalId) => ({
  type: DELETE_MARINE_CONTRACTORS_OPERATION,
  operation,
  modalId,
});
export const deleteMarineContractorsOperationSuccess = (operation) => ({
  type: DELETE_MARINE_CONTRACTORS_OPERATION_SUCCESS,
  operation,
});
export const deleteMarineContractorsOperationError = (error) => ({
  type: DELETE_MARINE_CONTRACTORS_OPERATION_ERROR,
  error,
});

export const createMarineContractorsConstraint = (constraint) => ({
  type: CREATE_MARINE_CONTRACTORS_CONSTRAINT,
  constraint,
});
export const createMarineContractorsTemporaryConstraint = (constraint) => ({
  type: CREATE_MARINE_CONTRACTORS_TEMPORARY_CONSTRAINT,
  constraint,
});
export const createMarineContractorsConstraintSuccess = (constraint) => ({
  type: CREATE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS,
  constraint,
});
export const createMarineContractorsConstraintError = (constraint, error) => ({
  type: CREATE_MARINE_CONTRACTORS_CONSTRAINT_ERROR,
  constraint,
  error,
});

export const updateMarineContractorsConstraint = (constraint) => ({
  type: UPDATE_MARINE_CONTRACTORS_CONSTRAINT,
  constraint,
});
export const updateMarineContractorsConstraintSuccess = (constraint) => ({
  type: UPDATE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS,
  constraint,
});
export const updateMarineContractorsTemporaryConstraint = (constraint) => ({
  type: UPDATE_MARINE_CONTRACTORS_TEMPORARY_CONSTRAINT,
  constraint,
});
export const updateMarineContractorsConstraintError = (constraint, error) => ({
  type: UPDATE_MARINE_CONTRACTORS_CONSTRAINT_ERROR,
  constraint,
  error,
});

export const deleteMarineContractorsConstraint = (constraint) => ({
  type: DELETE_MARINE_CONTRACTORS_CONSTRAINT,
  constraint,
});
export const deleteMarineContractorsConstraintSuccess = (constraint) => ({
  type: DELETE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS,
  constraint,
});
export const deleteMarineContractorsConstraintError = (error) => ({
  type: DELETE_MARINE_CONTRACTORS_CONSTRAINT_ERROR,
  error,
});

export const updateMarineContractorsCustomer = (customer) => ({
  type: UPDATE_MARINE_CONTRACTORS_CUSTOMER,
  customer,
});
export const updateMarineContractorsCustomerSuccess = (customer) => ({
  type: UPDATE_MARINE_CONTRACTORS_CUSTOMER_SUCCESS,
  customer,
});
export const updateMarineContractorsCustomerError = (error) => ({
  type: UPDATE_MARINE_CONTRACTORS_CUSTOMER_ERROR,
  error,
});

export const updateMarineContractorsCustomerPercentiles = (customer) => ({
  type: UPDATE_MARINE_CONTRACTORS_CUSTOMER_PERCENTILES,
  customer,
});
export const updateMarineContractorsCustomerPercentilesError = (error) => ({
  type: UPDATE_MARINE_CONTRACTORS_CUSTOMER_PERCENTILES_ERROR,
  error,
});

export const cleanBoatCreateError = () => ({
  type: CLEAN_BOAT_CREATE_ERROR,
});
export const cleanBoatUpdateError = (boat) => ({
  type: CLEAN_BOAT_UPDATE_ERROR,
  boat,
});
export const cleanOperationCreateError = (operation) => ({
  type: CLEAN_OPERATION_CREATE_ERROR,
  operation,
});
export const cleanOperationsCreateError = (operations) => ({
  type: CLEAN_OPERATIONS_CREATE_ERROR,
  operations,
});
export const cleanOperationUpdateError = (operation) => ({
  type: CLEAN_OPERATION_UPDATE_ERROR,
  operation,
});
export const cleanConstraintCreateError = (constraint) => ({
  type: CLEAN_CONSTRAINT_CREATE_ERROR,
  constraint,
});
export const cleanConstraintUpdateError = (constraint) => ({
  type: CLEAN_CONSTRAINT_UPDATE_ERROR,
  constraint,
});
export const cleanPercentilesError = () => ({
  type: CLEAN_PERCENTILES_ERROR,
});
export const cleanJobStatus = () => ({
  type: CLEAN_JOB_STATUS,
});
export const cleanAllErrors = (objectType) => ({
  type: CLEAN_ALL_ERRORS,
  objectType,
});

export const downloadResults = (jobId, projectId) => ({
  type: DOWNLOAD_RESULTS,
  projectId,
  jobId,
});

export const downloadResultsError = (error) => ({
  type: DOWNLOAD_RESULTS_ERROR,
  error,
});

export const resetMarineContractors = () => ({
  type: RESET_MARINE_CONTRACTORS_STATE,
});
