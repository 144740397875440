import React, { memo } from 'react';

/**
 * Component to work with native `a` tag same way as with Link
 * @param {String} to
 * @param {React.ReactNode} children
 * @param {Object} props
 */
const NativeLink = ({ to, children, ...restProps }) => (
  <a {...restProps} href={to} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

export default memo(NativeLink);
