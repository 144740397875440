/* eslint-disable */
import {
  call,
  delay,
  select,
  put,
  race,
  take,
  takeLatest,
} from 'redux-saga/effects';

import {
  getReports,
  getReport,
  postReport,
  cancelReport,
  generateReport as generateReportService,
  cleanReport as cleanReportService,
  deleteReport as deleteReportService,
  downloadReport,
} from 'services/reports';
import { UPDATE_REPORT_DELAY } from 'common/config';
import { selectLastComputationByProjectId } from 'ducks/projects/selectors';
import { findLatestReport } from 'helpers/reports';
import { REPORT_STATUS_RUNNING } from 'constants/reports';
import { triggerDownloadBlob } from 'helpers/dom';
import { trackReportGeneration } from 'ducks/trackers/actions/workzone';

import {
  REQUEST_REPORTS,
  POST_REPORT,
  POST_REPORT_SUCCESS,
  GENERATE_REPORT,
  CANCEL_REPORT,
  GET_REPORT_DATA,
  START_UPDATE_REPORT,
  CANCEL_UPDATE_REPORT,
  CREATE_REPORT,
  CLEAN_REPORT,
  CLEAN_REPORT_SUCCESS,
  DELETE_REPORT,
  DELETE_REPORT_SUCCESS,
  REMOVE_REPORT,
  GENERATE_REPORT_SUCCESS,
  DOWNLOAD_REPORT,
} from './types';
import {
  requestReports,
  requestReportsSuccess,
  requestReportsError,
  postReport as postReportAction,
  postReportError,
  postReportSuccess,
  getReportDataSuccess,
  getReportDataError,
  cancelReportSuccess,
  cancelReportError,
  generateReport,
  generateReportSuccess,
  generateReportError,
  finishUpdateReport,
  cancelUpdateReport,
  startUpdateReport,
  cleanReport,
  cleanReportSuccess,
  cleanReportError,
  deleteReport,
  deleteReportSuccess,
  deleteReportError,
  downloadReportSuccess,
  downloadReportError,
} from './actions';
import { selectCurrentReport, selectReport } from './selectors';

function* sagaRequestReports({ payload: params }) {
  try {
    const reports = yield call(getReports, params);
    yield put(requestReportsSuccess(reports));
    const lastReport = yield call(findLatestReport, reports);
    if (lastReport && lastReport.status === REPORT_STATUS_RUNNING) {
      yield put(generateReportSuccess(lastReport));
      yield put(startUpdateReport());
    }
  } catch (error) {
    yield put(requestReportsError(error));
  }
}

/**
 * Makes request to get report data every UPDATE_REPORT_DELAY milliseconds
 * @param id - report id
 */
function* sagaUpdateReportStatus(id) {
  while (true) {
    const response = yield call(getReport, id);
    if (response.status !== REPORT_STATUS_RUNNING) {
      yield put(getReportDataSuccess(response));
      yield put(cancelUpdateReport());
    }
    yield delay(UPDATE_REPORT_DELAY);
  }
}

/**
 * Start / Stop background task for updating report status
 */
function* sagaStartReportUpdateWorker() {
  try {
    const { id } = yield select(selectCurrentReport);
    yield race({
      report: call(sagaUpdateReportStatus, id),
      cancel: take(CANCEL_UPDATE_REPORT),
    });

    yield put(finishUpdateReport());
  } catch (error) {
    yield put(getReportDataError(error));
    yield put(finishUpdateReport());
  }
}

function* sagaGetReportData({ payload: id }) {
  try {
    const report = yield call(getReport, id);
    yield put(getReportDataSuccess(report));
  } catch (error) {
    yield put(getReportDataError(error));
  }
}

function* sagaPostReport({ payload: data }) {
  try {
    yield put(trackReportGeneration());
    const report = yield call(postReport, data);
    yield put(postReportSuccess(report));
  } catch (error) {
    yield put(postReportError(error));
  }
}

function* sagaGenerateReport({ id, data }) {
  try {
    const report = yield call(generateReportService, id, data);
    yield put(generateReportSuccess(report));
  } catch (error) {
    yield put(generateReportError(error));
  }
}

function* sagaCleanReport({ payload: id }) {
  try {
    const response = yield call(cleanReportService, id);
    yield put(cleanReportSuccess(response));
  } catch (error) {
    yield put(cleanReportError(error));
  }
}

function* sagaDeleteReport({ payload: id }) {
  try {
    const response = yield call(deleteReportService, id);
    yield put(deleteReportSuccess(response));
  } catch (error) {
    yield put(deleteReportError(error));
  }
}

function* sagaCreateReport({
  payload: { name, monthly, reportType, parts, points, projectId },
}) {
  const mostRecentComputation = yield select(
    selectLastComputationByProjectId,
    projectId
  );

  yield put(postReportAction({ name, computation: mostRecentComputation.id }));
  const { payload } = yield take(POST_REPORT_SUCCESS);

  yield put(
    generateReport(payload.id, {
      name,
      monthly,
      parts,
      points,
      reportType,
      detailedDists: true,
      tabulated: true,
    })
  );
  yield take(GENERATE_REPORT_SUCCESS);

  yield put(startUpdateReport());
}

function* sagaCancelReport() {
  try {
    const { id } = yield select(selectCurrentReport);
    yield call(cancelReport, id);
    yield put(cancelUpdateReport());
    yield put(cancelReportSuccess());
  } catch (error) {
    yield put(cancelReportError(error));
  }
}

function* deleteReportWorker({ payload: { reportId, projectId } }) {
  yield put(cleanReport(reportId));
  yield take(CLEAN_REPORT_SUCCESS);

  yield put(deleteReport(reportId));
  yield take(DELETE_REPORT_SUCCESS);

  yield put(requestReports({ project: projectId }));
}

/**
 * downloads report and opens it in new browser tab
 */
function* downloadReportWorker({ reportId }) {
  try {
    const { name } = yield select(selectReport, reportId);
    const report = yield call(downloadReport, reportId);
    yield call(triggerDownloadBlob, {
      blob: report,
      fileName: `${name}.pdf`,
      isDownload: true,
    });
    yield put(downloadReportSuccess({ reportId }));
  } catch (error) {
    yield put(downloadReportError({ error, reportId }));
  }
}

export default function* reportSagaWatcher() {
  yield takeLatest(REQUEST_REPORTS, sagaRequestReports);
  yield takeLatest(POST_REPORT, sagaPostReport);
  yield takeLatest(GENERATE_REPORT, sagaGenerateReport);
  yield takeLatest(GET_REPORT_DATA, sagaGetReportData);
  yield takeLatest(START_UPDATE_REPORT, sagaStartReportUpdateWorker);
  yield takeLatest(CANCEL_REPORT, sagaCancelReport);
  yield takeLatest(CREATE_REPORT, sagaCreateReport);
  yield takeLatest(CLEAN_REPORT, sagaCleanReport);
  yield takeLatest(DELETE_REPORT, sagaDeleteReport);
  yield takeLatest(REMOVE_REPORT, deleteReportWorker);
  yield takeLatest(DOWNLOAD_REPORT, downloadReportWorker);
}
