import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  group: {
    '& > *': {
      textTransform: ({ uppercase }) => (uppercase ? 'uppercase' : 'none'),
    },
    '& button': {
      outline: 'none',
    },
  },
});
