/**
 * Helps to get reducer with hooks
 * @param {Object} handlers
 * @param {string} type
 * @returns {function|undefined}
 */
const getHandler = (handlers, type) => {
  const handler = handlers[type];

  return typeof handler === 'string' ? getHandler(handlers, handler) : handler;
};

/**
 * helps to create reducers like an objects (key is action type, value is action handler)
 * It's faster and cleaner than switch approach
 * @param initialState
 * @param handlers
 * @return {function}
 */
export const createReducer = (initialState, handlers) => (
  state = initialState,
  action
) => {
  const handler = getHandler(handlers, action.type);

  return handler ? handler(state, action) : state;
};

export default createReducer;
