import {
  REQUEST_CUSTOMERS,
  REQUEST_CUSTOMERS_SUCCESS,
  REQUEST_CUSTOMERS_ERROR,
  REQUEST_CUSTOMER_DETAIL,
  REQUEST_CUSTOMER_DETAIL_SUCCESS,
  REQUEST_CUSTOMER_DETAIL_ERROR,
  REQUEST_USERS,
  REQUEST_USERS_SUCCESS,
  REQUEST_USERS_ERROR,
  REQUEST_USER_DETAIL,
  REQUEST_USER_DETAIL_SUCCESS,
  REQUEST_USER_DETAIL_ERROR,
  REQUEST_OFFERS,
  REQUEST_OFFERS_SUCCESS,
  REQUEST_OFFERS_ERROR,
  REQUEST_OFFER_DETAIL,
  REQUEST_OFFER_DETAIL_SUCCESS,
  REQUEST_OFFER_DETAIL_ERROR,
} from './types';

// CUSTOMERS
export const requestCustomers = () => ({
  type: REQUEST_CUSTOMERS,
});

export const requestCustomersSuccess = (customers) => ({
  type: REQUEST_CUSTOMERS_SUCCESS,
  customers,
});

export const requestCustomersError = (error) => ({
  type: REQUEST_CUSTOMERS_ERROR,
  error,
});

// CUSTOMER DETAIL
export const requestCustomerDetail = (id) => ({
  type: REQUEST_CUSTOMER_DETAIL,
  id,
});

export const requestCustomerDetailSuccess = (customer) => ({
  type: REQUEST_CUSTOMER_DETAIL_SUCCESS,
  customer,
});

export const requestCustomerDetailError = (error) => ({
  type: REQUEST_CUSTOMER_DETAIL_ERROR,
  error,
});

// USERS
export const requestUsers = () => ({
  type: REQUEST_USERS,
});

export const requestUsersSuccess = (users) => ({
  type: REQUEST_USERS_SUCCESS,
  users,
});

export const requestUsersError = (error) => ({
  type: REQUEST_USERS_ERROR,
  error,
});

// USER DETAIL
export const requestUserDetail = (id) => ({
  type: REQUEST_USER_DETAIL,
  id,
});

export const requestUserDetailSuccess = (user) => ({
  type: REQUEST_USER_DETAIL_SUCCESS,
  user,
});

export const requestUserDetailError = (error) => ({
  type: REQUEST_USER_DETAIL_ERROR,
  error,
});

// OFFERS
export const requestOffers = () => ({
  type: REQUEST_OFFERS,
});

export const requestOffersSuccess = (offers) => ({
  type: REQUEST_OFFERS_SUCCESS,
  offers,
});

export const requestOffersError = (error) => ({
  type: REQUEST_OFFERS_ERROR,
  error,
});

// OFFER DETAIL
export const requestOfferDetail = (id) => ({
  type: REQUEST_OFFER_DETAIL,
  id,
});

export const requestOfferDetailSuccess = (offer) => ({
  type: REQUEST_OFFER_DETAIL_SUCCESS,
  offer,
});

export const requestOfferDetailError = (error) => ({
  type: REQUEST_OFFER_DETAIL_ERROR,
  error,
});
