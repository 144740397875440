import {
  STATUS_LOADING,
  STATUS_ERROR,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
  EMPTY_VALUE,
} from 'constants/common';
import createReducer from 'helpers/createReducer';

import {
  POST_DEMO_FORM,
  POST_DEMO_FORM_SUCCESS,
  POST_DEMO_FORM_ERROR,
  DEMO_FORM_RESET,
} from './types';

const initialState = {
  response: EMPTY_VALUE,
  error: EMPTY_VALUE,
  status: STATUS_NOT_REQUESTED,
};

const setLoadingStatus = (state) => ({ ...state, status: STATUS_LOADING });

const setResponseData = (state, { payload }) => ({
  ...state,
  response: payload,
  status: STATUS_SUCCESS,
});

const setError = (state, { error }) => ({
  ...state,
  error,
  status: STATUS_ERROR,
});

const reset = () => initialState;

export default createReducer(initialState, {
  [POST_DEMO_FORM]: setLoadingStatus,
  [POST_DEMO_FORM_SUCCESS]: setResponseData,
  [POST_DEMO_FORM_ERROR]: setError,
  [DEMO_FORM_RESET]: reset,
});
