import { makeStyles } from '@material-ui/core/styles';

import {
  STATUS_CANCELLED_ICON,
  STATUS_FAILED_ICON,
  STATUS_FINISHED_ICON,
  STATUS_PENDING_ICON,
  STATUS_RUNNING_ICON,
} from 'constants/icons';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    height: 30,
  },
  status: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    paddingLeft: 24,
    fontWeight: 700,
    textTransform: 'uppercase',

    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      width: 16,
      height: 16,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
    },
    '&$pending': {
      color: theme.palette.white.main,

      '&:before': {
        backgroundImage: `url(${STATUS_PENDING_ICON})`,
      },
    },

    '&$running': {
      color: theme.palette.white.main,

      '&:before': {
        backgroundImage: `url(${STATUS_RUNNING_ICON})`,
        height: 24,
      },
    },

    '&$finished': {
      color: theme.palette.white.main,

      '&:before': {
        backgroundImage: `url(${STATUS_FINISHED_ICON})`,
      },
    },

    '&$failed': {
      color: theme.palette.white.main,

      '&:before': {
        backgroundImage: `url(${STATUS_FAILED_ICON})`,
      },
    },

    '&$cancelled': {
      color: theme.palette.white.main,

      '&:before': {
        backgroundImage: `url(${STATUS_CANCELLED_ICON})`,
      },
    },
  },

  pending: {},
  running: {},
  finished: {},
  failed: {},
  cancelled: {},
}));
