import { NAVIGATION_LABEL } from 'constants/tracker';

import { SEND_TRACKING_DATA } from '../types';

export const trackNavToDataValidation = (data) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: NAVIGATION_LABEL,
  gtmAction: 'Open DataValidation',
  data,
});

export const trackLogoClick = (data) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: NAVIGATION_LABEL,
  gtmAction: 'Back to MA logo',
  data,
});

export const trackMyProjectsClick = (data) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: NAVIGATION_LABEL,
  gtmAction: 'Back to My Project',
  data,
});
