import createReducer from 'helpers/createReducer';

import {
  APP_HIJACK_MODE_ENABLE,
  APP_HIJACK_EJECT,
  APP_INIT,
  REQUEST_INIT,
} from './types';

export const initialState = {
  isInitialized: false,
  hijackMode: false,
  requestIsLoaded: false,
  timeTrackerIsLoaded: false,
};

export default createReducer(initialState, {
  [APP_INIT]: (state) => ({
    ...state,
    isInitialized: true,
  }),
  [APP_HIJACK_MODE_ENABLE]: (state) => ({
    ...state,
    hijackMode: true,
  }),
  [APP_HIJACK_EJECT]: (state) => ({
    ...state,
    hijackMode: false,
  }),
  [REQUEST_INIT]: (state) => ({
    ...state,
    requestIsLoaded: true,
  }),
});
