import React, { memo, useMemo } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { X } from 'phosphor-react';

import { useToggle } from 'hooks/useToggle';
import { getControlContainerSelector } from 'helpers/map';
import { MAP_CONTROLS_POSITION } from 'constants/map';
import { useMapInstance } from 'hooks/leaflet';

import { useStyles } from './styles';

/**
 * Custom react component for render custom map controls.
 * @param {React.ReactNode} children
 * @param {String} position
 * @param {String} buttonText
 * @param {Boolean} hideActions
 * @param {Boolean} hidden
 * @return {React.ReactPortal}
 */
const MapCustomControl = ({
  children,
  position,
  hideActions,
  hidden,
  buttonText,
}) => {
  const classes = useStyles({ hidden });
  const mapInstance = useMapInstance();
  const containerSelector = useMemo(
    () => getControlContainerSelector(position),
    [position]
  );

  const { isActive, toggle } = useToggle(true);

  return (
    mapInstance &&
    ReactDOM.createPortal(
      hideActions ? (
        <div className={classes.container}>{children}</div>
      ) : (
        <div className={classes.container}>
          {isActive ? (
            <>
              <IconButton onClick={toggle} className={classes.closeButton}>
                <X size={20} />
              </IconButton>
              {children}
            </>
          ) : (
            <div
              role="button"
              tabIndex={0}
              onClick={toggle}
              className={classes.openLink}
            >
              {buttonText}
            </div>
          )}
        </div>
      ),
      document.querySelector(containerSelector)
    )
  );
};

MapCustomControl.propTypes = {
  position: PropTypes.string,
  children: PropTypes.node,
  buttonText: PropTypes.string,
  hideActions: PropTypes.bool,
  hidden: PropTypes.bool,
};

MapCustomControl.defaultProps = {
  position: MAP_CONTROLS_POSITION.bottomRight,
  buttonText: 'Show',
  hideActions: false,
  hidden: false,
};

export default memo(MapCustomControl);
