import React from 'react';
import PropTypes from 'prop-types';

import {
  AREA_GROUP_NAME,
  BUOYS_GROUP_NAME,
  MAP_LAYERS,
  POINTS_GROUP_NAME,
} from 'constants/map';
import {
  Map,
  MapGraticule,
  MapMouseCoordinates,
  MapLegend,
  MapMeasureControl,
} from 'components/leaflet';
import PointsFeatureGroup from 'components/maps/PointsFeatureGroup';
import ZoneCustomLayers from 'components/maps/ZoneCustomLayers';
import ZoneLayerGroup from 'components/maps/ZoneLayerGroup';

import ProjectPolygon from '../ProjectPolygon';

/**
 * DataValidationMap - map render project zone and project points with buoy.
 * @param {Object} grid - object with tilesPath, name and id
 * @param {Object} project
 * @param {Function} onPointClick
 * @param {React.ReactNode} children
 * @param {Object} props
 * @returns {jsx}
 */
const DataValidationMap = ({
  project,
  children,
  grid,
  onPointClick,
  ...props
}) => (
  <Map
    defaultLayer={MAP_LAYERS.worldImagery}
    position={project.zone.geom}
    {...props}
  >
    {children}
    <ProjectPolygon project={project} />
    <PointsFeatureGroup points={project.points} onClick={onPointClick} />
    {grid && <ZoneLayerGroup grid={grid} />}
    <MapGraticule />
    <MapMeasureControl />
    <ZoneCustomLayers />
    <MapLegend
      defaultGroups={[AREA_GROUP_NAME, POINTS_GROUP_NAME, BUOYS_GROUP_NAME]}
    />
    <MapMouseCoordinates />
  </Map>
);

DataValidationMap.propTypes = {
  project: PropTypes.shape({
    zone: PropTypes.object.isRequired,
    points: PropTypes.array.isRequired,
  }).isRequired,
  grid: PropTypes.shape({
    tilesPath: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  onPointClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default React.memo(DataValidationMap);
