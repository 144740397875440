import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, defaultTransition }) => ({
  search: {
    marginTop: 4,
    marginBottom: 20,
  },
  searchInputRoot: {
    cursor: 'pointer',
  },
  searchContainer: {
    marginBottom: 0,
    fontSize: 13,
    fontWeight: 500,
    color: palette.primary.main,
    textTransform: 'uppercase',
  },
  searchField: {
    height: 30,
    width: 470,
    borderBottom: `1px solid ${palette.lightGrey.main}`,
    transition: defaultTransition,
    '&:focus': {
      borderColor: palette.primary.light,
    },
  },
  searchFieldFocused: {},
}));
