const prefix = '@@projects';

export const REQUEST_PROJECTS = `${prefix}/REQUEST_PROJECTS`;
export const REQUEST_PROJECTS_IF_NEED = `${prefix}/REQUEST_PROJECTS_IF_NEED`;
export const REQUEST_PROJECTS_SUCCESS = `${prefix}/REQUEST_PROJECTS_SUCCESS`;
export const REQUEST_PROJECTS_ERROR = `${prefix}/REQUEST_PROJECTS_ERROR`;

export const REQUEST_PROJECT_ZONE = `${prefix}/REQUEST_PROJECT_ZONE`;
export const REQUEST_PROJECT_ZONE_SUCCESS = `${prefix}/REQUEST_PROJECT_ZONE_SUCCESS`;
export const REQUEST_PROJECT_ZONE_ERROR = `${prefix}/REQUEST_PROJECT_ZONE_ERROR`;

export const REQUEST_PROJECT = `${prefix}/REQUEST_PROJECT`;
export const REQUEST_PROJECT_IF_NEED = `${prefix}/REQUEST_PROJECT_IF_NEED`;
export const REQUEST_PROJECT_SUCCESS = `${prefix}/REQUEST_PROJECT_SUCCESS`;
export const REQUEST_PROJECT_ERROR = `${prefix}/REQUEST_PROJECT_ERROR`;

export const CREATE_PROJECT = `${prefix}/CREATE_PROJECT`;
export const CREATE_PROJECT_SUCCESS = `${prefix}/CREATE_PROJECT_SUCCESS`;
export const CREATE_PROJECT_ERROR = `${prefix}/CREATE_PROJECTS_ERROR`;

export const CREATE_TRIAL_PROJECT = `${prefix}/CREATE_TRIAL_PROJECT`;
export const CREATE_TRIAL_PROJECT_SUCCESS = `${prefix}/CREATE_TRIAL_PROJECT_SUCCESS`;
export const CREATE_TRIAL_PROJECT_ERROR = `${prefix}/CREATE_TRIAL_PROJECT_ERROR`;

export const CREATE_DEMO_PROJECT = `${prefix}/CREATE_DEMO_PROJECT`;
export const CREATE_DEMO_PROJECT_SUCCESS = `${prefix}/CREATE_DEMO_PROJECT_SUCCESS`;
export const CREATE_DEMO_PROJECT_ERROR = `${prefix}/CREATE_DEMO_PROJECT_ERROR`;

export const DELETE_PROJECT = `${prefix}/DELETE_PROJECT`;
export const DELETE_PROJECT_SUCCESS = `${prefix}/DELETE_PROJECT_SUCCESS`;
export const DELETE_PROJECT_ERROR = `${prefix}/DELETE_PROJECT_ERROR`;

export const RENAME_PROJECT = `${prefix}/RENAME_PROJECT`;
export const RENAME_PROJECT_SUCCESS = `${prefix}/RENAME_PROJECT_SUCCESS`;
export const RENAME_PROJECT_ERROR = `${prefix}/RENAME_PROJECT_ERROR`;

export const COMPUTE_PROJECT_STATISTICS = `${prefix}/COMPUTE_PROJECT_STATISTICS`;
export const COMPUTE_PROJECT_STATISTICS_SUCCESS = `${prefix}/COMPUTE_PROJECT_STATISTICS_SUCCESS`;
export const COMPUTE_PROJECT_STATISTICS_ERROR = `${prefix}/COMPUTE_PROJECT_STATISTICS_ERROR`;

export const CLEAR_PROJECTS_ERROR = `${prefix}/CLEAR_PROJECTS_ERROR`;

export const UPDATE_PROJECT_COLLABORATORS_REQUEST = `${prefix}/UPDATE_PROJECT_COLLABORATORS_REQUEST`;
export const UPDATE_PROJECT_COLLABORATORS_REQUEST_SUCCESS = `${prefix}/UPDATE_PROJECT_COLLABORATORS_REQUEST_SUCCESS`;
export const UPDATE_PROJECT_COLLABORATORS_REQUEST_ERROR = `${prefix}/UPDATE_PROJECT_COLLABORATORS_REQUEST_ERROR`;

export const REQUEST_PROJECT_STATS = `${prefix}/REQUEST_PROJECT_STATS`;
export const REQUEST_PROJECT_STATS_SUCCESS = `${prefix}/REQUEST_PROJECT_STATS_SUCCESS`;
export const REQUEST_PROJECT_STATS_ERROR = `${prefix}/REQUEST_PROJECT_STATS_ERROR`;

export const CANCEL_COMPUTE_PROJECT_STATISTICS = `${prefix}/CANCEL_COMPUTE_PROJECT_STATISTICS`;
export const CANCEL_COMPUTE_PROJECT_STATISTICS_SUCCESS = `${prefix}/CANCEL_COMPUTE_PROJECT_STATISTICS_SUCCESS`;
export const CANCEL_COMPUTE_PROJECT_STATISTICS_ERROR = `${prefix}/CANCEL_COMPUTE_PROJECT_STATISTICS_ERROR`;

export const REQUEST_PROJECT_VISUALIZATION_DATA = `${prefix}/REQUEST_PROJECT_VISUALIZATION_DATA`;
export const REQUEST_PROJECT_VISUALIZATION_DATA_IF_NEED = `${prefix}/REQUEST_PROJECT_VISUALIZATION_DATA_IF_NEED`;
export const REQUEST_PROJECT_VISUALIZATION_DATA_SUCCESS = `${prefix}/REQUEST_PROJECT_VISUALIZATION_DATA_SUCCESS`;
export const REQUEST_PROJECT_VISUALIZATION_DATA_ERROR = `${prefix}/REQUEST_PROJECT_VISUALIZATION_DATA_ERROR`;

export const START_UPDATE_PROJECT_JOB = `${prefix}/START_UPDATE_PROJECT_JOB`;
export const STOP_UPDATE_PROJECT_JOB = `${prefix}/STOP_UPDATE_PROJECT_JOB`;
export const FINISH_UPDATE_PROJECT_JOB = `${prefix}/FINISH_UPDATE_PROJECT_JOB`;

export const DOWNLOAD_GRAPH_FILE = `${prefix}/DOWNLOAD_GRAPH_FILE`;
export const DOWNLOAD_GRAPH_FILE_SUCCESS = `${prefix}/DOWNLOAD_GRAPH_FILE_SUCCESS`;
export const DOWNLOAD_GRAPH_FILE_ERROR = `${prefix}/DOWNLOAD_GRAPH_FILE_ERROR`;

export const DOWNLOAD_GRAPH_RESET = `${prefix}/DOWNLOAD_GRAPH_RESET`;
