import {
  REQUEST_STATIC_PAGE,
  REQUEST_STATIC_PAGE_ERROR,
  REQUEST_STATIC_PAGE_SUCCESS,
  REQUEST_STATIC_PAGE_IF_NEED,
  RESET_ERROR,
} from './types';

export const requestStaticPage = (url) => ({
  type: REQUEST_STATIC_PAGE,
  url,
});

export const requestStaticPageIfNeed = (url) => ({
  type: REQUEST_STATIC_PAGE_IF_NEED,
  url,
});

export const requestStaticPageSuccess = (data) => ({
  type: REQUEST_STATIC_PAGE_SUCCESS,
  data,
});

export const requestStaticPageError = (error) => ({
  type: REQUEST_STATIC_PAGE_ERROR,
  error,
});

export const resetStaticPagesError = () => ({
  type: RESET_ERROR,
});
