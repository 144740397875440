import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropsTypes from 'prop-types';

import MCLocationsComponent from 'components/projects/MarineContractors/Locations/index';
import { selectGeometriesByProjectId } from 'ducks/geometries/selectors';
import { selectMarineContractorsJobPoints } from 'ducks/marineContractors/selectors';
import { selectTilesFromStats2dWithPropertiesByZoneId } from 'ducks/stat2d/selectors';
import { EMPTY_ARRAY } from 'constants/common';
import { saveMarineContractorsJobPoints } from 'ducks/marineContractors/actions';
import { STEPS } from 'constants/marineContractors';

/**
 * Marine contractors locations creation/selection
 * @param { Object } project
 * @param { Object } job
 * @param { Function } handleNext
 * @param { Function } handleBack
 * @returns
 */
const MCLocationsContainer = ({ project, job, handleNext, handleBack }) => {
  const dispatch = useDispatch();

  // not a "frozen" list but acts as a ref
  const [frozenPoints, setFrozenPoints] = useState(null);
  const [selectedPoints, setSelectedPoints] = useState(EMPTY_ARRAY);
  const [selectedMarkers, setSelectedMarkers] = useState(EMPTY_ARRAY);

  const geometries = useSelector((state) =>
    selectGeometriesByProjectId(state, project?.id)
  );
  const zoneTileGeometries = useSelector((state) =>
    selectTilesFromStats2dWithPropertiesByZoneId(state, project?.zone?.id)
  );
  const favoritePoints = useSelector(selectMarineContractorsJobPoints);
  const projectPoints = project?.points;

  useEffect(() => {
    setFrozenPoints(projectPoints);
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setSelectedPoints(
      projectPoints.filter(
        (p) => !!favoritePoints?.map((fp) => fp.id).includes(p.id)
      )
    );
    // eslint-disable-next-line
  }, [favoritePoints])

  // no event is sent when a point is added or removed
  // so with the 'frozenPoints' length, we know when an addition or deletion has occured
  useEffect(() => {
    if (frozenPoints) {
      if (projectPoints.length > frozenPoints.length) {
        setSelectedPoints((prevState) => [
          ...prevState,
          ...projectPoints.filter(
            (p) => !frozenPoints.map((fp) => fp.id).includes(p.id)
          ),
        ]);
        setFrozenPoints(projectPoints);
      }
      if (projectPoints.length < frozenPoints.length) {
        setSelectedPoints((prevState) => [
          ...prevState.filter((pv) =>
            projectPoints.map((pp) => pp.id).includes(pv.id)
          ),
        ]);
        setFrozenPoints(projectPoints);
      }
    }
    // eslint-disable-next-line
  }, [projectPoints, frozenPoints]);

  const selectSingleMarkerOnMapHandler = useCallback(
    ({ sourceTarget }) => setSelectedMarkers([+sourceTarget.properties.id]),
    [setSelectedMarkers]
  );

  const selectSingleMarkerHandler = useCallback(
    ({ id }) => setSelectedMarkers([+id]),
    [setSelectedMarkers]
  );

  const handleSelectAll = useCallback(
    (e) => {
      if (e.target.checked) {
        setSelectedPoints(projectPoints);
        return;
      }
      setSelectedPoints(EMPTY_ARRAY);
    },
    // eslint-disable-next-line
    [projectPoints]
  );

  const handleOnDelete = useCallback(
    (id) => {
      const points = favoritePoints.filter((fp) => fp.id !== id);
      dispatch(
        saveMarineContractorsJobPoints(job, points, project.id, STEPS.LOCATIONS)
      );
    },
    // eslint-disable-next-line
   [favoritePoints])

  const handleMultipleSelection = useCallback(
    (e) => {
      const pointId = Number(e.target.value);
      const pointIndex = projectPoints.findIndex(({ id }) => id === pointId);
      if (pointIndex === -1) {
        console.error(`no point found with the following id : ${pointId}`);
        return;
      }
      if (e.target.checked) {
        setSelectedPoints((prevState) => [
          ...prevState.filter(({ id }) => id !== pointId),
          projectPoints[pointIndex],
        ]);
        return;
      }
      setSelectedPoints((prevState) => [
        ...prevState.filter(({ id }) => id !== pointId),
      ]);
    },
    // eslint-disable-next-line
    [projectPoints]
  );

  const prepareHandleNext = useCallback(() => {
    handleNext({ selectedPoints });
    // eslint-disable-next-line
  }, [selectedPoints]);

  return (
    <MCLocationsComponent
      project={project}
      selectedPoints={selectedPoints}
      geometries={geometries}
      zoneTileGeometries={zoneTileGeometries}
      handleNext={prepareHandleNext}
      handleBack={handleBack}
      handleMultipleSelection={handleMultipleSelection}
      selectedMarkers={selectedMarkers}
      selectSingleMarkerHandler={selectSingleMarkerHandler}
      selectSingleMarkerOnMapHandler={selectSingleMarkerOnMapHandler}
      handleSelectAll={handleSelectAll}
      handleOnDelete={handleOnDelete}
    />
  );
};

MCLocationsContainer.propTypes = {
  project: PropsTypes.object.isRequired,
  job: PropsTypes.object.isRequired,
  handleNext: PropsTypes.func.isRequired,
  handleBack: PropsTypes.func.isRequired,
};

export default React.memo(MCLocationsContainer);
