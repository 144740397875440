import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';

import { ProgressLoader } from 'components/common/ProgressLoader';
import { RUNNING_STATUS } from 'constants/projects';

import { useStyles } from './styles';

/**
 * Return status with icon
 * @param {String} status
 * @param {Number} progressValue
 * @returns {jsx}
 */
export const Status = ({ status, progressValue }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <Grid
        direction="column"
        justifyContent="center"
        alignItems="center"
        container
      >
        <Grid item>{t('projects.status.title')}&nbsp;</Grid>
        <Grid item className={classes.container}>
          <span
            className={classNames(
              classes.status,
              classes[status.toLowerCase()]
            )}
          >
            {t(`projects.status.${status.toLowerCase()}`)}
          </span>

          <div className={classes.loader}>
            {status.toLowerCase() === RUNNING_STATUS && (
              <ProgressLoader
                value={progressValue}
                whiteColor
                variant="determinate"
              />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

Status.propTypes = {
  status: PropTypes.string.isRequired,
  progressValue: PropTypes.number,
};

Status.defaultProps = {
  progressValue: 0,
};

export default Status;
