import uniqueId from 'lodash/uniqueId';

import { SHOW_MODAL, CLOSE_ALL_MODAL, CLOSE_MODAL } from './types';

export const showModal = ({ modalType, options, id = uniqueId() }) => ({
  type: SHOW_MODAL,
  modalType,
  options,
  id,
});

export const closeModal = ({ id }) => ({ type: CLOSE_MODAL, id });

export const closeAllModal = () => ({ type: CLOSE_ALL_MODAL });
