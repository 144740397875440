import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import SelectZoneMap from 'components/maps/SelectZoneMap';
import BaseSideBar from 'components/common/BaseSideBar';
import ZonesList from 'components/zones/ZonesList';
import { trackZoneSelection } from 'ducks/trackers/actions/projectManagment';

import { useStyles } from './styles';

/**
 * Render side bar with zones and map with available zones
 * @param {Array} zones
 * @param {Array} demoZones
 * @param {Array} offerZones
 * @param {Number} offerId
 * @returns {jsx}
 */
const SelectZone = ({ zones, demoZones, offerZones, offerId }) => {
  const classes = useStyles();
  const [selectedZoneId, setSelectedZoneIdRaw] = useState(null);

  // setup zone selection tracking
  const dispatch = useDispatch();
  const setSelectedZoneId = useCallback(
    (zoneId) => {
      dispatch(trackZoneSelection(zoneId));
      return setSelectedZoneIdRaw(zoneId);
    },
    [setSelectedZoneIdRaw, dispatch]
  );

  const { publicZones, demoOtherZones, allOtherZones } = useMemo(() => {
    const offerIds = offerZones.map(({ id }) => id);
    const demoIds = demoZones.map(({ id }) => id);
    const demoZonesWithoutOffer = demoZones.filter(
      ({ id }) => !offerIds.includes(id)
    );
    const allPublicZones = zones.filter(
      ({ id, isPrivate }) => !isPrivate || offerIds.includes(id)
    );
    const allPublicZonesWithoutOfferAndDemo = zones.filter(
      ({ id, isPrivate }) =>
        !demoIds.includes(id) && !offerIds.includes(id) && !isPrivate
    );

    return {
      publicZones: allPublicZones,
      demoOtherZones: demoZonesWithoutOffer,
      allOtherZones: allPublicZonesWithoutOfferAndDemo,
    };
  }, [zones, demoZones, offerZones]);

  const offerZoneIds = useMemo(() => offerZones.map(({ id }) => id), [
    offerZones,
  ]);

  return (
    <Grid container className={classes.createProjectContainer} wrap="nowrap">
      <Grid item>
        <BaseSideBar sideBarClass={classes.createProjectSideBar}>
          <ZonesList
            offerZones={offerZones}
            demoZones={demoOtherZones}
            otherZones={allOtherZones}
            onChange={setSelectedZoneId}
            selectedZoneId={selectedZoneId}
          />
        </BaseSideBar>
      </Grid>
      <Grid item className={classes.createProjectMap}>
        <SelectZoneMap
          zones={publicZones}
          offerZoneIds={offerZoneIds}
          onSelect={setSelectedZoneId}
          offerId={offerId}
          selectedZoneId={selectedZoneId}
        />
      </Grid>
    </Grid>
  );
};

const zoneShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  geom: PropTypes.string,
  isWorld: PropTypes.bool,
  isDemo: PropTypes.bool,
  isPrivate: PropTypes.bool,
});

SelectZone.propTypes = {
  zones: PropTypes.arrayOf(zoneShape).isRequired,
  demoZones: PropTypes.arrayOf(zoneShape).isRequired,
  offerZones: PropTypes.arrayOf(zoneShape).isRequired,
  offerId: PropTypes.number.isRequired,
};

export default React.memo(SelectZone);
