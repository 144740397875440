import {
  INIT_KEYCLOAK,
  AUTH_KEYCLOAK_SUCCESS,
  AUTH_KEYCLOAK_FAILED,
  SET_KEYCLOAK_INSTANCE,
  SET_KEYCLOAK_TOKENS,
  IMPERSONATE_USER,
  STOP_IMPERSONATE,
  LOGOUT_KEYCLOAK,
} from './types';

export const initKeycloak = () => ({
  type: INIT_KEYCLOAK,
});

export const setKeycloakInstance = (instance) => ({
  type: SET_KEYCLOAK_INSTANCE,
  instance,
});

export const authKeycloakSuccess = () => ({
  type: AUTH_KEYCLOAK_SUCCESS,
});

export const authKeycloakFailed = () => ({
  type: AUTH_KEYCLOAK_FAILED,
});

export const setKeycloakTokens = (accessToken, refreshToken) => ({
  type: SET_KEYCLOAK_TOKENS,
  accessToken,
  refreshToken,
});

export const impersonateUser = (accessToken, refreshToken) => ({
  type: IMPERSONATE_USER,
  accessToken,
  refreshToken,
});

export const stopImpersonation = () => ({
  type: STOP_IMPERSONATE,
});

export const logoutKeycloak = () => ({
  type: LOGOUT_KEYCLOAK,
});
