import { createSelector } from 'reselect';

import { STATUS_LOADING } from 'constants/common';

export const selectZones = ({ zones }) => ({
  data: zones.data,
  ids: zones.ids,
});

export const selectZonesData = ({ zones }) => zones.data;

export const selectZoneById = createSelector(
  selectZonesData,
  (_, zoneId) => zoneId,
  (zonesById, zoneId) => zonesById[zoneId]
);

export const selectZoneIsLoading = ({ zones }) =>
  zones.status === STATUS_LOADING;

export const selectZonesCollection = ({ zones }) =>
  zones.ids.map((id) => zones.data[id]);

/**
 * select zones wih flag 'isDemo'
 */
export const selectDemoZones = createSelector(
  [selectZonesCollection],
  (zones) => zones.filter(({ isDemo }) => isDemo)
);
