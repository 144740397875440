import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { useStyles } from './styles';

const ProfileRow = ({ title, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      <Typography className={classes.boldText}>{title}</Typography>
      <Typography>:&nbsp;{value}</Typography>
    </div>
  );
};

/**
 * MyProfilePage
 * Displays customer details information and list of collaborators
 */
const MyProfilePage = ({
  userDetails,
  showOverviewControl,
  checkboxHandler,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <Typography variant="h5" className={classes.title}>
        {userDetails.fullName}
      </Typography>
      <div className={classes.container}>
        <div className={classes.textBlock}>
          <ProfileRow
            title={t('profile.username')}
            value={userDetails.username}
          />
          <ProfileRow title={t('profile.email')} value={userDetails.email} />
          <ProfileRow
            title={t('profile.projects')}
            value={userDetails.projects}
          />
          <ProfileRow
            title={t('profile.computations')}
            value={userDetails.computations}
          />
          <ProfileRow
            title={t('profile.awdComputations')}
            value={userDetails.awdComputations}
          />
          <ProfileRow
            title={t('profile.reports')}
            value={userDetails.reports}
          />
          <ProfileRow title={t('profile.comments')} value="N/A" />
          <ProfileRow
            title={t('profile.credits')}
            value={userDetails.credits}
          />
        </div>
      </div>
      <Typography variant="h5" className={classes.title}>
        {t('profile.preferences')}
      </Typography>
      <FormControlLabel
        className={classes.checkboxRow}
        control={
          <Checkbox
            name="analyze"
            checked={showOverviewControl}
            onChange={checkboxHandler}
            color="primary"
          />
        }
        label={
          <Typography>
            <strong>{t('profile.analyzeCheckboxTitle')}</strong>:&nbsp;
            {t('profile.analyzeCheckbox')}
          </Typography>
        }
      />
    </div>
  );
};

MyProfilePage.propTypes = {
  userDetails: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    username: PropTypes.string,
    fullName: PropTypes.string,
    credits: PropTypes.number,
    reports: PropTypes.number,
    projects: PropTypes.number,
    computations: PropTypes.number,
    awdComputations: PropTypes.number,
  }),
  showOverviewControl: PropTypes.bool,
  checkboxHandler: PropTypes.func.isRequired,
};

MyProfilePage.defaultProps = {
  showOverviewControl: true,
};

export default memo(MyProfilePage);
