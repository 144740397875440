import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import ResetPasswordSuccessComponent from 'components/modals/ResetPasswordSuccessModal';
import { useModal } from 'hooks/useModal';
import { ROUTES } from 'constants/routes';

/**
 * ResetPasswordSuccessModal container
 * Contains logic and data for ResetPasswordSuccessModal
 * @param id - modal id
 */
const ResetPasswordSuccessModal = ({ id }) => {
  const history = useHistory();
  const { onClose } = useModal(id);

  const handleClose = useCallback(() => {
    onClose();
    history.push(ROUTES.login);
  }, [onClose, history]);

  const handleRedirect = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <ResetPasswordSuccessComponent
      onClose={handleClose}
      onRedirect={handleRedirect}
    />
  );
};

ResetPasswordSuccessModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default React.memo(ResetPasswordSuccessModal);
