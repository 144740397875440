import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import * as serviceWorker from 'serviceWorker';
import App from 'containers/App';
import { store, history } from 'store';
import ModalManager from 'containers/modals/ModalManager';
import { sendTrackingPageviewToGoogleWorker } from 'ducks/trackers/sagas';

import { theme } from './common/theme';

import './i18n';
import 'core-js';
import 'formdata-polyfill';
import 'assets/styles/main.scss';

// Send tracking data to google (GTM) on every history changes
sendTrackingPageviewToGoogleWorker(history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ModalManager />
        <App />
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
