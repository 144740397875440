import { getPreparedExtremeValueAnalysisData } from 'helpers/graphs/extremeValueAnalysis';
import { RETURN_VALUE_X_AXIS_VARIABLE, VALUES_KEY } from 'constants/graphs';
import {
  getAxisDataByVariables,
  getLevelsTypeByStatsId,
  getTitleFromRawGraphData,
} from 'helpers/graphs/common';
import { getReturnValuesPlotCommonData } from 'helpers/graphs/returnValuesPlot';
import { getPreparedExtremePeakRoseCommonData } from 'helpers/graphs/extremePeakRose';
import { INVALID_GRAPH_DATA } from 'constants/errors';

/**
 * since Extreme values component is very inconsistent now
 * there're different combinations of it's content with different space needed
 * TODO when content would be consistent and predictable in future, rm this and choose strict modal width
 */
const EXTREME_MODAL_SIZES = {
  xs: 520,
  sm: 600,
  md: 890,
  lg: 980,
};

/**
 * returns prepared extreme values for extremeCharts modal
 * includes data for ReturnValue, peakRose charts and extreme analysis table
 * width for modal is calculated here, can be removed in future when modal content is consistent
 * @param { object } rawData
 * @returns {{
 *    levels,
 *    title,
 *    extraProps: {
 *      modalWidth
 *    },
 *    data: {
 *     tableData,
 *     returnValuesData,
 *     peakRoseData,
 *   }
 * }}
 */
export const getPreparedExtremeValuesData = (rawData) => {
  const { mainVariable, associatedVariable } = rawData;
  if (!mainVariable) {
    throw Error(INVALID_GRAPH_DATA);
  }

  const levels =
    rawData.level && rawData.level[VALUES_KEY]
      ? {
          type: getLevelsTypeByStatsId(rawData.id),
          values: rawData.level,
        }
      : {};

  const hasLevels = !!Object.keys(levels).length;
  const withPeakRose = !!associatedVariable;

  const { data = [] } = rawData;

  const returnValueAxisData = getAxisDataByVariables({
    xVariable: RETURN_VALUE_X_AXIS_VARIABLE,
    yVariable: mainVariable,
  });

  const peakRoseAxisData = withPeakRose
    ? getAxisDataByVariables({
        xVariable: associatedVariable,
        yVariable: mainVariable,
      })
    : null;

  const dataToIterate = hasLevels ? data : [data];

  const preparedExtremeValuesdData = dataToIterate.map((currentItem) => {
    const currentData = hasLevels ? currentItem.data : currentItem;
    const returnValuesData = {
      chartData: getReturnValuesPlotCommonData(currentData, mainVariable),
      axisData: returnValueAxisData,
    };
    const tableData = getPreparedExtremeValueAnalysisData({
      data: currentData,
      mainVariable,
    });
    const peakRoseData = withPeakRose
      ? {
          axisData: peakRoseAxisData,
          chartData: getPreparedExtremePeakRoseCommonData(currentData),
        }
      : null;

    return {
      tableData,
      returnValuesData,
      peakRoseData,
    };
  });

  const [
    {
      tableData: { variants },
    },
  ] = preparedExtremeValuesdData;
  const hasOneLaw = variants && variants.length === 1;

  const modalWidth = {
    true: EXTREME_MODAL_SIZES.md,
    [hasOneLaw && !hasLevels && !withPeakRose]: EXTREME_MODAL_SIZES.xs,
    [hasOneLaw && hasLevels && !withPeakRose]: EXTREME_MODAL_SIZES.sm,
    [!hasOneLaw && hasLevels && withPeakRose]: EXTREME_MODAL_SIZES.lg,
  }.true;

  return {
    extraProps: {
      modalWidth,
    },
    levels,
    title: getTitleFromRawGraphData(rawData),
    data: hasLevels
      ? preparedExtremeValuesdData
      : preparedExtremeValuesdData[0],
  };
};

/**
 * Append success status and info to getPreparedExtremeValuesData(rawData).
 * @see getPreparedExtremeValuesData()
 *
 * @param { object } rawData
 * @returns {{
 *    succeed,
 *    info,
 *    levels,
 *    title,
 *    extraProps: {
 *      modalWidth
 *    },
 *    data: {
 *     tableData,
 *     returnValuesData,
 *     peakRoseData,
 *   }
 * }}
 */
export const getPreparedNonCyclonicExtremeValuesData = (rawData) => {
  if (!rawData.success) {
    return {
      succeed: false,
      info: rawData.info ? rawData.info : null,
      title: getTitleFromRawGraphData(rawData),
      data: null,
      level: null,
      extraProps: null,
    };
  }
  const data = getPreparedExtremeValuesData(rawData);
  data.succeed = true;
  data.info = null;
  return data;
};
