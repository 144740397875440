import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: 30,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
}));
