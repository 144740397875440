import pointNotReadySatelliteImg from 'assets/images/point/point-not-ready_s_white.png';
import pointNotReadyImg from 'assets/images/point/point-not-ready.png';
import pointReadyImg from 'assets/images/point/point-ready.png';
import pointReadySatelliteImg from 'assets/images/point/point-ready_s_white.png';
import pointNotReadySelectedSatelliteImg from 'assets/images/point/point-not-ready-selected_s_white.png';
import pointNotReadySelectedImg from 'assets/images/point/point-not-ready-selected.png';
import pointReadySelectedImg from 'assets/images/point/point-ready-selected.png';
import pointReadySelectedSatelliteImg from 'assets/images/point/point-ready-selected_s_white.png';
import statusPendingIcon from 'assets/images/project/project-status-pending.png';
import statusRunningIcon from 'assets/images/project/project-status-running.png';
import statusFinishedIcon from 'assets/images/project/project-status-finished.png';
import statusCancelledIcon from 'assets/images/project/project-status-cancelled.png';
import statusFailedIcon from 'assets/images/project/project-status-failed.png';
import zoneLegendIcon from 'assets/images/legends/zone.png';
import gridLegendIcon from 'assets/images/legends/grid.png';
import computedLegendIcon from 'assets/images/legends/computed.png';
import notComputedLegendIcon from 'assets/images/legends/not-computed.png';
import redCursor from 'assets/cursors/red-cursor.cur';
import cartIcon from 'assets/images/project/ic_cart.png';
import buoyCurrent from 'assets/images/buoy/buoy_current.png';
import buoyWave from 'assets/images/buoy/buoy_wave.png';
import buoyWind from 'assets/images/buoy/buoy_wind.png';
import buoy from 'assets/images/buoy/buoy.png';

export const POINT_NOT_READY_SATELLITE_ICON = pointNotReadySatelliteImg;
export const POINT_NOT_READY_ICON = pointNotReadyImg;
export const POINT_READY_ICON = pointReadyImg;
export const POINT_READY_SATELLITE_ICON = pointReadySatelliteImg;
export const POINT_NOT_READY_SELECTED_SATELLITE_ICON = pointNotReadySelectedSatelliteImg;
export const POINT_NOT_READY_SELECTED_ICON = pointNotReadySelectedImg;
export const POINT_READY_SELECTED_ICON = pointReadySelectedImg;
export const POINT_READY_SELECTED_SATELLITE_ICON = pointReadySelectedSatelliteImg;

export const STATUS_PENDING_ICON = statusPendingIcon;
export const STATUS_RUNNING_ICON = statusRunningIcon;
export const STATUS_FINISHED_ICON = statusFinishedIcon;
export const STATUS_CANCELLED_ICON = statusCancelledIcon;
export const STATUS_FAILED_ICON = statusFailedIcon;

export const ZONE_LEGEND_ICON = zoneLegendIcon;
export const GRID_LEGEND_ICON = gridLegendIcon;
export const COMPUTED_LEGEND_ICON = computedLegendIcon;
export const NOT_COMPUTED_LEGEND_ICON = notComputedLegendIcon;

export const RED_CURSOR = redCursor;

export const CART_ICON = cartIcon;

export const BUOY_CURRENT_ICON = buoyCurrent;
export const BUOY_WAVE_ICON = buoyWave;
export const BUOY_WIND_ICON = buoyWind;
export const BUOY_ICON = buoy;
