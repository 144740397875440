import { useState, useCallback } from 'react';

/**
 * hook for toggle something. Returns toggle function and isActive state
 * @param {Boolean} defaultValue
 * @returns {{ toggle: (function(): void), isActive: boolean }}
 */
export const useToggle = (defaultValue = false) => {
  const [isActive, setIsActive] = useState(defaultValue);
  const toggle = () => setIsActive((prevIsActive) => !prevIsActive);

  return {
    isActive,
    toggle: useCallback(toggle, [setIsActive]),
  };
};
