import { SEARCH_LABEL } from 'constants/tracker';

import { SEND_TRACKING_DATA } from '../types';

export const trackSearch = (data) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: SEARCH_LABEL,
  gtmAction: 'Type',
  data,
});
