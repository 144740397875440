import React, { memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

/**
 * BaseProject component
 * Base component for displaying dashboard cards
 * @param {Number} id - project id
 * @param {Function} onClick - card click handler
 * @param {React.ReactNode} children - card body (map)
 * @param {React.ReactNode} header - card header
 * @param {String} className - given custom className
 */
const BaseProject = ({ onClick, children, header, className, id }) => {
  const classes = useStyles();
  return (
    <div
      role="button"
      data-project-id={id}
      className={classNames(classes.card, className)}
      onClick={onClick}
      tabIndex={0}
    >
      {header}
      <div className={classes.cardBody}>{children}</div>
    </div>
  );
};

BaseProject.propTypes = {
  onClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  children: PropTypes.node,
  header: PropTypes.node,
  className: PropTypes.string,
};

export default memo(BaseProject);
