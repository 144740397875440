import React from 'react';
import PropTypes from 'prop-types';

import NotificationModal from 'components/modals/NotificationModal';
import { useModal } from 'hooks/useModal';

/**
 * NotificationModalContainer - container prepare data for NotificationModal and render it
 * @param { string } type - snackbar type (success, warning, info, error)
 * @param { number } autoHideDuration - autohiding duration in mseconds
 * @param { string } id id of modal
 * @param { string } title title of modal
 * @param { string } message text in modal
 * @param { function } closeModal callback when closing the modal
 */
const NotificationModalContainer = ({
  id,
  type,
  title,
  message,
  autoHideDuration,
  closeModal,
}) => {
  const { onClose } = useModal(id);

  return (
    <NotificationModal
      type={type}
      closeModal={closeModal || onClose}
      title={title}
      message={message}
      autoHideDuration={autoHideDuration}
    />
  );
};

NotificationModalContainer.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  autoHideDuration: PropTypes.number,
  title: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default React.memo(NotificationModalContainer);
