import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 500,
    backgroundColor: theme.palette.danger.main,
    color: 'white',
    '& .modal_close-button': {
      color: 'white',
    },
  },
  body: {
    padding: '10px 5px',
  },
  planningName: {
    margin: '0 5px 0 5px',
  },
}));
