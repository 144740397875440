/* eslint-disable */
import {
  all,
  put,
  fork,
  takeLatest,
  call,
  take,
  race,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  AUTH_KEYCLOAK_SUCCESS,
  AUTH_KEYCLOAK_FAILED,
} from 'ducks/keycloak/types';
import {
  loginRequest,
  fetchCurrentUser,
  inviteUser,
  removeUser,
  editUserProfile,
  resendUserInvitation,
  revokeUserInvitation,
  resetPassword,
  resetPasswordConfirm,
  registrationUser,
  registrationPending,
  registrationActivate,
} from 'services/user';
import { getUserSettings, changeUserSettings } from 'services/storage';
import { decamelizeKeys } from 'helpers/camelizer';
import { ROUTES } from 'constants/routes';
import {
  trackLogin,
  trackLogout,
  trackRegistrationActivation,
} from 'ducks/trackers/actions/authentification';
import { trackUserInvitation } from 'ducks/trackers/actions/admin';
import { setKeycloakTokens, logoutKeycloak } from 'ducks/keycloak/actions';

import {
  USER_LOGIN_REQUEST,
  FETCH_CURRENT_USER,
  USER_LOGIN_REQUEST_SUCCESS,
  USER_LOGOUT,
  REMOVE_USER_REQUEST,
  INVITE_USER_REQUEST,
  GET_USER_SETTINGS,
  CHANGE_USER_SETTINGS,
  USER_SIGNUP_REQUEST,
  USER_RESET_PASSWORD_REQUEST,
  EDIT_USER_PROFILE_REQUEST,
  RESEND_USER_INVITATION_REQUEST,
  REVOKE_USER_INVITATION_REQUEST,
  USER_RESET_PASSWORD_CONFIRM_REQUEST,
  CHECK_IS_REGISTRATION_PENDING,
  ACTIVATE_REGISTRATION_REQUEST,
} from './types';
import {
  removeUserSucces,
  removeUserError,
  userRequestError,
  getCurrentUserSuccess,
  userLoginSuccess,
  inviteUserError,
  inviteUserSuccess,
  putUserSettings,
  userSignupSuccess,
  userResetPasswordSuccess,
  editUserProfileRequestSuccess,
  editUserProfileRequestError,
  resendUserInvitationRequestSuccess,
  resendUserInvitationRequestError,
  revokeUserInvitationRequestSuccess,
  revokeUserInvitationRequestError,
  checkIsRegistrationPendingSuccess,
  checkIsRegistrationPendingError,
} from './actions';

function* userLoginWorker({ values }) {
  try {
    const data = {
      username: values.username,
      password: values.password,
    };
    const response = yield call(loginRequest, data);
    if (response.accessToken && response.refreshToken) {
      yield put(setKeycloakTokens(response.accessToken, response.refreshToken));
      // wait for success/failure of the keycloak auth
      yield race([take(AUTH_KEYCLOAK_SUCCESS), take(AUTH_KEYCLOAK_FAILED)]);
      // send tracking info about logged user to google
      yield put(trackLogin());
      yield put(userLoginSuccess());
    } else {
      yield put(userRequestError(response.data));
    }
  } catch (error) {
    yield put(userRequestError(error));
  }
}

function* getCurrentUserWorker() {
  try {
    const response = yield call(fetchCurrentUser);
    yield put(getCurrentUserSuccess(response));
  } catch (error) {
    if (error.status !== 401) {
      yield put(userRequestError(error));
    }
  }
}

function* removeUserWorker({ data }) {
  try {
    yield call(removeUser, data);
    yield put(removeUserSucces(data));
  } catch (error) {
    yield put(removeUserError(error));
  }
}

function* logoutWorker() {
  // closeActivityTracker();
  // send tracking info about the loggout to google
  yield put(logoutKeycloak());
  yield put(trackLogout());
}

function* inviteUserWorker({ data }) {
  try {
    const res = yield call(inviteUser, data);
    yield put(inviteUserSuccess({ id: res.id, email: res.email }));

    // send tracking info about the invitation to google
    yield put(trackUserInvitation(data));
  } catch (error) {
    yield put(inviteUserError({ msg: error.message[0] }));
  }
}

function* getUserSettingsWorker() {
  try {
    const data = yield call(getUserSettings);
    yield put(putUserSettings(data));
  } catch (error) {
    yield put(userRequestError(error));
  }
}

function* changeUserSettingsWorker({ value }) {
  try {
    const data = yield call(changeUserSettings, value);
    yield put(putUserSettings(data));
  } catch (error) {
    yield put(userRequestError(error));
  }
}

function* userSignUpWorker({ values }) {
  try {
    yield call(registrationUser, values);
    yield put(userSignupSuccess());
  } catch (error) {
    yield put(userRequestError(error));
  }
}

/**
 * sends request which checks if there is a pending registration with activation key provided in data
 * if request returns the status success, then activation form is displayed
 */
function* checkRegistrationWorker({ data }) {
  try {
    yield call(registrationPending, data);
    yield put(checkIsRegistrationPendingSuccess());
  } catch (error) {
    yield put(checkIsRegistrationPendingError(error));
  }
}

function* activateRegistrationWorker({ values }) {
  try {
    yield call(registrationActivate, values);
    yield put(trackRegistrationActivation(values));
    yield put(push(ROUTES.login));
  } catch (error) {
    yield put(userRequestError(error));
  }
}

function* userResetPasswordWorker({ values }) {
  try {
    yield call(resetPassword, values);
    yield put(userResetPasswordSuccess());
  } catch (error) {
    yield put(userRequestError(error));
  }
}

function* userResetPasswordConfirmWorker({ values }) {
  try {
    yield call(resetPasswordConfirm, values);
    yield put(push(ROUTES.login));
  } catch (error) {
    yield put(userRequestError(error));
  }
}

function* editUserProfileWorker({ values }) {
  try {
    const preparedValues = decamelizeKeys(values);
    const editedUserData = yield call(editUserProfile, preparedValues);
    yield put(editUserProfileRequestSuccess(editedUserData));
  } catch (error) {
    yield put(editUserProfileRequestError(error));
  }
}

function* resendUserInvitationWorker({ userId }) {
  try {
    const message = yield call(resendUserInvitation, userId);
    yield put(resendUserInvitationRequestSuccess(message));
  } catch (error) {
    yield put(resendUserInvitationRequestError(error));
  }
}

function* revokeUserInvitationWorker({ userId }) {
  try {
    const message = yield call(revokeUserInvitation, userId);
    yield put(revokeUserInvitationRequestSuccess({ message, userId }));
  } catch (error) {
    yield put(revokeUserInvitationRequestError(error));
  }
}

export function* userSagas() {
  yield takeLatest(REMOVE_USER_REQUEST, removeUserWorker);
  yield takeLatest(USER_LOGIN_REQUEST, userLoginWorker);
  yield takeLatest(USER_LOGIN_REQUEST_SUCCESS, getCurrentUserWorker);
  yield takeLatest(FETCH_CURRENT_USER, getCurrentUserWorker);
  yield takeLatest(USER_LOGOUT, logoutWorker);
  yield takeLatest(INVITE_USER_REQUEST, inviteUserWorker);
  yield takeLatest(GET_USER_SETTINGS, getUserSettingsWorker);
  yield takeLatest(CHANGE_USER_SETTINGS, changeUserSettingsWorker);
  yield takeLatest(USER_SIGNUP_REQUEST, userSignUpWorker);
  yield takeLatest(CHECK_IS_REGISTRATION_PENDING, checkRegistrationWorker);
  yield takeLatest(ACTIVATE_REGISTRATION_REQUEST, activateRegistrationWorker);
  yield takeLatest(USER_RESET_PASSWORD_REQUEST, userResetPasswordWorker);
  yield takeLatest(
    USER_RESET_PASSWORD_CONFIRM_REQUEST,
    userResetPasswordConfirmWorker
  );
  yield takeLatest(EDIT_USER_PROFILE_REQUEST, editUserProfileWorker);
  yield takeLatest(RESEND_USER_INVITATION_REQUEST, resendUserInvitationWorker);
  yield takeLatest(REVOKE_USER_INVITATION_REQUEST, revokeUserInvitationWorker);
}

export default function*() {
  yield all([fork(userSagas)]);
}
