import { makeStyles } from '@material-ui/core/styles';

import { theme } from 'common/theme';

export const useStyles = makeStyles({
  comparisonAgainstModal: {
    maxWidth: 900,
    width: '100%',
  },
  comparisonText: {
    fontSize: 14,
    '&:not(:last-child)': {
      marginBottom: 10,
    },
  },
  progress: {
    position: 'absolute',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  launchButton: {
    ...theme.buttons.outlined.secondary,
  },
  closeButton: {
    ...theme.buttons.elevated.neutral,
  },
});
