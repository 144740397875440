import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  floatingButton: {
    ...theme.buttons.fill.primary,
    zIndex: theme.zIndexNavBar + 1,
  },
  label: {
    color: 'white',
    fontWeight: 650,
    textTransform: 'none',
  },
}));
