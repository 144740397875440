import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    textAlign: 'center',
  },
  buttonsGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  noUploadsMessage: {
    marginLeft: 30,
    marginBottom: 15,
    fontSize: 14,
  },
  formText: {
    margin: '15px 0',
    fontSize: 12,
  },
  cancelButton: {
    ...theme.buttons.elevated.neutral,
  },
  uploadButton: {
    ...theme.buttons.fill.primary,
  },
}));
