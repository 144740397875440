export const waveDistributionRoseGraphData = {
  annual: {
    id: 'WAV-hs,dp-DR_2147',
    point: {
      name: 'test',
      id: '2147',
    },
    layer: 'WAVES',
    type: 'DR',
    name: 'Distribution rose',
    variables: {
      names: ['hs', 'dp'],
      variables: [
        {
          units: 'm',
          name: 'hs',
          hidden: false,
          long_name: 'significant wave height',
        },
        {
          units: 'degrees',
          name: 'dp',
          hidden: false,
          long_name: 'wave energy peak direction',
        },
      ],
    },
    visualization: {
      web: {
        'excel-uri-0':
          '/static/3.6.3/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2147/WAVES/WAV-hs-dp-DR_2147.xls',
        'netcdf-uri-0':
          '/static/3.6.3/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2147/WAVES/WAV-hs-dp-DR_2147.nc',
        'rose_distrib-image-uri-0':
          '/static/3.6.3/projects/dev-ma/MA/open-ocean/ENGIE-LNG/World-Csiro/WAVES/Visualization/WAV-hs%2Cdp-DR/2147_WAV-hs%2Cdp-DR_all%24%7BPER%7D__rose_distrib.png',
        'rose_distrib-image-legend-0':
          'Wave distribution rose (peak direction) at point test (coming from direction)',
        dataLegend0:
          'Wave distribution rose (peak direction) at point test (coming from direction)',
        'matlab-uri-0':
          '/static/3.6.3/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2147/WAVES/WAV-hs-dp-DR_2147.mat',
        'data-uri-0':
          '/api/projects/671/get_visu_data/?stats_id=WAV-hs%2Cdp-DR_2147',
      },
      driver: {
        method: 'rose_distrib',
        path:
          // eslint-disable-next-line no-template-curly-in-string
          '/mnt/glusterfs/projects/dev-ma/MA/open-ocean/ENGIE-LNG/World-Csiro/WAVES/Visualization/WAV-hs,dp-DR/2147_WAV-hs,dp-DR_all${PER}__rose_distrib.png',
        suffix: null,
        fileformat: '.png',
      },
      kwargs: {
        varBinsName: 'hs_bin',
        histogramName: 'jpd',
        legendTitle: 'Significant wave height [m]',
        dirBinsName: 'dp_bin',
      },
    },
    periodicity: 'all',
    hsBin: {
      values: [0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0],
      dimensions: ['hs_bin'],
      attributes: {
        standard_name: 'lower_bin',
        units: 'm',
        description: 'lower bound of the bin of hs',
        vartype: 'f8',
      },
    },
    jpd: {
      values: [
        [
          0.0,
          0.0,
          0.0,
          0.0,
          0.0,
          0.0,
          0.0,
          0.0,
          0.0,
          0.0,
          0.0,
          0.0,
          0.0,
          0.0,
          0.0,
          0.0,
        ],
        [
          0.013885770335267493,
          0.0,
          0.0,
          0.0,
          0.0,
          0.0,
          0.0,
          0.0002954419220269679,
          0.01004502534891691,
          0.029839634124723763,
          0.006795164206620263,
          0.005908838440539359,
          0.019499166853779885,
          0.03338493718904738,
          0.029839634124723763,
          0.013885770335267493,
        ],
        [
          1.8680792729765183,
          0.02422623760621137,
          0.0014772096101348397,
          0.0002954419220269679,
          0.0005908838440539358,
          0.0002954419220269679,
          0.0032498611422966473,
          0.005908838440539359,
          1.565546744820903,
          3.923173282596107,
          0.8115789598080809,
          0.5967926824944753,
          0.6458360415509519,
          1.1932899230669236,
          2.414055944882355,
          2.0946832271712026,
        ],
        [
          10.955872794526051,
          0.24580767912643733,
          0.06499722284593294,
          0.0,
          0.0002954419220269679,
          0.0,
          0.0008863257660809038,
          0.0073860480506741984,
          3.5538708800623975,
          9.04613621054373,
          1.5398432976045568,
          1.096975856486132,
          1.2423332821234,
          2.644796085985417,
          7.912525555726256,
          7.673513040806438,
        ],
        [
          9.739833843463051,
          0.27387466171899927,
          0.08154197047944316,
          0.0005908838440539358,
          0.0011817676881078717,
          0.0,
          0.0014772096101348397,
          0.004431628830404519,
          0.948664011628594,
          2.6781810231744645,
          0.4854110778903083,
          0.36368900601519755,
          0.478911355605715,
          1.1182476748720735,
          6.219347900589702,
          6.380659190016426,
        ],
        [
          2.6474550632836595,
          0.08154197047944316,
          0.0395892175516137,
          0.0002954419220269679,
          0.0,
          0.0,
          0.0008863257660809038,
          0.0011817676881078717,
          0.1152223495905175,
          0.26294331060400145,
          0.07326959666268805,
          0.05938382632742056,
          0.0847918316217398,
          0.1503799383117267,
          1.7948096763138301,
          2.703589028468784,
        ],
        [
          0.4963424290053061,
          0.01595386378945627,
          0.009749583426889943,
          0.0,
          0.0,
          0.0,
          0.0,
          0.0,
          0.011226793037024781,
          0.01152223495905175,
          0.004136186908377551,
          0.0017726515321618075,
          0.00709060612864723,
          0.006204280362566326,
          0.4800931232938229,
          0.8668265992271239,
        ],
      ],
      dimensions: ['hs_bin', 'dp_bin'],
      attributes: {
        units: '%',
        level_dependent: 'True',
        description:
          'joint probability distribution of significant wave height and wave energy peak direction',
        long_name: 'probability distribution',
        standard_name: 'probability_distribution_function',
        valid_min: '0.',
        valid_max: '100.',
        vartype: 'f8',
      },
    },
    global_attributes: {
      statistic: 'Distribution rose',
      history: '2020-03-16 05:38: generated by Open Ocean',
      location_name: 'test',
      longitude: "-18.45703 (18°27'25''W)",
      latitude: "15.42191 (15°25'18''N)",
      srs: 'EPSG:4326',
      period: '1979-01-10 00:00 - 2017-08-31 23:01',
      WAVES_dataset: 'GLOB-24M',
      depth: 2933.670347588957,
    },
    success: true,
    dpBin: {
      values: [
        348.75,
        11.25,
        33.75,
        56.25,
        78.75,
        101.25,
        123.75,
        146.25,
        168.75,
        191.25,
        213.75,
        236.25,
        258.75,
        281.25,
        303.75,
        326.25,
      ],
      dimensions: ['dp_bin'],
      attributes: {
        standard_name: 'lower_bin',
        units: 'degrees',
        description: 'lower bound of the bin of dp',
        vartype: 'f8',
      },
    },
  },
  monthly: {
    id: 'WAV-hs,dp-DR-month_2147',
    point: {
      name: 'test',
      id: '2147',
    },
    layer: 'WAVES',
    type: 'DR',
    name: 'Distribution rose',
    variables: {
      names: ['hs', 'dp'],
      variables: [
        {
          units: 'm',
          name: 'hs',
          hidden: false,
          long_name: 'significant wave height',
        },
        {
          units: 'degrees',
          name: 'dp',
          hidden: false,
          long_name: 'wave energy peak direction',
        },
      ],
    },
    visualization: {
      web: {
        'excel-uri-0':
          '/static/3.6.3/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2147/WAVES/WAV-hs-dp-DR_2147.xls',
        'netcdf-uri-0':
          '/static/3.6.3/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2147/WAVES/WAV-hs-dp-DR_2147.nc',
        'rose_distrib-image-uri-0':
          '/static/3.6.3/projects/dev-ma/MA/open-ocean/ENGIE-LNG/World-Csiro/WAVES/Visualization/WAV-hs%2Cdp-DR-month/2147_WAV-hs%2Cdp-DR-month_month%24%7BPER%7D__rose_distrib.png',
        'rose_distrib-image-legend-0':
          // eslint-disable-next-line no-template-curly-in-string
          'Monthly wave distribution rose (peak direction) at point test (coming from direction) ${PER}',
        dataLegend0:
          // eslint-disable-next-line no-template-curly-in-string
          'Monthly wave distribution rose (peak direction) at point test (coming from direction) ${PER}',
        'matlab-uri-0':
          '/static/3.6.3/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2147/WAVES/WAV-hs-dp-DR_2147.mat',
        'data-uri-0':
          '/api/projects/671/get_visu_data/?stats_id=WAV-hs%2Cdp-DR-month_2147',
      },
      driver: {
        method: 'rose_distrib',
        path:
          // eslint-disable-next-line no-template-curly-in-string
          '/mnt/glusterfs/projects/dev-ma/MA/open-ocean/ENGIE-LNG/World-Csiro/WAVES/Visualization/WAV-hs,dp-DR-month/2147_WAV-hs,dp-DR-month_month${PER}__rose_distrib.png',
        suffix: null,
        fileformat: '.png',
      },
      kwargs: {
        varBinsName: 'hs_bin',
        histogramName: 'jpd',
        legendTitle: 'Significant wave height [m]',
        dirBinsName: 'dp_bin',
      },
    },
    periodicity: 'month',
    month: {
      values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      dimensions: ['month'],
      attributes: {
        units: '',
        description: 'index of the month',
        vartype: 'i2',
      },
    },
    success: true,
    global_attributes: {
      statistic: 'Distribution rose',
      history: '2020-03-16 05:38: generated by Open Ocean',
      location_name: 'test',
      longitude: "-18.45703 (18°27'25''W)",
      latitude: "15.42191 (15°25'18''N)",
      srs: 'EPSG:4326',
      period: '1979-01-10 00:00 - 2017-08-31 23:01',
      WAVES_dataset: 'GLOB-24M',
      depth: 2933.670347588957,
    },
    hsBin: {
      values: [
        [0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0],
        [0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0],
        [0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0],
        [0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0],
        [0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0],
        [0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0],
        [0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0],
        [0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0],
        [0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0],
        [0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0],
        [0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0],
        [0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0],
      ],
      dimensions: ['month', 'hs_bin'],
      attributes: {
        units: 'm',
        standard_name: 'lower_bin',
        vartype: 'f8',
        description: 'lower bound of the bin of hs',
      },
    },
    jpd: {
      values: [
        [
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.006944444444444444,
            0.003472222222222222,
            0.03125,
            0.003472222222222222,
            0.020833333333333332,
            0.2152777777777778,
            0.13194444444444445,
            0.010416666666666666,
          ],
          [
            0.53125,
            0.052083333333333336,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.2152777777777778,
            0.8611111111111112,
            0.3958333333333333,
            0.3576388888888889,
            0.5555555555555556,
            1.5243055555555556,
            2.875,
            1.2326388888888888,
          ],
          [
            4.329861111111111,
            0.7916666666666666,
            0.125,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.4409722222222222,
            1.09375,
            0.6979166666666666,
            0.6423611111111112,
            1.1284722222222223,
            3.2291666666666665,
            16.055555555555557,
            7.638888888888889,
          ],
          [
            5.777777777777778,
            1.2638888888888888,
            0.28125,
            0.0,
            0.013888888888888888,
            0.0,
            0.0,
            0.0,
            0.1111111111111111,
            0.4722222222222222,
            0.23958333333333334,
            0.3854166666666667,
            0.5381944444444444,
            1.1319444444444444,
            15.409722222222221,
            11.354166666666666,
          ],
          [
            3.2569444444444446,
            0.4652777777777778,
            0.16319444444444445,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.059027777777777776,
            0.041666666666666664,
            0.034722222222222224,
            0.07291666666666667,
            0.11458333333333333,
            3.920138888888889,
            5.309027777777778,
          ],
          [
            1.2430555555555556,
            0.08333333333333333,
            0.05555555555555555,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.003472222222222222,
            0.003472222222222222,
            0.006944444444444444,
            0.0,
            0.0,
            1.0243055555555556,
            1.9652777777777777,
          ],
        ],
        [
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0037812901762081224,
            0.0,
            0.0,
          ],
          [
            0.43106708008772593,
            0.011343870528624367,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.370566437268396,
            0.5520683657263858,
            0.18150192845798988,
            0.2684716025107767,
            0.6163502987219239,
            0.9150722226423655,
            3.1611585873099903,
            1.2478257581486802,
          ],
          [
            5.86099977312259,
            0.37812901762081225,
            0.049156772290705586,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.4613174014973909,
            1.856613476518188,
            0.6390380397791726,
            0.650381910307797,
            1.4331089767828784,
            3.7586024351508733,
            13.423580125538834,
            6.4660062013158885,
          ],
          [
            9.630946078802086,
            0.370566437268396,
            0.08318838387657869,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.056719352643121834,
            0.465098691673599,
            0.2911593435680254,
            0.2609090221583604,
            0.4613174014973909,
            1.826363155108523,
            13.49164334871058,
            8.730999016864555,
          ],
          [
            3.0401573016713304,
            0.01890645088104061,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.05293806246691371,
            0.049156772290705586,
            0.10587612493382742,
            0.11721999546245179,
            0.15881418740074113,
            0.4045980488542691,
            5.433713983211072,
            6.333661045148605,
          ],
          [
            1.017167057399985,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0037812901762081224,
            0.007562580352416245,
            0.01512516070483249,
            2.3254934583679954,
            2.5107766770021933,
          ],
        ],
        [
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.02757289584338595,
            0.006893223960846488,
            0.020679671882539465,
            0.01723305990211622,
            0.0,
            0.0,
          ],
          [
            0.46184600537671466,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.2033501068449714,
            1.0064106982835872,
            0.437719721513752,
            0.39636037774867305,
            0.4721858413179844,
            1.244226924932791,
            2.6469980009650516,
            1.1615082374026333,
          ],
          [
            5.728269111463431,
            0.006893223960846488,
            0.013786447921692976,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.6134969325153374,
            2.8296684359274833,
            0.9753911904597781,
            1.2476735369132144,
            1.7129661542703523,
            4.4116633349417524,
            10.822361618528985,
            7.2172054870062725,
          ],
          [
            9.905562831736404,
            0.13097125525608327,
            0.01723305990211622,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.34810781002274765,
            1.3717515682084511,
            0.7548080237126904,
            0.7134486799476115,
            1.3062659405804096,
            2.626318329082512,
            12.039015647618392,
            9.123181912180327,
          ],
          [
            4.6632660095126495,
            0.01723305990211622,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.11373819535396705,
            0.2205831667470876,
            0.1102915833735438,
            0.06893223960846488,
            0.19301027090370165,
            0.36189425794444063,
            3.9429241056041913,
            5.183704418556559,
          ],
          [
            0.6720893361825325,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.010339835941269733,
            0.0,
            0.8719928310470807,
            1.5509753911904598,
          ],
        ],
        [
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.024932326542242485,
            0.0,
            0.010685282803818207,
            0.06054993588830318,
            0.0,
            0.0,
            0.0,
          ],
          [
            0.48439948710642544,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.30987320131072804,
            0.769340361874911,
            0.1745262857956974,
            0.18164980766490954,
            0.1745262857956974,
            0.2315144607493945,
            0.5022082917794558,
            0.22795269981478844,
          ],
          [
            9.032625730160992,
            0.07835874056133352,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            1.5778600940304888,
            6.446787291636985,
            1.538680723749822,
            1.0578429975780026,
            1.5137483972075794,
            3.3159994301182505,
            7.469012679868928,
            5.050577005271406,
          ],
          [
            16.405470864795554,
            0.0463028921498789,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.9794842570166691,
            2.888588117965522,
            1.0435959538395783,
            0.8868784727169112,
            1.0151018663627298,
            2.1299330388944293,
            9.246331386237356,
            8.38082347912808,
          ],
          [
            6.286508049579712,
            0.028494087476848555,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.21726741701097022,
            0.6446787291636985,
            0.15671748112266704,
            0.14247043738424278,
            0.06767345775751532,
            0.2315144607493945,
            1.9732155577717623,
            4.334663057415586,
          ],
          [
            1.2715486536543668,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.04274113121527283,
            0.08192050149593959,
            0.0,
            0.0,
            0.0,
            0.0,
            0.14603219831884884,
            1.1183929334663056,
          ],
        ],
        [
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
          [
            0.47234864156668044,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            1.1343262998207144,
            2.141083988415391,
            0.36891463246448764,
            0.15859881395669562,
            0.07585160667494138,
            0.19652461729416631,
            0.43442283822920974,
            0.24134602123844986,
          ],
          [
            13.66363260239967,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            4.051165356502551,
            8.460901944559371,
            1.7032133498827748,
            0.8998758791890774,
            1.1998345055854365,
            1.882498965659909,
            4.944145635084816,
            4.395945386843194,
          ],
          [
            24.71038477451386,
            0.034478003034064265,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            2.6858364363536062,
            5.995724727623776,
            0.9515928837401738,
            0.3654668321610812,
            0.4757964418700869,
            1.2412081092263136,
            2.4375948145083437,
            5.168252654806234,
          ],
          [
            5.275134464211833,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.6240518549165632,
            0.7412770652323818,
            0.1275686112260378,
            0.04137360364087712,
            0.06895600606812853,
            0.06206040546131568,
            0.35512343125086193,
            1.5584057371397049,
          ],
          [
            0.468900841263274,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.013791201213625707,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.01034340091021928,
            0.16204661426010206,
          ],
        ],
        [
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.003564172933670742,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
          [
            2.455715151299141,
            0.0,
            0.0,
            0.003564172933670742,
            0.003564172933670742,
            0.0,
            0.003564172933670742,
            0.010692518801012225,
            1.4613109028050042,
            5.103895641016502,
            0.6914495491321239,
            0.5168050753822575,
            0.3849306768364401,
            0.8874790604840147,
            1.20112627864704,
            0.8625298499483195,
          ],
          [
            23.559183091563604,
            0.010692518801012225,
            0.010692518801012225,
            0.0,
            0.0,
            0.0,
            0.0,
            0.024949210535695193,
            4.736785828848416,
            18.676266172434687,
            1.903268346580176,
            0.9195566168870514,
            0.7413479702035143,
            1.860498271376127,
            2.5341269558398976,
            4.123748084257048,
          ],
          [
            14.060662223331075,
            0.010692518801012225,
            0.010692518801012225,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            1.8391132337741027,
            6.176711694051395,
            0.2138503760202445,
            0.12474605267847597,
            0.18533699255087857,
            0.2744413158926471,
            0.4383932708415012,
            1.5824927825498094,
          ],
          [
            1.3757707523969063,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.09979684214278077,
            0.4633424813771964,
            0.03920590227037816,
            0.035641729336707416,
            0.017820864668353708,
            0.03920590227037816,
            0.07128345867341483,
            0.05702676693873187,
          ],
          [
            0.1532594361478419,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.03920590227037816,
          ],
        ],
        [
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
          [
            0.027623355547115085,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0034529194433893856,
            0.0,
            0.01726459721694693,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
          [
            6.305030903629018,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            4.5958357791512725,
            10.745485307827769,
            0.9909878802527538,
            0.9426470080453023,
            0.859776941403957,
            1.2223334829598425,
            1.6056075411760644,
            3.9950277960015192,
          ],
          [
            18.058768688926488,
            0.006905838886778771,
            0.0034529194433893856,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0034529194433893856,
            8.981043472255791,
            19.584959082904597,
            1.3052035496011878,
            0.8252477469700632,
            0.6595076136873727,
            0.6974897275646559,
            1.2775801940540727,
            4.651082490245503,
          ],
          [
            6.418977245260868,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            1.1429163357618868,
            3.919063568246953,
            0.06215254998100894,
            0.06215254998100894,
            0.06215254998100894,
            0.0483408722074514,
            0.0241704361037257,
            0.32112150823521285,
          ],
          [
            0.46614412485756707,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.06560546942439834,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
          [
            0.04143503332067263,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
        ],
        [
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.024212237556639347,
            0.0034588910795199067,
            0.0034588910795199067,
            0.006917782159039813,
            0.02075334647711944,
            0.02075334647711944,
            0.02075334647711944,
          ],
          [
            3.71484901940438,
            0.0034588910795199067,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            5.468506796720972,
            11.265608245996335,
            1.7536577773165927,
            1.0030784130607728,
            0.9408183736294147,
            1.1794818581162883,
            2.1721835979385014,
            2.9365985265124004,
          ],
          [
            12.932793746324931,
            0.024212237556639347,
            0.0034588910795199067,
            0.0,
            0.0,
            0.0,
            0.0034588910795199067,
            0.024212237556639347,
            12.721801390474218,
            18.781778561793093,
            2.362422607312096,
            1.3489675210127636,
            1.1691051848777285,
            1.708692193282834,
            1.9335201134516278,
            6.239839507453912,
          ],
          [
            2.8916329424786418,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.006917782159039813,
            1.9888623707239463,
            3.2790287433848717,
            0.19369790045311477,
            0.04496558403375878,
            0.08647227698799766,
            0.18678011829407495,
            0.2559579398844731,
            0.47040918681470734,
          ],
          [
            0.30784130607727167,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.006917782159039813,
            0.01037667323855972,
            0.15219120749887588,
            0.20753346477119441,
            0.0,
            0.0,
            0.0,
            0.0,
            0.017294455397599533,
            0.02075334647711944,
          ],
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.05534225727231851,
            0.024212237556639347,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
        ],
        [
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.01463860933211345,
            0.025617566331198535,
            0.0,
            0.0,
            0.0,
            0.0,
            0.018298261665141813,
            0.0,
          ],
          [
            2.192131747483989,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0036596523330283625,
            3.3851784080512353,
            7.209515096065874,
            2.012808783165599,
            1.0247026532479415,
            1.0137236962488563,
            1.8225068618481244,
            3.0118938700823423,
            3.648673376029277,
          ],
          [
            13.544373284537969,
            0.018298261665141813,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.021957913998170174,
            6.126258005489478,
            16.93321134492223,
            3.0741079597438246,
            1.9908508691674291,
            1.7236962488563587,
            2.3092406221408965,
            4.113449222323879,
            8.563586459286368,
          ],
          [
            5.478499542543458,
            0.018298261665141813,
            0.0,
            0.0,
            0.0,
            0.0,
            0.018298261665141813,
            0.04757548032936871,
            1.0832570905763952,
            3.7913998170173833,
            0.4537968892955169,
            0.3403476669716377,
            0.4281793229643184,
            0.3183897529734675,
            0.9881061299176578,
            1.789569990850869,
          ],
          [
            0.6184812442817932,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0036596523330283625,
            0.0036596523330283625,
            0.09515096065873742,
            0.32936870997255263,
            0.04025617566331199,
            0.007319304666056725,
            0.04025617566331199,
            0.05489478499542543,
            0.06953339432753888,
            0.1280878316559927,
          ],
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.021957913998170174,
            0.0292772186642269,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
        ],
        [
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.053095465647233726,
            0.16282609465151676,
            0.01769848854907791,
            0.02123818625889349,
            0.0,
            0.024777883968709072,
            0.07079395419631164,
            0.06371455877668047,
          ],
          [
            2.0636437648224843,
            0.01769848854907791,
            0.003539697709815582,
            0.0,
            0.003539697709815582,
            0.003539697709815582,
            0.03539697709815582,
            0.05663516335704931,
            0.8601465434851864,
            4.113128738805706,
            1.430037874765495,
            1.0300520335563343,
            1.0796078014937525,
            1.8760397862022584,
            3.960921737283636,
            4.038795086899579,
          ],
          [
            11.058015645463877,
            0.10265123358465188,
            0.0,
            0.0,
            0.003539697709815582,
            0.0,
            0.003539697709815582,
            0.0,
            1.7910870411666844,
            7.4581430745814306,
            2.141517114438427,
            1.5079112243814379,
            1.2495132915649003,
            2.0671834625322996,
            7.606810378393686,
            13.610137694240912,
          ],
          [
            9.45453258291742,
            0.05663516335704931,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.9026229160029734,
            1.7344518778096352,
            0.7327174259318254,
            0.5486531450214152,
            0.4035255389189763,
            0.60882800608828,
            2.4176135358040423,
            7.66698523946055,
          ],
          [
            2.045945276273406,
            0.028317581678524655,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.010619093129446745,
            0.21592156029875048,
            0.13804821068280768,
            0.1168100244239142,
            0.24777883968709072,
            0.14158790839262328,
            0.6017486106686489,
            1.6565785281936922,
          ],
          [
            0.20884216487911933,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.04601607022760256,
            0.007079395419631164,
            0.05663516335704931,
            0.028317581678524655,
            0.010619093129446745,
            0.3256521893030335,
          ],
        ],
        [
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
          [
            0.10234292189041998,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0475163565919807,
            0.09868781753719069,
            0.0,
            0.010965313059687854,
            0.12792865236302498,
            0.02193062611937571,
            0.10965313059687853,
            0.06944698271135641,
          ],
          [
            1.9701012463905845,
            0.09868781753719069,
            0.007310208706458569,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.48978398333272416,
            2.2844402207683028,
            0.8699148360685698,
            0.8516393143024233,
            1.0234292189041998,
            1.7288643590774517,
            3.7428268577067874,
            3.32248985708542,
          ],
          [
            7.7049599766073325,
            0.4020614788552213,
            0.0475163565919807,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.4276472093278263,
            4.364194597755766,
            1.3523886106948353,
            1.334113088928689,
            1.4547315325852552,
            3.6258635184034502,
            11.290617347125261,
            13.754157681201798,
          ],
          [
            7.006835045140539,
            0.38378595708907487,
            0.06579187835812712,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.13158375671625425,
            1.1111517233817025,
            0.5921269052231442,
            0.44226762674074344,
            0.4093716875616799,
            1.4693519499981724,
            5.97975072188311,
            10.555941372126174,
          ],
          [
            2.2588544902956977,
            0.02193062611937571,
            0.02193062611937571,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.07675719141781498,
            0.0475163565919807,
            0.08406740012427355,
            0.05848166965166855,
            0.20103073942761066,
            1.758105193903286,
            2.949669213056033,
          ],
          [
            0.4020614788552213,
            0.014620417412917138,
            0.007310208706458569,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0036551043532292845,
            0.010965313059687854,
            0.02193062611937571,
            0.25220220037282065,
            0.924741401367009,
          ],
        ],
        [
          [
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
          ],
          [
            0.038907753254103,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.014148273910582909,
            0.0,
            0.0919637804187889,
            0.007074136955291454,
            0.003537068477645727,
          ],
          [
            1.1990662139219015,
            0.10964912280701754,
            0.007074136955291454,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.1414827391058291,
            0.700339558573854,
            0.43152235427277874,
            0.43152235427277874,
            0.5800792303338993,
            1.5209394453876628,
            3.8058856819468025,
            2.2601867572156196,
          ],
          [
            5.705291454442558,
            1.1460101867572157,
            0.5270232031692134,
            0.0,
            0.0,
            0.0,
            0.003537068477645727,
            0.014148273910582909,
            0.27942840973401245,
            1.619977362761743,
            0.7887662705149971,
            0.7569326542161856,
            0.9620826259196378,
            2.992359932088285,
            13.918364459535937,
            10.681946802490096,
          ],
          [
            4.895302773061687,
            0.972693831352575,
            0.5199490662139219,
            0.007074136955291454,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.7498585172608941,
            0.29003961516694965,
            0.19100169779286927,
            0.3678551216751556,
            1.5916808149405772,
            12.224108658743633,
            11.682937181663837,
          ],
          [
            2.1080928126768534,
            0.38907753254103,
            0.2865025466893039,
            0.003537068477645727,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.06720430107526881,
            0.07427843803056026,
            0.07074136955291455,
            0.09550084889643463,
            0.21222410865874364,
            3.5830503678551215,
            5.100452744765138,
          ],
          [
            0.49872665534804755,
            0.0919637804187889,
            0.05305602716468591,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.0,
            0.010611205432937181,
            1.2238256932654217,
            1.8994057724957556,
          ],
        ],
      ],
      dimensions: ['month', 'hs_bin', 'dp_bin'],
      attributes: {
        standard_name: 'probability_distribution_function',
        vartype: 'f8',
        level_dependent: 'True',
        valid_min: '0.',
        valid_max: '100.',
        units: '%',
        long_name: 'probability distribution',
        description:
          'joint probability distribution of significant wave height and wave energy peak direction',
      },
    },
    dpBin: {
      values: [
        [
          348.75,
          11.25,
          33.75,
          56.25,
          78.75,
          101.25,
          123.75,
          146.25,
          168.75,
          191.25,
          213.75,
          236.25,
          258.75,
          281.25,
          303.75,
          326.25,
        ],
        [
          348.75,
          11.25,
          33.75,
          56.25,
          78.75,
          101.25,
          123.75,
          146.25,
          168.75,
          191.25,
          213.75,
          236.25,
          258.75,
          281.25,
          303.75,
          326.25,
        ],
        [
          348.75,
          11.25,
          33.75,
          56.25,
          78.75,
          101.25,
          123.75,
          146.25,
          168.75,
          191.25,
          213.75,
          236.25,
          258.75,
          281.25,
          303.75,
          326.25,
        ],
        [
          348.75,
          11.25,
          33.75,
          56.25,
          78.75,
          101.25,
          123.75,
          146.25,
          168.75,
          191.25,
          213.75,
          236.25,
          258.75,
          281.25,
          303.75,
          326.25,
        ],
        [
          348.75,
          11.25,
          33.75,
          56.25,
          78.75,
          101.25,
          123.75,
          146.25,
          168.75,
          191.25,
          213.75,
          236.25,
          258.75,
          281.25,
          303.75,
          326.25,
        ],
        [
          348.75,
          11.25,
          33.75,
          56.25,
          78.75,
          101.25,
          123.75,
          146.25,
          168.75,
          191.25,
          213.75,
          236.25,
          258.75,
          281.25,
          303.75,
          326.25,
        ],
        [
          348.75,
          11.25,
          33.75,
          56.25,
          78.75,
          101.25,
          123.75,
          146.25,
          168.75,
          191.25,
          213.75,
          236.25,
          258.75,
          281.25,
          303.75,
          326.25,
        ],
        [
          348.75,
          11.25,
          33.75,
          56.25,
          78.75,
          101.25,
          123.75,
          146.25,
          168.75,
          191.25,
          213.75,
          236.25,
          258.75,
          281.25,
          303.75,
          326.25,
        ],
        [
          348.75,
          11.25,
          33.75,
          56.25,
          78.75,
          101.25,
          123.75,
          146.25,
          168.75,
          191.25,
          213.75,
          236.25,
          258.75,
          281.25,
          303.75,
          326.25,
        ],
        [
          348.75,
          11.25,
          33.75,
          56.25,
          78.75,
          101.25,
          123.75,
          146.25,
          168.75,
          191.25,
          213.75,
          236.25,
          258.75,
          281.25,
          303.75,
          326.25,
        ],
        [
          348.75,
          11.25,
          33.75,
          56.25,
          78.75,
          101.25,
          123.75,
          146.25,
          168.75,
          191.25,
          213.75,
          236.25,
          258.75,
          281.25,
          303.75,
          326.25,
        ],
        [
          348.75,
          11.25,
          33.75,
          56.25,
          78.75,
          101.25,
          123.75,
          146.25,
          168.75,
          191.25,
          213.75,
          236.25,
          258.75,
          281.25,
          303.75,
          326.25,
        ],
      ],
      dimensions: ['month', 'dp_bin'],
      attributes: {
        units: 'degrees',
        standard_name: 'lower_bin',
        vartype: 'f8',
        description: 'lower bound of the bin of dp',
      },
    },
  },
};
