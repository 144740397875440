import React, { useState, memo } from 'react';
import { Grid } from '@material-ui/core';
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { palette } from 'common/theme';
import SelectMonthButtonGroup from 'components/common/buttons/SelectMonthButtonGroup/index';
import {
  ANNUAL_INDEX,
  CHART_SVG_ID,
  CHART_TITLE_ID,
  GRAPHS_WIDGETS,
} from 'constants/graphs';
import DownloadGraphDataFileButtonGroup from 'containers/buttons/DownloadGraphDataFileButtonGroup';
import { getTickFormatterByValuesRange } from 'helpers/graphs/common';
import { useUniqueId } from 'hooks/useUniqueId';
import { HorizontalLoader } from 'components/common/HorizontalLoader';

import CustomTooltip from './CustomTooltip';
import { useStyles } from './styles';

/**
 * MeanVerticalProfileGraph component. Renders Mean Vertical Profile graph for given data
 * @param { object } monthlyData - monthly data of graph
 * @param { object } annualData  - annual data of graph
 * @param { object } graphWidth - width of graph
 * @param { number } projectId - current project id
 * @param { Array } statsIds - array of current graphs stats ids
 * @param { string } pngFileName - file name for saving as png
 * @param { array } widgets - expected widgets list
 * @returns { JSX }
 * @note should be used in svg context
 */
const MeanVerticalProfileGraph = ({
  monthlyData,
  annualData,
  graphWidth,
  statsIds,
  projectId,
  pngFileName,
  widgets,
}) => {
  const salt = useUniqueId();
  const classes = useStyles();
  const [monthIndex, setMonthIndex] = useState(ANNUAL_INDEX);
  const graphData =
    monthIndex === ANNUAL_INDEX ? annualData : monthlyData[monthIndex - 1];
  const {
    values,
    xTicks,
    yTicks,
    xDomain,
    yDomain,
    xLabel,
    yLabel,
    invertY,
    title,
  } = graphData;

  const withMonths = widgets.includes(GRAPHS_WIDGETS.months);
  const yTickFormatter = getTickFormatterByValuesRange(
    yDomain[yDomain.length - 1] - yDomain[0]
  );

  return (
    <Grid justifyContent="center" container>
      <Grid item>
        <Typography
          id={CHART_TITLE_ID + salt}
          className={classes.title}
          variant="subtitle1"
          align="center"
        >
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Grid
          justifyContent="center"
          alignItems="center"
          container
          direction="column"
        >
          <Grid item>
            <ComposedChart
              id={CHART_SVG_ID + salt}
              layout="vertical"
              width={graphWidth}
              height={graphWidth * 0.8}
              data={values}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <XAxis
                dataKey="range"
                type="number"
                label={{ value: xLabel, position: 'bottom', offset: 0 }}
                tick={{
                  fontSize: 9,
                }}
                domain={xDomain}
                ticks={xTicks}
              />
              <YAxis
                dataKey="level"
                type="number"
                domain={yDomain}
                reversed={!invertY}
                interval={0}
                minTickGap={20}
                ticks={yTicks}
                tickFormatter={yTickFormatter}
                label={{
                  value: yLabel,
                  angle: -90,
                  position: 'center',
                  dx: -15,
                  offset: 0,
                }}
                tick={{
                  fontSize: 9,
                }}
              />
              <Area
                dataKey="range"
                stroke={palette.graph.main}
                fill={palette.graph.main}
              />
              <Line
                type="monotone"
                dataKey="value"
                stroke={palette.blue.main}
                dot={false}
              />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                payloadUniqBy="level"
                content={
                  <CustomTooltip yBaseTitle={yLabel} xBaseTitle={xLabel} />
                }
              />
            </ComposedChart>
          </Grid>
          {withMonths && (
            <Grid item>
              {monthlyData ? (
                <SelectMonthButtonGroup
                  withAnnual
                  value={monthIndex}
                  setMonth={setMonthIndex}
                />
              ) : (
                <HorizontalLoader justifyCenter opacity={0.7} />
              )}
            </Grid>
          )}
          <DownloadGraphDataFileButtonGroup
            salt={salt}
            projectId={projectId}
            statsIds={statsIds}
            monthNumber={monthIndex}
            pngFileName={pngFileName}
            currentLevel={null}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const graphDataTypes = {
  yTicks: PropTypes.arrayOf(PropTypes.number).isRequired,
  xTicks: PropTypes.arrayOf(PropTypes.number).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.number,
      range: PropTypes.array,
      value: PropTypes.number,
    })
  ).isRequired,
  xDomain: PropTypes.arrayOf(PropTypes.number).isRequired,
  yDomain: PropTypes.arrayOf(PropTypes.number).isRequired,
  yLabel: PropTypes.string.isRequired,
  xLabel: PropTypes.string.isRequired,
  invertY: PropTypes.bool.isRequired,
};

MeanVerticalProfileGraph.propTypes = {
  monthlyData: PropTypes.arrayOf(PropTypes.shape(graphDataTypes)),
  annualData: PropTypes.shape(graphDataTypes),
  graphWidth: PropTypes.number,
  projectId: PropTypes.number,
  statsIds: PropTypes.array,
  widgets: PropTypes.arrayOf(PropTypes.string),
};

MeanVerticalProfileGraph.defaultProps = {
  graphWidth: 500,
  widgets: [],
};

export default memo(MeanVerticalProfileGraph);
