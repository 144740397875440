import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    margin: 'auto',
    padding: 25,
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 20,
    '@media(min-width: 1500px)': {
      width: '60%',
    },
  },
  searchContainer: {
    width: '100%',
  },

  // TODO_R : DESIGN OPTION 2
  // container: {
  //   margin: 'auto',
  //   padding: 25,
  //   width: '80%',
  //   display: 'flex',
  //   flexDirection: 'column',
  //   '@media(min-width: 1500px)': {
  //     width: '60%',
  //   }
  // },
  // searchContainer: {
  //   width: '100%',
  // },
  // subContainer: {
  //   padding: '0 8px',
  //   display: 'flex',
  //   gap: 10,
  // },
}));
