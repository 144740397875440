import {
  STATUS_LOADING,
  STATUS_ERROR,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
  EMPTY_VALUE,
} from 'constants/common';
import createReducer from 'helpers/createReducer';
import { groupAndNormalizeArrayBy } from 'helpers/data';

import {
  REQUEST_GEOMETRIES,
  REQUEST_GEOMETRIES_SUCCESS,
  REQUEST_GEOMETRIES_ERROR,
  DELETE_GEOMETRY_SUCCESS,
  DELETE_GEOMETRY_ERROR,
  UPLOAD_GEOMETRY_SUCCESS,
  UPDATE_GEOMETRY,
  UPDATE_GEOMETRY_ERROR,
  UPDATE_GEOMETRY_SUCCESS,
  UPDATE_LAYER,
  UPDATE_LAYER_SUCCESS,
  UPDATE_LAYER_ERROR,
} from './types';

const initialState = {
  data: EMPTY_VALUE,
  ids: [],
  error: EMPTY_VALUE,
  status: STATUS_NOT_REQUESTED,
};

const setLoadingStatus = (state) => ({ ...state, status: STATUS_LOADING });

const setError = (state, { error }) => ({
  ...state,
  error,
  status: STATUS_ERROR,
});

const setGeometries = (state, { geometries }) => ({
  ...state,
  ...groupAndNormalizeArrayBy(geometries, 'project'),
  status: STATUS_SUCCESS,
});

const deleteGeometry = (state, { geometryId, projectId }) => {
  const { [projectId]: projectGeometries, ...data } = state.data;
  data[projectId] = projectGeometries.filter(({ id }) => id !== geometryId);

  return {
    ...state,
    ids: state.ids.filter((currentId) => currentId !== projectId),
    data,
  };
};

const uploadGeometry = (state, { projectId, geometry }) => {
  const { [projectId]: projectGeometries = [], ...data } = state.data;
  data[projectId] = [...projectGeometries, geometry];
  return { ...state, data };
};

const updateGeometry = (state, { projectId, geometry }) => {
  // Need to make an immutable update on geometry
  // use of map to update the targeted geometry
  const newState = {
    ...state,
    data: {
      ...state.data,
      [projectId]: [
        ...state.data[projectId].map((oldGeometry) =>
          oldGeometry.id === geometry.id ? geometry : oldGeometry
        ),
      ],
    },
  };
  return newState;
};

// geometry -> layer -> replace()
const updateLayer = (state, { projectId, geometryId, layer }) => {
  const { [projectId]: geometries = [] } = state.data;
  const geomIndex = geometries.findIndex(({ id }) => id === geometryId);
  const layerIndex = geometries[geomIndex].layers.findIndex(
    ({ id }) => id === layer.id
  );

  // Need to make an immutable update on layers
  // use of splice to add the array from start to target index
  // then append the modifyed geometry
  // and splice the rest of the array
  // The same process is needed to modify the right sub-layer
  const newState = {
    ...state,
    data: {
      ...state.data,
      [projectId]: [
        ...state.data[projectId].slice(0, geomIndex),
        {
          ...state.data[projectId][geomIndex],
          layers: [
            ...state.data[projectId][geomIndex].layers.slice(0, layerIndex),
            {
              ...layer,
            },
            ...state.data[projectId][geomIndex].layers.slice(layerIndex + 1),
          ],
        },
        ...state.data[projectId].slice(geomIndex + 1),
      ],
    },
  };
  return newState;
};

export default createReducer(initialState, {
  [REQUEST_GEOMETRIES]: setLoadingStatus,
  [REQUEST_GEOMETRIES_SUCCESS]: setGeometries,
  [REQUEST_GEOMETRIES_ERROR]: setError,
  [DELETE_GEOMETRY_SUCCESS]: deleteGeometry,
  [DELETE_GEOMETRY_ERROR]: setError,
  [UPLOAD_GEOMETRY_SUCCESS]: uploadGeometry,
  [UPDATE_GEOMETRY]: setLoadingStatus,
  [UPDATE_GEOMETRY_SUCCESS]: updateGeometry,
  [UPDATE_GEOMETRY_ERROR]: setError,
  [UPDATE_LAYER]: setLoadingStatus,
  [UPDATE_LAYER_SUCCESS]: updateLayer,
  [UPDATE_LAYER_ERROR]: setError,
});
