import React from 'react';

const BORDER_COLOR = 'rgba(0,0,0,0.3)';
const AXIS_TICK_COLOR = 'rgba(0,0,0,0.8)';

/**
 * Cross with double y values
 * draws one vertical line and 2 horizontal lines (for each y)
 * @param { number } x - pointer x
 * @param { number } y1 - first value pointer y
 * @param { number } y2 - first value pointer y
 * @param { number } offsetX - cross left offset
 * @param { number } offsetY - cross top offset
 * @param { number } width - cross width
 * @param { number } height - cross height
 * @returns { JSX }
 */
const DoubleYCross = ({ x, y1, y2, offsetX, offsetY, width, height }) => (
  <div>
    <div
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        top: offsetY + height - 10,
        left: x,
        height: 10,
        border: 'none',
        borderLeft: `2px solid ${AXIS_TICK_COLOR}`,
      }}
    />
    <div
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        top: y1,
        left: offsetX,
        width: x - offsetX,
        border: 'none',
        borderTop: `1px solid ${BORDER_COLOR}`,
      }}
    />
    <div
      style={{
        position: 'absolute',
        pointerEvents: 'none',
        top: y2,
        left: x,
        width: offsetX + width - x,
        border: 'none',
        borderTop: `1px solid ${BORDER_COLOR}`,
      }}
    />
  </div>
);
export default DoubleYCross;
