import {
  INVALID_BREAKPOINTS,
  INVALID_PERCENT_VALUE,
  INVALID_STEPS_AMOUNT,
} from 'constants/errors';
import { DESC } from 'constants/common';
import { floatRound, inRange } from 'helpers/common';
import { DEEP_BLUE_COLOR_RANGE_BREAKPOINTS } from 'constants/color';

/**
 * Fabric function, returns percent to color converter, build by custom rules
 * takes breakpoints for rgb channels
 * @param { Array<{ percent, color }> } red
 * @param { Array<{ percent, color }> } green
 * @param { Array<{ percent, color }> } blue
 */
export const getCustomPercentToColorConverterByBreakpoints = ({
  red,
  green,
  blue,
}) => {
  if (!red || !green || !blue) {
    throw Error(INVALID_BREAKPOINTS);
  }
  const converters = [
    getColorConverterByBreakpoints(red),
    getColorConverterByBreakpoints(green),
    getColorConverterByBreakpoints(blue),
  ];
  const redBreakpoints = red.slice(1).map(({ percent }) => percent);
  const greenBreakpoints = green.slice(1).map(({ percent }) => percent);
  const blueBreakpoints = blue.slice(1).map(({ percent }) => percent);

  return function converter(percent, alpha = 1) {
    const [redColor, greenColor, blueColor] = [
      redBreakpoints,
      greenBreakpoints,
      blueBreakpoints,
    ].map((breakpoints, index) => {
      const breakpoint = breakpoints.find(
        (currentBreakpoint) => percent <= currentBreakpoint
      );
      return converters[index][breakpoint](percent);
    });

    return `rgba(${redColor},${greenColor},${blueColor},${alpha})`;
  };
};

/**
 * returns converter for color
 * which is map of converter functions by percent breakpoints (upper limit)
 * @param { Array<{ color: number, percent: number }> } breakpoints
 * @returns {{
 *    [percentBreakpoint1]: converter,
 *    [percentBreakpoint2]: converter,
 *    ...
 * }}
 */
function getColorConverterByBreakpoints(breakpoints) {
  const [{ percent: firstPercent, color: firstColor }] = breakpoints;

  const { converter } = breakpoints.reduce(
    (acc, { color, percent }) => {
      if (percent === acc.lastPercent) {
        return acc;
      }
      const { lastColor, lastPercent } = acc;
      const colorRange = color - lastColor;
      const percentRange = percent - lastPercent;
      acc.converter[percent] = (value) =>
        colorRange === 0
          ? color
          : lastColor +
            Math.round(((value - lastPercent) * colorRange) / percentRange);

      acc.lastColor = color;
      acc.lastPercent = percent;
      return acc;
    },
    { converter: {}, lastColor: firstColor, lastPercent: firstPercent }
  );

  return converter;
}

/**
 * helpers to generate colors range with even steps
 * returns colors collection in hsl format
 * by default returns blue to red range
 * @param { number } steps - steps amount
 * @param { number } startHue - 0 to 360, default 240 (blue)
 * @param { number } endHue - 0 to 360, default 0 (red)
 * @param { number } saturation - 0 to 100, default 80
 * @param { number } lightness - 0 to 100, default 50
 * @param { string } direction - ascending or descending, default desc
 */
export const getColorRange = ({
  steps,
  startHue = 240,
  endHue = 0,
  saturation = 80,
  lightness = 50,
  direction = DESC,
}) => {
  if (!steps || steps < 2) {
    throw Error(INVALID_STEPS_AMOUNT);
  }
  const isDesc = direction === DESC;
  const isAsc = !isDesc;

  const hueSector = {
    [isDesc && startHue > endHue]: startHue - endHue,
    [isDesc && startHue < endHue]: 360 - (endHue - startHue),
    [isAsc && startHue > endHue]: 360 - (startHue - endHue),
    [isAsc && startHue < endHue]: endHue - startHue,
  }.true;
  const step = hueSector / (steps - 1);

  const getCurrentHue = (index) => {
    const hueOffset = index * step;
    const hueRaw = isAsc ? startHue + hueOffset : startHue - hueOffset;

    return (
      {
        [hueRaw > 360]: hueRaw - 360,
        [hueRaw < 0]: 360 + hueRaw,
      }.true || hueRaw
    );
  };

  return Array(steps)
    .fill(null)
    .map(
      (_, stepIndex) =>
        `hsl(${getCurrentHue(stepIndex)}, ${saturation}%, ${lightness}%)`
    );
};
/**
 * returns color in hsl format by given percent
 * scale is linear
 * @param { number } percent
 * @param { number } startHue - start hue vale
 * @param { number } endHue - end hue value
 * @param saturation
 * @param lightness
 * @param direction
 * @returns {string}
 * @see https://en.wikipedia.org/wiki/HSL_and_HSV and hsl color wheel for more info
 */
export const getColorByPercent = ({
  percent,
  startHue = 240,
  endHue = 0,
  saturation = 80,
  lightness = 50,
  direction = DESC,
}) => {
  if (!Number.isFinite(percent) || !inRange(percent, 0, 100)) {
    throw Error(INVALID_PERCENT_VALUE);
  }

  const isDesc = direction === DESC;
  const isAsc = !isDesc;
  const hueSector = {
    [isDesc && startHue > endHue]: startHue - endHue,
    [isDesc && startHue < endHue]: 360 - (endHue - startHue),
    [isAsc && startHue > endHue]: 360 - (startHue - endHue),
    [isAsc && startHue < endHue]: endHue - startHue,
  }.true;

  const hueValue = floatRound((hueSector * percent) / 100);
  const hue = (startHue + (isAsc ? hueValue : -hueValue)) % 360;

  return `hsl(${hue >= 0 ? hue : 360 + hue}, ${saturation}%, ${lightness}%)`;
};

/**
 * Custom RGBA percent to color converter
 * makes colors more deep and dark with rich blue range
 * @param { number } percent
 * @param { number } alpha
 * @returns { string } color in rgba mode
 */
export const getDeepColorByPercent = getCustomPercentToColorConverterByBreakpoints(
  DEEP_BLUE_COLOR_RANGE_BREAKPOINTS
);
