import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LineChart, XAxis, YAxis, CartesianGrid, Customized } from 'recharts';
import formatDate from 'date-fns/format';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import {
  getLinePathByValues,
  getTickFormatterByValuesRange,
} from 'helpers/graphs/common';
import { formatDateToUTC } from 'helpers/date';
import { findClosestIndex, inRange } from 'helpers/common';
import CustomPointer from 'components/common/graphs/CustomPointer';
import { DATE_FORMATS, EMPTY_VALUE, EOL, LEFT, RIGHT } from 'constants/common';
import BaseTooltip from 'components/common/graphs/BaseTooltip';
import { useXScalableCanvas } from 'hooks/useXScalableAxis';
import GraphParamSelect from 'components/common/graphs/GraphParamSelect';
import { palette } from 'common/theme';
import CustomLineChart from 'components/common/graphs/CustomLineChart';
import CustomLegend from 'components/common/graphs/CustomLegend';
import DoubleYCross from 'components/common/graphs/DoubleYCross';
import { getXAxisOptionsByTimestampRange } from 'helpers/graphs/timeSeries';
import CustomCross from 'components/common/graphs/CustomCross';
import {
  CHART_SVG_ID,
  CHART_TITLE_ID,
  GRAPH_EMPTY_PLACEHOLDER,
  MASKED_VALUE,
} from 'constants/graphs';
import DownloadGraphDataFileButtonGroup from 'containers/buttons/DownloadGraphDataFileButtonGroup';
import { useUniqueId } from 'hooks/useUniqueId';

import { useStyles } from './styles';

/**
 * wheel step in % of domain
 * @type {number}
 */
const WHEEL_STEP = 8;
const CANVAS_OFFSET = 20;
const MIN_DOMAIN_RANGE = 3600 * 24;
const AXIS_WIDTH = 40;
const X_CANVAS_OFFSET = AXIS_WIDTH + CANVAS_OFFSET;
const Y_CANVAS_OFFSET = CANVAS_OFFSET;
const TOOLTIP_X_OFFSET = 10;
const TOOLTIP_Y_OFFSET = -10;
const LABEL_EDGE_OFFSET = 20;
const LABEL_EDGE_OFFSET_DIVISOR = 7;

const GRAPH_MARGIN = {
  left: CANVAS_OFFSET,
  right: CANVAS_OFFSET,
  top: CANVAS_OFFSET,
  bottom: CANVAS_OFFSET,
};

const LEFT_SELECT_NAME = 'leftCtsSelect';
const RIGHT_SELECT_NAME = 'rightCtsSelect';
const LEFT_CHART_COLOR = palette.blue.deep;
const RIGHT_CHART_COLOR = palette.green.deep;

/**
 * Local chart component
 * Chart render implemented here and wrapped with React.memo to avoid expensive drawing
 * @param { boolean } same - if same left and right charts are selected
 * @param { string } salt - id addition
 * @param { number[] } xValues - x values (timestamps)
 * @param { number[] } yLeftValues
 * @param { number[] } yRightValues
 * @param { number[] } yLeftDomain
 * @param { number[] } yRightDomain
 * @param { number[] } yLeftTicks
 * @param { number[] } yRightTicks
 * @param { object } classes
 * @param { number } canvasWidth
 * @param { number } canvasHeight
 * @param { number[] } xDomain
 * @param { string } yLabel
 * @returns { JSX }
 */
const ChartComponent = React.memo(
  ({
    salt,
    same,
    xValues,
    yLeftValues,
    yRightValues,
    classes,
    canvasWidth,
    canvasHeight,
    xDomain,
    yLeftDomain,
    yRightDomain,
    yLeftLabel,
    yRightLabel,
    yLeftTicks,
    yRightTicks,
  }) => {
    const leftPath = getLinePathByValues({
      xValues,
      xDomain,
      yValues: yLeftValues,
      yDomain: yLeftDomain,
      canvasWidth,
      canvasHeight,
    });
    const rightPath = getLinePathByValues({
      xValues,
      xDomain,
      yValues: yRightValues,
      yDomain: yRightDomain,
      canvasWidth,
      canvasHeight,
    });

    const yOffset = AXIS_WIDTH + 2 * CANVAS_OFFSET;
    const xOffset = 2 * AXIS_WIDTH + 2 * CANVAS_OFFSET;
    const { dateFormat, tickOptions } = getXAxisOptionsByTimestampRange(
      xDomain[1] - xDomain[0]
    );
    const xTickFormatter = useCallback(
      (tick) => formatDate(new Date(tick), dateFormat),
      [dateFormat]
    );

    const [leftTickFormatter, rightTickFormatter] = useMemo(
      () => [
        getTickFormatterByValuesRange(yLeftDomain[1] - yLeftDomain[0]),
        getTickFormatterByValuesRange(yRightDomain[1] - yRightDomain[0]),
      ],
      [yLeftDomain, yRightDomain]
    );

    const getTicksLength = (arr) => {
      let length = 0;
      arr.forEach((value) => {
        const valueLength = value?.toString()?.length || 0;
        if (value.toString().length > length) {
          length = valueLength;
        }
      });
      return length;
    };

    const computeMargin = (arr) => {
      const maxLength = arr ? getTicksLength(arr) : 0;
      return (
        LABEL_EDGE_OFFSET +
        (maxLength * LABEL_EDGE_OFFSET) / LABEL_EDGE_OFFSET_DIVISOR
      );
    };

    return (
      <div>
        <LineChart
          id={CHART_SVG_ID + salt}
          className={classes.graphContainer}
          width={canvasWidth + xOffset}
          height={canvasHeight + yOffset}
          margin={GRAPH_MARGIN}
        >
          <XAxis
            type="number"
            interval={0}
            tickCount={10}
            tick={tickOptions}
            domain={xDomain}
            allowDataOverflow
            height={AXIS_WIDTH}
            tickFormatter={xTickFormatter}
          />
          <YAxis
            yAxisId="left"
            type="number"
            ticks={yLeftTicks}
            tick={{ fontSize: 12 }}
            scale="linear"
            interval={0}
            domain={yLeftDomain}
            width={AXIS_WIDTH}
            tickFormatter={leftTickFormatter}
            label={{
              value: yLeftLabel,
              angle: -90,
              position: 'center',
              dx: -computeMargin(yLeftTicks),
            }}
          />
          {!same && (
            <YAxis
              yAxisId="right"
              type="number"
              orientation="right"
              ticks={yRightTicks}
              tick={{ fontSize: 12 }}
              scale="linear"
              interval={0}
              domain={yRightDomain}
              width={AXIS_WIDTH}
              tickFormatter={rightTickFormatter}
              label={{
                value: yRightLabel,
                angle: -90,
                position: 'center',
                dx: computeMargin(yRightTicks),
              }}
            />
          )}
          <CartesianGrid strokeDasharray="3 3" />
          <Customized
            key={1}
            component={CustomLineChart}
            path={leftPath}
            color={LEFT_CHART_COLOR}
          />
          {!same && (
            <Customized
              key={2}
              component={CustomLineChart}
              path={rightPath}
              color={RIGHT_CHART_COLOR}
            />
          )}
        </LineChart>
      </div>
    );
  }
);

/**
 * Concomitant Time series graph. Displays 2 selected timeSeries at the same time
 * Chart canvas can be zoomed by wheel and dragged by mouse
 * @param { Object } annualData
 * @param { number } canvasWidth
 * @param { number } canvasHeight
 * @param { number } projectId
 * @param { Array } statsIds
 * @param { string } pngFileName - file name for saving as png
 * @returns { JSX }
 */
const ConcomitantTimeSeriesGraph = ({
  annualData,
  canvasWidth,
  canvasHeight,
  projectId,
  statsIds,
  pngFileName,
}) => {
  const salt = useUniqueId();
  const classes = useStyles({ canvasOffset: CANVAS_OFFSET });
  const { t } = useTranslation();

  const [pointer, setPointer] = useState(EMPTY_VALUE);
  const [selectValues, setSelectValues] = useState({
    [LEFT_SELECT_NAME]: 0,
    [RIGHT_SELECT_NAME]: 1,
  });

  const {
    title,
    xValues,
    yValues,
    yDomains,
    yLabels,
    yTicks,
    yUnits,
    xMin,
    xMax,
    selectOptions,
    tooltipLabels,
  } = annualData;

  const { xDomain, xDragPosition, wrapperRef } = useXScalableCanvas({
    canvasWidth,
    wheelStep: WHEEL_STEP,
    xCanvasOffset: X_CANVAS_OFFSET,
    initialDomain: [xMin, xMax],
    minDomainRange: MIN_DOMAIN_RANGE,
  });

  const {
    [LEFT_SELECT_NAME]: leftSelectValue,
    [RIGHT_SELECT_NAME]: rightSelectValue,
  } = selectValues;

  const handleChangeSelect = useCallback(
    ({ target }) => {
      const { name: selectName } = target;
      setSelectValues((prevValues) => ({
        ...prevValues,
        [selectName]: +target.value,
      }));
    },
    [setSelectValues]
  );

  const onMouseMove = useCallback(
    (event) => {
      if (xDragPosition.current !== null) {
        return;
      }
      const { left: chartX } = wrapperRef.current.getBoundingClientRect();
      const mouseCanvasX = event.clientX - (X_CANVAS_OFFSET + chartX);
      if (!inRange(mouseCanvasX, 0, canvasWidth)) {
        return setPointer(EMPTY_VALUE);
      }

      const xScaleDivision = canvasWidth / (xDomain[1] - xDomain[0]);
      const yLeftDomain = yDomains[leftSelectValue];
      const yRightDomain = yDomains[rightSelectValue];
      const yScaleDivisions = [
        canvasHeight / (yLeftDomain[1] - yLeftDomain[0]),
        canvasHeight / (yRightDomain[1] - yRightDomain[0]),
      ];

      const xCalculatedValue = xDomain[0] + mouseCanvasX / xScaleDivision;
      const closestIndex = findClosestIndex({
        array: xValues,
        needle: xCalculatedValue,
        isSorted: true,
      });
      const yLeftValue = yValues[leftSelectValue][closestIndex];
      const yRightValue = yValues[rightSelectValue][closestIndex];

      const xValue = xValues[closestIndex];
      const canvasX = xScaleDivision * (xValue - xDomain[0]);
      const canvasYCoordinates = [
        canvasHeight - yScaleDivisions[0] * (yLeftValue - yLeftDomain[0]),
        canvasHeight - yScaleDivisions[1] * (yRightValue - yRightDomain[0]),
      ];

      const yCoordinates = canvasYCoordinates.map((y) => y + Y_CANVAS_OFFSET);
      const fromWrapperX = X_CANVAS_OFFSET + canvasX;

      setPointer({
        x: fromWrapperX,
        xValue,
        yValues: [yLeftValue, yRightValue],
        yCoordinates,
      });
    },
    [
      xDomain,
      yDomains,
      wrapperRef,
      canvasWidth,
      canvasHeight,
      xValues,
      yValues,
      setPointer,
      xDragPosition,
      leftSelectValue,
      rightSelectValue,
    ]
  );

  useEffect(() => {
    const wrapper = wrapperRef.current;
    wrapper.addEventListener('mousemove', onMouseMove);
    return () => {
      wrapper.removeEventListener('mousemove', onMouseMove);
    };
  }, [wrapperRef, onMouseMove]);

  const canvasCenterX = X_CANVAS_OFFSET + 0.5 * canvasWidth;

  const yLeftDomain = yDomains[leftSelectValue];
  const yRightDomain = yDomains[rightSelectValue];
  const [pointerY1, pointerY2] = pointer.yCoordinates || [];
  const [yLeftValue, yRightValue] = pointer.yValues || [];
  const sameSelected = leftSelectValue === rightSelectValue;

  return (
    <Grid justifyContent="center" container>
      <Grid item>
        <Typography
          id={CHART_TITLE_ID + salt}
          className={classes.title}
          variant="h6"
          align="center"
        >
          {title}
        </Typography>
        <Grid justifyContent="center" container>
          <Grid className={classes.paramsContainer} item>
            <Typography gutterBottom variant="subtitle2" align="center">
              {t('graphs.parametersTitle')}
            </Typography>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <Grid item>
                <GraphParamSelect
                  label="Left:"
                  name={LEFT_SELECT_NAME}
                  variant="outlined"
                  value={leftSelectValue}
                  values={selectOptions}
                  onChange={handleChangeSelect}
                />
              </Grid>
              <Grid item>
                <GraphParamSelect
                  label="Right:"
                  name={RIGHT_SELECT_NAME}
                  variant="outlined"
                  value={rightSelectValue}
                  values={selectOptions}
                  onChange={handleChangeSelect}
                />
              </Grid>
            </Grid>
            <div ref={wrapperRef} className={classes.wrapper}>
              <ChartComponent
                salt={salt}
                xValues={xValues}
                same={sameSelected}
                yLeftValues={yValues[leftSelectValue]}
                yRightValues={yValues[rightSelectValue]}
                classes={classes}
                canvasWidth={canvasWidth}
                canvasHeight={canvasHeight}
                xDomain={xDomain}
                yLeftDomain={yLeftDomain}
                yRightDomain={yRightDomain}
                yLeftTicks={yTicks[leftSelectValue]}
                yRightTicks={yTicks[rightSelectValue]}
                yLeftLabel={yLabels[leftSelectValue]}
                yRightLabel={yLabels[rightSelectValue]}
              />
              {!!Object.keys(pointer).length && (
                <>
                  <BaseTooltip
                    y={0}
                    x={pointer.x}
                    xPosition={pointer.x > canvasCenterX ? LEFT : RIGHT}
                    offsetX={TOOLTIP_X_OFFSET}
                    offsetY={TOOLTIP_Y_OFFSET}
                  >
                    {formatDateToUTC(
                      new Date(pointer.xValue),
                      DATE_FORMATS.ymdhmFormat
                    )}
                    {EOL}
                    {tooltipLabels[leftSelectValue]}:&nbsp;
                    <strong>
                      {yLeftValue !== GRAPH_EMPTY_PLACEHOLDER
                        ? `${yLeftValue.toFixed(2)} ${yUnits[leftSelectValue]}`
                        : MASKED_VALUE}
                    </strong>
                    {EOL}
                    {tooltipLabels[rightSelectValue]}:&nbsp;
                    <strong>
                      {yRightValue !== GRAPH_EMPTY_PLACEHOLDER
                        ? `${yRightValue.toFixed(2)} ${
                            yUnits[rightSelectValue]
                          }`
                        : MASKED_VALUE}
                    </strong>
                  </BaseTooltip>
                  <CustomPointer x={pointer.x} y={pointerY1} />
                  {!sameSelected && (
                    <CustomPointer x={pointer.x} y={pointerY2} />
                  )}
                  {!sameSelected ? (
                    <DoubleYCross
                      x={pointer.x}
                      y1={pointerY1}
                      y2={pointerY2}
                      height={canvasHeight}
                      width={canvasWidth}
                      offsetX={X_CANVAS_OFFSET}
                      offsetY={Y_CANVAS_OFFSET}
                    />
                  ) : (
                    <CustomCross
                      x={pointer.x}
                      y={pointerY1}
                      height={canvasHeight}
                      width={canvasWidth}
                      offsetX={X_CANVAS_OFFSET}
                      offsetY={Y_CANVAS_OFFSET}
                    />
                  )}
                </>
              )}
            </div>
            <Grid justifyContent="center" container>
              <Grid item>
                <CustomLegend
                  width="auto"
                  legends={[
                    yLabels[leftSelectValue],
                    ...(sameSelected ? [] : [yLabels[rightSelectValue]]),
                  ]}
                  colors={[
                    LEFT_CHART_COLOR,
                    ...(sameSelected ? [] : [RIGHT_CHART_COLOR]),
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DownloadGraphDataFileButtonGroup
          salt={salt}
          statsIds={statsIds}
          projectId={projectId}
          pngFileName={pngFileName}
          currentLevel={null}
        />
      </Grid>
    </Grid>
  );
};

ConcomitantTimeSeriesGraph.propTypes = {
  canvasWidth: PropTypes.number,
  canvasHeight: PropTypes.number,
  annualData: PropTypes.shape({
    title: PropTypes.string,
    xValues: PropTypes.arrayOf(PropTypes.number),
    yValues: PropTypes.arrayOf(PropTypes.array),
    yDomains: PropTypes.arrayOf(PropTypes.array),
    yTicks: PropTypes.arrayOf(PropTypes.array),
    xMin: PropTypes.number,
    xMax: PropTypes.number,
    selectOptions: PropTypes.arrayOf(PropTypes.string),
    tooltipLabels: PropTypes.arrayOf(PropTypes.string),
  }),
  projectId: PropTypes.number,
  statsIds: PropTypes.array,
  pngFileName: PropTypes.string,
};

ConcomitantTimeSeriesGraph.defaultProps = {
  canvasWidth: 550,
  canvasHeight: 400,
};

export default React.memo(ConcomitantTimeSeriesGraph);
