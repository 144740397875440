import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useMapInstance, useMapEvents } from 'hooks/leaflet';
import {
  BUOYS_GROUP_NAME,
  CURRENT_BUOY_LEGEND,
  LEGENDS_BY_GROUP_NAME,
  LEGENDS_PARAMS,
  POINT_COMPUTED_LEGEND,
  POINT_NOT_COMPUTED_LEGEND,
  POINTS_GROUP_NAME,
  WAVE_BUOY_LEGEND,
  WIND_BUOY_LEGEND,
} from 'constants/map';
import MapCustomControl from 'components/leaflet/MapCustomControl';

import { useStyles } from './styles';

/**
 * returns legends by group name
 * @param {string} groupName
 * @returns {Object|Array|undefined}
 */
const getLegendsByGroupName = (groupName) => {
  if (groupName === POINTS_GROUP_NAME) {
    return [POINT_COMPUTED_LEGEND, POINT_NOT_COMPUTED_LEGEND];
  }

  if (groupName === BUOYS_GROUP_NAME) {
    return [WIND_BUOY_LEGEND, CURRENT_BUOY_LEGEND, WAVE_BUOY_LEGEND];
  }

  const legend = LEGENDS_BY_GROUP_NAME[groupName];

  return legend && [legend];
};

/**
 * Convert legend strings to legends objects.
 * @param {Array} defaultGroups
 * @return {Set<Object>}
 */
const getLegendsCollectionByLegendsName = (defaultGroups) =>
  new Set(
    defaultGroups.reduce((acc, name) => {
      acc.push(...getLegendsByGroupName(name));
      return acc;
    }, [])
  );

/**
 * MapLegend component. Shows legends for points and for controls groups
 * computed and not computed point legends are displayed always
 * when change overlaying controls groups, adds/removes related legend
 * @param {array} defaultGroups - controls groups names to be displayed by default
 * @returns {jsx}
 */
const MapLegend = ({ defaultGroups }) => {
  const classes = useStyles();
  const mapInstance = useMapInstance();

  const [legends, setLegends] = useState(() =>
    getLegendsCollectionByLegendsName(defaultGroups)
  );

  const onGroupAdd = useCallback(
    (featureGroup) => {
      const legendsByFeatureName = getLegendsByGroupName(featureGroup.name);

      if (!legendsByFeatureName) {
        return;
      }

      setLegends((prevLegends) => {
        const newLegends = new Set(prevLegends);
        legendsByFeatureName.forEach((legend) => newLegends.add(legend));
        return newLegends;
      });
    },
    [setLegends]
  );

  const onGroupRemove = useCallback(
    (featureGroup) => {
      const legendsByFeatureName = getLegendsByGroupName(featureGroup.name);

      if (!legendsByFeatureName) {
        return;
      }

      setLegends((prevLegends) => {
        const newLegends = new Set(prevLegends);
        legendsByFeatureName.forEach((legend) => newLegends.delete(legend));
        return newLegends;
      });
    },
    [setLegends]
  );

  useMapEvents(
    {
      element: mapInstance,
      events: {
        overlayadd: onGroupAdd,
        overlayremove: onGroupRemove,
      },
    },
    [onGroupAdd, onGroupRemove]
  );

  return (
    <MapCustomControl buttonText="Show Legends">
      {[...legends].map((legend) => {
        const { icon, label } = LEGENDS_PARAMS[legend];
        return (
          <Grid key={legend} className={classes.legend} container wrap="nowrap">
            <Grid item>
              <img className={classes.legendIcon} src={icon} alt={label} />
            </Grid>
            <Grid item container alignItems="center">
              <Typography className={classes.legendLabel} variant="body2">
                {label}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </MapCustomControl>
  );
};

MapLegend.propTypes = {
  defaultGroups: PropTypes.array,
};

MapLegend.defaultProps = {
  defaultGroups: [],
};

export default memo(MapLegend);
