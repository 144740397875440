import {
  STATUS_LOADING,
  STATUS_ERROR,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
  EMPTY_VALUE,
} from 'constants/common';
import createReducer from 'helpers/createReducer';

import {
  INIT_KEYCLOAK,
  SET_KEYCLOAK_INSTANCE,
  AUTH_KEYCLOAK_SUCCESS,
  AUTH_KEYCLOAK_FAILED,
} from './types';

const initialState = {
  // keycloak instance
  instance: EMPTY_VALUE,
  status: STATUS_NOT_REQUESTED,
};

export default createReducer(initialState, {
  [INIT_KEYCLOAK]: (state) => ({ ...state, status: STATUS_LOADING }),
  [AUTH_KEYCLOAK_SUCCESS]: (state) => ({ ...state, status: STATUS_SUCCESS }),
  [AUTH_KEYCLOAK_FAILED]: (state) => ({ ...state, status: STATUS_ERROR }),
  [SET_KEYCLOAK_INSTANCE]: (state, { instance }) => ({ ...state, instance }),
});
