import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Typography, TextField } from '@material-ui/core';

import CoordinateField from 'components/forms/fields/CoordinateField';
import { useForm } from 'hooks/useForm';
import { Validator } from 'helpers/Validator';
import { isNumber, required, uniqName, min, max } from 'validators';
import { LOCATIONS_DIGIT } from 'constants/projects';
import { getPolygonPositionsFromGeometry } from 'helpers/map';

import { useStyles } from './styles';

/**
 * EditPointForm component
 * Displays add a point form
 * @param {String} name
 * @param {Boolean} allowRemove
 * @param {Boolean} allowRename
 * @param {Boolean} disabled
 * @param {Number} lat
 * @param {Number} lng
 * @param {Number} digits
 * @param {Object} zone
 * @param {Array<String>} existingNames
 * @param {Function} onCancel -  handler for close modal button
 * @param {Function} onSuccess -  handler for adding a point
 * @param {Function} onRemove -  handler for adding a point
 */
const EditPointForm = ({
  name,
  lat,
  lng,
  zone,
  allowRename,
  allowRemove,
  disabled,
  existingNames,
  digits,
  onCancel,
  onSuccess,
  onRemove,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [minCoordinate, maxCoordinate] = useMemo(
    () => getPolygonPositionsFromGeometry(zone.geom),
    [zone.geom]
  );

  const initialValues = useMemo(
    () => ({
      name,
      lat: Number(lat.toFixed(digits)),
      lng: Number(lng.toFixed(digits)),
    }),
    [name, lat, lng, digits]
  );

  const validationRules = useMemo(() => {
    const getLatitudeErrorMessage = (latitude) =>
      t('points.errors.latitudeOutsideZone', {
        min: minCoordinate.lat,
        max: maxCoordinate.lat,
        latitude,
      });
    const getLongitudeErrorMessage = (longitude) =>
      t('points.errors.longitudeOutsideZone', {
        min: minCoordinate.lng,
        max: maxCoordinate.lng,
        longitude,
      });

    return {
      lat: new Validator([
        required(),
        isNumber(),
        min(minCoordinate.lat, getLatitudeErrorMessage),
        max(maxCoordinate.lat, getLatitudeErrorMessage),
      ]),
      lng: new Validator([
        required(),
        isNumber(),
        min(minCoordinate.lng, getLongitudeErrorMessage),
        max(maxCoordinate.lng, getLongitudeErrorMessage),
      ]),
      name: new Validator([
        uniqName(existingNames, t('points.errors.uniqPointName')),
      ]),
    };
  }, [existingNames, minCoordinate, maxCoordinate, t]);

  const { handleSubmit, handleChange, values, errors } = useForm({
    initialValues,
    validationRules,
    onSuccess,
  });

  return (
    <form onSubmit={handleSubmit}>
      {allowRename && (
        <div className={classes.row}>
          <Typography variant="body1" className={classes.fieldName}>
            {t('points.labels.name')}
          </Typography>
          <div className={classes.fullWidth}>
            <TextField
              error={!!errors.name}
              helperText={errors.name}
              disabled={disabled}
              fullWidth
              name="name"
              size="medium"
              defaultValue={values.name}
              placeholder={t('points.labels.namePlaceholder')}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
      <CoordinateField
        value={values}
        disabled={disabled}
        errors={errors}
        onChange={handleChange}
        latMin={minCoordinate.lat}
        lngMin={minCoordinate.lng}
        latMax={maxCoordinate.lat}
        lngMax={maxCoordinate.lng}
      />
      <div className={classes.modalButtonRow}>
        {allowRemove && (
          <Button
            className={classes.removeButton}
            type="button"
            variant="contained"
            onClick={onRemove}
          >
            {t('buttons.remove')}
          </Button>
        )}
        <Button className={classes.cancelButton} onClick={onCancel}>
          {t('buttons.cancel')}
        </Button>
        {!disabled && (
          <Button className={classes.saveButton} type="submit" data-submit>
            {t('buttons.save')}
          </Button>
        )}
      </div>
    </form>
  );
};

EditPointForm.propTypes = {
  name: PropTypes.string,
  allowRename: PropTypes.bool,
  allowRemove: PropTypes.bool,
  disabled: PropTypes.bool,
  lat: PropTypes.number,
  lng: PropTypes.number,
  digits: PropTypes.number,
  onRemove: PropTypes.func,
  existingNames: PropTypes.arrayOf(PropTypes.string),
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  zone: PropTypes.shape({
    geom: PropTypes.string,
  }).isRequired,
};

EditPointForm.defaultProps = {
  name: '',
  allowRemove: false,
  allowRename: false,
  existingNames: [],
  disabled: false,
  digits: LOCATIONS_DIGIT,
  lat: null,
  lng: null,
};

export default React.memo(EditPointForm);
