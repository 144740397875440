import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import { useMapInstance, useMapEvents } from 'hooks/leaflet';
import MapCustomControl from 'components/leaflet/MapCustomControl';
import Template from 'components/common/Template';

import { useStyles } from './styles';

/**
 * Render color bar scale and support text
 * @param {String} legend
 * @param {String} source
 * @param {Array} colorbar
 * @return {jsx}
 */
const MapColorBar = ({ legend, source, colorbar }) => {
  const classes = useStyles();

  const colors = useMemo(() => colorbar.slice().reverse(), [colorbar]);

  return (
    <>
      <Typography variant="subtitle2" className={classes.colorbarLegend}>
        <b>{legend}</b>
      </Typography>
      <table className={classes.colorbarTable}>
        <tbody>
          {colors.map(({ color, label }) => (
            <tr key={color}>
              <td
                className={classes.colorbarItem}
                style={{ backgroundColor: color }}
              />
              <td className={classes.colorbarLabel}>{label}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Grid container wrap="nowrap">
        Source:
        <Template template={source} className={classes.colorbarSource} />
      </Grid>
    </>
  );
};

MapColorBar.propTypes = {
  legend: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  colorbar: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
};

/**
 * Render color bar control with color bar and information.
 * @return {jsx}
 */
const MapColorBarControl = () => {
  const [colorbarParams, setColorbarParams] = useState(null);
  const mapInstance = useMapInstance();

  const onLayerAdd = useCallback(
    ({ layer: { _leaflet_id: overlayId, options } }) => {
      const { colorbar, legend, source } = options;
      if (colorbar) {
        setColorbarParams({ overlayId, colorbar, legend, source });
      }
    },
    [setColorbarParams]
  );

  const onLayerRemove = useCallback(
    ({ layer: { _leaflet_id: overlayId } }) => {
      setColorbarParams((params) =>
        params && overlayId === params.overlayId ? null : params
      );
    },
    [setColorbarParams]
  );

  useMapEvents(
    {
      element: mapInstance,
      events: {
        customlayeradd: onLayerAdd,
        customlayerremove: onLayerRemove,
      },
    },
    [onLayerAdd, onLayerRemove]
  );

  return (
    <MapCustomControl buttonText="Show Colorbar" hidden={!colorbarParams}>
      {colorbarParams && <MapColorBar {...colorbarParams} />}
    </MapCustomControl>
  );
};

export default MapColorBarControl;
