import React from 'react';
import PropsTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { Trash } from 'phosphor-react';

import EditableTextField from 'components/common/MarineContractors/EditableTextField/index';
import { conditions } from 'constants/marineContractors';

import { useStyles } from '../styles';

/**
 * Constraint component - display main constraint informations (variable name, condition, limit and unit)
 * @param { object } constraint
 * @param { Array } variables - array of variable object
 * @param { Array } units - array of unit object
 * @param { Boolean } isSelected
 * @param { object } forceFocus - object that holds for each field a bool to force focus or not
 * @param { function } handleOnChange
 * @param { function } handleDelete
 * @param { function } handleSelect
 * @param { function } handleHasValid
 * @returns { JSX }
 */
const ConstraintComponent = React.memo(
  ({
    constraint,
    variables,
    units,
    isSelected,
    forceFocus,
    handleOnChange,
    handleDelete,
    handleSelect,
    handleHasValid,
  }) => {
    const classes = useStyles();

    return (
      <div
        aria-hidden="true"
        className={classNames(
          isSelected ? classes.gridItemSelected : classes.gridItem,
          classes.mainContainer
        )}
        onClick={() => handleSelect(constraint.id)}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            direction="row"
            spacing={3}
            xs={11}
          >
            <Grid item xs={5}>
              <EditableTextField
                object={constraint}
                fieldName="varName"
                isSelect
                forceFocus={forceFocus.varName}
                canEdit={isSelected}
                items={variables}
                itemKey="longName"
                handleChange={handleOnChange}
                handleHasValid={handleHasValid}
              />
            </Grid>
            <Grid item xs={3}>
              <EditableTextField
                object={constraint}
                fieldName="condition"
                isSelect
                forceFocus={forceFocus.condition}
                canEdit={isSelected}
                items={conditions}
                itemKey="symbol"
                handleChange={handleOnChange}
                handleHasValid={handleHasValid}
              />
            </Grid>
            <Grid item xs={2}>
              <EditableTextField
                object={constraint}
                fieldName="limit"
                isNumber
                forceFocus={forceFocus.limit}
                forceTitle
                canEdit={isSelected}
                handleChange={handleOnChange}
                handleHasValid={handleHasValid}
              />
            </Grid>
            <Grid item xs={2}>
              <EditableTextField
                object={constraint}
                fieldName="unit"
                isSelect
                forceFocus={forceFocus.unit}
                canEdit={isSelected}
                items={units}
                handleChange={handleOnChange}
                handleHasValid={handleHasValid}
              />
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <IconButton size="small" onClick={() => handleDelete()}>
              <Trash size={20} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );
  }
);

ConstraintComponent.propTypes = {
  constraint: PropsTypes.object.isRequired,
  variables: PropsTypes.array.isRequired,
  units: PropsTypes.array.isRequired,
  isSelected: PropsTypes.bool,
  forceFocus: PropsTypes.object.isRequired,
  handleOnChange: PropsTypes.func.isRequired,
  handleDelete: PropsTypes.func.isRequired,
  handleSelect: PropsTypes.func.isRequired,
  handleHasValid: PropsTypes.func.isRequired,
};

ConstraintComponent.defaultProps = {
  isSelected: false,
};

export default React.memo(ConstraintComponent);
