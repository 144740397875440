import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { closeModal } from 'ducks/modals/actions';
import { removeUser } from 'ducks/user/actions';
import RemoveOtherProfileModalComponent from 'components/modals/RemoveOtherProfileModal';

/**
 * Modal for removing other profile
 */
const RemoveOtherProfileModal = ({ id, user }) => {
  const dispatch = useDispatch();

  const close = useCallback(() => {
    dispatch(closeModal({ id }));
  }, [dispatch, id]);

  const removeHandler = useCallback(() => {
    dispatch(removeUser(user.id));
    dispatch(closeModal({ id }));
  }, [dispatch, id, user]);

  return (
    <RemoveOtherProfileModalComponent
      user={user}
      closeModal={close}
      removeProfile={removeHandler}
    />
  );
};

RemoveOtherProfileModal.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

export default memo(RemoveOtherProfileModal);
