import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { useStyles } from './styles';

/**
 * BaseOfferCard - base information about offer card
 * @param {String} name
 * @param {String} description
 * @param {Function} onClick
 * @param children
 * @returns {jsx}
 */
const BaseOfferCard = ({ name, description, children, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box component="article" className={classes.offer} onClick={onClick}>
      <Typography variant="h3" className={classes.name}>
        {name}
      </Typography>
      <Typography className={classes.description}>{description}</Typography>
      {children}
      <Box className={classes.footer}>
        <Button className={classes.createButton}>
          {t('offers.buttons.create', { name })}
        </Button>
      </Box>
    </Box>
  );
};

BaseOfferCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default memo(BaseOfferCard);
