import React from 'react';
import PropTypes from 'prop-types';

import { EMPTY_FUNCTION } from 'constants/common';
import Swatch from 'components/colors/Swatch';

import { useStyles } from './styles';

/**
 * CheckerSwatch component
 * Simple selector button based on the Swatch
 * @param {string} color - the color used for the checker
 * @param {string} className - styling classes
 * @param {Function} onClick - callback when the check-button is clicked
 * @param {Function} onHover - callback whne the check-button is hovered
 */
const CheckerSwatch = ({
  color,
  onClick,
  onHover,
  hover,
  select,
  circleSize,
  ...props
}) => {
  const classes = useStyles({
    color,
    circleSize,
  });

  return (
    <div className={classes.checker}>
      <Swatch
        className={classes.swatch}
        color={color}
        checked={select}
        onClick={onClick}
        onHover={onHover}
        {...props}
      />
    </div>
  );
};

Swatch.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
};

CheckerSwatch.defaultProps = {
  onClick: EMPTY_FUNCTION,
  circleSize: 20,
};

export default CheckerSwatch;
