import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import React, { memo } from 'react';

import { useStyles } from '../styles';

/**
 * LocationTitles component for use in PlanningForm
 * Displays titles in location section
 */
const LocationTitles = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Typography align="center">
        <strong>{t('downtime.planningSettings.locationReferring')}</strong>
      </Typography>
      <div className={classes.locationLabel}>
        <Typography>
          <small>{t('downtime.planningSettings.fromPlanning')}</small>
        </Typography>
        <Typography>
          <small>{t('downtime.planningSettings.fromProject')}</small>
        </Typography>
      </div>
    </>
  );
};

export default memo(LocationTitles);
