import { useEffect } from 'react';

/**
 * Add event to map elements
 * @param {Object} element
 * @param {Object} events
 * @param {Array} [deps]
 */
export const useMapEvents = ({ element, events = {} }, deps = []) => {
  useEffect(() => {
    if (!element) {
      return;
    }

    element.on(events);
    return () => {
      element.off(events);
    };
    // eslint-disable-next-line
  }, [element, ...deps]);
};
