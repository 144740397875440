import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  checker: (props) => ({
    width: props.circleSize,
    height: props.circleSize,
    transform: 'scale(1)',
    transition: '100ms transform ease',
  }),
  swatch: (props) => ({
    borderRadius: '50%',
    background: 'transparent',
    boxShadow: `inset 0 0 0 ${props.circleSize / 2 + 1}px ${props.color}`,
    transition: '100ms box-shadow ease',
    '&[aria-checked=true]': {
      boxShadow: `inset 0 0 0 3px ${props.color}`,
    },
  }),
});
