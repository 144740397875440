import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import PlanningSettingsModalComponent from 'components/modals/PlanningSettingsModal';
import { useModal } from 'hooks/useModal';
import { selectCurrentUser, selectUserEmail } from 'ducks/user/selectors';
import { createPlanningWaitingRequest } from 'ducks/app/actions';

/**
 * PlanningSettingsModal container
 * Displays planning settings modal
 * @param { string } id - modal id
 * @param { string } fileName - loaded file name
 * @param { Object } points - current project points
 * @param { string } tasksCoords - coordinates of tasks table in planning
 * @param { string } envCoords - coordinates of environments table in planning
 * @param { string } percentiles - series of integers between 1 and 99, separated by commas
 * @param { Object } locationAliases - correspondence between planning location and project location
 * @param { Object } formats - output downtime formats: array of Format object (containing 'value': str and 'seleceted': bool)
 * @param { string } projectId - current project id
 * @param { Object } file - planning file
 */
const PlanningSettingsModal = ({
  id,
  fileName,
  points,
  tasksCoords,
  envCoords,
  percentiles,
  locationAliases,
  formats,
  projectId,
  file,
}) => {
  const dispatch = useDispatch();
  const { onClose } = useModal(id);
  const userEmail = useSelector(selectUserEmail);
  const user = useSelector(selectCurrentUser);

  const onSuccess = useCallback(
    (values) => {
      dispatch(
        createPlanningWaitingRequest({
          projectId,
          user,
          planningData: values,
          file,
        })
      );
    },
    [dispatch, projectId, user, file]
  );

  return (
    <PlanningSettingsModalComponent
      onClose={onClose}
      fileName={fileName}
      onSuccess={onSuccess}
      points={points}
      tasksCoords={tasksCoords}
      envCoords={envCoords}
      percentiles={percentiles}
      userEmail={userEmail}
      locationAliases={locationAliases}
      formats={formats}
    />
  );
};

PlanningSettingsModal.propTypes = {
  fileName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  points: PropTypes.object.isRequired,
  tasksCoords: PropTypes.string.isRequired,
  envCoords: PropTypes.string.isRequired,
  percentiles: PropTypes.string.isRequired,
  locationAliases: PropTypes.array.isRequired,
  projectId: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
};

export default memo(PlanningSettingsModal);
