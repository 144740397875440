import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet-providers';

import { withGraticule } from 'decorators/leaflet/withGraticule';
import { useMapInstance } from 'hooks/leaflet/useMapInstance';
import {
  GRATICULE_DEFAULT_INTERVAL,
  GRATICULE_DEFAULT_ZOOM_INTERVALS,
} from 'constants/map';

const Leaflet = withGraticule(L);

/**
 * Map Graticule. Adds graticule to map. Uses map context
 * @param {Number} interval - default interval between grid lines
 * @param {String} color - default lines color
 * @param {Array} zoomIntervals - zoom intervals settings (different lines interval on different zoom values)
 * @param {Object} props - rest props
 * @see withGraticule
 */
const MapGraticule = ({ interval, color, zoomIntervals, ...props }) => {
  const mapInstance = useMapInstance();
  useEffect(() => {
    if (!mapInstance) {
      return;
    }
    Leaflet.simpleGraticule({ interval, color, zoomIntervals, ...props }).addTo(
      mapInstance
    );
    // eslint-disable-next-line
  }, [mapInstance]);

  return null;
};

MapGraticule.propTypes = {
  interval: PropTypes.number,
  color: PropTypes.string,
  zoomIntervals: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.number,
      end: PropTypes.number,
      interval: PropTypes.number,
      digits: PropTypes.number,
    })
  ),
};

MapGraticule.defaultProps = {
  color: 'white',
  interval: GRATICULE_DEFAULT_INTERVAL,
  zoomIntervals: GRATICULE_DEFAULT_ZOOM_INTERVALS,
};

export default memo(MapGraticule);
