import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '90%',
    margin: '2vh auto',
    padding: '2vh',
  },
  button: {
    margin: '1vh 0',
  },
  informations: {
    margin: '5vh 0 4vh 5vw',
    display: 'flex',
    gap: '4vw',
  },
  table: {
    width: '90%',
    margin: '2vh auto',
  },
  map: {
    width: '70%',
    height: 500,
    margin: '4vh auto 0',
  },
}));
