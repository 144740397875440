import { useMemo } from 'react';

/**
 * useExclusifFilters - hook for filtration entities, by independents values
 * @param {Array} entities - any array
 * @param {Object} filters - object with comparator function
 * @param {Object} expectedValues - values for comparation
 * @returns {Array}
 *
 * Example:
 * const entities = [{ id: 1, name: 'FirstName' }, { id: 1, name: 'SecondName' }];
 *
 * const filteredEntities = useDependentsFilters({
 *   entities,
 *   filters: {
 *     search: (entity, expectedValue) => entity.name === expectedValue,
 *   },
 *   expectedValues: { search: 'FirstName' },
 * });
 *
 * filteredEntities -> [{ id: 1, name: 'FirstName' }]
 */
export function useArraySorting({
  entities = [],
  conditions,
  activeCondition,
  reverse = false,
}) {
  return useMemo(() => {
    if (!conditions) {
      return entities;
    }
    if (reverse) return entities.sort(conditions[activeCondition]).reverse();

    return entities.sort(conditions[activeCondition]);
  }, [entities, conditions, activeCondition, reverse]);
}
