import { makeStyles } from '@material-ui/core/styles';

import { theme } from 'common/theme';

export const useStyles = makeStyles({
  datasourceSummaryContainer: {
    marginTop: 20,
    padding: 10,
    border: `2px solid ${theme.palette.sinay.primary['40']}`,
  },
  datasourceSummaryTitle: {
    fontSize: 22,
    fontWeight: 600,
    color: theme.palette.sinay.primary['40'],
    marginBottom: 15,
  },
  header: {
    margin: '10px 0',
  },
  subtitle: {
    margin: '10px 0',
    fontSize: 16,
    fontWeight: 600,
  },
  text: {
    fontSize: 13,
  },
  textContainer: {
    '& p': {
      fontSize: 13,
    },
    '& h3': {
      margin: '10px 0',
      fontSize: 16,
      fontWeight: 600,
    },
  },
});
