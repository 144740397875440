import {
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  EMPTY_VALUE,
  STATUS_SUCCESS,
  STATUS_ERROR,
  EMPTY_ARRAY,
} from 'constants/common';
import createReducer from 'helpers/createReducer';
import { groupAndNormalizeArrayBy } from 'helpers/data';
import { prepareAndSetError } from 'helpers/apiErrors';

import {
  CREATE_SATELLITE_COMPARISON_SUCCESS,
  CREATE_SATELLITE_COMPARISON_ERROR,
  CREATE_SATELLITE_COMPARISON,
  COMPUTE_SATELLITE_COMPARISON_SUCCESS,
  COMPUTE_SATELLITE_COMPARISON_ERROR,
  COMPUTE_SATELLITE_COMPARISON,
  REQUEST_SATELLITE_COMPARISONS,
  REQUEST_SATELLITE_COMPARISONS_ERROR,
  REQUEST_SATELLITE_COMPARISONS_SUCCESS,
  REQUEST_SATELLITE_COMPARISON_SUCCESS,
  REQUEST_SATELLITE_COMPARISON_STATS_SUCCESS,
  REQUEST_SATELLITE_COMPARISON_STATS_ERROR,
  REQUEST_SATELLITE_COMPARISON_STATS,
  DOWNLOAD_SATELLITE_COMPARISON_SUCCESS,
  DOWNLOAD_SATELLITE_COMPARISON_ERROR,
  DOWNLOAD_SATELLITE_COMPARISON,
} from './types';

const initialState = {
  data: EMPTY_VALUE,
  ids: EMPTY_ARRAY,
  error: EMPTY_VALUE,
  downloadingSatelliteComparisonStats: EMPTY_ARRAY,
  status: STATUS_NOT_REQUESTED,
};

const setLoadingStatus = (state) => ({ ...state, status: STATUS_LOADING });

const setSatelliteComparison = (state, { data: satelliteComparison }) => {
  const {
    [satelliteComparison.point]: satelliteComparisonsData = [],
    ...data
  } = state.data;
  data[satelliteComparison.point] = [
    ...satelliteComparisonsData.filter(
      ({ id }) => id !== satelliteComparison.id
    ),
    satelliteComparison,
  ];

  return {
    ...state,
    data,
    ids: !state.ids.includes(satelliteComparison.point)
      ? [satelliteComparison.point, ...state.ids]
      : state.ids,
    status: STATUS_SUCCESS,
    error: EMPTY_VALUE,
  };
};

const setSatelliteComparisons = (state, { data: satelliteComparisons }) => ({
  ...state,
  ...groupAndNormalizeArrayBy(satelliteComparisons, 'point'),
  status: STATUS_SUCCESS,
  error: EMPTY_VALUE,
});

const setSatelliteComparisonStats = (state, { stats, comparisonId }) => {
  const {
    [stats.point]: satelliteComparisonsByPoint = [],
    ...data
  } = state.data;
  const satelliteComparison = satelliteComparisonsByPoint.find(
    ({ id }) => id === comparisonId
  );
  data[stats.point] = [{ ...satelliteComparison, stats }];

  return { ...state, data };
};

/**
 * Append comparisonId to the list of downloading satelliteComparisonStat IDs.
 * and set status as loading
 */
const setDownloadingSatelliteComparisonStats = (state, { comparisonId }) => ({
  ...state,
  downloadingSatelliteComparisonStats: [
    ...state.downloadingSatelliteComparisonStats,
    comparisonId,
  ],
  status: STATUS_LOADING,
});

/**
 * Remove comparisonId to the list of downloading satelliteComparisonStat IDs.
 * and set status success.
 */
const successSatelliteComparisonStatsDownload = (state, { comparisonId }) => ({
  ...state,
  downloadingSatelliteComparisonStats: state.downloadingSatelliteComparisonStats.filter(
    (item) => item.id === comparisonId
  ),
  status: STATUS_SUCCESS,
});

/**
 * Remove comparisonId to the list of downloading satelliteComparisonStat IDs.
 * and set status error.
 */
const errorSatelliteComparisonStatsDownload = (state, { comparisonId }) => ({
  ...state,
  downloadingSatelliteComparisonStats: state.downloadingSatelliteComparisonStats.filter(
    (item) => item.id === comparisonId
  ),
  status: STATUS_ERROR,
});

export default createReducer(initialState, {
  [CREATE_SATELLITE_COMPARISON]: setLoadingStatus,
  [REQUEST_SATELLITE_COMPARISON_STATS]: setLoadingStatus,
  [COMPUTE_SATELLITE_COMPARISON]: setLoadingStatus,
  [REQUEST_SATELLITE_COMPARISONS]: setLoadingStatus,
  [REQUEST_SATELLITE_COMPARISON_SUCCESS]: setSatelliteComparison,
  [CREATE_SATELLITE_COMPARISON_SUCCESS]: setSatelliteComparison,
  [COMPUTE_SATELLITE_COMPARISON_SUCCESS]: setSatelliteComparison,
  [REQUEST_SATELLITE_COMPARISONS_SUCCESS]: setSatelliteComparisons,
  [REQUEST_SATELLITE_COMPARISON_STATS_SUCCESS]: setSatelliteComparisonStats,
  [CREATE_SATELLITE_COMPARISON_ERROR]: prepareAndSetError,
  [COMPUTE_SATELLITE_COMPARISON_ERROR]: prepareAndSetError,
  [REQUEST_SATELLITE_COMPARISONS_ERROR]: prepareAndSetError,
  [REQUEST_SATELLITE_COMPARISON_STATS_ERROR]: prepareAndSetError,
  [DOWNLOAD_SATELLITE_COMPARISON]: setDownloadingSatelliteComparisonStats,
  [DOWNLOAD_SATELLITE_COMPARISON_SUCCESS]: successSatelliteComparisonStatsDownload,
  [DOWNLOAD_SATELLITE_COMPARISON_ERROR]: errorSatelliteComparisonStatsDownload,
});
