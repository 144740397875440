import React, { memo } from 'react';
import { Input, InputLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

/**
 * SearchInput component
 * Input used for search
 * @param value
 * @param handleChange
 */
const SearchInput = ({ value, handleChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.search}>
      <InputLabel htmlFor="search" className={classes.searchContainer}>
        {t('projects.grantAccess.labels.search')}
      </InputLabel>
      <Input
        htmlFor="search"
        disableUnderline
        autoFocus
        value={value}
        onChange={handleChange}
        classes={{
          root: classes.searchInputRoot,
          input: classes.searchField,
          focused: classes.searchFieldFocused,
        }}
      />
    </div>
  );
};

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default memo(SearchInput);
