import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ShareProjectButton from 'components/projects/ShareProjectButton';

/**
 * ProjectCollaboratorsList component with add button and list of collaborator
 * @param {Array} collaborators
 * @param {Function} onShareProject
 * @param {Boolean} canShareProject
 * @returns {jsx}
 */
const ProjectCollaboratorsList = ({ onShareProject, canShareProject }) => (
  <>{canShareProject && <ShareProjectButton onClick={onShareProject} />}</>
);

ProjectCollaboratorsList.propTypes = {
  onShareProject: PropTypes.func.isRequired,
  canShareProject: PropTypes.bool.isRequired,
};

export default memo(ProjectCollaboratorsList);
