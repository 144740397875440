import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import StartAnalysisModal from 'components/modals/StartAnalysisModal';
import { useModal } from 'hooks/useModal';
import { selectAvailableCredits } from 'ducks/user/selectors';

/**
 * StartAnalysisModalContainer is container for StartAnalysisModal component
 */
const StartAnalysisModalContainer = ({ id, onConfirm, ...props }) => {
  const { onClose } = useModal(id);
  const availableCredits = useSelector(selectAvailableCredits);

  const confirmHandler = useCallback(() => {
    onConfirm();
    onClose();
  }, [onClose, onConfirm]);

  return (
    <StartAnalysisModal
      {...props}
      closeModal={onClose}
      onConfirm={confirmHandler}
      availableCredits={availableCredits}
    />
  );
};

StartAnalysisModalContainer.propTypes = {
  id: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default React.memo(StartAnalysisModalContainer);
