import { get, pick } from 'lodash';

import {
  ANNUAL_INDEX,
  DEFAULT_GRAPH_CANVAS_HEIGHT,
  DEFAULT_GRAPH_CANVAS_WIDTH,
  GRAPH_EMPTY_PLACEHOLDER,
  JPD_GRAPH_VALUABLE_PARAMS,
  JPD_THRESHOLD_FACTOR,
  MIN_JPD_COLOR_TICKS_AMOUNT,
  MIN_JPD_TICKS_AMOUNT,
  RAW_DATA_GRAPH_PARAMS_PATH,
  STATS_API_SELECT_POSTFIX,
  VALUES_KEY,
  XY_NESTING_ORDER,
  YX_NESTING_ORDER,
} from 'constants/graphs';
import { INVALID_GRAPH_DATA, INVALID_RANGE } from 'constants/errors';
import { camelizeBoth, toCamelCase } from 'helpers/camelizer';
import {
  getGridTicksByMinMax,
  getIntervalsFromValues,
  getJointSelectLabelFromVariableName,
  getKeyByXY,
  getLevelsTypeByStatsId,
  getPartsFromAxisLabel,
  getPreparedUnits,
  getRectanglesMatrixByValues,
  getTitleFromRawGraphData,
} from 'helpers/graphs/common';
import {
  arrayDeepReplace,
  flatten,
  floatRound,
  getMinMax,
  getRange,
  sliceUntil,
} from 'helpers/common';
import { getDeepColorByPercent } from 'helpers/color';

/**
 * returns common static for JPD graph data
 * @param rawGraphData
 * @note values bins (x, y and probability) could be:
 * array of values (for annual data) or array of arrays of values (for monthly data or data with levels)
 * @returns {{
 *   axisLabels: { probability: string, x: string, y: string },
 *   xOptionsList: number[],
 *   yOptionsList: number[],
 *   xName: string,
 *   yName: string,
 *   units: { probability: string, x: string, y: string },
 *   title: string,
 *   nestingOrder: string,
 *   xSelectLabel: string,
 *   ySelectLabel: string,
 *   tooltipLabels: { x: string, y: string },
 *   levels: ({values: [], type: string } | {}),
 *   probabilityBin: [number[]] | [number[]][],,
 *   xValuesBin: [number[]] | [number[]][],
 *   yValuesBin: [number[]] | [number[]][],,
 * }}
 */
export const getJPDistributionCommonData = (rawGraphData) => {
  const rawGraphParams = get(rawGraphData, RAW_DATA_GRAPH_PARAMS_PATH, null);
  if (!rawGraphParams) {
    throw Error(INVALID_GRAPH_DATA);
  }

  const { yName, xName, cName, cbarLabel: probabilityLabel } = camelizeBoth(
    pick(rawGraphParams, JPD_GRAPH_VALUABLE_PARAMS)
  );

  const { ylabel: yLabel, xlabel: xLabel } = rawGraphParams;
  const { label: xTooltipLabel, units: xRawUnits } = getPartsFromAxisLabel(
    xLabel
  );
  const { label: yTooltipLabel, units: yRawUnits } = getPartsFromAxisLabel(
    yLabel
  );
  const { dimensions, ...probabilityParams } = rawGraphData[cName];
  const lastDimension = toCamelCase(dimensions[dimensions.length - 1]);
  const xSelectOptionsKey = `${xName}${STATS_API_SELECT_POSTFIX}`;
  const ySelectOptionsKey = `${yName}${STATS_API_SELECT_POSTFIX}`;
  const nestingOrder =
    lastDimension === yName ? XY_NESTING_ORDER : YX_NESTING_ORDER;

  const levels =
    rawGraphData.level && rawGraphData.level[VALUES_KEY]
      ? {
          type: getLevelsTypeByStatsId(rawGraphData.id),
          values: rawGraphData.level[VALUES_KEY],
        }
      : {};

  return {
    xName,
    yName,
    levels,
    nestingOrder,
    xValuesBin: rawGraphData[xName][VALUES_KEY],
    yValuesBin: rawGraphData[yName][VALUES_KEY],
    xOptionsList: rawGraphData[xSelectOptionsKey][VALUES_KEY],
    yOptionsList: rawGraphData[ySelectOptionsKey][VALUES_KEY],
    title: getTitleFromRawGraphData(rawGraphData),
    xSelectLabel: getJointSelectLabelFromVariableName(xName),
    ySelectLabel: getJointSelectLabelFromVariableName(yName),
    probabilityBin: probabilityParams[VALUES_KEY],
    tooltipLabels: { x: xTooltipLabel, y: yTooltipLabel },
    axisLabels: {
      x: xLabel,
      y: yLabel,
      probability: probabilityLabel,
    },
    units: {
      x: getPreparedUnits(xRawUnits),
      y: getPreparedUnits(yRawUnits),
      probability: getPreparedUnits(probabilityParams.attributes.units),
    },
  };
};

/**
 * returns data bins (data set for each combination of joint params)
 * @param { string } units
 * @param { string } nestingOrder - x -> y or y -> x
 * @param {{ x: string, y: string }} tooltipLabels - x and y tooltip label
 * @param { [number[]] } probabilityBin - array of bins with xValues arrays
 * @param { [number[]] } xValuesBin - array of bins with xValues arrays
 * @param { [number[]] } yValuesBin - array of bins with yValues arrays
 * @param { number } monthNumber - month number, 0 by default (ANNUAL_INDEX)
 * @param { number|null } levelIndex - level index, null by default (if no levels in data)
 * @param { number } canvasWidth - actual graph canvas width (used to calculate matrix)
 * @param { number } canvasHeight - actual graph canvas height
 * @returns {
 *   Array<{
 *    xTicks,
 *    xValues,
 *    yTicks,
 *    mockData,
 *    rectangles,
 *    maxProbability,
 *    tooltipData,
 *    colorTicks,
 *  }>
 *}
 */
export function getJpdDataBins({
  units,
  nestingOrder,
  tooltipLabels,
  probabilityBin,
  xValuesBin,
  yValuesBin,
  monthNumber = ANNUAL_INDEX,
  levelIndex = null,
  canvasWidth = DEFAULT_GRAPH_CANVAS_WIDTH,
  canvasHeight = DEFAULT_GRAPH_CANVAS_HEIGHT,
}) {
  const isXYOrder = nestingOrder === XY_NESTING_ORDER;

  return probabilityBin.reduce((binsAcc, binProbabilities, binIndex) => {
    const additionalIndex = {
      [monthNumber !== ANNUAL_INDEX]: monthNumber - 1,
      [levelIndex !== null]: levelIndex,
    }.true;

    const hasExtraDimension = additionalIndex !== undefined;
    const xValuesRaw = hasExtraDimension
      ? xValuesBin[binIndex][additionalIndex]
      : xValuesBin[binIndex];
    const yValuesRaw = hasExtraDimension
      ? yValuesBin[binIndex][additionalIndex]
      : yValuesBin[binIndex];

    const xValues = sliceUntil(xValuesRaw, GRAPH_EMPTY_PLACEHOLDER);
    const yValues = sliceUntil(yValuesRaw, GRAPH_EMPTY_PLACEHOLDER);
    const probabilitiesMatrix = hasExtraDimension
      ? binProbabilities[additionalIndex]
      : binProbabilities;

    const rectanglesMatrix = getRectanglesMatrixByValues({
      xValues,
      yValues,
      nestingOrder,
      canvasWidth,
      canvasHeight,
    });

    const xLastValue = xValues[xValues.length - 1];
    const yLastValue = yValues[yValues.length - 1];
    const xStep = (xLastValue - xValues[0]) / (xValues.length - 1);
    const yStep = (yLastValue - yValues[0]) / (yValues.length - 1);
    xValues.push(xLastValue + xStep);
    yValues.push(yLastValue + yStep);

    const tooltipData = {
      probability: {},
      x: getIntervalsFromValues({
        mask: `${tooltipLabels.x} in [{from}, {to}]${units.x}`,
        values: xValues,
      }),
      y: getIntervalsFromValues({
        mask: `${tooltipLabels.y} in [{from}, {to}]${units.y}`,
        values: yValues,
      }),
    };

    const xRange = xValues[xValues.length - 1] - xValues[0];
    const xTicks = xRange
      ? getGridTicksByMinMax({
          min: floatRound(xValues[0]),
          max: floatRound(xValues[xValues.length - 1]),
          minTicks: MIN_JPD_TICKS_AMOUNT,
          valueAsMaxTick: true,
        })
      : [];

    const yRange = yValues[yValues.length - 1] - yValues[0];
    const yTicks = yRange
      ? getGridTicksByMinMax({
          min: floatRound(yValues[0]),
          max: floatRound(yValues[yValues.length - 1]),
          minTicks: MIN_JPD_TICKS_AMOUNT,
          valueAsMaxTick: true,
        })
      : [];
    const maxProbability = Math.max.apply(null, flatten(probabilitiesMatrix));
    const colorTicks = getGridTicksByMinMax({
      max: maxProbability,
      minTicks: MIN_JPD_COLOR_TICKS_AMOUNT,
      valueAsMaxTick: true,
    });

    const probabilityThreshold = maxProbability / JPD_THRESHOLD_FACTOR;
    const mockData = xTicks.map((x) => ({
      x,
      y: maxProbability,
    }));

    const rectangles = rectanglesMatrix.length
      ? probabilitiesMatrix.reduce((rectAcc, probabilities, parentIndex) => {
          probabilities.forEach((probability, childIndex) => {
            const xIndex = isXYOrder ? parentIndex : childIndex;
            const yIndex = isXYOrder ? childIndex : parentIndex;
            const empty = probability === GRAPH_EMPTY_PLACEHOLDER;
            if (empty || probability < probabilityThreshold) {
              return rectAcc;
            }

            const colorPercent = (probability / maxProbability) * 100;
            const color = getDeepColorByPercent(colorPercent);

            const preparedProbability = +probability.toFixed(2);
            tooltipData.probability[
              getKeyByXY(xIndex, yIndex)
            ] = `${preparedProbability} ${units.probability}`;

            const rectangle = rectanglesMatrix[parentIndex][childIndex];
            rectangle.xIndex = xIndex;
            rectangle.yIndex = yIndex;
            rectangle.color = color;

            rectAcc.push(rectangle);
          });

          return rectAcc;
        }, [])
      : [];

    binsAcc.push({
      xTicks,
      xValues,
      yTicks,
      mockData,
      rectangles,
      maxProbability,
      tooltipData,
      colorTicks,
    });

    return binsAcc;
  }, []);
}

/**
 * returns calculated average probabilities bin by selected range (sectors on rose select)
 * @param { array } probabilities - initial probability bin with all leafs
 * @param {{ start, end }} range - selected leafs range (start, end indexes)
 * @param { number } monthNumber - month number, 0 by default (ANNUAL_INDEX)
 * @param { array } leafsOccurrences - leafs occurrences array
 * (higher occurrence means higher affect on average probability)
 * @returns { number[][][] } average of selected range probabilities bins
 * @note monthNumber is used to keep untouched bins initial structure
 * @note average probability is calculated by 'Law of total probability'
 * considering weights (occurence/occurrencesSum)
 * ( (aOccurrence/(occurrencesSum)) * a + (aOccurrence/(occurrencesSum)) * b + ...  )
 */
export const getAverageProbabilityBinByRange = ({
  probabilities,
  range = {},
  monthNumber = ANNUAL_INDEX,
  leafsOccurrences,
}) => {
  if (!Number.isFinite(range.start) || !Number.isFinite(range.end)) {
    throw Error(INVALID_RANGE);
  }
  const [startIndex, endIndex] = getMinMax(range.start, range.end);
  const probabilityBin = [];
  const isAnnual = monthNumber === ANNUAL_INDEX;
  const monthIndex = monthNumber - 1;
  const occurrences = isAnnual
    ? leafsOccurrences
    : leafsOccurrences[monthIndex];
  const leafsAmount = occurrences.length;

  const setBinResult = (result, binIndex) => {
    if (isAnnual) {
      probabilityBin[binIndex] = result;
      return;
    }
    probabilityBin[binIndex] = [];
    probabilityBin[binIndex][monthIndex] = result;
  };

  for (let binIndex = 0; binIndex < probabilities.length; binIndex += 1) {
    const binProbabilities = isAnnual
      ? probabilities[binIndex]
      : probabilities[binIndex][monthIndex];

    if (startIndex === endIndex) {
      const result = arrayDeepReplace({
        array: binProbabilities[startIndex],
        needle: GRAPH_EMPTY_PLACEHOLDER,
        replacer: 0,
      });
      setBinResult(result, binIndex);
      continue;
    }

    const selectedLeafsIndexes = !range.invert
      ? getRange(startIndex, endIndex)
      : getRange(0, startIndex).concat(getRange(endIndex, leafsAmount - 1));

    const selectedOccurrencesSum = selectedLeafsIndexes.reduce(
      (acc, index) => acc + occurrences[index],
      0
    );
    const averageProbabilities = [];
    const parentLength = binProbabilities[0].length;
    const childLength = binProbabilities[0][0].length;

    for (let parentIndex = 0; parentIndex < parentLength; parentIndex += 1) {
      if (!averageProbabilities[parentIndex]) {
        averageProbabilities[parentIndex] = [];
      }
      for (let childIndex = 0; childIndex < childLength; childIndex += 1) {
        averageProbabilities[parentIndex][childIndex] = 0;
        for (let i = 0; i < selectedLeafsIndexes.length; i += 1) {
          const leafIndex = selectedLeafsIndexes[i];
          const leaf = binProbabilities[leafIndex];
          const probability = leaf[parentIndex][childIndex];
          const currentAverage =
            averageProbabilities[parentIndex][childIndex] || 0;
          if (probability === 0 || probability === GRAPH_EMPTY_PLACEHOLDER) {
            continue;
          }
          averageProbabilities[parentIndex][childIndex] =
            currentAverage +
            (probability * occurrences[leafIndex]) / selectedOccurrencesSum;
        }
      }
    }
    setBinResult(averageProbabilities, binIndex);
  }

  return probabilityBin;
};
