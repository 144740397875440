import { createSelector } from 'reselect';

export const selectModals = (state) => state.modals;

export const selectModalsCollection = createSelector(
  [selectModals],
  ({ modalsIds, modalsById }) => modalsIds.map((id) => modalsById[id])
);

export const selectReversedModalsCollection = createSelector(
  [selectModalsCollection],
  (modalsCollection) => modalsCollection.reverse()
);
