import { useMemo } from 'react';

import { DEFAULT_GRAPH_SELECT_VALUE } from 'constants/graphs';
import { useFields } from 'hooks/useFields';

/**
 * hook that helps to work with graph controls.
 * Build initial values ('0') for given params and return tools to control them
 * Also returns dataPath which is path to data in graph multidimensional data array
 * @param { array } params - array of controlled params names
 * @return {{ handleChange: Function, setParam: Function, dataPath: string, params: object }}
 * @note should be used with inputs which have index as a value (e.g. 0,1,2,3..)
 */
export const useGraphControls = (params = []) => {
  const initialValues = useMemo(
    () =>
      params.reduce((acc, param) => {
        acc[param] = DEFAULT_GRAPH_SELECT_VALUE;
        return acc;
      }, {}),
    // eslint-disable-next-line
    []
  );
  const { handleChange, setField, values } = useFields(initialValues);
  const dataPath = params.reduce(
    (acc, param) => `${acc && `${acc}.`}${values[param]}`,
    ''
  );

  return {
    handleChange,
    setParam: setField,
    params: values,
    dataPath,
  };
};
