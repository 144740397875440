import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: 550,
    margin: '20px 0px',
    '& td': {
      '&:first-child': {
        minWidth: 200,
      },
    },
  },
  blueRow: {
    backgroundColor: theme.palette.primary.light,
  },
}));
