import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, IconButton } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { X } from 'phosphor-react';

import { DEFAULT_CLOSE_NOTIFICATION_TIME } from 'common/config';
import ReactElementFromHtml from 'components/common/CreateReactElement';

import { useStyles } from './styles';

/**
 * NotificationModal component
 * Modal with some info with message
 * @param {string} type - snackbar type
 * @param {Function} closeModal -  handler for close modal button
 * @param {string} title title of modal
 * @param {Number} autoHideDuration
 * @param {string} message text in modal
 */
const NotificationModal = ({ message, closeModal, type, autoHideDuration }) => {
  const classes = useStyles();

  return (
    <Snackbar
      open
      disableWindowBlurListener
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      classes={{ root: classes.notificationRoot }}
      autoHideDuration={autoHideDuration}
      onClose={closeModal}
    >
      <MuiAlert
        variant="filled"
        severity={type}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={closeModal}
          >
            <X size={14} />
          </IconButton>
        }
      >
        {message instanceof Object ? (
          message
        ) : (
          <ReactElementFromHtml is="div" element={message} />
        )}
      </MuiAlert>
    </Snackbar>
  );
};

NotificationModal.propTypes = {
  type: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  autoHideDuration: PropTypes.number,
};

NotificationModal.defaultProps = {
  autoHideDuration: DEFAULT_CLOSE_NOTIFICATION_TIME,
  type: 'error',
};

export default React.memo(NotificationModal);
