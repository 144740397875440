import React, { useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import SimpleSelect from 'components/common/SimpleSelect';

import { useStyles } from './styles';

const ALL_TAB_VALUE = '0';

/**
 * ProjectsFilterByOffers component
 * Contains filter by offers
 */
const ProjectsFilterByOffers = ({ offerId, offers, onChange }) => {
  const classes = useStyles();
  const options = useMemo(() => {
    const opt = [];

    opt.push({
      value: ALL_TAB_VALUE,
      option: 'ALL',
    });

    offers.forEach((offer) => {
      opt.push({
        value: String(offer.id),
        option: offer.name,
      });
    });
    return opt;
  }, [offers]);

  const handleChange = useCallback(
    (_, newTab) => {
      const result =
        newTab.props.value !== ALL_TAB_VALUE ? newTab.props.value : undefined;
      onChange(result);
    },
    [onChange]
  );

  return (
    <div className={classes.container}>
      <SimpleSelect
        onChange={handleChange}
        optionList={options}
        value={offerId}
        withBaseInput
      />
    </div>
  );
};

ProjectsFilterByOffers.propTypes = {
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  offerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ProjectsFilterByOffers.defaultProps = {
  offerId: ALL_TAB_VALUE,
};

export default memo(ProjectsFilterByOffers);
