import {
  CREATE_SATELLITE_COMPARISON,
  CREATE_SATELLITE_COMPARISON_SUCCESS,
  CREATE_SATELLITE_COMPARISON_ERROR,
  COMPUTE_SATELLITE_COMPARISON,
  COMPUTE_SATELLITE_COMPARISON_ERROR,
  COMPUTE_SATELLITE_COMPARISON_SUCCESS,
  LAUNCH_SATELLITE_COMPARISON,
  REQUEST_SATELLITE_COMPARISONS,
  REQUEST_SATELLITE_COMPARISONS_ERROR,
  REQUEST_SATELLITE_COMPARISONS_SUCCESS,
  START_UPDATE_SATELLITE_COMPARISON_JOB,
  STOP_UPDATE_SATELLITE_COMPARISON_JOB,
  FINISH_UPDATE_SATELLITE_COMPARISON_JOB,
  REQUEST_SATELLITE_COMPARISON_SUCCESS,
  REQUEST_SATELLITE_COMPARISON_STATS,
  REQUEST_SATELLITE_COMPARISON_STATS_ERROR,
  REQUEST_SATELLITE_COMPARISON_STATS_SUCCESS,
  DOWNLOAD_SATELLITE_COMPARISON_SUCCESS,
  DOWNLOAD_SATELLITE_COMPARISON_ERROR,
  DOWNLOAD_SATELLITE_COMPARISON,
} from './types';

export const requestSatelliteComparisons = (params) => ({
  type: REQUEST_SATELLITE_COMPARISONS,
  params,
});

export const requestSatelliteComparisonsSuccess = (data) => ({
  type: REQUEST_SATELLITE_COMPARISONS_SUCCESS,
  data,
});

export const requestSatelliteComparisonsError = (error) => ({
  type: REQUEST_SATELLITE_COMPARISONS_ERROR,
  error,
});

export const createSatelliteComparison = (data) => ({
  type: CREATE_SATELLITE_COMPARISON,
  data,
});

export const createSatelliteComparisonSuccess = (data) => ({
  type: CREATE_SATELLITE_COMPARISON_SUCCESS,
  data,
});

export const createSatelliteComparisonError = (error) => ({
  type: CREATE_SATELLITE_COMPARISON_ERROR,
  error,
});

export const computeSatelliteComparison = ({ id }) => ({
  type: COMPUTE_SATELLITE_COMPARISON,
  id,
});

export const computeSatelliteComparisonSuccess = (data) => ({
  type: COMPUTE_SATELLITE_COMPARISON_SUCCESS,
  data,
});

export const computeSatelliteComparisonError = (error) => ({
  type: COMPUTE_SATELLITE_COMPARISON_ERROR,
  error,
});

export const launchSatelliteComparison = (data) => ({
  type: LAUNCH_SATELLITE_COMPARISON,
  data,
});

export const startUpdateSatelliteComparisonJob = (comparisonId) => ({
  type: START_UPDATE_SATELLITE_COMPARISON_JOB,
  comparisonId,
});

export const stopUpdateSatelliteComparisonJob = (comparisonId) => ({
  type: STOP_UPDATE_SATELLITE_COMPARISON_JOB,
  comparisonId,
});

export const finishUpdateSatelliteComparisonJob = (comparisonId) => ({
  type: FINISH_UPDATE_SATELLITE_COMPARISON_JOB,
  comparisonId,
});

export const requestSatelliteComparisonSuccess = (data) => ({
  type: REQUEST_SATELLITE_COMPARISON_SUCCESS,
  data,
});

export const requestSatelliteComparisonStats = (comparisonId) => ({
  type: REQUEST_SATELLITE_COMPARISON_STATS,
  comparisonId,
});

export const requestSatelliteComparisonStatsError = (error) => ({
  type: REQUEST_SATELLITE_COMPARISON_STATS_ERROR,
  error,
});

export const requestSatelliteComparisonStatsSuccess = ({
  stats,
  comparisonId,
}) => ({
  type: REQUEST_SATELLITE_COMPARISON_STATS_SUCCESS,
  comparisonId,
  stats,
});

export const downloadSatelliteComparisonStats = ({ comparisonId }) => ({
  type: DOWNLOAD_SATELLITE_COMPARISON,
  comparisonId,
});

export const downloadSatelliteComparisonStatsSucess = ({ comparisonId }) => ({
  type: DOWNLOAD_SATELLITE_COMPARISON_SUCCESS,
  comparisonId,
});

export const downloadSatelliteComparisonStatsError = ({
  error,
  comparisonId,
}) => ({
  type: DOWNLOAD_SATELLITE_COMPARISON_ERROR,
  error,
  comparisonId,
});
