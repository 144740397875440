import React, { memo, useState, useCallback, useRef } from 'react';
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Line,
  Dot,
} from 'recharts';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import {
  CARDINAL_BY_ANGLE,
  EMPTY_FUNCTION,
  EOL,
  CARDINAL_ANGLES,
} from 'constants/common';
import { CHART_SVG_ID } from 'constants/graphs';
import { palette } from 'common/theme';
import BaseTooltip from 'components/common/graphs/BaseTooltip';
import { useUniqueId } from 'hooks/useUniqueId';

import { useStyles } from './styles';

const THRESHOLD_OFFSET = 10;
const ANGLE_DOMAIN = [0, 360];
const TICK_FONT_SIZE = 12;
const QUARTER_CORNER = 22.5;
const TOOLTIP_OFFSET = {
  x: -40,
  y: 30,
};

/**
 * Convert angle tick
 * @param {Number} angle
 * @return {String}
 */
const polarTickFormatter = (angle) => CARDINAL_BY_ANGLE[angle];

/**
 * Scatter for extreme chart
 * @param {Number} cx
 * @param {Number} cy
 * @param {Object} payload
 * @param {Number} r
 * @param {String} key
 * @param {Object} classes
 * @param {String} dataKey
 * @param {Function} onMouseEnter
 * @param {Function} onMouseLeave
 * @return {jsx}
 */
const ExtremePeakRoseScatter = ({
  cx,
  cy,
  payload: { payload },
  r,
  key,
  className,
  dataKey,
  onMouseEnter,
  onMouseLeave,
}) =>
  !!payload[dataKey] && (
    <Dot
      r={r}
      cx={cx}
      cy={cy}
      key={key}
      payload={payload}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );

/**
 * Render a threshold line
 * @param {Array} points
 * @param {String} dataKey
 * @param {Object} classes
 * @param {Function} onEnter
 * @param {Function} onLeave
 * @return {JSX.Element}
 */
const ExtremePeakRoseThreshold = ({
  points,
  dataKey,
  classes,
  onEnter,
  onLeave,
}) => {
  const preparedPoints = points.filter(({ payload }) => payload[dataKey]);

  return (
    <Line
      dot={false}
      isAnimationActive={false}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
      points={preparedPoints}
      type="linearClosed"
      className={classes.peakRoseLine}
    />
  );
};

/**
 *
 * @param {React.Ref} chartRef
 * @param {Array} values
 * @param {Array} pointsTicks
 * @param {Object} axisInfo
 * @param {Number} canvasWidth
 * @param {Number} canvasHeight
 * @param {Function} onPointEnter
 * @param {Function} onPointLeave
 * @param {Function} onThresholdEnter
 * @param {Function} onThresholdLeave
 * @param {bool} hasOutliers
 * @return {jsx}
 */
const ExtremePeakRoseChart = ({
  chartRef,
  values,
  pointsTicks,
  axisInfo,
  canvasWidth,
  canvasHeight,
  onPointEnter,
  onPointLeave,
  onThresholdEnter,
  onThresholdLeave,
  hasOutliers,
}) => {
  const salt = useUniqueId();
  const classes = useStyles();
  const domain = [pointsTicks[0], pointsTicks[pointsTicks.length - 1]];

  return (
    <RadarChart
      id={CHART_SVG_ID + salt}
      ref={chartRef}
      width={canvasWidth}
      height={canvasHeight}
      data={values}
    >
      <PolarGrid key={0} gridType="circle" strokeDasharray="3 3 " />
      <PolarAngleAxis
        key={2}
        isAnimationActive={false}
        dataKey="angle"
        type="number"
        tick={{
          fontSize: TICK_FONT_SIZE,
        }}
        ticks={CARDINAL_ANGLES}
        domain={ANGLE_DOMAIN}
        tickFormatter={polarTickFormatter}
      />
      <PolarRadiusAxis
        key={1}
        isAnimationActive={false}
        angle={90 - QUARTER_CORNER}
        type="number"
        tick={{
          angle: QUARTER_CORNER,
          textAnchor: 'middle',
          dy: -2,
          fontSize: TICK_FONT_SIZE,
          fill: palette.black.main,
        }}
        tickFormatter={(tick) => `${tick}${axisInfo.y.units}`}
        dataKey="value"
        stroke="transparent"
        ticks={pointsTicks}
        domain={domain}
      />
      <Radar
        key={3}
        isAnimationActive={false}
        name="value"
        dot={
          <ExtremePeakRoseScatter
            className={classes.peakRosePointScatter}
            onMouseEnter={onPointEnter}
            onMouseLeave={onPointLeave}
          />
        }
        shape={EMPTY_FUNCTION}
        dataKey="value"
      />
      {(() => {
        if (hasOutliers) {
          return (
            <Radar
              key={4}
              isAnimationActive={false}
              name="outlier"
              dot={
                <ExtremePeakRoseScatter
                  className={classes.peakRoseOutlierScatter}
                  onMouseEnter={onPointEnter}
                  onMouseLeave={onPointLeave}
                />
              }
              shape={EMPTY_FUNCTION}
              dataKey="outlier"
            />
          );
        }
      })()}
      <Radar
        isAnimationActive={false}
        key={5}
        name="threshold"
        dot={false}
        shape={
          <ExtremePeakRoseThreshold
            classes={classes}
            onEnter={onThresholdEnter}
            onLeave={onThresholdLeave}
          />
        }
        dataKey="threshold"
      />
    </RadarChart>
  );
};

ExtremePeakRoseChart.propTypes = {
  chartRef: PropTypes.object.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      angle: PropTypes.number,
      threshold: PropTypes.number,
      value: PropTypes.number,
    })
  ).isRequired,
  pointsTicks: PropTypes.arrayOf(PropTypes.number).isRequired,
  axisInfo: PropTypes.shape({
    x: PropTypes.shape({
      longName: PropTypes.string,
      units: PropTypes.string,
    }),
    y: PropTypes.shape({
      longName: PropTypes.string,
      units: PropTypes.string,
    }),
  }).isRequired,
  canvasWidth: PropTypes.number.isRequired,
  canvasHeight: PropTypes.number.isRequired,
  onPointEnter: PropTypes.func.isRequired,
  onPointLeave: PropTypes.func.isRequired,
  hasOutliers: PropTypes.bool.isRequired,
};

/**
 * Tooltip for extreme peak rose point
 * @param {Number} x
 * @param {Number} y
 * @param {Object} data
 * @param {Object} axisInfo
 * @return {jsx}
 */
const ExtremePeakRoseTooltip = ({ x, y, data, axisInfo }) => (
  <BaseTooltip
    x={x}
    y={y}
    offsetX={TOOLTIP_OFFSET.x}
    offsetY={TOOLTIP_OFFSET.y}
  >
    {axisInfo.x.longName}:&nbsp;{data.angle.toFixed(2)}&nbsp;
    {axisInfo.x.units}
    {EOL}
    {axisInfo.y.longName}:&nbsp;{data.value.toFixed(2)}&nbsp;
    {axisInfo.y.units}
    {EOL}
    Direction&nbsp;convention:&nbsp;{axisInfo.x.conventions}
  </BaseTooltip>
);

ExtremePeakRoseTooltip.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  data: PropTypes.shape({ angle: PropTypes.number, value: PropTypes.number }),
  axisInfo: PropTypes.shape({
    x: PropTypes.shape({
      longName: PropTypes.string,
      units: PropTypes.string,
    }),
    y: PropTypes.shape({
      longName: PropTypes.string,
      units: PropTypes.string,
      conventions: PropTypes.string,
    }),
  }),
};

/**
 * Component with chart and tooltip for every point.
 * @param {Object} commonData
 * @param {Number} canvasWidth
 * @param {Number} canvasHeight
 * @return {jsx}
 */
const ExtremePeakRose = ({
  commonData,
  canvasWidth,
  canvasHeight,
  hasOutliers = false,
}) => {
  const classes = useStyles();
  const chartRef = useRef(null);
  const wrapperRef = useRef(null);
  const [tooltip, setTooltip] = useState(null);
  const [thresholdTooltip, setThresholdTooltip] = useState(null);

  const { values, pointsTicks } = commonData.chartData;
  const { axisInfo } = commonData.axisData;
  const { threshold = 0 } = values.find((value) => !!value.threshold);

  const onThresholdEnter = useCallback(
    (_, { clientX, clientY }) => {
      const {
        top: wrapperY,
        left: wrapperX,
      } = wrapperRef.current.getBoundingClientRect();

      setThresholdTooltip({
        x: clientX - wrapperX,
        y: clientY - wrapperY,
      });
    },
    [wrapperRef, setThresholdTooltip]
  );

  const onThresholdLeave = useCallback(() => setThresholdTooltip(null), [
    setThresholdTooltip,
  ]);

  const onPointEnter = useCallback(
    ({ cx, cy, payload }) => {
      const { container: chartContainer } = chartRef.current;
      const { left: chartX } = chartContainer.getBoundingClientRect();
      const { left: wrapperX } = wrapperRef.current.getBoundingClientRect();

      setTooltip({
        payload: {
          angle: payload.angle,
          // outliers use the `outlier` attribute to store the value
          value: payload.value ? payload.value : payload.outlier,
        },
        x: cx + (chartX - wrapperX),
        y: cy,
      });
    },
    [chartRef, wrapperRef, setTooltip]
  );

  const onPointLeave = useCallback(() => setTooltip(null), [setTooltip]);

  return (
    <Grid
      ref={wrapperRef}
      className={classes.chartWrapper}
      justifyContent="center"
      container
    >
      <Grid item>
        <ExtremePeakRoseChart
          values={values}
          axisInfo={axisInfo}
          chartRef={chartRef}
          pointsTicks={pointsTicks}
          canvasWidth={canvasWidth}
          canvasHeight={canvasHeight}
          onPointEnter={onPointEnter}
          onPointLeave={onPointLeave}
          onThresholdEnter={onThresholdEnter}
          onThresholdLeave={onThresholdLeave}
          hasOutliers={hasOutliers}
        />
      </Grid>
      {tooltip && (
        <ExtremePeakRoseTooltip
          x={tooltip.x}
          y={tooltip.y}
          data={tooltip.payload}
          axisInfo={axisInfo}
        />
      )}
      {thresholdTooltip && (
        <BaseTooltip
          x={thresholdTooltip.x}
          y={thresholdTooltip.y}
          offsetX={THRESHOLD_OFFSET}
          offsetY={THRESHOLD_OFFSET}
        >
          threshold: {threshold} {axisInfo.y.units}
        </BaseTooltip>
      )}
    </Grid>
  );
};

ExtremePeakRose.propTypes = {
  canvasWidth: PropTypes.number,
  canvasHeight: PropTypes.number,
  commonData: PropTypes.shape({
    chartData: PropTypes.shape({
      values: PropTypes.arrayOf(
        PropTypes.shape({
          angle: PropTypes.number,
          value: PropTypes.number,
          threshold: PropTypes.number,
        })
      ),
      pointsTicks: PropTypes.arrayOf(PropTypes.number).isRequired,
    }),
    axisData: PropTypes.shape({
      axisInfo: PropTypes.shape({
        x: PropTypes.shape({
          units: PropTypes.string,
        }),
        y: PropTypes.shape({
          units: PropTypes.string,
        }),
      }),
    }),
  }),
};

ExtremePeakRose.defaultProps = {
  canvasWidth: 280,
  canvasHeight: 280,
};

export default memo(ExtremePeakRose);
