import { EMPTY_VALUE } from 'constants/common';

import {
  REQUEST_REPORTS,
  REQUEST_REPORTS_SUCCESS,
  REQUEST_REPORTS_ERROR,
  POST_REPORT,
  POST_REPORT_ERROR,
  POST_REPORT_SUCCESS,
  GENERATE_REPORT,
  GENERATE_REPORT_SUCCESS,
  GENERATE_REPORT_ERROR,
  CANCEL_REPORT,
  CANCEL_REPORT_SUCCESS,
  CANCEL_REPORT_ERROR,
  GET_REPORT_DATA,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_ERROR,
  START_UPDATE_REPORT,
  CANCEL_UPDATE_REPORT,
  FINISH_UPDATE_REPORT,
  CREATE_REPORT,
  CLEAN_REPORT,
  CLEAN_REPORT_SUCCESS,
  CLEAN_REPORT_ERROR,
  REMOVE_REPORT,
  DELETE_REPORT_ERROR,
  DELETE_REPORT_SUCCESS,
  DELETE_REPORT,
  RESET_REPORTS,
  DOWNLOAD_REPORT,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_ERROR,
} from './types';

export const requestReports = (params = EMPTY_VALUE) => ({
  type: REQUEST_REPORTS,
  payload: params,
});

export const requestReportsSuccess = (allReports) => ({
  type: REQUEST_REPORTS_SUCCESS,
  payload: allReports,
});

export const requestReportsError = (error) => ({
  type: REQUEST_REPORTS_ERROR,
  error,
});

export const postReport = ({ name, computation }) => ({
  type: POST_REPORT,
  payload: { name, computation },
});

export const postReportSuccess = (report) => ({
  type: POST_REPORT_SUCCESS,
  payload: report,
});

export const postReportError = (error) => ({
  type: POST_REPORT_ERROR,
  error,
});

export const generateReport = (id, data) => ({
  type: GENERATE_REPORT,
  id,
  data,
});

export const generateReportSuccess = (report) => ({
  type: GENERATE_REPORT_SUCCESS,
  payload: report,
});

export const generateReportError = (error) => ({
  type: GENERATE_REPORT_ERROR,
  error,
});

export const cancelReport = () => ({
  type: CANCEL_REPORT,
});

export const cancelReportSuccess = (report) => ({
  type: CANCEL_REPORT_SUCCESS,
  payload: report,
});

export const cancelReportError = (error) => ({
  type: CANCEL_REPORT_ERROR,
  error,
});

export const getReportData = (id) => ({
  type: GET_REPORT_DATA,
  payload: id,
});

export const getReportDataSuccess = (report = EMPTY_VALUE) => ({
  type: GET_REPORT_DATA_SUCCESS,
  payload: report,
});

export const getReportDataError = (error) => ({
  type: GET_REPORT_DATA_ERROR,
  error,
});

export const cleanReport = (reportId) => ({
  type: CLEAN_REPORT,
  payload: reportId,
});

export const cleanReportSuccess = (response) => ({
  type: CLEAN_REPORT_SUCCESS,
  payload: response,
});

export const cleanReportError = (error) => ({
  type: CLEAN_REPORT_ERROR,
  error,
});

export const deleteReport = (reportId) => ({
  type: DELETE_REPORT,
  payload: reportId,
});

export const deleteReportSuccess = (response) => ({
  type: DELETE_REPORT_SUCCESS,
  payload: response,
});

export const deleteReportError = (error) => ({
  type: DELETE_REPORT_ERROR,
  error,
});

export const removeReport = ({ reportId, projectId }) => ({
  type: REMOVE_REPORT,
  payload: { reportId, projectId },
});

export const startUpdateReport = () => ({
  type: START_UPDATE_REPORT,
});

export const cancelUpdateReport = () => ({
  type: CANCEL_UPDATE_REPORT,
});

export const finishUpdateReport = () => ({
  type: FINISH_UPDATE_REPORT,
});

export const createReport = ({
  name,
  projectId,
  monthly,
  reportType,
  parts,
  points,
}) => ({
  type: CREATE_REPORT,
  payload: {
    name,
    monthly,
    reportType,
    parts,
    points,
    projectId,
  },
});

export const resetReports = () => ({
  type: RESET_REPORTS,
});

export const downloadReport = ({ reportId }) => ({
  type: DOWNLOAD_REPORT,
  reportId,
});

export const downloadReportSuccess = ({ reportId }) => ({
  type: DOWNLOAD_REPORT_SUCCESS,
  reportId,
});

export const downloadReportError = ({ error, reportId }) => ({
  type: DOWNLOAD_REPORT_ERROR,
  error,
  reportId,
});
