/* eslint-disable */
import { takeLatest, put, call, select } from 'redux-saga/effects';

import { getOffers } from 'services/offers';
import {
  requestOffers,
  requestOffersSuccess,
  requestOffersError,
} from 'ducks/offers/actions';
import { selectOffersIds } from 'ducks/offers/selectors';

import { REQUEST_OFFERS, REQUEST_OFFERS_IF_NEED } from './types';

/**
 * Checks if offers collection exists, if not, requests it
 */
function* requestOffersIfNeedWorker() {
  const ids = yield select(selectOffersIds);

  if (!ids.length) {
    yield put(requestOffers());
  }
}

/**
 * Requests all offers
 */
export function* requestOffersWorker() {
  try {
    const offers = yield call(getOffers);
    yield put(requestOffersSuccess(offers));
  } catch (error) {
    yield put(requestOffersError(error));
  }
}

export default function* offersSaga() {
  yield takeLatest(REQUEST_OFFERS, requestOffersWorker);
  yield takeLatest(REQUEST_OFFERS_IF_NEED, requestOffersIfNeedWorker);
}
