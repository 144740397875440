export const GTM_EVENT_NAME = 'ga_event';

export const CATEGORY = 'METOC';

export const PROJECT_CREATE_LABEL = 'ProjectCreate';
export const PROJECT_COMPUTE_LABEL = 'ProjectCompute';
export const PROJECT_OPEN_LABEL = 'ProjectOpen';
export const AUTHENTIFICATION_LABEL = 'Authentification';
export const ADMIN_USER_LABEL = 'AdminUser';
export const NAVIGATION_LABEL = 'Navigation';
export const SEARCH_LABEL = 'Search';
export const SIGNUP_LABEL = 'AdminInvite';
export const MAP_LABEL = 'Map';
export const STATS_LABEL = 'StatUsage';
export const WORKZONE_QUERY_LABEL = 'WorkZone - Query';
export const WORKZONE_ANALYZE_LABEL = 'WorkZone - Analyze';
export const HELP_LINK_LABEL = 'Help link';
export const DATA_VALIDATION_LABEL = 'Data Validation';
export const AWD_LABEL = 'AWD';
