import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropsTypes from 'prop-types';

import BoatComponent from 'components/projects/MarineContractors/Operations/Boat/index';
import BlockUpdateComponent from 'components/common/MarineContractors/BlockUpdateComponent/index';
import {
  selectMarineContractorsBoats,
  selectMarineContractorsBoatIsCreating,
  selectMarineContractorsBoatIsUpdating,
  selectMarineContractorsFilteredJobOperations,
  selectMarineContractorsOperationsByBoatId,
  selectMarineContractorsOperationsConstraintsActions,
  selectMarineContractorsBoatOperationsActions,
} from 'ducks/marineContractors/selectors';
import {
  createMarineContractorsBoat,
  deleteMarineContractorsBoatSuccess,
  removeMarineContractorsJobOperations,
  setMarineContractorsJobOperations,
  updateMarineContractorsBoat,
} from 'ducks/marineContractors/actions';
import { useModal } from 'hooks/useModal';
import { DELETE_MC_MODAL } from 'constants/modals';
import { NO_ID } from 'constants/marineContractors';
import { EMPTY_VALUE } from 'constants/common';

/**
 * Boat container - display boat information and associated operations
 * @param { object } boat
 * @param { boolean } isSelected
 * @param { Boolean } forceFocus
 * @param { function } handleSelect
 * @returns { JSX }
 */
const BoatContainer = ({ boat, isSelected, forceFocus, handleSelect }) => {
  const { openModal } = useModal();
  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState(false);
  const [boatUpdate, setBoatUpdate] = useState(boat);
  const [hasChanged, setHasChanged] = useState(false);
  const [forceFocuses, setForceFocuses] = useState(EMPTY_VALUE);

  const boats = useSelector(selectMarineContractorsBoats);
  const operations = useSelector((state) =>
    selectMarineContractorsOperationsByBoatId(boat.id)(state)
  );
  const selectedOperations = useSelector((state) =>
    selectMarineContractorsFilteredJobOperations(operations)(state)
  );

  const isCreating = useSelector(selectMarineContractorsBoatIsCreating);
  const isUpdating = useSelector((state) =>
    selectMarineContractorsBoatIsUpdating(boat)(state)
  );

  const operationsActions = useSelector((state) =>
    selectMarineContractorsBoatOperationsActions(boat)(state)
  );
  const constraintsActions = useSelector((state) =>
    selectMarineContractorsOperationsConstraintsActions(operations)(state)
  );
  const hasActions =
    !!operationsActions?.length || !!constraintsActions?.length;

  useEffect(() => {
    setBoatUpdate(boat);
  }, [boat]);

  useEffect(() => {
    if (forceFocus) {
      setForceFocuses({
        name: true,
      });
    }
  }, [forceFocus]);

  useEffect(() => {
    const hasOperations = !!selectedOperations?.length;
    setIsChecked(hasOperations);
  }, [selectedOperations]);

  useEffect(() => {
    if (hasChanged) {
      if (boatUpdate.id === NO_ID) {
        dispatch(createMarineContractorsBoat(boatUpdate));
      } else {
        dispatch(updateMarineContractorsBoat(boatUpdate));
      }
      setHasChanged(false);
    }
    // eslint-disable-next-line
  }, [hasChanged]);

  const handleOnChange = (object, changed = true) => {
    setBoatUpdate({
      ...object,
    });
    setHasChanged(changed);
  };

  const handleHasValid = useCallback(
    (fieldName) => {
      switch (fieldName) {
        case 'name':
          setForceFocuses({
            name: false,
          });
          break;
        default:
          break;
      }
    },
    [setForceFocuses]
  );

  const handleOnCheck = useCallback(
    (e) => {
      setIsChecked(true);
      if (e.target.checked) {
        dispatch(setMarineContractorsJobOperations(operations));
      } else {
        dispatch(removeMarineContractorsJobOperations(operations));
      }
    },
    [operations, setIsChecked, dispatch]
  );

  const handleOnDelete = useCallback(() => {
    if (boat.id === NO_ID) {
      if (boats?.length > 1) {
        dispatch(deleteMarineContractorsBoatSuccess(boat));
      }
      return;
    }
    openModal(DELETE_MC_MODAL, {
      object: boat,
    });
  }, [boat, boats, openModal, dispatch]);

  return (
    <BlockUpdateComponent
      isUpdating={(isCreating && boat.id === NO_ID) || isUpdating}
      isSelected={isSelected}
      noGrow
    >
      <BoatComponent
        boat={boatUpdate}
        isChecked={isChecked}
        forceFocus={!(isUpdating || isCreating) ? forceFocuses : EMPTY_VALUE}
        handleOnCheck={handleOnCheck}
        handleOnDelete={handleOnDelete}
        handleHasValid={handleHasValid}
        handleOnChange={handleOnChange}
        handleSelect={handleSelect}
        isSelected={isSelected}
        blockDeletion={hasActions}
      />
    </BlockUpdateComponent>
  );
};

BoatContainer.propTypes = {
  boat: PropsTypes.object.isRequired,
  isSelected: PropsTypes.bool,
  forceFocus: PropsTypes.bool,
  handleSelect: PropsTypes.func.isRequired,
};

BoatContainer.defaultProps = {
  isSelected: false,
  forceFocus: false,
};

export default React.memo(BoatContainer);
