import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import RenameProjectModalComponent from 'components/modals/RenameProjectModal';
import { renameProject } from 'ducks/projects/actions';
import { closeModal } from 'ducks/modals/actions';
import { selectProjectById } from 'ducks/projects/selectors';

/**
 * RenameProjectModal container
 * Contains logic and data for RenameProjectModal
 * @param id - modal id
 * @param projectId
 */
const RenameProjectModal = ({ id, projectId }) => {
  const dispatch = useDispatch();
  const project = useSelector((state) => selectProjectById(state, projectId));

  const close = useCallback(() => {
    dispatch(closeModal({ id }));
  }, [dispatch, id]);

  const renameHandler = useCallback(
    (newProjectName) => {
      dispatch(renameProject(projectId, newProjectName));
      dispatch(closeModal({ id }));
    },
    [dispatch, id, projectId]
  );

  return (
    <RenameProjectModalComponent
      closeModal={close}
      renameProject={renameHandler}
      projectName={project.name}
    />
  );
};

RenameProjectModal.propTypes = {
  id: PropTypes.string.isRequired,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default React.memo(RenameProjectModal);
