import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import InviteCollaboratorComponent from 'components/modals/InviteCollaboratorModal';
import { inviteUserWaitingRequest } from 'ducks/app/actions';
import { closeModal } from 'ducks/modals/actions';
import { selectRegisterError } from 'ducks/user/selectors';
import { clearUserError } from 'ducks/user/actions';

/**
 * InviteCollaboratorModal container
 * Contains logic and data for InviteCollaboratorModal
 * @param id - modal id
 */
const InviteCollaboratorModal = ({ id }) => {
  const dispatch = useDispatch();
  const registerError = useSelector(selectRegisterError);
  useEffect(
    () => () => {
      dispatch(clearUserError());
    },
    [dispatch]
  );

  const onClose = useCallback(() => {
    dispatch(closeModal({ id }));
  }, [dispatch, id]);

  const onSuccess = useCallback(
    (values) => {
      dispatch(inviteUserWaitingRequest({ data: values, modalId: id }));
    },
    [dispatch, id]
  );

  return (
    <InviteCollaboratorComponent
      onClose={onClose}
      onSuccess={onSuccess}
      registerError={registerError}
    />
  );
};

InviteCollaboratorModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default React.memo(InviteCollaboratorModal);
