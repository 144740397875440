import React from 'react';
import PropTypes from 'prop-types';

import { useModal } from 'hooks/useModal';
import EmptyOperationModal from 'components/modals/EmptyOperationModal';

/**
 * EmptyOperationModal container
 * @param {String} id
 */
const EmptyOperationModalContainer = ({ id }) => {
  const { onClose } = useModal(id);

  return <EmptyOperationModal onClose={onClose} />;
};

EmptyOperationModalContainer.propTypes = {
  id: PropTypes.string.isRequired,
};

export default React.memo(EmptyOperationModalContainer);
