const prefix = '@@stat2d';

export const REQUEST_BUY_MAPS = `${prefix}/REQUEST_BUY_MAPS`;
export const REQUEST_BUY_MAPS_SUCCESS = `${prefix}/REQUEST_BUY_MAPS_SUCCESS`;
export const REQUEST_BUY_MAPS_ERROR = `${prefix}/REQUEST_BUY_MAPS_ERROR`;

export const REQUEST_GET_MAPS = `${prefix}/REQUEST_GET_MAPS`;
export const REQUEST_GET_MAPS_IF_NEED = `${prefix}/REQUEST_GET_MAPS_IF_NEED`;
export const REQUEST_GET_MAPS_SUCCESS = `${prefix}/REQUEST_GET_MAPS_SUCCESS`;
export const REQUEST_GET_MAPS_ERROR = `${prefix}/REQUEST_GET_MAPS_ERROR`;

export const REQUEST_GET_TILE_PROPERTIES_SUCCESS = `${prefix}/REQUEST_GET_TILE_PROPERTIES_SUCCESS`;
export const REQUEST_GET_TILE_PROPERTIES_ERROR = `${prefix}/REQUEST_GET_TILE_PROPERTIES_ERROR`;
