import {
  Children,
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  memo,
} from 'react';
import Leaflet from 'leaflet-providers';
import PropTypes from 'prop-types';

import { useMapInstance, useMapControls } from 'hooks/leaflet';

/**
 * Map Feature Group. Creates leaflet feature group and append children elements to it
 * Helps to store all feature elements in 1 layer and provide 1 callback for all feature group items
 * If needed it can bind popup to each group item
 * each children should have an `add` prop
 * @param {string} name Feature Group name, used in map controls
 * @param {function} onClick callback function, takes 2 arguments: layer event and map instance
 * @param {boolean} withControls - whether add controls for feature group
 * @param {React.ReactNode} children
 * @see https://leafletjs.com/reference-1.6.0.html#featuregroup
 */
const MapFeatureGroup = ({ children, onClick, withControls, name }) => {
  const mapInstance = useMapInstance();
  const mapControls = useMapControls();

  const featureGroup = useRef(Leaflet.featureGroup([]));

  const featureOnClick = useCallback(
    (layerEvent) => onClick(layerEvent, mapInstance),
    [mapInstance, onClick]
  );

  const addFeature = useCallback((feature) => {
    featureGroup.current.addLayer(feature);
  }, []);

  const removeFeature = useCallback((feature) => {
    featureGroup.current.removeLayer(feature);
  }, []);

  useEffect(() => {
    if (!mapInstance) {
      return;
    }
    const group = featureGroup.current;

    if (onClick) {
      group.on('click', featureOnClick);
    }

    featureGroup.current = group.addTo(mapInstance);

    if (withControls) {
      mapControls.addOverlay(group, name);
    }

    return () => {
      group.off('click', featureOnClick);

      if (withControls) {
        mapControls.removeLayerByName(name);
      }
    };
  }, [mapInstance, featureOnClick, onClick, name, withControls, mapControls]);

  return Children.toArray(children).map((child) =>
    cloneElement(child, {
      add: addFeature,
      remove: removeFeature,
      leafletElement: featureGroup.current,
    })
  );
};

MapFeatureGroup.propTypes = {
  name: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
  withControls: PropTypes.bool,
};

MapFeatureGroup.defaultProps = {
  name: '',
  onClick: null,
  withControls: false,
};

export default memo(MapFeatureGroup);
