/**
 * Based on keys in this array errors from backend will be
 * divided into 'common' and 'entityErrors'
 * @todo 'verificationKey' - is not related to common errors,
 * but it's included here because it's only used on registration form
 * and would require more changes in logic
 */
export const COMMON_ERRORS_KEYS = [
  'nonFieldErrors',
  'detail',
  'all',
  'verificationKey',
];
