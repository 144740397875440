import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 568,
    width: '100%',
  },
  body: {
    whiteSpace: 'pre-line',
    textAlign: 'center',
    padding: '10px 5px',
  },
  confirmButton: {
    ...theme.buttons.outlined.secondary,
    marginLeft: 10,
  },
  cancelButton: {
    ...theme.buttons.outlined.neutral,
  },
}));
