import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    background: theme.palette.primary.main,
    height: 50,
    marginTop: 20,
    color: 'white',
    textTransform: 'uppercase',
    '&:hover': {
      opacity: 0.95,
      background: theme.palette.primary.main,
    },
    '&:disabled': {
      background: theme.palette.grey.middle,
    },
  },
  image: {
    position: 'absolute',
    right: 20,
    height: 15,
    width: 15,
  },
}));
