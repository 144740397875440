import React from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_AXIS_INDEX } from 'constants/graphs';
import SvgColorRangeBar from 'components/common/graphs/SvgColorRangeBar';
import { GRADIENT_SCHEME_BY_MODE, RGB_CUSTOM_MODE } from 'constants/color';

const Y_BAR_OFFSET = 8;

/**
 * Custom color bar component.
 * Decorator for SvgColorRangeBar, helps to use it with recharts as `Customized` component
 * @param { object } xAxisMap
 * @param { object } yAxisMap
 * @param { string } label
 * @param { number } barWidth - full color bar width (band + label + ticks)
 * @param { array } ticks
 * @param { number } leftOffset - x offset from chart to color bar
 * @param { boolean } showMaxTick - whether to show max tick
 * @param { string } mode - gradient mode to use
 * @returns { JSX }
 * @see GRADIENT_SCHEME_BY_MODE
 * @see SvgColorRangeBar
 */
const CustomColorBar = ({
  xAxisMap,
  yAxisMap,
  label,
  ticks,
  mode,
  barWidth,
  leftOffset,
  showMaxTick,
}) => {
  const { x: xAxisStart, width: canvasWidth } = xAxisMap[DEFAULT_AXIS_INDEX];
  const { y: yAxisStart, height } = yAxisMap[DEFAULT_AXIS_INDEX];
  const x = xAxisStart + canvasWidth + leftOffset;
  const gradientScheme = GRADIENT_SCHEME_BY_MODE[mode];

  return (
    <svg
      x={x}
      y={yAxisStart - Y_BAR_OFFSET}
      width={barWidth}
      height={height + 2 * Y_BAR_OFFSET}
    >
      <SvgColorRangeBar
        ticks={ticks}
        label={label}
        gradientScheme={gradientScheme}
        height={height}
        width={barWidth}
        offsetY={Y_BAR_OFFSET}
        showMaxTick={showMaxTick}
      />
    </svg>
  );
};

CustomColorBar.propTypes = {
  label: PropTypes.string,
  barWidth: PropTypes.number,
  xAxisMap: PropTypes.object,
  yAxisMap: PropTypes.object,
  leftOffset: PropTypes.number,
  showMaxTick: PropTypes.bool,
  mode: PropTypes.string,
};

CustomColorBar.defaultProps = {
  barWidth: 80,
  leftOffset: 20,
  mode: RGB_CUSTOM_MODE,
  showMaxTick: false,
};

export default CustomColorBar;
