import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import GetMapsButton from 'components/common/GetMapsButton';
import { useModal } from 'hooks/useModal';
import { GET_MAPS_MODAL } from 'constants/modals';
import { requestBuyMaps, requestGetMapsIfNeed } from 'ducks/stat2d/actions';

/**
 * GetMapsContainer - tab container contains Get Maps Button
 * @param {Object} zone - zone id of selected project
 * @param {number} projectId - project id
 * @param {Boolean} allowBuyMap - if map buying is allowed
 */
const GetMapsContainer = ({ zone, projectId, allowBuyMap }) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const zoneId = zone.id;

  useEffect(() => {
    dispatch(requestGetMapsIfNeed({ zoneId, project: projectId }));
  }, [dispatch, zoneId, projectId]);

  const openGetMapsModal = useCallback(() => {
    const onConfirm = () => dispatch(requestBuyMaps({ zoneId }));

    openModal(GET_MAPS_MODAL, {
      id: GET_MAPS_MODAL,
      zone,
      onConfirm,
    });
  }, [openModal, dispatch, zoneId, zone]);

  return (
    allowBuyMap && (
      <GetMapsButton
        onClick={openGetMapsModal}
        disabled={!zone.has2DStatistics}
      />
    )
  );
};

GetMapsContainer.propTypes = {
  zone: PropTypes.shape({
    id: PropTypes.number,
    has2DStatistics: PropTypes.bool,
  }).isRequired,
  allowBuyMap: PropTypes.bool,
  projectId: PropTypes.number,
};

export default memo(GetMapsContainer);
