import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Button,
  Typography,
} from '@material-ui/core';
import { Warning, WarningCircle } from 'phosphor-react';

import BaseModal from 'components/common/BaseModal/index';
import { closeAllModal } from 'ducks/modals/actions';
import { computePlanning, deletePlanning } from 'ducks/plannings/actions';

import { useStyles } from './styles';

const Error = ({ error, isWarning = false }) => {
  const classes = useStyles();
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar className={isWarning ? classes.warningIcon : classes.errorIcon}>
          {isWarning ? <Warning size={20} /> : <WarningCircle size={20} />}
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={error.kind} secondary={error.error} />
    </ListItem>
  );
};

const ErrorList = ({ errors, isWarning = false }) => {
  // React item list must a key prop for performance issue, so...
  const idPrefix = isWarning ? 'w' : 'e';
  errors = errors.map((error, index) => {
    error.id = `${idPrefix}${index}`;
    return error;
  });
  return (
    <Grid item>
      <List>
        {errors.map((error) => (
          <Error key={error.id} error={error} isWarning={isWarning} />
        ))}
      </List>
    </Grid>
  );
};

/**
 * Displays planning errors and warning during parsing
 */
const PlanningSettingsErrorsModal = ({
  errors,
  warnings,
  projectId,
  newPlanningId,
  preparedPlanningData,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const hasErrors = !!errors?.length;
  const hasWarnings = !!warnings?.length;

  const onClose = useCallback(() => {
    dispatch(deletePlanning({ planningId: newPlanningId, projectId }));
    dispatch(closeAllModal());
  }, [dispatch, projectId, newPlanningId]);

  const onValidate = useCallback(() => {
    dispatch(
      computePlanning({ projectId, newPlanningId, preparedPlanningData })
    );
  }, [dispatch, projectId, newPlanningId, preparedPlanningData]);
  const controls = (
    <>
      {hasErrors ? (
        <Typography align="center" className={classes.smallMargin}>
          {t('downtime.planningSettings.errorSettingsTip')}
        </Typography>
      ) : (
        ''
      )}
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        direction="row"
      >
        <Grid item>
          <Button
            variant="contained"
            onClick={onClose}
            className={classes.closeButton}
          >
            {t('buttons.abort')}
          </Button>
        </Grid>
        {!hasErrors ? (
          <Grid item>
            <Button variant="contained" color="secondary" onClick={onValidate}>
              {t('buttons.anyway')}
            </Button>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </>
  );

  return (
    <BaseModal
      title={t('downtime.planningSettings.modalErrorTitle')}
      modalClass={classes.modal}
      onClose={onClose}
      controls={controls}
    >
      {hasErrors ? (
        <Typography align="center" className={classes.smallMargin}>
          {t('downtime.planningSettings.errorSettingsTitle')}
          <br />
          {t('downtime.planningSettings.errorSettingsSubTitle')}
        </Typography>
      ) : (
        <Typography align="center" className={classes.smallMargin}>
          {t('downtime.planningSettings.warningSettingTitle')}
          <br />
          {t('downtime.planningSettings.warningSettingSubTitle')}
        </Typography>
      )}
      <Grid
        container
        alignItems="center"
        justifyContent={
          hasErrors && hasWarnings ? 'space-evenly' : 'flex-start'
        }
        direction="row"
        height="50%"
      >
        {hasErrors ? <ErrorList errors={errors} /> : ''}
        {hasWarnings ? <ErrorList errors={warnings} isWarning /> : ''}
      </Grid>
    </BaseModal>
  );
};

export default memo(PlanningSettingsErrorsModal);
