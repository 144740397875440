import {
  REQUEST_ZONES,
  REQUEST_ZONES_IF_NEED,
  REQUEST_ZONES_ERROR,
  REQUEST_ZONES_SUCCESS,
  REQUEST_ZONE,
  REQUEST_ZONE_SUCCESS,
  REQUEST_ZONE_ERROR,
} from './types';

export const requestZones = () => ({
  type: REQUEST_ZONES,
});

export const requestZonesIfNeed = () => ({
  type: REQUEST_ZONES_IF_NEED,
});

export const requestZonesSuccess = (zones) => ({
  type: REQUEST_ZONES_SUCCESS,
  zones,
});

export const requestZonesError = (error) => ({
  type: REQUEST_ZONES_ERROR,
  error,
});

export const requestZone = (id) => ({
  type: REQUEST_ZONE,
  id,
});

export const requestZoneSuccess = (zone) => ({
  type: REQUEST_ZONE_SUCCESS,
  zone,
});

export const requestZoneError = (error) => ({
  type: REQUEST_ZONE_ERROR,
  error,
});
