import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import { useStyles } from './styles';

export const BaseTitleComponent = memo(({ children }) => (
  <Typography variant="h2" color="textSecondary" align="center">
    {children}
  </Typography>
));

export const BaseSubtitleComponent = memo(({ children }) => (
  <Typography variant="h4" color="textSecondary" align="center">
    {children}
  </Typography>
));

export const SmallerTitleComponent = memo(({ children }) => (
  <Typography variant="h5" color="textSecondary" align="center">
    {children}
  </Typography>
));

export const SmallerSubtitleComponent = memo(({ children }) => (
  <Typography variant="h6" color="textSecondary" align="center">
    {children}
  </Typography>
));

/**
 * Renders container with big icon, title and subtitle
 * @param {JSX} iconComponent
 * @param {String} title
 * @param {String} subtitle - hint
 * @param {Object} titleComponent
 * @param {Object} subtitleComponent
 * @param {Number} iconSize
 * @param {{container, icon, text}} classes - classes object to override standard
 */
const EmptyContentFallback = ({
  iconComponent,
  title,
  titleComponent,
  subtitle,
  subtitleComponent,
  iconSize,
  classes,
}) => {
  const classesObject = classes ?? useStyles({ iconSize });
  const Title = titleComponent ?? BaseTitleComponent;
  const Subtitle = subtitleComponent ?? BaseSubtitleComponent;

  return (
    <Grid
      item
      container
      alignItems="center"
      direction="column"
      className={classesObject.container}
    >
      <Grid item className={classesObject.icon}>
        {iconComponent}
      </Grid>
      <Grid item className={classesObject.text}>
        <Title>{title}</Title>
      </Grid>
      <Grid item className={classesObject.text}>
        <Subtitle>{subtitle}</Subtitle>
      </Grid>
    </Grid>
  );
};

EmptyContentFallback.propTypes = {
  iconComponent: PropTypes.object,
  title: PropTypes.string.isRequired,
  titleComponent: PropTypes.object,
  subtitle: PropTypes.string,
  subtitleComponent: PropTypes.object,
  iconSize: PropTypes.number,
  classes: PropTypes.object,
};

EmptyContentFallback.defaultProps = {
  iconComponent: null,
  titleComponent: null,
  subtitleComponent: null,
  subtitle: '',
  iconSize: 200,
  classes: null,
};

export default memo(EmptyContentFallback);
