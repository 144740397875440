import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const CyclingButton = ({ children, onClick, start = 0, values = [null] }) => {
  const [index, setIndex] = useState(start);

  const handleClick = (event) => {
    if (index < values.length - 1) setIndex(index + 1);
    else setIndex(0);
    return onClick(event, values[index]);
  };

  return <Box onClick={handleClick}>{children}</Box>;
};

CyclingButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  values: PropTypes.array,
};

export default memo(CyclingButton);
