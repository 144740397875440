import React from 'react';

import { Loader } from 'components/common/Loader';
import { palette } from 'common/theme';

import { useStyles } from './styles';

/**
 * Loader with black opacity color
 * @param {Object} props
 * @return {jsx}
 */
const LoaderOverlay = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.loaderOverlay}>
      <Loader {...props} color={palette.white.main} />
    </div>
  );
};

export default LoaderOverlay;
