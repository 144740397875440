import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Gear } from 'phosphor-react';

import Empty from 'components/common/EmptyContentFallback';

import { useStyles } from './style';

/**
 * Renders EmptyContentFallback component with message
 * that Activity page is under construction
 */
const Activity = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Empty
        iconComponent={<Gear size={18} />}
        title={t('emptyState.activity.title')}
        subtitle={t('emptyState.activity.subtitle')}
      />
    </div>
  );
};

export default memo(Activity);
