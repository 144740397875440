import React, { useMemo, memo, useState } from 'react';
import { PieChart, Pie, Cell, Customized, Tooltip } from 'recharts';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';

import SelectMonthButtonGroup from 'components/common/buttons/SelectMonthButtonGroup';
import { palette } from 'common/theme';
import DistributionRoseGrid from 'components/common/graphs/DistributionRoseGrid';
import RoseLeaf from 'components/common/graphs/RoseLeaf';
import { withCustomTooltipProps } from 'hocs/graphs/withCustomTooltipProps';
import RoseTooltip from 'components/common/graphs/RoseTooltip';
import { getPrettyNumber } from 'helpers/data';
import {
  ANNUAL_INDEX,
  MIN_ROSE_GRID_TICKS_AMOUNT,
  IGNORE_ON_SAVING_CLASS,
  DEFAULT_LEVELS_BAR_WIDTH,
  CHART_SVG_ID,
  CHART_TITLE_ID,
  GRAPHS_WIDGETS,
} from 'constants/graphs';
import { useToggle } from 'hooks/useToggle';
import { getGridTicksByMinMax } from 'helpers/graphs/common';
import CustomColorLegend from 'components/common/graphs/CustomColorLegend';
import CustomLevelsBar from 'components/common/graphs/CustomLevelsBar';
import DownloadGraphDataFileButtonGroup from 'containers/buttons/DownloadGraphDataFileButtonGroup';
import { useUniqueId } from 'hooks/useUniqueId';
import { HorizontalLoader } from 'components/common/HorizontalLoader';

import { useStyles } from './styles';

const CustomTooltip = withCustomTooltipProps(RoseTooltip, {
  propsToDisplay: ['sector', 'totalOccurence'],
  propsToPass: ['startAngle', 'endAngle', 'probabilities'],
  transform: { totalOccurence: (value) => `${getPrettyNumber(value)}%` },
});

const COLOR_LEGENDS_WIDTH = 200;
const COLOR_LEGENDS_OFFSET = 5;
const LEGENDS_FULL_WIDTH = COLOR_LEGENDS_WIDTH + COLOR_LEGENDS_OFFSET;
const CANVAS_OFFSET = 10;
const LEVELS_BAR_OFFSET = 10;
const LEVELS_FULL_WIDTH = DEFAULT_LEVELS_BAR_WIDTH + LEVELS_BAR_OFFSET;

const GRAPH_MARGIN = {
  left: 5,
  top: CANVAS_OFFSET,
  right: CANVAS_OFFSET,
  bottom: CANVAS_OFFSET,
};

/**
 * Distribution Rose Graph component.
 * Graph is rose diagram which shows probability depends on agnle, month and some dynamic params from statistics
 * Renders graph, month selector, if there're levels in graph data renders Levels Select
 * @param annualData
 * @param monthlyData
 * @param fill
 * @param stroke
 * @param radius
 * @param padding
 * @param projectId
 * @param statsIds
 * @param { string } pngFileName - file name for saving as png
 * @param { array } widgets - expected widgets list
 * @returns { JSX }
 */
const DistributionRoseGraph = ({
  widgets,
  withAnimation,
  annualData,
  monthlyData,
  radius,
  padding,
  stroke,
  statsIds,
  projectId,
  pngFileName,
}) => {
  const salt = useUniqueId();
  const classes = useStyles();
  const [monthIndex, setMonthIndex] = useState(ANNUAL_INDEX);
  const [level, setLevel] = useState(0);
  const { toggle: toggleTooltip, isActive: isTooltipExpanded } = useToggle();

  const withMonths = widgets.includes(GRAPHS_WIDGETS.months);
  const hasLevels = Array.isArray(annualData) && !!annualData[0].levels;
  const { legends, legendTitle, roseLeafs, colors } = hasLevels
    ? annualData[0]
    : annualData;
  const { endAngle: pieStartAngle } = roseLeafs[0];
  const { startAngle: pieEndAngle } = roseLeafs[roseLeafs.length - 1];

  const { graphData, RoseGrid, Sectors } = useMemo(() => {
    const selectedByMonth =
      monthIndex === ANNUAL_INDEX ? annualData : monthlyData[monthIndex - 1];
    const data = hasLevels ? selectedByMonth[level] : selectedByMonth;
    const gridLevels = getGridTicksByMinMax({
      max: data.maxProbability,
      minTicks: MIN_ROSE_GRID_TICKS_AMOUNT,
    });
    const maxGridProbability = gridLevels[gridLevels.length - 1];

    const RoseGridComponent = ({ cx, cy, outerRadius, margin }) => (
      <DistributionRoseGrid
        levels={gridLevels}
        radius={outerRadius}
        cx={cx + margin.left}
        cy={cy + margin.top}
      />
    );

    const SectorsWrapper = memo(({ children }) =>
      withAnimation ? (
        <Fade in timeout={700}>
          <g>{children}</g>
        </Fade>
      ) : (
        <g>{children}</g>
      )
    );

    const SectorsComponent = ({ cx, cy, outerRadius, margin }) => (
      <SectorsWrapper>
        {data.roseLeafs.map(
          ({ probabilities, totalOccurence, startAngle, endAngle }) => (
            <RoseLeaf
              key={startAngle}
              cx={cx + margin.left}
              cy={cy + margin.top}
              startAngle={startAngle}
              endAngle={endAngle}
              parts={probabilities}
              total={totalOccurence}
              colors={data.colors}
              radius={(totalOccurence / maxGridProbability) * outerRadius}
            />
          )
        )}
      </SectorsWrapper>
    );

    return {
      graphData: data,
      RoseGrid: RoseGridComponent,
      Sectors: SectorsComponent,
    };
  }, [annualData, monthlyData, withAnimation, monthIndex, level, hasLevels]);

  const { title, levels } = graphData;
  const graphSide = (radius + padding) * 2;
  const chartMargin = hasLevels
    ? { ...GRAPH_MARGIN, left: CANVAS_OFFSET + LEVELS_FULL_WIDTH }
    : GRAPH_MARGIN;
  const chartHeight = graphSide + chartMargin.top + chartMargin.bottom;
  const chartWidth = graphSide + chartMargin.left + LEGENDS_FULL_WIDTH;
  const cx = graphSide / 2;
  const cy = graphSide / 2;

  return (
    <Grid justifyContent="center" container>
      <Grid item>
        <Typography
          id={CHART_TITLE_ID + salt}
          className={classes.title}
          variant="subtitle1"
          align="center"
        >
          {title}
        </Typography>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item className={classes.graphWrapper}>
            <PieChart
              id={CHART_SVG_ID + salt}
              margin={chartMargin}
              cx={cx}
              cy={cy}
              width={chartWidth}
              height={chartHeight}
              outerRadius={radius}
            >
              <Customized key={1} component={Sectors} />
              <Customized key={2} component={RoseGrid} />
              <Pie
                key={3}
                cx={cx}
                cy={cy}
                data={graphData.roseLeafs}
                startAngle={pieStartAngle}
                endAngle={pieEndAngle}
                dataKey="sectorValue"
                outerRadius={radius}
                onClick={toggleTooltip}
                stroke={stroke}
              >
                {roseLeafs.map(({ startAngle }) => (
                  <Cell
                    key={startAngle}
                    className={classNames(
                      classes.sector,
                      IGNORE_ON_SAVING_CLASS
                    )}
                  />
                ))}
              </Pie>
              <Customized
                key={4}
                title={legendTitle}
                colors={colors}
                legends={legends}
                component={CustomColorLegend}
                legendWidth={COLOR_LEGENDS_WIDTH}
                leftOffset={chartMargin.left + COLOR_LEGENDS_OFFSET}
              />
              {hasLevels && (
                <Customized
                  key={5}
                  levels={levels.values}
                  type={levels.type}
                  barWidth={DEFAULT_LEVELS_BAR_WIDTH}
                  selectedLevel={level}
                  onSelect={setLevel}
                  xOffset={LEVELS_BAR_OFFSET}
                  component={CustomLevelsBar}
                />
              )}
              <Tooltip
                payloadUniqBy="startAngle"
                isAnimationActive={false}
                content={
                  <CustomTooltip
                    offsetRadius={80}
                    tooltipOffset={{ top: -40, right: 100 }}
                    rightOffset={LEGENDS_FULL_WIDTH}
                    legends={legends}
                    legendTitle={legendTitle}
                    isExpanded={isTooltipExpanded}
                  />
                }
              />
            </PieChart>
          </Grid>
        </Grid>
        {withMonths && (
          <Grid container justifyContent="center">
            {monthlyData ? (
              <SelectMonthButtonGroup
                withAnnual
                className={classes.monthSelector}
                value={monthIndex}
                setMonth={setMonthIndex}
              />
            ) : (
              <HorizontalLoader justifyCenter opacity={0.7} />
            )}
          </Grid>
        )}
        <DownloadGraphDataFileButtonGroup
          salt={salt}
          projectId={projectId}
          statsIds={statsIds}
          currentLevel={hasLevels ? level : null}
          monthNumber={monthIndex}
          pngFileName={pngFileName}
        />
      </Grid>
    </Grid>
  );
};

const graphDataShape = {
  legends: PropTypes.arrayOf(PropTypes.string),
  maxProbability: PropTypes.number,
  roseLeafs: PropTypes.arrayOf(
    PropTypes.shape({
      sector: PropTypes.string,
      startAngle: PropTypes.number,
      endAngle: PropTypes.number,
      sectorValue: PropTypes.number,
      totalOccurence: PropTypes.number,
      probabilities: PropTypes.arrayOf(PropTypes.number),
    })
  ),
  legendTitle: PropTypes.string,
  colors: PropTypes.arrayOf(PropTypes.string),
};

DistributionRoseGraph.propTypes = {
  annualData: PropTypes.oneOfType([
    PropTypes.shape(graphDataShape).isRequired,
    PropTypes.arrayOf(PropTypes.shape(graphDataShape)),
  ]),
  monthlyData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape(graphDataShape)),
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape(graphDataShape))),
  ]),
  withAnimation: PropTypes.bool,
  stroke: PropTypes.string,
  radius: PropTypes.number,
  padding: PropTypes.number,
  projectId: PropTypes.number,
  statsIds: PropTypes.array,
  pngFileName: PropTypes.string,
  widgets: PropTypes.arrayOf(PropTypes.string),
};

DistributionRoseGraph.defaultProps = {
  stroke: alpha(palette.grey.middle, 0.1),
  widgets: [],
  withAnimation: true,
  radius: 160,
  padding: 15,
};

export default memo(DistributionRoseGraph);
