import React, { memo } from 'react';
import PropTypes from 'prop-types';

import EditOtherProfileModalComponent from 'components/modals/EditOtherProfileModal';
import { useModal } from 'hooks/useModal';

/**
 * Modal for editing other profile
 */
const EditOtherProfileModal = ({ id, user, currentUserId }) => {
  const { onClose } = useModal(id);
  const isCurrentUser = currentUserId === user.id;
  return (
    <EditOtherProfileModalComponent
      onClose={onClose}
      user={user}
      isCurrentUser={isCurrentUser}
      modalId={id}
    />
  );
};

EditOtherProfileModal.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  currentUserId: PropTypes.number.isRequired,
};

export default memo(EditOtherProfileModal);
