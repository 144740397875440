import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  selectIsRunningProjectById,
  selectProjectNameById,
  selectProjectById,
  selectProjectOfferInstanceByProjectId,
  selectProjectsIsSuccess,
} from 'ducks/projects/selectors';
import Status from 'components/common/Status';
import {
  startUpdateProjectJob,
  stopUpdateProjectJob,
} from 'ducks/projects/actions';

import { useStyles } from './styles';

/**
 * Container render name and status from store by id
 * @returns {jsx}
 */
const ProjectHeader = () => {
  const { id: projectId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSuccess = useSelector(selectProjectsIsSuccess);
  const { status, taskInfo } = useSelector((state) =>
    selectProjectById(state, projectId)
  );
  const { name } = useSelector((state) => ({
    name: selectProjectNameById(state, projectId),
  }));
  const progressValue = taskInfo ? taskInfo.progress : 0;
  const isRunningProject = useSelector((state) =>
    selectIsRunningProjectById(state, projectId)
  );

  const { isMcDashboard } = useSelector((state) =>
    selectProjectOfferInstanceByProjectId(state, projectId)
  );

  useEffect(() => {
    if (isRunningProject) {
      dispatch(startUpdateProjectJob(projectId));
      return () => dispatch(stopUpdateProjectJob());
    }
  }, [dispatch, isRunningProject, projectId]);

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>{name}</h2>
      {isSuccess && !isMcDashboard && (
        <Status status={status} progressValue={progressValue} />
      )}
    </div>
  );
};

export default memo(ProjectHeader);
