import { useMemo } from 'react';

/**
 * useDependentsFilters - hook for filtration entities, by dependents values
 * @param {Array} entities - any array
 * @param {Object} filters - object with comparator function
 * @param {Object} expectedValues - values for comparation
 * @returns {Array}
 *
 * Example:
 * const entities = [{ id: 1, name: 'FirstName' }, { id: 1, name: 'SecondName' }];
 *
 * const filteredEntities = useDependentsFilters({
 *   entities,
 *   filters: {
 *     search: (entity, expectedValue) => entity.name === expectedValue,
 *   },
 *   expectedValues: { search: 'FirstName' },
 * });
 *
 * filteredEntities -> [{ id: 1, name: 'FirstName' }]
 */
export function useDependentsFilters({
  entities = [],
  filters,
  expectedValues,
}) {
  return useMemo(() => {
    if (!expectedValues || !filters) {
      return entities;
    }

    const filtersArray = Object.entries(filters);

    const filterFunction = (entity) =>
      filtersArray.every(([field, comparator]) =>
        comparator(entity, expectedValues[field])
      );

    return entities.filter(filterFunction);
  }, [filters, expectedValues, entities]);
}
