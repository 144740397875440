import {
  REQUEST_COMMENTS,
  REQUEST_COMMENTS_SUCCESS,
  REQUEST_COMMENTS_ERROR,
  POST_COMMENT,
  POST_COMMENT_ERROR,
  POST_COMMENT_SUCCESS,
} from './types';

export const requestComments = () => ({
  type: REQUEST_COMMENTS,
});

export const requestCommentsSuccess = (comments) => ({
  type: REQUEST_COMMENTS_SUCCESS,
  comments,
});

export const requestCommentsError = (error) => ({
  type: REQUEST_COMMENTS_ERROR,
  error,
});

export const postComment = ({ message, reportId, userId, reportTypeId }) => ({
  type: POST_COMMENT,
  payload: {
    message,
    reportId,
    userId,
    reportTypeId,
  },
});

export const postCommentSuccess = (comment) => ({
  type: POST_COMMENT_SUCCESS,
  comment,
});

export const postCommentError = (error) => ({
  type: POST_COMMENT_ERROR,
  error,
});
