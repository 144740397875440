import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 568,
    width: '100%',
  },
  body: {
    textAlign: 'center',
    padding: '10px 5px',
  },
  message: {
    paddingBottom: 10,
  },
  cancelButton: {
    ...theme.buttons.elevated.neutral,
  },
  confirmButton: {
    ...theme.buttons.fill.primary,
  },
}));
