import { PROJECT_CREATE_LABEL, PROJECT_OPEN_LABEL } from 'constants/tracker';

import { SEND_TRACKING_DATA } from '../types';

export const trackCreateProject = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: PROJECT_CREATE_LABEL,
  gtmAction: 'New Project',
});

export const trackOfferSelection = (offer) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: PROJECT_CREATE_LABEL,
  gtmAction: 'Select offer',
  offer,
});

export const trackZoneSelection = (zone) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: PROJECT_CREATE_LABEL,
  gtmAction: 'Select zone',
  zone,
});

export const trackProjectCreation = (data) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: PROJECT_CREATE_LABEL,
  gtmAction: 'Project created',
  data,
});

export const trackViewDatasource = (data) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: PROJECT_CREATE_LABEL,
  gtmAction: 'View datasources',
  data,
});

export const trackProjectOpen = (data) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: PROJECT_OPEN_LABEL,
  gtmAction: 'Open Project',
  data,
});

export const trackProjectRenameClick = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: 'ProjectAlteration',
  gtmAction: 'A - Project rename click',
});

export const trackProjectRenameConfirm = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: 'ProjectAlteration',
  gtmAction: 'B - Project rename confirm',
});

export const trackProjectDeleteClick = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: 'ProjectAlteration',
  gtmAction: '1 - Project delete click',
});

export const trackProjectDeleteConfirm = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: 'ProjectAlteration',
  gtmAction: '2 - Project delete confirm',
});
