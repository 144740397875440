export const weatherDowntimeData = {
  id: 'WIN-magw-WD_2985',
  point: {
    id: '2985',
    name: '#2985',
  },
  layer: 'WIND',
  type: 'WD',
  name: 'Weather downtime',
  variables: {
    names: ['magw'],
    variables: [
      {
        long_name: 'wind speed',
        units: 'm.s-1',
        hidden: false,
        name: 'magw',
      },
    ],
  },
  visualization: {
    kwargs: {
      ignoredDurationIdx: '3',
      ymax: 100,
      ylabel: 'magw downtime [days]',
      probabilityName: 'downtime',
      viewerTitle: 'magw weather windows at point #2985',
      durationName: 'duration',
      ignoreDimensions: true,
      ymin: 0,
      ignoredMagwLimitIdx: '0',
      quantileName: 'quantiles',
      vmax: 100,
      ignoredQuantileIdx: '0',
      ignoredDimensions: 'quantiles,duration,magw_limit',
      probabilityMaxName: 'downtime_max',
      vmin: 0,
      probabilityMinName: 'downtime_min',
    },
    driver: {
      path:
        // eslint-disable-next-line no-template-curly-in-string
        '/mnt/glusterfs/projects/dev-ma/MA/open-ocean/ENGIE-LNG/World/WIND/Visualization/WIN-magw-WD/2985_WIN-magw-WD_M${PER}__plot_bars_month.png',
      method: 'plot_bars_month',
      suffix: null,
      fileformat: '.png',
    },
    web: {
      'netcdf-uri-0':
        '/static/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2985/WIND/WIN-magw-WD_2985.nc',
      'plot_bars_month-image-uri-0':
        '/static/projects/dev-ma/MA/open-ocean/ENGIE-LNG/World/WIND/Visualization/WIN-magw-WD/2985_WIN-magw-WD_M%24%7BPER%7D__plot_bars_month.png',
      dataLegend0: 'Downtime due to wind speed at point #2985',
      'plot_bars_month-image-legend-0':
        'Downtime due to wind speed at point #2985',
      'excel-uri-0':
        '/static/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2985/WIND/WIN-magw-WD_2985.xlsx',
      'data-uri-0':
        '/api/projects/671/get_visu_data/?stats_id=WIN-magw-WD_2985',
      'matlab-uri-0':
        '/static/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2985/WIND/WIN-magw-WD_2985.mat',
    },
  },
  periodicity: 'month',
  quantiles: {
    values: ['average', '50', '80', '90'],
    dimensions: ['quantiles'],
    attributes: {
      description: 'quantile of the annual variations',
      vartype: 'S',
      units: '',
    },
  },
  duration: {
    values: [3, 6, 9, 12, 24, 36, 48, 72, 120, 240, 360],
    dimensions: ['duration'],
    attributes: {
      description: 'duration of the operation',
      vartype: 'i4',
      units: 'h',
    },
  },
  downtime: {
    values: [
      [
        [
          [
            [0.1175427685955891, 0, 0, 0, 0],
            [0.16610739074252995, 0, 0, 0, 0],
            [0.22415588306945206, 0, 0, 0, 0],
            [0.29249904634460017, 0, 0, 0, 0],
            [0.6448212517223938, 0, 0, 0, 0],
            [0.8334024362512927, 0, 0, 0, 0],
            [1.1559053313344418, 0, 0, 0, 0],
            [1.8721439742227877, 0, 0, 0, 0],
            [3.601048436455502, 0, 0, 0, 0],
            [11.941276725303123, 0, 0, 0, 0],
            [18.963476557561744, 0, 0, 0, 0],
          ],
          [
            [0.06978434571619813, 0, 0, 0, 0],
            [0.07241243708053692, 0, 0, 0, 0],
            [0.08428571055053548, 0, 0, 0, 0],
            [0.1333550347222222, 0, 0, 0, 0],
            [0.18346171142904952, 0, 0, 0, 0],
            [0.24763265520134226, 0, 0, 0, 0],
            [0.42355685763888884, 0, 0, 0, 0],
            [0.7312910496151271, 0, 0, 0, 0],
            [1.5706478256777108, 0, 0, 0, 0],
            [6.724966271335341, 0, 0, 0, 0],
            [31.00341796875, 0, 0, 0, 0],
          ],
          [
            [0.1634867213716443, 0, 0, 0, 0],
            [0.27430359458668, 0, 0, 0, 0],
            [0.2978613673443775, 0, 0, 0, 0],
            [0.5520889589450503, 0, 0, 0, 0],
            [0.7112891670850067, 0, 0, 0, 0],
            [0.8920809957005034, 0, 0, 0, 0],
            [1.6179470486111112, 0, 0, 0, 0],
            [2.6509161646586343, 0, 0, 0, 0],
            [6.0763684983221475, 0, 0, 0, 0],
            [31.00341796875, 0, 0, 0, 0],
            [31.0078125, 0, 0, 0, 0],
          ],
          [
            [0.28441565742135205, 0, 0, 0, 0],
            [0.4155976852314584, 0, 0, 0, 0],
            [0.5043808044678715, 0, 0, 0, 0],
            [0.7374027359437751, 0, 0, 0, 0],
            [1.8652866675033466, 0, 0, 0, 0],
            [2.2703803495230925, 0, 0, 0, 0],
            [2.2703803495230925, 0, 0, 0, 0],
            [6.0763684983221475, 0, 0, 0, 0],
            [8.155100872238956, 0, 0, 0, 0],
            [31.0113525390625, 0, 0, 0, 0],
            [31.0113525390625, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0.3010730425155989, 0.00020054785462202663, 0, 0, 0],
            [0.40696388049919585, 0.0003008282954911944, 0, 0, 0],
            [0.5317749062673189, 0.000366241517786169, 0, 0, 0],
            [0.6820218616361113, 0.000366241517786169, 0, 0, 0],
            [1.3202351007496145, 0.0009331561110092823, 0, 0, 0],
            [1.7280160865342304, 0.0009331561110092823, 0, 0, 0],
            [2.1994134562268224, 0.0009331561110092823, 0, 0, 0],
            [3.357182392449964, 0.0009331561110092823, 0, 0, 0],
            [7.138828149459696, 0.031919026165150244, 0, 0, 0],
            [15.638366260014134, 0.09574617163728268, 0, 0, 0],
            [25.400811268821787, 0.6071518521591669, 0, 0, 0],
          ],
          [
            [0.1512920170264391, 0, 0, 0, 0],
            [0.17268291917670683, 0, 0, 0, 0],
            [0.21763159408467203, 0, 0, 0, 0],
            [0.2798596730672691, 0, 0, 0, 0],
            [0.5742762717536813, 0, 0, 0, 0],
            [0.835927832686661, 0, 0, 0, 0],
            [1.0111507226823961, 0, 0, 0, 0],
            [2.0609161908048863, 0, 0, 0, 0],
            [4.305794140102075, 0, 0, 0, 0],
            [8.808884627049865, 0, 0, 0, 0],
            [31.00341796875, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [
              0.09396168780307391,
              0.0009988945570754365,
              0.000005998757904245709,
              0,
              0,
            ],
            [
              0.12420880057191622,
              0.0013678181681865476,
              0.000005998757904245709,
              0,
              0,
            ],
            [
              0.16015417115722158,
              0.0013678181681865476,
              0.000005998757904245709,
              0,
              0,
            ],
            [
              0.20013155601575527,
              0.0013678181681865476,
              0.000005998757904245709,
              0,
              0,
            ],
            [
              0.3189658928310388,
              0.0013678181681865476,
              0.000005998757904245709,
              0,
              0,
            ],
            [
              0.45738477474468475,
              0.0013678181681865476,
              0.000005998757904245709,
              0,
              0,
            ],
            [
              0.6639443419588967,
              0.0013678181681865476,
              0.000005998757904245709,
              0,
              0,
            ],
            [
              1.272641905330389,
              0.006154826975774625,
              0.000005998757904245709,
              0,
              0,
            ],
            [
              3.2721594518608,
              0.05280832971122475,
              0.000005998757904245709,
              0,
              0,
            ],
            [
              10.263933627284004,
              0.3357067530965003,
              0.000005998757904245709,
              0,
              0,
            ],
            [18.104346424883282, 1.4346792011543141, 0.10373652043812104, 0, 0],
          ],
          [
            [0.03164747071709585, 0, 0, 0, 0],
            [0.032957175925925924, 0, 0, 0, 0],
            [0.04562355324074074, 0, 0, 0, 0],
            [0.06382378472222222, 0, 0, 0, 0],
            [0.11665169657782318, 0, 0, 0, 0],
            [0.21992913984263232, 0, 0, 0, 0],
            [0.26906828703703706, 0, 0, 0, 0],
            [0.5600355418454936, 0, 0, 0, 0],
            [1.4398708534513591, 0, 0, 0, 0],
            [5.485104204795022, 0, 0, 0, 0],
            [28.0142822265625, 0, 0, 0, 0],
          ],
          [
            [0.13029152199074076, 0, 0, 0, 0],
            [0.2147750289351852, 0, 0, 0, 0],
            [0.24391709138054365, 0, 0, 0, 0],
            [0.3505565987124464, 0, 0, 0, 0],
            [0.4809018003285197, 0, 0, 0, 0],
            [0.7106083622685185, 0, 0, 0, 0],
            [0.9644892939814814, 0, 0, 0, 0],
            [2.0893988715277776, 0, 0, 0, 0],
            [3.6176021458638363, 0, 0, 0, 0],
            [28.013671875, 0, 0, 0, 0],
            [28.034820556640625, 0, 0, 0, 0],
          ],
          [
            [0.3096889870058565, 0.00024594907407407405, 0, 0, 0],
            [0.3465009608345534, 0.00024594907407407405, 0, 0, 0],
            [0.5308881875841613, 0.00024594907407407405, 0, 0, 0],
            [0.6576678240740741, 0.00024594907407407405, 0, 0, 0],
            [1.1100296585648148, 0.00024594907407407405, 0, 0, 0],
            [1.2909799380252833, 0.00024594907407407405, 0, 0, 0],
            [1.9479781539351853, 0.00024594907407407405, 0, 0, 0],
            [3.6176021458638363, 0.0008608217592592592, 0, 0, 0],
            [5.886952189067143, 0.0008608217592592592, 0, 0, 0],
            [28.01513671875, 0.1965133101851852, 0, 0, 0],
            [29.01123046875, 0.1965133101851852, 0, 0, 0],
          ],
        ],
        [
          [
            [
              0.19794433040881854,
              0.0027599332028627084,
              0.000008998136856368563,
              0,
              0,
            ],
            [
              0.2541292465090803,
              0.003716641454259725,
              0.000008998136856368563,
              0,
              0,
            ],
            [
              0.36157526853610333,
              0.003716641454259725,
              0.000008998136856368563,
              0,
              0,
            ],
            [
              0.49469557691913707,
              0.003716641454259725,
              0.000008998136856368563,
              0,
              0,
            ],
            [
              0.8409507537278871,
              0.0043105184867800505,
              0.000008998136856368563,
              0,
              0,
            ],
            [
              0.9497504857473633,
              0.01006947790268575,
              0.000008998136856368563,
              0,
              0,
            ],
            [
              1.5152554537682663,
              0.01319221814420987,
              0.000008998136856368563,
              0,
              0,
            ],
            [
              2.2983189815423173,
              0.034286964444450625,
              0.000008998136856368563,
              0,
              0,
            ],
            [
              6.549398120780448,
              0.07933913599481197,
              0.000008998136856368563,
              0,
              0,
            ],
            [
              16.717222780416964,
              0.5502117017049104,
              0.000008998136856368563,
              0,
              0,
            ],
            [24.427982194341876, 2.1850787471217523, 0.10373951981707319, 0, 0],
          ],
          [
            [0.09246780826290862, 0, 0, 0, 0],
            [0.12217520254629628, 0, 0, 0, 0],
            [0.15181206597222222, 0, 0, 0, 0],
            [0.3173357928240741, 0, 0, 0, 0],
            [0.42721354166666664, 0, 0, 0, 0],
            [0.6981879340277778, 0, 0, 0, 0],
            [0.7844772666309012, 0, 0, 0, 0],
            [1.1452003761574074, 0, 0, 0, 0],
            [3.952513039897511, 0, 0, 0, 0],
            [28.013671875, 0, 0, 0, 0],
            [28.01513671875, 0, 0, 0, 0],
          ],
          [
            [0.3111902326984979, 0.0004332770564403789, 0, 0, 0],
            [0.42062041756080115, 0.0004332770564403789, 0, 0, 0],
            [0.5387858352547769, 0.0004332770564403789, 0, 0, 0],
            [0.8074558241684548, 0.0004332770564403789, 0, 0, 0],
            [1.1774877894760372, 0.0004332770564403789, 0, 0, 0],
            [1.2876838128598627, 0.0004332770564403789, 0, 0, 0],
            [2.540776909722222, 0.0004332770564403789, 0, 0, 0],
            [3.938935908564815, 0.0004332770564403789, 0, 0, 0],
            [9.827379699979105, 0.0004332770564403789, 0, 0, 0],
            [28.01513671875, 0.21661964699074074, 0, 0, 0],
            [29.01123046875, 0.21661964699074074, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0.046931543272667756, 0.00010434586414764751, 0, 0, 0],
            [0.0633868921122458, 0.00010434586414764751, 0, 0, 0],
            [0.105423113550465, 0.00010434586414764751, 0, 0, 0],
            [0.12730382412332256, 0.00010434586414764751, 0, 0, 0],
            [0.2491448667461523, 0.00010434586414764751, 0, 0, 0],
            [0.3621856939023235, 0.00010434586414764751, 0, 0, 0],
            [0.4248881191436288, 0.00010434586414764751, 0, 0, 0],
            [0.843928407747684, 0.00010434586414764751, 0, 0, 0],
            [2.604001477991451, 0.00010434586414764751, 0, 0, 0],
            [9.419552080083731, 0.09641693361351912, 0, 0, 0],
            [15.853377079185446, 0.09641693361351912, 0, 0, 0],
          ],
          [
            [0.021390902150267736, 0, 0, 0, 0],
            [0.032503059111445784, 0, 0, 0, 0],
            [0.03672567875669344, 0, 0, 0, 0],
            [0.03672567875669344, 0, 0, 0, 0],
            [0.06130642361111111, 0, 0, 0, 0],
            [0.09539786751171353, 0, 0, 0, 0],
            [0.13956869143239625, 0, 0, 0, 0],
            [0.2107204861111111, 0, 0, 0, 0],
            [0.8536783854166666, 0, 0, 0, 0],
            [3.1443514945197455, 0, 0, 0, 0],
            [6.710242663361781, 0, 0, 0, 0],
          ],
          [
            [0.08373010270247658, 0, 0, 0, 0],
            [0.10728787546017404, 0, 0, 0, 0],
            [0.15206986801372155, 0, 0, 0, 0],
            [0.19424050368139223, 0, 0, 0, 0],
            [0.28952724962349397, 0, 0, 0, 0],
            [0.3596449600485274, 0, 0, 0, 0],
            [0.5277777777777778, 0, 0, 0, 0],
            [1.1682766221134537, 0, 0, 0, 0],
            [3.0096165913654622, 0, 0, 0, 0],
            [31.00341796875, 0, 0, 0, 0],
            [31.00341796875, 0, 0, 0, 0],
          ],
          [
            [0.10728787546017404, 0, 0, 0, 0],
            [0.1996298998075636, 0, 0, 0, 0],
            [0.2467990451388889, 0, 0, 0, 0],
            [0.2467990451388889, 0, 0, 0, 0],
            [0.8659879338821308, 0, 0, 0, 0],
            [0.9306431454986613, 0, 0, 0, 0],
            [0.9306431454986613, 0, 0, 0, 0],
            [1.7898220486111112, 0, 0, 0, 0],
            [4.508813247782798, 0, 0, 0, 0],
            [31.00341796875, 0, 0, 0, 0],
            [31.0113525390625, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0.11499690278991015, 0.0004310210872340235, 0, 0, 0],
            [0.1808841000958561, 0.0004310210872340235, 0, 0, 0],
            [0.22222701515202017, 0.0004310210872340235, 0, 0, 0],
            [0.29582250974664626, 0.0005400431243923146, 0, 0, 0],
            [0.5329220798958806, 0.0009384546056833322, 0, 0, 0],
            [0.6798011710390202, 0.0009384546056833322, 0, 0, 0],
            [0.8821533248070075, 0.0009384546056833322, 0, 0, 0],
            [1.9071188961220955, 0.0064023834919601464, 0, 0, 0],
            [5.747513307691849, 0.014126687721071715, 0, 0, 0],
            [14.837598569099368, 0.3872325381671845, 0, 0, 0],
            [23.437460822187827, 0.5263419320302349, 0, 0, 0],
          ],
          [
            [0.07698567708333333, 0, 0, 0, 0],
            [0.11473302062416332, 0, 0, 0, 0],
            [0.1265190972222222, 0, 0, 0, 0],
            [0.1335177951388889, 0, 0, 0, 0],
            [0.2863498263888889, 0, 0, 0, 0],
            [0.36724175347222227, 0, 0, 0, 0],
            [0.4569318942436412, 0, 0, 0, 0],
            [1.2010019243641232, 0, 0, 0, 0],
            [3.683720121447672, 0, 0, 0, 0],
            [8.805709941275168, 0, 0, 0, 0],
            [31.00341796875, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0.02416789484950802, 0.000008660050602461859, 0, 0, 0],
            [0.032639702645156574, 0.000008660050602461859, 0, 0, 0],
            [0.04342931048986024, 0.000008660050602461859, 0, 0, 0],
            [0.06754734937047631, 0.000008660050602461859, 0, 0, 0],
            [0.1415710081248547, 0.000008660050602461859, 0, 0, 0],
            [0.21986847909256638, 0.000008660050602461859, 0, 0, 0],
            [0.276740498464615, 0.000008660050602461859, 0, 0, 0],
            [0.4928386355876043, 0.000008660050602461859, 0, 0, 0],
            [0.8396240956563755, 0.000008660050602461859, 0, 0, 0],
            [6.392735800051719, 0.07503789512856493, 0, 0, 0],
            [12.62922380133513, 0.07503789512856493, 0, 0, 0],
          ],
          [
            [0.006507949897450755, 0, 0, 0, 0],
            [0.010173682856859531, 0, 0, 0, 0],
            [0.012175352818640498, 0, 0, 0, 0],
            [0.021042637848474023, 0, 0, 0, 0],
            [0.035380672490037526, 0, 0, 0, 0],
            [0.04040418013832676, 0, 0, 0, 0],
            [0.05559394825844097, 0, 0, 0, 0],
            [0.13863893906057267, 0, 0, 0, 0],
            [0.2258740937174481, 0, 0, 0, 0],
            [1.643990412889996, 0, 0, 0, 0],
            [4.3598037586160245, 0, 0, 0, 0],
          ],
          [
            [0.034146840841073424, 0, 0, 0, 0],
            [0.046681848634163206, 0, 0, 0, 0],
            [0.06484210257805595, 0, 0, 0, 0],
            [0.11413369771823806, 0, 0, 0, 0],
            [0.1806424511583679, 0, 0, 0, 0],
            [0.20606730223496722, 0, 0, 0, 0],
            [0.4402109103086809, 0, 0, 0, 0],
            [0.5015485876988245, 0, 0, 0, 0],
            [1.0085506623876235, 0, 0, 0, 0],
            [7.319050262577802, 0, 0, 0, 0],
            [30.00732421875, 0, 0, 0, 0],
          ],
          [
            [0.04815716037776626, 0, 0, 0, 0],
            [0.08238209662430845, 0, 0, 0, 0],
            [0.09760634898878709, 0, 0, 0, 0],
            [0.16794443724066396, 0, 0, 0, 0],
            [0.4373398989297781, 0, 0, 0, 0],
            [0.44684618783019314, 0, 0, 0, 0],
            [0.6684398639619191, 0, 0, 0, 0],
            [1.0162549462515311, 0, 0, 0, 0],
            [2.368493928851567, 0, 0, 0, 0],
            [30.00732421875, 0, 0, 0, 0],
            [30.0123291015625, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0.0665735449767005, 0.0001249629264609357, 0, 0, 0],
            [0.09148645424128841, 0.00021107061577628838, 0, 0, 0],
            [0.1631557366949002, 0.00021107061577628838, 0, 0, 0],
            [0.240068310767321, 0.00021107061577628838, 0, 0, 0],
            [0.49178926231706477, 0.0018198492778181276, 0, 0, 0],
            [0.6305288207607291, 0.0018198492778181276, 0, 0, 0],
            [0.7499353153608165, 0.0018198492778181276, 0, 0, 0],
            [1.206071637782002, 0.0018198492778181276, 0, 0, 0],
            [2.6800741620101354, 0.0018198492778181276, 0, 0, 0],
            [9.98184084246704, 0.23447551835124192, 0, 0, 0],
            [18.177348220426058, 0.23447551835124192, 0, 0, 0],
          ],
          [
            [0.0288708069126439, 0, 0, 0, 0],
            [0.046469449667185345, 0, 0, 0, 0],
            [0.05740013855172834, 0, 0, 0, 0],
            [0.07466300589139344, 0, 0, 0, 0],
            [0.17017749665024207, 0, 0, 0, 0],
            [0.21724839205323693, 0, 0, 0, 0],
            [0.21724839205323693, 0, 0, 0, 0],
            [0.38219689992198336, 0, 0, 0, 0],
            [1.0108521540219062, 0, 0, 0, 0],
            [5.847332738622869, 0, 0, 0, 0],
            [30.00732421875, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0.009086395974084395, 0, 0, 0, 0],
            [0.011502464005648572, 0, 0, 0, 0],
            [0.01352476195241677, 0, 0, 0, 0],
            [0.017565876106232114, 0, 0, 0, 0],
            [0.0431457628711592, 0, 0, 0, 0],
            [0.0741279776336277, 0, 0, 0, 0],
            [0.09237773864109436, 0, 0, 0, 0],
            [0.15011357002240822, 0, 0, 0, 0],
            [0.5391013828377424, 0, 0, 0, 0],
            [4.079402711698172, 0, 0, 0, 0],
            [8.080278202380184, 0, 0, 0, 0],
          ],
          [
            [0.0010834353037148595, 0, 0, 0, 0],
            [0.0010834353037148595, 0, 0, 0, 0],
            [0.00125011765813253, 0, 0, 0, 0],
            [0.00125011765813253, 0, 0, 0, 0],
            [0.00125011765813253, 0, 0, 0, 0],
            [0.002277992177041499, 0, 0, 0, 0],
            [0.005556078480589023, 0, 0, 0, 0],
            [0.0058061020122155286, 0, 0, 0, 0],
            [0.04492089451556225, 0, 0, 0, 0],
            [0.4826964106425703, 0, 0, 0, 0],
            [3.1486018945573964, 0, 0, 0, 0],
          ],
          [
            [0.005524197776845642, 0, 0, 0, 0],
            [0.00711805555555556, 0, 0, 0, 0],
            [0.008375167336010712, 0, 0, 0, 0],
            [0.010301884621820634, 0, 0, 0, 0],
            [0.058638852284136546, 0, 0, 0, 0],
            [0.1136893929886212, 0, 0, 0, 0],
            [0.12723785768072293, 0, 0, 0, 0],
            [0.2017718007446453, 0, 0, 0, 0],
            [1.0059625632002285, 0, 0, 0, 0],
            [5.014620057323116, 0, 0, 0, 0],
            [11.802855301414057, 0, 0, 0, 0],
          ],
          [
            [0.009704903730378741, 0, 0, 0, 0],
            [0.024054467002868864, 0, 0, 0, 0],
            [0.02858942279534807, 0, 0, 0, 0],
            [0.03362819768657969, 0, 0, 0, 0],
            [0.13266021523699675, 0, 0, 0, 0],
            [0.2300564236111113, 0, 0, 0, 0],
            [0.2300564236111113, 0, 0, 0, 0],
            [0.3673683115299916, 0, 0, 0, 0],
            [1.8505882906626512, 0, 0, 0, 0],
            [9.075415267842207, 0, 0, 0, 0],
            [31.0078125, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0.026048229190762023, 0.00002514320731963087, 0, 0, 0],
            [0.03986465949004921, 0.00002514320731963087, 0, 0, 0],
            [0.05321025343032022, 0.00002514320731963087, 0, 0, 0],
            [0.0740143657555562, 0.00002514320731963087, 0, 0, 0],
            [0.16848261974558826, 0.00002514320731963087, 0, 0, 0],
            [0.22651665875797394, 0.00002514320731963087, 0, 0, 0],
            [0.3585377588466852, 0.00002514320731963087, 0, 0, 0],
            [0.5480473052752861, 0.00002514320731963087, 0, 0, 0],
            [1.4750203041190515, 0.00002514320731963087, 0, 0, 0],
            [7.503418519071568, 0.00002514320731963087, 0, 0, 0],
            [14.35205948703603, 0.11149894970375421, 0, 0, 0],
          ],
          [
            [0.012183499623493976, 0, 0, 0, 0],
            [0.016223749163319947, 0, 0, 0, 0],
            [0.027808172795348057, 0, 0, 0, 0],
            [0.03828785872832774, 0, 0, 0, 0],
            [0.0743249860371997, 0, 0, 0, 0],
            [0.14042736471701261, 0, 0, 0, 0],
            [0.14781694909619603, 0, 0, 0, 0],
            [0.2695047769201807, 0, 0, 0, 0],
            [1.1031226631787567, 0, 0, 0, 0],
            [3.3664156241550725, 0, 0, 0, 0],
            [4.334824650163153, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0.0019263128292438202, 0, 0, 0, 0],
            [0.002843470784232271, 0, 0, 0, 0],
            [0.005086075602100777, 0, 0, 0, 0],
            [0.006281966717157056, 0, 0, 0, 0],
            [0.017711301895419584, 0, 0, 0, 0],
            [0.030473896580110117, 0, 0, 0, 0],
            [0.04199367028234905, 0, 0, 0, 0],
            [0.07892812848601433, 0, 0, 0, 0],
            [0.26532544354695825, 0, 0, 0, 0],
            [0.8688372506302722, 0, 0, 0, 0],
            [2.484046690483476, 0, 0, 0, 0],
          ],
          [
            [0.00020091794173582298, 0, 0, 0, 0],
            [0.00020091794173582298, 0, 0, 0, 0],
            [0.00020091794173582298, 0, 0, 0, 0],
            [0.00020091794173582298, 0, 0, 0, 0],
            [0.00020091794173582298, 0, 0, 0, 0],
            [0.0002598015180738557, 0, 0, 0, 0],
            [0.0002598015180738557, 0, 0, 0, 0],
            [0.0002598015180738557, 0, 0, 0, 0],
            [0.0002598015180738557, 0, 0, 0, 0],
            [0.006239807721480582, 0, 0, 0, 0],
            [0.006239807721480582, 0, 0, 0, 0],
          ],
          [
            [0.0014580902057399726, 0, 0, 0, 0],
            [0.0016303055843706789, 0, 0, 0, 0],
            [0.0016303055843706789, 0, 0, 0, 0],
            [0.008553363805325046, 0, 0, 0, 0],
            [0.023892013528699876, 0, 0, 0, 0],
            [0.03900104274723384, 0, 0, 0, 0],
            [0.0658083115642529, 0, 0, 0, 0],
            [0.0658083115642529, 0, 0, 0, 0],
            [0.1318406103718795, 0, 0, 0, 0],
            [1.2315831900870076, 0, 0, 0, 0],
            [2.297557611769678, 0, 0, 0, 0],
          ],
          [
            [0.005131961589954205, 0, 0, 0, 0],
            [0.010468198059336, 0, 0, 0, 0],
            [0.013185957490491017, 0, 0, 0, 0],
            [0.020443492788878297, 0, 0, 0, 0],
            [0.07131885629090456, 0, 0, 0, 0],
            [0.08853288669030647, 0, 0, 0, 0],
            [0.1261365237083912, 0, 0, 0, 0],
            [0.1775732270505686, 0, 0, 0, 0],
            [0.8957782919476145, 0, 0, 0, 0],
            [2.5058683636067864, 0, 0, 0, 0],
            [4.936738027036665, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0.013748211967922919, 0, 0, 0, 0],
            [0.016673790670295723, 0, 0, 0, 0],
            [0.01990876344547999, 0, 0, 0, 0],
            [0.02519240957739623, 0, 0, 0, 0],
            [0.08290590305382735, 0, 0, 0, 0],
            [0.1491250853383702, 0, 0, 0, 0],
            [0.1746669333945313, 0, 0, 0, 0],
            [0.3119084870209651, 0, 0, 0, 0],
            [0.8823887432643553, 0, 0, 0, 0],
            [3.3299148387813666, 0, 0, 0, 0],
            [6.805255391703378, 0, 0, 0, 0],
          ],
          [
            [0.006745102329702628, 0, 0, 0, 0],
            [0.007254072748751637, 0, 0, 0, 0],
            [0.008345427235691793, 0, 0, 0, 0],
            [0.008775965682268558, 0, 0, 0, 0],
            [0.028557522438595447, 0, 0, 0, 0],
            [0.028557522438595447, 0, 0, 0, 0],
            [0.029334019493430154, 0, 0, 0, 0],
            [0.06928798733575381, 0, 0, 0, 0],
            [0.2495439481324322, 0, 0, 0, 0],
            [1.3786849826739838, 0, 0, 0, 0],
            [2.0920891270172364, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0.004749942033518758, 0.000025002353162650605, 0, 0, 0],
            [0.006191835538221292, 0.000025002353162650605, 0, 0, 0],
            [0.008946329201492947, 0.000025002353162650605, 0, 0, 0],
            [0.011937705689512978, 0.000025002353162650605, 0, 0, 0],
            [0.0387290495015182, 0.000025002353162650605, 0, 0, 0],
            [0.04833010742711741, 0.000025002353162650605, 0, 0, 0],
            [0.08045283378049349, 0.000025002353162650605, 0, 0, 0],
            [0.12955462159998485, 0.000025002353162650605, 0, 0, 0],
            [0.6832129053912162, 0.000025002353162650605, 0, 0, 0],
            [4.802918721121554, 0.000025002353162650605, 0, 0, 0],
            [10.186785367921127, 0.000025002353162650605, 0, 0, 0],
          ],
          [
            [0.002861380417503347, 0, 0, 0, 0],
            [0.003817025916164659, 0, 0, 0, 0],
            [0.004358155935402685, 0, 0, 0, 0],
            [0.004358155935402685, 0, 0, 0, 0],
            [0.004358155935402685, 0, 0, 0, 0],
            [0.00852858046770415, 0, 0, 0, 0],
            [0.00852858046770415, 0, 0, 0, 0],
            [0.013723513847054886, 0, 0, 0, 0],
            [0.035197757174531455, 0, 0, 0, 0],
            [1.084717728993259, 0, 0, 0, 0],
            [4.037702204186924, 0, 0, 0, 0],
          ],
          [
            [0.008725527108433738, 0, 0, 0, 0],
            [0.01037901606425703, 0, 0, 0, 0],
            [0.014928725317938422, 0, 0, 0, 0],
            [0.021552093791834016, 0, 0, 0, 0],
            [0.034703266189759045, 0, 0, 0, 0],
            [0.06421715507864793, 0, 0, 0, 0],
            [0.12985425842421727, 0, 0, 0, 0],
            [0.21310175598226241, 0, 0, 0, 0],
            [0.9866120732931729, 0, 0, 0, 0],
            [7.2358921362533515, 0, 0, 0, 0],
            [31.00341796875, 0, 0, 0, 0],
          ],
          [
            [0.010862133429551541, 0, 0, 0, 0],
            [0.014664069668284437, 0, 0, 0, 0],
            [0.021518691955321297, 0, 0, 0, 0],
            [0.029669459086345388, 0, 0, 0, 0],
            [0.06634765170950162, 0, 0, 0, 0],
            [0.09913195582310895, 0, 0, 0, 0],
            [0.24486700011560503, 0, 0, 0, 0],
            [0.30704540323802215, 0, 0, 0, 0],
            [1.135729114374164, 0, 0, 0, 0],
            [12.307534303882196, 0, 0, 0, 0],
            [31.003857421875, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0.034777151611790943, 0.0000375035297439759, 0, 0, 0],
            [0.05539495194966019, 0.0000722290202476573, 0, 0, 0],
            [0.08163611187071236, 0.0000722290202476573, 0, 0, 0],
            [0.12199176319919816, 0.0000722290202476573, 0, 0, 0],
            [0.22730235881107452, 0.0000722290202476573, 0, 0, 0],
            [0.37101953314077757, 0.0000722290202476573, 0, 0, 0],
            [0.6005270760388142, 0.0000722290202476573, 0, 0, 0],
            [1.1207117187459419, 0.0000722290202476573, 0, 0, 0],
            [3.033437151522231, 0.0000722290202476573, 0, 0, 0],
            [12.916285052056802, 0.0000722290202476573, 0, 0, 0],
            [19.112702925180233, 0.0000722290202476573, 0, 0, 0],
          ],
          [
            [0.025244852929396204, 0, 0, 0, 0],
            [0.028460061144325623, 0, 0, 0, 0],
            [0.04041883680555555, 0, 0, 0, 0],
            [0.06250327321678352, 0, 0, 0, 0],
            [0.14106883262215528, 0, 0, 0, 0],
            [0.14106883262215528, 0, 0, 0, 0],
            [0.2251323000334672, 0, 0, 0, 0],
            [0.4136988361285606, 0, 0, 0, 0],
            [0.8307046545557228, 0, 0, 0, 0],
            [7.0971793974806, 0, 0, 0, 0],
            [31.00341796875, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [
              0.010331283993910924,
              0.0006162181277192101,
              0.00011237985797356092,
              0,
              0,
            ],
            [
              0.013002867448947162,
              0.0006162181277192101,
              0.00011237985797356092,
              0,
              0,
            ],
            [
              0.013555697257765767,
              0.0006162181277192101,
              0.00011237985797356092,
              0,
              0,
            ],
            [
              0.015081400022289679,
              0.0006162181277192101,
              0.00011237985797356092,
              0,
              0,
            ],
            [
              0.022135699599215252,
              0.0006162181277192101,
              0.00011237985797356092,
              0,
              0,
            ],
            [
              0.024305348245885268,
              0.0006162181277192101,
              0.00011237985797356092,
              0,
              0,
            ],
            [
              0.03809414601508707,
              0.0006162181277192101,
              0.00011237985797356092,
              0,
              0,
            ],
            [
              0.0787580795276433,
              0.0006162181277192101,
              0.00011237985797356092,
              0,
              0,
            ],
            [
              0.3755389624316377,
              0.021201488898301542,
              0.018475219236320277,
              0,
              0,
            ],
            [
              2.423368639636112,
              0.17126600477430554,
              0.044869533472431396,
              0,
              0,
            ],
            [
              5.663501136462896,
              0.17126600477430554,
              0.044869533472431396,
              0,
              0,
            ],
          ],
          [
            [0.0004118848967072148, 0, 0, 0, 0],
            [0.0004118848967072148, 0, 0, 0, 0],
            [0.0004118848967072148, 0, 0, 0, 0],
            [0.0004118848967072148, 0, 0, 0, 0],
            [0.0004118848967072148, 0, 0, 0, 0],
            [0.0004118848967072148, 0, 0, 0, 0],
            [0.0004118848967072148, 0, 0, 0, 0],
            [0.0004118848967072148, 0, 0, 0, 0],
            [0.0004118848967072148, 0, 0, 0, 0],
            [0.0013275759287148595, 0, 0, 0, 0],
            [1.3470003059111444, 0, 0, 0, 0],
          ],
          [
            [0.012037501849540222, 0, 0, 0, 0],
            [0.01473340351759387, 0, 0, 0, 0],
            [0.01473340351759387, 0, 0, 0, 0],
            [0.022741094586680066, 0, 0, 0, 0],
            [0.026192949317544244, 0, 0, 0, 0],
            [0.03142044916944063, 0, 0, 0, 0],
            [0.03142044916944063, 0, 0, 0, 0],
            [0.041950078172028255, 0, 0, 0, 0],
            [0.4965062071201482, 0, 0, 0, 0],
            [4.478713034859308, 0, 0, 0, 0],
            [5.652983008832029, 0, 0, 0, 0],
          ],
          [
            [0.03273085832914994, 0.000550051769578314, 0, 0, 0],
            [0.03356441813355037, 0.000550051769578314, 0, 0, 0],
            [0.03356441813355037, 0.000550051769578314, 0, 0, 0],
            [0.04551019064087553, 0.000550051769578314, 0, 0, 0],
            [0.04664461113535346, 0.000550051769578314, 0, 0, 0],
            [0.07362915202476579, 0.000550051769578314, 0, 0, 0],
            [0.07362915202476579, 0.000550051769578314, 0, 0, 0],
            [0.23536886944866822, 0.000550051769578314, 0, 0, 0],
            [1.1373961446535938, 0.000550051769578314, 0, 0, 0],
            [7.064770475129685, 0.8614195061495987, 0, 0, 0],
            [31.00341796875, 0.8614195061495987, 0, 0, 0],
          ],
        ],
        [
          [
            [
              0.03263537759068774,
              0.0012720832239665456,
              0.0003218276752844712,
              0,
              0,
            ],
            [
              0.038423654358810025,
              0.0013054196948500795,
              0.0003496080676874163,
              0,
              0,
            ],
            [
              0.046668279684208024,
              0.0013054196948500795,
              0.0003496080676874163,
              0,
              0,
            ],
            [
              0.064539754923813,
              0.0014609898923065722,
              0.0003496080676874163,
              0,
              0,
            ],
            [
              0.1356187267585705,
              0.002251342056170361,
              0.0003496080676874163,
              0,
              0,
            ],
            [
              0.21861789372736679,
              0.002251342056170361,
              0.0003496080676874163,
              0,
              0,
            ],
            [
              0.31801646476517964,
              0.002251342056170361,
              0.0003496080676874163,
              0,
              0,
            ],
            [
              0.6555632049737943,
              0.002251342056170361,
              0.0003496080676874163,
              0,
              0,
            ],
            [
              1.4652423295282158,
              0.04038409768807297,
              0.021096005114206826,
              0,
              0,
            ],
            [9.684304466392533, 0.21434125444008098, 0.04730043219754017, 0, 0],
            [
              14.384203056563777,
              0.40562036829131926,
              0.04730043219754017,
              0,
              0,
            ],
          ],
          [
            [0.01676808703371231, 0, 0, 0, 0],
            [0.022018412190428382, 0, 0, 0, 0],
            [0.03238932291666667, 0, 0, 0, 0],
            [0.035698784722222224, 0, 0, 0, 0],
            [0.04029333479752343, 0, 0, 0, 0],
            [0.047071750543842036, 0, 0, 0, 0],
            [0.11906676183902275, 0, 0, 0, 0],
            [0.25912391383820377, 0, 0, 0, 0],
            [0.6838019395289492, 0, 0, 0, 0],
            [4.576597405245984, 0, 0, 0, 0],
            [5.188600821135317, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [
              0.041816036905895845,
              0.00394738076997608,
              0.0007138756248754717,
              0.000048794357278699865,
              0,
            ],
            [
              0.050688690426297645,
              0.00394738076997608,
              0.0007138756248754717,
              0.00008323743300484094,
              0,
            ],
            [
              0.05637299364198921,
              0.00394738076997608,
              0.0007138756248754717,
              0.00008323743300484094,
              0,
            ],
            [
              0.07251540806534632,
              0.004101469525099031,
              0.0007138756248754717,
              0.00008323743300484094,
              0,
            ],
            [
              0.09457146747569815,
              0.005190453349629817,
              0.0007138756248754717,
              0.00008323743300484094,
              0,
            ],
            [
              0.13116554342126457,
              0.005190453349629817,
              0.001539074314147602,
              0.00008323743300484094,
              0,
            ],
            [
              0.151604578833694,
              0.005190453349629817,
              0.001539074314147602,
              0.00008323743300484094,
              0,
            ],
            [
              0.31963955257526033,
              0.021708453080319826,
              0.001539074314147602,
              0.00008323743300484094,
              0,
            ],
            [
              0.819651824931263,
              0.06743514118552774,
              0.04980158011284948,
              0.00008323743300484094,
              0,
            ],
            [
              3.021508594129171,
              0.3298504293209543,
              0.11761138544868972,
              0.06952047809690524,
              0,
            ],
            [
              8.607273006092267,
              1.1640822537057545,
              0.3561167686988195,
              0.06952047809690524,
              0,
            ],
          ],
          [
            [0.02224448640646611, 0, 0, 0, 0],
            [0.03573003554888556, 0, 0, 0, 0],
            [0.03652917962841351, 0, 0, 0, 0],
            [0.044926702123438314, 0, 0, 0, 0],
            [0.06757449690745243, 0, 0, 0, 0],
            [0.08347825094827435, 0, 0, 0, 0],
            [0.08714098158713693, 0, 0, 0, 0],
            [0.10574024247925312, 0, 0, 0, 0],
            [0.13599370363228203, 0, 0, 0, 0],
            [1.3641311677677304, 0, 0, 0, 0],
            [3.14330379333074, 0, 0, 0, 0],
          ],
          [
            [0.0710030332935118, 0.007703767937413557, 0, 0, 0],
            [0.07774612395273565, 0.007703767937413557, 0, 0, 0],
            [0.07774612395273565, 0.007703767937413557, 0, 0, 0],
            [0.09063121326071927, 0.007703767937413557, 0, 0, 0],
            [0.12190342947075714, 0.008783883788907071, 0, 0, 0],
            [0.21064684516926308, 0.008783883788907071, 0, 0, 0],
            [0.21064684516926308, 0.008783883788907071, 0, 0, 0],
            [0.5126507391078841, 0.01381755891393444, 0, 0, 0],
            [1.3909147270487559, 0.019409692846149962, 0, 0, 0],
            [4.1981750945269525, 0.1568284649514995, 0, 0, 0],
            [30.00732421875, 2.759716999481329, 0, 0, 0],
          ],
          [
            [
              0.09803073402921857,
              0.012987357896571664,
              0.0011726222337745005,
              0,
              0,
            ],
            [
              0.1158435446922545,
              0.012987357896571664,
              0.0011726222337745005,
              0,
              0,
            ],
            [
              0.12868507142548416,
              0.012987357896571664,
              0.0011726222337745005,
              0,
              0,
            ],
            [
              0.17071626783998536,
              0.012987357896571664,
              0.0011726222337745005,
              0,
              0,
            ],
            [
              0.18008956948967478,
              0.016675204918032788,
              0.0011726222337745005,
              0,
              0,
            ],
            [
              0.3459936923668034,
              0.016675204918032788,
              0.002615381216862469,
              0,
              0,
            ],
            [
              0.41728679702911375,
              0.016675204918032788,
              0.002615381216862469,
              0,
              0,
            ],
            [
              0.8646452356557378,
              0.0326348142505187,
              0.002615381216862469,
              0,
              0,
            ],
            [
              2.482167643915593,
              0.17885236289896783,
              0.012738197506051208,
              0,
              0,
            ],
            [6.990038522216461, 0.919484898557047, 0.06548202746801568, 0, 0],
            [30.00732421875, 4.358425573462265, 0.7609893366540295, 0, 0],
          ],
        ],
        [
          [
            [
              0.08151300893576933,
              0.007331269853091496,
              0.0015458652202978965,
              0.00024331968187396553,
              0.000012916153397302904,
            ],
            [
              0.1053683690591162,
              0.007833564707431054,
              0.0015458652202978965,
              0.000292347922140359,
              0.000012916153397302904,
            ],
            [
              0.12464658282012006,
              0.007833564707431054,
              0.0015458652202978965,
              0.000292347922140359,
              0.000012916153397302904,
            ],
            [
              0.14158244065129721,
              0.008508753616242528,
              0.0015458652202978965,
              0.000292347922140359,
              0.000012916153397302904,
            ],
            [
              0.24923930239633202,
              0.009201920515231119,
              0.0018386313639700957,
              0.000292347922140359,
              0.000012916153397302904,
            ],
            [
              0.35144278451042116,
              0.01043039021613015,
              0.0026638300532422256,
              0.000292347922140359,
              0.000012916153397302904,
            ],
            [
              0.39354021769417563,
              0.014849157369945234,
              0.0026638300532422256,
              0.000292347922140359,
              0.000012916153397302904,
            ],
            [
              0.6562063936198422,
              0.0375736510506904,
              0.0026638300532422256,
              0.000292347922140359,
              0.000012916153397302904,
            ],
            [
              1.4937064405095073,
              0.0736207355305741,
              0.048812213249359825,
              0.031086160953743936,
              0.000012916153397302904,
            ],
            [
              6.014654610652092,
              0.49230419286474325,
              0.11627471757162815,
              0.09921887012451676,
              0.06604316257672026,
            ],
            [
              14.699733457735999,
              2.1074783683212033,
              0.368443955987949,
              0.23272166676719483,
              0.06604316257672026,
            ],
          ],
          [
            [0.06663320719876412, 0.00028702563105117567, 0, 0, 0],
            [0.07250999842205921, 0.00028702563105117567, 0, 0, 0],
            [0.09160525148515974, 0.00028702563105117567, 0, 0, 0],
            [0.09866608200901866, 0.00028702563105117567, 0, 0, 0],
            [0.1252151528393946, 0.00028702563105117567, 0, 0, 0],
            [0.15858166115577454, 0.00028702563105117567, 0, 0, 0],
            [0.21014731483145463, 0.00028702563105117567, 0, 0, 0],
            [0.284025637844061, 0.00028702563105117567, 0, 0, 0],
            [0.7035736623022385, 0.00028702563105117567, 0, 0, 0],
            [3.447034316109094, 0.00028702563105117567, 0, 0, 0],
            [5.500439708195637, 0.05060375566338546, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [
              0.007887732286615469,
              0.00008919773160242252,
              0.000008334117720883533,
              0,
              0,
            ],
            [
              0.010255198919238491,
              0.00008919773160242252,
              0.000008334117720883533,
              0,
              0,
            ],
            [
              0.010642254100428853,
              0.00008919773160242252,
              0.000008334117720883533,
              0,
              0,
            ],
            [
              0.01177708313008916,
              0.00008919773160242252,
              0.000008334117720883533,
              0,
              0,
            ],
            [
              0.022009840878263527,
              0.00008919773160242252,
              0.000008334117720883533,
              0,
              0,
            ],
            [
              0.028963943094419818,
              0.0023010314038378983,
              0.000008334117720883533,
              0,
              0,
            ],
            [
              0.039193026735770034,
              0.0023010314038378983,
              0.000008334117720883533,
              0,
              0,
            ],
            [
              0.08378300598938151,
              0.0023010314038378983,
              0.000008334117720883533,
              0,
              0,
            ],
            [
              0.2527126847261013,
              0.010750437753193657,
              0.000008334117720883533,
              0,
              0,
            ],
            [
              1.3524247109623544,
              0.010750437753193657,
              0.000008334117720883533,
              0,
              0,
            ],
            [4.8227617228539525, 0.9522164420111272, 0.16725879756003179, 0, 0],
          ],
          [
            [0.0013694199939702182, 0, 0, 0, 0],
            [0.0013694199939702182, 0, 0, 0, 0],
            [0.0013694199939702182, 0, 0, 0, 0],
            [0.0015601266299380269, 0, 0, 0, 0],
            [0.0031114039491298533, 0, 0, 0, 0],
            [0.00477822749330656, 0, 0, 0, 0],
            [0.00477822749330656, 0, 0, 0, 0],
            [0.006606802673852569, 0, 0, 0, 0],
            [0.01195699192533557, 0, 0, 0, 0],
            [0.34878282661897597, 0, 0, 0, 0],
            [1.8012593723783556, 0, 0, 0, 0],
          ],
          [
            [0.009813568012963491, 0, 0, 0, 0],
            [0.015868157508389262, 0, 0, 0, 0],
            [0.01661912067312876, 0, 0, 0, 0],
            [0.01671743917785235, 0, 0, 0, 0],
            [0.04550886514984716, 0, 0, 0, 0],
            [0.05539410245147259, 0, 0, 0, 0],
            [0.05539410245147259, 0, 0, 0, 0],
            [0.11988725808663239, 0, 0, 0, 0],
            [0.32949584797523446, 0, 0, 0, 0],
            [2.259212762508367, 0, 0, 0, 0],
            [4.409250136568103, 0, 0, 0, 0],
          ],
          [
            [0.01667802849071938, 0, 0, 0, 0],
            [0.021422009613439354, 0, 0, 0, 0],
            [0.021422009613439354, 0, 0, 0, 0],
            [0.031838492938290074, 0, 0, 0, 0],
            [0.07327413137589511, 0, 0, 0, 0],
            [0.08792054947552111, 0, 0, 0, 0],
            [0.09607506931750792, 0, 0, 0, 0],
            [0.30995949945615797, 0, 0, 0, 0],
            [0.7041691876097815, 0, 0, 0, 0],
            [3.02404193597306, 0, 0, 0, 0],
            [9.831525320030154, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [
              0.022545618146410006,
              0.00024697159730761425,
              0.000018057255061914325,
              0,
              0,
            ],
            [
              0.029025745948823446,
              0.00030987569919781974,
              0.000018057255061914325,
              0,
              0,
            ],
            [
              0.03695972003027228,
              0.00030987569919781974,
              0.000018057255061914325,
              0,
              0,
            ],
            [
              0.04191783111662781,
              0.00030987569919781974,
              0.000018057255061914325,
              0,
              0,
            ],
            [
              0.08434599612819531,
              0.00030987569919781974,
              0.000018057255061914325,
              0,
              0,
            ],
            [
              0.11590190874788724,
              0.001831035645716276,
              0.000018057255061914325,
              0,
              0,
            ],
            [
              0.1886912895565131,
              0.001831035645716276,
              0.000018057255061914325,
              0,
              0,
            ],
            [
              0.3130411671334809,
              0.001831035645716276,
              0.000018057255061914325,
              0,
              0,
            ],
            [
              0.8569787414014771,
              0.010276274936211593,
              0.000018057255061914325,
              0,
              0,
            ],
            [
              5.305230043598321,
              0.16695274305833302,
              0.000018057255061914325,
              0,
              0,
            ],
            [11.721752901020864, 1.1084708594499593, 0.16726713167775267, 0, 0],
          ],
          [
            [0.013251247176204819, 0, 0, 0, 0],
            [0.014751388365963857, 0, 0, 0, 0],
            [0.016362651125334672, 0, 0, 0, 0],
            [0.020571496599860142, 0, 0, 0, 0],
            [0.04408094618055555, 0, 0, 0, 0],
            [0.0830633732848059, 0, 0, 0, 0],
            [0.12581399140100452, 0, 0, 0, 0],
            [0.2278629777078111, 0, 0, 0, 0],
            [0.268247469042838, 0, 0, 0, 0],
            [2.3383072114511947, 0, 0, 0, 0],
            [3.7446674719712183, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0.042541045954358696, 0.00008201585875693361, 0, 0, 0],
            [0.04828178619821848, 0.00008201585875693361, 0, 0, 0],
            [0.06074967558458827, 0.00008201585875693361, 0, 0, 0],
            [0.0657426893329851, 0.00008201585875693361, 0, 0, 0],
            [0.11468960331271245, 0.00008201585875693361, 0, 0, 0],
            [0.20374002978809233, 0.0017505189414979184, 0, 0, 0],
            [0.33277022223411257, 0.004655218327735815, 0, 0, 0],
            [0.5954612842122129, 0.004655218327735815, 0, 0, 0],
            [1.7316934834749271, 0.017679934433838453, 0, 0, 0],
            [7.889713140783982, 0.017679934433838453, 0, 0, 0],
            [14.216807624223051, 0.5075845618571282, 0, 0, 0],
          ],
          [
            [0.017547003197444455, 0, 0, 0, 0],
            [0.02319794846368161, 0, 0, 0, 0],
            [0.02436847607624481, 0, 0, 0, 0],
            [0.02522955296939834, 0, 0, 0, 0],
            [0.04055672166753112, 0, 0, 0, 0],
            [0.04584561048178132, 0, 0, 0, 0],
            [0.07691991306217831, 0, 0, 0, 0],
            [0.14328155645591004, 0, 0, 0, 0],
            [0.9994099652717581, 0, 0, 0, 0],
            [4.520215001106825, 0, 0, 0, 0],
            [5.0394679245820075, 0, 0, 0, 0],
          ],
          [
            [0.05154078562232512, 0, 0, 0, 0],
            [0.05686700609848982, 0, 0, 0, 0],
            [0.06685559065100558, 0, 0, 0, 0],
            [0.10415782937847098, 0, 0, 0, 0],
            [0.2149662438574328, 0, 0, 0, 0],
            [0.2274635867252651, 0, 0, 0, 0],
            [0.3714936064674208, 0, 0, 0, 0],
            [0.9042068291794604, 0, 0, 0, 0],
            [2.865028917405386, 0, 0, 0, 0],
            [9.02238436780803, 0, 0, 0, 0],
            [30.0123291015625, 0, 0, 0, 0],
          ],
          [
            [0.11446608086757294, 0.00034640202409847435, 0, 0, 0],
            [0.12898399769529398, 0.00034640202409847435, 0, 0, 0],
            [0.16862122874037588, 0.00034640202409847435, 0, 0, 0],
            [0.1848593819166054, 0.00034640202409847435, 0, 0, 0],
            [0.33618576250127546, 0.00034640202409847435, 0, 0, 0],
            [0.49701830917576634, 0.00034640202409847435, 0, 0, 0],
            [0.8108374423668034, 0.00034640202409847435, 0, 0, 0],
            [1.8761551981106601, 0.00034640202409847435, 0, 0, 0],
            [4.28297924500778, 0.0003863884857619331, 0, 0, 0],
            [30.00732421875, 0.0003863884857619331, 0, 0, 0],
            [30.01343994140625, 0.8157652200177747, 0, 0, 0],
          ],
        ],
        [
          [
            [0.108205853812595, 0.0008515620252702621, 0, 0, 0],
            [0.15955536972331136, 0.0010175463284841147, 0, 0, 0],
            [0.2071652242714273, 0.0010175463284841147, 0, 0, 0],
            [0.2406524372608682, 0.0010175463284841147, 0, 0, 0],
            [0.43362729915158493, 0.003296282250694309, 0, 0, 0],
            [0.653219548909585, 0.003296282250694309, 0, 0, 0],
            [0.7656574843463069, 0.006073255231114433, 0, 0, 0],
            [1.2873850353586462, 0.010309295190130827, 0, 0, 0],
            [3.8166663656566846, 0.022260165021528194, 0, 0, 0],
            [14.360432616730765, 0.11124759846380398, 0, 0, 0],
            [23.123299646941085, 0.8331787765115533, 0, 0, 0],
          ],
          [
            [0.04622191147989925, 0, 0, 0, 0],
            [0.061854023491528354, 0, 0, 0, 0],
            [0.07573459705953269, 0, 0, 0, 0],
            [0.12324955882748906, 0, 0, 0, 0],
            [0.2043018032139272, 0, 0, 0, 0],
            [0.342536388096473, 0, 0, 0, 0],
            [0.5138088363624131, 0, 0, 0, 0],
            [0.7263713504605538, 0, 0, 0, 0],
            [3.4950193334676323, 0, 0, 0, 0],
            [7.976900658635541, 0, 0, 0, 0],
            [30.00732421875, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0.09138746835848952, 0.00015085924391778523, 0, 0, 0],
            [0.1309078106912091, 0.00015085924391778523, 0, 0, 0],
            [0.17776932783038202, 0.00015085924391778523, 0, 0, 0],
            [0.2219970372920841, 0.00015085924391778523, 0, 0, 0],
            [0.38803643670846044, 0.00015085924391778523, 0, 0, 0],
            [0.49833308538983995, 0.00015085924391778523, 0, 0, 0],
            [0.6890414104733115, 0.00015085924391778523, 0, 0, 0],
            [1.2198077117311361, 0.00015085924391778523, 0, 0, 0],
            [3.2117033207920542, 0.00015085924391778523, 0, 0, 0],
            [10.895760439177291, 0.00015085924391778523, 0, 0, 0],
            [20.51369649224767, 0.17317523725880873, 0, 0, 0],
          ],
          [
            [0.06648981491191275, 0, 0, 0, 0],
            [0.08650814194277108, 0, 0, 0, 0],
            [0.10759219786900678, 0, 0, 0, 0],
            [0.1325147595590696, 0, 0, 0, 0],
            [0.1900828476009042, 0, 0, 0, 0],
            [0.22226542385811424, 0, 0, 0, 0],
            [0.3307919176706827, 0, 0, 0, 0],
            [0.7832681638010375, 0, 0, 0, 0],
            [1.8835246585299532, 0, 0, 0, 0],
            [6.989343828623007, 0, 0, 0, 0],
            [31.00341796875, 0, 0, 0, 0],
          ],
          [
            [0.11838107638888892, 0, 0, 0, 0],
            [0.15455920328229866, 0, 0, 0, 0],
            [0.2205429792085007, 0, 0, 0, 0],
            [0.28922722138554224, 0, 0, 0, 0],
            [0.7149895153530795, 0, 0, 0, 0],
            [0.8789940341207005, 0, 0, 0, 0],
            [1.1644873765896924, 0, 0, 0, 0],
            [1.8781434331074298, 0, 0, 0, 0],
            [5.3682025407014695, 0, 0, 0, 0],
            [31.00341796875, 0, 0, 0, 0],
            [31.0078125, 0, 0, 0, 0],
          ],
          [
            [0.1424911887131861, 0, 0, 0, 0],
            [0.17067161876673365, 0, 0, 0, 0],
            [0.2989336904911313, 0, 0, 0, 0],
            [0.4665161296226573, 0, 0, 0, 0],
            [0.8943488122312815, 0, 0, 0, 0],
            [1.1547642392486615, 0, 0, 0, 0],
            [1.8462842468737157, 0, 0, 0, 0],
            [2.938091951137885, 0, 0, 0, 0],
            [8.21979794176707, 0, 0, 0, 0],
            [31.00341796875, 0, 0, 0, 0],
            [31.0113525390625, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [
              0.23027107188299084,
              0.0006477017517163874,
              0.00001815898306417785,
              0,
              0,
            ],
            [
              0.3124247866302116,
              0.0006477017517163874,
              0.00001815898306417785,
              0,
              0,
            ],
            [
              0.3895621300788214,
              0.0008060499884131745,
              0.00001815898306417785,
              0,
              0,
            ],
            [
              0.46035434890474025,
              0.0009435629308077528,
              0.00001815898306417785,
              0,
              0,
            ],
            [
              0.6676748066565454,
              0.0014311088174794396,
              0.00001815898306417785,
              0,
              0,
            ],
            [
              1.0838642605153592,
              0.004806426494437272,
              0.00001815898306417785,
              0,
              0,
            ],
            [
              1.3373143195848372,
              0.006964962984146107,
              0.00001815898306417785,
              0,
              0,
            ],
            [
              2.5376711297801746,
              0.01188348145908754,
              0.00001815898306417785,
              0,
              0,
            ],
            [
              6.319492602511967,
              0.06301505690907154,
              0.00001815898306417785,
              0,
              0,
            ],
            [
              16.68957136351488,
              0.23485623019596913,
              0.00001815898306417785,
              0,
              0,
            ],
            [24.540380341357043, 1.038331259307144, 0.17506377149748323, 0, 0],
          ],
          [
            [0.15903983718799275, 0, 0, 0, 0],
            [0.24333989604250333, 0, 0, 0, 0],
            [0.31158774938259753, 0, 0, 0, 0],
            [0.33292309074805937, 0, 0, 0, 0],
            [0.5602230274609694, 0, 0, 0, 0],
            [0.6532600212616405, 0, 0, 0, 0],
            [0.9886542456923963, 0, 0, 0, 0],
            [1.5441754776025243, 0, 0, 0, 0],
            [3.7570439885108455, 0, 0, 0, 0],
            [8.625700719544845, 0, 0, 0, 0],
            [31.00341796875, 0, 0, 0, 0],
          ],
        ],
      ],
    ],
    dimensions: ['month', 'level', 'quantiles', 'duration', 'magw_limit'],
    attributes: {
      description:
        'average number of days needed to wait before meeting the defined operability window',
      vartype: 'f8',
      units: 'days',
    },
  },
  success: true,
  globalAttributes: {
    statistic: 'Weather downtime',
    history: '2020-04-22 13:17: generated by Open Ocean',
    location_name: '#2985',
    longitude: "-47.63672 (47°38'12''W)",
    latitude: "22.91792 (22°55'4''N)",
    srs: 'EPSG:4326',
    period: '1979-01-01 00:00 - 2019-03-10 23:00',
    WIND_dataset: 'CFSR~CFSV2%CFSR3D',
    WIND_node_longitude: "-47.50000 (47°30'W)",
    WIND_node_latitude: '23.00000 (23°N)',
    WIND_node_distance: '16.7 km',
    sampling_period: '1h',
    depth: 4790.351976390153,
  },
  downtimeMax: {
    values: [
      [
        [
          [
            [0.9680911144578312, 0, 0, 0, 0],
            [1.2451727482848058, 0, 0, 0, 0],
            [1.4174667419678715, 0, 0, 0, 0],
            [1.7122167053631192, 0, 0, 0, 0],
            [6.159135238872155, 0, 0, 0, 0],
            [6.278813169344043, 0, 0, 0, 0],
            [7.833070563504016, 0, 0, 0, 0],
            [11.154660961554551, 0, 0, 0, 0],
            [31.00341796875, 0, 0, 0, 0],
            [31.034088134765625, 0, 0, 0, 0],
            [31.04119300842285, 0, 0, 0, 0],
          ],
          [
            [0.9680911144578312, 0, 0, 0, 0],
            [1.2451727482848058, 0, 0, 0, 0],
            [1.4174667419678715, 0, 0, 0, 0],
            [1.7122167053631192, 0, 0, 0, 0],
            [6.159135238872155, 0, 0, 0, 0],
            [6.278813169344043, 0, 0, 0, 0],
            [7.833070563504016, 0, 0, 0, 0],
            [11.154660961554551, 0, 0, 0, 0],
            [31.00341796875, 0, 0, 0, 0],
            [31.034088134765625, 0, 0, 0, 0],
            [31.04119300842285, 0, 0, 0, 0],
          ],
          [
            [0.9680911144578312, 0, 0, 0, 0],
            [1.2451727482848058, 0, 0, 0, 0],
            [1.4174667419678715, 0, 0, 0, 0],
            [1.7122167053631192, 0, 0, 0, 0],
            [6.159135238872155, 0, 0, 0, 0],
            [6.278813169344043, 0, 0, 0, 0],
            [7.833070563504016, 0, 0, 0, 0],
            [11.154660961554551, 0, 0, 0, 0],
            [31.00341796875, 0, 0, 0, 0],
            [31.034088134765625, 0, 0, 0, 0],
            [31.04119300842285, 0, 0, 0, 0],
          ],
          [
            [0.9680911144578312, 0, 0, 0, 0],
            [1.2451727482848058, 0, 0, 0, 0],
            [1.4174667419678715, 0, 0, 0, 0],
            [1.7122167053631192, 0, 0, 0, 0],
            [6.159135238872155, 0, 0, 0, 0],
            [6.278813169344043, 0, 0, 0, 0],
            [7.833070563504016, 0, 0, 0, 0],
            [11.154660961554551, 0, 0, 0, 0],
            [31.00341796875, 0, 0, 0, 0],
            [31.034088134765625, 0, 0, 0, 0],
            [31.04119300842285, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [2.0249683630354753, 0.005222713771753682, 0, 0, 0],
            [2.19159515666834, 0.005222713771753682, 0, 0, 0],
            [2.791929436495984, 0.005222713771753682, 0, 0, 0],
            [4.283569826179718, 0.005222713771753682, 0, 0, 0],
            [8.543026271753682, 0.026763547346895972, 0, 0, 0],
            [9.562900037650602, 0.026763547346895972, 0, 0, 0],
            [11.891285846511044, 0.026763547346895972, 0, 0, 0],
            [11.891285846511044, 0.026763547346895972, 0, 0, 0],
            [31.0113525390625, 0.9235379417470638, 0, 0, 0],
            [31.034088134765625, 2.990837046101071, 0, 0, 0],
            [31.04166663775686, 7.062386917461513, 0, 0, 0],
          ],
          [
            [2.0249683630354753, 0.005222713771753682, 0, 0, 0],
            [2.19159515666834, 0.005222713771753682, 0, 0, 0],
            [2.791929436495984, 0.005222713771753682, 0, 0, 0],
            [4.283569826179718, 0.005222713771753682, 0, 0, 0],
            [8.543026271753682, 0.026763547346895972, 0, 0, 0],
            [9.562900037650602, 0.026763547346895972, 0, 0, 0],
            [11.891285846511044, 0.026763547346895972, 0, 0, 0],
            [11.891285846511044, 0.026763547346895972, 0, 0, 0],
            [31.0113525390625, 0.9235379417470638, 0, 0, 0],
            [31.034088134765625, 2.990837046101071, 0, 0, 0],
            [31.04166663775686, 7.062386917461513, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [
              0.6981264467592593,
              0.024410445601851852,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              0.7802119502314816,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              0.9046857842240117,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              0.9675603953147877,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              2.044328703703704,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              2.6109953703703703,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              3.6176021458638363,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              7.8972403067129635,
              0.1965133101851852,
              0.00024594907407407405,
              0,
              0,
            ],
            [28.01513671875, 1.1582971643518518, 0.00024594907407407405, 0, 0],
            [29.0133056640625, 9.814105902777778, 0.00024594907407407405, 0, 0],
            [29.0390625, 28.01513671875, 4.253197337962963, 0, 0],
          ],
          [
            [
              0.6981264467592593,
              0.024410445601851852,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              0.7802119502314816,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              0.9046857842240117,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              0.9675603953147877,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              2.044328703703704,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              2.6109953703703703,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              3.6176021458638363,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              7.8972403067129635,
              0.1965133101851852,
              0.00024594907407407405,
              0,
              0,
            ],
            [28.01513671875, 1.1582971643518518, 0.00024594907407407405, 0, 0],
            [29.0133056640625, 9.814105902777778, 0.00024594907407407405, 0, 0],
            [29.0390625, 28.01513671875, 4.253197337962963, 0, 0],
          ],
          [
            [
              0.6981264467592593,
              0.024410445601851852,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              0.7802119502314816,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              0.9046857842240117,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              0.9675603953147877,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              2.044328703703704,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              2.6109953703703703,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              3.6176021458638363,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              7.8972403067129635,
              0.1965133101851852,
              0.00024594907407407405,
              0,
              0,
            ],
            [28.01513671875, 1.1582971643518518, 0.00024594907407407405, 0, 0],
            [29.0133056640625, 9.814105902777778, 0.00024594907407407405, 0, 0],
            [29.0390625, 28.01513671875, 4.253197337962963, 0, 0],
          ],
          [
            [
              0.6981264467592593,
              0.024410445601851852,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              0.7802119502314816,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              0.9046857842240117,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              0.9675603953147877,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              2.044328703703704,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              2.6109953703703703,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              3.6176021458638363,
              0.0395363136574074,
              0.00024594907407407405,
              0,
              0,
            ],
            [
              7.8972403067129635,
              0.1965133101851852,
              0.00024594907407407405,
              0,
              0,
            ],
            [28.01513671875, 1.1582971643518518, 0.00024594907407407405, 0, 0],
            [29.0133056640625, 9.814105902777778, 0.00024594907407407405, 0, 0],
            [29.0390625, 28.01513671875, 4.253197337962963, 0, 0],
          ],
        ],
        [
          [
            [
              1.1385288936676428,
              0.05201822916666667,
              0.0003689236111111111,
              0,
              0,
            ],
            [
              1.1519805774158125,
              0.0820240162037037,
              0.0003689236111111111,
              0,
              0,
            ],
            [1.65425684022694, 0.0820240162037037, 0.0003689236111111111, 0, 0],
            [
              2.817531105324074,
              0.0820240162037037,
              0.0003689236111111111,
              0,
              0,
            ],
            [
              3.938935908564815,
              0.0820240162037037,
              0.0003689236111111111,
              0,
              0,
            ],
            [
              3.938935908564815,
              0.2825483801106287,
              0.0003689236111111111,
              0,
              0,
            ],
            [
              11.235278642020498,
              0.2825483801106287,
              0.0003689236111111111,
              0,
              0,
            ],
            [
              11.235278642020498,
              0.9345640548093889,
              0.0003689236111111111,
              0,
              0,
            ],
            [29.01123046875, 1.0081452546296297, 0.0003689236111111111, 0, 0],
            [29.0390625, 9.668811487268519, 0.0003689236111111111, 0, 0],
            [29.0390625, 28.034820556640625, 4.2533203125000005, 0, 0],
          ],
          [
            [
              1.1385288936676428,
              0.05201822916666667,
              0.0003689236111111111,
              0,
              0,
            ],
            [
              1.1519805774158125,
              0.0820240162037037,
              0.0003689236111111111,
              0,
              0,
            ],
            [1.65425684022694, 0.0820240162037037, 0.0003689236111111111, 0, 0],
            [
              2.817531105324074,
              0.0820240162037037,
              0.0003689236111111111,
              0,
              0,
            ],
            [
              3.938935908564815,
              0.0820240162037037,
              0.0003689236111111111,
              0,
              0,
            ],
            [
              3.938935908564815,
              0.2825483801106287,
              0.0003689236111111111,
              0,
              0,
            ],
            [
              11.235278642020498,
              0.2825483801106287,
              0.0003689236111111111,
              0,
              0,
            ],
            [
              11.235278642020498,
              0.9345640548093889,
              0.0003689236111111111,
              0,
              0,
            ],
            [29.01123046875, 1.0081452546296297, 0.0003689236111111111, 0, 0],
            [29.0390625, 9.668811487268519, 0.0003689236111111111, 0, 0],
            [29.0390625, 28.034820556640625, 4.2533203125000005, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0.29586117909136544, 0.004055937290829987, 0, 0, 0],
            [0.3383096186830656, 0.004055937290829987, 0, 0, 0],
            [1.068378331032463, 0.004055937290829987, 0, 0, 0],
            [1.4104660830823292, 0.004055937290829987, 0, 0, 0],
            [2.2753252593708164, 0.004055937290829987, 0, 0, 0],
            [4.0749946400184065, 0.004055937290829987, 0, 0, 0],
            [4.0749946400184065, 0.004055937290829987, 0, 0, 0],
            [8.21316189236111, 0.004055937290829987, 0, 0, 0],
            [31.00341796875, 0.004055937290829987, 0, 0, 0],
            [31.0113525390625, 2.051359735818273, 0, 0, 0],
            [31.039772033691406, 2.051359735818273, 0, 0, 0],
          ],
          [
            [0.29586117909136544, 0.004055937290829987, 0, 0, 0],
            [0.3383096186830656, 0.004055937290829987, 0, 0, 0],
            [1.068378331032463, 0.004055937290829987, 0, 0, 0],
            [1.4104660830823292, 0.004055937290829987, 0, 0, 0],
            [2.2753252593708164, 0.004055937290829987, 0, 0, 0],
            [4.0749946400184065, 0.004055937290829987, 0, 0, 0],
            [4.0749946400184065, 0.004055937290829987, 0, 0, 0],
            [8.21316189236111, 0.004055937290829987, 0, 0, 0],
            [31.00341796875, 0.004055937290829987, 0, 0, 0],
            [31.0113525390625, 2.051359735818273, 0, 0, 0],
            [31.039772033691406, 2.051359735818273, 0, 0, 0],
          ],
          [
            [0.29586117909136544, 0.004055937290829987, 0, 0, 0],
            [0.3383096186830656, 0.004055937290829987, 0, 0, 0],
            [1.068378331032463, 0.004055937290829987, 0, 0, 0],
            [1.4104660830823292, 0.004055937290829987, 0, 0, 0],
            [2.2753252593708164, 0.004055937290829987, 0, 0, 0],
            [4.0749946400184065, 0.004055937290829987, 0, 0, 0],
            [4.0749946400184065, 0.004055937290829987, 0, 0, 0],
            [8.21316189236111, 0.004055937290829987, 0, 0, 0],
            [31.00341796875, 0.004055937290829987, 0, 0, 0],
            [31.0113525390625, 2.051359735818273, 0, 0, 0],
            [31.039772033691406, 2.051359735818273, 0, 0, 0],
          ],
          [
            [0.29586117909136544, 0.004055937290829987, 0, 0, 0],
            [0.3383096186830656, 0.004055937290829987, 0, 0, 0],
            [1.068378331032463, 0.004055937290829987, 0, 0, 0],
            [1.4104660830823292, 0.004055937290829987, 0, 0, 0],
            [2.2753252593708164, 0.004055937290829987, 0, 0, 0],
            [4.0749946400184065, 0.004055937290829987, 0, 0, 0],
            [4.0749946400184065, 0.004055937290829987, 0, 0, 0],
            [8.21316189236111, 0.004055937290829987, 0, 0, 0],
            [31.00341796875, 0.004055937290829987, 0, 0, 0],
            [31.0113525390625, 2.051359735818273, 0, 0, 0],
            [31.039772033691406, 2.051359735818273, 0, 0, 0],
          ],
        ],
        [
          [
            [0.4604877844712182, 0.010723231467536813, 0, 0, 0],
            [1.4921959975317938, 0.010723231467536813, 0, 0, 0],
            [1.4921959975317938, 0.010723231467536813, 0, 0, 0],
            [2.1691485996067605, 0.010723231467536813, 0, 0, 0],
            [3.3256640952705534, 0.018223937416331996, 0, 0, 0],
            [3.6348421027861444, 0.018223937416331996, 0, 0, 0],
            [4.838677627175368, 0.018223937416331996, 0, 0, 0],
            [10.372746369022652, 0.22424332747657294, 0, 0, 0],
            [31.0113525390625, 0.31719652045682734, 0, 0, 0],
            [31.039772033691406, 2.8634361665411645, 0, 0, 0],
            [31.039772033691406, 5.704490876677852, 0, 0, 0],
          ],
          [
            [0.4604877844712182, 0.010723231467536813, 0, 0, 0],
            [1.4921959975317938, 0.010723231467536813, 0, 0, 0],
            [1.4921959975317938, 0.010723231467536813, 0, 0, 0],
            [2.1691485996067605, 0.010723231467536813, 0, 0, 0],
            [3.3256640952705534, 0.018223937416331996, 0, 0, 0],
            [3.6348421027861444, 0.018223937416331996, 0, 0, 0],
            [4.838677627175368, 0.018223937416331996, 0, 0, 0],
            [10.372746369022652, 0.22424332747657294, 0, 0, 0],
            [31.0113525390625, 0.31719652045682734, 0, 0, 0],
            [31.039772033691406, 2.8634361665411645, 0, 0, 0],
            [31.039772033691406, 5.704490876677852, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0.27347802126556015, 0.00034640202409847435, 0, 0, 0],
            [0.27347802126556015, 0.00034640202409847435, 0, 0, 0],
            [0.34180280074332525, 0.00034640202409847435, 0, 0, 0],
            [0.5959016374013957, 0.00034640202409847435, 0, 0, 0],
            [1.5669428260581006, 0.00034640202409847435, 0, 0, 0],
            [3.076976294656402, 0.00034640202409847435, 0, 0, 0],
            [3.076976294656402, 0.00034640202409847435, 0, 0, 0],
            [5.023063353647995, 0.00034640202409847435, 0, 0, 0],
            [7.223057410529045, 0.00034640202409847435, 0, 0, 0],
            [30.0234375, 3.001515805142597, 0, 0, 0],
            [30.0234375, 3.001515805142597, 0, 0, 0],
          ],
          [
            [0.27347802126556015, 0.00034640202409847435, 0, 0, 0],
            [0.27347802126556015, 0.00034640202409847435, 0, 0, 0],
            [0.34180280074332525, 0.00034640202409847435, 0, 0, 0],
            [0.5959016374013957, 0.00034640202409847435, 0, 0, 0],
            [1.5669428260581006, 0.00034640202409847435, 0, 0, 0],
            [3.076976294656402, 0.00034640202409847435, 0, 0, 0],
            [3.076976294656402, 0.00034640202409847435, 0, 0, 0],
            [5.023063353647995, 0.00034640202409847435, 0, 0, 0],
            [7.223057410529045, 0.00034640202409847435, 0, 0, 0],
            [30.0234375, 3.001515805142597, 0, 0, 0],
            [30.0234375, 3.001515805142597, 0, 0, 0],
          ],
          [
            [0.27347802126556015, 0.00034640202409847435, 0, 0, 0],
            [0.27347802126556015, 0.00034640202409847435, 0, 0, 0],
            [0.34180280074332525, 0.00034640202409847435, 0, 0, 0],
            [0.5959016374013957, 0.00034640202409847435, 0, 0, 0],
            [1.5669428260581006, 0.00034640202409847435, 0, 0, 0],
            [3.076976294656402, 0.00034640202409847435, 0, 0, 0],
            [3.076976294656402, 0.00034640202409847435, 0, 0, 0],
            [5.023063353647995, 0.00034640202409847435, 0, 0, 0],
            [7.223057410529045, 0.00034640202409847435, 0, 0, 0],
            [30.0234375, 3.001515805142597, 0, 0, 0],
            [30.0234375, 3.001515805142597, 0, 0, 0],
          ],
          [
            [0.27347802126556015, 0.00034640202409847435, 0, 0, 0],
            [0.27347802126556015, 0.00034640202409847435, 0, 0, 0],
            [0.34180280074332525, 0.00034640202409847435, 0, 0, 0],
            [0.5959016374013957, 0.00034640202409847435, 0, 0, 0],
            [1.5669428260581006, 0.00034640202409847435, 0, 0, 0],
            [3.076976294656402, 0.00034640202409847435, 0, 0, 0],
            [3.076976294656402, 0.00034640202409847435, 0, 0, 0],
            [5.023063353647995, 0.00034640202409847435, 0, 0, 0],
            [7.223057410529045, 0.00034640202409847435, 0, 0, 0],
            [30.0234375, 3.001515805142597, 0, 0, 0],
            [30.0234375, 3.001515805142597, 0, 0, 0],
          ],
        ],
        [
          [
            [0.4296800928208434, 0.0026406358056708158, 0, 0, 0],
            [0.6011014771692962, 0.006084943378284924, 0, 0, 0],
            [1.5746848097125303, 0.006084943378284924, 0, 0, 0],
            [1.9620150964236955, 0.006084943378284924, 0, 0, 0],
            [5.807963644320539, 0.04270941390041493, 0, 0, 0],
            [5.807963644320539, 0.04270941390041493, 0, 0, 0],
            [5.807963644320539, 0.04270941390041493, 0, 0, 0],
            [9.520238346084026, 0.04270941390041493, 0, 0, 0],
            [30.00732421875, 0.04270941390041493, 0, 0, 0],
            [30.039833068847656, 3.720541039937759, 0, 0, 0],
            [30.039833068847656, 3.720541039937759, 0, 0, 0],
          ],
          [
            [0.4296800928208434, 0.0026406358056708158, 0, 0, 0],
            [0.6011014771692962, 0.006084943378284924, 0, 0, 0],
            [1.5746848097125303, 0.006084943378284924, 0, 0, 0],
            [1.9620150964236955, 0.006084943378284924, 0, 0, 0],
            [5.807963644320539, 0.04270941390041493, 0, 0, 0],
            [5.807963644320539, 0.04270941390041493, 0, 0, 0],
            [5.807963644320539, 0.04270941390041493, 0, 0, 0],
            [9.520238346084026, 0.04270941390041493, 0, 0, 0],
            [30.00732421875, 0.04270941390041493, 0, 0, 0],
            [30.039833068847656, 3.720541039937759, 0, 0, 0],
            [30.039833068847656, 3.720541039937759, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0.2037158530830537, 0, 0, 0, 0],
            [0.25908678298028526, 0, 0, 0, 0],
            [0.25908678298028526, 0, 0, 0, 0],
            [0.25908678298028526, 0, 0, 0, 0],
            [0.3841323340499161, 0, 0, 0, 0],
            [0.6254512505243288, 0, 0, 0, 0],
            [0.9732582674447791, 0, 0, 0, 0],
            [2.366722750376506, 0, 0, 0, 0],
            [3.3508073025901846, 0, 0, 0, 0],
            [31.0113525390625, 0, 0, 0, 0],
            [31.0113525390625, 0, 0, 0, 0],
          ],
          [
            [0.2037158530830537, 0, 0, 0, 0],
            [0.25908678298028526, 0, 0, 0, 0],
            [0.25908678298028526, 0, 0, 0, 0],
            [0.25908678298028526, 0, 0, 0, 0],
            [0.3841323340499161, 0, 0, 0, 0],
            [0.6254512505243288, 0, 0, 0, 0],
            [0.9732582674447791, 0, 0, 0, 0],
            [2.366722750376506, 0, 0, 0, 0],
            [3.3508073025901846, 0, 0, 0, 0],
            [31.0113525390625, 0, 0, 0, 0],
            [31.0113525390625, 0, 0, 0, 0],
          ],
          [
            [0.2037158530830537, 0, 0, 0, 0],
            [0.25908678298028526, 0, 0, 0, 0],
            [0.25908678298028526, 0, 0, 0, 0],
            [0.25908678298028526, 0, 0, 0, 0],
            [0.3841323340499161, 0, 0, 0, 0],
            [0.6254512505243288, 0, 0, 0, 0],
            [0.9732582674447791, 0, 0, 0, 0],
            [2.366722750376506, 0, 0, 0, 0],
            [3.3508073025901846, 0, 0, 0, 0],
            [31.0113525390625, 0, 0, 0, 0],
            [31.0113525390625, 0, 0, 0, 0],
          ],
          [
            [0.2037158530830537, 0, 0, 0, 0],
            [0.25908678298028526, 0, 0, 0, 0],
            [0.25908678298028526, 0, 0, 0, 0],
            [0.25908678298028526, 0, 0, 0, 0],
            [0.3841323340499161, 0, 0, 0, 0],
            [0.6254512505243288, 0, 0, 0, 0],
            [0.9732582674447791, 0, 0, 0, 0],
            [2.366722750376506, 0, 0, 0, 0],
            [3.3508073025901846, 0, 0, 0, 0],
            [31.0113525390625, 0, 0, 0, 0],
            [31.0113525390625, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0.2832801358011745, 0.0010057282927852349, 0, 0, 0],
            [0.40240306470218123, 0.0010057282927852349, 0, 0, 0],
            [0.4151422897441275, 0.0010057282927852349, 0, 0, 0],
            [0.4151422897441275, 0.0010057282927852349, 0, 0, 0],
            [0.8953216757550335, 0.0010057282927852349, 0, 0, 0],
            [1.649450273961829, 0.0010057282927852349, 0, 0, 0],
            [3.3280107946203863, 0.0010057282927852349, 0, 0, 0],
            [3.3280107946203863, 0.0010057282927852349, 0, 0, 0],
            [8.161540969746225, 0.0010057282927852349, 0, 0, 0],
            [31.0113525390625, 0.0010057282927852349, 0, 0, 0],
            [31.0113525390625, 4.459957988150168, 0, 0, 0],
          ],
          [
            [0.2832801358011745, 0.0010057282927852349, 0, 0, 0],
            [0.40240306470218123, 0.0010057282927852349, 0, 0, 0],
            [0.4151422897441275, 0.0010057282927852349, 0, 0, 0],
            [0.4151422897441275, 0.0010057282927852349, 0, 0, 0],
            [0.8953216757550335, 0.0010057282927852349, 0, 0, 0],
            [1.649450273961829, 0.0010057282927852349, 0, 0, 0],
            [3.3280107946203863, 0.0010057282927852349, 0, 0, 0],
            [3.3280107946203863, 0.0010057282927852349, 0, 0, 0],
            [8.161540969746225, 0.0010057282927852349, 0, 0, 0],
            [31.0113525390625, 0.0010057282927852349, 0, 0, 0],
            [31.0113525390625, 4.459957988150168, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0.015645824755114425, 0, 0, 0, 0],
            [0.02644202117285021, 0, 0, 0, 0],
            [0.09306667714112343, 0, 0, 0, 0],
            [0.09306667714112343, 0, 0, 0, 0],
            [0.177473303679785, 0, 0, 0, 0],
            [0.3114802148167358, 0, 0, 0, 0],
            [0.4897231316995159, 0, 0, 0, 0],
            [1.4518330469830567, 0, 0, 0, 0],
            [4.162847537387621, 0, 0, 0, 0],
            [6.954401419865145, 0, 0, 0, 0],
            [30.034332275390625, 0, 0, 0, 0],
          ],
          [
            [0.015645824755114425, 0, 0, 0, 0],
            [0.02644202117285021, 0, 0, 0, 0],
            [0.09306667714112343, 0, 0, 0, 0],
            [0.09306667714112343, 0, 0, 0, 0],
            [0.177473303679785, 0, 0, 0, 0],
            [0.3114802148167358, 0, 0, 0, 0],
            [0.4897231316995159, 0, 0, 0, 0],
            [1.4518330469830567, 0, 0, 0, 0],
            [4.162847537387621, 0, 0, 0, 0],
            [6.954401419865145, 0, 0, 0, 0],
            [30.034332275390625, 0, 0, 0, 0],
          ],
          [
            [0.015645824755114425, 0, 0, 0, 0],
            [0.02644202117285021, 0, 0, 0, 0],
            [0.09306667714112343, 0, 0, 0, 0],
            [0.09306667714112343, 0, 0, 0, 0],
            [0.177473303679785, 0, 0, 0, 0],
            [0.3114802148167358, 0, 0, 0, 0],
            [0.4897231316995159, 0, 0, 0, 0],
            [1.4518330469830567, 0, 0, 0, 0],
            [4.162847537387621, 0, 0, 0, 0],
            [6.954401419865145, 0, 0, 0, 0],
            [30.034332275390625, 0, 0, 0, 0],
          ],
          [
            [0.015645824755114425, 0, 0, 0, 0],
            [0.02644202117285021, 0, 0, 0, 0],
            [0.09306667714112343, 0, 0, 0, 0],
            [0.09306667714112343, 0, 0, 0, 0],
            [0.177473303679785, 0, 0, 0, 0],
            [0.3114802148167358, 0, 0, 0, 0],
            [0.4897231316995159, 0, 0, 0, 0],
            [1.4518330469830567, 0, 0, 0, 0],
            [4.162847537387621, 0, 0, 0, 0],
            [6.954401419865145, 0, 0, 0, 0],
            [30.034332275390625, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0.09924417990421289, 0, 0, 0, 0],
            [0.1136198639042996, 0, 0, 0, 0],
            [0.11650654743845354, 0, 0, 0, 0],
            [0.15692011691660887, 0, 0, 0, 0],
            [0.7949461877593361, 0, 0, 0, 0],
            [1.331569307572614, 0, 0, 0, 0],
            [1.331569307572614, 0, 0, 0, 0],
            [3.4253638809647304, 0, 0, 0, 0],
            [4.903147450101855, 0, 0, 0, 0],
            [30.0234375, 0, 0, 0, 0],
            [30.034332275390625, 0, 0, 0, 0],
          ],
          [
            [0.09924417990421289, 0, 0, 0, 0],
            [0.1136198639042996, 0, 0, 0, 0],
            [0.11650654743845354, 0, 0, 0, 0],
            [0.15692011691660887, 0, 0, 0, 0],
            [0.7949461877593361, 0, 0, 0, 0],
            [1.331569307572614, 0, 0, 0, 0],
            [1.331569307572614, 0, 0, 0, 0],
            [3.4253638809647304, 0, 0, 0, 0],
            [4.903147450101855, 0, 0, 0, 0],
            [30.0234375, 0, 0, 0, 0],
            [30.034332275390625, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0.02716922377008032, 0.0010000941265060242, 0, 0, 0],
            [0.03982204861111111, 0.0010000941265060242, 0, 0, 0],
            [0.048610200817953024, 0.0010000941265060242, 0, 0, 0],
            [0.06651475694444445, 0.0010000941265060242, 0, 0, 0],
            [0.6722854961512718, 0.0010000941265060242, 0, 0, 0],
            [0.6722854961512718, 0.0010000941265060242, 0, 0, 0],
            [0.6722854961512718, 0.0010000941265060242, 0, 0, 0],
            [1.5245323742888217, 0.0010000941265060242, 0, 0, 0],
            [10.140299479166666, 0.0010000941265060242, 0, 0, 0],
            [31.0078125, 0.0010000941265060242, 0, 0, 0],
            [31.034088134765625, 0.0010000941265060242, 0, 0, 0],
          ],
          [
            [0.02716922377008032, 0.0010000941265060242, 0, 0, 0],
            [0.03982204861111111, 0.0010000941265060242, 0, 0, 0],
            [0.048610200817953024, 0.0010000941265060242, 0, 0, 0],
            [0.06651475694444445, 0.0010000941265060242, 0, 0, 0],
            [0.6722854961512718, 0.0010000941265060242, 0, 0, 0],
            [0.6722854961512718, 0.0010000941265060242, 0, 0, 0],
            [0.6722854961512718, 0.0010000941265060242, 0, 0, 0],
            [1.5245323742888217, 0.0010000941265060242, 0, 0, 0],
            [10.140299479166666, 0.0010000941265060242, 0, 0, 0],
            [31.0078125, 0.0010000941265060242, 0, 0, 0],
            [31.034088134765625, 0.0010000941265060242, 0, 0, 0],
          ],
          [
            [0.02716922377008032, 0.0010000941265060242, 0, 0, 0],
            [0.03982204861111111, 0.0010000941265060242, 0, 0, 0],
            [0.048610200817953024, 0.0010000941265060242, 0, 0, 0],
            [0.06651475694444445, 0.0010000941265060242, 0, 0, 0],
            [0.6722854961512718, 0.0010000941265060242, 0, 0, 0],
            [0.6722854961512718, 0.0010000941265060242, 0, 0, 0],
            [0.6722854961512718, 0.0010000941265060242, 0, 0, 0],
            [1.5245323742888217, 0.0010000941265060242, 0, 0, 0],
            [10.140299479166666, 0.0010000941265060242, 0, 0, 0],
            [31.0078125, 0.0010000941265060242, 0, 0, 0],
            [31.034088134765625, 0.0010000941265060242, 0, 0, 0],
          ],
          [
            [0.02716922377008032, 0.0010000941265060242, 0, 0, 0],
            [0.03982204861111111, 0.0010000941265060242, 0, 0, 0],
            [0.048610200817953024, 0.0010000941265060242, 0, 0, 0],
            [0.06651475694444445, 0.0010000941265060242, 0, 0, 0],
            [0.6722854961512718, 0.0010000941265060242, 0, 0, 0],
            [0.6722854961512718, 0.0010000941265060242, 0, 0, 0],
            [0.6722854961512718, 0.0010000941265060242, 0, 0, 0],
            [1.5245323742888217, 0.0010000941265060242, 0, 0, 0],
            [10.140299479166666, 0.0010000941265060242, 0, 0, 0],
            [31.0078125, 0.0010000941265060242, 0, 0, 0],
            [31.034088134765625, 0.0010000941265060242, 0, 0, 0],
          ],
        ],
        [
          [
            [0.11067708333333334, 0.0015001411897590361, 0, 0, 0],
            [0.29936150853413657, 0.002889160809906292, 0, 0, 0],
            [0.5252160987700802, 0.002889160809906292, 0, 0, 0],
            [0.5313042534722222, 0.002889160809906292, 0, 0, 0],
            [0.865939670138889, 0.002889160809906292, 0, 0, 0],
            [2.3352753461763722, 0.002889160809906292, 0, 0, 0],
            [3.7354071232848063, 0.002889160809906292, 0, 0, 0],
            [7.65944311098561, 0.002889160809906292, 0, 0, 0],
            [31.0078125, 0.002889160809906292, 0, 0, 0],
            [31.034088134765625, 0.002889160809906292, 0, 0, 0],
            [31.034088134765625, 0.002889160809906292, 0, 0, 0],
          ],
          [
            [0.11067708333333334, 0.0015001411897590361, 0, 0, 0],
            [0.29936150853413657, 0.002889160809906292, 0, 0, 0],
            [0.5252160987700802, 0.002889160809906292, 0, 0, 0],
            [0.5313042534722222, 0.002889160809906292, 0, 0, 0],
            [0.865939670138889, 0.002889160809906292, 0, 0, 0],
            [2.3352753461763722, 0.002889160809906292, 0, 0, 0],
            [3.7354071232848063, 0.002889160809906292, 0, 0, 0],
            [7.65944311098561, 0.002889160809906292, 0, 0, 0],
            [31.0078125, 0.002889160809906292, 0, 0, 0],
            [31.034088134765625, 0.002889160809906292, 0, 0, 0],
            [31.034088134765625, 0.002889160809906292, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [
              0.10323193816934403,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.1280120481927711,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.14812505229250333,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.14812505229250333,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.24191165704484605,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.24191165704484605,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.6996769630605756,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              1.1785553673025435,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [3.4835500857597057, 0.8420792545180723, 0.7387917555639223, 0, 0],
            [31.00341796875, 2.2459891649933064, 1.0559895833333335, 0, 0],
            [31.0078125, 2.2459891649933064, 1.0559895833333335, 0, 0],
          ],
          [
            [
              0.10323193816934403,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.1280120481927711,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.14812505229250333,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.14812505229250333,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.24191165704484605,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.24191165704484605,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.6996769630605756,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              1.1785553673025435,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [3.4835500857597057, 0.8420792545180723, 0.7387917555639223, 0, 0],
            [31.00341796875, 2.2459891649933064, 1.0559895833333335, 0, 0],
            [31.0078125, 2.2459891649933064, 1.0559895833333335, 0, 0],
          ],
          [
            [
              0.10323193816934403,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.1280120481927711,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.14812505229250333,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.14812505229250333,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.24191165704484605,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.24191165704484605,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.6996769630605756,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              1.1785553673025435,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [3.4835500857597057, 0.8420792545180723, 0.7387917555639223, 0, 0],
            [31.00341796875, 2.2459891649933064, 1.0559895833333335, 0, 0],
            [31.0078125, 2.2459891649933064, 1.0559895833333335, 0, 0],
          ],
          [
            [
              0.10323193816934403,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.1280120481927711,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.14812505229250333,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.14812505229250333,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.24191165704484605,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.24191165704484605,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              0.6996769630605756,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [
              1.1785553673025435,
              0.018668423694779116,
              0.004278180430053548,
              0,
              0,
            ],
            [3.4835500857597057, 0.8420792545180723, 0.7387917555639223, 0, 0],
            [31.00341796875, 2.2459891649933064, 1.0559895833333335, 0, 0],
            [31.0078125, 2.2459891649933064, 1.0559895833333335, 0, 0],
          ],
        ],
        [
          [
            [
              0.25557961010709507,
              0.03717016503514056,
              0.01216781187248996,
              0,
              0,
            ],
            [
              0.25685750815763053,
              0.03717016503514056,
              0.013279027568607764,
              0,
              0,
            ],
            [
              0.25685750815763053,
              0.03717016503514056,
              0.013279027568607764,
              0,
              0,
            ],
            [
              0.36070061495983935,
              0.03717016503514056,
              0.013279027568607764,
              0,
              0,
            ],
            [
              0.6629774305555556,
              0.0386703062248996,
              0.013279027568607764,
              0,
              0,
            ],
            [1.341181784429384, 0.0386703062248996, 0.013279027568607764, 0, 0],
            [1.86267531061747, 0.0386703062248996, 0.013279027568607764, 0, 0],
            [4.787651909722222, 0.0386703062248996, 0.013279027568607764, 0, 0],
            [12.550903483726572, 0.8211883994310576, 0.8431349094293842, 0, 0],
            [31.0113525390625, 3.3556491591365463, 1.0488823784722223, 0, 0],
            [31.034088134765625, 7.689834860274431, 1.0488823784722223, 0, 0],
          ],
          [
            [
              0.25557961010709507,
              0.03717016503514056,
              0.01216781187248996,
              0,
              0,
            ],
            [
              0.25685750815763053,
              0.03717016503514056,
              0.013279027568607764,
              0,
              0,
            ],
            [
              0.25685750815763053,
              0.03717016503514056,
              0.013279027568607764,
              0,
              0,
            ],
            [
              0.36070061495983935,
              0.03717016503514056,
              0.013279027568607764,
              0,
              0,
            ],
            [
              0.6629774305555556,
              0.0386703062248996,
              0.013279027568607764,
              0,
              0,
            ],
            [1.341181784429384, 0.0386703062248996, 0.013279027568607764, 0, 0],
            [1.86267531061747, 0.0386703062248996, 0.013279027568607764, 0, 0],
            [4.787651909722222, 0.0386703062248996, 0.013279027568607764, 0, 0],
            [12.550903483726572, 0.8211883994310576, 0.8431349094293842, 0, 0],
            [31.0113525390625, 3.3556491591365463, 1.0488823784722223, 0, 0],
            [31.034088134765625, 7.689834860274431, 1.0488823784722223, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [
              0.324224152835408,
              0.05200904434647303,
              0.010447732970262793,
              0.0019517742911479945,
              0,
            ],
            [
              0.39299549403526973,
              0.05200904434647303,
              0.010447732970262793,
              0.003329497320193638,
              0,
            ],
            [
              0.4529264457987552,
              0.05200904434647303,
              0.010447732970262793,
              0.003329497320193638,
              0,
            ],
            [
              0.611823835148686,
              0.05200904434647303,
              0.010447732970262793,
              0.003329497320193638,
              0,
            ],
            [
              0.611823835148686,
              0.05200904434647303,
              0.010447732970262793,
              0.003329497320193638,
              0,
            ],
            [
              0.611823835148686,
              0.05200904434647303,
              0.033352378328146616,
              0.003329497320193638,
              0,
            ],
            [
              0.8520267674180327,
              0.05200904434647303,
              0.033352378328146616,
              0.003329497320193638,
              0,
            ],
            [
              1.9460111451380462,
              0.3544192492219917,
              0.033352378328146616,
              0.003329497320193638,
              0,
            ],
            [
              5.18406692060452,
              0.7369906231037622,
              0.8303422771516393,
              0.003329497320193638,
              0,
            ],
            [
              30.00732421875,
              3.03719041753112,
              2.7213474131224067,
              2.78081912387621,
              0,
            ],
            [
              30.0234375,
              6.03006407762794,
              5.365886767375518,
              2.78081912387621,
              0,
            ],
          ],
          [
            [
              0.324224152835408,
              0.05200904434647303,
              0.010447732970262793,
              0.0019517742911479945,
              0,
            ],
            [
              0.39299549403526973,
              0.05200904434647303,
              0.010447732970262793,
              0.003329497320193638,
              0,
            ],
            [
              0.4529264457987552,
              0.05200904434647303,
              0.010447732970262793,
              0.003329497320193638,
              0,
            ],
            [
              0.611823835148686,
              0.05200904434647303,
              0.010447732970262793,
              0.003329497320193638,
              0,
            ],
            [
              0.611823835148686,
              0.05200904434647303,
              0.010447732970262793,
              0.003329497320193638,
              0,
            ],
            [
              0.611823835148686,
              0.05200904434647303,
              0.033352378328146616,
              0.003329497320193638,
              0,
            ],
            [
              0.8520267674180327,
              0.05200904434647303,
              0.033352378328146616,
              0.003329497320193638,
              0,
            ],
            [
              1.9460111451380462,
              0.3544192492219917,
              0.033352378328146616,
              0.003329497320193638,
              0,
            ],
            [
              5.18406692060452,
              0.7369906231037622,
              0.8303422771516393,
              0.003329497320193638,
              0,
            ],
            [
              30.00732421875,
              3.03719041753112,
              2.7213474131224067,
              2.78081912387621,
              0,
            ],
            [
              30.0234375,
              6.03006407762794,
              5.365886767375518,
              2.78081912387621,
              0,
            ],
          ],
          [
            [
              0.324224152835408,
              0.05200904434647303,
              0.010447732970262793,
              0.0019517742911479945,
              0,
            ],
            [
              0.39299549403526973,
              0.05200904434647303,
              0.010447732970262793,
              0.003329497320193638,
              0,
            ],
            [
              0.4529264457987552,
              0.05200904434647303,
              0.010447732970262793,
              0.003329497320193638,
              0,
            ],
            [
              0.611823835148686,
              0.05200904434647303,
              0.010447732970262793,
              0.003329497320193638,
              0,
            ],
            [
              0.611823835148686,
              0.05200904434647303,
              0.010447732970262793,
              0.003329497320193638,
              0,
            ],
            [
              0.611823835148686,
              0.05200904434647303,
              0.033352378328146616,
              0.003329497320193638,
              0,
            ],
            [
              0.8520267674180327,
              0.05200904434647303,
              0.033352378328146616,
              0.003329497320193638,
              0,
            ],
            [
              1.9460111451380462,
              0.3544192492219917,
              0.033352378328146616,
              0.003329497320193638,
              0,
            ],
            [
              5.18406692060452,
              0.7369906231037622,
              0.8303422771516393,
              0.003329497320193638,
              0,
            ],
            [
              30.00732421875,
              3.03719041753112,
              2.7213474131224067,
              2.78081912387621,
              0,
            ],
            [
              30.0234375,
              6.03006407762794,
              5.365886767375518,
              2.78081912387621,
              0,
            ],
          ],
          [
            [
              0.324224152835408,
              0.05200904434647303,
              0.010447732970262793,
              0.0019517742911479945,
              0,
            ],
            [
              0.39299549403526973,
              0.05200904434647303,
              0.010447732970262793,
              0.003329497320193638,
              0,
            ],
            [
              0.4529264457987552,
              0.05200904434647303,
              0.010447732970262793,
              0.003329497320193638,
              0,
            ],
            [
              0.611823835148686,
              0.05200904434647303,
              0.010447732970262793,
              0.003329497320193638,
              0,
            ],
            [
              0.611823835148686,
              0.05200904434647303,
              0.010447732970262793,
              0.003329497320193638,
              0,
            ],
            [
              0.611823835148686,
              0.05200904434647303,
              0.033352378328146616,
              0.003329497320193638,
              0,
            ],
            [
              0.8520267674180327,
              0.05200904434647303,
              0.033352378328146616,
              0.003329497320193638,
              0,
            ],
            [
              1.9460111451380462,
              0.3544192492219917,
              0.033352378328146616,
              0.003329497320193638,
              0,
            ],
            [
              5.18406692060452,
              0.7369906231037622,
              0.8303422771516393,
              0.003329497320193638,
              0,
            ],
            [
              30.00732421875,
              3.03719041753112,
              2.7213474131224067,
              2.78081912387621,
              0,
            ],
            [
              30.0234375,
              6.03006407762794,
              5.365886767375518,
              2.78081912387621,
              0,
            ],
          ],
        ],
        [
          [
            [
              0.4572318302645228,
              0.0885761097423928,
              0.03237649118257262,
              0.007979312543222684,
              0.0005166461358921162,
            ],
            [
              0.5599296010546335,
              0.0885761097423928,
              0.03237649118257262,
              0.007979312543222684,
              0.0005166461358921162,
            ],
            [
              0.7985053055843706,
              0.0885761097423928,
              0.03237649118257262,
              0.007979312543222684,
              0.0005166461358921162,
            ],
            [
              0.7985053055843706,
              0.0885761097423928,
              0.03237649118257262,
              0.007979312543222684,
              0.0005166461358921162,
            ],
            [
              1.4876538457382433,
              0.0885761097423928,
              0.03237649118257262,
              0.007979312543222684,
              0.0005166461358921162,
            ],
            [
              2.52697365577455,
              0.0885761097423928,
              0.033524593706777316,
              0.007979312543222684,
              0.0005166461358921162,
            ],
            [
              2.52697365577455,
              0.11406398577973721,
              0.033524593706777316,
              0.007979312543222684,
              0.0005166461358921162,
            ],
            [
              3.144082991803279,
              0.46819620937067774,
              0.033524593706777316,
              0.007979312543222684,
              0.0005166461358921162,
            ],
            [
              6.02077891525713,
              0.7525901424074636,
              0.7507204149590163,
              0.8765128714139344,
              0.0005166461358921162,
            ],
            [
              30.0234375,
              3.3716735310759525,
              2.7107274647735133,
              2.7332876793741354,
              2.6417265030688104,
            ],
            [
              30.039833068847656,
              30.00732421875,
              5.517436300570539,
              5.340456296464384,
              2.6417265030688104,
            ],
          ],
          [
            [
              0.4572318302645228,
              0.0885761097423928,
              0.03237649118257262,
              0.007979312543222684,
              0.0005166461358921162,
            ],
            [
              0.5599296010546335,
              0.0885761097423928,
              0.03237649118257262,
              0.007979312543222684,
              0.0005166461358921162,
            ],
            [
              0.7985053055843706,
              0.0885761097423928,
              0.03237649118257262,
              0.007979312543222684,
              0.0005166461358921162,
            ],
            [
              0.7985053055843706,
              0.0885761097423928,
              0.03237649118257262,
              0.007979312543222684,
              0.0005166461358921162,
            ],
            [
              1.4876538457382433,
              0.0885761097423928,
              0.03237649118257262,
              0.007979312543222684,
              0.0005166461358921162,
            ],
            [
              2.52697365577455,
              0.0885761097423928,
              0.033524593706777316,
              0.007979312543222684,
              0.0005166461358921162,
            ],
            [
              2.52697365577455,
              0.11406398577973721,
              0.033524593706777316,
              0.007979312543222684,
              0.0005166461358921162,
            ],
            [
              3.144082991803279,
              0.46819620937067774,
              0.033524593706777316,
              0.007979312543222684,
              0.0005166461358921162,
            ],
            [
              6.02077891525713,
              0.7525901424074636,
              0.7507204149590163,
              0.8765128714139344,
              0.0005166461358921162,
            ],
            [
              30.0234375,
              3.3716735310759525,
              2.7107274647735133,
              2.7332876793741354,
              2.6417265030688104,
            ],
            [
              30.039833068847656,
              30.00732421875,
              5.517436300570539,
              5.340456296464384,
              2.6417265030688104,
            ],
          ],
        ],
      ],
      [
        [
          [
            [
              0.10378754601740295,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.13267915411646586,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.13267915411646586,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.1449025267737617,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.1449025267737617,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.21690930388219545,
              0.09031887182453334,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.35189315488674494,
              0.09031887182453334,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.5745540756777109,
              0.09031887182453334,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              2.2828259653196117,
              0.33869854417670686,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              7.930801983977577,
              0.33869854417670686,
              0.00033336470883534134,
              0,
              0,
            ],
            [31.039772033691406, 31.039772033691406, 6.690351902401272, 0, 0],
          ],
          [
            [
              0.10378754601740295,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.13267915411646586,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.13267915411646586,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.1449025267737617,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.1449025267737617,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.21690930388219545,
              0.09031887182453334,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.35189315488674494,
              0.09031887182453334,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.5745540756777109,
              0.09031887182453334,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              2.2828259653196117,
              0.33869854417670686,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              7.930801983977577,
              0.33869854417670686,
              0.00033336470883534134,
              0,
              0,
            ],
            [31.039772033691406, 31.039772033691406, 6.690351902401272, 0, 0],
          ],
          [
            [
              0.10378754601740295,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.13267915411646586,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.13267915411646586,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.1449025267737617,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.1449025267737617,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.21690930388219545,
              0.09031887182453334,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.35189315488674494,
              0.09031887182453334,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.5745540756777109,
              0.09031887182453334,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              2.2828259653196117,
              0.33869854417670686,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              7.930801983977577,
              0.33869854417670686,
              0.00033336470883534134,
              0,
              0,
            ],
            [31.039772033691406, 31.039772033691406, 6.690351902401272, 0, 0],
          ],
          [
            [
              0.10378754601740295,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.13267915411646586,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.13267915411646586,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.1449025267737617,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.1449025267737617,
              0.0018455249351143038,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.21690930388219545,
              0.09031887182453334,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.35189315488674494,
              0.09031887182453334,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              0.5745540756777109,
              0.09031887182453334,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              2.2828259653196117,
              0.33869854417670686,
              0.00033336470883534134,
              0,
              0,
            ],
            [
              7.930801983977577,
              0.33869854417670686,
              0.00033336470883534134,
              0,
              0,
            ],
            [31.039772033691406, 31.039772033691406, 6.690351902401272, 0, 0],
          ],
        ],
        [
          [
            [
              0.14918070720381527,
              0.0059839747896130455,
              0.000722290202476573,
              0,
              0,
            ],
            [
              0.17351633094879515,
              0.007997274718828648,
              0.000722290202476573,
              0,
              0,
            ],
            [
              0.17351633094879515,
              0.007997274718828648,
              0.000722290202476573,
              0,
              0,
            ],
            [
              0.18376890860947986,
              0.007997274718828648,
              0.000722290202476573,
              0,
              0,
            ],
            [
              0.39117964162122487,
              0.007997274718828648,
              0.000722290202476573,
              0,
              0,
            ],
            [
              0.39117964162122487,
              0.0688436725795669,
              0.000722290202476573,
              0,
              0,
            ],
            [
              0.8145616319444445,
              0.0688436725795669,
              0.000722290202476573,
              0,
              0,
            ],
            [1.956597222222222, 0.0688436725795669, 0.000722290202476573, 0, 0],
            [4.511067708333334, 0.339143030455154, 0.000722290202476573, 0, 0],
            [31.0113525390625, 2.6528052313420347, 0.000722290202476573, 0, 0],
            [31.039772033691406, 31.039772033691406, 6.690685267110107, 0, 0],
          ],
          [
            [
              0.14918070720381527,
              0.0059839747896130455,
              0.000722290202476573,
              0,
              0,
            ],
            [
              0.17351633094879515,
              0.007997274718828648,
              0.000722290202476573,
              0,
              0,
            ],
            [
              0.17351633094879515,
              0.007997274718828648,
              0.000722290202476573,
              0,
              0,
            ],
            [
              0.18376890860947986,
              0.007997274718828648,
              0.000722290202476573,
              0,
              0,
            ],
            [
              0.39117964162122487,
              0.007997274718828648,
              0.000722290202476573,
              0,
              0,
            ],
            [
              0.39117964162122487,
              0.0688436725795669,
              0.000722290202476573,
              0,
              0,
            ],
            [
              0.8145616319444445,
              0.0688436725795669,
              0.000722290202476573,
              0,
              0,
            ],
            [1.956597222222222, 0.0688436725795669, 0.000722290202476573, 0, 0],
            [4.511067708333334, 0.339143030455154, 0.000722290202476573, 0, 0],
            [31.0113525390625, 2.6528052313420347, 0.000722290202476573, 0, 0],
            [31.039772033691406, 31.039772033691406, 6.690685267110107, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0.2546106557377049, 0.0010332922717842324, 0, 0, 0],
            [0.297699474897541, 0.0010332922717842324, 0, 0, 0],
            [0.5364612679871705, 0.0010332922717842324, 0, 0, 0],
            [0.5364612679871705, 0.0010332922717842324, 0, 0, 0],
            [0.671096188020111, 0.0010332922717842324, 0, 0, 0],
            [3.237184648870926, 0.06708652533373786, 0, 0, 0],
            [3.7190298643919033, 0.11722126772130015, 0, 0, 0],
            [3.7190298643919033, 0.11722126772130015, 0, 0, 0],
            [10.35683753842213, 0.5213350462682039, 0, 0, 0],
            [30.0234375, 0.5213350462682039, 0, 0, 0],
            [30.0234375, 6.674300999317354, 0, 0, 0],
          ],
          [
            [0.2546106557377049, 0.0010332922717842324, 0, 0, 0],
            [0.297699474897541, 0.0010332922717842324, 0, 0, 0],
            [0.5364612679871705, 0.0010332922717842324, 0, 0, 0],
            [0.5364612679871705, 0.0010332922717842324, 0, 0, 0],
            [0.671096188020111, 0.0010332922717842324, 0, 0, 0],
            [3.237184648870926, 0.06708652533373786, 0, 0, 0],
            [3.7190298643919033, 0.11722126772130015, 0, 0, 0],
            [3.7190298643919033, 0.11722126772130015, 0, 0, 0],
            [10.35683753842213, 0.5213350462682039, 0, 0, 0],
            [30.0234375, 0.5213350462682039, 0, 0, 0],
            [30.0234375, 6.674300999317354, 0, 0, 0],
          ],
          [
            [0.2546106557377049, 0.0010332922717842324, 0, 0, 0],
            [0.297699474897541, 0.0010332922717842324, 0, 0, 0],
            [0.5364612679871705, 0.0010332922717842324, 0, 0, 0],
            [0.5364612679871705, 0.0010332922717842324, 0, 0, 0],
            [0.671096188020111, 0.0010332922717842324, 0, 0, 0],
            [3.237184648870926, 0.06708652533373786, 0, 0, 0],
            [3.7190298643919033, 0.11722126772130015, 0, 0, 0],
            [3.7190298643919033, 0.11722126772130015, 0, 0, 0],
            [10.35683753842213, 0.5213350462682039, 0, 0, 0],
            [30.0234375, 0.5213350462682039, 0, 0, 0],
            [30.0234375, 6.674300999317354, 0, 0, 0],
          ],
          [
            [0.2546106557377049, 0.0010332922717842324, 0, 0, 0],
            [0.297699474897541, 0.0010332922717842324, 0, 0, 0],
            [0.5364612679871705, 0.0010332922717842324, 0, 0, 0],
            [0.5364612679871705, 0.0010332922717842324, 0, 0, 0],
            [0.671096188020111, 0.0010332922717842324, 0, 0, 0],
            [3.237184648870926, 0.06708652533373786, 0, 0, 0],
            [3.7190298643919033, 0.11722126772130015, 0, 0, 0],
            [3.7190298643919033, 0.11722126772130015, 0, 0, 0],
            [10.35683753842213, 0.5213350462682039, 0, 0, 0],
            [30.0234375, 0.5213350462682039, 0, 0, 0],
            [30.0234375, 6.674300999317354, 0, 0, 0],
          ],
        ],
        [
          [
            [0.8619059696276872, 0.015472623743065187, 0, 0, 0],
            [1.1953925461065573, 0.02211199587161928, 0, 0, 0],
            [2.2641990968489942, 0.02211199587161928, 0, 0, 0],
            [2.411362223420163, 0.02211199587161928, 0, 0, 0],
            [4.214211557840673, 0.0824436817354369, 0, 0, 0],
            [4.214211557840673, 0.0824436817354369, 0, 0, 0],
            [4.470549055673544, 0.11285847812932227, 0, 0, 0],
            [9.292717572554801, 0.17398021260245902, 0, 0, 0],
            [14.494162911696058, 0.482711220581224, 0, 0, 0],
            [30.039833068847656, 2.4981359304568307, 0, 0, 0],
            [30.041666638688184, 6.635273037935593, 0, 0, 0],
          ],
          [
            [0.8619059696276872, 0.015472623743065187, 0, 0, 0],
            [1.1953925461065573, 0.02211199587161928, 0, 0, 0],
            [2.2641990968489942, 0.02211199587161928, 0, 0, 0],
            [2.411362223420163, 0.02211199587161928, 0, 0, 0],
            [4.214211557840673, 0.0824436817354369, 0, 0, 0],
            [4.214211557840673, 0.0824436817354369, 0, 0, 0],
            [4.470549055673544, 0.11285847812932227, 0, 0, 0],
            [9.292717572554801, 0.17398021260245902, 0, 0, 0],
            [14.494162911696058, 0.482711220581224, 0, 0, 0],
            [30.039833068847656, 2.4981359304568307, 0, 0, 0],
            [30.041666638688184, 6.635273037935593, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0.8935285412483267, 0.006034369756711409, 0, 0, 0],
            [1.8454514673276439, 0.006034369756711409, 0, 0, 0],
            [2.0995864970297857, 0.006034369756711409, 0, 0, 0],
            [2.4485082256107766, 0.006034369756711409, 0, 0, 0],
            [3.1660757613788486, 0.006034369756711409, 0, 0, 0],
            [3.1660757613788486, 0.006034369756711409, 0, 0, 0],
            [3.1660757613788486, 0.006034369756711409, 0, 0, 0],
            [5.99806452371988, 0.006034369756711409, 0, 0, 0],
            [17.09939243393456, 0.006034369756711409, 0, 0, 0],
            [31.0113525390625, 0.006034369756711409, 0, 0, 0],
            [31.0113525390625, 6.927009490352349, 0, 0, 0],
          ],
          [
            [0.8935285412483267, 0.006034369756711409, 0, 0, 0],
            [1.8454514673276439, 0.006034369756711409, 0, 0, 0],
            [2.0995864970297857, 0.006034369756711409, 0, 0, 0],
            [2.4485082256107766, 0.006034369756711409, 0, 0, 0],
            [3.1660757613788486, 0.006034369756711409, 0, 0, 0],
            [3.1660757613788486, 0.006034369756711409, 0, 0, 0],
            [3.1660757613788486, 0.006034369756711409, 0, 0, 0],
            [5.99806452371988, 0.006034369756711409, 0, 0, 0],
            [17.09939243393456, 0.006034369756711409, 0, 0, 0],
            [31.0113525390625, 0.006034369756711409, 0, 0, 0],
            [31.0113525390625, 6.927009490352349, 0, 0, 0],
          ],
          [
            [0.8935285412483267, 0.006034369756711409, 0, 0, 0],
            [1.8454514673276439, 0.006034369756711409, 0, 0, 0],
            [2.0995864970297857, 0.006034369756711409, 0, 0, 0],
            [2.4485082256107766, 0.006034369756711409, 0, 0, 0],
            [3.1660757613788486, 0.006034369756711409, 0, 0, 0],
            [3.1660757613788486, 0.006034369756711409, 0, 0, 0],
            [3.1660757613788486, 0.006034369756711409, 0, 0, 0],
            [5.99806452371988, 0.006034369756711409, 0, 0, 0],
            [17.09939243393456, 0.006034369756711409, 0, 0, 0],
            [31.0113525390625, 0.006034369756711409, 0, 0, 0],
            [31.0113525390625, 6.927009490352349, 0, 0, 0],
          ],
          [
            [0.8935285412483267, 0.006034369756711409, 0, 0, 0],
            [1.8454514673276439, 0.006034369756711409, 0, 0, 0],
            [2.0995864970297857, 0.006034369756711409, 0, 0, 0],
            [2.4485082256107766, 0.006034369756711409, 0, 0, 0],
            [3.1660757613788486, 0.006034369756711409, 0, 0, 0],
            [3.1660757613788486, 0.006034369756711409, 0, 0, 0],
            [3.1660757613788486, 0.006034369756711409, 0, 0, 0],
            [5.99806452371988, 0.006034369756711409, 0, 0, 0],
            [17.09939243393456, 0.006034369756711409, 0, 0, 0],
            [31.0113525390625, 0.006034369756711409, 0, 0, 0],
            [31.0113525390625, 6.927009490352349, 0, 0, 0],
          ],
        ],
        [
          [
            [
              2.08330718708166,
              0.011901118131291947,
              0.0007263593225671141,
              0,
              0,
            ],
            [
              2.6494160234688757,
              0.011901118131291947,
              0.0007263593225671141,
              0,
              0,
            ],
            [
              3.023228983642905,
              0.011901118131291947,
              0.0007263593225671141,
              0,
              0,
            ],
            [
              3.03667469356593,
              0.011901118131291947,
              0.0007263593225671141,
              0,
              0,
            ],
            [
              3.0808455174866127,
              0.023724455112115128,
              0.0007263593225671141,
              0,
              0,
            ],
            [
              6.410698784722222,
              0.0932865576890897,
              0.0007263593225671141,
              0,
              0,
            ],
            [
              6.410698784722222,
              0.0932865576890897,
              0.0007263593225671141,
              0,
              0,
            ],
            [
              11.960514584379183,
              0.20113004099732262,
              0.0007263593225671141,
              0,
              0,
            ],
            [31.0113525390625, 1.363517219921352, 0.0007263593225671141, 0, 0],
            [
              31.034088134765625,
              3.3093670253932395,
              0.0007263593225671141,
              0,
              0,
            ],
            [31.034088134765625, 6.930361917994967, 7.002550859899329, 0, 0],
          ],
          [
            [
              2.08330718708166,
              0.011901118131291947,
              0.0007263593225671141,
              0,
              0,
            ],
            [
              2.6494160234688757,
              0.011901118131291947,
              0.0007263593225671141,
              0,
              0,
            ],
            [
              3.023228983642905,
              0.011901118131291947,
              0.0007263593225671141,
              0,
              0,
            ],
            [
              3.03667469356593,
              0.011901118131291947,
              0.0007263593225671141,
              0,
              0,
            ],
            [
              3.0808455174866127,
              0.023724455112115128,
              0.0007263593225671141,
              0,
              0,
            ],
            [
              6.410698784722222,
              0.0932865576890897,
              0.0007263593225671141,
              0,
              0,
            ],
            [
              6.410698784722222,
              0.0932865576890897,
              0.0007263593225671141,
              0,
              0,
            ],
            [
              11.960514584379183,
              0.20113004099732262,
              0.0007263593225671141,
              0,
              0,
            ],
            [31.0113525390625, 1.363517219921352, 0.0007263593225671141, 0, 0],
            [
              31.034088134765625,
              3.3093670253932395,
              0.0007263593225671141,
              0,
              0,
            ],
            [31.034088134765625, 6.930361917994967, 7.002550859899329, 0, 0],
          ],
        ],
      ],
    ],
    dimensions: ['month', 'level', 'quantiles', 'duration', 'magw_limit'],
    attributes: {
      description: 'maximum downtime over the years',
      vartype: 'f8',
      units: 'days',
    },
  },
  downtimeMin: {
    values: [
      [
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0.0008939807046979865, 0, 0, 0, 0],
            [0.0008939807046979865, 0, 0, 0, 0],
            [0.0008939807046979865, 0, 0, 0, 0],
            [0.0008939807046979865, 0, 0, 0, 0],
            [0.0008939807046979865, 0, 0, 0, 0],
            [0.0008939807046979865, 0, 0, 0, 0],
            [0.0008939807046979865, 0, 0, 0, 0],
            [0.0008939807046979865, 0, 0, 0, 0],
            [0.12174899722105705, 0, 0, 0, 0],
            [0.12174899722105705, 0, 0, 0, 0],
            [0.12174899722105705, 0, 0, 0, 0],
          ],
          [
            [0.0008939807046979865, 0, 0, 0, 0],
            [0.0008939807046979865, 0, 0, 0, 0],
            [0.0008939807046979865, 0, 0, 0, 0],
            [0.0008939807046979865, 0, 0, 0, 0],
            [0.0008939807046979865, 0, 0, 0, 0],
            [0.0008939807046979865, 0, 0, 0, 0],
            [0.0008939807046979865, 0, 0, 0, 0],
            [0.0008939807046979865, 0, 0, 0, 0],
            [0.12174899722105705, 0, 0, 0, 0],
            [0.12174899722105705, 0, 0, 0, 0],
            [0.12174899722105705, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0.0006148726851851852, 0, 0, 0, 0],
            [0.0006148726851851852, 0, 0, 0, 0],
            [0.0006148726851851852, 0, 0, 0, 0],
            [0.0006148726851851852, 0, 0, 0, 0],
            [0.0006148726851851852, 0, 0, 0, 0],
            [0.0006148726851851852, 0, 0, 0, 0],
            [0.0006148726851851852, 0, 0, 0, 0],
            [0.0006148726851851852, 0, 0, 0, 0],
            [0.012122869845839525, 0, 0, 0, 0],
            [0.38681851028510406, 0, 0, 0, 0],
            [0.38681851028510406, 0, 0, 0, 0],
          ],
          [
            [0.0006148726851851852, 0, 0, 0, 0],
            [0.0006148726851851852, 0, 0, 0, 0],
            [0.0006148726851851852, 0, 0, 0, 0],
            [0.0006148726851851852, 0, 0, 0, 0],
            [0.0006148726851851852, 0, 0, 0, 0],
            [0.0006148726851851852, 0, 0, 0, 0],
            [0.0006148726851851852, 0, 0, 0, 0],
            [0.0006148726851851852, 0, 0, 0, 0],
            [0.012122869845839525, 0, 0, 0, 0],
            [0.38681851028510406, 0, 0, 0, 0],
            [0.38681851028510406, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0.0018890666834002676, 0, 0, 0, 0],
            [0.00494490984772423, 0, 0, 0, 0],
            [0.00494490984772423, 0, 0, 0, 0],
            [0.00494490984772423, 0, 0, 0, 0],
            [0.011834447163654619, 0, 0, 0, 0],
            [0.011834447163654619, 0, 0, 0, 0],
            [0.011834447163654619, 0, 0, 0, 0],
            [0.011834447163654619, 0, 0, 0, 0],
            [0.011834447163654619, 0, 0, 0, 0],
            [0.2301883314508032, 0, 0, 0, 0],
            [0.2301883314508032, 0, 0, 0, 0],
          ],
          [
            [0.0018890666834002676, 0, 0, 0, 0],
            [0.00494490984772423, 0, 0, 0, 0],
            [0.00494490984772423, 0, 0, 0, 0],
            [0.00494490984772423, 0, 0, 0, 0],
            [0.011834447163654619, 0, 0, 0, 0],
            [0.011834447163654619, 0, 0, 0, 0],
            [0.011834447163654619, 0, 0, 0, 0],
            [0.011834447163654619, 0, 0, 0, 0],
            [0.011834447163654619, 0, 0, 0, 0],
            [0.2301883314508032, 0, 0, 0, 0],
            [0.2301883314508032, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
      ],
      [
        [
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
          [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
          ],
        ],
        [
          [
            [0.000335242764261745, 0, 0, 0, 0],
            [0.000335242764261745, 0, 0, 0, 0],
            [0.000335242764261745, 0, 0, 0, 0],
            [0.000335242764261745, 0, 0, 0, 0],
            [0.008333206176757812, 0, 0, 0, 0],
            [0.016482769242869125, 0, 0, 0, 0],
            [0.016482769242869125, 0, 0, 0, 0],
            [0.016482769242869125, 0, 0, 0, 0],
            [0.016482769242869125, 0, 0, 0, 0],
            [0.016482769242869125, 0, 0, 0, 0],
            [0.016482769242869125, 0, 0, 0, 0],
          ],
          [
            [0.000335242764261745, 0, 0, 0, 0],
            [0.000335242764261745, 0, 0, 0, 0],
            [0.000335242764261745, 0, 0, 0, 0],
            [0.000335242764261745, 0, 0, 0, 0],
            [0.008333206176757812, 0, 0, 0, 0],
            [0.016482769242869125, 0, 0, 0, 0],
            [0.016482769242869125, 0, 0, 0, 0],
            [0.016482769242869125, 0, 0, 0, 0],
            [0.016482769242869125, 0, 0, 0, 0],
            [0.016482769242869125, 0, 0, 0, 0],
            [0.016482769242869125, 0, 0, 0, 0],
          ],
        ],
      ],
    ],
    dimensions: ['month', 'level', 'quantiles', 'duration', 'magw_limit'],
    attributes: {
      description: 'minimum downtime over the years',
      vartype: 'f8',
      units: 'days',
    },
  },
  month: {
    values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    dimensions: ['month'],
    attributes: {
      description: 'index of the month',
      vartype: 'i2',
      units: '',
    },
  },
  level: {
    values: [10, 25, 50, 75, 100, 125, 150, 175, 200],
    dimensions: ['level'],
    attributes: {
      conventions: 'positive from seabed',
      vartype: 'f8',
      units: 'm',
      long_name: 'vertical level',
      description: 'vertical level, in meters from mean sea level',
      standard_name: 'vertical level',
    },
  },
  magwLimit: {
    values: [10, 15, 20, 25, 30],
    dimensions: ['magw_limit'],
    attributes: {
      vartype: 'f8',
      description: 'upper limit of magw for operability',
      long_name: 'wind speed',
      valid_max: '100',
      units: 'm.s-1',
      standard_name: 'wind_speed',
      valid_min: '0',
    },
  },
};
