import { COMMON_ERRORS_KEYS } from 'constants/apiErrors';
import {
  conditions,
  MC_OBJECT_PK,
  MC_OBJECT_TYPE,
  UPDATE_ACTION,
  getDefaultOperation,
  OPERATION_DEFAULT_NAME,
  OPERATION_DEFAULT_NAME_REGEX,
} from 'constants/marineContractors';
import {
  selectMarineContractorsBoatIsUpdating,
  selectMarineContractorsBoatIsCreating,
  selectMarineContractorsBoatError,
  selectMarineContractorsConstraintIsCreating,
  selectMarineContractorsConstraintIsUpdating,
  selectMarineContractorsConstraintById,
  selectMarineContractorsBoatById,
  selectMarineContractorsOperationById,
  selectMarineContractorsOperationError,
  selectMarineContractorsConstraintError,
  selectMarineContractorsOperationIsCreating,
  selectMarineContractorsOperationIsUpdating,
} from 'ducks/marineContractors/selectors';
import {
  cleanBoatCreateError,
  cleanBoatUpdateError,
  cleanOperationCreateError,
  cleanOperationUpdateError,
  cleanConstraintCreateError,
  cleanConstraintUpdateError,
  createMarineContractorsBoat,
  createMarineContractorsConstraint,
  createMarineContractorsOperation,
  deleteMarineContractorsBoat,
  deleteMarineContractorsConstraint,
  deleteMarineContractorsOperation,
  updateMarineContractorsBoat,
  updateMarineContractorsConstraint,
  updateMarineContractorsOperation,
} from 'ducks/marineContractors/actions';
import { EMPTY_VALUE, EMPTY_ARRAY } from 'constants/common';

/**
 * Build an item name based on his attributes (if no name attr exists)
 * @param { object } item
 * @param { array } attributes
 * @param { string } separator
 * @returns { string }
 */
export const getItemNameByAttributes = (
  item,
  attributes = [],
  separator = ' '
) =>
  Object.keys(item)
    .filter((k) => !attributes.length || attributes.includes(k))
    .reduce(
      (prev, curr) => [
        ...prev,
        curr === 'condition'
          ? conditions.find(({ name }) => name === item[curr])?.symbol
          : item[curr],
      ],
      []
    )
    .join(separator);

/**
 * Get a fresh operation to insert in database
 * Increment its numerix suffix to avoid an invalid PK exception
 * @param { object } boatId
 * @param { Array } operations
 * @returns { object }
 */
export const getOperation = (boatId, operations) => {
  const operation = getDefaultOperation(boatId, OPERATION_DEFAULT_NAME, 1);
  const matches = operations
    .map((o) => o.name.match(OPERATION_DEFAULT_NAME_REGEX))
    .filter((m) => !!m);
  if (matches.length) {
    let operationNumber = 1;
    const operationsNumber = matches.map((m) => +m[3]).filter((n) => !!n);
    if (operationsNumber.length) {
      const [max] = operationsNumber.sort((a, b) => b - a);
      operationNumber = max + 1;
    }
    operation.name += ` (${operationNumber})`;
  }
  return operation;
};

/**
 * Split errors (preparedErrors) in fields errors and non field error
 * @param {array} errors
 * @returns { object }
 */
export const getErrorMessages = (errors) => {
  const { entityErrors } = errors;
  const fieldErrors = entityErrors?.message || EMPTY_VALUE;
  const status = entityErrors?.status;
  if (status === 500) {
    return { EMPTY_VALUE, EMPTY_ARRAY, status };
  }
  const nonFieldErrors = COMMON_ERRORS_KEYS.reduce(
    (prev, curr) =>
      curr in fieldErrors ? [...prev, fieldErrors[curr]] : [...prev],
    EMPTY_ARRAY
  );
  return { fieldErrors, nonFieldErrors, status };
};

/**
 * Retrieve helper text message or error messsage for a form field name
 * @param { object } fieldsError
 * @param { string } fieldName
 * @param { string } modalName
 * @param { string } action
 * @param { function } t - react-i18next translation function
 * @returns { string }
 */
export const getHelperTextMessage = (
  fieldsError,
  fieldName,
  objectGroup,
  action,
  t
) => {
  if (fieldsError && fieldName in fieldsError) {
    // ListField return object thus, this condition to retrieve the error code
    const [fieldErrorCode] =
      typeof fieldsError === 'object'
        ? Object.values(fieldsError[fieldName])
        : fieldsError[fieldName];
    return t(
      `marineContractors.fields.${objectGroup}.labels.${fieldName}.errors.${fieldErrorCode}`
    );
  }
  return action !== UPDATE_ACTION
    ? t(
        `marineContractors.fields.${objectGroup}.labels.${fieldName}.helperText`
      )
    : '';
};

/**
 * Get Marine contractor type object based on primary key
 * @param { object } object
 * @returns { string }
 */
export const getMCObjectType = (object) => {
  const pk = Object.keys(MC_OBJECT_PK).find((p) =>
    Object.keys(object).includes(p)
  );
  return MC_OBJECT_PK[pk];
};

/**
 * Get actions for the corresponding marine contractors object
 * @param { objectType } string
 * @return {{ createAction: Function, updateAction: Function, deleteAction: Function, cleanCreateAction: Function, cleanUpdateAction: Function }}
 */
export const getMCActions = (objectType) => {
  switch (objectType) {
    case MC_OBJECT_TYPE.BOAT:
      return {
        createAction: createMarineContractorsBoat,
        updateAction: updateMarineContractorsBoat,
        deleteAction: deleteMarineContractorsBoat,
        cleanCreateAction: cleanBoatCreateError,
        cleanUpdateAction: cleanBoatUpdateError,
      };
    case MC_OBJECT_TYPE.OPERATION:
      return {
        createAction: createMarineContractorsOperation,
        updateAction: updateMarineContractorsOperation,
        deleteAction: deleteMarineContractorsOperation,
        cleanCreateAction: cleanOperationCreateError,
        cleanUpdateAction: cleanOperationUpdateError,
      };
    case MC_OBJECT_TYPE.CONSTRAINT:
      return {
        createAction: createMarineContractorsConstraint,
        updateAction: updateMarineContractorsConstraint,
        deleteAction: deleteMarineContractorsConstraint,
        cleanCreateAction: cleanConstraintCreateError,
        cleanUpdateAction: cleanConstraintUpdateError,
      };
    default:
      break;
  }
  throw Error(`unknown marine contractor object type : ${objectType}`);
};

/**
 * Get selectors for the corresponding marine contractors object
 * @param { objectType } string
 * @return {{ getByIdSelector: Function, isCreatingSelector: Function, isUpdatingSelector: Function, errorSelector: Function }}
 */
export const getMCSelectors = (objectType) => {
  switch (objectType) {
    case MC_OBJECT_TYPE.BOAT:
      return {
        getByIdSelector: selectMarineContractorsBoatById,
        isCreatingSelector: selectMarineContractorsBoatIsCreating,
        isUpdatingSelector: selectMarineContractorsBoatIsUpdating,
        errorSelector: selectMarineContractorsBoatError,
      };
    case MC_OBJECT_TYPE.OPERATION:
      return {
        getByIdSelector: selectMarineContractorsOperationById,
        isCreatingSelector: selectMarineContractorsOperationIsCreating,
        isUpdatingSelector: selectMarineContractorsOperationIsUpdating,
        errorSelector: selectMarineContractorsOperationError,
      };
    case MC_OBJECT_TYPE.CONSTRAINT:
      return {
        getByIdSelector: selectMarineContractorsConstraintById,
        isCreatingSelector: selectMarineContractorsConstraintIsCreating,
        isUpdatingSelector: selectMarineContractorsConstraintIsUpdating,
        errorSelector: selectMarineContractorsConstraintError,
      };
    default:
      break;
  }
  throw Error(`unknown marine contractor object type : ${objectType}`);
};

/**
 * getBoatsIdFromJob - extract non duplicated boats object from job operations
 * @param {*} job
 * @returns { Array }
 */
export const getBoatsFromJob = (job) => {
  const operations = job.parameters?.operations;
  if (!operations.length) {
    return EMPTY_ARRAY;
  }
  const boats = operations.reduce((acc, operation) => {
    const boatObj = operation.boatObj;
    if (boatObj === undefined) {
      return acc;
    }
    if (!acc.map((b) => b.id).includes(boatObj.id)) {
      acc.push(operation.boatObj);
    }
    return acc;
  }, []);
  return boats;
};
