import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getZoneDataSet } from 'helpers/zones';
import BaseDataTable from 'components/common/BaseDataTable';
import { formatDate } from 'helpers/date';
import { DATE_FORMATS, EMPTY_ARRAY } from 'constants/common';

import { useStyles } from './styles';

/**
 * Render table with zone dataset information
 * @param {Object} zone
 * @return {jsx}
 */
const ZoneDatasetTable = ({ zone }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const columns = [
    {
      field: 'field',
      title: t('zones.dataset.field'),
    },
    {
      field: 'name',
      title: t('zones.dataset.name'),
    },
    {
      field: 'dateBegin',
      title: t('zones.dataset.period'),
      cell: (dateBegin, { dateEnd }) =>
        t('zones.dataset.periodInterval', {
          dateBegin: formatDate(dateBegin, DATE_FORMATS.commonFormat),
          dateEnd: formatDate(dateEnd, DATE_FORMATS.commonFormat),
        }),
    },
    {
      field: 'spatialResolution',
      title: t('zones.dataset.resolution'),
      cell: (spatialResolution, { spatialResolutionUnits = '' }) =>
        `${spatialResolution}${spatialResolutionUnits}`,
    },
  ];
  const zoneDataset = zone ? getZoneDataSet(zone) : EMPTY_ARRAY;

  return (
    <BaseDataTable
      itemKey="field"
      data={zoneDataset}
      columns={columns}
      fallbackMessage={t('zones.dataset.emptyDataset')}
      className={classes.zoneDataset}
    />
  );
};

ZoneDatasetTable.propTypes = {
  zone: PropTypes.shape({
    name: PropTypes.string,
    field: PropTypes.string,
    dateBegin: PropTypes.string,
    dateEnd: PropTypes.string,
    spatialResolution: PropTypes.string,
    spatialResolutionUnits: PropTypes.string,
  }),
};

export default memo(ZoneDatasetTable);
