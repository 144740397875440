import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  GLOBAL_MAP_DEFAULT_POSITION,
  MAP_PROPERTIES,
  MAP_LAYERS,
  POINT_POSITION,
} from 'constants/map';
import {
  Map,
  MapGraticule,
  MapFeatureGroup,
  MapMouseCoordinates,
  MapSearchControl,
  MapCustomControlLayers,
} from 'components/leaflet';
import { SEARCH_API_URL } from 'common/config';
import SelectZonePopup from 'components/popups/SelectZonePopup';

import ZonesGroup from '../ZonesGroup';

import { useStyles } from './styles';

/**
 * Global zone select Map. Zones are displayed as polygons
 * Adds different colors based on isDemo, isWorld and on selected offer zone ids
 * on zone click zoom up map to clicked zone
 * @param { array } zones - available zones
 * @param { array } offerZoneIds - zone ids available for selected offer
 * @param { Number } offerId
 * @param { Number } selectedZoneId
 * @param { Function } onSelect
 */
const SelectZoneMap = ({
  zones,
  offerZoneIds,
  selectedZoneId,
  offerId,
  onSelect,
}) => {
  const classes = useStyles();

  const onPolygonClick = useCallback(
    ({ layer: { feature } }) => onSelect(feature.properties.id),
    [onSelect]
  );

  return (
    <Map
      wrapperClass={classes.map}
      positionType={POINT_POSITION}
      position={GLOBAL_MAP_DEFAULT_POSITION}
      defaultLayer={MAP_LAYERS.worldImagery}
    >
      <MapSearchControl
        url={SEARCH_API_URL}
        propertyLoc={[MAP_PROPERTIES.latitude, MAP_PROPERTIES.longitude]}
      />
      <MapCustomControlLayers />
      <MapGraticule />
      <MapFeatureGroup>
        <ZonesGroup
          zones={zones}
          selectedZoneId={selectedZoneId}
          offerZoneIds={offerZoneIds}
          onClick={onPolygonClick}
        >
          <SelectZonePopup offerId={offerId} />
        </ZonesGroup>
      </MapFeatureGroup>
      <MapMouseCoordinates />
    </Map>
  );
};

SelectZoneMap.propTypes = {
  zones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      geom: PropTypes.string,
      isWorld: PropTypes.bool,
      isDemo: PropTypes.bool,
    })
  ).isRequired,
  offerZoneIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedZoneId: PropTypes.number,
};

export default memo(SelectZoneMap);
