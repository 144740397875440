import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectZoneById, selectZoneIsLoading } from 'ducks/zones/selectors';
import { requestZone } from 'ducks/zones/actions';
import ZoneDatasetTable from 'components/zones/ZoneDatasetTable';
import { Loader } from 'components/common/Loader';

/**
 * Container for zone information table
 * @param {Number} zoneId
 * @return {jsx}
 */
const ZoneDataset = ({ zoneId }) => {
  const dispatch = useDispatch();
  const zone = useSelector((state) => selectZoneById(state, zoneId));
  const zonesIsLoading = useSelector(selectZoneIsLoading);

  useEffect(() => {
    dispatch(requestZone(zoneId));
  }, [dispatch, zoneId]);

  return zonesIsLoading ? (
    <Loader size={20} justifyCenter />
  ) : (
    <ZoneDatasetTable zone={zone} />
  );
};

ZoneDataset.propTypes = {
  zoneId: PropTypes.number.isRequired,
};

export default ZoneDataset;
