import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  swatch: {
    width: '20px',
    height: '20px',
    transform: 'scale(0.9)',
    transition: '100ms transform ease',
    '&:first-child': {
      borderRadius: '25% 0 0 25%',
    },
    '&:last-child': {
      borderRadius: '0 25% 25% 0',
    },
    '&:hover': {
      zIndex: 1,
      transform: 'scale(1.1)',
    },
  },
});
