import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CONFIRMATION_MODAL } from 'constants/modals';
import EditPointForm from 'components/forms/EditPointForm';
import { deletePoint, updatePoint } from 'ducks/points/actions';
import { useModal } from 'hooks/useModal';
import { getPointGeometry } from 'helpers/map';
import {
  selectIsTraditionalProjectById,
  selectIsRunningProjectById,
  selectProjectZoneByProjectId,
} from 'ducks/projects/selectors';
import {
  selectPointsNamesWithoutCurrentByProject,
  selectPointById,
} from 'ducks/points/selectors';
import { selectCurrentUserIsManager } from 'ducks/user/selectors';
import { trackPointSuppression } from 'ducks/trackers/actions/workzone';

/**
 * Container for edit point form
 * @param {Function} onCancel
 * @param {Object} point
 * @returns {jsx}
 */
const EditPointContainer = ({ onCancel, point }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const isManager = useSelector(selectCurrentUserIsManager);
  const {
    isTraditional,
    isRunning,
    existingNames,
    projectZone,
    pointStore,
  } = useSelector((state) => ({
    projectZone: selectProjectZoneByProjectId(state, point.project),
    isTraditional: selectIsTraditionalProjectById(state, point.project),
    isRunning: selectIsRunningProjectById(state, point.project),
    existingNames: selectPointsNamesWithoutCurrentByProject(
      state,
      point.project,
      point.id
    ),
    pointStore: selectPointById(point.id)(state),
  }));

  const handleUpdatePoint = useCallback(
    ({ lat, lng }) => {
      dispatch(
        updatePoint({
          id: point.id,
          geom: getPointGeometry({ lat, lng }),
          lat: +lat,
          lng: +lng,
          project: point.project,
          name: point.name,
          nbVersion: pointStore.nbVersion,
        })
      );
      onCancel();
    },
    [onCancel, dispatch, point, pointStore]
  );

  const handleDeletePoint = useCallback(() => {
    dispatch(trackPointSuppression());

    const onConfirm = () => {
      dispatch(deletePoint({ pointId: +point.id, projectId: point.project }));
      onCancel();
    };

    openModal(CONFIRMATION_MODAL, {
      title: t('points.deleteTitle'),
      message: t('points.deleteMessage', { name: point.name }),
      onConfirm,
    });
  }, [openModal, dispatch, t, point, onCancel]);

  return (
    <EditPointForm
      allowRemove={!isTraditional && !isRunning && isManager}
      disabled={point.isComputed || isRunning || !isManager}
      lat={point.lat}
      lng={point.lng}
      onSuccess={handleUpdatePoint}
      existingNames={existingNames}
      onCancel={onCancel}
      onRemove={handleDeletePoint}
      zone={projectZone}
    />
  );
};

EditPointContainer.propTypes = {
  onCancel: PropTypes.func.isRequired,
  point: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    id: PropTypes.number,
  }).isRequired,
};

export default memo(EditPointContainer);
