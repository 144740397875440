import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles(({ zIndexMax, palette }) => ({
  loaderOverlay: {
    background: alpha(palette.black.main, 0.4),
    zIndex: zIndexMax,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    position: 'fixed',
  },
}));
