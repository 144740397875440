import { useEffect, useRef } from 'react';

/**
 * Hook to use effect that is not fired on initial mount
 * @param { function } effect - effect to fire
 * @param { array } deps - dependencies
 */
export const useMountedEffect = (effect, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    effect();
    // eslint-disable-next-line
  }, deps);
};
