const prefix = '@@user';

export const USER_LOGIN_REQUEST = `${prefix}/LOGIN_REQUEST`;
export const USER_LOGIN_REQUEST_SUCCESS = `${prefix}/LOGIN_REQUEST_SUCCESS`;
export const USER_REQUEST_ERROR = `${prefix}/USER_REQUEST_ERROR`;
export const FETCH_CURRENT_USER = `${prefix}/FETCH_CURRENT_USER`;
export const FETCH_CURRENT_USER_SUCCESS = `${prefix}/FETCH_CURRENT_USER_SUCCESS`;
export const USER_LOGOUT = `${prefix}/LOGOUT`;
export const REMOVE_USER_REQUEST = `${prefix}/REMOVE_USER`;
export const REMOVE_USER_SUCCESS = `${prefix}/REMOVE_USER_SUCCESS`;
export const REMOVE_USER_ERROR = `${prefix}/REMOVE_USER_ERROR`;
export const INVITE_USER_REQUEST = `${prefix}/INVITE_USER_REQUEST`;
export const INVITE_USER_SUCCESS = `${prefix}/INVITE_USER_SUCCESS`;
export const INVITE_USER_ERROR = `${prefix}/INVITE_USER_ERROR`;
export const CHANGE_USER_SETTINGS = `${prefix}/CHANGE_USER_SETTINGS`;
export const GET_USER_SETTINGS = `${prefix}/GET_USER_SETTINGS`;
export const PUT_USER_SETTINGS = `${prefix}/PUT_USER_SETTINGS`;
export const USER_SIGNUP_REQUEST = `${prefix}/USER_SIGNUP_REQUEST`;
export const USER_SIGNUP_REQUEST_SUCCESS = `${prefix}/USER_SIGNUP_REQUEST_SUCCESS`;
export const CHECK_IS_REGISTRATION_PENDING = `${prefix}/CHECK_IS_REGISTRATION_PENDING`;
export const CHECK_IS_REGISTRATION_PENDING_SUCCESS = `${prefix}/CHECK_IS_REGISTRATION_PENDING_SUCCESS`;
export const CHECK_IS_REGISTRATION_PENDING_ERROR = `${prefix}/CHECK_IS_REGISTRATION_PENDING_ERROR`;
export const ACTIVATE_REGISTRATION_REQUEST = `${prefix}/ACTIVATE_REGISTRATION_REQUEST`;
export const USER_RESET_PASSWORD_REQUEST = `${prefix}/USER_RESET_PASSWORD_REQUEST`;
export const USER_RESET_PASSWORD_REQUEST_SUCCESS = `${prefix}/USER_RESET_PASSWORD_REQUEST_SUCCESS`;
export const USER_RESET_PASSWORD_CONFIRM_REQUEST = `${prefix}/USER_RESET_PASSWORD_CONFIRM_REQUEST`;
export const USER_RESET_PASSWORD_CONFIRM_REQUEST_SUCCESS = `${prefix}/USER_RESET_PASSWORD_CONFIRM_REQUEST_SUCCESS`;
export const EDIT_USER_PROFILE_REQUEST = `${prefix}/EDIT_USER_PROFILE_REQUEST`;
export const EDIT_USER_PROFILE_REQUEST_SUCCESS = `${prefix}/EDIT_USER_PROFILE_REQUEST_SUCCESS`;
export const EDIT_USER_PROFILE_REQUEST_ERROR = `${prefix}/EDIT_USER_PROFILE_REQUEST_ERROR`;
export const RESEND_USER_INVITATION_REQUEST = `${prefix}/RESEND_USER_INVITATION_REQUEST`;
export const RESEND_USER_INVITATION_REQUEST_SUCCESS = `${prefix}/RESEND_USER_INVITATION_REQUEST_SUCCESS`;
export const RESEND_USER_INVITATION_REQUEST_ERROR = `${prefix}/RESEND_USER_INVITATION_REQUEST_ERROR`;
export const REVOKE_USER_INVITATION_REQUEST = `${prefix}/REVOKE_USER_INVITATION_REQUEST`;
export const REVOKE_USER_INVITATION_REQUEST_SUCCESS = `${prefix}/REVOKE_USER_INVITATION_REQUEST_SUCCESS`;
export const REVOKE_USER_INVITATION_REQUEST_ERROR = `${prefix}/REVOKE_USER_INVITATION_REQUEST_ERROR`;
export const CLEAR_USER_ERROR = `${prefix}/CLEAR_USER_ERROR`;
