import { makeStyles } from '@material-ui/core/styles';

import { theme } from 'common/theme';

export const useStyles = makeStyles({
  dvContentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  mapContainer: {
    height: '70vh',
    width: '100%',
  },
  dvParametersContainer: {
    marginTop: 20,
    padding: 10,
  },
  dvTitle: {
    fontSize: 22,
    fontWeight: 600,
    color: theme.palette.sinay.primary['40'],
    marginBottom: 15,
  },
  parametersSection: {
    marginBottom: 20,
  },
  parametersTitle: {
    fontWeight: 600,
    fontSize: 14,
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.sinay.neutral['60'],
  },
  highlightText: {
    color: theme.palette.sinay.primary['40'],
  },
  introContainer: {
    marginBottom: 10,
  },
  subtitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    marginBottom: 10,
  },
  subtitle: {
    color: '#777680',
    textTransform: 'uppercase',
    margin: 0,
    fontWeight: 600,
  },
  introText: {
    margin: 0,
    fontSize: 13,
    fontWeight: 600,
  },
});
