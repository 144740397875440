import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    height: '100%',
    paddingTop: 75,
  },
  sideBarContent: {
    overflowY: 'auto',
    height: '100%',
    padding: 20,
  },
});
