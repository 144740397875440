import { useMemo } from 'react';

import { DEFAULT_GRAPH_SELECT_VALUE } from 'constants/graphs';
import { useFields } from 'hooks/useFields';
import { INVALID_PARAMS_AMOUNT } from 'constants/errors';

const VALID_PARAMS_AMOUNT = 2;

/**
 * hook that helps to work with graphs joint selects.
 * Build index from two joint selects
 * @note works for 2 params only (each select controls own grid axis)
 * @note select option values should be same as option index: 0,1,2..
 * @param { array } names - array of param names
 * @param { number } firstSelectSize - first select options amount
 * @returns {{ handleChange: function, setParam: function, index: string, params: object }}
 * @example how algorithm works (both selects has 3 options in example)
 * ('l.x r.x: i', l/r - left/right selector, x - selected option, i - index)
 * l.0 r.0: 0,
 * l.1 r.0: 1,
 * l.2 r.0: 2,
 * l.0 r.1: 3,
 * l.1 r.1: 4,
 * l.2, r.1: 5,
 * l.0, r.2: 6,
 * l.1, r.2: 7,
 * l.2, r.2: 8,
 */
export const useGraphJointSelects = ({ names = [], firstSelectSize }) => {
  const { initialValues, firstParam, secondParam } = useMemo(
    () => {
      if (names.length !== VALID_PARAMS_AMOUNT) {
        throw Error(INVALID_PARAMS_AMOUNT);
      }
      const [first, second] = names;

      return {
        firstParam: first,
        secondParam: second,
        initialValues: {
          [first]: +DEFAULT_GRAPH_SELECT_VALUE,
          [second]: +DEFAULT_GRAPH_SELECT_VALUE,
        },
      };
    },
    // eslint-disable-next-line
    []
  );
  const { handleChange, setField, values } = useFields(initialValues);
  const index = values[secondParam] * firstSelectSize + values[firstParam];

  return {
    handleChange,
    setParam: setField,
    params: values,
    index,
  };
};
