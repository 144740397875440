import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { selectCurrentUser } from 'ducks/user/selectors';
import BaseModal from 'components/common/BaseModal';

import { useStyles } from './styles';

/**
 * ConfirmationDeleteModal component
 * @param {function} onClose
 */
const ContactUsModal = ({ onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);

  const [formData, setFormData] = useState({
    firstName: currentUser?.firstName || '',
    lastName: currentUser?.lastName || '',
    email: currentUser?.email || '',
    phone: '',
    companyName: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const submitForm = () => {
    const portalId = '5405390';
    const formId = 'c47b5c6d-1430-4504-8069-b4acf313eead';
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;
    const data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'firstname',
          value: formData.firstName,
        },
        {
          name: 'lastname',
          value: formData.lastName,
        },
        {
          name: 'email',
          value: formData.email,
        },
        {
          name: 'phone',
          value: formData.phone,
        },
        {
          name: 'website',
          value: formData.companyName,
        },
        {
          name: 'message',
          value: formData.message,
        },
      ],
    };
    axios.post(url, data);
    onClose();
  };

  const controls = (
    <div className={classes.buttons}>
      <Button className={classes.cancelButton} onClick={onClose}>
        {t('buttons.cancel')}
      </Button>
      <Button onClick={submitForm} className={classes.submitButton}>
        {t('buttons.submit')}
      </Button>
    </div>
  );

  return (
    <BaseModal
      title="Contact Us"
      onClose={onClose}
      controls={controls}
      modalClass={classes.container}
    >
      <form onSubmit={submitForm} className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              name="firstName"
              label="First Name"
              value={formData.firstName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              name="lastName"
              label="Last Name"
              value={formData.lastName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              name="email"
              label="Email"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              name="phone"
              label="Phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              name="companyName"
              label="Company Name"
              value={formData.companyName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              name="message"
              label="Message"
              multiline
              minRows={4}
              value={formData.message}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </form>
    </BaseModal>
  );
};

ContactUsModal.propTypes = {
  onClose: PropTypes.func,
};

export default React.memo(ContactUsModal);
