import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  tabsContainer: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  statisticsTabs: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    marginBottom: 10,
  },
  definitionTabs: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    marginBottom: 10,
  },
  tabRoot: {
    minHeight: 20,
    minWidth: 0,
    padding: 10,
    backgroundColor: '#E4E1E9',
    textTransform: 'none',
    '&:focus': {
      outline: 'none',
    },
  },
  tabSelected: {
    backgroundColor: '#F5F2FA',
    borderBottom: `3px solid ${palette.secondary.main}`,
  },
  section: {
    marginTop: 10,
  },
  table: {
    '& table': {
      margin: 0,
    },
    '& td, th': {
      borderCollapse: 'collapse',
      borderSpacing: 0,
      border: palette.table.border,
      textAlign: 'center',
      fontSize: 12,
      padding: 5,
    },
    '& th': {
      color: '#0F154B',
      backgroundColor: palette.table.header,
      minWidth: 100,
    },
    '& td:first-child': {
      color: '#0F154B',
      backgroundColor: palette.table.firstColumn,
    },
    '& tr:nth-child(even)': {
      backgroundColor: palette.table.columnEven,
    },
    '& tr:nth-child(odd)': {
      backgroundColor: palette.table.columnOdd,
    },
  },
}));
