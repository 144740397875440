import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

/**
 * Spacer to add "linebreaks" or custom height vertical offsets
 * @param { number|string } height, by default 15px
 * @returns { JSX }
 */
const Spacer = ({ height }) => {
  const classes = useStyles({ height });

  return <div className={classes.spacer} />;
};

Spacer.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Spacer.degaultProps = {
  height: 15,
};

export default Spacer;
