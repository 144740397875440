import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { MapFeatureGroup } from 'components/leaflet';
import { getComputedPoints } from 'helpers/points';
import ProjectMarker from 'components/maps/ProjectMarker';
import OverviewOceanConditionsPopup from 'components/popups/OverviewOceanConditionsPopup';
import { trackAnalyzePointSelection } from 'ducks/trackers/actions/workzone';

/**
 * SelectableOverviewPointFeatureGroup - Feature group with selected markers
 * and overview popup for all computed points
 * @param {Array} points
 * @param {Array} selectedMarkers
 * @param {Boolean} isOverviewShown
 * @param {Function} selectMarker
 * @param {React.ReactNode} children
 * @returns {jsx}
 */
const SelectableOverviewPointFeatureGroup = ({
  points,
  selectedMarkers,
  selectMarker,
  isOverviewShown,
}) => {
  const computedPoints = useMemo(() => getComputedPoints(points), [points]);
  const dispatch = useDispatch();

  const handleClickMarker = (e) => {
    selectMarker(e);
    dispatch(trackAnalyzePointSelection('Marker'));
  };

  return (
    <MapFeatureGroup onClick={handleClickMarker}>
      {computedPoints.map((point) => (
        <ProjectMarker
          {...point}
          key={point.id}
          selected={selectedMarkers.includes(point.id)}
          allowOpenPopupOnSelect
        >
          {isOverviewShown && <OverviewOceanConditionsPopup point={point} />}
        </ProjectMarker>
      ))}
    </MapFeatureGroup>
  );
};

SelectableOverviewPointFeatureGroup.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isComputed: PropTypes.bool.isRequired,
    })
  ).isRequired,
  selectedMarkers: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectMarker: PropTypes.func.isRequired,
  isOverviewShown: PropTypes.bool,
};

export default memo(SelectableOverviewPointFeatureGroup);
