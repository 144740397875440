import { TYPE_LIGHT, TYPE_OFFER } from 'constants/projects';
import { PROJECT_REPORTS_ALL_VALUE } from 'constants/reports';
import { replaceAll } from 'helpers/common';
import { PROJECT_NAME_DEPRECATED_REGEXP } from 'constants/regexp';
import { ANNUAL_INDEX, DOWNLOADED_GRAPH_FILES_FORMATS } from 'constants/graphs';
import { MONTH_NAMES } from 'constants/common';

export const isOfferType = (type) => type === TYPE_OFFER;
export const isLightType = (type) => type === TYPE_LIGHT;

/**
 * returns user actions summary by parsing projects data
 * @param projects
 * @param user
 */
export const getUserActionsSummaryByProjects = ({ projects, user }) =>
  projects.reduce(
    (acc, project) => {
      if (project.user !== user.id) {
        return acc;
      }
      acc.projects += 1;
      project.computations.forEach(
        ({ spentBy, isDowntime, reports, credits }) => {
          if (spentBy === user.username) {
            acc.reports += reports.length;
            acc.credits += credits;
            acc.computations += 1;
            acc.awdComputations += isDowntime ? 1 : 0;
          }
        }
      );
      return acc;
    },
    { projects: 0, credits: 0, reports: 0, computations: 0, awdComputations: 0 }
  );

/**
 * Returns object with project ids and project collaborators,
 * for example - {{671: [110, 150, 154]}, {814: [110, 134, 148, 115]}}
 * @param {Array} selectedProjects - project ids that was selected (checked) in GrantAccessForCollaboratorForm
 * @param {Array} changedProjects - project ids that was changed (checked/unchecked) in GrantAccessForCollaboratorForm
 * @param {Array} projectsAvailableToUser - projects ids of which user is collaborator
 * @param {Object} projectsData - all projects
 * @param {number} collaboratorId - id of user who be added/removed in project
 * If projectsAvailableToUser exists it means that user already has a projects, and in GrantAccessForCollaboratorForm
 * projects can be not only added but also removed, and we use changedProjects array to get the data.
 * Otherwise the user is new, he has no projects, and projects in GrantAccessForCollaboratorForm can only be added.
 */
export const getProjectCollaboratorsIds = (
  selectedProjects,
  changedProjects,
  projectsAvailableToUser,
  projectsData,
  collaboratorId
) =>
  !projectsAvailableToUser
    ? selectedProjects.reduce((acc, projectId) => {
        acc[projectId] = [
          ...projectsData[projectId].collaborators.map((item) => item.id),
          collaboratorId,
        ];
        return acc;
      }, {})
    : changedProjects.reduce((acc, item) => {
        const newCollaborators = projectsData[item.id]?.collaborators.reduce(
          (collaboratorsIds, collaborator) => {
            if (collaborator.id !== collaboratorId) {
              collaboratorsIds = [...collaboratorsIds, collaborator.id];
            }
            return collaboratorsIds;
          },
          []
        );
        acc[item.id] =
          item.isChecked === true
            ? [...newCollaborators, collaboratorId]
            : newCollaborators;
        return acc;
      }, {});

/**
 * Updates project collaborators
 * If projectCollaborators item contains user id, then add user to project collaborators,
 * otherwise remove user from project collaborators
 * @param {Object} state - projects state
 * @param {Object} projectCollaborators - object with project ids and project collaborators
 * @param {Object} user - user data
 * @returns {{}}
 */
export const updateProjectCollaborators = (state, projectCollaborators, user) =>
  Object.entries(projectCollaborators).reduce(
    (acc, [projectId, collaboratorsIds]) => {
      const { collaborators: prevCollaborators } = state.data[projectId];
      const collaborators = collaboratorsIds.includes(user.id)
        ? [...prevCollaborators, user]
        : prevCollaborators.filter(({ id }) => id !== user.id);
      acc[projectId] = {
        ...state.data[projectId],
        collaborators,
      };
      return acc;
    },
    {}
  );

/**
 * generates report name from value
 */
export const generateReportName = (value) =>
  value.length === 0 ? PROJECT_REPORTS_ALL_VALUE : value.join('-');

/**
 * Prepare project name, replace all not available characters
 * @param {String} offerName
 * @param {String} zoneName
 * @param {String} separator
 * @param {String} replacer
 * @return {string}
 */
export const prepareTrialProjectName = ({
  offerName,
  zoneName,
  separator = '-',
  replacer = '_',
}) => {
  const nameWithoutDeprecatedCharacter = replaceAll(
    `${offerName}${separator}${zoneName}`,
    PROJECT_NAME_DEPRECATED_REGEXP,
    replacer
  );
  const nameWithoutDuplicate = replaceAll(
    nameWithoutDeprecatedCharacter,
    replacer.repeat(2),
    replacer
  );

  return replaceAll(
    nameWithoutDuplicate,
    `${replacer}${separator}${replacer}`,
    replacer
  );
};

/**
 * Prepares params for xhr request for downloading graph file
 * @param {Array} statsIds
 * @param {number} monthNumber
 * @param {string} vclassType
 * @param {Object} pdSelectRange
 * @param {number} currentLevel
 * @param {Array} fileFormat
 */
/* eslint-disable no-unused-vars */
export const prepareParamsForGraphDownloading = ({
  statsIds,
  monthNumber,
  vclassType,
  pdSelectRange,
  currentLevel,
  fileFormat,
}) => {
  const [fileType] = fileFormat;
  const filteredIdsByMonth = statsIds.filter((item) => {
    const isIdContainsMonth = item.includes('month');
    if ((monthNumber === ANNUAL_INDEX || !monthNumber) && !isIdContainsMonth) {
      return item;
    }
    if (isIdContainsMonth) {
      return item;
    }
    return null;
  });
  const fileName = filteredIdsByMonth.find((item) => {
    const isIdContainsVC = item.includes(vclassType);
    if (!pdSelectRange && !isIdContainsVC) {
      return item;
    }
    if (isIdContainsVC) {
      return item;
    }
    return null;
  });

  const params = {
    file_name: fileName,
    stat_id: fileName,
  };

  if (monthNumber && monthNumber !== ANNUAL_INDEX) {
    const monthName = MONTH_NAMES[monthNumber - 1];
    params.file_name = `${params.file_name}_${monthName}`;
    params.month = monthNumber - 1;
  }

  //  if (!!currentLevel || currentLevel === 0) {
  //    params.file_name +=
  //      fileType === EXCEL_GRAPH_DATA_FORMAT
  //        ? `_at_level_index_${currentLevel}`
  //        : '';
  //    params.export_level_idx = currentLevel;
  //  }

  if (pdSelectRange) {
    const arrayOfIndexes = [];
    for (let i = pdSelectRange.start; i <= pdSelectRange.end; i += 1) {
      arrayOfIndexes.push(i);
    }
    const stringOfIndexes = arrayOfIndexes.join();
    const vcClassParameter = `${vclassType}_class`;
    params[vcClassParameter] = stringOfIndexes;
  }
  params.file_name += `.${DOWNLOADED_GRAPH_FILES_FORMATS[fileType]}`;

  return params;
};
/* eslint-enable no-unused-vars */

/**
 * Checks if offer is unlimited
 * @param {Object} projectOffer - offer instance of current project
 * @param {boolean} isTraditionalProjectType - if true type of project is traditional (type = 2)
 * @param {boolean} hasUnlimitedDiscover - boolean from accounts pack
 * @param {boolean} hasUnlimitedFullStudy - boolean from accounts pack
 */
export const checkIsOfferUnlimited = ({
  projectOffer,
  isTraditionalProjectType,
  hasUnlimitedDiscover,
  hasUnlimitedFullStudy,
}) => {
  if (!projectOffer) {
    return isTraditionalProjectType
      ? hasUnlimitedDiscover
      : hasUnlimitedFullStudy;
  }
  return projectOffer.isUnlimited;
};

/**
 * Checks if project offer is expired
 * @param project
 * @returns {boolean}
 */
export const checkIsOfferExpired = (project) => {
  const { offerInstance = null } = project;
  const currentDate = new Date();
  const offerEndDate = offerInstance ? new Date(offerInstance.offerEnd) : null;
  return offerInstance
    ? currentDate.getTime() >= offerEndDate.getTime()
    : false;
};
