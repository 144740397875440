import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  footer: {
    padding: '10px 24px',
    position: 'relative',
    bottom: 0,
    '&.MuiAppBar-colorPrimary': {
      backgroundColor: 'white',
    },
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  logo: {
    '& img': {
      height: '40px',
      maxWidth: '100%',
    },
  },
  link: {
    color: 'black',
    marginLeft: 20,
  },
  text: {
    color: 'black',
  },
}));
