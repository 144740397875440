import { createSelector } from 'reselect';

import { EMPTY_ARRAY } from 'constants/common';

export const selectGeometriesData = ({ geometries }) => geometries.data;

export const selectGeometriesIds = ({ geometries }) => geometries.ids;

export const selectGeometriesByProjectId = ({ geometries }, projectId) =>
  geometries.data[projectId] || EMPTY_ARRAY;

export const selectGeometriesCollection = createSelector(
  selectGeometriesData,
  selectGeometriesIds,
  (geometriesIds, geometriesData) => {
    geometriesIds.reduce((acc, id) => {
      acc.push(...geometriesData[id]);
      return acc;
    }, []);
  }
);
