import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import PropertiesList from 'components/points/PropertiesList';
import { scrollToElement } from 'helpers/common';

const ZONES = 'zones';

/**
 * Zones list with description
 * @param {Array} otherZones
 * @param {Array} demoZones
 * @param {Array} offerZones
 * @param {Function} onChange
 * @param {Number} selectedZoneId
 * @returns {jsx}
 */
const ZonesList = ({
  otherZones,
  demoZones,
  offerZones,
  onChange,
  selectedZoneId,
}) => {
  const { t } = useTranslation();

  const handleSelect = useCallback(({ id }) => onChange(id), [onChange]);

  useEffect(() => {
    if (selectedZoneId) {
      scrollToElement(`${ZONES}-${selectedZoneId}`, { block: 'center' });
    }
  }, [selectedZoneId]);

  return (
    <div>
      <PropertiesList
        title={t('zones.offer')}
        idName={ZONES}
        items={offerZones}
        selectedId={selectedZoneId}
        onItemClick={handleSelect}
      />
      <PropertiesList
        title={t('zones.demo')}
        idName={ZONES}
        items={demoZones}
        selectedId={selectedZoneId}
        onItemClick={handleSelect}
      />
      <PropertiesList
        title={t('zones.other')}
        idName={ZONES}
        items={otherZones}
        selectedId={selectedZoneId}
        onItemClick={handleSelect}
      />
    </div>
  );
};

const zoneShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

ZonesList.propTypes = {
  otherZones: PropTypes.arrayOf(zoneShape).isRequired,
  demoZones: PropTypes.arrayOf(zoneShape).isRequired,
  offerZones: PropTypes.arrayOf(zoneShape).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedZoneId: PropTypes.number,
};

export default React.memo(ZonesList);
