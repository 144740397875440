import { EMPTY_ARRAY, EMPTY_VALUE } from 'constants/common';

export const selectPlanningsError = ({ plannings }) =>
  plannings.error || EMPTY_VALUE;

export const selectPlanningsListByProjectId = ({ plannings }, projectId) =>
  plannings.data[projectId] || EMPTY_ARRAY;

export const selectPlanningsStatus = ({ plannings }) => plannings.status;

export const selectPlanningFileNameById = (
  { plannings },
  projectId,
  planningId
) => plannings.data[projectId].find((item) => item.id === planningId).name;
