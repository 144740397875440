import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { MapFeatureGroup } from 'components/leaflet';
import ProjectMarker from 'components/maps/ProjectMarker';
import { getComputedPoints } from 'helpers/points';

/**
 * SelectablePointFeatureGroup - Feature group with selected markers
 * @param {Array} points
 * @param {Array} selectedMarkers
 * @param {Function} selectMarker
 * @param {Boolean} onlyComputed
 * @param {React.ReactNode} children
 * @returns {jsx}
 */
const SelectablePointFeatureGroup = ({
  points,
  selectedMarkers,
  onlyComputed,
  selectMarker,
  children,
  noPointsCompute = false,
}) => {
  const computedPoints = useMemo(
    () => (onlyComputed ? getComputedPoints(points) : points),
    [points, onlyComputed]
  );

  return (
    <MapFeatureGroup onClick={selectMarker}>
      {computedPoints.map(
        ({ isComputed, hasSatelliteValidation, id, ...point }) => (
          <ProjectMarker
            {...point}
            key={id}
            id={id}
            selected={selectedMarkers.includes(id)}
            isComputed={isComputed || noPointsCompute}
            hasSatelliteValidation={hasSatelliteValidation}
          >
            {children}
          </ProjectMarker>
        )
      )}
    </MapFeatureGroup>
  );
};

SelectablePointFeatureGroup.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isComputed: PropTypes.bool.isRequired,
      hasSatelliteValidation: PropTypes.bool.isRequired,
    })
  ).isRequired,
  selectedMarkers: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectMarker: PropTypes.func.isRequired,
  children: PropTypes.node,
  onlyComputed: PropTypes.bool,
};

SelectablePointFeatureGroup.defaultProps = {
  onlyComputed: false,
};

export default memo(SelectablePointFeatureGroup);
