import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import ExtremeCharts from 'components/common/graphs/ExtremeCharts';

import { useStyles } from './styles';

/**
 * Extreme charts component with laws switcher, shows extreme values for different distribution laws
 * Consists of: Extreme analysis table, Return values chart, Rose peak chart.
 * All of that with outliers.
 *
 * If might also return a simple textbox if the stat failed.
 *
 * @param { object } annualData
 * @param { number } projectId
 * @param { Array } statsIds
 * @returns { JSX }
 * @see ExtremeValueAnalysisTable
 * @see ReturnValuesPlot
 * @see ExtremePeakRose
 */
const NonCyclonicExtremeCharts = ({ annualData, projectId, statsIds }) => {
  const classes = useStyles();
  const { title, info, succeed } = annualData;
  if (!succeed) {
    return (
      <div>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        {info || ''}
      </div>
    );
  }
  return (
    <ExtremeCharts
      annualData={annualData}
      projectId={projectId}
      statsIds={statsIds}
      hasOutliers
    />
  );
};

const dataShape = {
  tableData: PropTypes.object.isRequired,
  returnValuesData: PropTypes.object.isRequired,
  peakRoseData: PropTypes.object,
};

NonCyclonicExtremeCharts.propTypes = {
  annualData: PropTypes.shape({
    title: PropTypes.string,
    succeed: PropTypes.bool.isRequired,
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
    data: PropTypes.oneOfType([
      PropTypes.shape(dataShape),
      PropTypes.arrayOf(PropTypes.shape(dataShape)),
      PropTypes.instanceOf(null),
    ]),
  }),
  projectId: PropTypes.number,
  statsIds: PropTypes.array,
};

export default NonCyclonicExtremeCharts;
