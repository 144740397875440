import React from 'react';
import { floor } from 'lodash';
import PropTypes from 'prop-types';

import { UNIT_REGEXP } from 'constants/regexp';

import { useStyles } from './styles';

const prepareTitle = (value, baseTitle, extraTitle = '') =>
  baseTitle
    .toLowerCase()
    .replace(UNIT_REGEXP, `$1 ${extraTitle}: ${floor(value, 1)}$2`);

const CustomTooltip = ({ active, payload, yBaseTitle, xBaseTitle, label }) => {
  const classes = useStyles();
  if (!active) {
    return null;
  }
  const averageValue = payload[1].value;
  const rangeValue = averageValue - payload[0].value[0];

  return (
    <div className={classes.container}>
      <div>{prepareTitle(label, yBaseTitle)}</div>
      <div>{prepareTitle(averageValue, xBaseTitle, 'average')}</div>
      <div>{prepareTitle(rangeValue, xBaseTitle, 'std')}</div>
    </div>
  );
};

CustomTooltip.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.arrayOf(PropTypes.number),
      ]),
    })
  ),
  yBaseTitle: PropTypes.string.isRequired,
  xBaseTitle: PropTypes.string.isRequired,
};

export default CustomTooltip;
