import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import { X } from 'phosphor-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { EMPTY_FUNCTION, TRANSITION_TIMEOUT } from 'constants/common';

import { useStyles } from './styles';

/**
 * Header for base modal. If there's no title, renders just close Icon, without header block
 * @param title
 * @param onClose
 * @param classes
 * @param warning
 * @returns { JSX }
 */
const BaseModalHeader = ({ title, onClose, classes, warning }) =>
  title ? (
    <div className={classNames(classes.modalHeader, warning ? 'warning' : '')}>
      <div className={classes.title}>{title}</div>
      <IconButton onClick={onClose} className="modal_close-button">
        <X size={24}></X>
      </IconButton>
    </div>
  ) : (
    <IconButton
      onClick={onClose}
      className={classNames('modal_close-button', classes.noHeaderCloseIcon)}
    >
      <X size={24}></X>
    </IconButton>
  );

/**
 * Base modal component to compose custom modals
 * takes controls component - buttons, etc..
 * takes custom modal class to override default styles
 * contains some static class names to allow overriding from given modalClass
 * @param {String} title
 * @param {React.ReactNode} children
 * @param {React.ReactNode} controls
 * @param {Function} onClose
 * @param {String} modalClass
 * @param {Boolean} warning

 * @returns {jsx}
 */
const BaseModal = ({
  title,
  children,
  controls,
  onClose,
  modalClass,
  warning,
}) => {
  const classes = useStyles();

  return (
    <Modal
      open
      onClose={onClose}
      BackdropProps={{ timeout: TRANSITION_TIMEOUT }}
      className={classes.modalStyle}
    >
      <Fade timeout={TRANSITION_TIMEOUT} in>
        <div className={classNames(classes.modalContent, modalClass)}>
          <BaseModalHeader
            title={title}
            onClose={onClose}
            classes={classes}
            warning={warning}
          />
          <div className={classes.modalBody}>{children}</div>
          {controls && <div className={classes.controls}>{controls}</div>}
        </div>
      </Fade>
    </Modal>
  );
};

BaseModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  controls: PropTypes.node,
  onClose: PropTypes.func,
  modalClass: PropTypes.string,
  warning: PropTypes.bool,
};

BaseModal.defaultProps = {
  title: '',
  controls: null,
  onClose: EMPTY_FUNCTION,
};

export default BaseModal;
