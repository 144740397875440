import { Validator } from 'helpers/Validator';
import { required } from 'validators';
import { BLANK_FIELD_MESSAGE } from 'constants/errors';

/**
 * validation rules
 */
export const validationRules = {
  firstName: new Validator([required(BLANK_FIELD_MESSAGE)]),
  lastName: new Validator([required(BLANK_FIELD_MESSAGE)]),
};
