import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Routes from 'components/app/Routes';
import { Loader } from 'components/common/Loader';

const App = ({ isAuthorized, isInitialized, isExpired }) =>
  !isInitialized ? (
    <Loader center />
  ) : (
    <Routes isAuthorized={isAuthorized} isExpired={isExpired} />
  );

App.propTypes = {
  isInitialized: PropTypes.bool.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
  isExpired: PropTypes.bool.isRequired,
};

export default memo(App);
