import React, { memo, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectUserDetails,
  selectUserShowOverviewControl,
} from 'ducks/user/selectors';
import MyProfilePageComponent from 'components/profile/MyProfilePage';
import { changeUserSettings, getUserSettings } from 'ducks/user/actions';

/**
 * MyProfilePage container
 * Displays user profile information
 */
const MyProfilePage = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector(selectUserDetails);
  const showOverviewControl = useSelector(selectUserShowOverviewControl);
  useEffect(() => {
    dispatch(getUserSettings());
  }, [dispatch]);

  const checkboxHandler = useCallback(
    ({ target }) => {
      dispatch(changeUserSettings({ showOverviewControl: target.checked }));
    },
    [dispatch]
  );

  return (
    <MyProfilePageComponent
      userDetails={userDetails}
      showOverviewControl={showOverviewControl}
      checkboxHandler={checkboxHandler}
    />
  );
};

export default memo(MyProfilePage);
