import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { MapMarker } from 'components/leaflet';
import { useMarkerIcon } from 'hooks/useMarkerIcon';

/**
 * ProjectMarker - marker for static project card
 * @param {Number} lat
 * @param {Number} lng
 * @param {String} name
 * @param {String|Number} id
 * @param {String|Number} project
 * @param {Boolean} isComputed
 * @param {Boolean} hasSatelliteValidation
 * @param {Boolean} selected
 * @param {Object} rest
 * @returns {jsx}
 */
const ProjectMarker = ({
  lat,
  lng,
  isComputed = false,
  hasSatelliteValidation = false,
  selected = false,
  name,
  id,
  project,
  ...rest
}) => {
  const { iconUrl, iconSize, iconAnchor, popupAnchor } = useMarkerIcon({
    isComputed,
    hasSatelliteValidation,
    selected,
  });
  const position = useMemo(() => [lat, lng], [lat, lng]);
  const customProps = useMemo(
    () => ({ project, name, lat, lng, id, isComputed, hasSatelliteValidation }),
    [name, lat, lng, id, project, isComputed, hasSatelliteValidation]
  );

  return (
    <MapMarker
      selected={selected}
      position={position}
      iconUrl={iconUrl}
      iconSize={iconSize}
      iconAnchor={iconAnchor}
      popupAnchor={popupAnchor}
      customProps={customProps}
      {...rest}
    />
  );
};

ProjectMarker.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  project: PropTypes.number,
  id: PropTypes.number,
  isComputed: PropTypes.bool,
  selected: PropTypes.bool,
  hasSatelliteValidation: PropTypes.bool,
};

export default memo(ProjectMarker);
