import React, { memo } from 'react';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { CART_ICON } from 'constants/icons';

import { useStyles } from './styles';

/**
 * GetMapsButton - component for render button "Get Maps"
 * @param {Object} props
 * @returns {jsx}
 */
const GetMapsButton = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ButtonBase classes={{ root: classes.container }} {...props}>
      {t('projects.buyMapsBtn')}
      <img
        src={CART_ICON}
        className={classes.image}
        alt={t('projects.buyMapsBtn')}
      />
    </ButtonBase>
  );
};

export default memo(GetMapsButton);
