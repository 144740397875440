import { generatePath } from 'react-router';

import { makeXhrRequest } from 'helpers/requestXhr';
import request from 'helpers/request';
import {
  SATELLITE_COMPARISON_COMPUTE_URL,
  SATELLITE_COMPARISONS_URL,
  SATELLITE_COMPARISON_URL,
  SATELLITE_COMPARISON_STATS_URL,
  SATELLITE_COMPARISON_STATS_DOWNLOAD_URL,
} from 'constants/api';

export const getSatelliteComparisons = (params) =>
  request.get(SATELLITE_COMPARISONS_URL, { params });

export const createSatelliteComparison = (data, options) =>
  request.post(SATELLITE_COMPARISONS_URL, data, options);

export const computeSatelliteComparison = (id, data, options) =>
  request.put(
    generatePath(SATELLITE_COMPARISON_COMPUTE_URL, { id }),
    data,
    options
  );

export const getSatelliteComparison = (id, params) =>
  request.get(generatePath(SATELLITE_COMPARISON_URL, { id }), { params });

export const getSatelliteComparisonStats = (id, params) =>
  request.get(generatePath(SATELLITE_COMPARISON_STATS_URL, { id }), { params });

export const downloadSatelliteComparisonStat = (comparisonId) =>
  makeXhrRequest({
    method: 'GET',
    url: generatePath(SATELLITE_COMPARISON_STATS_DOWNLOAD_URL, {
      id: comparisonId,
    }),
    responseType: 'blob',
  }).then((result) => result);
