import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    width: 800,
  },
  formText: {
    fontWeight: 700,
  },
});
