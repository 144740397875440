import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles((theme) => ({
  graphWrapper: {
    zIndex: 1,
  },
  title: {
    margin: '5px 25px',
    whiteSpace: 'pre-wrap',
  },
  sector: {
    fill: alpha(theme.palette.grey.middle, 0.4),
    fillOpacity: 0,
    '&:hover': {
      fillOpacity: 1,
      cursor: 'pointer',
    },
  },
  monthSelector: {
    marginTop: 20,
  },
}));
