import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import { MIN_ROSE_GRID_TICKS_AMOUNT } from 'constants/graphs';
import { INVALID_LEVELS_AMOUNT, INVALID_RADIUS } from 'constants/errors';
import { palette } from 'common/theme';
import { CARDINAL_POINTS } from 'constants/common';

const LABEL_OFFSET = 5;
const LETTER_SIZE = 14;
const { N, W, S, E } = CARDINAL_POINTS;

/**
 * Local component for render text labels at rose grid
 * @param { number } cx
 * @param { number } cy
 * @param { number } radius
 * @param { array } levelsData
 * @param { string } units
 * @returns { JSX }
 */
const Labels = ({ cx, cy, radius, levelsData, units }) => {
  const cos15 = Math.cos((5 * Math.PI) / 12);
  const sin45 = Math.sin(Math.PI / 4);
  const cathetus = sin45 * radius;

  return (
    <>
      <text x={cx} y={cy - radius - LABEL_OFFSET}>
        {N}
      </text>
      <text x={cx} y={cy + radius + LABEL_OFFSET + LETTER_SIZE}>
        {S}
      </text>
      <text x={cx - radius - LABEL_OFFSET - LETTER_SIZE} y={cy}>
        {W}
      </text>
      <text x={cx + radius + LABEL_OFFSET} y={cy}>
        {E}
      </text>
      <text
        x={cx - cathetus - LABEL_OFFSET - 2 * LETTER_SIZE}
        y={cy - cathetus - LABEL_OFFSET}
      >
        {`${N}-${W}`}
      </text>
      <text x={cx + cathetus + LABEL_OFFSET} y={cy - cathetus - LABEL_OFFSET}>
        {`${N}-${E}`}
      </text>
      <text
        x={cx + cathetus + LABEL_OFFSET}
        y={cy + cathetus + LABEL_OFFSET + LETTER_SIZE}
      >
        {`${S}-${E}`}
      </text>
      <text
        x={cx - cathetus - LABEL_OFFSET - 2 * LETTER_SIZE}
        y={cy + cathetus + LABEL_OFFSET + LETTER_SIZE}
      >
        {`${S}-${W}`}
      </text>
      {levelsData.map(({ radius: currentRadius, value }) => (
        <text
          key={value}
          x={cx + currentRadius * cos15}
          y={cy - currentRadius}
        >{`${value}${units}`}</text>
      ))}
    </>
  );
};

/**
 * Distribution Rose grid component. Renders grid for given value levels.
 * renders vertical, horizontal and diagonal axises and labels for given levels
 * @param { number } cx - x coordinate of center
 * @param { number } cy - y coordinate of center
 * @param { number } radius - outer grid radius. Should be greater than max level value
 * @param { array } levels - array of levels (e.g. [5, 10, 15, 20, 25, 30])
 * @param { string } stroke - lines fill color
 * @param { string } units - values units (e.g. %)
 * @returns {JSX}
 * @note should be used in svg context
 */
const DistributionRoseGrid = ({ cx, cy, radius, levels, stroke, units }) => {
  if (levels.length < MIN_ROSE_GRID_TICKS_AMOUNT) {
    throw Error(INVALID_LEVELS_AMOUNT);
  }

  const maxLevel = levels[levels.length - 1];
  if (radius < maxLevel) {
    throw Error(INVALID_RADIUS);
  }

  const step = radius / maxLevel;
  const levelsData = levels.map((value) => ({
    value,
    radius: Math.round(value * step),
  }));

  const sin45 = Math.sin(Math.PI / 4);
  const cathetus = sin45 * radius;

  return (
    <g>
      {levelsData.map(({ radius: currentRadius }) => (
        <circle
          cx={cx}
          cy={cy}
          stroke={stroke}
          r={currentRadius}
          key={currentRadius}
          strokeWidth="1"
          fill="none"
        />
      ))}
      <line x1={cx} x2={cx} y1={cy + radius} y2={cy - radius} stroke={stroke} />
      <line x1={cx - radius} x2={cx + radius} y1={cy} y2={cy} stroke={stroke} />
      <line x1={cx - radius} x2={cx + radius} y1={cy} y2={cy} stroke={stroke} />
      <line
        x1={cx - cathetus}
        x2={cx + cathetus}
        y1={cy - cathetus}
        y2={cy + cathetus}
        stroke={stroke}
      />
      <line
        x1={cx - cathetus}
        x2={cx + cathetus}
        y1={cy + cathetus}
        y2={cy - cathetus}
        stroke={stroke}
      />
      <Labels
        cx={cx}
        cy={cy}
        radius={radius}
        levelsData={levelsData}
        units={units}
      />
    </g>
  );
};

DistributionRoseGrid.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  radius: PropTypes.number.isRequired,
  levels: PropTypes.arrayOf(PropTypes.number).isRequired,
  stroke: PropTypes.string,
  units: PropTypes.string,
};

DistributionRoseGrid.defaultProps = {
  cx: 0,
  cy: 0,
  stroke: alpha(palette.grey.middle, 0.3),
  units: '%',
};

export default memo(DistributionRoseGrid);
