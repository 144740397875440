import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useModal } from 'hooks/useModal';
import { CONTACT_US_MODAL } from 'constants/modals';
import BaseModal from 'components/common/BaseModal';

import { useStyles } from './styles';

/**
 * AskDownloadStatsModal component
 * @param {function} onClose
 */
const AskDownloadStatsModal = ({ onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { openModal } = useModal();

  const openContactUsModal = useCallback(() => {
    openModal(CONTACT_US_MODAL);
  }, [openModal]);

  const controls = (
    <div className={classes.buttons}>
      <Button onClick={openContactUsModal} className={classes.button}>
        {t('buttons.contact')}
      </Button>
    </div>
  );

  return (
    <BaseModal
      title="Upgrade your plan for Downloadable Data"
      onClose={onClose}
      controls={controls}
      modalClass={classes.container}
      warning
    >
      {/* eslint-disable */}
      <p className={classes.highlightText}>You are currently in our Standard Access Plan.</p>
      <p>
        For more advanced features including downloadable data, contact our team to upgrade to a higher-tier plan.
      </p>
      {/* eslint-enable */}
    </BaseModal>
  );
};

AskDownloadStatsModal.propTypes = {
  onClose: PropTypes.func,
};

export default React.memo(AskDownloadStatsModal);
