import React from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_GRAPH_CANVAS_HEIGHT } from 'constants/graphs';
import { DEEP_BLUE_LINEAR_GRADIENT_SCHEME } from 'constants/color';
import { getTickPrecisionByRange } from 'helpers/graphs/common';
import { getPrettyNumber } from 'helpers/data';

const GRADIENT_ID = 'colorBarGradient';
const COLOR_BAND_WIDTH = 20;
const TICK_LENGTH = 8;
const TICK_FONT_SIZE = 12;
const LABEL_EDGE_OFFSET = 15;

/**
 * Svg color range bar implementation
 * @note All sg styling are implemented by props to be displayed correctly while svg image saving/converting
 * @param { string } label
 * @param { number } width
 * @param { number } height
 * @param { array } ticks
 * @param { array } gradientScheme
 * @param { number } offsetY - offset from top and bottom (to not crop first and last tick half)
 * @param { boolean } showMaxTick - whether to show last tick
 * (ticks could be 1,2,3,3.1, so it's hard to display 3 and 3.1 ticks correctly)
 * @returns { JSX }
 */
const SvgColorRangeBar = ({
  label,
  width,
  height,
  ticks = [],
  gradientScheme,
  showMaxTick,
  offsetY,
}) => {
  const maxTick = ticks[ticks.length - 1];
  const minTick = ticks[0];
  const scaleDivision = height / (maxTick - minTick);
  const preparedTicks = showMaxTick ? ticks : ticks.slice(0, -1);
  const tickX1 = COLOR_BAND_WIDTH;
  const tickX2 = COLOR_BAND_WIDTH + TICK_LENGTH;
  const precision = getTickPrecisionByRange(maxTick - minTick);
  const labelX = width - LABEL_EDGE_OFFSET;
  const labelY = offsetY + ~~(height / 2);

  return (
    <svg width={width} height={height + 2 * offsetY}>
      <defs>
        <linearGradient id={GRADIENT_ID} x1="0" x2="0" y1="0" y2="1">
          {gradientScheme.map(({ offset, color }) => (
            <stop key={offset} offset={`${offset}%`} stopColor={color} />
          ))}
        </linearGradient>
      </defs>
      <rect
        x={0}
        y={offsetY}
        rx="2"
        ry="2"
        width={COLOR_BAND_WIDTH}
        height={height}
        fill={`url(#${GRADIENT_ID})`}
      />
      {preparedTicks.map((tick) => {
        const y = offsetY + height - (tick - minTick) * scaleDivision - 1;

        return (
          <React.Fragment key={tick}>
            <line
              dy={-1}
              x1={tickX1}
              x2={tickX2}
              y1={y}
              y2={y}
              stroke="black"
            />
            <text
              y={y}
              x={tickX2 + 2}
              fontSize={TICK_FONT_SIZE}
              dominantBaseline="middle"
              textAnchor="start"
            >
              {getPrettyNumber(tick, precision)}
            </text>
          </React.Fragment>
        );
      })}
      {label && (
        <text
          x={labelX}
          y={labelY}
          fill="black"
          textAnchor="middle"
          dominantBaseline="middle"
          transform={`rotate(-90, ${labelX}, ${labelY})`}
        >
          {label}
        </text>
      )}
    </svg>
  );
};

SvgColorRangeBar.propTypes = {
  width: PropTypes.number,
  label: PropTypes.string,
  height: PropTypes.number,
  ticks: PropTypes.arrayOf(PropTypes.number).isRequired,
  showMaxTick: PropTypes.bool,
  offsetY: PropTypes.number,
  gradientScheme: PropTypes.arrayOf(
    PropTypes.shape({
      offset: PropTypes.number,
      color: PropTypes.string,
    })
  ),
};

SvgColorRangeBar.defaultProps = {
  label: '',
  width: 80,
  offsetY: 0,
  height: DEFAULT_GRAPH_CANVAS_HEIGHT,
  gradientScheme: DEEP_BLUE_LINEAR_GRADIENT_SCHEME,
  showMaxTick: false,
};

export default SvgColorRangeBar;
