import React, { useMemo, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
} from '@material-ui/core';
import uniqueId from 'lodash/uniqueId';

import GraphLevelSelect from 'components/common/graphs/GraphLevelSelect';
import DownloadGraphDataFileButtonGroup from 'containers/buttons/DownloadGraphDataFileButtonGroup';
import { useUniqueId } from 'hooks/useUniqueId';
import {
  CHART_SVG_ID,
  CHART_TITLE_ID,
  HTML_CAPTURE_MODE,
} from 'constants/graphs';

import { useStyles } from './styles';

/**
 * Extreme table chart
 * @param { object } annualData
 * @param { number } projectId
 * @param { Array } statsIds
 * @param { String } pngFileName
 * @returns { JSX }
 */
const ExtremeTableChart = ({
  annualData,
  projectId,
  statsIds,
  pngFileName,
}) => {
  const salt = useUniqueId();
  const classes = useStyles();

  const { levels, data } = annualData;
  const [levelIndex, setLevelIndex] = useState(0);

  const withLevels = !!levels?.type;
  const { title, columns } = withLevels ? data[levelIndex] : data;

  const { headerTitles, rows, rowIds } = useMemo(
    () =>
      columns.reduce(
        (acc, { name, values, units }) => {
          acc.headerTitles.push(`${name} ${units}`);

          for (let i = 0; i < values.length; i += 1) {
            acc.rowIds[i] = acc.rowIds[i] ?? `${uniqueId()}`;
            const rowId = acc.rowIds[i];
            acc.rows[rowId] = acc.rows[rowId] ?? [];
            acc.rows[rowId].push(values[i]);
          }

          return acc;
        },
        { headerTitles: [], rowIds: [], rows: {} }
      ),
    [columns]
  );

  return (
    <Grid justifyContent="center" container>
      <Grid item>
        <div id={CHART_SVG_ID + salt}>
          <Typography
            id={CHART_TITLE_ID + salt}
            className={classes.title}
            variant="subtitle1"
            align="center"
          >
            {title}
          </Typography>
          <Grid container>
            {withLevels && (
              <Grid item>
                <GraphLevelSelect
                  type={levels.type}
                  levels={levels.values}
                  currentLevel={levelIndex}
                  selectLevel={setLevelIndex}
                />
              </Grid>
            )}
            <Grid item>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-label="extreme table chart"
                >
                  <TableHead>
                    <TableRow className={classes.headerRow}>
                      {headerTitles.map((headerTitle) => (
                        <TableCell key={headerTitle} align="center">
                          {headerTitle}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowIds.map((rowId) => (
                      <TableRow key={rowId} className={classes.tableRow}>
                        {rows[rowId].map((value, index) => (
                          <TableCell key={headerTitles[index]} align="center">
                            {value}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
        <DownloadGraphDataFileButtonGroup
          salt={salt}
          captureMode={HTML_CAPTURE_MODE}
          projectId={projectId}
          statsIds={statsIds}
          currentLevel={withLevels ? levelIndex : null}
          pngFileName={pngFileName}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(ExtremeTableChart);
