import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { useStyles } from './styles';

/**
 * BaseMapPopup - base popup with styles
 * @param {React.ReactNode} children
 * @returns {jsx}
 */
export const BaseMapPopup = memo(({ children, className }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      className={className || classes.modalWrapper}
    >
      {children}
    </Grid>
  );
});

BaseMapPopup.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * BaseMapPopupTitle - title for map popup
 * @param {React.ReactNode} children
 * @returns {jsx}
 */
export const BaseMapPopupTitle = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={classes.modalTitle}
    >
      {children}
    </Grid>
  );
};

BaseMapPopupTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * BaseMapPopupContent - content for map popup
 * @param {React.ReactNode} children
 * @returns {jsx}
 */
export const BaseMapPopupContent = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid item className={classes.modalContent}>
      {children}
    </Grid>
  );
};

BaseMapPopupContent.propTypes = {
  children: PropTypes.node,
};
