import React from 'react';

import { Loader } from 'components/common/Loader';

/**
 * Page with Loader
 */
const LoaderPage = () => <Loader center />;

export default LoaderPage;
