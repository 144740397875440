import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Grid, Box } from '@material-ui/core';

import { POINT_POSITION, MAP_LAYERS } from 'constants/map';
import { Map } from 'components/leaflet';
import PointsFeatureGroup from 'components/maps/PointsFeatureGroup';
import { getAveragePositionByPoints } from 'helpers/map';
import { DATE_FORMATS } from 'constants/common';
import { Loader } from 'components/common/Loader';

import { useStyles } from './styles';

/** Detail user informations
 * @param { object } user - The user to display
 */
const DetailUser = ({ user }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const redirect = useCallback(() => {
    history.goBack();
  }, [history]);

  if (!user) {
    return <Loader center justifyCenter />;
  }

  return (
    <Paper elevation={1} className={classes.container}>
      <Button variant="contained" onClick={redirect} className={classes.button}>
        Go back
      </Button>
      <Grid className={classes.informations}>
        <Grid>
          <Box>{t('usageAnalysis.detail.name', { type: 'user' })}</Box>
          {user.firstName ? <Box>Full name:</Box> : null}
          <Box># of projects:</Box>
          <Box># of reports:</Box>
          <Box># of AWD:</Box>
          <Box>{t('usageAnalysis.detail.user.lastConnected')}</Box>
        </Grid>
        <Grid>
          <Box>
            <b>{user.username}</b>
          </Box>
          {user.firstName ? (
            <Box>
              {user.firstName} {user.lastName}
            </Box>
          ) : null}
          <Box>
            <b>{user.projectCount}</b>
          </Box>
          <Box>
            <b>{user.reportCount}</b>
          </Box>
          <Box>
            <b>{user.downtimeCount}</b>
          </Box>
          <Box>
            {user.lastLogin
              ? format(user.lastLogin, DATE_FORMATS.frenchFormat)
              : 'No date'}
          </Box>
        </Grid>
      </Grid>
      {user.points && user.points.length > 0 && (
        <Grid className={classes.table}>
          <h3>
            {t('usageAnalysis.detail.mapIntro', { type: 'points' })}{' '}
            {user.username}
          </h3>
          <Map
            position={getAveragePositionByPoints(user.points)}
            positionType={POINT_POSITION}
            defaultLayer={MAP_LAYERS.worldImagery}
            wrapperClass={classes.map}
          >
            <PointsFeatureGroup points={user.points} />
          </Map>
        </Grid>
      )}
    </Paper>
  );
};

DetailUser.propTypes = {
  user: PropTypes.object,
};

export default memo(DetailUser);
