const prefix = '@@plannings';

export const REQUEST_PLANNINGS_LIST = `${prefix}/REQUEST_PLANNINGS_LIST`;
export const REQUEST_PLANNINGS_LIST_SUCCESS = `${prefix}/REQUEST_PLANNINGS_LIST_SUCCESS`;
export const REQUEST_PLANNINGS_LIST_ERROR = `${prefix}/REQUEST_PLANNINGS_LIST_ERROR`;
export const REQUEST_PLANNINGS_LIST_IF_NEED = `${prefix}/REQUEST_PLANNINGS_LIST_IF_NEED`;

export const DELETE_PLANNING = `${prefix}/DELETE_PLANNING`;
export const DELETE_PLANNING_SUCCESS = `${prefix}/DELETE_PLANNING_SUCCESS`;
export const DELETE_PLANNING_ERROR = `${prefix}/DELETE_PLANNING_ERROR`;

export const UPLOAD_PLANNING = `${prefix}/UPLOAD_PLANNING`;
export const UPLOAD_PLANNING_SUCCESS = `${prefix}/UPLOAD_PLANNING_SUCCESS`;
export const UPLOAD_PLANNING_ERROR = `${prefix}/UPLOAD_PLANNING_ERROR`;

export const CREATE_PLANNING = `${prefix}/CREATE_PLANNING`;
export const CREATE_PLANNING_SUCCESS = `${prefix}/CREATE_PLANNING_SUCCESS`;
export const CREATE_PLANNING_ERROR = `${prefix}/CREATE_PLANNING_ERROR`;

export const START_UPDATE_PLANNING = `${prefix}/START_UPDATE_PLANNING`;
export const STOP_UPDATE_PLANNING = `${prefix}/STOP_UPDATE_PLANNING`;

export const GET_PLANNING_SUCCESS = `${prefix}/GET_PLANNING_SUCCESS`;
export const GET_PLANNING_ERROR = `${prefix}/GET_PLANNING_ERROR`;

export const DOWNLOAD_PLANNING = `${prefix}/DOWNLOAD_PLANNING`;
export const DOWNLOAD_PLANNING_ERROR = `${prefix}/DOWNLOAD_PLANNING_ERROR`;

export const COMPUTE_PLANNING = `${prefix}/COMPUTE_PLANNING`;
