import { generatePath } from 'react-router';

import request from 'helpers/request';
import {
  LOGIN_URL,
  CURRENT_USER_URL,
  EDIT_USER_URL,
  REMOVE_USER_URL,
  RESEND_USER_INVITATION_URL,
  REVOKE_USER_INVITATION_URL,
  RESET_PASSWORD_URL,
  RESET_PASSWORD_CONFIRM_URL,
  INVITE_USER_URL,
  REGISTRATION_URL,
  REGISTRATION_PENDING_URL,
  REGISTRATION_ACTIVATE_URL,
} from 'constants/api';

export const loginRequest = (data) => request.post(LOGIN_URL, data);

export const fetchCurrentUser = () => request.get(CURRENT_USER_URL);

export const inviteUser = (data) => request.post(INVITE_USER_URL, data);

export const removeUser = (data) => {
  request.put(generatePath(REMOVE_USER_URL, { id: data.id }));
};

export const editUserProfile = (data) =>
  request.put(generatePath(EDIT_USER_URL, { id: data.id }), data);

export const resendUserInvitation = (id) =>
  request.put(generatePath(RESEND_USER_INVITATION_URL, { id }));

export const revokeUserInvitation = (id) =>
  request.put(generatePath(REVOKE_USER_INVITATION_URL, { id }));

export const resetPassword = (data) =>
  request.post(RESET_PASSWORD_URL, data, { noRedirect: true });

export const resetPasswordConfirm = (data) =>
  request.post(RESET_PASSWORD_CONFIRM_URL, data, { noRedirect: true });

/**
 * creates inactive user and sends invitation e-mail
 */
export const registrationUser = (data) =>
  request.post(REGISTRATION_URL, data, { noRedirect: true });

/**
 * checks if there is a pending registration with provided activation key
 */
export const registrationPending = (data) =>
  request.post(REGISTRATION_PENDING_URL, data, { noRedirect: true });

/**
 * activates the user
 */
export const registrationActivate = (data) =>
  request.post(REGISTRATION_ACTIVATE_URL, data, { noRedirect: true });
