import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { MapFeatureGroup } from 'components/leaflet';
import BuoyMarker from 'components/maps/BuoyMarker';
import { BUOYS_GROUP_NAME } from 'constants/map';

/**
 * BuoysFeatureGroup - Feature group with buoys and
 * @param {Array} buoys
 * @param {String} numericalSimulation
 * @param {String} type
 * @param {Function} onClick
 * @param {React.ReactNode} children
 * @param {Object} props
 * @returns {jsx}
 */
const BuoysFeatureGroup = ({
  buoys,
  numericalSimulation,
  type,
  children,
  onClick,
  ...props
}) => {
  const onBuoyClickHandler = useCallback(
    ({ layer: buoy }) =>
      onClick && onClick({ ...buoy.properties, numericalSimulation }),
    [onClick, numericalSimulation]
  );

  return (
    <MapFeatureGroup
      {...props}
      name={BUOYS_GROUP_NAME}
      onClick={onBuoyClickHandler}
      withControls
    >
      {buoys.map(({ id, ...buoy }) => (
        <BuoyMarker {...buoy} key={id} id={id} type={type} />
      ))}
      {children}
    </MapFeatureGroup>
  );
};

BuoysFeatureGroup.propTypes = {
  buoys: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  numericalSimulation: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default memo(BuoysFeatureGroup);
