import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 500,
  },
  link: {
    textAlign: 'center',
  },
  floatingButton: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
    '&:hover, &.active': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  label: {
    color: 'white',
    fontWeight: 650,
    textTransform: 'none',
  },
  subContainer: {
    width: '100%',
    textAlign: 'center',
  },
}));
