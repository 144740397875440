import { LINK_TO_ABOUT_US } from 'common/config';
export const ROUTES = {
  home: '/',
  front: '/front',
  demo: '/demo',
  features: '/features',
  login: '/login',
  project: '/project/:id',
  projectAnalyze: '/project/:id/analyze',
  projectReport: '/project/:id/report',
  createProject: '/offers/:offerId/create',
  dataValidation: '/data-validation/:id',
  catalog: '/catalog/:id',
  downtime: '/downtime/:id',
  faq: '/help/faq',
  faqToCreateLocation:
    '/help/faq#how-do-i-createremove-a-location-in-a-project-',
  statistics: '/help/statistics',
  profile: '/account',
  selectZone: '/select_zone',
  examples: '/examples',
  people: '/people',
  registration: '/registration',
  registrationActivate: '/registration/activate',
  resetPassword: '/accounts/password/reset/',
  resetPasswordConfirm: '/accounts/password/reset/confirm',
  plans: '/plans',
  help: '/help/:url',
  helpDowntime: '/help/advanced_weather_downtime/',
  helpSatelliteComparison: '/help/satellite_comparison',
  helpValidation: '/help/validation',
  loaderPage: '/load',
  hijackLanding: '/hijack',
  admin: '/admin',
  usage: '/usage',
  usageCustomers: '/usage/customers',
  usageCustomerDetail: '/usage/customers/:id',
  usageUsers: '/usage/users',
  usageUserDetail: '/usage/users/:id',
  usageOffers: '/usage/offers',
  usageOfferDetail: '/usage/offers/:id',
};

/**
 * navigation links for navbar.
 * link.text is translation path
 */
export const NAVIGATION_LINKS = [
  { url: ROUTES.home, text: 'navLinks.home', isRelative: true },
  { url: ROUTES.people, text: 'navLinks.people', isRelative: true },
  { url: ROUTES.faq, text: 'navLinks.faq', isRelative: true },
  { url: ROUTES.statistics, text: 'navLinks.statistics', isRelative: true },
  {
    url: 'CONTACT_US',
    text: 'navLinks.contactUs',
    isRelative: false,
  },
];

/**
 * navigation links for navbar on the front page.
 * link.text is translation path
 */
export const NAVIGATION_LINKS_ON_FRONT_PAGE = [
  { url: ROUTES.features, text: 'navLinks.features', isRelative: true },
  { url: LINK_TO_ABOUT_US, text: 'navLinks.aboutUs', isRelative: false },
  {
    url: 'CONTACT_US',
    text: 'navLinks.contactUs',
    isRelative: false,
  },
];
