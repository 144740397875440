import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Button, Typography, IconButton } from '@material-ui/core';
import PropsTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Plus, PlusCircle } from 'phosphor-react';

import BoatContainer from 'containers/projects/ProjectMarineContractors/Operations/Boats/index';
import OperationContainer from 'containers/projects/ProjectMarineContractors/Operations/Operations/index';
import ConstraintContainer from 'containers/projects/ProjectMarineContractors/Operations/Constraints/index';
import BlockUpdateComponent from 'components/common/MarineContractors/BlockUpdateComponent/index';
import {
  selectMarineContractorsOperationIsCreating,
  selectMarineContractorsConstraints,
} from 'ducks/marineContractors/selectors';
import { useInterrupt } from 'hooks/useInterrupt';
import { NO_ID } from 'constants/marineContractors';
import { selectUserDetails } from 'ducks/user/selectors';
import {
  COMPLETE_CONSTRAINT_MODAL,
  EMPTY_OPERATION_MODAL,
} from 'constants/modals';
import { useModal } from 'hooks/useModal';

import { useStyles } from './styles';

/**
 * MCOperationBoxComponent - display a box with a title and a content
 * @param { Node } children - two children (title + content)
 * @param { Number } xs
 * @param { Number } md
 * @param { Number } lg
 */
const MCOperationBoxComponent = React.memo(
  ({ children, xs = 4, md = 4, lg = 4 }) => {
    const classes = useStyles();
    const [title, items] = children;
    return (
      <Grid item container direction="column" xs={xs} md={md} lg={lg}>
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.titleBox}
        >
          {title}
        </Grid>
        <Grid
          item
          container
          direction="row"
          alignItems="stretch"
          className={classes.contentBox}
        >
          {items}
        </Grid>
      </Grid>
    );
  }
);

/**
 * NoContentContainer - show some text in MCOperationBoxComponent content if no content
 * @param { string } name - object name
 * @param { string } text - useful informations to share
 */
const NoContentContainer = React.memo(({ name, text }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container direction="row" alignItems="center" justifyContent="center">
      <Grid item>
        <Typography className={classes.noContent}>
          {t('marineContractors.operations.noContent', { name })}
        </Typography>
        <Typography className={classes.noContent}>{text}</Typography>
      </Grid>
    </Grid>
  );
});

/**
 * BoatContainers - simple container displaying all boats container
 * @param { Array } boats - array of boats (object)
 * @param { Object } selectedBoat
 * @param { Function } handleSelect
 */
const BoatContainers = React.memo(({ boats, selectedBoat, handleSelect }) =>
  boats.map((b) => (
    <BoatContainer
      key={b.id}
      boat={b}
      isSelected={selectedBoat?.id === b.id}
      handleSelect={handleSelect}
      forceFocus={selectedBoat?.id === b.id && selectedBoat.id === NO_ID}
    />
  ))
);

/**
 * OperationContainers - simple container displaying all operations container
 * @param { Array } operations - array of operations (object)
 * @param { Object } selectedBoat
 * @param { Object } selectedOperation
 * @param { Array } selectedOperations - operations preselected from the project job
 * @param { Boolean } operationIsCreating
 * @param { Function } handleSelect
 * @param { Function } handleOperations
 */
const OperationContainers = React.memo(
  ({
    operations,
    selectedBoat,
    selectedOperation,
    selectedOperations,
    operationIsCreating,
    handleSelect,
    handleOperations,
  }) => {
    const { t } = useTranslation();

    const getOperationNoContentText = () => {
      if (selectedBoat) {
        if (selectedBoat?.id === NO_ID) {
          return t('marineContractors.operations.boatIsCreating');
        }
        return t('marineContractors.operations.noOperation', {
          name: selectedBoat?.name,
        });
      }
      return t('marineContractors.operations.noBoatSelected');
    };

    const selectedOperationsIds = selectedOperations.map((so) => so.id);
    const operationIsCreated = useInterrupt(operationIsCreating, true, false);

    if (operations?.length) {
      return operations.map((o, idx) => (
        <OperationContainer
          key={o.id}
          operation={o}
          isSelected={o.id === selectedOperation?.id}
          isChecked={selectedOperationsIds.includes(o.id)}
          handleSelect={handleSelect}
          handleOperations={handleOperations}
          forceFocus={idx === operations.length - 1 && operationIsCreated}
        />
      ));
    }
    return (
      <NoContentContainer name="operation" text={getOperationNoContentText()} />
    );
  }
);

/**
 * ConstraintContainers - simple container displaying all constraints container
 * @param { Array } constraints - array of constraints (object)
 * @param { Boolean } selectedConstraint
 * @param { Function } handleSelect
 */
const ConstraintContainers = React.memo(
  ({ constraints, selectedConstraint, handleSelect }) =>
    constraints.map((c) => (
      <ConstraintContainer
        key={c.id}
        constraint={c}
        isSelected={c.id === selectedConstraint?.id}
        handleSelect={handleSelect}
        forceFocus={
          selectedConstraint?.id === c.id && selectedConstraint.id === NO_ID
        }
      />
    ))
);

/**
 * MC operations component - display the operations board
 * @param { Array } boats - Boat objects list
 * @param { Array } operations - Operation objects list (filtered by selected boat)
 * @param { Array } constraints - Constraint objects list (filtered by selected operation)
 * @param { object } selectedBoat
 * @param { object } selectedOperation
 * @param { Array } selectedOperations - Preferred operation
 * @param { object } selectedConstraint
 * @param { Function } handleNext
 * @param { Function } handleBack
 * @param { Function } handleSelect - Action when a boat is selected
 * @param { Function } handleSelectOperation - Action when an operation is selected
 * @param { Function } handleSelectConstraint - Action when a constraint is selected
 * @param { Function } handleNewBoat - Action when clicking on 'Add boat' button
 * @param { Function } handleNewOperation - Action when clicking on 'Add operation' button
 * @param { Function } handleNewConstraint - Action when clicking on 'Add constraint' button
 * @param { Function } handleOperations - Add operation in user preference when checking an operation
 * @returns { JSX }
 */
const MCOperationsComponent = React.memo(
  ({
    boats,
    operations,
    constraints,
    selectedBoat,
    selectedOperation,
    selectedOperations,
    selectedConstraint,
    handleNext,
    handleBack,
    handleSelectBoat,
    handleSelectOperation,
    handleSelectConstraint,
    handleNewBoat,
    handleNewOperation,
    handleNewConstraint,
    handleOperations,
  }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { openModal } = useModal();
    const constraintsFull = useSelector((state) =>
      selectMarineContractorsConstraints(state)
    );

    const operationIsCreating = useSelector((state) =>
      selectMarineContractorsOperationIsCreating(selectedBoat)(state)
    );
    const { customerName } = useSelector(selectUserDetails);

    const getConstraintNoContentText = () => {
      if (selectedOperation) {
        return t('marineContractors.operations.noConstraint', {
          name: selectedOperation?.name,
        });
      }
      return t('marineContractors.operations.noOperationSelected');
    };

    const handleClick = () => {
      // Check for every constraint to be properly set
      // False if a single variable that are null
      const allOperations = constraintsFull.map(
        (constraint) => constraint.operation
      );
      const uniqueOperations = [...new Set(allOperations)];
      const allSelectedOperations = selectedOperations.map(
        (operation) => operation.id
      );

      const allOperationsConstraintNotEmpty = allSelectedOperations.every(
        (operationId) => uniqueOperations.includes(operationId)
      );

      const allConstraintsValid = Object.values(
        constraintsFull
      ).every((constraint) =>
        Object.values(constraint).every(
          (value) =>
            value !== null &&
            value !== '' &&
            value !== undefined &&
            value !== false
        )
      );

      if (!allConstraintsValid) {
        openModal(COMPLETE_CONSTRAINT_MODAL);
      }
      if (!allOperationsConstraintNotEmpty) {
        openModal(EMPTY_OPERATION_MODAL);
      }
      if (allOperationsConstraintNotEmpty && allConstraintsValid) {
        // If all constraints are set and all operation have at least one constraint, return handleNext
        return handleNext();
      }
    };

    return (
      <div className={classes.mainContainer}>
        <Grid container direction="row" spacing={4}>
          {/* boat container */}
          <Grid item container direction="column" xs={12} md={2} lg={2}>
            <Grid
              item
              container
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item style={{ overflowWrap: 'anywhere' }}>
                <Typography variant="h5">
                  {t('marineContractors.operations.boatTitle', {
                    customerName,
                  })}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => handleNewBoat()}
                  className={classes.addBoatButton}
                >
                  {t('marineContractors.buttons.boat')}
                  <Plus size={18} className={classes.iconImage} />
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="column"
              wrap="nowrap"
              style={{ textAlign: 'center' }}
              className={classes.boatsContainer}
            >
              {boats?.length ? (
                <BoatContainers
                  boats={boats}
                  selectedBoat={selectedBoat}
                  handleSelect={handleSelectBoat}
                />
              ) : (
                <NoContentContainer
                  name="boat"
                  text={t('marineContractors.operations.noBoat')}
                />
              )}
            </Grid>
          </Grid>
          {/* operation container */}
          <MCOperationBoxComponent xs={12} md={5} lg={5}>
            <Grid item container className={classes.mcContainer}>
              <Grid item>
                <h2>{t('marineContractors.operations.name')}</h2>
              </Grid>
              <BlockUpdateComponent isUpdating={operationIsCreating} noGrow>
                <Grid item>
                  <IconButton
                    onClick={
                      !!selectedBoat && selectedBoat.id !== NO_ID
                        ? () => handleNewOperation()
                        : null
                    }
                    className={classes.mcActionButton}
                    disabled={!selectedBoat || selectedBoat.id === NO_ID}
                  >
                    {t('marineContractors.buttons.operation')}
                    <PlusCircle size={18} className={classes.addIcon} />
                  </IconButton>
                </Grid>
              </BlockUpdateComponent>
            </Grid>
            <Grid
              item
              container
              direction="column"
              justifyContent={operations?.length ? 'flex-start' : 'center'}
              wrap="nowrap"
            >
              <OperationContainers
                operations={operations}
                selectedBoat={selectedBoat}
                selectedOperation={selectedOperation}
                selectedOperations={selectedOperations}
                operationIsCreating={operationIsCreating}
                handleSelect={handleSelectOperation}
                handleOperations={handleOperations}
              />
            </Grid>
          </MCOperationBoxComponent>
          {/* constraint container */}
          <MCOperationBoxComponent xs={12} md={5} lg={5}>
            <Grid item container className={classes.mcContainer}>
              <Grid item>
                <h2>{t('marineContractors.operations.constraintTitle')}</h2>
              </Grid>
              <Grid item>
                <IconButton
                  className={classes.mcActionButton}
                  disabled={!selectedOperation}
                  onClick={
                    selectedOperation ? () => handleNewConstraint() : null
                  }
                >
                  {t('marineContractors.buttons.constraint')}
                  <PlusCircle size={18} className={classes.addIcon} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="column"
              justifyContent={constraints?.length ? 'flex-start' : 'center'}
              wrap="nowrap"
            >
              {constraints?.length ? (
                <ConstraintContainers
                  constraints={constraints}
                  selectedConstraint={selectedConstraint}
                  handleSelect={handleSelectConstraint}
                />
              ) : (
                <NoContentContainer
                  name="constraint"
                  text={getConstraintNoContentText()}
                />
              )}
            </Grid>
          </MCOperationBoxComponent>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={2}
          className={classes.validStep}
        >
          <Grid item>
            <Button
              disabled={!handleBack}
              onClick={handleBack}
              className={classes.backButton}
            >
              {t('marineContractors.buttons.back')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={selectedOperations.length ? !handleNext : true}
              onClick={() => handleClick()}
              className={classes.nextButton}
            >
              {t('marineContractors.buttons.next')}
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
);

MCOperationsComponent.propTypes = {
  boats: PropsTypes.array.isRequired,
  operations: PropsTypes.array.isRequired,
  constraints: PropsTypes.array.isRequired,
  selectedBoat: PropsTypes.object,
  selectedOperation: PropsTypes.object,
  selectedOperations: PropsTypes.array.isRequired,
  selectedConstraint: PropsTypes.object,
  handleNext: PropsTypes.func.isRequired,
  handleBack: PropsTypes.func,
  handleSelectBoat: PropsTypes.func.isRequired,
  handleSelectOperation: PropsTypes.func.isRequired,
  handleSelectConstraint: PropsTypes.func.isRequired,
  handleNewBoat: PropsTypes.func.isRequired,
  handleNewOperation: PropsTypes.func.isRequired,
  handleNewConstraint: PropsTypes.func.isRequired,
  handleOperations: PropsTypes.func.isRequired,
};

export default React.memo(MCOperationsComponent);
