import { makeStyles } from '@material-ui/core/styles';

const underlinedItem = {
  textDecoration: 'underline',
  textDecorationColor: '#ffbb8a',
  textDecorationThickness: '3px',
  textDecorationSkipInk: 'none',
};

export const useStyles = makeStyles(({ palette }) => ({
  pointItem: {
    cursor: 'pointer',
    padding: 5,
    paddingLeft: 25,
    '&$pointItemSelected': {
      ...underlinedItem,
    },
    '&:hover': {
      ...underlinedItem,
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemName: {
    fontSize: 14,
    maxWidth: 200,
  },
  pointItemSelected: {
    ...underlinedItem,
  },
  link: {
    color: palette.grey.light,
    '&:hover': {
      color: palette.white.main,
    },
  },
  menuItemIcon: {
    width: 20,
  },
}));
