import { CircularProgress } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { palette } from 'common/theme';

import { useStyles } from './styles';

/**
 * Common loader component
 * @param { Boolean } center - if true Loader would be placed in center
 * @param { Boolean } justifyCenter
 * @param { Number } size - size in px
 * @param { string } color - color
 * @param { Number } opacity - fill opacity
 * @param { Object } props - rest props
 * @param { string } className - custom className
 * @see https://material-ui.com/ru/api/circular-progress
 */
export const Loader = ({
  center,
  size,
  color,
  opacity,
  justifyCenter,
  className,
  ...props
}) => {
  const classes = useStyles({ color, opacity });
  const wrapperClass = classNames(classes.base, className, {
    [classes.justifyCenter]: justifyCenter,
    [classes.center]: center,
    [classes.relative]: !center && !justifyCenter,
  });

  return (
    <div className={wrapperClass}>
      <CircularProgress {...props} color="inherit" size={size} />
    </div>
  );
};

Loader.propTypes = {
  justifyCenter: PropTypes.bool,
  center: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
  opacity: PropTypes.number,
  styleClass: PropTypes.string,
};

Loader.defaultProps = {
  justifyCenter: false,
  center: false,
  color: palette.grey.middle,
  opacity: 1,
  size: 50,
};
