import React, { useCallback, memo } from 'react';
import { useSelector } from 'react-redux';

import { INVITE_COLLABORATOR_MODAL } from 'constants/modals';
import InviteCollaboratorButton from 'components/common/buttons/InviteCollaboratorButton';
import { useModal } from 'hooks/useModal';
import { selectCurrentUserIsManager } from 'ducks/user/selectors';

/**
 * Container open a modal with invite collaborator form
 * @returns {jsx}
 */
const InviteCollaborator = () => {
  const { openModal } = useModal();
  const isManager = useSelector(selectCurrentUserIsManager);

  const openInviteModal = useCallback(
    () => openModal(INVITE_COLLABORATOR_MODAL),
    [openModal]
  );

  return isManager && <InviteCollaboratorButton onClick={openInviteModal} />;
};

export default memo(InviteCollaborator);
