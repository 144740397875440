import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { generatePath } from 'react-router';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FunnelSimple, WarningCircle } from 'phosphor-react';

import { ROUTES } from 'constants/routes';
import { FINISHED_PLANNING_STATUS } from 'constants/projects';
import ActiveProjectCard from 'components/projects/ActiveProjectCard';
import GridNet from 'components/common/GridNet';
import StaticProjectMap from 'components/maps/StaticProjectMap';
import { Loader } from 'components/common/Loader';
import Empty from 'components/common/EmptyContentFallback';
import {
  trackProjectOpen,
  trackProjectRenameClick,
  trackProjectDeleteClick,
} from 'ducks/trackers/actions/projectManagment';

import { useStyles } from './styles';

/**
 * ProjectList component
 * Displays collection of projects
 */
const ProjectList = ({
  projects,
  projectsData,
  deleteProject,
  renameProject,
  canDeleteProject,
  canCreateProject,
  canRenameProject,
  isLoading,
  hasProjects,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const redirectToProject = useCallback(
    ({ currentTarget }) => {
      const projectId = +currentTarget.getAttribute('data-project-id');
      const { status: projectStatus, name } = projectsData[projectId] || {};
      // track opened project
      dispatch(trackProjectOpen({ projectId, name }));
      history.push(
        generatePath(
          projectStatus === FINISHED_PLANNING_STATUS
            ? ROUTES.projectAnalyze
            : ROUTES.project,
          { id: projectId }
        )
      );
    },
    [projectsData, history, dispatch]
  );

  const onDeleteClick = useCallback(
    (event) => {
      event.stopPropagation();
      deleteProject(+event.currentTarget.getAttribute('data-project-id'));
      dispatch(trackProjectDeleteClick());
    },
    [deleteProject]
  );

  const onRenameClick = useCallback(
    (event) => {
      event.stopPropagation();
      renameProject(+event.currentTarget.getAttribute('data-project-id'));
      dispatch(trackProjectRenameClick());
    },
    [renameProject]
  );

  if (isLoading && !projects.length) {
    return <Loader center justifyCenter />;
  }

  if (!hasProjects) {
    return (
      <div className={classes.emptyStateContainer}>
        <Empty
          iconComponent={<WarningCircle size={20} />}
          title={t('emptyState.projectList.empty.title')}
          subtitle={
            canCreateProject
              ? t('emptyState.projectList.empty.subtitle')
              : t('emptyState.projectList.empty.subtitleForUser')
          }
        />
      </div>
    );
  }

  if (!projects.length) {
    return (
      <div className={classes.emptyStateContainer}>
        <Empty
          iconComponent={<FunnelSimple size={20} />}
          title={t('emptyState.projectList.emptyFilter.title')}
          subtitle={t('emptyState.projectList.emptyFilter.subtitle')}
        />
      </div>
    );
  }

  return (
    <div className={classes.dashboardContainer}>
      <GridNet spacing={3} columnsNumber={3}>
        {projects.map(({ id, type, name, zone, points }) => (
          <ActiveProjectCard
            id={id}
            key={id}
            type={type}
            name={name}
            zone={zone}
            points={points}
            canDeleteProject={canDeleteProject}
            canRenameProject={canRenameProject}
            onClick={redirectToProject}
            onDeleteClick={onDeleteClick}
            onRenameClick={onRenameClick}
            map={StaticProjectMap}
          />
        ))}
      </GridNet>
    </div>
  );
};

ProjectList.propTypes = {
  projects: PropTypes.array.isRequired,
  projectsData: PropTypes.object.isRequired,
  deleteProject: PropTypes.func.isRequired,
  renameProject: PropTypes.func.isRequired,
  canDeleteProject: PropTypes.bool.isRequired,
  canCreateProject: PropTypes.bool.isRequired,
  canRenameProject: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasProjects: PropTypes.bool.isRequired,
};

export default memo(ProjectList);
