import React from 'react';
import PropTypes from 'prop-types';

import { useModal } from 'hooks/useModal';
import CompleteConstraintModal from 'components/modals/CompleteConstraintModal';

/**
 * CompleteConstraintModal container
 * @param {String} id
 */
const CompleteConstraintModalContainer = ({ id }) => {
  const { onClose } = useModal(id);

  return <CompleteConstraintModal onClose={onClose} />;
};

CompleteConstraintModalContainer.propTypes = {
  id: PropTypes.string.isRequired,
};

export default React.memo(CompleteConstraintModalContainer);
