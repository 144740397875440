import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ROUTES } from 'constants/routes';
import { Loader } from 'components/common/Loader';
import DataValidationDashboard from 'components/projects/DataValidation/DataValidationDashboard';
import {
  selectProjectByIdWithPoints,
  selectProjectsIsError,
  selectProjectsShouldShowLoader,
} from 'ducks/projects/selectors';
import { requestProjectIfNeed } from 'ducks/projects/actions';
import { requestPoints } from 'ducks/points/actions';
import { requestBuoys } from 'ducks/buoys/actions';
import { selectBuoysCollection } from 'ducks/buoys/selectors';
import { BUOYS_VALIDATION_PARAM, BUOYS_ZONE_PARAM } from 'constants/buoys';
import { useModal } from 'hooks/useModal';
import {
  COMPARISON_AGAINST_MEASUREMENTS_MODAL,
  COMPARISON_AGAINST_SATELLITE,
  COMPARISON_AGAINST_SATELLITE_RESULT,
  NOTIFICATION_MODAL,
} from 'constants/modals';
import { requestSatelliteComparisons } from 'ducks/satelliteComparisons/actions';
import { selectZoneById } from 'ducks/zones/selectors';
import { requestZone } from 'ducks/zones/actions';
import { checkIsOfferExpired } from 'helpers/projects';
import { SNACKBAR_WARNING_TYPE } from 'constants/common';

/**
 * Container for data validation page. Page have analyze, reports and query.
 * @return {jsx}
 */
const DataValidation = () => {
  const { id: projectId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const project = useSelector((state) =>
    selectProjectByIdWithPoints(state, projectId)
  );
  const isShowLoader = useSelector(selectProjectsShouldShowLoader);
  const isError = useSelector(selectProjectsIsError);
  const buoys = useSelector(selectBuoysCollection);
  const zoneId = project.zone?.id;
  const zone = useSelector((state) => selectZoneById(state, zoneId));
  const isOfferExpired = useMemo(() => checkIsOfferExpired(project), [project]);
  const { openModal } = useModal();

  useEffect(() => {
    dispatch(requestProjectIfNeed({ projectId }));
    dispatch(requestPoints({ project: projectId }));
    dispatch(requestSatelliteComparisons({ project: projectId }));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (zoneId) {
      dispatch(requestZone(zoneId));
    }
  }, [dispatch, zoneId]);

  useEffect(() => {
    if (!zoneId) {
      return;
    }
    const params = {
      [BUOYS_ZONE_PARAM]: zoneId,
      [BUOYS_VALIDATION_PARAM]: 1,
    };

    dispatch(requestBuoys(params));
  }, [dispatch, zoneId]);

  useEffect(() => {
    if (isError) {
      history.replace(ROUTES.home);
    }
  }, [isError, history]);

  useEffect(() => {
    if (isOfferExpired) {
      openModal(NOTIFICATION_MODAL, {
        type: SNACKBAR_WARNING_TYPE,
        message: t('projects.offerExpired'),
      });
    }
  }, [openModal, isOfferExpired, t]);

  const openComparisonModal = useCallback(
    (point) => {
      if (!point.hasSatelliteValidation) {
        return openModal(COMPARISON_AGAINST_SATELLITE, {
          point,
          allowComparison: !isOfferExpired,
        });
      }
      openModal(COMPARISON_AGAINST_SATELLITE_RESULT, { point });
    },
    [openModal, isOfferExpired]
  );
  const openMeasurementsModal = useCallback(
    ({ id, numericalSimulation }) => {
      openModal(COMPARISON_AGAINST_MEASUREMENTS_MODAL, {
        buoyId: id,
        numericalSimulation,
        projectId,
      });
    },
    [openModal, projectId]
  );

  return !isShowLoader && zone ? (
    <DataValidationDashboard
      project={project}
      zone={zone}
      buoys={buoys}
      onPointClick={openComparisonModal}
      onBuoyClick={openMeasurementsModal}
    />
  ) : (
    <Loader center />
  );
};

export default React.memo(DataValidation);
