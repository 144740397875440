import { useState, useEffect } from 'react';

/**
 * useInterrupt
 * @param { boolean } value
 * @param { boolean } input
 * @param { boolean } output
 * @returns { boolean }
 */
export const useInterrupt = (value, input, output) => {
  const [initValue, setInitValue] = useState(null);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (initValue === null) {
      setInitValue(value);
    }
  }, [initValue, value]);

  useEffect(() => {
    if (initValue === null) {
      return;
    }
    if (initValue === input && value === output) {
      setStatus(true);
    } else {
      setStatus(false);
    }
    if (value !== initValue) {
      setInitValue(value);
    }
  }, [initValue, value, input, output]);

  return status;
};
