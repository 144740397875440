import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { getZoneLayers } from 'helpers/zones';
import { MapCustomControlLayers } from 'components/leaflet';
import { GRIDS_GROUP_NAME } from 'constants/map';

/**
 * Custom map control for zone layers
 *
 * @param {Object} zone
 * @return {jsx}
 */
const ZoneCustomLayers = ({ zone }) => {
  // useMemo useless because will rerender only if changes zone prop
  const layers = zone
    ? [{ name: GRIDS_GROUP_NAME, layers: getZoneLayers(zone) }]
    : [];

  return <MapCustomControlLayers customOverlays={layers} />;
};

ZoneCustomLayers.propTypes = {
  zone: PropTypes.object,
};

export default memo(ZoneCustomLayers);
