import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ROUTES } from 'constants/routes';
import BaseModal from 'components/common/BaseModal';

import { useStyles } from './styles';

/**
 * ResetPasswordSuccessModal component
 * Modal with info about successful reset password
 * @param {Function} onClose -  handler for close modal button
 */
const ResetPasswordSuccessModal = ({ onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <BaseModal
      onClose={onClose}
      title={t('resetPassword.modalTitle')}
      modalClass={classes.container}
    >
      <p>{t('resetPassword.modalText')}</p>
      <br />
      <p>{t('resetPassword.modalLink')}</p>

      <div className={classes.subContainer}>
        <Link to={ROUTES.registration}>
          <Button
            variant="contained"
            className={classes.floatingButton}
            classes={{ label: classes.label }}
          >
            {t('buttons.signup')}
          </Button>
        </Link>
      </div>
    </BaseModal>
  );
};

ResetPasswordSuccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default React.memo(ResetPasswordSuccessModal);
