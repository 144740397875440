import React from 'react';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { RGB_CUSTOM_MODE, HSL_LINEAR_MODE } from 'constants/color';
import { DEFAULT_GRAPH_CANVAS_HEIGHT } from 'constants/graphs';

import { useStyles } from './styles';

/**
 * Color range component - gradient color bar with ticks
 * @param { string } label
 * @param { number } width
 * @param { number } height
 * @param { string } mode
 * @param { string } className
 * @param { Array<number> } ticks
 * @param { number } offset
 * @returns { JSX }
 */
const ColorRangeBar = ({
  label,
  width,
  height,
  mode,
  ticks = [],
  offset,
  className,
}) => {
  const classes = useStyles({ width, height, offset });
  const barClassName = classNames(
    classes.bar,
    className || {
      [classes.linearGradient]: mode === HSL_LINEAR_MODE,
      [classes.deepBlueGradient]: mode === RGB_CUSTOM_MODE,
    }
  );
  const maxTick = ticks[ticks.length - 1];
  const scaleDivision = height / maxTick;

  return (
    <Grid container className={classes.container}>
      <Grid item>
        <div className={barClassName} />
      </Grid>
      <Grid item>
        {ticks.slice(0, -1).map((tick) => (
          <hr
            key={tick}
            style={{ top: height - tick * scaleDivision }}
            className={classes.tickLine}
            data-tick={tick.toFixed(1)}
          />
        ))}
      </Grid>
      {!!label && (
        <Grid item>
          <div className={classes.label}>{label}</div>
        </Grid>
      )}
    </Grid>
  );
};

ColorRangeBar.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  height: PropTypes.number,
  mode: PropTypes.string,
  className: PropTypes.string,
  offset: PropTypes.number,
  ticks: PropTypes.arrayOf(PropTypes.number).isRequired,
};

ColorRangeBar.defaultProps = {
  label: '',
  width: 20,
  offset: 0,
  height: DEFAULT_GRAPH_CANVAS_HEIGHT,
  mode: RGB_CUSTOM_MODE,
};

export default React.memo(ColorRangeBar);
