import React from 'react';
import { useTranslation } from 'react-i18next';
import PropsTypes from 'prop-types';
import { Grid, Button, TextField } from '@material-ui/core/index';

import BaseModal from 'components/common/BaseModal';
import { CREATE_ACTION } from 'constants/marineContractors';
import {
  getErrorMessages,
  getHelperTextMessage,
} from 'helpers/marineContractors';

import { useStyles } from './styles';

/**
 * Percentile modal component
 * Modal base component to create custom percentile
 * @param { function } onClose - callback used when closing modal
 * @param { function } handleCreate
 * @param { function } handleOnChange
 * @param { error } object
 * @returns { JSX }
 */
const PercentileModalComponent = ({
  onClose,
  handleCreate,
  handleOnChange,
  error,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { fieldErrors } = getErrorMessages(error);

  return (
    <BaseModal
      onClose={onClose}
      title={t('marineContractors.modals.percentiles.title')}
      modalClass={classes.container}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <TextField
            error={!!fieldErrors?.percentiles}
            label={
              fieldErrors?.percentiles
                ? t(
                    'marineContractors.fields.percentiles.labels.percentiles.error'
                  )
                : t(
                    'marineContractors.fields.percentiles.labels.percentiles.title'
                  )
            }
            onChange={handleOnChange}
            type="number"
            variant="standard"
            helperText={getHelperTextMessage(
              fieldErrors,
              'percentiles',
              'percentiles',
              CREATE_ACTION,
              t
            )}
            name="duration"
          />
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.buttonsContainer}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Button onClick={onClose} className={classes.backButton}>
            {t('marineContractors.buttons.back')}
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => handleCreate()} className={classes.buttonSafe}>
            {t('marineContractors.buttons.addPercentile')}
          </Button>
        </Grid>
      </Grid>
    </BaseModal>
  );
};

PercentileModalComponent.propTypes = {
  onClose: PropsTypes.func.isRequired,
  handleCreate: PropsTypes.func.isRequired,
  handleOnChange: PropsTypes.func.isRequired,
  error: PropsTypes.object.isRequired,
};

export default React.memo(PercentileModalComponent);
