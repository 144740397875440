import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  ({ palette, navbarHeight, dashboardToolbarHeight }) => ({
    trialHeader: {
      backgroundColor: palette.lightGreen.main,
    },
    dashboardContainer: {
      padding: 25,
    },
    emptyStateContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: `calc(100vh - ${dashboardToolbarHeight}px - ${navbarHeight}px)`,
    },
  })
);
