import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  title: {
    fontSize: 22,
    fontWeight: 600,
    color: palette.sinay.primary['40'],
    marginBottom: 15,
  },
  textContainer: {
    '& p': {
      fontSize: 13,
    },
    '& h3': {
      margin: '10px 0',
      fontSize: 16,
      fontWeight: 600,
    },
  },
}));
