import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 600,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelButton: {
    ...theme.buttons.elevated.neutral,
  },
  submitButton: {
    ...theme.buttons.fill.primary,
  },
}));
