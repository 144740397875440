import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import CheckboxIconButton from 'components/common/buttons/CheckboxIconButton';

import { useStyles } from './styles';

/**
 * AccessToProjectList component
 * Displays a list of collaborators with checkboxes
 * @param { Array } collaborators - list of collaborators
 * @param { Array } collaboratorsAccess - list of ids of collaborators, who was checked in form
 * @param { Function } updateCollaborators - handler for updating a collaborators
 */
const AccessToProjectList = ({
  collaborators,
  collaboratorsAccess,
  updateCollaborators,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ul className={classes.list}>
      {collaborators.map(({ id, firstName, lastName, email }) => (
        <li key={id} className={classes.listItem}>
          <div>
            <Typography variant="h4" className={classes.listItemTitle}>
              {!firstName && !lastName
                ? t('projects.grantAccess.defaultUserText')
                : `${firstName} ${lastName}`}
            </Typography>
            <Typography variant="body1" className={classes.listItemSubtitle}>
              {email}
            </Typography>
          </div>
          <div>
            <CheckboxIconButton
              isChecked={collaboratorsAccess.includes(id)}
              checkboxHandler={updateCollaborators}
              data-collaborator-id={id}
            />
          </div>
        </li>
      ))}
    </ul>
  );
};

AccessToProjectList.propTypes = {
  collaborators: PropTypes.array.isRequired,
  collaboratorsAccess: PropTypes.array.isRequired,
  updateCollaborators: PropTypes.func.isRequired,
};

export default React.memo(AccessToProjectList);
