import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Template from 'components/common/Template';

import { useStyles } from './styles';

/**
 * Description about zone rendering.
 * @param {Object} zone
 * @return {jsx}
 */
const ZoneInfo = ({ zone }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {zone.description ? (
        <>
          <h2 className={classes.title}>
            {t('dataValidation.environmentalConditions')}
          </h2>
          <Template
            template={zone.description}
            className={classes.textContainer}
          />
        </>
      ) : (
        <div>No description</div>
      )}
    </>
  );
};

ZoneInfo.propTypes = {
  zone: PropTypes.shape({
    description: PropTypes.string,
  }),
};

export default React.memo(ZoneInfo);
