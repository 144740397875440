/* eslint-disable */
import { takeLatest, put, call, select } from 'redux-saga/effects';

import { getProjectHistoryData } from 'services/history';
import { selectHistoryData } from 'ducks/productHistory/selectors';

import { GET_HISTORY, GET_HISTORY_IF_NEED } from './types';
import { getHistoryError, getHistory, getHistorySuccess } from './actions';

/**
 * Get product history information.
 */
export function* getProductHistoryWorker() {
  try {
    const response = yield call(getProjectHistoryData);
    yield put(getHistorySuccess(response));
  } catch (error) {
    yield put(getHistoryError(error));
  }
}

/**
 * Get product history if we don't have current information.
 */
export function* getProductHistoryIfNeed() {
  const historyData = yield select(selectHistoryData);

  if (!Object.keys(historyData).length) {
    yield put(getHistory());
  }
}

export default function* productHistoryWatcherSaga() {
  yield takeLatest(GET_HISTORY, getProductHistoryWorker);
  yield takeLatest(GET_HISTORY_IF_NEED, getProductHistoryIfNeed);
}
