import { merge } from 'lodash';

import {
  STATUS_LOADING,
  STATUS_ERROR,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
  EMPTY_VALUE,
  EMPTY_ARRAY,
} from 'constants/common';
import createReducer from 'helpers/createReducer';
import { groupAndNormalizeArrayBy } from 'helpers/data';

import {
  REQUEST_GET_MAPS,
  REQUEST_GET_MAPS_SUCCESS,
  REQUEST_GET_MAPS_ERROR,
  REQUEST_BUY_MAPS,
  REQUEST_BUY_MAPS_SUCCESS,
  REQUEST_BUY_MAPS_ERROR,
  REQUEST_GET_TILE_PROPERTIES_SUCCESS,
  REQUEST_GET_TILE_PROPERTIES_ERROR,
} from './types';

const initialState = {
  error: EMPTY_VALUE,
  data: EMPTY_VALUE,
  properties: EMPTY_VALUE,
  ids: EMPTY_ARRAY,
  status: STATUS_NOT_REQUESTED,
};

const setLoadingStatus = (state) => ({ ...state, status: STATUS_LOADING });

const setError = (state, { error }) => ({
  ...state,
  error,
  status: STATUS_ERROR,
});

const setMaps = (state, { maps }) => ({
  ...state,
  ...groupAndNormalizeArrayBy(maps, 'zone'),
  status: STATUS_SUCCESS,
});

const setMap = (state, { map }) => {
  const { [map.zone]: zoneMaps = EMPTY_ARRAY, ...data } = state.data;
  data[map.zone] = [...zoneMaps, map];

  return {
    ...state,
    data,
    status: STATUS_SUCCESS,
  };
};

const setTileProperties = (state, { properties }) => ({
  ...state,
  properties: merge(properties, state.properties),
  status: STATUS_SUCCESS,
});

export default createReducer(initialState, {
  [REQUEST_BUY_MAPS]: setLoadingStatus,
  [REQUEST_GET_MAPS]: setLoadingStatus,
  [REQUEST_GET_MAPS_SUCCESS]: setMaps,
  [REQUEST_BUY_MAPS_SUCCESS]: setMap,
  [REQUEST_GET_TILE_PROPERTIES_SUCCESS]: setTileProperties,
  [REQUEST_BUY_MAPS_ERROR]: setError,
  [REQUEST_GET_MAPS_ERROR]: setError,
  [REQUEST_GET_TILE_PROPERTIES_ERROR]: setError,
});
