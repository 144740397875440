const prefix = '@@keycloak';

export const INIT_KEYCLOAK = `${prefix}/INIT_KEYCLOAK`;
export const SET_KEYCLOAK_INSTANCE = `${prefix}/SET_KEYCLOAK_INSTANCE`;
export const AUTH_KEYCLOAK_SUCCESS = `${prefix}/AUTH_KEYCLOAK_SUCCESS`;
export const AUTH_KEYCLOAK_FAILED = `${prefix}/AUTH_KEYCLOAK_FAILED`;
export const SET_KEYCLOAK_TOKENS = `${prefix}/SET_KEYCLOAK_TOKENS`;
export const LOGOUT_KEYCLOAK = `${prefix}/LOGOUT_KEYCLOAK`;
export const IMPERSONATE_USER = `${prefix}/IMPERSONATE_USER`;
export const STOP_IMPERSONATE = `${prefix}/STOP_IMPERSONATE`;
