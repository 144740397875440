import {
  pointNotReadySatellite,
  pointNotReady,
  pointReady,
  pointNotReadySelected,
  pointNotReadySelectedSatellite,
  pointReadySatellite,
  pointReadySelected,
  pointReadySelectedSatellite,
} from 'constants/markers';

/**
 * Get not selected icon by hasSatelliteValidation and isComputed
 * @param {Boolean} hasSatelliteValidation
 * @param {Boolean} isComputed
 * @returns {MarkerIcon}
 */
export const getBaseMarkerIcon = (hasSatelliteValidation, isComputed) =>
  ({
    [hasSatelliteValidation]: pointNotReadySatellite,
    [!hasSatelliteValidation]: pointNotReady,
    [isComputed && !hasSatelliteValidation]: pointReady,
    [isComputed && hasSatelliteValidation]: pointReadySatellite,
  }.true);

/**
 * Get selected icon by hasSatelliteValidation and isComputed
 * @param {Boolean} hasSatelliteValidation
 * @param {Boolean} isComputed
 * @returns {MarkerIcon}
 */
export const getSelectedMarkerIcon = (hasSatelliteValidation, isComputed) =>
  ({
    [hasSatelliteValidation]: pointNotReadySelectedSatellite,
    [!hasSatelliteValidation]: pointNotReadySelected,
    [isComputed && !hasSatelliteValidation]: pointReadySelected,
    [isComputed && hasSatelliteValidation]: pointReadySelectedSatellite,
  }.true);

/**
 * Get icon by hasSatelliteValidation, isComputed and selected values
 * @param {Boolean} hasSatelliteValidation
 * @param {Boolean} isComputed
 * @param {Boolean} selected
 * @returns {MarkerIcon}
 */
export const getMarkerIcon = (
  hasSatelliteValidation,
  isComputed,
  selected = false
) =>
  selected
    ? getSelectedMarkerIcon(hasSatelliteValidation, isComputed)
    : getBaseMarkerIcon(hasSatelliteValidation, isComputed);
