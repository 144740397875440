/* eslint-disable */
import { takeLatest, put, call } from 'redux-saga/effects';

import {
  getGeometries,
  deleteGeometries,
  createGeometries,
  updateGeometries,
  updateLayers,
} from 'services/geometries';
import { jsonToFormData } from 'helpers/data';

import {
  REQUEST_GEOMETRIES,
  DELETE_GEOMETRY,
  UPLOAD_GEOMETRY,
  UPDATE_GEOMETRY,
  UPDATE_LAYER,
} from './types';
import {
  uploadGeometrySuccess,
  uploadGeometryError,
  requestGeometriesError,
  requestGeometriesSuccess,
  deleteGeometryError,
  deleteGeometrySuccess,
  updateGeometrySuccess,
  updateGeometryError,
  updateLayerSuccess,
  updateLayerError,
} from './actions';

/**
 * Requests all geometries
 */
export function* requestGeometriesWorker({ params }) {
  try {
    const geometries = yield call(getGeometries, params);
    yield put(requestGeometriesSuccess(geometries));
  } catch (error) {
    yield put(requestGeometriesError(error));
  }
}

/**
 * Delete geometry
 */
export function* deleteGeometryWorker({ geometryId, projectId }) {
  try {
    yield call(deleteGeometries, geometryId);
    yield put(deleteGeometrySuccess({ geometryId, projectId }));
  } catch (error) {
    yield put(deleteGeometryError(error));
  }
}

/**
 * Upload geometry
 */
export function* uploadGeometryWorker({ file, projectId }) {
  try {
    const data = yield call(jsonToFormData, {
      kml: file,
      project: projectId,
      name: file.name,
    });

    const geometry = yield call(createGeometries, data, {
      headers: { 'Content-Type': null },
    });
    yield put(uploadGeometrySuccess({ geometry, projectId }));
  } catch (error) {
    yield put(uploadGeometryError(error));
  }
}

/**
 * Update geometry
 */
export function* updateGeometryWorker({ projectId, geometryId, values }) {
  try {
    const newGeometry = yield call(updateGeometries, geometryId, values);
    yield put(updateGeometrySuccess({ projectId }, newGeometry));
  } catch (error) {
    yield put(updateGeometryError(error));
  }
}

/**
 * Update layer
 */
export function* updateLayerWorker({ projectId, geometryId, layerId, layer }) {
  try {
    const newLayer = yield call(updateLayers, geometryId, layerId, layer);
    yield put(updateLayerSuccess({ projectId, geometryId }, newLayer));
  } catch (error) {
    yield put(updateLayerError(error));
  }
}

export default function* offersSaga() {
  yield takeLatest(REQUEST_GEOMETRIES, requestGeometriesWorker);
  yield takeLatest(DELETE_GEOMETRY, deleteGeometryWorker);
  yield takeLatest(UPLOAD_GEOMETRY, uploadGeometryWorker);
  yield takeLatest(UPDATE_GEOMETRY, updateGeometryWorker);
  yield takeLatest(UPDATE_LAYER, updateLayerWorker);
}
