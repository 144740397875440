import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { closeModal, closeAllModal, showModal } from 'ducks/modals/actions';

/**
 * Hook for interactive with modal
 * Should be used only in containers
 * @param id
 * @returns {{ onClose: Function, onCloseAll: Function, openModal: Function }}
 */
export const useModal = (id = undefined) => {
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(closeModal({ id }));
  }, [dispatch, id]);

  const onCloseAll = useCallback(() => {
    dispatch(closeAllModal());
  }, [dispatch]);

  const openModal = useCallback(
    (modalType, options, modalId) => {
      dispatch(
        showModal({
          modalType,
          options,
          id: modalId,
        })
      );
    },
    [dispatch]
  );

  return { onClose, onCloseAll, openModal };
};
