import React from 'react';
import PropTypes from 'prop-types';

import BaseModal from 'components/common/BaseModal';

import { useStyles } from './styles';

/**
 * EmptyOperationModal component
 * @param {function} onClose
 */
const EmptyOperationModal = ({ onClose }) => {
  const classes = useStyles();

  return (
    <BaseModal
      title="Missing constraint"
      onClose={onClose}
      modalClass={classes.container}
      warning
    >
      <p>
        An operation have no constraint. Please add at least one constraint to
        each operation
      </p>
    </BaseModal>
  );
};

EmptyOperationModal.propTypes = {
  onClose: PropTypes.func,
};

export default React.memo(EmptyOperationModal);
