import { DATA_VALIDATION_LABEL } from 'constants/tracker';

import { SEND_TRACKING_DATA } from '../types';

export const trackBuoySelection = (buoy) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: DATA_VALIDATION_LABEL,
  gtmAction: 'Validation buoy',
  buoy,
});

export const trackNumericalSimulations = (simulationId) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: DATA_VALIDATION_LABEL,
  gtmAction: 'Numerical simulation',
  numericalSimulation: simulationId,
});

export const trackComparisonAgainstSat = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: DATA_VALIDATION_LABEL,
  gtmAction: 'Launch data validation',
});
