import {
  STATUS_LOADING,
  STATUS_ERROR,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
  EMPTY_VALUE,
  EMPTY_ARRAY,
} from 'constants/common';
import createReducer from 'helpers/createReducer';
import {
  formatLightCustomer,
  formatHeavyCustomer,
  formatLightUser,
  formatHeavyUser,
  formatLightOffer,
  formatHeavyOffer,
} from 'helpers/date';

import {
  REQUEST_CUSTOMERS,
  REQUEST_CUSTOMERS_SUCCESS,
  REQUEST_CUSTOMERS_ERROR,
  REQUEST_CUSTOMER_DETAIL,
  REQUEST_CUSTOMER_DETAIL_SUCCESS,
  REQUEST_CUSTOMER_DETAIL_ERROR,
  REQUEST_USERS,
  REQUEST_USERS_SUCCESS,
  REQUEST_USERS_ERROR,
  REQUEST_USER_DETAIL,
  REQUEST_USER_DETAIL_SUCCESS,
  REQUEST_USER_DETAIL_ERROR,
  REQUEST_OFFERS,
  REQUEST_OFFERS_SUCCESS,
  REQUEST_OFFERS_ERROR,
  REQUEST_OFFER_DETAIL,
  REQUEST_OFFER_DETAIL_SUCCESS,
  REQUEST_OFFER_DETAIL_ERROR,
} from './types';

const initialState = {
  data: {
    customers: EMPTY_ARRAY,
    offers: EMPTY_ARRAY,
    users: EMPTY_ARRAY,
  },
  error: EMPTY_VALUE,
  status: STATUS_NOT_REQUESTED,
};

const setLoadingStatus = (state) => ({ ...state, status: STATUS_LOADING });

const setError = (state, { error }) => ({
  ...state,
  error,
  status: STATUS_ERROR,
});

const setCustomers = (state, { customers }) => {
  customers = customers.map((customer) => formatLightCustomer(customer));
  return {
    ...state,
    data: {
      ...state.data,
      customers: [...customers],
    },
    status: STATUS_SUCCESS,
    error: EMPTY_VALUE,
  };
};

const setCustomer = (state, { customer }) => {
  customer = formatHeavyCustomer(customer);
  const index = state.data.customers.findIndex(
    (element) => element.id === customer.id
  );
  if (index === -1) {
    return {
      ...state,
      data: {
        ...state.data,
        customers: [customer],
      },
      status: STATUS_SUCCESS,
      error: EMPTY_VALUE,
    };
  }

  return {
    ...state,
    data: {
      ...state.data,
      customers: [
        ...state.data.customers.slice(0, index),
        {
          ...state.data.customers[index],
          ...customer,
        },
        ...state.data.customers.slice(index + 1),
      ],
    },
    status: STATUS_SUCCESS,
    error: EMPTY_VALUE,
  };
};

const setUsers = (state, { users }) => {
  users = users.map((user) => formatLightUser(user));
  return {
    ...state,
    data: {
      ...state.data,
      users: [...users],
    },
    status: STATUS_SUCCESS,
    error: EMPTY_VALUE,
  };
};

const setUser = (state, { user }) => {
  user = formatHeavyUser(user);
  const index = state.data.users.findIndex((element) => element.id === user.id);
  if (index === -1) {
    return {
      ...state,
      data: {
        ...state.data,
        users: [user],
      },
      status: STATUS_SUCCESS,
      error: EMPTY_VALUE,
    };
  }

  return {
    ...state,
    data: {
      ...state.data,
      users: [
        ...state.data.users.slice(0, index),
        {
          ...state.data.users[index],
          ...user,
        },
        ...state.data.users.slice(index + 1),
      ],
    },
    status: STATUS_SUCCESS,
    error: EMPTY_VALUE,
  };
};

const setOffers = (state, { offers }) => {
  offers = offers.map((offer) => formatLightOffer(offer));
  return {
    ...state,
    data: {
      ...state.data,
      offers: [...offers],
    },
    status: STATUS_SUCCESS,
    error: EMPTY_VALUE,
  };
};

const setOffer = (state, { offer }) => {
  offer = formatHeavyOffer(offer);
  const index = state.data.offers.findIndex(
    (element) => element.id === offer.id
  );
  if (index === -1) {
    return {
      ...state,
      data: {
        ...state.data,
        offers: [offer],
      },
      status: STATUS_SUCCESS,
      error: EMPTY_VALUE,
    };
  }

  return {
    ...state,
    data: {
      ...state.data,
      offers: [
        ...state.data.offers.slice(0, index),
        {
          ...state.data.offers[index],
          ...offer,
        },
        ...state.data.offers.slice(index + 1),
      ],
    },
    status: STATUS_SUCCESS,
    error: EMPTY_VALUE,
  };
};

export default createReducer(initialState, {
  [REQUEST_CUSTOMERS]: setLoadingStatus,
  [REQUEST_CUSTOMERS_SUCCESS]: setCustomers,
  [REQUEST_CUSTOMERS_ERROR]: setError,
  [REQUEST_CUSTOMER_DETAIL]: setLoadingStatus,
  [REQUEST_CUSTOMER_DETAIL_SUCCESS]: setCustomer,
  [REQUEST_CUSTOMER_DETAIL_ERROR]: setError,
  [REQUEST_USERS]: setLoadingStatus,
  [REQUEST_USERS_SUCCESS]: setUsers,
  [REQUEST_USERS_ERROR]: setError,
  [REQUEST_USER_DETAIL]: setLoadingStatus,
  [REQUEST_USER_DETAIL_SUCCESS]: setUser,
  [REQUEST_USER_DETAIL_ERROR]: setError,
  [REQUEST_OFFERS]: setLoadingStatus,
  [REQUEST_OFFERS_SUCCESS]: setOffers,
  [REQUEST_OFFERS_ERROR]: setError,
  [REQUEST_OFFER_DETAIL]: setLoadingStatus,
  [REQUEST_OFFER_DETAIL_SUCCESS]: setOffer,
  [REQUEST_OFFER_DETAIL_ERROR]: setError,
});
