import { makeStyles } from '@material-ui/core/styles';

import { MAP_LABEL_CLASSNAME } from 'constants/map';

export const useStyles = makeStyles({
  map: {
    [`& .${MAP_LABEL_CLASSNAME}`]: {
      color: 'white',
      textShadow: '1px 1px 2px #000',
    },
  },
});
