import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import DeletePlanningModalComponent from 'components/modals/DeletePlanningModal';
import { useModal } from 'hooks/useModal';
import { deletePlanning } from 'ducks/plannings/actions';

/**
 * DeletePlanningModal container
 * Contains logic and data for DeletePlanningModal
 * @param { number } id - modal id
 * @param {Object} planning - deleted planning
 */
const DeletePlanningModal = ({ id, planning }) => {
  const dispatch = useDispatch();
  const { onClose } = useModal(id);

  const deleteHandler = useCallback(() => {
    dispatch(
      deletePlanning({ planningId: planning.id, projectId: planning.project })
    );
    onClose();
  }, [dispatch, onClose, planning]);

  return (
    <DeletePlanningModalComponent
      onClose={onClose}
      deletePlanning={deleteHandler}
      planning={planning}
    />
  );
};

DeletePlanningModal.propTypes = {
  id: PropTypes.string.isRequired,
  planning: PropTypes.shape({
    id: PropTypes.number,
    user: PropTypes.number,
    project: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.string,
    taskId: PropTypes.string,
    document: PropTypes.string,
    computedAt: PropTypes.string,
    startedAt: PropTypes.string,
    points: PropTypes.array,
  }),
};

export default memo(DeletePlanningModal);
