import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import { EMPTY_FUNCTION, EMPTY_VALUE } from 'constants/common';
import CheckerSwatch from 'components/colors/CheckerSwatch';

import { useStyles } from './styles';

/**
 * Checker element which display colors to be selected
 * @param {Array} colors
 * @param {Function} onClick
 * @param {Function} onSwatchHover
 * @param {Text|Number} width
 * @param {Text|Hex} select
 * @param {Number} circleSize
 * @param {Object} styles
 */
const Checker = ({
  colors,
  onClick,
  onSwatchHover,
  select,
  circleSize,
  ...props
}) => {
  const classes = useStyles(props);

  const [color, setColor] = useState(select);

  const handleChange = useCallback(
    (event, value) => {
      setColor(value);
      onClick(event, value);
    },
    [onClick]
  );

  useEffect(() => {
    setColor(select);
  }, [select]);

  return (
    <Box className={classes.root}>
      {colors.map((value) => (
        <CheckerSwatch
          key={value}
          color={value}
          onClick={handleChange}
          onHover={onSwatchHover}
          select={color.toLowerCase() === value.toLowerCase()}
          circleSize={circleSize}
          {...props}
        />
      ))}
    </Box>
  );
};

Checker.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  onSwatchHover: PropTypes.func,
  circleSize: PropTypes.number,
  styles: PropTypes.object,
};

Checker.defaultProps = {
  onClick: EMPTY_FUNCTION,
  circleSize: 20,
  styles: EMPTY_VALUE,
};

export default React.memo(Checker);
