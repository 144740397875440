import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  selectProjectOverviewStatsCollectionById,
  selectProjectVisualizationDataById,
} from 'ducks/projects/selectors';
import { requestProjectVisualizationDataIfNeed } from 'ducks/projects/actions';
import { Loader } from 'components/common/Loader';
import OverviewOceanConditionsTable from 'components/projects/OverviewOceanConditionsTable';

/**
 * Container for point visualization data table
 * @param {Object} point
 * @return {jsx}
 */
const OverviewOceanConditions = ({ point }) => {
  const dispatch = useDispatch();

  const overviewStats = useSelector((state) =>
    selectProjectOverviewStatsCollectionById(state, point.project)
  );
  const visualizationData = useSelector((state) =>
    selectProjectVisualizationDataById(state, point.project, point.id)
  );

  useEffect(() => {
    if (overviewStats.length) {
      dispatch(requestProjectVisualizationDataIfNeed({ point }));
    }
  }, [overviewStats, point, dispatch]);

  return visualizationData.length ? (
    <OverviewOceanConditionsTable visualizations={visualizationData} />
  ) : (
    <Loader justifyCenter />
  );
};

OverviewOceanConditions.propTypes = {
  point: PropTypes.object.isRequired,
};

export default memo(OverviewOceanConditions);
