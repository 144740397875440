export const analyticalProbabilityDistributionGraphData = {
  id: 'WIN-magw-APD_2147',
  point: {
    id: '2147',
    name: 'test',
  },
  layer: 'WIND',
  type: 'APD',
  name: 'Analytical probability distribution',
  variables: {
    variables: [
      {
        units: 'm.s-1',
        hidden: false,
        longName: 'wind speed',
        name: 'magw',
      },
    ],
    names: ['magw'],
  },
  visualization: {
    driver: {
      suffix: null,
      method: 'plot_bars',
      fileformat: '.png',
      // eslint-disable-next-line
      path: '/mnt/glusterfs/projects/dev-ma/MA/open-ocean/ENGIE-LNG/World/WIND/Visualization/WIN-magw-APD/2147_WIN-magw-APD_all${PER}__plot_bars.png'
    },
    web: {
      'plot_bars-image-uri-0':
        '/static/3.6.4/projects/dev-ma/MA/open-ocean/ENGIE-LNG/World/WIND/Visualization/WIN-magw-APD/2147_WIN-magw-APD_all%24%7BPER%7D__plot_bars.png',
      'matlab-uri-0':
        '/static/3.6.4/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2147/WIND/WIN-magw-APD_2147.mat',
      'netcdf-uri-0':
        '/static/3.6.4/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2147/WIND/WIN-magw-APD_2147.nc',
      'excel-uri-0':
        '/static/3.6.4/projects/dev-ma/MA/open-ocean/ENGIE-LNG/Deliverables/Data/2147/WIND/WIN-magw-APD_2147.xls',
      'plot_bars-image-legend-0':
        'Analytical probability distribution of wind speed at point test',
      dataLegend0:
        'Analytical probability distribution of wind speed at point test',
      'data-uri-0':
        '/api/projects/671/get_visu_data/?stats_id=WIN-magw-APD_2147',
    },
    kwargs: {
      yName: 'magw_pdf',
      xName: 'magw_bin',
      ylabel: 'probability distribution [%]',
      annotate: true,
      grid: true,
      xlabel: 'Wind speed [m.s-1]',
    },
  },
  periodicity: 'all',
  scaleParameter: {
    attributes: {
      standard_name: 'scale_parameter',
      level_dependent: 'True',
      description: 'scale parameter of the Weibull distribution of magw',
      units: 'm.s-1',
      long_name: 'scale parameter',
      vartype: 'f8',
    },
    dimensions: ['level', 'point'],
    values: [
      [7.330993436166878],
      [10.078317404706477],
      [10.768210570322593],
      [11.171744555961968],
      [11.458077537639966],
      [11.68021752503801],
      [11.861629062500661],
      [12.015068853510003],
      [12.14795174466112],
    ],
  },
  locationParameter: {
    attributes: {
      standard_name: 'location_parameter',
      level_dependent: 'True',
      description: 'location parameter of the Weibull distribution of magw',
      units: 'm.s-1',
      long_name: 'location parameter',
      vartype: 'f8',
    },
    dimensions: ['level', 'point'],
    values: [
      [-2.0026453045666267e-10],
      [-1.9127743579150418],
      [-2.043747549734159],
      [-2.1203379412711234],
      [-2.174692181492106],
      [-2.2169018359556087],
      [-2.2513021337658636],
      [-2.2804379947710522],
      [-2.3056518869406473],
    ],
  },
  global_attributes: {
    statistic: 'Analytical probability distribution',
    history: '2020-03-27 08:36: generated by Open Ocean',
    location_name: 'test',
    longitude: "-18.45703 (18°27'25''W)",
    latitude: "15.42191 (15°25'18''N)",
    srs: 'EPSG:4326',
    period: '1979-01-01 00:00 - 2019-03-10 23:00',
    WIND_dataset: 'CFSR~CFSV2%CFSR3D',
    WIND_node_longitude: "-18.50000 (18°30'W)",
    WIND_node_latitude: "15.50000 (15°30'N)",
    WIND_node_distance: '9.8 km',
    sampling_period: '1h',
    depth: 2933.670347588957,
  },
  shapeParameter: {
    attributes: {
      standard_name: 'shape_parameter',
      level_dependent: 'True',
      description: 'shape parameter of the Weibull distribution of magw',
      units: '',
      long_name: 'shape parameter',
      vartype: 'f8',
    },
    dimensions: ['level', 'point'],
    values: [
      [3.089925554404764],
      [4.00365302487519],
      [4.003677748711514],
      [4.003673475487403],
      [4.003673665596106],
      [4.003705015525371],
      [4.003692199185728],
      [4.00369628087339],
      [4.003687878442229],
    ],
  },
  level: {
    attributes: {
      long_name: 'vertical level',
      conventions: 'positive from seabed',
      description: 'vertical level, in meters from mean sea level',
      units: 'm',
      vartype: 'f8',
      standard_name: 'vertical level',
    },
    dimensions: ['level'],
    values: [10, 25, 50, 75, 100, 125, 150, 175, 200],
  },
  law: {
    attributes: {
      description: 'name of the analytical law',
      units: '',
      vartype: 'S',
    },
    dimensions: ['level', 'point'],
    values: [
      ['Weibull'],
      ['Weibull'],
      ['Weibull'],
      ['Weibull'],
      ['Weibull'],
      ['Weibull'],
      ['Weibull'],
      ['Weibull'],
      ['Weibull'],
    ],
  },
  magwPdf: {
    attributes: {
      standard_name: 'probability_distribution_function',
      description: 'Weibull distribution of magw',
      valid_max: '100.',
      level_dependent: 'True',
      units: '%',
      long_name: 'probability distribution',
      valid_min: '0.',
      vartype: 'f8',
    },
    dimensions: ['level', 'magw_bin'],
    values: [
      [
        3.529609233243572e-21,
        0.6542303494164119,
        2.7411722816187885,
        6.114149177003311,
        10.188415494529211,
        13.941903879637055,
        16.184043251824626,
        16.08149053525881,
        13.652777363521464,
        9.826963410027583,
        5.930328898948282,
        2.960819931323176,
        1.2050258586661275,
        0.39351460259152204,
        0.10141557881115805,
        0.02027570385058071,
        0.0030897664400851973,
        0.0003524893139393589,
        0.00002955956058826552,
        0.0000017886796153174004,
        7.664892757768861e-8,
        2.2824050174664466e-9,
        4.6333390772773643e-11,
      ],
      [
        0.269665202006426,
        0.9483422164330064,
        2.265431676093507,
        4.339242643268439,
        7.115523196472518,
        10.26586073066186,
        13.144815453214017,
        14.905931659412689,
        14.83059793995441,
        12.758230678256075,
        9.311827321435127,
        5.6374985454277695,
        2.7588167552402467,
        1.0602683616765085,
        0.3100310528796704,
        0.06664243197402638,
        0.010147735704474178,
        0.001052106833807893,
        0.0000712074911633973,
        0.00000300875870467166,
        7.571724804379268e-8,
        1.080030647302972e-9,
        8.289800481590374e-12,
      ],
      [
        0.25240451224429455,
        0.8307647463929653,
        1.9240301830374225,
        3.6327279546185625,
        5.943219715398772,
        8.657181557454996,
        11.346533692389636,
        13.39245696804434,
        14.157771669654963,
        13.270707514844156,
        10.879486009485275,
        7.671251797700418,
        4.56282555247099,
        2.239855333359209,
        0.8858224086234558,
        0.2749152498516915,
        0.065081772043891,
        0.01140078757406651,
        0.0014307857568059547,
        0.00012430399576619759,
        0.000007210093578135789,
        2.6877776598565994e-7,
        6.186956972097446e-9,
      ],
      [
        0.24329536827804546,
        0.7728038551557791,
        1.7592625066245346,
        3.2936055120464287,
        5.3760359585668045,
        7.859000393471332,
        10.405404620513213,
        12.504821155290658,
        13.589205381195315,
        13.247314023183272,
        11.453882587449991,
        8.660489869903145,
        5.632810925650576,
        3.0930363726789425,
        1.4045302601533642,
        0.5156387015601167,
        0.1493543158978902,
        0.03324834032211588,
        0.00553191300824396,
        0.0006678400288718626,
        0.00005669907352915652,
        0.000003275556495259113,
        1.2439134715614264e-7,
      ],
      [
        0.2372225414509372,
        0.7356712109168281,
        1.6550526703715214,
        3.07996275416198,
        5.017529396706468,
        7.348292680583827,
        9.787477793503268,
        11.890850032800511,
        13.141038833079948,
        13.121369217200057,
        11.72075562118748,
        9.24971663658755,
        6.354670717886669,
        3.737406934439624,
        1.847031777032451,
        0.7515522351692535,
        0.24629354316205715,
        0.06348672467225328,
        0.012551423878256474,
        0.0018529276213141606,
        0.00019856205079505775,
        0.000014992476565389053,
        7.730603844829039e-7,
      ],
      [
        0.23271879824083716,
        0.7088883082614013,
        1.5805812562228094,
        2.9277587515538945,
        4.761665326629341,
        6.981030404607598,
        9.335864647013983,
        11.427625720737016,
        12.77751860003557,
        12.97346851650865,
        11.855195992555826,
        9.639539921147154,
        6.880609002514749,
        4.2454375687486365,
        2.225796719664239,
        0.9730730655848,
        0.3475778805853836,
        0.09924305084035354,
        0.02212824086963023,
        0.0037586050906853095,
        0.00047376287004662076,
        0.00004310889117362632,
        0.0000027508262188516493,
      ],
      [
        0.22915638271884284,
        0.6881961456155333,
        1.5234801846597963,
        2.811363100986145,
        4.565786036986179,
        6.698364922185354,
        8.984268724172866,
        11.05890024064881,
        12.47400657208134,
        12.825448105110864,
        11.922222342548421,
        9.914559942041778,
        7.283644482278665,
        4.659304316511495,
        2.553904022191169,
        1.1785160481519437,
        0.44916436381393515,
        0.1385111353260122,
        0.0338097340257627,
        0.0063819811677914276,
        0.0009089216798971201,
        0.000095166627767258,
        0.00000712846962896474,
      ],
      [
        0.22623448398881427,
        0.6714931623353174,
        1.477646327513417,
        2.718124457153039,
        4.408754236647866,
        6.470841683718448,
        8.698792087783241,
        10.754498288087238,
        12.214652518565867,
        12.684037089000261,
        11.95137224474386,
        10.117195520681797,
        7.603733371807332,
        5.004948417194465,
        2.841608847541183,
        1.3685379237917168,
        0.549029548686969,
        0.17993099927817588,
        0.047177739726906226,
        0.009680027118186874,
        0.0015183089164477156,
        0.00017761520483249753,
        0.000015100514611741439,
      ],
      [
        0.22376195321101744,
        0.6575725925374191,
        1.439642454481275,
        2.640960341081809,
        4.278730825984661,
        6.281854090708479,
        8.46002687603401,
        10.496548308808338,
        11.988983389174601,
        12.551112523281233,
        11.957783898211172,
        10.27113247124107,
        7.8647393192650625,
        5.299207968394105,
        3.0966200763536733,
        1.5444630763742075,
        0.6461705244197234,
        0.2225834946295651,
        0.06188105809732565,
        0.013593905692282316,
        0.002307414841396261,
        0.00029556541484765934,
        0.00002787176272184885,
      ],
    ],
  },
  magwBin: {
    attributes: {
      description: 'lower bound of the bin of magw',
      standard_name: 'lower_bin',
      units: 'm.s-1',
      vartype: 'f8',
    },
    dimensions: ['level', 'magw_bin'],
    values: [
      [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
      ],
      [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
      ],
      [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
      ],
      [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
      ],
      [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
      ],
      [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
      ],
      [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
      ],
      [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
      ],
      [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
      ],
    ],
  },
  success: true,
};
