import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelButton: {
    ...theme.buttons.outlined.neutral,
  },
  saveButton: {
    ...theme.buttons.fill.primary,
  },
}));
