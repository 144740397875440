import {
  GET_HISTORY,
  GET_HISTORY_IF_NEED,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_ERROR,
} from './types';

export const getHistory = () => ({
  type: GET_HISTORY,
});

export const getHistorySuccess = (historyData) => ({
  type: GET_HISTORY_SUCCESS,
  payload: historyData,
});

export const getHistoryError = (error = {}) => ({
  type: GET_HISTORY_ERROR,
  payload: error,
});

export const getHistoryIfNeed = () => ({
  type: GET_HISTORY_IF_NEED,
});
