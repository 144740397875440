import React, { useCallback, memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import UsageOffersComponent from 'components/usage/UsageOffers';
import { selectOffers, isReady } from 'ducks/usage/selectors';
import { requestOffers } from 'ducks/usage/actions';
import { useUrlQuery } from 'hooks/useUrlQuery';
import { hasSubString } from 'helpers/filters';
import { useExclusifFilters } from 'hooks/useExclusifFilters';
import { useArraySorting } from 'hooks/useArraySorting';

const filteringConditions = {
  name: ({ name }, expected) => hasSubString(name, expected),
};

const sortingConditions = {
  name: (a, b) => a.name.localeCompare(b.name),
};

const UsageOffers = () => {
  const dispatch = useDispatch();
  const offers = useSelector(selectOffers);
  const ready = useSelector(isReady);
  const [reverse, setReverse] = useState(false);

  const {
    urlQuery: { search, ordering = 'id', sort = 'asc' },
    mergeUrlQuery,
  } = useUrlQuery();

  let filteredOffers = useExclusifFilters({
    entities: offers,
    filters: filteringConditions,
    activeFilter: ordering,
    expectedValue: search,
  });

  filteredOffers = useArraySorting({
    entities: filteredOffers,
    conditions: sortingConditions,
    activeCondition: ordering,
    reverse,
  });

  const headers = {
    name: {
      header: 'name',
      name: 'name',
      values: [
        { order: 'name', sort: 'asc' },
        { order: 'name', sort: 'desc' },
      ],
    },
    description: {
      header: 'description',
      name: 'description',
      values: [],
    },
  };

  useEffect(() => {
    if (sort === 'asc') {
      setReverse(false);
    }
    if (sort === 'desc') {
      setReverse(true);
    }
  }, [sort, reverse]);

  useEffect(() => {
    dispatch(requestOffers());
  }, [dispatch]);

  const setSearch = useCallback(
    (value) => {
      mergeUrlQuery({ search: value });
    },
    [mergeUrlQuery]
  );

  const setParameters = useCallback(
    (event, param) => {
      mergeUrlQuery({ ordering: param.order, sort: param.sort });
    },
    [mergeUrlQuery]
  );

  return (
    <UsageOffersComponent
      headers={headers}
      search={search}
      sort={sort}
      offers={filteredOffers}
      ordering={ordering}
      onSearchChange={setSearch}
      onOrderingChange={setParameters}
      isReady={ready}
    />
  );
};
export default memo(UsageOffers);
