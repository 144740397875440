import { useCallback, useState } from 'react';

/**
 * useQuery hook.
 * TODO replace useState on useLocation and useHistory in future
 */
export function useQuery(initialQuery = {}) {
  const [query, setQuery] = useState(initialQuery);

  const mergeQuery = useCallback(
    (newQuery) =>
      setQuery((prevQuery) => ({
        ...prevQuery,
        ...newQuery,
      })),
    [setQuery]
  );

  return { query, setQuery, mergeQuery };
}
