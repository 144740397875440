import { scaleLog } from 'd3-scale';

import { getGridTicksByMinMax } from 'helpers/graphs/common';
import {
  EXTREME_PARAMETERS,
  GRAPH_EMPTY_PLACEHOLDER,
  MIN_RETURN_VALUE_TICKS_AMOUNT,
} from 'constants/graphs';
import { arrayExclude } from 'helpers/common';

/**
 * Get array with all points with all variants
 * @param {Array} returnPeriods
 * @param {Array} variants
 * @return {Array}
 */
const getLinePoints = (returnPeriods, variants) =>
  returnPeriods.map((x, index) => ({
    x,
    ...variants.reduce((variantsByLaw, variant) => {
      const {
        returnValues,
        returnValuesLower,
        returnValuesUpper,
        law,
      } = variant;

      const point = {};
      if (returnValues[index] !== GRAPH_EMPTY_PLACEHOLDER) {
        point.value = returnValues[index];
      }
      if (returnValuesLower[index] !== GRAPH_EMPTY_PLACEHOLDER) {
        point.lower = returnValuesLower[index];
      }
      if (returnValuesUpper[index] !== GRAPH_EMPTY_PLACEHOLDER) {
        point.upper = returnValuesUpper[index];
      }
      variantsByLaw[law] = point;

      return variantsByLaw;
    }, {}),
  }));

/**
 * Get min and max from line
 * @param {Array} variants
 * @return {{ min: number, max: number }}
 */
const getLineMinAndMax = (variants) =>
  variants.reduce((acc, { returnValuesLower, returnValuesUpper }) => {
    const [preparedLower, preparedUpper] = [
      returnValuesLower,
      returnValuesUpper,
    ].map((rawValues) => arrayExclude(rawValues, GRAPH_EMPTY_PLACEHOLDER));
    const yMin = Math.round(Math.min.apply(null, preparedLower));
    const yMax = Math.ceil(Math.max.apply(null, preparedUpper));

    if (!acc.min || acc.min > yMin) {
      acc.min = yMin;
    }

    if (!acc.max || acc.max < yMax) {
      acc.max = yMax;
    }

    return acc;
  }, {});

/**
 * Get min value with logarithmic.
 * @param {Array} data
 * @return {number}
 */
const getLogMinValue = (data = []) => {
  const min = Math.min.apply(null, data);
  const log = Math.log10(min);

  return 10 ** Math.floor(log);
};

/**
 * Get max value with logarithmic.
 * @param {Array} data
 * @return {number}
 */
const getLogMaxValue = (data = []) => {
  const max = Math.max.apply(null, data);
  const log = Math.log10(max);

  return 10 ** Math.ceil(log);
};

/**
 * Generate all chart ticks.
 * @param {Number} min
 * @param {Number} max
 * @return {number[]}
 *
 * TODO remove d3 scale someday
 */
const getXTicksByMinAndMax = (min, max) =>
  scaleLog()
    .domain([min, max])
    .ticks();

/**
 * return prepared extreme parameters according to config
 * @param { object } rawParameters
 * @param { object } variableMetadata
 * @see EXTREME_PARAMETERS
 */
const getPreparedParameters = (rawParameters, variableMetadata) =>
  EXTREME_PARAMETERS.reduce(
    (acc, { key, name, precision, useVariableUnits }) => {
      if (!rawParameters[key]) {
        return acc;
      }
      const rawValue = rawParameters[key];
      if (rawValue === GRAPH_EMPTY_PLACEHOLDER) {
        acc.push({ name, value: ' - ' });
        return acc;
      }

      const value = precision ? Number(rawValue).toFixed(precision) : rawValue;
      // load units values from variable metadata attribute `units`
      const units = useVariableUnits ? variableMetadata.units : undefined;

      acc.push({
        name,
        value: units ? `${value} ${units}` : value,
      });

      return acc;
    },
    []
  );

/**
 * Prepare return values plot common data for chart
 * @param {Array} data
 * @param {Object} [mainVariableMetadata]
 * @param {String} [variantName]
 * @return {Object}
 */
export const getReturnValuesPlotCommonData = (
  data,
  mainVariableMetadata,
  variantName
) =>
  data.reduce(
    (
      result,
      {
        returnPeriods = [],
        variants = [],
        empiricalReturnValues = [],
        empiricalReturnPeriods = [],
        empiricalReturnTimes = [],
        empiricalOutliersValues = [],
        empiricalOutliersPeriods = [],
        empiricalOutliersTimes = [],
        ...restParams
      }
    ) => {
      const parameters = getPreparedParameters(
        restParams,
        mainVariableMetadata
      );

      const preparedVariants = variantName
        ? variants.filter(({ law }) => law === variantName)
        : variants;

      const [
        preparedPeriods,
        preparedEmpiricalPeriods,
        preparedEmpiricalValues,
        preparedEmpiricalTimes,
        preparedEmpiricalOutliersPeriods,
        preparedEmpiricalOutliersValues,
        preparedEmpiricalOutliersTimes,
      ] = [
        returnPeriods,
        empiricalReturnPeriods,
        empiricalReturnValues,
        empiricalReturnTimes,
        empiricalOutliersPeriods,
        empiricalOutliersValues,
        empiricalOutliersTimes,
      ].map((rawValues) => arrayExclude(rawValues, GRAPH_EMPTY_PLACEHOLDER));

      const linePoints = getLinePoints(preparedPeriods, preparedVariants);
      const pointsYMin = Math.floor(
        Math.min(
          Math.min.apply(null, preparedEmpiricalValues),
          Math.min.apply(null, preparedEmpiricalOutliersValues)
        )
      );
      const pointsYMax = Math.ceil(
        Math.max(
          Math.max.apply(null, preparedEmpiricalValues),
          Math.max.apply(null, preparedEmpiricalOutliersValues)
        )
      );
      const {
        max: lineYMax = pointsYMax,
        min: lineYMin = pointsYMin,
      } = getLineMinAndMax(preparedVariants);

      const lineXMin = getLogMinValue(preparedPeriods);
      const lineXMax = getLogMaxValue(preparedPeriods);

      const pointsXMin = getLogMinValue(preparedEmpiricalPeriods);
      const pointsXMax = getLogMaxValue(preparedEmpiricalPeriods);

      // time in response is amount of days since Jesus was born
      const points = preparedEmpiricalPeriods.map((x, index) => ({
        x,
        point: preparedEmpiricalValues[index],
        timestamp: preparedEmpiricalTimes[index] * 24 * 3600 * 1000,
      }));
      const outliersPoints = preparedEmpiricalOutliersPeriods.map(
        (x, index) => ({
          x,
          outlier: preparedEmpiricalOutliersValues[index],
          timestamp: preparedEmpiricalOutliersTimes[index] * 24 * 3600 * 1000,
        })
      );

      const minXTick = Math.min(lineXMin, pointsXMin);
      const maxXTick = Math.max(lineXMax, pointsXMax);
      const minYTick = Math.min(lineYMin, pointsYMin);
      const maxYTick = Math.max(lineYMax, pointsYMax);

      const xTicks = getXTicksByMinAndMax(minXTick, maxXTick);
      const yTicks = getGridTicksByMinMax({
        min: minYTick,
        max: maxYTick,
        minTicks: MIN_RETURN_VALUE_TICKS_AMOUNT,
      });

      result.values = result.values.concat(linePoints, points, outliersPoints);
      result.xTicks = result.xTicks.concat(xTicks);
      result.yTicks = result.yTicks.concat(yTicks);
      result.variantsData = result.variantsData.concat(preparedVariants);
      result.parameters = result.parameters.concat(parameters);

      return result;
    },
    {
      values: [],
      xTicks: [],
      yTicks: [],
      variantsData: [],
      parameters: [],
    }
  );
