import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    background: 'white',
    borderRadius: 15,
    border: '1px solid',
    borderColor: theme.palette.tertiary.main,
    '&:hover': {
      background: 'white',
      transition: '300ms transform ease',
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.4)',
    },
  },
  menuItemLabel: {
    fontSize: 14,
    color: 'black',
    padding: '10px',
    margin: '5px 10px',
  },
  avatarIcon: {
    background: 'white',
    color: theme.palette.tertiary.main,
    borderRadius: 50,
  },
  userLabel: {
    fontSize: 14,
    color: 'black',
    '@media(max-width: 1200px)': {
      display: 'none',
    },
  },
  menuItemIcon: {
    width: 20,
  },
  userInitials: {
    width: 43,
    height: 43,
    borderRadius: 50,
    color: '#895020',
    background: '#FFDCC5',
    border: `1px solid #895020`,
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}));
