import omit from 'lodash/omit';

import createReducer from 'helpers/createReducer';

import { SHOW_MODAL, CLOSE_MODAL, CLOSE_ALL_MODAL } from './types';

export const initialState = {
  modalsIds: [],
  modalsById: {},
};

export default createReducer(initialState, {
  [SHOW_MODAL]: ({ modalsIds, modalsById }, { id, modalType, options }) => ({
    modalsIds: [...modalsIds, id],
    modalsById: {
      ...modalsById,
      [id]: { id, options, type: modalType },
    },
  }),
  [CLOSE_MODAL]: ({ modalsIds, modalsById }, { id }) => ({
    modalsIds: modalsIds.filter((item) => item !== id),
    modalsById: omit(modalsById, [id]),
  }),
  [CLOSE_ALL_MODAL]: () => initialState,
});
