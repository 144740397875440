import { get, pick, range } from 'lodash';

import {
  GRAPH_EMPTY_PLACEHOLDER,
  MV_PROFILE_GRAPH_VALUABLE_PARAMS,
  RAW_DATA_GRAPH_PARAMS_PATH,
  VALUES_KEY,
} from 'constants/graphs';
import { INVALID_GRAPH_DATA } from 'constants/errors';
import { camelizeBoth } from 'helpers/camelizer';
import { getTitleFromRawGraphData } from 'helpers/graphs/common';
import { MONTH_NAMES } from 'constants/common';
import { arrayExclude } from 'helpers/common';

const Y_TICKS_COUNT = 10;

/**
 * returns prepared for graph Mean Vertical Profile annual data.
 * @param rawGraphData
 * @returns {{
 *   yTicks: Array,
 *   title: string,
 *   values: { level, range: [], value }[],
 *   yDomain: [number, number],
 *   xDomain: [number, number],
 *   xTicks: number[],
 *   yLabel: string,
 *   xLabel: string,
 *   invertY: bool,
 * }}
 */
export const getPreparedMvProfileAnnualGraphData = (rawGraphData) => {
  const rawGraphParams = get(rawGraphData, RAW_DATA_GRAPH_PARAMS_PATH, null);
  if (!rawGraphParams) {
    throw Error(INVALID_GRAPH_DATA);
  }

  const { yName, xName, x2Name, invertY = false } = camelizeBoth(
    pick(rawGraphParams, MV_PROFILE_GRAPH_VALUABLE_PARAMS)
  );

  const [xValues, yValues, rangeValues] = [
    xName,
    yName,
    x2Name,
  ].map((paramName) =>
    arrayExclude(rawGraphData[paramName][VALUES_KEY], GRAPH_EMPTY_PLACEHOLDER)
  );

  const { xlabel: xLabel } = rawGraphParams;
  const { ylabel: yLabel } = rawGraphParams;

  const title = getTitleFromRawGraphData(rawGraphData);
  const commonParams = getCommonGraphDataByValuesAndRanges({
    xValues,
    yValues,
    rangeValues,
    invertY,
  });

  return {
    ...commonParams,
    title: `${title}\nAnnual`,
    xLabel,
    yLabel,
    invertY: !!invertY,
  };
};

/**
 * returns prepared for graph Mean Vertical Profile monthly data.
 * @param rawGraphData
 * @returns {{
 *   yTicks: Array,
 *   title: string,
 *   values: { level, range: [], value }[],
 *   yDomain: [number, number],
 *   xDomain: [number, number],
 *   xTicks: number[],
 *   yLabel: string,
 *   xLabel: string,
 *   invertY: bool,
 * }[]}
 */
export const getPreparedMvProfileMonthlyGraphData = (rawGraphData) => {
  const rawGraphParams = get(rawGraphData, RAW_DATA_GRAPH_PARAMS_PATH, null);
  if (!rawGraphParams) {
    throw Error(INVALID_GRAPH_DATA);
  }

  const { yName, xName, x2Name, invertY = false } = camelizeBoth(
    pick(rawGraphParams, MV_PROFILE_GRAPH_VALUABLE_PARAMS)
  );

  const yValuesByMonth = rawGraphData[yName][VALUES_KEY];
  const xValuesByMonth = rawGraphData[xName][VALUES_KEY];
  const rangeValuesByMonth = rawGraphData[x2Name][VALUES_KEY];
  const title = getTitleFromRawGraphData(rawGraphData);

  const { xlabel: xLabel } = rawGraphParams;
  const { ylabel: yLabel } = rawGraphParams;

  return xValuesByMonth.reduce((acc, xRawValues, monthIndex) => {
    const [xValues, yValues, rangeValues] = [
      xRawValues,
      yValuesByMonth[monthIndex],
      rangeValuesByMonth[monthIndex],
    ].map((values) => arrayExclude(values, GRAPH_EMPTY_PLACEHOLDER));

    const commonParams = getCommonGraphDataByValuesAndRanges({
      xValues,
      yValues,
      rangeValues,
      invertY,
    });
    acc.push({
      ...commonParams,
      title: `${title}\n${MONTH_NAMES[monthIndex]}`,
      xLabel,
      yLabel,
      invertY: !!invertY,
    });
    return acc;
  }, []);
};

/**
 * returns prepared common data for mvProfile by x, y values and ranges
 * @param xValues
 * @param yValues
 * @param rangeValues
 * @returns {{
 *   yTicks: Array,
 *   values: { level, range: [], value }[],
 *   yDomain: [number, number],
 *   xDomain: [number, number],
 *   xTicks: number[],
 * }}
 */
const getCommonGraphDataByValuesAndRanges = ({
  xValues,
  yValues,
  rangeValues,
  invertY,
}) => {
  const maxRange = Math.max(...rangeValues);
  const xMinValue = Math.min(...xValues);
  const xMaxValue = Math.max(...xValues);
  const yMinValue = Math.min(...yValues);
  const yMaxValue = Math.max(...yValues);

  // yValues can be in descending order and invert the visualization
  // in this case we reverse the yValues
  if (invertY && yMaxValue === yValues[0]) {
    yValues.reverse();
  }

  const values = rangeValues.map((currentRange, index) => ({
    level: yValues[index],
    range: [xValues[index] - currentRange, xValues[index] + currentRange],
    value: xValues[index],
  }));

  const rangeToShow = Math.ceil(xMaxValue - xMinValue + 2 * maxRange);
  const xMinTick = Math.ceil(xMinValue - maxRange);
  const step = Math.round((yMaxValue - yMinValue) / Y_TICKS_COUNT);
  const xTicks = range(xMinTick, xMinTick + rangeToShow);
  const yTicks = range(yMaxValue, yMinValue, -step);
  const xDomain = [xMinValue - maxRange, xMaxValue + maxRange];
  const yDomain = [yMinValue, yMaxValue];

  return {
    values,
    xTicks,
    yTicks,
    xDomain,
    yDomain,
  };
};
