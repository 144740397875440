import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { MapFeatureGroup } from 'components/leaflet';
import ProjectMarker from 'components/maps/ProjectMarker';
import { POINTS_GROUP_NAME } from 'constants/map';

/**
 * PointsFeatureGroup - Feature group with points
 * @param {Array} points
 * @param {React.ReactNode} children
 * @param {Function} onClick
 * @returns {jsx}
 */
const PointsFeatureGroup = ({ points, children, onClick }) => {
  const onPointClickHandler = useCallback(
    ({ layer: point }) => onClick && onClick(point.properties),
    [onClick]
  );

  return (
    <MapFeatureGroup
      name={POINTS_GROUP_NAME}
      withControls
      onClick={onPointClickHandler}
    >
      {points.map(({ id, ...point }) => (
        <ProjectMarker {...point} key={id} id={id} />
      ))}
      {children}
    </MapFeatureGroup>
  );
};

PointsFeatureGroup.propTypes = {
  points: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default memo(PointsFeatureGroup);
