import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';

import DistributionRoseGraph from 'components/common/graphs/DistributionRoseGraph';
import { getPreparedWeatherGraphData } from 'helpers/graphs/weatherGraphs';
import { wwGraphData } from 'mocks/graphs/ww';
import { waveDistributionRoseGraphData } from 'mocks/graphs/waveDistributionRose';
import {
  getPreparedDistributionRoseAnnualGraphData,
  getPreparedDistributionRoseMonthlyGraphData,
} from 'helpers/graphs/distributionRose';
import MeanVerticalProfile from 'components/common/graphs/MeanVerticalProfile';
import { meanVerticalProfileMonthlyGraphData } from 'mocks/graphs/meanVerticalProfileMonthly';
import { meanVerticalProfileAnnualGraphData } from 'mocks/graphs/meanVerticalProfileAnnual';
import AnalyticalProbabilityDistribution from 'components/common/graphs/AnalyticalProbabilityDistribution';
import { analyticalProbabilityDistributionGraphData } from 'mocks/graphs/analyticalProbabilityDistribution';
import { thetawAnnualData } from 'mocks/graphs/thetawAnnual';
import {
  getPreparedMvProfileAnnualGraphData,
  getPreparedMvProfileMonthlyGraphData,
} from 'helpers/graphs/meanVerticalProfile';
import { getPreparedAnalyticalProbabilityGraphData } from 'helpers/graphs/analyticalProbabilityDistribution';
import { weatherDowntimeData } from 'mocks/graphs/weatherDowntime';
import WeatherGraph from 'components/common/graphs/WeatherGraph';
import ProbabilityDistributionSelect from 'components/common/graphs/ProbabilityDistributionSelect';
import timeSeriesJson from 'assets/json/graphs/timeSeries.json';
import {
  getPreparedPDSelectAnnualData,
  getPreparedPDSelectMonthlyData,
} from 'helpers/graphs/probabilityDistributionSelect';
import concomitantTimeSeriesJson from 'assets/json/graphs/concomitantTimeSeries.json';
import jpdAnnualJson from 'assets/json/graphs/jpdAnnual.json';
import jpdMonthlyJson from 'assets/json/graphs/jpdMonthly.json';
import jpdWithLevesJson from 'assets/json/graphs/jpdWithLevels.json';
import jpd2AnnualJson from 'assets/json/graphs/jpd2Annual.json';
import jpd2WithPDSelectAnnual from 'assets/json/graphs/jpd2WithPDSelectAnnual.json';
import { camelizeKeys } from 'helpers/camelizer';
import { getJPDistributionCommonData } from 'helpers/graphs/jointProbabilityDistribution';
import JointProbabilityDistribution from 'components/common/graphs/JointProbabilityDistribution';
import { extremeValuesProposalData } from 'mocks/graphs/extremeValuesProposal';
import { GRAPHS_WIDGETS } from 'constants/graphs';
import {
  getPreparedConcomitantTimeSeriesData,
  getPreparedTimeSeriesData,
} from 'helpers/graphs/timeSeries';
import TimeSeriesGraph from 'components/common/graphs/TimeSeriesGraph';
import ReturnValuesPlot from 'components/common/graphs/ReturnValuesPlot';
import ExtremeValuesModal from 'components/modals/ExtremeValuesModal';
import { useToggle } from 'hooks/useToggle';
import ExtremePeakRose from 'components/common/graphs/ExtremePeakRose';
import { getPreparedExtremePeakRoseData } from 'helpers/graphs/extremePeakRose';
import { getPreparedScatterPlotData } from 'helpers/graphs/scatterPlot';
import ScatterPlot from 'components/common/graphs/ScatterPlot';
import { scatterPlotData } from 'mocks/graphs/scatterPlot';
import EmpiricalProbabilityDistribution from 'components/common/graphs/EmpiricalProbabilityDistribution';
import {
  getPreparedEmpiricalProbabilityAnnualGraphData,
  getPreparedEmpiricalProbabilityMonthlyGraphData,
} from 'helpers/graphs/empiricalProbabilityDistribution';
import epdAnnualWithLeafsJson from 'assets/json/graphs/epdAnnualWIthLeafs.json';
import epdMonthlyWithLeafsJson from 'assets/json/graphs/epdMonthlyWithLeafs.json';
import epdMonthlyJson from 'assets/json/graphs/epdMonthly.json';
import epdAnnualJson from 'assets/json/graphs/epdAnnual.json';
import extremeValuesJson from 'assets/json/graphs/extremeValues.json';
import extremeValuesWithLevelsJson from 'assets/json/graphs/extremeValuesLevels.json';
import extremeCurrentsJson from 'assets/json/graphs/extremeCurrentsLevels.json';
import ExtremeCharts from 'components/common/graphs/ExtremeCharts';
import Spacer from 'components/common/Spacer';
import ConcomitantTimeSeriesGraph from 'components/common/graphs/ConcomitantTimeSeriesGraph';
import { getPreparedExtremeValuesData } from 'helpers/graphs/extremeValues';
import { getPreparedExtremeTablesData } from 'helpers/graphs/extremeTable';
import ExtremeTableChart from 'components/common/graphs/ExtremeTableChart';
import SilentBoundary from 'components/common/SilentBoundary';

import { useStyles } from './styles';

const preparedWwData = getPreparedWeatherGraphData(wwGraphData);
const preparedWdData = getPreparedWeatherGraphData(weatherDowntimeData);

const annualRoseData = getPreparedDistributionRoseAnnualGraphData(
  waveDistributionRoseGraphData.annual
);
const monthlyRoseData = getPreparedDistributionRoseMonthlyGraphData(
  waveDistributionRoseGraphData.monthly
);
const annualMvProfileData = getPreparedMvProfileAnnualGraphData(
  meanVerticalProfileAnnualGraphData
);
const monthlyMvProfileData = getPreparedMvProfileMonthlyGraphData(
  meanVerticalProfileMonthlyGraphData
);
const annualApdData = getPreparedAnalyticalProbabilityGraphData(
  analyticalProbabilityDistributionGraphData
);

const preparedPDSelectAnnualData = getPreparedPDSelectAnnualData(
  thetawAnnualData
);

const scatterData = getPreparedScatterPlotData(camelizeKeys(scatterPlotData));

const timeSeriesRawData = camelizeKeys(timeSeriesJson);
const concomitantTimeSeriesRawData = camelizeKeys(concomitantTimeSeriesJson);
const jpdRawAnnualData = camelizeKeys(jpdAnnualJson);
const jpdRawMonthlyData = camelizeKeys(jpdMonthlyJson);
const jpdRawWithLevelsData = camelizeKeys(jpdWithLevesJson);
const jpd2RawWithPDSelectAnnualData = camelizeKeys(jpd2WithPDSelectAnnual);
const jpd2RawAnnualData = camelizeKeys(jpd2AnnualJson);
const extremeValuesRawData = camelizeKeys(extremeValuesJson);
const extremeValuesRawDataWithLevels = camelizeKeys(
  extremeValuesWithLevelsJson
);
const extremeCurrentsRawData = camelizeKeys(extremeCurrentsJson);

const timeSeriesData = getPreparedTimeSeriesData(timeSeriesRawData);
const concomitantTimeSeriesData = getPreparedConcomitantTimeSeriesData(
  concomitantTimeSeriesRawData
);
const jpdAnnualData = getJPDistributionCommonData(jpdRawAnnualData);
const jpdMonthlyData = getJPDistributionCommonData(jpdRawMonthlyData);
const jpdWithLevelsData = getJPDistributionCommonData(jpdRawWithLevelsData);
const jpd2AnnualData = getJPDistributionCommonData(jpd2RawAnnualData);
const jpd2WithPDSelectAnnualData = getPreparedPDSelectAnnualData(
  jpd2RawWithPDSelectAnnualData
);

const {
  data: { returnValuesData },
} = getPreparedExtremeValuesData(extremeValuesRawData);
const preparedExtremePeakRoseData = getPreparedExtremePeakRoseData(
  extremeValuesProposalData
);

const {
  extraProps: extraPropsEVRose,
  ...preparedExtremeValuesWithRoseData
} = getPreparedExtremeValuesData(extremeValuesRawData);

const {
  extraProps: extraPropsEVLevels,
  ...preparedExtremeValuesWithLevelsData
} = getPreparedExtremeValuesData(extremeValuesRawDataWithLevels);

const annualEpdData = getPreparedEmpiricalProbabilityAnnualGraphData(
  camelizeKeys(epdAnnualJson)
);
const monthlyEpdData = getPreparedEmpiricalProbabilityMonthlyGraphData(
  camelizeKeys(epdMonthlyJson)
);
const epdWithPDSelectAnnualData = getPreparedPDSelectAnnualData(
  camelizeKeys(epdAnnualWithLeafsJson)
);
const epdWithPDSelectMonthlyData = getPreparedPDSelectMonthlyData(
  camelizeKeys(epdMonthlyWithLeafsJson)
);

const preparedExtremeCurrents = getPreparedExtremeTablesData(
  extremeCurrentsRawData
);

/**
 * Graph controls usage example
 */
export const Graphs = () => {
  const classes = useStyles();
  const {
    isActive: isOpenWithLevels,
    toggle: toggleExtremeLevels,
  } = useToggle();
  const { isActive: isOpenWithRose, toggle: toggleExtremeRose } = useToggle();

  return (
    <SilentBoundary>
      <div className={classes.container}>
        <Grid justifyContent="center" container>
          <Grid item>
            <ExtremeTableChart annualData={preparedExtremeCurrents} />
          </Grid>
        </Grid>
        <div>
          <Grid justifyContent="center" container>
            <Grid item>
              {isOpenWithLevels ? (
                <ExtremeValuesModal
                  closeModal={toggleExtremeLevels}
                  width={extraPropsEVLevels.modalWidth}
                >
                  <ExtremeCharts
                    annualData={preparedExtremeValuesWithLevelsData}
                  />
                </ExtremeValuesModal>
              ) : (
                <Button variant="outlined" onClick={toggleExtremeLevels}>
                  Show extreme charts with levels
                </Button>
              )}
              {isOpenWithRose ? (
                <ExtremeValuesModal
                  closeModal={toggleExtremeRose}
                  width={extraPropsEVRose.modalWidth}
                >
                  <ExtremeCharts
                    annualData={preparedExtremeValuesWithRoseData}
                  />
                </ExtremeValuesModal>
              ) : (
                <Button variant="outlined" onClick={toggleExtremeRose}>
                  Show extreme charts with peak rose
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
        <div>
          <Typography align="center" gutterBottom variant="h4">
            Probability distribution select
          </Typography>
          <Grid justifyContent="center" container>
            <Grid item>
              <ProbabilityDistributionSelect
                radius={70}
                preparedData={preparedPDSelectAnnualData.data}
              />
            </Grid>
          </Grid>
          <Spacer />
        </div>
        <div id="ts">
          <Typography align="center" gutterBottom variant="h4">
            Time Series graph
          </Typography>
          <TimeSeriesGraph annualData={timeSeriesData} />
        </div>
        <div id="cts">
          <Typography align="center" gutterBottom variant="h4">
            Concomitant Time Series graph
          </Typography>
          <ConcomitantTimeSeriesGraph annualData={concomitantTimeSeriesData} />
        </div>
        <div id="ww">
          <Typography align="center" gutterBottom variant="h4">
            Weather window graph
          </Typography>
          <WeatherGraph monthlyData={preparedWwData} />
        </div>
        <div id="drose">
          <Typography align="center" gutterBottom variant="h4">
            Waves distribution rose graph
          </Typography>
          <DistributionRoseGraph
            annualData={annualRoseData}
            monthlyData={monthlyRoseData}
          />
        </div>
        <div id="mvp" className={classes.container}>
          <Typography align="center" gutterBottom variant="h4">
            Mean Vertical Profile
          </Typography>
          <MeanVerticalProfile
            monthlyData={monthlyMvProfileData}
            annualData={annualMvProfileData}
            widthGraph={500}
          />
        </div>
        <div id="analytical" className={classes.container}>
          <Typography align="center" gutterBottom variant="h4">
            Analytical Probability Distribution
          </Typography>
          <AnalyticalProbabilityDistribution annualData={annualApdData} />
        </div>
        <div id="wd">
          <Typography align="center" gutterBottom variant="h4">
            Weather downtime graph
          </Typography>
          <WeatherGraph monthlyData={preparedWdData} />
        </div>
        <div id="jpd">
          <Typography align="center" gutterBottom variant="h4">
            Empirical JPD graph
          </Typography>
          <JointProbabilityDistribution
            widgets={[GRAPHS_WIDGETS.months]}
            annualData={jpdAnnualData}
            monthlyData={jpdMonthlyData}
          />
          <Spacer height={15} />
          <div id="jpdLevels">
            <Typography align="center" gutterBottom variant="h4">
              Empirical JPD with levels graph
            </Typography>
            <JointProbabilityDistribution annualData={jpdWithLevelsData} />
            <Spacer />
            <Typography align="center" gutterBottom variant="h4">
              Empirical JPD with rose select
            </Typography>
            <JointProbabilityDistribution
              widgets={[GRAPHS_WIDGETS.vclass]}
              annualData={jpd2AnnualData}
              annualDataWidget={jpd2WithPDSelectAnnualData}
            />
          </div>
        </div>
        <div id="rValue">
          <Typography align="center" gutterBottom variant="h4">
            Return value plot
          </Typography>
          <ReturnValuesPlot
            commonData={returnValuesData}
            selectedLaw="GPD (type III)"
          />
        </div>
        <div>
          <Typography align="center" gutterBottom variant="h4">
            Scatter plot
          </Typography>
          <Grid justifyContent="center" container>
            <Grid item>
              <ScatterPlot commonData={scatterData} />
            </Grid>
          </Grid>
        </div>
        <div>
          <Typography align="center" gutterBottom variant="h4">
            Extreme peak rose
          </Typography>
          <Grid justifyContent="center" container>
            <Grid item>
              <ExtremePeakRose commonData={preparedExtremePeakRoseData} />
            </Grid>
          </Grid>
        </div>
        <div id="epd" className={classes.container}>
          <Typography align="center" gutterBottom variant="h4">
            Empirical Probability Distribution
          </Typography>
          <EmpiricalProbabilityDistribution
            annualData={annualEpdData}
            monthlyData={monthlyEpdData}
            annualDataWidget={epdWithPDSelectAnnualData}
            monthlyDataWidget={epdWithPDSelectMonthlyData}
          />
        </div>
      </div>
    </SilentBoundary>
  );
};
