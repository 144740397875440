import { Validator } from 'helpers/Validator';
import { minLength, required, email, maxLength } from 'validators';

/**
 * validation rules example
 */
export const validationRules = {
  name: new Validator([required(), minLength(3)]),
  password: new Validator([required(), minLength(4, 'Too short password!')]),
  email: new Validator([required(), email()]),
  gender: new Validator([required('choose your destiny')]),
  checkbox: new Validator([
    required('You should accept our mailing. You have no chance!'),
  ]),
  lastname: new Validator([
    required(),
    minLength(3, 'Do you really have {{length}} chars in your lastname?'),
    maxLength(20, 'Do you really have {{length}} chars in your lastname?'),
  ]),
};
