import React, { useCallback } from 'react';
import PropsTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import MCOperationsContainer from 'containers/projects/ProjectMarineContractors/Operations/index';
import MCLocationsContainer from 'containers/projects/ProjectMarineContractors/Locations/index';
import MCOutputsContainer from 'containers/projects/ProjectMarineContractors/Outputs/index';
import MCStatisticsContainer from 'containers/projects/ProjectMarineContractors/Statistics/index';
import MCProjectComponent from 'components/projects/MarineContractors/index';
import {
  saveMarineContractorsJobOperations,
  saveMarineContractorsJobOutputs,
  saveMarineContractorsJobPoints,
  updateMarineContractorsStep,
} from 'ducks/marineContractors/actions';
import { STEPS } from 'constants/marineContractors';

/**
 * Marine contractors project container
 * Main container that manage the stepper
 * @param { object } project
 * @param { object } job - save the current user progression and preferences
 * @returns
 */
const MCProjectContainer = ({ project, job }) => {
  const dispatch = useDispatch();
  const step = job.step || STEPS.OPERATIONS;

  const handleNext = useCallback(
    ({
      selectedOperations,
      selectedPoints,
      selectedPercentiles,
      selectedOutputFormat,
    }) => {
      if (selectedOperations?.length) {
        dispatch(
          saveMarineContractorsJobOperations(
            job,
            selectedOperations,
            project.id
          )
        );
        return;
      }
      if (selectedPoints?.length) {
        dispatch(
          saveMarineContractorsJobPoints(job, selectedPoints, project.id)
        );
        return;
      }
      if (selectedPercentiles?.length && !!selectedOutputFormat) {
        dispatch(
          saveMarineContractorsJobOutputs(
            job,
            selectedPercentiles,
            selectedOutputFormat,
            project.id
          )
        );
      }
    },
    [dispatch, job, project]
  );

  const handleBack = useCallback(() => {
    dispatch(updateMarineContractorsStep(job, project.id, job.step - 1));
  }, [dispatch, job, project]);

  // *WORK* display a confirm modal, to notify the user that all the data will be deleted
  const handleReset = useCallback(() => {
    dispatch(updateMarineContractorsStep(job, project.id, STEPS.OPERATIONS));
  }, [dispatch, job, project]);

  const getComponent = useCallback(() => {
    switch (step) {
      case STEPS.OPERATIONS:
        return <MCOperationsContainer handleNext={handleNext} />;
      case STEPS.LOCATIONS:
        return (
          <MCLocationsContainer
            project={project}
            job={job}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case STEPS.OUTPUTS:
        return (
          <MCOutputsContainer handleNext={handleNext} handleBack={handleBack} />
        );
      case STEPS.STATISTICS:
        return (
          <MCStatisticsContainer
            job={job}
            projectId={project.id}
            handleReset={handleReset}
          />
        );
      default:
        return <></>;
    }
    // eslint-disable-next-line
  }, [step, project, job]);

  return (
    <MCProjectComponent activeStep={step}>{getComponent()}</MCProjectComponent>
  );
};

MCProjectContainer.propTypes = {
  project: PropsTypes.object.isRequired,
  job: PropsTypes.object.isRequired,
};

export default React.memo(MCProjectContainer);
