import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ buttons }) => ({
  container: {
    width: 500,
  },
  buttonsContainer: {
    marginTop: '30px',
  },
  buttonSafe: {
    ...buttons.outlined.secondary,
  },
  backButton: {
    ...buttons.elevated.neutral,
  },
}));
