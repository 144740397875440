import { createSelector } from 'reselect';

import {
  REPORT_STATUS_RUNNING,
  REPORT_STATUS_FINISHED,
} from 'constants/reports';
import { EMPTY_VALUE } from 'constants/common';

export const selectReportsError = ({ reports }) => reports.error || EMPTY_VALUE;

export const selectCurrentReport = ({ reports }) => reports.currentReport;
export const selectCurrentReportStatus = ({ reports }) =>
  reports.currentReport.status;
export const selectIsCurrentReportRunning = ({ reports }) =>
  reports.currentReport.status === REPORT_STATUS_RUNNING;
export const selectReports = ({ reports }) => reports.allReports;

export const selectSuccessReports = createSelector(selectReports, (reports) =>
  reports.filter((report) => report.status === REPORT_STATUS_FINISHED)
);

export const selectSortedSuccessReports = createSelector(
  selectSuccessReports,
  (reports) =>
    reports.sort((a, b) => new Date(b.generatedAt) - new Date(a.generatedAt))
);

export const selectReportIsDownloadingById = ({ reports }, reportId) =>
  reports.downloadingReport.includes(reportId);

export const selectReport = (
  { reports },
  reportId,
  status = REPORT_STATUS_FINISHED
) => {
  const reportFilter = reports.allReports.filter(
    (report) => report.id === reportId && report.status === status
  );
  if (reportFilter.length === 1) {
    return reportFilter[0];
  }
};
