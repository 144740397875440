import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NavbarComponent from 'components/common/Navbar';
import { userLogout } from 'ducks/user/actions';
import { selectCurrentUser } from 'ducks/user/selectors';
import { selectAppIsInHijackMode } from 'ducks/app/selectors';
import { appHijackEject } from 'ducks/app/actions';

/**
 * Navbar container
 */
const Navbar = (props) => {
  const hijackMode = useSelector(selectAppIsInHijackMode);
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const logoutUser = useCallback(() => {
    dispatch(hijackMode ? appHijackEject() : userLogout());
  }, [dispatch, hijackMode]);

  return <NavbarComponent user={user} logout={logoutUser} {...props} />;
};

export default React.memo(Navbar);
