import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Metadata from 'components/common/Metadata';
import { formatDate } from 'helpers/date';
import { DATE_FORMATS } from 'constants/common';
import {
  convertCoordinateToString,
  getPositionFromGeometry,
} from 'helpers/map';
import { MAP_PROPERTIES } from 'constants/map';

/**
 * Information about buoy.
 * @param {Object} buoy
 * @return {jsx}
 */
const BuoyMetadata = ({ buoy }) => {
  const { t } = useTranslation();

  const metadataFields = [
    { field: 'name', label: t('buoys.metadata.name') },
    { field: 'provider', label: t('buoys.metadata.provider') },
    { field: 'sensors', label: t('buoys.metadata.sensors') },
    { field: 'from', label: t('buoys.metadata.from') },
    { field: 'to', label: t('buoys.metadata.to') },
    { field: 'lat', label: t('buoys.metadata.latitude') },
    { field: 'lng', label: t('buoys.metadata.longitude') },
  ];
  const [latitude, longitude] = getPositionFromGeometry(buoy.geom);

  const metadata = {
    name: buoy.name,
    provider: buoy.centre,
    sensors: buoy.sensors.join(', '),
    from: formatDate(buoy.dateBegin, DATE_FORMATS.shortMonthFormat),
    to: formatDate(buoy.dateEnd, DATE_FORMATS.shortMonthFormat),
    lat: convertCoordinateToString(latitude, MAP_PROPERTIES.latitude),
    lng: convertCoordinateToString(longitude, MAP_PROPERTIES.longitude),
  };

  return <Metadata title="Metadata" fields={metadataFields} data={metadata} />;
};

BuoyMetadata.propTypes = {
  buoy: PropTypes.shape({
    name: PropTypes.string,
    centre: PropTypes.string,
    geom: PropTypes.string,
    dateBegin: PropTypes.string,
    dateEnd: PropTypes.string,
    sensors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default memo(BuoyMetadata);
