import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttons: {
    marginTop: 5,
    '& button': {
      outline: 'none',
      color: palette.primary.main,
      textTransform: 'none',
    },
  },
  loader: {
    marginLeft: spacing(1),
  },
}));
