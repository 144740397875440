/*eslint-disable*/
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropsTypes from 'prop-types';

import MCStatisticsComponent from 'components/projects/MarineContractors/Statistics/index';
import MCRunningComponent from 'components/projects/MarineContractors/Run/index';
import { Loader } from 'components/common/Loader';
import {
  RUNNING_TASK_STATUS,
  FINISHED_TASK_STATUS,
  FAILED_TASK_STATUS,
} from 'constants/projects';
import {
  updateMarineContractorsStep,
  requestMarineContractorsResults,
  downloadResults,
  cleanJobStatus,
} from 'ducks/marineContractors/actions';
import { STEPS } from 'constants/marineContractors';
import {
  selectMarineContractorsResults,
  selectMarineContractorsResultsStatus,
} from 'ducks/marineContractors/selectors';
import { getBoatsFromJob } from 'helpers/marineContractors';
import { STATUS_ERROR, STATUS_NOT_REQUESTED } from 'constants/common';

/**
 * MCStatisticsContainer - display computation results
 * @param { Object } job - computation informations
 * @param { Number } projectId
 * @param { Function } handleReset - reset all results and create a duplicate project job for a new computation
 * @returns { JSX }
 */
const MCStatisticsContainer = ({ job, projectId, handleReset }) => {
  const dispatch = useDispatch();

  const boats = useMemo(() => getBoatsFromJob(job), [job]);
  const points = useMemo(() => job.parameters?.points, [job]);
  const percentiles = useMemo(() => job.parameters?.percentiles, [job]);

  const [boat, setBoat] = useState(null);
  const [point, setPoint] = useState(null);
  const [operations, setOperations] = useState();

  const data = useSelector((state) => selectMarineContractorsResults(state));
  const status = useSelector((state) =>
    selectMarineContractorsResultsStatus(state)
  );

  /**
   * getJobOperations
   * map the selected point and the selected boat with the output data
   * format output data to fit with MCWeatherGraph `operations` input
   */
  const getJobOperations = useCallback(() => {
    if (point === null || boat === null) {
      return [];
    }
    const pointId = point.id.toString();
    const boatId = boat.id.toString();
    const jobOperations = job.parameters?.operations
      .filter((operation) => operation.boat === boat.id)
      .map((operation) => {
        const operationId = operation.id.toString();
        return {
          name: operation.name,
          id: operation.id,
          data: data.dataByPoints[pointId][boatId][operationId],
          format: data.dataFormat,
          constraints: operation.constraints,
          duration: operation.duration,
        };
      });
    return jobOperations;
    // eslint-disable-next-line
  }, [job, data, point, boat])

  const handleChangePoints = (_event, id) => {
    setPoint(points.find((p) => p.id === id));
  };

  const handleChangeBoats = (_event, id) => {
    setBoat(boats.find((b) => b.id === id));
  };

  // trigger excel download
  const handleDownload = () => {
    dispatch(downloadResults(job.id, job.project));
  };

  const handleResetResults = () => {
    dispatch(cleanJobStatus());
    handleReset();
  }

  // init point and boat selection
  useEffect(() => {
    if (points.length) {
      setPoint(points[0]);
      if (boats.length) {
        setBoat(boats[0]);
        return;
      }
    }
    throw Error('unable to set default point and boat');
    // eslint-disable-next-line
  }, [points])

  useEffect(() => {
    switch (status) {
      case STATUS_NOT_REQUESTED:
        if (job.status === FINISHED_TASK_STATUS) {
          dispatch(requestMarineContractorsResults(job));
        }
        break;
      case STATUS_ERROR:
        // *WORK* display notification when error occured
        console.error('dispatch notification error');
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [job, status])

  useEffect(() => {
    if (
      Object.keys(data).length === 0 ||
      Object.keys(data.dataByPoints).length === 0
    ) {
      return;
    }
    const jobOperations = getJobOperations();
    setOperations(jobOperations);
    // eslint-disable-next-line
  }, [data, getJobOperations])

  useEffect(() => {
    if (job.status === FAILED_TASK_STATUS) {
      dispatch(updateMarineContractorsStep(job, projectId, STEPS.OUTPUTS));
    }
    // eslint-disable-next-line
  }, [job])

  switch (job.status) {
    case RUNNING_TASK_STATUS:
      return <MCRunningComponent job={job} />;
    case FINISHED_TASK_STATUS:
      if (operations === undefined) {
        return <Loader center />;
      }
      return (
        <MCStatisticsComponent
          boats={boats}
          points={points}
          boat={boat}
          point={point}
          operations={operations}
          percentiles={percentiles}
          handleReset={handleResetResults}
          handleChangePoints={handleChangePoints}
          handleChangeBoats={handleChangeBoats}
          handleDownload={handleDownload}
        />
      );
    default:
      break;
  }
};

MCStatisticsContainer.propTypes = {
  job: PropsTypes.object.isRequired,
  projectId: PropsTypes.number.isRequired,
  handleReset: PropsTypes.func.isRequired,
};

export default React.memo(MCStatisticsContainer);
