import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    width: 500,
  },
  body: {
    wordBreak: 'break-word',
    padding: '10px 5px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginRight: 15,
    },
  },
}));
