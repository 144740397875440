import { buoy, buoyCurrent, buoyWave, buoyWind } from 'constants/markers';

export const BUOY_TYPE_ICONS = {
  WIND: buoyWind,
  WAVES: buoyWave,
  CURRENTS: buoyCurrent,
  WATER_HEIGHTS: buoyWave,
  DEFAULT: buoy,
};

/**
 * Get buoy icon for
 *
 * @param {Array|String} [sensors] - could be array or string with single item
 * @param {String} [type]
 * @return {MarkerIcon}
 */
export const getBuoyIcon = (type, sensors = []) =>
  BUOY_TYPE_ICONS[type] ||
  BUOY_TYPE_ICONS[sensors[0]] ||
  BUOY_TYPE_ICONS[sensors] ||
  BUOY_TYPE_ICONS.DEFAULT;
