import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import NativeLink from 'components/common/NativeLink';

import { useStyles } from '../styles';

/**
 * SectionsCoordinatesTitles component for use in PlanningForm
 * Displays titles in coordinate section
 * @param { string } userEmail - current user email
 */
const SectionsCoordinatesTitles = ({ userEmail }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <Typography align="center" className={classes.title}>
        {t('downtime.planningSettings.processTimeTitle')}
      </Typography>
      <Typography align="center" className={classes.title}>
        {t('downtime.planningSettings.finishedEmailTitlePartOne')}
        <NativeLink to={`mailto:${userEmail}`} className={classes.emailLink}>
          {userEmail}
        </NativeLink>
        {t('downtime.planningSettings.finishedEmailTitlePartTwo')}
      </Typography>
    </div>
  );
};

SectionsCoordinatesTitles.propTypes = {
  userEmail: PropTypes.string.isRequired,
};

export default memo(SectionsCoordinatesTitles);
