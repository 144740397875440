import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    borderRight: `1px solid #e0e0fe`,
    width: 280,
    paddingRight: 15,
    marginRight: 10,
  },
  floatingButton: {
    height: 43,
    ...theme.buttons.elevated.primary,
    zIndex: theme.zIndexNavBar + 1,
    '@media(max-width: 1200px)': {
      width: 100,
      '& .MuiButton-startIcon': {
        margin: 0,
      },
    },
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: 650,
    textTransform: 'none',
  },
  textLabel: {
    '@media(max-width: 1200px)': {
      display: 'none',
    },
  },
  shareIcon: {
    width: 20,
    marginRight: 5,
  },
}));
