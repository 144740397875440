import {
  REQUEST_OFFERS,
  REQUEST_OFFERS_SUCCESS,
  REQUEST_OFFERS_ERROR,
  REQUEST_OFFERS_IF_NEED,
} from './types';

export const requestOffers = () => ({
  type: REQUEST_OFFERS,
});

export const requestOffersSuccess = (data) => ({
  type: REQUEST_OFFERS_SUCCESS,
  data,
});

export const requestOffersError = (error) => ({
  type: REQUEST_OFFERS_ERROR,
  error,
});

export const requestOffersIfNeed = () => ({
  type: REQUEST_OFFERS_IF_NEED,
});
