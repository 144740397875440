import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Grid,
  IconButton,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Trash, Info, Graph } from 'phosphor-react';

import { selectProjectById } from 'ducks/projects/selectors';
import Collapse from 'components/common/Collapse';
import QueryPointsList from 'components/points/QueryPointsList';
import UploadButton from 'components/common/buttons/UploadButton';
import { ROUTES } from 'constants/routes';
import { EMPTY_ARRAY } from 'constants/common';
import {
  trackPointSelection,
  trackAllPointsSuppression,
  trackPointSuppression,
  trackPointsUploadHelp,
} from 'ducks/trackers/actions/workzone';

import { useStyles } from './styles';

/**
 * PointLocations - this component render collapse with points list and interactive buttons
 * @param {Boolean} isRunningProject
 * @param {Boolean} allowEditAndCompute
 * @param {Array} points
 * @param {Function} onDeleteAll
 * @param {Function} onUpload
 * @param {Function} onDelete
 * @param {Function} onComputeStatistics
 * @param {Function} onCancelComputeStatistics
 * @param {Array} selectedMarkers
 * @param {Function} onSelectMarker
 * @param {Function} selectedPoints
 * @param {string} className
 * @param {Function} handleSelectAll
 * @returns {jsx}
 */
const PointLocations = ({
  points,
  onDeleteAll,
  onUpload,
  onDelete,
  onComputeStatistics,
  selectedMarkers,
  onSelectMarker,
  isRunningProject,
  allowEditAndCompute,
  onCancelComputeStatistics,
  canCompute,
  handleMultipleSelection,
  selectedPoints,
  className,
  handleSelectAll,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();

  const { id: projectId } = useParams();
  const project = useSelector((state) => selectProjectById(state, projectId));
  const isTrial = project.offerInstance.isTrial;

  useEffect(() => {
    const firstPoint = points[0];
    if (firstPoint && !selectedMarkers.includes(firstPoint.id)) {
      onSelectMarker(firstPoint);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (handleSelectAll) {
      if (selectedPoints.length === points.length) {
        setChecked(true);
        return;
      }
      if (!selectedPoints.length) {
        setChecked(false);
      }
    }
  }, [handleSelectAll, points, selectedPoints]);

  const handleLinkClick = useCallback((e) => {
    dispatch(trackPointsUploadHelp());
    e.stopPropagation();
  }, []);

  const handlePointClick = (e) => {
    onSelectMarker(e);
    dispatch(trackPointSelection());
  };

  const handlePointSuppression = (e) => {
    onDelete(e);
    dispatch(trackPointSuppression());
  };

  const handleAllPointsSuppressions = (e) => {
    onDeleteAll(e);
    dispatch(trackAllPointsSuppression());
  };

  const getSelectAllJSX = () => {
    if (!!points.length && !!handleSelectAll) {
      return (
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              indeterminate={
                !!selectedPoints.length &&
                selectedPoints.length !== points.length
              }
              onClick={handleSelectAll}
              checked={checked}
            />
          }
          label={t('points.selectAll')}
        />
      );
    }
  };

  const getComputeJSX = () => {
    if (canCompute) {
      return isRunningProject ? (
        <Button
          onClick={onCancelComputeStatistics}
          className={classNames(
            classes.pointsButton,
            classes.cancelComputationButton
          )}
        >
          {t('points.cancelComputeStatistics')}
        </Button>
      ) : (
        <Button onClick={onComputeStatistics} className={classes.computeButton}>
          <Graph size={20} className={classes.analyzeIcon} />
          {t('points.computeStatistics')}
        </Button>
      );
    }
  };

  return (
    <div className={classes.wrapper}>
      <Collapse
        className={className}
        title={t('points.pointsLocations')}
        isOpen
      >
        {points.length ? (
          <QueryPointsList
            points={points}
            canDeletePoints={!isTrial && allowEditAndCompute}
            disabled={isRunningProject}
            selectedMarkers={selectedMarkers}
            onClick={handlePointClick}
            onDelete={handlePointSuppression}
            handleMultipleSelection={handleMultipleSelection}
            selectedPoints={selectedPoints}
          />
        ) : (
          <Box className={classes.messageNoPoints}>
            <Box className={classes.messageNoPointsBig}>
              {t('points.noPointsMessage')}
            </Box>
            {t('points.addNewMessage')}
          </Box>
        )}
        {!isTrial && allowEditAndCompute && (
          <Grid
            container
            direction="column"
            className={classes.pointsButtonGroup}
          >
            <UploadButton
              disabled={isRunningProject}
              className={classes.uploadCard}
              onUpload={onUpload}
              endIcon={
                <IconButton
                  onClick={handleLinkClick}
                  aria-label="Link to help"
                  size="small"
                  component={Link}
                  to={ROUTES.faqToCreateLocation}
                  target="_blank"
                >
                  <Info size={20} className={classes.infoIcon} />
                </IconButton>
              }
            >
              {t('points.uploadZippedShapeFile')}
            </UploadButton>
            {!!points.length && (
              <>
                {getComputeJSX()}
                {getSelectAllJSX()}
                <Button
                  disabled={isRunningProject}
                  onClick={handleAllPointsSuppressions}
                  className={classes.removeAllButton}
                >
                  <Trash size={20} className={classes.deleteIcon} />
                  {t('points.removeAll')}
                </Button>
              </>
            )}
          </Grid>
        )}
      </Collapse>

      {!isTrial && (
        <Collapse
          className={classes.howToSelectPointContainer}
          title={t('points.howSelectPoints')}
          isOpen
        >
          <div className={classes.content}>
            <p>Click anywhere in the zone.</p>

            <p>
              Name your location once you have confirmed the displayed
              coordinates and click <strong>Save</strong>.
            </p>

            <p>
              To remove a location, click on its icon on the map. To remove all
              locations at once, you can select{' '}
              <strong>Remove all points</strong>.
            </p>

            <p>
              Once you have entered all desired points on the map, you can click
              on the button <strong>Compute statistics</strong>.
            </p>
          </div>
        </Collapse>
      )}
    </div>
  );
};

PointLocations.propTypes = {
  points: PropTypes.array.isRequired,
  isRunningProject: PropTypes.bool.isRequired,
  allowEditAndCompute: PropTypes.bool.isRequired,
  onDeleteAll: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onComputeStatistics: PropTypes.func.isRequired,
  onSelectMarker: PropTypes.func.isRequired,
  selectedMarkers: PropTypes.arrayOf(PropTypes.number),
  onCancelComputeStatistics: PropTypes.func.isRequired,
  canCompute: PropTypes.bool,
  handleMultipleSelection: PropTypes.func,
  selectedPoints: PropTypes.array,
  className: PropTypes.string,
  handleSelectAll: PropTypes.func,
};

PointLocations.defaultProps = {
  canCompute: true,
  handleMultipleSelection: null,
  selectedPoints: EMPTY_ARRAY,
  className: null,
  handleSelectAll: null,
};

export default React.memo(PointLocations);
