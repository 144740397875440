import { EMPTY_ARRAY, EMPTY_VALUE, STATUS_ERROR } from 'constants/common';
import { COMMON_ERRORS_KEYS } from 'constants/apiErrors';

/**
 * Helper function to make sure that error from api will always have same shape
 * @param {Object} errors
 * @return {{common: [], entityErrors: {}}}
 */
export const prepareErrorShape = (errors) => {
  if (typeof errors === 'string') {
    return {
      common: [errors],
      entityErrors: EMPTY_VALUE,
    };
  }
  if (typeof errors !== 'object') {
    return {
      common: EMPTY_ARRAY,
      entityErrors: EMPTY_VALUE,
    };
  }

  return Object.entries(errors).reduce(
    (acc, [key, value]) => {
      if (Array.isArray(value)) {
        acc.allErrors.push(...value);
      } else {
        acc.allErrors.push(value);
      }
      if (COMMON_ERRORS_KEYS.includes(key)) {
        if (Array.isArray(value)) {
          acc.common.push(...value);
        } else {
          acc.common.push(value);
        }
      } else {
        acc.entityErrors[key] = value;
      }

      return acc;
    },
    {
      common: [],
      entityErrors: {},
      allErrors: [],
    }
  );
};

/**
 * Helper function for reducers. Accepts action with error payload
 * and transforms error to common error shape.
 * @param state
 * @param error
 * @return {*&{error: {common: *[], entityErrors: {}}, status: string}}
 */
export const prepareAndSetError = (state, { error }) => ({
  ...state,
  error: prepareErrorShape(error?.message),
  status: STATUS_ERROR,
});

/**
 * Helper function that creates selector for all errors from api
 * @param selector
 * @return {function(*=)}
 */
export const createApiErrorsArraySelector = (selector) => (state) =>
  selector(state).allErrors || EMPTY_ARRAY;
