import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  relative: {
    marginLeft: 5,
  },
  center: {
    position: 'fixed',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  primaryValueContainer: {
    top: 5,
    width: 30,
    marginLeft: -15,
    position: 'absolute',
    left: '50%',
    display: 'block',
    color: theme.palette.sinay.primary['40'],
    textAlign: 'center',
    fontSize: 12,
  },
  whiteValueContainer: {
    top: 5,
    width: 30,
    marginLeft: -15,
    position: 'absolute',
    left: '50%',
    display: 'block',
    color: 'white',
    textAlign: 'center',
    fontSize: 12,
  },
  bgPrimaryColor: {
    position: 'absolute',
    color: theme.palette.sinay.primary['40'],
    opacity: 0.3,
  },
  bgWhiteColor: {
    position: 'absolute',
    color: theme.palette.sinay.primary['70'],
  },
  primaryBarColor: {
    color: theme.palette.sinay.primary['40'],
  },
  whiteBarColor: {
    color: 'white',
  },
}));
