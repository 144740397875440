import React, { useEffect, useState } from 'react';
import PropsTypes from 'prop-types';
import { LinearProgress, Grow, CircularProgress } from '@material-ui/core';

/**
 * Block component updating (disable and show a progress bar while updating)
 * @param { Node } children
 * @param { Boolean } isUpdating
 * @param { Boolean } noGrow - show a transition when mounting component
 * @param { Boolean } isCircular - hide the child component and show a circular progress (used for tiny component)
 */
const BlockUpdateComponent = ({
  children,
  isUpdating,
  noGrow = false,
  isCircular = false,
}) => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(isUpdating);
    }, 2000);

    return () => {
      clearTimeout(timeout);
      setShowLoader(false);
    };
  }, [isUpdating]);

  const getBlockChildren = () => (
    <div style={{ pointerEvents: 'none', opacity: '0.4' }}>{children}</div>
  );

  const getLoaderComponent = () => {
    if (!isCircular) {
      return (
        <div>
          {getBlockChildren()}
          {showLoader ? <LinearProgress color="secondary" /> : <></>}
        </div>
      );
    }
    return showLoader ? <CircularProgress size={20} /> : getBlockChildren();
  };

  return (
    <>
      {isUpdating ? (
        <Grow in appear={!noGrow}>
          {getLoaderComponent()}
        </Grow>
      ) : (
        <Grow in appear={!noGrow}>
          <div>{children}</div>
        </Grow>
      )}
    </>
  );
};

BlockUpdateComponent.propTypes = {
  children: PropsTypes.node.isRequired,
  isUpdating: PropsTypes.bool.isRequired,
  noGrow: PropsTypes.bool,
  isCircular: PropsTypes.bool,
};

BlockUpdateComponent.defaultProps = {
  noGrow: false,
  isCircular: false,
};

export default React.memo(BlockUpdateComponent);
