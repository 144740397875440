import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useModal } from 'hooks/useModal';
import {
  selectLastSatelliteComparisonByPoint,
  selectLastSatelliteComparisonStatsByPoint,
} from 'ducks/satelliteComparisons/selectors';
import {
  requestSatelliteComparisonStats,
  downloadSatelliteComparisonStats,
} from 'ducks/satelliteComparisons/actions';
import LoaderOverlay from 'components/common/LoaderOverlay';
import ComparisonAgainstSatelliteResultModal from 'components/modals/ComparisonAgainstSatelliteResultModal';
import { NOTIFICATION_MODAL } from 'constants/modals';
import SilentBoundary from 'components/common/SilentBoundary';

/**
 * Container for modal. Make a request to server for visualization data.
 * @param {Number} id
 * @param {Object} point
 * @return {jsx}
 */
const ComparisonAgainstSatelliteResult = ({ id, point }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const satelliteComparison = useSelector((state) =>
    selectLastSatelliteComparisonByPoint(state, point.id)
  );
  const satelliteComparisonStats = useSelector((state) =>
    selectLastSatelliteComparisonStatsByPoint(state, point.id)
  );
  const { onClose, openModal } = useModal(id);

  useEffect(() => {
    if (!satelliteComparisonStats) {
      dispatch(requestSatelliteComparisonStats(satelliteComparison.id));
    }
  }, [dispatch, satelliteComparison, satelliteComparisonStats]);

  const handleDownloadSatelliteComparisonStats = useCallback(() => {
    dispatch(
      downloadSatelliteComparisonStats({ comparisonId: satelliteComparison.id })
    );
  }, [dispatch, satelliteComparison.id]);

  const errorHandler = useCallback(() => {
    onClose();
    openModal(NOTIFICATION_MODAL, {
      message: t('errors.common'),
      autoHideDuration: null,
    });
  }, [onClose, openModal, t]);

  return !satelliteComparisonStats ? (
    <LoaderOverlay center />
  ) : (
    <SilentBoundary onError={errorHandler}>
      <ComparisonAgainstSatelliteResultModal
        pointName={point.name}
        statistics={satelliteComparisonStats}
        onClose={onClose}
        onClick={handleDownloadSatelliteComparisonStats}
      />
    </SilentBoundary>
  );
};

ComparisonAgainstSatelliteResult.propTypes = {
  id: PropTypes.string.isRequired,
  point: PropTypes.shape({
    id: PropTypes.number,
    project: PropTypes.number,
  }).isRequired,
};

export default memo(ComparisonAgainstSatelliteResult);
