import { formatDate } from 'helpers/date';
import { getTitleFromRawGraphData } from 'helpers/graphs/common';
import { DATE_FORMATS } from 'constants/common';

const formatExportInfo = (data) => {
  data.forEach((info) => {
    const begin = formatDate(info.begin, DATE_FORMATS.commonFormat);
    const end = formatDate(info.end, DATE_FORMATS.commonFormat);
    info.period = `${begin} to ${end}`;
    info.variables = info.variables.join(', ');
  });
};

export const formatData = (rawData) => {
  const title = getTitleFromRawGraphData(rawData);
  const data = rawData.datasets;
  formatExportInfo(data);
  return {
    title,
    data,
    point: rawData.point,
  };
};
