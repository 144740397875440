import React, { useCallback, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';

import ExtremeValueAnalysisTable from 'components/common/graphs/ExtremeValueAnalysis';
import ReturnValuesPlot from 'components/common/graphs/ReturnValuesPlot';
import ExtremePeakRose from 'components/common/graphs/ExtremePeakRose';
import GraphLevelSelect from 'components/common/graphs/GraphLevelSelect';
import DownloadGraphDataFileButtonGroup from 'containers/buttons/DownloadGraphDataFileButtonGroup';
import {
  DEFAULT_GRAPH_DATA_LOADING_FORMATS,
  IMAGE_GRAPH_DATA_FORMAT,
} from 'constants/graphs';
import { arrayExclude } from 'helpers/common';

import { useStyles } from './styles';

/**
 * Extreme charts component with laws switcher, shows extreme values for different distribution laws
 * Consists of: Extreme analysis table, Return values chart, Rose peak chart
 * If `hasOutliers` is set, an additonnal set of (outlier) points will be represented
 * in the Return value chart and the Rose peak chart.
 * @param { object } annualData
 * @param { number } projectId
 * @param { Array } statsIds
 * @param { bool } hasOutliers
 * @returns { JSX }
 * @see ExtremeValueAnalysisTable
 * @see ReturnValuesPlot
 * @see ExtremePeakRose
 */
const ExtremeCharts = ({
  annualData,
  projectId,
  statsIds,
  hasOutliers = false,
}) => {
  const classes = useStyles();

  const [selectedLaw, setSelectedLaw] = useState();
  const [levelIndex, setLevelIndex] = useState(0);

  const { levels, data, title } = annualData;
  const hasLevels = !!Object.keys(levels).length;
  const { tableData, returnValuesData, peakRoseData } = hasLevels
    ? data[levelIndex]
    : data;

  const { variants = [] } = tableData;

  const hasOneLaw = variants.length === 1;
  const [{ law: firstLaw }] = variants;

  const handleSelectLaw = useCallback(
    (event, newLaw) => setSelectedLaw(newLaw),
    []
  );

  const lawNames = useMemo(() => variants.map((variant) => variant.law), [
    variants,
  ]);

  return (
    <div>
      <Typography variant="subtitle1" className={classes.title}>
        {title}
      </Typography>
      <Grid container spacing={1} justifyContent="center">
        {hasLevels && (
          <Grid item>
            <GraphLevelSelect
              height={530}
              levels={levels.values}
              type={levels.type}
              currentLevel={levelIndex}
              selectLevel={setLevelIndex}
            />
          </Grid>
        )}
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <ExtremeValueAnalysisTable
                    preparedData={tableData}
                    selectedLaw={hasOneLaw ? firstLaw : selectedLaw}
                    lawNames={lawNames}
                  />
                </Grid>
                {lawNames.length && !hasOneLaw && (
                  <Grid item>
                    <ToggleButtonGroup
                      exclusive
                      onChange={handleSelectLaw}
                      value={selectedLaw}
                      className={classes.buttonGroup}
                    >
                      {lawNames.map((law) => (
                        <ToggleButton value={law} key={`button-${law}`}>
                          {law}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <ReturnValuesPlot
                    commonData={returnValuesData}
                    selectedLaw={hasOneLaw ? firstLaw : selectedLaw}
                    hasOutliers={hasOutliers}
                  />
                </Grid>
                {peakRoseData && (
                  <Grid item>
                    <ExtremePeakRose
                      commonData={peakRoseData}
                      hasOutliers={hasOutliers}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DownloadGraphDataFileButtonGroup
          loadingFormats={arrayExclude(
            DEFAULT_GRAPH_DATA_LOADING_FORMATS,
            IMAGE_GRAPH_DATA_FORMAT
          )}
          projectId={projectId}
          statsIds={statsIds}
          currentLevel={null}
        />
      </Grid>
    </div>
  );
};

const dataShape = {
  tableData: PropTypes.object.isRequired,
  returnValuesData: PropTypes.object.isRequired,
  peakRoseData: PropTypes.object,
};

ExtremeCharts.propTypes = {
  annualData: PropTypes.shape({
    title: PropTypes.string,
    data: PropTypes.oneOfType([
      PropTypes.shape(dataShape),
      PropTypes.arrayOf(PropTypes.shape(dataShape)),
    ]),
  }),
  projectId: PropTypes.number,
  statsIds: PropTypes.array,
};

export default ExtremeCharts;
