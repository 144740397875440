import {
  STATUS_LOADING,
  STATUS_ERROR,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
  EMPTY_VALUE,
} from 'constants/common';
import createReducer from 'helpers/createReducer';
import { normalizeArray } from 'helpers/data';

import {
  REQUEST_OFFERS,
  REQUEST_OFFERS_SUCCESS,
  REQUEST_OFFERS_ERROR,
} from './types';

const initialState = {
  data: EMPTY_VALUE,
  ids: [],
  error: EMPTY_VALUE,
  status: STATUS_NOT_REQUESTED,
};

export default createReducer(initialState, {
  [REQUEST_OFFERS]: (state) => ({ ...state, status: STATUS_LOADING }),
  [REQUEST_OFFERS_SUCCESS]: (state, { data }) => ({
    ...state,
    ...normalizeArray(data),
    status: STATUS_SUCCESS,
  }),
  [REQUEST_OFFERS_ERROR]: (state, { error }) => ({
    ...state,
    error,
    status: STATUS_ERROR,
  }),
});
