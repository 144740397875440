export const STEP_NAMES = ['operations', 'locations', 'outputs', 'statistics'];
export const STEPS = STEP_NAMES.reduce((prev, curr, idx) => {
  prev[curr.toUpperCase()] = idx;
  return prev;
}, {});

export const MC_OBJECT_TYPE = {
  BOAT: 'BOAT',
  OPERATION: 'OPERATION',
  CONSTRAINT: 'CONSTRAINT',
};

export const MC_OBJECT_PK = {
  customer: MC_OBJECT_TYPE.BOAT,
  boat: MC_OBJECT_TYPE.OPERATION,
  operation: MC_OBJECT_TYPE.CONSTRAINT,
};

export const MC_OBJECT_GROUP = {
  [MC_OBJECT_TYPE.BOAT]: 'boats',
  [MC_OBJECT_TYPE.OPERATION]: 'operations',
  [MC_OBJECT_TYPE.CONSTRAINT]: 'constraints',
};

export const UPDATE_ACTION = 'update';
export const CREATE_ACTION = 'create';
export const DELETE_ACTION = 'delete';

export const PERCENTILE_TYPE = 'percentiles';

export const NO_ID = 'noid';
export const OPERATION_DEFAULT_NAME = 'My Operation';
export const OPERATION_DEFAULT_NAME_REGEX = /^(My Operation)(\s\(([0-9]+)\))?$/;

export const ENTER_KEY_REGEX = /^enter$/i;
export const ESCAPE_KEY_REGEX = /^escape$/i;
export const TAB_KEY_REGEX = /^tab$/i;

export const conditions = [
  { name: 'LESS', symbol: 'LESS' },
  { name: 'LESS_EQUAL', symbol: 'LESS OR EQUAL' },
  { name: 'GREATER', symbol: 'GREATER' },
];

export const CONSTRAINTS_ATTRIBUTES = ['varName', 'limit', 'condition'];

export const DEFAULT_FORMAT_LIST = ['DURATION', 'UPTIME', 'DOWNTIME'];
export const DEFAULT_PERCENTILES_LIST = ['50', '60', '70', '80', '90', '99'];

export const getDefaultBoat = (customerId) => ({
  id: NO_ID,
  name: '',
  customer: customerId,
});

export const getDefaultOperation = (boatId, name = '', duration = 0) => ({
  id: NO_ID,
  name,
  duration,
  boat: boatId,
});

export const getDefaultConstraint = (operationId) => ({
  id: NO_ID,
  varName: '',
  condition: '',
  limit: null,
  operation: operationId,
  unit: '',
});
