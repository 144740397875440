import React, { memo, useCallback } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { MONTH_NAMES } from 'constants/common';
import { ANNUAL_INDEX } from 'constants/graphs';

import { useStyles } from './styles';

/**
 * Component for select month. Uses mui ToggleButtonGroup
 * @param { string } name - field name
 * @param { number|string } value - selected month value (jan - 1, feb - 2, etc..)
 * @param { function } setMonth - callback to set month value, takes 1 argument - month value
 * @param { boolean } withAnnual - flag, whether add "Annual" prop
 * @param { boolean } uppercase - by default ToggleButtonGroup is in uppercase, you may set it off
 * @param { string } className - group custom className
 * @param { string } size - button group size ('small'|'medium'|'large')
 * @param { object } props - rest props see mui doc
 * @returns { JSX }
 * @see https://material-ui.com/api/toggle-button-group/
 */
const SelectMonthButtonGroup = ({
  name,
  value,
  setMonth,
  withAnnual,
  uppercase,
  className,
  size,
  ...props
}) => {
  const classes = useStyles({ uppercase });

  const handleChange = useCallback(
    (_, selected) => {
      const month = selected.length ? selected[selected.length - 1] : 0;
      setMonth(month);
    },
    [setMonth]
  );

  return (
    <ToggleButtonGroup
      {...props}
      size={size}
      name={name}
      value={[+value]}
      onChange={handleChange}
      aria-label="select month"
      className={classNames(classes.group, className)}
    >
      {withAnnual && <ToggleButton value={ANNUAL_INDEX}>Annual</ToggleButton>}
      {MONTH_NAMES.map((monthName, index) => (
        <ToggleButton value={index + 1} key={monthName}>
          {monthName}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

SelectMonthButtonGroup.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setMonth: PropTypes.func.isRequired,
  className: PropTypes.string,
  withAnnual: PropTypes.bool,
  uppercase: PropTypes.bool,
  size: PropTypes.string,
};

SelectMonthButtonGroup.defaultProps = {
  name: 'month',
  withAnnual: false,
  uppercase: false,
  className: '',
  size: 'small',
};

export default memo(SelectMonthButtonGroup);
