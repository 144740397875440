import React, { memo } from 'react';
import { Route, Switch, NavLink, Redirect } from 'react-router-dom';
import Box from '@material-ui/core/Box';

import { ROUTES } from 'constants/routes';
import UsageCustomers from 'containers/usage/UsageCustomers';
import DetailCustomer from 'containers/usage/DetailCustomer';
import UsageUsers from 'containers/usage/UsageUsers';
import DetailUser from 'containers/usage/DetailUser';
import UsageOffers from 'containers/usage/UsageOffers';
import DetailOffer from 'containers/usage/DetailOffer';

import { useStyles } from './styles';

/**
 * Base Usage component
 * Mainly made as a template and switch for different sub-component
 */
const UsagePage = () => {
  const classes = useStyles();

  const links = [
    {
      title: 'Customers',
      link: ROUTES.usageCustomers,
    },
    {
      title: 'Users',
      link: ROUTES.usageUsers,
    },
    {
      title: 'Offers',
      link: ROUTES.usageOffers,
    },
  ];

  return (
    <>
      <Switch>
        <Route path={ROUTES.usageCustomerDetail} component={DetailCustomer} />
        <Route path={ROUTES.usageUserDetail} component={DetailUser} />
        <Route path={ROUTES.usageOfferDetail} component={DetailOffer} />
        <Route path={ROUTES.usage}>
          <Box className={classes.tabBar}>
            {links.map(({ title, link }) => (
              <NavLink to={link} key={link} className={classes.link}>
                {title}
              </NavLink>
            ))}
          </Box>
          <Box className={classes.component}>
            <Switch>
              <Route path={ROUTES.usageCustomers} component={UsageCustomers} />
              <Route path={ROUTES.usageUsers} component={UsageUsers} />
              <Route path={ROUTES.usageOffers} component={UsageOffers} />
              <Redirect to={ROUTES.usageCustomers} />
            </Switch>
          </Box>
        </Route>
      </Switch>
    </>
  );
};
export default memo(UsagePage);
