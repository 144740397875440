import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import axios from 'axios';

import { selectCurrentUser } from 'ducks/user/selectors';
import BaseModal from 'components/common/BaseModal';

import { useStyles } from './styles';

/**
 * AskSpecificPointModal component
 * @param {function} onClose
 */
const AskSpecificPointModal = ({ onClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);

  const [formData, setFormData] = useState({
    email: currentUser?.username || '',
    longitude: '',
    latitude: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const submitForm = () => {
    const portalId = '5405390';
    const formId = '74d0a1c3-449b-4682-83d8-f0e75fb6ba4c';
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;
    const data = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'email',
          value: formData.email,
        },
        {
          name: 'longitude',
          value: formData.longitude,
        },
        {
          name: 'latitude',
          value: formData.latitude,
        },
      ],
    };
    axios.post(url, data);
    onClose();
  };

  const controls = (
    <div className={classes.buttons}>
      <Button variant="contained" onClick={onClose}>
        {t('buttons.cancel')}
      </Button>
      <Button
        variant="contained"
        onClick={submitForm}
        className={classes.button}
      >
        {t('buttons.submit')}
      </Button>
    </div>
  );

  return (
    <BaseModal
      title="Contact Us"
      onClose={onClose}
      controls={controls}
      modalClass={classes.container}
    >
      <p>
        You are currently logged into our free demo which gives access to one
        calculated point within the Bay of Biscay. To view an analysis of the
        location of your choice, please fill out the form below.
      </p>

      <p>
        Our team will contact you with the performed analysis of your chosen
        location within 48hrs.
      </p>

      <form onSubmit={submitForm} className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              name="email"
              label="Email"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              name="longitude"
              label="Longitude"
              type="number"
              value={formData.longitude}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              name="latitude"
              label="Latitude"
              type="number"
              value={formData.latitude}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </form>
      <br />
      <p>
        Choosing a location is optional. For further demo of our product, simply
        include your email and we will contact you.
      </p>
    </BaseModal>
  );
};

AskSpecificPointModal.propTypes = {
  onClose: PropTypes.func,
};

export default React.memo(AskSpecificPointModal);
