import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    width: '100%',
    padding: spacing(2),
  },
  icon: {
    '&& > svg': {
      fontSize: ({ iconSize }) => iconSize,
      marginBottom: spacing(2),
    },
    color: palette.grey.middle,
  },
  text: {
    width: '100%',
    color: palette.grey.light,
  },
}));
