import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Loader } from 'components/common/Loader';
import SelectZone from 'components/projects/SelectZone';
import { requestZonesIfNeed } from 'ducks/zones/actions';
import { selectZonesCollection, selectDemoZones } from 'ducks/zones/selectors';
import {
  selectOffersIsLoaded,
  selectOfferZonesById,
} from 'ducks/offers/selectors';
import { requestOffersIfNeed } from 'ducks/offers/actions';

/**
 * Container for choose zone and create project
 * @returns {jsx}
 */
const SelectZoneContainer = () => {
  const dispatch = useDispatch();
  const { offerId } = useParams();

  const offersIsLoaded = useSelector(selectOffersIsLoaded);
  const allZones = useSelector(selectZonesCollection);
  const demoZones = useSelector(selectDemoZones);
  const offerZones = useSelector((state) =>
    selectOfferZonesById(state, offerId)
  );

  const isLoaded = allZones.length && offersIsLoaded;

  useEffect(() => {
    dispatch(requestZonesIfNeed());
    dispatch(requestOffersIfNeed());
  }, [dispatch, offerId]);

  return isLoaded ? (
    <SelectZone
      zones={allZones}
      demoZones={demoZones}
      offerZones={offerZones}
      offerId={+offerId}
    />
  ) : (
    <Loader center />
  );
};

export default React.memo(SelectZoneContainer);
