import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropsTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components/common/Loader';
import MCOperationsComponent from 'components/projects/MarineContractors/Operations/index';
import {
  cleanBoatCreateError,
  createMarineContractorsOperation,
  createMarineContractorsTemporaryBoat,
  createMarineContractorsTemporaryConstraint,
  deleteMarineContractorsConstraintSuccess,
  removeMarineContractorsJobOperations,
  requestMarineContractors,
  requestMarineContractorsVariables,
  setMarineContractorsJobOperations,
} from 'ducks/marineContractors/actions';
import {
  selectMarineContractorsBoats,
  selectMarineContractorsBoatIsCreating,
  selectMarineContractorsConstraints,
  selectMarineContractorsFilteredJobOperations,
  selectMarineContractorsIsLoading,
  selectMarineContractorsIsNotRequested,
  selectMarineContractorsJobOperations,
  selectMarineContractorsOperations,
  selectMarineContractorsVariablesIsLoading,
  selectMarineContractorsVariablesIsNotRequested,
  selectMarineContractorsBoatError,
  selectMarineConstractorsHasActions,
  selectMarineConstractorsErrorsFormatted,
} from 'ducks/marineContractors/selectors';
import { useModal } from 'hooks/useModal';
import { useInterrupt } from 'hooks/useInterrupt';
import { useCursor } from 'hooks/useCursor';
import { selectUserDetails } from 'ducks/user/selectors';
import { getOperation } from 'helpers/marineContractors';
import { NOTIFICATION_MODAL } from 'constants/modals';
import { SNACKBAR_ERROR_TYPE, SNACKBAR_WARNING_TYPE } from 'constants/common';
import {
  getDefaultBoat,
  getDefaultConstraint,
  NO_ID,
} from 'constants/marineContractors';

/**
 * MC operations container - core of the operations creation/selection
 * @param { function } handleNext - callback when clicking the 'Next' button
 * @param { function } handleBack - callback when clicking the 'Back' button
 * @returns { JSX }
 */
const MCOperationsContainer = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const { t } = useTranslation();

  const [selectedBoat, setSelectedBoat] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [selectedConstraint, setSelectedConstraint] = useState(null);

  // state to force the focus of new object (not validated) by clinking 'Add' button
  const [forceSelectNewBoat, setForceSelectNewBoat] = useState(false);
  const [forceSelectNewConstraint, setForceSelectNewConstraint] = useState(
    false
  );

  const isNotRequested = useSelector(selectMarineContractorsIsNotRequested);
  const isNotRequestedVariables = useSelector(
    selectMarineContractorsVariablesIsNotRequested
  );
  const { customerId } = useSelector(selectUserDetails);
  const isLoading = useSelector(selectMarineContractorsIsLoading);
  const isLoadingVariables = useSelector(
    selectMarineContractorsVariablesIsLoading
  );
  const boats = useSelector((state) => selectMarineContractorsBoats(state));
  const constraints = useSelector((state) =>
    selectMarineContractorsConstraints(state)
  );
  const operations = useSelector((state) =>
    selectMarineContractorsOperations(state)
  );
  const selectedOperations = useSelector((state) =>
    selectMarineContractorsJobOperations(state)
  );
  const boatIsCreating = useSelector(selectMarineContractorsBoatIsCreating);
  const availableOperations = useSelector((state) =>
    selectMarineContractorsFilteredJobOperations(operations)(state)
  );

  const hasActions = useSelector(selectMarineConstractorsHasActions);
  const errorsFormatted = useSelector(selectMarineConstractorsErrorsFormatted);

  const boatCreationError = useSelector((state) =>
    selectMarineContractorsBoatError({ id: NO_ID })(state)
  );
  const boatCreationHasError = !!boatCreationError?.allErrors?.length;

  const isReady = !isNotRequested && !isLoading;
  const isReadyVariables = !isNotRequestedVariables && !isLoadingVariables;

  const selectedBoatOperations = operations.filter(
    (o) => o.boat === selectedBoat?.id
  );
  const selectedOperationConstraints = constraints.filter(
    (c) => c.operation === selectedOperation?.id
  );

  useCursor(boats, setSelectedBoat);
  useCursor(selectedBoatOperations, setSelectedOperation, selectedBoat?.id);
  useCursor(
    selectedOperationConstraints,
    setSelectedConstraint,
    selectedOperation?.id
  );

  const boatIsCreated = useInterrupt(boatIsCreating, true, false);

  useEffect(() => {
    if (isNotRequested) {
      dispatch(requestMarineContractors());
    }
  }, [dispatch, isNotRequested]);

  useEffect(() => {
    if (isNotRequestedVariables) {
      dispatch(requestMarineContractorsVariables());
    }
  }, [dispatch, isNotRequestedVariables]);

  const handleOperations = useCallback(
    (e) => {
      const operationId = +e.target.value;
      const operation = operations.find((o) => o.id === operationId);
      if (e.target.checked) {
        dispatch(setMarineContractorsJobOperations([operation]));
        return;
      }
      if (selectedOperations.map((o) => o.id).includes(operationId)) {
        dispatch(removeMarineContractorsJobOperations([operation]));
      }
    },
    // eslint-disable-next-line
    [operations, selectedOperations]
  );

  useEffect(() => {
    if (boatIsCreated && !boatCreationHasError) {
      const boatCreated = boats[boats.length - 1];
      const operation = getOperation(boatCreated.id, selectedBoatOperations);
      dispatch(createMarineContractorsOperation(operation));
    }
    // eslint-disable-next-line
  }, [boatIsCreated, boatCreationHasError]);

  useEffect(() => {
    if (forceSelectNewBoat) {
      dispatch(cleanBoatCreateError());
      setSelectedBoat(boats[boats.length - 1]);
      setForceSelectNewBoat(false);
    }
    // eslint-disable-next-line
  }, [forceSelectNewBoat]);

  useEffect(() => {
    if (forceSelectNewConstraint) {
      setSelectedConstraint(
        selectedOperationConstraints[selectedOperationConstraints.length - 1]
      );
      setForceSelectNewConstraint(false);
    }
    // eslint-disable-next-line
  }, [forceSelectNewConstraint]);

  const handleNewBoat = useCallback(() => {
    const newBoat = boats.find((b) => b.id === NO_ID);
    if (newBoat) {
      if (!boatIsCreating) {
        setSelectedBoat(null);
        setForceSelectNewBoat(true);
      }
      return;
    }
    const boat = getDefaultBoat(customerId);
    dispatch(createMarineContractorsTemporaryBoat(boat));
    // eslint-disable-next-line
  }, [boats, customerId, boatIsCreating, cleanNoIdConstraint]);

  const handleNewOperation = useCallback(() => {
    const operation = getOperation(selectedBoat.id, selectedBoatOperations);
    dispatch(createMarineContractorsOperation(operation));
    // eslint-disable-next-line
  }, [
    selectedBoat,
    selectedBoatOperations,
  ]);

  const handleNewConstraint = useCallback(() => {
    const newConstraint = selectedOperationConstraints.find(
      (c) => c.id === NO_ID
    );
    if (newConstraint) {
      setSelectedConstraint(null);
      setForceSelectNewConstraint(true);
      return;
    }
    const constraint = getDefaultConstraint(selectedOperation.id);
    dispatch(createMarineContractorsTemporaryConstraint(constraint));
    // eslint-disable-next-line
  }, [selectedOperation, selectedOperationConstraints]);

  useEffect(() => {
    if (isReady && !!boats && !boats.length) {
      handleNewBoat();
    }
  }, [boats, isReady, handleNewBoat]);

  const preparedHandleNext = useCallback(() => {
    if (hasActions) {
      openModal(NOTIFICATION_MODAL, {
        message: t('marineContractors.operations.hasActions'),
        type: SNACKBAR_WARNING_TYPE,
        autoHideDuration: null,
      });
      return;
    }
    if (errorsFormatted.length) {
      openModal(NOTIFICATION_MODAL, {
        message: t('marineContractors.operations.hasErrors', {
          errorsFormatted: errorsFormatted.join('\n'),
        }),
        type: SNACKBAR_ERROR_TYPE,
        autoHideDuration: null,
      });
      return;
    }
    availableOperations.forEach((operation) => {
      const boatId = operation.boat;
      const boatIndex = boats.findIndex((b) => b.id === boatId);
      if (boatIndex === -1) {
        console.error(
          `can't find corresponding boat for operation id : ${operation.id} with boat id : ${boatId}`
        );
      }
      operation.boatObj = boats[boatIndex];
      operation.constraints = constraints.filter(
        (c) => c.operation === operation.id
      );
    });
    handleNext({ selectedOperations: availableOperations });
    // eslint-disable-next-line
  }, [boats, constraints, availableOperations, hasActions, errorsFormatted]);

  const cleanNoIdConstraint = useCallback(() => {
    const noIdConstraint = selectedOperationConstraints.find(
      (c) => c.id === NO_ID
    );
    if (noIdConstraint) {
      dispatch(deleteMarineContractorsConstraintSuccess(noIdConstraint));
    }
    // eslint-disable-next-line
  }, [selectedOperationConstraints]);

  const handleSelectBoat = useCallback(
    (id) => {
      if (id === selectedBoat?.id) {
        return;
      }
      const boat = boats.find((b) => b.id === +id || b.id === NO_ID);
      setSelectedBoat(boat);
    },
    // eslint-disable-next-line
    [boats, selectedBoat, cleanNoIdConstraint]
  );

  const handleSelectOperation = useCallback(
    (id) => {
      if (id === selectedOperation?.id) {
        return;
      }
      const operation = operations.find((o) => o.id === +id || o.id === NO_ID);
      setSelectedOperation(operation);
    },
    // eslint-disable-next-line
    [operations, selectedOperation, cleanNoIdConstraint]
  );

  const handleSelectConstraint = useCallback(
    (id) => {
      if (id === selectedConstraint?.id) {
        return;
      }
      const constraint = constraints.find(
        (c) => c.id === +id || c.id === NO_ID
      );
      setSelectedConstraint(constraint);
    },
    // eslint-disable-next-line
    [constraints, selectedConstraint]
  );

  return (
    <>
      {!isReady || !isReadyVariables ? (
        <Loader center justifyCenter />
      ) : (
        <MCOperationsComponent
          boats={boats}
          operations={selectedBoatOperations}
          constraints={selectedOperationConstraints}
          selectedBoat={selectedBoat}
          selectedOperation={selectedOperation}
          selectedConstraint={selectedConstraint}
          selectedOperations={availableOperations}
          handleSelectBoat={handleSelectBoat}
          handleSelectOperation={handleSelectOperation}
          handleSelectConstraint={handleSelectConstraint}
          handleNewBoat={handleNewBoat}
          handleNewOperation={handleNewOperation}
          handleNewConstraint={handleNewConstraint}
          handleOperations={handleOperations}
          handleNext={preparedHandleNext}
          handleBack={handleBack}
        />
      )}
    </>
  );
};

MCOperationsContainer.propTypes = {
  handleNext: PropsTypes.func,
  handleBack: PropsTypes.func,
};

export default React.memo(MCOperationsContainer);
