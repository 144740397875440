import { get } from 'lodash';

import {
  VALUES_KEY,
  MEAN_POSTFIX,
  MIN_POSTFIX,
  MAX_POSTFIX,
  STD_POSTFIX,
  MEDIAN_POSTFIX,
  DEFAULT_DIGITS,
  PROJECT_STATS_CTS_TYPE,
  PROJECT_STATS_OVERVIEW_NAME,
  PROJECT_STATS_VARIABLES_LONG_NAMES,
  PROJECT_STATS_VARIABLES_NAMES,
  OVERVIEW_TYPE,
  LEVEL_INDEX,
} from 'constants/statistics';
import { STATS_ID_MONTH_REGEXP, VCLASS_TYPE_REGEXP } from 'constants/regexp';
import { GRAPHS_WIDGETS } from 'constants/graphs';

/**
 * Gets variables for current type
 * @param type
 * @param itemId
 * @param ownVariables
 * @param variables
 */
const getAvailableVariables = ({
  type,
  itemId,
  ownVariables,
  variables: { variables },
}) => {
  const { wlev, mag, magw, mag10, hs } = PROJECT_STATS_VARIABLES_NAMES;
  const {
    waterHeights,
    currents,
    wind,
    waves,
  } = PROJECT_STATS_VARIABLES_LONG_NAMES;
  if (type === PROJECT_STATS_CTS_TYPE) {
    const variablesIds = itemId.split('-')[1].split(',');
    const newCtsVariables = [];

    if (variablesIds.includes(wlev)) {
      newCtsVariables.push(waterHeights);
    }
    if (variablesIds.includes(mag)) {
      newCtsVariables.push(currents);
    }
    if (variablesIds.includes(magw) || variablesIds.includes(mag10)) {
      newCtsVariables.push(wind);
    }
    if (variablesIds.includes(hs)) {
      newCtsVariables.push(waves);
    }

    return [
      ...ownVariables,
      {
        name: newCtsVariables.join(', '),
        id: itemId,
      },
    ];
  }

  const newVariables =
    variables !== undefined
      ? variables
          .reduce((acc, { hidden, name, longName }) => {
            const variable =
              typeof longName === 'string' && longName ? longName : name;
            if (hidden || acc.includes(variable)) {
              return acc;
            }
            acc.push(variable);

            return acc;
          }, [])
          .join(', ')
      : '';

  const newShortVariables =
    variables !== undefined
      ? variables
          .reduce((acc, { hidden, name }) => {
            if (hidden || acc.includes(name)) {
              return acc;
            }
            acc.push(name);

            return acc;
          }, [])
          .join(', ')
      : '';

  return [
    ...ownVariables,
    {
      id: itemId,
      name: newVariables,
      shortNames: newShortVariables,
    },
  ];
};

/**
 * Prepares project statistics data
 * from [
 *   {id: "WIN-magw-OV_2147", point: {name: "test", id: "2147"}, layer: "WIND", type: "OV",…}
 *   {id: "WIN-magw-EPD-month_2147", point: {name: "test", id: "2147"}, layer: "WIND", type: "EPD",…}
 * ]
 * to [ 2147: { WIND: { EPD: { name: Emperical probability, variables: [...] } } } ]
 * @param statistics
 */
export const prepareProjectStatistics = (statistics) =>
  statistics.reduce((acc, item) => {
    const {
      point: { id: pointId },
      layer,
      name,
      type,
      id: itemId,
      variables,
      periodicity,
    } = item;

    if (name === PROJECT_STATS_OVERVIEW_NAME) {
      return acc;
    }

    const layers = get(acc, [pointId, layer], {});
    const ownVariables = get(acc, [pointId, layer, type, 'variables'], []);
    const availableVariables = getAvailableVariables({
      type,
      itemId,
      ownVariables,
      variables,
    });

    return {
      ...acc,
      [pointId]: {
        ...acc[pointId],
        [layer]: {
          ...layers,
          [type]: {
            name,
            variables: availableVariables,
            periodicity,
            id: type,
          },
        },
      },
    };
  }, {});

/**
 * Prepare project overview data
 * @param {Array} statistics
 * @return {Array}
 */
export const getProjectOverviewStatistics = (statistics) =>
  statistics.filter(({ type }) => type === OVERVIEW_TYPE);

/**
 * Checking values is array or string
 * @param statistic
 * @return {boolean}
 */
export const hasStatisticLevels = (statistic) =>
  !!get(statistic, 'level.values.length');

/**
 * Get visualization parameters by stat value
 * @param {Object} visualization
 * @param {String} paramKey
 * @return {string}
 */
const getVisualizationParams = (visualization, paramKey) => {
  const valuesPath = hasStatisticLevels(visualization)
    ? [VALUES_KEY, 0, LEVEL_INDEX]
    : [VALUES_KEY, 0];

  const digits = visualization.visualization.kwargs.digits || DEFAULT_DIGITS;

  return get(visualization[paramKey], valuesPath).toFixed(digits);
};

/**
 * Prepare visualization data for table
 * @param {Object} visualization
 * @param {String} variableName
 * @return {{
 *  std: string,
 *  min: string,
 *  median: string,
 *  max: string,
 *  mean: string,
 *  variable: string,
 *  units: string,
 *  layer: string
 * }}
 */
export const prepareVisualizationData = (visualization, variableName) => {
  const {
    variables: [{ longName = variableName }],
  } = visualization.variables;

  const paramKeys = {
    mean: `${variableName}${MEAN_POSTFIX}`,
    std: `${variableName}${STD_POSTFIX}`,
    min: `${variableName}${MIN_POSTFIX}`,
    max: `${variableName}${MAX_POSTFIX}`,
    median: `${variableName}${MEDIAN_POSTFIX}`,
  };

  const units = get(visualization, [paramKeys.mean, 'attributes', 'units'], '');

  return {
    layer: visualization.layer,
    variable: longName,
    units,
    std: getVisualizationParams(visualization, paramKeys.std),
    min: getVisualizationParams(visualization, paramKeys.min),
    max: getVisualizationParams(visualization, paramKeys.max),
    mean: getVisualizationParams(visualization, paramKeys.mean),
    median: getVisualizationParams(visualization, paramKeys.median),
  };
};

/**
 * returns stats id priority, it's calculated as sum of priority weights by algorithm:
 * +0 for annual data, +1 for monthly data, +2 if stats is data for widget
 * so 3 is lowest possible and 0 is highest possible priority
 * @param { string } statsId
 * @returns {boolean|*|number}
 */
export const getStatsIdPriority = (statsId) =>
  (VCLASS_TYPE_REGEXP.test(statsId) && 2) +
  (STATS_ID_MONTH_REGEXP.test(statsId) && 1);

/**
 * sorting function for stats ids, sort by stats priorities
 * @param { string } aStatsId
 * @param { string } bStatsId
 * @returns {number}
 */
export const sortStatsIdsByPriority = (aStatsId, bStatsId) =>
  getStatsIdPriority(aStatsId) - getStatsIdPriority(bStatsId);

/**
 * returns widgets list expected by parsing stats id string
 * @param { string } statsId or stats ids string
 * @returns { array }
 * @example in:
 *  in: 'WAV-hs,tz-EJPD-month-dp-vclass_3086|WAV-hs,tz-EJPD-dp-vclass_3086|WAV-hs,tz-EJPD-month_3086|WAV-hs,tz-EJPD_3086'
 *  out: ['monthSelect', 'vclassSelect']
 */
export const getExpectedWidgetsByStatsIdsString = (statsId) => [
  ...(VCLASS_TYPE_REGEXP.test(statsId) ? [GRAPHS_WIDGETS.vclass] : []),
  ...(STATS_ID_MONTH_REGEXP.test(statsId) ? [GRAPHS_WIDGETS.months] : []),
];
