import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Button,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useToggle } from 'hooks/useToggle';
import { useForm } from 'hooks/useForm';
import ReportListItem from 'containers/projects/ProjectReportListItem';

import { useStyles } from './styles';
import { validationRules } from './validationRules';

/**
 * Returns string in format: "firstName lastName: message"
 * @param {{firstName: string, lastName: string}} user - user object
 * @param {string} message
 * @returns {string}
 */
const formatMessage = ({ firstName = '', lastName = '' } = {}, message = '') =>
  firstName && lastName && message && `${firstName} ${lastName}: ${message}`;

/**
 * Component that displays array of comments for one report
 * @param {Array} comments - array of comments for current report
 * @param {Object} collaborators - object with users by id
 * @param classes
 */
const CommentsList = memo(({ comments, collaborators, classes }) => (
  <List className={classes.commentsList}>
    {comments.map(({ message, user, id }) => (
      <ListItem key={id} className={classes.commentItem}>
        <ListItemText>
          <Typography variant="body2">
            {formatMessage(collaborators[user], message)}
          </Typography>
        </ListItemText>
      </ListItem>
    ))}
  </List>
));

/**
 * Component that contains input field and two buttons: submit and cancel
 * @param {Function} onSubmit
 * @param {Function} onCancel
 * @param {Object} classes
 * @param {Function} t - useTranslation
 */
const CommentCreationComponent = ({ onSubmit, onCancel, classes, t }) => {
  const { setField, values, handleSubmit, errors } = useForm({
    defaultValues: { text: '' },
    validationRules,
    onSuccess: onSubmit,
  });

  const handleTextChange = useCallback(
    ({ target }) => setField('text', target.value),
    [setField]
  );

  return (
    <form noValidate onSubmit={handleSubmit}>
      <TextField
        multiline
        autoFocus
        rowsMax={4}
        variant="outlined"
        label={t('report.comments.title')}
        defaultValue={values.text}
        onChange={handleTextChange}
        error={!!errors.text}
        helperText={errors.text}
      />
      <div className={classes.buttonsContainer}>
        <Button
          type="submit"
          onClick={handleSubmit}
          variant="outlined"
          size="small"
        >
          {t('report.comments.add')}
        </Button>
        <Button
          onClick={onCancel}
          variant="contained"
          size="small"
          className={classes.cancelButton}
        >
          {t('buttons.cancel')}
        </Button>
      </div>
    </form>
  );
};

/**
 * Component that handles comment creation form display
 * @param onCommentCreate
 * @param classes
 * @param t
 * @param disabled
 */
const CommentCreation = ({ onCommentCreate, classes, t, disabled = false }) => {
  const {
    toggle: toggleCommentsCreation,
    isActive: openCommentCreation,
  } = useToggle();

  const handleCommentCreate = useCallback(
    ({ text }) => {
      onCommentCreate(text);
      toggleCommentsCreation();
    },
    [onCommentCreate, toggleCommentsCreation]
  );

  return (
    <div className={classes.commentCreationContainer}>
      {!openCommentCreation ? (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={toggleCommentsCreation}
          disabled={disabled}
        >
          {t('report.comments.add')}
        </Button>
      ) : (
        <CommentCreationComponent
          onSubmit={handleCommentCreate}
          onCancel={toggleCommentsCreation}
          classes={classes}
          t={t}
        />
      )}
    </div>
  );
};

/**
 * List Item component. Renders report name, date of creation,
 * comments and provides comment creation functionality
 * @param report
 * @param {Array} comments - array of comments for current report
 * @param {Object} collaborators - object with users by id
 * @param {function} onReportDelete
 * @param {function} onCommentCreate
 * @param {boolean} allowDeleteReport
 * @param {boolean} allowAddComment
 */
const ProjectReportListItemComponent = ({
  report,
  comments,
  collaborators,
  onReportDelete,
  onCommentCreate,
  allowDeleteReport,
  allowAddComment,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleCreateComment = useCallback(
    (text) => onCommentCreate({ message: text, reportId: report.id }),
    [onCommentCreate, report]
  );

  return (
    <div>
      <ReportListItem
        report={report}
        collaborators={collaborators}
        onReportDelete={onReportDelete}
        classes={classes}
        t={t}
        allowDeleteReport={allowDeleteReport}
      />
      {comments && comments.length && (
        <CommentsList
          collaborators={collaborators}
          comments={comments}
          classes={classes}
        />
      )}
      <CommentCreation
        onCommentCreate={handleCreateComment}
        classes={classes}
        t={t}
        disabled={!allowAddComment}
      />
      <Divider />
    </div>
  );
};

/**
 * Return mapped reports as list items
 * @param {Array<Object>} reports
 * @param {Object} comments - object with comments by report id
 * @param {Object} collaborators - object with users by id
 * @param {function} onReportDelete
 * @param {function} onCommentCreate
 * @param {boolean} allowDeleteReport
 * @param {boolean} allowAddComment
 */
const ProjectReportListComponent = ({
  reports,
  comments,
  collaborators,
  onReportDelete,
  onCommentCreate,
  allowDeleteReport,
  allowAddComment,
}) => (
  <List>
    {reports.map((report) => (
      <ProjectReportListItemComponent
        key={report.id}
        report={report}
        collaborators={collaborators}
        comments={comments[report.id]}
        onReportDelete={onReportDelete}
        onCommentCreate={onCommentCreate}
        allowDeleteReport={allowDeleteReport}
        allowAddComment={allowAddComment}
      />
    ))}
  </List>
);

ProjectReportListComponent.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      user: PropTypes.number,
      name: PropTypes.string,
      computation: PropTypes.number,
      status: PropTypes.string,
      taskId: PropTypes.string,
      document: PropTypes.any,
      generatedAt: PropTypes.string,
      startedAt: PropTypes.string,
      project: PropTypes.number,
    })
  ).isRequired,
  comments: PropTypes.object,
  collaborators: PropTypes.object,
  onReportDelete: PropTypes.func.isRequired,
  onCommentCreate: PropTypes.func.isRequired,
  allowDeleteReport: PropTypes.bool.isRequired,
  allowAddComment: PropTypes.bool.isRequired,
};

ProjectReportListComponent.defaultProps = {
  comments: {},
};

export default memo(ProjectReportListComponent);
