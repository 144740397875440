import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 568,
    width: '100%',
  },
  body: {
    textAlign: 'center',
    padding: '10px 5px',
  },
}));
