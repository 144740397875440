import React from 'react';
import PropTypes from 'prop-types';

import BaseModal from 'components/common/BaseModal';

import { useStyles } from './styles';

/**
 * CompleteConstraintModal component
 * @param {function} onClose
 */
const CompleteConstraintModal = ({ onClose }) => {
  const classes = useStyles();

  return (
    <BaseModal
      title="Missing constraint data"
      onClose={onClose}
      modalClass={classes.container}
      warning
    >
      <p>
        A constraint is not properly set. Please fill in all required fields.
      </p>
    </BaseModal>
  );
};

CompleteConstraintModal.propTypes = {
  onClose: PropTypes.func,
};

export default React.memo(CompleteConstraintModal);
