import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import GetMapsModal from 'components/modals/GetMapsModal';
import { useModal } from 'hooks/useModal';
import {
  selectAvailableCredits,
  selectCurrentUserIsManager,
  selectHasUnlimitedFullStudy,
} from 'ducks/user/selectors';
import { CREDIT_NEEDED_STAT2D } from 'constants/projects';

/**
 * Container for get maps button
 * @param {Number|String} id
 * @param {Function} onConfirm
 * @param {Object} zone
 * @param {Number} price
 * @return {jsx}
 */
const GetMapsModalContainer = ({
  id,
  onConfirm,
  zone,
  price = CREDIT_NEEDED_STAT2D,
}) => {
  const { onClose } = useModal(id);
  const availableCredits = useSelector(selectAvailableCredits);
  const isManager = useSelector(selectCurrentUserIsManager);
  const hasUnlimitedFullStudy = useSelector(selectHasUnlimitedFullStudy);
  const isFree = zone.isDemo || hasUnlimitedFullStudy;
  const needCredits = zone.isDemo ? 0 : price;

  const confirmHandler = useCallback(() => {
    onConfirm();
    onClose();
  }, [onClose, onConfirm]);

  return (
    <GetMapsModal
      closeModal={onClose}
      onConfirm={confirmHandler}
      price={needCredits}
      isManager={isManager}
      availableCredits={availableCredits}
      isFree={isFree}
    />
  );
};

GetMapsModalContainer.propTypes = {
  id: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  price: PropTypes.number,
  zone: PropTypes.shape({
    isDemo: PropTypes.bool.isRequired,
  }).isRequired,
};

export default React.memo(GetMapsModalContainer);
