import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  helperText: {
    height: 20,
    wordWrap: 'break-word',
  },
  numberField: {
    '&:hover $controlGroup, &:focus $controlGroup': {
      visibility: 'visible',
    },
    '& .Mui-disabled $controlGroup': {
      visibility: 'hidden',
    },
  },
  controlGroup: {
    visibility: 'hidden',
    width: 16,
  },
  control: {
    width: 16,
    fontSize: 14,
    cursor: 'pointer',
    background: palette.lightGrey.light,

    '&:hover': {
      background: palette.lightGrey.main,
    },
  },
}));
