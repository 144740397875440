import { useState, useEffect } from 'react';

import { NO_ID } from 'constants/marineContractors';

/**
 * useCursor hook to auto select element in array
 * Default behavior :
 * - deletion in array -> select the first item if exist
 * - add item in array -> select the new item
 * @param { Array } objectsArray - objects array
 * @param { function } setCallback - callback to set item selected
 * @param { Any } depKey - reinit the cursor if the depKey is set and has changed
 */
export const useCursor = (objectsArray, setCallback, depKey = null) => {
  const [objects, setObjects] = useState(null);
  const [isInit, setIsInit] = useState(false);
  const [key, setKey] = useState(null);

  /**
   * getInvalidTransition - compute if invalid object became a valid object
   * invalidObject : object.id === NO_ID
   * @returns { number }
   */
  const getInvalidTransition = () => {
    const hadInvalidObject = !!objects.find((o) => o.id === NO_ID);
    const hasInvalidObject = !!objectsArray.find((o) => o.id === NO_ID);
    if (hadInvalidObject && !hasInvalidObject) {
      return -1;
    }
    if (!hadInvalidObject && hasInvalidObject) {
      return 1;
    }
    return 0;
  };

  // init objects list state
  useEffect(() => {
    if (!objects && !!objectsArray?.length) {
      setObjects(objectsArray);
    }
  }, [objects, objectsArray]);

  useEffect(() => {
    if (key === null && depKey !== null) {
      setKey(depKey);
      return;
    }
    if (key !== depKey) {
      setIsInit(false);
      setKey(depKey);
    }
  }, [key, depKey, setKey]);

  useEffect(() => {
    // if objects is not initialized, return
    if (!objects) {
      return;
    }

    // on first init, the first element need to be selected
    if (!isInit) {
      setIsInit(true);
      setCallback(objectsArray[0]);
      setObjects(objectsArray);
      return;
    }

    // get objects array state length, new objects array length and the invalid object transition
    const oldLength = objects.length;
    const newLength = objectsArray.length;
    const invalidTransition = getInvalidTransition();

    if (oldLength === newLength) {
      // transition -> invalid object became a valid object (add)
      if (invalidTransition === -1) {
        setCallback(objectsArray[objectsArray.length - 1]);
        setObjects(objectsArray);
      }
      return;
    }
    if (oldLength > newLength) {
      setCallback(objectsArray[0]);
    } else {
      setCallback(objectsArray[objectsArray.length - 1]);
    }
    setObjects(objectsArray);
    // eslint-disable-next-line
    }, [isInit, objectsArray, getInvalidTransition])
};
