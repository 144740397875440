import React from 'react';
import PropTypes from 'prop-types';

import { EMPTY_VALUE } from 'constants/common';

import { useStyles } from './styles';

/**
 * Swatch component
 * Simple plain color button which fire an event and a color
 * @param {string} color
 * @param {string} className - styling classes
 * @param {Function} onClick - callback when the swatch is clicked
 * @param {Function} onHover - callback whne the swatch is hovered
 * @param {Node} children - jsx nodes to append inside the swatch
 */
const Swatch = ({
  color,
  className,
  checked,
  onClick,
  onHover,
  title = color,
  children,
  ...props
}) => {
  const classes = useStyles({
    color,
  });

  const handleClick = (event) => onClick(event, color);
  const handleHover = (event) => onHover(event, color);

  const optionalEvent = EMPTY_VALUE;
  if (onHover) {
    optionalEvent.onMouseOver = handleHover;
  }

  return (
    <div
      role="radio"
      className={`${classes.root} ${className}`}
      aria-checked={checked}
      tabIndex={0}
      onClick={handleClick}
      title={title}
      {...optionalEvent}
      {...props}
    >
      {children}
    </div>
  );
};

Swatch.propTypes = {
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
};

export default Swatch;
