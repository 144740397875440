import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import UploadPlanningSuccessModalComponent from 'components/modals/UploadPlanningSuccessModal';
import { useModal } from 'hooks/useModal';
import { selectUserEmail } from 'ducks/user/selectors';

/**
 * UploadPlanningSuccessModal container
 * Displays upload planning success modal
 * @param { string } id - modal id
 */
const UploadPlanningSuccessModal = ({ id }) => {
  const { onClose } = useModal(id);
  const userEmail = useSelector(selectUserEmail);

  return (
    <UploadPlanningSuccessModalComponent
      onClose={onClose}
      userEmail={userEmail}
    />
  );
};

UploadPlanningSuccessModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default memo(UploadPlanningSuccessModal);
