import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

import BaseModal from 'components/common/BaseModal';
import { ROUTES } from 'constants/routes';

import { useStyles } from './styles';
import PlanningForm from './PlanningForm';

/**
 * PlanningSettingsModal component
 * Displays planning settings modal
 * @param { function } onClose - close handler
 * @param { function } onSuccess - success handler
 * @param { Object } points - current project points
 * @param { string } fileName - loaded planning file name
 * @param { string } percentiles - series of integers between 1 and 99, separated by commas
 * @param { string } tasksCoords - coordinates of tasks table in planning
 * @param { string } envCoords - coordinates of environments table in planning
 * @param { string } userEmail - current user email
 * @param { Object } locationAliases - correspondence between planning location and project location
 * @param { Object } formats - output downtime formats: array of Format object (containing 'value': str and 'seleceted': bool)
 */
const PlanningSettingsModal = ({
  fileName,
  onSuccess,
  points,
  tasksCoords,
  envCoords,
  percentiles,
  userEmail,
  locationAliases,
  formats,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <BaseModal
      title={t('downtime.planningSettings.modalTitle')}
      onClose={onClose}
      modalClass={classes.container}
    >
      <>
        <div>
          <Typography align="center" className={classes.title}>
            {t('downtime.planningSettings.titleWithFile', { fileName })}
          </Typography>
          <Typography align="center" className={classes.title}>
            {t('downtime.planningSettings.correctSettingsTitlePartOne')}
            <small>
              <Link to={ROUTES.helpDowntime} className={classes.link}>
                {t('downtime.planningSettings.correctSettingsTitleLink')}
              </Link>
              {t('downtime.planningSettings.correctSettingsTitlePartTwo')}
            </small>
          </Typography>
        </div>
        <PlanningForm
          onClose={onClose}
          onSuccess={onSuccess}
          envCoords={envCoords}
          tasksCoords={tasksCoords}
          points={points}
          fileName={fileName}
          percentiles={percentiles}
          userEmail={userEmail}
          locationAliases={locationAliases}
          formats={formats}
        />
      </>
    </BaseModal>
  );
};

PlanningSettingsModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  points: PropTypes.object.isRequired,
  tasksCoords: PropTypes.string.isRequired,
  envCoords: PropTypes.string.isRequired,
  percentiles: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  locationAliases: PropTypes.array.isRequired,
  formats: PropTypes.array.isRequired,
};

export default memo(PlanningSettingsModal);
