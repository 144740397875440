import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { selectProjectsCommonErrors } from 'ducks/projects/selectors';
import { clearProjectsError } from 'ducks/projects/actions';
import { createProjectWithRedirect } from 'ducks/app/actions';
import { selectCurrentUser } from 'ducks/user/selectors';
import EditProjectForm from 'components/forms/EditProjectForm';

/**
 * CreateProject container
 * Contains logic and data for CreateProject
 * @param {Number} offerInstance - offer id
 * @param {Number} zoneId
 * @param {Function} onClose
 * @param {Function} onExpand
 */
const CreateProject = ({ zoneId, offerInstance, onClose }) => {
  const dispatch = useDispatch();
  const commonErrors = useSelector(selectProjectsCommonErrors);
  const currentUser = useSelector(selectCurrentUser);

  const handleSuccess = useCallback(
    ({ name }) => {
      dispatch(
        createProjectWithRedirect({
          collaborators: [currentUser.id],
          offerInstance,
          zone: zoneId,
          name,
        })
      );
    },
    [dispatch, currentUser.id, offerInstance, zoneId]
  );

  useEffect(
    () => () => {
      dispatch(clearProjectsError());
    },
    [dispatch]
  );

  return (
    <EditProjectForm
      commonErrors={commonErrors}
      onSuccess={handleSuccess}
      onClose={onClose}
      zoneId={zoneId}
    />
  );
};

CreateProject.propTypes = {
  zoneId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateProject;
