import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, spacing }) => ({
  listItemTitle: {
    wordWrap: 'break-word',
    maxWidth: 230,
    fontSize: '14px',
    color: palette.links.main,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  deleteIcon: {
    color: palette.danger.middle,
  },
  reportItem: {
    paddingBottom: 0,
  },
  commentsList: {
    paddingTop: 0,
    paddingBottom: spacing(1),
  },
  commentItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  commentCreationContainer: {
    marginLeft: spacing(2),
    marginBottom: spacing(1),
  },
  buttonsContainer: {
    '& > button': {
      marginTop: spacing(1),
      marginRight: spacing(1),
    },
  },
  cancelButton: {
    color: palette.white.main,
    backgroundColor: palette.danger.middle,

    '&:hover': {
      backgroundColor: palette.danger.main,
      borderColor: palette.danger.main,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: palette.danger.main,
      borderColor: palette.danger.main,
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  reportLoader: {
    margin: '0 0 0 10px',
    padding: 0,
  },
}));
