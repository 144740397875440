import { useRef } from 'react';

/**
 * Hook to save ref only once.
 * @param initialValue - may be a function or value.
 * @return {React.MutableRefObject<null>}
 * Example:
 * const countRef = useExpensiveRef(new Observer())
 * or
 * const countRef = useExpensiveRef(() => new Observer())
 */
export const useExpensiveRef = (initialValue) => {
  const ref = useRef();

  if (!ref.current) {
    ref.current =
      typeof initialValue === 'function' ? initialValue() : initialValue;
  }

  return ref;
};
