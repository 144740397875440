import { get, pick } from 'lodash/object';

import { INVALID_GRAPH_DATA } from 'constants/errors';
import {
  VALUES_KEY,
  RAW_DATA_GRAPH_PARAMS_PATH,
  DISTRIBUTION_ROSE_GRAPH_VALUABLE_PARAMS,
} from 'constants/graphs';
import { camelizeValues } from 'helpers/camelizer';
import { DESC, MONTH_NAMES } from 'constants/common';
import {
  getIntervalsFromValues,
  getLevelsTypeByStatsId,
  getSectorsFromAngles,
  getTitleAdditionByLevelType,
  getTitleFromRawGraphData,
} from 'helpers/graphs/common';
import { getPrettyNumber } from 'helpers/data';
import { getColorRange } from 'helpers/color';

/**
 * return prepared annual graph data for rose distribution graph
 * @param rawGraphData
 * @returns {{
 *   title: string,
 *   levels: {},
 *   legends: string[],
 *   maxProbability: number,
 *   roseLeafs: {start, end, sector, totalOccurence, intervalText, probabilities}[],
 *   legendTitle: string,
 *   colors: array
 * }} or array of such shape if there're levels in graph data
 */
export const getPreparedDistributionRoseAnnualGraphData = (rawGraphData) => {
  const {
    title,
    legendTitle,
    leafsAngles,
    targetValues,
    probabilityData,
    levels,
  } = getDistributionRoseCommonParams(rawGraphData);

  const hasLevels = !!Object.keys(levels).length;
  if (hasLevels) {
    return levels.values.reduce((acc, levelValue, index) => {
      const targetValuesIntervals = getIntervalsFromValues({
        values: targetValues[index],
        fixedMax: false,
      });
      const roseLeafs = getRoseLeafsProbabilityData({
        leafsAngles: leafsAngles[index],
        probabilityData: probabilityData[index],
        targetValuesIntervals,
      });
      const maxProbability = Math.max.apply(
        null,
        roseLeafs.map(({ totalOccurence }) => totalOccurence)
      );
      const colors = getColorRange({ steps: targetValuesIntervals.length });
      const levelTitleAddition = getTitleAdditionByLevelType(
        levels.type,
        levelValue
      );
      const preparedTitle = `${title}\nAnnual, ${levelTitleAddition}`;

      acc.push({
        levels,
        legendTitle,
        title: preparedTitle,
        legends: targetValuesIntervals,
        maxProbability,
        roseLeafs,
        colors,
      });

      return acc;
    }, []);
  }

  const targetValuesIntervals = getIntervalsFromValues({
    values: targetValues,
    fixedMax: false,
  });

  const roseLeafs = getRoseLeafsProbabilityData({
    leafsAngles,
    probabilityData,
    targetValuesIntervals,
  });

  const maxProbability = Math.max.apply(
    null,
    roseLeafs.map(({ totalOccurence }) => totalOccurence)
  );

  const colors = getColorRange({ steps: targetValuesIntervals.length });

  return {
    title: `${title}\nAnnual`,
    levels,
    colors,
    legendTitle,
    maxProbability,
    roseLeafs,
    legends: targetValuesIntervals,
  };
};

/**
 * return prepared monthly graph data for rose distribution graph
 * returned data is 12 elements array where each element is graphData for certain month
 * @param rawGraphData
 * @returns {
 * {
 *   title: string,
 *   levels: {}
 *   legends: string[],
 *   maxProbability: number,
 *   roseLeafs: {start, end, sector, totalOccurence, intervalText, probabilities}[],
 *   legendTitle: string,
 *   colors: array
 * }[]
 * } or array of such shape if there're levels in graph data
 */
export const getPreparedDistributionRoseMonthlyGraphData = (rawGraphData) => {
  const {
    title,
    legendTitle,
    leafsAngles,
    targetValues,
    probabilityData,
    levels,
  } = getDistributionRoseCommonParams(rawGraphData);

  const hasLevels = !!Object.keys(levels).length;
  const paramLengths = [
    leafsAngles.length,
    targetValues.length,
    probabilityData.length,
  ];
  if (paramLengths.some((length) => length !== 12)) {
    throw Error(INVALID_GRAPH_DATA);
  }

  return probabilityData.reduce((acc, probability, monthIndex) => {
    const currentTargetValues = targetValues[monthIndex];
    const currentLeafAngles = leafsAngles[monthIndex];
    if (hasLevels) {
      const dataByLevels = levels.values.reduce(
        (levelsAcc, levelValue, levelIndex) => {
          const targetValuesIntervals = getIntervalsFromValues({
            values: currentTargetValues[levelIndex],
            fixedMax: false,
          });
          const roseLeafs = getRoseLeafsProbabilityData({
            leafsAngles: currentLeafAngles[levelIndex],
            probabilityData: probability[levelIndex],
            targetValuesIntervals,
          });
          const maxProbability = Math.max.apply(
            null,
            roseLeafs.map(({ totalOccurence }) => totalOccurence)
          );
          const colors = getColorRange({ steps: targetValuesIntervals.length });
          const preparedTitle = `${title}\n${MONTH_NAMES[monthIndex]}, ${
            levels.type
          } = ${getPrettyNumber(levelValue, 1)}`;

          levelsAcc.push({
            levels,
            legendTitle,
            title: preparedTitle,
            legends: targetValuesIntervals,
            maxProbability,
            roseLeafs,
            colors,
          });

          return levelsAcc;
        },
        []
      );
      acc.push(dataByLevels);
      return acc;
    }

    const targetValuesIntervals = getIntervalsFromValues({
      values: currentTargetValues,
      fixedMax: false,
    });
    const colors = getColorRange({ steps: targetValuesIntervals.length });
    const roseLeafs = getRoseLeafsProbabilityData({
      targetValuesIntervals,
      probabilityData: probability,
      leafsAngles: currentLeafAngles,
    });
    const maxProbability = Math.max.apply(
      null,
      roseLeafs.map(({ totalOccurence }) => totalOccurence)
    );

    acc.push({
      colors,
      levels,
      legendTitle,
      maxProbability,
      roseLeafs,
      legends: targetValuesIntervals,
      title: `${title}\n${MONTH_NAMES[monthIndex]}`,
    });

    return acc;
  }, []);
};

/**
 * returns common rose graph params from raw graph data
 * @param rawGraphData
 * @returns {{ title, leafsAngles, targetValues, legendTitle, probabilityData }}
 */
export const getDistributionRoseCommonParams = (rawGraphData) => {
  const rawGraphParams = get(rawGraphData, RAW_DATA_GRAPH_PARAMS_PATH, null);
  if (!rawGraphParams) {
    throw Error(INVALID_GRAPH_DATA);
  }

  const levels =
    rawGraphData.level && rawGraphData.level[VALUES_KEY]
      ? {
          type: getLevelsTypeByStatsId(rawGraphData.id),
          values: rawGraphData.level[VALUES_KEY],
        }
      : {};

  const commonParams = pick(
    rawGraphParams,
    DISTRIBUTION_ROSE_GRAPH_VALUABLE_PARAMS
  );

  const { legendTitle } = commonParams;
  const { varBinsName, histogramName, dirBinsName } = camelizeValues(
    commonParams
  );

  const leafsAngles = rawGraphData[dirBinsName][VALUES_KEY];
  const targetValues = rawGraphData[varBinsName][VALUES_KEY];
  const probabilityData = rawGraphData[histogramName][VALUES_KEY];
  const title = getTitleFromRawGraphData(rawGraphData);

  return {
    title,
    legendTitle,
    leafsAngles,
    targetValues,
    probabilityData,
    levels,
  };
};

/**
 * returns rose leafs data by probability
 * (probability of wave with certain height appearance in certain sector)
 * @param probabilityData
 * @param targetValuesIntervals
 * @param leafsAngles
 * @returns {{ startAngle, endAngle, sector, totalOccurence, intervalText, probabilities }[]}
 */
export const getRoseLeafsProbabilityData = ({
  targetValuesIntervals,
  probabilityData,
  leafsAngles,
}) => {
  const sectors = getSectorsFromAngles({
    anglesValues: leafsAngles,
    offset: 90,
    direction: DESC,
  });
  const sectorsIntervals = getIntervalsFromValues({
    values: leafsAngles,
    mask: '{from} ° to {to} °',
    isCyclic: true,
  });

  return sectors.map(({ sector, start, end }, sectorIndex) => {
    const { probabilities, totalOccurence } = targetValuesIntervals.reduce(
      (acc, _, intervalIndex) => {
        const currentWaveHeightProbability =
          probabilityData[intervalIndex][sectorIndex];

        acc.probabilities.push(currentWaveHeightProbability);
        acc.totalOccurence += currentWaveHeightProbability;

        return acc;
      },
      { probabilities: [], totalOccurence: 0 }
    );
    return {
      probabilities,
      totalOccurence,
      startAngle: start,
      endAngle: end,
      sectorValue: sector,
      sector: sectorsIntervals[sectorIndex],
    };
  });
};
