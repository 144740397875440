import { Input, InputAdornment, InputLabel } from '@material-ui/core';
import classNames from 'classnames';
import { MagnifyingGlass, X } from 'phosphor-react';
import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './styles';

/**
 * Search field component with one search field
 * @param {String} value
 * @param {String} placeholder
 * @param {Function} onChange
 * @param {Function} onClear
 * @returns {jsx}
 */
const SearchField = ({ value = '', onChange, onClear, placeholder }) => {
  const classes = useStyles();

  return (
    <InputLabel className={classes.searchContainer}>
      <Input
        disableUnderline
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        classes={{
          root: classNames(classes.searchInputRoot, {
            [classes.searchFieldFocused]: value,
          }),
          input: classes.searchField,
          focused: classes.searchFieldFocused,
        }}
        startAdornment={
          <InputAdornment position="start">
            <MagnifyingGlass size={18} />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment
            className={classes.clearField}
            position="end"
            onClick={onClear}
          >
            <X size={18} />
          </InputAdornment>
        }
      />
    </InputLabel>
  );
};

SearchField.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default memo(SearchField);
