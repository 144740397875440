import Grid from '@material-ui/core/Grid';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * GridNet component
 * Get collection and displays it in grid
 * @param {React.ReactNode} children - displayed collection
 * @param {Number} spacing - spacing between two grid items
 * @param {Object} props - other properties for Grid
 * @param {Number} columnsNumber - number of columns
 */
const GridNet = ({ children = [], spacing, columnsNumber, ...props }) => {
  const gridItemSize = 12 / columnsNumber;
  if (12 % columnsNumber !== 0) {
    throw new Error('Change number of columns');
  }
  return Array.isArray(children) ? (
    <Grid container spacing={spacing} justifyContent="center" {...props}>
      {Object.values(children).map((child) => (
        <Grid item lg={gridItemSize} md={6} sm={12} key={child.key}>
          {child}
        </Grid>
      ))}
    </Grid>
  ) : (
    <Grid item lg={gridItemSize} md={gridItemSize} xs={12} {...props}>
      {children}
    </Grid>
  );
};

GridNet.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  spacing: PropTypes.number,
  columnsNumber: PropTypes.number,
};

GridNet.defaultProps = {
  spacing: 1,
  columnsNumber: 3,
};

export default GridNet;
