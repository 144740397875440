import React, { memo, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  selectNumberOfAccounts,
  selectUserAccountInfo,
} from 'ducks/user/selectors';
import MyPlansPageComponent from 'components/plans/MyPlansPage';
import { formatDate } from 'helpers/date';
import { getUserSettings } from 'ducks/user/actions';
import { requestOffersIfNeed } from 'ducks/offers/actions';
import { selectOffers } from 'ducks/offers/selectors';
import { DATE_FORMATS } from 'constants/common';

/**
 * MyPlansPage container
 * Displays customer information and offer information
 */
const MyPlansPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const accountInfo = useSelector(selectUserAccountInfo);
  const { numberOfActivatedAccounts, numberOfPendingAccounts } = useSelector(
    selectNumberOfAccounts
  );
  useEffect(() => {
    dispatch(getUserSettings());
    dispatch(requestOffersIfNeed());
  }, [dispatch]);

  const offers = useSelector(selectOffers);
  const {
    pack,
    subscriptionEnd,
    subscriptionExpired,
    credits,
    totalCredits,
  } = accountInfo;
  const {
    hasUnlimitedDiscover,
    hasUnlimitedFullStudy,
    name: packageName,
  } = pack;
  const dateOfSubscriptionEnd = useMemo(
    () => formatDate(subscriptionEnd, DATE_FORMATS.frenchFormat),
    [subscriptionEnd]
  );

  const subtitleStatus = useMemo(() => {
    if (!Object.keys(accountInfo).length || subscriptionExpired) {
      return { status: t('profile.accountExpired'), textColor: 'danger' };
    }
    if (hasUnlimitedDiscover) {
      return { status: t('profile.unlimitedDiscover'), textColor: 'success' };
    }
    if (hasUnlimitedFullStudy) {
      return { status: t('profile.unlimitedFullStudy'), textColor: 'success' };
    }
    return { status: '', textColor: 'default' };
  }, [
    accountInfo,
    subscriptionExpired,
    hasUnlimitedDiscover,
    hasUnlimitedFullStudy,
    t,
  ]);

  return (
    <MyPlansPageComponent
      accountInfo={accountInfo}
      dateOfSubscriptionEnd={dateOfSubscriptionEnd}
      subtitleStatus={subtitleStatus}
      offers={offers}
      credits={credits}
      totalCredits={totalCredits}
      packageName={packageName}
      numberOfActivatedAccounts={numberOfActivatedAccounts}
      numberOfPendingAccounts={numberOfPendingAccounts}
    />
  );
};

export default memo(MyPlansPage);
