import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  geometriesList: {
    width: '100%',
  },
  panel: {
    padding: '0 15px',
    width: '100%',
    '&:not(:last-child)': {
      borderBottom: 'none',
    },
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    '&:last-child': {
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
  },
  expanded: {
    margin: '2px 0px !important',
  },
  summary: {
    '& span': {
      width: '60%',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      overflow: 'hidden',
      textAlign: 'left',
    },
  },
  options: {
    width: '100%',
  },
  items: {
    width: '100%',
    justifyContent: 'space-between',
  },
  checker: {
    justifyContent: 'flex-end',
    width: '100%',
  },
});
