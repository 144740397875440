import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { selectCollaborators, selectCurrentUser } from 'ducks/user/selectors';
import { useQuery } from 'hooks/useQuery';
import { useDependentsFilters } from 'hooks/useDependentsFilters';
import { hasSubString } from 'helpers/filters';
import GrantAccessToProjectForm from 'components/forms/GrantAccessToProjectForm';
import { useModal } from 'hooks/useModal';
import { INVITE_COLLABORATOR_MODAL } from 'constants/modals';
import { selectProjectById } from 'ducks/projects/selectors';
import { updateProjectCollaboratorsWaitingRequest } from 'ducks/app/actions';

const collaboratorsFilters = {
  search: ({ firstName, lastName, email }, expected) =>
    hasSubString(firstName, expected) ||
    hasSubString(lastName, expected) ||
    hasSubString(email, expected),
};

/**
 * GrantAccessToProject form container
 * Contains logic and data for GrantAccessToProjectModal
 * @param id - modal id
 * @param projectId - project id
 */
const GrantAccessToProjectContainer = ({ modalId, projectId, onSkip }) => {
  const collaborators = useSelector(selectCollaborators);
  const { collaborators: projectCollaborators } = useSelector((state) =>
    selectProjectById(state, projectId)
  );
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const {
    query: { search },
    mergeQuery: mergeProjectQuery,
  } = useQuery({
    search: '',
  });

  const filteredCollaborators = useDependentsFilters({
    entities: collaborators,
    filters: collaboratorsFilters,
    expectedValues: { search },
  });

  const setSearch = useCallback(
    (value) => {
      mergeProjectQuery({ search: value });
    },
    [mergeProjectQuery]
  );

  const { openModal } = useModal();

  const openInviteModal = useCallback(() => {
    openModal(INVITE_COLLABORATOR_MODAL);
  }, [openModal]);

  const onSuccess = useCallback(
    ({ collaboratorsAccess }) => {
      dispatch(
        updateProjectCollaboratorsWaitingRequest({
          data: { [projectId]: collaboratorsAccess },
          modalId,
          user,
        })
      );
    },
    [dispatch, projectId, modalId, user]
  );

  return (
    <GrantAccessToProjectForm
      collaborators={filteredCollaborators}
      projectCollaborators={projectCollaborators}
      onSkip={onSkip}
      onSuccess={onSuccess}
      onSearchChange={setSearch}
      onOpenInviteModal={openInviteModal}
      search={search}
    />
  );
};

GrantAccessToProjectContainer.propTypes = {
  onSkip: PropTypes.func.isRequired,
};

export default React.memo(GrantAccessToProjectContainer);
