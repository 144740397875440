import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import BaseModal from 'components/common/BaseModal';
import { ROUTES } from 'constants/routes';
import NativeLink from 'components/common/NativeLink';
import { SUPPORT_EMAIL } from 'common/config';

import { useStyles } from './styles';

/**
 * SignUpSuccessModal component
 * Displays modal with information about successful registration
 * @param onClose
 */
const SignUpSuccessModal = ({ onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <BaseModal
      onClose={onClose}
      title={t('signup.successful')}
      modalClass={classes.modal}
    >
      <div className={classes.container}>
        <Typography variant="subtitle2" align="center">
          {t('signup.followInstructions')}
        </Typography>

        <Link to={ROUTES.home}>
          <Button variant="contained" color="primary" onClick={onClose}>
            {t('signup.access')}
          </Button>
        </Link>

        <div className={classes.footerContainer}>
          {t('singup.supportContact')}&nbsp;
          <NativeLink to={`mailto:${SUPPORT_EMAIL}`}>
            {t('singup.contactUs')}
          </NativeLink>
        </div>
      </div>
    </BaseModal>
  );
};

SignUpSuccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default memo(SignUpSuccessModal);
