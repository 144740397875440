import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  header: {
    padding: 15,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.sinay.primary['40'],
    color: 'white',
  },
  container: {
    display: 'grid',
  },
  title: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 'bold',
  },
  subtitle: {
    padding: '5px 0px',
    color: theme.palette.lightGrey.main,
    fontSize: 10,
    textTransform: 'uppercase',
    fontFamily: 'Inter',
  },
  icon: {
    color: theme.palette.primary.main,
    padding: '12px 5px',
    background: theme.palette.sinay.primary['90'],
    borderRadius: 50,
    '&:hover': {
      background: theme.palette.sinay.primary['80'],
    },
    '&:focus': {
      outline: 'none',
    },
  },
  colorLabel: {
    height: 6,
    backgroundColor: theme.palette.blue.light,
    '&$offerType': {
      backgroundColor: theme.palette.sinay.primary['90'],
    },
    '&$lightType': {
      backgroundColor: theme.palette.blue.middle,
    },
    '&$demoType': {
      backgroundColor: theme.palette.lightGreen.main,
    },
  },
  cardMap: {
    pointerEvents: 'none',
    width: '100%',
    outline: 'none',
    height: 220,
  },
  offerType: {},
  lightType: {},
  demoType: {},
  renameButton: {
    ...theme.buttons.outlined.secondary,
    border: 'none',
    padding: '10px 40px',
    margin: '5px 20px',
  },
  deleteButton: {
    ...theme.buttons.outlined.tertiary,
    border: 'none',
    padding: '10px 40px',
    margin: '5px 20px',
  },
  svgIcons: {
    width: 20,
  },
}));
