import { get } from 'lodash';

import {
  getLevelsTypeByStatsId,
  getTitleAdditionByLevelType,
  getTitleFromRawGraphData,
} from 'helpers/graphs/common';
import {
  GRAPH_EMPTY_PLACEHOLDER,
  RAW_DATA_GRAPH_PARAMS_PATH,
  VALUES_KEY,
} from 'constants/graphs';
import { INVALID_GRAPH_DATA } from 'constants/errors';
import { arrayExclude, capitalizeFirstLetter } from 'helpers/common';
import { toCamelCase } from 'helpers/camelizer';
import { getPrettyNumber } from 'helpers/data';
import { EOL } from 'constants/common';

/**
 * returns prepared data for extreme tables charts
 * @param rawData
 * @returns {{
 *    data: { columns: { name, units, values }[], title: string },
 *    levels: {},
 *  } | {
 *    data: { columns: { name, units, values }[], title: string }[],
 *    levels: { values: number[], type: string }
 * }}
 */
export const getPreparedExtremeTablesData = (rawData) => {
  const title = getTitleFromRawGraphData(rawData);
  const levels = rawData?.level?.[VALUES_KEY]
    ? {
        type: getLevelsTypeByStatsId(rawData.id),
        values: rawData.level[VALUES_KEY],
      }
    : {};

  const hasLevels = !!levels.type;

  if (!hasLevels) {
    const preparedColumns = getPreparedColumnsByRawData(rawData);

    return {
      levels,
      data: {
        title,
        columns: preparedColumns,
      },
    };
  }

  const levelsValues = levels?.[VALUES_KEY] ?? [];
  const data = levelsValues.map((level, levelIndex) => {
    const columns = getPreparedColumnsByRawData(rawData, levelIndex);
    const levelTitleAddition = getTitleAdditionByLevelType(
      levels.type,
      levels.values[levelIndex]
    );

    return {
      title: `${title}${EOL}${levelTitleAddition}`,
      columns,
    };
  });

  levels.values = levelsValues.map(Math.round);

  return {
    levels,
    data,
  };
};

/**
 * returns prepared columns collection by raw data and level index (if given)
 * @param { object } rawData
 * @param { number|null } levelIndex
 * @returns { array }
 */
const getPreparedColumnsByRawData = (rawData, levelIndex = null) => {
  const rawParams = get(rawData, RAW_DATA_GRAPH_PARAMS_PATH, null);
  if (!rawParams) {
    throw Error(INVALID_GRAPH_DATA);
  }

  const { columns: rawColumnNames, digits } = rawParams;
  const roundFunction = (value) => getPrettyNumber(value, digits);

  return rawColumnNames.reduce((acc, rawName) => {
    const name = capitalizeFirstLetter(rawName.replaceAll('_', ' '));
    const params = rawData?.[toCamelCase(rawName)];
    const units = params?.attributes?.units ?? '';
    const values =
      levelIndex === null
        ? params?.[VALUES_KEY]
        : params?.[VALUES_KEY]?.[levelIndex];

    const preparedValues = arrayExclude(
      (values ?? []).map(roundFunction),
      GRAPH_EMPTY_PLACEHOLDER
    );

    acc.push({
      name,
      values: preparedValues,
      units: units ? `[${units}]` : '',
    });

    return acc;
  }, []);
};
