import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, buttons }) => ({
  errorMessage: {
    color: palette.danger.middle,
    padding: '20px',
  },
  buttonDanger: {
    ...buttons.fill.error,
  },
  backButton: {
    ...buttons.elevated.neutral,
  },
}));
