import React from 'react';
import PropTypes from 'prop-types';

import SvgColorsLegend from 'components/common/graphs/SvgColorsLegend';

const Y_OFFSET = 10;
const FONT_SIZE = 12;
const LINE_OFFSET = 5;

/**
 * Custom color legend component.
 * Decorator for SvgColorsLegend, helps to use it with recharts as `Customized` component
 * Supposed to be used with Radial charts (cx, cy, outerRadius)
 * @param { string } title
 * @param { number } cx
 * @param { number } cy
 * @param { number } outerRadius
 * @param { string[] } legends - legend text array
 * @param { string[] } colors - legend colors array
 * @param { number } legendWidth - legend container width
 * @param { number } leftOffset - x offset from chart to color bar
 * @returns { JSX }
 * @see SvgColorsLegend
 */
const CustomColorLegend = ({
  title,
  cx,
  cy,
  outerRadius,
  legends,
  colors,
  legendWidth,
  leftOffset,
}) => {
  const yAxisStart = cy - outerRadius;
  const height = outerRadius * 2;
  const x = cx * 2 + leftOffset;

  const legendsHeight =
    Y_OFFSET * 2 +
    LINE_OFFSET +
    (legends.length + 1) * (FONT_SIZE + LINE_OFFSET);
  const containerY = yAxisStart + ~~((height - legendsHeight) / 2);

  return (
    <svg x={x} y={containerY} width={legendWidth} height={legendsHeight}>
      <SvgColorsLegend
        title={title}
        height={legendsHeight}
        width={legendWidth}
        offsetY={Y_OFFSET}
        legends={legends}
        colors={colors}
        fontSize={FONT_SIZE}
        lineOffset={LINE_OFFSET}
      />
    </svg>
  );
};

CustomColorLegend.propTypes = {
  title: PropTypes.string,
  legendWidth: PropTypes.number,
  legends: PropTypes.arrayOf(PropTypes.string).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  cx: PropTypes.number,
  cy: PropTypes.number,
  outerRadius: PropTypes.number,
  leftOffset: PropTypes.number,
};

CustomColorLegend.defaultProps = {
  legendWidth: 200,
  leftOffset: 20,
};

export default CustomColorLegend;
