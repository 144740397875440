import { createSelector } from 'reselect';

import { REPORT_TYPE, DEFAULT_REPORT_TYPE_ID } from 'constants/contentTypes';

export const selectContentTypes = ({ contentTypes }) => contentTypes.data;

export const selectReportContentTypeId = createSelector(
  selectContentTypes,
  (contentTypes) => {
    const reportType = contentTypes.find((type) => type.model === REPORT_TYPE);
    return reportType ? reportType.id : DEFAULT_REPORT_TYPE_ID;
  }
);
