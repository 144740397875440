import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  listWrapper: {},
  list: {
    maxHeight: 250,
    overflowY: 'auto',
    paddingLeft: 0,
    margin: '0 0 40px',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 15px',
    '&:not(:last-child)': {
      borderBottom: '1px solid #ddd',
    },
  },
  listItemText: {},
  listItemTitle: {
    fontSize: 14,
    fontWeight: 700,
  },
  listItemSubtitle: {
    fontSize: 12,
    color: theme.palette.grey.main,
  },
  listItemIcon: {
    '& button': {
      color: theme.palette.grey.main,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& input': {
      padding: 10,
    },
    margin: '10px 50px 0 50px',
  },
  checkboxRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  saveButton: {
    ...theme.buttons.outlined.secondary,
  },
  fieldName: {
    marginBottom: 25,
    marginRight: 30,
    fontWeight: 700,
  },
  fullWidth: {
    width: '100%',
  },
  formText: {
    textAlign: 'center',
    marginBottom: 15,
    fontSize: 12,
  },
}));
