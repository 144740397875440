import { generatePath } from 'react-router-dom';

import request from 'helpers/request';
import { makeXhrRequest } from 'helpers/requestXhr';
import {
  MARINE_CONTRACTORS_URL,
  MARINE_CONTRACTORS_JOB,
  MARINE_CONTRACTORS_JOBS,
  MARINE_CONTRACTORS_OPERATIONS_URL,
  MARINE_CONTRACTORS_CONSTRAINTS_URL,
  MARINE_CONTRACTORS_VARIABLES_URL,
  MARINE_CONTRACTORS_CUSTOMER_URL,
  MARINE_CONTRACTORS_COMPUTE_URL,
  MARINE_CONTRACTORS_LATEST_JOB,
  MARINE_CONTRACTORS_RESULTS_URL,
  MARINE_CONTRACTORS_RESULTS_DOWNLOAD_URL,
} from 'constants/api';

export const getMarineContractors = () =>
  request.get(`${MARINE_CONTRACTORS_URL}`);

export const getMarineContractorsVariables = () =>
  request.get(`${MARINE_CONTRACTORS_VARIABLES_URL}`);

export const getMarineContractorsJob = (job) =>
  request.get(generatePath(MARINE_CONTRACTORS_JOB, { id: job.id }));

export const getMarineContractorsLatestJob = (projectId) =>
  request.get(`${MARINE_CONTRACTORS_LATEST_JOB}&project_id=${projectId}`);

export const getMarineContractorsCustomer = (customerId) =>
  request.get(`${MARINE_CONTRACTORS_CUSTOMER_URL}${customerId}`);

export const getMarineContractorsResults = (job) =>
  request.get(generatePath(MARINE_CONTRACTORS_RESULTS_URL, { id: job.id }));

export const updateMarineContractorsJobOperations = (jobId, data) =>
  request.put(`${MARINE_CONTRACTORS_JOBS}${jobId}/`, data);

export const createMarineContractorsBoatRequest = (data) =>
  request.post(`${MARINE_CONTRACTORS_URL}`, data);

export const updateMarineContractorsBoatRequest = (data) =>
  request.put(`${MARINE_CONTRACTORS_URL}${data.id}/`, data);

export const deleteMarineContractorsBoatRequest = (data) =>
  request.delete(`${MARINE_CONTRACTORS_URL}${data.id}/`);

export const createMarineContractorsOperationRequest = (data) =>
  request.post(`${MARINE_CONTRACTORS_OPERATIONS_URL}`, data);

export const updateMarineContractorsOperationsRequest = (data) =>
  request.put(`${MARINE_CONTRACTORS_OPERATIONS_URL}${data.id}/`, data);

export const deleteMarineContractorsOperationsRequest = (data) =>
  request.delete(`${MARINE_CONTRACTORS_OPERATIONS_URL}${data.id}/`);

export const createMarineConstractorsConstraintsRequest = (data) =>
  request.post(`${MARINE_CONTRACTORS_CONSTRAINTS_URL}`, data);

export const updateMarineConstractorsConstraintsRequest = (data) =>
  request.put(`${MARINE_CONTRACTORS_CONSTRAINTS_URL}${data.id}/`, data);

export const deleteMarineContractorsConstraintsRequest = (data) =>
  request.delete(`${MARINE_CONTRACTORS_CONSTRAINTS_URL}${data.id}/`);

export const updateMarineConstractorsCustomerRequest = (data) =>
  request.put(`${MARINE_CONTRACTORS_CUSTOMER_URL}${data.customer}/`, data);

export const launchMarineContractorsComputationRequest = (job) =>
  request.put(generatePath(MARINE_CONTRACTORS_COMPUTE_URL, { id: job.id }));

/**
 * Downloads results using xhr request
 */
export const downloadResults = ({ jobId }) => {
  const url = generatePath(MARINE_CONTRACTORS_RESULTS_DOWNLOAD_URL, {
    id: jobId,
  });
  return makeXhrRequest({
    method: 'GET',
    url,
    responseType: 'arraybuffer',
  }).then((result) => result);
};
