import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import messageIcon from 'assets/images/project/point_cursor.png';
import { theme } from 'common/theme';

export const useStyles = makeStyles(({ palette }) => ({
  pointsButtonGroup: {
    padding: 15,
  },
  pointsButton: {
    marginBottom: 10,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  uploadCard: {
    marginBottom: 10,
    color: palette.primary.main,
    border: `1px solid ${palette.primary.main}`,
    '&:hover': {
      backgroundColor: palette.sinay.primary['95'],
      borderColor: palette.primary.light,
    },
  },
  computeButton: {
    marginTop: 20,
    marginBottom: 20,
    ...theme.buttons.outlined.secondary,
  },
  removeAllButton: {
    marginBottom: 10,
    ...theme.buttons.elevated.neutral,
  },
  cancelComputationButton: {
    color: palette.white.main,
    ...theme.buttons.fill.error,
  },
  messageNoPoints: {
    marginBottom: 2,
    paddingTop: 85,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 13,
    lineHeight: 1.4,
    color: alpha(palette.primary.main, 0.5),
    background: `url(${messageIcon}) 50% 35px no-repeat`,
  },
  messageNoPointsBig: {
    fontWeight: 600,
  },
  checkbox: {
    justifyContent: 'center',
  },
  content: {
    padding: '16px 4px 0px 4px',
    textAlign: 'center',
  },
  infoIcon: {
    width: 20,
    color: palette.sinay.primary['60'],
  },
  deleteIcon: {
    width: 20,
    marginRight: 5,
  },
  wrapper: {
    paddingBottom: 60,
  },
  analyzeIcon: {
    width: 20,
    marginRight: 5,
  },
  howToSelectPointContainer: {
    background: palette.primary.light,
    borderRadius: 16,
    border: `1px solid ${palette.sinay.primary['80']}`,
    '& div.MuiButtonBase-root': {
      background: 'white',
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      border: `1px solid ${palette.sinay.primary['80']}`,
      textAlign: 'center',
    },
    '& div.MuiAccordionSummary-content': {
      justifyContent: 'center',
    },
  },
}));
