import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  colorbarLegend: {
    marginRight: 32,
  },
  colorbarTable: {
    margin: 10,
  },
  colorbarItem: {
    width: 18,
    height: 18,
    padding: 10,
  },
  colorbarLabel: {
    paddingLeft: 15,
  },
  colorbarSource: {
    paddingLeft: 10,
    marginBlockStart: 0,
    marginBlockEnd: 0,

    '& p': {
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
  },
});
