const PROJECT_REPORTS_CATEGORIES_NAMES = {
  operability: 'Operability',
  design: 'Design',
};

const PROJECT_REPORTS_PARTS_NAMES = {
  introduction: 'Introduction',
  locations: 'Locations',
  maps: 'Maps',
  statistics: 'Statistics',
  conclusion: 'Conclusion',
  databases: 'Databases',
  methodology: 'Methodology',
};

const PARTS_NAMES_POSTFIX = '(Annex)';

export const PROJECT_REPORTS_CATEGORIES = [
  {
    id: PROJECT_REPORTS_CATEGORIES_NAMES.operability,
    name: PROJECT_REPORTS_CATEGORIES_NAMES.operability,
  },
  {
    id: PROJECT_REPORTS_CATEGORIES_NAMES.design,
    name: PROJECT_REPORTS_CATEGORIES_NAMES.design,
  },
];

export const PROJECT_REPORTS_PARTS = [
  {
    id: PROJECT_REPORTS_PARTS_NAMES.introduction,
    name: PROJECT_REPORTS_PARTS_NAMES.introduction,
  },
  {
    id: PROJECT_REPORTS_PARTS_NAMES.locations,
    name: PROJECT_REPORTS_PARTS_NAMES.locations,
  },
  {
    id: PROJECT_REPORTS_PARTS_NAMES.maps,
    name: PROJECT_REPORTS_PARTS_NAMES.maps,
  },
  {
    id: PROJECT_REPORTS_PARTS_NAMES.statistics,
    name: PROJECT_REPORTS_PARTS_NAMES.statistics,
  },
  {
    id: PROJECT_REPORTS_PARTS_NAMES.conclusion,
    name: PROJECT_REPORTS_PARTS_NAMES.conclusion,
  },
  {
    id: PROJECT_REPORTS_PARTS_NAMES.databases,
    name: `${PROJECT_REPORTS_PARTS_NAMES.databases} ${PARTS_NAMES_POSTFIX}`,
  },
  {
    id: PROJECT_REPORTS_PARTS_NAMES.methodology,
    name: `${PROJECT_REPORTS_PARTS_NAMES.methodology} ${PARTS_NAMES_POSTFIX}`,
  },
];

export const PROJECT_REPORTS_ALL_VALUE = 'All';

export const REPORT_STATUS_RUNNING = 'RUNNING';
export const REPORT_STATUS_FINISHED = 'FINISHED';

export const REPORT_TYPE_BY_CATEGORY = {
  [PROJECT_REPORTS_CATEGORIES_NAMES.operability]: 'average',
  [PROJECT_REPORTS_CATEGORIES_NAMES.design]: 'extreme',
};
