import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  ({ palette, navbarHeight, projectMenuHeight }) => {
    const navSpace = projectMenuHeight + navbarHeight;
    return {
      projectContainer: {
        minHeight: `calc(100vh - (${navSpace}px))`,
        backgroundColor: palette.background.paper,
      },
    };
  }
);
