import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { MapPopup } from 'components/leaflet';
import {
  BaseMapPopup,
  BaseMapPopupContent,
  BaseMapPopupTitle,
} from 'components/common/BaseMapPopup';
import OverviewOceanConditions from 'containers/projects/OverviewOceanConditions';

import { useStyles } from './styles';

/**
 * Popup rendering if point is computed
 * @param {Object} point
 * @param {Object} props
 * @return {jsx}
 */
const OverviewOceanConditionsPopup = ({ point, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <MapPopup {...props}>
      <BaseMapPopup className={classes.overviewPopup}>
        <BaseMapPopupTitle>
          {t('points.overview.title', { point: point.name })}
        </BaseMapPopupTitle>
        <BaseMapPopupContent>
          <OverviewOceanConditions point={point} />
        </BaseMapPopupContent>
      </BaseMapPopup>
    </MapPopup>
  );
};

export default memo(OverviewOceanConditionsPopup);
