import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  createPointPopup: {
    width: 380,
  },
  addIcon: {
    margin: 10,
    fill: palette.blue.main,
  },
}));
