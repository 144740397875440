import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import CyclingButton from 'components/common/buttons/CyclingButton';

/**
 * HeaderCell component.
 * A special header cell that wrap around it's children to add extrat information
 * and allow for more flexible event/value bubling.
 * Right now it doesn't support children with no onClick binding.
 * @param { node } chidlren
 * @param { func } onClick
 * @param { func } onHover
 * @param { any } value
 * @param props
 */
const HeaderCell = ({
  children,
  onClick,
  onHover,
  sorting,
  values,
  ...props
}) => {
  const [sort, setSort] = useState(sorting);
  // When a column is sorted at fist,
  // the Cycling Button would start from the 0 nth element
  // which would do nothing since the array was already
  // on the precious position which is the max element and would
  // end up on the 0 nth element again.
  // To fix that the next element is give as the starting index for the
  // Cycling button
  const index = values.findIndex(({ sort: target }) => target === sorting);

  const handleClick = useCallback(
    (event, value) => {
      setSort(value.sort);
      return onClick(event, value);
    },
    [onClick]
  );

  return (
    <TableCell {...props}>
      {values.length > 0 ? (
        <TableSortLabel direction={sort}>
          <CyclingButton
            onClick={handleClick}
            start={(index + 1) % 2}
            values={values}
          >
            {children}
          </CyclingButton>
        </TableSortLabel>
      ) : (
        <span>{children}</span>
      )}
    </TableCell>
  );
};

HeaderCell.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  onHover: PropTypes.func,
};

export default HeaderCell;
