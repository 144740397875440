const prefix = '@@marineContractors';

export const REQUEST_MARINE_CONTRACTORS = `${prefix}/REQUEST_MARINE_CONTRACTORS`;
export const REQUEST_MARINE_CONTRACTORS_SUCCESS = `${prefix}/REQUEST_MARINE_CONTRACTORS_SUCCESS`;
export const REQUEST_MARINE_CONTRACTORS_ERROR = `${prefix}/REQUEST_MARINE_CONTRACTORS_ERROR`;

export const REQUEST_MARINE_CONTRACTORS_VARIABLES = `${prefix}/REQUEST_MARINE_CONTRACTORS_VARIABLES`;
export const REQUEST_MARINE_CONTRACTORS_VARIABLES_SUCCESS = `${prefix}/REQUEST_MARINE_CONTRACTORS_VARIABLES_SUCCESS`;
export const REQUEST_MARINE_CONTRACTORS_VARIABLES_ERROR = `${prefix}/REQUEST_MARINE_CONTRACTORS_VARIABLES_ERROR`;

export const REQUEST_MARINE_CONTRACTORS_JOB = `${prefix}/REQUEST_MARINE_CONTRACTORS_JOB`;
export const SET_MARINE_CONTRATORS_OPERATIONS = `${prefix}/SET_MARINE_CONTRATORS_OPERATIONS`;
export const REMOVE_MARINE_CONTRACTORS_OPERATIONS = `${prefix}/REMOVE_MARINE_CONTRACTORS_OPERATIONS`;
export const REQUEST_MARINE_CONTRACTORS_JOB_SUCCESS = `${prefix}/REQUEST_MARINE_CONTRACTORS_JOB_SUCCESS`;
export const REQUEST_MARINE_CONTRACTORS_JOB_ERROR = `${prefix}/REQUEST_MARINE_CONTRACTORS_JOB_ERROR`;

export const REQUEST_MARINE_CONTRACTORS_CUSTOMER = `${prefix}/REQUEST_MARINE_CONTRACTORS_CUSTOMER`;
export const REQUEST_MARINE_CONTRACTORS_CUSTOMER_SUCCESS = `${prefix}/REQUEST_MARINE_CONTRACTORS_CUSTOMER_SUCCESS`;
export const REQUEST_MARINE_CONTRACTORS_CUSTOMER_ERROR = `${prefix}/REQUEST_MARINE_CONTRACTORS_CUSTOMER_ERROR`;

export const REQUEST_MARINE_CONTRACTORS_RESULTS = `${prefix}/REQUEST_MARINE_CONTRACTORS_RESULTS`;
export const REQUEST_MARINE_CONTRACTORS_RESULTS_SUCCESS = `${prefix}/REQUEST_MARINE_CONTRACTORS_RESULTS_SUCCESS`;
export const REQUEST_MARINE_CONTRACTORS_RESULTS_ERROR = `${prefix}/REQUEST_MARINE_CONTRACTORS_RESULTS_ERROR`;

export const SAVE_MARINE_CONTRACTORS_JOB_OPERATIONS = `${prefix}/SAVE_MARINE_CONTRACTORS_JOB_OPERATIONS`;
export const SAVE_MARINE_CONTRACTORS_JOB_OPERATIONS_SUCCESS = `${prefix}/SAVE_MARINE_CONTRACTORS_JOB_OPERATIONS_SUCCESS`;
export const SAVE_MARINE_CONTRACTORS_JOB_OPERATIONS_ERROR = `${prefix}/SAVE_MARINE_CONTRACTORS_JOB_OPERATIONS_ERROR`;

export const SAVE_MARINE_CONTRACTORS_JOB_POINTS = `${prefix}/SAVE_MARINE_CONTRACTORS_JOB_POINTS`;
export const SAVE_MARINE_CONTRACTORS_JOB_POINTS_SUCCESS = `${prefix}/SAVE_MARINE_CONTRACTORS_JOB_POINTS_SUCCESS`;
export const SAVE_MARINE_CONTRACTORS_JOB_POINTS_ERROR = `${prefix}/SAVE_MARINE_CONTRACTORS_JOB_POINTS_ERROR`;

export const SAVE_MARINE_CONTRACTORS_JOB_OUTPUTS = `${prefix}/SAVE_MARINE_CONTRACTORS_JOB_OUTPUTS`;
export const SAVE_MARINE_CONTRACTORS_JOB_OUTPUTS_SUCCESS = `${prefix}/SAVE_MARINE_CONTRACTORS_JOB_OUTPUTS_SUCCESS`;
export const SAVE_MARINE_CONTRACTORS_JOB_OUTPUTS_ERROR = `${prefix}/SAVE_MARINE_CONTRACTORS_JOB_OUTPUTS_ERROR`;

export const UPDATE_MARINE_CONTRACTORS_STEP = `${prefix}/UPDATE_MARINE_CONTRACTORS_STEP`;
export const UPDATE_MARINE_CONTRACTORS_STEP_SUCCESS = `${prefix}/UPDATE_MARINE_CONTRACTORS_STEP_SUCCESS`;
export const UPDATE_MARINE_CONTRACTORS_STEP_ERROR = `${prefix}/UPDATE_MARINE_CONTRACTORS_STEP_ERROR`;

export const UPDATE_MARINE_CONTRACTORS_CUSTOMER = `${prefix}/UPDATE_MARINE_CONTRACTORS_CUSTOMER`;
export const UPDATE_MARINE_CONTRACTORS_CUSTOMER_SUCCESS = `${prefix}/UPDATE_MARINE_CONTRACTORS_CUSTOMER_SUCCESS`;
export const UPDATE_MARINE_CONTRACTORS_CUSTOMER_ERROR = `${prefix}/UPDATE_MARINE_CONTRACTORS_CUSTOMER_ERROR`;

export const UPDATE_MARINE_CONTRACTORS_CUSTOMER_PERCENTILES = `${prefix}/UPDATE_MARINE_CONTRACTORS_CUSTOMER_PERCENTILES`;
export const UPDATE_MARINE_CONTRACTORS_CUSTOMER_PERCENTILES_SUCCESS = `${prefix}/UPDATE_MARINE_CONTRACTORS_CUSTOMER_PERCENTILES_SUCCESS`;
export const UPDATE_MARINE_CONTRACTORS_CUSTOMER_PERCENTILES_ERROR = `${prefix}/UPDATE_MARINE_CONTRACTORS_CUSTOMER_PERCENTILES_ERROR`;

export const CREATE_MARINE_CONTRACTORS_BOAT = `${prefix}/CREATE_MARINE_CONTRACTORS_BOAT`;
export const CREATE_MARINE_CONTRACTORS_TEMPORARY_BOAT = `${prefix}/CREATE_MARINE_CONTRACTORS_TEMPORARY_BOAT`;
export const CREATE_MARINE_CONTRACTORS_BOAT_SUCCESS = `${prefix}/CREATE_MARINE_CONTRACTORS_BOAT_SUCCESS`;
export const CREATE_MARINE_CONTRACTORS_BOAT_ERROR = `${prefix}/CREATE_MARINE_CONTRACTORS_BOAT_ERROR`;

export const UPDATE_MARINE_CONTRACTORS_BOAT = `${prefix}/UPDATE_MARINE_CONTRACTORS_BOAT`;
export const UPDATE_MARINE_CONTRACTORS_BOAT_SUCCESS = `${prefix}/UPDATE_MARINE_CONTRACTORS_BOAT_SUCCESS`;
export const UPDATE_MARINE_CONTRACTORS_BOAT_ERROR = `${prefix}/UPDATE_MARINE_CONTRACTORS_BOAT_ERROR`;

export const DELETE_MARINE_CONTRACTORS_BOAT = `${prefix}/DELETE_MARINE_CONTRACTORS_BOAT`;
export const DELETE_MARINE_CONTRACTORS_BOAT_SUCCESS = `${prefix}/DELETE_MARINE_CONTRACTORS_BOAT_SUCCESS`;
export const DELETE_MARINE_CONTRACTORS_BOAT_ERROR = `${prefix}/DELETE_MARINE_CONTRACTORS_BOAT_ERROR`;

export const CREATE_MARINE_CONTRACTORS_OPERATION = `${prefix}/CREATE_MARINE_CONTRACTORS_OPERATION`;
export const CREATE_MARINE_CONTRACTORS_OPERATION_SUCCESS = `${prefix}/CREATE_MARINE_CONTRACTORS_OPERATION_SUCCESS`;
export const CREATE_MARINE_CONTRACTORS_OPERATION_ERROR = `${prefix}/CREATE_MARINE_CONTRACTORS_OPERATION_ERROR`;

export const UPDATE_MARINE_CONTRACTORS_OPERATION = `${prefix}/UPDATE_MARINE_CONTRACTORS_OPERATION`;
export const UPDATE_MARINE_CONTRACTORS_OPERATION_SUCCESS = `${prefix}/UPDATE_MARINE_CONTRACTORS_OPERATION_SUCCESS`;
export const UPDATE_MARINE_CONTRACTORS_OPERATION_ERROR = `${prefix}/UPDATE_MARINE_CONTRACTORS_OPERATION_ERROR`;

export const DELETE_MARINE_CONTRACTORS_OPERATION = `${prefix}/DELETE_MARINE_CONTRACTORS_OPERATION`;
export const DELETE_MARINE_CONTRACTORS_OPERATION_SUCCESS = `${prefix}/DELETE_MARINE_CONTRACTORS_OPERATION_SUCCESS`;
export const DELETE_MARINE_CONTRACTORS_OPERATION_ERROR = `${prefix}/DELETE_MARINE_CONTRACTORS_OPERATION_ERROR`;

export const CREATE_MARINE_CONTRACTORS_CONSTRAINT = `${prefix}/CREATE_MARINE_CONTRACTORS_CONSTRAINT`;
export const CREATE_MARINE_CONTRACTORS_TEMPORARY_CONSTRAINT = `${prefix}/CREATE_MARINE_CONTRACTORS_TEMPORARY_CONSTRAINT`;
export const CREATE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS = `${prefix}/CREATE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS`;
export const CREATE_MARINE_CONTRACTORS_CONSTRAINT_ERROR = `${prefix}/CREATE_MARINE_CONTRACTORS_CONSTRAINT_ERROR`;

export const UPDATE_MARINE_CONTRACTORS_CONSTRAINT = `${prefix}/UPDATE_MARINE_CONTRACTORS_CONSTRAINT`;
export const UPDATE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS = `${prefix}/UPDATE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS`;
export const UPDATE_MARINE_CONTRACTORS_TEMPORARY_CONSTRAINT = `${prefix}/UPDATE_MARINE_CONTRACTORS_TEMPORARY_CONSTRAINT`;
export const UPDATE_MARINE_CONTRACTORS_CONSTRAINT_ERROR = `${prefix}/UPDATE_MARINE_CONTRACTORS_CONSTRAINT_ERROR`;

export const DELETE_MARINE_CONTRACTORS_CONSTRAINT = `${prefix}/DELETE_MARINE_CONTRACTORS_CONSTRAINT`;
export const DELETE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS = `${prefix}/DELETE_MARINE_CONTRACTORS_CONSTRAINT_SUCCESS`;
export const DELETE_MARINE_CONTRACTORS_CONSTRAINT_ERROR = `${prefix}/DELETE_MARINE_CONTRACTORS_CONSTRAINT_ERROR`;

export const CLEAN_BOAT_CREATE_ERROR = `${prefix}/CLEAN_BOAT_CREATE_ERROR`;
export const CLEAN_BOAT_UPDATE_ERROR = `${prefix}/CLEAN_BOAT_UPDATE_ERROR`;
export const CLEAN_OPERATION_CREATE_ERROR = `${prefix}/CLEAN_OPERATION_CREATE_ERROR`;
export const CLEAN_OPERATION_UPDATE_ERROR = `${prefix}/CLEAN_OPERATION_UPDATE_ERROR`;
export const CLEAN_CONSTRAINT_CREATE_ERROR = `${prefix}/CLEAN_CONSTRAINT_CREATE_ERROR`;
export const CLEAN_CONSTRAINT_UPDATE_ERROR = `${prefix}/CLEAN_CONSTRAINT_UPDATE_ERROR`;
export const CLEAN_OPERATIONS_CREATE_ERROR = `${prefix}/CLEAN_OPERATIONS_CREATE_ERROR`;
export const CLEAN_OPERATIONS_UPDATE_ERROR = `${prefix}/CLEAN_OPERATIONS_UPDATE_ERROR`;
export const CLEAN_PERCENTILES_ERROR = `${prefix}/CLEAN_PERCENTILES_ERROR`;
export const CLEAN_JOB_STATUS = `${prefix}/CLEAN_JOB_STATUS`;
export const CLEAN_ALL_ERRORS = `${prefix}/CLEAN_ALL_ERRORS`;

export const START_UPDATE_MARINE_CONTRACTORS_JOB = `${prefix}/START_UPDATE_MARINE_CONTRACTORS_JOB`;
export const STOP_UPDATE_MARINE_CONTRACTORS_JOB = `${prefix}/STOP_UPDATE_MARINE_CONTRACTORS_JOB`;
export const FINISH_UPDATE_MARINE_CONTRACTORS_JOB = `${prefix}/FINISH_UPDATE_MARINE_CONTRACTORS_JOB`;

export const RESET_MARINE_CONTRACTORS_STATE = `${prefix}/RESET_MARINE_CONTRACTORS_STATE`;

export const DOWNLOAD_RESULTS = `${prefix}/DOWNLOAD_RESULTS`;
export const DOWNLOAD_RESULTS_ERROR = `${prefix}/DOWNLOAD_RESULTS_ERROR`;
