import { useCallback, useReducer } from 'react';

import createReducer from 'helpers/createReducer';
import { SET_FIELD, SET_FIELDS, UPDATE_FIELD } from 'common/types/forms';
import { DOM_EVENT, CUSTOM_EVENT } from 'constants/common';

const fieldsReducerHandlers = {
  [SET_FIELD]: (state, { field, value }) => ({ ...state, [field]: value }),
  [SET_FIELDS]: (state, { payload }) => ({ ...state, ...payload }),
  [UPDATE_FIELD]: (state, { field, updater }) => ({
    ...state,
    [field]: updater(state[field]),
  }),
};

/**
 * useFields hook. takes initial fields values.
 * works with useReducer
 * Returns fields values and callbacks to change field.
 * handleChange to use with standard inputs (set field value by `name` attribute)
 * setField to manually set field value for specific cases
 * updateField to apply given pure function to field
 * @param initialFields
 * @param eventType
 * @returns {{handleChange: function, setField: function, setFields: function, values: object}}
 */
export function useFields(initialFields = {}, eventType = DOM_EVENT) {
  const [values, dispatch] = useReducer(
    createReducer({}, fieldsReducerHandlers),
    initialFields
  );
  const setField = (field, value = '') =>
    dispatch({ type: SET_FIELD, field, value });
  const updateField = (field, updater) =>
    dispatch({ type: UPDATE_FIELD, field, updater });
  const setFields = (payload) => dispatch({ type: SET_FIELDS, payload });
  const handleChange = ({ target, detail }) => {
    const field = target.name || target.id;
    const value = eventType === CUSTOM_EVENT ? detail : target.value;
    if (!field) {
      throw Error(`provide field 'name' or 'id' attribute to use form hook`);
    }
    setField(field, value || parseInt(value, 10) === 0 ? value : '');
  };
  return {
    values,
    setField: useCallback(setField, []),
    setFields: useCallback(setFields, []),
    updateField: useCallback(updateField, []),
    handleChange: useCallback(handleChange, []),
  };
}
