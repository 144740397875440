import React, { memo } from 'react';
import PropTypes from 'prop-types';

import PrivateRoute from 'components/common/PrivateRoute';
import Navbar from 'containers/common/Navbar';
import { useNavbarLinks } from 'hooks/useNavbarLinks';
import ProjectMenu from 'containers/projects/ProjectMenu';

/**
 * Layout for project pages
 * A separate layer is required, in order to display an additional menu and modified navbar.
 * @param isAuthorized
 * @param Component
 * @param location
 * @param restProps
 * @returns {jsx}
 */
const ProjectLayout = ({
  isAuthorized,
  component: Component,
  location,
  ...restProps
}) => {
  const links = useNavbarLinks(location.pathname);

  return (
    <PrivateRoute hasAccess={isAuthorized} location={location} {...restProps}>
      <div>
        <Navbar links={links} projectMenu={<ProjectMenu />} />
        <Component />
      </div>
    </PrivateRoute>
  );
};

ProjectLayout.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
};

export default memo(ProjectLayout);
