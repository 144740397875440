import React, { useCallback } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import BaseDataTable from 'components/common/BaseDataTable/index';
import TaskButton from 'components/common/TaskButton/index';
import { downloadTask, runPointTask } from 'ducks/points/actions';
import { EXPORT_STATS_INFORMATIONS_COLUMNS } from 'constants/statistics';
import {
  EXCEL_FORMAT,
  EXPORT_PROCESS_TYPE_SEPARATOR,
  MATLAB_FORMAT,
} from 'constants/common';

import { useStyles } from './styles';

const ExportAllTimeSeriesCharts = ({ annualData, statsIds }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { data, title, point } = annualData;
  const pointId = Number(point.id);

  const buildStatId = useCallback(
    (statFormat) => `${statsIds}${EXPORT_PROCESS_TYPE_SEPARATOR}${statFormat}`,
    [statsIds]
  );

  const runExport = useCallback(
    ({ currentTarget }) => {
      const statsIdsFormat = currentTarget.getAttribute('data-stats-ids');
      dispatch(runPointTask(pointId, statsIdsFormat));
    },
    [dispatch, pointId]
  );

  const downloadExport = useCallback(
    (taskId) => {
      dispatch(downloadTask(pointId, taskId));
    },
    [dispatch, pointId]
  );

  return (
    <>
      <Typography variant="subtitle1" className={classes.title}>
        {title}
      </Typography>
      <hr />
      <BaseDataTable
        itemKey="field"
        data={data}
        columns={EXPORT_STATS_INFORMATIONS_COLUMNS}
        className={classes.table}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        className={classes.buttonsContainer}
      >
        <Grid item>
          <TaskButton
            statsIds={buildStatId(EXCEL_FORMAT)}
            title={EXCEL_FORMAT}
            pointId={pointId}
            handleOnRun={runExport}
            handleOnDownload={downloadExport}
          />
        </Grid>
        <Grid item>
          <TaskButton
            statsIds={buildStatId(MATLAB_FORMAT)}
            title={MATLAB_FORMAT}
            pointId={pointId}
            handleOnRun={runExport}
            handleOnDownload={downloadExport}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ExportAllTimeSeriesCharts;
