import React from 'react';

import UsagePageComponent from 'components/usage/UsagePage';

/**
 * UsagePage container
 * Just use to display the component
 */
const UsagePage = () => <UsagePageComponent />;

export default UsagePage;
