import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import { generatePath, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { Grid, Box } from '@material-ui/core';

import { ROUTES } from 'constants/routes';
import SimpleTable from 'components/common/SimpleTable';
import { Loader } from 'components/common/Loader';
import SimpleLinkRow from 'components/usage/SimpleLinkRow';

import { useStyles } from './styles';

/** Detail offer informations
 * @param { object } offer - The offer to display
 */
const DetailOffer = ({ offer }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const redirect = useCallback(() => {
    history.goBack();
  }, [history]);

  if (!offer) {
    return <Loader center justifyCenter />;
  }

  return (
    <Paper elevation={1} className={classes.container}>
      <Button variant="contained" onClick={redirect} className={classes.button}>
        Go back
      </Button>
      <Grid className={classes.informations}>
        <Grid className={classes.lines}>
          <Box>{t('usageAnalysis.detail.name', { type: 'offer' })}:</Box>
          <Box>{t('usageAnalysis.detail.config', { type: 'offer' })}:</Box>
          <Box>{t('usageAnalysis.detail.offer.canReport')}:</Box>
          <Box>{t('usageAnalysis.detail.offer.canDowntime')}:</Box>
          <Box>{t('usageAnalysis.detail.offer.hasMap')}:</Box>
          <Box>{t('usageAnalysis.detail.offer.isUnlimited')}:</Box>
        </Grid>
        <Grid className={classes.lines}>
          <Box>
            <b>{offer.name}</b>
          </Box>
          <Box>
            <b>{offer.projectTemplate}</b>
          </Box>
          <Box>
            <b>{offer.canRunDowntime ? 'Yes' : 'No'}</b>
          </Box>
          <Box>
            <b>{offer.canBuildReport ? 'Yes' : 'No'}</b>
          </Box>
          <Box>
            <b>{offer.hasMaps ? 'Yes' : 'No'}</b>
          </Box>
          <Box>
            <b>{offer.isUnlimited ? 'Yes' : 'No'}</b>
          </Box>
        </Grid>
      </Grid>
      {offer.customers && offer.customers.length > 0 && (
        <Grid className={classes.tables}>
          <h3>
            {t('usageAnalysis.detail.listIntro', { type: 'customers' })}{' '}
            {offer.name}
          </h3>
          <Paper elevation={2} className={classes.table}>
            <SimpleTable
              size="small"
              aria-label="a dense table"
              header={
                <TableRow>
                  <TableCell>name</TableCell>
                </TableRow>
              }
            >
              {offer.customers.map((customer) => (
                <SimpleLinkRow
                  key={`customer-id-${customer.id}`}
                  to={generatePath(ROUTES.usageCustomerDetail, {
                    id: customer.id,
                  })}
                >
                  <TableCell>{customer.name}</TableCell>
                </SimpleLinkRow>
              ))}
            </SimpleTable>
          </Paper>
        </Grid>
      )}
      {offer.zones && offer.zones.length > 0 && (
        <Grid className={classes.tables}>
          <h3>
            {t('usageAnalysis.detail.listIntro', { type: 'zones' })}{' '}
            {offer.name}
          </h3>
          <Paper elevation={2} className={classes.table}>
            <SimpleTable
              size="small"
              aria-label="a dense table"
              header={
                <TableRow>
                  <TableCell>name</TableCell>
                  <TableCell>active</TableCell>
                  <TableCell>demo</TableCell>
                  <TableCell>private</TableCell>
                  <TableCell>world</TableCell>
                </TableRow>
              }
            >
              {offer.zones.map((zone) => (
                <TableRow key={`offer-id-${zone.id}`}>
                  <TableCell>{zone.name}</TableCell>
                  <TableCell>{zone.isActive ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{zone.isDemo ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{zone.isPrivate ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{zone.isWorld ? 'Yes' : 'No'}</TableCell>
                </TableRow>
              ))}
            </SimpleTable>
          </Paper>
        </Grid>
      )}
    </Paper>
  );
};

DetailOffer.propTypes = {
  offer: PropTypes.object,
};

export default memo(DetailOffer);
