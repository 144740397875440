import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  row: {
    color: palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      color: palette.grey.middle,
      backgroundColor: palette.grey.lightBlue,
    },
  },
}));
