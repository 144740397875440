import React, { memo } from 'react';
import PropTypes from 'prop-types';

import GridNet from 'components/common/GridNet';

import OfferCard from '../OfferCard';

/**
 * OffersList - Grid list with offers card
 * @param {Array} offers
 * @param {Function} onSelect
 * @returns {jsx}
 */
const OffersList = ({ offers, onSelect }) => (
  <GridNet spacing={3} columnsNumber={3} component="section">
    {offers.map((offer) => (
      <OfferCard {...offer} key={offer.id} onClick={onSelect} />
    ))}
  </GridNet>
);

OffersList.propTypes = {
  offers: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default memo(OffersList);
