import React, { memo } from 'react';
import PropTypes from 'prop-types';

import PrivateRoute from 'components/common/PrivateRoute';

/**
 * Layout for LoaderPage. There is no NavBar or other tings, only Loader.
 * @param {Boolean} isAuthorized
 * @param {React.Component} Component
 * @param {Object} location
 * @param {Object} restProps
 * @returns {jsx}
 */
const LoaderLayout = ({
  isAuthorized,
  component: Component,
  location,
  ...restProps
}) => (
  <PrivateRoute hasAccess={isAuthorized} location={location} {...restProps}>
    <Component />
  </PrivateRoute>
);

LoaderLayout.propTypes = {
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isAuthorized: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
};

export default memo(LoaderLayout);
