import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ManageGeometries from 'components/geometries/ManageGeometries';
import { selectGeometriesByProjectId } from 'ducks/geometries/selectors';
import {
  deleteGeometry,
  uploadGeometry,
  updateGeometry,
  updateLayer,
} from 'ducks/geometries/actions';

/**
 * Container wit geometries list and upload button
 * @param {Number} projectId
 * @param {Function} onCancel
 * @return {jsx}
 */
const ManageGeometriesContainer = ({ projectId, onCancel }) => {
  const dispatch = useDispatch();
  const geometries = useSelector((state) =>
    selectGeometriesByProjectId(state, projectId)
  );

  const deleteGeometryHandler = useCallback(
    (geometryId) => dispatch(deleteGeometry({ projectId, geometryId })),
    [projectId, dispatch]
  );

  const uploadGeometryHandler = useCallback(
    (file) => dispatch(uploadGeometry({ file, projectId })),
    [projectId, dispatch]
  );

  const updateGeometryHandler = useCallback(
    (geometryId, values) => {
      dispatch(updateGeometry({ projectId, geometryId, values }));
    },
    [projectId, dispatch]
  );

  const updateLayerHandler = useCallback(
    (geometryId, layerId, values) => {
      dispatch(updateLayer({ projectId, geometryId, layerId }, values));
    },
    [projectId, dispatch]
  );

  return (
    <ManageGeometries
      geometries={geometries}
      onUpload={uploadGeometryHandler}
      onDelete={deleteGeometryHandler}
      onUpdateGeometry={updateGeometryHandler}
      onUpdateLayer={updateLayerHandler}
      onCancel={onCancel}
    />
  );
};

ManageGeometriesContainer.propTypes = {
  projectId: PropTypes.number,
  onCancel: PropTypes.func,
};

export default memo(ManageGeometriesContainer);
