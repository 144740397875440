import React, { memo, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Popup } from 'leaflet';

import { useMapPopupHandlers } from 'hooks/leaflet/useMapPopupHandlers';
import { EMPTY_FUNCTION } from 'constants/common';
import { injectOptionsToElement } from 'helpers/map';
import { useMapEvents } from 'hooks/leaflet/useMapEvents';
import { useExpensiveRef } from 'hooks/useExpensiveRef';

import './styles.scss';

/**
 * Map popup component. Renders itself to portal in leaflet popup.
 * @param {Object} leafletElement
 * @param {Number} yOffset
 * @param {Number} xOffset
 * @param {Boolean} isOpen
 * @param {Boolean} keepAlive
 * @param {Function} onOpen
 * @param {Function} onClose
 * @param {String} className
 * @param {String} maxWidth
 * @param {String} maxWidth
 * @param {React.ReactNode} children
 */
export const MapPopup = ({
  leafletElement,
  children,
  className,
  maxWidth,
  yOffset,
  xOffset,
  isOpen,
  keepAlive,
  onOpen,
  onClose,
}) => {
  const popupContentRef = useRef();
  const popup = useExpensiveRef(
    () => new Popup({ offset: [xOffset, yOffset] }, leafletElement)
  );
  const { onPopupOpen, onPopupClose } = useMapPopupHandlers({
    popup: popup.current,
    onOpen,
    onClose,
  });
  const { _contentNode: popupContainer } = popup.current;

  useEffect(() => {
    if (!leafletElement) {
      return;
    }

    leafletElement.bindPopup(popup.current);
    return () => {
      leafletElement.closePopup();
      leafletElement.unbindPopup();
    };
  }, [leafletElement, popup]);

  useMapEvents(
    {
      element: leafletElement,
      events: { popupopen: onPopupOpen, popupclose: onPopupClose },
    },
    [onPopupOpen, onPopupClose]
  );

  useEffect(() => {
    if (!popupContentRef.current || !popupContainer) {
      return;
    }
    const offset = [xOffset, yOffset];
    injectOptionsToElement(popup.current, {
      offset,
      className,
      maxWidth,
    });
    popup.current.update();
  }, [
    popup,
    popupContentRef,
    popupContainer,
    xOffset,
    yOffset,
    className,
    maxWidth,
    children,
  ]);

  useEffect(() => {
    if (leafletElement && isOpen) {
      const timeout = setTimeout(() => leafletElement.openPopup(), 0);
      return () => clearTimeout(timeout);
    }
  }, [leafletElement, isOpen]);

  return popupContainer && (keepAlive || popup.current.isOpen())
    ? ReactDOM.createPortal(
        <div ref={popupContentRef}>{children}</div>,
        popupContainer
      )
    : null;
};

MapPopup.propTypes = {
  leafletElement: PropTypes.object.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  keepAlive: PropTypes.bool,
  maxWidth: PropTypes.string,
  yOffset: PropTypes.number,
  xOffset: PropTypes.number,
};

MapPopup.defaultProps = {
  children: '',
  maxWidth: '100%',
  onOpen: EMPTY_FUNCTION,
  onClose: EMPTY_FUNCTION,
  yOffset: 40,
  xOffset: -5,
  keepAlive: false,
  isOpen: false,
};

export default memo(MapPopup);
