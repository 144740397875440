import {
  USER_LOGIN_REQUEST,
  USER_REQUEST_ERROR,
  FETCH_CURRENT_USER,
  REMOVE_USER_REQUEST,
  REMOVE_USER_SUCCESS,
  REMOVE_USER_ERROR,
  USER_LOGIN_REQUEST_SUCCESS,
  FETCH_CURRENT_USER_SUCCESS,
  USER_LOGOUT,
  INVITE_USER_REQUEST,
  INVITE_USER_ERROR,
  INVITE_USER_SUCCESS,
  CHANGE_USER_SETTINGS,
  GET_USER_SETTINGS,
  PUT_USER_SETTINGS,
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_REQUEST_SUCCESS,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_REQUEST_SUCCESS,
  EDIT_USER_PROFILE_REQUEST,
  EDIT_USER_PROFILE_REQUEST_ERROR,
  EDIT_USER_PROFILE_REQUEST_SUCCESS,
  RESEND_USER_INVITATION_REQUEST,
  RESEND_USER_INVITATION_REQUEST_SUCCESS,
  RESEND_USER_INVITATION_REQUEST_ERROR,
  REVOKE_USER_INVITATION_REQUEST,
  REVOKE_USER_INVITATION_REQUEST_SUCCESS,
  REVOKE_USER_INVITATION_REQUEST_ERROR,
  CLEAR_USER_ERROR,
  USER_RESET_PASSWORD_CONFIRM_REQUEST,
  USER_RESET_PASSWORD_CONFIRM_REQUEST_SUCCESS,
  CHECK_IS_REGISTRATION_PENDING,
  ACTIVATE_REGISTRATION_REQUEST,
  CHECK_IS_REGISTRATION_PENDING_SUCCESS,
  CHECK_IS_REGISTRATION_PENDING_ERROR,
} from './types';

export const userLogin = (values) => ({
  type: USER_LOGIN_REQUEST,
  values,
});

export const userLoginSuccess = () => ({
  type: USER_LOGIN_REQUEST_SUCCESS,
});

export const userRequestError = (error) => ({
  type: USER_REQUEST_ERROR,
  error: { auth: error.message },
});

export const removeUser = (id) => ({
  type: REMOVE_USER_REQUEST,
  data: { id },
});

export const removeUserSucces = (data) => ({
  type: REMOVE_USER_SUCCESS,
  data,
});

export const removeUserError = (error) => ({
  type: REMOVE_USER_ERROR,
  error,
});

export const getCurrentUser = () => ({
  type: FETCH_CURRENT_USER,
});

export const getCurrentUserSuccess = (user) => ({
  type: FETCH_CURRENT_USER_SUCCESS,
  user,
});

export const userLogout = () => ({
  type: USER_LOGOUT,
});

export const inviteUserRequest = ({ email, isStaff }) => ({
  type: INVITE_USER_REQUEST,
  data: {
    email,
    isStaff,
  },
});

export const inviteUserError = (error) => ({
  type: INVITE_USER_ERROR,
  error,
});

export const inviteUserSuccess = ({ id, email }) => ({
  type: INVITE_USER_SUCCESS,
  id,
  email,
});

export const getUserSettings = () => ({
  type: GET_USER_SETTINGS,
});

export const changeUserSettings = (value) => ({
  type: CHANGE_USER_SETTINGS,
  value,
});

export const putUserSettings = (value) => ({
  type: PUT_USER_SETTINGS,
  value,
});

export const userSignup = (values) => ({
  type: USER_SIGNUP_REQUEST,
  values,
});

export const userSignupSuccess = () => ({
  type: USER_SIGNUP_REQUEST_SUCCESS,
});

export const checkIsRegistrationPending = (data) => ({
  type: CHECK_IS_REGISTRATION_PENDING,
  data,
});

export const checkIsRegistrationPendingSuccess = () => ({
  type: CHECK_IS_REGISTRATION_PENDING_SUCCESS,
});

export const checkIsRegistrationPendingError = (error) => ({
  type: CHECK_IS_REGISTRATION_PENDING_ERROR,
  error: error.message,
});

export const activateRegistrationRequest = (values) => ({
  type: ACTIVATE_REGISTRATION_REQUEST,
  values,
});

export const userResetPassword = (values) => ({
  type: USER_RESET_PASSWORD_REQUEST,
  values,
});

export const userResetPasswordSuccess = () => ({
  type: USER_RESET_PASSWORD_REQUEST_SUCCESS,
});

export const userResetPasswordConfirm = (values) => ({
  type: USER_RESET_PASSWORD_CONFIRM_REQUEST,
  values,
});

export const userResetPasswordConfirmSuccess = () => ({
  type: USER_RESET_PASSWORD_CONFIRM_REQUEST_SUCCESS,
});

export const editUserProfileRequest = (values) => ({
  type: EDIT_USER_PROFILE_REQUEST,
  values,
});

export const editUserProfileRequestSuccess = (user) => ({
  type: EDIT_USER_PROFILE_REQUEST_SUCCESS,
  user,
});

export const editUserProfileRequestError = (error) => ({
  type: EDIT_USER_PROFILE_REQUEST_ERROR,
  error,
});

export const resendUserInvitationRequest = (userId) => ({
  type: RESEND_USER_INVITATION_REQUEST,
  userId,
});

export const resendUserInvitationRequestSuccess = (message) => ({
  type: RESEND_USER_INVITATION_REQUEST_SUCCESS,
  message,
});

export const resendUserInvitationRequestError = (error) => ({
  type: RESEND_USER_INVITATION_REQUEST_ERROR,
  error,
});

export const revokeUserInvitationRequest = (userId) => ({
  type: REVOKE_USER_INVITATION_REQUEST,
  userId,
});

export const revokeUserInvitationRequestSuccess = ({ message, userId }) => ({
  type: REVOKE_USER_INVITATION_REQUEST_SUCCESS,
  message,
  userId,
});

export const revokeUserInvitationRequestError = (error) => ({
  type: REVOKE_USER_INVITATION_REQUEST_ERROR,
  error,
});

export const clearUserError = () => ({
  type: CLEAR_USER_ERROR,
});
