import { ADMIN_USER_LABEL } from 'constants/tracker';

import { SEND_TRACKING_DATA } from '../types';

export const trackUserInvitation = (data) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: ADMIN_USER_LABEL,
  gtmAction: 'Invite user to Metocean',
  data,
});

export const trackProjectCollaboratorChange = (data) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: ADMIN_USER_LABEL,
  gtmAction: 'Invite user to project',
  data,
});
