import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ProjectReportListComponent from 'components/projects/ProjectReportList';
import { selectCommentsByReportIds } from 'ducks/comments/selectors';
import {
  selectCollaboratorsById,
  selectCurrentUser,
} from 'ducks/user/selectors';
import { useModal } from 'hooks/useModal';
import { DELETE_REPORT_MODAL } from 'constants/modals';
import { postComment } from 'ducks/comments/actions';
import { selectReportContentTypeId } from 'ducks/contentTypes/selectors';
import { requestContentTypesIfNeed } from 'ducks/contentTypes/actions';

/**
 * Container to display list of created reports on project
 * On delete button click displays modal with confirmation
 */
const ProjectReportList = ({ reports, allowDeleteReport, allowAddComment }) => {
  const { openModal } = useModal();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestContentTypesIfNeed());
  }, [dispatch]);

  const collaborators = useSelector(selectCollaboratorsById);
  const comments = useSelector(selectCommentsByReportIds);
  const user = useSelector(selectCurrentUser);
  const reportTypeId = useSelector(selectReportContentTypeId);

  const handleDelete = useCallback(
    ({ id, name, project }) => () =>
      openModal(DELETE_REPORT_MODAL, {
        reportId: id,
        projectId: project,
        reportName: name,
      }),
    [openModal]
  );

  const handleCreateComment = useCallback(
    ({ message, reportId }) =>
      dispatch(
        postComment({ message, reportId, reportTypeId, userId: user.id })
      ),
    [dispatch, user, reportTypeId]
  );

  return (
    <ProjectReportListComponent
      reports={reports}
      comments={comments.data}
      collaborators={collaborators}
      onReportDelete={handleDelete}
      onCommentCreate={handleCreateComment}
      allowDeleteReport={allowDeleteReport}
      allowAddComment={allowAddComment}
    />
  );
};

ProjectReportList.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      user: PropTypes.number,
      name: PropTypes.string,
      computation: PropTypes.number,
      status: PropTypes.string,
      taskId: PropTypes.string,
      document: PropTypes.any,
      generatedAt: PropTypes.string,
      startedAt: PropTypes.string,
      project: PropTypes.number,
    })
  ).isRequired,
  allowDeleteReport: PropTypes.bool.isRequired,
  allowAddComment: PropTypes.bool.isRequired,
};

export default memo(ProjectReportList);
