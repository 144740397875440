import React, { memo } from 'react';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { API_URL } from 'common/config';
import OfferTable from 'components/offers/OfferTable';

import { useStyles } from './styles';

/**
 * Local component for use in MyPlansPage
 */
const PlansRow = ({ title, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      <Typography className={classes.boldText}>{title}</Typography>
      <Typography>:&nbsp;{value}</Typography>
    </div>
  );
};

/**
 * MyPlansPage
 * Displays customer information and offer information
 * @param accountInfo - customer information
 * @param dateOfSubscriptionEnd - date of customer subscription end
 * @param subtitleStatus - subscription status
 * @param offers - array of offers(plans)
 * @param credits - used credits
 * @param totalCredits - total credits amount
 * @param packageName - package name
 * @param numberOfPendingAccounts - number of pending invitations
 * @param numberOfActivatedAccounts - number of activated accounts
 */
const MyPlansPage = ({
  accountInfo,
  dateOfSubscriptionEnd,
  subtitleStatus,
  offers,
  credits,
  packageName,
  totalCredits,
  numberOfPendingAccounts,
  numberOfActivatedAccounts,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { name, image, collaborators } = accountInfo;
  const subtitleClassNames = {
    success: classes.successSubtitle,
    danger: classes.dangerSubtitle,
    default: '',
  };

  return (
    <div className={classes.mainContainer}>
      <Typography variant="h5" className={classes.title}>
        {name}
      </Typography>
      <div className={classes.container}>
        <div className={classes.textBlock}>
          <PlansRow
            title={t('offers.numberOfActivePlans')}
            value={offers.length}
          />
          {dateOfSubscriptionEnd && (
            <PlansRow
              title={t('offers.endOfAccess')}
              value={dateOfSubscriptionEnd}
            />
          )}
          <PlansRow
            title={t('offers.people')}
            value={t('offers.numberOfPeople', {
              numberOfPendingAccounts,
              numberOfActivatedAccounts,
              totalUsers: collaborators.length,
            })}
          />
          <PlansRow
            title={t('offers.creditsUsed')}
            value={t('offers.creditsUsedOfTotal', {
              credits,
              totalCredits,
              packageName,
            })}
          />
          <Typography
            className={classNames(
              subtitleClassNames[subtitleStatus.textColor],
              classes.boldText
            )}
          >
            {subtitleStatus.status}
          </Typography>
        </div>
        <img src={`${API_URL}${image}`} className={classes.logo} alt="logo" />
      </div>
      {offers.map((offer) => (
        <div key={offer.id}>
          <Typography
            variant="h6"
            className={classNames(classes.boldText, {
              [classes.disabledTitle]: offer.expired,
            })}
          >
            {offer.name}
          </Typography>
          <OfferTable offer={offer} />
        </div>
      ))}
    </div>
  );
};

MyPlansPage.propTypes = {
  accountInfo: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    pack: PropTypes.object,
    credits: PropTypes.number,
    totalCredits: PropTypes.number,
    collaborators: PropTypes.array,
    totalUsers: PropTypes.number,
  }),
  subtitleStatus: PropTypes.shape({
    status: PropTypes.string,
    textColor: PropTypes.string,
  }).isRequired,
  dateOfSubscriptionEnd: PropTypes.string.isRequired,
  offers: PropTypes.arrayOf(PropTypes.object),
  numberOfPendingAccounts: PropTypes.number,
  numberOfActivatedAccounts: PropTypes.number,
};

export default memo(MyPlansPage);
