import React, { useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import GrantAccessForCollaboratorContainer from 'containers/collaborators/GrantAccessForCollaborator';
import BaseModal from 'components/common/BaseModal';
import { closeModal } from 'ducks/modals/actions';
import {
  selectProjectsCollection,
  selectProjectsIdsByCollaboratorId,
} from 'ducks/projects/selectors';
import { useDependentsFilters } from 'hooks/useDependentsFilters';
import { hasSubString } from 'helpers/filters';
import { useQuery } from 'hooks/useQuery';
import SearchInput from 'components/common/SearchInput';

import { useStyles } from './styles';

const projectsFilters = {
  search: ({ name, zone }, expected) =>
    hasSubString(name, expected) || hasSubString(zone.name, expected),
};

/**
 * AddUserToProjectModal container
 * contains logic and data for adding user to project
 * modal has search by project name/ project zone and list of projects
 * @param id - modal id
 * @param user - user add in project
 */
const AddUserToProjectModal = ({ id, user }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const { id: userId, username } = user;
  const projectsCollection = useSelector(selectProjectsCollection);
  const projectsAvailableToUser = useSelector((state) =>
    selectProjectsIdsByCollaboratorId(state, userId)
  );

  const {
    query: { search },
    mergeQuery: mergeProjectQuery,
  } = useQuery({
    search: '',
  });
  const filteredProjects = useDependentsFilters({
    entities: projectsCollection,
    filters: projectsFilters,
    expectedValues: { search },
  });

  const onClose = useCallback(() => {
    dispatch(closeModal({ id }));
  }, [dispatch, id]);
  const searchChangeHandler = useCallback(
    ({ target }) => mergeProjectQuery({ search: target.value }),
    [mergeProjectQuery]
  );

  return (
    <BaseModal
      onClose={onClose}
      title={t('collaborators.addUserToProject', { username })}
      modalClass={classes.container}
    >
      <SearchInput value={search} handleChange={searchChangeHandler} />
      <GrantAccessForCollaboratorContainer
        id={id}
        collaboratorId={userId}
        filteredProjects={filteredProjects}
        projectsAvailableToUser={projectsAvailableToUser}
        user={user}
      />
    </BaseModal>
  );
};

AddUserToProjectModal.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

export default memo(AddUserToProjectModal);
