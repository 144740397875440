import React, { memo } from 'react';
import Grid from '@material-ui/core/Grid';
import PropsTypes from 'prop-types';

import { useStyles } from './styles';

const getBoxStyleByColor = (color) => ({
  backgroundColor: color,
});

/**
 * CustomLegend component. Renders given legends with given colors
 * @param { number } width - container width
 * @param { string } title - legend block title
 * @param { array } legends - legend texts
 * @param { array } colors - colors set for legends
 * @param { number } colorBoxSize - color square box size in px
 * @returns { JSX }
 */
const CustomLegend = ({ title, legends, colors, colorBoxSize, width }) => {
  const classes = useStyles({ colorBoxSize, width });

  return (
    <div className={classes.container}>
      {title && <div className={classes.title}>{title}</div>}
      <Grid justifyContent="center" container>
        <Grid item align="center">
          {legends.map((legend, index) => {
            const color = colors[index];
            const boxStyle = getBoxStyleByColor(color);

            return (
              <Grid key={color} container>
                <Grid item>
                  <div className={classes.colorBox} style={boxStyle} />
                </Grid>
                <Grid item className={classes.legend}>
                  {legend}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

CustomLegend.propTypes = {
  width: PropsTypes.oneOfType([PropsTypes.number, PropsTypes.string]),
  title: PropsTypes.string,
  colorBoxSize: PropsTypes.number,
  legends: PropsTypes.arrayOf(PropsTypes.string).isRequired,
  colors: PropsTypes.arrayOf(PropsTypes.string).isRequired,
};

CustomLegend.defaultProps = {
  title: '',
  colorBoxSize: 12,
  width: 200,
};

export default memo(CustomLegend);
