/* eslint-disable */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { appReducer } from 'ducks/app';
import { modalsReducer } from 'ducks/modals';
import { projectsReducer } from 'ducks/projects';
import { zonesReducer } from 'ducks/zones/reducer';
import { userReducer } from 'ducks/user';
import { offersReducer } from 'ducks/offers';
import { pointsReducer } from 'ducks/points';
import { stat2dReducer } from 'ducks/stat2d';
import { geometriesReducer } from 'ducks/geometries';
import { buoysReducer } from 'ducks/buoys';
import { staticPagesReducer } from 'ducks/staticPages';
import { reportsReducer } from 'ducks/reports';
import { demoReducer } from 'ducks/demo';
import { productHistoryReducer } from 'ducks/productHistory';
import { satelliteComparisonsReducer } from 'ducks/satelliteComparisons';
import { planningsReducer } from 'ducks/plannings';
import { commentsReducer } from 'ducks/comments';
import { contentTypesReducer } from 'ducks/contentTypes';
import { usageReducer } from 'ducks/usage';
import { marineContractorsReducer } from 'ducks/marineContractors';
import { keycloakReducer } from 'ducks/keycloak';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    modals: modalsReducer,
    projects: projectsReducer,
    zones: zonesReducer,
    user: userReducer,
    offers: offersReducer,
    points: pointsReducer,
    stat2d: stat2dReducer,
    geometries: geometriesReducer,
    buoys: buoysReducer,
    staticPages: staticPagesReducer,
    reports: reportsReducer,
    demo: demoReducer,
    productHistory: productHistoryReducer,
    satelliteComparisons: satelliteComparisonsReducer,
    plannings: planningsReducer,
    comments: commentsReducer,
    contentTypes: contentTypesReducer,
    usage: usageReducer,
    marineContractors: marineContractorsReducer,
    keycloak: keycloakReducer,
  });
