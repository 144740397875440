import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import BaseModal from 'components/common/BaseModal';
import InviteCollaboratorForm from 'components/forms/InviteCollaboratorForm';

import { useStyles } from './styles';

/**
 * InviteCollaboratorModal component
 * Modal for adding a collaborator
 * @param {Function} onClose -  handler for close modal button
 * @param {Function} onSuccess -  handler for adding a collaborator
 * @param {Object} registerError - error from store
 */
const InviteCollaboratorModal = ({ onSuccess, onClose, registerError }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <BaseModal
      onClose={onClose}
      title={t('projects.invite.modalTitle')}
      modalClass={classes.container}
    >
      <InviteCollaboratorForm
        onSuccess={onSuccess}
        registerError={registerError}
      />
    </BaseModal>
  );
};

InviteCollaboratorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  registerError: PropTypes.object,
};

InviteCollaboratorModal.defaultProps = {
  registerError: {},
};

export default React.memo(InviteCollaboratorModal);
