import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import StatsModal from 'components/modals/StatsModal';
import { useModal } from 'hooks/useModal';
import { NOTIFICATION_MODAL } from 'constants/modals';
import SilentBoundary from 'components/common/SilentBoundary';

/**
 * StatsModalContainer - container for StatsModal
 * @param { string } id - id of modal
 * @param { string } projectId - project id
 * @param { array } statsIds - ids of stats to request
 * @param { string } helpLink - link to help page with info about current statistics
 */
const StatsModalContainer = ({ id, projectId, statsIds, helpLink }) => {
  const { t } = useTranslation();
  const { onClose, openModal } = useModal(id);

  const onError = useCallback(
    (errorMessage) => {
      onClose();
      openModal(NOTIFICATION_MODAL, {
        message: errorMessage || t('errors.common'),
        autoHideDuration: null,
      });
    },
    [openModal, onClose, t]
  );

  return (
    <SilentBoundary onError={onError}>
      <StatsModal
        onError={onError}
        closeModal={onClose}
        projectId={projectId}
        statsIds={statsIds}
        helpLink={helpLink}
      />
    </SilentBoundary>
  );
};

StatsModalContainer.propTypes = {
  id: PropTypes.string.isRequired,
  helpLink: PropTypes.string,
  statsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default React.memo(StatsModalContainer);
