import React from 'react';
import PropTypes from 'prop-types';

import SelectablePointFeatureGroup from 'components/maps/SelectablePointFeatureGroup';
import EditPointPopup from 'components/popups/EditPointPopup';

/**
 * EditPointFeatureGroup - Feature group with zone and new marker logic
 * @param {Object} project
 * @param {Array} selectedMarkers
 * @param {Function} onSelectMarker
 * @param {Array} selectedPoints
 * @param {Boolean} noPointsCompute
 * @returns {JSX}
 */
const EditPointFeatureGroup = ({
  project,
  selectedMarkers,
  onSelectMarker,
  selectedPoints,
  noPointsCompute,
}) => (
  <SelectablePointFeatureGroup
    points={
      selectedPoints !== null
        ? project?.points.filter((pp) =>
            selectedPoints.map((p) => p.id).includes(pp.id)
          )
        : project.points
    }
    selectedMarkers={selectedMarkers}
    selectMarker={onSelectMarker}
    noPointsCompute={noPointsCompute}
  >
    <EditPointPopup />
  </SelectablePointFeatureGroup>
);

EditPointFeatureGroup.propTypes = {
  project: PropTypes.shape({
    zone: PropTypes.object.isRequired,
  }).isRequired,
  selectedMarkers: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelectMarker: PropTypes.func,
  selectedPoints: PropTypes.array,
  noPointsCompute: PropTypes.bool,
};

EditPointFeatureGroup.defaultProps = {
  selectedPoints: null,
  noPointsCompute: false,
};

export default React.memo(EditPointFeatureGroup);
