import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  selectProjectById,
  selectProjectsIsSuccess,
} from 'ducks/projects/selectors';
import ProjectCollaboratorsList from 'components/projects/ProjectCollaboratorsList';
import { useModal } from 'hooks/useModal';
import { GRANT_ACCESS_TO_PROJECT_MODAL } from 'constants/modals';
import { selectCurrentUserIsManager } from 'ducks/user/selectors';

/**
 * ProjectCollaborators render list with collaborators by project id
 * @returns {jsx}
 * @constructor
 */
const ProjectCollaborators = () => {
  const { openModal } = useModal();
  const { id: projectId } = useParams();

  const isSuccess = useSelector(selectProjectsIsSuccess);
  const isManager = useSelector(selectCurrentUserIsManager);
  const { name } = useSelector((state) => selectProjectById(state, projectId));
  // TODO Modal window with sharing modal should be added
  const onShareProject = useCallback(
    () => openModal(GRANT_ACCESS_TO_PROJECT_MODAL, { projectId, name }),
    [openModal, projectId, name]
  );

  return (
    isSuccess && (
      <ProjectCollaboratorsList
        onShareProject={onShareProject}
        canShareProject={isManager}
      />
    )
  );
};

export default memo(ProjectCollaborators);
