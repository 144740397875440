import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 600,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'end',
  },
  highlightText: {
    fontWeight: 600,
  },
  button: {
    ...theme.buttons.fill.primary,
  },
}));
