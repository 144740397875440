import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import BaseDataTable from 'components/common/BaseDataTable';

import { useStyles } from './styles';

/**
 * Table with visualization data
 * @param {Array} visualizations
 * @return {jsx}
 */
const OverviewOceanConditionsTable = ({ visualizations }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const columns = useMemo(
    () => [
      {
        field: 'layer',
        title: t('points.overview.layer'),
        cell: (layer) => <b>{layer}</b>,
      },
      {
        field: 'variable',
        title: t('points.overview.variable'),
        cell: (variable, { units }) => (
          <b>
            {variable} [{units}]
          </b>
        ),
      },
      {
        field: 'mean',
        title: t('points.overview.mean'),
      },
      {
        field: 'min',
        title: t('points.overview.min'),
      },
      {
        field: 'max',
        title: t('points.overview.max'),
      },
      {
        field: 'median',
        title: t('points.overview.median'),
      },
      {
        field: 'std',
        title: t('points.overview.std'),
      },
    ],
    [t]
  );

  return (
    <>
      <BaseDataTable
        data={visualizations}
        columns={columns}
        itemKey="layer"
        className={classes.overviewTable}
      />
      <i>{t('points.overview.footerText')}</i>
    </>
  );
};

OverviewOceanConditionsTable.propTypes = {
  visualizations: PropTypes.array.isRequired,
};

export default memo(OverviewOceanConditionsTable);
