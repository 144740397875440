import { get, pick } from 'lodash';

import {
  EVA_MAIN_VARIABLES,
  EVA_DATA_PATH,
  EVA_PERIODS,
  EVA_VARIANTS,
  EVA_VARIANT_PARAMS_TO_PICK,
} from 'constants/graphs';
import { INVALID_GRAPH_DATA } from 'constants/errors';
import { camelizeKeys } from 'helpers/camelizer';

/**
 * Returns prepared Extreme Value Analysis table data
 * @param {Object} rawData
 * @returns {{
 *    mainVariable: Object,
 *    periods: Array<number>,
 *    variants: Array<Object>
 * }}
 */
export const getPreparedExtremeValueAnalysisData = (rawData) => {
  const mainVariable = camelizeKeys(rawData[EVA_MAIN_VARIABLES] || null);
  if (!mainVariable) {
    throw Error(INVALID_GRAPH_DATA);
  }
  const data = get(rawData, EVA_DATA_PATH, {});
  const periods = camelizeKeys(data[EVA_PERIODS] || {});
  const rawVariants = data[EVA_VARIANTS] || [];
  const variants = rawVariants.reduce((acc, variant) => {
    acc.push(camelizeKeys(pick(variant, EVA_VARIANT_PARAMS_TO_PICK)));
    return acc;
  }, []);

  return {
    mainVariable,
    periods,
    variants,
  };
};
