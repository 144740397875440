import {
  REQUEST_BUOYS,
  REQUEST_BUOYS_SUCCESS,
  REQUEST_BUOYS_ERROR,
  REQUEST_BUOY_STATS,
  REQUEST_BUOY_STATS_SUCCESS,
  REQUEST_BUOY_STATS_ERROR,
} from './types';

export const requestBuoys = (params = {}) => ({
  type: REQUEST_BUOYS,
  params,
});

export const requestBuoysSuccess = (data) => ({
  type: REQUEST_BUOYS_SUCCESS,
  data,
});

export const requestBuoysError = (error) => ({
  type: REQUEST_BUOYS_ERROR,
  error,
});

export const requestBuoyStats = ({ buoyId, params = {} }) => ({
  type: REQUEST_BUOY_STATS,
  buoyId,
  params,
});

export const requestBuoyStatsSuccess = ({ buoy }) => ({
  type: REQUEST_BUOY_STATS_SUCCESS,
  buoy,
});

export const requestBuoyStatsError = ({ error, buoyId }) => ({
  type: REQUEST_BUOY_STATS_ERROR,
  error,
  buoyId,
});
