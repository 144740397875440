import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  chartWrapper: {
    position: 'relative',
    overflow: 'visible',
  },
  peakRoseOutlierScatter: {
    fill: palette.danger.main,
    stroke: palette.black.main,
    r: 4,
    '&:hover': {
      r: 5,
    },
  },
  peakRosePointScatter: {
    fill: palette.tertiary.main,
    stroke: palette.black.main,
    r: 4,
    '&:hover': {
      r: 5,
    },
  },
  peakRoseLine: {
    '& > path': {
      stroke: palette.blue.main,
      strokeWidth: 2,
      '&:hover': {
        strokeWidth: 3,
      },
    },
  },
}));
