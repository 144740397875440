import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import DowntimeComponent from 'components/projects/Downtime';
import { requestProjectIfNeed } from 'ducks/projects/actions';
import {
  DELETE_PLANNING_MODAL,
  NOTIFICATION_MODAL,
  PLANNING_ERROR_LOG_MODAL,
} from 'constants/modals';
import {
  downloadPlanning,
  requestPlanningsListIfNeed,
} from 'ducks/plannings/actions';
import { selectPlanningsListByProjectId } from 'ducks/plannings/selectors';
import { useModal } from 'hooks/useModal';
import { selectCurrentUser } from 'ducks/user/selectors';
import { uploadPlanningWaitingRequest } from 'ducks/app/actions';
import {
  selectIsFinishedProjectById,
  selectProjectById,
} from 'ducks/projects/selectors';
import { checkIsOfferExpired } from 'helpers/projects';
import { SNACKBAR_WARNING_TYPE } from 'constants/common';

/**
 * Displays Advanced Weather Downtime page
 */
const Downtime = () => {
  const { id: projectId } = useParams();
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const { t } = useTranslation();
  const { isStaff } = useSelector(selectCurrentUser);
  const planningsList = useSelector((state) =>
    selectPlanningsListByProjectId(state, projectId)
  );
  const project = useSelector((state) => selectProjectById(state, projectId));
  const isProjectFinished = useSelector((state) =>
    selectIsFinishedProjectById(state, projectId)
  );
  const isOfferExpired = useMemo(() => checkIsOfferExpired(project), [project]);

  useEffect(() => {
    dispatch(requestProjectIfNeed({ projectId }));
    dispatch(requestPlanningsListIfNeed(projectId));
  }, [dispatch, projectId]);

  const offerExpiredCallback = useCallback(() => {
    if (isOfferExpired) {
      openModal(NOTIFICATION_MODAL, {
        type: SNACKBAR_WARNING_TYPE,
        message: t('projects.AWDOfferExpired'),
      });
    }
  }, [openModal, isOfferExpired, t]);

  const uploadPlanning = useCallback(
    (file) => {
      dispatch(uploadPlanningWaitingRequest({ projectId, file }));
    },
    [dispatch, projectId]
  );

  const deletePlanning = useCallback(
    (planning) => {
      openModal(DELETE_PLANNING_MODAL, {
        planning,
      });
    },
    [openModal]
  );

  const handleDownloadPlanning = useCallback(
    ({ planningId }) => {
      dispatch(downloadPlanning({ planningId, projectId }));
    },
    [dispatch, projectId]
  );

  const showErrorLog = useCallback(
    (errorMessage) => {
      openModal(PLANNING_ERROR_LOG_MODAL, {
        error: errorMessage,
      });
    },
    [openModal]
  );

  return (
    <DowntimeComponent
      planningsList={planningsList}
      deletePlanning={deletePlanning}
      uploadPlanning={uploadPlanning}
      downloadPlanning={handleDownloadPlanning}
      showErrorLog={showErrorLog}
      allowUploadPlanning={isProjectFinished}
      allowDeletePlanning={isStaff && !isOfferExpired}
      isOfferExpired={isOfferExpired}
      offerExpiredCallback={offerExpiredCallback}
    />
  );
};

export default memo(Downtime);
