import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles(({ palette }) => ({
  tabBar: {
    display: 'flex',
    alignItems: 'space-between',
    width: '80%',
    margin: 'auto',
  },
  link: {
    flexGrow: 1,
    flexBasis: 0,
    padding: '20px 10px',
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: alpha(palette.primary.main, 0.3),
    backgroundColor: palette.lightGrey.light,
    borderLeftColor: palette.grey.main,
    borderRightColor: palette.grey.main,
    borderLeft: '1px solid',
    borderRight: '1px solid',
    '&:is(:last-child)': {
      borderLeft: '0px transparent',
    },
    '&:is(:first-child)': {
      borderRight: '0px transparent',
    },
    '&:not(.disabled)': {
      '&.active, &:hover': {
        color: palette.primary.middle,
        backgroundColor: palette.white.main,
      },
    },
  },
  component: {
    padding: '2%',
    backgroundColor: palette.white.main,
  },
}));
