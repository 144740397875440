import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { ProgressLoader } from 'components/common/ProgressLoader/index';
import {
  startUpdateMarineContractorsJob,
  stopUpdateMarineContractorsJob,
} from 'ducks/marineContractors/actions';
import { FINISHED_TASK_STATUS } from 'constants/projects';

import { useStyles } from './styles';

const MCRunningComponent = ({ job }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const value = useMemo(() => {
    if (job.status === FINISHED_TASK_STATUS) {
      return 100;
    }
    return job.taskInfo?.progress || 0;
  }, [job]);

  useEffect(() => {
    dispatch(startUpdateMarineContractorsJob(job));
    return () => {
      dispatch(stopUpdateMarineContractorsJob());
    };
    // eslint-disable-next-line
  }, [])

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <h3 className={classes.title}>{t('marineContractors.run.title')}</h3>
      <Grid item>
        <ProgressLoader
          value={value}
          variant="determinate"
          size={300}
          style={{ top: '45%', fontSize: '20px', color: '#10416C' }}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(MCRunningComponent);
