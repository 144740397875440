import {
  REQUEST_GEOMETRIES,
  REQUEST_GEOMETRIES_SUCCESS,
  REQUEST_GEOMETRIES_ERROR,
  DELETE_GEOMETRY,
  DELETE_GEOMETRY_SUCCESS,
  DELETE_GEOMETRY_ERROR,
  UPLOAD_GEOMETRY,
  UPLOAD_GEOMETRY_SUCCESS,
  UPLOAD_GEOMETRY_ERROR,
  UPDATE_GEOMETRY,
  UPDATE_GEOMETRY_SUCCESS,
  UPDATE_GEOMETRY_ERROR,
  UPDATE_LAYER,
  UPDATE_LAYER_SUCCESS,
  UPDATE_LAYER_ERROR,
} from './types';

export const requestGeometries = (params = {}) => ({
  type: REQUEST_GEOMETRIES,
  params,
});

export const requestGeometriesSuccess = (geometries) => ({
  type: REQUEST_GEOMETRIES_SUCCESS,
  geometries,
});

export const requestGeometriesError = (error) => ({
  type: REQUEST_GEOMETRIES_ERROR,
  error,
});

export const deleteGeometry = ({ geometryId, projectId }) => ({
  type: DELETE_GEOMETRY,
  geometryId,
  projectId,
});

export const deleteGeometrySuccess = ({ geometryId, projectId }) => ({
  type: DELETE_GEOMETRY_SUCCESS,
  projectId,
  geometryId,
});

export const deleteGeometryError = (error) => ({
  type: DELETE_GEOMETRY_ERROR,
  error,
});

export const uploadGeometry = ({ file, projectId }) => ({
  type: UPLOAD_GEOMETRY,
  file,
  projectId,
});

export const uploadGeometrySuccess = ({ geometry, projectId }) => ({
  type: UPLOAD_GEOMETRY_SUCCESS,
  projectId,
  geometry,
});

export const uploadGeometryError = (error) => ({
  type: UPLOAD_GEOMETRY_ERROR,
  error,
});

export const updateGeometry = ({ projectId, geometryId, values }) => ({
  type: UPDATE_GEOMETRY,
  projectId,
  geometryId,
  values,
});

export const updateGeometrySuccess = ({ projectId }, geometry) => ({
  type: UPDATE_GEOMETRY_SUCCESS,
  projectId,
  geometry,
});

export const updateGeometryError = (error) => ({
  type: UPDATE_GEOMETRY_ERROR,
  error,
});

export const updateLayer = ({ projectId, geometryId, layerId }, layer) => ({
  type: UPDATE_LAYER,
  projectId,
  geometryId,
  layerId,
  layer,
});

export const updateLayerSuccess = ({ projectId, geometryId }, layer) => ({
  type: UPDATE_LAYER_SUCCESS,
  projectId,
  geometryId,
  layer,
});

export const updateLayerError = (error) => ({
  type: UPDATE_LAYER_ERROR,
  error,
});
