/* eslint-disable */
import { takeLatest, put, call } from 'redux-saga/effects';

import { postDemoRequest } from 'services/demo';

import { POST_DEMO_FORM } from './types';
import { postDemoFormSuccessAction, postDemoFormErrorAction } from './actions';

function* sagaPostDemoForm({ payload: formValues }) {
  try {
    const response = yield call(postDemoRequest, formValues);
    yield put(postDemoFormSuccessAction(response));
  } catch (error) {
    yield put(postDemoFormErrorAction(error));
  }
}

export default function* demoWatcherSaga() {
  yield takeLatest(POST_DEMO_FORM, sagaPostDemoForm);
}
