import { useRef, useEffect } from 'react';

/**
 * hook to check if the component has been already mounted
 * @return ref
 */
const useIsMounted = () => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return isMounted;
};

export default useIsMounted;
