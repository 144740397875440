import {
  CANCELLED_TASK_STATUS,
  FAILED_TASK_STATUS,
  PENDING_TASK_STATUS,
  RUNNING_TASK_STATUS,
} from 'constants/projects';

/**
 * Return only computed points
 * @param {Array} points
 * @return {Array}
 */
export const getComputedPoints = (points) =>
  points.filter(({ isComputed }) => isComputed);

export const getTasksPoint = (point) => {
  const tasks = [];
  point.tasksPoints.forEach((task) => {
    tasks.push(task);
  });
  return tasks;
};

export const getRunningTasks = (tasks) =>
  tasks.filter((task) => task.status === RUNNING_TASK_STATUS);

export const getEmptyTasks = (point) =>
  point.tasksPoints.filter(
    (task) => task.status === PENDING_TASK_STATUS && task.processType === ''
  );

export const getFailedTasks = (point, processType) =>
  point.tasksPoints.filter(
    (task) =>
      [FAILED_TASK_STATUS, CANCELLED_TASK_STATUS].includes(task.status) &&
      task.processType === processType
  );

export const getTaskByType = (tasksPoint, selectedType) => {
  let taskPoint;
  if (tasksPoint !== undefined) {
    tasksPoint.forEach((task) => {
      if (task.processType === selectedType.id) {
        taskPoint = task;
      }
    });
  }
  return taskPoint;
};

export const getTaskByProcessAndFormat = (tasksPoint, statId, format) => {
  tasksPoint.forEach((task) => {
    const processType = task.processType?.split(',');
    if (
      processType !== undefined &&
      statId === processType[0] &&
      format === processType[1]
    ) {
      return task;
    }
  });
  return {};
};
