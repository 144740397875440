const prefix = '@@usage';

export const REQUEST_CUSTOMERS = `${prefix}/REQUEST_CUSTOMERS`;
export const REQUEST_CUSTOMERS_SUCCESS = `${prefix}/REQUEST_CUSTOMERS_SUCCESS`;
export const REQUEST_CUSTOMERS_ERROR = `${prefix}/REQUEST_CUSTOMERS_ERROR`;

export const REQUEST_CUSTOMER_DETAIL = `${prefix}/REQUEST_CUSTOMER_DETAIL`;
export const REQUEST_CUSTOMER_DETAIL_SUCCESS = `${prefix}/REQUEST_CUSTOMER_DETAIL_SUCCESS`;
export const REQUEST_CUSTOMER_DETAIL_ERROR = `${prefix}/REQUEST_CUSTOMER_DETAIL_ERROR`;

export const REQUEST_USERS = `${prefix}/REQUEST_USERS`;
export const REQUEST_USERS_SUCCESS = `${prefix}/REQUEST_USERS_SUCCESS`;
export const REQUEST_USERS_ERROR = `${prefix}/REQUEST_USERS_ERROR`;

export const REQUEST_USER_DETAIL = `${prefix}/REQUEST_USER_DETAIL`;
export const REQUEST_USER_DETAIL_SUCCESS = `${prefix}/REQUEST_USER_DETAIL_SUCCESS`;
export const REQUEST_USER_DETAIL_ERROR = `${prefix}/REQUEST_USER_DETAIL_ERROR`;

export const REQUEST_OFFERS = `${prefix}/REQUEST_OFFERS`;
export const REQUEST_OFFERS_SUCCESS = `${prefix}/REQUEST_OFFERS_SUCCESS`;
export const REQUEST_OFFERS_ERROR = `${prefix}/REQUEST_OFFERS_ERROR`;

export const REQUEST_OFFER_DETAIL = `${prefix}/REQUEST_OFFER_DETAIL`;
export const REQUEST_OFFER_DETAIL_SUCCESS = `${prefix}/REQUEST_OFFER_DETAIL_SUCCESS`;
export const REQUEST_OFFER_DETAIL_ERROR = `${prefix}/REQUEST_OFFER_DETAIL_ERROR`;
