export const PROJECT_STATS_VARIABLES_NAMES = {
  wlev: 'wlev',
  mag: 'mag',
  magw: 'magw',
  mag10: 'mag10',
  hs: 'hs',
};

export const PROJECT_STATS_CTS_TYPE = 'CTS';
export const PROJECT_STATS_OVERVIEW_NAME = 'Overview';
export const PROJECT_STATS_VARIABLES_LONG_NAMES = {
  waterHeights: 'WATER-HEIGHTS',
  currents: 'CURRENTS',
  wind: 'WIND',
  waves: 'WAVES',
};

export const OVERVIEW_TYPE = 'OV';

export const VALUES_KEY = 'values';

export const MEAN_POSTFIX = 'Mean';
export const MIN_POSTFIX = 'Min';
export const MAX_POSTFIX = 'Max';
export const STD_POSTFIX = 'Std';
export const MEDIAN_POSTFIX = 'Percentile50';

export const DEFAULT_DIGITS = 2;
export const LEVEL_INDEX = 0;

export const EXPORT_STATS_INFORMATIONS_COLUMNS = [
  {
    field: 'field',
    title: 'Field',
  },
  {
    field: 'dataset',
    title: 'Dataset',
  },
  {
    field: 'period',
    title: 'Period',
  },
  {
    field: 'variables',
    title: 'Variables',
  },
];
