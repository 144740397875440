import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SearchField from 'components/projects/SearchField';
import ProjectsFilterByOffers from 'components/projects/ProjectsFilterByOffers';
import ProjectsOrderBySelect from 'components/projects/ProjectsOrderBySelect';

import { useStyles } from './styles';

/**
 * Toolbar component
 * Contains some actions and filters for list of projects
 * @param {Array} offers
 * @param {Function} onTabChange
 * @param {Function} onOrderingChange
 * @param {Function} onSearchChange
 * @param {String} ordering
 * @param {String} search
 * @param {Number|String} offerId
 * @returns {jsx}
 */
const Toolbar = ({
  offers,
  onTabChange,
  onOrderingChange,
  onSearchChange,
  ordering,
  search,
  offerId,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const searchChangeHandler = useCallback(
    ({ target }) => onSearchChange(target.value || undefined),
    [onSearchChange]
  );

  const searchClearHandler = useCallback(
    (event) => {
      event.stopPropagation();
      onSearchChange();
    },
    [onSearchChange]
  );

  return (
    <div className={classes.container}>
      {/* FILTER OFFERS */}
      <ProjectsFilterByOffers
        offerId={offerId}
        offers={offers}
        onChange={onTabChange}
      />

      {/* SEARCH PROJECTS */}
      <SearchField
        placeholder={t('projects.searchPlaceholder')}
        value={search}
        onChange={searchChangeHandler}
        onClear={searchClearHandler}
      />

      {/* ORDER PROJECTS */}
      <ProjectsOrderBySelect onChange={onOrderingChange} ordering={ordering} />
    </div>

    // TODO_R : DESIGN OPTION 2
    // <div className={classes.container}>
    //   {/* SEARCH PROJECTS */}
    //   <div className={classes.searchContainer}>
    //     <SearchField
    //       placeholder={t('projects.searchPlaceholder')}
    //       value={search}
    //       onChange={searchChangeHandler}
    //       onClear={searchClearHandler}
    //     />
    //   </div>

    //   <div className={classes.subContainer}>
    //     {/* FILTER OFFERS */}
    //     <ProjectsFilterByOffers
    //       offerId={offerId}
    //       offers={offers}
    //       onChange={onTabChange}
    //     />
    //     {/* ORDER PROJECTS */}
    //     <ProjectsOrderBySelect
    //       onChange={onOrderingChange}
    //       ordering={ordering}
    //     />
    //   </div>
    // </div>
  );
};

Toolbar.propTypes = {
  search: PropTypes.string,
  offerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offers: PropTypes.array.isRequired,
  ordering: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onOrderingChange: PropTypes.func.isRequired,
};

export default memo(Toolbar);
