import { generatePath } from 'react-router';

import request from 'helpers/request';
import {
  DOWNLOAD_PLANNING_URL,
  DOWNTIMES_COMPUTE_URL,
  DOWNTIMES_PLANNING_URL,
  DOWNTIMES_URL,
  UPLOAD_PLANNING_URL,
} from 'constants/api';

import { makeXhrRequest } from '../helpers/requestXhr';

export const getPlanningsList = (params) =>
  request.get(DOWNTIMES_URL, { params });

export const deletePlanning = (planningId) =>
  request.delete(generatePath(DOWNTIMES_PLANNING_URL, { id: planningId }));

export const getPlanning = (planningId) =>
  request.get(generatePath(DOWNTIMES_PLANNING_URL, { id: planningId }));

/**
 * Uploads planning computation template
 */
export const uploadPlanning = ({ projectId, data }) =>
  request.post(generatePath(UPLOAD_PLANNING_URL, { id: projectId }), data);

export const createNewDowntimeComputation = (data) =>
  request.post(DOWNTIMES_URL, data);

export const startDowntimeComputation = ({ planningId, data }) =>
  request.post(generatePath(DOWNTIMES_COMPUTE_URL, { id: planningId }), data);

/**
 * Downloads planning using xhr request
 */
export const downloadPlanning = ({ planningId }) =>
  makeXhrRequest({
    method: 'GET',
    url: generatePath(DOWNLOAD_PLANNING_URL, { id: planningId }),
    responseType: 'arraybuffer',
  }).then((result) => result);
