import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  title: {
    margin: '0 30px',
  },
  chartContainer: {
    // fixes phantom recharts bug with scrollbar
    overflow: 'hidden',
    '& .recharts-cartesian-axis-ticks': {
      fontSize: 12,
    },
  },
  quantileSelectContainer: {
    marginBottom: 15,
  },
  yLabel: {
    fontSize: 12,
  },
});
