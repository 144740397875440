import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Leaflet from 'leaflet-providers';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { MapProvider } from 'contexts/MapContext';
import {
  MAP_LAYERS,
  MAP_LAYERS_CONFIG,
  POINT_POSITION,
  GEOMETRY_POSITION,
  BASE_LAYER_Z_INDEX,
} from 'constants/map';
import {
  createLayer,
  setPositionByGeoJson,
  setPositionByPoint,
} from 'helpers/map';
import { useForceUpdate } from 'hooks/leaflet';
import {
  mapDrag,
  mapChangeLayer,
  mapAddLayer,
  mapRemoveLayer,
} from 'ducks/trackers/actions/map';

import { useStyles } from './styles';
import 'leaflet/dist/leaflet.css';

const positionSettersByType = {
  [POINT_POSITION]: (map, pointOptions) =>
    setPositionByPoint({ map, pointOptions }),
  [GEOMETRY_POSITION]: (map, geoJsonString) =>
    setPositionByGeoJson({ map, geoJsonString }),
};

/**
 * Base Map component to work with leaflet map
 * Provides map instance in context
 * @param { children } - Is used for markers, geometries, graticule and other map components composition
 * @param { array } layers - map layers to enable
 * @param { object|string } position - position data, can be different type depends on position type
 * @param { string } positionType
 * @param { bool } withControls - flag: whether map controls should be enabled
 * @param { string } defaultLayer - layer displayed by default
 * @param { string } wrapperClass - class to style map, use it to override default styles
 * @param { bool } isStatic - flag: whether map is draggable, zoomable
 * @param { number } minZoom - number from 1 to 20 (minimum allowed zoom)
 * @param {{ min: array, max: array }} maxBounds - object describing left bottom (min) and top right (max) allowed points
 * maxBounds example: { min: [-10, -10], max: [10, 10] }
 * @returns { jsx }
 * @see https://leafletjs.com/
 */
const Map = ({
  children,
  layers,
  position,
  positionType,
  withControls,
  defaultLayer,
  wrapperClass,
  isStatic,
  minZoom,
  maxBounds,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const mapContainer = useRef(null);
  const mapRef = useRef(null);
  const mapControls = useRef(null);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const layersWithDefault = layers.includes(defaultLayer)
      ? layers
      : [defaultLayer, ...layers];

    const southWest = Leaflet.latLng(maxBounds.min);
    const northEast = Leaflet.latLng(maxBounds.max);
    const latLngMaxBounds = Leaflet.latLngBounds(southWest, northEast);

    const leafletLayersByName = layersWithDefault.reduce((acc, layer) => {
      const { name } = MAP_LAYERS_CONFIG[layer];
      acc[name] = createLayer(layer, {
        zIndex: BASE_LAYER_Z_INDEX,
        autoZIndex: false,
        noWrap: false,
      });
      return acc;
    }, {});
    const defaultLayerName = MAP_LAYERS_CONFIG[defaultLayer].name;

    mapRef.current = Leaflet.map(mapContainer.current, {
      layers: leafletLayersByName[defaultLayerName],
      attributionControl: false,
      zoomControl: withControls,
      scrollWheelZoom: !isStatic,
      dragging: !isStatic,
      maxBounds: latLngMaxBounds,
      minZoom,
    });

    // mapRef.current.on('zoomend', () => {
    //   dispatch(mapZoom());
    // });

    mapRef.current.on('dragend', () => {
      dispatch(mapDrag());
    });

    mapRef.current.on('overlayadd', (e) => {
      dispatch(mapAddLayer(e.name));
    });

    mapRef.current.on('overlayremove', (e) => {
      dispatch(mapRemoveLayer(e.name));
    });

    mapRef.current.on('baselayerchange', (e) => {
      dispatch(mapChangeLayer(e.name));
    });

    mapControls.current = withControls
      ? Leaflet.control.customGroupedLayers(leafletLayersByName, undefined, {
          autoZIndex: false,
        })
      : null;

    const setPosition = positionSettersByType[positionType];
    mapRef.current = setPosition(mapRef.current, position);
    forceUpdate();
    // eslint-disable-next-line
  }, []);

  const providerValue = {
    instance: mapRef.current,
    controls: mapControls.current,
  };

  return (
    <MapProvider value={providerValue}>
      {mapContainer && (
        <div
          ref={mapContainer}
          className={classNames(classes.map, wrapperClass)}
        >
          {children}
        </div>
      )}
    </MapProvider>
  );
};

Map.propTypes = {
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  positionType: PropTypes.oneOf([GEOMETRY_POSITION, POINT_POSITION]),
  layers: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(MAP_LAYERS_CONFIG))),
  isStatic: PropTypes.bool,
  minZoom: PropTypes.number,
  maxBounds: PropTypes.shape({
    min: PropTypes.array,
    max: PropTypes.array,
  }),
  withControls: PropTypes.bool,
  defaultLayer: PropTypes.string,
  wrapperClass: PropTypes.string,
};

Map.defaultProps = {
  positionType: GEOMETRY_POSITION,
  withControls: true,
  isStatic: false,
  minZoom: 3,
  maxBounds: { min: [-90, -180], max: [90, 180] },
  layers: [
    MAP_LAYERS.worldImagery,
    MAP_LAYERS.worldStreet,
    MAP_LAYERS.googleHybrid,
    MAP_LAYERS.nationalGeo,
    MAP_LAYERS.openStreet,
  ],
  defaultLayer: MAP_LAYERS.worldImagery,
  wrapperClass: '',
};

export default Map;
