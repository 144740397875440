import {
  EMPTY_VALUE,
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'constants/common';
import createReducer from 'helpers/createReducer';

import {
  REQUEST_STATIC_PAGE,
  REQUEST_STATIC_PAGE_ERROR,
  REQUEST_STATIC_PAGE_SUCCESS,
  RESET_ERROR,
} from './types';

const initialState = {
  error: EMPTY_VALUE,
  status: STATUS_NOT_REQUESTED,
};

export default createReducer(initialState, {
  [RESET_ERROR]: (state) => ({ ...state, error: EMPTY_VALUE }),
  [REQUEST_STATIC_PAGE]: (state) => ({
    ...state,
    status: STATUS_LOADING,
    error: EMPTY_VALUE,
  }),
  [REQUEST_STATIC_PAGE_SUCCESS]: (state, { data }) => {
    const url = data.url.replace(/\//g, '');
    return {
      ...state,
      [url]: data,
      status: STATUS_SUCCESS,
    };
  },
  [REQUEST_STATIC_PAGE_ERROR]: (state, { error }) => ({
    ...state,
    error,
    status: STATUS_ERROR,
  }),
});
