import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  rowsTable: {
    border: '1px solid',
    borderColor: palette.lightGrey.main,
    borderBottom: 'none',
    borderRight: 'none',

    '& .MuiTableCell-root': {
      padding: 8,
      border: '1px solid',
      borderColor: palette.lightGrey.main,
      borderTop: 'none',
      borderLeft: 'none',
      textAlign: 'center',
    },

    '& .MuiTableRow-root:hover': {
      background: 'none',
    },
  },
}));
