import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { useStyles } from './styles';

/**
 * Component with metadata information.
 * @param {String} title
 * @param {Array} fields
 * @param {Object} data
 * @return {jsx}
 */
const Metadata = ({ title, fields, data }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.metadata}>
      <Grid item className={classes.titleContainer}>
        {title}
      </Grid>
      {fields.map(({ field, label }) => (
        <Grid className={classes.metadataRow} key={field} item container>
          <Grid className={classes.metadataCell} xs={6} item>
            {label}
          </Grid>
          <Grid className={classes.metadataCell} xs={6} item>
            {data[field]}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

Metadata.propTypes = {
  title: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      field: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.object.isRequired,
};

export default memo(Metadata);
