import Leaflet from 'leaflet-providers';
import leafletImage from 'leaflet-image';
import { sortBy } from 'lodash';
import { Marker } from 'leaflet';

import { CARDINAL_POINTS } from 'constants/common';
import {
  INVALID_GEOJSON_STRING,
  INVALID_POSITION_OPTIONS,
} from 'constants/errors';
import {
  DASHBOARD_STATIC_MAP_COEFFICIENT,
  GEOMETRY_TYPES,
  LEAFLET_EVENT_STOP_FLAG,
  MAP_CONTROL_CONTAINER_CLASS,
  MAP_CONTROL_POSITION_CLASS_PREFIX,
  MAP_LAYERS_CONFIG,
  MAP_PROPERTIES,
  PATH_TAG,
} from 'constants/map';

/**
 * Create leaflet layer
 * @param {String} layer
 * @param {Object} defaultOptions
 * @return {Object}
 */
export const createLayer = (layer, defaultOptions = {}) => {
  const { path, custom, options = defaultOptions } = MAP_LAYERS_CONFIG[layer];
  return custom
    ? Leaflet.tileLayer(path, options)
    : Leaflet.tileLayer.provider(path, options);
};

/**
 * helpers to generate snapshots out of leaflet map
 * TODO if it would be used, add map initialization by geoJson
 * @param { array } points collection of points
 * @param { number } width wanted image width
 * @param { number } height wanted image height
 * @param { string } layer layer to use
 * @returns {Promise}
 */
export const getMapSnapshotsByPoints = ({ points, width, height, layer }) => {
  const imageUrls = [];
  const mapContainer = document.createElement('div');
  mapContainer.style.cssText = `
    width: ${width}px; 
    height: ${height}px; 
    position: absolute;
    top: -${width + 100}px;
    left: -${height + 100}px;
  `;

  return new Promise((resolve) => {
    document.body.appendChild(mapContainer);
    const mapInstance = Leaflet.map(mapContainer);

    const { path, custom, options = {} } = MAP_LAYERS_CONFIG[layer];
    const tileLayer = custom
      ? Leaflet.tileLayer(path, options)
      : Leaflet.tileLayer.provider(path, options);

    tileLayer.addTo(mapInstance);
    points.forEach(({ lat, lng, zoom }, ind) => {
      mapInstance.setView([lat, lng], zoom);
      leafletImage(mapInstance, (err, canvas) => {
        canvas.toBlob(
          (blob) => {
            const src = URL.createObjectURL(blob);
            imageUrls.push({ order: ind, src });
            if (imageUrls.length === points.length) {
              return resolve(imageUrls);
            }
          },
          'image/jpeg',
          0.9
        );
      });
    });
  });
};

/**
 * builds and return geoJson layer from given geoJson string
 * @param geoJsonString
 */
export const getGeoJsonLayerFromString = (geoJsonString) => {
  try {
    const geoJson = JSON.parse(geoJsonString);
    return Leaflet.geoJson(geoJson);
  } catch (error) {
    throw Error(INVALID_GEOJSON_STRING);
  }
};

/**
 * injects props to layer.feature.properties (this is "legal" way to add custom props to leaflet layer)
 * @param leafletLayer
 * @param props props to inject
 * @return { object } layer with injected props
 */
export const injectPropsToLayer = (leafletLayer, props) => {
  leafletLayer.eachLayer(({ feature }) => {
    if (!feature) {
      return;
    }
    const prevProps = feature.properties || {};
    feature.properties = { ...prevProps, ...props };
  });
  return leafletLayer;
};

/**
 * Function for set options
 * @param {Object} feature
 * @param {Object} options
 */
export const injectOptionsToElement = (feature, options) => {
  if (feature.options && options) {
    Object.entries(options).forEach(([key, value]) => {
      feature.options[key] = value;
    });
  }
};

/**
 * Function for set options
 * @param {Object} feature
 * @param {Object} properties
 */
export const injectPropertiesToElement = (feature, properties) => {
  if (feature && properties) {
    const prevProperties = feature.properties ? { ...feature.properties } : {};

    feature.properties = Object.entries(properties).reduce(
      (acc, [key, value]) => {
        acc[key] = value;
        return acc;
      },
      prevProperties
    );
  }
};

/**
 * Sets leaflet map position by geojson string and returns map instance
 * @param map leaflet map instance
 * @param { string } geoJsonString
 */
export const setPositionByGeoJson = ({ map, geoJsonString }) => {
  try {
    const geoJsonLayer = getGeoJsonLayerFromString(geoJsonString);
    const bounds = geoJsonLayer.getBounds();
    return map.fitBounds(bounds);
  } catch (error) {
    throw Error(INVALID_POSITION_OPTIONS);
  }
};

/**
 * Sets leaflet map position by point options object and returns map instance
 * @param map leaflet map instance
 * @param { object } pointOptions
 */
export const setPositionByPoint = ({ map, pointOptions }) => {
  if (['lat', 'lng', 'zoom'].every({}.hasOwnProperty.bind(pointOptions))) {
    const { lat, lng, zoom } = pointOptions;
    return map.setView([lat, lng], zoom);
  }
  throw Error(INVALID_POSITION_OPTIONS);
};

/**
 * calculateAveragePosition - calculate average by points array
 * @param {Array} points
 * @param {Number} zoom
 * @returns {{ lat: Number, lng: Number, zoom: Number }}
 */
export const calculateAveragePosition = (points, zoom) =>
  points.reduce(
    (acc, { lat, lng }) => {
      acc.lat += lat / points.length;
      acc.lng += lng / points.length;

      return acc;
    },
    { lat: 0, lng: 0, zoom }
  );

/**
 * getAveragePositionByPoints - get average position by points array
 * If array is empty - return {{ lat: 0, lng: 0, zoom }}
 * @param {Array} points
 * @param {Number} zoom - default 3
 * @returns {{ lat: Number, lng: Number, zoom: Number }}
 */
export const getAveragePositionByPoints = (points, zoom = 3) => {
  if (!points || !points.length) {
    return { lat: 0, lng: 0, zoom };
  }

  if (points.length === 1) {
    const point = points[0];

    return { lat: point.lat, lng: point.lng, zoom };
  }

  return calculateAveragePosition(points, zoom);
};

/**
 * HOF to wrap Leaflet layer/feature/group onClick handler
 * Helps to manage clicking on overlapping elements
 * finds smallest of propogated elements and trigger event on it
 * @param { function } onClick - original onClick
 * @param { string } targetTag - targets tag (for example svg 'path' for polygons)
 * @returns {Function}
 * @note it's a common leaflet bug. Can be managed in other ways like these
 * @see https://gist.github.com/perliedman/84ce01954a1a43252d1b917ec925b3dd or https://github.com/danwild/leaflet-event-forwarder/blob/master/src/L.eventForwarder.js
 * (but it doesn't work correct with deep nested targets)
 */
export const withLeafletClickForwarding = (onClick, targetTag = PATH_TAG) => (
  leafletEvent,
  ...args
) => {
  const { originalEvent } = leafletEvent;
  if (originalEvent[LEAFLET_EVENT_STOP_FLAG]) {
    return false;
  }

  const { clientX, clientY, detail } = originalEvent;
  if (detail === 0) {
    onClick.call(null, leafletEvent, ...args);
    return true;
  }

  const elementsToCheck = document.elementsFromPoint(clientX, clientY);
  const { wantedNode } = elementsToCheck.reduce(
    (acc, node) => {
      if (node.nodeName.toLowerCase() !== targetTag) {
        return acc;
      }
      const { width, height } = node.getBoundingClientRect();
      const square = width * height;
      if (square && (square < acc.smallestSquare || !acc.smallestSquare)) {
        acc.smallestSquare = square;
        acc.wantedNode = node;
      }
      return acc;
    },
    { wantedNode: null, smallestSquare: null }
  );

  const forwardedEvent = new MouseEvent(originalEvent.type, {
    bubbles: true,
    view: window,
    detail: 0,
  });
  wantedNode.dispatchEvent(forwardedEvent);
};

/**
 * Function for sort zone by area
 * @param {Array} zones
 * @param {Array<Number>} offerZoneIds
 * @returns {Array}
 */
export const sortZoneByArea = (zones, offerZoneIds) => {
  const preparedZones = zones
    .map((zone) => {
      const bounds = getGeoJsonLayerFromString(zone.geom).getBounds();
      const westEastDist = bounds
        .getNorthWest()
        .distanceTo(bounds.getNorthEast());
      const southNorthDist = bounds
        .getNorthWest()
        .distanceTo(bounds.getSouthWest());

      return {
        ...zone,
        inOffer: offerZoneIds.includes(zone.id),
        area: westEastDist * southNorthDist,
      };
    })
    .sort((firstZone, secondZone) => secondZone.area - firstZone.area);

  return sortBy(preparedZones, [
    ({ isWorld }) => !isWorld,
    'inOffer',
    'isDemo',
  ]);
};

/**
 * Return  point geometry string
 * @param {number} lat
 * @param {number} lng
 * @returns {String}
 */
export const getPointGeometry = ({ lat, lng }) =>
  JSON.stringify({
    type: GEOMETRY_TYPES.point,
    coordinates: [+lng, +lat],
  });

/**
 * Get position from geometry string
 * @param {String} geom
 * @returns {Array}
 */
export const getPositionFromGeometry = (geom) =>
  JSON.parse(geom)
    .coordinates.reverse()
    .map(Number);

/**
 * Get polygon position from geometry string
 * @param {String} geom
 * @returns {Array}
 */
export const getPolygonPositionsFromGeometry = (geom) => {
  const bounds = getGeoJsonLayerFromString(geom).getBounds();
  const minCoordinates = { lat: bounds.getSouth(), lng: bounds.getWest() };
  const maxCoordinates = { lat: bounds.getNorth(), lng: bounds.getEast() };

  return [minCoordinates, maxCoordinates];
};

/**
 * Get selector for map controller;
 * @param position
 * @return {string}
 * For example:
 *  position: 'topleft'
 *  output: .leaflet-control-container > .leaflet-top .leaflet-left
 */
export const getControlContainerSelector = (position) => {
  const [, vertical, horizontal] = position.match(/^(top|bottom)(left|right)$/);

  const positionClasses = [vertical, horizontal].map(
    (side) => `${MAP_CONTROL_POSITION_CLASS_PREFIX}${side}`
  );

  return `${MAP_CONTROL_CONTAINER_CLASS} > ${positionClasses.join('')}`;
};

/**
 * Return a direction for coordinate.
 * @param {Number} coordinate
 * @param {String }type
 * @return {string}
 * Example:
 *   getCoordinateDirection(10, MAP_PROPERTIES.latitude) -> 'N'
 *   getCoordinateDirection(-10, MAP_PROPERTIES.latitude) -> 'S'
 *   getCoordinateDirection(10, MAP_PROPERTIES.longitude) -> 'W'
 *   getCoordinateDirection(-10, MAP_PROPERTIES.longitude) -> 'E'
 */
const getCoordinateDirection = (coordinate, type) => {
  if (type === MAP_PROPERTIES.latitude) {
    return coordinate < 0 ? CARDINAL_POINTS.S : CARDINAL_POINTS.N;
  }

  return coordinate < 0 ? CARDINAL_POINTS.W : CARDINAL_POINTS.E;
};

/**
 * Convert coordinate to string format
 * @param {Number} coordinate
 * @param {String} type
 * @param {Number} digits
 * @return {string}
 * Example:
 *   convertCoordinateToString(-10.36333, MAP_PROPERTIES.longitude) -> 10°36'E
 *   convertCoordinateToString(10.36333, MAP_PROPERTIES.longitude, 3) -> 10°363'W
 */
export const convertCoordinateToString = (coordinate, type, digits = 2) => {
  const preparedCoordinate = Number(coordinate.toFixed(digits));
  const integerPart = Math.floor(Math.abs(preparedCoordinate));
  const fractionalPart = Math.round(
    (Math.abs(preparedCoordinate) - integerPart) * 60
  );

  const fractionalStringPart = fractionalPart !== 0 ? `${fractionalPart}'` : '';
  const direction = getCoordinateDirection(coordinate, type);

  return `${integerPart}°${fractionalStringPart}${direction}`;
};

/**
 * Function for centering map with offset
 * @param {Object} map - leaflet map
 * @param {Object} latLng - leaflet coordinate
 * @param {Object} offset
 * @param {Number} zoom
 * @param {Object} options
 */
export const centeringMapByLatLng = ({
  map,
  latLng,
  offset = {},
  zoom,
  options,
}) => {
  const { _zoom: currentZoom } = map;
  const { x = 0, y = 0 } = offset;
  const position = map.project(latLng);

  map.setView(
    map.unproject({ x: position.x + x, y: position.y + y }),
    zoom || currentZoom,
    options
  );
};

/**
 * Get geostring by points array
 * @param points
 * @param {Object} options
 * @param {Number} options.marginCoefficient
 * @return {string}
 */
export const getMapPositionByPoints = (points, options = {}) => {
  const { marginCoefficient = DASHBOARD_STATIC_MAP_COEFFICIENT } = options;
  const latitudes = points.map(({ lat }) => lat);
  const longitudes = points.map(({ lng }) => lng);

  const minLat = Math.min.apply(null, latitudes);
  const maxLat = Math.max.apply(null, latitudes);

  const minLng = Math.min.apply(null, longitudes);
  const maxLng = Math.max.apply(null, longitudes);

  const latOffset = marginCoefficient * (maxLat - minLat);
  const lngOffset = marginCoefficient * (maxLng - minLng);

  const group = Leaflet.featureGroup([
    new Marker([maxLat + latOffset, maxLng + lngOffset]),
    new Marker([minLat - latOffset, minLng - lngOffset]),
  ]);

  return JSON.stringify(group.toGeoJSON());
};
