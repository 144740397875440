/**
 * Sorts array of reports by converting startedAt attribute to date object.
 * Returns most recent report.
 * @param {Array<Object>} arrayOfReports
 * @returns {Object} - Most recent report
 */
export const findLatestReport = (arrayOfReports) =>
  arrayOfReports.sort(
    (a, b) => new Date(b.startedAt) - new Date(a.startedAt)
  )[0];
