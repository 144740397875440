import { useCallback, useEffect, useMemo, useRef } from 'react';
import L from 'leaflet-providers';
import PropTypes from 'prop-types';
import 'leaflet-groupedlayercontrol';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
// eslint-disable-next-line
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';

import './style.scss';

import ReactDOM from 'react-dom';

import { useMapInstance } from 'hooks/leaflet/useMapInstance';
import { MAP_CONTROLS_POSITION } from 'constants/map';
import { withGeometries } from 'decorators/leaflet/withGeometries';
import { useForceUpdate, useMapEvents } from 'hooks/leaflet';

const Leaflet = withGeometries(L);

// eslint-disable-next-line
delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

/**
 * CustomGroupedLayersControl add layouts with a control panel
 * @param {React.ReactNode} children
 * @param {String} position
 * @param {String} iconClassName
 * @param {Boolean} autoZIndex
 * @param {Boolean} multiply
 * @param {Array} baseLayers
 * @param {Array} groupedOverlays
 * @param {Number} zIndex
 * @param {Function} onAddGeometries
 * @return {React.ReactPortal}
 */
const CustomGroupedLayersControl = ({
  iconClassName,
  children,
  baseLayers,
  groupedOverlays,
  position,
  autoZIndex,
  zIndex,
  multiply,
  onAddGeometries,
}) => {
  const mapInstance = useMapInstance();
  const forceUpdate = useForceUpdate();
  const options = useMemo(
    () => ({ position, autoZIndex, iconClassName, zIndex }),
    [position, autoZIndex, iconClassName, zIndex]
  );
  const groupedLayer = useRef(
    Leaflet.control.customGroupedLayers(baseLayers, groupedOverlays, options)
  );
  const { _contentNode: contentNode } = groupedLayer.current;

  const onOverlayAdd = useCallback(
    ({ layer }) => {
      if (!multiply) {
        groupedLayer.current.hideOtherLayers(layer);
      }
    },
    [groupedLayer, multiply]
  );

  useEffect(() => {
    if (!mapInstance) {
      return;
    }
    const group = groupedLayer.current;

    group.addTo(mapInstance);
    forceUpdate();

    return () => {
      group.removeGeometries();
      group.remove();
    };
  }, [mapInstance, groupedLayer, forceUpdate]);

  useMapEvents(
    {
      element: mapInstance,
      events: { overlayadd: onOverlayAdd },
    },
    [onOverlayAdd]
  );

  useEffect(() => {
    const group = groupedLayer.current;
    if (onAddGeometries) return onAddGeometries(group);
  }, [onAddGeometries, groupedLayer]);

  useEffect(() => {
    const group = groupedLayer.current;

    group.setOptions(options);
  }, [groupedLayer, options]);

  return !!contentNode && ReactDOM.createPortal(children, contentNode);
};

CustomGroupedLayersControl.propTypes = {
  children: PropTypes.node,
  autoZIndex: PropTypes.bool,
  multiply: PropTypes.bool,
  position: PropTypes.string,
  iconClassName: PropTypes.string,
  zIndex: PropTypes.number,
};

CustomGroupedLayersControl.defaultProps = {
  iconClassName: 'geom-control-btn',
  position: MAP_CONTROLS_POSITION.topLeft,
  autoZIndex: false,
  multiply: false,
};

export default CustomGroupedLayersControl;
