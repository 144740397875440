import { generatePath } from 'react-router';

import request from 'helpers/request';
import {
  USAGE_CUSTOMERS_URL,
  USAGE_CUSTOMER_DETAIL_URL,
  USAGE_USERS_URL,
  USAGE_USER_DETAIL_URL,
  USAGE_OFFERS_URL,
  USAGE_OFFER_DETAIL_URL,
} from 'constants/api';

export const getCustomers = () => request.get(`${USAGE_CUSTOMERS_URL}`);

export const getUsers = () => request.get(`${USAGE_USERS_URL}`);

export const getOffers = () => request.get(`${USAGE_OFFERS_URL}`);

export const getCustomerDetail = (id) =>
  request.get(generatePath(USAGE_CUSTOMER_DETAIL_URL, { id }));

export const getUserDetail = (id) =>
  request.get(generatePath(USAGE_USER_DETAIL_URL, { id }));

export const getOfferDetail = (id) =>
  request.get(generatePath(USAGE_OFFER_DETAIL_URL, { id }));
