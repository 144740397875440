/**
 * Merge points with project
 * @param {Object} project
 * @param {Array} points
 * @returns {Object}
 */
export const mergePointsWithProject = (project, points = []) => ({
  ...project,
  points,
});
