import { useContext } from 'react';

import { MapContext } from 'contexts/MapContext';

/**
 * Hook get and return map from context
 *
 * @return {Object}
 */
export const useMapInstance = () => {
  const { instance } = useContext(MapContext);

  return instance;
};
