import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  table: {
    maxWidth: 550,
  },
  unselectedCell: {
    backgroundColor: palette.grey.main,
  },
  headerCell: {
    backgroundColor: palette.primary.light,
    color: palette.white.main,
    fontWeight: 'bold',
    padding: 5,
    fontSize: 12,
    lineHeight: 1.4,
  },
  tableRow: {
    '& > td': {
      fontSize: 10,
      padding: 5,
    },
    backgroundColor: palette.lightGrey.main,
    '&:nth-of-type(odd)': {
      backgroundColor: palette.lightGrey.light,
    },
  },
  cell: {
    whiteSpace: 'pre-wrap',
    textAlign: 'center',
  },
}));
