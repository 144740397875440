import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MapPopup } from 'components/leaflet';
import EditPoint from 'containers/points/EditPoint';
import { useMapInstance } from 'hooks/leaflet/useMapInstance';
import {
  BaseMapPopup,
  BaseMapPopupContent,
  BaseMapPopupTitle,
} from 'components/common/BaseMapPopup';
import { centeringMapByLatLng } from 'helpers/map';
import {
  DEFAULT_CENTERING_MAP_DELAY,
  EDIT_POINT_Y_OFFSET,
} from 'constants/map';

import { useStyles } from './styles';

/**
 * Popup for map with create point form
 * @param {Object} props
 * @returns {jsx}
 */
const EditPointPopup = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const mapInstance = useMapInstance();
  const [editablePoint, setEditablePoint] = useState();

  const closePopup = useCallback(() => {
    mapInstance.closePopup();
    setEditablePoint(undefined);
  }, [mapInstance]);

  const openPopupHandler = useCallback(
    ({ popup: { _source: marker, _map: map } }) => {
      setTimeout(centeringMapByLatLng, DEFAULT_CENTERING_MAP_DELAY, {
        map,
        latLng: marker.getLatLng(),
        offset: { y: EDIT_POINT_Y_OFFSET },
      });

      setEditablePoint(marker.properties);
    },
    [setEditablePoint]
  );

  return (
    <MapPopup {...props} onOpen={openPopupHandler}>
      {editablePoint && (
        <BaseMapPopup className={classes.editPointPopup}>
          <BaseMapPopupTitle>
            {editablePoint.isComputed
              ? t('points.view.title', { name: editablePoint.name })
              : t('points.edit.title', { name: editablePoint.name })}
          </BaseMapPopupTitle>
          <BaseMapPopupContent>
            <EditPoint point={editablePoint} onCancel={closePopup} />
          </BaseMapPopupContent>
        </BaseMapPopup>
      )}
    </MapPopup>
  );
};

export default memo(EditPointPopup);
