import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import DataValidationMap from 'components/maps/DataValidationMap';
import BuoysFeatureGroup from 'components/maps/BuoysFeatureGroup';
import { Loader } from 'components/common/Loader';
import { selectBuoysIsLoading } from 'ducks/buoys/selectors';
import chartScatterIcon from 'assets/images/icons/chart-scatter.svg';

import { useStyles } from './styles';
import parameters from './parameters.json';

/**
 * DataValidationContent - component for render side bar with info and Map with descriptions
 * @param {Object} project
 * @param {Array} buoys
 * @param {Function} onPointClick
 * @param {Function} onPointClick
 * @returns {jsx}
 */
const DataValidationContent = ({
  project,
  buoys,
  onPointClick,
  onBuoyClick,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isLoading } = useSelector((state) => ({
    isLoading: selectBuoysIsLoading(state),
  }));

  const handleBuoyClick = useCallback((e) => {
    const buoyId = e.id;
    /* by default set first sensor type (only 1 sensor type /buoy) */
    const sensorType = e.sensors[0];
    onBuoyClick({ id: buoyId, numericalSimulation: sensorType });
  }, []);

  return (
    <div id="DV-validation-container" className={classes.dvContentContainer}>
      <div className={classes.introContainer}>
        <div className={classes.subtitleContainer}>
          <img src={chartScatterIcon} alt="" width="24" />
          <p className={classes.subtitle}>
            {t('dataValidation.graphicalValidation')}
          </p>
        </div>
        <p className={classes.introText}>{t('dataValidation.selectPoint')}</p>
        <p className={classes.introText}>{t('dataValidation.selectBuoy')}</p>
      </div>
      <DataValidationMap
        project={project}
        onPointClick={onPointClick}
        wrapperClass={classes.mapContainer}
      >
        {!isLoading ? (
          <BuoysFeatureGroup buoys={buoys} onClick={handleBuoyClick} />
        ) : (
          <div>
            <Loader center />
          </div>
        )}
      </DataValidationMap>
      <div className={classes.dvParametersContainer}>
        <h2 className={classes.dvTitle}>
          {t('dataValidation.dataValidationParameters')}
        </h2>
        {parameters.map((paragraph) => (
          <div key={paragraph.id}>
            {paragraph.title && (
              <h3 className={classes.parametersTitle}>{paragraph.title}</h3>
            )}
            <div
              className={classNames(classes.parametersSection, {
                [classes[paragraph.customClass]]: paragraph.customClass,
              })}
              dangerouslySetInnerHTML={{ __html: paragraph.content }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

DataValidationContent.propTypes = {
  project: PropTypes.shape({
    zone: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
  buoys: PropTypes.array.isRequired,
  onPointClick: PropTypes.func.isRequired,
  onBuoyClick: PropTypes.func.isRequired,
};

export default React.memo(DataValidationContent);
