import React from 'react';
import PropTypes from 'prop-types';

/**
 * Wrapper for danger component. If component did crash we show error.
 */
class SilentBoundary extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (this.props.onError) {
      this.props.onError(error, errorInfo);
    } else {
      // eslint-disable-next-line
      console.error('Maybe something went wrong: ', error, errorInfo)
    }
  }

  render() {
    return !this.state.hasError ? this.props.children : this.props.message;
  }
}

SilentBoundary.propTypes = {
  onError: PropTypes.func,
  message: PropTypes.node,
};

SilentBoundary.defaultProps = {
  message: false,
};

export default SilentBoundary;
