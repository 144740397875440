import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  text: {
    fontSize: '1em',
  },
  searchBar: {},
  table: {
    minWidth: 400,
    margin: '2vw',
  },
}));
