import {
  STATUS_LOADING,
  STATUS_ERROR,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
  EMPTY_VALUE,
  EMPTY_ARRAY,
} from 'constants/common';
import createReducer from 'helpers/createReducer';
import { prepareAndSetError } from 'helpers/apiErrors';

import {
  REQUEST_REPORTS,
  REQUEST_REPORTS_SUCCESS,
  REQUEST_REPORTS_ERROR,
  POST_REPORT,
  POST_REPORT_ERROR,
  POST_REPORT_SUCCESS,
  GENERATE_REPORT,
  GENERATE_REPORT_SUCCESS,
  GENERATE_REPORT_ERROR,
  CANCEL_REPORT,
  CANCEL_REPORT_SUCCESS,
  CANCEL_REPORT_ERROR,
  GET_REPORT_DATA,
  GET_REPORT_DATA_SUCCESS,
  GET_REPORT_DATA_ERROR,
  RESET_REPORTS,
  DOWNLOAD_REPORT,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_ERROR,
} from './types';

const initialState = {
  allReports: EMPTY_ARRAY,
  currentReport: EMPTY_VALUE,
  downloadingReport: EMPTY_ARRAY,
  error: EMPTY_VALUE,
  status: STATUS_NOT_REQUESTED,
};

const setReport = (state, { payload }) => ({
  ...state,
  allReports: [...state.allReports, payload],
  currentReport: payload,
  error: EMPTY_VALUE,
  status: STATUS_SUCCESS,
});

const setReports = (state, { payload }) => ({
  ...state,
  allReports: payload,
  error: EMPTY_VALUE,
  status: STATUS_SUCCESS,
});

const setLoading = (state) => ({
  ...state,
  status: STATUS_LOADING,
});

const cancelReport = (state) => ({
  ...state,
  currentReport: EMPTY_VALUE,
  status: STATUS_NOT_REQUESTED,
});

const setDownloadingReport = (state, { reportId }) => ({
  ...state,
  downloadingReport: [...state.downloadingReport, reportId],
  status: STATUS_LOADING,
});

const successReportDownload = (state, { reportId }) => ({
  ...state,
  downloadingReport: state.downloadingReport.filter(
    (item) => item.id === reportId
  ),
  status: STATUS_SUCCESS,
});

const errorReportDownload = (state, { reportId }) => ({
  ...state,
  downloadingReport: state.downloadingReport.filter(
    (item) => item.id === reportId
  ),
  status: STATUS_ERROR,
});

const setInitialState = () => initialState;

export default createReducer(initialState, {
  [REQUEST_REPORTS]: setLoading,
  [REQUEST_REPORTS_SUCCESS]: setReports,
  [REQUEST_REPORTS_ERROR]: prepareAndSetError,
  [POST_REPORT]: setLoading,
  [POST_REPORT_SUCCESS]: setReport,
  [POST_REPORT_ERROR]: prepareAndSetError,
  [GENERATE_REPORT]: setLoading,
  [GENERATE_REPORT_SUCCESS]: setReport,
  [GENERATE_REPORT_ERROR]: prepareAndSetError,
  [CANCEL_REPORT]: setLoading,
  [CANCEL_REPORT_SUCCESS]: cancelReport,
  [CANCEL_REPORT_ERROR]: prepareAndSetError,
  [GET_REPORT_DATA]: setLoading,
  [GET_REPORT_DATA_SUCCESS]: setReport,
  [GET_REPORT_DATA_ERROR]: prepareAndSetError,
  [RESET_REPORTS]: setInitialState,
  [DOWNLOAD_REPORT]: setDownloadingReport,
  [DOWNLOAD_REPORT_SUCCESS]: successReportDownload,
  [DOWNLOAD_REPORT_ERROR]: errorReportDownload,
});
