import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import { theme } from 'common/theme';

export const useStyles = makeStyles(({ palette: { primary } }) => ({
  offer: {
    position: 'relative',
    height: '100%',
    padding: 25,
    paddingBottom: 75,
    cursor: 'pointer',
    transition: '.16s',

    '&:hover': {
      boxShadow: `0 10px 20px 0 ${alpha(primary.main, 0.2)}`,
    },
  },
  name: {
    fontSize: 28,
    textAlign: 'center',
  },
  description: {
    fontSize: 16,
    margin: '10px 0',
  },
  footer: {
    position: 'absolute',
    right: 0,
    left: 0,
    bottom: 25,
  },
  createButton: {
    margin: '0 auto 0 auto',
    display: 'block',
    ...theme.buttons.fill.primary,
  },
}));
