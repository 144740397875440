import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  title: {
    margin: '5px 25px',
    whiteSpace: 'pre-wrap',
    lineHeight: 1.3,
  },
  table: {
    maxWidth: 550,
    borderCollapse: 'collapse',
    borderStyle: 'hidden',
    '& td,& th': {
      border: `2px solid ${palette.white.main}`,
    },
  },
  unselectedCell: {
    backgroundColor: palette.grey.main,
  },
  headerRow: {
    backgroundColor: palette.green.light,
    '& > th': {
      fontWeight: 'bold',
      padding: 5,
    },
  },
  tableRow: {
    '& > td': {
      padding: 5,
    },
    backgroundColor: palette.lightGrey.main,
    '&:nth-of-type(odd)': {
      backgroundColor: palette.lightGrey.light,
    },
  },
}));
