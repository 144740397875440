import React, { memo } from 'react';
import PropsTypes from 'prop-types';

import { palette } from 'common/theme';

const LEFT_OFFSET = 10;
const TEXT_COLOR = palette.black.main;
const STROKE_COLOR = palette.grey.light;

/**
 * CustomLegend component. Renders given legends with given colors
 * @param { string } title - legend block title
 * @param { number } width - container width
 * @param { array } colors - colors set for legendså
 * @param { array } legends - legend texts
 * @param { number } offsetY - top/bottom offset
 * @param { number } lineOffset - y offset between lines
 * @param { number } fontSize - fontSize (color box has same size as font)
 * @param { number } height - container height
 * @returns { JSX }
 */
const SvgColorsLegend = ({
  title,
  width,
  colors,
  legends,
  offsetY,
  fontSize,
  lineOffset,
  height: heightFromProps,
}) => {
  const height =
    heightFromProps ??
    offsetY * 2 + lineOffset + (legends.length + 1) * (fontSize + lineOffset);

  const startLegendsY = offsetY + fontSize + 2 * lineOffset;
  const boxOffsetX = LEFT_OFFSET;
  const legendTextOffsetX = LEFT_OFFSET + fontSize + 5;

  return (
    <svg width={width} height={height}>
      <rect
        stroke={STROKE_COLOR}
        strokeOpacity={0.5}
        fill={palette.white.main}
        width={width}
        height={height}
        rx={5}
        ry={5}
      />
      <text
        x={LEFT_OFFSET}
        y={offsetY}
        fill={TEXT_COLOR}
        fontSize={fontSize}
        dominantBaseline="hanging"
        fontWeight={700}
      >
        {title}
      </text>
      {legends.map((legend, index) => {
        const color = colors[index];
        const legendOffsetY = startLegendsY + index * (fontSize + lineOffset);

        return (
          <React.Fragment key={color}>
            <rect
              fill={color}
              x={boxOffsetX}
              y={legendOffsetY}
              width={fontSize}
              height={fontSize}
              rx={2}
              ry={2}
            />
            <text
              x={legendTextOffsetX}
              y={legendOffsetY + 1}
              fill={TEXT_COLOR}
              fontSize={fontSize}
              dominantBaseline="hanging"
            >
              {legend}
            </text>
          </React.Fragment>
        );
      })}
    </svg>
  );
};

SvgColorsLegend.propTypes = {
  title: PropsTypes.string,
  width: PropsTypes.number,
  colors: PropsTypes.arrayOf(PropsTypes.string).isRequired,
  legends: PropsTypes.arrayOf(PropsTypes.string).isRequired,
  offsetY: PropsTypes.number,
  fontSize: PropsTypes.number,
  lineOffset: PropsTypes.number,
  height: PropsTypes.number,
};

SvgColorsLegend.defaultProps = {
  title: '',
  width: 200,
  offsetY: 10,
  fontSize: 12,
  lineOffset: 5,
};

export default memo(SvgColorsLegend);
