import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    width: 500,
  },
  body: {
    padding: '10px 5px',
    textAlign: 'center',
  },
}));
