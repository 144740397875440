import React, { useMemo, useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import {
  ComposedChart,
  CartesianGrid,
  Customized,
  XAxis,
  YAxis,
} from 'recharts';
import Typography from '@material-ui/core/Typography';

import {
  getAverageProbabilityBinByRange,
  getJpdDataBins,
} from 'helpers/graphs/jointProbabilityDistribution';
import {
  ANNUAL_INDEX,
  CHART_SVG_ID,
  CHART_TITLE_ID,
  DEFAULT_GRAPH_CANVAS_HEIGHT,
  DEFAULT_GRAPH_CANVAS_WIDTH,
  DEFAULT_LEVELS_BAR_WIDTH,
  GRAPHS_WIDGETS,
} from 'constants/graphs';
import GraphParamSelect from 'components/common/graphs/GraphParamSelect';
import { useGraphJointSelects } from 'hooks/useGraphJointSelects';
import SelectMonthButtonGroup from 'components/common/buttons/SelectMonthButtonGroup';
import BaseTooltip from 'components/common/graphs/BaseTooltip';
import { getKeyByXY } from 'helpers/graphs/common';
import { BOTTOM, EOL, MONTH_NAMES, TOP } from 'constants/common';
import ProbabilityDistributionSelect from 'components/common/graphs/ProbabilityDistributionSelect';
import { getPrettyNumber } from 'helpers/data';
import { HorizontalLoader } from 'components/common/HorizontalLoader';
import { Loader } from 'components/common/Loader';
import CustomColorBar from 'components/common/graphs/CustomColorBar';
import DownloadGraphDataFileButtonGroup from 'containers/buttons/DownloadGraphDataFileButtonGroup';
import CustomLevelsBar from 'components/common/graphs/CustomLevelsBar';
import { useUniqueId } from 'hooks/useUniqueId';

import { useStyles } from './styles';
import ProbabilityMatrix from './ProbabilityMatrix';

const LEVELS_BAR_OFFSET = 10;
const LEVELS_FULL_LENGTH = DEFAULT_LEVELS_BAR_WIDTH + LEVELS_BAR_OFFSET;
const COLOR_BAR_WIDTH = 80;
const COLOR_BAR_OFFSET = 20;
const CANVAS_OFFSET = 10;
const AXIS_WIDTH = 35;
const TICK_FONT_SIZE = 10;
const TOOLTIP_Y_LIMIT = 50;
const TOOLTIP_OFFSET = {
  x: -40,
  y: 30,
};

const GRAPH_MARGIN = {
  left: CANVAS_OFFSET,
  right: CANVAS_OFFSET + COLOR_BAR_WIDTH + COLOR_BAR_OFFSET,
  top: 25,
  bottom: CANVAS_OFFSET,
};

/**
 * Local component with chart
 * It's made to separate it from main component with state and prevent expensive chart rerender
 * @param { string } salt - id addition
 * @param { React.ElementRef } chartRef - chart container ref
 * @param { number } canvasWidth
 * @param { number } canvasWidth
 * @param {{ x: string, y: string }} axisLabels
 * @param { Array<object> } rectangles
 * @param { function } onCellEnter - on rectangle enter callback
 * @param { function } onLeave - on rectangles matrix leave callback
 * @param { object } classes
 * @param { object } mockData - data for graph to correctly display axises (x is x-ticks y is max value)
 * @param { array } colorTicks
 * @param { array } xTicks
 * @param { array } yTicks
 * @param { object } levels
 * @param { number } currentLevel
 * @param { function } onLevelSelect
 */
const ChartComponent = React.memo(
  ({
    salt,
    chartRef,
    canvasWidth,
    canvasHeight,
    currentLevel,
    onLevelSelect,
    axisLabels,
    rectangles,
    onCellEnter,
    onLeave,
    classes,
    mockData,
    xTicks,
    yTicks,
    levels,
    colorTicks,
  }) => {
    const withLevels = !!levels?.type;
    const chartMargin = withLevels
      ? { ...GRAPH_MARGIN, left: CANVAS_OFFSET + LEVELS_FULL_LENGTH }
      : GRAPH_MARGIN;
    const chartWidth =
      canvasWidth + chartMargin.left + chartMargin.right + AXIS_WIDTH;
    const chartHeight =
      canvasHeight + chartMargin.top + chartMargin.bottom + AXIS_WIDTH;

    return (
      <ComposedChart
        id={CHART_SVG_ID + salt}
        ref={chartRef}
        width={chartWidth}
        height={chartHeight}
        margin={chartMargin}
        className={classes.graphContainer}
        data={mockData}
      >
        <XAxis
          ticks={xTicks}
          scale="linear"
          height={AXIS_WIDTH}
          label={{
            value: axisLabels.x,
            position: 'bottom',
            dy: -15,
          }}
          domain={[xTicks[0], xTicks[xTicks.length - 1]]}
          dataKey="x"
          interval={0}
          tick={{ fontSize: TICK_FONT_SIZE }}
          tickFormatter={(tick) => getPrettyNumber(tick, 1)}
        />
        <YAxis
          width={AXIS_WIDTH}
          ticks={yTicks}
          scale="linear"
          domain={[yTicks[0], yTicks[yTicks.length - 1]]}
          interval={0}
          tick={{ fontSize: TICK_FONT_SIZE }}
          label={{
            value: axisLabels.y,
            angle: -90,
            position: 'center',
            dx: -20,
          }}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Customized
          key={1}
          component={ProbabilityMatrix}
          rectangles={rectangles}
          onCellEnter={onCellEnter}
          onLeave={onLeave}
        />
        <Customized
          key={2}
          component={CustomColorBar}
          barWidth={COLOR_BAR_WIDTH}
          leftOffset={COLOR_BAR_OFFSET}
          ticks={colorTicks}
          label={axisLabels.probability}
        />
        {withLevels && (
          <Customized
            key={3}
            levels={levels.values}
            type={levels.type}
            barWidth={DEFAULT_LEVELS_BAR_WIDTH}
            selectedLevel={currentLevel}
            onSelect={onLevelSelect}
            xOffset={LEVELS_BAR_OFFSET}
            component={CustomLevelsBar}
          />
        )}
      </ComposedChart>
    );
  }
);

/**
 * JointProbabilityDistribution graph component
 * @param { widgets } expected widgets list
 * @param { object } annualData
 * @param { object|null } monthlyData
 * @param { object|null } annualDataWidget
 * @param { object|null } monthlyDataWidget
 * @param { number } canvasWidth
 * @param { number } canvasHeight
 * @param { number } projectId
 * @param { Array } statsIds
 * @param { string } pngFileName - file name for saving as png
 * @returns { JSX }
 */
const JointProbabilityDistribution = ({
  widgets,
  annualData,
  monthlyData,
  annualDataWidget,
  monthlyDataWidget,
  canvasWidth,
  canvasHeight,
  projectId,
  statsIds,
  pngFileName,
}) => {
  const salt = useUniqueId();
  const classes = useStyles({ canvasOffset: CANVAS_OFFSET });
  const [monthNumber, setMonthNumber] = useState(ANNUAL_INDEX);
  const [levelIndex, setLevelIndex] = useState(0);
  const [pdSelectRange, setPdSelectRange] = useState(null);
  const [tooltip, setTooltip] = useState(ANNUAL_INDEX);
  const [titleData, setTitleData] = useState({
    periodicity: 'Annual',
    sector: '',
  });

  const wrapperRef = useRef(null);
  const chartRef = useRef(null);
  const yLimit = canvasHeight + GRAPH_MARGIN.top - TOOLTIP_Y_LIMIT;

  const resetPdSelect = useCallback(() => {
    setPdSelectRange(null);
    setTitleData((prevData) => ({
      ...prevData,
      sector: '',
    }));
  }, [setPdSelectRange]);

  const selectMonth = useCallback(
    (numberOfMonth) => {
      const periodicity =
        numberOfMonth === ANNUAL_INDEX
          ? 'Annual'
          : MONTH_NAMES[numberOfMonth - 1];
      setMonthNumber(numberOfMonth);
      setTitleData((prevData) => ({
        ...prevData,
        periodicity,
      }));
    },
    [setMonthNumber, setTitleData]
  );

  const selectRange = useCallback(
    (range, sectorString) => {
      setPdSelectRange(range);
      setTitleData((prevData) => ({
        ...prevData,
        sector: sectorString,
      }));
    },
    [setPdSelectRange, setTitleData]
  );

  const onCellEnter = useCallback(
    ({ currentTarget, clientX, clientY }) => {
      const xIndex = currentTarget.getAttribute('data-x-index');
      const yIndex = currentTarget.getAttribute('data-y-index');

      const {
        top: wrapperY,
        left: wrapperX,
      } = wrapperRef.current.getBoundingClientRect();
      const { container: chartContainer } = chartRef.current;
      const { top: chartY } = chartContainer.getBoundingClientRect();
      const yFromWrapper = clientY - wrapperY;
      const yFromChart = clientY - chartY;

      setTooltip({
        xIndex,
        yIndex,
        x: clientX - wrapperX,
        y: yFromWrapper,
        xyKey: getKeyByXY(xIndex, yIndex),
        yPosition: yFromChart > yLimit ? TOP : BOTTOM,
      });
    },
    [wrapperRef, chartRef, setTooltip, yLimit]
  );

  const onChartLeave = useCallback(() => setTooltip(null), [setTooltip]);

  const isAnnual = monthNumber === ANNUAL_INDEX;
  const {
    units,
    xName,
    yName,
    levels,
    axisLabels,
    xValuesBin,
    yValuesBin,
    xSelectLabel,
    ySelectLabel,
    xOptionsList,
    yOptionsList,
    nestingOrder,
    tooltipLabels,
    title: initialTitle,
    probabilityBin: initialProbabilityBin,
  } = isAnnual ? annualData : monthlyData;

  const { handleChange, params: selectParams, index } = useGraphJointSelects({
    names: [xName, yName],
    firstSelectSize: xOptionsList.length,
  });

  const withMonths = widgets.includes(GRAPHS_WIDGETS.months);
  const withLevels = !!(levels && Object.keys(levels).length);
  const withPDSelect = widgets.includes(GRAPHS_WIDGETS.vclass);
  const pdSelectData = isAnnual ? annualDataWidget : monthlyDataWidget;
  const {
    leafsOccurrences,
    data: preparedPdSelectData,
    probabilities: probabilitiesForSelect,
  } = pdSelectData || {};

  const probabilityBin = useMemo(() => {
    if (!pdSelectRange || !probabilitiesForSelect) {
      return initialProbabilityBin;
    }
    return getAverageProbabilityBinByRange({
      monthNumber,
      leafsOccurrences,
      probabilities: probabilitiesForSelect,
      range: pdSelectRange,
    });
  }, [
    pdSelectRange,
    monthNumber,
    leafsOccurrences,
    initialProbabilityBin,
    probabilitiesForSelect,
  ]);

  const dataBins = useMemo(
    () =>
      getJpdDataBins({
        units,
        xValuesBin,
        yValuesBin,
        nestingOrder,
        tooltipLabels,
        probabilityBin,
        monthNumber,
        levelIndex: withLevels ? levelIndex : null,
      }),
    // eslint-disable-next-line
    [monthNumber, levelIndex, probabilityBin]
  );

  const monthIndex = monthNumber - 1;
  const { periodicity, sector } = titleData;
  const preparedTitle = `${initialTitle}${
    periodicity ? EOL + periodicity : ''
  }${sector ? `, sector: ${sector}` : ''}`;

  const {
    mockData,
    rectangles,
    xTicks,
    yTicks,
    colorTicks,
    tooltipData,
  } = dataBins[index];

  return (
    <div ref={wrapperRef} className={classes.wrapper}>
      <Typography
        id={CHART_TITLE_ID + salt}
        className={classes.title}
        variant="subtitle1"
        align="center"
      >
        {preparedTitle}
      </Typography>
      <Grid justifyContent="center" container>
        <Grid item>
          <Grid container direction="column" spacing={4} alignItems="center">
            <Grid
              item
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid
                item
                container
                alignItems="center"
                justifyContent={withPDSelect ? 'flex-end' : 'center'}
                spacing={2}
              >
                {withLevels && <Grid item xs={2} />}
                <Grid item>
                  <GraphParamSelect
                    name={xName}
                    variant="outlined"
                    label={xSelectLabel}
                    value={selectParams[xName]}
                    values={xOptionsList}
                    units={units.x}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item>
                  <GraphParamSelect
                    name={yName}
                    label={ySelectLabel}
                    value={selectParams[yName]}
                    values={yOptionsList}
                    units={units.y}
                    onChange={handleChange}
                  />
                </Grid>
                {withPDSelect && (
                  <Grid item xs={4}>
                    <div className={classes.pdSelect}>
                      {preparedPdSelectData ? (
                        <ProbabilityDistributionSelect
                          radius={60}
                          onSelect={selectRange}
                          onDeselect={resetPdSelect}
                          preparedData={
                            isAnnual
                              ? preparedPdSelectData
                              : preparedPdSelectData[monthIndex]
                          }
                        />
                      ) : (
                        <Loader justifyCenter opacity={0.7} />
                      )}
                    </div>
                  </Grid>
                )}
              </Grid>
              <Grid item container justifyContent="center">
                <Grid item>
                  <ChartComponent
                    salt={salt}
                    chartRef={chartRef}
                    onCellEnter={onCellEnter}
                    onLeave={onChartLeave}
                    canvasWidth={canvasWidth}
                    canvasHeight={canvasHeight}
                    axisLabels={axisLabels}
                    rectangles={rectangles}
                    tooltipData={tooltipData}
                    classes={classes}
                    mockData={mockData}
                    colorTicks={colorTicks}
                    levels={levels}
                    currentLevel={levelIndex}
                    onLevelSelect={setLevelIndex}
                    xTicks={xTicks}
                    yTicks={yTicks}
                  />
                </Grid>
              </Grid>
            </Grid>
            {withMonths && (
              <Grid item>
                {monthlyData ? (
                  <SelectMonthButtonGroup
                    withAnnual
                    value={monthNumber}
                    setMonth={selectMonth}
                  />
                ) : (
                  <HorizontalLoader justifyCenter opacity={0.7} />
                )}
              </Grid>
            )}
            <DownloadGraphDataFileButtonGroup
              salt={salt}
              projectId={projectId}
              statsIds={statsIds}
              currentLevel={withLevels ? levelIndex : null}
              monthNumber={monthNumber}
              pdSelectRange={pdSelectRange}
              pngFileName={pngFileName}
            />
          </Grid>
        </Grid>
      </Grid>
      {!!tooltip && (
        <BaseTooltip
          x={tooltip.x}
          y={tooltip.y}
          offsetX={TOOLTIP_OFFSET.x}
          offsetY={TOOLTIP_OFFSET.y}
          yPosition={tooltip.yPosition}
        >
          {tooltipData.x[tooltip.xIndex]}
          {EOL}
          {tooltipData.y[tooltip.yIndex]}
          {EOL}
          <strong>{tooltipData.probability[tooltip.xyKey]}</strong>
        </BaseTooltip>
      )}
    </div>
  );
};

const commonDataShape = {
  units: PropTypes.shape({
    x: PropTypes.string,
    y: PropTypes.string,
    probability: PropTypes.string,
  }),
  xName: PropTypes.string,
  yName: PropTypes.string,
  title: PropTypes.string,
  levels: PropTypes.object,
  xValuesBin: PropTypes.arrayOf(PropTypes.array),
  yValuesBin: PropTypes.arrayOf(PropTypes.array),
  probabilityBin: PropTypes.arrayOf(PropTypes.array),
  axisLabels: PropTypes.object,
  xSelectLabel: PropTypes.string,
  ySelectLabel: PropTypes.string,
  xOptionsList: PropTypes.arrayOf(PropTypes.number),
  yOptionsList: PropTypes.arrayOf(PropTypes.number),
  nestingOrder: PropTypes.string,
  tooltipLabels: PropTypes.object,
};

JointProbabilityDistribution.propTypes = {
  canvasWidth: PropTypes.number,
  canvasHeight: PropTypes.number,
  annualData: PropTypes.shape(commonDataShape),
  monthlyData: PropTypes.shape(commonDataShape),
  annualDataWidget: PropTypes.object,
  monthlyDataWidget: PropTypes.object,
  widgets: PropTypes.arrayOf(PropTypes.string),
  projectId: PropTypes.number,
  statsIds: PropTypes.array,
  pngFileName: PropTypes.string,
};

JointProbabilityDistribution.defaultProps = {
  canvasWidth: DEFAULT_GRAPH_CANVAS_WIDTH,
  canvasHeight: DEFAULT_GRAPH_CANVAS_HEIGHT,
  monthlyData: null,
  annualDataWidget: null,
  monthlyDataWidget: null,
  widgets: [],
};

export default JointProbabilityDistribution;
