import React from 'react';
import { useTranslation } from 'react-i18next';
import PropsTypes from 'prop-types';
import { Grid, StepLabel, Step, Stepper } from '@material-ui/core';

import { STEP_NAMES } from 'constants/marineContractors';

import { useStyles } from './styles';

/**
 * Marine constractors project component
 * Show the stepper and wrap all marine contractors container/components
 * @param { JSX } children - Marine contractors step component
 * @param { number } activeStep
 * @returns
 */
const MCProjectComponent = ({ children, activeStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.projectContainer}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Stepper activeStep={activeStep}>
            {STEP_NAMES.map((label) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>
                    {t(`marineContractors.${label}.name`)}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
      </Grid>
      {children}
    </div>
  );
};

MCProjectComponent.propTypes = {
  children: PropsTypes.node.isRequired,
  activeStep: PropsTypes.number.isRequired,
};

export default React.memo(MCProjectComponent);
