import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  baseStyle: {
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    width: theme.baseSideBarWidth,
    top: 0,
    left: 0,
    bottom: 0,
    overflowY: 'auto',
  },
}));
