import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, CircularProgress, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

import BaseModal from 'components/common/BaseModal';
import NativeLink from 'components/common/NativeLink';
import { ROUTES } from 'constants/routes';

import { useStyles } from './styles';

/**
 * Modal with compute satellite information
 * @param {Function} onClose
 * @param {Function} onLaunch
 * @param {String} pointName
 * @param {String} email
 * @param {Boolean} loading
 * @param {Boolean} allowed
 * @return {JSX}
 * @constructor
 */
const ComparisonAgainstSatelliteModal = ({
  allowed,
  onClose,
  onLaunch,
  pointName,
  email,
  loading,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const controls = (
    <div className={classes.buttons}>
      <Button onClick={onClose} className={classes.closeButton}>
        {t('buttons.close')}
      </Button>
      <Button
        onClick={onLaunch}
        disabled={loading || !allowed}
        className={classes.launchButton}
      >
        {loading && <CircularProgress size={25} className={classes.progress} />}
        {t('buttons.launch')}
      </Button>
    </div>
  );

  return (
    <BaseModal
      title={t('points.comparisonAgainstSatelliteTitle', { pointName })}
      onClose={onClose}
      modalClass={classes.comparisonAgainstModal}
      controls={controls}
    >
      <Typography align="center" className={classes.comparisonText}>
        {t('points.comparisonAgainstSatellite', {
          projectName: 'Metocean Analytics',
        })}
        &nbsp;
        {t('points.learnMoreAbout')}&nbsp;
        <NativeLink to={ROUTES.helpSatelliteComparison}>
          {t('points.howWeDoThis')}
        </NativeLink>
        .
      </Typography>
      <Typography align="center" className={classes.comparisonText}>
        {t('points.comparisonTime')}&nbsp;
        {email && t('points.emailSentMessage', { email })}
      </Typography>
      <Typography align="center" className={classes.comparisonText}>
        {t('common.in')}&nbsp;
        <NativeLink to={ROUTES.helpValidation}>
          {t('common.thisPage')}
        </NativeLink>
        &nbsp;
        {t('points.explainInformation')}
      </Typography>
    </BaseModal>
  );
};

ComparisonAgainstSatelliteModal.propTypes = {
  allowed: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onLaunch: PropTypes.func.isRequired,
  pointName: PropTypes.string.isRequired,
  email: PropTypes.string,
  loading: PropTypes.bool,
};

ComparisonAgainstSatelliteModal.defaultProps = {
  allowed: true,
  loading: false,
};

export default memo(ComparisonAgainstSatelliteModal);
