import React from 'react';
import PropTypes from 'prop-types';

import { useModal } from 'hooks/useModal';
import AskDownloadStatsModal from 'components/modals/AskDownloadStatsModal';

/**
 * AskDownloadStatsModal container
 * @param {String} id
 */
const AskDownloadStatsContainer = ({ id }) => {
  const { onClose } = useModal(id);

  return <AskDownloadStatsModal onClose={onClose} />;
};

AskDownloadStatsContainer.propTypes = {
  id: PropTypes.string.isRequired,
};

export default React.memo(AskDownloadStatsContainer);
