import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {
  Waves,
  Wind,
  NavigationArrow,
  Compass,
  Spiral,
  Timer,
} from 'phosphor-react';

import { MCWeatherGraph } from 'components/common/graphs/WeatherGraph';
import lessOrEqual from 'assets/images/icons/less-than-or-equal.svg';
import less from 'assets/images/icons/less-than.svg';
import greaterOrEqual from 'assets/images/icons/greater-than-or-equal.svg';
import greater from 'assets/images/icons/greater-than.svg';

import { useStyles } from './styles';

// aggressively copied from : https://v4.mui.com/components/tabs/
const TabPanel = ({ children, ...other }) => (
  <div role="tabpanel" {...other}>
    <Box p={3}>{children}</Box>
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
};

// aggressively copied from : https://v4.mui.com/components/tabs/
const a11yProps = (index) => ({
  id: `scrollable-auto-tab-${index}`,
  'aria-controls': `scrollable-auto-tabpanel-${index}`,
});

// aggressively copied from : https://v4.mui.com/components/tabs/
const a11yPropsVert = (index) => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`,
});

const ConstraintIcon = ({ label }) => {
  switch (label) {
    case 'wlev':
      return <Waves size={20} color="#4451c7" />;
    case 'theta':
      return <NavigationArrow size={20} color="#4451c7" />;
    case 'mag':
      return <Spiral size={20} color="#4451c7" />;
    case 'magw':
      return <Wind size={20} color="#4451c7" />;
    case 'thetaw':
      return <Compass size={20} color="#4451c7" />;
    case 'hs':
      return <Waves size={20} color="#4451c7" />;
    case 'tp':
      return <Waves size={20} color="#4451c7" />;
    case 'dp':
      return <Waves size={20} color="#4451c7" />;
    default:
      return <></>;
  }
};

/* eslint-disable*/
const ConditionIcon = ({ label }) => {
  switch (label) {
    case 'LESS_EQUAL':
      return <img src={lessOrEqual} alt='' width='12px'/>;
    case 'LESS':
      return <img src={less} alt='' width='12px'/>;
    case 'GREATER':
      return <img src={greater} alt='' width='12px'/>;
    case 'GREATER_EQUAL':
      return <img src={greaterOrEqual} alt='' width='12px'/>;
    default:
      return <></>;
  }
};
/* eslint-enable */

/**
 *
 * @param { Array } boats - boats object array (name and id attr used here)
 * @param { Array } points - points object array (name and id attr used here)
 * @param { Array } operations - filtered results output by boatId and pointId
 * @param { Object } boat - selected boat
 * @param { Object } point - selected point
 * @param { Array } percentiles
 * @param { Function } handleChangeBoats
 * @param { Function } handleChangePoints
 * @returns { JSX }
 */
const MCStatisticsComponent = ({
  boats,
  points,
  operations,
  boat,
  point,
  percentiles,
  handleReset,
  handleChangeBoats,
  handleChangePoints,
  handleDownload,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const graphProps = {
    width: 300,
    height: 300,
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      id="MCStatisticsComponent"
    >
      <Grid item className={classes.statisticsContainer}>
        {/* header */}
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <h1 className={classes.title}>
              {t('marineContractors.statistics.title')}
            </h1>
          </Grid>
          <Grid item>
            <AppBar
              position="static"
              color="default"
              elevation={0}
              className={classes.appBar}
            >
              <Tabs
                value={boat.id}
                onChange={handleChangeBoats}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                {boats.map(({ name, id }, index) => (
                  <Tab
                    key={`boat-${id}`}
                    label={name}
                    value={id}
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item className={classes.buttonsContainer}>
            <Button className={classes.launchButton} onClick={handleReset}>
              {t('marineContractors.buttons.relaunch')}
            </Button>
            <Button onClick={handleDownload} className={classes.exportButton}>
              {t('marineContractors.buttons.exportAll')}
            </Button>
          </Grid>
        </Grid>
        {/* content */}
        <Grid container>
          <Grid item md={2} lg={1}>
            <TabPanel>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={point.id}
                onChange={handleChangePoints}
                className={classes.tabs}
              >
                {points.map(({ name, id }, pIndex) => (
                  <Tab
                    key={`point-${id}`}
                    label={name}
                    value={id}
                    className={classes.pointTab}
                    {...a11yPropsVert(pIndex)}
                  />
                ))}
              </Tabs>
            </TabPanel>
          </Grid>
          <Grid item md={10} lg={11}>
            {/* operations content */}
            <Grid
              container
              justifyContent="center"
              className={classes.operationsDashboard}
            >
              {/* eslint-disable */}
              {operations.map(({ data, name, id, format, constraints, duration }) => (
                <Grid
                  key={`operation-${id}`}
                  item
                  className={classes.operationContainer}
                >
                  <div className={classes.operationCard}>
                    <div className={classes.operationCardHeader}>
                      <Typography variant="h4" className={classes.operationTitle}>
                        {name}{' '}
                      </Typography>
                    </div>
                    <div className={classes.operationConstraintsContainer}>
                      <div className={classes.constraintContainer}>
                        <Timer size={20} color="#4451c7" />
                        <div className={classes.constraintLabelContainer}>
                          <p className={classes.constraintLabel}>
                            {t(`marineContractors.results.duration`)} :
                          </p>
                          <p className={classes.constraintLimit}>
                            {duration} h
                          </p>
                        </div>
                      </div>
                      {constraints.map((c) => (
                        <div key={c.id} className={classes.constraintContainer}>
                          <ConstraintIcon label={c.varName} />
                          <div className={classes.constraintLabelContainer}>
                            <p className={classes.constraintLabel}>
                              {/* eslint-disable-next-line */}
                              {t(`marineContractors.results.constraints.${c.varName}`)}
                            </p>
                            <p className={classes.constraintLimit}>
                              {/* eslint-disable-next-line */}
                              <ConditionIcon label={c.condition} /> {c.limit} {c.unit}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <MCWeatherGraph
                      data={data}
                      percentiles={percentiles}
                      format={format}
                      graphProps={graphProps}
                    />
                  </div>
                </Grid>
              ))}
              {/* eslint-enable */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

MCStatisticsComponent.propTypes = {
  boats: PropTypes.array.isRequired,
  points: PropTypes.array.isRequired,
  operations: PropTypes.array.isRequired,
  boat: PropTypes.object.isRequired,
  point: PropTypes.object.isRequired,
  percentiles: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleReset: PropTypes.func.isRequired,
  handleChangeBoats: PropTypes.func.isRequired,
  handleChangePoints: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
};

export default React.memo(MCStatisticsComponent);
