import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  row: {
    marginBottom: 5,
    '& input': {
      padding: '10px 0',
      color: palette.primary.main,
    },
  },
  fieldName: {
    textTransform: 'uppercase',
    fontSize: 14,
    color: palette.primary.main,
  },
  fullWidth: {
    width: '100%',
  },
  coordinateText: {
    // Overwrite leaflet styles
    margin: '20px 0 !important',
    textAlign: 'center',
    fontSize: 12,
  },
  coordinateField: {
    '& .Mui-disabled': {
      background: palette.lightGrey.main,
    },
  },
  coordinateFieldNumber: {
    position: 'absolute',
  },
  helperText: {
    height: 20,
    wordWrap: 'break-word',
  },
}));
