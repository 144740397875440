import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useModal } from 'hooks/useModal';
import { resendUserInvitationWaitingRequest } from 'ducks/app/actions';
import UserInvitationManagementModal from 'components/modals/UserInvitationManagementModal';

/**
 * ResendInvitationModal container
 * Displays modal to resend user invitation
 * @param id - modal id
 * @param user - user data
 */
const ResendInvitationModal = ({ id, user }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { onClose } = useModal(id);
  const resendInvitation = useCallback(() => {
    dispatch(resendUserInvitationWaitingRequest({ user, modalId: id }));
  }, [dispatch, id, user]);

  return (
    <UserInvitationManagementModal
      onClose={onClose}
      invitationButtonHandler={resendInvitation}
      messageText={t('collaborators.resendInvitationMessage')}
      invitationButtonText={t('buttons.resend')}
      modalTitle={t('collaborators.resendInvitation')}
    />
  );
};

ResendInvitationModal.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

export default memo(ResendInvitationModal);
