import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Tab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import {
  getFieldsWithStatisticsData,
  getVariableName,
} from 'helpers/satelliteComparison';
import ComparisonAgainstSatelliteRows from 'components/projects/ComparisonAgainstSatelliteRows';
import BuoyMetadata from 'components/buoys/BuoyMetadata';
import ScatterPlot from 'components/common/graphs/ScatterPlot';
import { getPreparedScatterPlotData } from 'helpers/graphs/scatterPlot';

import { useStyles } from './styles';
import definitionTabs from './definitionTabs.json';

const DefinitionContent = ({ id }) => {
  const classes = useStyles();
  const tabContent = definitionTabs.find((tab) => tab.id === id);
  return (
    <>
      {tabContent.sections.map((item) => (
        <div
          key={item.id}
          className={classNames(classes.section, {
            [classes[item.customClass]]: item.customClass,
          })}
          dangerouslySetInnerHTML={{ __html: item.content }}
        ></div>
      ))}
    </>
  );
};

/**
 * Component with metadata and charts.
 * @param {Object} statistics
 * @param {Object} buoy
 * @return {jsx}
 */
const ComparisonAgainstMeasurements = ({ statistics, buoy }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { statisticsByTab, tabs } = useMemo(() => {
    const statsByTab = getFieldsWithStatisticsData(statistics);
    return {
      statisticsByTab: statsByTab,
      tabs: [...Object.keys(statsByTab).sort()],
    };
  }, [statistics]);

  const [statisticTab, setStatisticTab] = useState(tabs[0]);
  const [definitionTab, setDefinitionTab] = useState(null);

  const statisticData = statisticsByTab[statisticTab];
  const scatterData =
    statisticData && getPreparedScatterPlotData(statisticData);

  const handleChangeDefinitionTab = (_e) => {
    const id = _e.target.offsetParent.id;
    setDefinitionTab(id);
    setStatisticTab(null); // reset
  };

  const handleChangeStatisticTab = (_e) => {
    const id = _e.target.offsetParent.id;
    setStatisticTab(id);
    setDefinitionTab(null); // reset
  };

  const resetTabs = () => {
    setDefinitionTab(null);
    setStatisticTab(null);
  };

  return (
    <div>
      {/* comparison tabs */}
      <div className={classes.tabsContainer}>
        {tabs && tabs.length > 0 ? (
          <div className={classes.statisticsTabs}>
            {tabs.map((label) => (
              <Tab
                label={getVariableName(label)}
                key={label}
                id={label}
                selected={statisticTab === label}
                onClick={handleChangeStatisticTab}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              ></Tab>
            ))}
          </div>
        ) : (
          <Tab
            onClick={resetTabs}
            label="Metadata"
            selected={!definitionTab}
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
          />
        )}
        <div className={classes.definitionTabs}>
          {definitionTabs.map((item) => (
            <Tab
              label={item.label}
              key={item.id}
              id={item.id}
              selected={definitionTab === item.id}
              onClick={handleChangeDefinitionTab}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            ></Tab>
          ))}
        </div>
      </div>
      {/* content */}
      <div className={classes.dataContainer}>
        {statisticTab && scatterData && (
          <>
            <ScatterPlot commonData={scatterData} />
            <ComparisonAgainstSatelliteRows rows={statisticData.rows} />
            <BuoyMetadata buoy={buoy} />
          </>
        )}
        {!statisticTab && definitionTab && (
          <DefinitionContent id={definitionTab} />
        )}
        {!statisticTab && !definitionTab && (
          <>
            <Typography align="center">
              {t('points.visualizationDataIsEmpty')}
            </Typography>
            <BuoyMetadata buoy={buoy} />
          </>
        )}
      </div>
    </div>
  );
};

ComparisonAgainstMeasurements.propTypes = {
  statistics: PropTypes.object.isRequired,
  buoy: PropTypes.shape({
    name: PropTypes.string,
    centre: PropTypes.string,
    geom: PropTypes.string,
    dateBegin: PropTypes.string,
    dateEnd: PropTypes.string,
    sensors: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default memo(ComparisonAgainstMeasurements);
