import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  tableContainer: {
    margin: '0 auto',
    maxWidth: 1200,
  },
  row: {
    '&:hover': {
      backgroundColor: palette.lightGrey.light,
    },
  },
  emptyMessage: {
    padding: 5,
    fontSize: 14,
    backgroundColor: palette.lightGrey.light,
  },
  header: {
    fontWeight: 'bold',
  },
}));
