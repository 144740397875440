import { MAP_LABEL_CLASSNAME } from 'constants/map';

const DEFAULT_REDRAW_EVENT = 'move';
const HORIZONTAL_AXIS = 'horizontal-axis';
const VERTICAL_AXIS = 'vertical-axis';
const DEFAULT_COLOR = '#111';
const LEAFLET_EVENT_PREFIX = 'viewreset';
const MAX_Y_AXIS_VALUE = 90;

const DEFAULT_OPTIONS = {
  interval: 20,
  showOriginLabel: false,
  redraw: DEFAULT_REDRAW_EVENT,
  hidden: false,
  zoomIntervals: [],
  digits: 0,
  color: DEFAULT_COLOR,
};

const DEFAULT_LINE_STYLE = {
  stroke: true,
  color: DEFAULT_COLOR,
  opacity: 0.6,
  weight: 0.5,
  interactive: false,
  clickable: false,
};

/**
 * Return line coordinate
 * @param {Number} position
 * @param {Number} interval
 * @param {Number} angle
 * @returns {number}
 */
const getLineCoordinate = (position, interval, angle) =>
  Math.max(angle, Math.floor(position / interval) * interval);

/**
 * Calculate count of lines on map range
 * @param {Number} range
 * @param {Number} interval
 * @param {Number} maxCoordinate
 * @returns {number}
 */
const getLineCounts = ({ range, interval, maxCoordinate }) =>
  Math.ceil(Math.min(range, maxCoordinate) / interval);

/**
 * Leaflet decorator.
 * Adds graticule functionality to given Leaflet global object
 * @param { object } Leaflet
 * @returns { object } Leaflet global object with `simpleGraticule` method
 * based on this solution:
 * @see https://github.com/ablakey/Leaflet.SimpleGraticule
 */
export const withGraticule = (Leaflet) => {
  Leaflet.SimpleGraticule = Leaflet.LayerGroup.extend({
    options: DEFAULT_OPTIONS,

    lineStyle: DEFAULT_LINE_STYLE,

    initialize(options) {
      Leaflet.LayerGroup.prototype.initialize.call(this);
      Leaflet.Util.setOptions(this, options);
      this.lineStyle.color = this.options.color;
    },

    onAdd(map) {
      this.mapInstance = map;

      const self = this.redraw();
      this.mapInstance.on(
        `${LEAFLET_EVENT_PREFIX} ${this.options.redraw}`,
        self.redraw,
        self
      );

      this.eachLayer(map.addLayer, map);
    },

    onRemove(map) {
      map.off(`${LEAFLET_EVENT_PREFIX} ${this.options.redraw}`, map);
      this.eachLayer(this.removeLayer, map);
    },

    hide() {
      this.options.hidden = true;
      this.redraw();
    },

    show() {
      this.options.hidden = false;
      this.redraw();
    },

    redraw() {
      this.currentBounds = this.mapInstance.getBounds().pad(0.5);

      this.clearLayers();

      if (!this.options.hidden) {
        const currentZoom = this.mapInstance.getZoom();

        for (let i = 0; i < this.options.zoomIntervals.length; i += 1) {
          if (
            currentZoom >= this.options.zoomIntervals[i].start &&
            currentZoom <= this.options.zoomIntervals[i].end
          ) {
            this.options.interval = this.options.zoomIntervals[i].interval;
            this.options.digits = this.options.zoomIntervals[i].digits;
            break;
          }
        }

        this.constructLines(this.getLineStartPoints(), this.getLineCounts());

        if (this.options.showOriginLabel) {
          this.addLayer(this.addOriginLabel());
        }
      }

      return this;
    },

    getLineCounts() {
      const { interval } = this.options;
      return {
        x: getLineCounts({
          interval,
          range: this.currentBounds.getEast() - this.currentBounds.getWest(),
          maxCoordinate: 360,
        }),
        y: getLineCounts({
          interval,
          range: this.currentBounds.getNorth() - this.currentBounds.getSouth(),
          maxCoordinate: 180,
        }),
      };
    },

    getLineStartPoints() {
      const { interval } = this.options;
      return {
        x: getLineCoordinate(this.currentBounds.getWest(), interval, -180),
        y: getLineCoordinate(this.currentBounds.getSouth(), interval, -90),
      };
    },

    constructLines(startPoints, linesAmount) {
      const lines = new Array(linesAmount.x + linesAmount.y);
      const labels = new Array(linesAmount.x + linesAmount.y);

      for (let i = 0; i <= linesAmount.x; i += 1) {
        const x = startPoints.x + i * this.options.interval;
        lines.push(this.buildXLine(x));
        labels.push(this.buildLabel(HORIZONTAL_AXIS, x, this.options.digits));
      }

      for (let j = 0; j < linesAmount.y; j += 1) {
        const y = startPoints.y + j * this.options.interval;
        if (y > MAX_Y_AXIS_VALUE) {
          break;
        }
        lines.push(this.buildYLine(y));
        labels.push(this.buildLabel(VERTICAL_AXIS, y, this.options.digits));
      }

      lines.forEach(this.addLayer, this);
      labels.forEach(this.addLayer, this);
    },

    buildXLine(x) {
      const bottomLatLng = new Leaflet.LatLng(this.currentBounds.getSouth(), x);
      const topLatLng = new Leaflet.LatLng(this.currentBounds.getNorth(), x);

      return new Leaflet.Polyline([bottomLatLng, topLatLng], this.lineStyle);
    },

    buildYLine(y) {
      const leftLatLng = new Leaflet.LatLng(
        y,
        Math.max(this.currentBounds.getWest(), -180)
      );
      const rightLatLng = new Leaflet.LatLng(
        y,
        Math.min(this.currentBounds.getEast(), 180)
      );

      return new Leaflet.Polyline([leftLatLng, rightLatLng], this.lineStyle);
    },

    addGridLabel(axis, latLng, preparedValue) {
      return Leaflet.marker(latLng, {
        interactive: false,
        clickable: false,
        icon: Leaflet.divIcon({
          iconSize: [0, 0],
          className: MAP_LABEL_CLASSNAME,
          html: `<div class="${axis}-label">${preparedValue}°</div>`,
        }),
      });
    },

    buildLabel(axis, val, digits) {
      const bounds = this.mapInstance.getBounds().pad(-0.003);
      const preparedValue = val.toFixed(digits);
      if (axis === VERTICAL_AXIS) {
        const latLng = new Leaflet.LatLng(
          val,
          Math.max(bounds.getWest(), -180)
        );
        return this.addGridLabel(axis, latLng, preparedValue);
      }
      const pointLatLng = new Leaflet.LatLng(bounds.getSouth(), val);
      if (Math.round(bounds.getSouth()) >= -90) {
        const point = this.mapInstance.latLngToContainerPoint(pointLatLng);
        const newPoint = Leaflet.point([point.x, point.y - 30]);
        const correctedLatLng = this.mapInstance.containerPointToLatLng(
          newPoint
        );
        return this.addGridLabel(axis, correctedLatLng, preparedValue);
      }
      return this.addGridLabel(axis, pointLatLng, preparedValue);
    },

    addOriginLabel() {
      return Leaflet.marker([0, 0], {
        interactive: false,
        clickable: false,
        icon: Leaflet.divIcon({
          iconSize: [0, 0],
          className: MAP_LABEL_CLASSNAME,
          html: `<div class="${HORIZONTAL_AXIS}-label">(0,0)</div>`,
        }),
      });
    },
  });

  Leaflet.simpleGraticule = (options) => new Leaflet.SimpleGraticule(options);

  return Leaflet;
};
