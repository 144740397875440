import { createSelector } from 'reselect';

import {
  EMPTY_VALUE,
  STATUS_LOADING,
  STATUS_SUCCESS,
  STATUS_NOT_REQUESTED,
} from 'constants/common';
import {
  MC_OBJECT_GROUP,
  MC_OBJECT_TYPE,
  NO_ID,
} from 'constants/marineContractors';
import { getMCObjectType } from 'helpers/marineContractors';

export const selectMarineContractorsData = ({ marineContractors }) =>
  marineContractors.data;
export const selectMarineContractorsStatus = ({ marineContractors }) =>
  marineContractors.status;
export const selectMarineContractorsError = ({ marineContractors }) =>
  marineContractors.error || EMPTY_VALUE;
export const selectMarineContractorsErrors = ({ marineContractors }) =>
  marineContractors.errors;
export const selectMarineContractorsProjectId = ({ marineContractors }) =>
  marineContractors.projectId;

export const selectMarineContractorsContractorsStatus = createSelector(
  selectMarineContractorsStatus,
  (status) => status.contractors
);
export const selectMarineContractorsBoatsStatus = createSelector(
  selectMarineContractorsStatus,
  (status) => status.boats
);
export const selectMarineContractorsVariablesStatus = createSelector(
  selectMarineContractorsStatus,
  (status) => status.variables
);
export const selectMarineContractorsJobStatus = createSelector(
  selectMarineContractorsStatus,
  (status) => status.job
);
export const selectMarineContractorsOperationsStatus = createSelector(
  selectMarineContractorsStatus,
  (status) => status.operations
);
export const selectMarineContractorsConstraintsStatus = createSelector(
  selectMarineContractorsStatus,
  (status) => status.constraints
);
export const selectMarineContractorsCustomerStatus = createSelector(
  selectMarineContractorsStatus,
  (status) => status.customer
);
export const selectMarineContractorsResultsStatus = createSelector(
  selectMarineContractorsStatus,
  (status) => status.results
);

export const selectMarineContractorsBoatErrors = createSelector(
  selectMarineContractorsErrors,
  (errors) => errors.boats
);
export const selectMarineContractorsOperationErrors = createSelector(
  selectMarineContractorsErrors,
  (errors) => errors.operations
);
export const selectMarineContractorsConstraintErrors = createSelector(
  selectMarineContractorsErrors,
  (errors) => errors.constraints
);

export const selectMarineContractorsIsNotRequested = createSelector(
  selectMarineContractorsContractorsStatus,
  (status) => status === STATUS_NOT_REQUESTED
);
export const selectMarineContractorsIsLoading = createSelector(
  selectMarineContractorsContractorsStatus,
  (status) => status === STATUS_LOADING
);
export const selectMarineContractorsIsSuccess = createSelector(
  selectMarineContractorsContractorsStatus,
  (status) => status === STATUS_SUCCESS
);

export const selectMarineContractorsBoats = createSelector(
  selectMarineContractorsData,
  (data) => data.boats
);
export const selectMarineContractorsOperations = createSelector(
  selectMarineContractorsData,
  (data) => data.operations
);
export const selectMarineContractorsConstraints = createSelector(
  selectMarineContractorsData,
  (data) => data.constraints
);
export const selectMarineContractorsVariables = createSelector(
  selectMarineContractorsData,
  (data) => data.variables
);
export const selectMarineContractorsUnits = createSelector(
  selectMarineContractorsData,
  (data) => data.units
);
export const selectMarineContractorsCustomer = createSelector(
  selectMarineContractorsData,
  (data) => data.customer
);
export const selectMarineContractorsJob = createSelector(
  selectMarineContractorsData,
  (data) => data.job
);
export const selectMarineContractorsResults = createSelector(
  selectMarineContractorsData,
  (data) => data.results
);

export const selectMarineConstractorsHasActions = createSelector(
  selectMarineContractorsStatus,
  (status) => {
    const objectKeys = Object.values(MC_OBJECT_GROUP);
    const hasActions = objectKeys.some((k) => !!status[k].length);
    return hasActions;
  }
);

const formatError = (error, boats, operations) => {
  const objectType = getMCObjectType(error);
  switch (objectType) {
    case MC_OBJECT_TYPE.CONSTRAINT: {
      const operation = operations.find((o) => o.id === error.operation);
      const constraintBoat = boats.find((b) => b.id === operation.boat);
      return `Constraint error :\n-> Boat : ${constraintBoat.name}\n-> Operation: ${operation.name}`;
    }
    case MC_OBJECT_TYPE.OPERATION: {
      const operationBoat = boats.find((b) => b.id === error.boat);
      return `Operation ${error.name} error :\n-> Boat : ${operationBoat.name}`;
    }
    case MC_OBJECT_TYPE.BOAT:
      return `Boat ${error.name} error`;
    default:
      break;
  }
};

export const selectMarineConstractorsErrorsFormatted = createSelector(
  selectMarineContractorsErrors,
  selectMarineContractorsBoats,
  selectMarineContractorsOperations,
  (errors, boats, operations) => {
    const errorsFormatted = [];
    const objectGroups = Object.values(MC_OBJECT_GROUP);
    objectGroups.forEach((key) => {
      errors[key].forEach((e) => {
        const errorFormatted = formatError(e, boats, operations);
        if (errorFormatted) {
          errorsFormatted.push(errorFormatted);
        }
      });
    });
    return errorsFormatted;
  }
);

export const selectMarineContractorsBoatIsCreating = createSelector(
  selectMarineContractorsBoatsStatus,
  (status) => !!status.find((b) => b.id === NO_ID)
);
export const selectMarineContractorsBoatIsUpdating = (boat) =>
  createSelector(
    selectMarineContractorsBoatsStatus,
    (status) => !!status.find((b) => b.id === boat.id)
  );
export const selectMarineContractorsBoatError = (boat) =>
  createSelector(
    selectMarineContractorsBoatErrors,
    (errors) => errors.find((b) => b.id === boat.id)?.error || EMPTY_VALUE
  );
export const selectMarineContractorsBoatOperationsActions = (boat) =>
  createSelector(selectMarineContractorsOperationsStatus, (status) => [
    ...status.filter((o) => o.boat === boat.id),
  ]);

export const selectMarineContractorsOperationIsCreating = (boat) =>
  createSelector(
    selectMarineContractorsOperationsStatus,
    (status) => !!status.find((o) => o.id === NO_ID && o.boat === boat.id)
  );
export const selectMarineContractorsOperationIsUpdating = (operation) =>
  createSelector(
    selectMarineContractorsOperationsStatus,
    (status) => !!status.find((o) => o.id === operation.id)
  );
export const selectMarineContractorsOperationError = (operation) =>
  createSelector(
    selectMarineContractorsOperationErrors,
    (errors) =>
      errors.find((o) => o.id === operation.id && o.boat === operation.boat)
        ?.error || EMPTY_VALUE
  );
export const selectMarineContractorsOperationConstraintsActions = (operation) =>
  createSelector(selectMarineContractorsConstraintsStatus, (status) => [
    ...status.filter((c) => c.operation === operation.id),
  ]);
export const selectMarineContractorsOperationsConstraintsActions = (
  operations
) =>
  createSelector(selectMarineContractorsConstraintsStatus, (status) => [
    ...status.filter((c) => operations.map((o) => o.id).includes(c.operation)),
  ]);

export const selectMarineContractorsConstraintIsCreating = (constraint) =>
  createSelector(
    selectMarineContractorsConstraintsStatus,
    (status) =>
      !!status.find(
        (c) => constraint.id === NO_ID && c.operation === constraint.operation
      )
  );
export const selectMarineContractorsConstraintIsUpdating = (constraint) =>
  createSelector(
    selectMarineContractorsConstraintsStatus,
    (status) => !!status.find((c) => c.id === constraint.id)
  );
export const selectMarineContractorsConstraintError = (constraint) =>
  createSelector(
    selectMarineContractorsConstraintErrors,
    (errors) =>
      errors.find(
        (c) => c.id === constraint.id && c.operation === constraint.operation
      )?.error || EMPTY_VALUE
  );

export const selectMarineContractorsVariablesIsNotRequested = createSelector(
  selectMarineContractorsVariablesStatus,
  (status) => status === STATUS_NOT_REQUESTED
);
export const selectMarineContractorsVariablesIsLoading = createSelector(
  selectMarineContractorsVariablesStatus,
  (status) => status === STATUS_LOADING
);
export const selectMarineContractorsVariablesIsSuccess = createSelector(
  selectMarineContractorsVariablesStatus,
  (status) => status === STATUS_SUCCESS
);

export const selectMarineContractorsJobIsNotRequested = createSelector(
  selectMarineContractorsJobStatus,
  (status) => status === STATUS_NOT_REQUESTED
);
export const selectMarineContractorsJobIsLoading = createSelector(
  selectMarineContractorsJobStatus,
  (status) => status === STATUS_LOADING
);
export const selectMarineContractorsJobIsSuccess = createSelector(
  selectMarineContractorsJobStatus,
  (status) => status === STATUS_SUCCESS
);

export const selectMarineContractorsCustomerIsNotRequested = createSelector(
  selectMarineContractorsCustomerStatus,
  (status) => status === STATUS_NOT_REQUESTED
);
export const selectMarineContractorsCustomerIsLoading = createSelector(
  selectMarineContractorsCustomerStatus,
  (status) => status === STATUS_LOADING
);
export const selectMarineContractorsCustomerIsSuccess = createSelector(
  selectMarineContractorsCustomerStatus,
  (status) => status === STATUS_SUCCESS
);

export const selectMarineContractorsJobParameters = createSelector(
  selectMarineContractorsJob,
  (job) => job.parameters
);
export const selectMarineContractorsJobTaskStatus = createSelector(
  selectMarineContractorsJob,
  (job) => job.status
);
export const selectMarineContractorsJobOperations = createSelector(
  selectMarineContractorsJobParameters,
  (parameters) => parameters?.operations
);
export const selectMarineContractorsJobPoints = createSelector(
  selectMarineContractorsJobParameters,
  (parameters) => parameters?.points
);
export const selectMarineContractorsJobPercentiles = createSelector(
  selectMarineContractorsJobParameters,
  (parameters) => parameters?.percentiles
);
export const selectMarineContractorsJobOutputFormat = createSelector(
  selectMarineContractorsJobParameters,
  (parameters) => parameters?.outputFormat
);

export const selectMarineContractorsBoatById = (boatId) =>
  createSelector(selectMarineContractorsBoats, (boats) =>
    boats.find(({ id }) => id === boatId)
  );

// operation can be deleted but stay in the job parameter, so we need to filtered available operations
// click on `Next` button will override delete operations
export const selectMarineContractorsFilteredJobOperations = (operations) =>
  createSelector(selectMarineContractorsJobOperations, (jobOperations) =>
    operations.filter(({ id }) => jobOperations.map((o) => o.id).includes(id))
  );

export const selectMarineContractorsOperationsByBoatId = (boatId) =>
  createSelector(selectMarineContractorsOperations, (operations) =>
    operations.filter(({ boat }) => boat === boatId)
  );

export const selectMarineContractorsOperationById = (operationId) =>
  createSelector(selectMarineContractorsOperations, (operations) =>
    operations.find(({ id }) => id === operationId)
  );

export const selectMarineContractorsConstraintById = (constraintId) =>
  createSelector(selectMarineContractorsConstraints, (constraints) =>
    constraints.find(({ id }) => id === constraintId)
  );

export const selectMarineContractorsConstraintsByOperationId = (operationId) =>
  createSelector(selectMarineContractorsConstraints, (constraints) =>
    constraints.filter(({ operation }) => operation === operationId)
  );

export const selectMarineContractorsObjectError = (objectType) =>
  createSelector(selectMarineContractorsErrors, (errors) => errors[objectType]);
