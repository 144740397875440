import { useMemo } from 'react';

import { getBuoyIcon } from 'helpers/getBuoyIcon';

/**
 * Hook return icon for buoys by params
 * @param {String} [type]
 * @param {Array} sensors
 * @returns {MarkerIcon}
 */
export function useBuoyIcon({ type, sensors }) {
  return useMemo(() => getBuoyIcon(type, sensors), [type, sensors]);
}
