import { convertFromTextile } from './templateParser';

/**
 * parses static page into content blocks
 * each block contains one heading (h2, h3...) and different content
 * @param content - page content in textile format
 */
export const parseStaticPageIntoContentBlocks = (content) => {
  const parser = new DOMParser();
  const parsedContent = convertFromTextile(content);
  const htmlNode = parser.parseFromString(parsedContent, 'text/html');
  const nodes = [
    ...htmlNode.querySelectorAll('h1, h2, h3, h4, h5, p, div, ol, table'),
  ];
  return nodes.reduce((acc, item) => {
    const currentBlockIndex = acc.length - 1;
    if (['H1', 'H2', 'H3', 'H4', 'H5'].includes(item.tagName)) {
      acc.push({
        title: item.innerText,
        titleTag: item.tagName.toLowerCase(),
        titleId: `${item.innerText
          .toLowerCase()
          .replace(/[^\w\s]|_/g, '')
          .replace(/ /gi, '-')}`,
      });
    } else {
      acc[currentBlockIndex] = {
        ...acc[currentBlockIndex],
        content: acc[currentBlockIndex].content
          ? `${acc[currentBlockIndex].content}${item.outerHTML}`
          : item.outerHTML,
      };
    }
    return acc;
  }, []);
};
