import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';

import BaseModal from 'components/common/BaseModal';
import ComparisonAgainstSatelliteResult from 'components/projects/ComparisonAgainstSatelliteResult';
import ComparisonModalControls from 'components/common/ComparisonModalControls';

import { useStyles } from './styles';

/**
 * Modal with satellite result.
 * @param {Function} onClose
 * @param {String} pointName
 * @param {String} downloadLink
 * @param {Object} statistics
 * @return {jsx}
 */
const ComparisonAgainstSatelliteModal = ({
  onClose,
  pointName,
  statistics,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const downloadComponentAsPNG = async (componentId, fileName) => {
    const component = document.getElementById(componentId);

    if (!component) {
      console.error(`Element with ID "${componentId}" not found.`);
      return;
    }

    try {
      const canvas = await html2canvas(component);
      const image = canvas.toDataURL('image/png');

      const link = document.createElement('a');
      link.download = fileName || 'component.png';
      link.href = image;
      link.click();
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleDownload = () => {
    downloadComponentAsPNG('componentToDownload', 'SatelliteComparison.png');
  };

  return (
    <BaseModal
      title={t('points.comparisonAgainstSatelliteTitle', { pointName })}
      onClose={onClose}
      modalClass={classes.comparisonAgainstResultModal}
      controls={
        <ComparisonModalControls
          onClose={onClose}
          onClick={handleDownload}
          disableDownload={false}
        />
      }
    >
      <ComparisonAgainstSatelliteResult statistics={statistics} />
    </BaseModal>
  );
};

ComparisonAgainstSatelliteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  pointName: PropTypes.string.isRequired,
};

export default memo(ComparisonAgainstSatelliteModal);
