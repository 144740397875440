import { useCallback, useReducer } from 'react';

import { SET_ERRORS, SET_ERROR } from 'common/types/forms';
import createReducer from 'helpers/createReducer';

const validationReducerHandlers = {
  [SET_ERROR]: (state, { field, error }) => ({ ...state, [field]: error }),
  [SET_ERRORS]: (state, { payload }) => ({ ...state, ...payload }),
};

/**
 * useValidation hook.
 * takes validators object with shape { fieldName: Validator, ... }
 * and initial errors (sometimes it may be useful)
 * returns validate function, implementing all rules from provided Validators
 * and errors object { fieldName: ['error1', 'error2'], ... }
 * @param validators
 * @param initialErrors
 * @returns {{errors: {}, validate: (function(*=): boolean)}}
 * @note validate function doesn't decide whether all fields are valid or not
 * It just builds an object with errors, according to given rules and return it
 */
export function useValidation({ validators = {}, initialErrors = {} }) {
  const [errors, dispatch] = useReducer(
    createReducer({}, validationReducerHandlers),
    initialErrors
  );
  const setErrors = (payload) => dispatch({ type: SET_ERRORS, payload });
  const validate = (values) => {
    const validationErrors = Object.entries(validators).reduce(
      (acc, [field, validator]) => {
        if (!{}.hasOwnProperty.call(values, field)) {
          throw new Error(
            `Trying to validate not existing field. Check initialValues and validationRules`
          );
        }
        acc[field] = validator.validate(values[field]) ? '' : validator.error;
        return acc;
      },
      {}
    );
    setErrors(validationErrors);
    return validationErrors;
  };
  const validateOne = (field, value) => {
    const validator = validators[field];
    if (!validator) {
      return true;
    }
    const isValid = validator.validate(value);
    dispatch({ type: SET_ERROR, field, error: isValid ? '' : validator.error });
    return isValid;
  };

  return {
    errors,
    setErrors: useCallback(setErrors, []),
    validate: useCallback(validate, [validators]),
    validateOne: useCallback(validateOne, [validators]),
  };
}
