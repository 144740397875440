import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { isError, selectOfferDetail } from 'ducks/usage/selectors';
import { ROUTES } from 'constants/routes';
import DetailOfferComponent from 'components/usage/DetailOffer';
import { requestOfferDetail } from 'ducks/usage/actions';
/**
 * UsagePage container
 * Display usage informations
 */
const OfferOffer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const offer = useSelector(selectOfferDetail(id));
  const history = useHistory();
  const failed = useSelector(isError);

  useEffect(() => {
    if (failed) {
      history.replace(ROUTES.home);
    }
  }, [failed, history]);

  useEffect(() => {
    dispatch(requestOfferDetail(id));
  }, [id, dispatch]);

  return <DetailOfferComponent offer={offer} />;
};

export default OfferOffer;
