import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  CONFIRMATION_MODAL,
  START_ANALYSIS_MODAL,
  NOTIFICATION_MODAL,
} from 'constants/modals';
import { SNACKBAR_WARNING_TYPE } from 'constants/common';
import PointLocations from 'components/points/PointLocations';
import {
  deletePoint,
  deleteProjectPoints,
  createPointsFromFile,
} from 'ducks/points/actions';
import {
  computeProjectStatistics,
  cancelComputeProjectStatistics,
} from 'ducks/projects/actions';
import { useModal } from 'hooks/useModal';
import { userSelectors } from 'ducks/user';
import {
  selectProjectAvailableCreditsPointById,
  selectIsRunningProjectById,
  selectProjectOfferInstanceByProjectId,
  selectIsTraditionalProjectById,
} from 'ducks/projects/selectors';
import {
  selectHasUnlimitedDiscover,
  selectHasUnlimitedFullStudy,
} from 'ducks/user/selectors';
import { checkIsOfferUnlimited } from 'helpers/projects';
import { selectPointsByProject } from 'ducks/points/selectors';
import { trackPointsUpload } from 'ducks/trackers/actions/workzone';

/**
 * PointLocationsContainer - interactive with points
 * Delete all points, delete point
 * Upload points file
 * Generate statistics
 * @param {Number} projectId
 * @param {Boolean} allowEditAndCompute
 * @param {Object} props
 * @returns {jsx}
 */
const PointLocationsContainer = ({
  projectId,
  allowEditAndCompute,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const { credits, isRunningProject, projectNbPoints } = useSelector(
    (state) => ({
      credits: selectProjectAvailableCreditsPointById(state, projectId),
      isRunningProject: selectIsRunningProjectById(state, projectId),
      projectNbPoints: selectPointsByProject(state, projectId),
    })
  );
  const userEmail = useSelector(userSelectors.selectUserEmail);
  const { isTraditionalProjectType, projectOffer } = useSelector((state) => ({
    isTraditionalProjectType: selectIsTraditionalProjectById(state, projectId),
    projectOffer: selectProjectOfferInstanceByProjectId(state, projectId),
  }));
  const { hasUnlimitedFullStudy, hasUnlimitedDiscover } = useSelector(
    (state) => ({
      hasUnlimitedFullStudy: selectHasUnlimitedFullStudy(state),
      hasUnlimitedDiscover: selectHasUnlimitedDiscover(state),
    })
  );
  const isOfferExpired = !!props.isOfferExpired;

  const onDeletePoint = useCallback(
    ({ id, name }) => {
      // a point can belong to a marine contractors job and not included in the current project
      // so instead of delete from the project, the point is deleted from the job
      if (!projectNbPoints.map((p) => p.id).includes(+id)) {
        if (props.handleOnDelete) {
          props.handleOnDelete(+id);
        }
        return;
      }
      const onConfirm = () => {
        dispatch(deletePoint({ pointId: +id, projectId }));
        if (props.handleOnDelete) {
          props.handleOnDelete(+id);
        }
      };

      openModal(CONFIRMATION_MODAL, {
        title: t('points.deleteTitle'),
        message: t('points.deleteMessage', { name }),
        onConfirm,
      });
    },
    [openModal, dispatch, t, projectId, props, projectNbPoints]
  );

  const onDeleteAllPoint = useCallback(() => {
    const onConfirm = () => dispatch(deleteProjectPoints({ projectId }));

    openModal(CONFIRMATION_MODAL, {
      title: t('points.deleteAllTitle'),
      message: t('points.deleteAllMessage'),
      onConfirm,
    });
  }, [openModal, dispatch, t, projectId]);

  const onComputeStatistics = useCallback(() => {
    if (isOfferExpired) {
      openModal(NOTIFICATION_MODAL, {
        type: SNACKBAR_WARNING_TYPE,
        message: t('projects.offerExpired', { offerName: projectOffer.name }),
      });
      return;
    }
    const onConfirm = () => dispatch(computeProjectStatistics(projectId));
    const isUnlimitedOffer = checkIsOfferUnlimited({
      projectOffer,
      isTraditionalProjectType,
      hasUnlimitedDiscover,
      hasUnlimitedFullStudy,
    });

    const nbOfferAuthPoints = projectOffer ? projectOffer.nbAuthPoints || 0 : 0;
    const nbProjectPoints = projectNbPoints ? projectNbPoints.length || 0 : 0;
    const informationalOfferMessage = projectOffer
      ? projectOffer.informationalMessage
      : null;

    openModal(START_ANALYSIS_MODAL, {
      title: t('points.startAnalysisModal.title'),
      credits,
      userEmail,
      onConfirm,
      isUnlimitedOffer,
      nbOfferAuthPoints,
      nbProjectPoints,
      informationalOfferMessage,
    });
  }, [
    dispatch,
    projectId,
    t,
    openModal,
    credits,
    userEmail,
    projectOffer,
    projectNbPoints,
    isTraditionalProjectType,
    hasUnlimitedDiscover,
    hasUnlimitedFullStudy,
    isOfferExpired,
  ]);

  const onUploadPoints = useCallback(
    (file) => {
      dispatch(trackPointsUpload());
      dispatch(createPointsFromFile({ projectId, file }));
    },
    [dispatch, projectId]
  );

  const onCancelComputeStatistics = useCallback(() => {
    dispatch(cancelComputeProjectStatistics(projectId));
  }, [dispatch, projectId]);

  return (
    <PointLocations
      {...props}
      allowEditAndCompute={allowEditAndCompute}
      isRunningProject={isRunningProject}
      onDelete={onDeletePoint}
      onDeleteAll={onDeleteAllPoint}
      onComputeStatistics={onComputeStatistics}
      onUpload={onUploadPoints}
      onCancelComputeStatistics={onCancelComputeStatistics}
    />
  );
};

export default React.memo(PointLocationsContainer);
