import { API_URL } from 'common/config';

export const TYPE_CLOUD = 1;
export const TYPE_TRADITIONAL = 2;
export const TYPE_LIGHT = 3;
export const TYPE_OFFER = 4;
export const LOCATIONS_DIGIT = 5;
export const NAVBAR_COLLABORATORS_COUNT = 10;

export const PROJECTS_SORT_FIELDS = {
  nameAscending: 'name',
  idDescending: '-id',
};

export const DEFAULT_TYPES_OPTIONS = [
  {
    translateKey: 'projects.create.selectType',
    value: '',
  },
  {
    translateKey: 'projects.fullAnalysis.title',
    value: 1,
  },
  {
    translateKey: 'projects.discover.title',
    value: 3,
  },
];

export const RUNNING_STATUS = 'running';
export const FINISHED_STATUS = 'finished';

export const CREDIT_NEEDED_LIGHT_TYPE = 1;
export const CREDIT_NEEDED_OTHER_TYPE = 4;

export const CREDIT_NEEDED_STAT2D = 4;

export const STATS_ID_DELIMITER = '|';

export const FAILED_PLANNING_STATUS = 'FAILED';
export const RUNNING_PLANNING_STATUS = 'RUNNING';
export const FINISHED_PLANNING_STATUS = 'FINISHED';
export const PENDING_PLANNING_STATUS = 'PENDING';

export const PLANNING_TEMPLATE_URL = `${API_URL}static/images/help/downtime/Planning Template.xlsx`;
export const PLANNING_PERCENTILES = '10,20,30,40,50,60,70,80,90,99';
export const PLANNINGS_ORDERING_PARAMETER = '-id';

export const RUNNING_TASK_STATUS = 'RUNNING';
export const FINISHED_TASK_STATUS = 'FINISHED';
export const FAILED_TASK_STATUS = 'FAILED';
export const PENDING_TASK_STATUS = 'PENDING';
export const CANCELLED_TASK_STATUS = 'CANCELLED';
