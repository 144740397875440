const prefix = '@@satelliteComparison';

export const REQUEST_SATELLITE_COMPARISONS = `${prefix}/REQUEST_SATELLITE_COMPARISONS`;
export const REQUEST_SATELLITE_COMPARISONS_SUCCESS = `${prefix}/REQUEST_SATELLITE_COMPARISONS_SUCCESS`;
export const REQUEST_SATELLITE_COMPARISONS_ERROR = `${prefix}/REQUEST_SATELLITE_COMPARISONS_ERROR`;

export const CREATE_SATELLITE_COMPARISON = `${prefix}/CREATE_SATELLITE_COMPARISON`;
export const CREATE_SATELLITE_COMPARISON_SUCCESS = `${prefix}/CREATE_SATELLITE_COMPARISON_SUCCESS`;
export const CREATE_SATELLITE_COMPARISON_ERROR = `${prefix}/CREATE_SATELLITE_COMPARISON_ERROR`;

export const COMPUTE_SATELLITE_COMPARISON = `${prefix}/COMPUTE_SATELLITE_COMPARISON`;
export const COMPUTE_SATELLITE_COMPARISON_SUCCESS = `${prefix}/COMPUTE_SATELLITE_COMPARISON_SUCCESS`;
export const COMPUTE_SATELLITE_COMPARISON_ERROR = `${prefix}/COMPUTE_SATELLITE_COMPARISON_ERROR`;

export const LAUNCH_SATELLITE_COMPARISON = `${prefix}/LAUNCH_SATELLITE_COMPARISON`;

export const START_UPDATE_SATELLITE_COMPARISON_JOB = `${prefix}/START_UPDATE_SATELLITE_COMPARISON_JOB`;
export const STOP_UPDATE_SATELLITE_COMPARISON_JOB = `${prefix}/STOP_UPDATE_SATELLITE_COMPARISON_JOB`;
export const FINISH_UPDATE_SATELLITE_COMPARISON_JOB = `${prefix}/FINISH_UPDATE_SATELLITE_COMPARISON_JOB`;

export const REQUEST_SATELLITE_COMPARISON_SUCCESS = `${prefix}/REQUEST_SATELLITE_COMPARISON_SUCCESS`;

export const REQUEST_SATELLITE_COMPARISON_STATS = `${prefix}/REQUEST_SATELLITE_COMPARISON_STATS`;
export const REQUEST_SATELLITE_COMPARISON_STATS_SUCCESS = `${prefix}/REQUEST_SATELLITE_COMPARISON_STATS_SUCCESS`;
export const REQUEST_SATELLITE_COMPARISON_STATS_ERROR = `${prefix}/REQUEST_SATELLITE_COMPARISON_STATS_ERROR`;

export const DOWNLOAD_SATELLITE_COMPARISON = `${prefix}/DOWNLOAD_SATELLITE_COMPARISON`;
export const DOWNLOAD_SATELLITE_COMPARISON_SUCCESS = `${prefix}/DOWNLOAD_SATELLITE_COMPARISON_SUCCESS`;
export const DOWNLOAD_SATELLITE_COMPARISON_ERROR = `${prefix}/DOWNLOAD_SATELLITE_COMPARISON_ERROR`;
