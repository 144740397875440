import React, { memo, useMemo } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Card from '@material-ui/core/Card';
import { useTranslation } from 'react-i18next';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';

import { formatDate } from 'helpers/date';

import { useStyles } from './styles';

/**
 * Local component for use in OfferTable
 */
const OfferRow = ({ title, value, rowClass, number }) => (
  <TableRow className={rowClass}>
    <TableCell>{title}</TableCell>
    <TableCell>{value}</TableCell>
    <TableCell padding="checkbox">{number}</TableCell>
  </TableRow>
);

/**
 * OfferTable component
 * Displays table with offer data
 * @param {Object} offer - offer data
 * TODO add missing data (maps, start of subscription, all third column) when API will be ready
 */
const OfferTable = ({ offer }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    isUnlimited,
    canBuildReport,
    canRunDowntime,
    offerStart,
    offerEnd,
    zones,
    expired,
    hasMaps,
    projectCount,
    reportCount,
    downtimeCount,
  } = offer;
  const availableZones = useMemo(
    () => zones.map((zone) => zone.name).join(', '),
    [zones]
  );
  const [startDate, endDate] = useMemo(
    () => [offerStart, offerEnd].map((date) => formatDate(date)),
    [offerStart, offerEnd]
  );

  return (
    <Card className={classes.table} variant="outlined">
      <Table size="small">
        <TableBody>
          <OfferRow
            title={t('offers.card.type')}
            rowClass={!expired && classes.blueRow}
            value={
              isUnlimited
                ? t('offers.card.unlimited')
                : t('offers.card.onDemand')
            }
          />
          <OfferRow
            title={t('offers.card.projects')}
            number={projectCount}
            value={t('offers.card.unlimited')}
          />
          <OfferRow
            title={t('offers.card.pdfReportGeneration')}
            number={reportCount}
            value={
              canBuildReport
                ? t('offers.card.included')
                : t('offers.card.notIncluded')
            }
          />
          <OfferRow
            title={t('offers.card.advancedWeatherDowntimeFeature')}
            number={downtimeCount}
            value={
              canRunDowntime
                ? t('offers.card.included')
                : t('offers.card.notIncluded')
            }
          />
          <OfferRow
            title={t('offers.card.maps')}
            value={
              hasMaps ? t('offers.card.included') : t('offers.card.notIncluded')
            }
          />
          <OfferRow
            title={t('offers.card.availableZones')}
            value={availableZones}
          />
          <OfferRow
            title={t('offers.card.subscriptionStart')}
            value={startDate}
          />
          <OfferRow title={t('offers.card.subscriptionEnd')} value={endDate} />
        </TableBody>
      </Table>
    </Card>
  );
};

OfferTable.propTypes = {
  offer: PropTypes.shape({
    isUnlimited: PropTypes.bool,
    canBuildReport: PropTypes.bool,
    canRunDowntime: PropTypes.bool,
    expired: PropTypes.bool,
    offerEnd: PropTypes.string,
    zones: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default memo(OfferTable);
