import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SimpleSelect from 'components/common/SimpleSelect';
import { PROJECTS_SORT_FIELDS } from 'constants/projects';

import { useStyles } from './styles';

/**
 * ProjectsOrderBySelect component
 * Displays sorting select
 */
const ProjectsOrderBySelect = ({ onChange, ordering }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const options = useMemo(
    () => [
      {
        value: PROJECTS_SORT_FIELDS.idDescending,
        option: t('projects.ordering.recent'),
      },
      {
        value: PROJECTS_SORT_FIELDS.nameAscending,
        option: t('projects.ordering.name'),
      },
    ],
    [t]
  );

  const handleChange = useCallback(({ target }) => onChange(target.value), [
    onChange,
  ]);

  return (
    <div className={classes.container}>
      <SimpleSelect
        onChange={handleChange}
        optionList={options}
        value={ordering}
        withBaseInput
      />
    </div>
  );
};

ProjectsOrderBySelect.propTypes = {
  ordering: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default memo(ProjectsOrderBySelect);
