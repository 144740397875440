import createReducer from 'helpers/createReducer';
import {
  EMPTY_ARRAY,
  EMPTY_VALUE,
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
} from 'constants/common';

import {
  REQUEST_CONTENT_TYPES,
  REQUEST_CONTENT_TYPES_SUCCESS,
  REQUEST_CONTENT_TYPES_ERROR,
} from './types';

const initialState = {
  data: EMPTY_ARRAY,
  error: EMPTY_VALUE,
  status: STATUS_NOT_REQUESTED,
};

const setContentTypes = (state, { contentTypes }) => ({
  ...state,
  data: contentTypes,
  status: STATUS_SUCCESS,
});

const setLoading = (state) => ({
  ...state,
  status: STATUS_LOADING,
});

const setErrorStatus = (state, { error }) => ({
  ...state,
  error,
  status: STATUS_ERROR,
});

export default createReducer(initialState, {
  [REQUEST_CONTENT_TYPES]: setLoading,
  [REQUEST_CONTENT_TYPES_SUCCESS]: setContentTypes,
  [REQUEST_CONTENT_TYPES_ERROR]: setErrorStatus,
});
