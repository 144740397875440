import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ buttons }) => ({
  helpButton: {
    ...buttons.fill.primary,
  },
  downloadButton: {
    ...buttons.outlined.primary,
  },
  closeButton: {
    ...buttons.outlined.neutral,
  },
}));
