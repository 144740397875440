export const wwGraphData = {
  id: 'MET-magw,hs-WW_2869',
  point: {
    name: 'sfees',
    id: '2869',
  },
  layer: 'METOCEAN',
  type: 'WW',
  name: 'Weather windows',
  variables: {
    names: ['magw', 'hs'],
    variables: [
      {
        units: 'm.s-1',
        name: 'magw',
        hidden: false,
        longName: 'wind speed',
      },
      {
        units: 'm',
        name: 'hs',
        hidden: false,
        longName: 'significant wave height',
      },
    ],
  },
  visualization: {
    web: {
      'excel-uri-0':
        '/static/3.6.3/projects/dev-ma/MA/noveo/some/Deliverables/Data/2869/METOCEAN/MET-magw-hs-WW_2869.xlsx',
      'matlab-uri-0':
        '/static/3.6.3/projects/dev-ma/MA/noveo/some/Deliverables/Data/2869/METOCEAN/MET-magw-hs-WW_2869.mat',
      dataLegend0:
        'Wind speed, wave height weather windows persistence at point sfees',
      'netcdf-uri-0':
        '/static/3.6.3/projects/dev-ma/MA/noveo/some/Deliverables/Data/2869/METOCEAN/MET-magw-hs-WW_2869.nc',
      'plot_bars_month-image-legend-0':
        'Wind speed, wave height weather windows persistence at point sfees',
      'plot_bars_month-image-uri-0':
        '/static/3.6.3/projects/dev-ma/MA/noveo/some/Mont_Saint-Michel_Bay_-_DEMO_ZONE/METOCEAN/Visualization/MET-magw%2Chs-WW/2869_MET-magw%2Chs-WW_M%24%7BPER%7D__plot_bars_month.png',
      'data-uri-0':
        '/api/projects/812/get_visu_data/?stats_id=MET-magw%2Chs-WW_2869',
    },
    driver: {
      method: 'plot_bars_month',
      path:
        // eslint-disable-next-line no-template-curly-in-string
        '/mnt/glusterfs/projects/dev-ma/MA/noveo/some/Mont_Saint-Michel_Bay_-_DEMO_ZONE/METOCEAN/Visualization/MET-magw,hs-WW/2869_MET-magw,hs-WW_M${PER}__plot_bars_month.png',
      suffix: null,
      fileformat: '.png',
    },
    kwargs: {
      ignoredQuantilesIdx: '0',
      quantileName: 'quantiles',
      ignoredHsLimitIdx: '3',
      ymin: 0,
      ignoredDurationIdx: '3',
      ignoredMagwLimitIdx: '2',
      ignoreDimensions: true,
      viewerTitle: 'magw weather windows at point sfees',
      vmax: 100,
      probabilityName: 'probability',
      ylabel: 'magw, hs weather window occurrence [%]',
      ignoredDimensions: 'quantiles,duration,hs_limit,magw_limit',
      probabilityMaxName: 'probability_max',
      vmin: 0,
      ymax: 100,
      probabilityMinName: 'probability_min',
      durationName: 'duration',
    },
  },
  periodicity: 'month',
  hsLimit: {
    values: [1.0, 1.5, 2.0, 2.5],
    dimensions: ['hs_limit'],
    attributes: {
      standard_name: 'significant_height_of_wind_and_swell_waves',
      long_name: 'significant wave height',
      units: 'm',
      description:
        'The significant wave height Hs is defined as the average height (trough to crest) of the highest one-third waves in the indicated wave period, also denoted H1/3.',
      valid_min: '0',
      valid_max: '100',
      vartype: 'f8',
    },
  },
  quantiles: {
    values: ['average', '50', '80', '90'],
    dimensions: ['quantiles'],
    attributes: {
      units: '',
      description: 'quantile of the annual variations',
      vartype: 'S',
    },
  },
  month: {
    values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    dimensions: ['month'],
    attributes: {
      units: '',
      description: 'index of the month',
      vartype: 'i2',
    },
  },
  magwLimit: {
    values: [10.0, 15.0],
    dimensions: ['magw_limit'],
    attributes: {
      standard_name: 'wind_speed',
      long_name: 'wind speed',
      units: 'm.s-1',
      description: '',
      valid_min: '0',
      valid_max: '100',
      vartype: 'f8',
    },
  },
  globalAttributes: {
    statistic: 'Weather windows',
    history: '2020-03-02 07:06: generated by Open Ocean',
    location_name: 'sfees',
    longitude: "-1.70082 (1°42\\'2\\'\\'W)",
    latitude: "48.72630 (48°43\\'34\\'\\'N)",
    srs: 'EPSG:4326',
    period: '1994-01-01 00:00 - 2015-12-31 23:00',
    WIND_dataset: 'France-WRF9k',
    WAVES_dataset: 'HOMERE',
    WIND_node_longitude: "-1.75000 (1°45\\'W)",
    WIND_node_latitude: "48.75000 (48°45\\'N)",
    WIND_node_distance: '0 m',
    WAVES_node_longitude: "-1.70271 (1°42\\'9\\'\\'W)",
    WAVES_node_latitude: "48.72848 (48°43\\'42\\'\\'N)",
    WAVES_node_distance: '0 m',
    depth: 7.952227878750577,
  },
  duration: {
    values: [3, 6, 9, 12, 24, 36, 48, 72, 120, 240, 360],
    dimensions: ['duration'],
    attributes: {
      units: 'h',
      description: 'duration of the operation',
      vartype: 'i4',
    },
  },
  probability: {
    values: [
      [
        [
          [
            [
              66.88486479872752,
              78.13532362657533,
              78.85109506913005,
              78.85109506913005,
            ],
            [
              72.21950324238347,
              91.61874464700844,
              97.52844732656307,
              98.48280924996942,
            ],
          ],
          [
            [
              62.665847665847664,
              74.36117936117935,
              75.08599508599508,
              75.08599508599508,
            ],
            [
              68.31695331695332,
              89.62530712530712,
              96.77518427518426,
              97.95454545454544,
            ],
          ],
          [
            [
              59.28210188725792,
              71.23473541383989,
              71.91316146540026,
              71.91316146540026,
            ],
            [
              65.2275811027507,
              87.8561736770692,
              96.06512890094979,
              97.48365609966694,
            ],
          ],
          [
            [
              56.46519692841218,
              68.44810502848651,
              69.07976219965322,
              69.07976219965322,
            ],
            [
              62.70126331434233,
              86.25216745107753,
              95.38642556353729,
              97.02749566509783,
            ],
          ],
          [
            [
              47.79022916142029,
              59.783429866532344,
              60.243011835809625,
              60.243011835809625,
            ],
            [
              54.54545454545454,
              80.88013094938303,
              93.06220095693779,
              95.49861495844875,
            ],
          ],
          [
            [
              41.41485275288092,
              53.104993597951335,
              53.412291933418686,
              53.412291933418686,
            ],
            [
              48.5787451984635,
              76.43405889884764,
              91.04993597951345,
              94.22535211267608,
            ],
          ],
          [
            [
              36.07059129981766,
              47.47981245115915,
              47.73378483980203,
              47.73378483980203,
            ],
            [
              43.87861422245376,
              72.62307892680386,
              89.32664756446992,
              93.18181818181816,
            ],
          ],
          [
            [
              27.71783113029404,
              38.48125168599946,
              38.68357162125708,
              38.68357162125708,
            ],
            [
              36.289452387375235,
              66.2665227947127,
              86.2827083895333,
              91.24629080118693,
            ],
          ],
          [
            [
              18.007551553877434,
              27.17107173976183,
              27.21463839674702,
              27.21463839674702,
            ],
            [
              26.36508858553587,
              56.44786523380772,
              80.42404879465582,
              87.27853616032529,
            ],
          ],
          [
            [
              8.093783686669061,
              13.52856629536471,
              13.555515630614442,
              13.555515630614442,
            ],
            [
              14.094502335609052,
              38.85195831836148,
              67.50808480057492,
              77.20086237872799,
            ],
          ],
          [
            [
              4.545454545454546,
              8.478568064060292,
              8.478568064060292,
              8.478568064060292,
            ],
            [
              6.900612341026849,
              29.16862929816298,
              54.769194536033915,
              65.90909090909092,
            ],
          ],
        ],
        [
          [
            [
              62.6514131897712,
              77.79273216689097,
              78.39838492597578,
              78.39838492597578,
            ],
            [
              68.77523553162854,
              93.60699865410498,
              98.04845222072677,
              98.99057873485869,
            ],
          ],
          [
            [
              56.82432432432433,
              74.1891891891892,
              75.13513513513513,
              75.13513513513513,
            ],
            [
              63.108108108108105,
              91.28378378378379,
              97.50000000000001,
              98.58108108108108,
            ],
          ],
          [
            [
              53.120759837177744,
              70.48846675712348,
              71.64179104477613,
              71.64179104477613,
            ],
            [
              58.88738127544097,
              89.41655359565807,
              97.08276797829036,
              98.16824966078697,
            ],
          ],
          [
            [
              50.88555858310626,
              66.96185286103542,
              68.25613079019072,
              68.25613079019072,
            ],
            [
              56.1307901907357,
              88.14713896457766,
              96.66212534059946,
              97.75204359673025,
            ],
          ],
          [
            [
              44.11357340720222,
              58.72576177285318,
              58.86426592797784,
              58.86426592797784,
            ],
            [
              49.58448753462604,
              81.64819944598338,
              94.94459833795014,
              96.19113573407203,
            ],
          ],
          [
            [
              39.43661971830986,
              53.09859154929577,
              53.239436619718305,
              53.239436619718305,
            ],
            [
              45.2112676056338,
              75.63380281690141,
              93.16901408450704,
              94.43661971830986,
            ],
          ],
          [
            [
              35.31518624641834,
              49.28366762177651,
              49.4269340974212,
              49.4269340974212,
            ],
            [
              41.69054441260745,
              70.41547277936964,
              91.3323782234957,
              92.621776504298,
            ],
          ],
          [
            [
              25.148367952522253,
              39.76261127596439,
              39.910979228486646,
              39.910979228486646,
            ],
            [
              35.01483679525222,
              64.39169139465875,
              87.46290801186944,
              88.79821958456972,
            ],
          ],
          [
            [
              11.900958466453673,
              23.083067092651753,
              23.242811501597444,
              23.242811501597444,
            ],
            [
              23.242811501597444,
              54.3929712460064,
              79.71246006389777,
              85.78274760383387,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [
              5.0395256916996045,
              35.869565217391305,
              71.54150197628458,
              82.41106719367589,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 13.860103626943005, 62.69430051813472, 76.94300518134715],
          ],
        ],
        [
          [
            [
              52.27456258411844,
              69.52893674293405,
              70.1749663526245,
              70.1749663526245,
            ],
            [
              58.51951547779274,
              87.69851951547778,
              95.90847913862719,
              97.22745625841185,
            ],
          ],
          [
            [
              47.75675675675676,
              64.51351351351352,
              64.72972972972973,
              64.72972972972973,
            ],
            [52.45945945945946, 83.59459459459458, 95.0, 96.29729729729729],
          ],
          [
            [
              43.85345997286296,
              59.91858887381276,
              60.081411126187255,
              60.081411126187255,
            ],
            [
              47.978290366350066,
              81.0854816824966,
              94.05698778833109,
              95.5495251017639,
            ],
          ],
          [
            [
              40.19073569482289,
              56.02179836512261,
              56.26702997275205,
              56.26702997275205,
            ],
            [
              44.49591280653951,
              78.80108991825612,
              93.21525885558583,
              94.79564032697547,
            ],
          ],
          [
            [
              29.335180055401665,
              45.78947368421053,
              45.78947368421053,
              45.78947368421053,
            ],
            [
              33.54570637119114,
              69.52908587257618,
              90.16620498614958,
              91.71745152354572,
            ],
          ],
          [
            [
              21.830985915492956,
              38.70422535211267,
              39.74647887323944,
              39.74647887323944,
            ],
            [
              26.929577464788736,
              62.28169014084507,
              87.1830985915493,
              89.77464788732394,
            ],
          ],
          [
            [
              15.702005730659025,
              30.05730659025788,
              31.891117478510022,
              31.891117478510022,
            ],
            [
              21.26074498567335,
              56.275071633237815,
              84.84240687679082,
              89.25501432664757,
            ],
          ],
          [
            [
              6.735905044510386,
              18.45697329376855,
              18.45697329376855,
              18.45697329376855,
            ],
            [
              11.69139465875371,
              47.62611275964391,
              81.83976261127597,
              87.12166172106825,
            ],
          ],
          [
            [0.0, 6.261980830670927, 6.261980830670927, 6.261980830670927],
            [
              1.853035143769968,
              36.29392971246007,
              75.81469648562302,
              78.69009584664536,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 3.83399209486166, 46.798418972332016, 50.98814229249011],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 9.740932642487047, 14.922279792746115],
          ],
        ],
        [
          [
            [
              49.93270524899058,
              65.86810228802153,
              66.25841184387617,
              66.25841184387617,
            ],
            [
              54.979811574697166,
              82.32839838492598,
              94.54912516823687,
              96.6621803499327,
            ],
          ],
          [
            [
              44.932432432432435,
              61.45945945945945,
              61.608108108108105,
              61.608108108108105,
            ],
            [
              48.05405405405405,
              79.08108108108107,
              93.31081081081082,
              95.44594594594594,
            ],
          ],
          [
            [
              41.04477611940299,
              57.896879240162825,
              58.046132971506104,
              58.046132971506104,
            ],
            [
              43.32428765264586,
              76.30936227951153,
              92.06241519674356,
              94.35549525101764,
            ],
          ],
          [
            [
              37.62942779291553,
              54.468664850136236,
              54.61852861035423,
              54.61852861035423,
            ],
            [
              40.51771117166213,
              74.26430517711171,
              90.81743869209808,
              93.5149863760218,
            ],
          ],
          [
            [
              24.556786703601112,
              40.16620498614959,
              40.42936288088643,
              40.42936288088643,
            ],
            [
              29.556786703601112,
              67.50692520775624,
              85.91412742382273,
              90.08310249307479,
            ],
          ],
          [
            [
              16.830985915492956,
              30.95774647887324,
              31.22535211267606,
              31.22535211267606,
            ],
            [
              21.732394366197184,
              61.197183098591545,
              83.16901408450704,
              87.08450704225352,
            ],
          ],
          [
            [
              11.59025787965616,
              22.865329512893982,
              23.194842406876788,
              23.194842406876788,
            ],
            [
              18.10888252148997,
              54.14040114613181,
              82.55014326647564,
              84.61318051575931,
            ],
          ],
          [
            [
              4.5994065281899115,
              11.572700296735905,
              11.810089020771514,
              11.810089020771514,
            ],
            [
              9.035608308605342,
              41.63204747774481,
              80.04451038575667,
              80.6973293768546,
            ],
          ],
          [
            [0.0, 1.2460063897763578, 1.2460063897763578, 1.2460063897763578],
            [
              0.015974440894568703,
              30.23961661341853,
              70.84664536741214,
              71.98083067092652,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 2.6284584980237153, 40.21739130434783, 41.62055335968379],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.8031088082901561, 9.792746113989638],
          ],
        ],
      ],
      [
        [
          [
            [
              70.2333134170079,
              81.07392205731418,
              81.74852991201405,
              81.76207821170244,
            ],
            [
              74.6446300485692,
              92.90870297935882,
              97.84467020611935,
              98.8348559737767,
            ],
          ],
          [
            [
              66.43099769981845,
              77.55218674579378,
              78.26430690904058,
              78.26430690904058,
            ],
            [
              71.18082542219818,
              91.1182626863981,
              97.23931013432936,
              98.44391616137142,
            ],
          ],
          [
            [
              63.438368979580495,
              74.61051361746496,
              75.29944732526658,
              75.29944732526658,
            ],
            [
              68.4948616428259,
              89.54082660140256,
              96.65561846296703,
              98.0494679104411,
            ],
          ],
          [
            [
              61.02727040516055,
              72.08038431757528,
              72.65690872417551,
              72.65690872417551,
            ],
            [
              66.54136602653774,
              88.05829782770407,
              96.09412721811759,
              97.65833001698347,
            ],
          ],
          [
            [
              53.61702392562926,
              64.279886285821,
              64.79736880330353,
              64.79736880330353,
            ],
            [
              59.858687306758526,
              83.30464194559151,
              94.1003091863626,
              96.25967504305781,
            ],
          ],
          [
            [
              47.93579995850118,
              58.23740678910251,
              58.82874335222591,
              58.82874335222591,
            ],
            [
              54.408926033100904,
              79.65544361972967,
              92.2007199441402,
              94.91351113537135,
            ],
          ],
          [
            [
              43.505976451663365,
              53.56051296945865,
              54.301146138206846,
              54.301146138206846,
            ],
            [
              49.70980138072791,
              76.3826492995822,
              90.23581849460443,
              93.52324672132978,
            ],
          ],
          [
            [
              35.94818510859821,
              46.234499581702195,
              47.351986745700984,
              47.351986745700984,
            ],
            [
              41.68528180341836,
              70.66386321903076,
              86.47959700601055,
              90.90950100497615,
            ],
          ],
          [
            [
              24.589504907544207,
              35.95532732292129,
              37.60449043417827,
              37.60449043417827,
            ],
            [
              30.165044067396256,
              61.541984117340434,
              80.06266870925285,
              86.15545042318556,
            ],
          ],
          [
            [
              10.430452066598003,
              21.0168159773738,
              23.121968888978326,
              23.121968888978326,
            ],
            [
              14.197033421572089,
              45.73965969232907,
              68.00715483980731,
              75.02213591451515,
            ],
          ],
          [
            [
              4.61440812984174,
              12.033899466530528,
              14.943569414417038,
              14.943569414417038,
            ],
            [
              6.597616004769362,
              35.27682508574228,
              57.75911986103067,
              66.22756224667053,
            ],
          ],
        ],
        [
          [
            [
              75.55886736214606,
              83.750871136176,
              84.41379236402234,
              84.41379236402234,
            ],
            [79.06110283159464, 93.1445603576751, 98.8822652757079, 100.0],
          ],
          [
            [
              72.15568862275448,
              80.30095877608943,
              80.88937731473469,
              80.88937731473469,
            ],
            [75.52395209580838, 91.91616766467065, 98.42814371257485, 100.0],
          ],
          [
            [
              69.47368421052632,
              76.96563615133624,
              77.48409485251591,
              77.48409485251591,
            ],
            [72.78195488721805, 90.97744360902256, 97.96992481203009, 100.0],
          ],
          [
            [
              67.25731725577586,
              73.89745712700272,
              74.04851452881542,
              74.04851452881542,
            ],
            [71.07250755287009, 90.03021148036254, 97.50755287009063, 100.0],
          ],
          [
            [
              58.61538461538461,
              64.79205660808034,
              64.79205660808034,
              64.79205660808034,
            ],
            [65.92307692307693, 87.07692307692308, 95.61538461538461, 100.0],
          ],
          [
            [
              50.391849529780565,
              58.223631249467275,
              58.223631249467275,
              58.223631249467275,
            ],
            [61.5203761755486, 83.38557993730407, 93.65203761755487, 100.0],
          ],
          [
            [
              45.12779552715655,
              52.87539936102237,
              52.95527156549521,
              52.95527156549521,
            ],
            [56.52936839518309, 79.55271565495208, 91.61341853035142, 100.0],
          ],
          [
            [
              36.29568106312292,
              44.3741840298048,
              44.3741840298048,
              44.3741840298048,
            ],
            [46.08334306305923, 73.67109634551495, 87.29235880398672, 100.0],
          ],
          [
            [
              20.770926761020824,
              32.90694914619065,
              32.90694914619065,
              32.90694914619065,
            ],
            [31.594693515545952, 62.36462093862816, 78.15884476534296, 100.0],
          ],
          [
            [0.0, 3.0990280321171997, 3.0990280321171997, 3.0990280321171997],
            [1.8433179723502304, 42.05069124423964, 72.11981566820276, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 16.719745222929937, 61.46496815286624, 100.0],
          ],
        ],
        [
          [
            [
              56.482861400894194,
              69.65722801788375,
              70.13412816691506,
              70.13412816691506,
            ],
            [
              61.55648715007129,
              88.79284649776453,
              95.67809239940387,
              97.79433681073026,
            ],
          ],
          [
            [
              51.31736526946108,
              64.79041916167665,
              65.02042158457652,
              65.02042158457652,
            ],
            [
              55.01730642760722,
              86.04790419161677,
              94.79041916167664,
              97.33532934131736,
            ],
          ],
          [
            [
              47.338345864661655,
              60.75187969924812,
              60.902255639097746,
              60.902255639097746,
            ],
            [
              50.45202265460459,
              83.33328240776106,
              94.13533834586467,
              96.87218045112782,
            ],
          ],
          [
            [
              43.857997234284305,
              57.00906344410876,
              57.160120845921455,
              57.160120845921455,
            ],
            [
              47.988338192419825,
              81.18318022072877,
              92.93051359516618,
              96.40483383685799,
            ],
          ],
          [
            [
              34.50408582515407,
              46.15777219812828,
              46.15777219812828,
              46.15777219812828,
            ],
            [
              37.86350148367953,
              72.76923076923076,
              88.24615384615385,
              94.49230769230769,
            ],
          ],
          [
            [
              27.319038915038497,
              39.24764890282132,
              39.24764890282132,
              39.24764890282132,
            ],
            [
              31.585865951945753,
              66.33228840125392,
              84.63949843260188,
              92.50783699059562,
            ],
          ],
          [
            [
              22.507053330056525,
              35.814696485623,
              35.814696485623,
              35.814696485623,
            ],
            [
              27.642369132464978,
              59.93610223642172,
              80.89456869009585,
              90.44728434504792,
            ],
          ],
          [
            [
              13.921539490303886,
              26.21262458471761,
              26.21262458471761,
              26.21262458471761,
            ],
            [
              17.208347043401655,
              49.501661129568106,
              72.95681063122923,
              86.0797342192691,
            ],
          ],
          [
            [
              1.6908797921377092,
              9.350180505415164,
              9.350180505415164,
              9.350180505415164,
            ],
            [
              3.5622649994378737,
              32.70758122743682,
              60.39711191335739,
              76.20938628158844,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 6.866359447004609, 33.456221198156676, 42.16589861751152],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 2.5477707006369434, 7.707006369426753],
          ],
        ],
        [
          [
            [
              49.64826469673739,
              67.84845983124082,
              68.06891893340767,
              68.06891893340767,
            ],
            [
              56.06784676580643,
              85.3093525179856,
              94.30700447093889,
              96.64679582712368,
            ],
          ],
          [
            [
              45.63886677512028,
              61.67482953168806,
              62.55988023952096,
              62.55988023952096,
            ],
            [
              50.19763940327437,
              82.03757225433527,
              92.52994011976048,
              95.32934131736526,
            ],
          ],
          [
            [
              42.29732531619324,
              57.14228968648035,
              57.72718443423508,
              57.72718443423508,
            ],
            [45.95672053864705, 79.82260440651702, 90.73684210526316, 94.0],
          ],
          [
            [
              39.83445341882977,
              53.27772541904117,
              53.428254340147795,
              53.428254340147795,
            ],
            [
              43.685800604229605,
              77.76699285670246,
              89.06344410876133,
              92.79456193353475,
            ],
          ],
          [
            [
              30.046519059575434,
              42.323076923076925,
              42.323076923076925,
              42.323076923076925,
            ],
            [
              35.24615384615385,
              65.89363159096096,
              83.32307692307693,
              88.96923076923076,
            ],
          ],
          [
            [
              22.533786663383495,
              34.25176865014348,
              34.25176865014348,
              34.25176865014348,
            ],
            [26.44830427411946, 58.22131093200996, 78.11343984695375, 85.0],
          ],
          [
            [
              16.808306709265175,
              27.08173998525436,
              27.08173998525436,
              27.08173998525436,
            ],
            [
              18.92347014008356,
              51.693389039075946,
              73.51880068812976,
              80.87859424920129,
            ],
          ],
          [
            [
              9.292878902062348,
              15.664451827242527,
              15.664451827242527,
              15.664451827242527,
            ],
            [
              9.87942216042372,
              38.00913886618619,
              60.93617653614681,
              72.44186046511626,
            ],
          ],
          [
            [0.0, 1.3357400722021666, 1.3357400722021666, 1.3357400722021666],
            [
              0.12110726643598625,
              22.960288808664263,
              45.87260939627497,
              58.91696750902528,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.7531241824804299, 17.373271889400925, 23.617511520737327],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 0.19108280254777085],
          ],
        ],
      ],
      [
        [
          [
            [
              81.45111953994859,
              88.97589624372934,
              89.41637097760918,
              89.41637097760918,
            ],
            [
              84.58338431420532,
              96.76985195154776,
              99.48611281047349,
              99.65740854031566,
            ],
          ],
          [
            [
              78.42137592137593,
              86.71990171990171,
              87.22972972972973,
              87.22972972972973,
            ],
            [
              82.14987714987714,
              95.94594594594595,
              99.31203931203932,
              99.53931203931205,
            ],
          ],
          [
            [
              75.87887011224868,
              84.6984087825336,
              85.24731713334155,
              85.24731713334155,
            ],
            [
              80.20846182311584,
              95.1831750339213,
              99.14271617121007,
              99.4264216109535,
            ],
          ],
          [
            [
              73.62521674510775,
              82.79663116175378,
              83.3973247460986,
              83.3973247460986,
            ],
            [
              78.45553628932376,
              94.46371067624473,
              98.97820163487737,
              99.31260837255388,
            ],
          ],
          [
            [
              66.06018635104506,
              76.42911105514982,
              77.12792747418786,
              77.12792747418786,
            ],
            [
              72.280282044825,
              92.02341979350291,
              98.41979350289598,
              98.89826240241753,
            ],
          ],
          [
            [
              60.85147247119077,
              71.42765685019208,
              72.17029449423816,
              72.17029449423816,
            ],
            [
              67.73367477592829,
              89.8079385403329,
              97.8809218950064,
              98.49551856594111,
            ],
          ],
          [
            [
              56.21906746548579,
              67.26361031518624,
              68.09716071893722,
              68.09716071893722,
            ],
            [
              63.79265433706694,
              87.5618650690284,
              97.37561865069028,
              98.07892680385517,
            ],
          ],
          [
            [
              48.02400863231723,
              59.994604801726446,
              60.86458052333422,
              60.86458052333422,
            ],
            [
              56.71027785271108,
              83.69301321823578,
              96.31103318046938,
              97.20124089560291,
            ],
          ],
          [
            [
              36.19663084519315,
              48.1629392971246,
              49.0923613128086,
              49.0923613128086,
            ],
            [
              45.563462097008426,
              77.46877722916064,
              94.28550682544294,
              95.24397327911704,
            ],
          ],
          [
            [
              18.298598634567014,
              27.76679841897233,
              28.35070068271649,
              28.35070068271649,
            ],
            [
              25.521020481494798,
              61.1300754581387,
              90.12756018684873,
              91.06180380883939,
            ],
          ],
          [
            [
              11.116344795101272,
              16.686292981629773,
              16.721620348563356,
              16.721620348563356,
            ],
            [
              15.2378709373528,
              49.89401789919924,
              88.08290155440415,
              89.21337729627884,
            ],
          ],
        ],
        [
          [
            [
              81.35935397039032,
              90.98250336473754,
              91.72274562584118,
              91.72274562584118,
            ],
            [86.67563930013459, 98.1157469717362, 100.0, 100.0],
          ],
          [
            [
              78.24324324324324,
              88.85135135135135,
              89.86486486486487,
              89.86486486486487,
            ],
            [84.39189189189189, 97.36486486486487, 100.0, 100.0],
          ],
          [
            [
              75.30529172320217,
              86.70284938941654,
              87.72048846675713,
              87.72048846675713,
            ],
            [82.63229308005428, 96.74355495251018, 100.0, 100.0],
          ],
          [
            [
              73.50136239782017,
              84.40054495912807,
              85.69482288828338,
              85.69482288828338,
            ],
            [80.10899182561307, 96.11716621253406, 100.0, 100.0],
          ],
          [
            [
              68.49030470914128,
              78.25484764542936,
              78.25484764542936,
              78.25484764542936,
            ],
            [73.33795013850416, 94.3213296398892, 100.0, 100.0],
          ],
          [
            [
              62.11267605633803,
              75.63380281690141,
              75.63380281690141,
              75.63380281690141,
            ],
            [68.66197183098592, 92.53521126760563, 100.0, 100.0],
          ],
          [
            [
              58.2378223495702,
              73.49570200573066,
              73.49570200573066,
              73.49570200573066,
            ],
            [64.82808022922637, 90.75931232091689, 100.0, 100.0],
          ],
          [
            [50.0, 66.3946587537092, 66.3946587537092, 66.3946587537092],
            [59.86646884272997, 87.16617210682493, 100.0, 100.0],
          ],
          [
            [
              34.82428115015974,
              57.34824281150159,
              57.34824281150159,
              57.34824281150159,
            ],
            [47.683706070287535, 79.71246006389777, 100.0, 100.0],
          ],
          [
            [6.324110671936759, 25.0, 25.0, 25.0],
            [15.118577075098816, 56.52173913043479, 100.0, 100.0],
          ],
          [
            [0.0, 1.16580310880829, 1.16580310880829, 1.16580310880829],
            [0.0, 41.968911917098445, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              74.80484522207269,
              85.06056527590849,
              85.3835800807537,
              85.3835800807537,
            ],
            [
              77.22745625841185,
              96.15074024226111,
              99.1386271870794,
              99.5693135935397,
            ],
          ],
          [
            [
              72.83783783783784,
              81.54054054054055,
              81.75675675675676,
              81.75675675675676,
            ],
            [
              73.75675675675676,
              94.91891891891892,
              98.8108108108108,
              99.56756756756758,
            ],
          ],
          [
            [68.955223880597, 79.78290366350068, 80.0, 80.0],
            [
              71.69606512890094,
              93.67706919945726,
              98.48032564450475,
              99.56580732700135,
            ],
          ],
          [
            [
              64.93188010899182,
              78.25613079019074,
              78.4741144414169,
              78.4741144414169,
            ],
            [
              69.45504087193461,
              92.45231607629428,
              98.14713896457765,
              99.56403269754769,
            ],
          ],
          [
            [
              54.819944598337955,
              72.07756232686981,
              72.13296398891967,
              72.13296398891967,
            ],
            [
              60.221606648199455,
              87.67313019390582,
              97.89473684210526,
              99.5567867036011,
            ],
          ],
          [
            [
              49.04225352112676,
              64.11267605633803,
              66.14084507042254,
              66.14084507042254,
            ],
            [
              55.01408450704226,
              83.07042253521126,
              97.85915492957746,
              99.54929577464789,
            ],
          ],
          [
            [
              43.00859598853868,
              56.7621776504298,
              62.435530085959876,
              62.435530085959876,
            ],
            [
              48.99713467048711,
              82.09169054441261,
              97.82234957020057,
              99.54154727793696,
            ],
          ],
          [
            [
              31.513353115727007,
              44.59940652818992,
              51.36498516320474,
              51.36498516320474,
            ],
            [
              36.617210682492576,
              76.29080118694363,
              97.74480712166172,
              99.52522255192878,
            ],
          ],
          [
            [
              15.718849840255588,
              24.02555910543131,
              30.415335463258785,
              30.415335463258785,
            ],
            [
              24.313099041533548,
              66.16613418530352,
              97.57188498402556,
              99.4888178913738,
            ],
          ],
          [
            [0.0, 0.5533596837944669, 0.5533596837944669, 0.5533596837944669],
            [
              0.9881422924901194,
              40.11857707509881,
              96.99604743083003,
              99.36758893280633,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 14.4559585492228, 96.06217616580311, 99.17098445595855],
          ],
        ],
        [
          [
            [
              73.52624495289368,
              81.7900403768506,
              82.0592193808883,
              82.0592193808883,
            ],
            [
              74.41453566621804,
              90.98250336473754,
              98.39838492597576,
              99.32705248990578,
            ],
          ],
          [
            [
              68.71621621621622,
              79.63513513513514,
              79.78378378378378,
              79.78378378378378,
            ],
            [
              71.25675675675676,
              89.35135135135135,
              97.62162162162163,
              98.91891891891892,
            ],
          ],
          [
            [
              65.73948439620082,
              75.359565807327,
              75.50881953867028,
              75.50881953867028,
            ],
            [
              68.29036635006784,
              88.08683853459972,
              96.83853459972863,
              98.50746268656717,
            ],
          ],
          [
            [
              63.10626702997274,
              71.53950953678473,
              71.68937329700272,
              71.68937329700272,
            ],
            [
              65.64032697547682,
              86.81198910081744,
              96.06267029972751,
              98.09264305177112,
            ],
          ],
          [
            [
              47.686980609418285,
              61.26038781163435,
              61.28808864265929,
              61.28808864265929,
            ],
            [
              57.10526315789474,
              82.60387811634348,
              93.005540166205,
              96.50969529085872,
            ],
          ],
          [
            [
              38.02816901408451,
              52.70422535211268,
              52.70422535211268,
              52.70422535211268,
            ],
            [
              51.30985915492958,
              78.1549295774648,
              89.84507042253523,
              94.92957746478874,
            ],
          ],
          [
            [
              30.773638968481382,
              46.060171919770774,
              46.060171919770774,
              46.060171919770774,
            ],
            [
              45.315186246418335,
              72.87965616045845,
              86.57593123209169,
              93.29512893982809,
            ],
          ],
          [
            [
              18.560830860534125,
              36.3353115727003,
              36.3353115727003,
              36.3353115727003,
            ],
            [
              31.03857566765579,
              65.459940652819,
              79.68842729970326,
              89.85163204747775,
            ],
          ],
          [
            [
              3.258785942492014,
              16.230031948881788,
              16.341853035143767,
              16.341853035143767,
            ],
            [
              17.30031948881789,
              55.44728434504793,
              71.08626198083068,
              82.17252396166134,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 22.114624505928855, 64.22924901185772, 77.94466403162055],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.6994818652849747, 53.10880829015545, 71.0880829015544],
          ],
        ],
      ],
      [
        [
          [
            [
              88.16538121127829,
              93.29877354912125,
              93.5390061954735,
              93.5390061954735,
            ],
            [
              90.87115943861424,
              97.76836515362245,
              99.47528132507271,
              99.68390441269442,
            ],
          ],
          [
            [
              85.8557643473845,
              91.63915693245305,
              91.89309294057897,
              91.89309294057897,
            ],
            [
              89.18867445403758,
              97.20670391061454,
              99.32706957846624,
              99.57465718638903,
            ],
          ],
          [
            [
              83.90921841132219,
              90.1887033023078,
              90.46283309957924,
              90.46283309957924,
            ],
            [
              87.73428534999361,
              96.73594287900038,
              99.196735942879,
              99.47724085171491,
            ],
          ],
          [
            [
              82.27272727272727,
              88.79641485275287,
              89.08450704225352,
              89.08450704225352,
            ],
            [
              86.55569782330346,
              96.30601792573621,
              99.07810499359795,
              99.37900128040972,
            ],
          ],
          [
            [
              76.93409742120345,
              84.2406876790831,
              84.55978119301902,
              84.55978119301902,
            ],
            [
              82.37171138317268,
              94.62099505079446,
              98.59338369367023,
              98.9775983328992,
            ],
          ],
          [
            [
              72.389345348529,
              80.37370792472835,
              80.77789557381396,
              80.77789557381396,
            ],
            [
              78.8961038961039,
              93.15531407368141,
              98.10495626822158,
              98.5621521335807,
            ],
          ],
          [
            [
              68.14809819260857,
              76.82087941731858,
              77.28621526841111,
              77.28621526841111,
            ],
            [
              75.4990558403021,
              91.91394658753708,
              97.66657674669544,
              98.20609657404908,
            ],
          ],
          [
            [
              61.13986013986013,
              71.53846153846153,
              72.02097902097901,
              72.02097902097901,
            ],
            [
              69.34965034965033,
              90.00699300699301,
              97.04895104895105,
              97.72727272727273,
            ],
          ],
          [
            [
              50.12080942313501,
              63.07762005436424,
              63.38719420114769,
              63.38719420114769,
            ],
            [
              59.747810329205684,
              87.20175173663546,
              96.76834793113863,
              97.13832678948958,
            ],
          ],
          [
            [
              33.86457940399849,
              48.58543945680875,
              48.65145228215768,
              48.65145228215768,
            ],
            [
              44.18144096567333,
              81.02602791399472,
              95.9637872500943,
              96.09581290079215,
            ],
          ],
          [
            [
              22.65193370165746,
              38.39779005524862,
              38.4856855851331,
              38.4856855851331,
            ],
            [
              34.07835258663987,
              75.74083375188347,
              94.6258161727775,
              94.80160723254646,
            ],
          ],
        ],
        [
          [
            [
              92.28094575799722,
              96.66203059805285,
              96.80111265646731,
              96.80111265646731,
            ],
            [93.25452016689847, 99.23504867872045, 100.0, 100.0],
          ],
          [
            [
              91.06145251396647,
              95.39106145251397,
              95.53072625698324,
              95.53072625698324,
            ],
            [92.10893854748603, 98.95251396648045, 100.0, 100.0],
          ],
          [
            [
              90.18232819074333,
              94.24964936886396,
              94.46002805049089,
              94.46002805049089,
            ],
            [91.23422159887798, 98.73772791023842, 100.0, 100.0],
          ],
          [
            [
              89.1549295774648,
              93.09859154929578,
              93.2394366197183,
              93.2394366197183,
            ],
            [90.49295774647888, 98.52112676056338, 100.0, 100.0],
          ],
          [
            [
              84.67048710601719,
              89.97134670487105,
              89.97134670487105,
              89.97134670487105,
            ],
            [87.17765042979944, 98.49570200573066, 100.0, 100.0],
          ],
          [
            [
              80.75801749271136,
              87.24489795918367,
              87.31778425655976,
              87.31778425655976,
            ],
            [83.6734693877551, 98.46938775510203, 100.0, 100.0],
          ],
          [
            [
              76.8545994065282,
              84.49554896142433,
              84.49554896142433,
              84.49554896142433,
            ],
            [79.82195845697329, 98.44213649851632, 100.0, 100.0],
          ],
          [
            [69.0, 80.61538461538463, 80.61538461538463, 80.61538461538463],
            [72.0, 98.38461538461539, 100.0, 100.0],
          ],
          [
            [
              51.66112956810631,
              71.09634551495017,
              71.09634551495017,
              71.09634551495017,
            ],
            [61.54485049833887, 98.25581395348837, 100.0, 100.0],
          ],
          [
            [
              30.91286307053942,
              46.88796680497925,
              47.0954356846473,
              47.0954356846473,
            ],
            [43.67219917012448, 97.82157676348548, 100.0, 100.0],
          ],
          [
            [
              8.011049723756905,
              29.281767955801108,
              29.558011049723753,
              29.558011049723753,
            ],
            [21.685082872928177, 97.09944751381215, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              80.58414464534076,
              87.4547983310153,
              87.92767732962447,
              87.92767732962447,
            ],
            [86.20305980528512, 95.77190542420027, 100.0, 100.0],
          ],
          [
            [
              77.17877094972067,
              84.94413407821229,
              84.94413407821229,
              84.94413407821229,
            ],
            [83.91061452513966, 94.3854748603352, 100.0, 100.0],
          ],
          [
            [
              74.27769985974754,
              82.74894810659187,
              82.74894810659187,
              82.74894810659187,
            ],
            [81.73913043478261, 93.18373071528752, 100.0, 100.0],
          ],
          [
            [
              71.71830985915494,
              79.40845070422534,
              79.40845070422534,
              79.40845070422534,
            ],
            [79.83098591549296, 91.97183098591549, 100.0, 100.0],
          ],
          [
            [
              62.26361031518625,
              69.59885386819484,
              71.66189111747852,
              71.66189111747852,
            ],
            [73.06590257879657, 87.82234957020057, 100.0, 100.0],
          ],
          [
            [
              53.731778425655975,
              61.77842565597666,
              64.46064139941691,
              64.46064139941691,
            ],
            [67.49271137026238, 84.1399416909621, 100.0, 100.0],
          ],
          [
            [
              45.25222551928783,
              55.72700296735905,
              58.45697329376854,
              58.45697329376854,
            ],
            [61.246290801186944, 80.65281899109793, 100.0, 100.0],
          ],
          [
            [33.753846153846155, 45.723076923076924, 48.8, 48.8],
            [51.138461538461534, 74.09230769230768, 100.0, 100.0],
          ],
          [
            [
              18.704318936877076,
              31.09634551495017,
              31.09634551495017,
              31.09634551495017,
            ],
            [33.18936877076412, 65.51495016611297, 100.0, 100.0],
          ],
          [
            [
              0.29045643153526995,
              13.609958506224068,
              13.609958506224068,
              13.609958506224068,
            ],
            [2.1991701244813298, 56.1825726141079, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 41.65745856353592, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              72.85118219749651,
              84.71488178025035,
              85.88317107093185,
              85.88317107093185,
            ],
            [
              75.2712100139082,
              95.15994436717664,
              98.49791376912378,
              98.49791376912378,
            ],
          ],
          [
            [
              68.32402234636871,
              81.07541899441341,
              82.33240223463687,
              82.33240223463687,
            ],
            [
              70.89385474860336,
              93.35195530726257,
              98.1145251396648,
              98.1145251396648,
            ],
          ],
          [
            [
              63.78681626928472,
              78.80785413744739,
              79.35483870967741,
              79.35483870967741,
            ],
            [
              67.3913043478261,
              92.01963534361852,
              97.72791023842917,
              97.72791023842917,
            ],
          ],
          [
            [
              59.78873239436619,
              76.52112676056338,
              76.73239436619717,
              76.73239436619717,
            ],
            [
              64.70422535211267,
              90.71830985915493,
              97.3380281690141,
              97.3380281690141,
            ],
          ],
          [
            [
              51.57593123209169,
              67.09169054441261,
              67.56446991404012,
              67.56446991404012,
            ],
            [
              57.67908309455587,
              85.4727793696275,
              95.74498567335243,
              95.74498567335243,
            ],
          ],
          [
            [
              43.01749271137027,
              57.91545189504374,
              59.795918367346935,
              59.795918367346935,
            ],
            [
              50.32069970845481,
              80.05830903790087,
              94.09620991253644,
              94.09620991253644,
            ],
          ],
          [
            [
              38.45697329376855,
              50.53412462908011,
              52.93768545994065,
              52.93768545994065,
            ],
            [
              42.6706231454006,
              74.54005934718101,
              92.38872403560832,
              92.38872403560832,
            ],
          ],
          [
            [
              31.12307692307692,
              39.969230769230776,
              40.46153846153847,
              40.46153846153847,
            ],
            [
              33.87692307692308,
              66.27692307692308,
              90.72307692307693,
              90.72307692307693,
            ],
          ],
          [
            [
              8.903654485049834,
              24.25249169435216,
              26.01328903654485,
              26.01328903654485,
            ],
            [
              20.664451827242523,
              60.56478405315614,
              89.98338870431894,
              89.98338870431894,
            ],
          ],
          [
            [0.0, 1.8257261410788386, 1.8257261410788386, 1.8257261410788386],
            [0.0, 38.48547717842324, 87.4896265560166, 87.4896265560166],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 18.093922651933703, 83.34254143646409, 83.34254143646409],
          ],
        ],
      ],
      [
        [
          [
            [
              92.17545576899546,
              96.76985195154778,
              97.12467882050656,
              97.12467882050656,
            ],
            [93.64370488192829, 99.19246298788697, 100.0, 100.0],
          ],
          [
            [
              90.35012285012284,
              95.77395577395578,
              96.1977886977887,
              96.1977886977887,
            ],
            [92.20515970515972, 98.89434889434891, 100.0, 100.0],
          ],
          [
            [
              88.63944739114345,
              94.83779449858145,
              95.33119526335267,
              95.33119526335267,
            ],
            [90.85975083261378, 98.59380782040212, 100.0, 100.0],
          ],
          [
            [
              87.12534059945504,
              93.96210056973,
              94.49467426306661,
              94.49467426306661,
            ],
            [89.670547436215, 98.3093881595244, 100.0, 100.0],
          ],
          [
            [
              81.6167212289096,
              90.76429111055153,
              91.38755980861245,
              91.38755980861245,
            ],
            [85.28708133971293, 97.16696046335935, 100.0, 100.0],
          ],
          [
            [
              76.68373879641484,
              87.7272727272727,
              88.50192061459666,
              88.50192061459666,
            ],
            [81.32522407170295, 96.04353393085785, 100.0, 100.0],
          ],
          [
            [
              72.40817921333681,
              84.81375358166189,
              85.72544933576452,
              85.72544933576452,
            ],
            [77.89789007554052, 94.9270643396718, 100.0, 100.0],
          ],
          [
            [
              65.10655516590235,
              79.05314270299434,
              80.24008632317236,
              80.24008632317236,
            ],
            [71.93148098192609, 92.64229835446453, 100.0, 100.0],
          ],
          [
            [
              54.21144350856812,
              68.90066802207379,
              70.52715654952077,
              70.52715654952077,
            ],
            [62.031658437409234, 88.45483589892535, 100.0, 100.0],
          ],
          [
            [
              34.54904779015451,
              48.0596478620194,
              50.97915918074021,
              50.97915918074021,
            ],
            [42.47215235357528, 77.67696730147323, 100.0, 100.0],
          ],
          [
            [
              23.36316533207725,
              32.87800282618936,
              35.11540273198304,
              35.11540273198304,
            ],
            [29.745642958078193, 68.80593499764484, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              92.19380888290713,
              96.90444145356662,
              98.04845222072677,
              98.04845222072677,
            ],
            [94.41453566621803, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.2027027027027,
              96.01351351351352,
              97.2972972972973,
              97.2972972972973,
            ],
            [92.43243243243244, 100.0, 100.0, 100.0],
          ],
          [
            [
              87.92401628222524,
              95.25101763907735,
              96.47218453188601,
              96.47218453188601,
            ],
            [90.70556309362279, 100.0, 100.0, 100.0],
          ],
          [
            [
              85.83106267029972,
              94.61852861035422,
              95.64032697547684,
              95.64032697547684,
            ],
            [89.44141689373298, 100.0, 100.0, 100.0],
          ],
          [
            [
              81.16343490304709,
              91.68975069252076,
              92.65927977839335,
              92.65927977839335,
            ],
            [84.83379501385042, 100.0, 100.0, 100.0],
          ],
          [
            [
              76.69014084507042,
              88.16901408450704,
              89.50704225352113,
              89.50704225352113,
            ],
            [81.19718309859155, 100.0, 100.0, 100.0],
          ],
          [
            [
              72.63610315186246,
              85.88825214899714,
              87.60744985673352,
              87.60744985673352,
            ],
            [77.50716332378224, 100.0, 100.0, 100.0],
          ],
          [
            [
              64.09495548961424,
              81.82492581602374,
              83.6053412462908,
              83.6053412462908,
            ],
            [72.92284866468843, 100.0, 100.0, 100.0],
          ],
          [
            [
              50.47923322683706,
              72.76357827476039,
              75.15974440894568,
              75.15974440894568,
            ],
            [60.4632587859425, 100.0, 100.0, 100.0],
          ],
          [
            [
              26.976284584980238,
              46.14624505928854,
              49.60474308300395,
              49.60474308300395,
            ],
            [38.43873517786561, 100.0, 100.0, 100.0],
          ],
          [
            [0.0, 6.476683937823833, 13.471502590673575, 13.471502590673575],
            [13.730569948186528, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              86.51413189771198,
              94.99327052489906,
              95.55854643337818,
              95.55854643337818,
            ],
            [90.36339165545087, 98.38492597577388, 100.0, 100.0],
          ],
          [
            [
              84.72972972972973,
              93.1891891891892,
              94.08108108108108,
              94.08108108108108,
            ],
            [88.0, 97.64864864864865, 100.0, 100.0],
          ],
          [
            [
              82.68656716417911,
              91.50610583446404,
              92.42876526458616,
              92.42876526458616,
            ],
            [85.86160108548168, 96.90637720488468, 100.0, 100.0],
          ],
          [
            [
              80.2724795640327,
              89.83651226158038,
              90.92643051771117,
              90.92643051771117,
            ],
            [84.71389645776567, 96.4850136239782, 100.0, 100.0],
          ],
          [
            [
              71.41274238227146,
              85.09695290858726,
              85.59556786703601,
              85.59556786703601,
            ],
            [77.92243767313019, 94.76454293628808, 100.0, 100.0],
          ],
          [
            [
              64.25352112676056,
              81.2394366197183,
              81.40845070422536,
              81.40845070422536,
            ],
            [72.53521126760565, 92.98591549295774, 100.0, 100.0],
          ],
          [
            [
              58.16618911174785,
              77.24928366762178,
              77.47851002865329,
              77.47851002865329,
            ],
            [67.02005730659025, 91.14613180515758, 100.0, 100.0],
          ],
          [
            [
              48.99109792284867,
              68.13056379821958,
              68.90207715133532,
              68.90207715133532,
            ],
            [58.33827893175074, 87.2700296735905, 100.0, 100.0],
          ],
          [
            [
              31.2779552715655,
              50.926517571884986,
              52.77955271565495,
              52.77955271565495,
            ],
            [43.48242811501598, 78.6261980830671, 100.0, 100.0],
          ],
          [
            [
              1.3833992094861671,
              13.003952569169961,
              18.181818181818183,
              18.181818181818183,
            ],
            [8.735177865612648, 50.90909090909091, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 21.606217616580313, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              85.26244952893674,
              93.7685060565276,
              94.64333781965007,
              94.64333781965007,
            ],
            [87.10632570659489, 97.59084791386272, 100.0, 100.0],
          ],
          [
            [82.55405405405406, 91.74324324324324, 93.0, 93.0],
            [85.05405405405406, 96.91891891891892, 100.0, 100.0],
          ],
          [
            [
              80.08141112618723,
              89.67435549525102,
              91.34328358208955,
              91.34328358208955,
            ],
            [83.12075983717774, 96.13297150610583, 100.0, 100.0],
          ],
          [
            [
              77.95640326975476,
              87.62942779291552,
              89.67302452316078,
              89.67302452316078,
            ],
            [81.70299727520435, 95.34059945504087, 100.0, 100.0],
          ],
          [
            [
              70.77562326869807,
              83.1578947368421,
              83.6842105263158,
              83.6842105263158,
            ],
            [73.44875346260389, 92.10526315789474, 100.0, 100.0],
          ],
          [
            [
              62.014084507042256,
              78.45070422535213,
              78.70422535211267,
              78.70422535211267,
            ],
            [65.01408450704226, 88.7605633802817, 100.0, 100.0],
          ],
          [
            [
              53.123209169054434,
              73.06590257879655,
              73.32378223495701,
              73.32378223495701,
            ],
            [59.46991404011462, 85.30085959885388, 100.0, 100.0],
          ],
          [
            [
              36.70623145400593,
              61.5727002967359,
              63.17507418397626,
              63.17507418397626,
            ],
            [53.87240356083086, 78.01186943620178, 100.0, 100.0],
          ],
          [
            [
              19.82428115015975,
              40.287539936102235,
              41.21405750798722,
              41.21405750798722,
            ],
            [37.971246006389784, 70.95846645367412, 100.0, 100.0],
          ],
          [
            [0.0, 3.8735177865612656, 10.454545454545455, 10.454545454545455],
            [2.8260869565217392, 49.60474308300395, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 8.367875647668393, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [
              95.51144266026047,
              98.28044000505753,
              98.32469338728032,
              98.32469338728032,
            ],
            [96.32064736376282, 99.55746617777216, 100.0, 100.0],
          ],
          [
            [
              94.42610462163539,
              97.80980192991368,
              97.84789233113258,
              97.84789233113258,
            ],
            [95.42280345352974, 99.4540375825292, 100.0, 100.0],
          ],
          [
            [
              93.47826086956523,
              97.37345403544563,
              97.41170470483236,
              97.41170470483236,
            ],
            [94.63215606273111, 99.3561137319903, 100.0, 100.0],
          ],
          [
            [
              92.59282970550576,
              96.95262483994877,
              96.99103713188218,
              96.99103713188218,
            ],
            [93.87323943661973, 99.25736235595392, 100.0, 100.0],
          ],
          [
            [
              89.48293826517322,
              95.46105756707477,
              95.50013024225059,
              95.50013024225059,
            ],
            [91.07840583485284, 98.85386819484242, 100.0, 100.0],
          ],
          [
            [
              86.62205141796979,
              94.22873045322025,
              94.26848661542539,
              94.26848661542539,
            ],
            [88.39782666313279, 98.4362576199311, 100.0, 100.0],
          ],
          [
            [
              84.0302131103318,
              93.07391421634745,
              93.11437820339896,
              93.11437820339896,
            ],
            [85.9522524952792, 98.00377663879148, 100.0, 100.0],
          ],
          [
            [
              79.23776223776225,
              90.90909090909093,
              90.95104895104896,
              90.95104895104896,
            ],
            [81.3916083916084, 97.09090909090907, 100.0, 100.0],
          ],
          [
            [
              71.71549380851707,
              86.40138930836606,
              86.43914225309575,
              86.43914225309575,
            ],
            [74.66022349743281, 95.29598308668076, 100.0, 100.0],
          ],
          [
            [
              58.09128630705395,
              77.43304413428895,
              77.48019615239534,
              77.48019615239534,
            ],
            [62.33496793662769, 92.01244813278007, 100.0, 100.0],
          ],
          [
            [
              46.9236564540432,
              68.8598694123556,
              68.92265193370164,
              68.92265193370164,
            ],
            [52.15971873430437, 89.2767453540934, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              96.94019471488178,
              99.02642559109876,
              99.02642559109876,
              99.02642559109876,
            ],
            [97.07927677329624, 100.0, 100.0, 100.0],
          ],
          [
            [
              96.29888268156425,
              98.6731843575419,
              98.74301675977654,
              98.74301675977654,
            ],
            [96.50837988826815, 100.0, 100.0, 100.0],
          ],
          [
            [
              95.65217391304348,
              98.45722300140251,
              98.5273492286115,
              98.5273492286115,
            ],
            [95.8625525946704, 100.0, 100.0, 100.0],
          ],
          [
            [
              94.85915492957746,
              98.23943661971832,
              98.30985915492958,
              98.30985915492958,
            ],
            [95.2112676056338, 100.0, 100.0, 100.0],
          ],
          [
            [
              91.69054441260744,
              97.34957020057307,
              97.42120343839542,
              97.42120343839542,
            ],
            [92.97994269340974, 100.0, 100.0, 100.0],
          ],
          [
            [
              89.79591836734694,
              96.42857142857143,
              96.50145772594752,
              96.50145772594752,
            ],
            [91.10787172011662, 100.0, 100.0, 100.0],
          ],
          [
            [
              87.83382789317507,
              95.47477744807122,
              95.54896142433235,
              95.54896142433235,
            ],
            [89.16913946587538, 100.0, 100.0, 100.0],
          ],
          [
            [83.6923076923077, 95.0, 95.0, 95.0],
            [85.07692307692307, 100.0, 100.0, 100.0],
          ],
          [
            [
              74.4186046511628,
              94.60132890365449,
              94.60132890365449,
              94.60132890365449,
            ],
            [76.578073089701, 100.0, 100.0, 100.0],
          ],
          [
            [
              59.439834024896264,
              93.25726141078839,
              93.25726141078839,
              93.25726141078839,
            ],
            [60.580912863070544, 100.0, 100.0, 100.0],
          ],
          [
            [
              45.99447513812155,
              91.02209944751381,
              91.02209944751381,
              91.02209944751381,
            ],
            [47.51381215469613, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              91.23783031988873,
              96.66203059805285,
              96.66203059805285,
              96.66203059805285,
            ],
            [92.62865090403338, 99.33240611961057, 100.0, 100.0],
          ],
          [
            [
              89.41340782122904,
              95.39106145251397,
              95.39106145251397,
              95.39106145251397,
            ],
            [90.78212290502793, 98.99441340782123, 100.0, 100.0],
          ],
          [
            [
              87.51753155680224,
              94.53015427769986,
              94.53015427769986,
              94.53015427769986,
            ],
            [89.48106591865357, 98.65357643758766, 100.0, 100.0],
          ],
          [
            [
              85.74647887323943,
              93.74647887323944,
              93.74647887323944,
              93.74647887323944,
            ],
            [88.16901408450704, 98.30985915492958, 100.0, 100.0],
          ],
          [
            [
              79.88538681948424,
              90.74498567335243,
              90.74498567335243,
              90.74498567335243,
            ],
            [83.15186246418338, 96.90544412607449, 100.0, 100.0],
          ],
          [
            [
              74.28571428571428,
              88.83381924198251,
              88.83381924198251,
              88.83381924198251,
            ],
            [77.90087463556851, 95.45189504373177, 100.0, 100.0],
          ],
          [
            [
              69.67359050445103,
              86.8545994065282,
              86.8545994065282,
              86.8545994065282,
            ],
            [73.62017804154301, 93.94658753709199, 100.0, 100.0],
          ],
          [
            [
              61.784615384615385,
              83.6923076923077,
              83.6923076923077,
              83.6923076923077,
            ],
            [66.73846153846154, 90.76923076923077, 100.0, 100.0],
          ],
          [
            [
              50.332225913621265,
              74.4186046511628,
              74.4186046511628,
              74.4186046511628,
            ],
            [54.750830564784046, 87.10963455149502, 100.0, 100.0],
          ],
          [
            [
              23.69294605809129,
              46.72199170124481,
              46.72199170124481,
              46.72199170124481,
            ],
            [28.54771784232365, 83.90041493775935, 100.0, 100.0],
          ],
          [
            [0.0, 29.06077348066298, 29.06077348066298, 29.06077348066298],
            [0.6077348066298348, 78.56353591160222, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              90.29207232267038,
              96.2586926286509,
              96.2586926286509,
              96.2586926286509,
            ],
            [92.07232267037551, 97.98331015299027, 100.0, 100.0],
          ],
          [
            [
              88.4217877094972,
              94.97206703910615,
              94.97206703910615,
              94.97206703910615,
            ],
            [89.81843575418993, 97.55586592178771, 100.0, 100.0],
          ],
          [
            [
              86.31136044880785,
              93.73071528751753,
              93.73071528751753,
              93.73071528751753,
            ],
            [87.67180925666199, 97.12482468443197, 100.0, 100.0],
          ],
          [
            [
              84.1830985915493,
              92.46478873239437,
              92.46478873239437,
              92.46478873239437,
            ],
            [85.64788732394366, 96.69014084507043, 100.0, 100.0],
          ],
          [
            [
              77.07736389684814,
              88.12320916905443,
              88.12320916905443,
              88.12320916905443,
            ],
            [79.64183381088824, 94.91404011461319, 100.0, 100.0],
          ],
          [
            [
              71.66180758017492,
              84.41690962099125,
              84.41690962099125,
              84.41690962099125,
            ],
            [74.21282798833819, 93.07580174927114, 100.0, 100.0],
          ],
          [
            [
              67.3293768545994,
              81.37982195845697,
              81.37982195845697,
              81.37982195845697,
            ],
            [69.68842729970326, 91.17210682492582, 100.0, 100.0],
          ],
          [
            [
              58.84615384615385,
              75.67692307692307,
              75.67692307692307,
              75.67692307692307,
            ],
            [61.18461538461539, 87.15384615384615, 100.0, 100.0],
          ],
          [
            [
              46.096345514950166,
              65.7641196013289,
              65.7641196013289,
              65.7641196013289,
            ],
            [47.52491694352159, 78.67109634551494, 100.0, 100.0],
          ],
          [
            [
              10.12448132780083,
              43.02904564315353,
              43.02904564315353,
              43.02904564315353,
            ],
            [14.29460580912863, 64.46058091286307, 100.0, 100.0],
          ],
          [
            [0.0, 11.215469613259671, 11.215469613259671, 11.215469613259671],
            [0.0, 52.67955801104972, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [
              94.47571271259025,
              98.9966964394959,
              99.07010889514254,
              99.07010889514254,
            ],
            [94.78159794445122, 99.69411476813899, 100.0, 100.0],
          ],
          [
            [
              93.1941031941032,
              98.67936117936117,
              98.73464373464373,
              98.73464373464373,
            ],
            [93.64250614250615, 99.6007371007371, 100.0, 100.0],
          ],
          [
            [
              92.10558776366102,
              98.37177747625508,
              98.4211175527322,
              98.4211175527322,
            ],
            [92.62982607623042, 99.5065992352288, 100.0, 100.0],
          ],
          [
            [
              91.18776319048798,
              98.06787218231361,
              98.11741392122865,
              98.11741392122865,
            ],
            [91.75130047064654, 99.41169185038395, 100.0, 100.0],
          ],
          [
            [
              87.93125157391087,
              96.9403173004281,
              96.96549987408714,
              96.96549987408714,
            ],
            [88.55452027197181, 99.02417527071266, 100.0, 100.0],
          ],
          [
            [
              85.08962868117798,
              95.9090909090909,
              95.9346991037132,
              95.9346991037132,
            ],
            [85.74263764404611, 98.6235595390525, 100.0, 100.0],
          ],
          [
            [
              82.46939307111226,
              94.92055222714248,
              94.9466006772597,
              94.9466006772597,
            ],
            [83.211773899453, 98.20916905444126, 100.0, 100.0],
          ],
          [
            [
              77.79875910439709,
              92.95926625303483,
              92.98624224440249,
              92.98624224440249,
            ],
            [78.72943080658213, 97.41030482870246, 100.0, 100.0],
          ],
          [
            [
              69.53964565785652,
              88.95585245425501,
              88.98489689224515,
              88.98489689224515,
            ],
            [70.83938425791462, 95.81760092942201, 100.0, 100.0],
          ],
          [
            [
              49.59575997125405,
              78.20697089471793,
              78.24290334171758,
              78.24290334171758,
            ],
            [52.057132590729424, 91.12468559108876, 100.0, 100.0],
          ],
          [
            [
              31.417804992934535,
              63.84832783796516,
              63.8954309938766,
              63.8954309938766,
            ],
            [35.8337258596326, 86.05746585021197, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              94.81830417227457,
              99.32705248990578,
              99.32705248990578,
              99.32705248990578,
            ],
            [95.49125168236878, 100.0, 100.0, 100.0],
          ],
          [
            [
              93.78378378378378,
              98.91891891891892,
              98.91891891891892,
              98.91891891891892,
            ],
            [94.05405405405405, 100.0, 100.0, 100.0],
          ],
          [
            [
              92.80868385345997,
              98.50746268656717,
              98.50746268656717,
              98.50746268656717,
            ],
            [92.80868385345997, 100.0, 100.0, 100.0],
          ],
          [
            [
              91.96185286103542,
              98.09264305177112,
              98.09264305177112,
              98.09264305177112,
            ],
            [91.96185286103542, 100.0, 100.0, 100.0],
          ],
          [
            [
              88.50415512465375,
              96.39889196675901,
              96.39889196675901,
              96.39889196675901,
            ],
            [88.50415512465375, 100.0, 100.0, 100.0],
          ],
          [
            [
              84.92957746478874,
              94.64788732394366,
              94.64788732394366,
              94.64788732394366,
            ],
            [85.35211267605634, 100.0, 100.0, 100.0],
          ],
          [
            [
              82.09169054441261,
              92.83667621776505,
              92.83667621776505,
              92.83667621776505,
            ],
            [82.6647564469914, 100.0, 100.0, 100.0],
          ],
          [
            [
              77.89317507418397,
              89.46587537091987,
              89.46587537091987,
              89.46587537091987,
            ],
            [78.486646884273, 100.0, 100.0, 100.0],
          ],
          [
            [
              70.52715654952078,
              85.30351437699682,
              85.30351437699682,
              85.30351437699682,
            ],
            [71.8849840255591, 100.0, 100.0, 100.0],
          ],
          [
            [
              48.023715415019765,
              81.81818181818181,
              81.81818181818181,
              81.81818181818181,
            ],
            [49.30830039525692, 100.0, 100.0, 100.0],
          ],
          [
            [
              16.70984455958549,
              76.16580310880829,
              76.16580310880829,
              76.16580310880829,
            ],
            [21.1139896373057, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              90.95558546433378,
              97.73889636608344,
              97.92732166890983,
              97.92732166890983,
            ],
            [91.35935397039032, 99.35397039030956, 100.0, 100.0],
          ],
          [
            [
              88.24324324324324,
              97.0810810810811,
              97.32432432432432,
              97.32432432432432,
            ],
            [88.89189189189189, 99.02702702702703, 100.0, 100.0],
          ],
          [
            [
              86.29579375848031,
              96.33649932157394,
              96.66214382632293,
              96.66214382632293,
            ],
            [87.27272727272728, 98.69742198100407, 100.0, 100.0],
          ],
          [
            [
              84.44141689373296,
              95.5858310626703,
              96.0217983651226,
              96.0217983651226,
            ],
            [85.09536784741145, 98.36512261580381, 100.0, 100.0],
          ],
          [
            [
              79.86149584487534,
              93.85041551246537,
              94.29362880886426,
              94.29362880886426,
            ],
            [80.0, 97.00831024930748, 100.0, 100.0],
          ],
          [
            [
              75.38028169014085,
              92.05633802816902,
              92.50704225352113,
              92.50704225352113,
            ],
            [75.6056338028169, 95.6056338028169, 100.0, 100.0],
          ],
          [
            [
              72.72206303724928,
              90.42979942693408,
              90.6590257879656,
              90.6590257879656,
            ],
            [73.18051575931231, 94.15472779369628, 100.0, 100.0],
          ],
          [
            [
              65.3412462908012,
              87.29970326409496,
              87.29970326409496,
              87.29970326409496,
            ],
            [67.00296735905043, 92.40356083086054, 100.0, 100.0],
          ],
          [
            [
              53.22683706070288,
              79.16932907348243,
              79.16932907348243,
              79.16932907348243,
            ],
            [53.22683706070288, 91.82108626198084, 100.0, 100.0],
          ],
          [
            [
              29.209486166007903,
              52.17391304347826,
              52.17391304347826,
              52.17391304347826,
            ],
            [29.209486166007903, 89.88142292490119, 100.0, 100.0],
          ],
          [
            [
              3.575129533678757,
              9.481865284974093,
              9.481865284974093,
              9.481865284974093,
            ],
            [5.492227979274611, 86.73575129533678, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              88.41184387617767,
              97.22745625841185,
              97.71197846567968,
              97.71197846567968,
            ],
            [88.8963660834455, 98.78869448183042, 100.0, 100.0],
          ],
          [
            [
              85.91891891891892,
              96.4054054054054,
              96.9054054054054,
              96.9054054054054,
            ],
            [86.77027027027026, 98.37837837837839, 100.0, 100.0],
          ],
          [
            [
              84.27408412483041,
              95.82089552238806,
              96.21438263229308,
              96.21438263229308,
            ],
            [84.87109905020353, 97.96472184531886, 100.0, 100.0],
          ],
          [
            [
              83.01089918256132,
              95.24523160762944,
              95.40871934604904,
              95.40871934604904,
            ],
            [83.46049046321525, 97.54768392370572, 100.0, 100.0],
          ],
          [
            [
              77.45152354570638,
              93.36565096952909,
              93.36565096952909,
              93.36565096952909,
            ],
            [77.57617728531856, 95.84487534626038, 100.0, 100.0],
          ],
          [
            [
              73.91549295774648,
              91.56338028169014,
              91.56338028169014,
              91.56338028169014,
            ],
            [73.94366197183099, 94.08450704225352, 100.0, 100.0],
          ],
          [
            [
              69.5272206303725,
              89.7134670487106,
              89.74212034383953,
              89.74212034383953,
            ],
            [69.92836676217766, 92.26361031518626, 100.0, 100.0],
          ],
          [
            [
              61.32047477744806,
              85.78635014836794,
              85.84569732937686,
              85.84569732937686,
            ],
            [61.73590504451039, 88.4272997032641, 100.0, 100.0],
          ],
          [
            [
              46.805111821086264,
              77.44408945686901,
              78.01916932907348,
              78.01916932907348,
            ],
            [48.81789137380191, 79.87220447284345, 100.0, 100.0],
          ],
          [
            [
              14.051383399209488,
              51.77865612648221,
              51.77865612648221,
              51.77865612648221,
            ],
            [14.051383399209488, 54.624505928853765, 100.0, 100.0],
          ],
          [
            [
              0.10362694300518145,
              6.89119170984456,
              6.89119170984456,
              6.89119170984456,
            ],
            [0.10362694300518145, 40.51813471502591, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [
              94.41453566621806,
              98.46445613605776,
              98.61739875198826,
              98.61739875198826,
            ],
            [94.94065826501895, 99.59011378930626, 99.96329377217668, 100.0],
          ],
          [
            [
              92.96683046683046,
              97.85012285012286,
              98.04668304668304,
              98.04668304668304,
            ],
            [93.67936117936115, 99.42260442260444, 99.94471744471744, 100.0],
          ],
          [
            [
              91.61218699888984,
              97.3047983224374,
              97.52682866658444,
              97.52682866658444,
            ],
            [92.48180584679905, 99.25373134328356, 99.92598988528431, 100.0],
          ],
          [
            [
              90.40128808521179,
              96.7921724052514,
              97.0336883824622,
              97.0336883824622,
            ],
            [91.38593014614813, 99.08347783007184, 99.9071092395343, 100.0],
          ],
          [
            [
              85.91035003777385,
              94.90052883404682,
              95.20901536136994,
              95.20901536136994,
            ],
            [87.28909594560564, 98.45127171996978, 99.83001762780155, 100.0],
          ],
          [
            [
              81.5236875800256,
              92.97055057618437,
              93.36107554417414,
              93.36107554417414,
            ],
            [83.30345710627401, 97.81049935979512, 99.75032010243278, 100.0],
          ],
          [
            [
              77.55926022401668,
              90.99374837197186,
              91.4691325866111,
              91.4691325866111,
            ],
            [79.71476947121647, 97.14769471216464, 99.66788226100547, 100.0],
          ],
          [
            [
              71.12894523873753,
              87.4831400053952,
              88.13730779606152,
              88.13730779606152,
            ],
            [73.5635284596709, 95.75128135958998, 99.49420016185596, 100.0],
          ],
          [
            [
              60.56491431890794,
              80.62009875108916,
              81.67295962823121,
              81.67295962823121,
            ],
            [63.62910252686609, 92.94946267789719, 99.10688353180366, 100.0],
          ],
          [
            [
              39.57060725835429,
              64.04060366510961,
              66.29536471433704,
              66.29536471433704,
            ],
            [45.11318720804886, 86.14804168163853, 98.39202299676609, 100.0],
          ],
          [
            [
              26.990108337258594,
              50.129533678756474,
              52.64955252001884,
              52.64955252001884,
            ],
            [32.87800282618936, 81.34715025906733, 97.89213377296278, 100.0],
          ],
        ],
        [
          [
            [
              94.21265141318977,
              98.51951547779272,
              99.0578734858681,
              99.0578734858681,
            ],
            [94.6164199192463, 100.0, 100.0, 100.0],
          ],
          [
            [
              93.37837837837839,
              98.1081081081081,
              98.64864864864865,
              98.64864864864865,
            ],
            [93.64864864864865, 100.0, 100.0, 100.0],
          ],
          [
            [
              92.53731343283582,
              97.69335142469471,
              98.23609226594301,
              98.23609226594301,
            ],
            [92.60515603799186, 100.0, 100.0, 100.0],
          ],
          [
            [
              91.68937329700273,
              97.27520435967303,
              97.82016348773843,
              97.82016348773843,
            ],
            [91.68937329700273, 100.0, 100.0, 100.0],
          ],
          [
            [
              88.22714681440443,
              95.56786703601108,
              96.1218836565097,
              96.1218836565097,
            ],
            [88.22714681440443, 100.0, 100.0, 100.0],
          ],
          [
            [
              84.64788732394366,
              93.80281690140845,
              94.36619718309859,
              94.36619718309859,
            ],
            [84.78873239436619, 100.0, 100.0, 100.0],
          ],
          [
            [
              80.94555873925502,
              91.97707736389685,
              92.55014326647564,
              92.55014326647564,
            ],
            [81.9484240687679, 100.0, 100.0, 100.0],
          ],
          [
            [
              74.40652818991099,
              88.13056379821958,
              88.79821958456972,
              88.79821958456972,
            ],
            [76.40949554896143, 100.0, 100.0, 100.0],
          ],
          [
            [
              59.50479233226837,
              80.03194888178913,
              80.43130990415335,
              80.43130990415335,
            ],
            [62.77955271565495, 100.0, 100.0, 100.0],
          ],
          [
            [
              32.31225296442688,
              59.48616600790514,
              64.03162055335969,
              64.03162055335969,
            ],
            [42.19367588932806, 100.0, 100.0, 100.0],
          ],
          [
            [
              11.26943005181347,
              46.89119170984456,
              52.84974093264248,
              52.84974093264248,
            ],
            [24.222797927461137, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              91.46702557200538,
              97.57738896366084,
              97.65814266487214,
              97.65814266487214,
            ],
            [92.97442799461642, 99.08479138627186, 100.0, 100.0],
          ],
          [
            [
              89.32432432432432,
              96.43243243243244,
              96.75675675675676,
              96.75675675675676,
            ],
            [90.43243243243244, 98.67567567567568, 100.0, 100.0],
          ],
          [
            [
              87.13704206241519,
              95.49525101763908,
              95.92944369063771,
              95.92944369063771,
            ],
            [88.03256445047491, 98.26322930800542, 100.0, 100.0],
          ],
          [
            [
              85.61307901907355,
              94.65940054495913,
              95.09536784741145,
              95.09536784741145,
            ],
            [86.10354223433244, 97.84741144414168, 100.0, 100.0],
          ],
          [
            [
              78.86426592797784,
              91.24653739612188,
              91.82825484764543,
              91.82825484764543,
            ],
            [80.66481994459834, 96.14958448753463, 100.0, 100.0],
          ],
          [
            [
              72.11267605633803,
              87.71830985915493,
              88.30985915492958,
              88.30985915492958,
            ],
            [75.52112676056338, 94.39436619718309, 100.0, 100.0],
          ],
          [
            [
              65.44412607449857,
              84.06876790830945,
              84.69914040114614,
              84.69914040114614,
            ],
            [71.31805157593124, 92.57879656160459, 100.0, 100.0],
          ],
          [
            [
              55.48961424332344,
              78.69436201780415,
              79.40652818991099,
              79.40652818991099,
            ],
            [62.166172106824924, 88.75370919881306, 100.0, 100.0],
          ],
          [
            [
              44.05750798722045,
              69.3929712460064,
              70.15974440894568,
              70.15974440894568,
            ],
            [50.7667731629393, 80.22364217252395, 100.0, 100.0],
          ],
          [
            [
              11.581027667984191,
              39.05138339920949,
              43.320158102766804,
              43.320158102766804,
            ],
            [18.774703557312254, 65.21739130434781, 100.0, 100.0],
          ],
          [
            [0.0, 8.082901554404145, 10.932642487046632, 10.932642487046632],
            [0.0, 54.40414507772021, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              90.12113055181696,
              96.59488559892328,
              97.45625841184389,
              97.45625841184389,
            ],
            [91.26514131897711, 98.66756393001346, 100.0, 100.0],
          ],
          [
            [
              87.6891891891892,
              95.32432432432432,
              95.9054054054054,
              95.9054054054054,
            ],
            [89.2027027027027, 98.12162162162161, 100.0, 100.0],
          ],
          [
            [
              85.26458616010856,
              94.07055630936227,
              94.57259158751697,
              94.57259158751697,
            ],
            [87.25915875169608, 97.70691994572591, 100.0, 100.0],
          ],
          [
            [
              82.91553133514988,
              92.82016348773841,
              93.36512261580381,
              93.36512261580381,
            ],
            [85.3133514986376, 97.28882833787466, 100.0, 100.0],
          ],
          [
            [
              75.85872576177285,
              89.12742382271469,
              89.93074792243767,
              89.93074792243767,
            ],
            [76.9252077562327, 95.58171745152355, 100.0, 100.0],
          ],
          [
            [
              67.95774647887323,
              85.56338028169014,
              86.38028169014085,
              86.38028169014085,
            ],
            [68.25352112676056, 93.81690140845069, 100.0, 100.0],
          ],
          [
            [
              58.99713467048711,
              81.9054441260745,
              82.96561604584527,
              82.96561604584527,
            ],
            [61.088825214899714, 91.99140401146131, 100.0, 100.0],
          ],
          [
            [
              52.16617210682494,
              74.25816023738872,
              76.89910979228488,
              76.89910979228488,
            ],
            [53.10089020771513, 88.1454005934718, 100.0, 100.0],
          ],
          [
            [
              39.53674121405751,
              59.185303514377,
              60.84664536741214,
              60.84664536741214,
            ],
            [41.69329073482428, 79.56869009584665, 100.0, 100.0],
          ],
          [
            [
              4.565217391304349,
              22.944664031620555,
              27.845849802371546,
              27.845849802371546,
            ],
            [4.565217391304349, 53.91304347826087, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.7512953367875654, 0.7512953367875654],
            [0.0, 39.58549222797927, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [
              91.01656340877481,
              96.25110633455557,
              96.31432545201667,
              96.31432545201667,
            ],
            [92.19243899355163, 98.7861929447465, 99.95574661777721, 100.0],
          ],
          [
            [
              89.1378872524124,
              95.25774504824783,
              95.32122905027933,
              95.32122905027933,
            ],
            [90.64245810055867, 98.43829355002538, 99.93651599796851, 100.0],
          ],
          [
            [
              87.48565599897998,
              94.33890093076627,
              94.4026520464108,
              94.4026520464108,
            ],
            [89.28981257171998, 98.08746653066429, 99.91712354966211, 100.0],
          ],
          [
            [
              85.99231754161333,
              93.4571062740077,
              93.52112676056339,
              93.52112676056339,
            ],
            [88.04737516005123, 97.76568501920615, 99.89756722151088, 100.0],
          ],
          [
            [
              80.64600156290702,
              90.11461318051576,
              90.16019796822088,
              90.16019796822088,
            ],
            [83.47877051315447, 96.56811669705654, 99.81766084917948, 100.0],
          ],
          [
            [
              76.13967664988074,
              87.03949112112377,
              87.07924728332891,
              87.07924728332891,
            ],
            [79.41293400477075, 95.47442353564804, 99.7349589186324, 100.0],
          ],
          [
            [
              72.10682492581601,
              84.18532506069597,
              84.2257890477475,
              84.2257890477475,
            ],
            [75.83625573239817, 94.34178581062854, 99.64931211222012, 100.0],
          ],
          [
            [
              64.68531468531468,
              78.62237762237764,
              78.66433566433568,
              78.66433566433568,
            ],
            [69.7062937062937, 92.013986013986, 99.46853146853147, 100.0],
          ],
          [
            [
              52.212322561159766,
              68.0459075807913,
              68.09121111446692,
              68.09121111446692,
            ],
            [58.932346723044404, 87.51887647236487, 99.06372697070371, 100.0],
          ],
          [
            [
              29.536024141833273,
              47.7744247453791,
              47.783855149000374,
              47.783855149000374,
            ],
            [38.62693323274235, 76.17880045265937, 98.47227461335346, 100.0],
          ],
          [
            [
              17.164741336012057,
              30.775991963837267,
              30.78854846810648,
              30.78854846810648,
            ],
            [25.313912606730288, 64.70366649924661, 97.96584630838775, 100.0],
          ],
        ],
        [
          [
            [
              91.23783031988873,
              96.03616133518777,
              96.17524339360223,
              96.17524339360223,
            ],
            [93.7413073713491, 99.65229485396384, 100.0, 100.0],
          ],
          [
            [
              89.73463687150837,
              95.25139664804469,
              95.25139664804469,
              95.25139664804469,
            ],
            [91.89944134078212, 99.4413407821229, 100.0, 100.0],
          ],
          [
            [
              88.28892005610098,
              94.60028050490882,
              94.60028050490882,
              94.60028050490882,
            ],
            [90.95371669004207, 99.22861150070126, 100.0, 100.0],
          ],
          [
            [
              86.76056338028168,
              93.94366197183098,
              93.94366197183098,
              93.94366197183098,
            ],
            [90.07042253521126, 99.15492957746478, 100.0, 100.0],
          ],
          [
            [
              82.23495702005731,
              90.83094555873924,
              90.83094555873924,
              90.83094555873924,
            ],
            [87.46418338108883, 99.14040114613181, 100.0, 100.0],
          ],
          [
            [
              79.59183673469387,
              87.46355685131195,
              87.46355685131195,
              87.46355685131195,
            ],
            [84.6209912536443, 99.12536443148687, 100.0, 100.0],
          ],
          [
            [
              76.03857566765579,
              84.56973293768546,
              84.56973293768546,
              84.56973293768546,
            ],
            [82.56676557863501, 99.10979228486647, 100.0, 100.0],
          ],
          [
            [
              67.53846153846155,
              79.15384615384615,
              79.23076923076924,
              79.23076923076924,
            ],
            [78.15384615384615, 99.07692307692308, 100.0, 100.0],
          ],
          [
            [
              50.08305647840532,
              73.83720930232558,
              73.9202657807309,
              73.9202657807309,
            ],
            [68.43853820598007, 99.00332225913621, 100.0, 100.0],
          ],
          [
            [
              18.15352697095436,
              46.6804979253112,
              46.6804979253112,
              46.6804979253112,
            ],
            [35.68464730290456, 98.7551867219917, 100.0, 100.0],
          ],
          [
            [0.0, 12.569060773480665, 12.569060773480665, 12.569060773480665],
            [0.2762430939226519, 98.34254143646407, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              87.64951321279555,
              94.01947148817803,
              94.1029207232267,
              94.1029207232267,
            ],
            [88.09457579972184, 97.44089012517385, 100.0, 100.0],
          ],
          [
            [
              85.64245810055866,
              92.82122905027933,
              92.84916201117319,
              92.84916201117319,
            ],
            [86.64804469273744, 96.92737430167597, 100.0, 100.0],
          ],
          [
            [
              83.47826086956522,
              91.50070126227209,
              91.50070126227209,
              91.50070126227209,
            ],
            [85.16129032258064, 96.15708274894811, 100.0, 100.0],
          ],
          [
            [
              81.35211267605634,
              90.30985915492958,
              90.30985915492958,
              90.30985915492958,
            ],
            [83.26760563380282, 95.38028169014085, 100.0, 100.0],
          ],
          [
            [
              72.49283667621776,
              85.27220630372493,
              85.27220630372493,
              85.27220630372493,
            ],
            [75.44412607449857, 92.03438395415473, 100.0, 100.0],
          ],
          [
            [
              64.89795918367346,
              80.26239067055394,
              80.84548104956268,
              80.84548104956268,
            ],
            [67.11370262390672, 88.74635568513118, 100.0, 100.0],
          ],
          [
            [
              59.64391691394659,
              75.8160237388724,
              76.40949554896143,
              76.40949554896143,
            ],
            [60.534124629080125, 86.76557863501483, 100.0, 100.0],
          ],
          [
            [
              47.29230769230769,
              67.53846153846153,
              68.15384615384616,
              68.15384615384616,
            ],
            [49.47692307692308, 82.58461538461539, 100.0, 100.0],
          ],
          [
            [
              31.22923588039867,
              52.19269102990034,
              52.19269102990034,
              52.19269102990034,
            ],
            [32.59136212624584, 73.62126245847176, 100.0, 100.0],
          ],
          [
            [
              7.883817427385892,
              18.630705394190876,
              18.630705394190876,
              18.630705394190876,
            ],
            [8.547717842323651, 47.05394190871369, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 9.447513812154696, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              83.65785813630042,
              93.894297635605,
              93.894297635605,
              93.894297635605,
            ],
            [84.95132127955493, 96.8289290681502, 100.0, 100.0],
          ],
          [
            [
              80.44692737430168,
              92.3463687150838,
              92.3463687150838,
              92.3463687150838,
            ],
            [81.7877094972067, 95.97765363128492, 100.0, 100.0],
          ],
          [
            [
              77.85413744740532,
              91.30434782608695,
              91.30434782608695,
              91.30434782608695,
            ],
            [79.22861150070125, 95.11921458625527, 100.0, 100.0],
          ],
          [
            [
              75.64788732394365,
              90.01408450704226,
              90.01408450704226,
              90.01408450704226,
            ],
            [76.64788732394366, 94.25352112676056, 100.0, 100.0],
          ],
          [
            [
              68.88252148997134,
              84.9570200573066,
              84.9570200573066,
              84.9570200573066,
            ],
            [68.9971346704871, 90.7163323782235, 100.0, 100.0],
          ],
          [
            [
              63.09037900874635,
              79.48979591836735,
              79.48979591836735,
              79.48979591836735,
            ],
            [64.78134110787171, 87.39067055393586, 100.0, 100.0],
          ],
          [
            [
              54.02077151335312,
              74.25816023738872,
              74.25816023738872,
              74.25816023738872,
            ],
            [59.37685459940653, 84.28783382789318, 100.0, 100.0],
          ],
          [
            [
              40.276923076923076,
              64.12307692307692,
              64.12307692307692,
              64.12307692307692,
            ],
            [46.800000000000004, 77.93846153846154, 100.0, 100.0],
          ],
          [
            [
              26.627906976744185,
              48.05647840531561,
              48.13953488372093,
              48.13953488372093,
            ],
            [30.24916943521595, 70.78073089700997, 100.0, 100.0],
          ],
          [
            [
              3.7759336099585066,
              16.224066390041493,
              16.224066390041493,
              16.224066390041493,
            ],
            [6.016597510373444, 38.60995850622407, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 4.005524861878453, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [
              82.89489783433257,
              89.70390309555853,
              89.99143521350788,
              89.99143521350788,
            ],
            [
              86.68175700477178,
              97.30820995962316,
              99.16799216933808,
              99.48611281047349,
            ],
          ],
          [
            [
              79.85257985257985,
              87.5921375921376,
              87.89926289926292,
              87.89926289926292,
            ],
            [
              84.33046683046683,
              96.65233415233416,
              98.88206388206389,
              99.28132678132677,
            ],
          ],
          [
            [
              77.30356482052547,
              85.75922042679166,
              86.07993092389292,
              86.07993092389292,
            ],
            [
              82.29308005427406,
              96.01578882447271,
              98.59997532996178,
              99.0872085851733,
            ],
          ],
          [
            [
              75.1238543472876,
              84.10948724300222,
              84.42531582858557,
              84.42531582858557,
            ],
            [
              80.56725291057717,
              95.43596730245231,
              98.32796631161752,
              98.89769630914044,
            ],
          ],
          [
            [
              67.5774364140015,
              78.36816922689498,
              78.63888189372955,
              78.63888189372955,
            ],
            [
              74.44598337950139,
              93.40846134474944,
              97.26139511458072,
              98.1238982624024,
            ],
          ],
          [
            [
              61.60691421254803,
              73.61075544174138,
              73.9628681177977,
              73.9628681177977,
            ],
            [
              69.64148527528809,
              91.63892445582587,
              96.22919334186939,
              97.32394366197184,
            ],
          ],
          [
            [
              56.863766605886944,
              69.5102891377963,
              69.9466006772597,
              69.9466006772597,
            ],
            [
              65.55743683250846,
              89.93878614222456,
              95.30476686637144,
              96.57462880958583,
            ],
          ],
          [
            [
              49.15025627191799,
              62.267332074453726,
              62.881035878068516,
              62.881035878068516,
            ],
            [
              58.659293229026154,
              86.78176422983543,
              93.55948206096575,
              95.0431615861883,
            ],
          ],
          [
            [
              36.96630845193146,
              50.04356665698517,
              50.98751089166424,
              50.98751089166424,
            ],
            [
              47.415045018878885,
              80.88875980249782,
              90.22654661632299,
              92.17252396166133,
            ],
          ],
          [
            [
              19.214876033057852,
              30.219187926697806,
              31.162414660438376,
              31.162414660438376,
            ],
            [
              29.38375853395617,
              67.99317283507006,
              82.92310456342076,
              85.22278117139777,
            ],
          ],
          [
            [
              9.797456429580782,
              18.68817710786623,
              18.711728685821953,
              18.711728685821953,
            ],
            [
              17.275082430522843,
              58.113518605746584,
              77.00188412623646,
              79.82807348092324,
            ],
          ],
        ],
        [
          [
            [
              84.38761776581427,
              90.57873485868102,
              91.18438761776582,
              91.18438761776582,
            ],
            [87.34858681022881, 98.18304172274563, 99.66352624495289, 100.0],
          ],
          [
            [
              81.89189189189189,
              88.51351351351352,
              89.1891891891892,
              89.1891891891892,
            ],
            [84.45945945945947, 97.70270270270271, 99.45945945945947, 100.0],
          ],
          [
            [
              79.4436906377205,
              86.29579375848033,
              87.31343283582089,
              87.31343283582089,
            ],
            [82.70013568521031, 97.28629579375848, 99.25373134328358, 100.0],
          ],
          [
            [
              76.90735694822888,
              84.80926430517711,
              85.42234332425068,
              85.42234332425068,
            ],
            [81.53950953678475, 96.86648501362399, 99.04632152588556, 100.0],
          ],
          [
            [
              70.84487534626038,
              79.77839335180056,
              80.60941828254849,
              80.60941828254849,
            ],
            [76.38504155124654, 95.15235457063712, 98.1994459833795, 100.0],
          ],
          [
            [
              65.07042253521126,
              75.98591549295774,
              76.05633802816901,
              76.05633802816901,
            ],
            [72.46478873239437, 93.38028169014085, 97.32394366197184, 100.0],
          ],
          [
            [
              59.957020057306586,
              72.13467048710602,
              72.13467048710602,
              72.13467048710602,
            ],
            [69.19770773638969, 91.54727793696276, 96.41833810888252, 100.0],
          ],
          [
            [
              52.002967359050444,
              64.16913946587538,
              64.16913946587538,
              64.16913946587538,
            ],
            [62.61127596439169, 88.13056379821958, 95.99406528189911, 100.0],
          ],
          [
            [
              35.942492012779546,
              51.43769968051119,
              51.43769968051119,
              51.43769968051119,
            ],
            [50.71884984025559, 80.27156549520767, 95.68690095846645, 100.0],
          ],
          [
            [
              10.079051383399209,
              28.85375494071146,
              28.85375494071146,
              28.85375494071146,
            ],
            [30.8300395256917, 69.07114624505928, 94.66403162055336, 100.0],
          ],
          [
            [0.0, 6.7357512953367875, 6.7357512953367875, 6.7357512953367875],
            [9.32642487046632, 59.4559585492228, 93.00518134715026, 100.0],
          ],
        ],
        [
          [
            [
              76.2314939434724,
              87.3485868102288,
              87.3485868102288,
              87.3485868102288,
            ],
            [
              82.53028263795423,
              95.6393001345895,
              98.0349932705249,
              98.9771197846568,
            ],
          ],
          [
            [
              70.72972972972973,
              85.83783783783784,
              85.83783783783784,
              85.83783783783784,
            ],
            [
              78.62162162162161,
              94.48648648648648,
              97.62162162162163,
              98.56756756756756,
            ],
          ],
          [
            [
              67.10990502035278,
              84.50474898236092,
              84.50474898236092,
              84.50474898236092,
            ],
            [
              75.79375848032564,
              93.64993215739484,
              97.20488466757124,
              98.15468113975577,
            ],
          ],
          [
            [
              63.86920980926432,
              82.17983651226157,
              82.20708446866485,
              82.20708446866485,
            ],
            [
              73.07901907356948,
              93.02452316076295,
              96.78474114441417,
              97.73841961852861,
            ],
          ],
          [
            [
              52.57617728531856,
              74.29362880886427,
              74.29362880886427,
              74.29362880886427,
            ],
            [
              63.40720221606648,
              90.66481994459834,
              95.06925207756233,
              96.0387811634349,
            ],
          ],
          [
            [46.00000000000001, 67.6619718309859, 68.0, 68.0],
            [
              56.760563380281695,
              87.38028169014085,
              93.29577464788731,
              94.28169014084506,
            ],
          ],
          [
            [
              41.174785100286535,
              62.06303724928367,
              62.92263610315185,
              62.92263610315185,
            ],
            [
              50.51575931232092,
              83.83954154727795,
              91.46131805157593,
              92.46418338108883,
            ],
          ],
          [
            [
              33.23442136498517,
              50.62314540059347,
              54.03560830860534,
              54.03560830860534,
            ],
            [
              39.465875370919875,
              77.83382789317508,
              88.45697329376854,
              88.63501483679525,
            ],
          ],
          [
            [
              21.118210862619808,
              33.83386581469649,
              37.12460063897763,
              37.12460063897763,
            ],
            [
              24.504792332268373,
              68.72204472843451,
              79.93610223642172,
              80.5111821086262,
            ],
          ],
          [
            [0.0, 4.940711462450593, 6.521739130434784, 6.521739130434784],
            [
              1.1857707509881432,
              49.36758893280632,
              65.61264822134387,
              65.61264822134387,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 29.430051813471504, 54.92227979274612, 54.92227979274612],
          ],
        ],
        [
          [
            [
              73.9434724091521,
              78.82907133243606,
              80.28263795423958,
              80.28263795423958,
            ],
            [
              76.70255720053835,
              93.09555854643338,
              97.84656796769852,
              98.43876177658142,
            ],
          ],
          [
            [
              69.09459459459461,
              76.01351351351352,
              76.01351351351352,
              76.01351351351352,
            ],
            [
              71.94594594594594,
              91.48648648648648,
              97.05405405405405,
              97.98648648648648,
            ],
          ],
          [
            [
              63.839891451831754,
              72.04884667571234,
              72.04884667571234,
              72.04884667571234,
            ],
            [
              67.44911804613297,
              89.86431478968792,
              96.26865671641791,
              97.55766621438265,
            ],
          ],
          [
            [
              59.44141689373297,
              69.03269754768392,
              69.03269754768392,
              69.03269754768392,
            ],
            [
              64.45504087193461,
              88.22888283378747,
              95.59945504087193,
              97.13896457765668,
            ],
          ],
          [
            [
              45.73407202216067,
              59.73684210526316,
              59.73684210526316,
              59.73684210526316,
            ],
            [
              53.57340720221606,
              81.8005540166205,
              93.86426592797784,
              95.42936288088643,
            ],
          ],
          [
            [
              35.15492957746479,
              51.95774647887325,
              51.95774647887325,
              51.95774647887325,
            ],
            [
              43.929577464788736,
              76.4225352112676,
              92.07042253521126,
              93.66197183098592,
            ],
          ],
          [
            [
              28.710601719197705,
              44.48424068767908,
              44.51289398280803,
              44.51289398280803,
            ],
            [
              38.495702005730664,
              72.02005730659026,
              90.21489971346705,
              91.83381088825216,
            ],
          ],
          [
            [
              19.391691394658757,
              34.76261127596439,
              34.85163204747774,
              34.85163204747774,
            ],
            [
              32.22551928783383,
              67.15133531157271,
              86.32047477744807,
              88.04154302670622,
            ],
          ],
          [
            [
              8.562300319488818,
              24.16932907348243,
              24.760383386581474,
              24.760383386581474,
            ],
            [
              19.15335463258786,
              56.932907348242814,
              77.60383386581469,
              80.36741214057508,
            ],
          ],
          [
            [0.0, 0.0, 0.4743083003952573, 0.4743083003952573],
            [0.0, 33.59683794466403, 51.462450592885375, 52.17391304347826],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 12.95336787564767, 31.34715025906736, 31.34715025906736],
          ],
        ],
      ],
      [
        [
          [
            [
              73.80831963585788,
              83.41762548994815,
              83.860159312176,
              83.860159312176,
            ],
            [
              79.13769123783031,
              95.12580604374762,
              99.08332279681376,
              99.56378808951825,
            ],
          ],
          [
            [
              69.90858303707465,
              80.04062976130015,
              80.44692737430168,
              80.44692737430168,
            ],
            [
              75.74911122397154,
              93.85474860335198,
              98.77475876079227,
              99.42864398171662,
            ],
          ],
          [
            [
              66.69641718730077,
              77.16435037613158,
              77.52773173530537,
              77.52773173530537,
            ],
            [
              72.99502741297972,
              92.73874792808876,
              98.46359811296698,
              99.29236261634578,
            ],
          ],
          [
            [
              63.911651728553146,
              74.59667093469909,
              74.8911651728553,
              74.8911651728553,
            ],
            [
              70.6786171574904,
              91.69654289372602,
              98.16901408450704,
              99.15492957746478,
            ],
          ],
          [
            [
              54.4738213076322,
              65.71372753321178,
              66.01328470955977,
              66.01328470955977,
            ],
            [
              62.93305548319875,
              87.68559520708519,
              96.97186767387342,
              98.59338369367023,
            ],
          ],
          [
            [
              46.76649880731513,
              58.49456665783198,
              58.845746090644056,
              58.845746090644056,
            ],
            [
              56.42724622316459,
              84.07765703684072,
              95.8984892658362,
              98.01219188974291,
            ],
          ],
          [
            [
              40.9360669004586,
              52.62341516050715,
              53.048287024548145,
              53.048287024548145,
            ],
            [
              51.18019962233613,
              80.73239816563259,
              94.85432964661452,
              97.41030482870246,
            ],
          ],
          [
            [
              32.06293706293707,
              42.531468531468526,
              43.05594405594405,
              43.05594405594405,
            ],
            [
              43.18181818181817,
              74.51748251748253,
              92.65034965034965,
              96.13986013986012,
            ],
          ],
          [
            [
              20.386590154032017,
              29.160374509211717,
              29.658713379643608,
              29.658713379643608,
            ],
            [
              31.123527635155547,
              64.51223195409243,
              87.94926004228327,
              93.29507701600724,
            ],
          ],
          [
            [
              5.356469256884194,
              12.146359864202187,
              12.731044888721236,
              12.731044888721236,
            ],
            [
              11.373066767257638,
              47.54809505846849,
              79.04564315352697,
              86.35420596001508,
            ],
          ],
          [
            [
              1.9337016574585637,
              4.796584630838774,
              5.575087895529885,
              5.575087895529885,
            ],
            [
              3.465595178302361,
              35.9618282270216,
              71.43395278754394,
              79.74635861376194,
            ],
          ],
        ],
        [
          [
            [
              75.31293463143254,
              85.81363004172462,
              85.81363004172462,
              85.81363004172462,
            ],
            [81.91933240611962, 97.14881780250349, 100.0, 100.0],
          ],
          [
            [
              71.50837988826815,
              83.24022346368716,
              83.51955307262571,
              83.51955307262571,
            ],
            [78.14245810055867, 96.36871508379889, 100.0, 100.0],
          ],
          [
            [
              68.5133239831697,
              80.92566619915848,
              81.48667601683029,
              81.48667601683029,
            ],
            [75.45582047685835, 95.58204768583451, 100.0, 100.0],
          ],
          [
            [
              66.26760563380282,
              78.16901408450704,
              78.87323943661971,
              78.87323943661971,
            ],
            [73.2394366197183, 94.71830985915493, 100.0, 100.0],
          ],
          [
            [
              57.37822349570201,
              68.83954154727793,
              69.41260744985674,
              69.41260744985674,
            ],
            [66.189111747851, 91.189111747851, 100.0, 100.0],
          ],
          [
            [
              48.39650145772595,
              63.11953352769679,
              63.26530612244898,
              63.26530612244898,
            ],
            [57.79883381924198, 88.41107871720118, 100.0, 100.0],
          ],
          [
            [
              41.097922848664695,
              57.27002967359051,
              57.27002967359051,
              57.27002967359051,
            ],
            [51.261127596439174, 85.53412462908013, 100.0, 100.0],
          ],
          [
            [
              30.61538461538461,
              42.69230769230769,
              42.69230769230769,
              42.69230769230769,
            ],
            [41.69230769230769, 79.84615384615384, 100.0, 100.0],
          ],
          [
            [
              19.186046511627907,
              26.74418604651163,
              26.74418604651163,
              26.74418604651163,
            ],
            [22.674418604651166, 70.265780730897, 100.0, 100.0],
          ],
          [
            [0.0, 3.319502074688797, 3.319502074688797, 3.319502074688797],
            [0.7261410788381742, 51.86721991701244, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 35.911602209944746, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              63.86648122392212,
              77.19054242002782,
              78.97079276773297,
              78.97079276773297,
            ],
            [
              66.2586926286509,
              93.7413073713491,
              98.88734353268428,
              99.19332406119611,
            ],
          ],
          [
            [
              58.60335195530726,
              73.60335195530726,
              75.36312849162012,
              75.36312849162012,
            ],
            [
              61.675977653631286,
              91.95530726256983,
              98.46368715083798,
              98.77094972067039,
            ],
          ],
          [
            [
              54.44600280504909,
              70.46283309957924,
              72.0056100981767,
              72.0056100981767,
            ],
            [
              57.784011220196355,
              90.18232819074333,
              98.03646563814866,
              98.34502103786815,
            ],
          ],
          [
            [
              50.901408450704224,
              67.88732394366197,
              68.50704225352113,
              68.50704225352113,
            ],
            [
              54.25352112676056,
              88.47887323943662,
              97.60563380281691,
              97.91549295774648,
            ],
          ],
          [
            [
              40.40114613180516,
              55.98853868194843,
              56.4756446991404,
              56.4756446991404,
            ],
            [
              43.06590257879656,
              81.46131805157594,
              95.84527220630372,
              96.16045845272205,
            ],
          ],
          [
            [
              32.6530612244898,
              47.93002915451895,
              48.0466472303207,
              48.0466472303207,
            ],
            [
              34.72303206997085,
              74.34402332361516,
              94.02332361516035,
              94.34402332361516,
            ],
          ],
          [
            [
              27.922848664688427,
              40.23738872403561,
              40.35608308605341,
              40.35608308605341,
            ],
            [
              30.089020771513354,
              68.90207715133532,
              92.13649851632047,
              92.46290801186943,
            ],
          ],
          [
            [21.6, 26.092307692307692, 26.092307692307692, 26.092307692307692],
            [
              23.200000000000003,
              58.246153846153845,
              88.15384615384613,
              88.49230769230769,
            ],
          ],
          [
            [
              8.106312292358805,
              10.897009966777409,
              10.897009966777409,
              10.897009966777409,
            ],
            [15.348837209302326, 40.0, 79.2358803986711, 79.60132890365449],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 11.28630705394191, 52.36514522821577, 63.60995850622406],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 36.574585635359114, 51.546961325966855],
          ],
        ],
        [
          [
            [
              54.89568845618915,
              72.5452016689847,
              72.65646731571628,
              72.65646731571628,
            ],
            [
              59.165507649513216,
              85.70236439499304,
              97.64951321279554,
              98.65090403337969,
            ],
          ],
          [
            [50.96368715083799, 67.0391061452514, 67.5, 67.5],
            [
              55.18156424581006,
              82.30446927374302,
              96.57821229050279,
              98.22625698324022,
            ],
          ],
          [
            [
              48.16269284712482,
              62.412342215988794,
              63.88499298737729,
              63.88499298737729,
            ],
            [
              51.61290322580645,
              79.84572230014025,
              95.38569424964936,
              97.79803646563815,
            ],
          ],
          [
            [
              45.718309859154935,
              58.85915492957747,
              60.309859154929576,
              60.309859154929576,
            ],
            [
              48.49295774647888,
              78.0281690140845,
              94.1830985915493,
              97.36619718309859,
            ],
          ],
          [
            [
              36.891117478510026,
              49.01146131805157,
              49.26934097421204,
              49.26934097421204,
            ],
            [
              39.240687679083095,
              71.9484240687679,
              89.52722063037248,
              95.60171919770772,
            ],
          ],
          [
            [
              29.620991253644313,
              40.20408163265306,
              40.466472303207,
              40.466472303207,
            ],
            [
              31.311953352769677,
              64.63556851311954,
              87.59475218658892,
              93.77551020408164,
            ],
          ],
          [
            [
              23.189910979228486,
              33.24925816023738,
              34.45103857566766,
              34.45103857566766,
            ],
            [
              24.762611275964392,
              57.06231454005935,
              85.59347181008901,
              91.88427299703264,
            ],
          ],
          [
            [10.200000000000001, 17.907692307692308, 18.2, 18.2],
            [
              12.200000000000003,
              42.55384615384616,
              81.36923076923077,
              87.8923076923077,
            ],
          ],
          [
            [
              0.5315614617940204,
              6.827242524916944,
              6.827242524916944,
              6.827242524916944,
            ],
            [
              1.2790697674418616,
              27.92358803986711,
              71.91029900332225,
              78.95348837209303,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 8.153526970954356, 49.41908713692946, 49.89626556016597],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 14.419889502762432, 25.82872928176796],
          ],
        ],
      ],
      [
        [
          [
            [
              66.90321791263918,
              78.70427015783679,
              79.64027896733144,
              79.64639667196866,
            ],
            [
              71.95032423834579,
              92.1448672458094,
              97.96280435580567,
              98.67857579836044,
            ],
          ],
          [
            [
              62.4938574938575,
              74.92628992628994,
              75.98894348894348,
              75.995085995086,
            ],
            [
              67.83783783783784,
              90.0982800982801,
              97.43243243243242,
              98.26167076167077,
            ],
          ],
          [
            [
              59.09090909090909,
              71.67879610213394,
              72.8444554089059,
              72.8444554089059,
            ],
            [
              64.87603305785123,
              88.23855926976685,
              96.9162452201801,
              97.86604169236462,
            ],
          ],
          [
            [
              56.3723061679465,
              68.80728263562051,
              70.0767896953183,
              70.0767896953183,
            ],
            [
              62.62695070596978,
              86.53703244983897,
              96.41441664602426,
              97.46717859796881,
            ],
          ],
          [
            [
              47.32435154872828,
              59.37421304457315,
              60.73407202216067,
              60.73407202216067,
            ],
            [
              55.086879879123664,
              80.86124401913875,
              94.39058171745155,
              95.83857970284562,
            ],
          ],
          [
            [
              40.59539052496799,
              51.90781049935978,
              53.3226632522407,
              53.3226632522407,
            ],
            [
              49.39820742637645,
              76.06274007682458,
              92.50320102432781,
              94.24455825864278,
            ],
          ],
          [
            [
              35.27611357124251,
              45.8192237561865,
              47.212815837457676,
              47.212815837457676,
            ],
            [
              44.86194321437875,
              71.82860119822872,
              90.73977598332897,
              92.66736129200311,
            ],
          ],
          [
            [
              28.095495009441596,
              36.613164283787434,
              37.72592392770434,
              37.72592392770434,
            ],
            [
              38.19125977879686,
              64.27029943350416,
              87.17291610466685,
              89.71540329107094,
            ],
          ],
          [
            [
              17.869590473424342,
              25.04356665698519,
              25.980249782166716,
              25.980249782166716,
            ],
            [
              28.855649143189076,
              53.608771420273015,
              80.01016555329656,
              83.93842579146093,
            ],
          ],
          [
            [
              7.258354293927417,
              12.055335968379447,
              12.450592885375498,
              12.450592885375498,
            ],
            [
              14.696370822853035,
              38.959755659360404,
              68.76572044556234,
              74.299317283507,
            ],
          ],
          [
            [
              1.000942063118229,
              3.3089967027790856,
              3.827131417804993,
              3.827131417804993,
            ],
            [
              6.983042863871878,
              25.800753650494585,
              58.325482807348095,
              65.01413094677343,
            ],
          ],
        ],
        [
          [
            [
              67.6985195154778,
              80.95558546433378,
              81.76312247644684,
              81.76312247644684,
            ],
            [
              75.97577388963661,
              95.02018842530282,
              98.58681022880216,
              98.92328398384926,
            ],
          ],
          [
            [
              63.986486486486484,
              77.16216216216216,
              78.17567567567568,
              78.17567567567568,
            ],
            [
              71.55405405405406,
              93.58108108108108,
              98.17567567567566,
              98.51351351351352,
            ],
          ],
          [
            [
              61.46540027137042,
              74.08412483039348,
              75.7123473541384,
              75.7123473541384,
            ],
            [
              68.3853459972863,
              91.85888738127544,
              97.76119402985076,
              98.10040705563094,
            ],
          ],
          [
            [
              59.19618528610354,
              70.91280653950953,
              73.56948228882834,
              73.56948228882834,
            ],
            [
              65.9400544959128,
              90.19073569482289,
              97.34332425068119,
              97.68392370572208,
            ],
          ],
          [
            [
              49.86149584487535,
              62.396121883656505,
              63.227146814404435,
              63.227146814404435,
            ],
            [
              58.72576177285319,
              84.14127423822715,
              95.6371191135734,
              95.98337950138504,
            ],
          ],
          [
            [
              41.690140845070424,
              56.690140845070424,
              56.690140845070424,
              56.690140845070424,
            ],
            [
              51.61971830985915,
              78.59154929577464,
              93.87323943661971,
              94.29577464788733,
            ],
          ],
          [
            [
              37.17765042979943,
              48.853868194842406,
              49.14040114613181,
              49.14040114613181,
            ],
            [
              44.84240687679083,
              73.4240687679083,
              92.0487106017192,
              92.4785100286533,
            ],
          ],
          [
            [
              27.81899109792285,
              39.39169139465876,
              39.465875370919875,
              39.465875370919875,
            ],
            [
              35.38575667655786,
              64.2433234421365,
              88.20474777448071,
              88.64985163204749,
            ],
          ],
          [
            [13.019169329073483, 25.0, 25.0, 25.0],
            [
              24.041533546325876,
              52.635782747603834,
              79.63258785942492,
              80.59105431309904,
            ],
          ],
          [
            [0.0, 3.0632411067193677, 3.0632411067193677, 3.0632411067193677],
            [4.24901185770751, 32.905138339920946, 66.50197628458498, 75.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 5.051813471502591, 56.0880829015544, 67.2279792746114],
          ],
        ],
        [
          [
            [
              57.388963660834456,
              71.46702557200538,
              73.94347240915208,
              73.94347240915208,
            ],
            [
              59.73082099596232,
              87.45625841184388,
              96.79676985195155,
              98.25033647375506,
            ],
          ],
          [
            [
              50.810810810810814,
              67.54054054054055,
              68.2972972972973,
              68.2972972972973,
            ],
            [
              52.54054054054053,
              83.94594594594594,
              96.13513513513513,
              97.43243243243242,
            ],
          ],
          [
            [
              46.7842605156038,
              63.98914518317503,
              64.42333785617367,
              64.42333785617367,
            ],
            [
              48.62957937584804,
              80.48846675712348,
              95.38670284938942,
              96.6078697421981,
            ],
          ],
          [
            [
              44.60490463215259,
              60.98092643051771,
              61.36239782016348,
              61.36239782016348,
            ],
            [
              46.4850136239782,
              77.90190735694823,
              94.63215258855587,
              95.77656675749317,
            ],
          ],
          [
            [
              37.03601108033241,
              51.52354570637119,
              52.04986149584487,
              52.04986149584487,
            ],
            [
              39.83379501385041,
              71.7174515235457,
              91.21883656509695,
              92.40997229916897,
            ],
          ],
          [
            [31.774647887323944, 42.0, 43.38028169014085, 43.38028169014085],
            [
              34.02816901408451,
              67.52112676056338,
              87.69014084507042,
              88.90140845070424,
            ],
          ],
          [
            [
              25.845272206303726,
              34.75644699140401,
              36.618911174785104,
              36.618911174785104,
            ],
            [
              29.34097421203439,
              63.180515759312314,
              84.04011461318052,
              85.27220630372493,
            ],
          ],
          [
            [
              16.023738872403563,
              23.709198813056382,
              26.26112759643917,
              26.26112759643917,
            ],
            [
              22.848664688427302,
              53.41246290801187,
              76.35014836795251,
              77.74480712166172,
            ],
          ],
          [
            [
              0.638977635782748,
              5.399361022364218,
              5.782747603833867,
              5.782747603833867,
            ],
            [
              4.249201277955272,
              41.277955271565496,
              62.14057507987221,
              66.19808306709265,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 15.256916996047432, 46.75889328063241, 48.81422924901185],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 26.06217616580311, 31.917098445595855],
          ],
        ],
        [
          [
            [
              45.289367429340516,
              68.60026917900404,
              70.20188425302827,
              70.20188425302827,
            ],
            [
              48.734858681022885,
              82.34185733512787,
              94.69717362045759,
              96.81022880215343,
            ],
          ],
          [
            [
              39.432432432432435,
              62.472972972972975,
              67.04054054054053,
              67.04054054054053,
            ],
            [42.5945945945946, 78.5, 93.13513513513513, 96.35135135135135],
          ],
          [
            [
              35.10176390773406,
              58.00542740841248,
              62.795115332428765,
              62.795115332428765,
            ],
            [
              38.46675712347354,
              75.40027137042063,
              91.56037991858888,
              95.56309362279511,
            ],
          ],
          [
            [
              31.321525885558586,
              54.27792915531335,
              58.8283378746594,
              58.8283378746594,
            ],
            [
              34.94550408719346,
              72.77929155313352,
              90.21798365122615,
              94.76839237057222,
            ],
          ],
          [
            [
              18.559556786703606,
              41.34349030470915,
              45.83102493074792,
              45.83102493074792,
            ],
            [
              22.493074792243767,
              63.476454293628805,
              85.23545706371192,
              91.38504155124653,
            ],
          ],
          [
            [
              11.19718309859155,
              32.21126760563381,
              35.38028169014085,
              35.38028169014085,
            ],
            [
              13.67605633802817,
              54.47887323943662,
              80.08450704225353,
              87.85915492957747,
            ],
          ],
          [
            [
              7.320916905444126,
              25.716332378223495,
              28.93982808022923,
              28.93982808022923,
            ],
            [
              7.607449856733525,
              47.89398280802292,
              76.17478510028654,
              84.21203438395416,
            ],
          ],
          [
            [
              1.9287833827893175,
              16.275964391691396,
              17.87833827893175,
              17.87833827893175,
            ],
            [
              2.759643916913947,
              37.35905044510386,
              70.20771513353117,
              77.59643916913946,
            ],
          ],
          [
            [0.0, 3.6102236421725236, 3.6102236421725236, 3.6102236421725236],
            [0.0, 16.23003194888179, 60.495207667731634, 63.27476038338658],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 28.83399209486166, 43.399209486166015],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 4.637305699481866, 10.906735751295338],
          ],
        ],
      ],
    ],
    dimensions: ['month', 'quantiles', 'duration', 'magw_limit', 'hs_limit'],
    attributes: {
      units: '%',
      description: 'probability that the operability window occurs',
      vartype: 'f8',
    },
  },
  probabilityMin: {
    values: [
      [
        [
          [
            [
              34.58950201884253,
              46.16419919246299,
              46.16419919246299,
              46.16419919246299,
            ],
            [
              46.56796769851951,
              77.65814266487214,
              90.1749663526245,
              93.27052489905788,
            ],
          ],
          [
            [
              29.18918918918919,
              40.945945945945944,
              40.945945945945944,
              40.945945945945944,
            ],
            [
              42.027027027027025,
              72.56756756756756,
              87.29729729729729,
              91.48648648648648,
            ],
          ],
          [
            [
              24.96607869742198,
              37.04206241519674,
              37.04206241519674,
              37.04206241519674,
            ],
            [
              38.670284938941656,
              67.84260515603799,
              84.39620081411127,
              89.00949796472185,
            ],
          ],
          [
            [
              21.662125340599456,
              33.106267029972756,
              33.106267029972756,
              33.106267029972756,
            ],
            [
              35.83106267029973,
              63.62397820163488,
              81.74386920980926,
              86.51226158038146,
            ],
          ],
          [
            [
              14.265927977839334,
              22.437673130193904,
              22.437673130193904,
              22.437673130193904,
            ],
            [
              24.37673130193906,
              49.445983379501385,
              71.32963988919667,
              80.60941828254848,
            ],
          ],
          [
            [
              9.15492957746479,
              14.788732394366196,
              14.788732394366196,
              14.788732394366196,
            ],
            [
              16.619718309859156,
              39.57746478873239,
              62.112676056338024,
              77.6056338028169,
            ],
          ],
          [
            [
              5.300859598853868,
              8.882521489971348,
              8.882521489971348,
              8.882521489971348,
            ],
            [
              10.028653295128938,
              31.23209169054441,
              55.15759312320917,
              74.35530085959886,
            ],
          ],
          [
            [
              1.9287833827893175,
              2.0771513353115725,
              2.0771513353115725,
              2.0771513353115725,
            ],
            [
              3.857566765578635,
              18.100890207715135,
              44.65875370919881,
              69.8813056379822,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 4.63258785942492, 25.079872204472842, 59.904153354632584],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 30.8300395256917],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 0.0],
          ],
        ],
        [
          [
            [
              34.58950201884253,
              46.16419919246299,
              46.16419919246299,
              46.16419919246299,
            ],
            [
              46.56796769851951,
              77.65814266487214,
              90.1749663526245,
              93.27052489905788,
            ],
          ],
          [
            [
              29.18918918918919,
              40.945945945945944,
              40.945945945945944,
              40.945945945945944,
            ],
            [
              42.027027027027025,
              72.56756756756756,
              87.29729729729729,
              91.48648648648648,
            ],
          ],
          [
            [
              24.96607869742198,
              37.04206241519674,
              37.04206241519674,
              37.04206241519674,
            ],
            [
              38.670284938941656,
              67.84260515603799,
              84.39620081411127,
              89.00949796472185,
            ],
          ],
          [
            [
              21.662125340599456,
              33.106267029972756,
              33.106267029972756,
              33.106267029972756,
            ],
            [
              35.83106267029973,
              63.62397820163488,
              81.74386920980926,
              86.51226158038146,
            ],
          ],
          [
            [
              14.265927977839334,
              22.437673130193904,
              22.437673130193904,
              22.437673130193904,
            ],
            [
              24.37673130193906,
              49.445983379501385,
              71.32963988919667,
              80.60941828254848,
            ],
          ],
          [
            [
              9.15492957746479,
              14.788732394366196,
              14.788732394366196,
              14.788732394366196,
            ],
            [
              16.619718309859156,
              39.57746478873239,
              62.112676056338024,
              77.6056338028169,
            ],
          ],
          [
            [
              5.300859598853868,
              8.882521489971348,
              8.882521489971348,
              8.882521489971348,
            ],
            [
              10.028653295128938,
              31.23209169054441,
              55.15759312320917,
              74.35530085959886,
            ],
          ],
          [
            [
              1.9287833827893175,
              2.0771513353115725,
              2.0771513353115725,
              2.0771513353115725,
            ],
            [
              3.857566765578635,
              18.100890207715135,
              44.65875370919881,
              69.8813056379822,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 4.63258785942492, 25.079872204472842, 59.904153354632584],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 30.8300395256917],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 0.0],
          ],
        ],
        [
          [
            [
              34.58950201884253,
              46.16419919246299,
              46.16419919246299,
              46.16419919246299,
            ],
            [
              46.56796769851951,
              77.65814266487214,
              90.1749663526245,
              93.27052489905788,
            ],
          ],
          [
            [
              29.18918918918919,
              40.945945945945944,
              40.945945945945944,
              40.945945945945944,
            ],
            [
              42.027027027027025,
              72.56756756756756,
              87.29729729729729,
              91.48648648648648,
            ],
          ],
          [
            [
              24.96607869742198,
              37.04206241519674,
              37.04206241519674,
              37.04206241519674,
            ],
            [
              38.670284938941656,
              67.84260515603799,
              84.39620081411127,
              89.00949796472185,
            ],
          ],
          [
            [
              21.662125340599456,
              33.106267029972756,
              33.106267029972756,
              33.106267029972756,
            ],
            [
              35.83106267029973,
              63.62397820163488,
              81.74386920980926,
              86.51226158038146,
            ],
          ],
          [
            [
              14.265927977839334,
              22.437673130193904,
              22.437673130193904,
              22.437673130193904,
            ],
            [
              24.37673130193906,
              49.445983379501385,
              71.32963988919667,
              80.60941828254848,
            ],
          ],
          [
            [
              9.15492957746479,
              14.788732394366196,
              14.788732394366196,
              14.788732394366196,
            ],
            [
              16.619718309859156,
              39.57746478873239,
              62.112676056338024,
              77.6056338028169,
            ],
          ],
          [
            [
              5.300859598853868,
              8.882521489971348,
              8.882521489971348,
              8.882521489971348,
            ],
            [
              10.028653295128938,
              31.23209169054441,
              55.15759312320917,
              74.35530085959886,
            ],
          ],
          [
            [
              1.9287833827893175,
              2.0771513353115725,
              2.0771513353115725,
              2.0771513353115725,
            ],
            [
              3.857566765578635,
              18.100890207715135,
              44.65875370919881,
              69.8813056379822,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 4.63258785942492, 25.079872204472842, 59.904153354632584],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 30.8300395256917],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 0.0],
          ],
        ],
        [
          [
            [
              34.58950201884253,
              46.16419919246299,
              46.16419919246299,
              46.16419919246299,
            ],
            [
              46.56796769851951,
              77.65814266487214,
              90.1749663526245,
              93.27052489905788,
            ],
          ],
          [
            [
              29.18918918918919,
              40.945945945945944,
              40.945945945945944,
              40.945945945945944,
            ],
            [
              42.027027027027025,
              72.56756756756756,
              87.29729729729729,
              91.48648648648648,
            ],
          ],
          [
            [
              24.96607869742198,
              37.04206241519674,
              37.04206241519674,
              37.04206241519674,
            ],
            [
              38.670284938941656,
              67.84260515603799,
              84.39620081411127,
              89.00949796472185,
            ],
          ],
          [
            [
              21.662125340599456,
              33.106267029972756,
              33.106267029972756,
              33.106267029972756,
            ],
            [
              35.83106267029973,
              63.62397820163488,
              81.74386920980926,
              86.51226158038146,
            ],
          ],
          [
            [
              14.265927977839334,
              22.437673130193904,
              22.437673130193904,
              22.437673130193904,
            ],
            [
              24.37673130193906,
              49.445983379501385,
              71.32963988919667,
              80.60941828254848,
            ],
          ],
          [
            [
              9.15492957746479,
              14.788732394366196,
              14.788732394366196,
              14.788732394366196,
            ],
            [
              16.619718309859156,
              39.57746478873239,
              62.112676056338024,
              77.6056338028169,
            ],
          ],
          [
            [
              5.300859598853868,
              8.882521489971348,
              8.882521489971348,
              8.882521489971348,
            ],
            [
              10.028653295128938,
              31.23209169054441,
              55.15759312320917,
              74.35530085959886,
            ],
          ],
          [
            [
              1.9287833827893175,
              2.0771513353115725,
              2.0771513353115725,
              2.0771513353115725,
            ],
            [
              3.857566765578635,
              18.100890207715135,
              44.65875370919881,
              69.8813056379822,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 4.63258785942492, 25.079872204472842, 59.904153354632584],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 30.8300395256917],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 0.0],
          ],
        ],
      ],
      [
        [
          [
            [
              27.719821162444113,
              47.540983606557376,
              49.18032786885246,
              49.18032786885246,
            ],
            [
              35.91654247391952,
              75.55886736214606,
              90.76005961251863,
              94.0387481371088,
            ],
          ],
          [
            [
              21.25748502994012,
              39.52095808383233,
              41.467065868263475,
              41.467065868263475,
            ],
            [
              30.08982035928144,
              69.16167664670658,
              87.42514970059881,
              91.31736526946108,
            ],
          ],
          [
            [
              17.74436090225564,
              33.23308270676692,
              35.037593984962406,
              35.037593984962406,
            ],
            [
              26.61654135338346,
              63.45864661654136,
              84.21052631578947,
              88.57142857142857,
            ],
          ],
          [
            [
              15.256797583081571,
              28.851963746223564,
              30.513595166163142,
              30.513595166163142,
            ],
            [
              24.169184290030213,
              58.45921450151057,
              81.41993957703929,
              85.80060422960725,
            ],
          ],
          [
            [
              8.153846153846153,
              18.923076923076923,
              18.923076923076923,
              18.923076923076923,
            ],
            [
              15.384615384615385,
              48.15384615384615,
              72.76923076923076,
              77.07692307692308,
            ],
          ],
          [
            [
              4.545454545454546,
              12.539184952978054,
              12.539184952978054,
              12.539184952978054,
            ],
            [
              9.247648902821316,
              43.260188087774296,
              64.73354231974922,
              69.12225705329153,
            ],
          ],
          [
            [
              2.7156549520766773,
              7.0287539936102235,
              7.0287539936102235,
              7.0287539936102235,
            ],
            [
              5.7507987220447285,
              36.102236421725244,
              56.54952076677316,
              61.0223642172524,
            ],
          ],
          [
            [0.0, 1.9933554817275747, 1.9933554817275747, 1.9933554817275747],
            [
              1.9933554817275747,
              25.91362126245847,
              46.179401993355484,
              50.83056478405316,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 17.509025270758123, 35.55956678700361, 39.35018050541516],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 6.986899563318777, 7.860262008733625],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 0.0],
          ],
        ],
        [
          [
            [
              27.719821162444113,
              47.540983606557376,
              49.18032786885246,
              49.18032786885246,
            ],
            [
              35.91654247391952,
              75.55886736214606,
              90.76005961251863,
              94.0387481371088,
            ],
          ],
          [
            [
              21.25748502994012,
              39.52095808383233,
              41.467065868263475,
              41.467065868263475,
            ],
            [
              30.08982035928144,
              69.16167664670658,
              87.42514970059881,
              91.31736526946108,
            ],
          ],
          [
            [
              17.74436090225564,
              33.23308270676692,
              35.037593984962406,
              35.037593984962406,
            ],
            [
              26.61654135338346,
              63.45864661654136,
              84.21052631578947,
              88.57142857142857,
            ],
          ],
          [
            [
              15.256797583081571,
              28.851963746223564,
              30.513595166163142,
              30.513595166163142,
            ],
            [
              24.169184290030213,
              58.45921450151057,
              81.41993957703929,
              85.80060422960725,
            ],
          ],
          [
            [
              8.153846153846153,
              18.923076923076923,
              18.923076923076923,
              18.923076923076923,
            ],
            [
              15.384615384615385,
              48.15384615384615,
              72.76923076923076,
              77.07692307692308,
            ],
          ],
          [
            [
              4.545454545454546,
              12.539184952978054,
              12.539184952978054,
              12.539184952978054,
            ],
            [
              9.247648902821316,
              43.260188087774296,
              64.73354231974922,
              69.12225705329153,
            ],
          ],
          [
            [
              2.7156549520766773,
              7.0287539936102235,
              7.0287539936102235,
              7.0287539936102235,
            ],
            [
              5.7507987220447285,
              36.102236421725244,
              56.54952076677316,
              61.0223642172524,
            ],
          ],
          [
            [0.0, 1.9933554817275747, 1.9933554817275747, 1.9933554817275747],
            [
              1.9933554817275747,
              25.91362126245847,
              46.179401993355484,
              50.83056478405316,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 17.509025270758123, 35.55956678700361, 39.35018050541516],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 6.986899563318777, 7.860262008733625],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 0.0],
          ],
        ],
        [
          [
            [
              27.719821162444113,
              47.540983606557376,
              49.18032786885246,
              49.18032786885246,
            ],
            [
              35.91654247391952,
              75.55886736214606,
              90.76005961251863,
              94.0387481371088,
            ],
          ],
          [
            [
              21.25748502994012,
              39.52095808383233,
              41.467065868263475,
              41.467065868263475,
            ],
            [
              30.08982035928144,
              69.16167664670658,
              87.42514970059881,
              91.31736526946108,
            ],
          ],
          [
            [
              17.74436090225564,
              33.23308270676692,
              35.037593984962406,
              35.037593984962406,
            ],
            [
              26.61654135338346,
              63.45864661654136,
              84.21052631578947,
              88.57142857142857,
            ],
          ],
          [
            [
              15.256797583081571,
              28.851963746223564,
              30.513595166163142,
              30.513595166163142,
            ],
            [
              24.169184290030213,
              58.45921450151057,
              81.41993957703929,
              85.80060422960725,
            ],
          ],
          [
            [
              8.153846153846153,
              18.923076923076923,
              18.923076923076923,
              18.923076923076923,
            ],
            [
              15.384615384615385,
              48.15384615384615,
              72.76923076923076,
              77.07692307692308,
            ],
          ],
          [
            [
              4.545454545454546,
              12.539184952978054,
              12.539184952978054,
              12.539184952978054,
            ],
            [
              9.247648902821316,
              43.260188087774296,
              64.73354231974922,
              69.12225705329153,
            ],
          ],
          [
            [
              2.7156549520766773,
              7.0287539936102235,
              7.0287539936102235,
              7.0287539936102235,
            ],
            [
              5.7507987220447285,
              36.102236421725244,
              56.54952076677316,
              61.0223642172524,
            ],
          ],
          [
            [0.0, 1.9933554817275747, 1.9933554817275747, 1.9933554817275747],
            [
              1.9933554817275747,
              25.91362126245847,
              46.179401993355484,
              50.83056478405316,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 17.509025270758123, 35.55956678700361, 39.35018050541516],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 6.986899563318777, 7.860262008733625],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 0.0],
          ],
        ],
        [
          [
            [
              27.719821162444113,
              47.540983606557376,
              49.18032786885246,
              49.18032786885246,
            ],
            [
              35.91654247391952,
              75.55886736214606,
              90.76005961251863,
              94.0387481371088,
            ],
          ],
          [
            [
              21.25748502994012,
              39.52095808383233,
              41.467065868263475,
              41.467065868263475,
            ],
            [
              30.08982035928144,
              69.16167664670658,
              87.42514970059881,
              91.31736526946108,
            ],
          ],
          [
            [
              17.74436090225564,
              33.23308270676692,
              35.037593984962406,
              35.037593984962406,
            ],
            [
              26.61654135338346,
              63.45864661654136,
              84.21052631578947,
              88.57142857142857,
            ],
          ],
          [
            [
              15.256797583081571,
              28.851963746223564,
              30.513595166163142,
              30.513595166163142,
            ],
            [
              24.169184290030213,
              58.45921450151057,
              81.41993957703929,
              85.80060422960725,
            ],
          ],
          [
            [
              8.153846153846153,
              18.923076923076923,
              18.923076923076923,
              18.923076923076923,
            ],
            [
              15.384615384615385,
              48.15384615384615,
              72.76923076923076,
              77.07692307692308,
            ],
          ],
          [
            [
              4.545454545454546,
              12.539184952978054,
              12.539184952978054,
              12.539184952978054,
            ],
            [
              9.247648902821316,
              43.260188087774296,
              64.73354231974922,
              69.12225705329153,
            ],
          ],
          [
            [
              2.7156549520766773,
              7.0287539936102235,
              7.0287539936102235,
              7.0287539936102235,
            ],
            [
              5.7507987220447285,
              36.102236421725244,
              56.54952076677316,
              61.0223642172524,
            ],
          ],
          [
            [0.0, 1.9933554817275747, 1.9933554817275747, 1.9933554817275747],
            [
              1.9933554817275747,
              25.91362126245847,
              46.179401993355484,
              50.83056478405316,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 17.509025270758123, 35.55956678700361, 39.35018050541516],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 6.986899563318777, 7.860262008733625],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 0.0],
          ],
        ],
      ],
      [
        [
          [
            [
              53.566621803499324,
              67.56393001345896,
              68.10228802153432,
              68.10228802153432,
            ],
            [
              59.89232839838493,
              86.13728129205921,
              95.02018842530283,
              95.96231493943472,
            ],
          ],
          [
            [
              47.56756756756757,
              61.21621621621621,
              61.62162162162163,
              61.62162162162163,
            ],
            [54.864864864864856, 83.91891891891892, 93.64864864864865, 95.0],
          ],
          [
            [
              42.469470827679785,
              56.30936227951153,
              56.71641791044776,
              56.71641791044776,
            ],
            [
              50.3392130257802,
              81.00407055630936,
              92.40162822252374,
              94.16553595658074,
            ],
          ],
          [
            [
              37.73841961852861,
              51.63487738419619,
              52.043596730245234,
              52.043596730245234,
            ],
            [
              46.049046321525886,
              78.06539509536785,
              91.14441416893733,
              93.3242506811989,
            ],
          ],
          [
            [
              23.130193905817176,
              38.227146814404435,
              38.227146814404435,
              38.227146814404435,
            ],
            [
              32.27146814404433,
              68.42105263157895,
              86.01108033240997,
              89.88919667590028,
            ],
          ],
          [
            [
              15.352112676056336,
              26.338028169014084,
              26.338028169014084,
              26.338028169014084,
            ],
            [
              20.985915492957748,
              60.985915492957744,
              81.26760563380282,
              86.33802816901408,
            ],
          ],
          [
            [
              10.028653295128938,
              19.62750716332378,
              19.62750716332378,
              19.62750716332378,
            ],
            [
              12.034383954154727,
              53.72492836676218,
              77.50716332378224,
              82.6647564469914,
            ],
          ],
          [
            [
              2.8189910979228485,
              9.643916913946587,
              9.643916913946587,
              9.643916913946587,
            ],
            [
              3.4124629080118694,
              44.21364985163205,
              69.58456973293768,
              74.92581602373886,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 32.26837060702875, 51.91693290734825, 57.66773162939297],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 2.5691699604743086, 4.150197628458498],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 0.0],
          ],
        ],
        [
          [
            [
              53.566621803499324,
              67.56393001345896,
              68.10228802153432,
              68.10228802153432,
            ],
            [
              59.89232839838493,
              86.13728129205921,
              95.02018842530283,
              95.96231493943472,
            ],
          ],
          [
            [
              47.56756756756757,
              61.21621621621621,
              61.62162162162163,
              61.62162162162163,
            ],
            [54.864864864864856, 83.91891891891892, 93.64864864864865, 95.0],
          ],
          [
            [
              42.469470827679785,
              56.30936227951153,
              56.71641791044776,
              56.71641791044776,
            ],
            [
              50.3392130257802,
              81.00407055630936,
              92.40162822252374,
              94.16553595658074,
            ],
          ],
          [
            [
              37.73841961852861,
              51.63487738419619,
              52.043596730245234,
              52.043596730245234,
            ],
            [
              46.049046321525886,
              78.06539509536785,
              91.14441416893733,
              93.3242506811989,
            ],
          ],
          [
            [
              23.130193905817176,
              38.227146814404435,
              38.227146814404435,
              38.227146814404435,
            ],
            [
              32.27146814404433,
              68.42105263157895,
              86.01108033240997,
              89.88919667590028,
            ],
          ],
          [
            [
              15.352112676056336,
              26.338028169014084,
              26.338028169014084,
              26.338028169014084,
            ],
            [
              20.985915492957748,
              60.985915492957744,
              81.26760563380282,
              86.33802816901408,
            ],
          ],
          [
            [
              10.028653295128938,
              19.62750716332378,
              19.62750716332378,
              19.62750716332378,
            ],
            [
              12.034383954154727,
              53.72492836676218,
              77.50716332378224,
              82.6647564469914,
            ],
          ],
          [
            [
              2.8189910979228485,
              9.643916913946587,
              9.643916913946587,
              9.643916913946587,
            ],
            [
              3.4124629080118694,
              44.21364985163205,
              69.58456973293768,
              74.92581602373886,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 32.26837060702875, 51.91693290734825, 57.66773162939297],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 2.5691699604743086, 4.150197628458498],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 0.0],
          ],
        ],
        [
          [
            [
              53.566621803499324,
              67.56393001345896,
              68.10228802153432,
              68.10228802153432,
            ],
            [
              59.89232839838493,
              86.13728129205921,
              95.02018842530283,
              95.96231493943472,
            ],
          ],
          [
            [
              47.56756756756757,
              61.21621621621621,
              61.62162162162163,
              61.62162162162163,
            ],
            [54.864864864864856, 83.91891891891892, 93.64864864864865, 95.0],
          ],
          [
            [
              42.469470827679785,
              56.30936227951153,
              56.71641791044776,
              56.71641791044776,
            ],
            [
              50.3392130257802,
              81.00407055630936,
              92.40162822252374,
              94.16553595658074,
            ],
          ],
          [
            [
              37.73841961852861,
              51.63487738419619,
              52.043596730245234,
              52.043596730245234,
            ],
            [
              46.049046321525886,
              78.06539509536785,
              91.14441416893733,
              93.3242506811989,
            ],
          ],
          [
            [
              23.130193905817176,
              38.227146814404435,
              38.227146814404435,
              38.227146814404435,
            ],
            [
              32.27146814404433,
              68.42105263157895,
              86.01108033240997,
              89.88919667590028,
            ],
          ],
          [
            [
              15.352112676056336,
              26.338028169014084,
              26.338028169014084,
              26.338028169014084,
            ],
            [
              20.985915492957748,
              60.985915492957744,
              81.26760563380282,
              86.33802816901408,
            ],
          ],
          [
            [
              10.028653295128938,
              19.62750716332378,
              19.62750716332378,
              19.62750716332378,
            ],
            [
              12.034383954154727,
              53.72492836676218,
              77.50716332378224,
              82.6647564469914,
            ],
          ],
          [
            [
              2.8189910979228485,
              9.643916913946587,
              9.643916913946587,
              9.643916913946587,
            ],
            [
              3.4124629080118694,
              44.21364985163205,
              69.58456973293768,
              74.92581602373886,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 32.26837060702875, 51.91693290734825, 57.66773162939297],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 2.5691699604743086, 4.150197628458498],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 0.0],
          ],
        ],
        [
          [
            [
              53.566621803499324,
              67.56393001345896,
              68.10228802153432,
              68.10228802153432,
            ],
            [
              59.89232839838493,
              86.13728129205921,
              95.02018842530283,
              95.96231493943472,
            ],
          ],
          [
            [
              47.56756756756757,
              61.21621621621621,
              61.62162162162163,
              61.62162162162163,
            ],
            [54.864864864864856, 83.91891891891892, 93.64864864864865, 95.0],
          ],
          [
            [
              42.469470827679785,
              56.30936227951153,
              56.71641791044776,
              56.71641791044776,
            ],
            [
              50.3392130257802,
              81.00407055630936,
              92.40162822252374,
              94.16553595658074,
            ],
          ],
          [
            [
              37.73841961852861,
              51.63487738419619,
              52.043596730245234,
              52.043596730245234,
            ],
            [
              46.049046321525886,
              78.06539509536785,
              91.14441416893733,
              93.3242506811989,
            ],
          ],
          [
            [
              23.130193905817176,
              38.227146814404435,
              38.227146814404435,
              38.227146814404435,
            ],
            [
              32.27146814404433,
              68.42105263157895,
              86.01108033240997,
              89.88919667590028,
            ],
          ],
          [
            [
              15.352112676056336,
              26.338028169014084,
              26.338028169014084,
              26.338028169014084,
            ],
            [
              20.985915492957748,
              60.985915492957744,
              81.26760563380282,
              86.33802816901408,
            ],
          ],
          [
            [
              10.028653295128938,
              19.62750716332378,
              19.62750716332378,
              19.62750716332378,
            ],
            [
              12.034383954154727,
              53.72492836676218,
              77.50716332378224,
              82.6647564469914,
            ],
          ],
          [
            [
              2.8189910979228485,
              9.643916913946587,
              9.643916913946587,
              9.643916913946587,
            ],
            [
              3.4124629080118694,
              44.21364985163205,
              69.58456973293768,
              74.92581602373886,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 32.26837060702875, 51.91693290734825, 57.66773162939297],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 2.5691699604743086, 4.150197628458498],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 0.0],
          ],
        ],
      ],
      [
        [
          [
            [
              66.89847009735745,
              70.79276773296245,
              70.9318497913769,
              70.9318497913769,
            ],
            [
              70.37552155771905,
              80.11126564673157,
              92.07232267037551,
              96.66203059805285,
            ],
          ],
          [
            [
              61.731843575419,
              66.62011173184358,
              66.75977653631286,
              66.75977653631286,
            ],
            [
              67.87709497206704,
              77.51396648044692,
              90.08379888268156,
              95.53072625698324,
            ],
          ],
          [
            [
              57.643758765778394,
              63.8148667601683,
              63.955119214586254,
              63.955119214586254,
            ],
            [
              65.21739130434783,
              75.45582047685835,
              88.49929873772791,
              94.67040673211781,
            ],
          ],
          [
            [
              54.507042253521135,
              61.12676056338028,
              61.267605633802816,
              61.267605633802816,
            ],
            [
              61.83098591549295,
              74.08450704225352,
              87.1830985915493,
              93.80281690140845,
            ],
          ],
          [
            [
              46.41833810888252,
              53.43839541547278,
              53.43839541547278,
              53.43839541547278,
            ],
            [
              52.29226361031518,
              68.91117478510029,
              81.80515759312321,
              90.25787965616045,
            ],
          ],
          [
            [
              36.005830903790084,
              48.39650145772595,
              48.39650145772595,
              48.39650145772595,
            ],
            [
              42.711370262390666,
              66.61807580174927,
              76.53061224489795,
              86.58892128279884,
            ],
          ],
          [
            [
              27.1513353115727,
              44.06528189910979,
              44.06528189910979,
              44.06528189910979,
            ],
            [
              34.42136498516321,
              66.02373887240356,
              72.55192878338279,
              84.42136498516321,
            ],
          ],
          [
            [
              14.000000000000002,
              33.69230769230769,
              33.69230769230769,
              33.69230769230769,
            ],
            [
              19.384615384615383,
              62.92307692307693,
              65.23076923076923,
              80.15384615384616,
            ],
          ],
          [
            [
              4.318936877076411,
              17.60797342192691,
              17.60797342192691,
              17.60797342192691,
            ],
            [
              8.637873754152823,
              50.99667774086378,
              62.45847176079734,
              70.59800664451828,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 6.224066390041494, 53.11203319502075, 56.016597510373444],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 37.569060773480665, 41.43646408839779],
          ],
        ],
        [
          [
            [
              66.89847009735745,
              70.79276773296245,
              70.9318497913769,
              70.9318497913769,
            ],
            [
              70.37552155771905,
              80.11126564673157,
              92.07232267037551,
              96.66203059805285,
            ],
          ],
          [
            [
              61.731843575419,
              66.62011173184358,
              66.75977653631286,
              66.75977653631286,
            ],
            [
              67.87709497206704,
              77.51396648044692,
              90.08379888268156,
              95.53072625698324,
            ],
          ],
          [
            [
              57.643758765778394,
              63.8148667601683,
              63.955119214586254,
              63.955119214586254,
            ],
            [
              65.21739130434783,
              75.45582047685835,
              88.49929873772791,
              94.67040673211781,
            ],
          ],
          [
            [
              54.507042253521135,
              61.12676056338028,
              61.267605633802816,
              61.267605633802816,
            ],
            [
              61.83098591549295,
              74.08450704225352,
              87.1830985915493,
              93.80281690140845,
            ],
          ],
          [
            [
              46.41833810888252,
              53.43839541547278,
              53.43839541547278,
              53.43839541547278,
            ],
            [
              52.29226361031518,
              68.91117478510029,
              81.80515759312321,
              90.25787965616045,
            ],
          ],
          [
            [
              36.005830903790084,
              48.39650145772595,
              48.39650145772595,
              48.39650145772595,
            ],
            [
              42.711370262390666,
              66.61807580174927,
              76.53061224489795,
              86.58892128279884,
            ],
          ],
          [
            [
              27.1513353115727,
              44.06528189910979,
              44.06528189910979,
              44.06528189910979,
            ],
            [
              34.42136498516321,
              66.02373887240356,
              72.55192878338279,
              84.42136498516321,
            ],
          ],
          [
            [
              14.000000000000002,
              33.69230769230769,
              33.69230769230769,
              33.69230769230769,
            ],
            [
              19.384615384615383,
              62.92307692307693,
              65.23076923076923,
              80.15384615384616,
            ],
          ],
          [
            [
              4.318936877076411,
              17.60797342192691,
              17.60797342192691,
              17.60797342192691,
            ],
            [
              8.637873754152823,
              50.99667774086378,
              62.45847176079734,
              70.59800664451828,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 6.224066390041494, 53.11203319502075, 56.016597510373444],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 37.569060773480665, 41.43646408839779],
          ],
        ],
        [
          [
            [
              66.89847009735745,
              70.79276773296245,
              70.9318497913769,
              70.9318497913769,
            ],
            [
              70.37552155771905,
              80.11126564673157,
              92.07232267037551,
              96.66203059805285,
            ],
          ],
          [
            [
              61.731843575419,
              66.62011173184358,
              66.75977653631286,
              66.75977653631286,
            ],
            [
              67.87709497206704,
              77.51396648044692,
              90.08379888268156,
              95.53072625698324,
            ],
          ],
          [
            [
              57.643758765778394,
              63.8148667601683,
              63.955119214586254,
              63.955119214586254,
            ],
            [
              65.21739130434783,
              75.45582047685835,
              88.49929873772791,
              94.67040673211781,
            ],
          ],
          [
            [
              54.507042253521135,
              61.12676056338028,
              61.267605633802816,
              61.267605633802816,
            ],
            [
              61.83098591549295,
              74.08450704225352,
              87.1830985915493,
              93.80281690140845,
            ],
          ],
          [
            [
              46.41833810888252,
              53.43839541547278,
              53.43839541547278,
              53.43839541547278,
            ],
            [
              52.29226361031518,
              68.91117478510029,
              81.80515759312321,
              90.25787965616045,
            ],
          ],
          [
            [
              36.005830903790084,
              48.39650145772595,
              48.39650145772595,
              48.39650145772595,
            ],
            [
              42.711370262390666,
              66.61807580174927,
              76.53061224489795,
              86.58892128279884,
            ],
          ],
          [
            [
              27.1513353115727,
              44.06528189910979,
              44.06528189910979,
              44.06528189910979,
            ],
            [
              34.42136498516321,
              66.02373887240356,
              72.55192878338279,
              84.42136498516321,
            ],
          ],
          [
            [
              14.000000000000002,
              33.69230769230769,
              33.69230769230769,
              33.69230769230769,
            ],
            [
              19.384615384615383,
              62.92307692307693,
              65.23076923076923,
              80.15384615384616,
            ],
          ],
          [
            [
              4.318936877076411,
              17.60797342192691,
              17.60797342192691,
              17.60797342192691,
            ],
            [
              8.637873754152823,
              50.99667774086378,
              62.45847176079734,
              70.59800664451828,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 6.224066390041494, 53.11203319502075, 56.016597510373444],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 37.569060773480665, 41.43646408839779],
          ],
        ],
        [
          [
            [
              66.89847009735745,
              70.79276773296245,
              70.9318497913769,
              70.9318497913769,
            ],
            [
              70.37552155771905,
              80.11126564673157,
              92.07232267037551,
              96.66203059805285,
            ],
          ],
          [
            [
              61.731843575419,
              66.62011173184358,
              66.75977653631286,
              66.75977653631286,
            ],
            [
              67.87709497206704,
              77.51396648044692,
              90.08379888268156,
              95.53072625698324,
            ],
          ],
          [
            [
              57.643758765778394,
              63.8148667601683,
              63.955119214586254,
              63.955119214586254,
            ],
            [
              65.21739130434783,
              75.45582047685835,
              88.49929873772791,
              94.67040673211781,
            ],
          ],
          [
            [
              54.507042253521135,
              61.12676056338028,
              61.267605633802816,
              61.267605633802816,
            ],
            [
              61.83098591549295,
              74.08450704225352,
              87.1830985915493,
              93.80281690140845,
            ],
          ],
          [
            [
              46.41833810888252,
              53.43839541547278,
              53.43839541547278,
              53.43839541547278,
            ],
            [
              52.29226361031518,
              68.91117478510029,
              81.80515759312321,
              90.25787965616045,
            ],
          ],
          [
            [
              36.005830903790084,
              48.39650145772595,
              48.39650145772595,
              48.39650145772595,
            ],
            [
              42.711370262390666,
              66.61807580174927,
              76.53061224489795,
              86.58892128279884,
            ],
          ],
          [
            [
              27.1513353115727,
              44.06528189910979,
              44.06528189910979,
              44.06528189910979,
            ],
            [
              34.42136498516321,
              66.02373887240356,
              72.55192878338279,
              84.42136498516321,
            ],
          ],
          [
            [
              14.000000000000002,
              33.69230769230769,
              33.69230769230769,
              33.69230769230769,
            ],
            [
              19.384615384615383,
              62.92307692307693,
              65.23076923076923,
              80.15384615384616,
            ],
          ],
          [
            [
              4.318936877076411,
              17.60797342192691,
              17.60797342192691,
              17.60797342192691,
            ],
            [
              8.637873754152823,
              50.99667774086378,
              62.45847176079734,
              70.59800664451828,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 6.224066390041494, 53.11203319502075, 56.016597510373444],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 37.569060773480665, 41.43646408839779],
          ],
        ],
      ],
      [
        [
          [
            [
              79.40780619111709,
              89.36742934051144,
              89.36742934051144,
              89.36742934051144,
            ],
            [79.40780619111709, 97.03903095558546, 100.0, 100.0],
          ],
          [
            [
              75.27027027027027,
              87.16216216216216,
              87.16216216216216,
              87.16216216216216,
            ],
            [75.27027027027027, 95.8108108108108, 100.0, 100.0],
          ],
          [
            [
              71.50610583446404,
              85.07462686567165,
              85.07462686567165,
              85.07462686567165,
            ],
            [71.50610583446404, 94.57259158751697, 100.0, 100.0],
          ],
          [
            [
              67.8474114441417,
              82.9700272479564,
              82.9700272479564,
              82.9700272479564,
            ],
            [67.8474114441417, 93.3242506811989, 100.0, 100.0],
          ],
          [
            [
              54.986149584487535,
              74.37673130193906,
              74.37673130193906,
              74.37673130193906,
            ],
            [54.986149584487535, 88.22714681440443, 100.0, 100.0],
          ],
          [
            [
              46.19718309859155,
              65.49295774647888,
              65.49295774647888,
              65.49295774647888,
            ],
            [46.19718309859155, 82.95774647887325, 100.0, 100.0],
          ],
          [
            [
              39.541547277936964,
              56.30372492836676,
              56.30372492836676,
              56.30372492836676,
            ],
            [39.541547277936964, 77.50716332378224, 100.0, 100.0],
          ],
          [
            [
              29.673590504451035,
              39.61424332344214,
              39.61424332344214,
              39.61424332344214,
            ],
            [29.673590504451035, 66.02373887240356, 100.0, 100.0],
          ],
          [
            [
              14.217252396166133,
              14.536741214057509,
              14.536741214057509,
              14.536741214057509,
            ],
            [14.217252396166133, 45.367412140575084, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 3.9525691699604746, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              79.40780619111709,
              89.36742934051144,
              89.36742934051144,
              89.36742934051144,
            ],
            [79.40780619111709, 97.03903095558546, 100.0, 100.0],
          ],
          [
            [
              75.27027027027027,
              87.16216216216216,
              87.16216216216216,
              87.16216216216216,
            ],
            [75.27027027027027, 95.8108108108108, 100.0, 100.0],
          ],
          [
            [
              71.50610583446404,
              85.07462686567165,
              85.07462686567165,
              85.07462686567165,
            ],
            [71.50610583446404, 94.57259158751697, 100.0, 100.0],
          ],
          [
            [
              67.8474114441417,
              82.9700272479564,
              82.9700272479564,
              82.9700272479564,
            ],
            [67.8474114441417, 93.3242506811989, 100.0, 100.0],
          ],
          [
            [
              54.986149584487535,
              74.37673130193906,
              74.37673130193906,
              74.37673130193906,
            ],
            [54.986149584487535, 88.22714681440443, 100.0, 100.0],
          ],
          [
            [
              46.19718309859155,
              65.49295774647888,
              65.49295774647888,
              65.49295774647888,
            ],
            [46.19718309859155, 82.95774647887325, 100.0, 100.0],
          ],
          [
            [
              39.541547277936964,
              56.30372492836676,
              56.30372492836676,
              56.30372492836676,
            ],
            [39.541547277936964, 77.50716332378224, 100.0, 100.0],
          ],
          [
            [
              29.673590504451035,
              39.61424332344214,
              39.61424332344214,
              39.61424332344214,
            ],
            [29.673590504451035, 66.02373887240356, 100.0, 100.0],
          ],
          [
            [
              14.217252396166133,
              14.536741214057509,
              14.536741214057509,
              14.536741214057509,
            ],
            [14.217252396166133, 45.367412140575084, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 3.9525691699604746, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              79.40780619111709,
              89.36742934051144,
              89.36742934051144,
              89.36742934051144,
            ],
            [79.40780619111709, 97.03903095558546, 100.0, 100.0],
          ],
          [
            [
              75.27027027027027,
              87.16216216216216,
              87.16216216216216,
              87.16216216216216,
            ],
            [75.27027027027027, 95.8108108108108, 100.0, 100.0],
          ],
          [
            [
              71.50610583446404,
              85.07462686567165,
              85.07462686567165,
              85.07462686567165,
            ],
            [71.50610583446404, 94.57259158751697, 100.0, 100.0],
          ],
          [
            [
              67.8474114441417,
              82.9700272479564,
              82.9700272479564,
              82.9700272479564,
            ],
            [67.8474114441417, 93.3242506811989, 100.0, 100.0],
          ],
          [
            [
              54.986149584487535,
              74.37673130193906,
              74.37673130193906,
              74.37673130193906,
            ],
            [54.986149584487535, 88.22714681440443, 100.0, 100.0],
          ],
          [
            [
              46.19718309859155,
              65.49295774647888,
              65.49295774647888,
              65.49295774647888,
            ],
            [46.19718309859155, 82.95774647887325, 100.0, 100.0],
          ],
          [
            [
              39.541547277936964,
              56.30372492836676,
              56.30372492836676,
              56.30372492836676,
            ],
            [39.541547277936964, 77.50716332378224, 100.0, 100.0],
          ],
          [
            [
              29.673590504451035,
              39.61424332344214,
              39.61424332344214,
              39.61424332344214,
            ],
            [29.673590504451035, 66.02373887240356, 100.0, 100.0],
          ],
          [
            [
              14.217252396166133,
              14.536741214057509,
              14.536741214057509,
              14.536741214057509,
            ],
            [14.217252396166133, 45.367412140575084, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 3.9525691699604746, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              79.40780619111709,
              89.36742934051144,
              89.36742934051144,
              89.36742934051144,
            ],
            [79.40780619111709, 97.03903095558546, 100.0, 100.0],
          ],
          [
            [
              75.27027027027027,
              87.16216216216216,
              87.16216216216216,
              87.16216216216216,
            ],
            [75.27027027027027, 95.8108108108108, 100.0, 100.0],
          ],
          [
            [
              71.50610583446404,
              85.07462686567165,
              85.07462686567165,
              85.07462686567165,
            ],
            [71.50610583446404, 94.57259158751697, 100.0, 100.0],
          ],
          [
            [
              67.8474114441417,
              82.9700272479564,
              82.9700272479564,
              82.9700272479564,
            ],
            [67.8474114441417, 93.3242506811989, 100.0, 100.0],
          ],
          [
            [
              54.986149584487535,
              74.37673130193906,
              74.37673130193906,
              74.37673130193906,
            ],
            [54.986149584487535, 88.22714681440443, 100.0, 100.0],
          ],
          [
            [
              46.19718309859155,
              65.49295774647888,
              65.49295774647888,
              65.49295774647888,
            ],
            [46.19718309859155, 82.95774647887325, 100.0, 100.0],
          ],
          [
            [
              39.541547277936964,
              56.30372492836676,
              56.30372492836676,
              56.30372492836676,
            ],
            [39.541547277936964, 77.50716332378224, 100.0, 100.0],
          ],
          [
            [
              29.673590504451035,
              39.61424332344214,
              39.61424332344214,
              39.61424332344214,
            ],
            [29.673590504451035, 66.02373887240356, 100.0, 100.0],
          ],
          [
            [
              14.217252396166133,
              14.536741214057509,
              14.536741214057509,
              14.536741214057509,
            ],
            [14.217252396166133, 45.367412140575084, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 3.9525691699604746, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [
              87.76077885952712,
              93.88038942976355,
              93.88038942976355,
              93.88038942976355,
            ],
            [90.26425591098747, 96.94019471488178, 100.0, 100.0],
          ],
          [
            [
              84.4972067039106,
              92.59776536312849,
              92.59776536312849,
              92.59776536312849,
            ],
            [87.98882681564247, 96.36871508379889, 100.0, 100.0],
          ],
          [
            [
              82.0476858345021,
              91.30434782608695,
              91.30434782608695,
              91.30434782608695,
            ],
            [86.25525946704067, 95.93267882187938, 100.0, 100.0],
          ],
          [
            [80.56338028169014, 90.0, 90.0, 90.0],
            [84.92957746478874, 95.49295774647887, 100.0, 100.0],
          ],
          [
            [
              70.77363896848138,
              86.38968481375359,
              86.38968481375359,
              86.38968481375359,
            ],
            [76.79083094555874, 93.69627507163324, 100.0, 100.0],
          ],
          [
            [
              61.51603498542274,
              83.38192419825073,
              83.38192419825073,
              83.38192419825073,
            ],
            [67.63848396501457, 91.83673469387756, 100.0, 100.0],
          ],
          [
            [
              51.92878338278932,
              80.1186943620178,
              80.1186943620178,
              80.1186943620178,
            ],
            [58.160237388724035, 89.91097922848664, 100.0, 100.0],
          ],
          [
            [
              35.38461538461539,
              72.3076923076923,
              72.3076923076923,
              72.3076923076923,
            ],
            [41.84615384615385, 85.84615384615385, 100.0, 100.0],
          ],
          [
            [
              12.790697674418606,
              54.15282392026578,
              54.15282392026578,
              54.15282392026578,
            ],
            [17.60797342192691, 76.74418604651163, 100.0, 100.0],
          ],
          [
            [0.0, 26.141078838174277, 26.141078838174277, 26.141078838174277],
            [0.0, 47.30290456431535, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 27.900552486187845, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              87.76077885952712,
              93.88038942976355,
              93.88038942976355,
              93.88038942976355,
            ],
            [90.26425591098747, 96.94019471488178, 100.0, 100.0],
          ],
          [
            [
              84.4972067039106,
              92.59776536312849,
              92.59776536312849,
              92.59776536312849,
            ],
            [87.98882681564247, 96.36871508379889, 100.0, 100.0],
          ],
          [
            [
              82.0476858345021,
              91.30434782608695,
              91.30434782608695,
              91.30434782608695,
            ],
            [86.25525946704067, 95.93267882187938, 100.0, 100.0],
          ],
          [
            [80.56338028169014, 90.0, 90.0, 90.0],
            [84.92957746478874, 95.49295774647887, 100.0, 100.0],
          ],
          [
            [
              70.77363896848138,
              86.38968481375359,
              86.38968481375359,
              86.38968481375359,
            ],
            [76.79083094555874, 93.69627507163324, 100.0, 100.0],
          ],
          [
            [
              61.51603498542274,
              83.38192419825073,
              83.38192419825073,
              83.38192419825073,
            ],
            [67.63848396501457, 91.83673469387756, 100.0, 100.0],
          ],
          [
            [
              51.92878338278932,
              80.1186943620178,
              80.1186943620178,
              80.1186943620178,
            ],
            [58.160237388724035, 89.91097922848664, 100.0, 100.0],
          ],
          [
            [
              35.38461538461539,
              72.3076923076923,
              72.3076923076923,
              72.3076923076923,
            ],
            [41.84615384615385, 85.84615384615385, 100.0, 100.0],
          ],
          [
            [
              12.790697674418606,
              54.15282392026578,
              54.15282392026578,
              54.15282392026578,
            ],
            [17.60797342192691, 76.74418604651163, 100.0, 100.0],
          ],
          [
            [0.0, 26.141078838174277, 26.141078838174277, 26.141078838174277],
            [0.0, 47.30290456431535, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 27.900552486187845, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              87.76077885952712,
              93.88038942976355,
              93.88038942976355,
              93.88038942976355,
            ],
            [90.26425591098747, 96.94019471488178, 100.0, 100.0],
          ],
          [
            [
              84.4972067039106,
              92.59776536312849,
              92.59776536312849,
              92.59776536312849,
            ],
            [87.98882681564247, 96.36871508379889, 100.0, 100.0],
          ],
          [
            [
              82.0476858345021,
              91.30434782608695,
              91.30434782608695,
              91.30434782608695,
            ],
            [86.25525946704067, 95.93267882187938, 100.0, 100.0],
          ],
          [
            [80.56338028169014, 90.0, 90.0, 90.0],
            [84.92957746478874, 95.49295774647887, 100.0, 100.0],
          ],
          [
            [
              70.77363896848138,
              86.38968481375359,
              86.38968481375359,
              86.38968481375359,
            ],
            [76.79083094555874, 93.69627507163324, 100.0, 100.0],
          ],
          [
            [
              61.51603498542274,
              83.38192419825073,
              83.38192419825073,
              83.38192419825073,
            ],
            [67.63848396501457, 91.83673469387756, 100.0, 100.0],
          ],
          [
            [
              51.92878338278932,
              80.1186943620178,
              80.1186943620178,
              80.1186943620178,
            ],
            [58.160237388724035, 89.91097922848664, 100.0, 100.0],
          ],
          [
            [
              35.38461538461539,
              72.3076923076923,
              72.3076923076923,
              72.3076923076923,
            ],
            [41.84615384615385, 85.84615384615385, 100.0, 100.0],
          ],
          [
            [
              12.790697674418606,
              54.15282392026578,
              54.15282392026578,
              54.15282392026578,
            ],
            [17.60797342192691, 76.74418604651163, 100.0, 100.0],
          ],
          [
            [0.0, 26.141078838174277, 26.141078838174277, 26.141078838174277],
            [0.0, 47.30290456431535, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 27.900552486187845, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              87.76077885952712,
              93.88038942976355,
              93.88038942976355,
              93.88038942976355,
            ],
            [90.26425591098747, 96.94019471488178, 100.0, 100.0],
          ],
          [
            [
              84.4972067039106,
              92.59776536312849,
              92.59776536312849,
              92.59776536312849,
            ],
            [87.98882681564247, 96.36871508379889, 100.0, 100.0],
          ],
          [
            [
              82.0476858345021,
              91.30434782608695,
              91.30434782608695,
              91.30434782608695,
            ],
            [86.25525946704067, 95.93267882187938, 100.0, 100.0],
          ],
          [
            [80.56338028169014, 90.0, 90.0, 90.0],
            [84.92957746478874, 95.49295774647887, 100.0, 100.0],
          ],
          [
            [
              70.77363896848138,
              86.38968481375359,
              86.38968481375359,
              86.38968481375359,
            ],
            [76.79083094555874, 93.69627507163324, 100.0, 100.0],
          ],
          [
            [
              61.51603498542274,
              83.38192419825073,
              83.38192419825073,
              83.38192419825073,
            ],
            [67.63848396501457, 91.83673469387756, 100.0, 100.0],
          ],
          [
            [
              51.92878338278932,
              80.1186943620178,
              80.1186943620178,
              80.1186943620178,
            ],
            [58.160237388724035, 89.91097922848664, 100.0, 100.0],
          ],
          [
            [
              35.38461538461539,
              72.3076923076923,
              72.3076923076923,
              72.3076923076923,
            ],
            [41.84615384615385, 85.84615384615385, 100.0, 100.0],
          ],
          [
            [
              12.790697674418606,
              54.15282392026578,
              54.15282392026578,
              54.15282392026578,
            ],
            [17.60797342192691, 76.74418604651163, 100.0, 100.0],
          ],
          [
            [0.0, 26.141078838174277, 26.141078838174277, 26.141078838174277],
            [0.0, 47.30290456431535, 100.0, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 27.900552486187845, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [
              86.27187079407807,
              96.36608344549124,
              96.36608344549124,
              96.36608344549124,
            ],
            [86.27187079407807, 97.71197846567968, 100.0, 100.0],
          ],
          [
            [
              85.13513513513513,
              95.94594594594594,
              95.94594594594594,
              95.94594594594594,
            ],
            [85.13513513513513, 97.2972972972973, 100.0, 100.0],
          ],
          [
            [
              83.85345997286295,
              94.70827679782904,
              94.70827679782904,
              94.70827679782904,
            ],
            [84.26051560379919, 96.87924016282226, 100.0, 100.0],
          ],
          [
            [
              82.56130790190736,
              93.46049046321527,
              93.46049046321527,
              93.46049046321527,
            ],
            [82.69754768392372, 96.45776566757493, 100.0, 100.0],
          ],
          [
            [
              75.62326869806094,
              88.36565096952909,
              88.36565096952909,
              88.36565096952909,
            ],
            [75.62326869806094, 94.73684210526315, 100.0, 100.0],
          ],
          [
            [
              66.7605633802817,
              85.2112676056338,
              85.2112676056338,
              85.2112676056338,
            ],
            [66.7605633802817, 92.95774647887323, 100.0, 100.0],
          ],
          [
            [
              59.742120343839545,
              83.2378223495702,
              83.2378223495702,
              83.2378223495702,
            ],
            [59.742120343839545, 91.11747851002865, 100.0, 100.0],
          ],
          [
            [
              50.89020771513353,
              79.08011869436203,
              79.08011869436203,
              79.08011869436203,
            ],
            [50.89020771513353, 87.24035608308606, 100.0, 100.0],
          ],
          [
            [
              37.85942492012779,
              72.84345047923323,
              72.84345047923323,
              72.84345047923323,
            ],
            [37.85942492012779, 78.59424920127796, 100.0, 100.0],
          ],
          [
            [
              7.707509881422925,
              47.82608695652174,
              47.82608695652174,
              47.82608695652174,
            ],
            [7.707509881422925, 49.80237154150198, 100.0, 100.0],
          ],
          [
            [0.0, 5.699481865284974, 5.699481865284974, 5.699481865284974],
            [0.0, 9.32642487046632, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              86.27187079407807,
              96.36608344549124,
              96.36608344549124,
              96.36608344549124,
            ],
            [86.27187079407807, 97.71197846567968, 100.0, 100.0],
          ],
          [
            [
              85.13513513513513,
              95.94594594594594,
              95.94594594594594,
              95.94594594594594,
            ],
            [85.13513513513513, 97.2972972972973, 100.0, 100.0],
          ],
          [
            [
              83.85345997286295,
              94.70827679782904,
              94.70827679782904,
              94.70827679782904,
            ],
            [84.26051560379919, 96.87924016282226, 100.0, 100.0],
          ],
          [
            [
              82.56130790190736,
              93.46049046321527,
              93.46049046321527,
              93.46049046321527,
            ],
            [82.69754768392372, 96.45776566757493, 100.0, 100.0],
          ],
          [
            [
              75.62326869806094,
              88.36565096952909,
              88.36565096952909,
              88.36565096952909,
            ],
            [75.62326869806094, 94.73684210526315, 100.0, 100.0],
          ],
          [
            [
              66.7605633802817,
              85.2112676056338,
              85.2112676056338,
              85.2112676056338,
            ],
            [66.7605633802817, 92.95774647887323, 100.0, 100.0],
          ],
          [
            [
              59.742120343839545,
              83.2378223495702,
              83.2378223495702,
              83.2378223495702,
            ],
            [59.742120343839545, 91.11747851002865, 100.0, 100.0],
          ],
          [
            [
              50.89020771513353,
              79.08011869436203,
              79.08011869436203,
              79.08011869436203,
            ],
            [50.89020771513353, 87.24035608308606, 100.0, 100.0],
          ],
          [
            [
              37.85942492012779,
              72.84345047923323,
              72.84345047923323,
              72.84345047923323,
            ],
            [37.85942492012779, 78.59424920127796, 100.0, 100.0],
          ],
          [
            [
              7.707509881422925,
              47.82608695652174,
              47.82608695652174,
              47.82608695652174,
            ],
            [7.707509881422925, 49.80237154150198, 100.0, 100.0],
          ],
          [
            [0.0, 5.699481865284974, 5.699481865284974, 5.699481865284974],
            [0.0, 9.32642487046632, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              86.27187079407807,
              96.36608344549124,
              96.36608344549124,
              96.36608344549124,
            ],
            [86.27187079407807, 97.71197846567968, 100.0, 100.0],
          ],
          [
            [
              85.13513513513513,
              95.94594594594594,
              95.94594594594594,
              95.94594594594594,
            ],
            [85.13513513513513, 97.2972972972973, 100.0, 100.0],
          ],
          [
            [
              83.85345997286295,
              94.70827679782904,
              94.70827679782904,
              94.70827679782904,
            ],
            [84.26051560379919, 96.87924016282226, 100.0, 100.0],
          ],
          [
            [
              82.56130790190736,
              93.46049046321527,
              93.46049046321527,
              93.46049046321527,
            ],
            [82.69754768392372, 96.45776566757493, 100.0, 100.0],
          ],
          [
            [
              75.62326869806094,
              88.36565096952909,
              88.36565096952909,
              88.36565096952909,
            ],
            [75.62326869806094, 94.73684210526315, 100.0, 100.0],
          ],
          [
            [
              66.7605633802817,
              85.2112676056338,
              85.2112676056338,
              85.2112676056338,
            ],
            [66.7605633802817, 92.95774647887323, 100.0, 100.0],
          ],
          [
            [
              59.742120343839545,
              83.2378223495702,
              83.2378223495702,
              83.2378223495702,
            ],
            [59.742120343839545, 91.11747851002865, 100.0, 100.0],
          ],
          [
            [
              50.89020771513353,
              79.08011869436203,
              79.08011869436203,
              79.08011869436203,
            ],
            [50.89020771513353, 87.24035608308606, 100.0, 100.0],
          ],
          [
            [
              37.85942492012779,
              72.84345047923323,
              72.84345047923323,
              72.84345047923323,
            ],
            [37.85942492012779, 78.59424920127796, 100.0, 100.0],
          ],
          [
            [
              7.707509881422925,
              47.82608695652174,
              47.82608695652174,
              47.82608695652174,
            ],
            [7.707509881422925, 49.80237154150198, 100.0, 100.0],
          ],
          [
            [0.0, 5.699481865284974, 5.699481865284974, 5.699481865284974],
            [0.0, 9.32642487046632, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              86.27187079407807,
              96.36608344549124,
              96.36608344549124,
              96.36608344549124,
            ],
            [86.27187079407807, 97.71197846567968, 100.0, 100.0],
          ],
          [
            [
              85.13513513513513,
              95.94594594594594,
              95.94594594594594,
              95.94594594594594,
            ],
            [85.13513513513513, 97.2972972972973, 100.0, 100.0],
          ],
          [
            [
              83.85345997286295,
              94.70827679782904,
              94.70827679782904,
              94.70827679782904,
            ],
            [84.26051560379919, 96.87924016282226, 100.0, 100.0],
          ],
          [
            [
              82.56130790190736,
              93.46049046321527,
              93.46049046321527,
              93.46049046321527,
            ],
            [82.69754768392372, 96.45776566757493, 100.0, 100.0],
          ],
          [
            [
              75.62326869806094,
              88.36565096952909,
              88.36565096952909,
              88.36565096952909,
            ],
            [75.62326869806094, 94.73684210526315, 100.0, 100.0],
          ],
          [
            [
              66.7605633802817,
              85.2112676056338,
              85.2112676056338,
              85.2112676056338,
            ],
            [66.7605633802817, 92.95774647887323, 100.0, 100.0],
          ],
          [
            [
              59.742120343839545,
              83.2378223495702,
              83.2378223495702,
              83.2378223495702,
            ],
            [59.742120343839545, 91.11747851002865, 100.0, 100.0],
          ],
          [
            [
              50.89020771513353,
              79.08011869436203,
              79.08011869436203,
              79.08011869436203,
            ],
            [50.89020771513353, 87.24035608308606, 100.0, 100.0],
          ],
          [
            [
              37.85942492012779,
              72.84345047923323,
              72.84345047923323,
              72.84345047923323,
            ],
            [37.85942492012779, 78.59424920127796, 100.0, 100.0],
          ],
          [
            [
              7.707509881422925,
              47.82608695652174,
              47.82608695652174,
              47.82608695652174,
            ],
            [7.707509881422925, 49.80237154150198, 100.0, 100.0],
          ],
          [
            [0.0, 5.699481865284974, 5.699481865284974, 5.699481865284974],
            [0.0, 9.32642487046632, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [
              89.36742934051144,
              94.885598923284,
              94.885598923284,
              94.885598923284,
            ],
            [90.1749663526245, 97.57738896366084, 99.19246298788694, 100.0],
          ],
          [
            [
              85.94594594594595,
              93.24324324324324,
              93.24324324324324,
              93.24324324324324,
            ],
            [87.70270270270271, 96.75675675675676, 98.78378378378379, 100.0],
          ],
          [
            [
              82.90366350067842,
              91.99457259158751,
              91.99457259158751,
              91.99457259158751,
            ],
            [85.21031207598372, 95.92944369063771, 98.37177747625509, 100.0],
          ],
          [
            [
              80.24523160762944,
              91.14441416893733,
              91.14441416893733,
              91.14441416893733,
            ],
            [82.69754768392372, 95.09536784741145, 97.95640326975476, 100.0],
          ],
          [
            [
              71.60664819944598,
              87.67313019390582,
              87.67313019390582,
              87.67313019390582,
            ],
            [72.57617728531855, 91.68975069252078, 96.26038781163435, 100.0],
          ],
          [
            [
              62.676056338028175,
              84.08450704225352,
              84.08450704225352,
              84.08450704225352,
            ],
            [64.64788732394366, 88.16901408450704, 94.50704225352112, 100.0],
          ],
          [
            [
              57.306590257879655,
              80.37249283667622,
              80.37249283667622,
              80.37249283667622,
            ],
            [58.73925501432665, 84.5272206303725, 92.69340974212035, 100.0],
          ],
          [
            [
              42.87833827893175,
              72.55192878338279,
              72.55192878338279,
              72.55192878338279,
            ],
            [42.87833827893175, 76.8545994065282, 88.87240356083086, 100.0],
          ],
          [
            [
              16.773162939297126,
              56.54952076677316,
              56.70926517571885,
              56.70926517571885,
            ],
            [16.773162939297126, 59.7444089456869, 80.3514376996805, 100.0],
          ],
          [
            [0.0, 16.99604743083004, 21.936758893280633, 21.936758893280633],
            [0.0, 16.99604743083004, 64.62450592885376, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 53.626943005181346, 100.0],
          ],
        ],
        [
          [
            [
              89.36742934051144,
              94.885598923284,
              94.885598923284,
              94.885598923284,
            ],
            [90.1749663526245, 97.57738896366084, 99.19246298788694, 100.0],
          ],
          [
            [
              85.94594594594595,
              93.24324324324324,
              93.24324324324324,
              93.24324324324324,
            ],
            [87.70270270270271, 96.75675675675676, 98.78378378378379, 100.0],
          ],
          [
            [
              82.90366350067842,
              91.99457259158751,
              91.99457259158751,
              91.99457259158751,
            ],
            [85.21031207598372, 95.92944369063771, 98.37177747625509, 100.0],
          ],
          [
            [
              80.24523160762944,
              91.14441416893733,
              91.14441416893733,
              91.14441416893733,
            ],
            [82.69754768392372, 95.09536784741145, 97.95640326975476, 100.0],
          ],
          [
            [
              71.60664819944598,
              87.67313019390582,
              87.67313019390582,
              87.67313019390582,
            ],
            [72.57617728531855, 91.68975069252078, 96.26038781163435, 100.0],
          ],
          [
            [
              62.676056338028175,
              84.08450704225352,
              84.08450704225352,
              84.08450704225352,
            ],
            [64.64788732394366, 88.16901408450704, 94.50704225352112, 100.0],
          ],
          [
            [
              57.306590257879655,
              80.37249283667622,
              80.37249283667622,
              80.37249283667622,
            ],
            [58.73925501432665, 84.5272206303725, 92.69340974212035, 100.0],
          ],
          [
            [
              42.87833827893175,
              72.55192878338279,
              72.55192878338279,
              72.55192878338279,
            ],
            [42.87833827893175, 76.8545994065282, 88.87240356083086, 100.0],
          ],
          [
            [
              16.773162939297126,
              56.54952076677316,
              56.70926517571885,
              56.70926517571885,
            ],
            [16.773162939297126, 59.7444089456869, 80.3514376996805, 100.0],
          ],
          [
            [0.0, 16.99604743083004, 21.936758893280633, 21.936758893280633],
            [0.0, 16.99604743083004, 64.62450592885376, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 53.626943005181346, 100.0],
          ],
        ],
        [
          [
            [
              89.36742934051144,
              94.885598923284,
              94.885598923284,
              94.885598923284,
            ],
            [90.1749663526245, 97.57738896366084, 99.19246298788694, 100.0],
          ],
          [
            [
              85.94594594594595,
              93.24324324324324,
              93.24324324324324,
              93.24324324324324,
            ],
            [87.70270270270271, 96.75675675675676, 98.78378378378379, 100.0],
          ],
          [
            [
              82.90366350067842,
              91.99457259158751,
              91.99457259158751,
              91.99457259158751,
            ],
            [85.21031207598372, 95.92944369063771, 98.37177747625509, 100.0],
          ],
          [
            [
              80.24523160762944,
              91.14441416893733,
              91.14441416893733,
              91.14441416893733,
            ],
            [82.69754768392372, 95.09536784741145, 97.95640326975476, 100.0],
          ],
          [
            [
              71.60664819944598,
              87.67313019390582,
              87.67313019390582,
              87.67313019390582,
            ],
            [72.57617728531855, 91.68975069252078, 96.26038781163435, 100.0],
          ],
          [
            [
              62.676056338028175,
              84.08450704225352,
              84.08450704225352,
              84.08450704225352,
            ],
            [64.64788732394366, 88.16901408450704, 94.50704225352112, 100.0],
          ],
          [
            [
              57.306590257879655,
              80.37249283667622,
              80.37249283667622,
              80.37249283667622,
            ],
            [58.73925501432665, 84.5272206303725, 92.69340974212035, 100.0],
          ],
          [
            [
              42.87833827893175,
              72.55192878338279,
              72.55192878338279,
              72.55192878338279,
            ],
            [42.87833827893175, 76.8545994065282, 88.87240356083086, 100.0],
          ],
          [
            [
              16.773162939297126,
              56.54952076677316,
              56.70926517571885,
              56.70926517571885,
            ],
            [16.773162939297126, 59.7444089456869, 80.3514376996805, 100.0],
          ],
          [
            [0.0, 16.99604743083004, 21.936758893280633, 21.936758893280633],
            [0.0, 16.99604743083004, 64.62450592885376, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 53.626943005181346, 100.0],
          ],
        ],
        [
          [
            [
              89.36742934051144,
              94.885598923284,
              94.885598923284,
              94.885598923284,
            ],
            [90.1749663526245, 97.57738896366084, 99.19246298788694, 100.0],
          ],
          [
            [
              85.94594594594595,
              93.24324324324324,
              93.24324324324324,
              93.24324324324324,
            ],
            [87.70270270270271, 96.75675675675676, 98.78378378378379, 100.0],
          ],
          [
            [
              82.90366350067842,
              91.99457259158751,
              91.99457259158751,
              91.99457259158751,
            ],
            [85.21031207598372, 95.92944369063771, 98.37177747625509, 100.0],
          ],
          [
            [
              80.24523160762944,
              91.14441416893733,
              91.14441416893733,
              91.14441416893733,
            ],
            [82.69754768392372, 95.09536784741145, 97.95640326975476, 100.0],
          ],
          [
            [
              71.60664819944598,
              87.67313019390582,
              87.67313019390582,
              87.67313019390582,
            ],
            [72.57617728531855, 91.68975069252078, 96.26038781163435, 100.0],
          ],
          [
            [
              62.676056338028175,
              84.08450704225352,
              84.08450704225352,
              84.08450704225352,
            ],
            [64.64788732394366, 88.16901408450704, 94.50704225352112, 100.0],
          ],
          [
            [
              57.306590257879655,
              80.37249283667622,
              80.37249283667622,
              80.37249283667622,
            ],
            [58.73925501432665, 84.5272206303725, 92.69340974212035, 100.0],
          ],
          [
            [
              42.87833827893175,
              72.55192878338279,
              72.55192878338279,
              72.55192878338279,
            ],
            [42.87833827893175, 76.8545994065282, 88.87240356083086, 100.0],
          ],
          [
            [
              16.773162939297126,
              56.54952076677316,
              56.70926517571885,
              56.70926517571885,
            ],
            [16.773162939297126, 59.7444089456869, 80.3514376996805, 100.0],
          ],
          [
            [0.0, 16.99604743083004, 21.936758893280633, 21.936758893280633],
            [0.0, 16.99604743083004, 64.62450592885376, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 53.626943005181346, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [
              72.73991655076495,
              87.20445062586927,
              87.20445062586927,
              87.20445062586927,
            ],
            [75.79972183588318, 94.99304589707927, 99.02642559109874, 100.0],
          ],
          [
            [
              68.43575418994413,
              83.93854748603353,
              83.93854748603353,
              83.93854748603353,
            ],
            [72.20670391061452, 94.1340782122905, 98.60335195530726, 100.0],
          ],
          [
            [
              64.79663394109397,
              81.4866760168303,
              81.4866760168303,
              81.4866760168303,
            ],
            [69.28471248246845, 92.98737727910238, 98.17671809256662, 100.0],
          ],
          [
            [
              61.97183098591549,
              79.5774647887324,
              79.5774647887324,
              79.5774647887324,
            ],
            [66.61971830985915, 91.97183098591549, 97.74647887323944, 100.0],
          ],
          [
            [
              54.44126074498568,
              74.21203438395415,
              74.21203438395415,
              74.21203438395415,
            ],
            [58.16618911174785, 88.39541547277938, 95.98853868194843, 100.0],
          ],
          [
            [
              48.39650145772595,
              67.05539358600583,
              67.05539358600583,
              67.05539358600583,
            ],
            [50.583090379008745, 86.44314868804665, 94.16909620991254, 100.0],
          ],
          [
            [
              42.13649851632047,
              57.566765578635014,
              57.566765578635014,
              57.566765578635014,
            ],
            [44.362017804154306, 83.23442136498517, 92.28486646884274, 100.0],
          ],
          [
            [
              35.53846153846154,
              42.30769230769231,
              42.30769230769231,
              42.30769230769231,
            ],
            [37.07692307692308, 75.23076923076924, 88.3076923076923, 100.0],
          ],
          [
            [
              20.09966777408638,
              20.764119601328904,
              20.764119601328904,
              20.764119601328904,
            ],
            [24.58471760797342, 57.308970099667775, 79.40199335548172, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [1.2448132780082988, 17.42738589211618, 66.39004149377593, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 55.24861878453039, 100.0],
          ],
        ],
        [
          [
            [
              72.73991655076495,
              87.20445062586927,
              87.20445062586927,
              87.20445062586927,
            ],
            [75.79972183588318, 94.99304589707927, 99.02642559109874, 100.0],
          ],
          [
            [
              68.43575418994413,
              83.93854748603353,
              83.93854748603353,
              83.93854748603353,
            ],
            [72.20670391061452, 94.1340782122905, 98.60335195530726, 100.0],
          ],
          [
            [
              64.79663394109397,
              81.4866760168303,
              81.4866760168303,
              81.4866760168303,
            ],
            [69.28471248246845, 92.98737727910238, 98.17671809256662, 100.0],
          ],
          [
            [
              61.97183098591549,
              79.5774647887324,
              79.5774647887324,
              79.5774647887324,
            ],
            [66.61971830985915, 91.97183098591549, 97.74647887323944, 100.0],
          ],
          [
            [
              54.44126074498568,
              74.21203438395415,
              74.21203438395415,
              74.21203438395415,
            ],
            [58.16618911174785, 88.39541547277938, 95.98853868194843, 100.0],
          ],
          [
            [
              48.39650145772595,
              67.05539358600583,
              67.05539358600583,
              67.05539358600583,
            ],
            [50.583090379008745, 86.44314868804665, 94.16909620991254, 100.0],
          ],
          [
            [
              42.13649851632047,
              57.566765578635014,
              57.566765578635014,
              57.566765578635014,
            ],
            [44.362017804154306, 83.23442136498517, 92.28486646884274, 100.0],
          ],
          [
            [
              35.53846153846154,
              42.30769230769231,
              42.30769230769231,
              42.30769230769231,
            ],
            [37.07692307692308, 75.23076923076924, 88.3076923076923, 100.0],
          ],
          [
            [
              20.09966777408638,
              20.764119601328904,
              20.764119601328904,
              20.764119601328904,
            ],
            [24.58471760797342, 57.308970099667775, 79.40199335548172, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [1.2448132780082988, 17.42738589211618, 66.39004149377593, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 55.24861878453039, 100.0],
          ],
        ],
        [
          [
            [
              72.73991655076495,
              87.20445062586927,
              87.20445062586927,
              87.20445062586927,
            ],
            [75.79972183588318, 94.99304589707927, 99.02642559109874, 100.0],
          ],
          [
            [
              68.43575418994413,
              83.93854748603353,
              83.93854748603353,
              83.93854748603353,
            ],
            [72.20670391061452, 94.1340782122905, 98.60335195530726, 100.0],
          ],
          [
            [
              64.79663394109397,
              81.4866760168303,
              81.4866760168303,
              81.4866760168303,
            ],
            [69.28471248246845, 92.98737727910238, 98.17671809256662, 100.0],
          ],
          [
            [
              61.97183098591549,
              79.5774647887324,
              79.5774647887324,
              79.5774647887324,
            ],
            [66.61971830985915, 91.97183098591549, 97.74647887323944, 100.0],
          ],
          [
            [
              54.44126074498568,
              74.21203438395415,
              74.21203438395415,
              74.21203438395415,
            ],
            [58.16618911174785, 88.39541547277938, 95.98853868194843, 100.0],
          ],
          [
            [
              48.39650145772595,
              67.05539358600583,
              67.05539358600583,
              67.05539358600583,
            ],
            [50.583090379008745, 86.44314868804665, 94.16909620991254, 100.0],
          ],
          [
            [
              42.13649851632047,
              57.566765578635014,
              57.566765578635014,
              57.566765578635014,
            ],
            [44.362017804154306, 83.23442136498517, 92.28486646884274, 100.0],
          ],
          [
            [
              35.53846153846154,
              42.30769230769231,
              42.30769230769231,
              42.30769230769231,
            ],
            [37.07692307692308, 75.23076923076924, 88.3076923076923, 100.0],
          ],
          [
            [
              20.09966777408638,
              20.764119601328904,
              20.764119601328904,
              20.764119601328904,
            ],
            [24.58471760797342, 57.308970099667775, 79.40199335548172, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [1.2448132780082988, 17.42738589211618, 66.39004149377593, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 55.24861878453039, 100.0],
          ],
        ],
        [
          [
            [
              72.73991655076495,
              87.20445062586927,
              87.20445062586927,
              87.20445062586927,
            ],
            [75.79972183588318, 94.99304589707927, 99.02642559109874, 100.0],
          ],
          [
            [
              68.43575418994413,
              83.93854748603353,
              83.93854748603353,
              83.93854748603353,
            ],
            [72.20670391061452, 94.1340782122905, 98.60335195530726, 100.0],
          ],
          [
            [
              64.79663394109397,
              81.4866760168303,
              81.4866760168303,
              81.4866760168303,
            ],
            [69.28471248246845, 92.98737727910238, 98.17671809256662, 100.0],
          ],
          [
            [
              61.97183098591549,
              79.5774647887324,
              79.5774647887324,
              79.5774647887324,
            ],
            [66.61971830985915, 91.97183098591549, 97.74647887323944, 100.0],
          ],
          [
            [
              54.44126074498568,
              74.21203438395415,
              74.21203438395415,
              74.21203438395415,
            ],
            [58.16618911174785, 88.39541547277938, 95.98853868194843, 100.0],
          ],
          [
            [
              48.39650145772595,
              67.05539358600583,
              67.05539358600583,
              67.05539358600583,
            ],
            [50.583090379008745, 86.44314868804665, 94.16909620991254, 100.0],
          ],
          [
            [
              42.13649851632047,
              57.566765578635014,
              57.566765578635014,
              57.566765578635014,
            ],
            [44.362017804154306, 83.23442136498517, 92.28486646884274, 100.0],
          ],
          [
            [
              35.53846153846154,
              42.30769230769231,
              42.30769230769231,
              42.30769230769231,
            ],
            [37.07692307692308, 75.23076923076924, 88.3076923076923, 100.0],
          ],
          [
            [
              20.09966777408638,
              20.764119601328904,
              20.764119601328904,
              20.764119601328904,
            ],
            [24.58471760797342, 57.308970099667775, 79.40199335548172, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [1.2448132780082988, 17.42738589211618, 66.39004149377593, 100.0],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 55.24861878453039, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [
              58.00807537012113,
              70.12113055181696,
              72.27456258411844,
              72.27456258411844,
            ],
            [
              64.19919246298788,
              87.21399730820995,
              95.96231493943472,
              97.30820995962316,
            ],
          ],
          [
            [
              54.054054054054056,
              65.4054054054054,
              67.29729729729729,
              67.29729729729729,
            ],
            [
              60.40540540540541,
              84.72972972972973,
              94.72972972972973,
              96.48648648648648,
            ],
          ],
          [
            [
              51.01763907734057,
              61.601085481682496,
              63.50067842605156,
              63.50067842605156,
            ],
            [
              57.25915875169606,
              82.36092265943013,
              93.48710990502035,
              95.65807327001356,
            ],
          ],
          [
            [
              48.50136239782017,
              58.31062670299727,
              60.217983651226156,
              60.217983651226156,
            ],
            [
              53.950953678474114,
              80.24523160762944,
              92.23433242506812,
              94.82288828337875,
            ],
          ],
          [
            [
              39.19667590027701,
              50.83102493074792,
              50.83102493074792,
              50.83102493074792,
            ],
            [
              39.19667590027701,
              75.06925207756233,
              87.53462603878116,
              91.41274238227147,
            ],
          ],
          [
            [
              30.985915492957744,
              45.63380281690141,
              45.63380281690141,
              45.63380281690141,
            ],
            [
              30.985915492957744,
              72.11267605633803,
              83.94366197183099,
              87.88732394366198,
            ],
          ],
          [
            [
              26.36103151862464,
              41.260744985673355,
              41.260744985673355,
              41.260744985673355,
            ],
            [
              26.36103151862464,
              70.34383954154728,
              81.9484240687679,
              85.95988538681948,
            ],
          ],
          [
            [
              16.61721068249258,
              32.047477744807125,
              32.047477744807125,
              32.047477744807125,
            ],
            [
              18.100890207715135,
              59.64391691394659,
              77.74480712166172,
              81.89910979228486,
            ],
          ],
          [
            [
              3.993610223642172,
              14.536741214057509,
              14.536741214057509,
              14.536741214057509,
            ],
            [
              8.30670926517572,
              44.56869009584665,
              68.370607028754,
              72.84345047923323,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 13.24110671936759, 41.89723320158103, 42.68774703557312],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 23.83419689119171, 24.093264248704664],
          ],
        ],
        [
          [
            [
              58.00807537012113,
              70.12113055181696,
              72.27456258411844,
              72.27456258411844,
            ],
            [
              64.19919246298788,
              87.21399730820995,
              95.96231493943472,
              97.30820995962316,
            ],
          ],
          [
            [
              54.054054054054056,
              65.4054054054054,
              67.29729729729729,
              67.29729729729729,
            ],
            [
              60.40540540540541,
              84.72972972972973,
              94.72972972972973,
              96.48648648648648,
            ],
          ],
          [
            [
              51.01763907734057,
              61.601085481682496,
              63.50067842605156,
              63.50067842605156,
            ],
            [
              57.25915875169606,
              82.36092265943013,
              93.48710990502035,
              95.65807327001356,
            ],
          ],
          [
            [
              48.50136239782017,
              58.31062670299727,
              60.217983651226156,
              60.217983651226156,
            ],
            [
              53.950953678474114,
              80.24523160762944,
              92.23433242506812,
              94.82288828337875,
            ],
          ],
          [
            [
              39.19667590027701,
              50.83102493074792,
              50.83102493074792,
              50.83102493074792,
            ],
            [
              39.19667590027701,
              75.06925207756233,
              87.53462603878116,
              91.41274238227147,
            ],
          ],
          [
            [
              30.985915492957744,
              45.63380281690141,
              45.63380281690141,
              45.63380281690141,
            ],
            [
              30.985915492957744,
              72.11267605633803,
              83.94366197183099,
              87.88732394366198,
            ],
          ],
          [
            [
              26.36103151862464,
              41.260744985673355,
              41.260744985673355,
              41.260744985673355,
            ],
            [
              26.36103151862464,
              70.34383954154728,
              81.9484240687679,
              85.95988538681948,
            ],
          ],
          [
            [
              16.61721068249258,
              32.047477744807125,
              32.047477744807125,
              32.047477744807125,
            ],
            [
              18.100890207715135,
              59.64391691394659,
              77.74480712166172,
              81.89910979228486,
            ],
          ],
          [
            [
              3.993610223642172,
              14.536741214057509,
              14.536741214057509,
              14.536741214057509,
            ],
            [
              8.30670926517572,
              44.56869009584665,
              68.370607028754,
              72.84345047923323,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 13.24110671936759, 41.89723320158103, 42.68774703557312],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 23.83419689119171, 24.093264248704664],
          ],
        ],
        [
          [
            [
              58.00807537012113,
              70.12113055181696,
              72.27456258411844,
              72.27456258411844,
            ],
            [
              64.19919246298788,
              87.21399730820995,
              95.96231493943472,
              97.30820995962316,
            ],
          ],
          [
            [
              54.054054054054056,
              65.4054054054054,
              67.29729729729729,
              67.29729729729729,
            ],
            [
              60.40540540540541,
              84.72972972972973,
              94.72972972972973,
              96.48648648648648,
            ],
          ],
          [
            [
              51.01763907734057,
              61.601085481682496,
              63.50067842605156,
              63.50067842605156,
            ],
            [
              57.25915875169606,
              82.36092265943013,
              93.48710990502035,
              95.65807327001356,
            ],
          ],
          [
            [
              48.50136239782017,
              58.31062670299727,
              60.217983651226156,
              60.217983651226156,
            ],
            [
              53.950953678474114,
              80.24523160762944,
              92.23433242506812,
              94.82288828337875,
            ],
          ],
          [
            [
              39.19667590027701,
              50.83102493074792,
              50.83102493074792,
              50.83102493074792,
            ],
            [
              39.19667590027701,
              75.06925207756233,
              87.53462603878116,
              91.41274238227147,
            ],
          ],
          [
            [
              30.985915492957744,
              45.63380281690141,
              45.63380281690141,
              45.63380281690141,
            ],
            [
              30.985915492957744,
              72.11267605633803,
              83.94366197183099,
              87.88732394366198,
            ],
          ],
          [
            [
              26.36103151862464,
              41.260744985673355,
              41.260744985673355,
              41.260744985673355,
            ],
            [
              26.36103151862464,
              70.34383954154728,
              81.9484240687679,
              85.95988538681948,
            ],
          ],
          [
            [
              16.61721068249258,
              32.047477744807125,
              32.047477744807125,
              32.047477744807125,
            ],
            [
              18.100890207715135,
              59.64391691394659,
              77.74480712166172,
              81.89910979228486,
            ],
          ],
          [
            [
              3.993610223642172,
              14.536741214057509,
              14.536741214057509,
              14.536741214057509,
            ],
            [
              8.30670926517572,
              44.56869009584665,
              68.370607028754,
              72.84345047923323,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 13.24110671936759, 41.89723320158103, 42.68774703557312],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 23.83419689119171, 24.093264248704664],
          ],
        ],
        [
          [
            [
              58.00807537012113,
              70.12113055181696,
              72.27456258411844,
              72.27456258411844,
            ],
            [
              64.19919246298788,
              87.21399730820995,
              95.96231493943472,
              97.30820995962316,
            ],
          ],
          [
            [
              54.054054054054056,
              65.4054054054054,
              67.29729729729729,
              67.29729729729729,
            ],
            [
              60.40540540540541,
              84.72972972972973,
              94.72972972972973,
              96.48648648648648,
            ],
          ],
          [
            [
              51.01763907734057,
              61.601085481682496,
              63.50067842605156,
              63.50067842605156,
            ],
            [
              57.25915875169606,
              82.36092265943013,
              93.48710990502035,
              95.65807327001356,
            ],
          ],
          [
            [
              48.50136239782017,
              58.31062670299727,
              60.217983651226156,
              60.217983651226156,
            ],
            [
              53.950953678474114,
              80.24523160762944,
              92.23433242506812,
              94.82288828337875,
            ],
          ],
          [
            [
              39.19667590027701,
              50.83102493074792,
              50.83102493074792,
              50.83102493074792,
            ],
            [
              39.19667590027701,
              75.06925207756233,
              87.53462603878116,
              91.41274238227147,
            ],
          ],
          [
            [
              30.985915492957744,
              45.63380281690141,
              45.63380281690141,
              45.63380281690141,
            ],
            [
              30.985915492957744,
              72.11267605633803,
              83.94366197183099,
              87.88732394366198,
            ],
          ],
          [
            [
              26.36103151862464,
              41.260744985673355,
              41.260744985673355,
              41.260744985673355,
            ],
            [
              26.36103151862464,
              70.34383954154728,
              81.9484240687679,
              85.95988538681948,
            ],
          ],
          [
            [
              16.61721068249258,
              32.047477744807125,
              32.047477744807125,
              32.047477744807125,
            ],
            [
              18.100890207715135,
              59.64391691394659,
              77.74480712166172,
              81.89910979228486,
            ],
          ],
          [
            [
              3.993610223642172,
              14.536741214057509,
              14.536741214057509,
              14.536741214057509,
            ],
            [
              8.30670926517572,
              44.56869009584665,
              68.370607028754,
              72.84345047923323,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 13.24110671936759, 41.89723320158103, 42.68774703557312],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 23.83419689119171, 24.093264248704664],
          ],
        ],
      ],
      [
        [
          [
            [
              34.909596662030594,
              56.4673157162726,
              57.02364394993046,
              57.02364394993046,
            ],
            [
              46.17524339360223,
              82.19749652294854,
              94.85396383866481,
              96.66203059805285,
            ],
          ],
          [
            [
              29.18994413407821,
              47.3463687150838,
              47.76536312849162,
              47.76536312849162,
            ],
            [
              40.92178770949721,
              79.74860335195531,
              93.15642458100558,
              96.22905027932961,
            ],
          ],
          [
            [
              25.666199158485277,
              41.09396914446003,
              41.23422159887798,
              41.23422159887798,
            ],
            [
              37.30715287517531,
              77.84011220196354,
              91.4446002805049,
              95.79242636746143,
            ],
          ],
          [
            [
              22.816901408450704,
              36.19718309859155,
              36.33802816901409,
              36.33802816901409,
            ],
            [
              34.50704225352113,
              75.49295774647888,
              90.14084507042254,
              95.35211267605634,
            ],
          ],
          [
            [
              14.183381088825215,
              22.922636103151863,
              23.065902578796564,
              23.065902578796564,
            ],
            [
              25.787965616045845,
              63.89684813753582,
              84.81375358166189,
              93.55300859598854,
            ],
          ],
          [
            [
              8.309037900874635,
              14.431486880466474,
              14.577259475218659,
              14.577259475218659,
            ],
            [
              19.096209912536445,
              55.539358600583085,
              81.34110787172013,
              91.69096209912536,
            ],
          ],
          [
            [
              6.379821958456973,
              9.940652818991099,
              10.089020771513352,
              10.089020771513352,
            ],
            [
              14.540059347181009,
              48.516320474777444,
              77.59643916913946,
              89.76261127596439,
            ],
          ],
          [
            [
              2.769230769230769,
              4.615384615384616,
              4.615384615384616,
              4.615384615384616,
            ],
            [
              7.6923076923076925,
              37.69230769230769,
              65.6923076923077,
              85.6923076923077,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 21.096345514950166, 42.857142857142854, 76.578073089701],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 3.7344398340248963, 45.850622406639005],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 9.668508287292818],
          ],
        ],
        [
          [
            [
              34.909596662030594,
              56.4673157162726,
              57.02364394993046,
              57.02364394993046,
            ],
            [
              46.17524339360223,
              82.19749652294854,
              94.85396383866481,
              96.66203059805285,
            ],
          ],
          [
            [
              29.18994413407821,
              47.3463687150838,
              47.76536312849162,
              47.76536312849162,
            ],
            [
              40.92178770949721,
              79.74860335195531,
              93.15642458100558,
              96.22905027932961,
            ],
          ],
          [
            [
              25.666199158485277,
              41.09396914446003,
              41.23422159887798,
              41.23422159887798,
            ],
            [
              37.30715287517531,
              77.84011220196354,
              91.4446002805049,
              95.79242636746143,
            ],
          ],
          [
            [
              22.816901408450704,
              36.19718309859155,
              36.33802816901409,
              36.33802816901409,
            ],
            [
              34.50704225352113,
              75.49295774647888,
              90.14084507042254,
              95.35211267605634,
            ],
          ],
          [
            [
              14.183381088825215,
              22.922636103151863,
              23.065902578796564,
              23.065902578796564,
            ],
            [
              25.787965616045845,
              63.89684813753582,
              84.81375358166189,
              93.55300859598854,
            ],
          ],
          [
            [
              8.309037900874635,
              14.431486880466474,
              14.577259475218659,
              14.577259475218659,
            ],
            [
              19.096209912536445,
              55.539358600583085,
              81.34110787172013,
              91.69096209912536,
            ],
          ],
          [
            [
              6.379821958456973,
              9.940652818991099,
              10.089020771513352,
              10.089020771513352,
            ],
            [
              14.540059347181009,
              48.516320474777444,
              77.59643916913946,
              89.76261127596439,
            ],
          ],
          [
            [
              2.769230769230769,
              4.615384615384616,
              4.615384615384616,
              4.615384615384616,
            ],
            [
              7.6923076923076925,
              37.69230769230769,
              65.6923076923077,
              85.6923076923077,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 21.096345514950166, 42.857142857142854, 76.578073089701],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 3.7344398340248963, 45.850622406639005],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 9.668508287292818],
          ],
        ],
        [
          [
            [
              34.909596662030594,
              56.4673157162726,
              57.02364394993046,
              57.02364394993046,
            ],
            [
              46.17524339360223,
              82.19749652294854,
              94.85396383866481,
              96.66203059805285,
            ],
          ],
          [
            [
              29.18994413407821,
              47.3463687150838,
              47.76536312849162,
              47.76536312849162,
            ],
            [
              40.92178770949721,
              79.74860335195531,
              93.15642458100558,
              96.22905027932961,
            ],
          ],
          [
            [
              25.666199158485277,
              41.09396914446003,
              41.23422159887798,
              41.23422159887798,
            ],
            [
              37.30715287517531,
              77.84011220196354,
              91.4446002805049,
              95.79242636746143,
            ],
          ],
          [
            [
              22.816901408450704,
              36.19718309859155,
              36.33802816901409,
              36.33802816901409,
            ],
            [
              34.50704225352113,
              75.49295774647888,
              90.14084507042254,
              95.35211267605634,
            ],
          ],
          [
            [
              14.183381088825215,
              22.922636103151863,
              23.065902578796564,
              23.065902578796564,
            ],
            [
              25.787965616045845,
              63.89684813753582,
              84.81375358166189,
              93.55300859598854,
            ],
          ],
          [
            [
              8.309037900874635,
              14.431486880466474,
              14.577259475218659,
              14.577259475218659,
            ],
            [
              19.096209912536445,
              55.539358600583085,
              81.34110787172013,
              91.69096209912536,
            ],
          ],
          [
            [
              6.379821958456973,
              9.940652818991099,
              10.089020771513352,
              10.089020771513352,
            ],
            [
              14.540059347181009,
              48.516320474777444,
              77.59643916913946,
              89.76261127596439,
            ],
          ],
          [
            [
              2.769230769230769,
              4.615384615384616,
              4.615384615384616,
              4.615384615384616,
            ],
            [
              7.6923076923076925,
              37.69230769230769,
              65.6923076923077,
              85.6923076923077,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 21.096345514950166, 42.857142857142854, 76.578073089701],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 3.7344398340248963, 45.850622406639005],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 9.668508287292818],
          ],
        ],
        [
          [
            [
              34.909596662030594,
              56.4673157162726,
              57.02364394993046,
              57.02364394993046,
            ],
            [
              46.17524339360223,
              82.19749652294854,
              94.85396383866481,
              96.66203059805285,
            ],
          ],
          [
            [
              29.18994413407821,
              47.3463687150838,
              47.76536312849162,
              47.76536312849162,
            ],
            [
              40.92178770949721,
              79.74860335195531,
              93.15642458100558,
              96.22905027932961,
            ],
          ],
          [
            [
              25.666199158485277,
              41.09396914446003,
              41.23422159887798,
              41.23422159887798,
            ],
            [
              37.30715287517531,
              77.84011220196354,
              91.4446002805049,
              95.79242636746143,
            ],
          ],
          [
            [
              22.816901408450704,
              36.19718309859155,
              36.33802816901409,
              36.33802816901409,
            ],
            [
              34.50704225352113,
              75.49295774647888,
              90.14084507042254,
              95.35211267605634,
            ],
          ],
          [
            [
              14.183381088825215,
              22.922636103151863,
              23.065902578796564,
              23.065902578796564,
            ],
            [
              25.787965616045845,
              63.89684813753582,
              84.81375358166189,
              93.55300859598854,
            ],
          ],
          [
            [
              8.309037900874635,
              14.431486880466474,
              14.577259475218659,
              14.577259475218659,
            ],
            [
              19.096209912536445,
              55.539358600583085,
              81.34110787172013,
              91.69096209912536,
            ],
          ],
          [
            [
              6.379821958456973,
              9.940652818991099,
              10.089020771513352,
              10.089020771513352,
            ],
            [
              14.540059347181009,
              48.516320474777444,
              77.59643916913946,
              89.76261127596439,
            ],
          ],
          [
            [
              2.769230769230769,
              4.615384615384616,
              4.615384615384616,
              4.615384615384616,
            ],
            [
              7.6923076923076925,
              37.69230769230769,
              65.6923076923077,
              85.6923076923077,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 21.096345514950166, 42.857142857142854, 76.578073089701],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 3.7344398340248963, 45.850622406639005],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 9.668508287292818],
          ],
        ],
      ],
      [
        [
          [
            [
              31.22476446837147,
              53.566621803499324,
              54.50874831763123,
              54.50874831763123,
            ],
            [
              38.088829071332434,
              74.56258411843876,
              89.90578734858681,
              93.13593539703902,
            ],
          ],
          [
            [
              24.594594594594597,
              47.7027027027027,
              49.18918918918919,
              49.18918918918919,
            ],
            [
              31.351351351351354,
              68.64864864864865,
              88.51351351351352,
              91.35135135135135,
            ],
          ],
          [
            [
              20.352781546811396,
              41.926729986431475,
              43.82632293080054,
              43.82632293080054,
            ],
            [
              27.408412483039346,
              63.36499321573948,
              87.24559023066486,
              90.09497964721845,
            ],
          ],
          [
            [
              16.7574931880109,
              36.78474114441417,
              38.69209809264305,
              38.69209809264305,
            ],
            [
              24.250681198910083,
              58.58310626702997,
              85.96730245231608,
              88.8283378746594,
            ],
          ],
          [
            [
              6.9252077562326875,
              23.407202216066484,
              23.407202216066484,
              23.407202216066484,
            ],
            [
              14.127423822714682,
              43.62880886426593,
              80.74792243767313,
              83.65650969529086,
            ],
          ],
          [
            [
              1.4084507042253522,
              16.19718309859155,
              16.19718309859155,
              16.19718309859155,
            ],
            [
              8.591549295774648,
              32.25352112676057,
              76.19718309859155,
              78.59154929577464,
            ],
          ],
          [
            [0.0, 11.031518624641834, 11.031518624641834, 11.031518624641834],
            [
              5.873925501432665,
              23.78223495702006,
              73.92550143266476,
              74.92836676217765,
            ],
          ],
          [
            [0.0, 3.2640949554896146, 3.2640949554896146, 3.2640949554896146],
            [
              1.1869436201780417,
              11.572700296735905,
              65.8753709198813,
              68.5459940652819,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 47.92332268370607, 51.118210862619804],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 14.229249011857709, 15.810276679841898],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 0.0],
          ],
        ],
        [
          [
            [
              31.22476446837147,
              53.566621803499324,
              54.50874831763123,
              54.50874831763123,
            ],
            [
              38.088829071332434,
              74.56258411843876,
              89.90578734858681,
              93.13593539703902,
            ],
          ],
          [
            [
              24.594594594594597,
              47.7027027027027,
              49.18918918918919,
              49.18918918918919,
            ],
            [
              31.351351351351354,
              68.64864864864865,
              88.51351351351352,
              91.35135135135135,
            ],
          ],
          [
            [
              20.352781546811396,
              41.926729986431475,
              43.82632293080054,
              43.82632293080054,
            ],
            [
              27.408412483039346,
              63.36499321573948,
              87.24559023066486,
              90.09497964721845,
            ],
          ],
          [
            [
              16.7574931880109,
              36.78474114441417,
              38.69209809264305,
              38.69209809264305,
            ],
            [
              24.250681198910083,
              58.58310626702997,
              85.96730245231608,
              88.8283378746594,
            ],
          ],
          [
            [
              6.9252077562326875,
              23.407202216066484,
              23.407202216066484,
              23.407202216066484,
            ],
            [
              14.127423822714682,
              43.62880886426593,
              80.74792243767313,
              83.65650969529086,
            ],
          ],
          [
            [
              1.4084507042253522,
              16.19718309859155,
              16.19718309859155,
              16.19718309859155,
            ],
            [
              8.591549295774648,
              32.25352112676057,
              76.19718309859155,
              78.59154929577464,
            ],
          ],
          [
            [0.0, 11.031518624641834, 11.031518624641834, 11.031518624641834],
            [
              5.873925501432665,
              23.78223495702006,
              73.92550143266476,
              74.92836676217765,
            ],
          ],
          [
            [0.0, 3.2640949554896146, 3.2640949554896146, 3.2640949554896146],
            [
              1.1869436201780417,
              11.572700296735905,
              65.8753709198813,
              68.5459940652819,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 47.92332268370607, 51.118210862619804],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 14.229249011857709, 15.810276679841898],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 0.0],
          ],
        ],
        [
          [
            [
              31.22476446837147,
              53.566621803499324,
              54.50874831763123,
              54.50874831763123,
            ],
            [
              38.088829071332434,
              74.56258411843876,
              89.90578734858681,
              93.13593539703902,
            ],
          ],
          [
            [
              24.594594594594597,
              47.7027027027027,
              49.18918918918919,
              49.18918918918919,
            ],
            [
              31.351351351351354,
              68.64864864864865,
              88.51351351351352,
              91.35135135135135,
            ],
          ],
          [
            [
              20.352781546811396,
              41.926729986431475,
              43.82632293080054,
              43.82632293080054,
            ],
            [
              27.408412483039346,
              63.36499321573948,
              87.24559023066486,
              90.09497964721845,
            ],
          ],
          [
            [
              16.7574931880109,
              36.78474114441417,
              38.69209809264305,
              38.69209809264305,
            ],
            [
              24.250681198910083,
              58.58310626702997,
              85.96730245231608,
              88.8283378746594,
            ],
          ],
          [
            [
              6.9252077562326875,
              23.407202216066484,
              23.407202216066484,
              23.407202216066484,
            ],
            [
              14.127423822714682,
              43.62880886426593,
              80.74792243767313,
              83.65650969529086,
            ],
          ],
          [
            [
              1.4084507042253522,
              16.19718309859155,
              16.19718309859155,
              16.19718309859155,
            ],
            [
              8.591549295774648,
              32.25352112676057,
              76.19718309859155,
              78.59154929577464,
            ],
          ],
          [
            [0.0, 11.031518624641834, 11.031518624641834, 11.031518624641834],
            [
              5.873925501432665,
              23.78223495702006,
              73.92550143266476,
              74.92836676217765,
            ],
          ],
          [
            [0.0, 3.2640949554896146, 3.2640949554896146, 3.2640949554896146],
            [
              1.1869436201780417,
              11.572700296735905,
              65.8753709198813,
              68.5459940652819,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 47.92332268370607, 51.118210862619804],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 14.229249011857709, 15.810276679841898],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 0.0],
          ],
        ],
        [
          [
            [
              31.22476446837147,
              53.566621803499324,
              54.50874831763123,
              54.50874831763123,
            ],
            [
              38.088829071332434,
              74.56258411843876,
              89.90578734858681,
              93.13593539703902,
            ],
          ],
          [
            [
              24.594594594594597,
              47.7027027027027,
              49.18918918918919,
              49.18918918918919,
            ],
            [
              31.351351351351354,
              68.64864864864865,
              88.51351351351352,
              91.35135135135135,
            ],
          ],
          [
            [
              20.352781546811396,
              41.926729986431475,
              43.82632293080054,
              43.82632293080054,
            ],
            [
              27.408412483039346,
              63.36499321573948,
              87.24559023066486,
              90.09497964721845,
            ],
          ],
          [
            [
              16.7574931880109,
              36.78474114441417,
              38.69209809264305,
              38.69209809264305,
            ],
            [
              24.250681198910083,
              58.58310626702997,
              85.96730245231608,
              88.8283378746594,
            ],
          ],
          [
            [
              6.9252077562326875,
              23.407202216066484,
              23.407202216066484,
              23.407202216066484,
            ],
            [
              14.127423822714682,
              43.62880886426593,
              80.74792243767313,
              83.65650969529086,
            ],
          ],
          [
            [
              1.4084507042253522,
              16.19718309859155,
              16.19718309859155,
              16.19718309859155,
            ],
            [
              8.591549295774648,
              32.25352112676057,
              76.19718309859155,
              78.59154929577464,
            ],
          ],
          [
            [0.0, 11.031518624641834, 11.031518624641834, 11.031518624641834],
            [
              5.873925501432665,
              23.78223495702006,
              73.92550143266476,
              74.92836676217765,
            ],
          ],
          [
            [0.0, 3.2640949554896146, 3.2640949554896146, 3.2640949554896146],
            [
              1.1869436201780417,
              11.572700296735905,
              65.8753709198813,
              68.5459940652819,
            ],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 47.92332268370607, 51.118210862619804],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 14.229249011857709, 15.810276679841898],
          ],
          [
            [0.0, 0.0, 0.0, 0.0],
            [0.0, 0.0, 0.0, 0.0],
          ],
        ],
      ],
    ],
    dimensions: ['month', 'quantiles', 'duration', 'magw_limit', 'hs_limit'],
    attributes: {
      units: '%',
      description: 'minimum probability of the weather window over the years',
      vartype: 'f8',
    },
  },
  probabilityMax: {
    values: [
      [
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [
              93.2935916542474,
              98.95678092399403,
              98.95678092399403,
              98.95678092399403,
            ],
            [96.274217585693, 100.0, 100.0, 100.0],
          ],
          [
            [
              92.21556886227546,
              98.50299401197606,
              98.50299401197606,
              98.50299401197606,
            ],
            [94.91017964071857, 100.0, 100.0, 100.0],
          ],
          [
            [
              91.2781954887218,
              98.04511278195488,
              98.04511278195488,
              98.04511278195488,
            ],
            [93.53383458646617, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.33232628398792,
              97.58308157099698,
              97.58308157099698,
              97.58308157099698,
            ],
            [92.14501510574019, 100.0, 100.0, 100.0],
          ],
          [
            [
              86.46153846153845,
              95.6923076923077,
              95.6923076923077,
              95.6923076923077,
            ],
            [87.6923076923077, 100.0, 100.0, 100.0],
          ],
          [
            [
              82.44514106583071,
              93.73040752351098,
              93.73040752351098,
              93.73040752351098,
            ],
            [83.69905956112854, 100.0, 100.0, 100.0],
          ],
          [
            [
              80.19169329073482,
              91.69329073482429,
              91.69329073482429,
              91.69329073482429,
            ],
            [80.19169329073482, 100.0, 100.0, 100.0],
          ],
          [
            [
              75.41528239202658,
              87.70764119601328,
              87.70764119601328,
              87.70764119601328,
            ],
            [75.41528239202658, 100.0, 100.0, 100.0],
          ],
          [
            [
              64.62093862815884,
              86.64259927797833,
              86.64259927797833,
              86.64259927797833,
            ],
            [65.34296028880865, 100.0, 100.0, 100.0],
          ],
          [
            [
              54.377880184331794,
              82.94930875576037,
              82.94930875576037,
              82.94930875576037,
            ],
            [55.76036866359447, 100.0, 100.0, 100.0],
          ],
          [
            [
              36.94267515923567,
              76.43312101910828,
              76.43312101910828,
              76.43312101910828,
            ],
            [38.853503184713375, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              93.2935916542474,
              98.95678092399403,
              98.95678092399403,
              98.95678092399403,
            ],
            [96.274217585693, 100.0, 100.0, 100.0],
          ],
          [
            [
              92.21556886227546,
              98.50299401197606,
              98.50299401197606,
              98.50299401197606,
            ],
            [94.91017964071857, 100.0, 100.0, 100.0],
          ],
          [
            [
              91.2781954887218,
              98.04511278195488,
              98.04511278195488,
              98.04511278195488,
            ],
            [93.53383458646617, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.33232628398792,
              97.58308157099698,
              97.58308157099698,
              97.58308157099698,
            ],
            [92.14501510574019, 100.0, 100.0, 100.0],
          ],
          [
            [
              86.46153846153845,
              95.6923076923077,
              95.6923076923077,
              95.6923076923077,
            ],
            [87.6923076923077, 100.0, 100.0, 100.0],
          ],
          [
            [
              82.44514106583071,
              93.73040752351098,
              93.73040752351098,
              93.73040752351098,
            ],
            [83.69905956112854, 100.0, 100.0, 100.0],
          ],
          [
            [
              80.19169329073482,
              91.69329073482429,
              91.69329073482429,
              91.69329073482429,
            ],
            [80.19169329073482, 100.0, 100.0, 100.0],
          ],
          [
            [
              75.41528239202658,
              87.70764119601328,
              87.70764119601328,
              87.70764119601328,
            ],
            [75.41528239202658, 100.0, 100.0, 100.0],
          ],
          [
            [
              64.62093862815884,
              86.64259927797833,
              86.64259927797833,
              86.64259927797833,
            ],
            [65.34296028880865, 100.0, 100.0, 100.0],
          ],
          [
            [
              54.377880184331794,
              82.94930875576037,
              82.94930875576037,
              82.94930875576037,
            ],
            [55.76036866359447, 100.0, 100.0, 100.0],
          ],
          [
            [
              36.94267515923567,
              76.43312101910828,
              76.43312101910828,
              76.43312101910828,
            ],
            [38.853503184713375, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              93.2935916542474,
              98.95678092399403,
              98.95678092399403,
              98.95678092399403,
            ],
            [96.274217585693, 100.0, 100.0, 100.0],
          ],
          [
            [
              92.21556886227546,
              98.50299401197606,
              98.50299401197606,
              98.50299401197606,
            ],
            [94.91017964071857, 100.0, 100.0, 100.0],
          ],
          [
            [
              91.2781954887218,
              98.04511278195488,
              98.04511278195488,
              98.04511278195488,
            ],
            [93.53383458646617, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.33232628398792,
              97.58308157099698,
              97.58308157099698,
              97.58308157099698,
            ],
            [92.14501510574019, 100.0, 100.0, 100.0],
          ],
          [
            [
              86.46153846153845,
              95.6923076923077,
              95.6923076923077,
              95.6923076923077,
            ],
            [87.6923076923077, 100.0, 100.0, 100.0],
          ],
          [
            [
              82.44514106583071,
              93.73040752351098,
              93.73040752351098,
              93.73040752351098,
            ],
            [83.69905956112854, 100.0, 100.0, 100.0],
          ],
          [
            [
              80.19169329073482,
              91.69329073482429,
              91.69329073482429,
              91.69329073482429,
            ],
            [80.19169329073482, 100.0, 100.0, 100.0],
          ],
          [
            [
              75.41528239202658,
              87.70764119601328,
              87.70764119601328,
              87.70764119601328,
            ],
            [75.41528239202658, 100.0, 100.0, 100.0],
          ],
          [
            [
              64.62093862815884,
              86.64259927797833,
              86.64259927797833,
              86.64259927797833,
            ],
            [65.34296028880865, 100.0, 100.0, 100.0],
          ],
          [
            [
              54.377880184331794,
              82.94930875576037,
              82.94930875576037,
              82.94930875576037,
            ],
            [55.76036866359447, 100.0, 100.0, 100.0],
          ],
          [
            [
              36.94267515923567,
              76.43312101910828,
              76.43312101910828,
              76.43312101910828,
            ],
            [38.853503184713375, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              93.2935916542474,
              98.95678092399403,
              98.95678092399403,
              98.95678092399403,
            ],
            [96.274217585693, 100.0, 100.0, 100.0],
          ],
          [
            [
              92.21556886227546,
              98.50299401197606,
              98.50299401197606,
              98.50299401197606,
            ],
            [94.91017964071857, 100.0, 100.0, 100.0],
          ],
          [
            [
              91.2781954887218,
              98.04511278195488,
              98.04511278195488,
              98.04511278195488,
            ],
            [93.53383458646617, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.33232628398792,
              97.58308157099698,
              97.58308157099698,
              97.58308157099698,
            ],
            [92.14501510574019, 100.0, 100.0, 100.0],
          ],
          [
            [
              86.46153846153845,
              95.6923076923077,
              95.6923076923077,
              95.6923076923077,
            ],
            [87.6923076923077, 100.0, 100.0, 100.0],
          ],
          [
            [
              82.44514106583071,
              93.73040752351098,
              93.73040752351098,
              93.73040752351098,
            ],
            [83.69905956112854, 100.0, 100.0, 100.0],
          ],
          [
            [
              80.19169329073482,
              91.69329073482429,
              91.69329073482429,
              91.69329073482429,
            ],
            [80.19169329073482, 100.0, 100.0, 100.0],
          ],
          [
            [
              75.41528239202658,
              87.70764119601328,
              87.70764119601328,
              87.70764119601328,
            ],
            [75.41528239202658, 100.0, 100.0, 100.0],
          ],
          [
            [
              64.62093862815884,
              86.64259927797833,
              86.64259927797833,
              86.64259927797833,
            ],
            [65.34296028880865, 100.0, 100.0, 100.0],
          ],
          [
            [
              54.377880184331794,
              82.94930875576037,
              82.94930875576037,
              82.94930875576037,
            ],
            [55.76036866359447, 100.0, 100.0, 100.0],
          ],
          [
            [
              36.94267515923567,
              76.43312101910828,
              76.43312101910828,
              76.43312101910828,
            ],
            [38.853503184713375, 100.0, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [
              98.38492597577388,
              98.65410497981158,
              98.65410497981158,
              98.65410497981158,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              97.56756756756756,
              98.24324324324324,
              98.24324324324324,
              98.24324324324324,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              96.74355495251018,
              97.82903663500679,
              97.82903663500679,
              97.82903663500679,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              95.91280653950953,
              97.41144414168937,
              97.41144414168937,
              97.41144414168937,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              92.797783933518,
              95.70637119113573,
              95.70637119113573,
              95.70637119113573,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.98591549295774,
              93.94366197183098,
              93.94366197183098,
              93.94366197183098,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.40114613180515,
              92.12034383954155,
              92.12034383954155,
              92.12034383954155,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.0593471810089,
              90.0593471810089,
              90.0593471810089,
              90.0593471810089,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              89.29712460063898,
              89.29712460063898,
              89.29712460063898,
              89.29712460063898,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              86.7588932806324,
              86.7588932806324,
              86.7588932806324,
              86.7588932806324,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              82.64248704663213,
              82.64248704663213,
              82.64248704663213,
              82.64248704663213,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              98.38492597577388,
              98.65410497981158,
              98.65410497981158,
              98.65410497981158,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              97.56756756756756,
              98.24324324324324,
              98.24324324324324,
              98.24324324324324,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              96.74355495251018,
              97.82903663500679,
              97.82903663500679,
              97.82903663500679,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              95.91280653950953,
              97.41144414168937,
              97.41144414168937,
              97.41144414168937,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              92.797783933518,
              95.70637119113573,
              95.70637119113573,
              95.70637119113573,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.98591549295774,
              93.94366197183098,
              93.94366197183098,
              93.94366197183098,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.40114613180515,
              92.12034383954155,
              92.12034383954155,
              92.12034383954155,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.0593471810089,
              90.0593471810089,
              90.0593471810089,
              90.0593471810089,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              89.29712460063898,
              89.29712460063898,
              89.29712460063898,
              89.29712460063898,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              86.7588932806324,
              86.7588932806324,
              86.7588932806324,
              86.7588932806324,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              82.64248704663213,
              82.64248704663213,
              82.64248704663213,
              82.64248704663213,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              98.38492597577388,
              98.65410497981158,
              98.65410497981158,
              98.65410497981158,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              97.56756756756756,
              98.24324324324324,
              98.24324324324324,
              98.24324324324324,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              96.74355495251018,
              97.82903663500679,
              97.82903663500679,
              97.82903663500679,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              95.91280653950953,
              97.41144414168937,
              97.41144414168937,
              97.41144414168937,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              92.797783933518,
              95.70637119113573,
              95.70637119113573,
              95.70637119113573,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.98591549295774,
              93.94366197183098,
              93.94366197183098,
              93.94366197183098,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.40114613180515,
              92.12034383954155,
              92.12034383954155,
              92.12034383954155,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.0593471810089,
              90.0593471810089,
              90.0593471810089,
              90.0593471810089,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              89.29712460063898,
              89.29712460063898,
              89.29712460063898,
              89.29712460063898,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              86.7588932806324,
              86.7588932806324,
              86.7588932806324,
              86.7588932806324,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              82.64248704663213,
              82.64248704663213,
              82.64248704663213,
              82.64248704663213,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              98.38492597577388,
              98.65410497981158,
              98.65410497981158,
              98.65410497981158,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              97.56756756756756,
              98.24324324324324,
              98.24324324324324,
              98.24324324324324,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              96.74355495251018,
              97.82903663500679,
              97.82903663500679,
              97.82903663500679,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              95.91280653950953,
              97.41144414168937,
              97.41144414168937,
              97.41144414168937,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              92.797783933518,
              95.70637119113573,
              95.70637119113573,
              95.70637119113573,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.98591549295774,
              93.94366197183098,
              93.94366197183098,
              93.94366197183098,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.40114613180515,
              92.12034383954155,
              92.12034383954155,
              92.12034383954155,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.0593471810089,
              90.0593471810089,
              90.0593471810089,
              90.0593471810089,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              89.29712460063898,
              89.29712460063898,
              89.29712460063898,
              89.29712460063898,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              86.7588932806324,
              86.7588932806324,
              86.7588932806324,
              86.7588932806324,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              82.64248704663213,
              82.64248704663213,
              82.64248704663213,
              82.64248704663213,
            ],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [99.30458970792768, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.88268156424581, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.45722300140253, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.02816901408451, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [96.27507163323781, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [94.46064139941691, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [92.58160237388724, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [88.61538461538461, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [86.71096345514951, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [83.40248962655602, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [77.90055248618785, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [99.30458970792768, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.88268156424581, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.45722300140253, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.02816901408451, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [96.27507163323781, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [94.46064139941691, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [92.58160237388724, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [88.61538461538461, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [86.71096345514951, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [83.40248962655602, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [77.90055248618785, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [99.30458970792768, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.88268156424581, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.45722300140253, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.02816901408451, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [96.27507163323781, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [94.46064139941691, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [92.58160237388724, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [88.61538461538461, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [86.71096345514951, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [83.40248962655602, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [77.90055248618785, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [99.30458970792768, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.88268156424581, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.45722300140253, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.02816901408451, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [96.27507163323781, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [94.46064139941691, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [92.58160237388724, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [88.61538461538461, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [86.71096345514951, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [83.40248962655602, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [77.90055248618785, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [100.0, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [99.30458970792768, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.88268156424581, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.45722300140253, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.02816901408451, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [96.27507163323781, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [94.46064139941691, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [92.58160237388724, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [88.61538461538461, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [79.734219269103, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [72.82157676348547, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [63.812154696132595, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [99.30458970792768, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.88268156424581, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.45722300140253, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.02816901408451, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [96.27507163323781, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [94.46064139941691, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [92.58160237388724, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [88.61538461538461, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [79.734219269103, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [72.82157676348547, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [63.812154696132595, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [99.30458970792768, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.88268156424581, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.45722300140253, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.02816901408451, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [96.27507163323781, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [94.46064139941691, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [92.58160237388724, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [88.61538461538461, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [79.734219269103, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [72.82157676348547, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [63.812154696132595, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [99.30458970792768, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.88268156424581, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.45722300140253, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [98.02816901408451, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [96.27507163323781, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [94.46064139941691, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [92.58160237388724, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [88.61538461538461, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [79.734219269103, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [72.82157676348547, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
          [
            [63.812154696132595, 100.0, 100.0, 100.0],
            [100.0, 100.0, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [97.30820995962316, 100.0, 100.0, 100.0],
            [98.78869448183042, 100.0, 100.0, 100.0],
          ],
          [
            [96.48648648648648, 100.0, 100.0, 100.0],
            [98.37837837837839, 100.0, 100.0, 100.0],
          ],
          [
            [95.79375848032564, 100.0, 100.0, 100.0],
            [97.96472184531886, 100.0, 100.0, 100.0],
          ],
          [
            [95.36784741144415, 100.0, 100.0, 100.0],
            [97.54768392370572, 100.0, 100.0, 100.0],
          ],
          [
            [93.62880886426593, 100.0, 100.0, 100.0],
            [95.84487534626038, 100.0, 100.0, 100.0],
          ],
          [
            [91.83098591549296, 100.0, 100.0, 100.0],
            [94.08450704225352, 100.0, 100.0, 100.0],
          ],
          [
            [91.11747851002865, 100.0, 100.0, 100.0],
            [92.26361031518626, 100.0, 100.0, 100.0],
          ],
          [
            [90.80118694362018, 100.0, 100.0, 100.0],
            [90.80118694362018, 100.0, 100.0, 100.0],
          ],
          [
            [90.09584664536742, 100.0, 100.0, 100.0],
            [90.09584664536742, 100.0, 100.0, 100.0],
          ],
          [
            [87.74703557312253, 100.0, 100.0, 100.0],
            [87.74703557312253, 100.0, 100.0, 100.0],
          ],
          [
            [83.93782383419689, 100.0, 100.0, 100.0],
            [83.93782383419689, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [97.30820995962316, 100.0, 100.0, 100.0],
            [98.78869448183042, 100.0, 100.0, 100.0],
          ],
          [
            [96.48648648648648, 100.0, 100.0, 100.0],
            [98.37837837837839, 100.0, 100.0, 100.0],
          ],
          [
            [95.79375848032564, 100.0, 100.0, 100.0],
            [97.96472184531886, 100.0, 100.0, 100.0],
          ],
          [
            [95.36784741144415, 100.0, 100.0, 100.0],
            [97.54768392370572, 100.0, 100.0, 100.0],
          ],
          [
            [93.62880886426593, 100.0, 100.0, 100.0],
            [95.84487534626038, 100.0, 100.0, 100.0],
          ],
          [
            [91.83098591549296, 100.0, 100.0, 100.0],
            [94.08450704225352, 100.0, 100.0, 100.0],
          ],
          [
            [91.11747851002865, 100.0, 100.0, 100.0],
            [92.26361031518626, 100.0, 100.0, 100.0],
          ],
          [
            [90.80118694362018, 100.0, 100.0, 100.0],
            [90.80118694362018, 100.0, 100.0, 100.0],
          ],
          [
            [90.09584664536742, 100.0, 100.0, 100.0],
            [90.09584664536742, 100.0, 100.0, 100.0],
          ],
          [
            [87.74703557312253, 100.0, 100.0, 100.0],
            [87.74703557312253, 100.0, 100.0, 100.0],
          ],
          [
            [83.93782383419689, 100.0, 100.0, 100.0],
            [83.93782383419689, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [97.30820995962316, 100.0, 100.0, 100.0],
            [98.78869448183042, 100.0, 100.0, 100.0],
          ],
          [
            [96.48648648648648, 100.0, 100.0, 100.0],
            [98.37837837837839, 100.0, 100.0, 100.0],
          ],
          [
            [95.79375848032564, 100.0, 100.0, 100.0],
            [97.96472184531886, 100.0, 100.0, 100.0],
          ],
          [
            [95.36784741144415, 100.0, 100.0, 100.0],
            [97.54768392370572, 100.0, 100.0, 100.0],
          ],
          [
            [93.62880886426593, 100.0, 100.0, 100.0],
            [95.84487534626038, 100.0, 100.0, 100.0],
          ],
          [
            [91.83098591549296, 100.0, 100.0, 100.0],
            [94.08450704225352, 100.0, 100.0, 100.0],
          ],
          [
            [91.11747851002865, 100.0, 100.0, 100.0],
            [92.26361031518626, 100.0, 100.0, 100.0],
          ],
          [
            [90.80118694362018, 100.0, 100.0, 100.0],
            [90.80118694362018, 100.0, 100.0, 100.0],
          ],
          [
            [90.09584664536742, 100.0, 100.0, 100.0],
            [90.09584664536742, 100.0, 100.0, 100.0],
          ],
          [
            [87.74703557312253, 100.0, 100.0, 100.0],
            [87.74703557312253, 100.0, 100.0, 100.0],
          ],
          [
            [83.93782383419689, 100.0, 100.0, 100.0],
            [83.93782383419689, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [97.30820995962316, 100.0, 100.0, 100.0],
            [98.78869448183042, 100.0, 100.0, 100.0],
          ],
          [
            [96.48648648648648, 100.0, 100.0, 100.0],
            [98.37837837837839, 100.0, 100.0, 100.0],
          ],
          [
            [95.79375848032564, 100.0, 100.0, 100.0],
            [97.96472184531886, 100.0, 100.0, 100.0],
          ],
          [
            [95.36784741144415, 100.0, 100.0, 100.0],
            [97.54768392370572, 100.0, 100.0, 100.0],
          ],
          [
            [93.62880886426593, 100.0, 100.0, 100.0],
            [95.84487534626038, 100.0, 100.0, 100.0],
          ],
          [
            [91.83098591549296, 100.0, 100.0, 100.0],
            [94.08450704225352, 100.0, 100.0, 100.0],
          ],
          [
            [91.11747851002865, 100.0, 100.0, 100.0],
            [92.26361031518626, 100.0, 100.0, 100.0],
          ],
          [
            [90.80118694362018, 100.0, 100.0, 100.0],
            [90.80118694362018, 100.0, 100.0, 100.0],
          ],
          [
            [90.09584664536742, 100.0, 100.0, 100.0],
            [90.09584664536742, 100.0, 100.0, 100.0],
          ],
          [
            [87.74703557312253, 100.0, 100.0, 100.0],
            [87.74703557312253, 100.0, 100.0, 100.0],
          ],
          [
            [83.93782383419689, 100.0, 100.0, 100.0],
            [83.93782383419689, 100.0, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [
              93.3240611961057,
              96.80111265646731,
              96.80111265646731,
              96.80111265646731,
            ],
            [98.74826147426981, 100.0, 100.0, 100.0],
          ],
          [
            [
              91.4804469273743,
              95.53072625698324,
              95.53072625698324,
              95.53072625698324,
            ],
            [98.32402234636871, 100.0, 100.0, 100.0],
          ],
          [
            [
              89.76157082748948,
              94.24964936886396,
              94.24964936886396,
              94.24964936886396,
            ],
            [97.89621318373072, 100.0, 100.0, 100.0],
          ],
          [
            [
              88.02816901408451,
              92.95774647887323,
              92.95774647887323,
              92.95774647887323,
            ],
            [97.46478873239437, 100.0, 100.0, 100.0],
          ],
          [
            [
              80.94555873925502,
              87.67908309455588,
              87.67908309455588,
              87.67908309455588,
            ],
            [95.70200573065902, 100.0, 100.0, 100.0],
          ],
          [
            [
              75.94752186588921,
              82.6530612244898,
              82.6530612244898,
              82.6530612244898,
            ],
            [93.87755102040816, 100.0, 100.0, 100.0],
          ],
          [
            [
              73.29376854599407,
              80.56379821958457,
              80.56379821958457,
              80.56379821958457,
            ],
            [91.98813056379822, 100.0, 100.0, 100.0],
          ],
          [
            [
              68.61538461538461,
              76.15384615384615,
              76.15384615384615,
              76.15384615384615,
            ],
            [88.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              65.4485049833887,
              73.08970099667775,
              73.08970099667775,
              73.08970099667775,
            ],
            [79.06976744186046, 100.0, 100.0, 100.0],
          ],
          [
            [
              56.84647302904564,
              66.39004149377593,
              66.39004149377593,
              66.39004149377593,
            ],
            [58.50622406639005, 100.0, 100.0, 100.0],
          ],
          [
            [
              42.5414364640884,
              55.24861878453039,
              55.24861878453039,
              55.24861878453039,
            ],
            [44.751381215469614, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              93.3240611961057,
              96.80111265646731,
              96.80111265646731,
              96.80111265646731,
            ],
            [98.74826147426981, 100.0, 100.0, 100.0],
          ],
          [
            [
              91.4804469273743,
              95.53072625698324,
              95.53072625698324,
              95.53072625698324,
            ],
            [98.32402234636871, 100.0, 100.0, 100.0],
          ],
          [
            [
              89.76157082748948,
              94.24964936886396,
              94.24964936886396,
              94.24964936886396,
            ],
            [97.89621318373072, 100.0, 100.0, 100.0],
          ],
          [
            [
              88.02816901408451,
              92.95774647887323,
              92.95774647887323,
              92.95774647887323,
            ],
            [97.46478873239437, 100.0, 100.0, 100.0],
          ],
          [
            [
              80.94555873925502,
              87.67908309455588,
              87.67908309455588,
              87.67908309455588,
            ],
            [95.70200573065902, 100.0, 100.0, 100.0],
          ],
          [
            [
              75.94752186588921,
              82.6530612244898,
              82.6530612244898,
              82.6530612244898,
            ],
            [93.87755102040816, 100.0, 100.0, 100.0],
          ],
          [
            [
              73.29376854599407,
              80.56379821958457,
              80.56379821958457,
              80.56379821958457,
            ],
            [91.98813056379822, 100.0, 100.0, 100.0],
          ],
          [
            [
              68.61538461538461,
              76.15384615384615,
              76.15384615384615,
              76.15384615384615,
            ],
            [88.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              65.4485049833887,
              73.08970099667775,
              73.08970099667775,
              73.08970099667775,
            ],
            [79.06976744186046, 100.0, 100.0, 100.0],
          ],
          [
            [
              56.84647302904564,
              66.39004149377593,
              66.39004149377593,
              66.39004149377593,
            ],
            [58.50622406639005, 100.0, 100.0, 100.0],
          ],
          [
            [
              42.5414364640884,
              55.24861878453039,
              55.24861878453039,
              55.24861878453039,
            ],
            [44.751381215469614, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              93.3240611961057,
              96.80111265646731,
              96.80111265646731,
              96.80111265646731,
            ],
            [98.74826147426981, 100.0, 100.0, 100.0],
          ],
          [
            [
              91.4804469273743,
              95.53072625698324,
              95.53072625698324,
              95.53072625698324,
            ],
            [98.32402234636871, 100.0, 100.0, 100.0],
          ],
          [
            [
              89.76157082748948,
              94.24964936886396,
              94.24964936886396,
              94.24964936886396,
            ],
            [97.89621318373072, 100.0, 100.0, 100.0],
          ],
          [
            [
              88.02816901408451,
              92.95774647887323,
              92.95774647887323,
              92.95774647887323,
            ],
            [97.46478873239437, 100.0, 100.0, 100.0],
          ],
          [
            [
              80.94555873925502,
              87.67908309455588,
              87.67908309455588,
              87.67908309455588,
            ],
            [95.70200573065902, 100.0, 100.0, 100.0],
          ],
          [
            [
              75.94752186588921,
              82.6530612244898,
              82.6530612244898,
              82.6530612244898,
            ],
            [93.87755102040816, 100.0, 100.0, 100.0],
          ],
          [
            [
              73.29376854599407,
              80.56379821958457,
              80.56379821958457,
              80.56379821958457,
            ],
            [91.98813056379822, 100.0, 100.0, 100.0],
          ],
          [
            [
              68.61538461538461,
              76.15384615384615,
              76.15384615384615,
              76.15384615384615,
            ],
            [88.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              65.4485049833887,
              73.08970099667775,
              73.08970099667775,
              73.08970099667775,
            ],
            [79.06976744186046, 100.0, 100.0, 100.0],
          ],
          [
            [
              56.84647302904564,
              66.39004149377593,
              66.39004149377593,
              66.39004149377593,
            ],
            [58.50622406639005, 100.0, 100.0, 100.0],
          ],
          [
            [
              42.5414364640884,
              55.24861878453039,
              55.24861878453039,
              55.24861878453039,
            ],
            [44.751381215469614, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              93.3240611961057,
              96.80111265646731,
              96.80111265646731,
              96.80111265646731,
            ],
            [98.74826147426981, 100.0, 100.0, 100.0],
          ],
          [
            [
              91.4804469273743,
              95.53072625698324,
              95.53072625698324,
              95.53072625698324,
            ],
            [98.32402234636871, 100.0, 100.0, 100.0],
          ],
          [
            [
              89.76157082748948,
              94.24964936886396,
              94.24964936886396,
              94.24964936886396,
            ],
            [97.89621318373072, 100.0, 100.0, 100.0],
          ],
          [
            [
              88.02816901408451,
              92.95774647887323,
              92.95774647887323,
              92.95774647887323,
            ],
            [97.46478873239437, 100.0, 100.0, 100.0],
          ],
          [
            [
              80.94555873925502,
              87.67908309455588,
              87.67908309455588,
              87.67908309455588,
            ],
            [95.70200573065902, 100.0, 100.0, 100.0],
          ],
          [
            [
              75.94752186588921,
              82.6530612244898,
              82.6530612244898,
              82.6530612244898,
            ],
            [93.87755102040816, 100.0, 100.0, 100.0],
          ],
          [
            [
              73.29376854599407,
              80.56379821958457,
              80.56379821958457,
              80.56379821958457,
            ],
            [91.98813056379822, 100.0, 100.0, 100.0],
          ],
          [
            [
              68.61538461538461,
              76.15384615384615,
              76.15384615384615,
              76.15384615384615,
            ],
            [88.0, 100.0, 100.0, 100.0],
          ],
          [
            [
              65.4485049833887,
              73.08970099667775,
              73.08970099667775,
              73.08970099667775,
            ],
            [79.06976744186046, 100.0, 100.0, 100.0],
          ],
          [
            [
              56.84647302904564,
              66.39004149377593,
              66.39004149377593,
              66.39004149377593,
            ],
            [58.50622406639005, 100.0, 100.0, 100.0],
          ],
          [
            [
              42.5414364640884,
              55.24861878453039,
              55.24861878453039,
              55.24861878453039,
            ],
            [44.751381215469614, 100.0, 100.0, 100.0],
          ],
        ],
      ],
      [
        [
          [
            [
              92.86675639300135,
              94.6164199192463,
              94.6164199192463,
              94.6164199192463,
            ],
            [97.98115746971736, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.4054054054054,
              92.56756756756756,
              92.56756756756756,
              92.56756756756756,
            ],
            [97.56756756756756, 100.0, 100.0, 100.0],
          ],
          [
            [
              87.92401628222524,
              90.50203527815468,
              90.50203527815468,
              90.50203527815468,
            ],
            [97.15061058344641, 100.0, 100.0, 100.0],
          ],
          [
            [
              86.10354223433242,
              88.8283378746594,
              88.8283378746594,
              88.8283378746594,
            ],
            [96.73024523160763, 100.0, 100.0, 100.0],
          ],
          [
            [
              81.99445983379502,
              83.65650969529086,
              83.65650969529086,
              83.65650969529086,
            ],
            [95.01385041551247, 100.0, 100.0, 100.0],
          ],
          [
            [
              78.30985915492957,
              79.15492957746478,
              79.71830985915493,
              79.71830985915493,
            ],
            [93.2394366197183, 100.0, 100.0, 100.0],
          ],
          [
            [
              74.49856733524355,
              75.35816618911176,
              75.93123209169055,
              75.93123209169055,
            ],
            [91.40401146131805, 100.0, 100.0, 100.0],
          ],
          [
            [
              67.95252225519289,
              68.84272997032642,
              69.43620178041543,
              69.43620178041543,
            ],
            [88.27893175074183, 100.0, 100.0, 100.0],
          ],
          [
            [
              57.82747603833865,
              58.78594249201278,
              59.424920127795524,
              59.424920127795524,
            ],
            [87.38019169329073, 100.0, 100.0, 100.0],
          ],
          [
            [
              35.177865612648226,
              36.56126482213439,
              37.15415019762846,
              37.15415019762846,
            ],
            [84.38735177865613, 100.0, 100.0, 100.0],
          ],
          [
            [
              15.025906735751295,
              16.83937823834197,
              17.616580310880828,
              17.616580310880828,
            ],
            [79.53367875647669, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              92.86675639300135,
              94.6164199192463,
              94.6164199192463,
              94.6164199192463,
            ],
            [97.98115746971736, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.4054054054054,
              92.56756756756756,
              92.56756756756756,
              92.56756756756756,
            ],
            [97.56756756756756, 100.0, 100.0, 100.0],
          ],
          [
            [
              87.92401628222524,
              90.50203527815468,
              90.50203527815468,
              90.50203527815468,
            ],
            [97.15061058344641, 100.0, 100.0, 100.0],
          ],
          [
            [
              86.10354223433242,
              88.8283378746594,
              88.8283378746594,
              88.8283378746594,
            ],
            [96.73024523160763, 100.0, 100.0, 100.0],
          ],
          [
            [
              81.99445983379502,
              83.65650969529086,
              83.65650969529086,
              83.65650969529086,
            ],
            [95.01385041551247, 100.0, 100.0, 100.0],
          ],
          [
            [
              78.30985915492957,
              79.15492957746478,
              79.71830985915493,
              79.71830985915493,
            ],
            [93.2394366197183, 100.0, 100.0, 100.0],
          ],
          [
            [
              74.49856733524355,
              75.35816618911176,
              75.93123209169055,
              75.93123209169055,
            ],
            [91.40401146131805, 100.0, 100.0, 100.0],
          ],
          [
            [
              67.95252225519289,
              68.84272997032642,
              69.43620178041543,
              69.43620178041543,
            ],
            [88.27893175074183, 100.0, 100.0, 100.0],
          ],
          [
            [
              57.82747603833865,
              58.78594249201278,
              59.424920127795524,
              59.424920127795524,
            ],
            [87.38019169329073, 100.0, 100.0, 100.0],
          ],
          [
            [
              35.177865612648226,
              36.56126482213439,
              37.15415019762846,
              37.15415019762846,
            ],
            [84.38735177865613, 100.0, 100.0, 100.0],
          ],
          [
            [
              15.025906735751295,
              16.83937823834197,
              17.616580310880828,
              17.616580310880828,
            ],
            [79.53367875647669, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              92.86675639300135,
              94.6164199192463,
              94.6164199192463,
              94.6164199192463,
            ],
            [97.98115746971736, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.4054054054054,
              92.56756756756756,
              92.56756756756756,
              92.56756756756756,
            ],
            [97.56756756756756, 100.0, 100.0, 100.0],
          ],
          [
            [
              87.92401628222524,
              90.50203527815468,
              90.50203527815468,
              90.50203527815468,
            ],
            [97.15061058344641, 100.0, 100.0, 100.0],
          ],
          [
            [
              86.10354223433242,
              88.8283378746594,
              88.8283378746594,
              88.8283378746594,
            ],
            [96.73024523160763, 100.0, 100.0, 100.0],
          ],
          [
            [
              81.99445983379502,
              83.65650969529086,
              83.65650969529086,
              83.65650969529086,
            ],
            [95.01385041551247, 100.0, 100.0, 100.0],
          ],
          [
            [
              78.30985915492957,
              79.15492957746478,
              79.71830985915493,
              79.71830985915493,
            ],
            [93.2394366197183, 100.0, 100.0, 100.0],
          ],
          [
            [
              74.49856733524355,
              75.35816618911176,
              75.93123209169055,
              75.93123209169055,
            ],
            [91.40401146131805, 100.0, 100.0, 100.0],
          ],
          [
            [
              67.95252225519289,
              68.84272997032642,
              69.43620178041543,
              69.43620178041543,
            ],
            [88.27893175074183, 100.0, 100.0, 100.0],
          ],
          [
            [
              57.82747603833865,
              58.78594249201278,
              59.424920127795524,
              59.424920127795524,
            ],
            [87.38019169329073, 100.0, 100.0, 100.0],
          ],
          [
            [
              35.177865612648226,
              36.56126482213439,
              37.15415019762846,
              37.15415019762846,
            ],
            [84.38735177865613, 100.0, 100.0, 100.0],
          ],
          [
            [
              15.025906735751295,
              16.83937823834197,
              17.616580310880828,
              17.616580310880828,
            ],
            [79.53367875647669, 100.0, 100.0, 100.0],
          ],
        ],
        [
          [
            [
              92.86675639300135,
              94.6164199192463,
              94.6164199192463,
              94.6164199192463,
            ],
            [97.98115746971736, 100.0, 100.0, 100.0],
          ],
          [
            [
              90.4054054054054,
              92.56756756756756,
              92.56756756756756,
              92.56756756756756,
            ],
            [97.56756756756756, 100.0, 100.0, 100.0],
          ],
          [
            [
              87.92401628222524,
              90.50203527815468,
              90.50203527815468,
              90.50203527815468,
            ],
            [97.15061058344641, 100.0, 100.0, 100.0],
          ],
          [
            [
              86.10354223433242,
              88.8283378746594,
              88.8283378746594,
              88.8283378746594,
            ],
            [96.73024523160763, 100.0, 100.0, 100.0],
          ],
          [
            [
              81.99445983379502,
              83.65650969529086,
              83.65650969529086,
              83.65650969529086,
            ],
            [95.01385041551247, 100.0, 100.0, 100.0],
          ],
          [
            [
              78.30985915492957,
              79.15492957746478,
              79.71830985915493,
              79.71830985915493,
            ],
            [93.2394366197183, 100.0, 100.0, 100.0],
          ],
          [
            [
              74.49856733524355,
              75.35816618911176,
              75.93123209169055,
              75.93123209169055,
            ],
            [91.40401146131805, 100.0, 100.0, 100.0],
          ],
          [
            [
              67.95252225519289,
              68.84272997032642,
              69.43620178041543,
              69.43620178041543,
            ],
            [88.27893175074183, 100.0, 100.0, 100.0],
          ],
          [
            [
              57.82747603833865,
              58.78594249201278,
              59.424920127795524,
              59.424920127795524,
            ],
            [87.38019169329073, 100.0, 100.0, 100.0],
          ],
          [
            [
              35.177865612648226,
              36.56126482213439,
              37.15415019762846,
              37.15415019762846,
            ],
            [84.38735177865613, 100.0, 100.0, 100.0],
          ],
          [
            [
              15.025906735751295,
              16.83937823834197,
              17.616580310880828,
              17.616580310880828,
            ],
            [79.53367875647669, 100.0, 100.0, 100.0],
          ],
        ],
      ],
    ],
    dimensions: ['month', 'quantiles', 'duration', 'magw_limit', 'hs_limit'],
    attributes: {
      units: '%',
      description: 'maximum probability of the weather window over the years',
      vartype: 'f8',
    },
  },
  success: true,
};
