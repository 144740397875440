import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import DeleteReportModalComponent from 'components/modals/DeleteReportModal';
import { removeReport } from 'ducks/reports/actions';
import { useModal } from 'hooks/useModal';

/**
 * DeleteReportModal container
 * Contains logic and data for DeleteReportModal component
 * @param id - modal id
 * @param projectId
 * @param reportId
 * @param reportName
 */
const DeleteReportModal = ({ id, projectId, reportId, reportName }) => {
  const dispatch = useDispatch();
  const { onClose } = useModal(id);

  const deleteHandler = useCallback(() => {
    dispatch(removeReport({ reportId, projectId }));
    onClose();
  }, [dispatch, onClose, reportId, projectId]);

  return (
    <DeleteReportModalComponent
      closeModal={onClose}
      deleteReport={deleteHandler}
      reportName={reportName}
    />
  );
};

DeleteReportModal.propTypes = {
  id: PropTypes.string.isRequired,
  projectId: PropTypes.number.isRequired,
  reportId: PropTypes.number.isRequired,
  reportName: PropTypes.string.isRequired,
};

export default memo(DeleteReportModal);
