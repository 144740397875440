import React from 'react';
import PropTypes from 'prop-types';

import ManageGeometriesComponent from 'components/modals/ManageGeometriesModal';
import { useModal } from 'hooks/useModal';

/**
 * ManageGeometriesModal container
 * Contains logic and data for ManageGeometriesModal
 * @param projectId
 * @param id - modal id
 */
const ManageGeometriesModal = ({ id, projectId }) => {
  const { onClose } = useModal(id);

  return <ManageGeometriesComponent onClose={onClose} projectId={projectId} />;
};

ManageGeometriesModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default React.memo(ManageGeometriesModal);
