import { alpha, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  base: {
    color: ({ color, opacity }) => alpha(color, opacity),
  },
  relative: {
    margin: 30,
  },
  center: {
    zIndex: 500,
    position: 'fixed',
    left: '50%',
    top: '50%',
    display: 'block',
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
});
