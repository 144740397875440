import { Validator } from 'helpers/Validator';
import { minLength, regexpMatch, required } from 'validators';
import {
  ONE_TO_NINETY_NINE_NUMBERS_SERIES_REGEXP,
  PLANNING_TABLE_COORDINATES_REGEXP,
} from 'constants/regexp';

const percentilesMessage =
  'Please enter a series of integers between 1 and 99, separated by commas.';
const coordinatesMessage = 'Please check if the coordinates are correct.';
const planningTableCoordinatesValidator = regexpMatch(
  PLANNING_TABLE_COORDINATES_REGEXP,
  coordinatesMessage
);

/**
 * validation rules
 */
export const validationRules = {
  outputDir: new Validator([required()]),
  percentiles: new Validator([
    required(),
    regexpMatch(ONE_TO_NINETY_NINE_NUMBERS_SERIES_REGEXP, percentilesMessage),
  ]),
  envCoords: new Validator([
    required(),
    minLength(2),
    planningTableCoordinatesValidator,
  ]),
  tasksCoords: new Validator([
    required(),
    minLength(2),
    planningTableCoordinatesValidator,
  ]),
};
