import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { floatRound } from 'helpers/common';
import { GRAPH_EMPTY_PLACEHOLDER } from 'constants/graphs';
import { EOL } from 'constants/common';

import { useStyles } from './styles';

/**
 * Component to render table header with law names in cells with span of 3 columns
 * @params {Array<string>} lawNames - array of law names strings
 * @params {string} className
 */
const EVATableHeader = memo(({ lawNames, className }) => {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell className={className} />
        {lawNames.map((law) => (
          <TableCell
            align="center"
            key={`header-${law}`}
            className={className}
            colSpan={3}
          >
            {`${law} ${t('extreme.law')}`}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

/**
 * Component to render table subheader with N (length of lawNames) cells
 * One cell contains 3 other cells with main variable name and units and also 2 other cells for lower and upper
 * If selected law was provided all other other cells will be inactive and colored grey
 * @param {Array<string>} lawNames
 * @param {string} selectedLaw
 * @param {{ name: string, units: string}} mainVariable - data about variable in table
 * @param {Object} classes
 */
const EVATableSubheader = memo(
  ({ lawNames, selectedLaw, mainVariable, classes }) => {
    const { t } = useTranslation();
    const lower = t('extreme.lower');
    const upper = t('extreme.upper');

    const { name, units } = mainVariable;

    return (
      <TableRow className={classes.tableRow}>
        <TableCell className={classes.cell}>{`${t(
          'extreme.returnPeriodText'
        )}${EOL}${t('extreme.returnPeriodUnits')}`}</TableCell>
        {lawNames.map((law) => {
          const isNotSelected = selectedLaw && selectedLaw !== law;
          return (
            <React.Fragment key={`subheader-${law}`}>
              <TableCell
                className={classNames(classes.cell, {
                  [classes.unselectedCell]: isNotSelected,
                })}
              >
                <strong>{`${name}${EOL}[${units}]`}</strong>
              </TableCell>
              <TableCell
                className={classNames(classes.cell, {
                  [classes.unselectedCell]: isNotSelected,
                })}
              >{`${name} ${upper}${EOL}[${units}]`}</TableCell>
              <TableCell
                className={classNames(classes.cell, {
                  [classes.unselectedCell]: isNotSelected,
                })}
              >{`${name} ${lower}${EOL}[${units}]`}</TableCell>
            </React.Fragment>
          );
        })}
      </TableRow>
    );
  }
);

/**
 * Component to render table row with data for each law: value, lower and upper bound for current year of index
 * @param {Array<string>} variants - array with data for every law
 * @param {string} selectedLaw
 * @param {number} index - index of current data
 * @param {Object} classes
 */
const EVATableBodyCells = memo(({ variants, selectedLaw, index, classes }) =>
  variants.map((lawData) => {
    const isNotSelected = selectedLaw && selectedLaw !== lawData.law;
    const [middleValue, upperValue, lowerValue] = [
      lawData.returnValues[index],
      lawData.returnValuesUpper[index],
      lawData.returnValuesLower[index],
    ].map((value) =>
      value === GRAPH_EMPTY_PLACEHOLDER ? '-' : floatRound(value, 2)
    );

    return (
      <React.Fragment key={`data-${lawData.law}`}>
        <TableCell
          className={classNames(classes.cell, {
            [classes.unselectedCell]: isNotSelected,
          })}
        >
          <strong>{middleValue}</strong>
        </TableCell>
        <TableCell
          className={classNames(classes.cell, {
            [classes.unselectedCell]: isNotSelected,
          })}
        >
          {upperValue}
        </TableCell>
        <TableCell
          className={classNames(classes.cell, {
            [classes.unselectedCell]: isNotSelected,
          })}
        >
          {lowerValue}
        </TableCell>
      </React.Fragment>
    );
  })
);

/**
 * Component to render table rows with data for each law: value, lower and upper bound for every year
 * @param {Array<string>} periods - array of years
 * @param {Array<string>} variants - array with data for every law
 * @param {string} selectedLaw
 * @param {Object} classes
 */
const EVATableBody = memo(({ periods, variants, selectedLaw, classes }) =>
  periods.map((year, index) => (
    <TableRow key={`row-${year}`} className={classes.tableRow}>
      <TableCell>{year}</TableCell>
      <EVATableBodyCells
        variants={variants}
        selectedLaw={selectedLaw}
        classes={classes}
        index={index}
      />
    </TableRow>
  ))
);

/**
 * Table that shows extreme estimates table for some period of years
 * @param { Object } preparedData
 * @param { string } selectedLaw
 * @param { string[] } lawNames
 */
const ExtremeValueAnalysisTable = ({ preparedData, selectedLaw, lawNames }) => {
  const classes = useStyles();
  const { mainVariable, periods, variants } = preparedData;

  return (
    <TableContainer component={Paper} className={classes.table}>
      <Table>
        <EVATableHeader lawNames={lawNames} className={classes.headerCell} />
        <TableBody>
          <EVATableSubheader
            lawNames={lawNames}
            selectedLaw={selectedLaw}
            mainVariable={mainVariable}
            classes={classes}
          />
          <EVATableBody
            variants={variants}
            selectedLaw={selectedLaw}
            periods={periods}
            classes={classes}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ExtremeValueAnalysisTable.propTypes = {
  selectedLaw: PropTypes.string,
  lawNames: PropTypes.arrayOf(PropTypes.string),
  preparedData: PropTypes.shape({
    mainVariable: PropTypes.shape({
      name: PropTypes.string,
      longName: PropTypes.string,
      units: PropTypes.string,
    }),
    periods: PropTypes.arrayOf(PropTypes.number),
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        law: PropTypes.string,
        returnValues: PropTypes.arrayOf(PropTypes.number),
        returnValuesUpper: PropTypes.arrayOf(PropTypes.number),
        returnValuesLower: PropTypes.arrayOf(PropTypes.number),
      })
    ),
  }).isRequired,
};

ExtremeValueAnalysisTable.defaultProps = {
  selectedLaw: null,
  lawNames: [],
};

export default memo(ExtremeValueAnalysisTable);
