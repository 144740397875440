import get from 'lodash/get';

export const selectPageContentByUrl = ({ staticPages }, url) =>
  get(staticPages, [url, 'content'], '');

export const selectTemplateNameByUrl = ({ staticPages }, url) =>
  get(staticPages, [url, 'templateName'], '');

export const selectStaticPageTitleByUrl = ({ staticPages }, url) =>
  get(staticPages, [url, 'title'], '');

export const selectStaticPageError = ({ staticPages }) => staticPages.error;
