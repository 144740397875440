export const extremeValuesProposalData = {
  pointName: 'Name of the point',
  mainVariable: {
    name: 'hs',
    longName: 'significant wave height',
    units: 'm',
  },
  associatedVariable: {
    name: 'dp',
    longName: 'weak energy peak direction',
    units: '\u00b0',
  },
  data: [
    {
      threshold: 2.46,
      sampleLength: '23.0 years',
      poissonLambda: '6.00 year-1',
      returnPeriods: [1, 5, 10, 50, 100, 500, 1000],
      empiricalReturnPeriods: [
        0.16667079989551267,
        0.16788737507723173,
        0.16912184107044667,
        0.17037459544874628,
        0.17164604765358768,
        0.17293661944045674,
        0.1742467453453087,
        0.1742467453453087,
        0.17692746450446728,
        0.17829899523706005,
        0.1796919561373496,
        0.18110685342976968,
        0.18254420940937102,
        0.18254420940937102,
        0.18548847085145764,
        0.18548847085145764,
        0.1885292654555799,
        0.19008735855851858,
        0.19167141987983957,
        0.19328210408051047,
        0.19328210408051047,
        0.1965860716716303,
        0.19828077918604092,
        0.2000049598746152,
        0.2000049598746152,
        0.20354487066885618,
        0.20354487066885618,
        0.20721234581604278,
        0.20909609441437044,
        0.21101440720716283,
        0.21296824431093284,
        0.2149586017343995,
        0.21698651307151648,
        0.21905305129124522,
        0.2211593306305841,
        0.2211593306305841,
        0.2254957880939289,
        0.2277284196592153,
        0.2300057038558075,
        0.23232899379374491,
        0.23469969781204844,
        0.23711928232557472,
        0.23958927484979944,
        0.24211126721663945,
        0.24468691899553988,
        0.24731796113527685,
        0.250006199843269,
        0.25275352072066753,
        0.2555618931731194,
        0.2584333751188848,
        0.2584333751188848,
        0.26437437224805455,
        0.2674484928555901,
        0.2705949457127147,
        0.2738163141140565,
        0.2738163141140565,
        0.2738163141140565,
        0.2839576590812438,
        0.2839576590812438,
        0.29114646057697147,
        0.29114646057697147,
        0.29870870630624347,
        0.3026390840207993,
        0.3066742718077433,
        0.31081851872406413,
        0.31507630665179104,
        0.3194523664663993,
        0.3194523664663993,
        0.32857957693686785,
        0.33334159979102534,
        0.33824368214089334,
        0.34329209530717536,
        0.3484934906906174,
        0.35385492900893456,
        0.3593839122746992,
        0.36508841881874204,
        0.3709769417029153,
        0.3770585309111598,
        0.38334283975967914,
        0.38984017602679233,
        0.39656155837208185,
        0.40351877869439906,
        0.4107244711710848,
        0.4181921888287409,
        0.42593648862186567,
        0.43397302614303296,
        0.4423186612611682,
        0.4509915761878578,
        0.460011407711615,
        0.4693993956240969,
        0.4791785496995989,
        0.48937383799107975,
        0.48937383799107975,
        0.5111237863462388,
        0.5227402360359261,
        0.5348969857111802,
        0.5348969857111802,
        0.5609895215995304,
        0.5750142596395187,
        0.589758215014891,
        0.6052781680415986,
        0.6216370374481283,
        0.6216370374481283,
        0.6571591538737357,
        0.6764873642817867,
        0.6969869813812348,
        0.7187678245493984,
        0.7419538834058306,
        0.7666856795193583,
        0.7666856795193583,
        0.8214489423421696,
        0.8518729772437313,
        0.8846373225223364,
        0.92002281542323,
        0.9583570993991978,
        1.000024799373076,
        1.0454804720718522,
        1.095265256456226,
        1.1500285192790374,
        1.2105563360831972,
        1.2778094658655972,
        1.3529747285635734,
        1.4375356490987967,
        1.5333713590387166,
        1.6428978846843392,
        1.7692746450446728,
        1.9167141987983956,
        2.0909609441437045,
        2.3000570385580748,
        2.5556189317311944,
        2.8750712981975934,
        3.2857957693686783,
        3.833428397596791,
        4.6001140771161495,
        5.750142596395187,
        7.666856795193582,
        11.500285192790374,
        23.000570385580748,
      ],
      empiricalReturnValues: [
        2.4820001125335693,
        2.4840002059936523,
        2.4860000610351562,
        2.4880001544952393,
        2.490000009536743,
        2.494000196456909,
        2.496000051498413,
        2.496000051498413,
        2.5,
        2.508000135421753,
        2.5220000743865967,
        2.5260000228881836,
        2.5340001583099365,
        2.5340001583099365,
        2.5360000133514404,
        2.5360000133514404,
        2.5480000972747803,
        2.552000045776367,
        2.55400013923645,
        2.556000232696533,
        2.556000232696533,
        2.562000036239624,
        2.564000129699707,
        2.56600022315979,
        2.56600022315979,
        2.570000171661377,
        2.570000171661377,
        2.572000026702881,
        2.578000068664551,
        2.580000162124634,
        2.5820000171661377,
        2.5840001106262207,
        2.5880000591278076,
        2.5960001945495605,
        2.6000001430511475,
        2.6000001430511475,
        2.6080000400543213,
        2.6120002269744873,
        2.616000175476074,
        2.622000217437744,
        2.626000165939331,
        2.630000114440918,
        2.632000207901001,
        2.6540000438690186,
        2.6680002212524414,
        2.6700000762939453,
        2.682000160217285,
        2.684000015258789,
        2.694000244140625,
        2.696000099182129,
        2.696000099182129,
        2.698000192642212,
        2.700000047683716,
        2.702000141143799,
        2.7060000896453857,
        2.7060000896453857,
        2.7060000896453857,
        2.7180001735687256,
        2.7180001735687256,
        2.7240002155303955,
        2.7240002155303955,
        2.7280001640319824,
        2.7320001125335693,
        2.7360000610351562,
        2.7380001544952393,
        2.744000196456909,
        2.752000093460083,
        2.752000093460083,
        2.758000135421753,
        2.760000228881836,
        2.76200008392334,
        2.7680001258850098,
        2.7740001678466797,
        2.7760000228881836,
        2.7900002002716064,
        2.7920000553131104,
        2.7940001487731934,
        2.806000232696533,
        2.81000018119812,
        2.8320000171661377,
        2.8340001106262207,
        2.8420002460479736,
        2.8520002365112305,
        2.868000030517578,
        2.876000165939331,
        2.880000114440918,
        2.882000207901001,
        2.888000249862671,
        2.892000198364258,
        2.8980002403259277,
        2.9080002307891846,
        2.9240000247955322,
        2.9240000247955322,
        2.930000066757202,
        2.934000253677368,
        2.948000192642212,
        2.948000192642212,
        2.950000047683716,
        2.954000234603882,
        2.9600000381469727,
        2.9660000801086426,
        2.9760000705718994,
        2.9760000705718994,
        2.992000102996826,
        2.996000051498413,
        3.004000186920166,
        3.00600004196167,
        3.0300002098083496,
        3.0340001583099365,
        3.0340001583099365,
        3.0380001068115234,
        3.0440001487731934,
        3.062000036239624,
        3.080000162124634,
        3.0980000495910645,
        3.128000259399414,
        3.1440000534057617,
        3.1680002212524414,
        3.194000244140625,
        3.196000099182129,
        3.2240002155303955,
        3.2300002574920654,
        3.2340002059936523,
        3.2400002479553223,
        3.2900002002716064,
        3.312000274658203,
        3.324000120162964,
        3.366000175476074,
        3.380000114440918,
        3.388000249862671,
        3.4020001888275146,
        3.5340001583099365,
        3.5440001487731934,
        3.57200026512146,
        3.578000068664551,
        3.832000255584717,
        4.058000087738037,
        4.386000156402588,
      ],
      empiricalAssociatedReturnValues: [
        180.4767691334416,
        179.24531120446204,
        166.90390402631274,
        169.64285328459565,
        163.01641797503777,
        156.67848775267265,
        173.166055524458,
        170.3085383933044,
        170.52390613984164,
        156.4681571905301,
        164.80997129754286,
        165.89681741074583,
        166.28664528945365,
        168.90649698940035,
        174.7180777304401,
        178.41050534601828,
        152.59461618457428,
        173.26810551244841,
        184.8486783457207,
        170.59164722021652,
        184.89852983158892,
        188.33007304173384,
        169.2096264571573,
        176.60650129543956,
        174.51020269940588,
        178.1195868938198,
        177.01307031529444,
        172.41584767955467,
        196.70022424046982,
        165.90473361266115,
        180.64715285454764,
        171.66458412186853,
        167.3203679675533,
        170.7251795608805,
        162.5678598979075,
        171.0120015751139,
        148.4071115193051,
        184.67947006130194,
        166.36008540697867,
        181.59005022785243,
        179.0292855091107,
        176.17610219096318,
        153.08313824627686,
        175.95771245189817,
        167.9310785042382,
        166.43116440847646,
        178.88610195125582,
        179.09474083408864,
        189.87024330780918,
        181.82775490371603,
        167.4540926137625,
        188.53022120483038,
        179.67279592156234,
        169.34951754752547,
        169.47232266047212,
        172.74649818153523,
        184.67692134321732,
        158.89240115139236,
        174.3535734480908,
        173.87918318535665,
        164.167738092766,
        162.71249499197873,
        177.27751117448776,
        187.2993422699016,
        164.0021646546029,
        172.765017608426,
        174.86809417154774,
        176.53519028879367,
        180.2463150505224,
        185.0102109751403,
        168.0997539686771,
        161.23414693616968,
        177.34149851932142,
        183.63197302455092,
        183.17584254732373,
        166.83435216342153,
        176.03823366287457,
        197.07831906672675,
        166.12895830135423,
        170.15666038735384,
        169.24056444022622,
        173.3233704136436,
        176.19956126990112,
        183.04407621270528,
        185.1321818091673,
        181.816117996512,
        174.17346328777091,
        160.25014168913668,
        184.05031078471762,
        188.43017617728052,
        177.85705096961726,
        176.46357746143718,
        169.75942743369342,
        186.36689346597885,
        184.9604035127527,
        177.5289707007276,
        170.6049967923972,
        169.315837887668,
        171.7293000370086,
        182.86318236548482,
        168.86537870836935,
        170.52893527001905,
        166.98525027222516,
        188.6327363262272,
        166.76592632698544,
        172.36704630639022,
        173.98065194951891,
        179.21303495391388,
        167.7722342348688,
        151.56865033880095,
        171.3410717350442,
        175.54099308611927,
        173.06137580276666,
        163.06930580719109,
        178.05074100277105,
        167.91885795274447,
        172.6604783916277,
        171.68363360944562,
        165.96641801412275,
        194.80236961135827,
        184.10971765585478,
        168.1678396778178,
        184.50221024318347,
        179.31405474844834,
        183.29150467295096,
        191.6337665517126,
        180.14362795681194,
        155.1692018444192,
        169.57951957111962,
        180.64438380015432,
        147.22503273272073,
        191.4369456329416,
        153.82486476252677,
        182.34956495706095,
        162.0513644270603,
        180.6436126349746,
        161.2466780722684,
        180.67703118652415,
      ],
      empiricalReturnTimes: [
        2.4820001125335693,
        2.4840002059936523,
        2.4860000610351562,
        2.4880001544952393,
        2.490000009536743,
        2.494000196456909,
        2.496000051498413,
        2.496000051498413,
        2.5,
        2.508000135421753,
        2.5220000743865967,
        2.5260000228881836,
        2.5340001583099365,
        2.5340001583099365,
        2.5360000133514404,
        2.5360000133514404,
        2.5480000972747803,
        2.552000045776367,
        2.55400013923645,
        2.556000232696533,
        2.556000232696533,
        2.562000036239624,
        2.564000129699707,
        2.56600022315979,
        2.56600022315979,
        2.570000171661377,
        2.570000171661377,
        2.572000026702881,
        2.578000068664551,
        2.580000162124634,
        2.5820000171661377,
        2.5840001106262207,
        2.5880000591278076,
        2.5960001945495605,
        2.6000001430511475,
        2.6000001430511475,
        2.6080000400543213,
        2.6120002269744873,
        2.616000175476074,
        2.622000217437744,
        2.626000165939331,
        2.630000114440918,
        2.632000207901001,
        2.6540000438690186,
        2.6680002212524414,
        2.6700000762939453,
        2.682000160217285,
        2.684000015258789,
        2.694000244140625,
        2.696000099182129,
        2.696000099182129,
        2.698000192642212,
        2.700000047683716,
        2.702000141143799,
        2.7060000896453857,
        2.7060000896453857,
        2.7060000896453857,
        2.7180001735687256,
        2.7180001735687256,
        2.7240002155303955,
        2.7240002155303955,
        2.7280001640319824,
        2.7320001125335693,
        2.7360000610351562,
        2.7380001544952393,
        2.744000196456909,
        2.752000093460083,
        2.752000093460083,
        2.758000135421753,
        2.760000228881836,
        2.76200008392334,
        2.7680001258850098,
        2.7740001678466797,
        2.7760000228881836,
        2.7900002002716064,
        2.7920000553131104,
        2.7940001487731934,
        2.806000232696533,
        2.81000018119812,
        2.8320000171661377,
        2.8340001106262207,
        2.8420002460479736,
        2.8520002365112305,
        2.868000030517578,
        2.876000165939331,
        2.880000114440918,
        2.882000207901001,
        2.888000249862671,
        2.892000198364258,
        2.8980002403259277,
        2.9080002307891846,
        2.9240000247955322,
        2.9240000247955322,
        2.930000066757202,
        2.934000253677368,
        2.948000192642212,
        2.948000192642212,
        2.950000047683716,
        2.954000234603882,
        2.9600000381469727,
        2.9660000801086426,
        2.9760000705718994,
        2.9760000705718994,
        2.992000102996826,
        2.996000051498413,
        3.004000186920166,
        3.00600004196167,
        3.0300002098083496,
        3.0340001583099365,
        3.0340001583099365,
        3.0380001068115234,
        3.0440001487731934,
        3.062000036239624,
        3.080000162124634,
        3.0980000495910645,
        3.128000259399414,
        3.1440000534057617,
        3.1680002212524414,
        3.194000244140625,
        3.196000099182129,
        3.2240002155303955,
        3.2300002574920654,
        3.2340002059936523,
        3.2400002479553223,
        3.2900002002716064,
        3.312000274658203,
        3.324000120162964,
        3.366000175476074,
        3.380000114440918,
        3.388000249862671,
        3.4020001888275146,
        3.5340001583099365,
        3.5440001487731934,
        3.57200026512146,
        3.578000068664551,
        3.832000255584717,
        4.058000087738037,
        4.386000156402588,
      ],
      empiricalOutliersIndices: [],
      distributionBins: [
        2.5772001147270203,
        2.672400116920471,
        2.767600119113922,
        2.862800121307373,
        2.958000123500824,
        3.053200125694275,
        3.148400127887726,
        3.2436001300811768,
        3.3388001322746277,
        3.4340001344680786,
        3.5292001366615295,
        3.6244001388549805,
        3.7196001410484314,
        3.8148001432418823,
        3.9100001454353333,
        4.005200147628784,
        4.100400149822235,
        4.195600152015686,
        4.290800154209137,
        4.386000156402588,
      ],
      empiricalDistributionValues: [
        2.1005221166144454,
        1.6210398198365563,
        1.2510080597156914,
        0.9654427647751519,
        0.7450629313039894,
        0.5749887946310087,
        0.4437371664331654,
        0.3424461045375577,
        0.2642765659130577,
        0.20395064322636738,
        0.15739520728498227,
        0.12146689456030002,
        0.09373987129994908,
        0.07234204433347713,
        0.05582865973435162,
        0.043084754881493714,
        0.03324987760822439,
        0.02565998957178176,
        0.01980263123317735,
        0.015282321244138437,
      ],
      empiricalQuantiles: [
        2.4820001007535364,
        2.4846721125310403,
        2.487363699660868,
        2.4900751510820145,
        2.4928067621783287,
        2.495558834971621,
        2.4983316783220606,
        2.4983316783220606,
        2.5039409475829126,
        2.5067780273178153,
        2.509637185716226,
        2.512518769115407,
        2.5154231320663203,
        2.5154231320663203,
        2.521301657477003,
        2.521301657477003,
        2.527275772845295,
        2.5302996582280506,
        2.5333486383821695,
        2.5364231333111174,
        2.5364231333111174,
        2.5426504010355977,
        2.545804068467815,
        2.5489850407196353,
        2.5489850407196353,
        2.5554308200853795,
        2.5554308200853795,
        2.561991708718142,
        2.5653166182881595,
        2.568671892749306,
        2.572058091834855,
        2.575475790898989,
        2.57892558150352,
        2.582408072032419,
        2.585923888335753,
        2.585923888335753,
        2.593058093079652,
        2.596677826792815,
        2.600333578348269,
        2.604026071740845,
        2.6077560530167254,
        2.6115242911781507,
        2.6153315791350105,
        2.6191787347062547,
        2.623066601674294,
        2.6269960508957872,
        2.630967981472476,
        2.634983321986003,
        2.639043031800954,
        2.6431481024407053,
        2.6431481024407053,
        2.6514984618865993,
        2.6557459080367547,
        2.660043033045751,
        2.66439101278526,
        2.66439101278526,
        2.66439101278526,
        2.6777524852536394,
        2.6777524852536394,
        2.68693796368391,
        2.68693796368391,
        2.69635899269125,
        2.7011616754590952,
        2.706027971767053,
        2.7109595894370813,
        2.7159583060023746,
        2.721025972553795,
        2.721025972553795,
        2.731375952751359,
        2.73666237489126,
        2.742025973798592,
        2.7474690363160525,
        2.752993952459785,
        2.7586032217206364,
        2.76429945985395,
        2.7700854062040445,
        2.7759639316147267,
        2.781938046983019,
        2.7880109125198937,
        2.794185847789332,
        2.8004663426055387,
        2.8068560688778796,
        2.8133588935041995,
        2.8199788924258837,
        2.826720365972579,
        2.8335878556412437,
        2.840586162473477,
        2.847720367217376,
        2.8549958524859926,
        2.862418327154449,
        2.8699938532727343,
        2.877728875812018,
        2.877728875812018,
        2.8937053059386777,
        2.9019618331787242,
        2.9104081821744785,
        2.9104081821744785,
        2.9279067273731307,
        2.936978793238833,
        2.9462805558922613,
        2.9558239495968195,
        2.965621863574805,
        2.965621863574805,
        2.986038226889083,
        2.9966882479363157,
        3.0076562265975086,
        3.018961733991674,
        3.0306262057524505,
        3.0426731866576175,
        3.0426731866576175,
        3.0680211676419233,
        3.081382640110303,
        3.095248436611201,
        3.1096581266237164,
        3.124656127410458,
        3.140292529747924,
        3.156624107316448,
        3.1737155610607077,
        3.1916410673765574,
        3.2104862237345433,
        3.2303505208292425,
        3.2513505220740395,
        3.273624008129398,
        3.2973354607953413,
        3.3226834417796476,
        3.349910710748925,
        3.3793184015481823,
        3.411286381454172,
        3.446303341514281,
        3.4850127949669667,
        3.5282862822671213,
        3.577345715917371,
        3.6339806756859065,
        3.7009656156520054,
        3.782948556404845,
        3.8886429498236303,
        4.037610830542571,
        4.292273104680292,
      ],
      variants: [
        {
          law: 'GPD',
          parameters: [
            { name: 'Scale', value: '0.37 m' },
            { name: 'Location', value: '2.48 m' },
            {
              name: 'RMSE',
              value: '0.02 m',
            },
          ],
          returnValues: [
            3.29028,
            3.88159,
            4.13625,
            4.72756,
            4.982220000000001,
            5.573530000000001,
            5.82819,
          ],
          returnValuesUpper: [
            3.4902800000000003,
            4.08159,
            4.33625,
            4.92756,
            5.18222,
            5.77353,
            6.0281899999999995,
          ],
          returnValuesLower: [
            2.9902800000000003,
            3.5815900000000003,
            3.83625,
            4.42756,
            4.68222,
            5.27353,
            5.5281899999999995,
          ],
          confidence: 90,
          theoricalReturnValues: [
            2.5240235582573987,
            2.5326135601711184,
            2.5219261471756167,
            2.544703615772091,
            2.5360902013203805,
            2.538239081316425,
            2.5504138459050285,
            2.562067474056442,
            2.536574889578622,
            2.546251779973164,
            2.562427600101396,
            2.576855435525499,
            2.5895543830841095,
            2.5801422944359063,
            2.5767445414750023,
            2.586676416884534,
            2.59188507090131,
            2.6205014256410197,
            2.6046738232888296,
            2.5962468552147966,
            2.611404309588792,
            2.6007333763686513,
            2.615353848847922,
            2.6051517676369533,
            2.6246087046324185,
            2.631255495169742,
            2.631979565949178,
            2.6155649800787777,
            2.636530157281171,
            2.6382184658819727,
            2.627726592894979,
            2.6407229123853417,
            2.6197071486594643,
            2.6335067354023094,
            2.6602137507143624,
            2.6433790715371335,
            2.653811671542566,
            2.667035126942112,
            2.685230926558074,
            2.660643447104373,
            2.6889125991745004,
            2.653499571917099,
            2.682694815619409,
            2.7124215697596523,
            2.7005469129283615,
            2.7270708617238246,
            2.7420763251032496,
            2.7271611749144546,
            2.7276305134830143,
            2.7705958798705432,
            2.7480296567554983,
            2.754915781159218,
            2.7445911914121357,
            2.7538287934568664,
            2.7611544146227125,
            2.738396986293289,
            2.754765414939729,
            2.7470292649378063,
            2.763722186768333,
            2.7654998984715595,
            2.757676638663095,
            2.7842473968648442,
            2.7822260011944686,
            2.7486383235400904,
            2.784694245349681,
            2.7857608085877716,
            2.8080449907833054,
            2.7932491037038076,
            2.827851123776895,
            2.8045436216830946,
            2.8272814833025466,
            2.8152516593751504,
            2.8251205952841403,
            2.834448385194773,
            2.8352399696050665,
            2.8316456136019803,
            2.844467312421314,
            2.856237072745318,
            2.8720780183885566,
            2.8848462213129547,
            2.8738031577010568,
            2.885823292442887,
            2.9069223432382936,
            2.919190106969658,
            2.9346701308113596,
            2.9235424925810363,
            2.9270070517949565,
            2.9396629409443604,
            2.941033701038277,
            2.953971127461192,
            2.9539488118919084,
            2.9785230100791265,
            2.95967712235123,
            2.986022631420584,
            2.9944559155984485,
            2.99680323405052,
            2.994797581675402,
            2.9944727424697892,
            2.99190399528286,
            3.005616583604836,
            3.020693136774459,
            3.027230428461472,
            3.0376011157940566,
            3.0325326328366202,
            3.0571554857715086,
            3.053184467405051,
            3.04871846545202,
            3.0844217342433597,
            3.0640951148545774,
            3.083093563518994,
            3.076159529382325,
            3.091054507688171,
            3.1003833698931955,
            3.1335360162787476,
            3.1473404815110375,
            3.161003251728848,
            3.190186824826407,
            3.212349896081123,
            3.2406600883411394,
            3.252883117738496,
            3.273886389865745,
            3.288597563017519,
            3.278580465840913,
            3.297382636709956,
            3.33349269594733,
            3.3835283118685195,
            3.3772468553974218,
            3.4047909490120243,
            3.438070182449808,
            3.4506238432606255,
            3.4563932551124767,
            3.601765508335433,
            3.577643815145054,
            3.6244693824232863,
            3.6421755416091024,
            3.8837099970586477,
            4.129808713163356,
            4.427771150072725,
          ],
          theoricalDistributionValues: [
            1.5111715287707288,
            1.8185084612605058,
            1.3440477846394658,
            1.9050176401697758,
            1.1138266083557096,
            1.0965414931529236,
            0.07885559157728145,
            -0.43302063135404284,
            -0.6006455658566481,
            0.27020613600650695,
            -0.04081489301348326,
            -0.3000433598396266,
            -1.0040502324824703,
            0.5132996894083133,
            0.5101808821286019,
            0.20709033140104882,
            0.3133026611332913,
            0.6202913488703661,
            -0.2628260934832209,
            -0.20576895545698776,
          ],
          theoricalQuantiles: [
            2.5893063356116803,
            2.471048706412831,
            2.469109528887938,
            2.4179877026353136,
            2.5047553121584745,
            2.5280971997162345,
            2.521434376058102,
            2.4121533628446,
            2.5697132223318504,
            2.511341197773924,
            2.484894903559749,
            2.507735413361751,
            2.5349522361508328,
            2.618416278650023,
            2.4782187547111003,
            2.466198228972749,
            2.4883447281190274,
            2.545019582176009,
            2.4552650905410545,
            2.49568608850898,
            2.4908368047170657,
            2.552476853871226,
            2.6049243595127525,
            2.5770338718794283,
            2.590147002377899,
            2.52925732141002,
            2.5917848417485176,
            2.5268864217080442,
            2.5463695090498564,
            2.624101293175858,
            2.4493878202694273,
            2.5542196200848184,
            2.515821585098222,
            2.5234159192704784,
            2.557434408130544,
            2.6560828565133097,
            2.597920705080651,
            2.666118659840674,
            2.5898524883452843,
            2.620589161029844,
            2.6995189565711577,
            2.620651694732158,
            2.6937565245037915,
            2.635679340068698,
            2.6576171812306297,
            2.5945374373389583,
            2.7052106757723178,
            2.6575234852584213,
            2.6670596828562254,
            2.60919605636229,
            2.6549897935967093,
            2.6414242282745715,
            2.7511511236826087,
            2.6848399697625216,
            2.615552478451043,
            2.7347935619365487,
            2.607336548831475,
            2.63951626483208,
            2.7102982550894468,
            2.7406086657805595,
            2.758403936955656,
            2.7183678022104125,
            2.6552875701973866,
            2.629060575574226,
            2.7904595546209032,
            2.646916339271783,
            2.838863230142968,
            2.624090057311924,
            2.771352857760912,
            2.7541992865500293,
            2.776405069950234,
            2.6995034393703667,
            2.7810890517769615,
            2.6940512588588126,
            2.8201880906754506,
            2.720720400454979,
            2.737621204015937,
            2.788584925359651,
            2.688193131431206,
            2.822518324453009,
            2.8515356129108818,
            2.772415552466888,
            2.8893735073821767,
            2.877002165304329,
            2.781407501267566,
            2.781011192587238,
            2.8638188498246575,
            2.808912965670277,
            2.8834498543526847,
            2.8873838815935238,
            2.907891321819069,
            2.881476077521978,
            2.9507652286331836,
            2.9002340677447624,
            2.941221672248215,
            2.8927818894418755,
            2.9378531241879577,
            2.939500541884402,
            3.0049337850448303,
            2.938433731002413,
            2.948186456240605,
            2.9070686079061314,
            2.930877695791669,
            3.0336107457956265,
            2.9870881829442437,
            3.037188863523928,
            3.10412125255399,
            3.027778635242672,
            2.925173689092339,
            3.065667543522687,
            3.043619771976036,
            3.0064842433465895,
            3.072235837547957,
            3.0828328204344237,
            3.0766962185618763,
            3.0865715802033713,
            3.1596402426127197,
            3.117263630801033,
            3.204066763759237,
            3.213335383328352,
            3.274851564799877,
            3.2347400248293035,
            3.2609124984801117,
            3.321739388621389,
            3.389385721894585,
            3.345316099770652,
            3.412823111928391,
            3.4583663484536085,
            3.4452559869019366,
            3.529645032200757,
            3.5178057670738276,
            3.571759671989363,
            3.5671217219515716,
            3.6611961627824288,
            3.8028150684145396,
            3.955300212627877,
            4.12439697450639,
            4.279500162127114,
          ],
        },
        {
          law: 'Exponential',
          parameters: [
            { name: 'Scale', value: '0.35 m' },
            { name: 'Location', value: '2.54 m' },
            {
              name: 'RMSE',
              value: '0.023 m',
            },
          ],
          returnValues: [
            3.2402800000000003,
            3.8315900000000003,
            4.08625,
            4.67756,
            4.93222,
            5.52353,
            5.7781899999999995,
          ],
          returnValuesUpper: [
            3.44028,
            4.0315900000000005,
            4.28625,
            4.87756,
            5.13222,
            5.72353,
            5.97819,
          ],
          returnValuesLower: [
            3.04028,
            3.63159,
            3.88625,
            4.47756,
            4.732220000000001,
            5.323530000000001,
            5.57819,
          ],
          confidence: 90,
          theoricalReturnValues: [
            2.5318608829947933,
            2.526962173950033,
            2.5483133499901944,
            2.5527919043247476,
            2.541023666588901,
            2.541974484272877,
            2.5465483260653285,
            2.5521877439040197,
            2.5494824691504046,
            2.5789360571866684,
            2.5753707523344502,
            2.5602704346740786,
            2.582767311092946,
            2.572459488937603,
            2.5948133639526976,
            2.5826894380189573,
            2.5887174979464467,
            2.619274877789638,
            2.599141930688412,
            2.6143162884836535,
            2.609267888423128,
            2.61265446266033,
            2.6114812494089428,
            2.608987045390926,
            2.6142210799167462,
            2.616855142993093,
            2.608017851509479,
            2.6251463873033667,
            2.6251750588067098,
            2.6270150901289804,
            2.6254328443820074,
            2.637761657529387,
            2.631062626772176,
            2.6538169067112687,
            2.6571067640754045,
            2.6321912446588533,
            2.6689584730152487,
            2.66314096661644,
            2.6622799036010205,
            2.675678327079495,
            2.672727704871501,
            2.6623360932557425,
            2.675233414733402,
            2.6970617960039536,
            2.723805395480031,
            2.723835390429013,
            2.7489544005359905,
            2.724030094069154,
            2.7406373046286623,
            2.742552275691081,
            2.733567742456873,
            2.7558186186579583,
            2.7617351587214056,
            2.76081311371224,
            2.743568049412029,
            2.7710440581778117,
            2.746667452885196,
            2.795472601960177,
            2.755835842123942,
            2.7534555034620567,
            2.7909388778802007,
            2.7652712255705736,
            2.776350389639701,
            2.791054712169634,
            2.793656807241814,
            2.78783557354648,
            2.790455085395993,
            2.8028833280151417,
            2.809018720814906,
            2.8120293482319165,
            2.812316824836611,
            2.8139811296482478,
            2.8359671290112365,
            2.8462419450270406,
            2.8351047905969056,
            2.8390531346018086,
            2.848955342861873,
            2.8698446057516174,
            2.8638038261156566,
            2.8886888512789386,
            2.891909410235304,
            2.868684100027442,
            2.8971798911147015,
            2.9128375467072813,
            2.9209924209505083,
            2.955093334107226,
            2.9337187092051584,
            2.92911162151534,
            2.9489531441147037,
            2.953716092497892,
            2.9575211398057157,
            2.9781609300452794,
            2.982116914941308,
            2.9887289136223893,
            2.9800925444631683,
            3.0065540422600763,
            2.995494772345854,
            2.989751611585908,
            3.012189115288561,
            3.008622580931608,
            3.0101346320153795,
            3.012449775473676,
            3.0136495598922295,
            3.052532576557718,
            3.0500252893483952,
            3.0560123655669957,
            3.0489782827891365,
            3.0860157740753635,
            3.0783319227250323,
            3.104914393299952,
            3.069851793203046,
            3.102587797103727,
            3.1055306647310847,
            3.1270459754528264,
            3.1376691442011113,
            3.18407626175668,
            3.1913630233022494,
            3.2220546335360094,
            3.2407698218394096,
            3.2553229730936026,
            3.2702821264308284,
            3.2847174129565757,
            3.271705203251695,
            3.2984697404214574,
            3.323430152149225,
            3.3747154060825952,
            3.3617236951907334,
            3.4295778521009788,
            3.4338288254643103,
            3.435834447977693,
            3.454824012413065,
            3.5735037624458337,
            3.6141422987679697,
            3.6093557561548963,
            3.6278279203380213,
            3.889265843534277,
            4.104692876748861,
            4.453433044983543,
          ],
          theoricalDistributionValues: [
            1.0312867072031036,
            1.2048980562799918,
            1.5527763884872448,
            1.3560539432092331,
            1.1025676798598827,
            0.6882826122730813,
            1.1783910222704472,
            -0.05431045734754403,
            -0.26254416170317685,
            -0.2819963433122221,
            0.14895775983466786,
            0.5580850185880957,
            -0.47568993079948463,
            0.03108835741776294,
            -0.2314670905321403,
            0.3258195028886623,
            0.34483637547353135,
            0.3357738486003769,
            -0.5109060966176694,
            -0.16676447623921659,
          ],
          theoricalQuantiles: [
            2.4845258011392732,
            2.4770003377012735,
            2.4955892792361896,
            2.5232023828545684,
            2.5001184720249956,
            2.464185045734696,
            2.5254313827118833,
            2.459935528871528,
            2.566483064906924,
            2.5405195213209035,
            2.4654364666455733,
            2.4774319511052543,
            2.5388504548568416,
            2.5321110395428565,
            2.575620453627055,
            2.540333903469622,
            2.5397630724008056,
            2.443024067877749,
            2.57435042415689,
            2.499100795133908,
            2.530012595631369,
            2.5901190226646946,
            2.5510028757144454,
            2.530804693196891,
            2.5311681441252496,
            2.5615169133220883,
            2.5417211221496836,
            2.601805546557642,
            2.514637795252446,
            2.58089675992794,
            2.4932912842095645,
            2.457318157768667,
            2.6996437339218224,
            2.590122791368748,
            2.569505188605792,
            2.523576966152764,
            2.589746736409519,
            2.5848623277928486,
            2.609994373483547,
            2.6080518666236507,
            2.7013025609839754,
            2.5996745801572274,
            2.655487590931281,
            2.6580168539475664,
            2.718529566770081,
            2.6698577209202328,
            2.7182350681417913,
            2.6003249005930194,
            2.6389809251274627,
            2.636129575702718,
            2.6630562360640826,
            2.6191117501462204,
            2.560342045112254,
            2.5698881697022107,
            2.6446472351238937,
            2.6465403737154847,
            2.6635959720209206,
            2.768479149723106,
            2.721142490077293,
            2.6393102963254043,
            2.624007919654784,
            2.7005230051008073,
            2.7024770355010372,
            2.733257668194174,
            2.722894553380642,
            2.7475626809385147,
            2.720951374227965,
            2.72421668603819,
            2.7809487819997467,
            2.6719438170257876,
            2.771447349446819,
            2.6941865358645196,
            2.7592835307950763,
            2.753550148598115,
            2.7757557884227557,
            2.762517620736085,
            2.8224541086561588,
            2.6934799753642076,
            2.751174127049607,
            2.7450095204099174,
            2.7617306754742277,
            2.8365195331929383,
            2.7547364386552817,
            2.8183402094470997,
            2.832058796822308,
            2.8657003486726365,
            2.8301119866447286,
            2.890689669667757,
            2.8084634517712668,
            2.981623413240169,
            2.833744431895384,
            3.0188879933100696,
            2.794066781784724,
            2.836815278758959,
            2.924430555007035,
            2.9382297335312746,
            2.9388928681657047,
            2.8745821110403376,
            2.965358598735352,
            3.000253209384599,
            3.070406749823099,
            2.9612598988784224,
            2.978988088080992,
            2.982433758973344,
            2.9774308228874076,
            2.9917731994094257,
            3.023354535337471,
            3.1358574270497117,
            3.061759358747022,
            3.064694763257836,
            3.081003465994448,
            3.1251834036517185,
            3.036540564060223,
            3.0967359194358886,
            3.146843575270116,
            3.1598396494917056,
            3.1292298586346603,
            3.2556957263760506,
            3.1738804952326363,
            3.2040650815917124,
            3.202230475104696,
            3.234858834948898,
            3.211134204311257,
            3.442774450553571,
            3.3290401597792942,
            3.3194497140355765,
            3.3969256555334875,
            3.34895613393442,
            3.4995063999319425,
            3.5035208959258117,
            3.5669392478814546,
            3.513381621585913,
            3.6350959672455985,
            3.7119714807605027,
            3.6822839935150573,
            3.8789743119517346,
            4.069431077613801,
            4.115753026348083,
          ],
        },
        {
          law: 'Weibull',
          parameters: [
            { name: 'Scale', value: '0.28 m' },
            { name: 'Location', value: '2.8 m' },
            {
              name: 'RMSE',
              value: '0.11 m',
            },
          ],
          returnValues: [
            3.14028,
            3.73159,
            3.98625,
            4.57756,
            4.83222,
            5.42353,
            5.67819,
          ],
          returnValuesUpper: [
            3.64028,
            4.231590000000001,
            4.48625,
            5.07756,
            5.33222,
            5.92353,
            6.17819,
          ],
          returnValuesLower: [
            2.64028,
            3.23159,
            3.48625,
            4.07756,
            4.33222,
            4.92353,
            5.17819,
          ],
          confidence: 90,
          theoricalReturnValues: [
            2.5335394745986033,
            2.546261975796707,
            2.531450640875321,
            2.5385355127913396,
            2.525976063903404,
            2.5490478074540617,
            2.544317287150068,
            2.54676188219599,
            2.5551954258785274,
            2.5341316489185575,
            2.5782178369576703,
            2.5614488433859663,
            2.564222201059523,
            2.588654194471847,
            2.5718490199639645,
            2.578630346691492,
            2.594945194490057,
            2.6040891831044917,
            2.588101833408057,
            2.6090793446877854,
            2.6221001117513416,
            2.6076124331805213,
            2.6091064119308545,
            2.610002142285215,
            2.6113299502393494,
            2.630714816119685,
            2.607461795287088,
            2.6226106657906847,
            2.6174786720778345,
            2.6149983653969873,
            2.633172429566908,
            2.6287768930462145,
            2.623604028709945,
            2.6495168864910195,
            2.6563116749259734,
            2.643111879269433,
            2.665804218155079,
            2.6644442537108817,
            2.6591931664303603,
            2.6744153572703615,
            2.677283770622655,
            2.672882948092679,
            2.682531278901271,
            2.698189852360846,
            2.743060165535029,
            2.7309767911353275,
            2.7360387750790167,
            2.724756256187242,
            2.7502842371726244,
            2.748730452898181,
            2.749994568667116,
            2.7402427306722767,
            2.7458724740736984,
            2.7459168666532396,
            2.7682781516873463,
            2.7671261060559575,
            2.745377848852295,
            2.7735536934209724,
            2.7609172243555475,
            2.774998582263588,
            2.778952181067678,
            2.7735318602051584,
            2.782409998060102,
            2.7894387218091032,
            2.782439165341711,
            2.796046148969483,
            2.799355784235176,
            2.7872065361946596,
            2.8061029467349594,
            2.7987783161207256,
            2.82625803165864,
            2.821352075645346,
            2.8387083162700866,
            2.812533809966482,
            2.849209770231367,
            2.8468110515210103,
            2.849315606745701,
            2.8582408684809706,
            2.8635989481573554,
            2.8761510317171544,
            2.8717994018212223,
            2.891783985988884,
            2.8871517731750633,
            2.8995891549766384,
            2.9314997278902797,
            2.92640060878737,
            2.940589125857095,
            2.9242461053302065,
            2.9335489265408885,
            2.946446553616825,
            2.968997353299956,
            2.9735755945336217,
            2.975277140274372,
            2.972047940719249,
            3.0064636672790916,
            2.9923628259857016,
            3.0101916559895083,
            2.992946686356761,
            2.997696617603728,
            3.0005859413975284,
            3.0197013256232488,
            3.004990942622132,
            3.027328188738736,
            3.0228006951751016,
            3.0453432282259043,
            3.0682969731392102,
            3.0593967018280663,
            3.078248102537504,
            3.084142299897105,
            3.088698926300384,
            3.0711881453099172,
            3.089145903703708,
            3.10769076225739,
            3.1195739026282916,
            3.1420293145996916,
            3.1911004825927187,
            3.1940258581050975,
            3.2113046280764164,
            3.254477680766803,
            3.2637445336880484,
            3.2504045581811924,
            3.2852495680581235,
            3.2904167634046226,
            3.278730992322554,
            3.3543055963472725,
            3.37141573039892,
            3.383426724943819,
            3.41444076662813,
            3.428456040269614,
            3.445622684475935,
            3.4372699812060565,
            3.5879084243593327,
            3.6019315119339286,
            3.6239141770970638,
            3.6254047790791213,
            3.8891407997186493,
            4.101255002298261,
            4.4412808217991815,
          ],
          theoricalDistributionValues: [
            2.526947169146071,
            2.1794435400097103,
            1.4395028290474312,
            0.7105952353073506,
            0.4124741648673707,
            0.5091239008324404,
            0.2345272996535933,
            1.2842460769249875,
            -0.27673544354896484,
            -0.4303473513786833,
            -0.7051251476028142,
            0.1976326297793447,
            -0.6352871951701959,
            0.7787941631061472,
            0.35878743837511623,
            0.15599296499620713,
            0.2827477597891808,
            -0.20653826248581197,
            0.48435981901898956,
            0.8357789625746129,
          ],
          theoricalQuantiles: [
            2.5430161194071785,
            2.5251629659626906,
            2.4421714103232417,
            2.5771517631954537,
            2.493778709180607,
            2.5262062135539756,
            2.554606296457803,
            2.441030401041722,
            2.556025936311421,
            2.5209577775183507,
            2.5207623542686406,
            2.491647270492758,
            2.507731260045073,
            2.439467918522967,
            2.4570903642078816,
            2.5944240432284076,
            2.551770460399906,
            2.480153290672607,
            2.4477049240803335,
            2.496181721660204,
            2.5248884424708353,
            2.602063837543442,
            2.5640952441007476,
            2.5932012976373877,
            2.6184004241080583,
            2.6136335017248746,
            2.5584341205211363,
            2.5206426684674135,
            2.621355240153437,
            2.6779422661770163,
            2.6431654120429027,
            2.5202252600411152,
            2.574134772784076,
            2.579914104069525,
            2.6659453257263235,
            2.557300066215693,
            2.4835793868918334,
            2.5626769274342074,
            2.6521369685953866,
            2.6454227547531692,
            2.7176007806321665,
            2.6175390702561687,
            2.5336120151301866,
            2.560812694121433,
            2.659470340380864,
            2.6589420234608316,
            2.686427028033442,
            2.6187269132281035,
            2.6598138746473743,
            2.6176786126618845,
            2.6677376500259666,
            2.6126689878472975,
            2.654313554728906,
            2.7759596000731728,
            2.6786199854221855,
            2.6914285657172266,
            2.652476575992873,
            2.676704617753721,
            2.647663020135422,
            2.6693544716593447,
            2.552974570336756,
            2.73284670875824,
            2.7586610479968425,
            2.6921222242966727,
            2.716525480291775,
            2.7024115730348406,
            2.7107980069996813,
            2.6981737237985053,
            2.7303894171407417,
            2.7626769552774153,
            2.733834088904646,
            2.777879036013307,
            2.754403190945691,
            2.743440268737971,
            2.6955306802093753,
            2.7862567562609386,
            2.7682628433136647,
            2.8206417905908046,
            2.7170687404171425,
            2.8303771160322926,
            2.6615268300511428,
            2.821103642063436,
            2.7964371818181823,
            2.752484453611093,
            2.845204409111137,
            2.9061099876845757,
            2.7539826594757435,
            2.8381801808493616,
            2.8547781997323294,
            2.8729113831927524,
            2.897759497838691,
            2.8759191639290997,
            2.90311229042711,
            2.90625755134609,
            2.9159890824423775,
            2.912976210627696,
            2.861944288022913,
            2.882320427320709,
            3.0565764272028715,
            2.9730259191284287,
            2.990184575215655,
            2.9985114222628226,
            2.9250303483290923,
            2.9830364279120913,
            2.982024172877252,
            3.1420839077356715,
            3.0341198656501223,
            2.9949765193539637,
            3.051682577249378,
            3.0723394747694743,
            3.1231545680882142,
            3.2074591916510755,
            2.986418579338226,
            3.0636268755562015,
            3.0925673651816528,
            3.1515599167632384,
            3.1344997226888824,
            3.2018679656726268,
            3.222688132330328,
            3.2035318909203756,
            3.1907074190980054,
            3.235200008241862,
            3.337072826346578,
            3.3416038303354,
            3.360117126550885,
            3.3236617917922633,
            3.312031738492574,
            3.449276558058036,
            3.510939016322866,
            3.5024001357080308,
            3.55397956430812,
            3.4922747096475715,
            3.5785035842414668,
            3.750738083100769,
            3.803635532980946,
            3.8931516441861946,
            4.081133743189025,
            4.351559707418407,
          ],
        },
      ],
    },
  ],
};
