import request from 'helpers/request';
import { GEOMETRIES_URL } from 'constants/api';

export const createGeometries = (data, options) =>
  request.post(GEOMETRIES_URL, data, options);

export const getGeometries = (params) =>
  request.get(GEOMETRIES_URL, { params });

export const deleteGeometries = (id) =>
  request.delete(`${GEOMETRIES_URL}${id}/`);

export const updateGeometries = (geometryId, values) =>
  request.patch(`${GEOMETRIES_URL}${geometryId}/`, values);

export const updateLayers = (geometryId, layerId, values) =>
  request.patch(
    `${GEOMETRIES_URL}${geometryId}/${values.type}/${layerId}/`,
    values
  );
