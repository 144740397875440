import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    marginBottom: '0.5em',
    padding: '0 10px',
    height: 49,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #E5E5E5',
    borderRadius: 8,
    background: 'white',
    textAlign: 'right',
  },
}));
