import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles(({ palette }) => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    flexGrow: 1,
    flexBasis: 0,
    padding: '15px 0',
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: alpha(palette.primary.main, 0.5),
    borderRight: `1px solid transparent`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '&:not(:last-child)': {
      borderRightColor: palette.lightGrey.main,
    },
    '&:not(.disabled)': {
      '&.active, &:hover': {
        color: '#000',
        backgroundColor: palette.white.main,
        textDecoration: 'none',
      },
    },
  },
}));
