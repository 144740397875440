import { alpha, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  title: {
    dominantBaseline: 'middle',
    textAnchor: 'middle',
    fontWeight: 'bold',
    fontStyle: 'italic',
    fontSize: 18,
    pointerEvents: 'none',
    userSelect: 'none',
  },
  cardinalPointLabel: {
    dominantBaseline: 'middle',
    textAnchor: 'middle',
    fontSize: 16,
    pointerEvents: 'none',
    userSelect: 'none',
  },
  grid: {
    fill: palette.white.main,
    stroke: palette.grey.middle,
    strokeWidth: 2,
    paintOrder: 'stroke',
  },
  tooltip: {
    minWidth: 150,
    position: 'absolute',
  },
  gridSector: {
    fill: alpha(palette.grey.middle, 0.4),
    fillOpacity: 0,
    cursor: 'pointer',
    stroke: ({ stroke }) => stroke,
    '&$hoverable': {
      '&:hover': {
        fillOpacity: 1,
      },
    },
  },
  overlaySector: {
    fill: 'none',
    '&$selectRange': {
      fill: alpha(palette.blue.deep, 0.4),
    },
    '&$selectedRange': {
      fill: alpha(palette.black.main, 0.3),
    },
  },
  sector: {
    stroke: 'none',
    fill: ({ fill }) => fill,
  },
  hoverable: {},
  selectRange: {},
  selectedRange: {},
}));
