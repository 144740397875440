import React, { memo, useMemo, useState, useCallback } from 'react';
import PropsTypes from 'prop-types';
import classNames from 'classnames';

import { getIntervalStringByMask, getMinMax, inRange } from 'helpers/common';
import { palette } from 'common/theme';
import {
  SELECT_MODE,
  SELECT_RANGE_MODE,
  SELECTED_RANGE_MODE,
} from 'constants/graphs';
import { useRangeSelect } from 'hooks/useRangeSelect';
import SvgSector from 'components/common/graphs/SvgSector';
import KeyValueTooltip from 'components/common/graphs/KeyValueTooltip';
import { CARDINAL_POINTS, EMPTY_FUNCTION } from 'constants/common';
import { useMountedEffect } from 'hooks/useMountedEffect';

import { useStyles } from './styles';

const PADDING = 5;
const LABELS_OFFSET = 11;
const SECTOR_INTERVAL_MASK_TOOLTIP = '[{from}° ; {to}°]';
const SECTOR_INTERVAL_MASK = 'from {from}° to {to}°';

/**
 * returns ascending range object from given range limits in any order
 * in invert mode boundary values are not included to make correct inverted range
 * @param { number } start - range start
 * @param { number } end - range end
 * @param { bool } invert - if range is inverted
 * @returns {[ number, number ]} - min, max values array
 */
const getRangeMinMaxConsideringInvert = ({ start, end, invert }) => {
  const [min, max] = getMinMax(start, end);
  return invert ? [min + 1, max - 1] : [min, max];
};

/**
 * Component to help select sectors range on rose diagram. Used as control for some graphs
 * @param { string } fill
 * @param { string } stroke
 * @param { number } radius
 * @param { function } onSelect - callback that fires when range is selected,
 *   takes as argument selected range and selected sector label (ex.: 'from 15° to 90°')
 * @param { function } onDeselect - callback that fires when range is deselected, no arguments
 * @param {object|object[]} preparedData
 * @returns { JSX }
 */
const ProbabilityDistributionSelect = ({
  fill,
  stroke,
  radius,
  preparedData,
  onSelect,
  onDeselect,
}) => {
  const classes = useStyles({ fill, stroke });
  const [invert, setInvert] = useState(false);
  const { title, label, leafs, legendAngles } = preparedData;
  const leafsAmount = leafs.length;

  /**
   * helps to manage case when range cross zero angle
   * @example: in clock we go from 23:00 to 01:00
   * but we need to know exactly which arc choose 01, 02, 03 ... 23 or 23, 24, 01
   * with no crossing zero index step should be 1 (see example above) if more - zero is crossed
   * but if mouse is really fast, some steps can be skipped, so to be sure we consider max jump is items amount / 2
   */
  const observeCrossZero = useCallback(
    (event, mode) => {
      if (mode !== SELECT_RANGE_MODE) {
        return;
      }
      const { currentTarget, relatedTarget = {} } = event;
      const oldIndex = relatedTarget.getAttribute('data-index');
      const newIndex = currentTarget.getAttribute('data-index');

      if (!oldIndex) {
        return;
      }
      const maxJump = ~~(leafsAmount / 2);

      return Math.abs(newIndex - oldIndex) > maxJump
        ? setInvert((prevInvert) => !prevInvert)
        : false;
    },
    [leafsAmount, setInvert]
  );

  const resetInvert = useCallback(
    (_, mode) => mode === SELECT_MODE && setInvert(false),
    [setInvert]
  );

  const {
    mode,
    range,
    handleEnter,
    handleClick,
    handleRangeLeave,
  } = useRangeSelect({
    onEnter: observeCrossZero,
    onClick: resetInvert,
  });

  const getFromToAnglesByRange = useCallback(
    ({ start, end }) => {
      const allBoundaryAngles = [].concat(
        legendAngles[start],
        legendAngles[end]
      );

      return [
        Math.min.apply(null, allBoundaryAngles),
        Math.max.apply(null, allBoundaryAngles),
      ];
    },
    [legendAngles]
  );

  useMountedEffect(() => {
    if (mode === SELECT_RANGE_MODE) {
      return;
    }
    if (mode === SELECT_MODE) {
      onDeselect();
      return;
    }

    const sectorString = getIntervalStringByMask(
      getFromToAnglesByRange(range),
      SECTOR_INTERVAL_MASK
    );
    onSelect({ ...range, invert }, sectorString);
  }, [mode, invert]);

  const scaleDivision = useMemo(() => {
    const occurrenceValues = leafs.map((leaf) => leaf.occurrence);
    const maxOccurrence = Math.max.apply(null, occurrenceValues);
    return radius / maxOccurrence;
  }, [leafs, radius]);

  const { RoseGrid, sideLength, centerOffset } = useMemo(
    () => {
      const radiusWithPadding = radius + PADDING;
      const GridComponent = React.memo(
        ({ onClick, onEnter, onLeave, isSelectMode }) => {
          const sectorClassName = classNames(classes.gridSector, {
            [classes.hoverable]: isSelectMode,
          });

          return (
            <g onMouseLeave={onLeave}>
              {leafs.map(({ start, end }, index) => (
                <SvgSector
                  key={start}
                  data-index={index}
                  startAngle={start}
                  endAngle={end}
                  radius={radius}
                  cx={radiusWithPadding}
                  cy={radiusWithPadding}
                  onClick={onClick}
                  onMouseEnter={onEnter}
                  className={sectorClassName}
                />
              ))}
            </g>
          );
        }
      );

      return {
        RoseGrid: GridComponent,
        centerOffset: radiusWithPadding,
        sideLength: radiusWithPadding * 2,
      };
    },
    // eslint-disable-next-line
    []
  );

  const isSelectMode = mode === SELECT_MODE;
  const selectActive = range.start !== null && range.end !== null;
  const sectorString = selectActive
    ? getIntervalStringByMask(
        getFromToAnglesByRange(range),
        SECTOR_INTERVAL_MASK_TOOLTIP
      )
    : '';
  const [start, end] = selectActive
    ? getRangeMinMaxConsideringInvert({ ...range, invert })
    : [];

  return (
    <div>
      <svg width={sideLength} height={sideLength}>
        <circle
          className={classes.grid}
          cx={centerOffset}
          cy={centerOffset}
          r={radius}
        />
        <g>
          {leafs.map(
            ({ start: startAngle, end: endAngle, occurrence }, index) => {
              const inAscendingRange =
                !isSelectMode && selectActive
                  ? inRange(index, start, end)
                  : false;

              const selected = inAscendingRange ? !invert : invert;
              const sectorClass = classes.sector;
              const overlayClass = classNames(classes.overlaySector, {
                [classes.selectRange]:
                  selectActive && selected && mode === SELECT_RANGE_MODE,
                [classes.selectedRange]:
                  selectActive && selected && mode === SELECTED_RANGE_MODE,
              });

              return (
                <g key={startAngle}>
                  <SvgSector
                    fill={fill}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    cx={centerOffset}
                    cy={centerOffset}
                    radius={occurrence * scaleDivision}
                    className={sectorClass}
                  />
                  <SvgSector
                    startAngle={startAngle}
                    endAngle={endAngle}
                    cx={centerOffset}
                    cy={centerOffset}
                    radius={radius}
                    className={overlayClass}
                  />
                </g>
              );
            }
          )}
        </g>
        <RoseGrid
          onClick={handleClick}
          onEnter={handleEnter}
          onLeave={handleRangeLeave}
          isSelectMode={isSelectMode}
        />
        <text
          x={centerOffset}
          y={PADDING + LABELS_OFFSET}
          className={classes.cardinalPointLabel}
        >
          {CARDINAL_POINTS.N}
        </text>
        <text
          x={centerOffset}
          y={sideLength - PADDING - LABELS_OFFSET}
          className={classes.cardinalPointLabel}
        >
          {CARDINAL_POINTS.S}
        </text>

        <text x={centerOffset} y={centerOffset} className={classes.title}>
          {title}
        </text>
      </svg>
      {sectorString && (
        <KeyValueTooltip
          className={classes.tooltip}
          title={label}
          data={{ sector: sectorString }}
        />
      )}
    </div>
  );
};

const selectDataShape = {
  leafs: PropsTypes.arrayOf(
    PropsTypes.shape({
      sector: PropsTypes.number,
      start: PropsTypes.number,
      end: PropsTypes.number,
      occurrence: PropsTypes.number,
    })
  ),
  label: PropsTypes.string,
  title: PropsTypes.string,
  legendAngles: PropsTypes.arrayOf(PropsTypes.arrayOf(PropsTypes.number)),
};

ProbabilityDistributionSelect.propTypes = {
  fill: PropsTypes.string,
  stroke: PropsTypes.string,
  radius: PropsTypes.number,
  onSelect: PropsTypes.func,
  onDeselect: PropsTypes.func,
  preparedData: PropsTypes.oneOfType([
    PropsTypes.shape(selectDataShape),
    PropsTypes.arrayOf(PropsTypes.shape(selectDataShape)),
  ]).isRequired,
};

ProbabilityDistributionSelect.defaultProps = {
  fill: palette.grey.main,
  stroke: palette.lightGrey.main,
  onSelect: EMPTY_FUNCTION,
  onDeselect: EMPTY_FUNCTION,
  radius: 50,
};

export default memo(ProbabilityDistributionSelect);
