import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    margin: '40px 0',
    '& > *': {
      marginBottom: 20,
    },
  },
});
