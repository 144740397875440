import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useModal } from 'hooks/useModal';
import ComparisonAgainstSatelliteModal from 'components/modals/ComparisonAgainstSatelliteModal';
import { selectCurrentUser } from 'ducks/user/selectors';
import { SATELLITE_COMPARISON_NAME_PREFIX } from 'constants/satelliteComparison';
import { selectRunningSatelliteComparisonByPoint } from 'ducks/satelliteComparisons/selectors';
import {
  launchSatelliteComparison,
  startUpdateSatelliteComparisonJob,
  stopUpdateSatelliteComparisonJob,
} from 'ducks/satelliteComparisons/actions';

/**
 * Container for modal launch satellite computation.
 * @param {Number} id
 * @param {Object} point
 * @param {Boolean} allowComparison
 * @return {jsx}
 * @constructor
 */
const ComparisonAgainstSatellite = ({ id, point, allowComparison }) => {
  const dispatch = useDispatch();
  const runningSatelliteComparison = useSelector((state) =>
    selectRunningSatelliteComparisonByPoint(state, point.id)
  );
  const { onClose } = useModal(id);
  const currentUser = useSelector(selectCurrentUser);
  const satelliteComparisonId = runningSatelliteComparison
    ? runningSatelliteComparison.id
    : undefined;

  const onLaunchHandler = useCallback(() => {
    dispatch(
      launchSatelliteComparison({
        name: `${SATELLITE_COMPARISON_NAME_PREFIX}_${point.project}_${point.id}`,
        project: point.project,
        point: point.id,
        user: currentUser,
      })
    );
  }, [dispatch, point, currentUser]);

  useEffect(() => {
    if (satelliteComparisonId) {
      dispatch(startUpdateSatelliteComparisonJob(satelliteComparisonId));

      return () => dispatch(stopUpdateSatelliteComparisonJob());
    }
  }, [dispatch, satelliteComparisonId]);

  return (
    <ComparisonAgainstSatelliteModal
      allowed={allowComparison}
      onClose={onClose}
      pointName={point.name}
      email={currentUser.email}
      onLaunch={onLaunchHandler}
      loading={!!runningSatelliteComparison}
    />
  );
};

ComparisonAgainstSatellite.propTypes = {
  id: PropTypes.string.isRequired,
  point: PropTypes.shape({
    id: PropTypes.number,
    project: PropTypes.number,
  }).isRequired,
  allowComparison: PropTypes.bool,
};

export default memo(ComparisonAgainstSatellite);
