import { isObject } from 'lodash';

/**
 * Get all tabs from statistics
 * @param {Object} statistics
 * @return {Object}
 */
export const getFieldsWithStatisticsData = (statistics) =>
  Object.entries(statistics).reduce((acc, [type, value]) => {
    if (isObject(value) && value.rows && value.varNames) {
      acc[type] = value;
    }
    return acc;
  }, {});

/**
 * Return only variable name from tab label (variable name in 2nd position)
 * @param {String} label
 * @return {String}
 */
export const getVariableName = (label) => {
  const varName = label.split(' ')[1];
  return varName;
};
