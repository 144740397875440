import { createSelector } from 'reselect';

import { EMPTY_ARRAY, EMPTY_VALUE, STATUS_LOADING } from 'constants/common';
import { getFieldsWithStatisticsData } from 'helpers/satelliteComparison';

export const selectBuoysErrors = ({ buoys }) => buoys.error || EMPTY_VALUE;

export const selectBuoysData = ({ buoys }) => buoys.data;

export const selectBuoysIds = ({ buoys }) => buoys.ids;

export const selectBuoysIsLoading = ({ buoys }) =>
  buoys.status === STATUS_LOADING;

export const selectBuoyById = createSelector(
  [(_, buoyId) => buoyId, selectBuoysData],
  (buoysId, buoysData) => buoysData[buoysId] || EMPTY_VALUE
);

export const selectBuoysCollection = createSelector(
  selectBuoysIds,
  selectBuoysData,
  (buoysIds, buoysData) => buoysIds.map((id) => buoysData[id])
);

export const selectBuoyRunsById = createSelector(
  [selectBuoyById],
  ({ runs } = {}) => runs || EMPTY_ARRAY
);

export const selectBuoyStatisticsById = createSelector(
  [selectBuoyById],
  (buoy) => getFieldsWithStatisticsData(buoy)
);
