const prefix = '@@zones';

export const REQUEST_ZONES = `${prefix}/REQUEST_ZONES`;
export const REQUEST_ZONES_IF_NEED = `${prefix}/REQUEST_ZONES_IF_NEED`;
export const REQUEST_ZONES_SUCCESS = `${prefix}/REQUEST_ZONES_SUCCESS`;
export const REQUEST_ZONES_ERROR = `${prefix}/REQUEST_ZONES_ERROR`;

export const REQUEST_ZONE = `${prefix}/REQUEST_ZONE`;
export const REQUEST_ZONE_SUCCESS = `${prefix}/REQUEST_ZONE_SUCCESS`;
export const REQUEST_ZONE_ERROR = `${prefix}/REQUEST_ZONE_ERROR`;
