import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { selectStaticPageTitleByUrl } from 'ducks/staticPages/selectors';
import { EMPTY_ARRAY } from 'constants/common';
import { useNavbarLinks } from 'hooks/useNavbarLinks';

import Navbar from '../Navbar';

/**
 * Displays NavBar for Help Page
 * @param isAuthorized
 * @param location
 */
const HelpPageNavBar = ({ isAuthorized, location }) => {
  const { url } = useParams();
  const links = useNavbarLinks(location.pathname);
  const pageHeader = useSelector((state) =>
    selectStaticPageTitleByUrl(state, url)
  );

  return (
    <Navbar
      links={isAuthorized ? links : EMPTY_ARRAY}
      isUserBarNotUsed={!isAuthorized}
      header={pageHeader}
    />
  );
};

HelpPageNavBar.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

export default memo(HelpPageNavBar);
