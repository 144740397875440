import {
  POST_DEMO_FORM,
  POST_DEMO_FORM_ERROR,
  POST_DEMO_FORM_SUCCESS,
  DEMO_FORM_RESET,
} from './types';

export const postDemoFormAction = (formValues = {}) => ({
  type: POST_DEMO_FORM,
  payload: formValues,
});

export const postDemoFormSuccessAction = (response) => ({
  type: POST_DEMO_FORM_SUCCESS,
  payload: response,
});

export const postDemoFormErrorAction = (error = {}) => ({
  type: POST_DEMO_FORM_ERROR,
  payload: error,
});

export const demoFormReset = () => ({
  type: DEMO_FORM_RESET,
});
