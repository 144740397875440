import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { LANGUAGE_LIST, LANGUAGES } from 'constants/locales';

import translationEnglish from './translations/en.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEnglish,
    },
  },
  lng: LANGUAGES.english,
  whitelist: LANGUAGE_LIST,
  fallbackLng: LANGUAGES.english,
  interpolation: { escapeValue: false },
});

export default i18n;
