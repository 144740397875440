import {
  STATUS_LOADING,
  STATUS_ERROR,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
  EMPTY_VALUE,
} from 'constants/common';
import createReducer from 'helpers/createReducer';

import { GET_HISTORY, GET_HISTORY_ERROR, GET_HISTORY_SUCCESS } from './types';

const initialState = {
  historyData: EMPTY_VALUE,
  error: EMPTY_VALUE,
  status: STATUS_NOT_REQUESTED,
};

const setLoadingStatus = (state) => ({ ...state, status: STATUS_LOADING });

const setResponseData = (state, { payload }) => ({
  ...state,
  historyData: payload,
  status: STATUS_SUCCESS,
});

const setError = (state, { error }) => ({
  ...state,
  error,
  status: STATUS_ERROR,
});

export default createReducer(initialState, {
  [GET_HISTORY]: setLoadingStatus,
  [GET_HISTORY_SUCCESS]: setResponseData,
  [GET_HISTORY_ERROR]: setError,
});
