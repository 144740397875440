import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { trackOfferSelection } from 'ducks/trackers/actions/projectManagment';
import { selectOffers } from 'ducks/offers/selectors';
import { selectCurrentUser } from 'ducks/user/selectors';
import { prepareTrialProjectName } from 'helpers/projects';
import { createComputedProjectWithRedirect } from 'ducks/app/actions';
import map from 'assets/images/background/map.png';

import { useStyles } from './styles';

/**
 * ProjectDemo component
 * Displays project demo
 */
const ProjectDemo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const offers = useSelector(selectOffers);
  const currentUser = useSelector(selectCurrentUser);

  const redirectToCreateProject = useCallback(() => {
    const offer = offers.find((item) => item.name === 'Free Trial');

    if (offer) {
      const isTrial = true;
      const name = offer.name;
      const offerInstance = offer.id;
      const zone = offer.zones[0];

      dispatch(trackOfferSelection(name));
      dispatch(
        createComputedProjectWithRedirect({
          isTrial,
          collaborators: [currentUser.id],
          offerInstance,
          zone: zone.id,
          name: prepareTrialProjectName({
            offerName: offer.name,
            zoneName: zone.name,
          }),
        })
      );
    }
  }, [history, currentUser.id, dispatch, offers]);

  return (
    <div className={classes.container}>
      <img src={map} className={classes.img} alt="map background" />

      <div className={classes.parent}>
        <div className={classes.card}>
          <Typography variant="body1" className={classes.title}>
            Free Demo
          </Typography>
          <div className={classes.cardContent}>
            <Typography variant="body1" align="center">
              <strong>Zone available:</strong>&nbsp;North and Celtic Seas
            </Typography>
            <Typography variant="body1" align="center">
              <strong>Advanced weather downtime tool</strong>
            </Typography>
            <Typography variant="body1" align="center">
              <strong>PDF report generation</strong>
            </Typography>
          </div>

          <Button
            variant="contained"
            onClick={redirectToCreateProject}
            className={classes.floatingButton}
            classes={{ label: classes.label }}
          >
            CREATE FREE DEMO NOW
          </Button>
        </div>
      </div>
    </div>
  );
};

ProjectDemo.propTypes = {};

export default memo(ProjectDemo);
