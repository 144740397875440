import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { userLogout } from 'ducks/user/actions';

/**
 * Private route for pages
 * @param {Boolean} hasAccess
 * @param {React.ReactNode} children
 * @param {Object} restProps
 * @returns {jsx}
 */
const PrivateRoute = ({ hasAccess, children, ...restProps }) => {
  const dispatch = useDispatch();
  const logoutUser = useCallback(() => {
    dispatch(userLogout());
  }, [dispatch]);

  return (
    <Route
      {...restProps}
      render={() => (hasAccess ? children : logoutUser())}
    />
  );
};

PrivateRoute.propTypes = {
  hasAccess: PropTypes.bool,
  children: PropTypes.node,
};

export default PrivateRoute;
