import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  downloadProgress: {
    marginLeft: '45%',
  },
  progressBar: {
    textAlign: 'center',
  },
}));
