import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { MapFeatureGroup, MapLayer } from 'components/leaflet';
import { GRIDS_GROUP_NAME } from 'constants/map';
import { prepareZoneLayer } from 'helpers/zones';

/**
 * ZoneLayerGroup - render one layer by simulation
 *
 * @param {Object} grid
 * @returns {jsx}
 */
const ZoneLayerGroup = ({ grid }) => {
  // useMemo useless because will rerender only if changes grid prop
  const layer = prepareZoneLayer({}, grid);

  return (
    <MapFeatureGroup name={GRIDS_GROUP_NAME} withControls>
      <MapLayer path={layer.tile} name={layer.name} />
    </MapFeatureGroup>
  );
};

ZoneLayerGroup.propTypes = {
  grid: PropTypes.object.isRequired,
};

export default memo(ZoneLayerGroup);
