import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { MapMarker } from 'components/leaflet';
import { useBuoyIcon } from 'hooks/useBuoyIcon';
import { getPositionFromGeometry } from 'helpers/map';

/**
 * Marker for buoy with icon and params
 * @param {Array|String} sensors
 * @param {String} geom
 * @param {String} name
 * @param {String} type
 * @param {Number} id
 * @param {Object} rest
 * @param {Function} add
 * @param {Function} remove
 * @return {jsx}
 */
const BuoyMarker = ({ sensors, name, geom, id, type, add, remove }) => {
  const { iconUrl, iconSize, iconAnchor, popupAnchor } = useBuoyIcon({
    type,
    sensors,
  });
  const position = useMemo(() => getPositionFromGeometry(geom), [geom]);
  const customProps = useMemo(() => ({ sensors, geom, id }), [
    sensors,
    geom,
    id,
  ]);

  return (
    <MapMarker
      position={position}
      iconUrl={iconUrl}
      iconSize={iconSize}
      iconAnchor={iconAnchor}
      popupAnchor={popupAnchor}
      customProps={customProps}
      title={`${name}\n${sensors}`}
      add={add}
      remove={remove}
    />
  );
};

BuoyMarker.propTypes = {
  sensors: PropTypes.array,
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.number,
  add: PropTypes.func,
  remove: PropTypes.func,
  geom: PropTypes.string.isRequired,
};

export default memo(BuoyMarker);
