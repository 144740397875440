import { INVALID_RULE_MESSAGE, INVALID_RULES_MESSAGE } from 'constants/errors';

/**
 * checks whether the given rule has incorrect form
 * @param {Object} rule
 * @return {boolean}
 */
const isIncorrectRule = (rule) =>
  !{}.hasOwnProperty.call(rule, 'message') ||
  !{}.hasOwnProperty.call(rule, 'validate') ||
  typeof rule.validate !== 'function';

/**
 * Validator class to make validation with given rules.
 * Takes as much rules as u want and implements it in validate function
 * has getter for error, which returns first error from errors collection
 * @param [{ message: string|function, validate: function }] rules
 */
export class Validator {
  constructor(rules) {
    this.rules = rules;
    this.errors = [];
    if (!Array.isArray(rules)) {
      throw new Error(INVALID_RULES_MESSAGE);
    }
    if (rules.some(isIncorrectRule)) {
      throw new Error(INVALID_RULE_MESSAGE);
    }
  }

  validate = (value) => {
    this.errors = [];
    return this.rules.reduce((acc, { validate, message }) => {
      const isValid = validate(value);
      if (!isValid) {
        const errorMessage =
          typeof message === 'function' ? message(value) : message;

        this.errors.push(errorMessage);
      }
      return isValid ? acc : false;
    }, true);
  };

  get error() {
    return this.errors[0] || '';
  }
}
