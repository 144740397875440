import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { GEOMETRY_POSITION } from 'constants/map';
import { getMapPositionByPoints } from 'helpers/map';
import { StaticMap, MapFeatureGroup } from 'components/leaflet';
import ProjectMarker from 'components/maps/ProjectMarker';

/**
 * StaticProjectMap - calculate center and draw a markers
 * @param {String} geometry - project geometry
 * @param {Array} points - project points
 * @param {Object} rest
 * @returns {jsx}
 */
const StaticProjectMap = ({ geometry, points, ...rest }) => {
  const { position, positionType } = useMemo(() => {
    if (!points || !points.length || points.length === 1) {
      return { position: geometry, positionType: GEOMETRY_POSITION };
    }

    return {
      position: getMapPositionByPoints(points),
      positionType: GEOMETRY_POSITION,
    };
  }, [points, geometry]);

  return (
    <StaticMap position={position} positionType={positionType} {...rest}>
      <MapFeatureGroup>
        {points.map(({ lat, lng, isComputed, hasSatelliteValidation, id }) => (
          <ProjectMarker
            id={id}
            key={id}
            lat={lat}
            lng={lng}
            isComputed={isComputed}
            hasSatelliteValidation={hasSatelliteValidation}
          />
        ))}
      </MapFeatureGroup>
    </StaticMap>
  );
};

StaticProjectMap.propTypes = {
  geometry: PropTypes.string.isRequired,
  zoom: PropTypes.number,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
      isComputed: PropTypes.bool,
      hasSatelliteValidation: PropTypes.bool,
      id: PropTypes.number,
    })
  ),
};

export default memo(StaticProjectMap);
