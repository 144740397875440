import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PeoplePageComponent from 'components/peoples/PeoplePage';
import {
  selectCollaboratorsForTable,
  selectCurrentUser,
  selectCurrentUserIsManager,
} from 'ducks/user/selectors';
import {
  ADD_USER_TO_PROJECT_MODAL,
  EDIT_OTHER_PROFILE_MODAL,
  REMOVE_OTHER_PROFILE_MODAL,
  RESEND_INVITATION_MODAL,
  REVOKE_INVITATION_MODAL,
} from 'constants/modals';
import { useModal } from 'hooks/useModal';
import { requestProjectsIfNeed } from 'ducks/projects/actions';

/**
 * PeoplePage container
 * Displays people page with collaborators list
 */
const PeoplePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requestProjectsIfNeed());
  }, [dispatch]);
  const collaborators = useSelector(selectCollaboratorsForTable);
  const isManager = useSelector(selectCurrentUserIsManager);
  const { id } = useSelector(selectCurrentUser);
  const { openModal } = useModal();

  const editProfile = useCallback(
    (user) => {
      openModal(EDIT_OTHER_PROFILE_MODAL, { user, currentUserId: id });
    },
    [openModal, id]
  );
  const removeProfile = useCallback(
    (user) => {
      openModal(REMOVE_OTHER_PROFILE_MODAL, { user });
    },
    [openModal, id]
  );
  const addToProject = useCallback(
    (user) => {
      openModal(ADD_USER_TO_PROJECT_MODAL, { user });
    },
    [openModal]
  );
  const resendInvite = useCallback(
    (user) => {
      openModal(RESEND_INVITATION_MODAL, { user });
    },
    [openModal]
  );
  const revokeInvite = useCallback(
    (user) => {
      openModal(REVOKE_INVITATION_MODAL, { user });
    },
    [openModal]
  );

  return (
    <PeoplePageComponent
      canEditUsers={isManager}
      canAddUserToProject={isManager}
      canManipulateInvitations={isManager}
      collaborators={collaborators}
      editProfile={editProfile}
      removeProfile={removeProfile}
      currentUserId={id}
      addToProject={addToProject}
      revokeInvite={revokeInvite}
      resendInvite={resendInvite}
    />
  );
};

export default memo(PeoplePage);
