import qs from 'qs';

import { AUTH_TOKEN_KEY } from 'constants/common';
import { API_URL, REACT_APP_VERSION_INFO } from 'common/config';
import { camelizeKeys } from 'helpers/camelizer';
import { tryJsonParse } from 'helpers/data';
// import { keycloak } from "keycloak-js";

/**
 * Prepare request error.
 * @param {XMLHttpRequest} xhr
 * @returns {{ message: (Object|undefined), status: number }}
 */
const prepareXhrRequestError = (xhr) => {
  const response = tryJsonParse(xhr.responseText);
  return {
    status: xhr.status,
    message: response ? camelizeKeys(response) : undefined,
  };
};

/**
 * makes xhr request
 * use it for specific tasks, if 'request' from 'helpers/request' can't complete task
 * @param {string} method
 * @param {string} url
 * @param {string} responseType
 * @param {string} contentType
 * @param {bool} withToken
 * @param {string} params
 * @param {*} data - data to send, if need
 */
export const makeXhrRequest = ({
  method,
  url,
  responseType,
  contentType = 'application/json',
  withToken = true,
  params,
  data,
}) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    const requestXhr = new XMLHttpRequest();
    requestXhr.withCredentials = true;
    // remove duplicate slashes, if any
    if (url[0] === '/' && API_URL[API_URL.length - 1] === '/') {
      url = url.replace(/^\//, '');
    }
    let requestUrl = `${API_URL}${url}`;

    if (params) {
      const paramsString = qs.stringify(params, { addQueryPrefix: true });
      requestUrl = `${API_URL}${url}${paramsString}`;
    }

    requestXhr.open(method, requestUrl, true);
    if (responseType) {
      requestXhr.responseType = responseType;
    }

    // console.log(window.keycloak.token);
    if (withToken) {
      requestXhr.setRequestHeader('authorization', `Bearer ${token}`);
      // requestXhr.setRequestHeader('authorization', `Bearer ${keycloak.token}`);
    }

    if (contentType) {
      requestXhr.setRequestHeader('Content-Type', contentType);
    }

    requestXhr.setRequestHeader('MA-Version', REACT_APP_VERSION_INFO);

    requestXhr.onload = () => {
      if (requestXhr.status >= 200 && requestXhr.status < 300) {
        resolve(requestXhr.response);
      } else {
        reject(prepareXhrRequestError(requestXhr));
      }
    };
    requestXhr.onerror = () => {
      reject(prepareXhrRequestError(requestXhr));
    };

    return data ? requestXhr.send(data) : requestXhr.send();
  });
