import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropsTypes from 'prop-types';

import MCOutputsComponent from 'components/projects/MarineContractors/Outputs/index';
import {
  selectMarineContractorsCustomerIsLoading,
  selectMarineContractorsCustomerIsNotRequested,
  selectMarineContractorsCustomer,
  selectMarineContractorsJobOutputFormat,
  selectMarineContractorsJobPercentiles,
  selectMarineContractorsJobTaskStatus,
  selectMarineContractorsJobStatus,
} from 'ducks/marineContractors/selectors';
import { selectUserDetails } from 'ducks/user/selectors';
import {
  cleanJobStatus,
  requestMarineContractorsCustomer,
  updateMarineContractorsCustomerPercentiles,
} from 'ducks/marineContractors/actions';
import { Loader } from 'components/common/Loader';
import {
  EMPTY_ARRAY,
  SNACKBAR_ERROR_TYPE,
  STATUS_ERROR,
} from 'constants/common';
import { DEFAULT_PERCENTILES_LIST } from 'constants/marineContractors';
import { PERCENTILE_MODAL, NOTIFICATION_MODAL } from 'constants/modals';
import { useModal } from 'hooks/useModal';
import { SUPPORT_EMAIL } from 'common/config';
import { FAILED_TASK_STATUS } from 'constants/projects';

/**
 * Marine contractors outputs container - manage the customer outputs
 * @param { function } handleNext
 * @param { function } handleBack
 * @returns { JSX }
 */
const MCOutputsContainer = ({ handleNext, handleBack }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { openModal } = useModal();
  const [percentilesList, setPercentilesList] = useState(
    DEFAULT_PERCENTILES_LIST
  );
  const [selectedPercentiles, setSelectedPercentiles] = useState(EMPTY_ARRAY);
  const [selectedFormat, setSelectedFormat] = useState(
    t('marineContractors.outputs.labels.defaultFormat')
  );
  const [disabled, setDisabled] = useState(false);

  const { customerId } = useSelector(selectUserDetails);
  const customerIsLoading = useSelector(
    selectMarineContractorsCustomerIsLoading
  );
  const customerIsNotRequested = useSelector(
    selectMarineContractorsCustomerIsNotRequested
  );
  const customerPercentiles = useSelector(
    selectMarineContractorsJobPercentiles
  );
  const customerOutputFormat = useSelector(
    selectMarineContractorsJobOutputFormat
  );
  const taskStatus = useSelector(selectMarineContractorsJobTaskStatus);
  const status = useSelector(selectMarineContractorsJobStatus);
  const customer = useSelector(selectMarineContractorsCustomer);
  const { percentiles } = customer;

  const isLoading = customerIsNotRequested || customerIsLoading;

  useEffect(() => {
    if (taskStatus === FAILED_TASK_STATUS) {
      openModal(NOTIFICATION_MODAL, {
        type: SNACKBAR_ERROR_TYPE,
        message: t('marineContractors.outputs.errors.computation', {
          email: SUPPORT_EMAIL,
        }),
      });
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (disabled && status === STATUS_ERROR) {
      setDisabled(false);
    }
    // eslint-disable-next-line
  }, [status])

  useEffect(() => {
    if (customerIsNotRequested && !!customerId) {
      dispatch(requestMarineContractorsCustomer(customerId));
    }
  }, [dispatch, customerId, customerIsNotRequested]);

  useEffect(() => {
    if (customerPercentiles?.length) {
      setSelectedPercentiles(customerPercentiles.map((p) => p.toString()));
    }
  }, [customerPercentiles, setSelectedPercentiles]);

  useEffect(() => {
    const newPercentilesList = percentiles?.length
      ? [...percentiles.map((p) => p.toString())]
      : EMPTY_ARRAY;
    setPercentilesList(() => [
      ...DEFAULT_PERCENTILES_LIST,
      ...newPercentilesList,
    ]);
    // custom percentiles always displayed in the AutoComplete field
    // when adding a new percentile, it appears in the field (no ambiguity for the user)
    // if the user does not want custom percentiles, he can delete it in the field or in the options (affects the customer)
    setSelectedPercentiles((prevState) => [
      ...prevState.filter((p) => !newPercentilesList.includes(p)),
      ...newPercentilesList,
    ]);
  }, [percentiles, setPercentilesList]);

  useEffect(() => {
    if (customerOutputFormat) {
      setSelectedFormat(customerOutputFormat);
    }
  }, [customerOutputFormat, setSelectedFormat]);

  const handlePercentiles = useCallback(
    (_event, values) => {
      setSelectedPercentiles(values);
    },
    [setSelectedPercentiles]
  );

  const handleOutputFormat = useCallback(
    (e) => {
      setSelectedFormat(e.target.value);
    },
    [setSelectedFormat]
  );

  const handleAddPercentile = useCallback(() => {
    openModal(PERCENTILE_MODAL);
  }, [openModal]);

  const handleDelete = useCallback(
    (value) => {
      const percentilesUpdated = [...customer.percentiles].filter(
        (p) => p !== +value
      );
      customer.percentiles = percentilesUpdated;
      dispatch(updateMarineContractorsCustomerPercentiles(customer));
    },
    [dispatch, customer]
  );

  const prepareHandleNext = useCallback(() => {
    setDisabled(true);
    dispatch(cleanJobStatus());
    handleNext({
      selectedPercentiles,
      selectedOutputFormat: selectedFormat,
    });
    // eslint-disable-next-line
  }, [handleNext, selectedPercentiles, selectedFormat]);

  return !isLoading ? (
    <MCOutputsComponent
      percentiles={percentilesList}
      selectedPercentiles={selectedPercentiles}
      selectedOutputFormat={selectedFormat}
      disabled={disabled}
      handlePercentiles={handlePercentiles}
      handleOutputFormat={handleOutputFormat}
      handleNext={prepareHandleNext}
      handleBack={handleBack}
      handleAddPercentile={handleAddPercentile}
      handleDelete={handleDelete}
    />
  ) : (
    <Loader center />
  );
};

MCOutputsContainer.propTypes = {
  handleNext: PropsTypes.func.isRequired,
  handleBack: PropsTypes.func.isRequired,
};

export default React.memo(MCOutputsContainer);
