import { generatePath } from 'react-router';

import request from 'helpers/request';
import {
  REPORTS_URL,
  REPORT_URL,
  REPORT_CANCEL,
  REPORT_GENERATE,
  REPORT_CLEAN,
  REPORT_DOWNLOAD_URL,
} from 'constants/api';
import { makeXhrRequest } from 'helpers/requestXhr';

export const getReports = (params) => request.get(REPORTS_URL, { params });
export const postReport = (data) => request.post(REPORTS_URL, data);
export const getReport = (id) => request.get(generatePath(REPORT_URL, { id }));
export const cleanReport = (id) =>
  request.put(generatePath(REPORT_CLEAN, { id }));
export const deleteReport = (id) =>
  request.delete(generatePath(REPORT_URL, { id }));
export const generateReport = (id, data) =>
  request.put(generatePath(REPORT_GENERATE, { id }), data);
export const cancelReport = (id) =>
  request.put(generatePath(REPORT_CANCEL, { id }));
export const downloadReport = (reportId) =>
  makeXhrRequest({
    method: 'GET',
    url: generatePath(REPORT_DOWNLOAD_URL, {
      id: reportId,
    }),
    responseType: 'blob',
  }).then((result) => result);
