import React, { memo, useCallback } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { EMPTY_FUNCTION } from 'constants/common';

import { useStyles } from './styles';

/**
 * Local component for usage in BaseDataTable component
 */
const TableRowComponent = ({ item, onRowClick, itemKey, columns, classes }) => (
  <TableRow
    key={item[itemKey]}
    className={classes.row}
    onClick={useCallback(() => onRowClick(item), [onRowClick, item])}
  >
    {columns.map(({ field, cell }) => (
      <TableCell key={field}>
        {cell ? cell(item[field], item) : item[field]}
      </TableCell>
    ))}
  </TableRow>
);

/**
 * BaseDataTable component
 * Base component for tables
 * @param { string } fallbackMessage - message if we don't have data
 * @param { string } itemKey - field name, which will be tableRow key
 * @param { string } className - class for setting table styles
 * @param { function } onRowClick - handler for click on table row
 * @param { array } data - array of data
 * @param { array } columns - array to define the table columns (headers, rows, how row will be shown)
 * example columns - [{field: 'fieldName', title: 'fieldTitle', cell: () => (<Component />)}]
 * if cell is set, then renderProp pattern is used in TableCell - we call cell function
 * instead of implementing its own render logic
 * @param { Object } props
 */
const BaseDataTable = ({
  data,
  columns,
  itemKey,
  onRowClick,
  className,
  fallbackMessage,
  ...props
}) => {
  const classes = useStyles();
  return (
    <TableContainer className={classNames(classes.tableContainer, className)}>
      <Table {...props}>
        <TableHead>
          <TableRow>
            {columns.map(({ title, headerCell }) => (
              <TableCell key={title} className={classes.header}>
                {headerCell ? headerCell(title) : title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRowComponent
              key={item[itemKey]}
              item={item}
              onRowClick={onRowClick}
              itemKey={itemKey}
              columns={columns}
              classes={classes}
            />
          ))}
        </TableBody>
      </Table>
      {!data.length && fallbackMessage && (
        <Typography align="center" className={classes.emptyMessage}>
          {fallbackMessage}
        </Typography>
      )}
    </TableContainer>
  );
};

BaseDataTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      cell: PropTypes.func,
      headerCell: PropTypes.func,
    })
  ).isRequired,
  itemKey: PropTypes.string,
  fallbackMessage: PropTypes.string,
  onRowClick: PropTypes.func,
  className: PropTypes.string,
};

BaseDataTable.defaultProps = {
  itemKey: 'id',
  onRowClick: EMPTY_FUNCTION,
};

export default memo(BaseDataTable);
