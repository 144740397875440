import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { WarningCircle } from 'phosphor-react';

import { useForm } from 'hooks/useForm';
import AccessToProjectList from 'components/forms/AccessToProjectList';
import SearchInput from 'components/common/SearchInput';
import Empty, {
  SmallerTitleComponent,
  SmallerSubtitleComponent,
} from 'components/common/EmptyContentFallback';

import { useStyles } from './styles';

/**
 * GrantAccessToProjectForm component
 * Displays grant access to project for a list of collaborators form
 * @param { Array } collaborators - list of collaborators
 * @param { Array } projectCollaborators - list of collaborators, who have access to project
 * @param { Function } onSearchChange -  handler for changing a search value
 * @param { Function } onOpenInviteModal -  handler for open modal button
 * @param { Function } onSkip -  handler for close modal button
 * @param { Function } onSuccess -  handler for adding a collaborators
 * @param { String } search -  search value
 */
const GrantAccessToProjectForm = ({
  collaborators,
  projectCollaborators,
  onSkip,
  onSuccess,
  onSearchChange,
  onOpenInviteModal,
  search,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const initialValues = useMemo(
    () => ({
      collaboratorsAccess: projectCollaborators.map(
        (collaborator) => collaborator.id
      ),
    }),
    [projectCollaborators]
  );

  const { updateField, values, handleSubmit } = useForm({
    initialValues,
    onSuccess,
  });

  const { collaboratorsAccess } = values;

  const updateCollaborators = useCallback(
    ({ currentTarget }) => {
      const { collaboratorId } = currentTarget.dataset;
      const collaboratorIdNumber = +collaboratorId;
      updateField('collaboratorsAccess', (prevCollaboratorsAccess) =>
        prevCollaboratorsAccess.includes(collaboratorIdNumber)
          ? prevCollaboratorsAccess.filter((id) => id !== collaboratorIdNumber)
          : [...prevCollaboratorsAccess, collaboratorIdNumber]
      );
    },
    [updateField]
  );

  const searchChangeHandler = useCallback(
    ({ target }) => onSearchChange(target.value),
    [onSearchChange]
  );

  return (
    <form>
      <SearchInput value={search} handleChange={searchChangeHandler} />
      {collaborators.length ? (
        <AccessToProjectList
          collaborators={collaborators}
          collaboratorsAccess={collaboratorsAccess}
          updateCollaborators={updateCollaborators}
        />
      ) : (
        <Empty
          iconComponent={<WarningCircle size={80} />}
          iconSize={80}
          title={t('emptyState.noMatch.title')}
          titleComponent={SmallerTitleComponent}
          subtitle={t('emptyState.noMatch.subtitle')}
          subtitleComponent={SmallerSubtitleComponent}
        />
      )}
      <Typography gutterBottom variant="body1" className={classes.formText}>
        {t('projects.grantAccess.notListedText')}
        <Link
          component="button"
          type="button"
          variant="body1"
          className={classes.formTextLink}
          onClick={onOpenInviteModal}
        >
          {t('projects.grantAccess.inviteLink')}
        </Link>
      </Typography>
      <div className={classes.modalButtonRow}>
        <Button className={classes.skipButton} onClick={onSkip}>
          {t('buttons.skip')}
        </Button>
        <Button
          className={classes.saveButton}
          onClick={handleSubmit}
          data-submit
        >
          {t('buttons.save')}
        </Button>
      </div>
    </form>
  );
};

GrantAccessToProjectForm.propTypes = {
  collaborators: PropTypes.array.isRequired,
  projectCollaborators: PropTypes.array.isRequired,
  onSkip: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  onOpenInviteModal: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
};

export default React.memo(GrantAccessToProjectForm);
