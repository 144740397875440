/* eslint-disable */
import { createSelector } from 'reselect';
import get from 'lodash/get';

import { EMPTY_ARRAY, EMPTY_VALUE, STATUS_PENDING } from 'constants/common';
import { selectProjectsCollection } from 'ducks/projects/selectors';
import { getUserActionsSummaryByProjects } from 'helpers/projects';
import { normalizeArray } from 'helpers/data';

export const selectAuthErrors = ({ user }) => user.authError;
export const selectAuthCommonErrors = (state) => selectAuthErrors(state).common;
export const selectAuthEntityErrors = (state) =>
  selectAuthErrors(state).entityErrors;

export const selectRegistrationCheckError = ({ user }) =>
  user.error.registration || EMPTY_VALUE;

export const selectRegisterError = ({ user }) => user.error.register;

export const selectIsAuthorized = ({ user }) => Boolean(user.data.id);

export const selectUsername = ({ user }) => user.data.username;

export const selectCurrentUser = ({ user }) => user.data;

export const selectCurrentUserIsManager = ({ user }) => user.data.isStaff;

export const selectUserAccountsInfo = createSelector(
  selectCurrentUser,
  ({ accounts = EMPTY_ARRAY }) => accounts
);

export const selectUserAccountInfo = createSelector(
  selectUserAccountsInfo,
  ([account = EMPTY_VALUE]) => account
);

export const selectIsUserExpired = createSelector(
  selectUserAccountInfo,
  (accountInfo) =>
    !Object.keys(accountInfo).length || accountInfo?.subscriptionExpired
);

export const selectIsRegistrationValid = createSelector(
  selectRegistrationCheckError,
  (error) => !Object.keys(error).length
);

/**
 * selects user details for profile
 */
export const selectUserDetails = createSelector(
  [selectCurrentUser, selectProjectsCollection],
  (user, projectsCollection) => {
    if (!user.id) {
      return EMPTY_VALUE;
    }
    const {
      id,
      username,
      firstName,
      lastName,
      email,
      customerId,
      accounts,
    } = user;
    // user can't be related with many accounts in database
    // but many user accounts are not supported yet
    const [firstAccount] = accounts;
    const {
      credits,
      projects,
      reports,
      computations,
      awdComputations,
    } = getUserActionsSummaryByProjects({ user, projects: projectsCollection });

    return {
      id,
      email,
      username,
      fullName: `${firstName} ${lastName}`,
      projects,
      credits,
      reports,
      computations,
      awdComputations,
      customerId,
      customerName: firstAccount?.name,
    };
  }
);

export const selectCollaborators = createSelector(
  [selectUserAccountInfo],
  ({ collaborators }) => collaborators
);

export const selectCollaboratorsById = createSelector(
  [selectUserAccountsInfo],
  (accounts) =>
    accounts.reduce(
      (acc, { collaborators }) => ({
        ...acc,
        ...normalizeArray(collaborators).data,
      }),
      {}
    )
);

export const selectCollaboratorsForTable = createSelector(
  [selectCollaborators],
  (data) =>
    data.map(({ firstName, lastName, ...item }) => ({
      fullName: `${firstName} ${lastName}`,
      firstName,
      lastName,
      ...item,
    }))
);

export const selectUserShowOverviewControl = ({ user }) =>
  get(user, 'settings.showOverviewControl', true);

export const selectUserEmail = ({ user }) => user.data.email;

export const selectNumberOfAccounts = createSelector(
  [selectCollaborators],
  (data) =>
    data.reduce(
      (acc, item) => {
        if (item.role === STATUS_PENDING) {
          acc.numberOfPendingAccounts += 1;
        } else {
          acc.numberOfActivatedAccounts += 1;
        }
        return acc;
      },
      { numberOfActivatedAccounts: 0, numberOfPendingAccounts: 0 }
    )
);

export const selectAvailableCredits = createSelector(
  selectUserAccountInfo,
  ({ totalCredits = 0, credits = 0 }) => totalCredits - credits
);

export const selectHasUnlimitedFullStudy = createSelector(
  selectUserAccountInfo,
  ({ pack = {} }) => pack.hasUnlimitedFullStudy
);

export const selectCurrentUserStatus = ({ user }) => user.status;

export const selectHasUnlimitedDiscover = createSelector(
  selectUserAccountInfo,
  ({ pack = {} }) => pack.hasUnlimitedDiscover
);
