import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  titleContainer: {
    backgroundColor: '#F5F2FA',
    padding: 10,
    textAlign: 'center',
    fontWeight: 600,
  },
  metadata: {
    border: '1px solid',
    borderColor: palette.lightGrey.main,
  },
  metadataRow: {
    '&:not(:last-child)': {
      borderBottom: '1px solid',
      borderBottomColor: palette.lightGrey.main,
    },
  },
  metadataCell: {
    padding: 8,
    textAlign: 'center',
    '&:first-child': {
      fontWeight: 600,
      borderRight: '1px solid',
      borderRightColor: palette.lightGrey.main,
    },
  },
}));
