import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { toRadian } from 'helpers/common';

/**
 * Svg sector component
 * Draws sector based on given start/end angles and radius
 * @note Sector component from recharts but doesn't support extra params like data-attributes :(
 * But if you don't need this, feel free to use it
 * @see http://recharts.org/en-US/api/Sector
 * @param { number } cx
 * @param { number } cy
 * @param { number } startAngle
 * @param { number } endAngle
 * @param { number } radius
 * @param { object } props
 * @returns { JSX }
 */
const SvgSector = ({ cx, cy, startAngle, endAngle, radius: r, ...props }) => {
  const startAngleRad = toRadian(startAngle);
  const endAngleRad = toRadian(endAngle);

  const x1 = cx + Math.cos(startAngleRad) * r;
  const y1 = cy - Math.sin(startAngleRad) * r;
  const x2 = cx + Math.cos(endAngleRad) * r;
  const y2 = cy - Math.sin(endAngleRad) * r;
  const isLongArc = endAngleRad - startAngleRad > Math.PI;

  const d = `M ${cx},${cy} L ${x1},${y1} A ${r},${r},0,${+isLongArc},0,${x2},${y2} L ${cx},${cy} Z`;

  return <path d={d} {...props} />;
};

SvgSector.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  startAngle: PropTypes.number.isRequired,
  endAngle: PropTypes.number.isRequired,
  radius: PropTypes.number.isRequired,
};

SvgSector.defaultProps = {
  cx: 0,
  cy: 0,
};

export default memo(SvgSector);
