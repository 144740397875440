import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import GrantAccessForCollaboratorContainer from 'containers/collaborators/GrantAccessForCollaborator';
import BaseModal from 'components/common/BaseModal';
import { closeModal } from 'ducks/modals/actions';

import { useStyles } from './styles';

/**
 * GrantAccessForCollaboratorModal component
 * Modal for adding an access to projects for a collaborator
 * @param {String} email - user email
 * @param {String} id - modal id
 * @param {number} collaboratorId - collaborator id
 */
const GrantAccessForCollaboratorModal = ({ id, email, collaboratorId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const onClose = useCallback(() => {
    dispatch(closeModal({ id }));
  }, [dispatch, id]);

  return (
    <BaseModal
      onClose={onClose}
      title={t('collaborators.userInvited', { email })}
      modalClass={classes.container}
    >
      <GrantAccessForCollaboratorContainer
        id={id}
        collaboratorId={collaboratorId}
      />
    </BaseModal>
  );
};

GrantAccessForCollaboratorModal.propTypes = {
  id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  collaboratorId: PropTypes.number.isRequired,
};

export default React.memo(GrantAccessForCollaboratorModal);
