import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import BaseModal from 'components/common/BaseModal';

import { useStyles } from './styles';

/**
 * UserInvitationManagementModal component
 * Used to management (resend or revoke) users invitation
 * @param onClose
 * @param invitationButtonHandler
 * @param messageText
 * @param invitationButtonText
 * @param modalTitle
 */
const UserInvitationManagementModal = ({
  onClose,
  invitationButtonHandler,
  messageText,
  invitationButtonText,
  modalTitle,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <BaseModal title={modalTitle} onClose={onClose}>
      <Typography>{messageText}</Typography>
      <div className={classes.buttons}>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t('buttons.cancel')}
        </Button>
        <Button
          onClick={invitationButtonHandler}
          color="secondary"
          variant="contained"
        >
          {invitationButtonText}
        </Button>
      </div>
    </BaseModal>
  );
};

UserInvitationManagementModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  invitationButtonHandler: PropTypes.func.isRequired,
  messageText: PropTypes.string.isRequired,
  invitationButtonText: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
};

export default memo(UserInvitationManagementModal);
