import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import CustomGroupedLayersControl from '../CustomGroupedLayersControl';

/**
 * Control with extra layers to display geometries.
 * @param {Array} tileGeometries
 * @param {Boolean} autoZIndex
 * @param {Boolean} noWrap
 * @param {React.ReactNode} children
 * @return {jsx}
 */
const MapGeometriesControl = ({ autoZIndex, zIndex, geometries, children }) => {
  /*
   * This callback is used to add the geometries and clear them
   * before adding them again in order to refrest leaflet
   * checked is the id's of the checked layers
   * and the checked layers are added to the map to make them appear
   */
  const addGeometriesHandler = useCallback(
    (group) => {
      let checked = group.getCheckedLayers();
      group.removeGeometries();
      geometries.forEach((geometry) => {
        geometry.layers.forEach((layer) => {
          group.addGeometry(layer, geometry.name);
          group.setStyle(layer.name, {
            color: layer.color,
          });
          /* Needed in order to fix a display bug,
           * the cursor would be underneath the geometries
           * and thus the new point couldn't be added on a geometry
           */
          const lLayer = group.getLayerByName(layer.name);
          if (lLayer !== undefined) {
            // eslint-disable-next-line
            lLayer.layer.on('add', (event) => {
              event.target.bringToBack();
            });
          }
        });
      });
      checked = checked.filter(({ name }) =>
        group.getLayersName().includes(name)
      );
      checked.forEach((layer) => {
        group.addLayerToMap(layer.name);
      });
    },
    [geometries]
  );

  return (
    <CustomGroupedLayersControl
      zIndex={zIndex}
      autoZIndex={autoZIndex}
      onAddGeometries={addGeometriesHandler}
      geometries={geometries}
      multiply
    >
      {children}
    </CustomGroupedLayersControl>
  );
};

MapGeometriesControl.propTypes = {
  geometries: PropTypes.array.isRequired,
  children: PropTypes.node,
  autoZIndex: PropTypes.bool,
};

export default MapGeometriesControl;
