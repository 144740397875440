import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles(({ palette }) => ({
  container: {
    padding: 5,
    borderRadius: 5,
    color: palette.lightGrey.light,
    backgroundColor: alpha(palette.black.main, 0.6),
  },
}));
