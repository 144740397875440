import { toCamelCase } from 'helpers/camelizer';
import { getCustomPercentToColorConverterByBreakpoints } from 'helpers/color';
import { SCATTER_PLOT_COLOR_RANGE_BREAKPOINTS } from 'constants/color';
import { getGridTicksByMinMax } from 'helpers/graphs/common';
import { capitalizeFirstLetter } from 'helpers/common';
import {
  MIN_ERROR_SCATTER_PLOT_TICKS_AMOUNT,
  MIN_SCATTER_PLOT_TICKS_AMOUNT,
} from 'constants/graphs';

const DEFAULT_CIRCLE_RADIUS = 2;

/**
 * Get circle point color by value;
 * @param {Number} value
 * @param {Number} maxValue
 * @return {string}
 */
const getScatterColorByPercent = (value, maxValue = 100) => {
  const perPercent = maxValue / 100;
  const percent = Math.min(value / perPercent, 100);

  const converter = getCustomPercentToColorConverterByBreakpoints(
    SCATTER_PLOT_COLOR_RANGE_BREAKPOINTS
  );

  return converter(percent);
};

/**
 * Get start point regression line
 * @param {Number} minValue
 * @param {Number} maxValue
 * @param {Number} slope
 * @param {Number} intercept
 * @return {{ regress: number, x: number }}
 */
const getRegressionLineStartPoint = (
  minValue,
  maxValue,
  { slope, intercept }
) => {
  const startRegressValue = intercept + minValue * slope;

  if (startRegressValue < minValue) {
    return {
      x: (minValue - intercept) / slope,
      regress: minValue,
    };
  }

  return {
    x: minValue,
    regress: startRegressValue,
  };
};

/**
 * Get end point regression line
 * @param {Number} minValue
 * @param {Number} maxValue
 * @param {Number} slope
 * @param {Number} intercept
 * @return {{ regress: number, x: number }}
 */
const getRegressionLineEndPoint = (
  minValue,
  maxValue,
  { slope, intercept }
) => {
  const endRegressValue = intercept + maxValue * slope;

  if (endRegressValue > maxValue) {
    return {
      x: (maxValue - intercept) / slope,
      regress: maxValue,
    };
  }

  return {
    x: maxValue,
    regress: endRegressValue,
  };
};

/**
 * Calculate point occurrence by radius
 * @param {Number} radius
 * @param {Number} nbTimeSteps
 * @param {Number} digits
 * @return {string}
 */
const calculateOccurrence = (radius, nbTimeSteps, digits = 2) => {
  const occurrence = (
    (100 * radius) /
    (nbTimeSteps * DEFAULT_CIRCLE_RADIUS)
  ).toFixed(digits);

  return Number(occurrence) === 0
    ? calculateOccurrence(radius, nbTimeSteps, digits + 1)
    : occurrence;
};

/**
 * Get radius by empirical variables
 * @param minRadius
 * @param maxRadius
 * @param radius
 * @return {number}
 */
export const getCustomScatterCircleRadius = (minRadius, maxRadius, radius) => {
  const calculatedRadius =
    minRadius !== maxRadius
      ? Math.sqrt((radius - minRadius) / (maxRadius - minRadius))
      : 0;

  return 18.5 * calculatedRadius + 1.5;
};

/**
 * Prepare scatter plot raw data for chart
 * @param rawData
 * @return {{
 *   ticks: number[],
 *   axisLabels: { x: string, y: string },
 *   values: *[],
 *   axisInfo: {
 *     x: { units: string },
 *     y: { units: string }
 *   },
 *   lineRegress: object,
 *   error: { units: string }
 * }}
 */
export const getPreparedScatterPlotData = (rawData) => {
  const {
    varNames: [measureKey, modelKey],
    radii: radiiData,
    error: errorData,
    linregress: lineRegressData,
    nbTimeSteps,
  } = rawData;

  const measureData = rawData[measureKey] || rawData[toCamelCase(measureKey)];
  const modelData = rawData[modelKey] || rawData[toCamelCase(modelKey)];
  const { attributes: modelAttributes } = modelData;
  const units = modelAttributes.units.replace('degrees', '°');

  const modelMin = Math.min.apply(null, modelData.values);
  const measureMin = Math.min.apply(null, measureData.values);

  const modelMax = Math.max.apply(null, modelData.values);
  const measureMax = Math.max.apply(null, measureData.values);

  const minValue = Math.floor(Math.min(modelMin, measureMin));
  const maxValue = Math.ceil(Math.max(modelMax, measureMax));

  const ticks = getGridTicksByMinMax({
    min: minValue,
    max: maxValue,
    minTicks: MIN_SCATTER_PLOT_TICKS_AMOUNT,
    valueAsMaxTick: true,
  });

  const biasStart = { x: minValue, bias: minValue };
  const biasEnd = { x: maxValue, bias: maxValue };

  const radiusMin = Math.min.apply(null, radiiData);
  const radiusMax = Math.max.apply(null, radiiData);

  const errorMin = Math.min.apply(null, errorData.values);
  const errorMax = Math.max.apply(null, errorData.values);

  const errorTicks = getGridTicksByMinMax({
    min: errorMin,
    max: errorMax,
    minTicks: MIN_ERROR_SCATTER_PLOT_TICKS_AMOUNT,
  });

  const values = modelData.values.reduce((acc, model, index) => {
    const radius = radiiData[index];
    const measure = measureData.values[index];
    const error = errorData.values[index];

    if (model && measure) {
      const occurrence = calculateOccurrence(radius, nbTimeSteps);

      acc.push({
        x: model,
        occurrence,
        y: measure,
        r: getCustomScatterCircleRadius(radiusMin, radiusMax, radius),
        hoverR: getCustomScatterCircleRadius(
          radiusMin,
          radiusMax,
          radius * 1.5
        ),
        color: getScatterColorByPercent(error, errorMax),
        error,
      });
    }

    return acc;
  }, []);

  const lineRegressStartPoint = getRegressionLineStartPoint(
    minValue,
    maxValue,
    lineRegressData
  );

  const lineRegressEndPoint = getRegressionLineEndPoint(
    minValue,
    maxValue,
    lineRegressData
  );

  return {
    values: [
      lineRegressStartPoint,
      biasStart,
      ...values,
      biasEnd,
      lineRegressEndPoint,
    ],
    ticks,
    errorTicks,
    axisLabels: {
      bottom: `Model (${modelData.run}) [${units}]`,
      left: `Measure (${measureData.run}) [${units}]`,
      right: `Error [${units}]`,
      top: `${capitalizeFirstLetter(
        modelData.attributes.longName
      )} scatter plot`,
    },
    lineRegress: {
      slope: lineRegressData.slope.toFixed(3),
      r2: lineRegressData.r2.toFixed(2),
      intercept: `${lineRegressData.intercept.toFixed(3)} ${units}`,
    },
    axisInfo: {
      error: {
        units: errorData.attributes.units,
      },
      x: {
        units: modelData.attributes.units,
      },
      y: {
        units: measureData.attributes.units,
      },
    },
  };
};
