import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  statsContainer: {
    margin: '0 58px',
  },
  statsTitle: {
    color: palette.primary.main,
    fontWeight: 'bold',
    fontSize: 24,
    padding: '15px 0px',
  },
  statsTextContainer: {
    marginBottom: 20,
    display: 'flex',
    gap: 4,
    fontSize: 16,
  },
  statsText: {
    fontWeight: 800,
  },
  flexContainer: {
    display: 'flex',
    justifyItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 30,
    marginBottom: 50,
  },
  statsSubContainer: {
    width: '100%',
    '@media(min-width: 800px)': {
      width: '45%',
    },
    '@media(min-width: 1200px)': {
      width: '30%',
    },
    borderRadius: 8,
    margin: 0,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
    borderTop: `8px solid ${palette.sinay.primary['40']}`,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    textDecoration: 'none',
    textAlign: 'left',
    outline: 'none',
    cursor: 'pointer',
  },
  statsSubTitle: {
    color: palette.sinay.primary['40'],
    fontWeight: 'bold',
    height: 80,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: 24,
  },
  imgContainer: {
    width: '100%',
    height: 350,
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    width: 300,
  },
  overlay: {
    width: '100%',
    height: '100vh',
    position: 'fixed',
    top: 0,
    background: '#33333330',
    cursor: 'pointer',
    border: 0,
    padding: 0,
    margin: 0,
    textDdecoration: 'none',
    outline: 'none',
  },
  popupContainer: {
    width: '90%',
    maxHeight: '90vh',
    margin: 'auto',
    padding: 40,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: '#FFFFFF',
    overflowY: 'auto',
    borderRadius: 15,
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.4)',
    zIndex: 1,
  },
  detailsContainer: {
    marginTop: 20,
    display: 'flex',
    gap: 20,
  },
  imgPopupContainer: {
    width: '100%',
    height: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media(max-width: 1510px)': {
      display: 'none',
    },
  },
  contentContainer: {
    width: '100%',
    maxWidth: 1200,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 20,
  },
  detailsbutton: {
    padding: '5px 10px',
    marginBottom: 8,
    fontSize: 14,
    color: '#525252',
    background: '#FAFAFA',
    borderRadius: 10,
    border: 0,
    width: 'fit-content',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 0.3,
    '&:hover': {
      background: '#E1E5E6',
    },
  },
  detailssection: {
    color: palette.primary.main,
    fontWeight: 'bold',
    fontSize: 24,
  },
  detailstitle: {
    color: '#525252',
    fontWeight: 'bold',
    fontSize: 36,
  },
  detailscontent: {
    fontSize: 16,
    fontFamily: 'Inter',
  },
  imgHidden: {
    width: '100%',
    height: 500,
    display: 'none',
    '@media(max-width: 1510px)': {
      marginTop: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));
