export const thetawAnnualData = {
  id: 'WIN-magw-EPD-thetaw-vclass_3136',
  point: {
    id: '3136',
    name: '#3136',
  },
  layer: 'WIND',
  type: 'EPD',
  name: 'Empirical probability distribution',
  variables: {
    names: ['magw'],
    variables: [
      {
        hidden: false,
        long_name: 'wind speed',
        units: 'm.s-1',
        name: 'magw',
      },
    ],
  },
  visualization: {
    driver: {
      method: 'plot_bars',
      suffix: null,
      path:
        // eslint-disable-next-line no-template-curly-in-string
        '/mnt/glusterfs/projects/dev-ma/MA/noveo/Biscay2/Bay_of_Biscay_-_North/WIND/Visualization/WIN-magw-EPD-thetaw-vclass/3136_WIN-magw-EPD-thetaw-vclass_all${PER}__plot_bars.png',
      fileformat: '.png',
    },
    kwargs: {
      grid: true,
      ylabel: 'Probability distribution [%]',
      condensed_view: false,
      xName: 'magw_bin',
      yName: 'magw_pdf',
      xlabel: 'Wind speed [m.s-1]',
    },
    web: {
      'netcdf-uri-0':
        '/static/projects/dev-ma/MA/noveo/Biscay2/Deliverables/Data/3136/WIND/WIN-magw-EPD-thetaw-vclass_3136.nc',
      'matlab-uri-0':
        '/static/projects/dev-ma/MA/noveo/Biscay2/Deliverables/Data/3136/WIND/WIN-magw-EPD-thetaw-vclass_3136.mat',
      'excel-uri-0':
        '/static/projects/dev-ma/MA/noveo/Biscay2/Deliverables/Data/3136/WIND/WIN-magw-EPD-thetaw-vclass_3136.xls',
      'plot_bars-image-uri-0':
        '/static/projects/dev-ma/MA/noveo/Biscay2/Bay_of_Biscay_-_North/WIND/Visualization/WIN-magw-EPD-thetaw-vclass/3136_WIN-magw-EPD-thetaw-vclass_all%24%7BPER%7D__plot_bars.png',
      'data-uri-0':
        '/api/projects/909/get_visu_data/?stats_id=WIN-magw-EPD-thetaw-vclass_3136',
      'plot_bars-image-legend-0':
        // eslint-disable-next-line no-template-curly-in-string
        'Probability distribution of wind speed per directional sectors at point #3136 ${VCLASS}',
      'data-legend-0':
        // eslint-disable-next-line no-template-curly-in-string
        'Probability distribution of wind speed per directional sectors at point #3136 ${VCLASS}',
    },
  },
  periodicity: 'all',
  thetawClass: {
    values: [
      7.5,
      22.5,
      37.5,
      52.5,
      67.5,
      82.5,
      97.5,
      112.5,
      127.5,
      142.5,
      157.5,
      172.5,
      187.5,
      202.5,
      217.5,
      232.5,
      247.5,
      262.5,
      277.5,
      292.5,
      307.5,
      322.5,
      337.5,
      352.5,
    ],
    attributes: {
      interval: 15.0,
      conventions: 'meteorological',
      standard_name: 'wind_from_direction',
      valid_min: '0',
      valid_max: '360',
      vartype: 'f8',
      units: 'degrees',
      long_name: 'wind direction',
      description: 'center of the bin of thetaw classes',
    },
    dimensions: ['thetaw_class'],
  },
  global_attributes: {
    statistic: 'Empirical probability distribution',
    history: '2020-04-27 09:29: generated by Open Ocean',
    location_name: '#3136',
    longitude: "-3.47992 (3°28'47''W)",
    latitude: "47.55243 (47°33'8''N)",
    srs: 'EPSG:4326',
    period: '1991-01-01 00:00 - 2015-12-31 23:00',
    WIND_dataset: 'France-WRF9k',
    WIND_node_longitude: "-3.45000 (3°27'W)",
    WIND_node_latitude: "47.55000 (47°32'59''N)",
    WIND_node_distance: '2.3 km',
    sampling_period: '1h',
    depth: 57.86632284665696,
  },
  success: true,
  magwBin: {},
  count: {
    values: [
      58831,
      65371,
      81621,
      94387,
      88725,
      78723,
      61487,
      48020,
      41944,
      38476,
      40055,
      44114,
      58732,
      87784,
      122068,
      136924,
      137153,
      138869,
      145058,
      122098,
      90931,
      73576,
      59801,
      56684,
    ],
    attributes: {
      conventions: 'meteorological',
      standard_name: 'wind_from_direction',
      valid_min: '0',
      valid_max: '360',
      vartype: 'i4',
      units: 'degrees',
      longName: 'wind direction',
      description: '',
    },
    dimensions: ['thetaw_class'],
  },
  level: {
    values: [10.0, 25.0, 50.0, 75.0, 100.0, 125.0, 150.0, 175.0, 200.0],
    attributes: {
      conventions: 'positive from seabed',
      standard_name: 'vertical level',
      long_name: 'vertical level',
      vartype: 'f8',
      units: 'm',
      description: 'vertical level, in meters from mean sea level',
    },
    dimensions: ['level'],
  },
  magwPdf: {},
};
