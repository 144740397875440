import { EMPTY_VALUE, SETTINGS_KEY } from 'constants/common';
import { tryJsonParse } from 'helpers/data';

export const getUserSettings = () =>
  tryJsonParse(localStorage.getItem(SETTINGS_KEY)) || EMPTY_VALUE;

export const changeUserSettings = (data) => {
  localStorage.setItem(SETTINGS_KEY, JSON.stringify(data));
  return getUserSettings();
};
