import React from 'react';
import PropTypes from 'prop-types';

/**
 * Creates React element from html
 * @param is - type of created element
 * @param element - html for element
 * @param props- other props
 */
const ReactElementFromHtml = ({ is, element, ...props }) =>
  React.createElement(is, {
    ...props,
    dangerouslySetInnerHTML: {
      __html: element,
    },
  });

ReactElementFromHtml.propTypes = {
  is: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  element: PropTypes.string.isRequired,
};

export default ReactElementFromHtml;
