import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  notificationRoot: {
    top: '20vh',
    whiteSpace: 'pre',
    '& .MuiSnackbarContent-root': {
      display: 'flex',
      flexWrap: 'nowrap',
    },
    '& .MuiAlert-filledError': {
      backgroundColor: theme.palette.sinay.error['90'],
      color: theme.palette.sinay.error['10'],
    },
    '& .MuiAlert-filledWarning': {
      backgroundColor: theme.palette.sinay.tertiary['90'],
      color: theme.palette.sinay.tertiary['10'],
    },
    '& .MuiAlert-filledInfo': {
      backgroundColor: theme.palette.sinay.primary['90'],
      color: theme.palette.sinay.primary['10'],
    },
    '& .MuiAlert-filledSuccess': {
      backgroundColor: theme.palette.sinay.secondary['90'],
      color: theme.palette.sinay.secondary['10'],
    },
  },
}));
