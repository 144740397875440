import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  modal: {
    width: 500,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 20,
  },
  footerContainer: {
    textAlign: 'center',
  },
});
