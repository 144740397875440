import Button from '@material-ui/core/Button';
import React, { memo } from 'react';
import { Plus } from 'phosphor-react';

import { useStyles } from './styles';

/**
 * CreateProjectButton component
 * Floating button for creating a new project.
 * Adds floating class to Button and label class for its label span.
 * @props props will be passed to Button
 * @param {Object} props - other props for button
 * @returns {jsx}
 * @see Button
 */
const CreateProjectButton = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonContainer}>
      <Button
        className={classes.floatingButton}
        classes={{ label: classes.label }}
        startIcon={<Plus size={20} />}
        {...props}
      >
        <span className={classes.textLabel}>New Project</span>
      </Button>
    </div>
  );
};

export default memo(CreateProjectButton);
