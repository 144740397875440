import { AWD_LABEL } from 'constants/tracker';

import { SEND_TRACKING_DATA } from '../types';

export const trackDownloadTemplate = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: AWD_LABEL,
  gtmAction: 'Download template',
});

export const trackAWDHelplink = (helplink) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: AWD_LABEL,
  gtmAction: 'Help Link',
  helplink,
});

export const trackUploadClick = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: AWD_LABEL,
  gtmAction: '1 - Click on upload planning',
});

export const trackUploadedFile = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: AWD_LABEL,
  gtmAction: '2 - Upload a file',
});

export const trackSubmit = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: AWD_LABEL,
  gtmAction: '3 - Submit the planning',
});

export const trackDownload = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: AWD_LABEL,
  gtmAction: '4 - Download planning',
});

export const trackDeleteClick = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: AWD_LABEL,
  gtmAction: 'A - Delete planning click',
});

export const trackDeleteConfirm = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: AWD_LABEL,
  gtmAction: 'B - Delete planning confirm',
});

export const trackPlanningCreationError = (data) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: AWD_LABEL,
  gtmAction: 'Error planning parsing',
  data,
});

export const trackPlanningComputationError = (data) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: AWD_LABEL,
  gtmAction: 'Error planning computation',
  data,
});
