import createReducer from 'helpers/createReducer';
import { prepareErrorShape } from 'helpers/apiErrors';
import {
  EMPTY_ARRAY,
  EMPTY_VALUE,
  MANAGER_ROLE,
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_PENDING,
  STATUS_SUCCESS,
  USER_ROLE,
} from 'constants/common';

import {
  REMOVE_USER_SUCCESS,
  USER_LOGIN_REQUEST_SUCCESS,
  USER_REQUEST_ERROR,
  FETCH_CURRENT_USER_SUCCESS,
  USER_LOGOUT,
  INVITE_USER_ERROR,
  PUT_USER_SETTINGS,
  EDIT_USER_PROFILE_REQUEST_SUCCESS,
  REVOKE_USER_INVITATION_REQUEST_SUCCESS,
  INVITE_USER_SUCCESS,
  CLEAR_USER_ERROR,
  CHECK_IS_REGISTRATION_PENDING_SUCCESS,
  CHECK_IS_REGISTRATION_PENDING,
  CHECK_IS_REGISTRATION_PENDING_ERROR,
} from './types';

const initialState = {
  error: EMPTY_VALUE,
  data: EMPTY_VALUE,
  status: STATUS_NOT_REQUESTED,
  authError: {
    common: EMPTY_ARRAY,
    entityErrors: EMPTY_VALUE,
  },
};

const errorHandler = (state, { error }) => ({
  ...state,
  error,
  status: STATUS_ERROR,
});

export default createReducer(initialState, {
  [USER_LOGIN_REQUEST_SUCCESS]: (state) => ({
    ...state,
    error: EMPTY_VALUE,
    status: STATUS_SUCCESS,
  }),
  [REMOVE_USER_SUCCESS]: (state, { data }) => {
    const updatedAccounts = state.data.accounts.map((account) => ({
      ...account,
      collaborators: account.collaborators.filter(
        (collaborator) => collaborator.id !== data.id
      ),
    }));
    return {
      ...state,
      data: {
        ...state.data,
        accounts: updatedAccounts,
      },
    };
  },
  [FETCH_CURRENT_USER_SUCCESS]: (state, { user }) => ({
    ...state,
    error: EMPTY_VALUE,
    data: user,
    status: STATUS_SUCCESS,
  }),
  [USER_LOGOUT]: (state) => ({
    ...state,
    data: EMPTY_VALUE,
    status: STATUS_NOT_REQUESTED,
  }),
  [USER_REQUEST_ERROR]: (state, { error }) => ({
    ...state,
    status: STATUS_ERROR,
    authError: prepareErrorShape(error.auth),
  }),
  [INVITE_USER_ERROR]: errorHandler,
  [PUT_USER_SETTINGS]: (state, { value }) => ({
    ...state,
    settings: value,
    status: STATUS_SUCCESS,
  }),
  [EDIT_USER_PROFILE_REQUEST_SUCCESS]: (state, { user }) => {
    const accounts = [...state.data.accounts];
    const collaborators = [...accounts[0].collaborators];
    const currentUserIndex = collaborators.findIndex(
      ({ id }) => id === user.id
    );
    if (currentUserIndex > 0) {
      collaborators[currentUserIndex] = {
        ...collaborators[currentUserIndex],
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.isStaff ? MANAGER_ROLE : USER_ROLE,
      };
      accounts[0] = { ...accounts[0], collaborators };
    }
    return {
      ...state,
      data: { ...state.data, accounts },
      status: STATUS_SUCCESS,
    };
  },
  [REVOKE_USER_INVITATION_REQUEST_SUCCESS]: (state, { userId }) => {
    const accounts = [...state.data.accounts];
    accounts[0] = {
      ...accounts[0],
      collaborators: [...accounts[0].collaborators].filter(
        (item) => item.id !== userId
      ),
    };
    return {
      ...state,
      data: { ...state.data, accounts },
      status: STATUS_SUCCESS,
    };
  },
  [INVITE_USER_SUCCESS]: (state, { id, email }) => {
    const accounts = [...state.data.accounts];
    accounts[0] = {
      ...accounts[0],
      collaborators: [
        ...accounts[0].collaborators,
        {
          id,
          email,
          username: '',
          firstName: '',
          lastName: '',
          role: STATUS_PENDING,
          usedCredits: 0,
        },
      ],
    };
    return {
      ...state,
      data: { ...state.data, accounts },
      status: STATUS_SUCCESS,
    };
  },
  [CLEAR_USER_ERROR]: (state) => ({
    ...state,
    error: initialState.error,
    authError: initialState.authError,
    status: STATUS_SUCCESS,
  }),
  [CHECK_IS_REGISTRATION_PENDING]: (state) => ({
    ...state,
    status: STATUS_LOADING,
  }),
  [CHECK_IS_REGISTRATION_PENDING_SUCCESS]: (state) => ({
    ...state,
    status: STATUS_SUCCESS,
  }),
  [CHECK_IS_REGISTRATION_PENDING_ERROR]: (state, { error }) => ({
    ...state,
    error: {
      ...state.error,
      registration: error,
    },
    status: STATUS_ERROR,
  }),
});
