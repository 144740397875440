import React, { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import SignUpSuccessModalComponent from 'components/modals/SignUpSuccessModal';
import { useModal } from 'hooks/useModal';
import { ROUTES } from 'constants/routes';

/**
 * SignUpSuccessModal container
 * Contains logic and data for SignUpSuccessModal
 * @param id - modal id
 */
const SignUpSuccessModal = ({ id }) => {
  const history = useHistory();
  const { onClose } = useModal(id);

  const handleClose = useCallback(() => {
    onClose();
    history.push(ROUTES.login);
  }, [history, onClose]);

  return <SignUpSuccessModalComponent onClose={handleClose} />;
};

SignUpSuccessModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default memo(SignUpSuccessModal);
