import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ buttons }) => ({
  advancedBlock: {
    marginTop: 15,
  },
  advancedBlockWrapper: {
    padding: 15,
  },
  checkboxRow: {
    textAlign: 'center',
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      fontWeight: 400,
    },
  },
  checkboxSubtext: {
    marginTop: 5,
    fontSize: 13,
    fontStyle: 'italic',
  },
  fieldRow: {
    marginTop: 25,
    textAlign: 'center',
  },
  fieldName: {
    marginBottom: 5,
    fontSize: 14,
    fontWeight: 700,
  },
  submitButton: {
    ...buttons.outlined.secondary,
    margin: '10px 0',
  },
  cancelReportGenerationButton: {
    ...buttons.elevated.neutral,
    margin: '10px 0',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
