import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  warningIcon: {
    backgroundColor: 'orange',
  },
  errorIcon: {
    backgroundColor: palette.danger.main,
  },
  modal: {
    width: '600px',
  },
  smallMargin: {
    margin: '10px',
  },
}));
