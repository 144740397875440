import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { DropHalfBottom, X } from 'phosphor-react';
import { get } from 'lodash';

import { useToggle } from 'hooks/useToggle';
import DatasetCardList from 'components/projects/DataValidation/DatasetCardList';
import DatasourceSummary from 'components/projects/DataValidation/DatasourceSummary';
import ZoneInfo from 'components/projects/DataValidation/ZoneInfo';

import { useStyles } from './styles';

/**
 * DataValidationOverview
 * @param {array} numericalSimulationsItems List of dataset
 * @param {Object} zone
 * @param {array} zoneDatasetList
 * @param {string} numericalSimulation
 * @returns {jsx}
 */
const DataValidationOverview = ({
  numericalSimulationsItems,
  zone,
  zoneDatasetList,
  numericalSimulation,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isActive, toggle } = useToggle(false);

  const selectedZoneDataset = useMemo(
    () => get(zone, [numericalSimulation, 0]),
    [zone, numericalSimulation]
  );

  return (
    <div id="DV-overview-container">
      <DatasetCardList
        numericalSimulation={numericalSimulation}
        numericalSimulationsItems={numericalSimulationsItems}
        zoneDatasetList={zoneDatasetList}
      />
      <div className={classes.overviewContentContainer}>
        <Button
          className={classes.environmentalConditionsButton}
          onClick={toggle}
        >
          <DropHalfBottom size={22} />
          {t('dataValidation.environmentalConditions')}
        </Button>
        <div className={classes.descriptionContainer}>
          {isActive ? (
            <div className={classes.envDescriptionContainer}>
              <div>
                <X size={22} onClick={toggle} className={classes.closeButton} />
              </div>
              <ZoneInfo zone={zone} />
            </div>
          ) : (
            <DatasourceSummary selectedZoneDataset={selectedZoneDataset} />
          )}
        </div>
      </div>
    </div>
  );
};

DataValidationOverview.propTypes = {
  numericalSimulationsItems: PropTypes.array.isRequired,
  zone: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  zoneDatasetList: PropTypes.array.isRequired,
  numericalSimulation: PropTypes.string.isRequired,
};

export default React.memo(DataValidationOverview);
