import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  table: {
    width: '90%',
  },
  buttonsContainer: {
    marginTop: '20px',
  },
}));
