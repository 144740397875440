import {
  STATUS_LOADING,
  STATUS_ERROR,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
  EMPTY_VALUE,
} from 'constants/common';
import createReducer from 'helpers/createReducer';
import { normalizeArray } from 'helpers/data';
import { prepareAndSetError, prepareErrorShape } from 'helpers/apiErrors';

import {
  REQUEST_PROJECTS,
  REQUEST_PROJECTS_SUCCESS,
  REQUEST_PROJECTS_ERROR,
  REQUEST_PROJECT,
  REQUEST_PROJECT_SUCCESS,
  REQUEST_PROJECT_ERROR,
  DELETE_PROJECT_SUCCESS,
  RENAME_PROJECT,
  RENAME_PROJECT_SUCCESS,
  RENAME_PROJECT_ERROR,
  CREATE_PROJECT,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_ERROR,
  CREATE_TRIAL_PROJECT,
  CREATE_TRIAL_PROJECT_SUCCESS,
  CREATE_TRIAL_PROJECT_ERROR,
  CREATE_DEMO_PROJECT,
  CREATE_DEMO_PROJECT_SUCCESS,
  CREATE_DEMO_PROJECT_ERROR,
  CLEAR_PROJECTS_ERROR,
  COMPUTE_PROJECT_STATISTICS_SUCCESS,
  REQUEST_PROJECT_STATS_SUCCESS,
  REQUEST_PROJECT_STATS,
  REQUEST_PROJECT_STATS_ERROR,
  UPDATE_PROJECT_COLLABORATORS_REQUEST_SUCCESS,
  REQUEST_PROJECT_VISUALIZATION_DATA_ERROR,
  REQUEST_PROJECT_VISUALIZATION_DATA_SUCCESS,
  CANCEL_COMPUTE_PROJECT_STATISTICS_SUCCESS,
  REQUEST_PROJECT_ZONE_SUCCESS,
  REQUEST_PROJECT_ZONE_ERROR,
  DOWNLOAD_GRAPH_FILE,
  DOWNLOAD_GRAPH_FILE_SUCCESS,
  DOWNLOAD_GRAPH_FILE_ERROR,
  DOWNLOAD_GRAPH_RESET,
  COMPUTE_PROJECT_STATISTICS_ERROR,
} from './types';

const initialState = {
  data: EMPTY_VALUE,
  ids: [],
  error: EMPTY_VALUE,
  status: STATUS_NOT_REQUESTED,
  downloadStatuses: EMPTY_VALUE,
};

const setProject = (state, { project }) => {
  const { [project.id]: projectData = {}, ...data } = state.data;
  data[project.id] = { ...projectData, ...project };

  return {
    ...state,
    data,
    ids: !state.ids.includes(project.id)
      ? [project.id, ...state.ids]
      : state.ids,
    status: STATUS_SUCCESS,
    error: EMPTY_VALUE,
  };
};

const setProjects = (state, { data }) => ({
  ...state,
  ...normalizeArray(data),
  status: STATUS_SUCCESS,
});

const setLoading = (state) => ({ ...state, status: STATUS_LOADING });

const setErrorStatus = (state, { error }) => ({
  ...state,
  error,
  status: STATUS_ERROR,
});

const setError = (state, { error }) => ({
  ...state,
  status: STATUS_SUCCESS,
  error,
});

const setVisualizationData = (state, { projectId, visualizationData }) => {
  const { [projectId]: project, ...data } = state.data;
  data[projectId] = {
    ...project,
    visualizationData,
  };

  return { ...state, data };
};

const setUpdatedCollaborators = (state, { projects }) => {
  const { data: projectsData } = state;

  return {
    ...state,
    data: projects.reduce(
      (acc, { id, collaborators }) => {
        acc[id] = {
          ...state.data[id],
          collaborators,
        };
        return acc;
      },
      { ...projectsData }
    ),
  };
};

const setProjectStats = (state, { id, stats, overviewStats }) => ({
  ...state,
  data: {
    ...state.data,
    [id]: {
      ...state.data[id],
      overviewStats: normalizeArray(overviewStats),
      stats: {
        ...stats,
        status: STATUS_SUCCESS,
      },
    },
  },
});

const setProjectStatsLoadingStatus = (state, { projectId }) => ({
  ...state,
  data: {
    ...state.data,
    [projectId]: {
      ...state.data[projectId],
      stats: {
        status: STATUS_LOADING,
      },
    },
  },
});

const setProjectZone = (state, { projectId, zone }) => {
  const { [projectId]: project, ...data } = state.data;
  data[projectId] = {
    ...project,
    zone,
  };

  return { ...state, data };
};

const deleteProject = (state, { id }) => {
  const { [id]: deleted, ...data } = state.data;
  return {
    ...state,
    ids: state.ids.filter((currentId) => currentId !== id),
    data,
  };
};

const renameProject = (state, { id, projectName }) => {
  const currentProject = state.data[id];
  currentProject.name = projectName;
  return {
    ...state,
    data: { ...state.data, currentProject },
  };
};

const clearError = (state) => ({
  ...state,
  error: initialState.error,
});

const setDownloadStatus = (status) => (state, { id, error }) => ({
  ...state,
  downloadStatuses: {
    ...state.downloadStatuses,
    [id]: status,
  },
  error:
    status === STATUS_ERROR
      ? prepareErrorShape(error.message)
      : initialState.error,
});

const resetDownloadStatuses = (state) => ({
  ...state,
  downloadStatuses: EMPTY_VALUE,
});

export default createReducer(initialState, {
  [REQUEST_PROJECTS]: setLoading,
  [REQUEST_PROJECT]: setLoading,
  [CREATE_PROJECT]: setLoading,
  [CREATE_TRIAL_PROJECT]: setLoading,
  [CREATE_DEMO_PROJECT]: setLoading,
  [REQUEST_PROJECTS_SUCCESS]: setProjects,
  [REQUEST_PROJECT_SUCCESS]: setProject,
  [CREATE_PROJECT_SUCCESS]: setProject,
  [CREATE_TRIAL_PROJECT_SUCCESS]: setProject,
  [CREATE_DEMO_PROJECT_SUCCESS]: setProject,
  [COMPUTE_PROJECT_STATISTICS_SUCCESS]: setProject,
  [CANCEL_COMPUTE_PROJECT_STATISTICS_SUCCESS]: setProject,
  [DELETE_PROJECT_SUCCESS]: deleteProject,
  [RENAME_PROJECT]: setLoading,
  [RENAME_PROJECT_SUCCESS]: renameProject,
  [RENAME_PROJECT_ERROR]: setError,
  [REQUEST_PROJECT_STATS]: setProjectStatsLoadingStatus,
  [REQUEST_PROJECT_STATS_SUCCESS]: setProjectStats,
  [UPDATE_PROJECT_COLLABORATORS_REQUEST_SUCCESS]: setUpdatedCollaborators,
  [REQUEST_PROJECT_VISUALIZATION_DATA_SUCCESS]: setVisualizationData,
  [REQUEST_PROJECT_ZONE_SUCCESS]: setProjectZone,
  [CREATE_PROJECT_ERROR]: setError,
  [CREATE_TRIAL_PROJECT_ERROR]: setError,
  [CREATE_DEMO_PROJECT_ERROR]: setError,
  [REQUEST_PROJECT_ERROR]: setErrorStatus,
  [REQUEST_PROJECTS_ERROR]: setErrorStatus,
  [REQUEST_PROJECT_STATS_ERROR]: setErrorStatus,
  [REQUEST_PROJECT_VISUALIZATION_DATA_ERROR]: setErrorStatus,
  [REQUEST_PROJECT_ZONE_ERROR]: setErrorStatus,
  [CLEAR_PROJECTS_ERROR]: clearError,
  [DOWNLOAD_GRAPH_FILE]: setDownloadStatus(STATUS_LOADING),
  [DOWNLOAD_GRAPH_FILE_SUCCESS]: setDownloadStatus(STATUS_SUCCESS),
  [DOWNLOAD_GRAPH_FILE_ERROR]: setDownloadStatus(STATUS_ERROR),
  [DOWNLOAD_GRAPH_RESET]: resetDownloadStatuses,
  [COMPUTE_PROJECT_STATISTICS_ERROR]: prepareAndSetError,
});
