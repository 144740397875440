import React, { memo } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import CommonLayout from 'components/layouts/CommonLayout';
import ProjectLayout from 'components/layouts/ProjectLayout';
import { ExampleForm } from 'components/examples/ExampleForm';
import { Dashboard } from 'containers/projects/Dashboard';
import { ROUTES } from 'constants/routes';
import Project from 'containers/projects/Project';
import SelectZone from 'containers/zones/SelectZone';
import MyProfilePage from 'containers/peoples/MyProfilePage';
import PeoplePage from 'containers/peoples/PeoplePage';
import { Graphs } from 'components/examples/Graphs';
import PeopleLayout from 'components/layouts/PeopleLayout';
import MyPlansPage from 'containers/plans/MyPlansPage';
import DataValidation from 'containers/projects/DataValidation';
import HelpPageContainer from 'containers/common/HelpPageContainer/index';
import HelpLayout from 'components/layouts/HelpLayout';
import Downtime from 'containers/projects/Downtime';
import LoaderLayout from 'components/layouts/LoaderLayout';
import LoaderPage from 'components/common/LoaderPage';
import Activity from 'components/projects/Activity';
import UsagePage from 'containers/usage/UsagePage';

/**
 * Routes Component. Manages all application routes
 * Takes isAuthorized prop to manage public/private routes
 * @param { Boolean } isAuthorized
 * @param { Boolean } isExpired
 */
const Routes = ({ isAuthorized, isExpired }) => (
  <Switch>
    {/* PLANS PAGES */}
    <CommonLayout
      header="My plans"
      component={MyPlansPage}
      isAuthorized={isAuthorized}
      path={ROUTES.plans}
      exact
    />

    {/* EXPIRATION REDIRECT */}
    {isExpired && <Redirect from="*" to={ROUTES.plans} />}

    {/* HOME PAGES */}
    <CommonLayout
      header="My projects"
      component={Dashboard}
      isAuthorized={isAuthorized}
      path={ROUTES.home}
      exact
    />
    <CommonLayout
      header="Create new project"
      component={SelectZone}
      isAuthorized={isAuthorized}
      path={ROUTES.createProject}
      exact
    />

    {/* PROJECT DETAILS PAGES */}
    <ProjectLayout
      component={Project}
      isAuthorized={isAuthorized}
      path={ROUTES.project}
    />
    <ProjectLayout
      component={DataValidation}
      isAuthorized={isAuthorized}
      path={ROUTES.dataValidation}
    />
    <ProjectLayout
      component={Downtime}
      isAuthorized={isAuthorized}
      path={ROUTES.downtime}
    />
    <ProjectLayout
      component={Activity}
      isAuthorized={isAuthorized}
      path={ROUTES.catalog}
    />

    {/* TEAM PAGES */}
    <PeopleLayout
      component={PeoplePage}
      isAuthorized={isAuthorized}
      path={ROUTES.people}
      exact
    />

    {/* HELP PAGES */}
    <HelpLayout
      component={HelpPageContainer}
      isAuthorized={isAuthorized}
      path={ROUTES.help}
      exact
    />

    {/* SETTINGS PAGES */}
    <CommonLayout
      header="Usage analyze"
      component={UsagePage}
      isAuthorized={isAuthorized}
      path={ROUTES.usage}
    />
    <CommonLayout
      header="My profile"
      component={MyProfilePage}
      isAuthorized={isAuthorized}
      path={ROUTES.profile}
      exact
    />

    {/* EXAMPLES PAGES */}
    <CommonLayout
      header="Form example"
      component={ExampleForm}
      isAuthorized={isAuthorized}
      path={`${ROUTES.examples}/form`}
      exact
    />
    <CommonLayout
      header="Graphs examples"
      component={Graphs}
      isAuthorized={isAuthorized}
      path={`${ROUTES.examples}/graphs`}
      exact
    />

    {/* LOADING PAGES */}
    <LoaderLayout
      component={LoaderPage}
      isAuthorized={isAuthorized}
      path={ROUTES.loaderPage}
      exact
    />

    {/* HOME REDIRECT */}
    <Redirect from="*" to={ROUTES.home} />
  </Switch>
);

Routes.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  isExpired: PropTypes.bool.isRequired,
};

export default memo(Routes);
