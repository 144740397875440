import {
  COMPUTE_PLANNING,
  CREATE_PLANNING,
  CREATE_PLANNING_ERROR,
  CREATE_PLANNING_SUCCESS,
  DELETE_PLANNING,
  DELETE_PLANNING_ERROR,
  DELETE_PLANNING_SUCCESS,
  DOWNLOAD_PLANNING,
  DOWNLOAD_PLANNING_ERROR,
  GET_PLANNING_ERROR,
  GET_PLANNING_SUCCESS,
  REQUEST_PLANNINGS_LIST,
  REQUEST_PLANNINGS_LIST_ERROR,
  REQUEST_PLANNINGS_LIST_IF_NEED,
  REQUEST_PLANNINGS_LIST_SUCCESS,
  START_UPDATE_PLANNING,
  STOP_UPDATE_PLANNING,
  UPLOAD_PLANNING,
  UPLOAD_PLANNING_ERROR,
  UPLOAD_PLANNING_SUCCESS,
} from './types';

export const requestPlanningsList = (projectId) => ({
  type: REQUEST_PLANNINGS_LIST,
  projectId,
});

export const requestPlanningsListIfNeed = (projectId) => ({
  type: REQUEST_PLANNINGS_LIST_IF_NEED,
  projectId,
});

export const requestPlanningsListSuccess = (planningsList) => ({
  type: REQUEST_PLANNINGS_LIST_SUCCESS,
  planningsList,
});

export const requestPlanningsListError = (error) => ({
  type: REQUEST_PLANNINGS_LIST_ERROR,
  error,
});

export const deletePlanning = ({ planningId, projectId }) => ({
  type: DELETE_PLANNING,
  planningId,
  projectId,
});

export const deletePlanningSuccess = ({ planningId, projectId }) => ({
  type: DELETE_PLANNING_SUCCESS,
  planningId,
  projectId,
});

export const deletePlanningError = (error) => ({
  type: DELETE_PLANNING_ERROR,
  error,
});

export const uploadPlanning = ({ projectId, file }) => ({
  type: UPLOAD_PLANNING,
  projectId,
  file,
});

export const uploadPlanningSuccess = ({ data }) => ({
  type: UPLOAD_PLANNING_SUCCESS,
  data,
});

export const uploadPlanningError = (error) => ({
  type: UPLOAD_PLANNING_ERROR,
  error,
});

export const createPlanning = ({ projectId, file, user, planningData }) => ({
  type: CREATE_PLANNING,
  projectId,
  file,
  user,
  planningData,
});

export const createPlanningSuccess = ({ projectId, planningData }) => ({
  type: CREATE_PLANNING_SUCCESS,
  projectId,
  planningData,
});

export const createPlanningError = (error) => ({
  type: CREATE_PLANNING_ERROR,
  error,
});

export const startUpdatePlanning = ({ planningId, projectId }) => ({
  type: START_UPDATE_PLANNING,
  planningId,
  projectId,
});

export const stopUpdatePlanning = ({ projectId, planningData }) => ({
  type: STOP_UPDATE_PLANNING,
  projectId,
  planningData,
});

export const getPlanningError = (error) => ({
  type: GET_PLANNING_ERROR,
  error,
});

export const getPlanningSuccess = ({ projectId, planningData }) => ({
  type: GET_PLANNING_SUCCESS,
  projectId,
  planningData,
});

export const downloadPlanning = ({ projectId, planningId }) => ({
  type: DOWNLOAD_PLANNING,
  planningId,
  projectId,
});

export const downloadPlanningError = (error) => ({
  type: DOWNLOAD_PLANNING_ERROR,
  error,
});

export const computePlanning = ({
  projectId,
  newPlanningId,
  preparedPlanningData,
}) => ({
  type: COMPUTE_PLANNING,
  projectId,
  planningId: newPlanningId,
  planningData: preparedPlanningData,
});
