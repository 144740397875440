import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import Footer from 'components/common/Footer/index';
import HelpPageNavBar from 'containers/common/HelpPageNavBar';

/**
 * Local component
 * Displays NavBar without userBar and Component
 */
const HelpLayoutComponent = ({
  isAuthorized,
  location,
  component: Component,
}) => (
  <>
    <div>
      <HelpPageNavBar isAuthorized={isAuthorized} location={location} />
      <Component />
    </div>
    <Footer />
  </>
);

/**
 * Layout for help page
 */
const HelpLayout = ({ isAuthorized, component, location, ...restProps }) => (
  <Route
    render={() => (
      <HelpLayoutComponent
        location={location}
        isAuthorized={isAuthorized}
        component={component}
      />
    )}
    {...restProps}
  />
);

HelpLayout.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
};

export default HelpLayout;
