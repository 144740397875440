import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useModal } from 'hooks/useModal';
import BaseModal from 'components/common/BaseModal';
import GrantAccessToProjectContainer from 'containers/projects/GrantAccessToProject';

import { useStyles } from './styles';

/**
 * GrantAccessToProjectModal container
 * Contains logic and data for GrantAccessToProjectModal
 * @param id - modal id
 * @param {Number} projectId - project id
 * @param {String} name - project title
 */
const GrantAccessToProjectModal = ({ id, projectId, name }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose } = useModal(id);

  return (
    <BaseModal
      title={t('projects.grantAccess.modalTitle', { title: name })}
      onClose={onClose}
      modalClass={classes.container}
    >
      <GrantAccessToProjectContainer
        modalId={id}
        projectId={projectId}
        onSkip={onClose}
      />
    </BaseModal>
  );
};

GrantAccessToProjectModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default React.memo(GrantAccessToProjectModal);
