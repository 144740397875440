/* eslint-disable */
import { takeLatest, put, call } from 'redux-saga/effects';

import {
  getCustomers,
  getCustomerDetail,
  getUsers,
  getUserDetail,
  getOffers,
  getOfferDetail,
} from 'services/usage';

import {
  REQUEST_CUSTOMERS,
  REQUEST_CUSTOMER_DETAIL,
  REQUEST_USERS,
  REQUEST_USER_DETAIL,
  REQUEST_OFFERS,
  REQUEST_OFFER_DETAIL,
} from './types';
import {
  requestCustomersSuccess,
  requestCustomersError,
  requestCustomerDetailSuccess,
  requestCustomerDetailError,
  requestUsersSuccess,
  requestUsersError,
  requestUserDetailSuccess,
  requestUserDetailError,
  requestOffersSuccess,
  requestOffersError,
  requestOfferDetailSuccess,
  requestOfferDetailError,
} from './actions';

export function* requestCustomersWorker() {
  try {
    const customers = yield call(getCustomers);
    yield put(requestCustomersSuccess(customers));
  } catch (error) {
    yield put(requestCustomersError(error));
  }
}

export function* requestCustomerDetailWorker({ id }) {
  try {
    const customer = yield call(getCustomerDetail, id);
    yield put(requestCustomerDetailSuccess(customer));
  } catch (error) {
    yield put(requestCustomerDetailError(error));
  }
}

export function* requestUsersWorker() {
  try {
    const users = yield call(getUsers);
    yield put(requestUsersSuccess(users));
  } catch (error) {
    yield put(requestUsersError(error));
  }
}

export function* requestUserDetailWorker({ id }) {
  try {
    const user = yield call(getUserDetail, id);
    yield put(requestUserDetailSuccess(user));
  } catch (error) {
    yield put(requestUserDetailError(error));
  }
}

export function* requestOffersWorker() {
  try {
    const offers = yield call(getOffers);
    yield put(requestOffersSuccess(offers));
  } catch (error) {
    yield put(requestOffersError(error));
  }
}

export function* requestOfferDetailWorker({ id }) {
  try {
    const offer = yield call(getOfferDetail, id);
    yield put(requestOfferDetailSuccess(offer));
  } catch (error) {
    yield put(requestOfferDetailError(error));
  }
}

export default function* mainSaga() {
  yield takeLatest(REQUEST_CUSTOMERS, requestCustomersWorker);
  yield takeLatest(REQUEST_CUSTOMER_DETAIL, requestCustomerDetailWorker);
  yield takeLatest(REQUEST_USERS, requestUsersWorker);
  yield takeLatest(REQUEST_USER_DETAIL, requestUserDetailWorker);
  yield takeLatest(REQUEST_OFFERS, requestOffersWorker);
  yield takeLatest(REQUEST_OFFER_DETAIL, requestOfferDetailWorker);
}
