import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { trackProjectDeleteConfirm } from 'ducks/trackers/actions/projectManagment';
import ConfirmationModal from 'components/common/ConfirmationModal';

import { useStyles } from './styles';

/**
 * DeleteProjectModal component
 * Modal for approve project deletion
 * @param {function} closeModal
 * @param {function} deleteProject
 * @param {String} projectName
 */
const DeleteProjectModal = ({ closeModal, deleteProject, projectName }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleConfirm = (e) => {
    deleteProject(e);
    dispatch(trackProjectDeleteConfirm());
  };

  return (
    <ConfirmationModal
      title={t('projects.delete.modalTitle')}
      onClose={closeModal}
      onCancel={closeModal}
      onConfirm={handleConfirm}
      modalClass={classes.container}
      warning
    >
      <div className={classes.body}>
        <Typography gutterBottom variant="body1">
          <strong>{`${projectName}`}</strong>&nbsp;
        </Typography>
        <Typography gutterBottom variant="body1">
          {t('projects.delete.sureQuestion')}
        </Typography>
        <br />
        <Typography variant="body1">
          {t('projects.delete.warning.main')}&nbsp;
          <strong>{`${projectName}`}</strong>&nbsp;will&nbsp;
          <strong>{`${t('projects.delete.warning.bold')}`}</strong>
        </Typography>
      </div>
    </ConfirmationModal>
  );
};

DeleteProjectModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  deleteProject: PropTypes.func.isRequired,
  projectName: PropTypes.string,
};

DeleteProjectModal.defaultProps = {
  projectName: '',
};

export default React.memo(DeleteProjectModal);
