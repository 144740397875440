import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import ConfirmationDeleteModal from 'components/modals/ConfirmationDeleteModal';
import { useModal } from 'hooks/useModal';

const ConfirmationDeleteModalContainer = ({
  id,
  onConfirm,
  title,
  message,
}) => {
  const { onClose } = useModal(id);

  const confirmHandler = useCallback(() => {
    onConfirm();
    onClose();
  }, [onClose, onConfirm]);

  return (
    <ConfirmationDeleteModal
      closeModal={onClose}
      onConfirm={confirmHandler}
      title={title}
      message={message}
    />
  );
};

ConfirmationDeleteModalContainer.propTypes = {
  id: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default React.memo(ConfirmationDeleteModalContainer);
