import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import DeleteProjectModalComponent from 'components/modals/DeleteProjectModal';
import { deleteProject } from 'ducks/projects/actions';
import { closeModal } from 'ducks/modals/actions';
import { selectProjectById } from 'ducks/projects/selectors';

/**
 * DeleteProjectModal container
 * Contains logic and data for DeleteProjectModal
 * @param id - modal id
 * @param projectId
 */
const DeleteProjectModal = ({ id, projectId }) => {
  const dispatch = useDispatch();
  const project = useSelector((state) => selectProjectById(state, projectId));

  const close = useCallback(() => {
    dispatch(closeModal({ id }));
  }, [dispatch, id]);

  const deleteHandler = useCallback(() => {
    dispatch(deleteProject(projectId));
    dispatch(closeModal({ id }));
  }, [dispatch, id, projectId]);

  return (
    <DeleteProjectModalComponent
      closeModal={close}
      deleteProject={deleteHandler}
      projectName={project.name}
    />
  );
};

DeleteProjectModal.propTypes = {
  id: PropTypes.string.isRequired,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default React.memo(DeleteProjectModal);
