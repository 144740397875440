import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { DELETE_PROJECT_MODAL, RENAME_PROJECT_MODAL } from 'constants/modals';
import { PROJECTS_SORT_FIELDS } from 'constants/projects';
import { ProjectsToolbar, ProjectList, ProjectDemo } from 'components/projects';
import { requestProjects } from 'ducks/projects/actions';
import {
  selectProjectsIsLoading,
  selectProjectsCollectionWithPoints,
  selectProjectsData,
} from 'ducks/projects/selectors';
import { requestOffers } from 'ducks/offers/actions';
import { selectOffers } from 'ducks/offers/selectors';
import { requestPoints } from 'ducks/points/actions';
import { useModal } from 'hooks/useModal';
import { useDependentsFilters } from 'hooks/useDependentsFilters';
import { hasSubString } from 'helpers/filters';
import {
  selectCurrentUserIsManager,
  selectUserAccountInfo,
} from 'ducks/user/selectors';
import { useUrlQuery } from 'hooks/useUrlQuery';
import { trackSearch } from 'ducks/trackers/actions/search';

const projectsFilters = {
  search: ({ name, zone }, expected) =>
    hasSubString(name, expected) || hasSubString(zone.name, expected),
  offerId: ({ offerInstance }, expectedOfferId) =>
    !expectedOfferId || offerInstance?.id === Number(expectedOfferId),
};

/**
 * Dashboard container
 * Contains logic for Dashboard page
 */
export const Dashboard = () => {
  const dispatch = useDispatch();
  const projects = useSelector(selectProjectsCollectionWithPoints);
  const projectsData = useSelector(selectProjectsData);
  const isLoading = useSelector(selectProjectsIsLoading);
  const offers = useSelector(selectOffers);
  const isManager = useSelector(selectCurrentUserIsManager);
  const userInfo = useSelector(selectUserAccountInfo);
  const [sendRequest, setSendRequest] = useState(false);
  const { openModal } = useModal();

  const isFreeUser = userInfo.pack.name === 'Pack 10 Demo';

  const {
    urlQuery: { ordering = PROJECTS_SORT_FIELDS.idDescending, offerId, search },
    mergeUrlQuery,
  } = useUrlQuery();

  const filteredProjects = useDependentsFilters({
    entities: projects,
    filters: projectsFilters,
    expectedValues: { search, offerId },
  });

  const openDeleteModal = useCallback(
    (projectId) => openModal(DELETE_PROJECT_MODAL, { projectId }),
    [openModal]
  );

  const openRenameModal = useCallback(
    (projectId) => openModal(RENAME_PROJECT_MODAL, { projectId }),
    [openModal]
  );

  const setOrdering = useCallback(
    (order) => mergeUrlQuery({ ordering: order }),
    [mergeUrlQuery]
  );
  const setOfferId = useCallback((id) => mergeUrlQuery({ offerId: id }), [
    mergeUrlQuery,
  ]);
  const setSearch = useCallback(
    (value) => {
      dispatch(trackSearch(value));
      return mergeUrlQuery({ search: value });
    },
    [mergeUrlQuery, dispatch]
  );

  useEffect(() => {
    setSendRequest(true);
    dispatch(requestProjects({ params: { ordering } }));
    dispatch(requestOffers());
    dispatch(requestPoints());
  }, [dispatch, ordering]);

  if (isFreeUser && sendRequest && !isLoading && projects.length === 0) {
    return <ProjectDemo />;
  }
  return (
    <>
      <ProjectsToolbar
        offerId={offerId}
        offers={offers}
        ordering={ordering}
        search={search}
        onTabChange={setOfferId}
        onOrderingChange={setOrdering}
        onSearchChange={setSearch}
      />
      <ProjectList
        projects={filteredProjects}
        deleteProject={openDeleteModal}
        renameProject={openRenameModal}
        canDeleteProject={isManager}
        canCreateProject={isManager}
        canRenameProject={isManager}
        projectsData={projectsData}
        hasProjects={!!projects.length}
        isLoading={isLoading}
      />
    </>
  );
};
