import {
  REQUEST_BUY_MAPS,
  REQUEST_BUY_MAPS_SUCCESS,
  REQUEST_BUY_MAPS_ERROR,
  REQUEST_GET_MAPS,
  REQUEST_GET_MAPS_IF_NEED,
  REQUEST_GET_MAPS_SUCCESS,
  REQUEST_GET_MAPS_ERROR,
  REQUEST_GET_TILE_PROPERTIES_ERROR,
  REQUEST_GET_TILE_PROPERTIES_SUCCESS,
} from './types';

export const requestBuyMaps = (payload) => ({
  type: REQUEST_BUY_MAPS,
  payload,
});

export const requestBuyMapsSuccess = (map) => ({
  type: REQUEST_BUY_MAPS_SUCCESS,
  map,
});

export const requestBuyMapsError = (error) => ({
  type: REQUEST_BUY_MAPS_ERROR,
  error,
});

export const requestGetMaps = (params) => ({
  type: REQUEST_GET_MAPS,
  params,
});

export const requestGetMapsIfNeed = (params) => ({
  type: REQUEST_GET_MAPS_IF_NEED,
  params,
});

export const requestGetMapsSuccess = (maps) => ({
  type: REQUEST_GET_MAPS_SUCCESS,
  maps,
});

export const requestGetMapsError = (error) => ({
  type: REQUEST_GET_MAPS_ERROR,
  error,
});

export const requestGetTilePropertiesSuccess = (properties) => ({
  type: REQUEST_GET_TILE_PROPERTIES_SUCCESS,
  properties,
});

export const requestGetTilePropertiesError = (error) => ({
  type: REQUEST_GET_TILE_PROPERTIES_ERROR,
  error,
});
