import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'recharts';

import { DEFAULT_AXIS_INDEX } from 'constants/graphs';

/**
 * Render point projection
 * @param {{ x: number, y: number }} point
 * @param {Object} yAxisMap
 * @param {Object} xAxisMap
 * @param {Boolean} isAnimationActive
 * @param {String} stroke
 * @param {String} strokeDasharray
 * @param {Number} strokeWidth
 * @return {jsx}
 */
const ProjectionLines = ({
  point,
  yAxisMap,
  xAxisMap,
  isAnimationActive,
  stroke,
  strokeDasharray,
  strokeWidth,
}) => {
  const { height: yHeight, width, x: originX, domain: yDomain } = yAxisMap[
    DEFAULT_AXIS_INDEX
  ];
  const { width: xWidth, y: originY, domain: xDomain } = xAxisMap[
    DEFAULT_AXIS_INDEX
  ];
  const [xStartValue, xEndValue] = xDomain;
  const xPart = (point.x - xStartValue) / (xEndValue - xStartValue);

  const [yStartValue, yEndValue] = yDomain;
  const yPart = (point.y - yStartValue) / (yEndValue - yStartValue);

  const originPoint = { x: width + originX, y: originY };

  const endPoint = {
    x: originPoint.x + xWidth * xPart,
    y: originPoint.y - yHeight * yPart,
  };

  return (
    <g>
      <Line
        points={[{ x: endPoint.x, y: originPoint.y }, endPoint]}
        isAnimationActive={isAnimationActive}
        dot={false}
        stroke={stroke}
        strokeDasharray={strokeDasharray}
        strokeWidth={strokeWidth}
      />
      <Line
        points={[{ x: originPoint.x, y: endPoint.y }, endPoint]}
        isAnimationActive={isAnimationActive}
        dot={false}
        stroke={stroke}
        strokeDasharray={strokeDasharray}
        strokeWidth={strokeWidth}
      />
    </g>
  );
};

ProjectionLines.propTypes = {
  point: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }).isRequired,
  yAxisMap: PropTypes.shape({
    [DEFAULT_AXIS_INDEX]: PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      x: PropTypes.number,
      domain: PropTypes.arrayOf(PropTypes.number),
    }),
  }).isRequired,
  xAxisMap: PropTypes.shape({
    [DEFAULT_AXIS_INDEX]: PropTypes.shape({
      height: PropTypes.number,
      y: PropTypes.number,
      domain: PropTypes.arrayOf(PropTypes.number),
    }),
  }).isRequired,
  isAnimationActive: PropTypes.bool,
  stroke: PropTypes.string,
  strokeDasharray: PropTypes.string,
  strokeWidth: PropTypes.number,
};

export default ProjectionLines;
