import { cloneElement, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon, Marker } from 'leaflet';

import { EMPTY_FUNCTION } from 'constants/common';
import { injectPropertiesToElement } from 'helpers/map';
import { useExpensiveRef } from 'hooks/useExpensiveRef';

/**
 * MapMarker - Marker for leaflet map.
 * @param {Array} position - array with [lat, lng]
 * @param {String} iconUrl
 * @param {String} [title]
 * @param {Boolean} selected
 * @param {Boolean} allowOpenPopupOnSelect
 * @param {Array} iconSize - array with size. For example [12, 12].
 * @param {Array} iconAnchor - array with size. For example [12, 12].
 * @param {Array} popupAnchor - array with size. For example [12, 12].
 * @param {Function} onClick.
 * @param {Function} add - callback to add polygon to feature group.
 * @param {Function} remove - callback to remove polygon from feature group.
 * @param {Object} customProps
 * @param {ReactNode} children
 * @returns {ReactNode|null}
 */
export const MapMarker = ({
  customProps,
  add,
  remove,
  onClick,
  position,
  selected,
  allowOpenPopupOnSelect,
  iconUrl,
  title,
  iconSize,
  iconAnchor,
  popupAnchor,
  children,
}) => {
  const markerRef = useExpensiveRef(() => new Marker(position, { title }));

  useEffect(() => {
    const icon = new Icon({
      iconUrl,
      iconSize,
      iconAnchor,
      popupAnchor,
    });
    markerRef.current.setIcon(icon);
  }, [markerRef, iconUrl, iconSize, iconAnchor, popupAnchor]);

  useEffect(() => {
    if (!allowOpenPopupOnSelect) {
      return;
    }

    setTimeout(() => {
      if (selected) {
        return markerRef.current.openPopup();
      }
      if (markerRef.current.isPopupOpen()) {
        return markerRef.current.closePopup();
      }
    });
  }, [selected, markerRef, allowOpenPopupOnSelect]);

  useEffect(() => {
    markerRef.current.setLatLng(position);
  }, [markerRef, position]);

  useEffect(() => {
    if (onClick) {
      const marker = markerRef.current;
      marker.on('click', onClick);

      return () => marker.off('click');
    }
  }, [markerRef, onClick]);

  useEffect(() => {
    const marker = markerRef.current;

    add(marker);

    return () => remove(marker);
  }, [markerRef, add, remove]);

  useEffect(() => {
    injectPropertiesToElement(markerRef.current, customProps);
  }, [markerRef, customProps]);

  return children
    ? cloneElement(children, { leafletElement: markerRef.current })
    : null;
};

MapMarker.propTypes = {
  position: PropTypes.arrayOf(PropTypes.number).isRequired,
  iconUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
  iconSize: PropTypes.arrayOf(PropTypes.number).isRequired,
  iconAnchor: PropTypes.arrayOf(PropTypes.number).isRequired,
  popupAnchor: PropTypes.arrayOf(PropTypes.number).isRequired,
  allowOpenPopupOnSelect: PropTypes.bool,
  selected: PropTypes.bool,
  add: PropTypes.func,
  customProps: PropTypes.object,
  remove: PropTypes.func,
};

MapMarker.defaultProps = {
  add: EMPTY_FUNCTION,
  remove: EMPTY_FUNCTION,
  allowOpenPopupOnSelect: false,
  selected: false,
};

export default memo(MapMarker);
