import React from 'react';
import PropTypes from 'prop-types';

import BaseModal from 'components/common/BaseModal';

import { useStyles } from './styles';

/**
 * Extreme values modal
 * @param { React.ReactNode } children
 * @param { function } closeModal
 * @param { number } width
 * @returns { JSX }
 */
const ExtremeValuesModal = ({ children, closeModal, width }) => {
  const classes = useStyles({ width });

  return (
    <BaseModal onClose={closeModal} modalClass={classes.container}>
      {children}
    </BaseModal>
  );
};

ExtremeValuesModal.propTypes = {
  width: PropTypes.number,
  children: PropTypes.node,
  closeModal: PropTypes.func.isRequired,
};

ExtremeValuesModal.defaultProps = {
  width: 600,
};

export default React.memo(ExtremeValuesModal);
