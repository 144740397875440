import {
  STATUS_LOADING,
  STATUS_ERROR,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
  EMPTY_VALUE,
  EMPTY_ARRAY,
} from 'constants/common';
import createReducer from 'helpers/createReducer';
import { groupAndNormalizeArrayBy } from 'helpers/data';

import {
  REQUEST_COMMENTS,
  REQUEST_COMMENTS_SUCCESS,
  REQUEST_COMMENTS_ERROR,
  POST_COMMENT,
  POST_COMMENT_ERROR,
  POST_COMMENT_SUCCESS,
} from './types';

const initialState = {
  comments: EMPTY_ARRAY,
  commentsByReportIds: EMPTY_VALUE,
  error: EMPTY_VALUE,
  status: STATUS_NOT_REQUESTED,
};

const setComments = (state, { comments }) => ({
  ...state,
  comments,
  commentsByReportIds: groupAndNormalizeArrayBy(comments, 'objectId'),
  status: STATUS_SUCCESS,
});

const setComment = (state, { comment }) => {
  const allComments = [comment, ...state.comments];
  return {
    ...state,
    comments: allComments,
    commentsByReportIds: groupAndNormalizeArrayBy(allComments, 'objectId'),
    status: STATUS_SUCCESS,
  };
};

const setLoading = (state) => ({
  ...state,
  status: STATUS_LOADING,
});

const setErrorStatus = (state, { error }) => ({
  ...state,
  error,
  status: STATUS_ERROR,
});

export default createReducer(initialState, {
  [REQUEST_COMMENTS]: setLoading,
  [REQUEST_COMMENTS_SUCCESS]: setComments,
  [REQUEST_COMMENTS_ERROR]: setErrorStatus,
  [POST_COMMENT]: setLoading,
  [POST_COMMENT_SUCCESS]: setComment,
  [POST_COMMENT_ERROR]: setErrorStatus,
});
