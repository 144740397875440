import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { generatePath } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';

import HeaderCell from 'components/common/HeaderCell';
import SearchField from 'components/projects/SearchField';
import SimpleTable from 'components/common/SimpleTable';
import SimpleLinkRow from 'components/usage/SimpleLinkRow';
import { ROUTES } from 'constants/routes';
import { Loader } from 'components/common/Loader';

import { useStyles } from './styles';

/** List of users present in the app, with some informations on them
 * @param { object } users - The list of users and they're informations
 * @param { func } onSearchChange - Event in cas the search entry change
 * @param { func } onOrderingChange - Event en case the ordering order change
 * @param { object } headers - Headers to automatically generate the table headers
 * @param { string } search - The searched string needed to fill up the search field
 * @param { bool } sort - A bool to indicate which column the table is sorted on
 * @param { bool } isReady - A bool to indicate whenever the page is ready and can display everything
 * or should be running the loading spinner
 */
const UsageUsers = ({
  users,
  onSearchChange,
  onOrderingChange,
  headers,
  search,
  sort,
  isReady,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const searchChangeHandler = useCallback(
    ({ target }) => onSearchChange(target.value || undefined),
    [onSearchChange]
  );

  const searchClearHandler = useCallback(
    (event) => {
      event.stopPropagation();
      onSearchChange();
    },
    [onSearchChange]
  );

  if (!isReady) {
    return <Loader center justifyCenter />;
  }

  return (
    <>
      <Grid
        justifyContent="space-evenly"
        alignItems="center"
        wrap="nowrap"
        container
        className={classes.searchBar}
      >
        <SearchField
          placeholder={t('projects.searchPlaceholder')}
          value={search}
          onChange={searchChangeHandler}
          onClear={searchClearHandler}
        />
      </Grid>
      <Paper className={classes.table}>
        <SimpleTable
          size="small"
          aria-label="A data table"
          header={
            <TableRow>
              {headers.map((header) => (
                <HeaderCell
                  key={header.header}
                  onClick={onOrderingChange}
                  values={header.values}
                  sorting={sort}
                  {...header.props}
                >
                  {header.name}
                </HeaderCell>
              ))}
            </TableRow>
          }
        >
          {users.map((user) => (
            <SimpleLinkRow
              key={`id-${user.id}`}
              to={generatePath(ROUTES.usageUserDetail, {
                id: user.id,
              })}
            >
              <TableCell>{user.username}</TableCell>
              <TableCell>{user.firstName}</TableCell>
              <TableCell>{user.lastName}</TableCell>
              <TableCell>{user.email}</TableCell>
            </SimpleLinkRow>
          ))}
        </SimpleTable>
      </Paper>
    </>
  );
};
export default memo(UsageUsers);
