import React, { memo } from 'react';
import classNames from 'classnames';

import BaseModal from 'components/common/BaseModal';

import { useStyles } from './styles';

/**
 * Fullscreen base modal component. Adds fullwidth class to BaseModal
 * @props props will be passed to BaseModal
 * @returns {jsx}
 * @see BaseModal
 */
const BaseModalFullscreen = ({ modalClass = '', ...props }) => {
  const classes = useStyles();

  const baseModalFullscreenClass = classNames(
    classes.modalBodyFullscreen,
    modalClass
  );

  return <BaseModal modalClass={baseModalFullscreenClass} {...props} />;
};

export default memo(BaseModalFullscreen);
