import {
  ARRAY_EXPECTED,
  ARRAY_OF_ARRAYS_EXPECTED,
  CALCULATION_ERROR,
  IS_NOT_NUMBER,
} from 'constants/errors';

/**
 * Redirects to given url
 * @param {String} to
 */
export const redirect = (to) => {
  window.location = to;
};

/**
 * Scrolls to element with the passed id
 * @param id - element id
 * @param options - scroll options
 */
export const scrollToElement = (id, options = {}) => {
  const { block = 'start', behavior = 'smooth', ...otherOptions } = options;
  const scroll = document.getElementById(id);
  scroll.scrollIntoView({ block, behavior, ...otherOptions });
};

/**
 * return angle in radian
 * @param { number } angle - angke in degrees
 */
export const toRadian = (angle) => (angle * Math.PI) / 180;

/**
 * shows native alert
 * @param message
 */
export const showAlert = (message) => {
  // eslint-disable-next-line no-alert
  window.alert(message);
};

/**
 * tests variable to null or undefined
 * @param {*} value
 */
export const isNullOrUndefined = (value) =>
  value === null || typeof value === 'undefined';

/**
 * Get [min, max] array from two given numbers
 * @param { number } a
 * @param { number } b
 * @returns { array }
 */
export const getMinMax = (a, b) => (a < b ? [a, b] : [b, a]);

/**
 * tests value to be in range from a to b
 * @param {number} value
 * @param {number} start
 * @param {number} end
 */
export const inRange = (value, start, end) => start <= value && value <= end;

/**
 * returns ascending range object from given range limits in any order
 * @param { number } a - one range limit
 * @param { number } b - other range limit
 * @returns {{ start: number, end: number }}
 */
export const getAscendingRange = (a, b) =>
  a <= b ? { start: a, end: b } : { start: b, end: a };

/**
 * returns array range from given start to end, including start and end values
 * @param { number } start
 * @param {number } end
 * @returns { number[] }
 * @example in: 3,7 out: [3,4,5,6,7]
 */
export const getRange = (start, end) => {
  const length = end - start + 1;
  return Array(length)
    .fill(null)
    .map((_, index) => start + index);
};

/**
 * returns interval string from given mask and range
 * @param { number|string[] } range array
 * @param { string } mask - mask of interval, should contain {from} and {to}
 * @returns { string }
 * @example
 *   in: ([12, 95], '[{from}° - {to}°]')
 *   out: '[12° - 95°]'
 */
export const getIntervalStringByMask = ([from, to], mask) =>
  mask.replace(/{from}/, from).replace(/{to}/, to);

/**
 * returns function build from given sequence. Each call will return next sequence value.
 * after last value, 1 value will be returned again and so on
 * @param { array } sequence
 * @returns {function(): *}
 * @example
 *   const seqNext = cyclicSequenceGenerator([1,2,3])
 *   seqNext() // 1
 *   seqNext() // 2
 */
export const cyclicSequence = (sequence) => {
  if (!Array.isArray(sequence)) {
    throw Error(ARRAY_EXPECTED);
  }

  const generator = (function* recursiveGenerator() {
    yield* sequence;
    return yield* recursiveGenerator();
  })();

  return () => generator.next().value;
};

/**
 * rounds given float value. By default precision is 5 digits after comma
 * it's enough in most cases
 * @param value
 * @param precision
 * @returns { number }
 */
export const floatRound = (value, precision = 5) => {
  const factor = 10 ** precision;
  return Math.round(value * factor) / factor;
};

/**
 * Returns sliced copy of array before given stopValue.
 * returned array is clone of given array
 * @param array
 * @param stopValue
 */
export const sliceUntil = (array, stopValue) => {
  const stopIndex = array.indexOf(stopValue);

  return stopIndex === -1 ? array.slice() : array.slice(0, stopIndex);
};

/**
 * returns flat array from given multidimensional array
 * @param { array } rawArray - array with custom nesting depth
 * @returns {[]}
 * @example in: [[1,2],[3,4],[5,[6,7]]] out: [1,2,3,4,5,6,7]
 */
export const flatten = (rawArray) => {
  if (!Array.isArray(rawArray)) {
    throw Error(ARRAY_EXPECTED);
  }
  const flatArray = [];

  (function recursiveFlatten(arr) {
    for (let i = 0; i < arr.length; i += 1) {
      const current = arr[i];
      if (Array.isArray(current)) {
        recursiveFlatten(current);
        continue;
      }
      flatArray.push(current);
    }
  })(rawArray);

  return flatArray;
};

/**
 * Sums values of 2 or more custom depth arrays with the same structure
 * @param { array } arraysToSum - array of arrays to sum
 * @param { * } placeholder - value to ignore
 * @returns { array } - sum of arrays, has same structure as each given arrays
 * @note don't touch since it works:) it written in that way to has good performance
 * @example
 * in: [
 *     [[1,2,3], [2,2,2], [1,2,1]],
 *     [[0,2,1], [2,2,2], [1,2,1]],
 *   ]
 * out: [
 *  [[1,4,4], [4,2,4], [2,4,2]]
 * ]
 */
export const arrayDeepSum = (arraysToSum, placeholder = null) => {
  if (!Array.isArray(arraysToSum[0]) || arraysToSum.length < 2) {
    throw Error(ARRAY_OF_ARRAYS_EXPECTED);
  }

  const arraySizes = [];
  const result = [];
  let deepestElement = arraysToSum[0];
  while (Array.isArray(deepestElement)) {
    arraySizes.push(deepestElement.length);
    deepestElement = deepestElement[0];
  }
  const possiblePaths = getPossiblePathsByArraySizes(arraySizes);

  for (let i = 0; i < arraysToSum.length; i += 1) {
    const array = arraysToSum[i];

    for (let j = 0; j < possiblePaths.length; j += 1) {
      const path = possiblePaths[j];
      let value = array;
      let currentResultItem = result;

      for (let pathIndex = 0; pathIndex < path.length; pathIndex += 1) {
        const pathPart = path[pathIndex];
        value = value[pathPart] === placeholder ? 0 : value[pathPart];

        if (value === undefined) {
          throw Error(CALCULATION_ERROR);
        }
        if (!Array.isArray(value)) {
          currentResultItem[pathPart] = currentResultItem[pathPart]
            ? currentResultItem[pathPart] + value
            : value;
          continue;
        }
        if (!currentResultItem[pathPart]) {
          currentResultItem[pathPart] = [];
        }

        currentResultItem = currentResultItem[pathPart];
      }
    }
  }

  return result;
};

/**
 * filters given array by given range
 * @param { array } array
 * @param { array } filterRange
 * @returns { array }
 */
export const arrayFilterByRange = (array, filterRange) => {
  const result = [];
  for (let i = 0; i < array.length; i += 1) {
    if (array[i] < filterRange[0] || array[i] > filterRange[1]) {
      continue;
    }
    result.push(array[i]);
  }

  return result;
};

/**
 * Sums all array elements
 * @param { number[] } array
 * @returns { number }
 */
export const arrayElementsSum = (array) => {
  let sum = 0;
  for (let i = 0; i < array.length; i += 1) {
    sum += array[i];
  }

  return sum;
};

/**
 * replaces needle in custom depth array
 * @param { array } array
 * @param { * } needle
 * @param { * } replacer
 * @returns { array }
 */
export const arrayDeepReplace = ({ array, needle, replacer }) =>
  (function recursiveReplacer(items) {
    const result = [];
    for (let i = 0; i < items.length; i += 1) {
      const value = items[i];
      if (Array.isArray(value)) {
        result[i] = recursiveReplacer(value);
        continue;
      }
      result[i] = value === needle ? replacer : value;
    }

    return result;
  })(array);

/**
 * returns array without given value, check is ===
 * @param array
 * @param needle
 * @returns {[]}
 */
export function arrayExclude(array, needle) {
  const result = [];
  for (let i = 0; i < array.length; i += 1) {
    if (array[i] === needle) {
      continue;
    }
    result.push(array[i]);
  }

  return result;
}

/**
 * returns possible paths (indexes sequences) by array dimensions sizes
 * @param { number[] } sizes
 * @returns { number[][] }
 * @example in: [2,2] out: [[0,0],[0,1],[1,0],[1,1]]
 */
function getPossiblePathsByArraySizes(sizes) {
  const valuesPositions = [];
  function generatePosition(currentPosition, currentSizes) {
    if (currentSizes.length === 1) {
      for (let i = 0; i < currentSizes[0]; i += 1) {
        valuesPositions.push(currentPosition.concat(i));
      }
      return;
    }
    const newSizes = currentSizes.slice(1);
    for (let i = 0; i < currentSizes[0]; i += 1) {
      generatePosition(currentPosition.concat(i), newSizes);
    }
  }
  generatePosition([], sizes);

  return valuesPositions;
}

/**
 * makes the first letter of a string uppercase
 * @param string
 * @returns {string}
 */
export const capitalizeFirstLetter = (string) =>
  string[0].toUpperCase() + string.slice(1);

/**
 * returns index of closest to needle element in array
 * @note performance is better if given array is sorted, in this case pass isSorted: true
 * (binary search principle is used)
 * @param { array } array
 * @param { number } needle
 * @param { boolean } isSorted
 * @returns { number }
 * @note uses binary search to increase performance
 */
export function findClosestIndex({
  array: initialArray,
  needle,
  isSorted = false,
}) {
  if (!Number.isFinite(needle)) {
    throw Error(IS_NOT_NUMBER);
  }
  let start = 0;
  let end = initialArray.length - 1;
  let middle;
  let closestIndex;
  const array = isSorted
    ? initialArray
    : initialArray.slice(0).sort((a, b) => a - b);

  if (needle <= array[start]) {
    closestIndex = start;
  } else if (needle >= array[end]) {
    closestIndex = end;
  } else {
    closestIndex =
      Math.abs(array[start] - needle) < Math.abs(array[end] - needle)
        ? start
        : end;

    while (end > start + 1) {
      middle = ~~((start + end) / 2);
      if (array[middle] === needle) {
        closestIndex = middle;
        break;
      }
      closestIndex =
        Math.abs(array[middle] - needle) <
        Math.abs(array[closestIndex] - needle)
          ? middle
          : closestIndex;
      if (needle > array[middle]) {
        start = middle;
        continue;
      }
      end = middle;
    }
  }

  if (isSorted) {
    return closestIndex;
  }

  for (let i = 0; i < initialArray.length; i += 1) {
    if (initialArray[i] === array[closestIndex]) {
      return i;
    }
  }
}

/**
 * creates instance of Uint8Array with passed data
 * @param data
 * @returns {Uint8Array}
 */
export const toUnit8Array = (data) => new Uint8Array(data);

/**
 * Replace all character in string
 * @param {String} value
 * @param {String|RegExp} searchValue
 * @param {String} replaceValue
 * @return {string}
 */
export const replaceAll = (value, searchValue, replaceValue) =>
  value.split(searchValue).join(replaceValue);

/**
 * Helper function that prevents supplied event
 * @param {Event} event
 * @return {*}
 */
export const preventDefault = (event) => event?.preventDefault();
