import { useRef, useEffect, useCallback } from 'react';

/**
 * Persistent version of useCallback. will not recreate function each time when deps are changed
 * @param func
 * @param dependencies
 * @returns {function(): *}
 */
export const usePersistentCallback = (func, dependencies) => {
  const ref = useRef(() => {
    throw new Error(`Cannot call handler while rendering.`);
  });

  useEffect(() => {
    ref.current = func;
    // eslint-disable-next-line
  }, [func, ...dependencies]);

  return useCallback(
    (...args) => {
      const fn = ref.current;
      return fn(...args);
    },
    [ref]
  );
};
