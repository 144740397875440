import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import BaseSideBar from 'components/common/BaseSideBar';
import Tabs from 'components/projects/Tabs';
import { ROUTES } from 'constants/routes';
import { selectProjectOfferInstanceByProjectId } from 'ducks/projects/selectors';

import { useStyles } from './styles';

/**
 * ProjectSideBar - side bar for project page
 * @param children
 * @param {Object} project
 * @returns {jsx}
 */
const ProjectSideBar = ({ children, project }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const offerInstance = useSelector((state) =>
    selectProjectOfferInstanceByProjectId(state, project.id)
  );

  const links = useMemo(() => {
    const disabled = !project.computations.length || !project.points.length;
    const linksList = [
      {
        title: t('projects.tabs.query'),
        link: generatePath(ROUTES.project, { id: project.id }),
        disabled,
      },
      {
        title: t('projects.tabs.analyze'),
        link: generatePath(ROUTES.projectAnalyze, { id: project.id }),
        disabled,
      },
      {
        title: t('projects.tabs.report'),
        link: generatePath(ROUTES.projectReport, { id: project.id }),
        disabled,
      },
    ];
    if (
      (Object.keys(offerInstance).length && !offerInstance.canBuildReport) ||
      !project.canBuildReport
    ) {
      linksList.pop();
    }

    return linksList;
  }, [t, project, offerInstance]);

  return (
    <BaseSideBar>
      <Grid container direction="column" className={classes.wrapper}>
        <Tabs links={links} />
        <Grid classes={{ root: classes.sideBarContent }} item>
          {children}
        </Grid>
      </Grid>
    </BaseSideBar>
  );
};

ProjectSideBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(ProjectSideBar);
