import React from 'react';
import PropTypes from 'prop-types';

import { useModal } from 'hooks/useModal';
import PlanningErrorLogModalComponent from 'components/modals/PlanningErrorLogModal';

/**
 * PlanningErrorLogModal container
 * Contains logic and data for PlanningErrorLogModal
 * @param { string } id - modal id
 * @param { string } error - error message to display
 */
const PlanningErrorLogModal = ({ id, error }) => {
  const { onClose } = useModal(id);

  return <PlanningErrorLogModalComponent onClose={onClose} error={error} />;
};

PlanningErrorLogModal.propTypes = {
  id: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
};

export default React.memo(PlanningErrorLogModal);
