import { createSelector } from 'reselect';

import { EMPTY_ARRAY, EMPTY_VALUE } from 'constants/common';
import { FINISHED_STATUS, RUNNING_STATUS } from 'constants/satelliteComparison';

export const selectSatelliteComparisonsError = ({ satelliteComparisons }) =>
  satelliteComparisons.error || EMPTY_VALUE;

export const selectSatelliteComparisonsData = ({ satelliteComparisons }) =>
  satelliteComparisons.data;

export const selectSatelliteComparisonsIds = ({ satelliteComparisons }) =>
  satelliteComparisons.ids;

export const selectSatelliteComparisonsByPoint = (
  { satelliteComparisons },
  pointId
) => satelliteComparisons.data[pointId] || EMPTY_ARRAY;

export const selectRunningSatelliteComparisonByPoint = createSelector(
  selectSatelliteComparisonsByPoint,
  (satelliteComparisons) =>
    satelliteComparisons.find(({ status }) => status === RUNNING_STATUS)
);

export const selectSatelliteComparisonsCollection = createSelector(
  selectSatelliteComparisonsIds,
  selectSatelliteComparisonsData,
  (satelliteComparisonsIds, satelliteComparisonsData) =>
    satelliteComparisonsIds.reduce((acc, id) => {
      acc.push(...satelliteComparisonsData[id]);
      return acc;
    }, [])
);

export const selectSatelliteComparisonById = createSelector(
  selectSatelliteComparisonsCollection,
  (_, comparisonId) => comparisonId,
  (satelliteComparisons, comparisonId) =>
    satelliteComparisons.find(({ id }) => id === comparisonId)
);

export const selectIsFinishedSatelliteComparisonById = createSelector(
  selectSatelliteComparisonById,
  ({ status } = {}) => status === FINISHED_STATUS
);

export const selectLastSatelliteComparisonByPoint = createSelector(
  selectSatelliteComparisonsByPoint,
  (satelliteComparisons) =>
    satelliteComparisons[satelliteComparisons.length - 1] || EMPTY_VALUE
);

export const selectLastSatelliteComparisonStatsByPoint = createSelector(
  selectLastSatelliteComparisonByPoint,
  (satelliteComparison) => satelliteComparison.stats
);
