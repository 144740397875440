import {
  APP_HIJACK_MODE_ENABLE,
  APP_HIJACK_EJECT,
  APP_INIT,
  CREATE_PLANNING_WAITING_REQUEST,
  CREATE_PROJECT_WITH_REDIRECT,
  CREATE_COMPUTED_PROJECT_WITH_REDIRECT,
  EDIT_USER_PROFILE_WAITING_REQUEST,
  INVITE_USER_WAITING_REQUEST,
  RESEND_USER_INVITATION_WAITING_REQUEST,
  REVOKE_USER_INVITATION_WAITING_REQUEST,
  UPDATE_PROJECT_COLLABORATORS_WAITING_REQUEST,
  UPLOAD_PLANNING_WAITING_REQUEST,
  USER_SIGNUP_WAITING_REQUEST,
} from './types';

export const appInit = () => ({ type: APP_INIT });

export const appHijackModeEnable = () => ({ type: APP_HIJACK_MODE_ENABLE });
export const appHijackEject = () => ({ type: APP_HIJACK_EJECT });

export const createProjectWithRedirect = (project) => ({
  type: CREATE_PROJECT_WITH_REDIRECT,
  project,
});

export const createComputedProjectWithRedirect = (project) => ({
  type: CREATE_COMPUTED_PROJECT_WITH_REDIRECT,
  project,
});

export const inviteUserWaitingRequest = ({ data, modalId }) => ({
  type: INVITE_USER_WAITING_REQUEST,
  data,
  modalId,
});

export const updateProjectCollaboratorsWaitingRequest = ({
  data,
  modalId,
  user,
}) => ({
  type: UPDATE_PROJECT_COLLABORATORS_WAITING_REQUEST,
  data,
  modalId,
  user,
});

export const editUserProfileWaitingRequest = ({ values, modalId }) => ({
  type: EDIT_USER_PROFILE_WAITING_REQUEST,
  values,
  modalId,
});

export const resendUserInvitationWaitingRequest = ({ user, modalId }) => ({
  type: RESEND_USER_INVITATION_WAITING_REQUEST,
  user,
  modalId,
});

export const revokeUserInvitationWaitingRequest = ({ user, modalId }) => ({
  type: REVOKE_USER_INVITATION_WAITING_REQUEST,
  user,
  modalId,
});

export const uploadPlanningWaitingRequest = ({ projectId, file }) => ({
  type: UPLOAD_PLANNING_WAITING_REQUEST,
  projectId,
  file,
});

export const createPlanningWaitingRequest = ({
  projectId,
  user,
  planningData,
  file,
}) => ({
  type: CREATE_PLANNING_WAITING_REQUEST,
  projectId,
  user,
  planningData,
  file,
});

export const userSignupWaitingRequest = ({ values }) => ({
  type: USER_SIGNUP_WAITING_REQUEST,
  values,
});
