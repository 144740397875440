import {
  WORKZONE_QUERY_LABEL,
  WORKZONE_ANALYZE_LABEL,
  HELP_LINK_LABEL,
} from 'constants/tracker';

import { SEND_TRACKING_DATA } from '../types';

export const trackProjectComputation = (data) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: WORKZONE_QUERY_LABEL,
  gtmAction: 'Compute project statistics',
  data,
});

export const trackNotEnoughCredits = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: WORKZONE_QUERY_LABEL,
  gtmAction: 'Compute without credits',
});

export const trackPointCreation = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: WORKZONE_QUERY_LABEL,
  gtmAction: 'Point created',
});

export const trackPointSelection = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: WORKZONE_QUERY_LABEL,
  gtmAction: 'Point selection',
});

export const trackPointSuppression = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: WORKZONE_QUERY_LABEL,
  gtmAction: 'Remove point',
});

export const trackPointSuppressionConfirmation = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: WORKZONE_QUERY_LABEL,
  gtmAction: 'Remove point confirmation',
});

export const trackAllPointsSuppression = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: WORKZONE_QUERY_LABEL,
  gtmAction: 'Remove all points',
});

export const trackAllPointsSuppressionConfirmation = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: WORKZONE_QUERY_LABEL,
  gtmAction: 'Remove all points confirmation',
});

export const trackPointsUpload = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: WORKZONE_QUERY_LABEL,
  gtmAction: 'Point upload',
});

export const trackPointsUploadHelp = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: WORKZONE_QUERY_LABEL,
  gtmAction: 'Help point upload',
});

export const trackAnalyzePointSelection = (origin) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: WORKZONE_ANALYZE_LABEL,
  gtmAction: 'Point Selection',
  origins: origin,
});

export const trackStats = (statField, statVariable, statType) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: WORKZONE_ANALYZE_LABEL,
  gtmAction: 'Visualize Stat',
  statField,
  statVariable,
  statType,
});

export const trackStatsDownload = (format, statsId, canDownloadStats) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: WORKZONE_ANALYZE_LABEL,
  gtmAction: 'Stat download',
  format,
  statsId,
  canDownload: canDownloadStats,
});

export const trackStatsHelp = (origin, helplink) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: WORKZONE_ANALYZE_LABEL,
  gtmAction: HELP_LINK_LABEL,
  origins: origin,
  helplink,
});

export const trackReportGeneration = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: WORKZONE_ANALYZE_LABEL,
  gtmAction: 'Generate report',
});
