import React, { memo, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { generatePath } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { format } from 'date-fns';

import HeaderCell from 'components/common/HeaderCell';
import SimpleLinkRow from 'components/usage/SimpleLinkRow';
import SearchField from 'components/projects/SearchField';
import SimpleTable from 'components/common/SimpleTable';
import { Loader } from 'components/common/Loader';
import { ROUTES } from 'constants/routes';
import { DATE_FORMATS } from 'constants/common';

import { useStyles } from './styles';

/** List of customers using the app, with some informations on them
 * @param { object } customers - The list of customers and they're informations
 * @param { func } onSearchChange - Event in cas the search entry change
 * @param { func } onOrderingChange - Event en case the ordering order change
 * @param { object } headers - Headers to automatically generate the table headers
 * @param { string } search - The searched string needed to fill up the search field
 * @param { bool } sort - A bool to indicate which column the table is sorted on
 * @param { bool } isReady - A bool to indicate whenever the page is ready and can display everything
 * or should be running the loading spinner
 */
const UsageCustomers = ({
  customers,
  onSearchChange,
  onOrderingChange,
  headers,
  search,
  sort,
  isReady,
}) => {
  const classes = useStyles();

  const searchChangeHandler = useCallback(
    ({ target }) => onSearchChange(target.value || undefined),
    [onSearchChange]
  );

  const searchClearHandler = useCallback(
    (event) => {
      event.stopPropagation();
      onSearchChange();
    },
    [onSearchChange]
  );

  if (!isReady) {
    return <Loader center justifyCenter />;
  }

  return (
    <>
      <Grid
        justifyContent="space-evenly"
        alignItems="center"
        wrap="nowrap"
        container
        className={classes.searchBar}
      >
        <SearchField
          placeholder="Search based on the selected colunm"
          value={search}
          onChange={searchChangeHandler}
          onClear={searchClearHandler}
        />
      </Grid>
      <Paper className={classes.table}>
        <SimpleTable
          size="small"
          aria-label="A data table"
          header={
            <TableRow>
              {Object.values(headers).map((header) => (
                <HeaderCell
                  key={header.header}
                  onClick={onOrderingChange}
                  values={header.values}
                  sorting={sort}
                  {...header.props}
                >
                  {header.name}
                </HeaderCell>
              ))}
            </TableRow>
          }
        >
          {customers.map((customer) => (
            <SimpleLinkRow
              key={`id-${customer.id}`}
              to={generatePath(ROUTES.usageCustomerDetail, {
                id: customer.id,
              })}
            >
              <TableCell {...headers.name.props}>{customer.name}</TableCell>
              <TableCell>{customer.projectCount}</TableCell>
              <TableCell>{customer.reportCount}</TableCell>
              <TableCell>{customer.downtimeCount}</TableCell>
              <TableCell>{customer.activeUser}</TableCell>
              <TableCell>{customer.activeOffer}</TableCell>
              <TableCell>
                {customer.subscriptionEnd
                  ? format(customer.subscriptionEnd, DATE_FORMATS.frenchFormat)
                  : 'No date'}
              </TableCell>
              <TableCell {...headers.name.props}>
                {customer.lastVisit
                  ? format(customer.lastVisit, DATE_FORMATS.frenchFormat)
                  : 'No date'}
              </TableCell>
            </SimpleLinkRow>
          ))}
        </SimpleTable>
      </Paper>
    </>
  );
};
export default memo(UsageCustomers);
