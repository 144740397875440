import React from 'react';
import PropTypes from 'prop-types';
import { convert } from 'textile-js';

import CreateReactElement from '../CreateReactElement';

/**
 * Component for render string template to html.
 * @param {String} template
 * @param {String|React.ReactElement} is
 * @param {Object} props
 * @return {React.ReactElement}
 * @constructor
 */
export const Template = ({ template, is, ...props }) => (
  <CreateReactElement is={is} element={convert(template)} {...props} />
);

Template.propTypes = {
  is: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  template: PropTypes.string.isRequired,
};

Template.defaultProps = {
  is: 'p',
};

export default Template;
