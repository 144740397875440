import React, { memo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useMapInstance } from 'hooks/leaflet/useMapInstance';
import MapCustomControl from 'components/leaflet/MapCustomControl';

/**
 * MapMouseCoordinates. Uses map context
 * Adds to map container with map coordinates
 * @returns {jsx}
 */
export const MapMouseCoordinates = ({ precision }) => {
  const mapInstance = useMapInstance();
  const [position, setPosition] = useState({ lat: '', lng: '' });

  const onMove = useCallback(
    (event) => {
      setPosition({
        lat: event.latlng.lat.toFixed(precision, 10),
        lng: event.latlng.lng.toFixed(precision, 10),
      });
    },
    [setPosition, precision]
  );

  useEffect(() => {
    if (!mapInstance) {
      return;
    }
    mapInstance.on('mousemove', onMove);

    return () => {
      mapInstance.off('mousemove', onMove);
    };
    // eslint-disable-next-line
  }, [mapInstance]);

  return (
    <MapCustomControl hideActions>
      Latitude: {position.lat}&ensp; Longitude: {position.lng}
    </MapCustomControl>
  );
};

MapMouseCoordinates.propTypes = {
  precision: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MapMouseCoordinates.defaultProps = {
  precision: 4,
};

export default memo(MapMouseCoordinates);
