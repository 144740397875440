import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  title: {
    fontWeight: 'bold',
    marginTop: -5,
    textAlign: 'center',
  },
  buttonGroup: {
    flexDirection: 'column',
    '& > button': {
      fontSize: 10,
      padding: 5,
      border: `1px solid ${palette.grey.main} !important`,
      borderRadius: '4px !important',
      marginLeft: 0,
    },
  },
}));
