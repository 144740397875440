export const DEEP_BLUE_COLOR_RANGE_BREAKPOINTS = {
  red: [
    { percent: 0, color: 0 },
    { percent: 35, color: 0 },
    { percent: 66, color: 256 },
    { percent: 89, color: 256 },
    { percent: 100, color: 230 },
  ],
  green: [
    { percent: 0, color: 0 },
    { percent: 12.5, color: 0 },
    { percent: 37.5, color: 256 },
    { percent: 64, color: 256 },
    { percent: 91, color: 0 },
    { percent: 100, color: 0 },
  ],
  blue: [
    { percent: 0, color: 128 },
    { percent: 11, color: 256 },
    { percent: 34, color: 256 },
    { percent: 65, color: 0 },
    { percent: 100, color: 0 },
  ],
};

export const SCATTER_PLOT_COLOR_RANGE_BREAKPOINTS = {
  red: [
    { percent: 0, color: 0 },
    { percent: 10, color: 0 },
    { percent: 25, color: 255 },
    { percent: 100, color: 255 },
  ],
  green: [
    { percent: 0, color: 131 },
    { percent: 10, color: 255 },
    { percent: 25, color: 255 },
    { percent: 100, color: 0 },
  ],
  blue: [
    { percent: 0, color: 9 },
    { percent: 10, color: 0 },
    { percent: 25, color: 0 },
    { percent: 100, color: 0 },
  ],
};

/** List of colors for geometries */
export const GEOMETRIES_COLORS = [
  {
    name: 'Chrome Yellow',
    value: '#ffab00',
  },
  {
    name: 'Cyber Yellow',
    value: '#ffcf00',
  },
  {
    name: 'Platinum',
    value: '#e4e7eb',
  },
  {
    name: 'Azure',
    value: '#007bff',
  },
  {
    name: 'French Blue',
    value: '#0072b2',
  },
];

export const HSL_LINEAR_MODE = 'hslLinear';
export const RGB_CUSTOM_MODE = 'rgbCustom';

export const DEEP_BLUE_LINEAR_GRADIENT_SCHEME = [
  { offset: 0, color: '#e60000' },
  { offset: 10, color: '#ff0900' },
  { offset: 35, color: '#f8f800' },
  { offset: 65, color: '#00e6f8' },
  { offset: 100, color: '#000080' },
];

export const GRADIENT_SCHEME_BY_MODE = {
  RGB_CUSTOM_MODE: DEEP_BLUE_LINEAR_GRADIENT_SCHEME,
};
