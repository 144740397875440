import React from 'react';
import PropTypes from 'prop-types';

import { DEFAULT_AXIS_INDEX } from 'constants/graphs';

const STROKE_COLOR = 'rgba(0,0,0,0.1)';

/**
 * Renders probability matrix and manage some state, because of performance issue
 * (state in custom component is much faster than keep state with graph axises and grid)
 * @param { array } rectangles
 * @param {{ xAxisId: { x } }} xAxisMap - x axis parameters
 * @param {{ yAxisId: { y } }} yAxisMap
 * @param { function } onCellEnter - callback for cell enter
 * @param { function } onLeave - callback for matrix leave
 * @returns { JSX }
 */
const ProbabilityMatrix = ({
  rectangles,
  xAxisMap,
  yAxisMap,
  onCellEnter,
  onLeave,
}) => {
  const { x: leftOffset, width: svgWidth } = xAxisMap[DEFAULT_AXIS_INDEX];
  const { y: topOffset, height: svgHeight } = yAxisMap[DEFAULT_AXIS_INDEX];

  return (
    <svg
      x={leftOffset}
      y={topOffset}
      width={svgWidth}
      height={svgHeight}
      onMouseLeave={onLeave}
    >
      <g>
        {rectangles.map(({ x, y, width, height, color, xIndex, yIndex }) => (
          <rect
            x={x}
            y={y}
            key={`x${xIndex}y${yIndex}`}
            fill={color}
            stroke={STROKE_COLOR}
            width={width}
            height={height}
            data-x-index={xIndex}
            data-y-index={yIndex}
            onMouseEnter={onCellEnter}
          />
        ))}
      </g>
    </svg>
  );
};

ProbabilityMatrix.propTypes = {
  xAxisMap: PropTypes.object,
  yAxisMap: PropTypes.object,
  rectangles: PropTypes.arrayOf(PropTypes.object),
  onCellEnter: PropTypes.func,
  onLeave: PropTypes.func,
};

export default ProbabilityMatrix;
