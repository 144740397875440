/* eslint-disable */
import { takeLatest } from 'redux-saga/effects';
import TagManager from 'react-gtm-module';

import { GTM_EVENT_NAME, CATEGORY } from 'constants/tracker';
import { REACT_APP_GTM_ID } from 'common/config';
import { selectUserDetails } from 'ducks/user/selectors';
import { store } from 'store/index';

import { SEND_TRACKING_DATA, SEND_TRACKING_PAGEVIEW } from './types';

/**
 * Sends tracking pageview to google (GTM)
 * for marketing purpose.
 * The `REACT_APP_GTM_ID` env var drives which google GTM container/account is used.
 */
export function sendTrackingPageviewToGoogleWorker(history) {
  const tagManagerInitArgs = {
    gtmId: REACT_APP_GTM_ID,
  };
  TagManager.initialize(tagManagerInitArgs);
  let firstPageVisited = true;
  history.listen((location) => {
    firstPageVisited = false;
    const page = location.pathname + location.search;
    const tagManagerPageViewArgs = {
      dataLayer: {
        event: 'pageview',
        pageName: page,
        p_title: document.title,
        p_path: document.URL,
        p_site: 'Metocean Analytics',
        u_name: undefined,
      },
    };
    TagManager.dataLayer(tagManagerPageViewArgs);
  });
  if (firstPageVisited) {
    const originalLocation = `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`;
    const firstPage = document.location.pathname + document.location.search;
    const tagManagerPageViewArgs = {
      dataLayer: {
        event: 'pageview',
        originalLocation,
        pageName: firstPage,
        p_title: document.title,
        p_path: document.URL,
        p_site: 'Metocean Analytics',
        u_name: undefined,
      },
    };
    TagManager.dataLayer(tagManagerPageViewArgs);
  }
}

/**
 * Sends tracking data to google (GTM)
 * for marketing purpose.
 */
export function sendTrackingDataToGoogleWorker({
  gtmLabel,
  gtmAction,
  data = undefined,
  statField = undefined,
  statVariable = undefined,
  statType = undefined,
  zone = undefined,
  offer = undefined,
  mapOption = undefined,
  buoy = undefined,
  numericalSimulation = undefined,
  origins = undefined,
  statsId = undefined,
  format = undefined,
  helplink = undefined,
  canDownload = undefined,
}) {
  const currentState = store.getState();
  const tagManagerArgs = {
    dataLayer: {
      event: GTM_EVENT_NAME,
      e_category: CATEGORY,
      e_label: gtmLabel,
      e_action: gtmAction,
      e_data: data,
      e_stat_field: statField,
      e_stat_variable: statVariable,
      e_stat_type: statType,
      e_zone: zone,
      e_offer: offer,
      e_map_option: mapOption,
      e_buoy: buoy,
      e_numerical_simulation: numericalSimulation,
      e_origins: origins,
      e_statsId: statsId,
      e_format: format,
      e_helplink: helplink,
      e_canDownload: canDownload,
    },
  };
  tagManagerArgs.dataLayer.u_username = currentState.user.data.email;
  console.log(currentState.user.data.firstName + " " + currentState.user.data.lastName)
  tagManagerArgs.dataLayer.u_name = currentState.user.data.firstName + " " + currentState.user.data.lastName
  tagManagerArgs.dataLayer.u_customer = selectUserDetails(
    currentState
  ).customerName;

  TagManager.dataLayer(tagManagerArgs);
}

export default function*() {
  yield takeLatest(SEND_TRACKING_DATA, sendTrackingDataToGoogleWorker);
  yield takeLatest(SEND_TRACKING_PAGEVIEW, sendTrackingPageviewToGoogleWorker);
}
