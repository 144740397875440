import { useMemo } from 'react';

/**
 * useExclusifFilters - hook for filtration entities, by independents values
 * @param {Array} entities - any array
 * @param {Object} filters - object with comparator function
 * @param {Object} expectedValues - values for comparation
 * @
 * @returns {Array}
 *
 * Example:
 * const entities = [{ id: 1, name: 'FirstName' }, { id: 1, name: 'SecondName' }];
 *
 * const filteredEntities = useDependentsFilters({
 *   entities,
 *   filters: {
 *     search: (entity, expectedValue) => entity.name === expectedValue,
 *   },
 *   expectedValues: { search: 'FirstName' },
 * });
 *
 * filteredEntities -> [{ id: 1, name: 'FirstName' }]
 */
export function useExclusifFilters({
  entities = [],
  filters,
  activeFilter,
  expectedValue,
}) {
  return useMemo(() => {
    if (!expectedValue || !filters) {
      return entities;
    }

    const filterFunction = (entity) =>
      filters[activeFilter](entity, expectedValue);

    return entities.filter(filterFunction);
  }, [entities, filters, activeFilter, expectedValue]);
}
