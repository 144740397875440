import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { useNavbarLinks } from 'hooks/useNavbarLinks';
import Navbar from 'containers/common/Navbar';
import Footer from 'components/common/Footer/index';
import PrivateRoute from 'components/common/PrivateRoute';

/**
 * Layout for people page
 */
const PeopleLayout = ({
  isAuthorized,
  component: Component,
  location,
  ...restProps
}) => {
  const links = useNavbarLinks(location.pathname);

  return (
    <PrivateRoute hasAccess={isAuthorized} location={location} {...restProps}>
      <div>
        <Navbar links={links} header="People" />
        <Component />
      </div>
      <Footer />
    </PrivateRoute>
  );
};

PeopleLayout.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
};

export default memo(PeopleLayout);
