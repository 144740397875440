const API_PREFIX = '/api';
const API_AUTH_PREFIX = '/api-auth';

export const STAT2D_URL_PROPERTIES_POSTFIX = 'properties.json';

export const PROJECTS_URL = `${API_PREFIX}/projects/`;
export const PROJECT_URL = `${API_PREFIX}/projects/:id/`;
export const CREATE_DEMO_PROJECT_URL = `${PROJECTS_URL}create_demo_project/`;
export const PROJECT_URL_COMPUTE = `${PROJECT_URL}compute/`;
export const PROJECT_ZONE_URL = `${PROJECT_URL}zone/`;
export const UPDATE_PROJECT_NAME_URL = `${PROJECT_URL}update_project_name/`;
export const UPDATE_PROJECT_COLLABORATORS_URL = `${PROJECT_URL}update_collaborators/`;
export const PROJECT_URL_CANCEL_COMPUTE = `${PROJECT_URL}cancel/`;

export const USERS_URL = `${API_PREFIX}/users/`;
export const CURRENT_USER_URL = `${USERS_URL}current/`;
export const REMOVE_USER_URL = `${USERS_URL}:id/remove_user/`;

export const ZONES_URL = `${API_PREFIX}/zones/`;
export const ZONE_URL = `${ZONES_URL}:id/`;

export const OFFERS_URL = `${API_PREFIX}/offers/`;

export const POINTS_URL = `${API_PREFIX}/points/`;

export const STAT2D_URL = `${API_PREFIX}/stat2Ds/`;

export const GEOMETRIES_URL = `${API_PREFIX}/geometries/`;

export const USAGE_URL = `${API_PREFIX}/usage/`;
export const USAGE_CUSTOMERS_URL = `${USAGE_URL}customers/`;
export const USAGE_CUSTOMER_DETAIL_URL = `${USAGE_CUSTOMERS_URL}:id/`;
export const USAGE_USERS_URL = `${USAGE_URL}users/`;
export const USAGE_USER_DETAIL_URL = `${USAGE_USERS_URL}:id/`;
export const USAGE_OFFERS_URL = `${USAGE_URL}offers/`;
export const USAGE_OFFER_DETAIL_URL = `${USAGE_OFFERS_URL}:id/`;
export const POINT_TASKS_URL = `${API_PREFIX}/tasks/`;
export const POINT_TASKS_COMPUTE = `${POINT_TASKS_URL}:id/compute/?id=:processType`;
export const POINT_TASKS_CANCEL = `${POINT_TASKS_URL}:id/cancel/`;
export const POINT_TASKS_DOWNLOAD = `${POINT_TASKS_URL}:id/download/`;

export const BUOYS_URL = `${API_PREFIX}/buoys/`;
export const BUOY_URL = `${BUOYS_URL}:id/`;
export const BUOY_DOWNLOAD_URL = `${BUOYS_URL}:id/download/`;
export const BUOY_STATS_URL = `${BUOY_URL}get_visu_data/`;

export const SATELLITE_COMPARISONS_URL = `${API_PREFIX}/satellite_comparisons/`;
export const SATELLITE_COMPARISON_URL = `${SATELLITE_COMPARISONS_URL}:id/`;
export const SATELLITE_COMPARISON_COMPUTE_URL = `${SATELLITE_COMPARISON_URL}compute/`;
export const SATELLITE_COMPARISON_STATS_URL = `${SATELLITE_COMPARISON_URL}get_visu_data/`;
export const SATELLITE_COMPARISON_STATS_DOWNLOAD_URL = `${SATELLITE_COMPARISON_URL}download/`;

export const LOGIN_URL = `${API_AUTH_PREFIX}/token/`;
export const REFRESH_TOKEN_URL = `${API_AUTH_PREFIX}/token/refresh/`;
export const RESET_PASSWORD_URL = `${API_AUTH_PREFIX}/reset-password/`;
export const RESET_PASSWORD_CONFIRM_URL = `${API_AUTH_PREFIX}/reset-password/confirm/`;
export const REGISTRATION_URL = `${API_AUTH_PREFIX}/registration/`;
export const REGISTRATION_PENDING_URL = `${API_AUTH_PREFIX}/registration/pending/`;
export const REGISTRATION_ACTIVATE_URL = `${API_AUTH_PREFIX}/registration/activate/`;
export const INVITE_USER_URL = `${API_PREFIX}/users/`;
export const PROJECT_STATS_URL = `${PROJECT_URL}stats/`;
export const PROJECT_GRAPH_STATS_URL = `${PROJECT_URL}get_visu_data/`;

export const EDIT_USER_URL = `${API_PREFIX}/users/:id/`;
export const RESEND_USER_INVITATION_URL = `${EDIT_USER_URL}resend_invite/ `;
export const REVOKE_USER_INVITATION_URL = `${EDIT_USER_URL}revoke_invite/ `;

export const HELP_PAGE_URL = `${API_PREFIX}/flatpages/:url/`;

export const REPORTS_URL = `${API_PREFIX}/reports/`;
export const REPORT_URL = `${REPORTS_URL}:id/`;
export const REPORT_CANCEL = `${REPORT_URL}cancel/`;
export const REPORT_GENERATE = `${REPORT_URL}generate/`;
export const REPORT_CLEAN = `${REPORT_URL}clean/`;
export const REPORT_DOWNLOAD_URL = `${REPORT_URL}download/`;

export const DEMO_PAGE_API = `${API_PREFIX}/demos/`;
export const HISTORY_API = `${API_PREFIX}/history/`;

export const DOWNTIMES_URL = `${API_PREFIX}/downtimes/`;
export const DOWNTIMES_PLANNING_URL = `${API_PREFIX}/downtimes/:id/`;
export const DOWNTIMES_COMPUTE_URL = `${DOWNTIMES_PLANNING_URL}compute/`;
export const UPLOAD_PLANNING_URL = `${PROJECT_URL}scan_planning_file/`;
export const DOWNLOAD_PLANNING_URL = `${DOWNTIMES_PLANNING_URL}download/`;

export const MARINE_CONTRACTORS_URL_BASE = `${API_PREFIX}/contractors`;
export const MARINE_CONTRACTORS_URL = `${MARINE_CONTRACTORS_URL_BASE}/boats/`;
export const MARINE_CONTRACTORS_JOBS = `${MARINE_CONTRACTORS_URL_BASE}/jobs/`;
export const MARINE_CONTRACTORS_OPERATIONS_URL = `${MARINE_CONTRACTORS_URL_BASE}/operations/`;
export const MARINE_CONTRACTORS_CONSTRAINTS_URL = `${MARINE_CONTRACTORS_URL_BASE}/constraints/`;
export const MARINE_CONTRACTORS_JOB = `${MARINE_CONTRACTORS_JOBS}:id/`;
export const MARINE_CONTRACTORS_LATEST_JOB = `${MARINE_CONTRACTORS_JOBS}?latest`;
export const MARINE_CONTRACTORS_VARIABLES_URL = `${MARINE_CONTRACTORS_URL_BASE}/variables/`;
export const MARINE_CONTRACTORS_CUSTOMER_URL = `${API_PREFIX}/customer/settings/`;
export const MARINE_CONTRACTORS_COMPUTE_URL = `${MARINE_CONTRACTORS_JOBS}:id/compute/`;
export const MARINE_CONTRACTORS_RESULTS_URL = `${MARINE_CONTRACTORS_JOBS}:id/results/`;
export const MARINE_CONTRACTORS_RESULTS_DOWNLOAD_URL = `${MARINE_CONTRACTORS_JOBS}:id/download/`;

export const COMMENTS_URL = `${API_PREFIX}/comments/`;

export const CONTENT_TYPES_URL = `${API_PREFIX}/content_types/`;

export const PROJECT_GRAPH_EXPORT_URL = `${PROJECT_URL}export/`;

export const SESSION_CHECK_URL = CURRENT_USER_URL;

export const MAINTENANCE_MODE = 'maintenance_mode';
