import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { useNavbarLinks } from 'hooks/useNavbarLinks';
import Navbar from 'containers/common/Navbar';
import Footer from 'components/common/Footer/index';
import PrivateRoute from 'components/common/PrivateRoute';

/**
 * Layout for page. Use it for routes with Navbar
 * @param {string|node} header - pass here header text or component to display in navbar if need
 * @param {Boolean} isAuthorized
 * @param {React.Component} Component
 * @param {Object} location
 * @param {Object} restProps
 * @returns {jsx}
 */
const CommonLayout = ({
  header,
  isAuthorized,
  component: Component,
  location,
  ...restProps
}) => {
  const links = useNavbarLinks(location.pathname);

  return (
    <PrivateRoute hasAccess={isAuthorized} location={location} {...restProps}>
      <div>
        <Navbar links={links} />
        <Component />
      </div>
      <Footer />
    </PrivateRoute>
  );
};

CommonLayout.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
};

CommonLayout.defaultProps = {
  header: '',
};

export default memo(CommonLayout);
