import React, { useCallback, memo, useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import UsageUsersComponent from 'components/usage/UsageUsers';
import { selectUsers, isReady } from 'ducks/usage/selectors';
import { requestUsers } from 'ducks/usage/actions';
import { useUrlQuery } from 'hooks/useUrlQuery';
import { hasSubString } from 'helpers/filters';
import { useExclusifFilters } from 'hooks/useExclusifFilters';
import { useArraySorting } from 'hooks/useArraySorting';

const usersFilters = {
  firstName: (a, b) => a.firstName.localeCompare(b.firstName),
  lastName: (a, b) => a.lastName.localeCompare(b.lastName),
  username: ({ username }, expected) => hasSubString(username, expected),
  email: ({ email }, expected) => hasSubString(email, expected),
};

const sortingConditions = {
  firstName: (a, b) => a.firstName.localeCompare(b.firstName),
  lastName: (a, b) => a.lastName.localeCompare(b.lastName),
  username: (a, b) => a.username.localeCompare(b.username),
  email: (a, b) => a.email.localeCompare(b.email),
};

const UsageUsers = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const ready = useSelector(isReady);
  const [reverse, setReverse] = useState(false);

  const {
    urlQuery: { search, ordering = 'username', sort = 'asc' },
    mergeUrlQuery,
  } = useUrlQuery();

  let filteredUsers = useExclusifFilters({
    entities: users,
    filters: usersFilters,
    activeFilter: ordering,
    expectedValue: search,
  });

  filteredUsers = useArraySorting({
    entities: filteredUsers,
    conditions: sortingConditions,
    activeCondition: ordering,
    reverse,
  });

  const headers = useMemo(
    () => ({
      username: {
        header: 'username',
        name: 'username',
        sorting: sort,
        values: [
          { order: 'username', sort: 'asc' },
          { order: 'username', sort: 'desc' },
        ],
        props: {},
      },
      name: {
        header: 'firstName',
        name: 'first name',
        sorting: sort,
        values: [
          { order: 'firstName', sort: 'asc' },
          { order: 'firstName', sort: 'desc' },
        ],
        props: {},
      },
      lastName: {
        header: 'lastName',
        name: 'last name',
        sorting: sort,
        values: [
          { order: 'lastName', sort: 'asc' },
          { order: 'lastName', sort: 'desc' },
        ],
        props: {},
      },
      email: {
        header: 'email',
        name: 'email',
        sorting: sort,
        values: [
          { order: 'email', sort: 'asc' },
          { order: 'email', sort: 'desc' },
        ],
        props: {},
      },
    }),
    [sort]
  );

  useEffect(() => {
    if (sort === 'asc') {
      setReverse(false);
    }
    if (sort === 'desc') {
      setReverse(true);
    }
  }, [sort, reverse]);

  useEffect(() => {
    dispatch(requestUsers());
  }, [dispatch]);

  const setSearch = useCallback(
    (value) => {
      mergeUrlQuery({ search: value });
    },
    [mergeUrlQuery]
  );

  const setParameters = useCallback(
    (event, param) => {
      mergeUrlQuery({ ordering: param.order, sort: param.sort });
    },
    [mergeUrlQuery]
  );

  return (
    <UsageUsersComponent
      headers={Object.values(headers)}
      search={search}
      sort={sort}
      users={filteredUsers}
      ordering={ordering}
      onSearchChange={setSearch}
      onOrderingChange={setParameters}
      isReady={ready}
    />
  );
};
export default memo(UsageUsers);
