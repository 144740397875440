import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  text: {
    fontSize: '1em',
  },
  searchBar: {},
  table: {
    minWidth: 400,
    margin: '2vw',
  },
  component: {
    padding: '200px auto',
    backgroundColor: palette.white.main,
  },
}));
