import {
  REQUEST_CONTENT_TYPES,
  REQUEST_CONTENT_TYPES_SUCCESS,
  REQUEST_CONTENT_TYPES_ERROR,
  REQUEST_CONTENT_TYPES_IF_NEED,
} from './types';

export const requestContentTypes = () => ({
  type: REQUEST_CONTENT_TYPES,
});

export const requestContentTypesSuccess = (contentTypes) => ({
  type: REQUEST_CONTENT_TYPES_SUCCESS,
  contentTypes,
});

export const requestContentTypesError = (error) => ({
  type: REQUEST_CONTENT_TYPES_ERROR,
  error,
});

export const requestContentTypesIfNeed = () => ({
  type: REQUEST_CONTENT_TYPES_IF_NEED,
});
