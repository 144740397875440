import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, zIndexMap }) => ({
  container: {
    pointerEvents: 'all',
    display: ({ hidden }) => (hidden ? 'none' : 'block'),
    padding: 5,
    margin: 15,
    zIndex: zIndexMap + 1,
    position: 'relative',
    width: 250,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: 2,
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    padding: 5,
  },
  openLink: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: palette.links.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
