import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  label: {
    width: 0,
    marginLeft: 25,
    transformOrigin: 'top',
    marginTop: ({ height }) => height - 80,
    transform: 'rotate(-90deg)',
  },
  container: {
    margin: ({ offset }) => `${offset}px 0 0 ${offset}px`,
  },
  tickLine: {
    width: 6,
    margin: 0,
    marginTop: -1,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    borderTop: `1px solid ${palette.black.main}`,
    '&::after': {
      fontSize: 12,
      content: 'attr(data-tick)',
      marginLeft: 7,
    },
  },
  bar: {
    width: ({ width }) => width,
    height: ({ height }) => height,
    '&$deepBlueGradient': {
      background: `linear-gradient(${palette.deepBlueColorRange.join(',')})`,
    },
    '&$linearGradient': {
      background: `linear-gradient(${palette.linearColorRange.join(',')})`,
    },
  },
  deepBlueGradient: {},
  linearGradient: {},
}));
