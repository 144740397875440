import { cloneElement, memo, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { palette } from 'common/theme';
import { EMPTY_FUNCTION, EMPTY_VALUE } from 'constants/common';
import { getGeoJsonLayerFromString, injectPropsToLayer } from 'helpers/map';
import { useMapEvents } from 'hooks/leaflet/useMapEvents';

const ON_HOVER_ZONE_OPACITY = 0.2;
/**
 * Map polygon component. Adds polygon to feature group
 * @param {String} geometry
 * @param {String} color
 * @param {Number} fillOpacity - fill opacity from 0 to 1
 * @param {Function} add - callback to add polygon to feature group.
 * @param {Function} remove - callback to remove polygon from feature group.
 * @param {Function} onClick - callback to remove polygon from feature group.
 * @param {String} className
 * @param {Boolean} selected
 * @param {Boolean} allowOpenPopupOnSelect
 * @param {Object} leafletElement
 * @param {Object} customProps - props to inject in polygon layer. Can be accessed via layer.feature.properties
 * @param {Boolean} highlightColor - color background on focus
 * @returns {null}
 * @note use this component inside <FeatureGroup> only!
 */
const MapPolygon = ({
  geometry,
  color,
  fillOpacity,
  customProps,
  className,
  add,
  remove,
  onClick,
  leafletElement,
  selected,
  allowOpenPopupOnSelect,
  children,
  highlightColor,
}) => {
  const polygon = useRef(getGeoJsonLayerFromString(geometry));

  const handleMouseOver = (e) =>
    e.setStyle({
      fillColor: palette.green.light,
      color: palette.green.light,
      fillOpacity: ON_HOVER_ZONE_OPACITY,
    });
  const handleMouseOut = useCallback(
    (e) => e.setStyle({ color, fillOpacity, className, fillColor: null }),
    [color, fillOpacity, className]
  );

  useEffect(() => {
    const element = polygon.current;
    if (highlightColor) {
      element.addEventListener('mouseover', () => handleMouseOver(element));
      element.addEventListener('mouseout', () => handleMouseOut(element));
    }
    return () => {
      element.removeEventListener('mouseover', () => handleMouseOver(element));
      element.removeEventListener('mouseout', () => handleMouseOut(element));
    };
  }, [polygon, highlightColor, handleMouseOut]);

  useEffect(() => {
    const element = polygon.current;
    add(element);
    return () => remove(element);
  }, [polygon, add, remove]);

  useEffect(() => {
    polygon.current.setStyle({
      color,
      fillOpacity,
      className,
    });
  }, [polygon, className, color, fillOpacity]);

  useEffect(() => {
    if (Object.keys(customProps).length) {
      injectPropsToLayer(polygon.current, customProps);
    }
  }, [polygon, customProps]);

  useMapEvents(
    {
      element: polygon.current,
      events: { click: onClick },
    },
    [polygon, onClick]
  );

  useEffect(() => {
    if (!allowOpenPopupOnSelect) {
      return;
    }
    const polygonElement = polygon.current;

    setTimeout(() => {
      if (selected && !polygonElement.isPopupOpen()) {
        return polygonElement.openPopup();
      }
    });
  }, [selected, polygon, leafletElement, allowOpenPopupOnSelect]);

  return children
    ? cloneElement(children, { leafletElement: polygon.current })
    : null;
};

MapPolygon.propTypes = {
  geometry: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  allowOpenPopupOnSelect: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
  fillOpacity: PropTypes.number,
  customProps: PropTypes.object,
  add: PropTypes.func,
  remove: PropTypes.func,
  onClick: PropTypes.func,
};

MapPolygon.defaultProps = {
  selected: false,
  allowOpenPopupOnSelect: false,
  color: palette.grey.main,
  fillOpacity: 0.1,
  customProps: EMPTY_VALUE,
  add: EMPTY_FUNCTION,
  remove: EMPTY_FUNCTION,
  onClick: EMPTY_FUNCTION,
};

export default memo(MapPolygon);
