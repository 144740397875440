import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  modalBodyFullscreen: {
    margin: 0,
    borderRadius: 0,
    width: '100vw',
    minHeight: '100vh',
  },
});
