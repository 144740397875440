import 'leaflet-groupedlayercontrol';
import { injectOptionsToElement } from 'helpers/map';

const groupActivatorClass = 'leaflet-control-layers-toggle';

/**
 * Leaflet decorator.
 * Adds geometries control functionality to given Leaflet global object
 * @param { object } Leaflet
 * @returns { object } Leaflet global object with `GroupedLayers` method
 */
export const withGeometries = (Leaflet) => {
  Leaflet.Control.CustomGroupedLayers = Leaflet.Control.GroupedLayers.extend({
    initialize(baseLayers, groupedOverlays, options) {
      Leaflet.Control.GroupedLayers.prototype.initialize.call(
        this,
        baseLayers,
        groupedOverlays,
        options
      );
    },
    // eslint-disable-next-line
    _initLayout() {
      // eslint-disable-next-line
      Leaflet.Control.GroupedLayers.prototype._initLayout.call(this);
      const { _container: activator, _form: form } = this;

      // For Firefox. Click on element in form doesn't work.
      Leaflet.DomEvent.off(
        activator,
        'click',
        Leaflet.DomEvent.stopPropagation
      );

      Leaflet.DomEvent.on(
        activator,
        'dblclick',
        Leaflet.DomEvent.stopPropagation
      );
      // eslint-disable-next-line
      this._map.off('click', this._collapse, this);

      // eslint-disable-next-line
      this._contentNode = Leaflet.DomUtil.create('div');
      // eslint-disable-next-line
      this.form = this._form;

      const activatorLink = activator.querySelector(`.${groupActivatorClass}`);
      if (activatorLink) {
        activatorLink.classList.remove(groupActivatorClass);
      }
      // eslint-disable-next-line
      form.insertBefore(this._contentNode, form.firstChild);
      form.classList.add('geometries-list');
      this.update();
    },

    // eslint-disable-next-line
    _update() {
      // eslint-disable-next-line
      Leaflet.Control.GroupedLayers.prototype._update.call(this);

      if (this.form) {
        // eslint-disable-next-line
        this._container.classList.add(this.options.iconClassName);
      }
    },

    update() {
      // eslint-disable-next-line
      return this._update.call(this);
    },

    removeGeometries() {
      // eslint-disable-next-line
      this._layers.forEach((layer) => {
        layer.layer.remove();
        this.removeLayer(layer);
      });
      // eslint-disable-next-line
      this._layers = [];
      this.update();
    },

    addGeometry(newLayer, overlayName) {
      const layout = newLayer.layer
        ? newLayer.layer
        : Leaflet.geoJson(newLayer);
      return this.addOverlay(layout, newLayer.name, overlayName);
    },

    addGeometries(geometries = []) {
      geometries.forEach(({ layers, name }) => {
        layers.forEach((layer) => {
          const layout = layer.layer ? layer.layer : Leaflet.geoJson(layer);
          this.addOverlay(layout, layer.name, name);
        });
      });
    },

    setOptions(options) {
      injectOptionsToElement(this, options);
      this.update();

      return this;
    },

    getLayersName() {
      // eslint-disable-next-line
      return this._layers.map(({ name }) => name);
    },

    getLayer(layerId) {
      // eslint-disable-next-line
      return this._getLayer(layerId);
    },

    getLayerByName(layerName) {
      // eslint-disable-next-line
      return this._layers.find(({ name }) => name == layerName)
    },

    getCheckedLayers() {
      // eslint-disable-next-line
      if (this._form) {
        // eslint-disable-next-line
        const inputs = this._form.getElementsByTagName('input');
        return [...inputs].reduce((values, value) => {
          if (value.checked) values.push(this.getLayer(value.layerId));
          return values;
        }, []);
      }
      return [];
    },

    addLayerToMap(layerName) {
      const layer = this.getLayerByName(layerName);
      // eslint-disable-next-line
      this._map.addLayer(layer.layer);
    },

    setOption(layerName, option) {
      const layer = this.getLayerByName(layerName);
      if (layer !== undefined) {
        // eslint-disable-next-line
        layer.layer.setOptions(option);
        this.update();
      }
    },

    setStyle(layerName, style) {
      const layer = this.getLayerByName(layerName);
      if (layer !== undefined) {
        // eslint-disable-next-line
        layer.layer.setStyle(style);
        this.update();
      }
    },

    removeLayerByName(layerName) {
      // eslint-disable-next-line
      this._layers = this._layers.filter(({ name }) => name !== layerName);
      this.update();
    },

    // eslint-disable-next-line
    _onInputClick({ target }) {
      const actionType = target.checked
        ? 'customlayeradd'
        : 'customlayerremove';
      // eslint-disable-next-line
      this._map.fire(actionType, this.getLayer(target.layerId));
      // eslint-disable-next-line
      Leaflet.Control.GroupedLayers.prototype._onInputClick.call(this);
    },

    // Hide all layers without selected
    hideOtherLayers(layer, onlyGroup = false) {
      // eslint-disable-next-line
      if (this._layers.every(({ layer: groupLayer }) => layer !== groupLayer)) {
        return;
      }

      const formInputs = this.form.getElementsByTagName('input');

      [...formInputs].forEach((checkbox) => {
        // eslint-disable-next-line
        if (!checkbox.checked || checkbox.layerId === layer._leaflet_id) {
          return;
        }
        // eslint-disable-next-line
        const { group: currentGroup } = this.getLayer(layer._leaflet_id);
        const { layer: checkboxLayer, group } = this.getLayer(checkbox.layerId);

        const hasName = currentGroup.name || group.name;

        if (!onlyGroup || (hasName && currentGroup.name === group.name)) {
          checkboxLayer.remove();
          checkbox.checked = false;
        }
      });

      return this.update();
    },
  });

  Leaflet.control.customGroupedLayers = (...args) =>
    new Leaflet.Control.CustomGroupedLayers(...args);

  return Leaflet;
};
