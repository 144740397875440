import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Collapse from 'components/common/Collapse';
import PropertiesListItem from 'components/points/PropertiesListItem';

/**
 * PropertiesList - component for displaying point properties
 * @param {Array} items
 * @param {String|React.ReactNode} title
 * @param {Number|String} selectedId
 * @param {Function} onItemClick
 * @param {String|Number} idName
 * @param {String} iconLabel
 */
const PropertiesList = ({
  items,
  title,
  selectedId,
  onItemClick,
  idName,
  iconLabel,
  sort = false,
}) => {
  const displayHelpIcon = iconLabel === 'statistic';

  items = sort ? items.sort((a, b) => a.id - b.id) : items;
  return (
    <Collapse
      title={title}
      iconLabel={iconLabel}
      isOpen
      id={idName}
      displayHelpIcon={displayHelpIcon}
    >
      {items.sort().map((item) => (
        <PropertiesListItem
          item={item}
          selected={[item.id, item.name].includes(selectedId)}
          onItemClick={onItemClick}
          key={item.id}
          id={`${idName}-${item.id}`}
        />
      ))}
    </Collapse>
  );
};

PropertiesList.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  selectedId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onItemClick: PropTypes.func,
  idName: PropTypes.string,
  iconLabel: PropTypes.string,
};

PropertiesList.defaultProps = {
  idName: '',
};

export default memo(PropertiesList);
