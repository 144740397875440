import { MAP_LABEL } from 'constants/tracker';

import { SEND_TRACKING_DATA } from '../types';

export const mapZoom = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: MAP_LABEL,
  gtmAction: 'Zoom',
});

export const mapDrag = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: MAP_LABEL,
  gtmAction: 'Dragging',
});

export const mapSearch = (searchOption) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: MAP_LABEL,
  gtmAction: 'Search',
  mapOption: searchOption,
});

export const mapChangeLayer = (layer) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: MAP_LABEL,
  gtmAction: 'Change Layer',
  mapOption: layer,
});

export const mapAddLayer = (layer) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: MAP_LABEL,
  gtmAction: 'Add Layer',
  mapOption: layer,
});

export const mapRemoveLayer = (layer) => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: MAP_LABEL,
  gtmAction: 'Remove Layer',
  mapOption: layer,
});

export const mapUploadGeometry = () => ({
  type: SEND_TRACKING_DATA,
  gtmLabel: MAP_LABEL,
  gtmAction: 'Upload geometry',
});
