import { makeStyles } from '@material-ui/core/styles';

import { theme } from 'common/theme';

export const useStyles = makeStyles(({ palette }) => ({
  header: {
    width: '100%',
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.sinay.primary['90'],
  },
  title: {
    color: '#525252',
    fontWeight: 'bold',
    fontSize: 36,
    fontFamily: 'Inter',
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  subContainer: {
    width: '100%',
    margin: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  item: {
    width: '60%',
    maxWidth: 1200,
    padding: 20,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '@media(max-width: 1400px)': {
      width: '80%',
    },
    '@media(max-width: 1200px)': {
      width: '100%',
    },
  },
  subTitle: {
    fontFamily: 'Inter',
    color: '#525252',
    fontWeight: 700,
    fontSize: 20,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  link: {
    color: '#676F78',
    fontSize: 18,
    fontWeight: 500,
    display: 'flex',
    cursor: 'pointer',
    position: 'relative',
    '&::after': {
      content: '"  \\0021D2"',
      marginRight: -12,
      position: 'absolute',
      transition: 'opacity 0.3s',
      fontWeight: 900,
      right: 0,
      opacity: 0,
    },
    '&:hover': {
      fontWeight: 'bold',
      color: palette.primary.main,
      '&::after': {
        opacity: 1,
      },
    },
  },
  footer: {
    width: '100%',
    margin: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 20,
  },
  overlay: {
    width: '100%',
    height: '100vh',
    position: 'fixed',
    top: 0,
    background: '#33333330',
    cursor: 'pointer',
    border: 0,
    padding: 0,
    margin: 0,
    textDdecoration: 'none',
    outline: 'none',
  },
  popupContainer: {
    width: '90%',
    maxWidth: 1300,
    maxHeight: '80vh',
    margin: 'auto',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: '#FFFFFF',
    overflowY: 'auto',
    borderRadius: 15,
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.4)',
    zIndex: 1,
  },
  detailsContainer: {
    width: '100%',
    maxWidth: 1200,
    margin: '0 auto',
    padding: 40,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 20,
  },
  button: {
    padding: 0,
    marginBottom: 8,
    fontSize: 14,
    color: '#525252',
    background: 'none',
    borderRadius: 10,
    border: 0,
    width: 'fit-content',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
    },
  },
  section: {
    fontFamily: 'Inter',
    color: palette.sinay.primary['40'],
    fontSize: 24,
    fontWeight: 600,
  },
  content: {
    fontFamily: 'Inter',
    fontSize: 16,
  },
}));
