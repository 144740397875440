import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
  },
  wrapper: {
    marginTop: 15,
  },
  container: {
    width: ({ width }) => width,
    marginTop: 20,
  },
});
