import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ defaultTransition, palette }) => ({
  graphWrapper: {
    position: 'relative',
    paddingRight: 35,
  },
  scatterPoint: {
    opacity: 0.6,
    transition: defaultTransition,

    '&:hover': {
      opacity: 1,
      r: ({ hoverR }) => hoverR,
    },
  },
  regressLine: {
    '& path': {
      strokeWidth: 3,
      transition: defaultTransition,

      '&:hover': {
        strokeWidth: 6,
      },
    },
  },
  colorBar: {
    border: '1px solid',
    borderColor: palette.black.main,
    background: `linear-gradient(${palette.redToGreenRange.join(',')})`,
  },
  colorBarContainer: {
    marginTop: 40,
  },
  graphContainer: {
    '& svg': {
      overflow: 'visible',
    },
  },
}));
