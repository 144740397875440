import { useCallback, useState } from 'react';

/**
 * force update hook. Returns function which triggers rerender
 * @returns function
 */
export const useForceUpdate = () => {
  const [, setFlag] = useState(true);
  const forceUpdate = () => setFlag((flag) => !flag);
  return useCallback(forceUpdate, []);
};
