import {
  ARRAY_EXPECTED,
  INVALID_NUMBER_FORMAT,
  NO_ID_IN_COLLECTION,
} from 'constants/errors';

/**
 * helpers for convert data array to object with array of ids and object of data
 * @param {Array} data
 * @param {String} idKey
 * @returns { Object }
 * @example
 * in [{ id: 'id1', ... }, { id: 'id2', ... }]
 * out { data: { id1: { ... }, id2: { ... } }, ids: [id1, id2] }
 */
export const normalizeArray = (data = [], idKey = 'id') =>
  data.reduce(
    (acc, item) => {
      if (!item[idKey]) {
        throw Error(NO_ID_IN_COLLECTION);
      }
      acc.ids.push(item[idKey]);
      acc.data[item[idKey]] = item;
      return acc;
    },
    { data: {}, ids: [] }
  );

/**
 * helpers for group and normalize data array to object with array of ids and object of data
 * Object key - id value
 * Object value - array with entity id value
 * @param {Array} data
 * @param {String} idKey
 * @returns { Object }
 * @example
 * in [{ id: '1', ... }, { id: '2', ... }, { id: '2', ... }]
 * out {
 *  data: {
 *    1: [{ ... }],
 *    2: [{ ... }, { ... }]
 *  },
 *  ids: [1, 2]
 * }
 */
export const groupAndNormalizeArrayBy = (data = [], idKey = 'id') =>
  data.reduce(
    (acc, item) => {
      if (!item[idKey]) {
        throw Error(NO_ID_IN_COLLECTION);
      }

      const accItem = acc.data[item[idKey]];

      if (accItem) {
        accItem.push(item);
      } else {
        acc.data[item[idKey]] = [item];
        acc.ids.push(item[idKey]);
      }

      return acc;
    },
    { data: {}, ids: [] }
  );

/**
 * helps to convert json object to formData
 * @param jsonObject
 * @returns {FormData}
 */
export const jsonToFormData = (jsonObject) =>
  Object.entries(jsonObject).reduce((acc, [key, value]) => {
    acc.append(key, value);
    return acc;
  }, new FormData());

/**
 * returns int if given value is integer otherwise float with given precision
 * @param { string|number } value
 * @param { number } precision num of digits after point
 * @returns { number }
 */
export const getPrettyNumber = (value, precision = 2) => {
  const preparedValue = parseFloat(value);
  if (!preparedValue && preparedValue !== 0) {
    throw Error(INVALID_NUMBER_FORMAT);
  }
  // eslint-disable-next-line
  return value === ~~preparedValue
    ? preparedValue
    : +preparedValue.toFixed(precision);
};

/**
 * returns array of prettified numbers
 * @param { array } numbersArray - array of numbers to prettify. Can be single or multidimensional array
 * @return { array }
 */
export const prettifyNumbersArray = (numbersArray) => {
  if (!Array.isArray(numbersArray)) {
    throw Error(ARRAY_EXPECTED);
  }
  const recursivePrettifier = (arg) => {
    if (Array.isArray(arg)) {
      return arg.map(recursivePrettifier);
    }
    return getPrettyNumber(arg);
  };
  return numbersArray.map(recursivePrettifier);
};

/**
 * Helper function to parse json string.
 * Returns parsed json string or undefined if
 * there's no string or there was an error during parsing
 *
 * @param string - json string to parse
 * @return {*}
 */
export const tryJsonParse = (string) => {
  try {
    return JSON.parse(string);
  } catch (e) {
    return undefined;
  }
};

/**
 * Turn a javascript object which doesn't guarente the ordering of key:value pair
 * into a data structure with an header and rows like structure
 */
export const reorderObjects = (data, keys) => {
  if (keys.length <= 0) throw Error(NO_ID_IN_COLLECTION);
  if (data === undefined || data.length === 0) {
    return {
      header: keys,
      data: [],
    };
  }
  const res = data.map((object) =>
    keys.reduce((acc, key) => {
      acc.push(object[key]);
      return acc;
    }, [])
  );
  return {
    header: keys,
    data: res,
  };
};
