import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ navbarHeight }) => ({
  createProjectContainer: {
    position: 'fixed',
    top: navbarHeight,
    zIndex: 1101,
    right: 0,
    left: 0,
    bottom: 0,
  },
  createProjectSideBar: {
    padding: 15,
    position: 'relative',
    width: '400px',
    zIndex: 1000,
    height: '100%',
  },
  createProjectMap: {
    width: 'calc(100% - 400px)',
  },
}));
