import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 5,
    zIndex: theme.zIndexMap + 1,
    width: 250,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    position: 'absolute',
    bottom: 55,
    right: 15,
    borderRadius: 2,
  },
  legend: {
    marginBottom: 5,
  },
  legendIcon: {
    width: 24,
  },
  legendLabel: {
    fontWeight: 'bold',
    fontSize: 12,
    padding: '0 10px',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    padding: 5,
  },
  openLink: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: theme.palette.links.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
