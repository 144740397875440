import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

import BaseOfferCard from '../BaseOfferCard';

import { useStyles } from './styles';

/**
 * Component for draw a row with label and value
 * @param {String} label
 * @param {String} value
 * @returns {jsx}
 */
const OfferDescriptionRow = ({ label, value }) => {
  const classes = useStyles();

  return (
    <Box className={classes.descriptionRow}>
      <Typography>{label}</Typography>
      <Typography>{value}</Typography>
    </Box>
  );
};

OfferDescriptionRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

/**
 *
 * @param {Number} id
 * @param {String} name
 * @param {String} description - offer description
 * @param {Boolean} isUnlimited - flag for limit access
 * @param {Boolean} canBuildReport - flag for access to build pdf report
 * @param {Boolean} canRunDowntime - flag for access to weather downtime
 * @param {Boolean} isTrial - flag for trial offer
 * @param {Array} zones - array with zone
 * @param {Function} onClick - handler on card click
 * @returns {jsx}
 */
const OfferCard = ({
  id,
  name,
  description,
  isUnlimited,
  isTrial,
  canBuildReport,
  canRunDowntime,
  zones,
  onClick,
}) => {
  const { t } = useTranslation();

  const clickHandler = useCallback(
    () => onClick({ id, isTrial, name, zones }),
    [onClick, zones, name, id, isTrial]
  );

  const availableZones = useMemo(
    () => zones.map((zone) => zone.name).join(', '),
    [zones]
  );

  return (
    <BaseOfferCard name={name} description={description} onClick={clickHandler}>
      <OfferDescriptionRow
        label={t('offers.card.numberOfProjects')}
        value={
          isUnlimited ? t('offers.card.unlimited') : t('offers.card.onDemand')
        }
      />
      <OfferDescriptionRow
        label={t('offers.card.pdfReportGeneration')}
        value={
          canBuildReport
            ? t('offers.card.included')
            : t('offers.card.notIncluded')
        }
      />
      <OfferDescriptionRow
        label={t('offers.card.advancedWeatherDowntimeFeature')}
        value={
          canRunDowntime
            ? t('offers.card.included')
            : t('offers.card.notIncluded')
        }
      />
      <OfferDescriptionRow
        label={t('offers.card.availableZones')}
        value={availableZones}
      />
    </BaseOfferCard>
  );
};

OfferCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isUnlimited: PropTypes.bool.isRequired,
  isTrial: PropTypes.bool.isRequired,
  canBuildReport: PropTypes.bool.isRequired,
  canRunDowntime: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  zones: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(OfferCard);
