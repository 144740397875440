/**
 * checks if given string contains one of given substrings
 * @param {string} string
 * @param {array} needles
 * @returns {boolean}
 */
export const containsOneOf = (string, needles) => {
  const needleRegexp = new RegExp(needles.join('|'));
  return needleRegexp.test(string);
};

/**
 * returns exploded to lines text by given line maximum length
 * @param { string } text
 * @param { number } lineMaxLength
 * @param { string } separator - char to separae words, by default - space char
 * @returns { string[] }
 */
export const getExplodedTextByLineMaxLength = (
  text,
  lineMaxLength,
  separator = ' '
) => {
  const words = text.split(separator);
  const lines = [];
  for (let i = 0, lineIndex = 0; i < words.length; i += 1) {
    const lineLength = (lines[lineIndex]?.length ?? 0) + words[i].length;
    if (lineLength > lineMaxLength) {
      lineIndex += 1;
    }
    lines[lineIndex] = (lines[lineIndex] ?? '') + separator + words[i];
  }

  return lines;
};
