import React from 'react';

import CommonBackdrop from 'components/common/CommonBackdrop';
import { Loader } from 'components/common/Loader';
import { palette } from 'common/theme';

/**
 * BackdropLoader modal component
 * Modal for disabling UI actions and showing loader
 */
const BackdropLoader = () => (
  <CommonBackdrop open>
    <Loader center color={palette.lightGrey.main} />
  </CommonBackdrop>
);

export default React.memo(BackdropLoader);
