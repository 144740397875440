/* eslint-disable */
import { fork } from 'redux-saga/effects';

import { appSagas } from 'ducks/app';
import projectsSaga from 'ducks/projects/sagas';
import zonesSaga from 'ducks/zones/sagas';
import { userSagas } from 'ducks/user';
import { offersSagas } from 'ducks/offers';
import { pointsSagas } from 'ducks/points';
import { stat2dSagas } from 'ducks/stat2d';
import { geometriesSagas } from 'ducks/geometries';
import { buoysSagas } from 'ducks/buoys';
import { staticPagesSagas } from 'ducks/staticPages';
import { reportsSagas } from 'ducks/reports';
import { satelliteComparisonsSagas } from 'ducks/satelliteComparisons';
import { demoSagas } from 'ducks/demo';
import { productHistorySagas } from 'ducks/productHistory';
import { planningsSagas } from 'ducks/plannings';
import { commentsSagas } from 'ducks/comments';
import { contentTypesSaga } from 'ducks/contentTypes';
import { usageSagas } from 'ducks/usage';
import { marineContractorsSagas } from 'ducks/marineContractors/index';
import { trackerSagas } from 'ducks/trackers';
import { keycloakSagas } from 'ducks/keycloak';

export default function* rootSaga() {
  yield fork(appSagas);
  yield fork(projectsSaga);
  yield fork(zonesSaga);
  yield fork(userSagas);
  yield fork(offersSagas);
  yield fork(pointsSagas);
  yield fork(stat2dSagas);
  yield fork(geometriesSagas);
  yield fork(buoysSagas);
  yield fork(staticPagesSagas);
  yield fork(reportsSagas);
  yield fork(satelliteComparisonsSagas);
  yield fork(demoSagas);
  yield fork(productHistorySagas);
  yield fork(planningsSagas);
  yield fork(commentsSagas);
  yield fork(contentTypesSaga);
  yield fork(usageSagas);
  yield fork(marineContractorsSagas);
  // spawn worker that forward tracking request to google tag manager
  yield fork(trackerSagas);
  // spawn worker handle auth through Keycloak
  yield fork(keycloakSagas);
}
