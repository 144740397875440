import html2canvas from 'html2canvas';
import _ from 'lodash';

import { NO_SUCH_NODE } from 'constants/errors';
import { IGNORE_ON_SAVING_CLASS } from 'constants/graphs';
import { palette } from 'common/theme';
import { getExplodedTextByLineMaxLength } from 'helpers/string';

import { toUnit8Array } from './common';

/**
 * indicates if browser supports passive listeners
 * @returns { boolean }
 */
export const isPassiveListenersSupported = () => {
  let passiveSupported = false;
  try {
    window.addEventListener(
      'test',
      null,
      Object.defineProperty({}, 'passive', {
        get() {
          passiveSupported = true;
          return false;
        },
      })
    );
  } catch (err) {
    // eslint-disable-next-line
    console.warn('passive listeners not supported in current browser');
  }
  return passiveSupported;
};

/**
 * Converts html node to png
 * @param { string } htmlSelector
 * @param { object } options
 * @returns { Promise }
 * @see html2canvas
 */
export function convertHtmlToPngByQuerySelector(htmlSelector, options) {
  return new Promise((resolve, reject) => {
    try {
      const node = document.querySelector(htmlSelector);
      const convertOptions = {
        logging: false,
      };
      if (options?.padding) {
        const { width, height } = node.getBoundingClientRect();
        convertOptions.width = width + 2 * options.padding;
        convertOptions.height = height + 2 * options.padding;
      }

      html2canvas(node, convertOptions).then((canvas) => {
        resolve(canvas.toDataURL('image/png'));
      });
    } catch (e) {
      reject(e);
    }
  });
}

/**
 * Converts svg node (by given query selector) to png
 * returns Promise which resolves with png dataUrl
 * @note to include title out of svg, you can pass `titleSelector` in options,
 * it would be injected in top of result image
 * @note svg styling are ignored, so:
 * - for transparent svg elements add `IGNORE_ON_SAVING_CLASS` to not include and render them on saving
 * - If you set `fill` or `stroke` props by css, duplicate them by passing through arguments, to appear in saved image
 * @param { string } svgSelector
 * @param {{ titleSelector, padding }} options
 * @returns { Promise }
 * @see IGNORE_ON_SAVING_CLASS
 */
export function convertSvgToPngByQuerySelector(svgSelector, options) {
  // get WW & WD selectors
  const durationSelector = options?.durationSelector;
  const limitSelector = options?.limitSelector;
  const quantileSelector = options?.quantileSelector;

  return new Promise((resolve, reject) => {
    try {
      const svgNode = document.querySelector(svgSelector);
      if (!svgNode) {
        throw Error(NO_SUCH_NODE);
      }
      const { width, height } = svgNode.getBoundingClientRect();
      const title = options?.titleSelector
        ? document.querySelector(options.titleSelector)?.textContent ?? ''
        : '';

      // retrieve WW & WD parameters
      const duration = durationSelector
        ? document.getElementById(options.durationSelector)?.innerHTML ?? ''
        : '';
      const getArrLimit = () => {
        // multiple limit parameters can be displayed
        const arr = limitSelector
          ? document.querySelectorAll(options.limitSelector)
          : [];
        return arr.length
          ? Array.from(arr).map((x) => _.unescape(x.innerHTML))
          : arr;
      };
      const quantile = quantileSelector
        ? document.getElementById(options.quantileSelector)?.innerHTML ?? ''
        : '';

      const clonedSvgNode = svgNode.cloneNode(true);
      clonedSvgNode
        .querySelectorAll(`.${IGNORE_ON_SAVING_CLASS}`)
        .forEach((transarentElement) => transarentElement.remove());

      const svgRawString = new XMLSerializer().serializeToString(clonedSvgNode);
      const svgBlob = new Blob([svgRawString], {
        type: 'image/svg+xml;charset=utf-8',
      });
      const svgUrlObject = URL.createObjectURL(svgBlob);

      const lineHeight = 25;
      const canvas = document.createElement('canvas');
      const padding = options?.padding ?? 0;
      const titleLines = title ? getExplodedTextByLineMaxLength(title, 60) : [];
      const titleHeight = (titleLines.length + 1) * lineHeight;

      canvas.width = width + 2 * padding;
      canvas.height = height + 2 * padding + titleHeight;
      const context = canvas.getContext('2d');
      context.font = '18px Arial';
      context.fillStyle = palette.white.main;
      context.fillRect(0, 0, canvas.width, canvas.height);

      if (title) {
        context.fillStyle = palette.black.main;
        titleLines.forEach((line, index) =>
          context.fillText(
            line,
            padding,
            padding + lineHeight + index * lineHeight
          )
        );
      }

      // add AWD parameters if exist
      context.font = '14px Arial';
      const arrParameters = [];
      const arrLimit = getArrLimit();
      const yPadding = title ? padding + titleHeight : padding;
      if (duration) {
        arrParameters.push(duration);
      }
      if (arrLimit.length) {
        arrParameters.push(arrLimit.join(' | '));
      }
      if (quantile) {
        arrParameters.push(quantile);
      }
      if (arrParameters.length) {
        context.fillText(
          arrParameters.join(', '),
          lineHeight - padding,
          yPadding
        );
      }

      const image = new Image();
      // calculate img padding if title or arrParameters exists
      const getImgPadding = () => {
        if (arrParameters.length) {
          return lineHeight + yPadding;
        }
        if (title) {
          return padding + titleHeight;
        }
        return padding;
      };

      image.addEventListener('load', () => {
        context.drawImage(image, padding, getImgPadding());
        const png = canvas.toDataURL('image/png');
        URL.revokeObjectURL(svgUrlObject);
        resolve(png);
      });

      image.src = svgUrlObject;
    } catch (e) {
      reject(e);
    }
  });
}

/**
 * Converts dataUrl to Blob
 * @note not use it for urlencoded dataURLs
 * @param { string } dataURL
 * @returns { Blob }
 */
function dataURLtoBlob(dataURL) {
  const [typeInfo, bytes] = dataURL.split(',');

  const byteString = atob(bytes);
  const [, mimeString] = /:(.*);/.exec(typeInfo);
  const u8Array = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i += 1) {
    u8Array[i] = byteString[i];
  }

  return new Blob([u8Array], { type: mimeString });
}

/**
 * Emulates user click on link with given dataUrl
 * @param { string } dataUrl - dataUrl file to save
 * @param { string } fileName - custom filename for saved file, by default current ISO date string
 */
export const triggerDownloadDataUrl = (
  dataUrl,
  fileName = new Date().toISOString()
) => {
  const name = fileName || `${new Date().toISOString()}`;

  if (window.navigator.msSaveOrOpenBlob) {
    const blob = dataURLtoBlob(dataUrl);
    window.navigator.msSaveBlob(blob, name);
    return;
  }

  const tmpLink = window.document.createElement('a');
  tmpLink.href = dataUrl;
  tmpLink.download = name;
  document.body.appendChild(tmpLink);
  tmpLink.click();
  document.body.removeChild(tmpLink);
};

/**
 * Emulates user click on link with given blob and downloads or opens file
 * @param { Object } arrayBuffer - arrayBuffer file to save
 * @param { string } fileName - custom name for saved file, by default current ISO date string
 * @param { string } fileType - custom saved file type, for example 'application/zip'
 * @param { boolean } isArrayBuffer - if true, file is arrayBuffer
 * @param { boolean } isDownload - if true, file will be downloaded
 * @param { boolean } isOpenInNewTab - if true, file will be opened in new tab
 * @param { Object } windowTabInstance - new window for opening file, if you can't use  'target = '_blank''
 */
export const triggerDownloadBlob = ({
  blob = null,
  fileName = new Date().toISOString(),
  fileType = '',
  isArrayBuffer = false,
  isDownload = false,
  isOpenInNewTab = true,
}) => {
  const name = fileName || `${new Date().toISOString()}`;

  // deprecated : https://developer.mozilla.org/en-US/docs/Web/API/Navigator/msSaveOrOpenBlob
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, name);
    return;
  }

  const url = URL.createObjectURL(
    isArrayBuffer
      ? new Blob([toUnit8Array(blob)], {
          type: fileType,
        })
      : blob
  );

  const tmpLink = window.document.createElement('a');
  tmpLink.href = url;

  if (isDownload) {
    tmpLink.download = name;
  }

  if (isOpenInNewTab) {
    tmpLink.target = '_blank';
  }

  document.body.appendChild(tmpLink);
  tmpLink.click();
  URL.revokeObjectURL(url);
  document.body.removeChild(tmpLink);
};
