import { useEffect, useMemo } from 'react';
import Leaflet from 'leaflet-providers';
import PropTypes from 'prop-types';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet.measurecontrol';

import './style.scss';

import { useMapInstance } from 'hooks/leaflet/useMapInstance';
import { MAP_CONTROLS_POSITION } from 'constants/map';
import { useExpensiveRef } from 'hooks/useExpensiveRef';

const MapMeasureControl = ({
  position,
  primaryLengthUnit,
  secondaryLengthUnit,
  primaryAreaUnit,
  secondaryAreaUnit,
  activeColor,
  completedColor,
}) => {
  const mapInstance = useMapInstance();
  const options = useMemo(
    () => ({
      position,
      primaryLengthUnit,
      secondaryLengthUnit,
      primaryAreaUnit,
      secondaryAreaUnit,
      activeColor,
      completedColor,
    }),
    [
      position,
      primaryLengthUnit,
      secondaryLengthUnit,
      primaryAreaUnit,
      secondaryAreaUnit,
      activeColor,
      completedColor,
    ]
  );
  const measure = useExpensiveRef(() =>
    Leaflet.Control.measureControl(options)
  );

  useEffect(() => {
    if (!mapInstance) {
      return;
    }
    const control = measure.current;

    control.addTo(mapInstance);
    return () => control.remove();
  }, [mapInstance, measure]);

  useEffect(() => {
    const { _container: element } = measure.current;
    if (!element) {
      return;
    }
    Leaflet.DomEvent.on(element, 'dblclick', Leaflet.DomEvent.stopPropagation);

    return () => {
      Leaflet.DomEvent.off(
        element,
        'dblclick',
        Leaflet.DomEvent.stopPropagation
      );
    };
  }, [mapInstance, measure]);

  return null;
};

MapMeasureControl.propTypes = {
  position: PropTypes.string,
  primaryLengthUnit: PropTypes.string,
  secondaryLengthUnit: PropTypes.string,
  primaryAreaUnit: PropTypes.string,
  secondaryAreaUnit: PropTypes.string,
  activeColor: PropTypes.string,
  completedColor: PropTypes.string,
};

MapMeasureControl.defaultProps = {
  position: MAP_CONTROLS_POSITION.topLeft,
};

export default MapMeasureControl;
