const prefix = '@@points';

export const REQUEST_POINTS = `${prefix}/REQUEST_POINTS`;
export const REQUEST_POINTS_SUCCESS = `${prefix}/REQUEST_POINTS_SUCCESS`;
export const REQUEST_POINTS_ERROR = `${prefix}/REQUEST_POINTS_ERROR`;

export const REQUEST_POINT = `${prefix}/REQUEST_POINT`;
export const REQUEST_POINT_SUCCESS = `${prefix}/REQUEST_POINT_SUCCESS`;
export const REQUEST_POINT_ERROR = `${prefix}/REQUEST_POINT_ERROR`;

export const DELETE_POINT = `${prefix}/DELETE_POINT`;
export const DELETE_POINT_SUCCESS = `${prefix}/DELETE_POINT_SUCCESS`;
export const DELETE_POINT_ERROR = `${prefix}/DELETE_POINT_ERROR`;

export const DELETE_PROJECT_POINTS = `${prefix}/DELETE_PROJECT_POINTS`;
export const DELETE_PROJECT_POINTS_SUCCESS = `${prefix}/DELETE_PROJECT_POINTS_SUCCESS`;
export const DELETE_PROJECT_POINTS_ERROR = `${prefix}/DELETE_PROJECT_POINTS_ERROR`;

export const CREATE_POINTS_FROM_FILE = `${prefix}/CREATE_POINTS_FROM_FILE`;
export const CREATE_POINTS_FROM_FILE_SUCCESS = `${prefix}/CREATE_POINTS_FROM_FILE_SUCCESS`;
export const CREATE_POINTS_FROM_FILE_ERROR = `${prefix}/CREATE_POINTS_FROM_FILE_ERROR`;

export const CREATE_POINT = `${prefix}/CREATE_POINT`;
export const CREATE_POINT_SUCCESS = `${prefix}/CREATE_POINT_SUCCESS`;
export const CREATE_POINT_ERROR = `${prefix}/CREATE_POINT_ERROR`;

export const UPDATE_POINT = `${prefix}/UPDATE_POINT`;
export const UPDATE_POINT_SUCCESS = `${prefix}/UPDATE_POINT_SUCCESS`;
export const UPDATE_POINT_ERROR = `${prefix}/UPDATE_POINT_ERROR`;

export const RUN_POINT_TASK = `${prefix}/RUN_POINT_TASK`;
export const CANCEL_POINT_TASK = `${prefix}/CANCEL_POINT_TASK`;

export const START_UPDATE_POINT_TASK_JOB = `${prefix}/START_UPDATE_POINT_TASK_JOB`;
export const STOP_UPDATE_POINT_TASK_JOB = `${prefix}/STOP_UPDATE_POINT_TASK_JOB`;
export const FINISH_UPDATE_POINT_TASK_JOB = `${prefix}/FINISH_UPDATE_POINT_TASK_JOB`;

export const CANCEL_POINT_TASK_JOB = `${prefix}/CANCEL_POINT_TASK_JOB`;
export const CANCEL_POINT_TASK_JOB_SUCCESS = `${prefix}/CANCEL_POINT_TASK_JOB_SUCCESS`;
export const CANCEL_POINT_TASK_JOB_ERROR = `${prefix}/CANCEL_POINT_TASK_JOB_ERROR`;

export const DOWNLOAD_TASK = `${prefix}/DOWNLOAD_TASK`;
export const DOWNLOAD_TASK_SUCCESS = `${prefix}/DOWNLOAD_TASK_SUCCESS`;
export const DOWNLOAD_TASK_ERROR = `${prefix}/DOWNLOAD_TASK_ERROR`;
