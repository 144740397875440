import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { selectReversedModalsCollection } from 'ducks/modals/selectors';
import { MODAL_COMPONENTS_BY_TYPES } from 'constants/modals';

/**
 * ModalManager container
 * Controls the display of modal windows
 * @param modalsMap - all modal components by types
 */
const ModalManager = ({ modalsMap = MODAL_COMPONENTS_BY_TYPES }) => {
  const reversedModalsCollection = useSelector(selectReversedModalsCollection);
  if (!reversedModalsCollection.length) {
    return null;
  }
  return (
    <>
      {reversedModalsCollection.map(({ id, type, options }) => {
        const CurrentModal = modalsMap[type];
        return <CurrentModal {...options} id={id} key={id} />;
      })}
    </>
  );
};

export default memo(ModalManager);
