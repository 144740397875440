import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ defaultTransition }) => ({
  searchContainer: {
    padding: 5,
    width: '100%',
  },
  searchInputRoot: {
    width: '100%',
    cursor: 'pointer',
    border: '2px solid #E5E5E5',
    padding: '0 10px',
    borderRadius: 8,
    background: 'white',
  },
  searchField: {
    fontSize: 14,
    height: 32,
    width: '100%',
    transition: defaultTransition,
    '&[value=""] + $clearField': {
      opacity: 0,
      pointerEvents: 'none',
    },
    '&::-ms-clear': {
      display: 'none',
    },
  },
  clearField: {
    width: 0,
    opacity: 1,
    transition: defaultTransition,
    position: 'relative',
    right: 30,
  },
}));
