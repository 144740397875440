import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropsTypes from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  TextField,
  ClickAwayListener,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { useModal } from 'hooks/useModal';
import {
  getHelperTextMessage,
  getMCObjectType,
  getMCSelectors,
  getMCActions,
  getErrorMessages,
  getItemNameByAttributes,
} from 'helpers/marineContractors';
import {
  CONSTRAINTS_ATTRIBUTES,
  ENTER_KEY_REGEX,
  ESCAPE_KEY_REGEX,
  MC_OBJECT_GROUP,
  MC_OBJECT_TYPE,
  UPDATE_ACTION,
  NO_ID,
  TAB_KEY_REGEX,
  OPERATION_DEFAULT_NAME_REGEX,
} from 'constants/marineContractors';
import { NOTIFICATION_MODAL } from 'constants/modals';
import {
  EMPTY_VALUE,
  EMPTY_ARRAY,
  SNACKBAR_ERROR_TYPE,
  EMPTY_STRING,
} from 'constants/common';
import { NUMBER_REGEXP } from 'constants/regexp';

/**
 * ClickAway text field component - connected with EditableMCTextField
 * A simple text field component but catching the component unmounting
 * @param { object } object - Marine contractors object
 * @param { object } objectGroup - Object type label
 * @param { string } fieldName
 * @param { boolean } hasError
 * @param { object } fieldErrors
 * @param { function } handleOnChange
 * @param { function } handleClickAway
 * @param { function } handleOnKeyPush
 * @param { function } handleUnmount
 * @param { boolean } isNumber
 */
const ClickAwayTextFieldComponent = React.memo(
  ({
    object,
    objectGroup,
    fieldName,
    hasError,
    fieldErrors,
    handleOnChange,
    handleClickAway,
    handleOnKeyPush,
    handleUnmount,
    isNumber,
  }) => {
    const { t } = useTranslation();

    const helperText = getHelperTextMessage(
      fieldErrors,
      fieldName,
      objectGroup,
      UPDATE_ACTION,
      t
    );

    const value = object[fieldName];

    // this useMemo only implemented for default operation name
    // disgusting... kind of laziness to implement the constraint behavior for operation (autocommit when all fields are filled)
    const defaultValue = useMemo(() => {
      if (value) {
        if (_.isString(value) && value.match(OPERATION_DEFAULT_NAME_REGEX)) {
          return EMPTY_STRING;
        }
        return value;
      }
      return EMPTY_STRING;
    }, [value]);

    // transform string into 0 int if TextField is number type (possible on Firefox)
    const prepareHandleOnChange = useCallback(
      (e) => {
        const targetValue = e.target.value;
        if (isNumber) {
          if (
            targetValue !== EMPTY_STRING &&
            !targetValue.toString().match(NUMBER_REGEXP)
          ) {
            e.target.value = 0;
          }
        }
        handleOnChange(e);
        // eslint-disable-next-line
    }, [handleOnChange])

    useEffect(
      () => () => {
        handleUnmount();
      },
      [handleUnmount]
    );

    return (
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={handleClickAway}
      >
        <TextField
          onKeyPress={handleOnKeyPush}
          onKeyDown={handleOnKeyPush}
          error={hasError}
          label={
            hasError
              ? t(
                  `marineContractors.fields.${objectGroup}.labels.${fieldName}.error`
                )
              : t(
                  `marineContractors.fields.${objectGroup}.labels.${fieldName}.title`
                )
          }
          defaultValue={defaultValue}
          variant="standard"
          onChange={prepareHandleOnChange}
          helperText={helperText}
          type={isNumber ? 'number' : 'text'}
          autoFocus
        />
      </ClickAwayListener>
    );
  }
);

ClickAwayTextFieldComponent.propTypes = {
  object: PropsTypes.object.isRequired,
  objectGroup: PropsTypes.string.isRequired,
  fieldName: PropsTypes.string.isRequired,
  hasError: PropsTypes.bool.isRequired,
  fieldErrors: PropsTypes.object,
  handleOnChange: PropsTypes.func.isRequired,
  handleClickAway: PropsTypes.func.isRequired,
  handleOnKeyPush: PropsTypes.func.isRequired,
  handleUnmount: PropsTypes.func.isRequired,
  isNumber: PropsTypes.bool,
};

ClickAwayTextFieldComponent.defaultProps = {
  isNumber: false,
};

/**
 * Select component - connected with EditableMCTextField
 * A simple select field component but catching the component unmounting
 * @param { object } object - Marine contractors object
 * @param { object } objectGroup - Object type label
 * @param { string } fieldName
 * @param { boolean } hasError
 * @param { object } fieldErrors
 * @param { Array } items
 * @param { string } itemKey
 * @param { function } handleOnChange
 * @param { function } handleClickAway
 * @param { function } handleUnmount
 */
const SelectComponent = React.memo(
  ({
    object,
    objectGroup,
    fieldName,
    hasError,
    fieldErrors,
    items,
    itemKey,
    handleOnChange,
    handleClickAway,
    handleUnmount,
  }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const helperText = getHelperTextMessage(
      fieldErrors,
      fieldName,
      objectGroup,
      UPDATE_ACTION,
      t
    );

    useEffect(() => {
      setOpen(true);
      return () => {
        setOpen(false);
        handleUnmount();
      };
      // eslint-disable-next-line
      }, [])

    const handleOpen = useCallback(() => {
      setOpen(true);
    }, [setOpen]);

    const handleClose = useCallback(
      (e) => {
        // here's the trick : the event target value equals 0 when click on the item menu else no value is supplied
        // if no value, the selection will be no valid (acts as an Escape key pushed)
        handleClickAway(e, e.target.value !== 0);
      },
      [handleClickAway]
    );

    const handleOnChangeWrapped = useCallback(
      (e) => {
        handleOnChange(e);
        setOpen(false);
      },
      [handleOnChange, setOpen]
    );

    return (
      <FormControl error={hasError} fullWidth>
        <InputLabel size="small">
          {hasError
            ? t(
                `marineContractors.fields.${objectGroup}.labels.${fieldName}.error`
              )
            : t(
                `marineContractors.fields.${objectGroup}.labels.${fieldName}.title`
              )}
        </InputLabel>
        <Select
          onChange={handleOnChangeWrapped}
          open={open}
          onOpen={handleOpen}
          value={object[fieldName]}
          name={fieldName}
          onClose={handleClose}
          autoFocus
        >
          {items.map((i) => (
            <MenuItem key={i.name} value={i.name}>
              {itemKey && itemKey in i ? i[itemKey] : i.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  }
);

SelectComponent.propTypes = {
  object: PropsTypes.object.isRequired,
  objectGroup: PropsTypes.string.isRequired,
  fieldName: PropsTypes.string.isRequired,
  hasError: PropsTypes.bool.isRequired,
  fieldErrors: PropsTypes.object,
  items: PropsTypes.array.isRequired,
  itemKey: PropsTypes.string,
  handleOnChange: PropsTypes.func.isRequired,
  handleClickAway: PropsTypes.func.isRequired,
  handleUnmount: PropsTypes.func.isRequired,
};

SelectComponent.defaultProps = {
  itemKey: '',
};

/**
 * Editable marine contractors TextField component - useful to edit a MC field without displaying a modal
 * @param { Node } children
 * @param { object } object - Marine contractors object (boat, operation, constraint)
 * @param { string } fieldName - Field name includes in the object (one of his existing key)
 * @param { boolean } canEdit - Ability to desactivate the edit mode (acts as a read only TextField)
 * @param { boolean } isNumber - display a number field
 * @param { boolean } isSelect - display a select drop down field (can not be used with isNumber)
 * @param { Array } items - array of objects that display content in select drop down (default field to show value : name)
 * @param { string } itemKey - specify a field instead of items default field : name
 * @param { string } placeholder - show a default value is no value available
 * @param { boolean } forceFocus - obvious
 * @param { boolean } forceTitle - display the EditableComponent as a Grid flex box with two rows (title + value)
 * @param { function } handleHasValid - callback fired when any field is validated
 * @param { function } handleChange - callback fired when validation is done (commit updated object)
 * @returns { JSX }
 */
const EditableTextField = ({
  children,
  object,
  fieldName,
  canEdit,
  isNumber,
  isSelect,
  items,
  itemKey,
  placeholder,
  forceFocus,
  forceTitle,
  handleHasValid,
  handleChange,
}) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const { t } = useTranslation();

  const [objectUpdated, setObjectUpdated] = useState(EMPTY_VALUE);
  const [hasValid, setHasValid] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  const objectType = getMCObjectType(object);
  const objectGroup = MC_OBJECT_GROUP[objectType];

  const { errorSelector, getByIdSelector } = getMCSelectors(objectType);
  const { cleanCreateAction, cleanUpdateAction } = getMCActions(objectType);

  const errors = useSelector((state) => errorSelector(object)(state));
  const objectStore = useSelector((state) => getByIdSelector(object.id)(state));

  const { fieldErrors, nonFieldErrors } = getErrorMessages(errors);
  const hasError = !!errors?.allErrors?.length;
  const hasFieldNameError = fieldName in fieldErrors;

  useEffect(() => {
    if (hasValid) {
      if (handleChange) {
        // hasChanged means nothing for a new object
        const hasChanged =
          object.id !== NO_ID
            ? objectStore[fieldName] !== objectUpdated[fieldName]
            : true;
        handleChange(objectUpdated, hasChanged);
      }
      setHasValid(false);
    }
    // eslint-disable-next-line
      }, [hasValid]);

  useEffect(() => {
    if (hasError) {
      if (hasFieldNameError) {
        setIsEditable(true);
        return;
      }
      if (Object.entries(nonFieldErrors).length) {
        showNonFieldErrors();
        if (handleChange) {
          handleChange(objectStore);
        }
      }
    }
    // eslint-disable-next-line
      }, [hasError])

  useEffect(() => {
    setObjectUpdated(object);
  }, [object]);

  useEffect(() => {
    if (forceFocus) {
      if (!hasError || fieldName in fieldErrors) {
        setIsEditable(true);
      }
    }
    // eslint-disable-next-line
  }, [forceFocus]);

  const showNonFieldErrors = useCallback(() => {
    openModal(NOTIFICATION_MODAL, {
      type: SNACKBAR_ERROR_TYPE,
      message: t(
        [
          `marineContractors.modals.common.${nonFieldErrors}`,
          'marineContractors.modals.common.error',
        ],
        {
          object:
            object?.name ||
            getItemNameByAttributes(object, CONSTRAINTS_ATTRIBUTES),
        }
      ),
    });
    // eslint-disable-next-line
      }, [object, nonFieldErrors])

  const cleanErrors = useCallback(() => {
    dispatch(
      objectStore.id === NO_ID
        ? cleanCreateAction(objectStore)
        : cleanUpdateAction(objectStore)
    );
    // eslint-disable-next-line
  }, [objectStore]);

  const handleOnChange = useCallback(
    (e) => {
      e.persist();
      setObjectUpdated((prevState) => ({
        ...prevState,
        [fieldName]: e.target.value,
      }));
    },
    // eslint-disable-next-line
    [fieldName]
  );

  const handleOnValid = useCallback(
    () => {
      if (handleHasValid) {
        handleHasValid(fieldName);
      }
      cleanErrors();
      if (handleChange) {
        setHasValid(true);
        return;
      }
      setIsEditable(false);
      setHasValid(true);
    },
    // eslint-disable-next-line
    [
      objectUpdated,
      cleanErrors
    ]
  );

  const handleClickAway = useCallback(
    (e, forceEscape = false) => {
      const valid = isSelect && !e.key?.match(ESCAPE_KEY_REGEX) && !forceEscape;
      const hasChanged = object[fieldName] !== objectUpdated[fieldName];
      setIsEditable(false);
      cleanErrors();
      if (hasError && !hasChanged) {
        handleChange(
          {
            ...objectUpdated,
            [fieldName]: object.id === NO_ID ? '' : objectStore[fieldName],
          },
          object.id === NO_ID && objectType === MC_OBJECT_TYPE.CONSTRAINT
        );
        return;
      }
      setHasValid(true);
      if (handleHasValid) {
        handleHasValid(fieldName, valid);
      }
      // eslint-disable-next-line
      }, [fieldErrors, hasError, objectUpdated, objectStore, object, isSelect, cleanErrors]);

  const handleOnKeyPush = useCallback(
    (e) => {
      if (e.key.match(ESCAPE_KEY_REGEX)) {
        handleClickAway();
        e.preventDefault();
      }
      if (e.key.match(ENTER_KEY_REGEX) || e.key.match(TAB_KEY_REGEX)) {
        handleOnValid();
        e.preventDefault();
      }
    },
    [handleOnValid, handleClickAway]
  );

  const handleEdit = useCallback(() => {
    if (canEdit) {
      setIsEditable(true);
    }
    // eslint-disable-next-line
      }, [canEdit]);

  const handleUnmount = useCallback(() => {
    setIsEditable(false);
    // eslint-disable-next-line
      }, [object]);

  const selectValue = useMemo(() => {
    if (['', undefined, null].includes(object[fieldName])) {
      return placeholder;
    }
    if (itemKey) {
      const item = items.find((o) => o.name === object[fieldName]);
      if (item) {
        return item[itemKey];
      }
    }
    return object[fieldName];
    // eslint-disable-next-line
      }, [itemKey, items, object])

  const getUnselectMCComponent = () => {
    if (forceTitle || isSelect) {
      return (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          onClick={() => handleEdit()}
        >
          <Grid item>
            <div style={{ color: 'gray', fontSize: '12px' }}>
              {t(
                `marineContractors.fields.${objectGroup}.labels.${fieldName}.title`
              )}
            </div>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            direction="row"
          >
            <Grid item zeroMinWidth>
              <Typography noWrap>{selectValue}</Typography>
            </Grid>
            <Grid item>{children}</Grid>
          </Grid>
        </Grid>
      );
    }
    return (
      <div
        aria-hidden="true"
        style={{ display: 'inline-flex' }}
        onClick={() => handleEdit()}
      >
        <Typography>
          {object[fieldName] ? object[fieldName] : placeholder}
        </Typography>
        {children}
      </div>
    );
  };

  const getSelectableMCComponent = () =>
    isEditable && (canEdit || hasFieldNameError) ? (
      <SelectComponent
        object={object}
        objectGroup={objectGroup}
        fieldName={fieldName}
        hasError={hasError}
        items={items}
        itemKey={itemKey}
        fieldErrors={fieldErrors}
        handleOnChange={handleOnChange}
        handleClickAway={handleClickAway}
        handleUnmount={handleUnmount}
      />
    ) : (
      getUnselectMCComponent()
    );

  const getEditableMCComponent = () =>
    isEditable && (canEdit || hasFieldNameError) ? (
      <ClickAwayTextFieldComponent
        object={object}
        objectGroup={objectGroup}
        fieldName={fieldName}
        hasError={hasError}
        fieldErrors={fieldErrors}
        handleOnChange={handleOnChange}
        handleClickAway={handleClickAway}
        handleOnKeyPush={handleOnKeyPush}
        handleUnmount={handleUnmount}
        isNumber={isNumber}
      />
    ) : (
      getUnselectMCComponent()
    );

  return isSelect ? getSelectableMCComponent() : getEditableMCComponent();
};

EditableTextField.propTypes = {
  children: PropsTypes.node,
  object: PropsTypes.object.isRequired,
  fieldName: PropsTypes.string.isRequired,
  canEdit: PropsTypes.bool,
  isNumber: PropsTypes.bool,
  isSelect: PropsTypes.bool,
  items: PropsTypes.array,
  itemKey: PropsTypes.string,
  placeholder: PropsTypes.string,
  forceFocus: PropsTypes.bool,
  forceTitle: PropsTypes.bool,
  handleHasValid: PropsTypes.func.isRequired,
  handleChange: PropsTypes.func.isRequired,
};

EditableTextField.defaultProps = {
  canEdit: true,
  isNumber: false,
  isSelect: false,
  items: EMPTY_ARRAY,
  itemKey: '',
  placeholder: '',
  forceFocus: false,
  forceTitle: false,
};

export default React.memo(EditableTextField);
