import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  legend: {
    cursor: 'pointer',
    fillOpacity: 0.9,
  },
  legendLink: {
    cursor: 'pointer',
    fill: palette.links.main,
  },
}));
