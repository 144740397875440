import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ProjectMenuComponent from 'components/projects/ProjectMenu';
import {
  selectProjectById,
  selectProjectOfferInstanceByProjectId,
} from 'ducks/projects/selectors';

/**
 * ProjectMenu container
 * Displays project  menu list
 */
const ProjectMenu = () => {
  const { id: projectId } = useParams();
  const { canRunDowntime } = useSelector((state) =>
    selectProjectOfferInstanceByProjectId(state, projectId)
  );
  const { canRunDowntime: projectCanRunDowntime } = useSelector((state) =>
    selectProjectById(state, projectId)
  );
  const hasAWD = canRunDowntime || projectCanRunDowntime;

  return <ProjectMenuComponent projectId={projectId} hasAWD={hasAWD} />;
};

export default memo(ProjectMenu);
