import React, { useCallback, useEffect, useState } from 'react';
import PropsTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import OperationComponent from 'components/projects/MarineContractors/Operations/Operation/index';
import BlockUpdateComponent from 'components/common/MarineContractors/BlockUpdateComponent/index';
import { useModal } from 'hooks/useModal';
import { DELETE_MC_MODAL } from 'constants/modals';
import {
  selectMarineContractorsConstraintsByOperationId,
  selectMarineContractorsOperationIsUpdating,
  selectMarineContractorsOperationConstraintsActions,
} from 'ducks/marineContractors/selectors';
import {
  createMarineContractorsTemporaryConstraint,
  updateMarineContractorsOperation,
} from 'ducks/marineContractors/actions';
import { getDefaultConstraint } from 'constants/marineContractors';
import { EMPTY_VALUE } from 'constants/common';

/**
 * Operation container - display operation information
 * @param { object } operation
 * @param { boolean } isSelected
 * @param { function } handleSelect
 * @param { function } handleOperations
 * @param { Boolean } forceFocus
 * @returns { JSX }
 */
const OperationContainer = React.memo(
  ({
    operation,
    isSelected,
    isChecked,
    handleSelect,
    handleOperations,
    forceFocus,
  }) => {
    const { openModal } = useModal();
    const dispatch = useDispatch();

    const [forceFocuses, setForceFocuses] = useState(EMPTY_VALUE);
    const [operationUpdate, setOperationUpdate] = useState(operation);
    const [durationValidated, setDurationValidated] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);

    const constraints = useSelector((state) =>
      selectMarineContractorsConstraintsByOperationId(operation.id)(state)
    );

    const isUpdating = useSelector((state) =>
      selectMarineContractorsOperationIsUpdating(operation)(state)
    );
    const constraintsActions = useSelector((state) =>
      selectMarineContractorsOperationConstraintsActions(operation)(state)
    );

    useEffect(() => {
      setOperationUpdate(operation);
    }, [operation]);

    useEffect(() => {
      if (forceFocus) {
        setForceFocuses({
          name: true,
          duration: false,
        });
      }
    }, [forceFocus]);

    useEffect(() => {
      if (!isSelected) {
        setForceFocuses({
          name: false,
          duration: false,
        });
      }
    }, [isSelected]);

    useEffect(() => {
      if (hasChanged) {
        dispatch(updateMarineContractorsOperation(operationUpdate));
        setHasChanged(false);
      }
      // eslint-disable-next-line
    }, [hasChanged])

    useEffect(() => {
      if (durationValidated) {
        if (!constraints?.length) {
          const constraint = getDefaultConstraint(operation.id);
          dispatch(createMarineContractorsTemporaryConstraint(constraint));
        }
        setDurationValidated(false);
      }
      // eslint-disable-next-line
    }, [durationValidated]);

    const handleOnDelete = useCallback(() => {
      openModal(DELETE_MC_MODAL, {
        object: operation,
      });
    }, [operation, openModal]);

    const handleOnChange = (object, changed = true) => {
      setOperationUpdate(() => ({
        ...object,
      }));
      setHasChanged(changed);
    };

    const handleHasValid = useCallback(
      (fieldName, hasValid = true) => {
        switch (fieldName) {
          case 'name':
            setForceFocuses({
              name: false,
              duration: hasValid,
            });
            break;
          case 'duration':
            setForceFocuses({
              duration: false,
              name: false,
            });
            setDurationValidated(hasValid);
            break;
          default:
            break;
        }
      },
      [setForceFocuses]
    );

    return (
      <BlockUpdateComponent
        isUpdating={isUpdating}
        isSelected={isSelected}
        noGrow
      >
        <OperationComponent
          operation={operationUpdate}
          isChecked={isChecked}
          isSelected={isSelected}
          handleSelect={handleSelect}
          handleOperations={handleOperations}
          handleOnChange={handleOnChange}
          handleOnDelete={handleOnDelete}
          handleHasValid={handleHasValid}
          forceFocus={!isUpdating ? forceFocuses : EMPTY_VALUE}
          blockDeletion={!!constraintsActions?.length}
        />
      </BlockUpdateComponent>
    );
  }
);

OperationContainer.propTypes = {
  operation: PropsTypes.object.isRequired,
  isSelected: PropsTypes.bool,
  handleSelect: PropsTypes.func.isRequired,
  handleOperations: PropsTypes.func.isRequired,
  forceFocus: PropsTypes.bool,
};

OperationContainer.defaultProps = {
  isSelected: false,
  forceFocus: false,
};

export default React.memo(OperationContainer);
