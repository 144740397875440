import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
} from 'recharts';
import { generatePath, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Grid, Box } from '@material-ui/core';

import { POINT_POSITION, MAP_LAYERS } from 'constants/map';
import { Map } from 'components/leaflet';
import PointsFeatureGroup from 'components/maps/PointsFeatureGroup';
import { getAveragePositionByPoints } from 'helpers/map';
import { ROUTES } from 'constants/routes';
import SimpleTable from 'components/common/SimpleTable';
import { Loader } from 'components/common/Loader';
import SimpleLinkRow from 'components/usage/SimpleLinkRow';
import { DATE_FORMATS } from 'constants/common';

import { useStyles } from './styles';

/** Detail customer informations
 * @param { object } customer - The customer to display
 */
const DetailCustomer = ({ customer }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const redirect = useCallback(() => {
    history.goBack();
  }, [history]);

  if (!customer) {
    return <Loader center justifyCenter />;
  }

  return (
    <Paper elevation={1} className={classes.container}>
      <Button variant="contained" onClick={redirect} className={classes.button}>
        Go back
      </Button>
      <Grid container spacing={1} className={classes.informations}>
        <Grid className={classes.lines}>
          <Box>{t('usageAnalysis.detail.name', { type: 'customer' })}</Box>
          <Box>{t('usageAnalysis.detail.subscription')}</Box>
          <Box>{t('usageAnalysis.detail.customer.lastConnected')}</Box>
          <Box>credit consumed</Box>
        </Grid>
        <Grid className={classes.lines}>
          <Box>
            <b>{customer.name}</b>
          </Box>
          <Box>
            <b>
              {customer.subscription
                ? format(customer.subscriptionEnd, DATE_FORMATS.frenchFormat)
                : 'No date'}
            </b>
          </Box>
          <Box>
            <b>{customer.lastVisitBy}</b> on{' '}
            <b>
              {customer.lastVisit
                ? format(customer.lastVisit, DATE_FORMATS.frenchFormat)
                : 'No date'}
            </b>
          </Box>
          <Box>{customer.creditCount}</Box>
        </Grid>
      </Grid>
      {customer.offerInstances && customer.offerInstances.length > 0 && (
        <Grid className={classes.tables}>
          <h3>
            {t('usageAnalysis.detail.listIntro', { type: 'offers' })}{' '}
            {customer.name}
          </h3>
          <Paper elevation={2}>
            <SimpleTable
              size="small"
              aria-label="a dense table"
              header={
                <TableRow>
                  <TableCell>name</TableCell>
                  <TableCell>description</TableCell>
                  <TableCell># projects</TableCell>
                  <TableCell># credits</TableCell>
                  <TableCell># reports</TableCell>
                  <TableCell># AWD</TableCell>
                  <TableCell>offer end</TableCell>
                </TableRow>
              }
            >
              {customer.offerInstances.map((offer) => (
                <SimpleLinkRow
                  key={`id-${offer.offer}`}
                  to={generatePath(ROUTES.usageOfferDetail, {
                    id: offer.offer,
                  })}
                >
                  <TableCell>{offer.name}</TableCell>
                  <TableCell>{offer.description}</TableCell>
                  <TableCell>{offer.projectCount}</TableCell>
                  <TableCell>{offer.creditCount}</TableCell>
                  <TableCell>{offer.reportCount}</TableCell>
                  <TableCell>{offer.downtimeCount}</TableCell>
                  <TableCell>
                    {offer.offerEnd
                      ? format(offer.offerEnd, DATE_FORMATS.frenchFormat)
                      : 'No date'}
                  </TableCell>
                </SimpleLinkRow>
              ))}
            </SimpleTable>
          </Paper>
        </Grid>
      )}
      {customer.users && customer.users.length > 0 && (
        <Grid className={classes.tables}>
          <h3>
            {t('usageAnalysis.detail.listIntro', { type: 'users' })}{' '}
            {customer.name}
          </h3>
          <Paper elevation={2}>
            <SimpleTable
              size="small"
              aria-label="a dense table"
              header={
                <TableRow>
                  <TableCell align="left">name</TableCell>
                  <TableCell align="left">email</TableCell>
                  <TableCell align="left">last login</TableCell>
                </TableRow>
              }
            >
              {customer.users.map((user) => (
                <SimpleLinkRow
                  key={`id-${user.id}`}
                  to={generatePath(ROUTES.usageUserDetail, {
                    id: user.id,
                  })}
                >
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {user.lastLogin
                      ? format(user.lastLogin, DATE_FORMATS.frenchFormat)
                      : 'No date'}
                  </TableCell>
                </SimpleLinkRow>
              ))}
            </SimpleTable>
          </Paper>
        </Grid>
      )}
      {customer.points && customer.points.length > 0 && (
        <Grid className={classes.tables}>
          <h3>
            {t('usageAnalysis.detail.mapIntro', { type: 'points' })}{' '}
            {customer.name}
          </h3>
          <Map
            position={getAveragePositionByPoints(customer.points)}
            positionType={POINT_POSITION}
            defaultLayer={MAP_LAYERS.worldImagery}
            wrapperClass={classes.map}
          >
            <PointsFeatureGroup points={customer.points} />
          </Map>
        </Grid>
      )}
      <hr></hr>
      <Grid className={classes.tables}>
        <h3>
          {t('usageAnalysis.detail.exportDetails')}
          {` ${customer.name}`}
        </h3>
      </Grid>
      <Grid container alignItems="center" direction="column">
        <Grid item>
          <BarChart
            width={750}
            height={350}
            data={customer.exportDetails?.data}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="statType" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="EXCEL" fill="#8884d8" />
            <Bar dataKey="MATLAB" fill="#82ca9d" />
            <Bar dataKey="NETCDF" fill="#9d242c" />
          </BarChart>
        </Grid>
        {customer.exportDetails === null ? (
          <h5 className={classes.nodata}>
            {t('usageAnalysis.detail.noDataAvailable')}
          </h5>
        ) : (
          <h6 className={classes.date}>
            {t('usageAnalysis.detail.dataGeneration', {
              date: customer.exportDetails?.generatedAt || '...',
            })}
          </h6>
        )}
      </Grid>
    </Paper>
  );
};

DetailCustomer.propTypes = {
  customer: PropTypes.object,
};

export default memo(DetailCustomer);
