import Button from '@material-ui/core/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import BaseModal from 'components/common/BaseModal';

import { useStyles } from './styles';

/**
 * ConfirmationModal component
 * Base for all confirm modals.
 * @param {String} modalClass - string which contains given modal className
 * @param {String} title - modal title
 * @param {React.ReactNode} children - react components, to be displayed in modal
 * @param {Function} onClose - handler for close button
 * @param {Function} onCancel handler ofr cancel button
 * @param {Function} onConfirm - handler for confirm button
 */
const ConfirmationModal = ({
  title,
  children,
  onClose,
  onCancel,
  onConfirm,
  modalClass,
  warning,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const controls = (
    <div className={classes.buttons}>
      <Button className={classes.cancelButton} onClick={onCancel}>
        {t('buttons.cancel')}
      </Button>
      <Button onClick={onConfirm} className={classes.saveButton}>
        {t('buttons.confirm')}
      </Button>
    </div>
  );

  return (
    <BaseModal
      title={title}
      onClose={onClose}
      modalClass={modalClass}
      controls={controls}
      warning={warning}
    >
      {children}
    </BaseModal>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  modalClass: PropTypes.string,
  warning: PropTypes.bool,
};

export default React.memo(ConfirmationModal);
