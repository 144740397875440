import axios from 'axios';
import qs from 'qs';

import i18n from 'i18n';
import {
  API_URL,
  CSRF_TOKEN_KEY,
  CSRF_HEADER_NAME,
  REACT_APP_VERSION_INFO,
} from 'common/config';
import { SNACKBAR_WARNING_TYPE } from 'constants/common';
import { NEED_UPDATE_MODAL_ID, NOTIFICATION_MODAL } from 'constants/modals';
import { REQUEST_INIT } from 'ducks/app/types';
import { MAINTENANCE_MODE } from 'constants/api';
import { userLogout } from 'ducks/user/actions';
import { userTypes } from 'ducks/user';
import { closeModal, closeAllModal, showModal } from 'ducks/modals/actions';
import { selectKeycloakAccessToken } from 'ducks/keycloak/selectors';

import { camelizeKeys, decamelizeKeys } from './camelizer';

// the application will init if the store is correctly imported
let store = null;
// avoid multiple banner message => xhr request
let modalisDisplaying = false;

import('../store/index').then((module) => {
  store = module.store;
  store.dispatch({
    type: REQUEST_INIT,
  });
});

const request = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  xsrfCookieName: CSRF_TOKEN_KEY,
  xsrfHeaderName: CSRF_HEADER_NAME,
  crossDomain: true,
  headers: {
    'Content-Type': 'application/json',
    'MA-Version': REACT_APP_VERSION_INFO,
  },
  paramsSerializer: (params) =>
    qs.stringify(decamelizeKeys(params), {
      arrayFormat: 'brackets',
      encodeValuesOnly: true,
    }),
});

function requestMapper(config) {
  const newConfig = config;

  if (newConfig.data) {
    newConfig.data = decamelizeKeys(newConfig.data);
  }
  return newConfig;
}

function responseMapper(response) {
  return Promise.resolve(
    camelizeKeys(response.data, {
      // If key in uppercase or we have 'space' in field return without convert return without convert.
      // For example: 'FIELD_NAME' or 'field name'
      process: (key, converter) => {
        const isUpperCase = key.toUpperCase() === key;
        const hasSpace = key.includes(' ');

        if (isUpperCase || hasSpace) {
          return key;
        }
        return converter(key);
      },
    })
  );
}

function closeNotificationModal() {
  store.dispatch(closeModal({ id: NEED_UPDATE_MODAL_ID }));
  modalisDisplaying = !modalisDisplaying;
}

const errorHandler = async ({ response, config }) => {
  const status = response.status;
  const JWT_NEED_REFRESH = 'JWT token has expired';

  // if the response status is `260`, we encountered a JWT expiration
  // refresh the token and retry
  console.error(
    `failed with error code ${response.status} and ${response.data}`
  );

  if (
    response.status === 401 &&
    response.data.detail === JWT_NEED_REFRESH &&
    !config.secondAttempt
  ) {
    config.secondAttempt = true; // avoid infinite recursion
    try {
      return request(config);
    } catch (_error) {
      return Promise.reject(_error);
    }
  }

  if ([403, 401].includes(status) && !config.noRedirect) {
    invalidateToken(status);
  }

  if (response.status === 401 && !config.noRedirect) {
    invalidateToken();
  }

  if (response.status === 426) {
    // reload the page if the backen is in maintenance mode,
    // this will redirect the user to a "maintenance page".
    if (response.data === MAINTENANCE_MODE) {
      window.location.reload(true);
    }
    /* eslint-disable */
    const message = i18n.t('errors.status426.update', {
      href: "javascript:window.location.reload(true)",
    });
    /* eslint-enable */
    const options = {
      type: SNACKBAR_WARNING_TYPE,
      message,
      closeModal: closeNotificationModal,
      autoHideDuration: null,
    };
    if (!modalisDisplaying) {
      store.dispatch(closeAllModal());
      store.dispatch(
        showModal({
          modalType: NOTIFICATION_MODAL,
          options,
          id: NEED_UPDATE_MODAL_ID,
        })
      );
      modalisDisplaying = !modalisDisplaying;
    }
  }
  return Promise.reject({
    message: camelizeKeys(response).data,
    status,
  });
};

function appendBearerToken(config) {
  if (config.withoutAuthorization) {
    return config;
  }
  const accessToken = store
    ? selectKeycloakAccessToken(store.getState())
    : null;
  if (accessToken) {
    config.headers.authorization = `Bearer ${accessToken}`;
  }

  return config;
}

function invalidateToken(status = 401) {
  store.dispatch({
    type: userTypes.USER_REQUEST_ERROR,
    error: {
      auth:
        status === 401
          ? i18n.t('login.expiredCredential')
          : i18n.t('login.permissionError'),
    },
  });
  store.dispatch(userLogout());
}

request.interceptors.request.use(requestMapper);
request.interceptors.request.use(appendBearerToken);
request.interceptors.response.use(responseMapper, errorHandler);

export default request;
