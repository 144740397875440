import { DOWNLOAD_REPORT_ERROR } from 'ducks/reports/types';

import {
  REQUEST_POINTS,
  REQUEST_POINTS_SUCCESS,
  REQUEST_POINTS_ERROR,
  DELETE_POINT,
  DELETE_POINT_ERROR,
  DELETE_POINT_SUCCESS,
  DELETE_PROJECT_POINTS,
  DELETE_PROJECT_POINTS_ERROR,
  DELETE_PROJECT_POINTS_SUCCESS,
  CREATE_POINTS_FROM_FILE,
  CREATE_POINTS_FROM_FILE_SUCCESS,
  CREATE_POINTS_FROM_FILE_ERROR,
  CREATE_POINT,
  CREATE_POINT_SUCCESS,
  CREATE_POINT_ERROR,
  UPDATE_POINT_SUCCESS,
  UPDATE_POINT,
  UPDATE_POINT_ERROR,
  REQUEST_POINT,
  REQUEST_POINT_SUCCESS,
  REQUEST_POINT_ERROR,
  RUN_POINT_TASK,
  START_UPDATE_POINT_TASK_JOB,
  FINISH_UPDATE_POINT_TASK_JOB,
  STOP_UPDATE_POINT_TASK_JOB,
  CANCEL_POINT_TASK_JOB,
  CANCEL_POINT_TASK_JOB_SUCCESS,
  CANCEL_POINT_TASK_JOB_ERROR,
  DOWNLOAD_TASK,
  DOWNLOAD_TASK_SUCCESS,
} from './types';

export const requestPoints = (params = {}) => ({
  type: REQUEST_POINTS,
  params,
});

export const requestPointsSuccess = (data) => ({
  type: REQUEST_POINTS_SUCCESS,
  data,
});

export const requestPointsError = (error) => ({
  type: REQUEST_POINTS_ERROR,
  error,
});

export const requestPoint = (id, params = {}) => ({
  type: REQUEST_POINT,
  params,
  id,
});

export const requestPointSuccess = (point) => ({
  type: REQUEST_POINT_SUCCESS,
  point,
});

export const requestPointError = (error) => ({
  type: REQUEST_POINT_ERROR,
  error,
});

export const deletePoint = ({ pointId, projectId }) => ({
  type: DELETE_POINT,
  pointId,
  projectId,
});

export const deletePointSuccess = ({ pointId, projectId }) => ({
  type: DELETE_POINT_SUCCESS,
  pointId,
  projectId,
});

export const deletePointError = (error) => ({
  type: DELETE_POINT_ERROR,
  error,
});

export const deleteProjectPoints = ({ projectId }) => ({
  type: DELETE_PROJECT_POINTS,
  projectId,
});

export const deleteProjectPointsSuccess = ({ projectId }) => ({
  type: DELETE_PROJECT_POINTS_SUCCESS,
  projectId,
});

export const deleteProjectPointsError = (error) => ({
  type: DELETE_PROJECT_POINTS_ERROR,
  error,
});

export const createPointsFromFile = ({ projectId, file }) => ({
  type: CREATE_POINTS_FROM_FILE,
  projectId,
  file,
});

export const createPointsFromFileSuccess = ({ points, projectId }) => ({
  type: CREATE_POINTS_FROM_FILE_SUCCESS,
  points,
  projectId,
});

export const createPointsFromFileError = (error) => ({
  type: CREATE_POINTS_FROM_FILE_ERROR,
  error,
});

export const createPoint = (data) => ({
  type: CREATE_POINT,
  data,
});

export const createPointSuccess = (point) => ({
  type: CREATE_POINT_SUCCESS,
  point,
});

export const createPointError = (error) => ({
  type: CREATE_POINT_ERROR,
  error,
});

export const updatePoint = (point) => ({
  type: UPDATE_POINT,
  point,
});

export const updatePointSuccess = (point) => ({
  type: UPDATE_POINT_SUCCESS,
  point,
});

export const updatePointError = (error) => ({
  type: UPDATE_POINT_ERROR,
  error,
});

export const runPointTask = (pointId, processType) => ({
  type: RUN_POINT_TASK,
  pointId,
  processType,
});

export const startUpdatePointTaskJob = (pointId) => ({
  type: START_UPDATE_POINT_TASK_JOB,
  pointId,
});

export const stopUpdatePointTaskJob = () => ({
  type: STOP_UPDATE_POINT_TASK_JOB,
});

export const finishUpdatePointTaskJob = (pointId) => ({
  type: FINISH_UPDATE_POINT_TASK_JOB,
  pointId,
});

export const cancelPointTaskJob = (taskId) => ({
  type: CANCEL_POINT_TASK_JOB,
  taskId,
});

export const cancelPointTaskJobSuccess = (point) => ({
  type: CANCEL_POINT_TASK_JOB_SUCCESS,
  point,
});

export const cancelPointTaskJobError = (error) => ({
  type: CANCEL_POINT_TASK_JOB_ERROR,
  error,
});

export const downloadTask = (pointId, taskId) => ({
  type: DOWNLOAD_TASK,
  pointId,
  taskId,
});

export const downloadTaskSuccess = (taskId) => ({
  type: DOWNLOAD_TASK_SUCCESS,
  taskId,
});

export const downloadTaskError = (error, taskId) => ({
  type: DOWNLOAD_REPORT_ERROR,
  error,
  taskId,
});
