import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Leaflet from 'leaflet-providers';

import { TILE_LAYER_COORDINATE_POSTFIX } from 'constants/map';

import CustomGroupedLayersControl from '../CustomGroupedLayersControl';

import './style.scss';

/**
 * Control with extra layers.
 * @param {Array} tileGeometries
 * @param {Number} zIndex
 * @param {Boolean} autoZIndex
 * @param {Boolean} noWrap
 * @param {React.ReactNode} children
 * @return {jsx}
 */
const MapExtraTilesControl = ({
  tileGeometries,
  children,
  zIndex,
  autoZIndex,
  noWrap,
}) => {
  const geometries = useMemo(
    () =>
      tileGeometries.map(({ layers, ...geometry }) => ({
        layers: layers.map(({ tile, property, name }) => {
          const options = {
            zIndex,
            autoZIndex,
            noWrap,
            ...property,
          };

          return {
            layer: new Leaflet.TileLayer(
              `${tile}${TILE_LAYER_COORDINATE_POSTFIX}`,
              options
            ),
            name,
          };
        }),
        ...geometry,
      })),
    [tileGeometries, zIndex, autoZIndex, noWrap]
  );

  const addGeometriesHandler = useCallback(
    (group) => {
      group.removeGeometries();
      geometries.forEach((geometry) => {
        geometry.layers.forEach((layer) => {
          group.addGeometry(layer, geometry.name);
        });
      });
    },
    [geometries]
  );

  return (
    <CustomGroupedLayersControl
      iconClassName="extra-layers-btn"
      onAddGeometries={addGeometriesHandler}
    >
      {children}
    </CustomGroupedLayersControl>
  );
};

MapExtraTilesControl.propTypes = {
  tileGeometries: PropTypes.array.isRequired,
  children: PropTypes.node,
  zIndex: PropTypes.number,
  autoZIndex: PropTypes.bool,
  noWrap: PropTypes.bool,
};

MapExtraTilesControl.defaultProps = {
  autoZIndex: false,
  noWrap: true,
};

export default MapExtraTilesControl;
