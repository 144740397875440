import React, { useCallback, memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import UsageCustomersComponent from 'components/usage/UsageCustomers';
import { selectCustomers, isReady } from 'ducks/usage/selectors';
import { requestCustomers } from 'ducks/usage/actions';
import { useUrlQuery } from 'hooks/useUrlQuery';
import { hasSubString } from 'helpers/filters';
import { useExclusifFilters } from 'hooks/useExclusifFilters';
import { useArraySorting } from 'hooks/useArraySorting';

const filteringConditions = {
  name: ({ name }, expected) => hasSubString(name, expected),
};

const sortingConditions = {
  name: (a, b) => a.name.localeCompare(b.name),
  projects: (a, b) => a.projectCount - b.projectCount,
  reports: (a, b) => a.reportCount - b.reportCount,
  downtimes: (a, b) => a.downtimeCount - b.downtimeCount,
  activeUser: (a, b) => a.activeUser - b.activeUser,
  activeOffer: (a, b) => a.activeOffer - b.activeOffer,
  subscription: (a, b) => a.subscriptionEnd - b.subscriptionEnd,
  login: (a, b) => a.lastVisit - b.lastVisit,
};

/** The container of the list of customers to diplay
 */
const UsageCustomers = () => {
  const dispatch = useDispatch();
  const customers = useSelector(selectCustomers);
  const ready = useSelector(isReady);
  const [reverse, setReverse] = useState(false);

  const {
    urlQuery: { search, ordering = 'name', sort = 'asc' },
    mergeUrlQuery,
  } = useUrlQuery();

  let filteredCustomers = useExclusifFilters({
    entities: customers,
    filters: filteringConditions,
    activeFilter: ordering,
    expectedValue: search,
  });

  filteredCustomers = useArraySorting({
    entities: filteredCustomers,
    conditions: sortingConditions,
    activeCondition: ordering,
    reverse,
  });

  const headers = {
    name: {
      header: 'name',
      name: 'name',
      values: [
        { order: 'name', sort: 'asc' },
        { order: 'name', sort: 'desc' },
      ],
    },
    projects: {
      header: 'projects',
      name: '# projects',
      values: [
        { order: 'projects', sort: 'asc' },
        { order: 'projects', sort: 'desc' },
      ],
    },
    reports: {
      header: 'reports',
      name: '# reports',
      values: [
        { order: 'reports', sort: 'asc' },
        { order: 'reports', sort: 'desc' },
      ],
    },
    downtimes: {
      header: 'downtimes',
      name: '# dowtimes',
      values: [
        { order: 'downtimes', sort: 'asc' },
        { order: 'dowtnimes', sort: 'desc' },
      ],
    },
    activeUser: {
      header: 'activeUser',
      name: '# user',
      values: [
        { order: 'activeUser', sort: 'asc' },
        { order: 'activeUser', sort: 'desc' },
      ],
    },
    activeOffer: {
      header: 'activeOffer',
      name: '# offer',
      values: [
        { order: 'activeOffer', sort: 'asc' },
        { order: 'activeOffer', sort: 'desc' },
      ],
    },
    subscription: {
      header: 'subscription',
      name: 'expiration date',
      values: [
        { order: 'subscription', sort: 'asc' },
        { order: 'subscription', sort: 'desc' },
      ],
    },
    login: {
      header: 'login',
      name: 'last access',
      values: [
        { order: 'login', sort: 'asc' },
        { order: 'login', sort: 'desc' },
      ],
    },
  };

  useEffect(() => {
    if (sort === 'asc') {
      setReverse(false);
    }
    if (sort === 'desc') {
      setReverse(true);
    }
  }, [sort, reverse]);

  useEffect(() => {
    dispatch(requestCustomers());
  }, [dispatch]);

  const setSearch = useCallback(
    (value) => {
      mergeUrlQuery({ search: value });
    },
    [mergeUrlQuery]
  );

  const setParameters = useCallback(
    (event, param) => {
      mergeUrlQuery({ ordering: param.order, sort: param.sort });
    },
    [mergeUrlQuery]
  );

  return (
    <UsageCustomersComponent
      headers={headers}
      search={search}
      sort={sort}
      customers={filteredCustomers}
      ordering={ordering}
      onSearchChange={setSearch}
      onOrderingChange={setParameters}
      isReady={ready}
    />
  );
};
export default memo(UsageCustomers);
