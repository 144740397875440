const prefix = '@@geometries';

export const REQUEST_GEOMETRIES = `${prefix}/REQUEST_GEOMETRIES`;
export const REQUEST_GEOMETRIES_SUCCESS = `${prefix}/REQUEST_GEOMETRIES_SUCCESS`;
export const REQUEST_GEOMETRIES_ERROR = `${prefix}/REQUEST_GEOMETRIES_ERROR`;

export const DELETE_GEOMETRY = `${prefix}/DELETE_GEOMETRY`;
export const DELETE_GEOMETRY_SUCCESS = `${prefix}/DELETE_GEOMETRY_SUCCESS`;
export const DELETE_GEOMETRY_ERROR = `${prefix}/DELETE_GEOMETRY_ERROR`;

export const UPLOAD_GEOMETRY = `${prefix}/UPLOAD_GEOMETRY`;
export const UPLOAD_GEOMETRY_SUCCESS = `${prefix}/UPLOAD_GEOMETRY_SUCCESS`;
export const UPLOAD_GEOMETRY_ERROR = `${prefix}/UPLOAD_GEOMETRY_ERROR`;

export const UPDATE_GEOMETRY = `${prefix}/UPDATE_GEOMETRY`;
export const UPDATE_GEOMETRY_SUCCESS = `${prefix}/UPDATE_GEOMETRY_SUCCESS`;
export const UPDATE_GEOMETRY_ERROR = `${prefix}/UPDATE_GEOMETRY_ERROR`;

export const UPDATE_LAYER = `${prefix}/UPDATE_LAYER`;
export const UPDATE_LAYER_SUCCESS = `${prefix}/UPDATE_LAYER_SUCCESS`;
export const UPDATE_LAYER_ERROR = `${prefix}/UPDATE_LAYER_ERROR`;
