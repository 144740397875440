import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import EditPointForm from 'components/forms/EditPointForm';
import { createPoint } from 'ducks/points/actions';
import { getPointGeometry } from 'helpers/map';
import { selectPointsNamesByProject } from 'ducks/points/selectors';
import { selectProjectZoneByProjectId } from 'ducks/projects/selectors';

/**
 * Container for point create form
 * @param {Function} onClose
 * @param {Object} project
 * @param {Object} props
 * @param {Boolean} canCreatePoint
 * @returns {jsx}
 */
const CreatePointContainer = ({
  onClose,
  project,
  canCreatePoint,
  ...props
}) => {
  const dispatch = useDispatch();
  const { existingNames, projectZone } = useSelector((state) => ({
    existingNames: selectPointsNamesByProject(state, project.id),
    projectZone: selectProjectZoneByProjectId(state, project.id),
  }));

  const handleSuccess = useCallback(
    ({ lat, lng, name }) => {
      dispatch(
        createPoint({
          geom: getPointGeometry({ lat, lng }),
          project: project.id,
          name,
        })
      );
      onClose();
    },
    [dispatch, project.id, onClose]
  );

  return (
    <EditPointForm
      {...props}
      allowRename={canCreatePoint}
      disabled={!canCreatePoint}
      onCancel={onClose}
      onSuccess={handleSuccess}
      existingNames={existingNames}
      zone={projectZone}
    />
  );
};

CreatePointContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  canCreatePoint: PropTypes.bool.isRequired,
};

export default memo(CreatePointContainer);
