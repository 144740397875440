import {
  STATUS_LOADING,
  STATUS_NOT_REQUESTED,
  STATUS_SUCCESS,
  EMPTY_VALUE,
  EMPTY_ARRAY,
} from 'constants/common';
import createReducer from 'helpers/createReducer';
import { normalizeArray } from 'helpers/data';
import { prepareAndSetError } from 'helpers/apiErrors';

import {
  REQUEST_BUOYS,
  REQUEST_BUOYS_SUCCESS,
  REQUEST_BUOYS_ERROR,
  REQUEST_BUOY_STATS,
  REQUEST_BUOY_STATS_SUCCESS,
  REQUEST_BUOY_STATS_ERROR,
} from './types';

const initialState = {
  data: EMPTY_VALUE,
  ids: EMPTY_ARRAY,
  error: EMPTY_VALUE,
  status: STATUS_NOT_REQUESTED,
};

const setLoadingStatus = (state) => ({ ...state, status: STATUS_LOADING });

const setBuoys = (state, { data }) => ({
  ...state,
  ...normalizeArray(data),
  status: STATUS_SUCCESS,
});

const setBuoy = (state, { buoy }) => {
  const { [buoy.id]: updated, ...data } = state.data;
  data[buoy.id] = buoy;

  return {
    ...state,
    data,
    ids: !state.ids.includes(buoy.id) ? [buoy.id, ...state.ids] : state.ids,
    status: STATUS_SUCCESS,
    error: EMPTY_VALUE,
  };
};

export default createReducer(initialState, {
  [REQUEST_BUOYS]: setLoadingStatus,
  [REQUEST_BUOY_STATS]: setLoadingStatus,
  [REQUEST_BUOYS_SUCCESS]: setBuoys,
  [REQUEST_BUOY_STATS_SUCCESS]: setBuoy,
  [REQUEST_BUOYS_ERROR]: prepareAndSetError,
  [REQUEST_BUOY_STATS_ERROR]: prepareAndSetError,
});
