import React, { useState, useMemo, useCallback, useRef } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { range as lodashRange } from 'lodash';
import { BarChart, CartesianGrid, Customized, XAxis, YAxis } from 'recharts';
import Typography from '@material-ui/core/Typography';

import SelectMonthButtonGroup from 'components/common/buttons/SelectMonthButtonGroup';
import {
  ANNUAL_INDEX,
  CHART_SVG_ID,
  CHART_TITLE_ID,
  DEFAULT_LEVELS_BAR_WIDTH,
  GRAPHS_WIDGETS,
  OMNIDIRECTIONAL_TITLE,
} from 'constants/graphs';
import {
  getAverageProbabilityByRange,
  getEpdDataByProbability,
} from 'helpers/graphs/empiricalProbabilityDistribution';
import ProbabilityDistributionSelect from 'components/common/graphs/ProbabilityDistributionSelect';
import BarChartWithRangeSelection from 'components/common/graphs/BarChartWithRangeSelection';
import { EOL } from 'constants/common';
import { getTickFormatterByValuesRange } from 'helpers/graphs/common';
import DownloadGraphDataFileButtonGroup from 'containers/buttons/DownloadGraphDataFileButtonGroup';
import CustomLevelsBar from 'components/common/graphs/CustomLevelsBar';
import { useUniqueId } from 'hooks/useUniqueId';
import { Loader } from 'components/common/Loader';
import { HorizontalLoader } from 'components/common/HorizontalLoader';

import { useStyles } from './styles';

const LEVELS_BAR_OFFSET = 10;
const LEVELS_FULL_LENGTH = DEFAULT_LEVELS_BAR_WIDTH + LEVELS_BAR_OFFSET;

const GRAPH_MARGIN = {
  left: 10,
  right: 10,
  top: 25,
  bottom: 10,
};

/**
 * EmpiricalProbabilityDistribution component.
 * @param { array } widgets - expected widgets list
 * @param { object } annualData - graph annual Data in array
 * @param { object } monthlyData - graph monthly Data in array
 * @param { object } monthlyDataWidget - widget monthly Data in array
 * @param { object } annualDataWidget - widget annual Data in array
 * @param { number } graphWidth width of graph
 * @param { number } yTickStep number between two ticks in y axis
 * @param { number } projectId - current project id
 * @param { Array } statsIds - array of current graphs stats ids
 * @param { string } pngFileName - file name for saving as png
 * @returns { JSX }
 */
const EmpiricalProbabilityDistribution = ({
  widgets,
  graphWidth,
  yTickStep,
  annualData,
  monthlyData,
  annualDataWidget,
  monthlyDataWidget,
  projectId,
  statsIds,
  pngFileName,
}) => {
  const salt = useUniqueId();
  const [currentLevel, setCurrentLevel] = useState(0);
  const [monthNumber, setMonthNumber] = useState(ANNUAL_INDEX);
  const [pdSelectRange, setPdSelectRange] = useState(null);
  const [sectorTitle, setSectorTitle] = useState(OMNIDIRECTIONAL_TITLE);
  const classes = useStyles();
  const graphContainer = useRef(null);
  const graphHeight = graphWidth * 0.8;

  const resetPdSelect = useCallback(() => {
    setPdSelectRange(null);
    setSectorTitle(OMNIDIRECTIONAL_TITLE);
  }, [setPdSelectRange, setSectorTitle]);

  const selectRange = useCallback(
    (range, sectorString) => {
      setPdSelectRange(range);
      setSectorTitle(`sector: ${sectorString}`);
    },
    [setPdSelectRange, setSectorTitle]
  );

  const isAnnual = monthNumber === ANNUAL_INDEX;
  const withMonths = widgets.includes(GRAPHS_WIDGETS.months);
  const withPDSelect = widgets.includes(GRAPHS_WIDGETS.vclass);
  const pdSelectData = isAnnual ? annualDataWidget : monthlyDataWidget;
  const {
    data: preparedPdSelectData,
    probabilities: probabilitiesForSelect,
    leafsOccurrences,
  } = pdSelectData || {};
  const hasLevels = Array.isArray(annualData) && !!annualData[0].levels;
  const monthIndex = monthNumber - 1;

  const averageProbabilities = useMemo(() => {
    if (!pdSelectRange) {
      return probabilitiesForSelect;
    }
    return getAverageProbabilityByRange({
      leafsOccurrences,
      monthNumber,
      probabilities: probabilitiesForSelect,
      range: pdSelectRange,
      hasLevels,
    });
  }, [
    monthNumber,
    pdSelectRange,
    probabilitiesForSelect,
    hasLevels,
    leafsOccurrences,
  ]);

  const {
    data,
    title,
    yTicks,
    xUnits,
    maxYValue,
    yLabel,
    xLabel,
    levels,
    tooltipTitle,
    withLevelsSelect,
    xDomain,
  } = useMemo(() => {
    const selectedByMonth = isAnnual
      ? annualData
      : monthlyData[monthNumber - 1];
    const dataByLevel = hasLevels
      ? selectedByMonth[currentLevel]
      : selectedByMonth;

    // labels can be missed in monthly data
    const annualPrepared = hasLevels ? annualData[currentLevel] : annualData;

    if (!pdSelectRange) {
      return {
        ...dataByLevel,
        xLabel: annualPrepared.xLabel,
        yLabel: annualPrepared.yLabel,
        withLevelsSelect: hasLevels,
        yTicks: lodashRange(
          Math.floor(dataByLevel.minYValue),
          dataByLevel.maxYValue,
          yTickStep
        ),
      };
    }

    const dataByProbability = getEpdDataByProbability({
      probabilities: averageProbabilities,
      monthNumber,
      xValues: dataByLevel.xValues,
      hasLevels,
    });
    const {
      data: replacedData,
      maxYValue: replacedYValue,
      ...preparedData
    } = dataByLevel;
    const dataByProbabilityByLevel = hasLevels
      ? dataByProbability[currentLevel]
      : dataByProbability;
    const {
      minYValue: yMinValue,
      maxYValue: yMaxValue,
    } = dataByProbabilityByLevel;

    return {
      ...dataByProbabilityByLevel,
      ...preparedData,
      xLabel: annualPrepared.xLabel,
      yLabel: annualPrepared.yLabel,
      withLevelsSelect: hasLevels,
      yTicks: lodashRange(Math.floor(yMinValue), yMaxValue, yTickStep),
    };
  }, [
    annualData,
    averageProbabilities,
    yTickStep,
    currentLevel,
    monthNumber,
    monthlyData,
    pdSelectRange,
    isAnnual,
    hasLevels,
  ]);
  const preparedTitle = `${title} ${EOL} ${withPDSelect ? sectorTitle : ''}`;
  const yTicksFormatter = getTickFormatterByValuesRange(
    yTicks[yTicks.length - 1] - yTicks[0]
  );
  const xTicksFormatter = getTickFormatterByValuesRange(
    xDomain[1] - xDomain[0]
  );

  const chartMargin = withLevelsSelect
    ? { ...GRAPH_MARGIN, left: LEVELS_FULL_LENGTH }
    : GRAPH_MARGIN;

  return (
    <Grid justifyContent="center" container>
      <Grid item>
        <Typography
          id={CHART_TITLE_ID + salt}
          className={classes.title}
          variant="subtitle1"
          align="center"
        >
          {preparedTitle}
        </Typography>
        <Grid item>
          <Grid
            item
            container
            alignItems="center"
            justifyContent={withPDSelect ? 'flex-end' : 'center'}
          >
            {withPDSelect && (
              <div className={classes.pdSelect}>
                {preparedPdSelectData ? (
                  <ProbabilityDistributionSelect
                    radius={45}
                    onSelect={selectRange}
                    onDeselect={resetPdSelect}
                    preparedData={
                      isAnnual
                        ? preparedPdSelectData
                        : preparedPdSelectData[monthIndex]
                    }
                  />
                ) : (
                  <Loader justifyCenter opacity={0.7} />
                )}
              </div>
            )}
          </Grid>
        </Grid>
        <Grid justifyContent="center" container>
          <Grid item className={classes.graphWrapper}>
            <BarChart
              id={CHART_SVG_ID + salt}
              ref={graphContainer}
              margin={chartMargin}
              data={data}
              width={graphWidth}
              height={graphHeight}
              barCategoryGap={0}
              className={classes.graphContainer}
            >
              <XAxis
                tick={{ fontSize: 9 }}
                tickFormatter={xTicksFormatter}
                label={{ value: xLabel, position: 'bottom', offset: -10 }}
                domain={xDomain}
                dataKey="xValue"
              />
              <YAxis
                ticks={yTicks}
                tickFormatter={yTicksFormatter}
                domain={[0, maxYValue]}
                dataKey="yValue"
                tick={{
                  fontSize: 9,
                }}
                label={{
                  value: yLabel,
                  angle: -90,
                  position: 'center',
                  dx: -5,
                }}
              />
              <CartesianGrid strokeDasharray="3 3" />
              <Customized
                key={1}
                xUnits={xUnits}
                data={data}
                tooltipTitle={tooltipTitle}
                graphContainer={graphContainer}
                component={BarChartWithRangeSelection}
                classes={classes}
              />
              {withLevelsSelect && (
                <Customized
                  key={2}
                  levels={levels.values}
                  type={levels.type}
                  barWidth={DEFAULT_LEVELS_BAR_WIDTH}
                  selectedLevel={currentLevel}
                  onSelect={setCurrentLevel}
                  xOffset={LEVELS_BAR_OFFSET}
                  component={CustomLevelsBar}
                />
              )}
            </BarChart>
          </Grid>
        </Grid>
        {withMonths && (
          <Grid container justifyContent="center">
            {monthlyData ? (
              <SelectMonthButtonGroup
                withAnnual
                className={classes.monthSelector}
                value={monthNumber}
                setMonth={setMonthNumber}
              />
            ) : (
              <HorizontalLoader justifyCenter opacity={0.7} />
            )}
          </Grid>
        )}
        <DownloadGraphDataFileButtonGroup
          salt={salt}
          projectId={projectId}
          statsIds={statsIds}
          currentLevel={hasLevels ? currentLevel : null}
          monthNumber={monthNumber}
          pdSelectRange={pdSelectRange}
          pngFileName={pngFileName}
        />
      </Grid>
    </Grid>
  );
};

const graphDataShape = {
  levels: PropTypes.shape({
    type: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  xUnits: PropTypes.string.isRequired,
  xLabel: PropTypes.string.isRequired,
  yLabel: PropTypes.string.isRequired,
  minYValue: PropTypes.number.isRequired,
  maxYValue: PropTypes.number.isRequired,
  tooltipTitle: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      xValue: PropTypes.number.isRequired,
      yValue: PropTypes.number.isRequired,
    })
  ).isRequired,
  projectId: PropTypes.number,
  statsIds: PropTypes.array,
  pngFileName: PropTypes.string,
};

EmpiricalProbabilityDistribution.propTypes = {
  annualData: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape(graphDataShape)),
    PropTypes.shape(graphDataShape),
  ]),
  graphWidth: PropTypes.number,
  yTickStep: PropTypes.number,
  widgets: PropTypes.arrayOf(PropTypes.string),
};

EmpiricalProbabilityDistribution.defaultProps = {
  yTickStep: 1,
  graphWidth: 500,
  widgets: [],
};

export default EmpiricalProbabilityDistribution;
