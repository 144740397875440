import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Typography, Tab } from '@material-ui/core';
import classNames from 'classnames';

import { getFieldsWithStatisticsData } from 'helpers/satelliteComparison';
import { getPreparedScatterPlotData } from 'helpers/graphs/scatterPlot';
import ScatterPlot from 'components/common/graphs/ScatterPlot';

import ComparisonAgainstSatelliteRows from '../ComparisonAgainstSatelliteRows';

import { useStyles } from './styles';
import definitionTabs from './definitionTabs.json';

const DefinitionContent = ({ id }) => {
  const classes = useStyles();
  const tabContent = definitionTabs.find((tab) => tab.id === id);
  return (
    <>
      {tabContent.sections.map((item) => (
        <div
          key={item.id}
          className={classNames(classes.section, {
            [classes[item.customClass]]: item.customClass,
          })}
          dangerouslySetInnerHTML={{ __html: item.content }}
        ></div>
      ))}
    </>
  );
};

/**
 * Component for rendering tabs, charts and tabla about satellite information.
 * @param {Object} statistics
 * @return {jsx}
 */
const ComparisonAgainstSatelliteResult = ({ statistics }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { statisticsByTab, tabs } = useMemo(() => {
    const statsByTab = getFieldsWithStatisticsData(statistics);
    return {
      statisticsByTab: statsByTab,
      tabs: Object.keys(statsByTab).sort(),
    };
  }, [statistics]);
  const [statisticTab, setStatisticTab] = useState(tabs[0]);
  const [definitionTab, setDefinitionTab] = useState(null);

  const statisticData = statisticsByTab[statisticTab];
  const scatterData =
    statisticData && getPreparedScatterPlotData(statisticData);

  const handleChangeDefinitionTab = (_e) => {
    const id = _e.target.offsetParent.id;
    setDefinitionTab(id);
    setStatisticTab(null); // reset
  };

  const handleChangeComparisonTab = (_e) => {
    const id = _e.target.offsetParent.id;
    setStatisticTab(id);
    setDefinitionTab(null); // reset
  };

  return (
    <div>
      {/* comparison tabs */}
      <div className={classes.tabsContainer}>
        {tabs && tabs.length > 0 && (
          <div className={classes.statisticsTabs}>
            {tabs.map((label) => (
              <Tab
                label={label}
                key={label}
                id={label}
                selected={statisticTab === label}
                onClick={handleChangeComparisonTab}
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              ></Tab>
            ))}
          </div>
        )}
        <div className={classes.definitionTabs}>
          {definitionTabs.map((item) => (
            <Tab
              label={item.label}
              key={item.id}
              id={item.id}
              selected={definitionTab === item.id}
              onClick={handleChangeDefinitionTab}
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            ></Tab>
          ))}
        </div>
      </div>
      {/* content */}
      <div className={classes.dataContainer}>
        {statisticTab && scatterData && (
          <div id="componentToDownload">
            <ScatterPlot commonData={scatterData} />
            <ComparisonAgainstSatelliteRows rows={statisticData.rows} />
          </div>
        )}
        {!statisticTab && definitionTab && (
          <DefinitionContent id={definitionTab} />
        )}
        {!statisticTab && !definitionTab && (
          <>
            <Typography align="center">
              {t('points.visualizationDataIsEmpty')}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

ComparisonAgainstSatelliteResult.propTypes = {
  statistics: PropTypes.object.isRequired,
};

export default memo(ComparisonAgainstSatelliteResult);
