import dateFnsFormat from 'date-fns/format';
import dateFnsParseISO from 'date-fns/parseISO';
import { format, addMinutes } from 'date-fns';

import { DATE_FORMATS, UTC_SUFFIX } from 'constants/common';

/**
 * Date formatter. Convert input date to format.
 * @param {String|Date} date
 * @param {String} [dateFormat]
 * @param {Object} [options]
 * @return {string}
 */
export const formatDate = (
  date,
  dateFormat = DATE_FORMATS.digitsFormat,
  options = {}
) => dateFnsFormat(dateFnsParseISO(date), dateFormat, options);

/**
 * Convert and format a date in UTC
 * @param {Date} date
 * @param {String} strFormat
 * @returns {string}
 */
export const formatDateToUTC = (date, strFormat) =>
  `${format(
    addMinutes(date, date.getTimezoneOffset()),
    strFormat
  )} ${UTC_SUFFIX}`;

const dateOrNull = (date) => (date !== null ? new Date(date) : null);

export const formatHeavyCustomer = (customer) => {
  customer.lastVisit = dateOrNull(customer.lastVisit);
  customer.subscriptionEnd = dateOrNull(customer.subscriptionEnd);
  customer.offerInstances = customer.offerInstances.map((offer) => ({
    ...offer,
    offerStart: dateOrNull(offer.offerStart),
    offerEnd: dateOrNull(offer.offerEnd),
  }));
  customer.users = customer.users.map((user) => ({
    ...user,
    lastLogin: dateOrNull(user.lastLogin),
  }));
  return customer;
};

export const formatLightCustomer = (customer) => {
  customer.lastVisit = dateOrNull(customer.lastVisit);
  customer.subscriptionEnd = dateOrNull(customer.subscriptionEnd);
  return customer;
};

export const formatHeavyUser = (user) => {
  user.lastLogin = dateOrNull(user.lastLogin);
  user.dateJoined = dateOrNull(user.dateJoined);
  return user;
};

export const formatLightUser = (user) => {
  user.lastLogin = dateOrNull(user.lastLogin);
  return user;
};

export const formatHeavyOffer = (offer) => {
  offer.offerStart = dateOrNull(offer.offerStart);
  offer.offerEnd = new Date(offer.offerEnd);
  return offer;
};

export const formatLightOffer = (offer) => offer;
