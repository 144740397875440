import React, { useCallback } from 'react';
import PropsTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { useModal } from 'hooks/useModal';
import DeleteModalComponent from 'components/modals/MarineContractorsModal/Delete/index';
import { selectMarineContractorsObjectError } from 'ducks/marineContractors/selectors';
import { getMCObjectType, getMCActions } from 'helpers/marineContractors';
import { MC_OBJECT_GROUP } from 'constants/marineContractors';

/**
 * Delete marine contractors modal container
 * @param { string } id - modalId
 * @param { object } object - Marine contractors object (boats/oeprations/constraints)
 * @returns { JSX }
 */
const DeleteModalMCContainer = ({ id, object }) => {
  const { onClose } = useModal(id);
  const dispatch = useDispatch();

  const objectType = getMCObjectType(object);
  const objectGroup = MC_OBJECT_GROUP[objectType];
  const { deleteAction } = getMCActions(objectType);

  const error = useSelector((state) =>
    selectMarineContractorsObjectError(objectGroup)(state)
  );

  const handleOnValid = useCallback(() => {
    dispatch(deleteAction(object, id));
  }, [object, id, deleteAction, dispatch]);

  return (
    <DeleteModalComponent
      onClose={onClose}
      error={error}
      handleOnValid={handleOnValid}
    />
  );
};

DeleteModalMCContainer.propTypes = {
  id: PropsTypes.string.isRequired,
  object: PropsTypes.object.isRequired,
};

export default React.memo(DeleteModalMCContainer);
