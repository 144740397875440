import { makeStyles } from '@material-ui/core/styles';

import { theme } from 'common/theme';

export const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& input': {
      padding: 10,
    },
    marginTop: 10,
  },
  fieldName: {
    fontWeight: 'bold',
    marginBottom: 25,
  },
  checkboxRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  saveButton: {
    ...theme.buttons.outlined.secondary,
  },
  helperText: {
    height: 22,
    maxWidth: 250,
    wordWrap: 'break-word',
  },
});
