import {
  VALUES_KEY,
  PD_SELECT_OCCURENCE_KEY,
  PD_SELECT_TITLES_BY_VCLASS_TYPE,
  DP_VCLASS_TYPE,
  OFFSET_ANGLE,
} from 'constants/graphs';
import { INVALID_GRAPH_DATA } from 'constants/errors';
import { VCLASS_TYPE_REGEXP } from 'constants/regexp';
import {
  getSectorsFromAngles,
  getAnglesFromBisectorsCollection,
  getProbabilitiesForSelectFromRawGraphData,
  getThetawValuesForSelectFromRawGraphData,
} from 'helpers/graphs/common';
import { DESC } from 'constants/common';
import { toCamelCase } from 'helpers/camelizer';

/**
 * returns common probability distribution selector params
 * @note occurrenceValues could be values array, or array of values arrays (by months)
 * depends on given rawData
 * @param { object } rawData
 * @returns {{
 *   title: string,
 *   label: string,
 *   sectors: number[],
 *   legendAngles: [number, number][],
 *   occurrenceValues: number[] | array[],
 *   probabilities: number[][][] | number[][][][] ,
 * }}
 */
const getCommonPDSelectParams = (rawData) => {
  const [, vclassType] = VCLASS_TYPE_REGEXP.exec(rawData.id) || [];
  const occurrence = rawData[PD_SELECT_OCCURENCE_KEY];

  if (!vclassType || !occurrence) {
    throw Error(INVALID_GRAPH_DATA);
  }

  const title = PD_SELECT_TITLES_BY_VCLASS_TYPE[vclassType];
  const probabilities =
    vclassType === DP_VCLASS_TYPE
      ? getProbabilitiesForSelectFromRawGraphData(rawData)
      : getThetawValuesForSelectFromRawGraphData(rawData);
  const anglesKey = toCamelCase(
    occurrence.dimensions[occurrence.dimensions.length - 1]
  );
  const label = occurrence.attributes.longName;
  const angles = rawData[anglesKey][VALUES_KEY];
  const occurrenceValues = occurrence[VALUES_KEY];
  const arc = 360 / angles.length;
  const legendAngles = getAnglesFromBisectorsCollection(angles);
  const sectors = getSectorsFromAngles({
    anglesValues: angles,
    offset: OFFSET_ANGLE + arc / 2,
    direction: DESC,
  });

  return {
    title,
    label,
    angles,
    sectors,
    legendAngles,
    occurrenceValues,
    probabilities,
  };
};

/**
 * returns prepared probability distribution select data for year
 * @param { object } rawData
 * @returns {{
 *   leafsOccurrences: number[],
 *   probabilities: number[][][],
 *   data: {
 *     leafs: { sector, start, end, occurrence }[],
 *     title: string,
 *     label: string,
 *     legendAngles: array,
 *   }
 * }}
 */
export const getPreparedPDSelectAnnualData = (rawData) => {
  const {
    title,
    label,
    sectors,
    legendAngles,
    occurrenceValues,
    probabilities,
  } = getCommonPDSelectParams(rawData);

  const leafs = occurrenceValues.map((occurrence, index) => ({
    ...sectors[index],
    occurrence,
  }));

  return {
    probabilities,
    leafsOccurrences: occurrenceValues,
    data: {
      title,
      label,
      leafs,
      legendAngles,
    },
  };
};

/**
 * returns prepared probability distribution select data in arrays by months
 * @param { object } rawData
 * @returns {{
 *   leafsOccurrences: number[],
 *   probabilities: number[][][][],
 *   data: Array<{
 *     leafs: { sector, start, end, occurrence }[],
 *     title: string,
 *     label: string,
 *     legendAngles: array,
 *    }>,
 * }}
 */
export const getPreparedPDSelectMonthlyData = (rawData) => {
  const {
    title,
    label,
    sectors,
    legendAngles,
    occurrenceValues,
    probabilities,
  } = getCommonPDSelectParams(rawData);
  const { data, leafsOccurrences } = occurrenceValues.reduce(
    (acc, monthOccurrences) => {
      const leafs = monthOccurrences.map((occurrence, index) => ({
        ...sectors[index],
        occurrence,
      }));

      acc.leafsOccurrences.push(monthOccurrences);
      acc.data.push({
        title,
        label,
        leafs,
        legendAngles,
      });

      return acc;
    },
    { data: [], leafsOccurrences: [] }
  );

  return {
    probabilities,
    leafsOccurrences,
    data,
  };
};
