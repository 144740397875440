import { useEffect, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Leaflet from 'leaflet-providers';

import { useExpensiveRef } from 'hooks/useExpensiveRef';

import './style.scss';

/**
 * React component - leaflet layer wrapper
 *
 * @param {Boolean} autoZIndex - leaflet option, if true - leaflet auto generate zIndex
 * @param {String} path
 * @param {String} name
 * @param {String} zIndex
 * @param {Function} add
 * @param {Function} remove
 * @return {null}
 */
const MapLayer = ({ path, autoZIndex, name, zIndex, add, remove }) => {
  const options = useMemo(() => ({ autoZIndex, name, zIndex }), [
    autoZIndex,
    name,
    zIndex,
  ]);
  const tileLayer = useExpensiveRef(() => new Leaflet.TileLayer(path, options));

  useEffect(() => {
    const layer = tileLayer.current;
    add(layer);

    return () => remove(layer);
  }, [tileLayer, add, remove]);

  useEffect(() => {
    tileLayer.current.setUrl(path);
  }, [tileLayer, path]);

  useEffect(() => {
    Leaflet.setOptions(tileLayer.current, options);
  }, [tileLayer, options]);

  return null;
};

MapLayer.propTypes = {
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  autoZIndex: PropTypes.bool,
  zIndex: PropTypes.bool,
};

MapLayer.defaultProps = {
  autoZIndex: false,
};

export default memo(MapLayer);
