import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SimpleSelect from 'components/common/SimpleSelect';
import { DEFAULT_GRAPH_SELECT_VALUE } from 'constants/graphs';

import { useStyles } from './styles';

/**
 * Select component to use in graph controls
 * @param { string } name - graph param name as in graph data
 * @param { string } label
 * @param { string|number } value
 * @param { array } values - possible param values
 * @param { string } units - param units, e.g. m/s or %
 * @param { function } unitsRule - rule to define whether add units or not
 * @param { function } onChange
 * @param { object } restProps
 * @return { jsx }
 */
const GraphParamSelect = ({
  name,
  label,
  value,
  values,
  units,
  unitsRule,
  onChange,
  ...restProps
}) => {
  const classes = useStyles();
  const optionList = useMemo(
    () =>
      values.map((currentValue, index) => {
        const option =
          units && (!unitsRule || !!unitsRule(currentValue))
            ? `${currentValue} ${units}`
            : currentValue;

        return {
          value: index,
          option,
        };
      }),
    [values, units, unitsRule]
  );
  const renderValue = useCallback(
    (selectedValue) => {
      const { option: selectedOption = '' } = optionList[selectedValue];
      return `${label} ${selectedOption}`;
    },
    [label, optionList]
  );
  const selectClassName = classNames(classes.select, restProps.className);

  return (
    <SimpleSelect
      {...restProps}
      className={selectClassName}
      name={name}
      optionList={optionList}
      onChange={onChange}
      valueUppercase={false}
      value={value}
      renderValue={label ? renderValue : undefined}
      withBaseInput
    />
  );
};

GraphParamSelect.propTypes = {
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  units: PropTypes.string,
  unitsRule: PropTypes.func,
};

GraphParamSelect.defaultType = {
  unitsRule: null,
  value: DEFAULT_GRAPH_SELECT_VALUE,
  label: '',
  units: '',
};

export default React.memo(GraphParamSelect);
