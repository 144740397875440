import React from 'react';
import PropTypes from 'prop-types';

import { useToggle } from 'hooks/useToggle';
import { DEFAULT_AXIS_INDEX } from 'constants/graphs';
import { palette } from 'common/theme';

import { useStyles } from './styles';

const LEGEND_WIDTH = 160;
const LEGEND_HEIGHT = 65;
const LEGEND_OFFSET = 5;
const FONT_SIZE = 10;
const SVG_TEXT_DY = 15;

/**
 * CustomSvgLegend for Return value chart
 * @param { number } legendWidth
 * @param { number } legendHeight
 * @param { object } margin
 * @param { object } yAxisMap
 * @param { object } xAxisMap
 * @param {{ point: string, lines: string, outliers: string }} colors
 * @param { number } confidence
 * @param { bool } withOutlier
 * @returns {*}
 * @constructor
 */
const OneLawLegend = ({
  legendWidth,
  legendHeight,
  margin,
  yAxisMap,
  xAxisMap,
  colors,
  confidence,
  withOutlier = false,
}) => {
  const classes = useStyles();
  const { isActive: showLegend, toggle: toggleLegend } = useToggle(true);
  const { x: xOffset } = xAxisMap[DEFAULT_AXIS_INDEX];
  const { padding = {} } = yAxisMap[DEFAULT_AXIS_INDEX];
  const yOffset = (margin.top || 0) + padding.top || 0;

  const x = xOffset + LEGEND_OFFSET;
  const y = yOffset + LEGEND_OFFSET;
  const {
    line: lineColor,
    point: pointColor,
    outliers: outliersColor,
  } = colors;

  return (
    <g>
      {showLegend ? (
        <g onClick={toggleLegend}>
          <rect
            x={x}
            y={y}
            fill={palette.white.main}
            className={classes.legend}
            width={legendWidth}
            height={legendHeight}
            rx={3}
            ry={3}
          />
          <line
            x1={x + 5}
            x2={x + 20}
            y1={y + 15}
            y2={y + 15}
            stroke={lineColor}
          />
          <line
            x1={x + 5}
            x2={x + 20}
            y1={y + 30}
            y2={y + 30}
            strokeDasharray="2"
            stroke={lineColor}
          />
          <circle
            cx={x + 13}
            cy={y + 45}
            r={5}
            fill={pointColor}
            stroke={palette.black.main}
          />
          {(() => {
            if (withOutlier) {
              return (
                <circle
                  cx={x + 13}
                  cy={y + 60}
                  r={5}
                  fill={outliersColor}
                  stroke={palette.black.main}
                />
              );
            }
          })()}
          <text y={y + 3} fontSize={FONT_SIZE}>
            <tspan x={x + 30} dy={SVG_TEXT_DY}>
              Return values
            </tspan>
            <tspan x={x + LEGEND_WIDTH - 15} dy={0}>
              X
            </tspan>
            <tspan x={x + 30} dy={SVG_TEXT_DY}>
              {confidence}% confidence interval
            </tspan>
            <tspan x={x + 30} dy={SVG_TEXT_DY}>
              Empirical return values
            </tspan>
            {(() => {
              if (withOutlier) {
                return (
                  <tspan x={x + 30} dy={SVG_TEXT_DY}>
                    Ignored outliers
                  </tspan>
                );
              }
            })()}
          </text>
        </g>
      ) : (
        <text
          x={x}
          y={y}
          dy="1em"
          className={classes.legendLink}
          fontSize={FONT_SIZE}
          onClick={toggleLegend}
        >
          show legend
        </text>
      )}
    </g>
  );
};

OneLawLegend.propTypes = {
  legendWidth: PropTypes.number,
  legendHeight: PropTypes.number,
  margin: PropTypes.object,
  yAxisMap: PropTypes.object,
  xAxisMap: PropTypes.object,
  colors: PropTypes.shape({
    dot: PropTypes.string,
    line: PropTypes.string,
  }),
  confidence: PropTypes.number,
};

OneLawLegend.defaultProps = {
  legendWidth: LEGEND_WIDTH,
  legendHeight: LEGEND_HEIGHT,
};

export default OneLawLegend;
