import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useStyles } from './styles';

/**
 * BaseSideBar component.
 * used to create custom sidebars
 * @param {React.ReactNode} children - sidebar elements
 * @param {String} sideBarClass - class for setting sidebar styles
 */
const BaseSideBar = ({ children, sideBarClass }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.baseStyle, sideBarClass)}>
      {children}
    </div>
  );
};

BaseSideBar.propTypes = {
  children: PropTypes.node.isRequired,
  sideBarClass: PropTypes.string,
};

export default memo(BaseSideBar);
