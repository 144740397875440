import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: (props) => ({
    background: props.color,
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
    outline: 'none',
  }),
});
