import { API_URL } from 'common/config';

/**
 * Return layers from stats2d field.
 * @param {Object} field
 * @param {Object} propertiesData
 * @return {Array}
 */
const getStats2dLayersWithPropertyFromField = (field, propertiesData) =>
  Object.entries(field).reduce((acc, [layerName, tile]) => {
    const property = propertiesData[tile];
    // avoid double slash
    const cleanedTilePath = API_URL.endsWith('/')
      ? tile.replace(/^\//, '')
      : tile;
    if (property) {
      acc.push({
        name: layerName,
        property,
        tile: `${API_URL}${cleanedTilePath}`,
      });
    }

    return acc;
  }, []);

/**
 * Map 2d stats to array with tile data
 * @param {Array} stats2d
 * @param {Object} propertiesData
 * @return {Array}
 */
export const getTilesFromStats2dToGeometries = (stats2d, propertiesData) =>
  stats2d.reduce((acc, { fields }) => {
    Object.entries(fields).forEach(([geometryName, field]) => {
      const layers = getStats2dLayersWithPropertyFromField(
        field,
        propertiesData
      );

      if (layers.length > 0) {
        acc.push({
          name: geometryName,
          layers,
        });
      }
    });

    return acc;
  }, []);

/**
 * Convert properties to object property by tile
 * @param {Array} tiles
 * @param {Array} properties
 * @return {Object}
 */
export const prepareTileProperties = (tiles, properties) =>
  tiles.reduce((acc, tile, index) => {
    acc[tile] = properties[index];
    return acc;
  }, {});

/**
 * Tiles get from fields array
 * @param {Array} fields
 * @return {Array}
 */
export const getTilesFromStats2dFields = (fields) =>
  fields.reduce((acc, field) => {
    Object.values(field).forEach((subfields) => {
      acc.push(...Object.values(subfields));
      return acc;
    });

    return acc;
  }, []);
