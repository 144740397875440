import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, buttons }) => ({
  container: {
    width: 600,
    padding: '10px 20px 20px 20px',
  },
  greenTitle: {
    marginBottom: 10,
    color: palette.tertiary.main,
  },
  closeButton: {
    ...buttons.elevated.primary,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${palette.lightGrey.main}`,
    paddingTop: 15,
  },
}));
