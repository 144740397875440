import { makeStyles } from '@material-ui/core/styles';

import { theme } from 'common/theme';

export const useStyles = makeStyles(({ palette }) => ({
  title: {
    marginRight: '20px',
    color: palette.sinay.primary['40'],
    padding: '15px 0px',
    fontSize: 24,
    fontWeight: 'bold',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  statisticsContainer: {
    width: '95%',
    backgroundColor: palette.background.paper,
  },
  tabs: {
    borderRight: `1px solid ${palette.divider}`,
  },
  pointTab: {
    minWidth: 0,
  },
  appBar: {
    background: 'none',
  },
  graphTitle: {
    fontWeight: 'bold',
  },
  launchButton: {
    ...theme.buttons.outlined.secondary,
  },
  exportButton: {
    ...theme.buttons.elevated.neutral,
  },
  operationsDashboard: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
  },
  operationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 20,
    padding: 10,
    width: '100%',
    '@media(min-width: 800px)': {
      width: '45%',
    },
    '@media(min-width: 1200px)': {
      width: '32%',
    },
  },
  operationCard: {
    width: '100%',
    height: 'auto',
    background: '#FFFFFF',
    overflow: 'none',
    borderRadius: 15,
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.4)',
  },
  operationCardHeader: {
    padding: '10px 20px',
    backgroundColor: palette.sinay.primary['40'],
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  operationTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
  },
  operationSummaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 25,
    padding: 10,
  },
  operationSummaryItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    gap: 10,
  },
  operationSummarySubtitleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  operationSummaryLabel: {
    margin: 0,
    fontSize: 14,
    fontWeight: 600,
    color: palette.sinay.primary['80'],
  },
  operationSummarySubtitle: {
    width: '50%',
    fontSize: 16,
    color: 'white',
  },
  operationSummaryIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    borderRadius: 15,
    backgroundColor: palette.sinay.primary['80'],
  },
  operationConstraintsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    padding: '10px 20px',
  },
  constraintContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 15,
  },
  constraintLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  },
  constraintLabel: {
    margin: 0,
    fontWeight: 600,
    fontSize: 12,
  },
  constraintLimit: {
    margin: 0,
    fontSize: 12,
  },
}));
