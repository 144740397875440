import { get, pick } from 'lodash/object';

import {
  RAW_DATA_GRAPH_PARAMS_PATH,
  VALUES_KEY,
  WEATHER_GRAPH_VALUABLE_PARAMS,
} from 'constants/graphs';
import { INVALID_GRAPH_DATA } from 'constants/errors';
import { camelizeBoth, toCamelCase } from 'helpers/camelizer';
import { prettifyNumbersArray } from 'helpers/data';
import {
  getLevelsTypeByStatsId,
  getPreparedLimitVariablesFromGraphData,
  getTitleFromRawGraphData,
} from 'helpers/graphs/common';

/**
 * returns prepared data for weather graph.
 * @param rawGraphData
 * @returns { object }
 * @note variables with 'Limit' postfix are dynamic and may change,
 * duration, probability and quantile variables are common and always exists in weather graph data
 */
export const getPreparedWeatherGraphData = (rawGraphData) => {
  const rawGraphParams = get(rawGraphData, RAW_DATA_GRAPH_PARAMS_PATH, null);
  if (!rawGraphParams) {
    throw Error(INVALID_GRAPH_DATA);
  }

  const { ylabel: yLabel, ...graphParams } = pick(
    rawGraphParams,
    WEATHER_GRAPH_VALUABLE_PARAMS
  );

  const {
    durationName,
    quantileName,
    probabilityName,
    probabilityMinName,
    probabilityMaxName,
  } = camelizeBoth(graphParams);

  const levels =
    rawGraphData.level && rawGraphData.level[VALUES_KEY]
      ? {
          type: getLevelsTypeByStatsId(rawGraphData.id),
          values: rawGraphData.level[VALUES_KEY],
        }
      : {};

  const title = getTitleFromRawGraphData(rawGraphData);
  const { limitsParams, limitsValues } = getPreparedLimitVariablesFromGraphData(
    rawGraphData
  );

  const duration = rawGraphData[durationName];
  const quantiles = rawGraphData[quantileName];

  const { [VALUES_KEY]: values, ...rest } = rawGraphData[probabilityName];
  const probability = {
    ...rest,
    [VALUES_KEY]: prettifyNumbersArray(values),
  };

  const { [VALUES_KEY]: minValues, ...minRest } = rawGraphData[
    probabilityMinName
  ];
  const probabilityMin = {
    ...minRest,
    [VALUES_KEY]: prettifyNumbersArray(minValues),
  };

  const { [VALUES_KEY]: maxValues, ...maxRest } = rawGraphData[
    probabilityMaxName
  ];
  const probabilityMax = {
    ...maxRest,
    [VALUES_KEY]: prettifyNumbersArray(maxValues),
  };
  const [, ...dimensionsOmitMonth] = probability.dimensions;
  const dimensions = dimensionsOmitMonth.map(toCamelCase);

  return {
    title,
    yLabel,
    levels,
    duration,
    quantiles,
    probability,
    probabilityMin,
    probabilityMax,
    limitsParams,
    limitsValues,
    dimensions,
  };
};
