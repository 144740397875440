import React, { memo, useCallback } from 'react';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';

import { useStyles } from './styles';

/** A simple selectable row which redirect to a different page/resource
 * @param { string } to - The page to reditect to
 * @param { number } id - the id of the row, needed for React optimisation (usually the id of the object putted in row)
 * @param { node } children - The content displayed by the row
 */
const SimpleLinkRow = ({ to, id, children, ...props }) => {
  const classes = useStyles();
  const history = useHistory();

  const redirect = useCallback(() => {
    history.push(to);
  }, [to, history]);

  return (
    <TableRow key={id} onClick={redirect} className={classes.row} {...props}>
      {children}
    </TableRow>
  );
};
export default memo(SimpleLinkRow);
