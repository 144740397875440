import React, { memo } from 'react';
import PropTypes from 'prop-types';

import BaseDataTable from 'components/common/BaseDataTable';

import { useStyles } from './styles';

/**
 * Render a table with rows information
 * @param {Array} rows
 * @param {Number} digits
 * @return {jsx}
 */
const ComparisonAgainstSatelliteRows = ({ rows, digits = 2 }) => {
  const classes = useStyles();
  const [headers, rowsData] = rows;
  const { columns, data } = headers.reduce(
    (acc, header, index) => {
      const value = rowsData[index];
      acc.columns.push({ title: header, field: header });
      acc.data[header] = value === '-' ? value : Number(value).toFixed(digits);

      return acc;
    },
    {
      columns: [],
      data: { id: 'rows' },
    }
  );

  return (
    <BaseDataTable
      columns={columns}
      data={[data]}
      className={classes.rowsTable}
    />
  );
};

ComparisonAgainstSatelliteRows.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.array).isRequired,
};

export default memo(ComparisonAgainstSatelliteRows);
