import Typography from '@material-ui/core/Typography';
import React from 'react';
import PropTypes from 'prop-types';

import ConfirmationModal from 'components/common/ConfirmationModal';

import { useStyles } from './styles';

/**
 * ConfirmationDeleteModal component
 * Modal for approve project deletion
 * @param {function} closeModal
 * @param {function} onConfirm
 * @param {String} title
 * @param {String} message
 */
const ConfirmationDeleteModal = ({ closeModal, onConfirm, title, message }) => {
  const classes = useStyles();

  return (
    <ConfirmationModal
      title={title}
      onClose={closeModal}
      onConfirm={onConfirm}
      onCancel={closeModal}
      modalClass={classes.container}
      warning
    >
      <div className={classes.body}>
        <Typography gutterBottom variant="body1">
          {message}
        </Typography>
      </div>
    </ConfirmationModal>
  );
};

ConfirmationDeleteModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export default React.memo(ConfirmationDeleteModal);
