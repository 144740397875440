import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import BaseModal from 'components/common/BaseModal';
import { SUPPORT_EMAIL } from 'common/config';
import NativeLink from 'components/common/NativeLink';

import { useStyles } from './styles';

/**
 * ConfirmationDeleteModal component
 * Modal for approve project deletion
 * @param {Function} closeModal
 * @param {Function} onConfirm
 * @param {Number} price
 * @param {Number} availableCredits
 * @param {Boolean} isManager
 * @param {Boolean} isFree
 */
const GetMapsModal = ({
  closeModal,
  onConfirm,
  price,
  availableCredits,
  isManager,
  isFree,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hasCredits = availableCredits >= price;
  const allowToBuy = isFree || (hasCredits && isManager);
  const controls = (
    <Grid container justifyContent="center">
      <Button onClick={closeModal} className={classes.cancelButton}>
        {t('buttons.cancel')}
      </Button>
      {allowToBuy && (
        <Button onClick={onConfirm} className={classes.confirmButton}>
          {t('buttons.yes')}
        </Button>
      )}
    </Grid>
  );

  return (
    <BaseModal
      title={
        isFree || hasCredits
          ? t('projects.buyMapsModal.title')
          : t('projects.buyMapsModal.notEnoughCredits')
      }
      onClose={closeModal}
      modalClass={classes.container}
      controls={controls}
    >
      <div className={classes.body}>
        {allowToBuy ? (
          <Typography gutterBottom variant="body1">
            {t('projects.buyMapsModal.message', { price })}
          </Typography>
        ) : (
          <>
            {!hasCredits && (
              <Typography align="center" variant="body1" gutterBottom>
                {t('projects.buyMapsModal.notEnoughCreditsMessage')}&nbsp;
                <NativeLink to={`mailto:${SUPPORT_EMAIL}`}>
                  {SUPPORT_EMAIL}
                </NativeLink>
              </Typography>
            )}
            {!isManager && (
              <Typography gutterBottom>
                {t('projects.buyMapsModal.onlyManagerCanBuy')}
              </Typography>
            )}
          </>
        )}
      </div>
    </BaseModal>
  );
};

GetMapsModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  availableCredits: PropTypes.number.isRequired,
  isManager: PropTypes.bool.isRequired,
  isFree: PropTypes.bool.isRequired,
};

export default React.memo(GetMapsModal);
