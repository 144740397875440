import React, { memo } from 'react';
import Backdrop from '@material-ui/core/Backdrop';

import { useStyles } from './styles';

/**
 * Common Backdrop with zIndex enough to overlap all other components
 * @param props
 * @returns { JSX }
 * @see https://material-ui.com/ru/api/backdrop/
 */
const CommonBackdrop = (props) => {
  const classes = useStyles();
  return <Backdrop {...props} className={classes.root} />;
};

export default memo(CommonBackdrop);
