import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  card: {
    height: 300,
    overflow: 'hidden',
    borderRadius: 10,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
      transition: 'all 0.1s ease 0s',
    },
  },
});
