import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import React, { useCallback } from 'react';

import SelectMonthRadioGroup from 'components/common/buttons/SelectMonthButtonGroup';
import SimpleSelect from 'components/common/SimpleSelect';
import { EMPTY_VALUE } from 'constants/common';
import { useForm } from 'hooks/useForm';

import { validationRules } from './validationRules';
import { defaultValues } from './defaultValues';
import { useStyles } from './styles';

/**
 * make sure you declare initialValues out of component, or use memoized value
 */
const initialValues = {
  name: 'Silvester',
  lastname: 'Stallone',
  gender: 3,
  occupation: 4,
};

const genderOptionList = [
  { value: '', option: ' - ' },
  { value: 1, option: 'Man' },
  { value: 2, option: 'Woman' },
  { value: 3, option: 'Superman' },
];

const occupationOptionList = [
  { value: '', option: ' - ' },
  { value: 1, option: 'employee' },
  { value: 2, option: 'unemployed' },
  { value: 3, option: 'star' },
  { value: 4, option: 'superstar' },
];

/**
 * callbacks example
 */
const onError = (errors) => {
  // eslint-disable-next-line
  console.log('errors: ', errors);
};
/**
 * here could be action dispatch for posting form data to api
 */
const onSuccess = (values) => {
  // eslint-disable-next-line
  console.log('values: ', values);
};

export const ExampleForm = React.memo(() => {
  const classes = useStyles();
  const {
    setField,
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    errors,
  } = useForm({
    defaultValues,
    initialValues,
    initialErrors: EMPTY_VALUE,
    validationRules,
    onSuccess,
    onError,
  });
  const {
    name,
    lastname,
    email,
    password,
    checkbox,
    occupation,
    month,
  } = values;

  const checkboxHandler = useCallback(
    ({ target }) => setField('checkbox', target.checked),
    [setField]
  );

  const setMonth = useCallback((value) => setField('month', value), [setField]);

  return (
    <form className={classes.form} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            name="name"
            defaultValue={name}
            onBlur={handleBlur}
            error={!!errors.name}
            helperText={errors.name}
            variant="outlined"
            required
            label="First Name"
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            name="lastname"
            defaultValue={lastname}
            onBlur={handleBlur}
            error={!!errors.lastname}
            helperText={errors.lastname}
            variant="outlined"
            required
            fullWidth
            label="Last Name"
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="outlined"
            required
            label="Email Address"
            name="email"
            defaultValue={email}
            onBlur={handleBlur}
            error={!!errors.email}
            helperText={errors.email}
          />
        </Grid>
        {/**
         * UNCONTROLLED INPUT EXAMPLE
         */}
        <Grid item xs={4}>
          <SimpleSelect
            name="gender"
            error={!!errors.gender}
            label="Gender: "
            defaultValue={3}
            onBlur={handleBlur}
            variant="filled"
            optionList={genderOptionList}
          />
          <FormHelperText error>{errors.gender}</FormHelperText>
        </Grid>
        {/**
         * CONTROLLED INPUT EXAMPLE
         */}
        <Grid item xs={4}>
          <SimpleSelect
            name="occupation"
            label="Occupation: "
            value={occupation}
            onChange={handleChange}
            variant="outlined"
            optionList={occupationOptionList}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            required
            defaultValue={password}
            error={!!errors.password}
            helperText={errors.password}
            onBlur={handleBlur}
            fullWidth
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="checkbox"
                value={checkbox}
                onChange={checkboxHandler}
                color="primary"
              />
            }
            label="I want to receive inspiration, marketing promotions and updates via email."
          />
          {!!errors.checkbox && (
            <FormHelperText error>{errors.checkbox}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={12}>
          <SelectMonthRadioGroup
            withAnnual
            size="small"
            name="month"
            value={month}
            setMonth={setMonth}
          />
        </Grid>
      </Grid>
      <br />
      <Button
        data-submit
        color="secondary"
        variant="contained"
        onClick={handleSubmit}
      >
        Show me validation!
      </Button>
    </form>
  );
});
