import React, { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useModal } from 'hooks/useModal';
import { revokeUserInvitationWaitingRequest } from 'ducks/app/actions';
import UserInvitationManagementModal from 'components/modals/UserInvitationManagementModal';

/**
 * RevokeInvitationModal container
 * Displays modal to revoke user invitation
 * @param id - modal id
 * @param user - user data
 */
const RevokeInvitationModal = ({ id, user }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { onClose } = useModal(id);
  const revokeInvitation = useCallback(() => {
    dispatch(revokeUserInvitationWaitingRequest({ user, modalId: id }));
  }, [dispatch, id, user]);

  return (
    <UserInvitationManagementModal
      onClose={onClose}
      invitationButtonHandler={revokeInvitation}
      messageText={t('collaborators.revokeInvitationMessage')}
      invitationButtonText={t('buttons.revoke')}
      modalTitle={t('collaborators.revokeInvitation')}
    />
  );
};

RevokeInvitationModal.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

export default memo(RevokeInvitationModal);
