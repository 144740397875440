import React, { useCallback, useEffect, useState } from 'react';
import PropsTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import ConstraintComponent from 'components/projects/MarineContractors/Operations/Constraint/index';
import BlockUpdateComponent from 'components/common/MarineContractors/BlockUpdateComponent/index';
import {
  deleteMarineContractorsConstraintSuccess,
  updateMarineContractorsTemporaryConstraint,
} from 'ducks/marineContractors/actions';
import {
  selectMarineContractorsUnits,
  selectMarineContractorsVariables,
} from 'ducks/marineContractors/selectors';
import { getMCSelectors, getMCActions } from 'helpers/marineContractors';
import { EMPTY_ARRAY, EMPTY_VALUE } from 'constants/common';
import { MC_OBJECT_TYPE, NO_ID } from 'constants/marineContractors';

/**
 * Constraint Retrieve/Update/Delete actions container
 * @param { object } constraint
 * @param { boolean } isSelected
 * @param { Boolean } forceFocus
 * @param { Function } handleSelect
 * @returns { JSX }
 */
const ConstraintContainer = ({
  constraint,
  isSelected,
  forceFocus,
  handleSelect,
}) => {
  const dispatch = useDispatch();

  const { createAction, updateAction, deleteAction } = getMCActions(
    MC_OBJECT_TYPE.CONSTRAINT
  );
  const { isCreatingSelector, isUpdatingSelector } = getMCSelectors(
    MC_OBJECT_TYPE.CONSTRAINT
  );

  const variableList = useSelector(selectMarineContractorsVariables);
  const unitList = useSelector(selectMarineContractorsUnits);

  const [constraintUpdate, setConstraintUpdate] = useState(constraint);
  const [forceFocuses, setForceFocuses] = useState(EMPTY_VALUE);
  const [variables, setVariables] = useState(EMPTY_ARRAY);
  const [units, setUnits] = useState(EMPTY_ARRAY);
  const [hasChanged, setHasChanged] = useState(false);

  // look if fields are filled to auth updating/creating
  const isFull = Object.values(constraintUpdate).every(
    (v) => ![undefined, null, ''].includes(v)
  );

  const isUpdating = useSelector((state) =>
    isUpdatingSelector(constraint)(state)
  );
  const isCreating = useSelector((state) =>
    isCreatingSelector(constraint)(state)
  );

  useEffect(() => {
    if (forceFocus) {
      if (!constraintUpdate.varName) {
        setForceFocuses({
          varName: true,
        });
        return;
      }
      if (!constraintUpdate.condition) {
        setForceFocuses({
          condition: true,
        });
        return;
      }
      if (constraintUpdate.limit === null) {
        setForceFocuses({
          limit: true,
        });
      }
    }
    // eslint-disable-next-line
  }, [forceFocus]);

  useEffect(() => {
    if (!isSelected) {
      setForceFocuses({
        varName: false,
        condition: false,
        limit: false,
        unit: false,
      });
    }
  }, [isSelected]);

  const handleHasValid = useCallback(
    (fieldName, hasValid = true) => {
      switch (fieldName) {
        case 'varName':
          setForceFocuses({
            varName: false,
            condition: hasValid,
            limit: false,
            unit: false,
          });
          break;
        case 'condition':
          setForceFocuses({
            varName: false,
            condition: false,
            limit: hasValid,
            unit: false,
          });
          break;
        case 'limit':
          setForceFocuses({
            varName: false,
            condition: false,
            limit: false,
            unit: units?.length > 1 ? hasValid : false,
          });
          break;
        case 'unit':
          setForceFocuses({
            varName: false,
            condition: false,
            limit: false,
            unit: false,
          });
          break;
        default:
          break;
      }
    },
    [units]
  );

  useEffect(() => {
    if (constraint.varName) {
      const unitsFiltered = [
        ...unitList.filter((u) => u.variables.includes(constraint.varName)),
      ];
      setUnits(unitsFiltered);
    }
    setConstraintUpdate(constraint);
  }, [constraint, unitList]);

  useEffect(() => {
    setVariables(variableList);
  }, [variableList]);

  useEffect(() => {
    if (!unitList.length) {
      setUnits(unitList);
    }
  }, [unitList]);

  useEffect(() => {
    if (hasChanged) {
      if (isFull) {
        if (constraintUpdate.id === NO_ID) {
          dispatch(createAction(constraintUpdate));
        } else {
          dispatch(updateAction(constraintUpdate));
        }
      } else {
        dispatch(updateMarineContractorsTemporaryConstraint(constraintUpdate));
      }
      setHasChanged(false);
    }
    // eslint-disable-next-line
  }, [hasChanged, isFull]);

  const handleOnChange = useCallback(
    (object, changed = true) => {
      // yes, it change but same as in store so, just replacing in the component
      // heavy with stringify... but needed
      if (!changed) {
        setConstraintUpdate(() => ({
          ...object,
        }));
        return;
      }
      // when limit field is filled with, for example: '-67-' it returns an empty string
      // does not affect NO_ID object, the user need to select the limit
      if (object.limit === '' && object.id !== NO_ID) {
        object.limit = 0;
      }
      // if variable change, then units too
      // if two units are available for one variable, the first is used
      if (!!object.varName && object.varName !== constraintUpdate.varName) {
        const unitsFiltered = [
          ...unitList.filter((u) => u.variables.includes(object.varName)),
        ];
        setUnits(unitsFiltered);
        setConstraintUpdate(() => ({
          ...object,
          unit: unitsFiltered?.length > 1 ? '' : unitsFiltered[0].name,
        }));
        setHasChanged(true);
        return;
      }
      setConstraintUpdate(() => ({
        ...object,
      }));
      setHasChanged(true);
    },
    // eslint-disable-next-line
    [constraintUpdate]
  );

  const handleDelete = useCallback(() => {
    if (constraint.id === NO_ID) {
      dispatch(deleteMarineContractorsConstraintSuccess(constraint));
    } else {
      dispatch(deleteAction(constraint));
    }
    // eslint-disable-next-line
  }, [constraint]);

  return (
    <BlockUpdateComponent
      isUpdating={isUpdating || isCreating}
      isSelected={isSelected}
    >
      <ConstraintComponent
        constraint={constraintUpdate}
        isSelected={isSelected}
        variables={variables}
        units={units}
        forceFocus={!(isUpdating || isCreating) ? forceFocuses : EMPTY_VALUE}
        handleOnChange={handleOnChange}
        handleDelete={handleDelete}
        handleSelect={handleSelect}
        handleHasValid={handleHasValid}
      />
    </BlockUpdateComponent>
  );
};

ConstraintContainer.propTypes = {
  constraint: PropsTypes.object.isRequired,
  handleSelect: PropsTypes.func.isRequired,
  isSelected: PropsTypes.bool,
  forceFocus: PropsTypes.bool,
};

ConstraintContainer.defaultProps = {
  isSelected: false,
  forceFocus: false,
};

export default React.memo(ConstraintContainer);
